import React from 'react';

import styled from 'styled-components';

import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import colin from '@bootcamp/shared/src/assets/images/colingustus.jpeg';

import {
  OverviewText,
  InstructorDetails,
  InstructorDetailsImage,
  InstructorDetailsText,
  InstructorDetailsTextHeading,
  StyledBody2,
  GuidesContainer,
  GuideWell,
  GuideDownloadButton,
  StyledDownloadIcon,
  Testimonial,
  TestimonialRow,
  TestimonialImage,
  TestimonialAuthor,
  TestimonialName,
  TestimonialByline,
} from './shared';

const StyledTestimonialImage = styled(TestimonialImage)`
  border: 1px solid white;
  border-radius: 100px;
`;

const studyGuide = (
  <>
    <OverviewText>Ari’s Study Guide for INBDE Domination</OverviewText>
    <InstructorDetails>
      <InstructorDetailsImage src={ari} />
      <InstructorDetailsText>
        <InstructorDetailsTextHeading>
          The Step-by-Step Guide on How to Pass the {(new Date()).getFullYear()} INBDE
        </InstructorDetailsTextHeading>
        <StyledBody2>
          I'm Ari, creator of INBDE Bootcamp, and in this guide I will outline a study plan that will help you prepare the right way for the INBDE.
        </StyledBody2>
      </InstructorDetailsText>
    </InstructorDetails>
    <GuidesContainer>
      <GuideWell>
        <StyledBody2>
          Learn exactly how to <strong>save 100s of hours of your life</strong> and <strong>pass the INBDE</strong> the first time.
        </StyledBody2>
        <GuideDownloadButton color="blue" onClick={() => window.open('https://docs.google.com/document/d/1JL5kU_tUSoDMU0Km6umldqIZ1RUvpLEUhdtYMrDuhdw/edit?usp=sharing', '_blank')}>
          <StyledDownloadIcon /> Get Ari's Study Guide
        </GuideDownloadButton>
      </GuideWell>
    </GuidesContainer>
    <Testimonial>
      <StyledBody2 style={{textAlign: 'center'}}>
        "INBDE Bootcamp and Mental Dental are literally ALL YOU NEED to pass the INBDE. Bootcamp saved me so much time studying."
      </StyledBody2>
      <TestimonialRow>
        <StyledTestimonialImage src={colin}/>
        <TestimonialAuthor>
      <TestimonialName>Colin Gustus - <strong>January {(new Date()).getFullYear()} INBDE Pass</strong></TestimonialName>
      <TestimonialByline>Happy INBDE Bootcamp Student</TestimonialByline>
        </TestimonialAuthor>
      </TestimonialRow>
    </Testimonial>
  </>
  );

export default studyGuide;
