import React from 'react';

const Serum = () => (
	<table>
		<thead>
			<tr>
				<th class="labName">Serum</th>
				<th class="refRange">Reference Range</th>
				<th class="SI">SI Reference</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Alanine aminotransferase (ALT)</td>
				<td class="refRange">10–40 U/L</td>
				<td class="SI">10–40 U/L</td>
			</tr>
			<tr>
				<td>Aspartate aminotransferase (AST)</td>
				<td class="refRange">12–38 U/L</td>
				<td class="SI">12–38 U/L</td>
			</tr>
			<tr>

				<td>Alkaline phosphatase</td>
				<td class="refRange">25–100 U/L</td>
				<td class="SI">25–100 U/L</td>
			</tr>
			<tr>

				<td>Amylase</td>
				<td class="refRange">25–125 U/L</td>
				<td class="SI">25–125 U/L</td>
			</tr>

			<tr>

				<td>Bilirubin, total // direct</td>
				<td class="refRange">0.1–1.0 mg/dL // 0.0–0.3 mg/dL</td>
				<td class="SI">2–17 µmol/L // 0–5 µmol/L</td>
			</tr>
			<tr>

				<td>Calcium</td>
				<td class="refRange">8.4–10.2 mg/dL</td>
				<td class="SI">2.1–2.6 mmol/L</td>
			</tr>
			<tr>

				<th colspan="3">Cholesterol</th>
			</tr>
			<tr class="group">

				<td> Total</td>
				<td class="refRange">Normal: &lt;200 mg/dL // High: &gt;240 mg/dL</td>
				<td class="SI">&lt;5.2 mmol/L // &gt;6.2 mmol/L</td>
			</tr>
			<tr class="group">

				<td> HDL</td>
				<td class="refRange">40–60 mg/dL</td>
				<td class="SI">1.0–1.6 mmol/L</td>
			</tr>
			<tr class="group">

				<td> LDL</td>
				<td class="refRange">&lt;160 mg/dL</td>
				<td class="SI">&lt;4.2 mmol/L</td>
			</tr>
			<tr>

				<td>Triglycerides</td>
				<td class="refRange">Normal: &lt;150 mg/dL // Borderline: 151–199 mg/dL</td>
				<td class="SI">&lt;1.70 mmol/L // 1.71–2.25 mmol/L</td>
			</tr>
			<tr>
				<td rowspan="2">Cortisol</td>
				<td class="refRange">0800 h: 5–23 µg/dL // 1600 h: 3–15 µg/dL</td>
				<td class="SI">138–635 nmol/L // 82–413 nmol/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">2000 h: &lt;50% of 0800 h</td>
				<td class="SI">Fraction of 0800 h: &lt;0.50</td>
			</tr>

			<tr>
				<td rowspan="2">Creatine kinase</td>
				<td class="refRange">Male: 25–90 U/L</td>
				<td class="SI">25–90 U/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 10–70 U/L</td>
				<td class="SI">10–70 U/L</td>
			</tr>
			<tr>

				<td>Creatinine</td>
				<td class="refRange">0.6–1.2 mg/dL</td>
				<td class="SI">53–106 µmol/L</td>
			</tr>
			<tr>

				<td>Urea nitrogen</td>
				<td class="refRange">7–18 mg/dL</td>
				<td class="SI">2.5–6.4 mmol/L</td>
			</tr>
			<tr>
				<td rowspan="2">Creatinine clearance</td>
				<td class="refRange">Male: 97–137 mL/min</td>
				<td class="SI">97–137 mL/min</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 88–128 mL/min</td>
				<td class="SI">88–128 mL/min</td>
			</tr>
			<tr>

				<th colspan="3">Electrolytes, serum</th>
			</tr>
			<tr class="group">

				<td> Sodium (Na<sup>+</sup>)</td>
				<td class="refRange">136–146 mEq/L</td>
				<td class="SI">136–146 mmol/L</td>
			</tr>
			<tr class="group">

				<td> Potassium (K<sup>+</sup>)</td>
				<td class="refRange">3.5–5.0 mEq/L</td>
				<td class="SI">3.5–5.0 mmol/L</td>
			</tr>
			<tr class="group">

				<td> Chloride (Cl<sup>−</sup>)</td>
				<td class="refRange">95–105 mEq/L</td>
				<td class="SI">95–105 mmol/L</td>
			</tr>
			<tr class="group">

				<td> Bicarbonate (HCO<sub>3</sub><sup>−</sup>)</td>
				<td class="refRange">22–28 mEq/L</td>
				<td class="SI">22–28 mmol/L</td>
			</tr>
			<tr class="group">

				<td> Magnesium (Mg<sup>2+</sup>)</td>
				<td class="refRange">1.5–2.0 mg/dL</td>
				<td class="SI">0.75–1.0 mmol/L</td>
			</tr>
			<tr>
				<td rowspan="2">Ferritin</td>
				<td class="refRange">Male: 20–250 ng/mL</td>
				<td class="SI">20–250 µg/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 10–120 ng/mL</td>
				<td class="SI">10–120 µg/L</td>
			</tr>
			<tr>
				<td rowspan="4">Follicle-stimulating hormone</td>
				<td class="refRange">Male: 4–25 mIU/mL</td>
				<td class="SI">4–25 U/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: premenopause 4–30 mIU/mL</td>
				<td class="SI">4–30 U/L</td>
			</tr>
			<tr class="group">
				<td class="refRange"> midcycle peak 10–90 mIU/mL</td>
				<td class="SI">10–90 U/L</td>
			</tr>
			<tr class="group">
				<td class="refRange"> postmenopause 40–250 mIU/mL</td>
				<td class="SI">40–250 U/L</td>
			</tr>
			<tr>
				<td rowspan="2">Glucose</td>
				<td class="refRange">Fasting: 70–100 mg/dL</td>
				<td class="SI">3.8–5.6 mmol/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Random, non-fasting: &lt;140 mg/dL </td>
				<td class="SI">&lt;7.77 mmol/L</td>
			</tr>
			<tr>
				<td rowspan="2">Growth hormone - arginine stimulation</td>
				<td class="refRange">Fasting: &lt;5 ng/mL</td>
				<td class="SI">&lt;5 µg/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Provocative stimuli: &gt;7 ng/mL</td>
				<td class="SI">&gt;7 µg/L</td>
			</tr>
			<tr>
				<td rowspan="2">Iron</td>
				<td class="refRange">Male: 65–175 µg/dL</td>
				<td class="SI">11.6–31.3 µmol/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 50–170 µg/dL</td>
				<td class="SI">9.0–30.4 µmol/L</td>
			</tr>
			<tr>
				<td>Total iron-binding capacity</td>
				<td class="refRange">250–400 µg/dL</td>
				<td class="SI">44.8–71.6 µmol/L</td>
			</tr>
			<tr>
				<td>Transferrin</td>
				<td class="refRange">200–360 mg/dL</td>
				<td class="SI">2.0–3.6 g/L</td>
			</tr>
			<tr>
				<td>Lactate dehydrogenase</td>
				<td class="refRange">45–200 U/L</td>
				<td class="SI">45–200 U/L</td>
			</tr>
			<tr>
				<td rowspan="4">Luteinizing hormone</td>
				<td class="refRange">Male: 6–23 mIU/mL</td>
				<td class="SI">6–23 U/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: follicular phase 5–30 mIU/mL</td>
				<td class="SI">5–30 IU/L</td>
			</tr>
			<tr class="group">
				<td class="refRange"> midcycle 75–150 mIU/mL</td>
				<td class="SI">75–150 IU/L</td>
			</tr>
			<tr class="group">
				<td class="refRange"> postmenopause 30–200 mIU/mL</td>
				<td class="SI">30–200 IU/L</td>
			</tr>
			<tr>

				<td>Osmolality</td>
				<td class="refRange">275–295 mOsmol/kg H<sub>2</sub>O</td>
				<td class="SI">275–295 mOsmol/kg H<sub>2</sub>O</td>
			</tr>
			<tr>

				<td>Intact parathyroid hormone (PTH)</td>
				<td class="refRange">10–60 pg/mL</td>
				<td class="SI">10–60 ng/L</td>
			</tr>
			<tr>

				<td>Phosphorus (inorganic)</td>
				<td class="refRange">3.0–4.5 mg/dL</td>
				<td class="SI">1.0–1.5 mmol/L</td>
			</tr>
			<tr>
				<td rowspan="2">Prolactin (hPRL)</td>
				<td class="refRange">Male: &lt;17 ng/mL</td>
				<td class="SI">&lt;17 µg/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: &lt;25 ng/mL</td>
				<td class="SI">&lt;25 µg/L</td>
			</tr>	<tr>

				<th colspan="3">Proteins</th>
			</tr>
			<tr class="group">

				<td> Total</td>
				<td class="refRange">6.0–7.8 g/dL</td>
				<td class="SI">60–78 g/L</td>
			</tr>
			<tr class="group">

				<td> Albumin</td>
				<td class="refRange">3.5–5.5 g/dL</td>
				<td class="SI">35–55 g/L</td>
			</tr>
			<tr class="group">

				<td> Globulin</td>
				<td class="refRange">2.3–3.5 g/dL</td>
				<td class="SI">23–35 g/L</td>
			</tr>
			<tr>
				<td>Troponin I</td>
				<td class="refRange">≤0.04 ng/mL</td>
				<td class="SI">≤0.04 µg/L</td>
			</tr>
			<tr>

				<td>TSH</td>
				<td class="refRange">0.4–4.0 μU/mL</td>
				<td class="SI">0.4–4.0 mIU/L</td>
			</tr>
			<tr>

				<td>Thyroidal iodine (<sup>123</sup>I) uptake</td>
				<td class="refRange">8%–30% of administered dose/24 h</td>
				<td class="SI">0.08–0.30/24 h</td>
			</tr>
			<tr>

				<td>Thyroxine (T<sub>4</sub>)</td>
				<td class="refRange">5–12 µg/dL</td>
				<td class="SI">64–155 nmol/L</td>
			</tr>
			<tr>

				<td>Free T<sub>4</sub></td>
				<td class="refRange">0.9–1.7 ng/dL</td>
				<td class="SI">12.0–21.9 pmol/L</td>
			</tr>
			<tr>

				<td>Triiodothyronine (T<sub>3</sub>) (RIA)</td>
				<td class="refRange">100–200 ng/dL</td>
				<td class="SI">1.5–3.1 nmol/L</td>
			</tr>
			<tr>

				<td>Triiodothyronine (T<sub>3</sub>) resin uptake</td>
				<td class="refRange">25%–35%</td>
				<td class="SI">0.25–0.35</td>
			</tr>
			<tr>

				<td>Uric acid</td>
				<td class="refRange">3.0–8.2 mg/dL</td>
				<td class="SI">0.18–0.48 mmol/L</td>
			</tr>
			<tr>

				<th colspan="3">Immunoglobulins</th>
			</tr>
			<tr class="group">

				<td>IgA</td>
				<td class="refRange">76–390 mg/dL</td>
				<td class="SI">0.76–3.90 g/L</td>
			</tr>
			<tr class="group">

				<td>IgE</td>
				<td class="refRange">0–380 IU/mL</td>
				<td class="SI">0–380 kIU/L</td>
			</tr>
			<tr class="group">

				<td>IgG</td>
				<td class="refRange">650–1500 mg/dL</td>
				<td class="SI">6.5–15.0 g/L</td>
			</tr>
			<tr class="group">

				<td>IgM</td>
				<td class="refRange">50–300 mg/dL</td>
				<td class="SI">0.5–3.0 g/L</td>
			</tr>
			<tr>
				<th colspan="3">Gases, arterial blood (room air)</th>
			</tr>
			<tr class="group">

				<td>pH</td>
				<td class="refRange">7.35–7.45</td>
				<td class="SI">[H<sup>+</sup>] 36–44 nmol/L</td>
			</tr>
			<tr class="group">

				<td>Pco<sub>2</sub></td>
				<td class="refRange">33–45 mm Hg</td>
				<td class="SI">4.4–5.9 kPa</td>
			</tr>
			<tr class="group">

				<td>Po<sub>2</sub></td>
				<td class="refRange">75–105 mm Hg</td>
				<td class="SI">10.0–14.0 kPa</td>
			</tr>
		</tbody>
	</table>
);

export default Serum;