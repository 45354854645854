import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Container from '@bootcamp/web/src/bootcamps/pages/PageContainer';
import {getTestProgressByUserIdHashTestIdForScoring, getTest} from '@bootcamp/shared/src/requests';
import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {scoreResult} from '@bootcamp/shared/src/util/scoring';
import Test from './Test';
import Subject from './Subject';
import firstBy from 'thenby';
import {H1, H2} from '@bootcamp/web/src/components/Typography';
import useDocumentTitle from '@rehooks/document-title';
import Breadcrumbs from '@bootcamp/web/src/components/NavBar/components/Breadcrumbs';
import {Element} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';

const Title = styled(H1)`
  color: white;
  margin-bottom: 32px;
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const getTestsByClassroom = (classrooms, isBootcampPlusStudent) => classrooms
  .filter(({name}) => name !== 'Application Services')
  .reduce((acc, {route, contentTypes}) => ({
    ...acc,
    [route]: contentTypes
      .filter(({name}) => isBootcampPlusStudent ? ['Practice Tests', 'Plus Tests'].includes(name) : name === 'Practice Tests')
      .reduce((acc, {content}) => [
        ...acc,
        ...content
          .reduce((acc, {content}) => [
            ...acc,
            ...content
              ?.reduce((acc, {id}) => [...acc, id], []) || []
          ], [])
      ], [])
  }), {});

export const restoreQuizProgress = quizProgress => {
  const uniqueBlockProgresses = quizProgress.blockProgresses.items
    .sort(firstBy('index'))
    .reduce((acc, blockProgress) => {
      if (blockProgress.questionProgresses?.items.length === 0) return acc;
      if (!acc.find(filteredBlockProgress => filteredBlockProgress.index === blockProgress.index)) {
        acc.push(blockProgress);
      }
      return acc;
    }, []);

  return uniqueBlockProgresses.map(blockProgress => {
    const { questionProgresses: { items: questionProgresses } } = blockProgress;

    const restoredQuestionProgresses = questionProgresses.map((questionProgress, index) => {
      const question = questionProgress.question || {};
      const { subject, topic } = question.tags && question.tags.items && question.tags.items.length > 0 ? (question.tags.items.find(({ tag }) => tag && !!tag.subject && !!tag.topic) || {}).tag || {} : {};

      return {
        subject,
        topic,
        ...questionProgress,
      };
    });

    return {
      index: blockProgress.index,
      questions: restoredQuestionProgresses
    };
  });
};

const Performance = ({history}) => {
  const {config} = useBootcampConfigContext();
  const {isAdmin, isBootcampPlusStudent, DEFAULT_USER_ID, searchUserInteractions, saveUserInteraction, bootcamp} = useUserDataContext();

  const [userId, setUserId] = useState();
  
  useDocumentTitle(`${config.meta.siteTitle} | Performance`);

  useEffect(() => {
    setUserId(DEFAULT_USER_ID);
  }, [DEFAULT_USER_ID]);

  const {fetching, dataByTest, dataBySubject} = usePerformance(isBootcampPlusStudent, config, bootcamp, searchUserInteractions, userId, saveUserInteraction);

  return (
    <Container>
      <StyledBreadcrumbs history={history} lockAtDepth={2} />
      <Title onClick={() => isAdmin && setUserId(window.prompt('Enter user ID to spoof') || DEFAULT_USER_ID)}>Performance</Title>
      <Test dataByTest={dataByTest} fetching={fetching} />
      <Subject dataBySubject={dataBySubject} />
      <H2 style={{margin: '32px 0px 16px', color: 'white'}}>FAQs</H2>
      <Element title="How is my performance calculated?" text="<p>Your performance is based on the results of your completed practice tests.</p>" />
      <Element title="Can I reset my performance?" text="<p>Unfortunately, you cannot reset your performance. However, you can take each practice test as many times as you’d like. Select Show latest attempt to only see your most recent attempt for each practice test.</p>" />
      <Element title="Can I reset or untag my tagged questions?" text="<p>Unfortunately, you cannot reset or untag your tagged questions. Once a question is tagged, it remains tagged. However, you can review every question as many times as you'd like and change the tag each time.</p>" />
    </Container>
  )
}

export const usePerformance = (isBootcampPlusStudent, config, bootcamp, searchUserInteractions, DEFAULT_USER_ID, saveUserInteraction) => {
  const [fetching, setFetching] = useState(false);

  const {sortOrder} = searchUserInteractions(`performance_order`, {sortOrder: 'DESC'});
  const performanceRecord = searchUserInteractions(`${bootcamp}_performance`);
  const SUBJECTS = ['Biology', 'General Chemistry', 'Organic Chemistry', 'Perceptual Ability', 'Reading Comprehension', 'Quantitative Reasoning']
  const TESTS = Array.from({ length: isBootcampPlusStudent ? 15 : 10 }, (_, i) => (i + 1).toString());

  const testsByClassroom = getTestsByClassroom(config.classrooms, isBootcampPlusStudent);
  const practiceTestIds = Object.entries(testsByClassroom).reduce((acc, [classroom, testIds]) => [...acc, ...testIds], []);
  const fltIds = config.studyTools[0].content.map(({id}) => id);

  const dataByTest = TESTS.map(testNumber => {
    const subjectResults = SUBJECTS.map(testSubject => {
      const record = performanceRecord?.results?.find(({testSubject: ts, testNumber: tn}) => ts === testSubject && tn === testNumber);
      if (!record) return {
        score: '-'
      };
      const {score, testId, testProgressId} = record;
      return {score, testId, testProgressId};
    })
    const filteredSubjectResults = subjectResults.filter(({score}, index) => score != '-' && index !== 3);
    const academicAverage = filteredSubjectResults.length === 5 ? Math.round(filteredSubjectResults.reduce((acc, {score}) => acc + parseFloat(score), 0) / filteredSubjectResults.length) : '-';
    return [...subjectResults, {score: academicAverage}]
  })

  const dataBySubject = SUBJECTS.map(testSubject => {
    return TESTS.reduce((acc, testNumber) => {
      const record = performanceRecord?.results?.find(({testSubject: ts, testNumber: tn}) => ts === testSubject && tn === testNumber);
      if (!record) return acc;
      return record.topics.reduce((acc, {name, correct, total, timeSpent}) => {
        return {
          testSubject,
          total: acc.total + total,
          correct: acc.correct + correct,
          timeSpent: acc.timeSpent + parseFloat(timeSpent),
          topics: {
            ...acc.topics,
            [name]: acc.topics[name] ? {
              total: acc.topics[name].total + total,
              correct: acc.topics[name].correct + correct,
              timeSpent: acc.topics[name].timeSpent + parseFloat(timeSpent)
            } : {
              total,
              correct,
              timeSpent: parseFloat(timeSpent)
            }
          }
        }
      }, acc);
    }, {testSubject, total: 0, correct: 0, timeSpent: 0.0, topics: {}})
  })


  useEffect(() => {
    if (!DEFAULT_USER_ID) return;
    const fetch = async () => {
      try {
        setFetching(true);

        if (performanceRecord.createdAt && performanceRecord.sortOrder === sortOrder) {
          return;
        }

        performanceRecord.createdAt = new Date().toISOString();
        performanceRecord.results = [];
        performanceRecord.sortOrder = sortOrder;

        const testFetchResults = await Promise.all([...practiceTestIds, ...fltIds].map(testId => getTestProgressByUserIdHashTestIdForScoring(`${DEFAULT_USER_ID}#${testId}`, true, sortOrder)));
        const testResults = testFetchResults.map(({ data }) => data.TestProgressByTestId.items[0]).filter(a => !!a);
        const tests = await Promise.all(testResults.map(({ testId }) => getTest(testId, 'getTestConfig')));
        const testsById = tests.reduce((acc, { data }) => ({ ...acc, [data.getTest.id]: data.getTest }), {});

        const statsReducer = (acc, quizProgress) => {
          const testConfig = testsById[quizProgress.testId];
          const testTitle = testConfig.config.title;
          const testSubject = testTitle.match(/([^#]+)( Test)/)[1];
          const testNumber = testTitle.match(/#(.*)/)[1];

          const restoredProgress = restoreQuizProgress(quizProgress);

          const stats = fltIds.includes(quizProgress.testId)
            ? SUBJECTS.map((subject, index) => {
                const testTitle = `${subject} Test #${testNumber}`;
                const startIndex = index >= 5 ? index + 2 : index;
                const endIndex = index >= 4 ? index + 3 : index + 1;
                const result = restoredProgress.slice(startIndex, endIndex);
                const stats = scoreResult(result, testTitle, subject, testNumber, quizProgress.createdAt);
                return { stats: { ...stats, testProgressId: quizProgress.id, testId: quizProgress.testId } };
              })
            : [{ stats: { ...scoreResult(restoredProgress, testTitle, testSubject, testNumber, quizProgress.createdAt), testProgressId: quizProgress.id, testId: quizProgress.testId } }];

          return [...acc, ...stats];
        };

        const testStats = testResults.reduce(statsReducer, []);
        for (const { stats } of testStats) {
          const matchingRecord = performanceRecord.results.find(({ testSubject, testNumber }) => testSubject === stats.testSubject && testNumber === stats.testNumber);
          if (!matchingRecord) {
            performanceRecord.results.push(stats);
          } else {
            if ((sortOrder === 'DESC' && matchingRecord.createdAt < stats.createdAt) || (sortOrder === 'ASC' && matchingRecord.createdAt > stats.createdAt)) {
              performanceRecord.results = performanceRecord.results.filter(({ testSubject, testNumber }) => !(testSubject === stats.testSubject && testNumber === stats.testNumber));
              performanceRecord.results.push(stats);
            }
          }
        }

        await saveUserInteraction(`${bootcamp}_performance`, JSON.stringify(performanceRecord));
      } catch (error) {
        console.log(error);
      } finally {
        setFetching(false);
      }
    };
    fetch();
  }, [DEFAULT_USER_ID, sortOrder]);

  return {dataByTest, dataBySubject, fetching};
}
export default Performance;