import {useState} from 'react';
import baseTheme from '@bootcamp/shared/src/styles/theme';

export const useDarkMode = () => {
  const [darkModeEnabled, setMode] = useState(false);

  function getTheme() {
    const overrides = baseTheme.colors.darkModePalette

    // return base theme if light darkModeEnabled
    if (!darkModeEnabled) return baseTheme;

    // otherwise incorporate dark darkModeEnabled overrides
    let darkModeColors = JSON.parse(JSON.stringify(baseTheme.colors));

    Object.keys(overrides).forEach(key => {
      Object.keys(overrides[key]).forEach(subKey => {
        if (typeof overrides[key][subKey] === 'object') {
          Object.keys(overrides[key][subKey]).forEach(subSubKey => {
            if (darkModeColors[key] && darkModeColors[key][subKey]) {
              darkModeColors[key][subKey][subSubKey] = overrides[key][subKey][subSubKey];
            }
          });
        } else {
          if (darkModeColors[key] && darkModeColors[key][subKey]) {
            darkModeColors[key][subKey] = overrides[key][subKey];
          }
        }
      });
    });

    return {
      ...baseTheme,
      colors: darkModeColors,
      darkModeEnabled: true,
    }
  }

  const toggleDarkMode = (value) => {
    setMode(value);
  };

  return {getTheme, toggleDarkMode, darkModeEnabled};
};