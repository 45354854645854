import React from 'react';
import styled from 'styled-components';
const StreakText = styled.span`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  padding-bottom: .5em;
  margin: auto;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
`;

const StreakAlert = ({progress}) => {
  let streak = 0;

  [...progress].reverse().some(progressObj => {
    if (!progressObj.didSubmitCorrectAnswer) {
      return true;
    }
    ++streak;
    return false;
  });

  if (streak === 0) {
    return null;
  }

  const encouragement = ['Awesome!', 'Great Work!', 'That\'s Right!'];
  // const encouragementIndex = Math.floor(Math.random() * encouragement.length);

  if (streak === 1) {
    return (
      <StreakText>{encouragement[0]}</StreakText>
    )
  }

  return (
    <StreakText>{streak} in a row</StreakText>
  )
};


export default StreakAlert;
