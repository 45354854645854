import React, {useCallback, useRef, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import styled from 'styled-components';
import API from '@aws-amplify/api';

import {useAsync} from '../../../hooks';
import {LoadingSpinner} from '../../../components/Branding';
import {Body2} from '../../../components/Typography';

import {createWidget} from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import * as Sentry from '@sentry/react'; // TODO set up sentry for app.bootcamp

import Receipt from './Receipt';

import {IntercomAPI} from 'react-intercom';
import TagManager from 'react-gtm-module';
import {getMembership} from '@bootcamp/shared/src/requests'
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData'
import {invokeLambda} from '@bootcamp/shared/src/requests';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;
  padding: ${({theme}) => theme.layouts.spacing.xl} ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding-top: ${({theme}) => theme.layouts.spacing.m};
  }
  // height: 100%;
  overflow-y: auto;
`;
const EmbedWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 640px;
  max-height: 640px;
  margin: 0 auto ${({theme}) => theme.layouts.spacing.xl};
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
`;
const Typeform = styled.div`
  width: 100%;
  height: 100%;
`;
const LoadingSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;
const ErrorText = styled(Body2)`
  color: white;
`;

function formatReceiptFromPaymentIntent(result) {
  if (result?.url === '/stripe/billing' || result?.body?.subscription) {
    const {amount, paymentMethod: paymentMethodDetails} = result?.body;
    return {amount, ...paymentMethodDetails}
  }
  const {amount, payment_method, currency} = result?.body?.paymentIntent || {};
  const paymentMethodDetails = payment_method?.card || {};
  return {amount, ...paymentMethodDetails, currency}
}

const feedbackFormIdByBootcamp = {
  dat: 'RokieLsJ',
  oat: 'F3gEwHuy',
  inbde: 'b4e2VndJ',
  'med-school': 'xAho4SSo',
  'dental-school': 'QzVA2150',
  anatomy: 'TBMrel22',
  nclex: 'Zlj9pQ4q'
};

const ThankYou = ({match, location}) => {
  const {bootcamp} = match.params;
  const urlParams = new URLSearchParams(location.search);
  const {intentId, referrer} = location.state || {};
  const typeformContainer = useRef();
  const {cognitoUser} = useUserDataContext();
  const sessionId = urlParams.get('session_id');

  const fetchPaymentIntent = useCallback(async () => {
    const result = await invokeLambda('tbc-Stripe', {path: '/stripe/get-payment-intent-or-subscription-from-checkout-session', sessionId});
    return result;
  }, []);
  const {pending: fetchingPaymentIntent, value: paymentIntent, error: fetchError} = useAsync(fetchPaymentIntent);
  const receipt = formatReceiptFromPaymentIntent(paymentIntent);
  useEffect(() => {
    if (!typeformContainer || !typeformContainer.current || !feedbackFormIdByBootcamp[bootcamp]) return;

    createWidget(feedbackFormIdByBootcamp[bootcamp], {container: typeformContainer.current});

  }, [typeformContainer]);

  useEffect(() => {
    const trackMembership = async () => {
      try {
        const membershipId = paymentIntent.body.subscription || paymentIntent.body.paymentIntent.id
        window.localStorage.setItem(`${membershipId}-tracked`, true);
        const {data: {getMembership: membershipInput}} = await getMembership(membershipId);
        const discountedPrice = paymentIntent.body.amount || paymentIntent.body.paymentIntent.amount;
        const originalPrice = discountedPrice;
        const priceId = membershipInput.productId;
        const productName = paymentIntent.body.paymentIntent.metadata.title;
        const discountCode = paymentIntent.body.promoCode;
        // update intercom user attributes
        // create intercom event
        await IntercomAPI('trackEvent', membershipId.startsWith('sub_') ? 'Upgraded to monthly plan' : `Account Upgraded: Joined group(s) ${membershipInput.groups.join(', ')}`, {
          ...membershipInput,
          groups: JSON.stringify(membershipInput.groups),
          discountCode,
          discountedPrice: (discountedPrice / 100).toFixed(2),
          originalPrice: (originalPrice / 100).toFixed(2),
        });
        TagManager.dataLayer({
          dataLayer: {
            'transactionId': membershipInput.id,
            'transactionTotal': (discountedPrice / 100).toFixed(2),
            'transactionCoupon': discountCode,
            'transactionProducts': [{
              'sku': priceId,
              'item_id': priceId,
              'name': productName || priceId,
              'item_name': productName || priceId,
              'price': (discountedPrice / 100).toFixed(2),
              'quantity': 1
            }],
            'email': cognitoUser?.attributes?.email,
            'event': 'Transaction Complete',
          }
        })
        window.rewardful && window.rewardful('convert', {email: membershipInput.userMembershipsId});
      } catch (e) {
        console.log(e);
        Sentry.captureException({error: JSON.stringify(e)});
      }
    }
    if (sessionId && paymentIntent && !window.localStorage.getItem(`${paymentIntent?.body?.paymentIntent?.id}-tracked`)) {
      trackMembership();
    }
  }, [paymentIntent])

  if (fetchError || paymentIntent?.error) return <Redirect to={`/${bootcamp}`}/>

  return (
    <Container>
      {
        feedbackFormIdByBootcamp[bootcamp] && (
          <EmbedWrapper>
            <Typeform ref={typeformContainer}/>
          </EmbedWrapper>
        )
      }
      {
        fetchingPaymentIntent ?
          <LoadingSpinnerContainer>
            <LoadingSpinner color={'white'} size={24}/>
          </LoadingSpinnerContainer>
        : fetchError || paymentIntent?.error ?
          <ErrorText>
            Error fetching receipt. Please contact support for more info.
          </ErrorText>
        : <Receipt {...receipt}/>
      }
    </Container>
  );
};

ThankYou.defaultProps = {};
ThankYou.propTypes = {};

export default ThankYou;
