import React, {useState} from 'react';
import styled, {css} from 'styled-components';

import {Body4, H4, H3} from '../../../../components/Typography';
import {useUserDataContext} from '../../../../contexts/UserData';
import {useHistory} from 'react-router-dom';
import {OpenIndicator} from '@bootcamp/web/src/components/Branding';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
  user-select: none;
`;
const Title = styled(H3)`
  display: flex;
  padding: ${({theme}) => theme.layouts.spacing.m};
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  }
  svg {
    margin-left: auto;
    stroke: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  }
`;
const Options = styled.div`
  display: ${({open}) => open ? 'block' : 'none'};
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};

`;
const Option = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.m};
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  text-decoration: none;

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  }
`;
const OptionDetails = styled(Body4)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};

  ${({active}) => active && css`
    color: ${({theme}) => theme.colors.brandPalette.royal.default};
    font-weight: ${({theme}) => theme.font.weight.bold};
  `}
`;

const Folder = ({name, options, activeBootcamp, defaultOpen}) => {
  const [open, setOpen] = useState(defaultOpen);
  const {setBootcamp} = useUserDataContext();
  const {push} = useHistory();

  const handleLinkClick = (event, bootcamp) => {
    event.stopPropagation();
    event.preventDefault();
    setBootcamp(bootcamp);
    push(`/${bootcamp}`);
  };

  return (
    <Container onClick={() => setOpen(!open)}>
      <Title open={open}>
        <span>{name}</span>
        <OpenIndicator open={open} size={24}/>
      </Title>
      <Options open={open}>
        {options.map(({route, name, details}) => {
          const currentlyStudying = route === activeBootcamp;

          return (
            <Option key={name} to={route} onClick={event => handleLinkClick(event, route)}>
              <H4>{name}</H4>
              <OptionDetails active={currentlyStudying}>{currentlyStudying ? 'Currently Studying' : details}</OptionDetails>
            </Option>
          );
        })}
      </Options>
    </Container>
  );
};

export default Folder;
