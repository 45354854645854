import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';

import {insertVideoElements} from '../Question';
import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '../../../contexts/UserData';
import {useTestNavigatorContext} from '../../../contexts/TestNavigator';

import QuestionFAQs, {BootcampBot} from '../../Modal/components/QuestionFAQs';
import RelatedVideos from './RelatedVideos';
import SolutionMeta from './SolutionMeta';

import baseTheme from '@bootcamp/shared/src/styles/theme';

import useHotkeys from "@reecelucas/react-use-hotkeys";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {IntercomAPI} from 'react-intercom';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.toLowerCase().split('');

const Solution = styled.div`
  padding: 16px;
  border: 1px solid ${({theme}) => theme?.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.lightBorder};
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  border-radius: 8px;
  background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'initial'};
  span[style*='font-weight:600']{
    font-weight: 700 !important;
  }
  div {
    overflow-x: auto;
  }

  * {
    max-width: 100%;
    font-size: 16px;
    line-height: 1.6em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0;
  }
  p {
    margin: 0;
    overflow-wrap: anywhere;
    span {
      font-size: inherit !important;
      ${({theme}) => theme.darkModeEnabled && css`color: ${theme.colors.darkModePalette.neutralsPalette.extraDark} !important;`}
    }
  }
  li {
    span {
      font-size: inherit !important;
      ${({theme}) => theme.darkModeEnabled && css`color: ${theme.colors.darkModePalette.neutralsPalette.extraDark} !important;`}
    }
  }
  table p {
    overflow-wrap: normal;
  }
  ol, ul {
    margin: 1em 0;
    padding-left: 40px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  hr {
    margin: 40px 0 30px;
    border: 0;
    border-bottom: 1px solid #eee;
    height: 1px;
  }
  .sup, .sub, sup, sub {
    font-size: 12px;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  max-width: 100%;
  font-size: 16px;
  line-height: 1.6em;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  img {
    height: auto !important;
    max-width: 100% !important;
    cursor: zoom-in;
  }
  img.unlockedDimensions {
    width: auto !important;
    max-height: calc(100vh - 112px) !important;
  }
  blockquote.callout {
    padding: 15px;
    margin: 0 0 1em 0;
    font-family: 'proxima-nova', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #F9f9f9;
    color: #333;
    border-left: solid 5px #0F6292;
  }
  blockquote.callout p {
    margin-bottom: 0 !important;
  }
  table {

    ${({theme}) => theme.darkModeEnabled && css`
      background: white;
      color: ${baseTheme.colors.neutralsPalette.extraDark} !important;

      p {
        span {
          color: ${baseTheme.colors.neutralsPalette.extraDark} !important;
        }
      }
      li {
        span {
          color: ${baseTheme.colors.neutralsPalette.extraDark} !important;
        }
      }
    `}


    margin: 0 auto;
    li {
      line-height: inherit !important;
      * {
        line-height: inherit !important;
      }
    }
    * {
      overflow: visible;
    }
  }
  .toggle-trigger {
    color: grey;
    text-decoration: underline;
  }
  .fr-hyperlink {
    cursor: pointer;
    color: #53A3DF !important;
    text-decoration: underline;
    font-weight: normal;
    &:active {
      color: red;
    }
  }
  ${({bootcamp}) => bootcamp === 'med-school' && css`
    table {
      background: white;
    }
  `}
`;
const SolutionBox = styled.div`
  width: 100%;
  position: relative;
  margin: 24px 0px;
`;
const TopicText = styled.span`
  display: block;
  font-weight: 600;
  margin: 32px 0;
  font-family: proxima-nova;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
`;
const SubjectSystemWrapper = styled.div`
  ${TopicText} {
    &:first-child {
      margin-bottom: ${({theme}) => theme.layouts.spacing.m};
    }
    &:last-child {
      margin-top: 0;
    }
  }
`;
const IntercomButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  * {
    font-family: proxima-nova !important;
  }
`;
export const IntercomButton = styled.div`
  font-family: proxima-nova;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: .75em;
  border: 1px solid ${({theme}) => theme?.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.lightBorder};
  border-radius: 1em;
  margin-right: 2em;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  text-decoration: none;
  flex-grow: 1;
  text-align: left;
  font: 400 16px;
  cursor: pointer;
  margin-right: 2em;
  &:last-child {
    margin-right: 0;
  }
  opacity: .75;
  transition: opacity .4s;
  &:hover {
    opacity: 1;
    transition: opacity .4s;
  }
`;

function getQuestionReviewUrl(questionId, answerIndex, questionRevisionId) {
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;
  // const port = window.location.port ? `:${window.location.port}` : '';
  // const route = 'tbc-question-review'; // to load question review route for tbc locally use /question-review instead
  return `https://admin.bootcamp.com/tbc-question-review/?questionId=${questionId}&answerIndex=${answerIndex}${questionRevisionId ? `&questionRevisionId=${questionRevisionId}` : ''}`
}

const ImageRendererTag = styled.img`
  user-select: none;
  max-width: 96vw;
  max-height: 96vh;
  background: white;
`;

const ImageRenderer = props => {
  useHotkeys('Escape', props.close, [props.close])
  return (
    <div>
      <TransformWrapper doubleClick={{mode: 'reset'}}>
        <TransformComponent wrapperStyle={{overflow: 'visible'}}>
          <ImageRendererTag {...props} />
        </TransformComponent>
      </TransformWrapper>
    </div>
  )
}
const HyperlinkModalContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 90vh;
`;
const HyperlinkModalContent = styled.div`
  background: white;
  line-height: 1.6em;

  img {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
    max-height: calc(90vh - 96px);
    object-fit: contain;
  }

  table {
    margin: 0 auto;
    li {
      line-height: inherit !important;
      * {
        line-height: inherit !important;
      }
    }
    * {
      overflow: visible;
    }
  }

  ${({tableWidth}) => tableWidth && css`width: 100%; max-width: 950px !important;`};
  ${({videoWidth}) => videoWidth && css`width: 60vw;`};
`;


const SolutionContainer = ({
  className,
  backgroundColor,
  solution,
  hideTimeSpent,
  correctAnswerIndex,
  citations,
  correctPercentage,
  didSelectCorrectAnswer,
  didNotAnswer,
  selectedAnswerIndex,
  showFeedback,
  questionId,
  questionRevisionId,
  tags,
  didMark,
  time,
  solutionRef,
  hyperlinksRef,
  questionSetExplanation,
  lastUpdated,
  relatedVids,
  shouldHideAnswerData,
  hyperlinks,
  blockId
}) => {
  const {test, subject, topic} = tags.find(tag => !!tag.topic) || {};
  const {modalDispatch} = useModalContext();
  const {bootcamp, searchUserInteractions, saveUserInteraction, DEFAULT_USER_ID} = useUserDataContext();
  const {variables: {template}} = useTestNavigatorContext();
  const citationsBox = citations
    ? `
      <br />
      <div class="closed toggle-trigger-container">
        <h4 class="toggle-trigger" style="cursor: pointer;">View Source(s)</h4>
        <div class="toggle-content" style="display: none;">
        ${citations?.replace(/<a /g, '<a target="_blank" ')}
        </div>
      </div>
    `
    : '';

  const showSubjectSystem = ['Med School USMLE', 'Med School Recall', 'INBDE'].includes(test);

  const questionReviewUrl = getQuestionReviewUrl(questionId, selectedAnswerIndex, questionRevisionId);
  const intercomQuestionString = `I have content questions or feedback on ${subject} ${questionReviewUrl}. Specifically:`;
  const loadingAnswerData = correctPercentage === undefined;
  const correctPercentageText = loadingAnswerData
    ? ''
    : correctPercentage > 0
    ? `${correctPercentage}%`
    : 'Collecting stats';

  useEffect(() => {
    const handler = event => {
      if (event.target.classList.contains('toggle-trigger')) {
        event.target.parentNode.querySelector('.toggle-content').style.display = 'block';
      }
    }
    solutionRef.current?.addEventListener('click', handler);
    return () => solutionRef.current?.removeEventListener('click', handler);
  }, []);
  useEffect(() => {
    const handler = event => {
      const hyperlinkNode = event?.target?.parentNode?.classList?.contains('fr-hyperlink')
      ? event.target.parentNode
      : event?.target?.classList?.contains('fr-hyperlink')
      ? event.target
      : null;

      if (hyperlinkNode) {
        const associatedHyperlink = hyperlinks?.[alphabet.indexOf(hyperlinkNode.dataset.hrefIndex?.toLowerCase())];
        const isTable = associatedHyperlink?.match('<table');
        const isVideo = associatedHyperlink?.match('data-vimeo-id');

        const HyperlinkRenderer = props => {
          useEffect(() => {

            if (hyperlinksRef?.current) {
              // insert video player for solution
              insertVideoElements(hyperlinksRef);
            }

          }, []);

          useHotkeys('Escape', props.close, [props.close]);

          return (
            <HyperlinkModalContainer onClick={e => e.stopPropagation()}>
              <TransformWrapper doubleClick={{mode: 'reset'}} disabled={isVideo} wheel={{wheelDisabled: true}}>
                <TransformComponent
                  wrapperStyle={{overflow: 'visible'}}
                  contentStyle={{background: 'url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-small.gif") no-repeat center', width: '100%', minHeight: '100%', justifyContent: 'center', alignItems: 'center'}}
                  >
                  <HyperlinkModalContent ref={hyperlinksRef} onClick={props.close} tableWidth={isTable} videoWidth={isVideo} className="fr-view" dangerouslySetInnerHTML={{__html: associatedHyperlink}} />
                </TransformComponent>
              </TransformWrapper>
            </HyperlinkModalContainer>
          )
        }
        modalDispatch({
          type: 'open',
          component: HyperlinkRenderer,
          enableClickClose: true,
          modalContainerStyle: theme => `
            overflow: auto;
          `
        })
      }
    }
    solutionRef.current?.addEventListener('click', handler);
    return () => solutionRef.current?.removeEventListener('click', handler);
  }, [hyperlinks]);
  const hideAnswerData = searchUserInteractions('hideAnswerData') === true || shouldHideAnswerData || bootcamp === 'chemistry';

  return (
    <SolutionBox className={className}>
      {DEFAULT_USER_ID !== 'matthewdempsey' && <SolutionMeta
        didSelectCorrectAnswer={didSelectCorrectAnswer}
        didNotAnswer={didNotAnswer}
        backgroundColor={backgroundColor}
        didMark={didMark}
        correctAnswerIndex={correctAnswerIndex}
        hideAnswerData={hideAnswerData}
        loadingAnswerData={loadingAnswerData}
        correctPercentageText={correctPercentageText}
        hideTimeSpent={hideTimeSpent}
        time={time}
        lastUpdated={lastUpdated}
      />}
      <Solution bootcamp={bootcamp} onClick={e => {
        if (e.target?.tagName === 'IMG') {
          modalDispatch({type: 'open', component: ImageRenderer, componentProps: {src: e.target.src}, enableClickClose: true, modalContainerStyle: theme => `
            z-index: 20;
          `})
        }
      }} ref={solutionRef} backgroundColor={backgroundColor} className={'fr-view'} dangerouslySetInnerHTML={{__html: solution + (questionSetExplanation || '') + citationsBox}} />
      {topic && !showSubjectSystem && <TopicText>Topic: {topic}</TopicText>}
      {subject && topic && showSubjectSystem &&
        <SubjectSystemWrapper>
          <TopicText>{test === 'INBDE' ? 'Subject' : 'System'}: {subject}</TopicText>
          <TopicText>{test === 'INBDE' ? 'Topic' : 'Subject'}: {topic}</TopicText>
        </SubjectSystemWrapper>
      }
      {relatedVids && <RelatedVideos videos={relatedVids}/>}
      {showFeedback && ['dat', 'inbde', 'med-school', 'oat', 'mcat'].includes(bootcamp) && !['Perceptual Ability'].includes(subject) && (
        <BootcampBot questionId={questionId} intercomQuestionString={intercomQuestionString} blockId={blockId} />
      )}
      <IntercomButtons>
        <IntercomButton onClick={() => IntercomAPI('showNewMessage', intercomQuestionString)}>Still have a question? Contact our team →</IntercomButton>
      </IntercomButtons>
    </SolutionBox>
  );
}

export default SolutionContainer;
export {Solution};