import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {PageSection} from '../ClassroomContentWrapper';
import {DownloadButton} from '../Cards/components/Buttons';
import Download from '../Cards/Download';
import Callout from '../Cards/Callout';

import {CircleStack} from '@styled-icons/heroicons-outline/CircleStack';

const Container = styled.div`
`;

const StyledCollection = styled(CircleStack)`
  color: ${({theme, themePalette}) => themePalette?.default || theme.colors.neutralsPalette.lightGrey};
  width: 42px;
  height: 42px;
  padding: 6px;
  border-radius: 64px;
  background: ${({themePalette}) => themePalette?.light || '#f6f9fc'} ;
`;



const Flashcards = ({content, themePalette, calloutText}) => {
  const flashcards = content.find(({name}) => name === 'Flashcards').content;
  return (
    <Container>
      {calloutText && <Callout children={calloutText}/>}
      <PageSection>
        {flashcards.map((item, index) => (
          <Download
            key={`${item.name}-${index}`}
            name={item.name}
            icon={<StyledCollection themePalette={themePalette} size={20}/>}
            themePalette={themePalette}
            description={item.description}
            onClick={() => window.open(item.route, '_blank')}
            actions={[
              <DownloadButton themePalette={themePalette} children={'Study Flashcards'} />
            ]}
          />
        ))}
      </PageSection>
    </Container>
  );
};

Flashcards.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
Flashcards.defaultProps = {
  content: [],
  data: {},
};

export default Flashcards;
