import React, {useCallback, useState, useEffect, useRef} from 'react';
import {useCreateTestState} from '../context';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import styled, {css} from 'styled-components';

import {usePrevious} from '@bootcamp/web/src/hooks';
import {Container, Label, Count, Header, TextTooltip, CustomInputWrapper, InputCheck, InputMinus, InputHoverWrapper} from './shared';
import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';
import {Plus} from '@styled-icons/heroicons-outline/Plus';
import {Minus} from '@styled-icons/heroicons-outline/Minus';
import {ReactComponent as Badge} from '../assets/badge.svg';

const Wrapper = styled.div`
  display: flex;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const Column = styled.div`
  flex: 1;

  &:first-child {
    margin-right: ${({theme}) => theme.layouts.spacing.xl};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    &:first-child {
      margin: 0;
    }
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  ${Header} {
    margin-bottom: 0;
    margin-left: 6px;
  }
`;
const RowWrapper = styled.div`
  cursor: row-resize;
`;
const RowText= styled.div`
  padding: 6px 8px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`;
const Expand = styled(ChevronRight)`
  width: 18px;
  height: 18px;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};

  ${({toggled}) => toggled && css`
    transform: rotate(90deg);
  `}
`;
const ToggleControl = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: ${({theme, disabled}) => disabled && theme.colors.neutralsPalette.extraLight};
    border-radius: 4px;
  }
`;
const TestInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({toggled}) => toggled ? 'auto' : '0'};
  overflow: hidden;
  padding-left: 20px;
  font-size: 14px;
`;
const InputWrapper = styled.label`
  padding-left: ${({depth}) => depth === 1 ? '6px' : '4px'};
  display: flex;
  align-items: center;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    background: ${({theme, disabled}) => !disabled && theme.colors.neutralsPalette.extraLight};
    border-radius: 4px;
  }
`;
const Input = styled.input`
  margin: 0;
  cursor: pointer;

  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;
const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`;
const ExpandCollapse = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 0;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    display: none;
  }
`;
const LockedBadge = styled(Badge)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const InputComponent = ({checked, name, free, value, depth=0, count, loading, containerRef}) => {
  const ref = useRef();

  const {isUpgraded} = useUserDataContext();

  const locked = !isUpgraded && !free;
  const disabled = loading || !count || locked;

  return (
    <InputWrapper ref={containerRef} htmlFor={value} depth={depth} disabled={disabled}>
      <InputHoverWrapper active={checked} disabled={disabled}>
        <CustomInputWrapper htmlFor={value} checked={checked} disabled={disabled} locked={locked}>
          {locked && <LockedBadge/>}
          <Input
            ref={ref}
            id={value}
            type={'checkbox'}
            checked={checked}
            disabled={disabled}
            value={value}
          />
          {!!checked && <InputCheck data-input-element />}
        </CustomInputWrapper>
        <RowText disabled={disabled}>{name}</RowText>
        <Count loading={loading}>{loading ? '' : count}</Count>
      </InputHoverWrapper>
    </InputWrapper>
  );
};

const MemoizedInput = React.memo(InputComponent);

const Row = ({id, parentId, name, children, checked, testCounts, free, subjectCounts, topicCounts, type, onChange, expandAll, loading, defaultToggled=false}) => {
  const [toggled, setToggled] = useState(defaultToggled);
  const previousExpandAll = usePrevious(expandAll);
  const inputContainerRef = useRef();

  function handleToggleClick(event) {
    const shouldHandleToggleClick = !inputContainerRef?.current?.contains(event?.target);

    if (shouldHandleToggleClick) {
      event.preventDefault()
      setToggled(!toggled)
    }
  }

  // use effect to sync toggled state with expandAll state
  useEffect(() => {
    if (previousExpandAll === undefined || previousExpandAll === expandAll) return;

    setToggled(expandAll);
  }, [expandAll]);

  const checkedSubjectTotals = Object
    .keys(children)
    .reduce((acc, testId) => children[testId].checked ? acc + testCounts[testId] : acc, 0);

  return (
    <RowWrapper onClick={onChange}>
      <ToggleControl onClick={handleToggleClick} disabled={loading || subjectCounts[id] || checkedSubjectTotals}>
        <MemoizedInput
          containerRef={inputContainerRef}
          checked={checked}
          value={JSON.stringify({type, id, parentId, children})}
          count={subjectCounts[id] || checkedSubjectTotals}
          name={name}
          loading={loading}
          free={free}
          />
        <Expand toggled={toggled}/>
      </ToggleControl>
      <TestInputWrapper toggled={toggled} >
        {Object.keys(children).map((key) => {
          const {name: testName, checked, children: nestedChildren} = children[key];

          return nestedChildren
            ? <Row
                key={key}
                id={key}
                parentId={id}
                expandAll={expandAll}
                onChange={onChange}
                testCounts={testCounts}
                subjectCounts={topicCounts}
                loading={loading}
                name={testName}
                checked={checked}
                children={nestedChildren}
                type={'topic'}
              />
            : <MemoizedInput
                key={key}
                checked={testCounts[key] && checked}
                value={JSON.stringify({type: 'test', testId: key, parentId: id, grandparentId: parentId})}
                name={nestedChildren ? key : testName}
                count={testCounts[key]}
                depth={1}
                loading={loading}
                free={free}
              />
        })}
      </TestInputWrapper>
    </RowWrapper>
  );
}

const Questions = () => {
  const [expandAll, setExpandAll] = useState(false);

  const {isUpgraded, bootcamp} = useUserDataContext();

  const {
    methods: {
      setData,
      setSelectAll,
      recursiveToggleState,
    },
    variables: {
      loading,
      selectAll,
      masteryFilter,
      testCounts,
      subjectCounts,
      topicCounts,
      data,
    },
  } = useCreateTestState();

  const previousMasteryFilter = usePrevious(masteryFilter);

  const handleSubjectChange = useCallback(({id}) => {
    const subjectChecked = data[id]?.checked;

    const validate = ({id, checked}) =>  !!testCounts[id] ? !subjectChecked : checked;
    const update = recursiveToggleState(data[id], validate);

    setData({...data, [id]: update});

  }, [data, testCounts]);

  const handleTopicChange = useCallback(({id, parentId}) => {
    const topicChecked = data[parentId].children[id].checked;

    const validate = ({id: testId, checked, parentId: testParentId}) => (!!testCounts[testId] && testParentId === id) ? !topicChecked : checked;

    const update = recursiveToggleState(data[parentId], validate);

    setData({...data, [parentId]: update});

  }, [data, testCounts]);

  const handleTestChange = useCallback(({testId, parentId, grandparentId}) => {

    const updateKey = grandparentId || parentId;

    const validate = ({id, checked}) =>  (!!testCounts[id] && testId === id) ? !checked : checked;

    const update = recursiveToggleState(data[updateKey], validate);

    setData({...data, [updateKey]: update});

  }, [data, testCounts]);

  const handleOnChange = useCallback((event) => {
    event.stopPropagation();

    const {type, ...args} = JSON.parse(event?.target?.value || '{}');

    if (!type) return;

    const runUpdate = type === 'subject'
      ? handleSubjectChange
      : type === 'topic'
      ? handleTopicChange
      : handleTestChange

    runUpdate(args);

  }, [data, testCounts]);

  const handleSelectAllChange = useCallback(event => {

    event.preventDefault();
    event.stopPropagation();

    const validate = ({id, free}) =>  ((!!topicCounts[id] || !!testCounts[id]) && (isUpgraded || free)) ? !selectAll : false;

    const update = Object.keys(data).reduce((acc, key) => ({
      ...acc,
      [key]: recursiveToggleState(data[key], validate)
    }), {})

    setData(update);
    setSelectAll(!selectAll);

  }, [data, selectAll, testCounts]);

  const handleExpandAllChange =  useCallback(event => {
    setExpandAll(!expandAll);
  }, [expandAll]);

  // use effect for syncing data state with mastery filter
  useEffect(() => {
    if (previousMasteryFilter === undefined || (previousMasteryFilter === masteryFilter) || loading) return;

    const validate = ({id, checked, free}) =>  (
      ((!!topicCounts[id] || !!testCounts[id]) && (isUpgraded || free)) ? selectAll || checked : false
    );

    const update = Object.keys(data).reduce((acc, key) => ({
      ...acc,
      [key]: recursiveToggleState(data[key], validate)
    }), {});

    setData(update);

  }, [data, masteryFilter, previousMasteryFilter, testCounts, loading, selectAll]);

  // use effect for syncing 'select all' state
  useEffect(() => {
    if (loading) return;

    if (selectAll) {
      const noneChecked = Object
        .values(data)
        .every(({ checked }) => !checked);

        noneChecked && setSelectAll(false);
    } else {
      const allChecked = Object
        .values(data)
        .every(({ checked }) => checked);

      allChecked && setSelectAll(true);
    }
  }, [data, selectAll]);

  // splitting subjects out into two columns
  const dataKeys = Object.keys(data);
  const column1 = dataKeys.slice(0, Math.ceil(dataKeys.length / 2));
  const column2 = dataKeys.slice(Math.ceil(dataKeys.length / 2));

  return (
    <Container>
      <HeaderWrapper>
        <SelectAllWrapper onClick={(loading || isUpgraded) ? handleSelectAllChange : () => {}} disabled={loading || !isUpgraded}>
          <CustomInputWrapper checked={selectAll} disabled={loading || !isUpgraded}>
            <Input type={'checkbox'} disabled={loading || !isUpgraded} checked={selectAll}/>
            {selectAll && isUpgraded &&  <InputCheck data-input-element/>}
          </CustomInputWrapper>
          <Header>Subjects</Header>
        </SelectAllWrapper>
        <ExpandCollapse onClick={loading ? ()=> {} : handleExpandAllChange}>
          {expandAll ? <Minus/> : <Plus/>}
          {expandAll ? 'Collapse All' : 'Expand All'}
        </ExpandCollapse>
      </HeaderWrapper>
      <Wrapper>
        <Column>
          {column1
            .map(key => (
              <Row
                key={key}
                id={key}
                expandAll={expandAll}
                onChange={handleOnChange}
                testCounts={testCounts}
                subjectCounts={subjectCounts}
                topicCounts={topicCounts}
                loading={loading}
                type={'subject'}
                {...(data[key])}
              />
            ))
          }
        </Column>
        <Column>
          {column2
            .map(key => (
              <Row
                key={key}
                id={key}
                expandAll={expandAll}
                onChange={handleOnChange}
                testCounts={testCounts}
                subjectCounts={subjectCounts}
                topicCounts={topicCounts}
                loading={loading}
                type={'subject'}
                {...(data[key])}
              />
          ))}
        </Column>
      </Wrapper>
    </Container>
  );
};

export default Questions;
