import Storage from '@aws-amplify/storage';
import {insertAtIndex} from '@bootcamp/shared/src/util';
import {updateQuestionProgress as updateQbQuestionProgress} from '@bootcamp/shared/src/requests';

export const handleGoBack = (history) => {
  const {action, push, replace, goBack, location} = history;

  const searchParams = new URLSearchParams(location?.search);
  const testStorageKey = searchParams.get('key');

  const canGoBack = action !== 'POP';
  const urlParts = window.location.pathname.split('/');
  const previousPath = urlParts
  .slice(0,2)
  .join('/');

  if (urlParts[2] === 'self-assessment') {
    replace(`${previousPath}/self-assessment/review?id=b21f76dd-8a20-4a71-9bf6-839e7b4d7faa&testRoute=med-school/self-assessment/assessment`)
  } else if (urlParts[2] === 'custom-test') {
    push(`${previousPath}/previous-tests/review?key=${encodeURIComponent(testStorageKey)}`);
  } else if (canGoBack) {
    goBack();
  } else {
    push(previousPath);
  }
};

export function resolveIconPath(iconPath) {
  try {
    return require(`@bootcamp/shared/src/assets/${iconPath}`).default;
  } catch (error) {
    return null;
  }
}

export function updateQuestionProgressState(updater, questionProgresses, questionIndex, params) {
  const updatedQuestionProgress = {...questionProgresses[questionIndex], ...params};

  updater({
    questions: insertAtIndex(
      questionProgresses,
      questionIndex,
      updatedQuestionProgress
    )
  });

  return updatedQuestionProgress;
};

export async function updateQuestionProgressData(updater, startTime, questionProgresses, questionIndex) {
  const timeElapsed = new Date(new Date() - startTime);
  const time = (parseFloat(questionProgresses[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

  const {
    blockArrayIndex,
    questionProgressId,
    questionBaseId,
    questionRevisionId,
    current,
    crossedAnswerIndexes,
    indexes,
    bookmarked,
    originalTestId,
    questionIndex: currentQuestionIndex,
    subject,
    topic,
    subjectTopic,
    question,
    isSequentialStart,
    isSequentialEnd,
    isSequentialSet,
    answerState,
    seen,
    fullCredit,
    partialCredit,
    noCredit,
    // highlights, // not saving these for now b/c there's an issue with restoration from the new object format they're being saved in
    ...questionProgress
  } = updateQuestionProgressState(updater, questionProgresses, questionIndex, {time});

  const updatedProgress = await updateQbQuestionProgress(questionProgressId, questionProgress);

  return updatedProgress;
}

export function normalizeHighlights(highlights) {
  try {
    const parsedHighlights = JSON.parse(highlights);
    return parsedHighlights;
  } catch (error) {
    return highlights;
  }
}