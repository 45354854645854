import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import API, {graphqlOperation} from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import {IntercomAPI} from 'react-intercom';
import TagManager from 'react-gtm-module';

import { useUserDataContext } from '@bootcamp/web/src/contexts/UserData';
import {getActivationCode, createMembership, updateActivationCode, updateUser} from '@bootcamp/shared/src/requests';

import {Input, InputDecorator} from '@bootcamp/web/src/components/Form/FieldRenderer/components/Field';
import {RoundButton} from '@bootcamp/web/src/components/UI';
import nanoid from 'nanoid';
import {Container, TitleBar, Content} from '../Cards/shared';
import {H3} from '@bootcamp/web/src/components/Typography';
import {useAsync} from '@bootcamp/shared/src/util/hooks';
import Callout from '@bootcamp/web/src/bootcamps/components/Cards/Callout';
import {Info} from '@styled-icons/fluentui-system-filled/Info';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {SubmitAnatomyCase} from '@bootcamp/web/src/components/Modal';
import moment from 'moment';
const StyledContainer = styled(Container)`
  max-width: 100%;
`;
const StyledInputDecorator = styled(InputDecorator)`
  position: relative;
  margin-bottom: 24px;
`;
const StyledButton = styled(RoundButton)`
`;


function findStartDate(membershipGroups, user) {
  try {

    const extendableMemberships = user?.memberships?.items
      // active memberships are extendable
      ?.filter(membership => membership.status === 'active')
      // memberships that share a group with the membership being purchased are extendable
      ?.filter(membership => membership.groups
        .filter(group => group !== 'PlusPackAccess')
        .find(group => membershipGroups.find(membershipGroup => membershipGroup === group))
      );

    if (!extendableMemberships || extendableMemberships.length === 0) return new Date();

    // we need to find when this membership should start
    // based on the ultimate end date of our current memberships
    let latestEnd;

    for (const membership of extendableMemberships) {
      if (!latestEnd) latestEnd = membership;

      // end date of the current membership iteration
      const membershipDate = new Date(membership.startDate);
      const membershipEndDate = membershipDate.setDate(membershipDate.getDate() + membership.duration);

      // end date of the stored "latestEnd" membership
      const endDate = new Date(latestEnd.startDate);
      const latestEndDate = endDate.setDate(endDate.getDate() + latestEnd.duration);

      // if the current membership iteration ends later than the latestEnd - update latest end
      if (membershipEndDate > latestEndDate) {
        latestEnd = membership;
      }
    }

    const endDate = new Date(latestEnd.startDate);
    const latestEndDate = endDate.setDate(endDate.getDate() + latestEnd.duration);

    // latestEndDate should be a unix string
    return new Date(latestEndDate);
  } catch (e) {
    console.error('findStartDate err', e);
    return new Date();
  }
}

const createCohortUserLink = async (cohortId, userId) => {
  const getCohortUserLinks = /* GraphQL */ `
      query queryCohortUserLinksByGsiUserCohorts($userId: String!) {
        queryCohortUserLinksByGsiUserCohorts(userId: $userId) {
          items {
            cohortId
          }
        }
      }
    `;
  const cohortUserLink = await API.graphql(graphqlOperation(getCohortUserLinks, {userId}));
  const cohortUserLinks = cohortUserLink.data.queryCohortUserLinksByGsiUserCohorts.items;
  if (cohortUserLinks.find(link => link.cohortId === cohortId)) return;

  const createCohortUserLink = /* GraphQL */ `
      mutation CreateCohortUserLink($input: CreateCohortUserLinkInput!) {
        createCohortUserLink(input: $input) {
          id
          user {
            id
          }
        }
      }
    `;

  return await API.graphql(graphqlOperation(createCohortUserLink, {input: {cohortId, userId}}));
}

async function addUserToCohort(discountCode, membershipInput) {
  if (!discountCode) return;

  try {
    // attempt to update the activation code - if it's not able to update, return
    const activationCode = await updateActivationCode({
      id: discountCode,
      priceId: membershipInput.productId,
      userId: membershipInput.userMembershipsId,
      redeemedAt: new Date().toISOString(),
      membershipId: membershipInput.id,
    });

    if (!activationCode) return;

    const {cohortId, userId} = activationCode;

    // create cohort user link
    await createCohortUserLink(cohortId, userId);

  } catch (error) {
    console.log('error adding user to cohort', error);
  }
}
const getCodes = `
  query getUser($id: ID!) {
    getUser(id: $id) {
      codes {
        items {
          id
          duration
          redeemedAt
          description
        }
      }
    }
  }
`
const useMembershipCodes = (userId) => {
  const fetchActivationCodes = useCallback(async () => {
    try {
      if (!userId) return [];
      const result = await API.graphql(graphqlOperation(getCodes, {id: userId}));
      return result.data.getUser.codes.items;
    } catch (error) {
      console.log('error fetching activation codes', error);
      return [];
    }
  }, [userId]);
  return useAsync(fetchActivationCodes);
}

const CodeRedemption = () => {
  const {refreshSession, userModel, DEFAULT_USER_ID, bootcamp, cognitoUser} = useUserDataContext();
  const [value, setValue] = useState();
  const username = cognitoUser?.attributes?.name;
  const [name, setName] = useState(username);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [membership, setMembership] = useState();
  const {modalDispatch} = useModalContext();

  function handleInputChange(e) {
    setMembership();
    setValue(e.target.value);
    setError(false);
  }
  const {value: userCodes, pending, execute} = useMembershipCodes(DEFAULT_USER_ID);

  const redeemCode = async (membershipInput, discountCode, email, productName) => {

    // create membership
    const membership = await createMembership(membershipInput);

    // add user to cohort
    await addUserToCohort(discountCode, membershipInput);

    await refreshSession();

    try {
        // update intercom user attributes
        // create intercom event
        await IntercomAPI('trackEvent', `Account Upgraded: Joined group(s) ${membershipInput.groups.join(', ')}`, {
          ...membershipInput,
          groups: JSON.stringify(membershipInput.groups),
          discountCode,
          discountedPrice: 0,
          originalPrice: 0,
        });
        try {
          if (discountCode) {
            await IntercomAPI('update', {discountCode});
          }
        } catch (error) {
          console.log(error)
        }
        try {
          TagManager.dataLayer({
            dataLayer: {
              'transactionId': membershipInput.id,
              'transactionTotal': 0,
              'transactionCoupon': discountCode,
              'transactionProducts': [{
                'sku': membershipInput.priceId,
                'name': productName || membershipInput.priceId,
                'price': 0,
                'quantity': 1
              }],
              'email': email,
              'event': 'Transaction Complete',
            }
          })
        } catch (e) {
          console.log(e);
        }
    } catch (error) {
      console.log('error registering intercom', error);
    }

    return membership;
  }

  async function handleRedemption() {
    try {
      setLoading(true);
      const code = await getActivationCode(value);

      const isValid = !!(code?.id);
      const isRedeemed = !!(code?.membershipId);

      if (!isValid) {
        throw new Error('Invalid Code.')
      } else if (isRedeemed) {
        throw new Error('This code has already been redeemed.');
      } else if (!name) {
        throw new Error('Please enter your name as it appears on your institution’s records.');
      }

      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {name});
      await Auth.currentAuthenticatedUser({bypassCache: true});
      await updateUser({id: userModel.id, name});
      
      const startDate = findStartDate(code.groups, userModel);
      const membershipId = nanoid();

      const membershipInput = {
        id: `redeemed_${membershipId}`,
        startDate: startDate.toISOString(),
        productId: `${code?.cohort?.id}_institutional_membership`,
        duration: code.duration,
        status: 'active',
        userMembershipsId: userModel.id,
        type: 'oneTimePayment',
        groups: code.groups,
      };

      const membership = await redeemCode(membershipInput, code.id, userModel.email);
      setMembership(membership);
      setValue('');
      execute();
      const expirationDate = new Date(startDate).setDate(startDate.getDate() + code.duration)
      // format expiration date like MM/DD/YYYY
      const formattedExpirationDate = new Date(expirationDate).toLocaleDateString('en-US');
      modalDispatch({
        type: 'open',
        component: SubmitAnatomyCase,
        componentProps: {
          headerText: 'Code redeemed successfully!',
          bodyText: `Your upgraded membership expires on ${formattedExpirationDate}.`,
          confirmConfig: {
            shouldRedirect: true,
            redirect: location => `/${bootcamp}`,
            text: 'Return to Home'
          },
          cancelConfig: {
            text: null
          }
        },
        enableClickClose: true,
        modalContainerStyle: theme => `
        background: ${theme.overlays.opacity.dark._300};
        padding: 10vh 10vw;
        align-items: flex-start;
        ${theme.mediaQueries.tablet} {
          background: ${theme.overlays.opacity.dark._400};
          padding: 0;
        }
      `
      });
      return membership;

    } catch (error) {
      setError(error);
      console.log('error redeeming code:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Callout icon={<Info size={20} color={'white'} style={{marginRight: 8}} />}>
        {"If your institution purchased memberships for your class, enter the code they gave you below to activate your upgraded membership. <strong>This section is not for redeeming promo codes. Promo codes can be used during checkout.</strong> If you need help, click the blue button at the bottom-right of your screen to send us a message. We’ll respond ASAP."}
      </Callout>
      <StyledContainer>
        <TitleBar><H3>Enter Your Institutional Code</H3></TitleBar>
        <Content>
          <StyledInputDecorator complete={username === name && !!name} label="Name">
            <Input
              placeholder={'Enter your name as it appears on your institution’s records'}
              onChange={e => setName(e.target.value)}
              value={name}
              defaultValue={username}
            />
          </StyledInputDecorator>
          <StyledInputDecorator error={error} complete={membership} label="Institutional Code">
            <Input
              placeholder={'Enter code provided by your institution'}
              onChange={handleInputChange}
              value={value}
              error={error}
            />
          </StyledInputDecorator>
          <StyledButton small onClick={handleRedemption} loading={loading}>
            Redeem Code
          </StyledButton>
        </Content>
      </StyledContainer>
      <br/>
      <StyledContainer>
        <TitleBar><H3>Redemption History</H3></TitleBar>
        <Content>
          {pending
            ? 'Loading...'
            : !userCodes.length 
            ? <p style={{textAlign: 'center', fontSize: '18px', lineHeight: '150%'}}>
                If you redeem an institutional code, it will appear here. Don’t have an institutional code? Meet with your advisor or program director and ask them to email us at <a target="_blank" style={{color: '#6255E5', textDecoration: 'none'}} href="mailto:team@bootcamp.com">team@bootcamp.com</a>. We'd love to offer our resource to your institution!
            </p>
            : <StyledTable>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Duration</th>
                  <th>Redemption Date</th>
                </tr>
              </thead>
              <tbody>
                {userCodes.map((code, i) => (
                  <tr key={i}>
                    <td>{code.id}</td>
                    <td>{code.description}</td>
                    <td>{code.duration} days</td>
                    <td>{moment(code.redeemedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          }
        </Content>
      </StyledContainer>
    </div>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    padding: 8px;
    border-bottom: 1px solid #E5E5E5;
  }
  th {
    text-align: left;
    font-size: 14px;
    color: #A0A0A0;
  }
  td {
    font-size: 16px;
    color: #333333;
  }
`;

CodeRedemption.defaultProps = {};
CodeRedemption.propTypes = {};

export default CodeRedemption;