import React, {useState} from 'react';
import styled from 'styled-components';

import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

import {ReactComponent as Strikethrough} from '../assets/pencil.svg';

import flag from '../assets/flag-blue.png';
import maximize from '../assets/maximize.png';
import minimize from '../assets/minimize.png';

import flagFilled from '../assets/flag-blue-filled.png';
import {ReactComponent as Clock} from '../assets/clock.svg';
import {QuestionIcon} from '../../NCLEXQBank/shared';

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Verdana !important;
  background: white;
`;

const Top = styled.div`
  display: flex;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.dark};
  align-items: center;
  padding: 12px 20px;
  color: white;
`;
const Middle = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.light};
  height: 30px;
  color: white;
`;
const Title = styled.div`
  display: flex;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: 4px;
  align-items: center;
  justify-content: center;
`;
const Left = styled.div`
  font-size: 21px;
`;
const Right = styled.div`
  margin-left: auto;
  font-size: 15px;
`;
const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
`;
const ClockIcon = styled(Clock)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const CountIcon = styled(QuestionIcon)`
  margin-right: 4px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const FlagWrapper = styled(IconWrapper)`
  margin-left: auto;
`;
const Highlight = styled(IconWrapper)`
  margin-right: 16px;
`;

const CollapsibleTop = styled.div`
  display: flex;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.light};
  padding: 4px;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: Verdana;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 19px */
`;
const CollapsibleSection = props => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Container>
      <CollapsibleTop onClick={() => setIsOpen(!isOpen)}>
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          <img src={isOpen ? minimize : maximize} style={{width: 16, height: 16, marginRight: 8}}/>
          {props.title}
        </div>
      </CollapsibleTop>
      {isOpen && props.body}
    </Container>
  )
}

const QuestionContainer = styled.div`
  width: calc(100%/3);
  display: flex;
  flex-direction: row;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: 12px 16px;
  align-items: center;
  border: 1px solid black;
  cursor: pointer;
`;

const SectionReview = ({highlightTooltipRef, strikethroughTooltipRef}) => {
  const {
    methods: {
      markQuestion,
      setCurrentIndex
    },
    variables: {
      blockIndex,
      quizProgress,
      customTestConfig,
      type,
    }
  } = useTestNavigatorContext();

  const {DEFAULT_USER_ID} = useUserDataContext();
  const {timeElapsed, longTimeLeft} = useTimerContext();
  const timed = true;

  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const didMark = questionProgresses[questionIndex]?.didMark || questionProgresses[questionIndex]?.bookmarked;
  const unseen = quizProgress.reduce((acc, {questions}) => [...acc, ...(questions || [])], [])?.filter(({seen, selectedAnswerIndex}) => !seen || selectedAnswerIndex === -1)?.length
  return (
    <Container>
      <Middle style={{borderTop: '2px solid white'}}/>
      <Title>
        Section Review
      </Title>
      <div>
        <CollapsibleSection
          title={<div>Instructions</div>}
          body={
            <div style={{padding: 10, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
              <div style={{color: '#151A1E', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>The table below is a summary of your answers in this section. You can review your answers in four different ways using the features at the bottom of the Section Review:</div>
              <div style={{color: '#151A1E', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>Select Review All or the Alt+A keyboard shortcut to review all the questions and answers from the beginning, starting with Question 1.<br />Select Review Incomplete or the Alt+I keyboard shortcut to review only the questions that are Incomplete or Unseen.<br />Select Review Flagged or the Alt+R keyboard shortcut to review only the questions that are flagged for review.<br />Select an individual question number to review a question of your choice. This will take you directly to the question. After you’ve reviewed the question, select Review Screen or the Alt+W keyboard shortcut at the bottom of the question to return to the Section Review.</div>
              <div style={{color: '#151A1E', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>If you do not wish to review any of your answers, or you’ve finished reviewing your answers, select End Section or the Alt+E keyboard shortcut.</div>
            </div>
          }
        />
        <CollapsibleSection
          title={
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <div>Section</div>
              <div>{unseen} Unseen / Incomplete</div>
            </div>
          }
          body={
            <div style={{flexFlow: 'wrap', display: 'flex', flexDirection: 'column', height: (20 * 45), alignContent: 'flex-start'}}>
              {quizProgress.reduce((acc, { questions }, blockIndex) => [...acc, ...(questions || []).map((question, index) => (
                <QuestionContainer onClick={() => setCurrentIndex(blockIndex, index)}>
                  <div style={{width: '50%', display: 'flex', alignItems: 'center'}}>
                    <img src={question.didMark ? flagFilled : flag} style={{width: 16, height: 16, marginRight: 8}}/>
                    Questions {acc.length + index + 1}
                    </div>
                  <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', color: 'red'}}>
                    {question.selectedAnswerIndex > -1 ? '' : question.seen ? 'Incomplete' : 'Unseen'}
                  </div>
                </QuestionContainer>
              ))], [])}
            </div>
          }
        />
      </div>
    </Container>
  );
};

SectionReview.defaultProps = {};
SectionReview.propTypes = {};

export default SectionReview;