import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import selectorImg from '../assets/hotSpotSelector.png';


const HotspotContainer = styled.div`
  width: 100%;
  overflow: auto;
`;
const Wrapper = styled.div`
  position: relative;
  width: 700px;
`;

const HotSpot = ({hotSpotImageSelection, hotSpotImageInput, hotSpotImage, showingExplanation, setAnswerState, answerState}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <HotspotContainer>
      <Wrapper>
        {loaded && <CanvasOverlay hotSpotImageSelection={hotSpotImageSelection} showingExplanation={showingExplanation} setAnswerState={setAnswerState} answerState={answerState} />}
        <img draggable="false" onLoad={() => setLoaded(true)} style={{ margin: 0, maxWidth: '700px', maxHeight: '700px', userSelect: 'none' }} src={hotSpotImageInput ? URL.createObjectURL(hotSpotImageInput) : `https://dekni9rgkrcu0.cloudfront.net/${hotSpotImage}`} />
      </Wrapper>
    </HotspotContainer>
  )
}

const Canvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: crosshair;
`;

const CanvasOverlay = ({hotSpotImageSelection, showingExplanation, setAnswerState, answerState}) => {
  const [clickTarget, setClickTarget] = useState(answerState || []);

  const ref = useRef();

  function handleClick(event) {
    if (showingExplanation) return;

    const rect = event?.target?.getBoundingClientRect();

    var x = event.clientX - rect.left;              // get mouse x and adjust for el.
    var y = event.clientY - rect.top;
    if (clickTarget[0] === x && clickTarget[1] === y) {
      setClickTarget([]);
      setAnswerState([]);
      return
    }
    setClickTarget([x, y]);
    setAnswerState([x, y]);
  }

  function drawSelection(reset) {
    const canvas = ref?.current;
    const ctx = canvas?.getContext('2d');
    var img = new Image();
    img.onload = () => {
      const canvasOffset = canvas?.getBoundingClientRect();
      
      if (canvasOffset.width == 0) {
        return;
      } else if (reset) {
        ctx.canvas.width = canvasOffset.width;
        ctx.canvas.height = canvasOffset.height;
      }
      
      ctx.drawImage(img, clickTarget[0] - 12, clickTarget[1] - 12, 24, 24);
    }
    img.src = selectorImg;
    
  }

  function drawCorrectArea() {
    const canvas = ref?.current;
    const ctx = canvas?.getContext('2d');

    const canvasOffset = canvas?.getBoundingClientRect();

    if (canvasOffset.width == 0) return;

    ctx.canvas.width = canvasOffset.width;
    ctx.canvas.height = canvasOffset.height;

    ctx.setLineDash([3]);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;
    ctx.fillStyle = "rgba(102, 172, 92, 0.5)";

    // restore saved selection if necessary
    if (hotSpotImageSelection) {
      ctx.fillRect(hotSpotImageSelection.x, hotSpotImageSelection.y, hotSpotImageSelection.width, hotSpotImageSelection.height);
      ctx.strokeRect(hotSpotImageSelection.x, hotSpotImageSelection.y, hotSpotImageSelection.width, hotSpotImageSelection.height);
      drawSelection(false);
    }
  }

  // selection area drawing
  useEffect(() => {
    if (!ref?.current || !showingExplanation) return;

    drawCorrectArea();

  }, [ref?.current, hotSpotImageSelection, showingExplanation]);

  // click target drawing
  useEffect(() => {
    if (!ref?.current || showingExplanation) return;

    drawSelection(true);

  }, [ref?.current, clickTarget])



  return (
    <Canvas ref={ref} onClick={handleClick}/>
  );
}

export default HotSpot