import React from 'react';
import styled from 'styled-components';

import {Hero, Label2, Label3} from '../../../../components/Typography/next';
import {Card, Heading, Title, SubTitle, DivergentBar, Table} from './shared';

import {formatTimeSpent} from '@bootcamp/shared/src/util';

import {useConfigState} from '../context';

const Container = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledHeading = styled(Heading)`
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;
const RightWrapper = styled.div`
  flex: 2;
  min-width: 450px;
  ${({theme}) => theme.mediaQueries.tablet} {
    overflow-x: auto;
    min-width: auto;
  }
  `;
const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0;
  }

`;
const Left = styled.div`
  min-width: 315px;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0px 16px;
    width: 100%;
  }
`;
const LeftContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 48px 20px;
  margin-bottom: 0;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
const BarWrapper = styled.div`
  width: 66%;
  margin-bottom: 16px;
`;

const Right = styled.div`
  height: auto;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin: 0px 16px;
    min-width: 600px;
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledCard = styled(Card)`
  margin-bottom: 0px;
`;
const TotalTimeLabel = styled(Label2)`
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
  font-weight: bold;
  margin-bottom: 6px;
`;
const TotalTime = styled(Hero)`
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
`;
const Average = styled(Label3)`
  color: ${({theme}) => theme.colors.typographyPalette.secondary};
  text-align: center;

  span {
    display: block;
    margin-bottom: 4px;
  }
`;
const StyledTable = styled(Table)`
  margin-bottom: 0px;

  td:first-child {
    white-space: nowrap;
    width: auto;
  }

  tr:first-child {
    td {
      white-space: nowrap;
    }
    td:last-child {
      text-align: center;
      width: 122px;
    }
  }

  td {
    width: 122px;
  }
`;


const Time = () => {
  const {scoring} = useConfigState();

  const rows = Object
    .entries(scoring?.subjectBreakdown || {})
    .map(([subject, breakdown]) => ({
      cells: [
        {content: subject},
        {content: formatTimeSpent(breakdown?.timeSpent)},
        {content: formatTimeSpent(breakdown?.avgTime)},
        {content: (
          <DivergentBar
            reference={breakdown?.avgTime}
            value={breakdown?.timeSpent}
            height={16}
            dividerWidth={1.5}
            />
        )}
      ],
    }))

  return (
    <Container>
      <StyledHeading>
        <Title>Time Management</Title>
        <SubTitle>Green means you’re faster than average! Red shows where you can improve your pace.</SubTitle>
      </StyledHeading>
        <Wrapper>
          <Left>
            <LeftContainer>
              <TotalTimeLabel>Your Total Time</TotalTimeLabel>
              <TotalTime>{formatTimeSpent(scoring?.overview?.totalTime)}</TotalTime>
              <BarWrapper>
                <DivergentBar
                  reference={scoring?.overview?.avgTime}
                  value={scoring?.overview?.totalTime}
                  />
              </BarWrapper>
              <Average>
                <span>Avg. Total Time</span>
                {formatTimeSpent(scoring?.overview?.avgTime)}
              </Average>
            </LeftContainer>
          </Left>
        <RightWrapper>
          <Right>
            <StyledCard>
              <StyledTable
                fixed={false}
                hideChevrons
                rows={[
                  { cells: [{ content: 'Subject' }, { content: 'Time' }, { content: 'Avg. Time' }, { content: 'Pace' }] },
                  ...rows
                ]}
              />
            </StyledCard>
          </Right>
        </RightWrapper>
      </Wrapper>
    </Container>
  );
};

export default Time;