import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {Column} from '../../Grid';
import VerifyEmail from '../../../components/Form/Auth/VerifyEmail';
import {IconButton} from '../../Branding/Buttons';
import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';

const ModalContainer = styled(Column)`
  max-width: 528px;
  position: relative;
  height: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    transition: transform .5s, opacity .75s;
    ${({visible}) => visible
      ? `
        transform: translateY(0%);
        opacity: 1;
        margin-top: auto;
      `
      : `
        transform: translateY(100%);
        opacity: 0;
      `
    }
  }
`;

const CloseButton = styled(IconButton)`
  z-index: 2;
  position: absolute;
  top: -32px;
  right: -32px;
  opacity: 0;
  ${({visible}) => visible && `
    opacity: 1;
  `}

  ${({theme}) => theme.mediaQueries.tablet} {
    color: white;
    top: -40px;
    right: -32px;
    ${({visible}) => visible && `
      right: 16px;
    `}
  }
`;

const VerifyEmailModal = ({close}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <ModalContainer visible={visible}>
      <CloseButton
        type={'primary'}
        size={'large'}
        visible={visible}
        onClick={close}
      >
        <X />
      </CloseButton>
      <VerifyEmail onSubmit={close} />
    </ModalContainer>
  )
}
export default VerifyEmailModal;
