import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTestNavigatorContext} from '../../../contexts/TestNavigator';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/shared/src/util/contexts/Timer';
import {Button} from '@bootcamp/web/src/components/USMLEBank/shared';
import {Box} from '@bootcamp/web/src/components/USMLEBank/shared';
import {CloseButton, CloseIcon} from '../../../components/QBank/SidebarTop';

import {
  SettingsContainer,
  Setting,
  SettingWrapper,
  Toggle,
} from '@bootcamp/web/src/components/PrometricBank/components/shared';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;

  font-family: arial;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: auto;
`;
const Header = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
`;
const Body = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;

  ol {
    margin: 0;
    padding: 0px 22px;
  }
`;
const SettingHeader = styled.div`
  margin-bottom: 16px;
  color: #000;
  font-weight: 700;
  line-height: 100%;
`;
const Bar = styled(Box)`
  display: flex;
  align-items: center;
  color: white;
  padding: 6px 8px;
  height: 54px;
  background: ${({bootcamp, theme}) => bootcamp === 'nclex' ? theme.colors.prometricPalette.nclex.default : theme.colors.prometricPalette['med-school'].default};
`;
const StyledCloseButton = styled(CloseButton)`
  width: 42px;
  height: 42px;
  margin: 0;
  padding: 0;
`;
const Luck = styled.div`
  margin: 12px 0px 24px;
`;

const CancelButton = styled(Button)`
  background: #f0f0f0;
  color: #000;
  margin-top: 16px;
  &:hover {
    background: #e0e0e0;
  }
  border: 1px solid #d0d0d0;
`;


const SelfAssessmentRules = ({timeLimit, totalQuestionBlocks, timeMultiplier}) => (
  <ol>
    <li>You will have {timeLimit * timeMultiplier} minutes to finish each of block of 40 questions.</li>
    <li>There are {totalQuestionBlocks} blocks for a total of {totalQuestionBlocks * 40} questions.</li>
    <li>You will have a {5 * timeMultiplier} minute break between each block.</li>
    <li>You cannot pause the test and must answer all 4 blocks together, set aside at least {4 * timeMultiplier} hours to complete the Self Assessment.</li>
    <li>Your score report will appear after finishing the assessment.</li>
  </ol>
)
export const StartBlock = ({timeLimit, questions, totalQuestionBlocks, headerText="Welcome to Bootcamp.com Self Assessment 1", Rules=SelfAssessmentRules, startText="Start Assessment" }) => {
  const [loading, setLoading] = useState(false);
  const {bootcamp} = useUserDataContext();

  const {methods: {transitionBlock, setTimeMultiplier, handleGoBack, instantiateTestProgress}, variables: {timeMultiplier, test, testProgressId}} = useTestNavigatorContext();
  const {updateTimeLimit} = useTimerContext();

  function handleTimeAccommodationChange(value) {
    const update = value === timeMultiplier ? 1 : value;
    setTimeMultiplier(update);
    updateTimeLimit(timeLimit * update);
  }

  useEffect(() => {
    testProgressId && transitionBlock('next');
  }, [testProgressId]);

  return (
    <Container>
      <Bar bootcamp={bootcamp}>
        <StyledCloseButton
          type={'secondary'}
          size={'large'}
          onClick={() => handleGoBack()}
          children={<CloseIcon />}
        />
      </Bar>
      <Wrapper>
        <SettingsContainer>
        <Header>{headerText}</Header>
        <Body>
          Please read the following before starting:
          <Rules timeLimit={timeLimit} totalQuestionBlocks={totalQuestionBlocks} timeMultiplier={timeMultiplier}/>
          <Luck>Good Luck!</Luck>
        </Body>
        <SettingHeader>Test Settings</SettingHeader>
        <Setting>
          <Toggle checked={timeMultiplier === 1.5} onChange={() => handleTimeAccommodationChange(1.5)}/>
          <SettingWrapper>
            <strong>Time Accommodation 1.5x:</strong> Get an additional 1.5x time for students who have approved accommodations.
          </SettingWrapper>
        </Setting>
        <Setting>
          <Toggle checked={timeMultiplier === 2} onChange={() => handleTimeAccommodationChange(2)}/>
          <SettingWrapper>
            <strong>Time Accommodation 2x:</strong> Get an additional 2x time for students who have approved accommodations.
          </SettingWrapper>
        </Setting>
        <Button disabled={loading} onClick={() => {
          if (loading) return;
          setLoading(true);
          instantiateTestProgress(test);
        }}>{startText}</Button>
        <CancelButton disabled={loading} onClick={() => {
          handleGoBack();

        }}>Cancel</CancelButton>
        </SettingsContainer>
      </Wrapper>
      <Bar bootcamp={bootcamp} />
    </Container>

  );
};

StartBlock.defaultProps = {};
StartBlock.propTypes = {};

export default StartBlock;