import React, {useCallback} from 'react';

import CreateTest from '../../pages/CreateTest';
import ChapterCard from './Chapter';
import {StyledBeginPracticeButton} from './components/Buttons'

import {useModalContext} from '../../../contexts/Modal';
import {getCounts, getProgress} from '../../api/progress/contentType';
import {useAsync} from '../../../hooks';

import styled from 'styled-components';
import { useUserDataContext } from '../../../contexts/UserData';

const StyledChapterCard = styled(ChapterCard)`
  background: white;
  border: none;
  overflow: inherit;
`;

const FreeQuestions = ({id, themePalette}) => {
  const {DEFAULT_USER_ID} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  const handleFreeQuestionCardClick = () => modalDispatch({
    type: 'open',
    component: CreateTest,
    componentProps: { defaults: { subject: 'Free Questions' } },
    enableClickClose: true,
  });

  const fetchCounts = useCallback(async () => {
    return await getCounts([{name: 'Free Questions', id}]);
  }, []);

  const fetchProgress = useCallback(async () => {
    return await getProgress([{name: 'Free Questions', id}], DEFAULT_USER_ID);
  }, [])

  const {value: counts} = useAsync(fetchCounts);
  const {value: progress} = useAsync(fetchProgress);

  return (
    <StyledChapterCard
      name={'Free Questions'}
      id={id}
      questionCount={counts?.[id]}
      progress={progress && progress.byTest ? progress.byTest[id] : 'loading'}
      themePalette={themePalette}
      defaultLocked={false}
      actions={[<StyledBeginPracticeButton themePalette={themePalette} hideOnMobile />]}
      onClick={handleFreeQuestionCardClick}
    />
  );
};

export default FreeQuestions;