import React, {useState} from 'react';
import PropTypes from 'prop-types';

// import {TestSearch} from '../../../admin/TestList';
import {createQuestionConnection, deleteQuestionConnection} from '@bootcamp/shared/src/requests';
import {H2} from '../../../components/Typography';

import styled from 'styled-components';

const Container = styled.div`${({theme}) => `
  width: 80%;
  height: 80%;
  background: white;
  border-radius: 12px;
  padding: ${theme.layouts.spacing.l};
  overflow: hidden;
`}`;

const Wrapper = styled.div`
  height: 100%;
`;

const Header = styled(H2)`
  margin ${({theme}) => theme.layouts.spacing.l} 0px;
`;

// Leaving this here just in case we want to bring it back
async function addQuestion(questionId, testBlockConnectionTestBlockId, blockName) {
  const confirmAdd = window.confirm(`Are you sure you want to add question ${questionId} to ${blockName}?`);

  if (!confirmAdd) return;

  const {data: {addUniqueQuestionToTestBlock}} = await createQuestionConnection(questionId, testBlockConnectionTestBlockId);

  if (addUniqueQuestionToTestBlock.match('This question already exists')) alert(`This question is already in ${blockName}!`);

  return addUniqueQuestionToTestBlock;
}

async function moveQuestion(questionConnection, testBlockConnectionTestBlockId, blockName) {
  const {id, question} = questionConnection;

  const confirmAdd = window.confirm(`Are you sure you want to move question ${question.id} to ${blockName}?`);

  if (!confirmAdd) return;

  // create connection
  const {data: {addUniqueQuestionToTestBlock}} = await createQuestionConnection(question.id, testBlockConnectionTestBlockId);

  // double check that the connection isn't already there
  if (addUniqueQuestionToTestBlock.match('This question already exists')) {
    alert(`This question is already in ${blockName}!`);
    return;
  }

  // delete connection
  await deleteQuestionConnection(id);

  window.location.reload();
  return true;
}

const MoveQuestionToTest = ({question, questionConnection, modes}) => {
  const [{loadingBlockId, finishedLoading}, setState] = useState({loadingBlockId: false, finishedLoading: false});

  return (
    <Container onClick={event => {
      event.preventDefault();
      event.stopPropagation();
    }}>
      <Header>Moving question {question.id}</Header>
      <Wrapper>
        {/* <TestSearch
          linkResultToTest={false}
          showBlocks={true}
          createButtonConfig={() => ([])}
          createBlockButtonConfig={(testBlockConnectionTestBlockId, blockName) => [
            modes.indexOf('add') !== -1 && {
              children: loadingBlockId === testBlockConnectionTestBlockId && !finishedLoading
                ? 'Adding...'
                : loadingBlockId === testBlockConnectionTestBlockId && finishedLoading
                ? 'Added'
                : 'Add',
              onClick: async event => {
                setState({loadingBlockId: testBlockConnectionTestBlockId, finishedLoading: false});
                await addQuestion(question.id, testBlockConnectionTestBlockId, blockName);
                setState({loadingBlockId: testBlockConnectionTestBlockId, finishedLoading: true});
              },
              disabled: loadingBlockId === testBlockConnectionTestBlockId,
              as: 'button',
            },
            modes.indexOf('move') !== -1 && {
              children: loadingBlockId === testBlockConnectionTestBlockId && !finishedLoading
                ? 'Moving....'
                : loadingBlockId === testBlockConnectionTestBlockId && finishedLoading
                ? 'Moved!'
                : 'Move',
              onClick: async event => {
                setState({loadingBlockId: testBlockConnectionTestBlockId, finishedLoading: false});
                const success = await moveQuestion(questionConnection, testBlockConnectionTestBlockId, blockName);
                setState({loadingBlockId: testBlockConnectionTestBlockId, finishedLoading: true});
                success && window.location.reload();
              },
              disabled: loadingBlockId === testBlockConnectionTestBlockId,
              as: 'button',
            }
          ]}
          /> */}
      </Wrapper>
    </Container>
  );
}

MoveQuestionToTest.propTypes = {
  question: PropTypes.shape({}).isRequired,
  questionConnection: PropTypes.shape({}),
  modes: PropTypes.oneOf(['add', 'move']),
};

MoveQuestionToTest.defaultProps = {
  modes: ['add', 'move'],
};

export default MoveQuestionToTest;
