import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {useUserDataContext} from '../../contexts/UserData';
import {useTestNavigatorContext} from '../../contexts/TestNavigator';
import {TimerProvider} from '../../contexts/TestTimer';
import {useReloadBlockerContext} from '../../contexts/ReloadBlocker';

import Exhibit from '../Exhibit';
import {FullPageSpinner} from '../Branding';
import {Column, FlexBox} from '../Grid';
import QuestionSet from '../TestBlocks/QuestionSet';
import {StartButton} from './Buttons';
import Sidebar from '../PrometricBank/components/Sidebar';
import ProgressBar from '../ProgressBar';
import {Body4} from '../Typography';
import {StartBlock} from '@bootcamp/web/src/bootcamps/pages/SelfAssessment/StartBlock';
import ImagePrefetcher from '../../bootcamps/anatomy/Case/components/ImagePrefetcher';

import Question from './Question';
import TopBar from './TopBar';
import BottomBar from './BottomBar';

import {changeIntercomDisplay} from '@bootcamp/shared/src/util';
import {useModalContext} from '../../contexts/Modal';
import {SubmitAnatomyCase} from '../Modal';
import moment from 'moment';

import theme from '@bootcamp/shared/src/styles/theme';

const Container = styled(Column)`
  justify-content: center;
  position: relative;
  background: ${({themePalette}) => themePalette.dark};
`;
const ContentArea = styled(Column)`
  opacity: ${({loading, template, reviewType}) => (loading && (template === 'testReview' || (template === 'quickReview' && reviewType === 'quick-study'))) ? 0 : 1};
  transition: opacity .5s;
`;

const QuestionSetContainer = styled(FlexBox)`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  flex-direction: row;
`;
const QuestionSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  z-index: 3;
  background: white;
`;
const Loader = styled(FullPageSpinner)`
  background: ${({color}) => color};
`;
const ProgressLabel = styled(Body4)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
`;

function getTestTitle(title, contentType, config) {

  if (!title) return config?.title;

  const contentTypePrefixes = {
    'clinical-cases': 'Clinical Cases',
    'application': 'Application',
    'lecture-style-questions': 'Lecture Style',
    'identify-structures': 'Identification',
    'practicals': 'Practicals',
    'bio-bites': 'Bio Bites',
    'bites': 'Bites',
    'rbites': 'Reaction Bites',
    'plus-packs': 'Plus Pack',
  };

  const prefix = contentTypePrefixes[contentType] ? `${contentTypePrefixes[contentType]}:` : '';

  return `${prefix} ${title}`;
}

const QBank = ({
  match,
  themePalette,
  username,
  startBlockTitle,
  educators,
  handleEndBlockUpdate,
  enableBookmarking,
  alignQuestion,
  name,
}) => {
  const [showingExhibit, setShowingExhibit] = useState(false);
  const [exhibitType, setExhibitType] = useState();

  const [fontScale, setFontScale] = useState(0);
  const updateFontScale = int => {
    const scale = Math.min(Math.max(fontScale + int, -2), 2);
    setFontScale(scale);
  };

  const {
    methods: {
      setLeftButtons,
      transitionBlock,
    },
    variables: {
      test,
      blockIndex,
      testBlockConnections,
      startBlockIndex,
      endBlockIndex,
      loading,
      template,
      quizProgress,
      filterActive,
      skipStartBlock,
      customTestConfig,
      type,
      timeMultiplier
    }
  } = useTestNavigatorContext();

  const {modalDispatch} = useModalContext();
  const {bootcamp} = useUserDataContext();
  const {toggleReloadBlocker} = useReloadBlockerContext();
  const [sidebarState, setSidebarState] = useState();

  const questions = quizProgress.map(block => block.questions || []).flat();
  const questionCount = questions.length;
  const questionCountThusFar = questions.findIndex(({current}) => current) + 1;
  const contentType = match?.params?.contentType;
  const reviewType = match?.params?.reviewType;
  const isReadinessExam = type === 'readinessExam';
  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('review');
  const alwaysShowingExplanation = customTestConfig?.submitted || isReview;

  const mastery = questions.reduce((acc, {masteryLevel}) => ({
      ...acc,
      ...((masteryLevel && masteryLevel !== 'none') ? {[masteryLevel]: acc[masteryLevel] + 1, total: acc.total + 1} : {}),
    }), {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    total: 0
  });

  const testTitle = getTestTitle(name, contentType, test?.config);

  const blockRenderer = (testBlockConnection) => {
    const defaultBlocksForTemplate = {
      tbcQuestionBank: {testBlock: {type: 'startBlock'}},
    }[template] || {testBlock: {}};

    const {testBlock} = testBlockConnection || defaultBlocksForTemplate;

    switch (testBlock.type) {
      case 'startBlock':
        if (loading) return <Loader color={themePalette.dark} active />;
        if (isReadinessExam) {
          return (
            <StartBlock
              timeLimit={200}
              questions={questions}
              totalQuestionBlocks={testBlockConnections.length}
              headerText={`Welcome to NCLEX Bootcamp Readiness Exam ${test.config?.title.slice(-1)}`}
              Rules={({timeLimit, totalQuestionBlocks, timeMultiplier}) => {
                const formattedDuration = moment.utc(moment.duration(timeLimit * timeMultiplier, 'minutes').asMilliseconds()).format('H [hours] m [minutes]');
                return (
                  <ol>
                    <li>You will have {formattedDuration} to answer 100 questions, similar to the real NCLEX.</li>
                    <li>You cannot pause the exam and must answer all questions at once, set aside at least {formattedDuration} to complete the Readiness Exam.</li>
                    <li>Your score report will appear after finishing the exam.</li>
                  </ol>
                  )
              }}
              startText={'Start Readiness Exam'}
            />
          )
        }
        return transitionBlock('nextUntagged')
      case 'passage':
      case 'questionSet':
      case 'caseQuestionSet':
        return (
          <QuestionSetContainer>
            <ImagePrefetcher />
            <QuestionSetWrapper>
              {(showingExhibit && exhibitType) && <Exhibit type={exhibitType} onCloseExhibit={() => setShowingExhibit(false)} defaultPosition={{x: 0, y: 0}} />}
              <TopBar
                themePalette={themePalette}
                title={testTitle}
                setShowingExhibit={setShowingExhibit}
                toggleSidebar={() => setSidebarState(!sidebarState)}
                setFontScale={updateFontScale}
                fontScale={fontScale}
              />
                  <QuestionSet
                    themePalette={themePalette}
                    enableBookmarking={enableBookmarking}
                    setShowingExhibit={setShowingExhibit}
                    setExhibitType={setExhibitType}
                    noMaxWidth
                    match={match}
                    alignQuestion={alignQuestion}
                    scrollable={false}
                    overflowHidden
                    maxHeight
                    renderQuestion={({testBlock, questionIndex, blockParts, ...props}) => {
                      const {masteryLevel} = testBlock.questionConnections.items[questionIndex] || {};

                      return <Question
                          key={`${testBlock.id}-${questionIndex}`}
                          questionIndex={questionIndex}
                          themePalette={themePalette}
                          showingTags={false}
                          alwaysShowingExplanation={alwaysShowingExplanation}
                          masteryLevel={masteryLevel}
                          bootcamp={bootcamp}
                          fontScale={fontScale}
                          {...props}
                          />
                    }}
                  />
              <BottomBar themePalette={themePalette} alwaysShowingExplanation={alwaysShowingExplanation} toggleSidebar={() => setSidebarState(!sidebarState)}/>
            </QuestionSetWrapper>
          </QuestionSetContainer>
        );
      default:
        return null;
    }
  }

  const examComponent = blockRenderer(testBlockConnections[blockIndex]);

  useEffect(() => {
    switch (blockIndex) {
      case startBlockIndex:
        setLeftButtons([
          {component: <StartButton colorConfig={themePalette} children={'Begin Review'} onClick={() => transitionBlock('next')} />}
        ]);
        return;
      case endBlockIndex:
        if (handleEndBlockUpdate) {
          modalDispatch({
            type: 'open',
            enableClickClose: true,
            component: SubmitAnatomyCase,
            componentProps: {
              headerText: 'Nice work! You\'ve reached the end of your review.',
              bodyText: 'Would you like to keep reviewing or complete your review?',
              cancelConfig: {
                text: 'Keep Reviewing',
                onCancel: () => {
                  modalDispatch({type: 'close'});
                  // filterActive ? transitionBlock('firstFilterActive') : transitionBlock('firstQuestionSet');
                },
                shouldRedirect: false,

              },
              confirmConfig: {
                text: 'Complete Review',
                colorConfig: themePalette,
                onConfirm: () => toggleReloadBlocker(false),
                shouldRedirect: true,
                redirect: location => location.pathname.split('/').slice(0, -1).join('/')
              }
            },
          });
        }
        return;
      default:
        break;
    }
  }, [blockIndex, loading]);

  useEffect(() => {
    changeIntercomDisplay('none');
    return () => changeIntercomDisplay('block');
  }, []);

  const timeLimit = isReadinessExam && !isReview
    ? 200
    : !customTestConfig?.config?.timed
    ? null
    : customTestConfig?.suspended
    ? moment.duration(customTestConfig?.timeLeft).asMinutes()
    : (parseInt(customTestConfig?.config?.testLength) * 1.5 || null);

    const stopwatch = isReadinessExam
      ? false
      : !customTestConfig || !customTestConfig?.config?.timed

    return (
    <TimerProvider
      stopwatch={stopwatch}
      timeLimit={timeLimit}
      autoStart={!isReadinessExam}
      disabled={customTestConfig?.submitted}
      >
      <Container themePalette={themePalette}>
        <Loader color={themePalette.dark} active={loading && (template === 'testReview' || skipStartBlock)}/>
        <ContentArea children={examComponent} loading={loading} template={template} reviewType={reviewType}/>
        <Sidebar
          sidebarState={[sidebarState, setSidebarState]}
          themePalette={theme.colors.prometricPalette.nclex}
          hide={template === 'customTest' ? ['timer', 'toggleDarkMode'] : ['toggleDarkMode']}
          title={testTitle}
          progressBar={
            <ProgressBar
              withMargin={false}
              showLabel={false}
              total={questionCount}
              segments={[
                { count: mastery.mastered },
                { count: mastery.reviewing },
                { count: mastery.learning },
              ]}
            />
          }
          />
      </Container>
    </TimerProvider>
  );
}

QBank.defaultProps = {
  showScore: true,
  enableBookmarking: false
};

export default QBank;
