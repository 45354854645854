import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


function getButtonTypeStyles(buttonType, color, theme) {
  const {colors: {neutralsPalette}} = theme;

  switch (buttonType) {
    case 'primary':
      return `
        transition: all 100ms ease;
        color: ${theme.colors.brandPalette[color].default};

        &:hover {
          color: ${theme.colors.brandPalette[color].hover};
        }

        &:disabled {
          color: ${neutralsPalette.light};
        }
      `;
    default:
      return '';
  }
}

const Container = styled.button.attrs({type: 'button'})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  border: none;
  background: none;
  font-family: proxima-nova;

  width: ${({fixed}) => fixed ? '100%' : 'auto'};
  font-weight: ${({theme}) => theme.font.weight.semibold};
  font-size: ${({theme}) => theme.font.size.regular};
  color: ${({theme}) => theme.colors.neutralsPalette.white};

  padding: 0px 8px;
  &:focus {
    outline: none;
  }

  ${({buttonType, color, theme}) => getButtonTypeStyles(buttonType, color, theme)}

  a:-webkit-any-link {
    color: inherit;
    text-decoration: none;
  }
`;

const TextButton = ({className, type, color, fixed, children, iconConfig, disabled, onClick}) => {
  return (
    <Container className={className} buttonType={type} color={color} fixed={fixed} disabled={disabled} onClick={onClick}>
      {children}
    </Container>
  );
}


TextButton.propTypes = {
  type: PropTypes.oneOf(['primary']),
  color: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red', 'pink', 'indigo']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fixed: PropTypes.bool,

};

TextButton.defaultProps = {
  type: 'primary',
  color: 'indigo',
  disabled: false,
  onClick: () => true,
  fixed: true,
};

export default TextButton;
