import React from 'react';
import {useHistory} from 'react-router-dom';
import useDocumentTitle from '@rehooks/document-title';
import {Checkout} from '../../../components/Form';
import {useUserDataContext} from '../../../contexts/UserData';
import styled from 'styled-components';
const ContainerBackgroundColor = styled.div`
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.colors.brandPalette.royal.dark};
  overflow: hidden;
`;
const ScrollWrapper = styled.div`
  overflow-y: scroll;
  margin-top: 71px;
  height: calc(100% - 71px);
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: 55px;
    height: calc(100% - 55px);
  }
`;
const CheckoutPage = ({location}) => {
  useDocumentTitle('Bootcamp.com | Checkout');

  const {bootcamp} = useUserDataContext();
  const urlParams = new URLSearchParams(location.search);
  const priceId = urlParams.get('priceId');
  const {push} = useHistory();
  if (!priceId) window.location = 'https://bootcamp.com';
  return (
    <ContainerBackgroundColor>
      <ScrollWrapper>
        <Checkout
          priceId={priceId}
          onAnimationFinished={(bootcamp, intentId) => push({
            pathname: `/${bootcamp}/thank-you`,
            state: {intentId, referrer: 'checkout'},
          })}
          bootcamp={bootcamp}
        />
      </ScrollWrapper>
    </ContainerBackgroundColor>
  );
};

export default CheckoutPage;
