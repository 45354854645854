import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {MagnifyingGlass} from '@styled-icons/heroicons-outline/MagnifyingGlass';
import {XMark as X} from '@styled-icons/heroicons-outline/XMark';

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  color: white;
`;
const Input = styled.input`
  color: white;
  font-family: 'proxima-nova';
  background: ${({theme}) => theme.overlays.opacity.light._100};
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  transition: box-shadow .4s ease, background .4s ease;
  border-radius: 100px;
  box-shadow: rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
  border: none;
  padding: 16px;
  height: auto;
  vertical-align: middle;
  white-space: normal;
  font-size: 18px;
  width: 100%;
  line-height: 27px;
  padding: 16px 32px 16px 64px;
  outline: none;

  ::placeholder {
    color: ${({theme}) => theme.overlays.opacity.light._400};
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 12px 24px 12px 60px;
  }
`;
const Icon = styled(MagnifyingGlass).attrs(props => ({size: 24}))`
  position: absolute;
  z-index: 10;
  user-select: none;
  left: 24px;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
  color: ${({theme}) => theme.overlays.opacity.light._400};
  ${({theme}) => theme.mediaQueries.tablet} {
    left: 24px;
  }
`;
const ClearIcon = styled(X).attrs(props => ({size: 24}))`
  position: absolute;
  z-index: 10;
  user-select: none;
  right: 32px;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
  color: ${({theme}) => theme.overlays.opacity.light._400};
  cursor: pointer;
`;

const SearchBar = ({className, ...props}) => {
  const ref = useRef();

  // useEffect(() => {
  //   ref?.current?.focus();
  // }, []);

  return (
    <Container className={className}>
      <Icon />
      <Input ref={ref} {...props} />
      {props.value && <ClearIcon onClick={props.clear}/>}
    </Container>
  );
}


SearchBar.propTypes = {
  onChange: PropTypes.func,
  clear: PropTypes.func,
  value: PropTypes.node,
  placeholder: PropTypes.string,
};

export default SearchBar;
