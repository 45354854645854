import React from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import {Times} from '@styled-icons/fa-solid/Times';

const Frame = styled.iframe.attrs({
  scrolling: 'no',
  frameborder: '1',
  marginheight: '0px',
  marginwidth: '0px',
  height: '228px',
  width: '316px'
})`
  border:0px #FFFFFF none;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 242px;
  z-index: 10;
  height: 0px;
`;

const Title = styled.div`
  position: absolute;
  background: #000080;
  top: 0;
  width: 100%;
  height: 23px;
  color: white;
  font-size: 9pt;
  font-weight: bold;
  font-family: Arial;
  text-align: center;
  line-height: 23px;
  cursor: grab;
`;

const CloseButton = styled(Times)`
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  text-align: center;
  background: red;
  color: white;
`;

const Calculator = ({onCloseExhibit, defaultPosition}) => (
  <Draggable defaultPosition={defaultPosition || {x: 50, y: -228}}>
    <Wrapper onClick={e => e.stopPropagation()}>
      <Title>Calculator</Title>
      <CloseButton onClick={onCloseExhibit} />
      <Frame src={`${process.env.PUBLIC_URL}/assets/calculator/calc.html`} />
    </Wrapper>
  </Draggable>
);

export default Calculator;
