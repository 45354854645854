import React from 'react';
import styled from 'styled-components';
import {Row} from '@bootcamp/web/src/components/Grid';
import {Loader} from '@bootcamp/web/src/components/Branding';
import {Table, Heading, Blurb, TableRow, Cell} from '@bootcamp/web/src/bootcamps/pages/CustomTestReview/components/shared';
import {H2, H3} from '@bootcamp/web/src/components/Typography';

export const StyledHeader = styled(Heading)`
`;
export const StyledLabel = styled(H2)`
  color: white;
  margin-bottom: 16px;
`;
export const StyledTable = styled(Table)`
  border-radius: 8px;
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  overflow: hidden;
  ${TableRow} {
    &:first-child {
      ${Cell} {
        span {
          color: ${({theme}) => theme.colors.neutralsPalette.grey} !important;
        }
      }
    }
    &:nth-child(2) {
      background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
      font-weight: 600;
    }
  }
`;
export const StyledBlurb = styled(Blurb)`
`;


export const LoadingSpinner = styled(Loader)`
  margin: 12px auto;
  overflow: initial;
  display: flex;
`;

const ToggleContainer = styled(Row)`
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border-radius: 8px;
  position: relative;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex: 1;
  max-width: 300px;
`;

const ToggleActive = styled.div`
  background: white;
  position: absolute;
  left: ${({value}) => value ? '50%' : 0};
  top: 0;
  height: 100%;
  width: 50%;
  transition: left .3s;
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.shadow.low};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ToggleText = styled(H3)`
  color: ${({theme, active}) => !active ? 'white' : theme.colors.neutralsPalette.extraDark};
  z-index: 2;
  font-weight: normal;
  transition: color .3s;
  font-size: 14px;
  line-height: 100%;
`;

export const Toggle = ({value, setValue}) => {
  return (
    <ToggleContainer onClick={() => setValue(!value)}>
      <IconContainer>
        <ToggleText active={!value}>
          Show First Attempt
        </ToggleText>
      </IconContainer>
      <IconContainer>
        <ToggleText active={value}>
          Show Latest Attempt
        </ToggleText>
      </IconContainer>
      <ToggleActive value={value} />
    </ToggleContainer>
  )
}