import React from 'react';
import styled from 'styled-components';
import Chapter from '../Cards/Chapter';
import Callout from '../Cards/Callout';

import {resolveIconPath} from '@bootcamp/web/src/helpers';
import {useUserDataContext} from '../../../contexts/UserData';

import {BeginPracticeButton, UpgradeButton} from '../Cards/components/Buttons';

const Container = styled.div`
`;

const Wrapper = styled.div`
`;
const Card = styled(Chapter)`
  background: white;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 32px;
    height: 32px;
  }
`;

const Generators = ({content, themePalette, calloutText}) => {
  const {isUpgraded} = useUserDataContext();
  // TODO add icons here
  return (
    <Container>
      {calloutText && <Callout children={calloutText}/>}
      {content.map(({name, icon, route}, index) => {
        const hasAccess = isUpgraded || (!isUpgraded && index === 0);
        return (
          <Wrapper>
            <Card
              name={name}
              id={`generator-${name}`}
              icon={<Image src={resolveIconPath(icon)} size={16} />}
              showProgress={false}
              defaultLocked={!hasAccess}
              themePalette={themePalette}
              route={route}
              onClick={() => window.location.href = `/dat/perceptual-ability/generators/${route}`}
              actions={hasAccess ? <BeginPracticeButton themePalette={themePalette} /> : <UpgradeButton/>} />

          </Wrapper>
        );
      })}
    </Container>
  );
};

Generators.defaultProps = {};
Generators.propTypes = {};

export default Generators;
