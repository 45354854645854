import styled from 'styled-components';

export const RouteBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({theme, color}) => color || theme.colors.brandPalette.indigo.dark};
`;

export const NavBarRouteContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${({theme, color}) => color || theme.colors.brandPalette.indigo.default};
  padding-top: 72px;
  height: 100%;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding-top: 56px;
    min-height: auto;
  }
`;
