import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {GutterRow} from '../../../../components/Spacing';
import dailywarmup from './images/dailywarmup.svg';

const FrontPageContainer = styled(GutterRow)`
  overflow: visible;
`;

const Image = styled.img`
  opacity: ${({loading}) => loading ? '0' : '1'};
  transform: ${({loading}) => loading ? 'translateY(100px)' : 'translateY(0px)'};
  transition: opacity .8s, transform 1s;
  width: 100%;
  max-width: 450px;
  margin: auto;
`;

const FrontPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1);
  }, [])
  return (
    <FrontPageContainer>
      <Image loading={loading} src={dailywarmup} />
    </FrontPageContainer>
  )
}

export default FrontPage;
