import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {Auth} from 'aws-amplify';
import {useFormContext} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import queryString from 'query-string';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {Container, Header, SubHeader, Body, FieldGroup, SubmitContainer, SubmitButton, LinkButton} from '../../';

async function updatePassword(values) {
  try {
    const {username, resetCode, confirmPassword} = values;
    await Auth.forgotPasswordSubmit(username, resetCode, confirmPassword);
  } catch (error) {
    console.log('error resetting password', error);
    return false;
  }
}

const FormSubmit = ({children, onSubmit}) => {
  const {formState} = useFormContext();

  return (
    <SubmitContainer>
      <SubmitButton loading={formState.isSubmitting} disabled={!formState.isValid}>
        {children}
      </SubmitButton>
    </SubmitContainer>
  );
}

const ResetForm = ({username}) => {
  const {register, setValue} = useFormContext();

  useEffect(() => setValue('username', username), [username]);

  return (
    <Container>
      <Body>
        {/* TODO we can move this input into the form config and add it's hidden prop based on whether or not the email prop is passed*/}
        <input ref={register} type="text" name={'username'} hidden/>
        <Header>Create your new password?</Header>
        <SubHeader>Use the code we sent to your contact email</SubHeader>
        <FieldGroup children={<FieldRenderer config={RESET_PASSWORD_FORM_CONFIG} />}/>
        <LinkButton
          children={<Link to={`/forgot`}>Resend Code</Link>}
        />
      </Body>
      <FormSubmit children={'Update My Password'}/>
    </Container>
  );
}

const ResetPassword = ({onSubmit, location}) => {
  const {username} = queryString.parse(location.search);
  const {push} = useHistory();

  return (
    <FormWrapper onSubmit={async values => {
      await updatePassword(values);
      push('/signin');
    }}>
      <ResetForm username={username}/>
    </FormWrapper>
  );
}


const RESET_PASSWORD_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'Enter Your Code',
    name: 'resetCode',
    inputProps: {
      autoFocus: true
    }
  },
  {
    type: 'Text',
    label: 'New Password',
    name: 'newPassword',
    additionalInfo: '8+ characters',
    inputProps: {
      type: 'password'
    },
    validation: () => ({
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters'
      }
    })
  },
  {
    type: 'Text',
    label: 'Confirm New Password',
    name: 'confirmPassword',
    inputProps: {
      type: 'password',
    },
    validation: watch => ({
      validate: value => (watch('newPassword') === value) || 'passwords must match',
    })
  }
];

ResetPassword.propTypes = {
  onSubmit: PropTypes.func,
};

ResetPassword.defaultProps = {
  onSubmit: updatePassword,
};

export default ResetPassword;
