import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import {Link} from 'react-router-dom';

import MasteryReview from '../../components/MasteryReview';
import GettingStarted from '../../components/GettingStarted';
import FAQs from '../../components/WebflowFAQs';

import {H2} from '../../../components/Typography';
import FullClassroomCard from '../../components/Cards/ClassroomRow';
import CreateTest from '../../pages/CreateTest';

import {useMasteryArray} from '../../../hooks';

import anatomyIcon from '@bootcamp/shared/src/assets/icons/msb/anatomy.png';
import heartIcon from '@bootcamp/shared/src/assets/icons/msb/heart.png';
import {AddCircle} from '@styled-icons/fluentui-system-filled/AddCircle';

import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';
import {formatClassroomMastery} from '@bootcamp/shared/src/util/formatting';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '../../../contexts/UserData';

import Container from '../../pages/PageContainer';

import styled from 'styled-components';

const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledClassroomCard = styled(FullClassroomCard)`
  flex: 1;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;

const CardIcon = styled.img`
  height: 24px;
  width: 24px;
`;
const StyledAddCircle = styled(AddCircle)`
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
  width: 24px;
  height: 24px;
`;

const Home = props => {
  const {config} = useBootcampConfigContext();
  const {modalDispatch} = useModalContext();
  const {isUpgraded} = useUserDataContext()

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'med-school';

  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);
  const testsByClassroom = getTestsByClassroom(config?.classrooms);

  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom), [])
  const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);

  const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const todayActions = [
    {
      interactionKey: `Streak-${bootcamp}-Create-Test`,
      icon: {
        component: () => <StyledAddCircle/>,
        text: 'Create Test'
      },
      text: 'Study up to 40 questions',
      containerProps: {
        // as: Link,
        // to: `/${bootcamp}/quick-study/bites`,
        onClick: () => modalDispatch({
          type: 'open',
          component: CreateTest,
          enableClickClose: true,
        }),
      },
      // checkIfComplete: interactionObj => {
      //   const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
      //   const {streakCompletedToday} = getStreakDetails(streak, streakDate);
      //   return streakCompletedToday;
      // }
    },
    {
      interactionKey: `Streak-${bootcamp}-Review-Board`,
      icon: {
        component: () => <CardIcon src={heartIcon}/>,
        text: 'Step 1 Qbank'
      },
      text: 'Study 10 random questions',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study/board`,
      },
      // checkIfComplete: interactionObj => {
      //   const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
      //   const {streakCompletedToday} = getStreakDetails(streak, streakDate);
      //   return streakCompletedToday;
      // }
    },
    {
      interactionKey: `Streak-${bootcamp}-Review-Anatomy`,
      icon: {
        component: () => <CardIcon src={anatomyIcon}/>,
        text: 'Gross Anatomy Qbank'
      },
      text: 'Study 10 random questions',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study/anatomy`,
      },
      // checkIfComplete: interactionObj => {
      //   const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
      //   const {streakCompletedToday} = getStreakDetails(streak, streakDate);
      //   return streakCompletedToday;
      // }
    },
  ];

  // const {DEFAULT_USER_ID} = useUserDataContext();
  // const [testProgressCount, setTestProgressCount] = useState(null);

  // wire this up to progress load
  const progresses = useMasteryArray(config.classrooms.reduce((acc, {tagId, tagIds}) => tagIds ? [...acc, ...tagIds] :  [...acc, tagId], []), 'subject', data => formatClassroomMastery(data, 'subject'));
  const loading = Object.keys(progresses).length === 0;

  const classroomMasteryConfig = config.classrooms
    .filter(({locked}) => !locked)
    .map(({name, route, tagId, tagIds}) => ({
      name,
      route,
      progress: tagIds
        ? Object.keys(progresses)
          .reduce((acc, tagId) => tagIds.includes(tagId)
            ? {
                learning: (acc.learning || 0) + progresses[tagId].learning,
                reviewing: (acc.reviewing || 0) + progresses[tagId].reviewing,
                mastered: (acc.mastered || 0) + progresses[tagId].mastered,
                bookmarked: (acc.bookmarked || 0) + progresses[tagId].bookmarked,
              }
            : acc, {})
        : progresses[tagId]
      })
    );

  const renderClassroomCard = ({icon, themePalette, name, route, tagId, tagIds, locked, comingSoon, showVideoProgress, videoLessonCount, questionCount, educators}) => (
    <StyledClassroomCard
      key={tagId || `classroom-${name}`}
      iconPath={icon}
      themePalette={themePalette}
      name={name}
      route={`/${bootcamp}/${route}`}
      masteryType={'subject'}
      tagId={tagId}
      locked={comingSoon}
      comingSoon={comingSoon}
      questionCount={questionCounts?.classroomQuestionCounts ? questionCounts?.classroomQuestionCounts?.[route] || 0 : 'loading'}
      showProgress={true}
      courses={coursesByClassroom[route]}
      videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts ? courseBlockCounts?.classroomCourseBlockCounts?.[route] || 0 : 'loading'}
      showVideoProgress={coursesByClassroom[route]}
      progress={
        Object.keys(progresses).length === 0 ? null : tagIds
          ? tagIds.reduce((acc, tagId) => ({
              learning: acc.learning + progresses[tagId].learning,
              reviewing: acc.reviewing + progresses[tagId].reviewing,
              mastered: acc.mastered + progresses[tagId].mastered
            }), {learning: 0, reviewing: 0, mastered: 0})
          : progresses[tagId]
      }
      showExpertEducators
      educatorLayout={'column'}
      educatorNameLayout={'column'}
      educators={educators}
      detailsBorder={false}
      withHeaderPadding={false}
    />
  )
  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Step 1 - Preclinical
      </Title>
      <CardWrapper>
        {config.classrooms.filter(({section}) => section === 'step1').map(renderClassroomCard)}
      </CardWrapper>
      <Title>
        Anatomy
      </Title>
      <CardWrapper>
        {config.classrooms.filter(({section}) => section !== 'step1').map(renderClassroomCard)}
      </CardWrapper>
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
