import styled, {css, keyframes} from 'styled-components';
import {Spinner2} from '@styled-icons/icomoon/Spinner2';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';

const LoadingSpinner = styled(Spinner2).attrs(props => ({size: props.size || '14'}))`
  animation: ${({theme}) => keyframes`${theme.animation.spin}`} 2s infinite linear;
  fill: ${({theme: {colors: {brandPalette, neutralsPalette}}, color}) => (brandPalette[color] && brandPalette[color].default) || neutralsPalette.white};
`;

const UIButtonText = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  ${({iconPosition, IconComponent}) => iconPosition && IconComponent && css`
    margin-${iconPosition}: 8px;
  `}
`;
// getColor is a function that will return the styles based on the props that are passed to the component
//
// dark: if true, the button will be a dark version of the button
// active: if true, the button will be in the active state
// disabled: if true, the button will be in the disabled state
// theme: the theme object
// color: the color of the button. If not defined, it will default to royal
//

function getColor ({dark, active, disabled, theme, color="royal", themePalette}) {
  const {overlays: {opacity}, colors: {brandPalette, special, neutralsPalette}} = theme;

  const palette = themePalette || brandPalette[color];

  let background = palette.default,
  activeBackground = special.tuxedo,
  hoverBackground = palette.hover,
  disabledBackground = neutralsPalette.light,
  textColor = neutralsPalette.white,
  activeTextColor = neutralsPalette.white,
  disabledTextColor = neutralsPalette.grey,
  disabledHoverTextColor = neutralsPalette.grey;

  if (dark) {
    background = opacity.light._100;
    activeBackground = neutralsPalette.white;
    hoverBackground = opacity.light._200;
    textColor = neutralsPalette.white;
    activeTextColor = neutralsPalette.extraDark;
    disabledTextColor = opacity.light._400;
    disabledHoverTextColor = neutralsPalette.white;
  }

  if (active) {
    background = activeBackground;
    hoverBackground = activeBackground;
    textColor = activeTextColor;
  }

  if (disabled) {
    hoverBackground = disabledBackground;
    background = disabledBackground;
  }

  return css`
    background: ${background};
    color: ${disabled ? disabledTextColor : textColor};
    ${!disabled && css`box-shadow: 0px 2px 0px ${palette.dark};`}
    &:hover {
      background: ${hoverBackground};
      ${!disabled && css`transform: translateY(-1px);`}
      ${disabled && css`color: ${disabledHoverTextColor}; cursor: not-allowed;`}
    }
  `;
}

const UIButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  text-decoration: none;

  ${({full}) => css`width: ${full ? '100%' : 'fit-content'}`};
  ${({small}) => css`
    height: ${small ? '32px' : '42px'};
    font-size: ${small ? '14px' : '16px'};
  `}
  ${getColor}
  transition: background .1s;
  &:hover {
    transition: background .1s;
  }
`;

const RoundButtonText = styled(UIButtonText)`
  font-weight: 600;
`;

export const RoundButtonContainer = styled(UIButtonContainer)`
  border-radius: 64px;
  ${({small}) => css`
    height: ${small ? '42px' : '56px'};
    font-size: ${small ? '16px' : '18px'};
  `}
`
// DefaultButton is a function that returns a button component
// Container: the container component that will be used for the button
// Text: the text component that will be used for the button
// loading: if true, the button will show a loading spinner
// iconPosition: if defined, the button will show an icon on the left or right side of the button
// IconComponent: the icon component that will be used for the button
// children: the text that will be shown on the button

// What does this component do?
// It will return a button component that will have the styles defined in the Container and Text components
// It will also show a loading spinner if the loading prop is true
// It will show an icon if the iconPosition and IconComponent props are defined
// It will show the children prop as the text of the button

// Is this component easy to modify?
// Yes, you can modify the Container and Text components to change the styles of the button
// You can also modify the getColor function to change the colors of the button
// You can also modify the DefaultButton function to change the logic of the button


export const DefaultButton = (Container=UIButtonContainer, Text=UIButtonText) => ({className, IconComponent, iconPosition, children, ...containerProps}) => (
  <Container as={containerProps.href ? 'a' : 'div'} className={className} {...containerProps}>
    {
      containerProps.loading
      ? (
        <LoadingSpinner size={14} active />
      )
      : [
        iconPosition === 'left' && IconComponent,
        <Text iconPosition={iconPosition} IconComponent={IconComponent}>{children}</Text>,
        iconPosition === 'right' && IconComponent
      ]
    }
  </Container>
);


export const Chiclet = DefaultButton();

export const RoundButton = DefaultButton(RoundButtonContainer, RoundButtonText);

export const UpgradeButton = styled((props) => <RoundButton iconPosition={'left'} small IconComponent={<Badge width={16}/>} {...props}/>)`
  background: ${({theme}) => theme.colors.neutralsPalette.light};
  box-shadow: none;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};

  svg > path {
    fill: ${({theme}) => theme.colors.neutralsPalette.grey};
  }

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  }
`;