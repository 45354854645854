import React from 'react';
import styled, {css} from 'styled-components';
import {H3, H5, Body4, Body2} from '../../Typography';
import moment from 'moment';

import {ChartBarSquare as ChartSquareBar} from '@styled-icons/heroicons-outline/ChartBarSquare';
import {Clock} from '@styled-icons/heroicons-outline/Clock';
import {Calendar} from '@styled-icons/heroicons-outline/Calendar';
import {PencilSquare as PencilAlt} from '@styled-icons/heroicons-outline/PencilSquare';
import {Check} from '@styled-icons/heroicons-outline/Check';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {DraggablePopup} from '@bootcamp/web/src/components/NCLEXQBank/shared';
import ZeroOneScoring from '@bootcamp/web/src/components/NCLEXQBank/assets/scoring/01.png';
import PlusMinusScoring from '@bootcamp/web/src/components/NCLEXQBank/assets/scoring/PlusMinus.png';
import RationaleScoring from '@bootcamp/web/src/components/NCLEXQBank/assets/scoring/Rationale.png';
import {ReactComponent as ScoringSVG} from '@bootcamp/web/src/components/NCLEXQBank/assets/scoring/scoring.svg';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';


const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const Container = styled(Body2)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px 0px 24px;
`;
const SolutionStats = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${({theme}) => theme?.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.lightBorder};
  border-radius: 8px;
  background: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'initial'};

  ${({theme}) => theme.mediaQueries.tablet} {
    background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
    padding: ${({theme}) => theme.layouts.spacing.xs} ${({theme}) => theme.layouts.spacing.ml};
    flex-direction: column;
  }
`;
const SolutionStat = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: ${({isResult, correct}) => (isResult && correct) ? 'none' : 'initial'};
    border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
    padding: ${({theme}) => theme.layouts.spacing.s} 0px;

    &:last-child {
      border: none;
    }
  }
`;
const SolutionResult = styled(H5)`
  color: ${({correct, didNotAnswer, theme}) => didNotAnswer ? '' : correct ? theme.colors.interfacePalette.green.default  : theme.colors.interfacePalette.red.default};
  font-weight: bold;

  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const SolutionResultMobile = styled(H3)`
  color: ${({correct, didNotAnswer, theme}) => didNotAnswer ? '' : correct ? theme.colors.interfacePalette.green.default : theme.colors.interfacePalette.red.default};
  display: none;
  border: none;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.tablet} {
    display: initial;
  }
`;
const PencilAltMobile = styled(PencilAlt)`
  display: none;

  ${({theme}) => theme.mediaQueries.tablet} {
    display: initial;
    margin-right: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const SolutionStatTop = styled.div`
  display: flex;
  align-items: center;
`;
const SolutionStatTopText = styled(Body4)`
  display: flex;
  align-items: center;
  ${({loading, theme}) => theme.animation.loadingBackground({loading})};
  min-width: 64px;
  height: 18px;
  border-radius: 8px;
  padding: ${({noIcon}) => noIcon ? '8px 0px' : '8px'};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-left: auto;
    justify-content: flex-end;
    font-weight: bold;
  }
`;
const SolutionStatBottom = styled(Body4)`
  color: ${({theme}) => theme.colors.neutralsPalette.dark};
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;

    ${({showForIncorrectAnswerOnMobile}) => showForIncorrectAnswerOnMobile && css`
      display: flex;
      strong {
        margin-left: auto;
      }
    `}
  }
`;
const Colon = styled.span`
  display: initial;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const SolutionStatBottomMobile = styled(Body4)`
  display: none;
  color: ${({theme}) => theme.colors.neutralsPalette.dark};

  ${({theme}) => theme.mediaQueries.tablet} {
    display: initial;
    margin-left: ${({theme, noIcon}) => noIcon ? '0px' : theme.layouts.spacing.s};
  }
`;
const Mark = styled(Body4)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  padding: 0 4px;
  margin-left: 8px;
  display: inline;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;
const StyledScoringSVG = styled(ScoringSVG)`
  margin-right: 4px;
`;

const SolutionMeta = ({
  className,
  didSelectCorrectAnswer,
  didNotAnswer,
  backgroundColor,
  didMark,
  correctAnswerIndex,
  hideAnswerData,
  loadingAnswerData,
  correctPercentageText,
  hideTimeSpent,
  time,
  lastUpdated,
  hideCorrectAnswer,
  score,
  avgScore,
  hideResult,
  scoringRule,
  fullCredit,
  partialCredit
}) => {
  const {bootcamp} = useUserDataContext();
  const {modalDispatch} = useModalContext();
  const onClickScoringType = () => modalDispatch({
    type: 'open',
    component: ({close}) => {
        const scoringImg = {
          plusMinus: PlusMinusScoring,
          zeroOne: ZeroOneScoring,
          rationale: RationaleScoring
        }[scoringRule]
        return (
          <DraggablePopup
            close={close}
            headerContent={
              <div>
                Scoring Example
              </div>
            }
            footerContent={
              <div/>
            }
          >
            <img style={{width: '100%'}} src={scoringImg} draggable={false} />
          </DraggablePopup>
        )
      },
      enableClickClose: true,
      componentProps: {},
      modalContainerStyle: theme => `
        background: transparent;
      `
    });

  const scoreDetail = didNotAnswer ? 'Not Answered' : fullCredit ? 'Full Credit' : partialCredit ? 'Partial Credit' : 'Incorrect';

  return (
  <Container className={className}>
    {!hideResult &&
      <SolutionResultMobile correct={didSelectCorrectAnswer} didNotAnswer={didNotAnswer}>
        {didNotAnswer ? 'Not Answered' : didSelectCorrectAnswer ? 'Correct' : 'Incorrect'}
      </SolutionResultMobile>
    }
    <SolutionStats backgroundColor={backgroundColor}>
      {!hideResult &&
        <SolutionStat isResult correct={didSelectCorrectAnswer}>
          <SolutionResult correct={didSelectCorrectAnswer} didNotAnswer={didNotAnswer}>
            {didNotAnswer ? 'Not Answered' : didSelectCorrectAnswer ? 'Correct' : 'Incorrect'}
            {didMark ? <Mark>marked</Mark> : ''}
          </SolutionResult>
          {!didSelectCorrectAnswer && !hideCorrectAnswer &&
            <SolutionStatBottom showForIncorrectAnswerOnMobile>
              <PencilAltMobile />
              Correct answer<Colon>:</Colon> <strong>{alphabet[correctAnswerIndex]}</strong>
            </SolutionStatBottom>
          }
        </SolutionStat>
      }
      {score && (
        <SolutionStat>
          <SolutionStatTop>
            <Check />
            {!loadingAnswerData &&
              <SolutionStatBottomMobile>
                {scoreDetail}
              </SolutionStatBottomMobile>
            }
            <SolutionStatTopText loading={loadingAnswerData}>{score}</SolutionStatTopText>
          </SolutionStatTop>
          {!loadingAnswerData &&
            <SolutionStatBottom>
              {scoreDetail}
            </SolutionStatBottom>
          }
        </SolutionStat>
      )}
      {!hideAnswerData && avgScore && (
        <SolutionStat>
          <SolutionStatTop>
            <ChartSquareBar />
            {!loadingAnswerData &&
              <SolutionStatBottomMobile>
                Avg Points Scored
              </SolutionStatBottomMobile>
            }
            <SolutionStatTopText loading={loadingAnswerData}>{avgScore}</SolutionStatTopText>
          </SolutionStatTop>
          {!loadingAnswerData &&
            <SolutionStatBottom>
              Avg Points Scored
            </SolutionStatBottom>
          }
        </SolutionStat>
      )}
      {!hideAnswerData && correctPercentageText && (
        <SolutionStat>
          <SolutionStatTop>
            <ChartSquareBar />
            {!loadingAnswerData && !correctPercentageText?.match('Collecting') &&
              <SolutionStatBottomMobile>
                Answered Correctly
              </SolutionStatBottomMobile>
            }
            <SolutionStatTopText loading={loadingAnswerData}>{correctPercentageText}</SolutionStatTopText>
          </SolutionStatTop>
          {!loadingAnswerData && !correctPercentageText?.match('Collecting') &&
            <SolutionStatBottom>
              Answered Correctly
            </SolutionStatBottom>
          }
        </SolutionStat>
      )}
      {
        scoringRule && (
          <SolutionStat onClick={onClickScoringType} style={{cursor: 'pointer'}}>
            <SolutionStatTop>
              <StyledScoringSVG/>
              {scoringRule === 'plusMinus' ? '+ / -' : scoringRule === 'zeroOne' ? '0 / 1' : 'Rationale'} Scoring
            </SolutionStatTop>
            <SolutionStatBottom>
              Scoring Rule
            </SolutionStatBottom>
          </SolutionStat>
        )
      }
      {(!hideTimeSpent) && (
        <SolutionStat>
          <SolutionStatTop>
            <Clock />
            <SolutionStatBottomMobile>
              Time Spent
            </SolutionStatBottomMobile>
            <SolutionStatTopText>
              {time ? moment.utc(time * 1000).format('mm:ss') : ""}
            </SolutionStatTopText>
          </SolutionStatTop>
          <SolutionStatBottom>
            Time Spent
          </SolutionStatBottom>
        </SolutionStat>
      )}
      {!['mcat'].includes(bootcamp) && lastUpdated && (<SolutionStat>
        <SolutionStatTop>
          <Calendar />
          <SolutionStatBottomMobile>
            Version
          </SolutionStatBottomMobile>
          <SolutionStatTopText>
            {new Date().getFullYear()}
          </SolutionStatTopText>
        </SolutionStatTop>
        <SolutionStatBottom>
          Version
        </SolutionStatBottom>
      </SolutionStat>)}
    </SolutionStats>
  </Container>
)};

export default SolutionMeta;