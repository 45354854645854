import React from 'react';
import {logError} from './logger';
import * as Sentry from '@sentry/react'; // TODO set up sentry for app.bootcamp

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logError(error, errorInfo);
    Sentry.captureException(error);
    window.location.pathname = '/not-found';
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
    }

    return this.props.children;
  }
}
