import React from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useContentTypeProgress} from '../../api/progress/contentType';

import Container from '../../pages/PageContainer';
import VideoLessons from '../../components/ContentTypes/VideoLessons';
import {Heading} from '../shared';
import theme from '@bootcamp/shared/src/styles/theme';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;


const NextGenCourse = () => {
  const {config} = useBootcampConfigContext();

  const name = 'Next Gen Strategy Course';
  const filteredClassrooms = config?.classrooms?.find(({route}) => route.match('next-gen-strategy-course'));
  const content = filteredClassrooms?.contentTypes[0].content

  const contentTypeProgress = useContentTypeProgress(filteredClassrooms.contentTypes[0], 'next-gen-strategy-course');
  const data = contentTypeProgress?.['videos-next-gen-strategy-course'] || {};

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading name={name} description={"Learn everything you need to know about the Next Gen NCLEX. At the end, we'll walk through a full Next Gen case together."} />
      <Wrapper>
        <VideoLessons data={data} content={content} themePalette={theme.colors.brandPalette.royal}/>
      </Wrapper>
    </Container>
  );
}

NextGenCourse.propTypes = {};
NextGenCourse.defaultProps = {};

export default NextGenCourse;
