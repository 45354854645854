import {getInObj} from '@bootcamp/shared/src/util';

const defaultCount = {
  learning: 0,
  reviewing: 0,
  mastered: 0,
  total: 0,
};

export function formatChapterTestMastery(testProgressArray) {
  return testProgressArray.reduce((acc, curr) => {
    const masteryItems = getInObj(['data', 'QuestionMasteryByTestId', 'items'], curr);
    if (!masteryItems) return acc;
    const {testId} = curr;
    let accumulator = {...acc};

    masteryItems.forEach(({masteryLevel}) => {
      // accumulate progress by testId
      const currentCountByTest = accumulator.byTest[testId] || defaultCount;

      accumulator.byTest[testId] = {
        ...currentCountByTest,
        [masteryLevel]: currentCountByTest[masteryLevel] + 1,
        total: currentCountByTest.total + 1,
      };

      // accumulate totals
      accumulator.all = {
        ...accumulator.all,
        [masteryLevel]: accumulator.all[masteryLevel] + 1
      };
    });

    return accumulator;
  }, {byTest: {}, all: {...defaultCount}});
}

export function formatChapterMastery({data}) {
  const mastery = data
    && data.QuestionMasteryBySubjectTagId
    && data.QuestionMasteryBySubjectTagId.items;

  if (!mastery) return [];

  return mastery.reduce((acc, {masteryLevel, userIdHashTopicTagId}) => {
    const topicTagId = userIdHashTopicTagId.split('#')[1];

    // accumulate total
    const all = {
      ...acc.all,
      [masteryLevel]: acc.all[masteryLevel] + 1,
      total: acc.all.total + 1,
    };

    // accumulate total for a given topic (chapter)
    const chapterTotal = {
      [topicTagId]: {
        ...(acc[topicTagId] ? acc[topicTagId] : defaultCount),
        [masteryLevel]: ((acc[topicTagId] && acc[topicTagId][masteryLevel]) || 0) + 1,
        total: (acc[topicTagId] && acc[topicTagId].total) ? acc[topicTagId].total + 1 : 1,
      }
    };

    return {
      ...acc,
      ...chapterTotal,
      all,
    };
  }, {
    all: defaultCount
  });
}

export function formatQuestionCount(counts) {
  return counts.reduce((acc, {id, questionCount}) => ({
    ...acc,
    [id]: questionCount,
    all: acc.all + questionCount,
  }), {all: 0});
}
