import {anatomyConfigReducer} from './msb';
import ChemistryConfig from './chemistry';
import DATConfig from './json/dat.json';
import {colors} from '../styles/theme';

const generalChemistryClassroom = {...DATConfig.classrooms.find(({name}) => name === 'General Chemistry')};
const generalChemistryClassroomContentTypes = generalChemistryClassroom.contentTypes.filter(({name}) => !['Practice Tests', 'Plus Tests', 'Strategy'].includes(name));

generalChemistryClassroom.contentTypes = [
  generalChemistryClassroomContentTypes[1], // Mike's videos
  generalChemistryClassroomContentTypes[0], // QBs
  { // Worksheets
    ...generalChemistryClassroomContentTypes[2],
    content: [{
      name: 'Downloads',
      content: generalChemistryClassroomContentTypes[2]?.content[0].content?.filter(({name}) => !['General Chemistry Lab Techniques'].includes(name))
    }]
  },
];

generalChemistryClassroom.classroomDescription = 'Everything around us is made of chemicals. Let’s learn how we interact with it. Start studying with Dr. Mike’s Videos, and progress on to the Question Banks.';
generalChemistryClassroom.icon = 'icons/chemistry/gc.png';

const ocReactionBites = DATConfig.classrooms.find(({name}) => name === 'Organic Chemistry')?.contentTypes.find(({name}) => name === 'Reaction Bites');
const organicChemistryClassrooms = ChemistryConfig.reduce((acc, {name: classroomName, route: classroomRoute, chapters}) => {
    const {videoBanks, qbanks} = chapters.reduce((acc, {name, route, questionBankIds, videoLessonId}, chapterIndex) => {
      return {
        videoBanks: videoLessonId ? [...acc.videoBanks, {id: videoLessonId, classroomName, route, name}] : acc.videoBanks,
        qbanks: questionBankIds ? [...acc.qbanks, ...questionBankIds.map(id => ({id, name, route: `bank-${chapterIndex+1}`}))] : acc.qbanks
      }
    }, {videoBanks: [], qbanks: []})

    const qbanksTab = acc[1];
    qbanksTab?.content[0].content.push(...qbanks);
    const videosTab = acc[0];
    videosTab?.content[0].content.push(...videoBanks);

    return acc;
  }, [
    {
      name: 'Mike\'s Videos',
      route: 'videos',
      content: [
        {
          name: 'Videos',
          type: 'Course',
          content: [
          ]
        }
      ]
    },
    {
      name: `Question Banks`,
      route: 'qbanks',
      type: 'Question Bank',
      content: [
        {
          name: 'Question Banks',
          content: [
          ]
        }
      ]
    },
    ocReactionBites,
    {
      name: 'Worksheets',
      // icon: <StarIcon />,
      route: 'downloads',
      content: [
        {
        name: 'Downloads',
          content: [
            {
              "name": "Bootcamp OC Reactions Sheet",
              description: "A big part of Organic Chemistry is knowing the reactions. Download the Bootcamp OC Reactions Sheet below, study it, and practice with the Reaction Qbank!",
              "route": "https://drive.google.com/file/d/15XmSS5e8_g5F8EBnJ3b4twekmq_J2Ktp/view",
            },
          ]
        }
      ]
    }
  ]
);


const config = {
  classrooms: [
    generalChemistryClassroom,
    {
      name: 'Organic Chemistry',
      tagIds: ['13925cee-9aa5-4ddd-9a82-7e65274ef211', '1a0ba62c-1c67-45ef-a811-245a6ddcedd4'],
      route: 'organic-chemistry',
      themePalette: colors.brandPalette.orange,
      classroomDescription: 'Dr. Mike is going to make organic chemistry easy. Study Dr. Mike’s Videos and move on to the Question Banks to ace this class',
      questionCount: 328,
      icon: 'icons/chemistry/oc.png',
      educators: [
        {
          firstName: 'Dr. Mike',
          avatar: 'images/mike.png',
        }
      ],
      contentTypes: organicChemistryClassrooms
    },
  ],
  studyTools: [
    {
      name: 'Bookmarks',
      route: 'bookmarks',
      themePalette: colors.brandPalette.royal,
      showOnHome: true,
    },
  ],
  meta: {
    siteTitle: 'Chemistry Bootcamp',
    subjectIds: ['1a0ba62c-1c67-45ef-a811-245a6ddcedd4', '13925cee-9aa5-4ddd-9a82-7e65274ef211', 'fa72381b-e2c2-4e46-a113-135220a010b6'],
  }
}

export default config;
