// extendee ID as key, extension ID as value
const extensionPriceIdMap = {
  'price_1IbUpzD2xOfNJKfxz8UQVMds': 'price_1Io8o6D2xOfNJKfxUab2jaY8', // OAT DEV PRO
  'price_1IbUnqD2xOfNJKfxJ2oTennj': 'price_1Io8pdD2xOfNJKfx0m0uISMo', // OAT DEV PLUS,
  'prod_JDXsqjb71yNsHa': 'price_1MMvYFD2xOfNJKfxxkMeghna', // OLD OAT PRO
  'price_1Ib6oYD2xOfNJKfxKFPJaAvV': 'price_1MMvYFD2xOfNJKfxxkMeghna', // OLD OAT PRO
  'price_1IoCt6D2xOfNJKfxdkNKXgVs': 'price_1MMvYFD2xOfNJKfxxkMeghna', // OAT PRO
  'price_1Lb627D2xOfNJKfxuBRMPinN': 'price_1MMvYFD2xOfNJKfxxkMeghna', // new OAT PRO,
  'price_1MMvKWD2xOfNJKfxORh5JBpI': 'price_1MMvYFD2xOfNJKfxxkMeghna', // new OAT PRO,
  'prod_JDXvMXPfUyDpWv': 'price_1MMvZ8D2xOfNJKfxDt2WkVwL', // OLD OAT PLUS
  'price_1Ib6q8D2xOfNJKfxn9tgiNGh': 'price_1MMvZ8D2xOfNJKfxDt2WkVwL', // OLD OAT PLUS
  'price_1IoCt6D2xOfNJKfxHZ7f7y0K': 'price_1MMvZ8D2xOfNJKfxDt2WkVwL', // OAT PLUS
  'price_1Lb63VD2xOfNJKfxJqtIyXFc': 'price_1MMvZ8D2xOfNJKfxDt2WkVwL', // new OAT PLUS
  'price_1MMvN3D2xOfNJKfxJAYuMdD2': 'price_1MMvZ8D2xOfNJKfxDt2WkVwL', // new OAT PLUS
  'price_1IpbcED2xOfNJKfxFuJEDaUO': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE Plus
  'price_1IpbcED2xOfNJKfxDRJn9koY': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE Pro
  'price_1KlFrlD2xOfNJKfxStcmZvNF': 'price_1OkByWD2xOfNJKfxL89fpy82', // inbde 3mo
  'price_1KlFttD2xOfNJKfxL8VYkCpf': 'price_1OkByWD2xOfNJKfxL89fpy82', // inbde 6mo
  'price_1Lb6JbD2xOfNJKfxCFwYVkDD': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1Lb6KmD2xOfNJKfx6wje4VAB': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1Lb6LeD2xOfNJKfxePOGYQYL': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1MMuaxD2xOfNJKfx57v21M60': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1MMuZxD2xOfNJKfxGEIIbtvG': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1MMuYzD2xOfNJKfxgqP9nIck': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1OkBx9D2xOfNJKfxPpkFB48q': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1MMyiTD2xOfNJKfxAqMtXKVI': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1OkBvcD2xOfNJKfxtdYOz8qJ': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1OkBxtD2xOfNJKfxrf6AqXzY': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1J50JYD2xOfNJKfxBFkLvXrb': 'price_1OkByWD2xOfNJKfxL89fpy82', // INBDE
  'price_1MMyaAD2xOfNJKfxTrEOnXUv': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // DAT Plus
  'price_1OoUqzD2xOfNJKfxyOTxcCth': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // DAT Plus
  'price_1MMyUeD2xOfNJKfxJZ3R7PdE': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // DAT Plus
  'price_1JpIi9D2xOfNJKfx5Q7ARaMs': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // DAT Plus
  'price_1Lb5tnD2xOfNJKfxxQdxE2Cn': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // new DAT PLUS
  'price_1Lb5wiD2xOfNJKfxvWJ8eEgX': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // new DAT PLUS
  'price_1MMySoD2xOfNJKfxxTkAFKDv#price_1MMyeaD2xOfNJKfxVbsDTVb6': 'price_1OoV3AD2xOfNJKfxI3T4ANXc',
  'price_1Lb5sSD2xOfNJKfxI7ZFpphf#price_1LkYULD2xOfNJKfxYruyY38X': 'price_1OoV3AD2xOfNJKfxI3T4ANXc', // new DAT PLUS
  'price_1OoUmjD2xOfNJKfxNneSpdRl': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // DAT pro
  'price_1Lb5veD2xOfNJKfxkoYcYZ3G': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // DAT pro
  'price_1MMySoD2xOfNJKfxxTkAFKDv': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // DAT pro
  'price_1JpIi9D2xOfNJKfxp7n8Nnnz': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // DAT Pro,
  'price_1Lb5sSD2xOfNJKfxI7ZFpphf': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // new DAT PRO,
  'price_1JpIi9D2xOfNJKfxMcMyQUsa': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // new DAT PRO,
  'price_1MMyZYD2xOfNJKfxgUY9LgJg': 'price_1OoV1fD2xOfNJKfxOkPcPdRK', // new DAT PRO,
  'price_1Lb6QbD2xOfNJKfx6ljBWqGF': 'price_1MMtjSD2xOfNJKfx1m1w0v1L', // DSB 1 year
  'price_1JMeTgD2xOfNJKfxUC0tvtZ6': 'price_1MMtjSD2xOfNJKfx1m1w0v1L', // NEW DSB 1 year
  'price_1Kj2rJD2xOfNJKfxIn4A2v3T': 'price_1PRJuiD2xOfNJKfxDCAMMdJx', // MSB 2 year (archived)
  'price_1LaQIOD2xOfNJKfxuR4PaHhE': 'price_1PRJuiD2xOfNJKfxDCAMMdJx', // MSB 2 year (archived)
  'price_1LaQEsD2xOfNJKfxH7q1jSMU': 'price_1PRJuiD2xOfNJKfxDCAMMdJx', // MSB 1 year
  'price_1JMeSRD2xOfNJKfx44i5zdrc': 'price_1PRJuiD2xOfNJKfxDCAMMdJx', // MSB 1 year
  'price_1MMu4SD2xOfNJKfx3n9BAaui': 'price_1PRJuiD2xOfNJKfxDCAMMdJx', // MSB 1 year
  'price_1MMu5ND2xOfNJKfxpZktiHVS': 'price_1PRJuiD2xOfNJKfxDCAMMdJx', // MSB 2 year
  'price_1LaPZkD2xOfNJKfxmEdPKkny': 'price_1MMultD2xOfNJKfxFaMYXFQQ', // AB 2 year
  'price_1Lb6UbD2xOfNJKfxrWsq4gQh': 'price_1MMutRD2xOfNJKfx1UWAZ2wc', // CB 2 year
  'price_1Lb6TwD2xOfNJKfx2uguASCT': 'price_1MMusVD2xOfNJKfxuQ3tOInm', // CB 1 year
  'price_1MMtAeD2xOfNJKfxYn3iMiBD': 'price_1N8MR9D2xOfNJKfx1VGXW2wE', // NCLEX 1 mo
  'price_1MMtErD2xOfNJKfxEFchnNp1': 'price_1N8MR9D2xOfNJKfx1VGXW2wE', // NCLEX 3 mo
  'price_1MMtFeD2xOfNJKfxhgbhb33l': 'price_1N8MR9D2xOfNJKfx1VGXW2wE', // NCLEX 6 mo
  'price_1N8MPkD2xOfNJKfx6mHFY2IK': 'price_1N8MR9D2xOfNJKfx1VGXW2wE', // NCLEX 1 mo
  'price_1N8MSDD2xOfNJKfxOykEYToY': 'price_1N8MR9D2xOfNJKfx1VGXW2wE', // NCLEX 3 mo
  'price_1N8MT6D2xOfNJKfxbRHLzQ4X': 'price_1N8MR9D2xOfNJKfx1VGXW2wE', // NCLEX 6 mo
};

export const extensionIdsByBootcamp = {
  'dat': 'price_1OoV1fD2xOfNJKfxOkPcPdRK',
  'inbde': 'price_1OkByWD2xOfNJKfxL89fpy82',
  'dental-school': 'price_1MMtjSD2xOfNJKfx1m1w0v1L',
  'med-school': 'price_1PRJuiD2xOfNJKfxDCAMMdJx',
  'nclex': 'price_1N8MR9D2xOfNJKfx1VGXW2wE',
  'oat': 'price_1Io8o6D2xOfNJKfxUab2jaY8',
  'chemistry': 'price_1MMusVD2xOfNJKfxuQ3tOInm',
};

export default extensionPriceIdMap;
