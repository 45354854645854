import React from 'react';
import {Route as BasicRoute, Redirect} from 'react-router-dom';
import {UpgradeRoute} from '../wrappers';
import {SideBarRoute, Route} from './wrappers';

import NotFound from '../../../bootcamps/pages/NotFound';
import AccountPage from '../../../bootcamps/pages/AccountPage/updated';
import FAQPage from '../../../bootcamps/pages/FAQ';
import CheatSheetPage from '../../../bootcamps/pages/CheatSheets';
import Authentication from '../../../bootcamps/pages/Authentication';
import PastTestResults from '../../../bootcamps/pages/PastTestResults';
import CustomTests from '../../../bootcamps/pages/CustomTests';
import Classroom from '../../../bootcamps/pages/Classroom';
import OATHome from '../../../bootcamps/oat/Home';
import DATHome from '../../../bootcamps/dat/Home';
import DATDWU from '../../../bootcamps/pages/WarmupTest';
import INBDEHome from '../../../bootcamps/inbde/Home';
import Step1Home from '../../../bootcamps/step1/Home';
import DSBHome from '../../../bootcamps/dsb/Home';
import MCATHome from '../../../bootcamps/mcat/Home';
import MSBHome from '../../../bootcamps/msb/Home';
import MSBSelfAssessments from '../../../bootcamps/msb/SelfAssessments';
import INBDESimulationExams from '../../../bootcamps/inbde/SimulationExams';
import NCLEXHome from '../../../bootcamps/nclex/Home';
import NCLEXStandalone from '../../../bootcamps/nclex/StandaloneQuestions';
import NCLEXReadinessExams from '../../../bootcamps/nclex/ReadinessExams';
import MCATPassages from '../../../bootcamps/mcat/Passages';
import NCLEXNextGenCases from '../../../bootcamps/nclex/NextGenCases';
import NCLEXNextGenCourse from '../../../bootcamps/nclex/NextGenCourse';
import NCLEXCheatSheets from '../../../bootcamps/nclex/CheatSheets';
import AnatomyHome from '../../../bootcamps/anatomy/Home';
import ChemistryHome from '../../../bootcamps/chemistry/Home';
import ReviewBanks from '../../../bootcamps/pages/ReviewBanks';
import StudyGuides from '../../../bootcamps/pages/StudyGuides';
import ExplorePage from '../../../bootcamps/pages/ExplorePage';
import TestReview from '../../../bootcamps/pages/TestReview';
import CustomTest from '../../../bootcamps/pages/CustomTest';
import CustomTestReview from '../../../bootcamps/pages/CustomTestReview';
import QuickReview from '../../../bootcamps/pages/QuickReview';
import BitesReview from '../../../bootcamps/pages/QuickReview/Bites';
import BoardStyleReview from '../../../bootcamps/pages/QuickReview/BoardStyle';
import GrossAnatomyReview from '../../../bootcamps/pages/QuickReview/GrossAnatomy';
import MasteryReview from '../../../bootcamps/pages/MasteryReview';
import QuestionSearch from '../../../bootcamps/pages/QuestionSearch';
import Content from '../../../bootcamps/pages/Content';
import FullLengthTestDirectory from '../../../bootcamps/pages/FullLengthTestDirectory';
import Bookmarks, {BookmarkQuestionBank} from '../../../bootcamps/pages/Bookmarks';
import Checkout from '../../../bootcamps/pages/Checkout';
import CheckoutRedirect from '../../../bootcamps/pages/CheckoutRedirect';
import ThankYou from '../../../bootcamps/pages/ThankYou';
import DATPerformance from '../../../bootcamps/dat/Performance';
import Performance from '../../../bootcamps/pages/Performance';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import {colors} from '@bootcamp/shared/src/styles/theme';

// These routes are shared across all Bootcamps.
const sharedRoutes = [
  <Route
    key={'auth-route'}
    path={"/auth"}
    component={Authentication}
    themePalette={colors.brandPalette.royal}
    bootcampRequired={false}
    exact
  />,
]

const bootcampRoutes = [
  <Route
    key={'root-route'}
    path={"/"}
    component={props => <Redirect to={'/auth'}/>}
    themePalette={colors.brandPalette.royal}
    exact
  />,
  <Route
    key={'account-route'}
    allow={['Free']}
    path={["/account", "/account/:page(profile|memberships|billing|redeem-code)"]}
    themePalette={colors.brandPalette.royal}
    component={({match}) => {
      const {searchUserInteractions, DEFAULT_USER_ID, interactions} = useUserDataContext();
      const recentBootcamp = interactions?.find(interaction => interaction.id === `${DEFAULT_USER_ID}-recent-bootcamp`)?.value || '';
      if (recentBootcamp) {
        window.location.pathname = `/${recentBootcamp}/account${match.params.page ? `/${match.params.page}` : ''}`;
      }
      return <div />;
    }}
    exact
  />,
  <Route
    key={'upgrade-redirect'}
    path={["/upgrade", "/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex|mcat)/upgrade"]}
    component={props => {
      // TODO update this to be bootcamp specific
      const bootcamp = props?.match?.params?.bootcamp;

      if (bootcamp === 'mcat') {
        window.location.replace('https://bootcamp.com/mcat');
      } else {
        window.location.replace(bootcamp ? `https://bootcamp.com/${bootcamp}/upgrade` : `https://bootcamp.com`);
      }

      return null;
    }}
    themePalette={colors.brandPalette.royal}
    exact
  />,
  <SideBarRoute
    key={'account-route'}
    allow={['Free']}
    path={["/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/account", "/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/account/:page(profile|memberships|billing|redeem-code)"]}
    themePalette={colors.brandPalette.royal}
    component={AccountPage}
    exact
  />,
  <SideBarRoute
    key={'faq-route'}
    allow={['Free']}
    path={["/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/faqs"]}
    themePalette={colors.brandPalette.royal}
    component={FAQPage}
    exact
  />,
  <SideBarRoute
    key={'nclex-cheat-sheet-route'}
    path={"/:bootcamp(nclex)/cheat-sheets"}
    allow={['NCLEXBootcamp', 'FreeTrial', 'Admin', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={NCLEXCheatSheets}
    exact
    noFade
  />,
  <SideBarRoute
    key={'inbde-cheat-sheet-route'}
    path={"/:bootcamp(inbde)/cheat-sheets"}
    allow={['INBDEBootcamp', 'FreeTrial', 'Admin', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={CheatSheetPage}
    exact
    noFade
  />,
  <Route
    key={'custom-test-route'}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/custom-test"}
    allow={['NCLEXBootcamp', 'INBDEBootcamp', 'MedSchoolBootcamp', 'AnatomyBootcamp', 'FreeTrial', 'Admin', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={CustomTest}
    exact
    noFade
  />,
  <SideBarRoute
    key={'custom-test-review-route'}
    path={"/:bootcamp(nclex|med-school|inbde|anatomy)/previous-tests/review"}
    allow={['NCLEXBootcamp', 'INBDEBootcamp', 'MedSchoolBootcamp', 'FreeTrial', 'Admin', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={CustomTestReview}
    exact
    noFade
  />,
  <SideBarRoute
    key={'self-assessment-review-route'}
    path={"/:bootcamp(med-school)/self-assessment/review"}
    allow={['MedSchoolBootcamp', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={CustomTestReview}
    exact
    noFade
  />,
  <SideBarRoute
    key={'simulation-exam-review-route'}
    path={"/:bootcamp(inbde)/simulation-exam/review"}
    allow={['INBDEBootcamp', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={CustomTestReview}
    exact
    noFade
  />,
  <SideBarRoute
    key={'nclex-readiness-exam-review-route'}
    path={"/:bootcamp(nclex)/next-gen-readiness-exams/review"}
    allow={['NCLEXBootcamp', 'Free']}
    themePalette={colors.brandPalette.royal}
    component={CustomTestReview}
    exact
    noFade
  />,
  <SideBarRoute
    key={'mcat-practice-test-review-route'}
    path={"/:bootcamp(mcat)/practice-tests/review"}
    allow={['Admin']}
    themePalette={colors.brandPalette.royal}
    component={CustomTestReview}
    exact
    noFade
  />,
  <Route
    key={'quick-review-bites'}
    path={"/:bootcamp(med-school)/:reviewType(quick-study)/bites"}
    allow={['Free', 'Admin']}
    themePalette={colors.brandPalette.royal}
    component={BitesReview}
    exact
    noFade
  />,
  <Route
    key={'quick-review-board-style'}
    path={"/:bootcamp(med-school)/:reviewType(quick-study)/board"}
    allow={['Free', 'Admin']}
    themePalette={colors.brandPalette.royal}
    component={BoardStyleReview}
    exact
    noFade
  />,
  <Route
    key={'quick-review-gross-anatomy'}
    path={"/:bootcamp(med-school)/:reviewType(quick-study)/anatomy"}
    allow={['Free', 'Admin']}
    themePalette={colors.brandPalette.royal}
    component={GrossAnatomyReview}
    exact
    noFade
  />,
  <Route
    key={'quick-study-route'}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/quick-study"}
    allow={['Free', 'Admin']}
    themePalette={colors.brandPalette.royal}
    component={QuickReview}
    exact
    noFade
  />,
  <SideBarRoute
    key={'explore-route'}
    // path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/explore"}
    path={"/:bootcamp(med-school)/explore"}
    allow={['Free', 'Admin']}
    themePalette={colors.brandPalette.royal}
    component={ExplorePage}
    exact
    noFade
  />,
  <SideBarRoute
    key={'full-length-test-directory-route'}
    path={["/:bootcamp(dat|oat|inbde|step-1)/(full-length|full-length-plus)"]}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={FullLengthTestDirectory}
    withoutBorder
    exact
    noFade
  />,
  <Route
    key={'full-length-test-content-route'}
    path={["/:bootcamp(dat|oat|inbde|step-1)/full-length/:contentSlug"]}
    allow={['DATBootcamp', 'OATBootcamp', 'INBDEBootcamp']}
    themePalette={colors.brandPalette.royal}
    component={Content}
    exact
    noFade
  />,
  <SideBarRoute
    key={'msb-self-assessments-route'}
    path={["/med-school/self-assessment"]}
    allow={['Free']}
    component={({...props}) => <MSBSelfAssessments key={`self-assessment`} {...props} />}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <SideBarRoute
    key={'inbde-simulation-exams-route'}
    path={["/inbde/simulation-exam"]}
    allow={['Free']}
    component={({...props}) => <INBDESimulationExams key={`self-assessment`} {...props} />}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <Route
    key={'content-route'}
    path={['/:bootcamp(med-school)/:classroom(self-assessment)/:contentType']}
    allow={['MedSchoolBootcamp']}
    component={Content}
    exact
    noFade
  />,

  <Route
    key={'search-route'}
    path={["/:bootcamp(dat|oat|inbde)/search"]}
    allow={['DATBootcamp', 'OATBootcamp', 'INBDEBootcamp']}
    themePalette={colors.brandPalette.royal}
    component={QuestionSearch}
    exact
    noFade
  />,
  <SideBarRoute
    key={'anatomy-home-route'}
    path={"/:bootcamp(anatomy)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={AnatomyHome}
    exact
  />,
  <SideBarRoute
    key={'chemistry-home-route'}
    path={"/:bootcamp(chemistry)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={ChemistryHome}
    exact
    />,
  <SideBarRoute
    key={'oat-home-route'}
    path={"/:bootcamp(oat)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={OATHome}
    exact
  />,
  <SideBarRoute
    key={'oat-home-route'}
    path={"/:bootcamp(dat)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={DATHome}
    exact
  />,
  <Route
    key={'dat-dwu-route'}
    path={"/:bootcamp(dat)/warmup"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={DATDWU}
    exact
  />,
  <SideBarRoute
    key={'inbde-home-route'}
    path={"/:bootcamp(inbde)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={INBDEHome}
    exact
  />,
  <SideBarRoute
    key={'step-1-home-route'}
    path={"/:bootcamp(step-1)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={Step1Home}
    exact
  />,
<SideBarRoute
  key={'dental-school-1-home-route'}
  path={"/:bootcamp(dental-school)"}
  allow={['Free']}
  themePalette={colors.brandPalette.royal}
  component={DSBHome}
  exact
/>,
<SideBarRoute
  key={'mcat-home-route'}
  path={"/:bootcamp(mcat)"}
  allow={['Admin']}
  themePalette={colors.brandPalette.royal}
  component={MCATHome}
  exact
/>,
  <SideBarRoute
    key={'med-school-1-home-route'}
    path={"/:bootcamp(med-school)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={MSBHome}
    exact
  />,
  <SideBarRoute
    key={'nclex1-home-route'}
    path={"/:bootcamp(nclex)"}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={NCLEXHome}
    exact
  />,
  <UpgradeRoute
    key={'app-checkout-route'}
    path={["/:bootcamp/checkout", "/checkout"]}
    themePalette={colors.brandPalette.royal}
    component={Checkout}
    allow={['Free']}
    hideMenu
    exact
    withHomeButton
    fullHeight={false}
  />,
  <Route
    key={'app-stripe-checkout-route'}
    path={["/:bootcamp/stripe-checkout", "/stripe-checkout"]}
    themePalette={colors.brandPalette.royal}
    component={CheckoutRedirect}
    allow={['Free']}
  />,
  <SideBarRoute
    key={'app-thank-you'}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/thank-you"}
    themePalette={colors.brandPalette.royal}
    component={ThankYou}
    allow={['Free']}
    exact
  />,
  <Route
    key={'mastery-review-route'}
    allow={['DATBootcamp', 'OATBootcamp', 'INBDEBootcamp', 'Step1Bootcamp', 'MedSchoolBootcamp', 'DentalSchoolBootcamp', 'AnatomyBootcamp', 'ChemistryBootcamp', 'FreeTrial', 'Admin']}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/tagged-questions/:masteryLevel"}
    themePalette={colors.brandPalette.violet}
    component={MasteryReview}
    noFade
    exact
  />,
  <Route
    key={'classroom-mastery-route'}
    allow={['DATBootcamp', 'OATBootcamp', 'INBDEBootcamp', 'Step1Bootcamp', 'MedSchoolBootcamp', 'DentalSchoolBootcamp', 'AnatomyBootcamp', 'ChemistryBootcamp', 'FreeTrial', 'Admin']}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/:classroom/tagged-questions/:masteryLevel"}
    themePalette={colors.brandPalette.violet}
    component={MasteryReview}
    noFade
    exact
  />,
  <SideBarRoute
    key={'past-test-results-route'}
    path={["/:bootcamp(dat|oat|inbde)/past-test-results"]}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={PastTestResults}
    exact
    noFade
  />,
  <SideBarRoute
    key={'performance-route'}
    path={["/:bootcamp(inbde|med-school|nclex)/performance"]}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={Performance}
    exact
    noFade
  />,
  <SideBarRoute
    key={'performance-route'}
    path={["/:bootcamp(dat)/performance"]}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={DATPerformance}
    exact
    noFade
  />,
  <SideBarRoute
    key={'custom-tests-route'}
    path={["/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/previous-tests"]}
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={CustomTests}
    exact
    noFade
  />,
  <SideBarRoute
    key={'bookmarks-route'}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/bookmarks"}
    themePalette={colors.brandPalette.royal}
    allow={['Free']}
    component={Bookmarks}
    exact
    withoutBorder
    noFade
  />,
  <SideBarRoute
    key={'tagged-questions-route'}
    path={"/:bootcamp(dat|oat|step-1|med-school|inbde|dental-school|anatomy|chemistry|nclex)/tagged-questions"}
    themePalette={colors.brandPalette.royal}
    allow={['DATBootcamp', 'OATBootcamp', 'INBDEBootcamp', 'Step1Bootcamp', 'MedSchoolBootcamp', 'DentalSchoolBootcamp', 'AnatomyBootcamp', 'ChemistryBootcamp', 'NCLEXBootcamp', 'FreeTrial']}
    component={ReviewBanks}
    exact
    noFade
  />,
  <SideBarRoute
    key={'study-guides-route'}
    path={"/:bootcamp(dat|oat|inbde)/(study-guides|study-guide)"}
    themePalette={colors.brandPalette.royal}
    allow={['Free']}
    component={StudyGuides}
    exact
    noFade
  />,
  <Route
    key={'bookmark-subject-route'}
    path={"/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/bookmarks/:classroom"}
    themePalette={colors.brandPalette.royal}
    allow={['Free']}
    component={BookmarkQuestionBank}
    exact
    noFade
  />,
  <Route
    exact
    key={'test-review-route'}
    path="/:bootcamp(dat|oat|inbde)/test-review"
    allow={['Free']}
    themePalette={colors.brandPalette.royal}
    component={({match, location}) => {
      const urlParams = new URLSearchParams(location.search);
      const id = urlParams.get('id');
      const testProgressId = urlParams.get('testProgressId');
      return <TestReview match={{...match, params: {...match.params, id, testProgressId}}}/>
    }}
    noFade
  />,
  <SideBarRoute
    key={'nclex-next-gen-standalone-route'}
    path={["/nclex/next-gen-standalone"]}
    allow={['Free']}
    component={({...props}) => <NCLEXStandalone key={`classroom-${props.match.params.classroom}`} {...props}/>}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <SideBarRoute
    key={'nclex-next-gen-readiness-exam-route'}
    path={["/nclex/next-gen-readiness-exams"]}
    allow={['Free']}
    component={({...props}) => <NCLEXReadinessExams key={`classroom-${props.match.params.classroom}`} {...props}/>}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <SideBarRoute
    key={'mcat-cars-passages-route'}
    path={["/mcat/cars-passages"]}
    allow={['Admin']}
    component={({...props}) => <MCATPassages key={`classroom-${props.match.params.classroom}`} {...props}/>}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <SideBarRoute
    key={'nclex-next-gen-cases-route'}
    path={["/nclex/next-gen-cases"]}
    allow={['Free']}
    component={({ ...props }) => <NCLEXNextGenCases key={`classroom-${props.match.params.classroom}`} {...props} />}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <SideBarRoute
    key={'nclex-next-gen-course-route'}
    path={["/nclex/next-gen-strategy-course/videos"]}
    allow={['Free']}
    component={({ ...props }) => <NCLEXNextGenCourse key={`classroom-${props.match.params.classroom}`} {...props} />}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,
  <SideBarRoute
    key={'classroom-route'}
    path={["/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/:classroom", "/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/:classroom/:contentType", "/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry|nclex)/:classroom/:contentType/(progress|plus)"]}
    allow={['Free']}
    component={({...props}) => <Classroom key={`classroom-${props.match.params.classroom}`} {...props}/>}
    withoutPadding
    withoutBorder
    lockAtDepth={1}
    exact
  />,

  <Route
    key={'plus-content-route'}
    path={['/:bootcamp(dat|oat|inbde|step-1)/:classroom/:contentType/plus/:contentSlug']}
    allow={['PlusPackAccess']}
    component={Content}
    exact
    noFade
  />,
  <Route
    key={'bio-bites-route'}
    path={['/:bootcamp(dat|oat|inbde)/:classroom/:contentType/bio-bites/:contentSlug']}
    allow={['PlusPackAccess']}
    component={Content}
    exact
    noFade
  />,
  <Route
    key={'ap-bites-route'}
    path={['/:bootcamp(anatomy)/:classroom/:contentType/bites/:contentSlug']}
    allow={['PlusPackAccess']}
    component={Content}
    exact
    noFade
  />,
  <Route
  key={'content-route'}
  path={['/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/:classroom/:contentType/progress/:contentSlug', '/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/:classroom/:contentType/:contentSlug', '/:bootcamp(dat|oat|inbde|step-1|med-school|dental-school|anatomy|chemistry)/:classroom/:contentType/:contentParent/:contentSlug']}
  allow={['DATBootcamp', 'OATBootcamp', 'INBDEBootcamp', 'Step1Bootcamp', 'MedSchoolBootcamp', 'DentalSchoolBootcamp', 'AnatomyBootcamp', 'ChemistryBootcamp', 'FreeTrial']}
  component={Content}
  exact
  noFade
  />,
  <Route
  key={'nclex-strategy-route'}
  path={['/:bootcamp(nclex)/:classroom(next-gen-strategy-course)/:contentType(videos)/:contentSlug']}
  allow={['Free']}
  component={Content}
  exact
  noFade
  />,
  <Route
    key={'nclex-content-route'}
    path={['/:bootcamp(nclex)/:classroom/:contentType/:contentSlug']}
    allow={['NCLEXBootcamp']}
    component={Content}
    exact
    noFade
  />,
  <Route
    key={'mcat-content-route'}
    path={['/:bootcamp(mcat)/:classroom(cars-passages)/:contentSlug']}
    allow={['Admin']}
    component={Content}
    exact
    noFade
  />,
  <BasicRoute
    key={'catchall-route'}
    component={NotFound}
  />
];

const allRoutes = [...sharedRoutes, bootcampRoutes];
export default allRoutes;
