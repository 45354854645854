export const listQuestionBases = `query ListQuestionBases(
  $filter: ModelQuestionBaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestionBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      latestRevisions(limit: 2000, sortDirection: ASC) {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
        }
      }
      tags {
        items {
          id
          tag {
            id
            test
            subject
            topic
            contentType
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const getActivationCode = `query GetActivationCode($id: ID!) {
  getActivationCode(id: $id) {
    id
    redeemedAt
    groups
    duration
    priceId
    membershipId
    cohort {
      id
      bootcamp
      name
      universityId
    }
  }
}
`;

export const getTest = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        index
        testBlockConnectionTestBlockId
        testBlock {
          id
          type
          title
          blockConnections(limit: 5) {
            items {
              test {
                id
                config {
                  title
                }
              }
            }
          }
          components {
            index
            renderType
            contents
          }
          questionConnections(limit: 5000) {
            items {
              id
              index
              question {
                id
                createdAt
                updatedAt
                status
                latestRevisions(limit: 1, sortDirection: DESC) {
                  items {
                    id
                    questionComponents {
                      index
                      renderType
                      contents
                    }
                    createdAt
                  }
                }
                tags {
                  items {
                    id
                    tag {
                      id
                      test
                      subject
                      system
                      topic
                      contentType
                    }
                  }
                }
              }
              pinnedRevisionId
            }
            nextToken
          }
        }
      }
      nextToken
    }
    createdAt
    config {
      title
      type
      timeLimit
    }
    groupsCanRead
  }
}
`;

export const getTestWithSearchString = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        id
        index
        testBlock {
          id
          type
          title
          components {
            index
            renderType
            contents
          }
          questionConnections(limit: 5000) {
            items {
              id
              index
              question {
                id
                createdAt
                searchString
                status
                latestRevisions(limit: 1, sortDirection: DESC) {
                  items {
                    id
                    questionComponents {
                      index
                      renderType
                      contents
                    }
                    createdAt
                  }
                }
                tags {
                  items {
                    id
                    tag {
                      id
                      test
                      subject
                      topic
                      contentType
                    }
                  }
                  nextToken
                }
              }
              pinnedRevisionId
            }
            nextToken
          }
        }
      }
      nextToken
    }
    createdAt
    config {
      title
      type
      timeLimit
      questionCount
    }
    groupsCanRead
  }
}
`;

export const getTestConfig = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    config {
      questionCount
      title
    }
  }
}
`;

export const getTestTile = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    createdAt
    config {
      title
      type
      timeLimit
    }
    blockConnections(limit: 2000) {
      items {
        testBlockConnectionTestBlockId
        index
        testBlock {
          id
          type
          title
        }
      }
    }
  }
}
`;

export const getTestFromCache = `query GetTestFromCache($id: ID!) {
  getTestFromCache(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        id
        index
        testBlock {
          id
          type
          title
          components {
            index
            renderType
            contents
          }
          questionConnections(limit: 5000) {
            items {
              id
              index
              question {
                id
                oldId
                createdAt
                status
                questionRevisions(limit: 5000) {
                  items {
                    id
                    questionComponents {
                      index
                      renderType
                      contents
                    }
                    scorePossible
                    createdAt
                  }
                }
                tags {
                  items {
                    id
                    tag {
                      id
                      test
                      subject
                      topic
                      contentType
                    }
                  }
                  nextToken
                }
              }
              pinnedRevisionId
            }
            nextToken
          }
        }
      }
      nextToken
    }
    createdAt
    config {
      title
      type
      timeLimit
    }
    groupsCanRead
  }
}
`;

export const getTestBlock = `query GetTestBlock($id: ID!) {
  getTestBlock(id: $id) {
    id
    type
    components {
      index
      renderType
      contents
    }
  }
}
`;
export const getTestBlockWithQuestionIds = `query GetTestBlock($id: ID!) {
  getTestBlock(id: $id) {
    id
    type
    components {
      index
      renderType
      contents
    }
    questionConnections(limit: 5000) {
      items {
        index
        question {
          id
          status
        }
      }
    }
  }
}
`;

export const getTestNoQuestions = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        id
        index
        testBlock {
          id
          type
          title
          blockConnections(limit: 5) {
            items {
              id
              test {
                id
                config {
                  title
                }
              }
            }
          }
          components {
            index
            renderType
            contents
          }
        }
      }
      nextToken
    }
    createdAt
    config {
      title
      type
      timeLimit
    }
  }
}
`;
export const getTestStructureOnly = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        id
        index
        testBlock {
          id
          type
          title
          components {
            index
            renderType
            contents
          }
        }
      }
      nextToken
    }
    createdAt
    config {
      title
      type
      timeLimit
    }
  }
}
`;

export const getTestQuestionIds = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        testBlock {
          questionConnections(limit: 5000) {
            items {
              index
              question {
                id
                status
              }
            }
          }
        }
      }
    }
  }
}
`;


export const getTestQuestionIdsWithConfig = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        id
        index
        testBlock {
          id
          type
          title
          components {
            index
            renderType
            contents
          }
          questionConnections(limit: 5000) {
            items {
              question {
                id
              }
            }
          }
        }
      }
    }
  }
}
`;
export const getTestQuestions = `query GetTest($id: ID!) {
  getTest(id: $id) {
    blockConnections(limit: 5000) {
      items {
        index
        testBlock {
          id
          type
          questionConnections(limit: 5000) {
            items {
              index
              question {
                id
                createdAt
                status
                latestRevisions(limit: 5000, sortDirection: DESC) {
                  items {
                    id
                    questionComponents {
                      index
                      renderType
                      contents
                    }
                    createdAt
                  }
                }
                tags {
                  items {
                    tag {
                      id
                      test
                      subject
                      topic
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
    config {
      title
      type
      timeLimit
    }
  }
}
`;

export const getTestQuestionTags = `query GetTest($id: ID!) {
  getTest(id: $id) {
    blockConnections(limit: 5000) {
      items {
        testBlock {
          questionConnections(limit: 5000) {
            items {
              question {
                id
                status
                tags {
                  items {
                    tag {
                      id
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;


export const getTestQuestionIdsAndStatus = `query GetTest($id: ID!) {
  getTest(id: $id) {
    blockConnections(limit: 5000) {
      items {
        testBlock {
          questionConnections(limit: 5000) {
            items {
              question {
                id
                status
              }
            }
          }
        }
      }
    }
  }
}
`;

export const getTestConnectionsOnly = `query GetTest($id: ID!) {
  getTest(id: $id) {
    id
    blockConnections(limit: 5000) {
      items {
        id
      }
    }
  }
}
`;

export const listTests = `query ListTests(
  $filter: ModelTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      config {
        title
        type
        timeLimit
      }
      blockConnections(limit: 2000) {
        items {
          testBlockConnectionTestBlockId
          index
          testBlock {
            type
            title
          }
        }
      }
    }
    nextToken
  }
}
`;

export const listTestBlocks = `query ListTestBlocks(
  $filter: ModelTestBlockFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      type
    }
    nextToken
  }
}
`;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        idOverride
        createdAt
        updatedAt
        memberships(limit: 200) {
          items {
            id
            productId
            groups
            startDate
            duration
            status
            userMembershipsId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listExams = `query ListExams(
  $filter: ModelExamFilterInput
  $limit: Int
  $nextToken: String
) {
  listExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      title
      active
      examComponents(sortDirection: ASC) {
        items {
          id
          index
          questionRevisionId
          examComponentQuestionId
          extra {
            index
            renderType
            contents
          }
          question {
            id
            createdAt
            searchString
            questionRevisions(limit: 2000) {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
              }
              nextToken
            }
            tags {
              items {
                id
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const getQuestionBase = `query GetQuestionBase($id: ID!) {
  getQuestionBase(id: $id) {
    id
    oldId
    createdAt
    updatedAt
    status
    connections {
      items {
        id
        index
        pinnedRevisionId
      }
      nextToken
    }
    latestRevisions(limit: 5000, sortDirection: DESC) {
      items {
        id
        questionComponents {
          index
          renderType
          contents
        }
        scorePossible
        createdAt
        revisedBy
        updatedAt
      }
    }
    tags {
      items {
        id
        tag {
          id
          test
          subject
          topic
          contentType
        }
      }
      nextToken
    }
  }
}
`;

export const getQuestionBaseTagsOnly = `query GetQuestionBase($id: ID!) {
  getQuestionBase(id: $id) {
    id
    tags {
      items {
        id
        tag {
          id
          test
          subject
          topic
          contentType
        }
      }
      nextToken
    }
  }
}
`;

export const getQuestionBaseWithFaqOnly = `query GetQuestionBase($id: ID!) {
  getQuestionBase(id: $id) {
    comments(limit: 500) {
      items {
        content
        createdAt
      }
      nextToken
    }
  }
}`

export const getQuestionBaseWithFaq = `query GetQuestionBase($id: ID!) {
  getQuestionBase(id: $id) {
    id
    oldId
    createdAt
    status
    comments {
      items {
        id
        content
        createdAt
        createdBy
      }
    }
    connections {
      items {
        id
        index
        questionConnectionQuestionId
        pinnedRevisionId
        block {
          id
          title
          type
          components {
            index
            renderType
            contents
          }
        }
      }
      nextToken
    }
    latestRevisions(limit: 5000, sortDirection: DESC) {
      items {
        id
        questionComponents {
          index
          renderType
          contents
        }
        scorePossible
        createdAt
        revisedBy
      }
    }
    tags {
      items {
        id
        tag {
          id
          test
          subject
          topic
          contentType
        }
      }
      nextToken
    }
  }
}
`;
export const getQuestionBaseTestBlocks = `query GetQuestionBase($id: ID!) {
  getQuestionBase(id: $id) {
    id
    connections(limit: 5000) {
      items {
        block {
          components {
            index
            renderType
            contents
          }
        }
      }
      nextToken
    }
  }
}
`;
export const getQuestionBaseWithTests = `query GetQuestionBase($id: ID!) {
  getQuestionBase(id: $id) {
    id
    oldId
    createdAt
    updatedAt
    status
    connections {
      items {
        id
        index
        questionConnectionQuestionId
        pinnedRevisionId
        block {
          blockConnections {
            items {
              test {
                id
                config {
                  title
                }
              }
            }
          }
        }
      }
      nextToken
    }
    latestRevisions(limit: 5000, sortDirection: DESC) {
      items {
        id
        questionComponents {
          index
          renderType
          contents
        }
        scorePossible
        createdAt
        revisedBy
      }
    }
    tags {
      items {
        id
        tag {
          id
          test
          subject
          topic
          contentType
        }
      }
      nextToken
    }
    comments {
      items {
        id
        content
        createdBy
        createdAt
      }
    }
  }
}
`;
export const getQuestionBaseProgresses = `query GetQuestionBase($id: ID!, $limit: Int, $progressNextToken: String) {
  getQuestionBase(id: $id) {
    progresses(limit: $limit, nextToken: $progressNextToken) {
      items {
        selectedAnswerIndex
        didSelectCorrectAnswer
        questionRevisionId
        answerState
      }
      nextToken
    }
  }
}`

export const searchQuestionBases = `query SearchQuestionBases(
  $filter: SearchableQuestionBaseFilterInput
  $sort: SearchableQuestionBaseSortInput
  $limit: Int
  $nextToken: String
) {
  searchQuestionBases(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      latestRevisions(limit: 1, sortDirection: DESC) {
        items {
          id
          revisedBy
          createdAt
          questionComponents {
            index
            renderType
            contents
          }
        }
      }
      connections {
        items {
          block {
            blockConnections {
              items {
                test {
                  id
                  config {
                    title
                  }
                }
              }
            }
          }
        }
      }
      tags {
        items {
          tag {
            id
            test
            subject
            topic
            contentType
          }
        }
        nextToken
      }
    }
    nextToken
    total
  }
}
`;

export const searchQuestionBasesAndBlocks = `query SearchQuestionBases(
  $filter: SearchableQuestionBaseFilterInput
  $sort: SearchableQuestionBaseSortInput
  $limit: Int
  $nextToken: String
) {
  searchQuestionBases(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oldId
      createdAt
      connections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
          }
        }
        nextToken
      }
      latestRevisions(limit: 5000, sortDirection: DESC) {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
        }
      }
      tags {
        items {
          id
          tag {
            id
            test
            subject
            topic
            contentType
          }
        }
        nextToken
      }
    }
    nextToken
    total
  }
}
`;
export const listTags = `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      test
      subject
      topic
      contentType
    }
    nextToken
  }
}
`;
export const getWordPressUserData = `query GetWordPressUserData($wpUserId: Int!, $masteryNextToken: String) {
  getWordPressUserData(wpUserId: $wpUserId) {
    wpUserId
    createdAt
    testProgresses(limit: 5000) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
            questionProgresses(limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                masteryLevel
                highlights
                questionRevisionId
                question {
                  id
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
    questionMastery(limit: 500, nextToken: $masteryNextToken) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        question {
          id
        }
      }
      nextToken
    }
  }
}
`;

export const getTestProgressByTestId = /* GraphQL */ `
  query TestProgressByTestId(
    $userIdHashTestId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TestProgressByTestId(
      userIdHashTestId: $userIdHashTestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
      }
      nextToken
    }
  }
`;
export const getBlockProgressByTestId = /* GraphQL */ `
  query TestProgressByTestId(
    $userIdHashTestId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TestProgressByTestId(
      userIdHashTestId: $userIdHashTestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestProgressByTestIdFull = /* GraphQL */ `
  query TestProgressByTestId(
    $userIdHashTestId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TestProgressByTestId(
      userIdHashTestId: $userIdHashTestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
            status
            index
            questionProgresses(limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                masteryLevel
                highlights
                questionRevisionId
                answerState
                question {
                  id

                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestProgressByTestIdFullForScoring = /* GraphQL */ `
  query TestProgressByTestId(
    $userIdHashTestId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TestProgressByTestId(
      userIdHashTestId: $userIdHashTestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testId
        createdAt
        blockProgresses (limit: 5000) {
          items {
            id
            blockId
            highlights
            index
            status
            questionProgresses (limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                answerState
                question {
                  id
                  oldId
                  createdAt
                  updatedAt
                  statistics
                  latestRevisions(limit: 1, sortDirection: DESC) {
                    items {
                      id
                      questionComponents {
                        index
                        renderType
                        contents
                      }
                      scorePossible
                      createdAt
                    }
                  }
                  tags {
                    items {
                      id
                      tag {
                        id
                        test
                        subject
                        system
                        topic
                        contentType
                      }
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getTestProgressByTestIdFullForDATScoring = /* GraphQL */ `
  query TestProgressByTestId(
    $userIdHashTestId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TestProgressByTestId(
      userIdHashTestId: $userIdHashTestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
         id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            index
            questionProgresses(limit: 5000) {
              items {
                selectedAnswerIndex
                didSelectCorrectAnswer
                time
                question {
                  id
                  tags {
                    items {
                      tag {
                        id
                        test
                        subject
                        topic
                      }
                    }
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBlockProgress = /* GraphQL */ `
  query GetBlockProgress($id: ID!) {
    getBlockProgress(id: $id) {
      id
      status
    }
  }
`;

export const getBlockProgressWithQuestions = /* GraphQL */ `
  query GetBlockProgress($id: ID!, $questionProgressesNextToken: String) {
    getBlockProgress(id: $id) {
      id
      questionProgresses (limit: 150, nextToken: $questionProgressesNextToken) {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          answerState
          question {
            id
            tags {
              items {
                tag {
                  id
                  test
                  subject
                  system
                  topic
                  contentType
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getTestProgressesCount = `query GetUserData($id: ID!) {
  getUser(id: $id) {
    id
    testProgresses(limit: 100) {
      items {
        id
      }
    }
  }
}
`;

export const getTestProgressIds = `query GetUserData($id: ID!) {
  getUser(id: $id) {
    id
    testProgresses(limit: 5000, sortDirection: DESC) {
      items {
        id
        testId
        createdAt
      }
    }
  }
}
`;

export const getTestProgressIdsByWpUserId = `query GetWordPressUserData($wpUserId: Int!) {
  getWordPressUserData(wpUserId: $wpUserId) {
    wpUserId
    testProgresses(limit: 5000, sortDirection: DESC) {
      items {
        id
        testId
        createdAt
      }
    }
  }
}
`;

export const getTestProgressesByUserId = `query GetUserData($id: ID!, $limit: Int, $testProgressesNextToken: String) {
  getUser(id: $id) {
    id
    testProgresses(limit: $limit, nextToken: $testProgressesNextToken) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
            questionProgresses(limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                masteryLevel
                highlights
                questionRevisionId
                question {
                  id

                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;

export const getTestProgressesByWpUserId = `query GetWordPressUserData($wpUserId: Int!, $testProgressesNextToken: String) {
  getWordPressUserData(wpUserId: $wpUserId) {
    wpUserId
    createdAt
    testProgresses(limit: 50, nextToken: $testProgressesNextToken) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
            questionProgresses(limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                masteryLevel
                highlights
                questionRevisionId
                question {
                  id

                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;

export const getQuestionRevision = /* GraphQL */ `
  query GetQuestionRevision($id: ID!) {
    getQuestionRevision(id: $id) {
      id
      questionComponents {
        index
        renderType
        contents
      }
      scorePossible
      createdAt
    }
  }
`;

export const getTestProgressPerformance = `query GetTestProgress($id: ID!) {
  getTestProgress(id: $id) {
    id
    blockProgresses (limit: 5000) {
      items {
        id
        status
      }
      nextToken
    }
  }
}
`;
export const getTestProgressSpecial = `query GetTestProgress($id: ID!, $nextToken:) {
  getTestProgress(id: $id) {
    id
    blockProgresses (limit: 5000) {
      items {
        id
        questionProgresses (limit: 15) {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            question {
              id
              tags {
                items {
                  id
                  tag {
                    id
                    test
                    subject
                    system
                    topic
                    contentType
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
}
`;
export const getTestProgressSimple = `query GetTestProgress($id: ID!) {
  getTestProgress(id: $id) {
    id
  }
}
`;
export const getTestProgress = `query GetTestProgress($id: ID!) {
  getTestProgress(id: $id) {
    id
    testId
    createdAt
    wordPressUserDataTestProgressesId
    blockProgresses (limit: 5000) {
      items {
        id
        blockId
        highlights
        index
        status
        questionProgresses (limit: 5000) {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            answerState
            question {
              id
              oldId
              createdAt
              updatedAt
              statistics
              latestRevisions(limit: 1, sortDirection: DESC) {
                items {
                  id
                  questionComponents {
                    index
                    renderType
                    contents
                  }
                  scorePossible
                  createdAt
                }
              }
              tags {
                items {
                  id
                  tag {
                    id
                    test
                    subject
                    system
                    topic
                    contentType
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const getTestProgressWithoutQuestion = `query GetTestProgress($id: ID!) {
  getTestProgress(id: $id) {
    id
    testId
    createdAt
    wordPressUserDataTestProgressesId
    blockProgresses (limit: 5000) {
      items {
        id
        blockId
        highlights
        index
        status
        questionProgresses (limit: 5000) {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            answerState
            question {
              id
              statistics
              tags {
                items {
                  id
                  tag {
                    id
                    test
                    subject
                    system
                    topic
                    contentType
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;

// could add to question above
// oldId
// createdAt
// searchString
// questionRevisions(limit: 2000, sortDirection: ASC) {
//   items {
//     id
//     questionComponents {
//       index
//       renderType
//       contents
//     }
//     scorePossible
//     createdAt
//   }
//   nextToken
// }
// tags {
//   items {
//     id
//     tag {
//       id
//       test
//       subject
//       topic
//       contentType
//     }
//   }
//   nextToken
// }

export const getAllQuestionMasteryQuestionsByUserId = /* GraphQL */ `
  query QuestionMasteryByWpUserId(
    $wpUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $masteryNextToken: String
  ) {
    QuestionMasteryByWpUserId(
      wpUserId: $wpUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $masteryNextToken
    ) {
      items {
        userIdHashQuestionBaseId
        masteryLevel
        userIdHashTestId
        question {
          id
          connections {
            items {
              block {
                blockConnections {
                  items {
                    test {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getAllQuestionMasteryIdsByUserId = /* GraphQL */ `
  query QuestionMasteryByWpUserId(
    $wpUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $masteryNextToken: String
  ) {
    QuestionMasteryByWpUserId(
      wpUserId: $wpUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $masteryNextToken
    ) {
      items {
        userIdHashQuestionBaseId
        masteryLevel
        updatedAt
      }
      nextToken
    }
  }
`;

export const getAllQuestionMasteryIdsTagsByUserId = /* GraphQL */ `
  query QuestionMasteryByWpUserId(
    $wpUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $masteryNextToken: String
  ) {
    QuestionMasteryByWpUserId(
      wpUserId: $wpUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $masteryNextToken
    ) {
      items {
        bookmarked
        question {
          tags {
            items {
              tag {
                id
                test
                subject
                topic
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;



export const questionMasteryByWpUserId = `query GetWordPressUserData($wpUserId: Int!, $masteryNextToken: String) {
  getWordPressUserData(wpUserId: $wpUserId) {
    questionMastery(limit: 500, nextToken: $masteryNextToken) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
      }
      nextToken
    }
  }
}
`;

export const questionMasteryWithQuestionsByWpUserId = `query GetWordPressUserData($wpUserId: Int!, $masteryNextToken: String) {
  getWordPressUserData(wpUserId: $wpUserId) {
    questionMastery(limit: 500, nextToken: $masteryNextToken) {
      items {
        masteryLevel
        bookmarked
        updatedAt
        question {
          id
          latestRevisions(limit: 2000, sortDirection: ASC) {
            items {
              id
              createdAt
              questionComponents {
                index
                renderType
                contents
              }
            }
          }
          tags {
            items {
              tag {
                id
                test
                subject
                topic
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;

export const questionMasteryWithQuestionTagsByWpUserId = `query GetWordPressUserData($wpUserId: Int!, $masteryNextToken: String) {
  getWordPressUserData(wpUserId: $wpUserId) {
    questionMastery(limit: 500, nextToken: $masteryNextToken) {
      items {
        masteryLevel
        bookmarked
        question {
          id
          tags {
            items {
              tag {
                id
                test
                subject
                topic
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;

export const questionMasteryByQuestionId = /* GraphQL */ `
  query QuestionMasteryByQuestionId(
    $questionMasteryQuestionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByQuestionId(
      questionMasteryQuestionId: $questionMasteryQuestionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        userIdHashTopicTagId
      }
      nextToken
    }
  }
`;

export const questionMasteryByQuestionIdForFixing = /* GraphQL */ `
  query QuestionMasteryByQuestionId(
    $questionMasteryQuestionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByQuestionId(
      questionMasteryQuestionId: $questionMasteryQuestionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashTestId
        userIdHashQuestionBaseId
        wpUserId
      }
      nextToken
    }
  }
`;


export const getQuestionMasteryByQuestionBaseId = /* GraphQL */ `
  query GetQuestionMastery($userIdHashQuestionBaseId: ID!) {
    getQuestionMastery(userIdHashQuestionBaseId: $userIdHashQuestionBaseId) {
      userIdHashTestId
      userIdHashQuestionBaseId
      wpUserId
      masteryLevel
      bookmarked
      questionRevisionId
      createdAt
      updatedAt
    }
  }
`;

export const questionMasteryBySubjectTagId = /* GraphQL */ `
  query QuestionMasteryBySubjectTagId(
    $userIdHashSubjectTagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryBySubjectTagId(
      userIdHashSubjectTagId: $userIdHashSubjectTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        userIdHashTopicTagId
        bookmarked
        userIdHashTestId
        userIdHashQuestionBaseId
        userIdHashSubjectTagId
      }
      nextToken
    }
  }
`;

export const questionMasteryByMasteryLevel = /* GraphQL */ `
  query QuestionMasteryByMasteryLevel(
    $wpUserId: ID
    $masteryLevel: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByMasteryLevel(
      wpUserId: $wpUserId
      masteryLevel: $masteryLevel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        userIdHashTopicTagId
        bookmarked
        question {
          id
          oldId
          createdAt
          latestRevisions(limit: 1, sortDirection: DESC) {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
            }
          }
          tags {
            items {
              id
              tag {
                id
                test
                subject
                topic
                contentType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByMasteryLevelAnatomy = /* GraphQL */ `
  query QuestionMasteryByMasteryLevel(
    $wpUserId: ID
    $masteryLevel: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByMasteryLevel(
      wpUserId: $wpUserId
      masteryLevel: $masteryLevel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        userIdHashTopicTagId
        question {
          id
          oldId
          createdAt
          latestRevisions(limit: 1, sortDirection: DESC) {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
            }
          }
          connections {
            items {
              block {
                components {
                  index
                  renderType
                  contents
                }
              }
            }
          }
          tags {
            items {
              id
              tag {
                id
                test
                subject
                topic
                contentType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;


export const questionMasteryBySubjectTagIdWithQuestion = /* GraphQL */ `
  query QuestionMasteryBySubjectTagId(
    $userIdHashSubjectTagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryBySubjectTagId(
      userIdHashSubjectTagId: $userIdHashSubjectTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        bookmarked
        userIdHashTopicTagId
        question {
          id
          oldId
          createdAt
          latestRevisions(limit: 1, sortDirection: DESC) {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
            }
          }
          tags {
            items {
              id
              tag {
                id
                test
                subject
                topic
                contentType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const questionMasteryBySubjectTagIdWithQuestionTags = /* GraphQL */ `
  query QuestionMasteryBySubjectTagId(
    $userIdHashSubjectTagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryBySubjectTagId(
      userIdHashSubjectTagId: $userIdHashSubjectTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        bookmarked
        userIdHashTopicTagId
        question {
          id
          tags {
            items {
              id
              tag {
                id
                test
                subject
                topic
                contentType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const questionMasteryBySubjectTagIdWithQuestionBaseId = /* GraphQL */ `
  query QuestionMasteryBySubjectTagId(
    $userIdHashSubjectTagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryBySubjectTagId(
      userIdHashSubjectTagId: $userIdHashSubjectTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        userIdHashTopicTagId
        userIdHashQuestionBaseId
      }
      nextToken
    }
  }
`;

export const questionMasteryByTopicTagId = /* GraphQL */ `
  query QuestionMasteryByTopicTagId(
    $userIdHashTopicTagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTopicTagId(
      userIdHashTopicTagId: $userIdHashTopicTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        userIdHashTestId
        userIdHashQuestionBaseId
      }
      nextToken
    }
  }
`;
export const questionMasteryByTopicTagIdWithQuestion = /* GraphQL */ `
  query QuestionMasteryByTopicTagId(
    $userIdHashTopicTagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTopicTagId(
      userIdHashTopicTagId: $userIdHashTopicTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        bookmarked
        userIdHashTestId
        userIdHashQuestionBaseId
        question {
          id
          oldId
          createdAt
          latestRevisions(limit: 1, sortDirection: DESC) {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
            }
          }
          tags {
            items {
              id
              tag {
                id
                test
                subject
                topic
                contentType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      test
      subject
      topic
      system
      contentType
    }
  }
`;
export const questionMasteryByTestId = /* GraphQL */ `
  query QuestionMasteryByTestId(
    $userIdHashTestId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTestId(
      userIdHashTestId: $userIdHashTestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        bookmarked
        userIdHashTestId
        userIdHashTopicTagId
        userIdHashSubjectTagId
        question {
          tags {
            items {
              tag {
                id
                test
                subject
                topic
                contentType
                system
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByTestIdSmall = /* GraphQL */ `
  query QuestionMasteryByTestId(
    $userIdHashTestId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTestId(
      userIdHashTestId: $userIdHashTestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masteryLevel
        bookmarked
        questionMasteryQuestionId
      }
      nextToken
    }
  }
`;
export const questionMasteryByTestIdCustomTest = /* GraphQL */ `
  query QuestionMasteryByTestId(
    $userIdHashTestId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTestId(
      userIdHashTestId: $userIdHashTestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        masteryLevel
        bookmarked
      }
      nextToken
    }
  }
`;
export const questionMasteryByTestIdCustomTestWithTestId = /* GraphQL */ `
  query QuestionMasteryByTestId(
    $userIdHashTestId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTestId(
      userIdHashTestId: $userIdHashTestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        userIdHashTestId
        masteryLevel
        bookmarked
      }
      nextToken
    }
  }
`;

export const getFilteredBootcampTestProgresses = `query GetFilteredBootcampTestProgresses($id: ID!, $testId: ID, $testProgressesNextToken: String) {
  getUser(id: $id) {
    id
    createdAt
    testProgresses(limit: 50, filter: {testId: {eq: $testId}}, nextToken: $testProgressesNextToken) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
            questionProgresses(limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                masteryLevel
                highlights
                questionRevisionId
                question {
                  id
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const getFilteredTestProgresses = `query GetFilteredTestProgresses($wpUserId: Int!, $testId: ID, $testProgressesNextToken: String) {
  getWordPressUserData(wpUserId: $wpUserId) {
    wpUserId
    createdAt
    testProgresses(limit: 50, filter: {testId: {eq: $testId}}, nextToken: $testProgressesNextToken) {
      items {
        id
        testId
        createdAt
        blockProgresses(limit: 5000) {
          items {
            id
            blockId
            highlights
            questionProgresses(limit: 5000) {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                masteryLevel
                highlights
                questionRevisionId
                question {
                  id
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const listTestsWithConnections = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blockConnections {
          items {
            id
            index
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                }
                nextToken
              }
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                }
                nextToken
              }
            }
            testBlockConnectionTestBlockId
          }
          nextToken
        }
        createdAt
        config {
          title
          type
          timeLimit
          status
        }
        tags {
          items {
            id
            tag {
              id
              test
              subject
              topic
              contentType
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listQuestionProgresss = /* GraphQL */ `
  query ListQuestionProgresss(
    $filter: ModelQuestionProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionProgresss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question {
          id
        }
      }
      nextToken
    }
  }
`;

export const listBlockProgresss = /* GraphQL */ `
  query ListBlockProgresss(
    $filter: ModelBlockProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockProgresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blockId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      idOverride
      createdAt
      updatedAt
      memberships {
        items {
          id
          productId
          groups
          startDate
          duration
          status
          userMembershipsId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;


export const getUserInteraction = /* GraphQL */ `
  query GetUserInteraction($id: ID!) {
    getUserInteraction(id: $id) {
      value
    }
  }
`;

export const listUserInteractionsByUserId = /* GraphQL */ `
  query UserId(
    $userId: String
  ) {
    UserId(userId: $userId) {
      items {
        id
        value
      }
    }
  }
`;

export const getQuestionForTest = /* GraphQL */ `
  query GetQuestionBase(
    $id: ID!
  ) {
    getQuestionBase(id: $id) {
      id
      status
      latestRevisions(limit: 1, sortDirection: DESC) {
        items {
          id
          createdAt
          questionComponents {
            index
            renderType
            contents
          }
        }
      }
      updatedAt
      tags {
        items {
          id
          tag {
            id
            test
            subject
            system
            topic
            contentType
          }
        }
        nextToken
      }
    }
  }
`
export const getQuestionForTestWithBlocks = /* GraphQL */ `
  query GetQuestionBase(
    $id: ID!
  ) {
    getQuestionBase(id: $id) {
      id
      status
      latestRevisions(limit: 1, sortDirection: DESC) {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
        }
      }
      connections {
        items {
          block {
            components {
              index
              renderType
              contents
            }
          }
        }
      }
      tags {
        items {
          id
          tag {
            id
            test
            subject
            topic
            contentType
          }
        }
        nextToken
      }
    }
  }
`
export const getQuestionBaseWithConnections = /* GraphQL */ `
  query GetQuestionBase(
    $id: ID!
  ) {
    getQuestionBase(id: $id) {
      id
      status
      connections {
        items {
          block {
            id
            blockConnections {
              items {
                id
              }
            }
            components {
              index
              renderType
              contents
            }
          }
        }
      }
    }
  }
`
export const listTestBlockConnections = /* GraphQL */ `
  query ListTestBlockConnections(
    $filter: ModelTestBlockConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestBlockConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testBlockConnectionTestBlockId
        testBlock {
          id
        }
      }
      nextToken
    }
  }
`;

export const listQuestionConnections = /* GraphQL */ `
  query ListQuestionConnections(
    $filter: ModelQuestionConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question {
          id
        }
      }
      nextToken
    }
  }
`;
