import React from 'react';

import {
  Container,
  Wrapper,
  Heading,
  HeadingName,
  HeadingSchool,
  HeadingResultContainer,
  Comment,
  StudentImageContainer,
  StudentImage,
  StudentImageIconContainer,
  StudentImageIcon,
} from './shared';

import samantha from '../assets/samanthacooper.png';
import baranda from '../assets/barandataylor.png';

const outcome1 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={samantha} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Samantha Cooper
        </HeadingName>
        <HeadingSchool>
          <strong>OMS-1</strong> at Philadelphia College of Osteopathic Medicine
        </HeadingSchool>
        <HeadingResultContainer>
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      "Anatomy Bootcamp has completely extinguished my fear of anatomy and physiology. As a current OMS-1, Anatomy Bootcamp’s cadaveric identification images have aided in my understanding of the different organ and muscle groups, as well as aided in my ability to get a 95% or higher on every practical I have taken so far!"
    </Comment>
  </Container>
);

const outcome2 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={baranda} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Baranda Taylor
        </HeadingName>
        <HeadingSchool>
          <strong>Nursing</strong> at Brenau University
        </HeadingSchool>
        <HeadingResultContainer>
          ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      "Anatomy Bootcamp is great supplemental material to lecture and lab. It keeps the information in your mind and helps close gaps in your studies."
    </Comment>
  </Container>
);


export const anatomyOutcomes = [
  outcome1,
  outcome2,
];
