import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import {Link} from 'react-router-dom';

import Grid from '../../components/Grid';
import MasteryReview from '../../components/MasteryReview';
import GettingStarted from '../../components/GettingStarted';
import FAQs from '../../components/WebflowFAQs';

import {H2} from '../../../components/Typography';
import FullClassroomCard from '../../components/Cards/Classroom';

import {useMasteryArray, useTestProgressArray} from '../../../hooks';

import {ReactComponent as Coffee} from '@bootcamp/shared/src/assets/svg/coffee.svg';

import {formatClassroomMastery, formatClassroomVideoProgress} from '@bootcamp/shared/src/util/formatting';
import {getStreakDetails, getTestsByClassroom} from '@bootcamp/shared/src/util';
import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import Container from '../../pages/PageContainer';

import styled from 'styled-components';

const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledClassroomCard = styled(FullClassroomCard)`
  flex: 1;
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  color: black;

  &:last-child {
    margin-right: 0;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    margin-right: 0px;
    margin-bottom: 0 0 ${({theme}) => theme.layouts.spacing.m};
  }

  svg {
    width: 42px;
    height: 42px;
  }
`;

const Home = () => {
  const {config} = useBootcampConfigContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'dental-school';

  const testsByClassroom = getTestsByClassroom(config?.classrooms);
  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom), [])
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const todayActions = [
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      icon: {
        component: Coffee,
        text: 'Daily Warmup', themeColor: 'royal'
      },
      text: 'New questions brewed daily just for you',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study`,
      },
      checkIfComplete: interactionObj => {
        const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        return streakCompletedToday;
      }
    },
  ];

  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);
  const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);
  const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);

  const progresses = useMasteryArray(config.classrooms.reduce((acc, {tagId, tagIds}) => tagIds ? [...acc, ...tagIds] :  [...acc, tagId], []), 'subject', data => formatClassroomMastery(data, 'subject'));
  const loading = !progresses;

  const classroomMasteryConfig = config.classrooms
    .filter(({locked}) => !locked)
    .map(({name, route, tagId, tagIds}) => ({
      name,
      route,
      progress: tagIds
        ? Object.keys(progresses)
          .reduce((acc, tagId) => tagIds.includes(tagId)
            ? {
                learning: (acc.learning || 0) + progresses[tagId].learning,
                reviewing: (acc.reviewing || 0) + progresses[tagId].reviewing,
                mastered: (acc.mastered || 0) + progresses[tagId].mastered,
              }
            : acc, {})
        : progresses[tagId]
      })
    );

  const [dentalAnatomyVideoProgress] = useTestProgressArray(coursesByClassroom["dental-anatomy"], formatClassroomVideoProgress);
  const [grossAnatomyVideoProgress] = useTestProgressArray(coursesByClassroom["gross-anatomy"], formatClassroomVideoProgress);
  const [neuroAnatomyVideoProgress] = useTestProgressArray(coursesByClassroom["neuroanatomy"], formatClassroomVideoProgress);
  const [histoVideoProgress] = useTestProgressArray(coursesByClassroom["histology"], formatClassroomVideoProgress);
  const [embryoVideoProgress] = useTestProgressArray(coursesByClassroom["embryology"], formatClassroomVideoProgress);

  const videoProgressMap = {
    "dental-anatomy": dentalAnatomyVideoProgress,
    "gross-anatomy": grossAnatomyVideoProgress,
    "neuroanatomy": neuroAnatomyVideoProgress,
    "histology": histoVideoProgress,
    "embryology": embryoVideoProgress,
  };

  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Dental School Courses
      </Title>
      <Grid
        items={config.classrooms}
        itemsPerRow={3}
        breakpoints={{
          1440: 3
        }}
        renderItem={({icon, themePalette, name, route, tagId, tagIds, locked, comingSoon, questionCount, showVideoProgress, videoLessonCount, educators}) => (
          <StyledClassroomCard
            key={tagId}
            icon={icon}
            themePalette={themePalette}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={comingSoon}
            comingSoon={comingSoon}
            questionCount={questionCounts?.classroomQuestionCounts?.[route] || null}
            showProgress={true}
            videoLessonProgress={videoProgressMap[route]}
            videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts?.[route] || null}
            showVideoProgress={!!courseBlockCounts?.classroomCourseBlockCounts?.[route]}
            progress={
              Object.keys(progresses).length === 0 ? null : tagIds
                ? tagIds.reduce((acc, tagId) => ({
                    learning: acc.learning + progresses[tagId].learning,
                    reviewing: acc.reviewing + progresses[tagId].reviewing,
                    mastered: acc.mastered + progresses[tagId].mastered
                  }), {learning: 0, reviewing: 0, mastered: 0})
                : progresses[tagId]
            }
            showExpertEducators
            educatorLayout={'column'}
            expertEducatorOrientation={'column'}
            educators={educators}
            detailsBorder={false}
            withHeaderPadding={false}
          />
        )}
      />
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
