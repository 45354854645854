import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const htmlString = `
<div id="introduction"> <!-- START TRAINER GAME -->

        <div class="bartop" style="padding: 30px 0px 26px;">

          <div class="bartopcontainer">

            <div class="column-center" style="width: 100%;"><h2>PAT Trainer Game</h2></div>

          </div>

        </div>

        <div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 86px; background-color: rgb(15, 98, 146); z-index: 3;"></div>

        <div style="width: 100%; max-width: 900px; padding: 4em; margin: 0 auto; padding-bottom: 86px;">

        <p>This is a perceptual ability training <strong>game</strong>.  Please read the following before starting:</p>
        <p>1. This is great practice to develop your spatial and perceptual abilities, plus it’s fun. Play this game everyday during your DAT prep; make a habit to do it during your lunch break or when you’re waiting for something to download.</p>
        <p>2. <u>These questions will not be on your DAT</u>. This is just to develop your skills of judging distances, angles, etc. which will help you get a better PAT score.</p>
        <p>3. Try to beat my high score of 1.28!</p>
        <p style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;" onclick="document.getElementById('generator').style.display='block'; document.getElementById('introduction').style.display='none';">Start Game</a></p>

        </div>

        </div>

        <div id="generator" style="display: none;">
        <div class="bartop" style="padding: 30px 0px 26px;">

          <div style="display: flex; width: 100%; max-width: 1000px; margin: 0 auto; align-items: center; justify-content: space-between;">

            <div style="display: flex; flex-direction: column; width: 33%;"></div>
            <div style="display: flex; flex-direction: column; width: 33%; text-align: center; color: #FFF; font: 600 16px 'proxima-nova', sans-serif;">PAT Trainer Game</div>
            <div style="display: flex; flex-direction: column; width: 33%; text-align: right; color: #FFF;"><p id="time-box" style="margin: 0;">Time: <span id="time-display" style="font-weight: 600;">0</span></p></div>

          </div>

        </div>

        <div style="clear: both; height: 70px;"></div>

        <div id="container" style="width: 100%; max-width: 900px; color: #444; text-align: left; display: block; margin: 0 auto;"">
              <div style="position: relative;">
        <div style="width: 52%; max-width: 500px; float: left;"><p id="position" style="font-weight: 600 !important; font-size: 18px; text-align: center; margin-bottom: 0px;">Instructions</p></div><div style="width: 44%; float: right;"><p style="text-align: center; font-size: 18px; font-weight: 600; margin-bottom: 0px;">Results</p></div><div style="clear: both;"></div>
        <div style="width: 100%; height: 25px; margin: 15px 0px; display: block;"><p id="single-result" style="margin: 0 0px; padding: 0;"></p></div>
                <div id="display-area" style="width: 54%; min-width: 502px; margin-left: -10px; height: 500px; display: inline-block; border: thin solid;">
                  <canvas id="screen" style="width: 50%; max-width: 500px; height: 500px;"></canvas>
                </div>
                <div id="results">
                  <table>
                    <tr>
                      <td style="width: 200px;">Parallelogram</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Midpoint</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Bisect Angle</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Circle Center</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Right Angle</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Two Angles</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Cubes</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                    <tr>
                      <td style="width: 200px;">Hole Punching</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                      <td style="width: 70px;">---</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

        <div class="mobileexamfix" style="clear: both; height: 70px; margin: 15px 0px;"></div>

        <div class="barbottom">

          <div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; justify-content: space-between;">

            <div style="display: flex; flex-direction: column; width: 33%;"><button type="button" id="next" class="nextbutton" style="margin-left: 10px;">Next</button></div>
            <div style="display: flex; flex-direction: column; width: 33%;"></div>
            <div style="display: flex; flex-direction: column; width: 33%; text-align: right; color: #FFF;"><p id="scoreboard" style="margin: 0;">Average Deviation: <span id="average" style="">0</span> |<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span></p></div>

          </div>

            <!-- <div class="bartopcontainer">
              <div style="float: right; width: 50%; margin-top: 18px; text-align: right;"><p id="scoreboard">Average Deviation: <span id="average" style="">0</span> |<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span></p></div>
              <div style="float: left; width: 50%;"><button type="button" id="next" class="nextbutton" style="margin-left: 10px;">Next</button></div>
            </div> -->

        </div>

      </div>
`


const Trainer = () => (
  <Container dangerouslySetInnerHTML={{__html: htmlString}} />
);

export default Trainer;
