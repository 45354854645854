import React, {useEffect} from 'react';
import styled from 'styled-components';
import Highlightable from '@bootcamp/web/src/components/Highlightable';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

import {
  PrometricWrapper,
  NextText,
  NextButton,
} from './shared';


const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 21px;
`;

const Passage = styled.div`
  max-height: 500px;
  min-height: 500px;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  font-family: Arial;
  text-align: justify;
  line-height: 25px;
  margin-top: 10px;
  border: 1px solid #D5D8D9;
  margin-top: 2em;
  p {
    margin: .5em 4em;
    img {
      margin: auto;
      display: block;
    }
  }
  margin-bottom: 2em;
`;

const RCPassage = ({setDismissedRCPassage}) => {

  const {
    variables: {
      blockIndex,
      testBlockConnections
    },
    methods: {
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
      updateQuizProgress
    }
  } = useTestNavigatorContext();

  useEffect(() => {
    setLeftButtons([{component: <div />}]);
    setCenterButtons([{
      component: <NextButton onClick={() => setDismissedRCPassage(dismissedRCPassage => [...dismissedRCPassage, blockIndex])} />
    }]);
    setRightButtons([{component: <div />}]);
    return () => {
      // When this component dismounts...
      setCenterButtons([]);
    }

  }, [])
  const testBlock = testBlockConnections[blockIndex].testBlock;
  const passageBlock = testBlock.type === 'passage';
  const passage = passageBlock && testBlock.components.find(component => component.renderType === 'passage').contents;
  const passageTitle = passageBlock && testBlock.components.find(component => component.renderType === 'passageTitle').contents;
  const passageHtml = passageBlock && `<h2 style="text-align: center;">${passageTitle}</h2>`+passage;

  return (
    <PrometricWrapper>
      <Heading>
        Reading Comprehension - Passage
      </Heading>
      {passage &&
        <Highlightable
          type={'passage'}
          htmlString={passageHtml}
          setHighlights={updateQuizProgress}>
          <Passage dangerouslySetInnerHTML={{__html: passageHtml}}/>
        </Highlightable>
      }
      <NextText />
    </PrometricWrapper>
  );
}

export default RCPassage;
