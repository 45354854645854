import React from 'react';
import styled from 'styled-components';

import {Header as Heading} from './shared';
import {Dismiss} from '@styled-icons/fluentui-system-filled/Dismiss';
import { useModalContext } from '../../../../contexts/Modal';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({theme}) => theme.layouts.spacing.m};
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  position: relative;

  ${Heading} {
    margin-bottom: 0;
  }
`;

const DismissIconWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const DismissIcon = styled(Dismiss)`
  width: 16px;
  height: 16px;
  color: ${({theme}) => theme.colors.special.tuxedo};
`;

const Header = () => {
  const {modalDispatch} = useModalContext();

  return (
    <Container>
      <DismissIconWrapper onClick={() => modalDispatch({type: 'close'})}>
        <DismissIcon />
      </DismissIconWrapper>
      <Heading>Create Test</Heading>
    </Container>
  );
};

Header.defaultProps = {};
Header.propTypes = {};

export default Header;
