import React from 'react';
import PropTypes from 'prop-types';

import {FlexBox, Column, Row} from '../Grid';
import {H2, H3, H4, Body2, Label2} from '../Typography';

import {useTestContext} from '../../contexts/TestBase';

import {ExitQuizButton, backButtonWidth} from './Buttons';
import MasteryIcon from '../../bootcamps/components/MasteryIcon';
import styled from 'styled-components';

import {ReactComponent as DWUIcon} from '@bootcamp/shared/src/assets/svg/dailywarmup.svg';
import {colors} from '@bootcamp/shared/src/styles/theme';

import {Loader} from '../Branding';

import moment from 'moment';

const Container = styled(FlexBox)`
  padding: 48px ${({theme}) => theme.layouts.spacing.xxl};
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.colors.brandPalette.orange.gradient};
  min-height: 100vh;
  ${({theme}) => theme.mediaQueries.laptop} {
    display: block;
    flex-direction: column;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.m};
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 0;
  }
`;
const Wrapper = styled(FlexBox)`
  text-align: center;

  flex-direction: column;
  height: auto;
  align-items: center;
  margin-left -${backButtonWidth}px;

  ${({theme}) => theme.mediaQueries.laptop} {
    margin: 0;
    display: block;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: auto;
  }
`;
const ContentWrapper = styled(FlexBox)`
  height: auto;
  max-width: 480px;
  margin: 0 auto;
  flex-direction: column;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  border-radius: 8px;
  padding: ${({theme}) => theme.layouts.spacing.xxl};
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.l};
  }
`;
const Header = styled(Column)`
  text-align: center;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const ButtonWrapper = styled(FlexBox)`
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;

const DateString = styled(Label2)`
  font-weight: 600;
  color: ${({theme}) => theme.colors.brandPalette.orange.dark};
  margin: auto;
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const Greeting = styled(H2)`
  margin: auto;
  text-align: center;
  word-break: break-word;
`;

const Description = styled(Body2)`
  color: ${({theme}) => theme.colors.neutralsPalette.dark};
  margin: auto;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;

const DescriptionBox = styled.div`
  display: flex;
  padding: ${({theme}) => theme.layouts.spacing.l} ${({theme}) => theme.layouts.spacing.m};
  background: ${({theme}) => theme.colors.special.naturalLight};
  border: 0.5px solid ${({theme}) => theme.colors.neutralsPalette.light};
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.inner.progressLight};
  margin: auto;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  width: 100%;
  min-height: 128px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const DescriptionWrapper = styled.div`
  margin: auto;
`;

const LoadingSpinner = styled(Loader)`
  fill: ${({theme}) => theme.colors.brandPalette.orange.default};
  margin: auto;
`;

const QuestionCount = styled(H3)`
  margin: auto;
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const QuestionCountList = styled.div`
  max-width: 220px;
  margin: auto;
`;

const QuestionCountListRow = styled(Row)`
  align-items: center;
`;

const QuestionCountListRowText = styled(H4)`
  font-weight: normal;
  color: ${({theme}) => theme.overlays.opacity.dark._400};
  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledDWUIcon = styled(DWUIcon)`
  ${({theme}) => theme.mediaQueries.tablet} {
    max-width: 192px;
    height: auto;
  }
`;

const StyledMasteryIcon = styled(MasteryIcon)`
  width: 40px;
  height: 40px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
`;

const ExitStart = styled(ExitQuizButton)`
  ${({theme}) => theme.mediaQueries.mobileL} {
    svg {
      stroke: ${({theme}) => theme.colors.neutralsPalette.white};
    }
  }
`;

const DailyReviewIntroBlock = ({
  username,
  buttons,
  loading,
  totalQuestions,
}) => {
  const currentMoment = moment();
  const dateStringText = currentMoment.format('dddd, MMM. D');
  const currentHour = currentMoment.hour();
  const {test} = useTestContext();

  const masteryLevels = test && test.blockConnections.items
    .reduce((acc, {testBlock}) => {
      if (!testBlock.questionConnections) return acc;
      return [
        ...acc,
        ...testBlock.questionConnections.items.map(({masteryLevel}) => masteryLevel)
      ]
    }, [])
    .reduce((acc, masteryLevel) => ({
      ...acc,
      [masteryLevel]: acc[masteryLevel] ? acc[masteryLevel] + 1 : 1
    }), {
      learning: 0,
      reviewing: 0
    });

  const timeOfDay = currentHour >= 4 && currentHour <= 11
    ? 'morning'
    : currentHour >= 12 && currentHour <= 16
    ? 'afternoon'
    : currentHour >= 16 && currentHour <= 23
    ? 'evening'
    : 'luck';

  const greetingText = `Good ${timeOfDay}, ${username || '____'}`;

  return (
    <Container>
      <ExitStart/>
      <Wrapper>
        <HeadingWrapper>
          <StyledDWUIcon />
        </HeadingWrapper>
        <ContentWrapper>
          <Header>
            <DateString children={dateStringText} />
            <Greeting children={greetingText} />
          </Header>
          <Description>
            Today's warmup consists of:
          </Description>
          <DescriptionBox>
            {
              loading
                ? <LoadingSpinner active/>
                : <DescriptionWrapper>
                    <QuestionCount>
                      {`${totalQuestions} questions`}
                    </QuestionCount>
                    <QuestionCountList>
                      {Object.entries(masteryLevels).filter(([masteryLevel, masteryLevelCount]) => masteryLevelCount > 0).map(([masteryLevel, masteryLevelCount]) => (
                        <QuestionCountListRow>
                          <StyledMasteryIcon level={masteryLevel === 'none' ? 'untagged' : masteryLevel} />
                          <QuestionCountListRowText>
                            {masteryLevel === 'none'
                              ? <span>{masteryLevelCount} <strong>unused</strong></span>
                              : <span>{masteryLevelCount} tagged as <strong>{masteryLevel}</strong></span>
                            }
                          </QuestionCountListRowText>
                        </QuestionCountListRow>
                      ))}
                    </QuestionCountList>
                  </DescriptionWrapper>
            }
          </DescriptionBox>
          <ButtonWrapper>
            {buttons(colors.brandPalette.orange.default)}
          </ButtonWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}

DailyReviewIntroBlock.propTypes = {
  buttons: PropTypes.func,
  testId: PropTypes.string,
  questionCount: PropTypes.number,
  loading: PropTypes.bool,
  header: PropTypes.string,
  subheader: PropTypes.string,
  descriptionHeader: PropTypes.string,
  descriptionText: PropTypes.string,
  themePalette: PropTypes.shape({}),
  educators: PropTypes.array,
};

DailyReviewIntroBlock.defaultProps = {
  buttons: () => [],
  themePalette: {},
  questionCount: 0,
  loading: false,
  header: '',
  subheader: '',
  descriptionHeader: '',
  descriptionText: '',
  educators: [],
};

export default DailyReviewIntroBlock;
