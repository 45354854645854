import {colors} from '../styles/theme';


import anatomyConfig from './anatomy';
import step1Config from './step1';

const step1Classrooms = step1Config.classrooms.filter(({comingSoon}) => !comingSoon).map(classroom => ({...classroom, importedBootcamp: 'step-1'}));
const grossAnatomyClassrooms = anatomyConfig.classrooms.filter(({name}) => ['Gross Anatomy', 'Neuroanatomy', 'Histology', 'Embryology'].includes(name))

const config = {
  classrooms: [
    ...grossAnatomyClassrooms,
    ...step1Classrooms,
    {
      name: 'Pulmonology',
      route: 'pulmonology',
      section: 'step1',
      tagIds: ['fe4787d2-942d-4d6f-95a9-da5a67a49bf7', 'fa156415-adc1-4d82-a134-36ea31fbe264'],
      themePalette: colors.brandPalette.indigo,
      questionCount: 128,
      videoLessonCount: 40,
      classroomDescription: `All the high-yield pulmonary concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!`,
      educators: [
        {
          firstName: 'Myles',
          avatar: 'images/myles_melamed.gif',
        },
        {
          firstName: 'Katie',
          avatar: 'images/katie_buerk.gif',
        },
        {
          firstName: 'Tyler',
          avatar: 'images/tyler_nelson.gif',
        },
      ],
      contentTypes: [
        {
          name: `Pulmonary Videos`,
          route: 'videos',
          content: [
            {
              name: 'Pulmonary Videos',
              type: 'Course',
              content: [
                {
                  name: 'Introduction to the Respiratory System',
                  route: 'introduction-to-the-respiratory-system',
                  id: '48d58a03-0427-4e4c-abcd-8ef6248e6c55'
                },
                {
                  name: 'Respiratory Physiology - Air',
                  route: 'respiratory-physiology-air',
                  id: 'db048b7d-ca34-4bc0-98fd-3a0e4f81cdfb'
                },
                {
                  name: 'Respiratory Physiology - Blood',
                  route: 'respiratory-physiology-blood',
                  id: 'c3765201-ccac-49b9-b308-1e0f915e3205'
                },
                {
                  name: 'Lung Pathology Fundamentals',
                  route: 'lung-pathology-fundamentals',
                  id: 'a1950ff7-9093-43f7-ad4a-bc5462412968'
                },
                {
                  name: 'Pathology - Obstructive Lung Disease',
                  route: 'pathology-obstructive-lung-disease',
                  id: '2da21109-1811-4331-aebb-e2eeabaa2ef9'
                },
                {
                  name: 'Pathology - Restrictive Lung Disease',
                  route: 'pathology-restrictive-lung-disease',
                  id: '2f2cfcf6-b260-49de-99df-e667e86ce15f'
                },
                {
                  name: 'Lung Cancer',
                  route: 'lung-cancer',
                  id: 'f833dcb7-f512-4d5a-bd54-eb610f28b00c'
                },
                {
                  name: 'Lung Pathology Special Topics',
                  route: 'lung-pathology-special-topics',
                  id: '977230d9-6315-4822-b54d-32607bfdf155'
                },
              ]
            }
          ]
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Anatomy',
                  id: '58d46b82-76e0-4a69-8f8d-ce223c8993ed',
                  route: 'anatomy'
                },
                {
                  name: 'Histology',
                  id: '8c870d59-5c08-409e-8cb5-153fce9287c3',
                  route: 'histology'
                },
                {
                  name: 'Immunology',
                  id: 'e45ff058-4cba-41c3-817c-ded90f90227b',
                  route: 'immunology'
                },
                {
                  name: 'Microbiology',
                  id: '4cbe39d1-815a-48f4-9bc8-87e9485c22a4',
                  route: 'microbiology'
                },
                {
                  name: 'Pathology',
                  id: 'ddb50a87-94c7-4d96-a428-a4dc43d6d9e2',
                  route: 'pathology'
                },
                {
                  name: 'Pathophysiology',
                  id: 'd8d2f853-c14a-446d-bb82-f501b65797b2',
                  route: 'pathophysiology'
                },
                {
                  name: 'Pharmacology',
                  id: '13abbe66-e34c-4c4a-8743-f69ddf1611e0',
                  route: 'pharmacology'
                },
                {
                  name: 'Physiology',
                  id: 'a8354a5c-64d6-440c-b911-4b9380645503',
                  route: 'physiology'
                },
              ]
            }
          ]
        },
        {
          name: `Pulm Bites`,
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Introduction to the Respiratory System',
                  route: 'introduction-to-the-respiratory-system',
                  id: '93394017-c71b-4229-a831-dda5737f4df5'
                },
                {
                  name: 'Respiratory Physiology - Air',
                  route: 'respiratory-physiology-air',
                  id: '7ca51a3b-a07a-43ae-b996-867894363568'
                },
                {
                  name: 'Respiratory Physiology - Blood',
                  route: 'respiratory-physiology-blood',
                  id: 'eea31fd8-7986-4d51-ab13-54aec98e3352'
                },
              ]
            }
          ]
        },
      ]
    },
    {
      name: 'Immunology',
      route: 'immunology',
      section: 'step1',
      tagIds: ['c41e7205-f9b9-4c97-b6c0-6c3716921813', 'bf8d83c5-0ce3-4902-bd38-d2aba900301a'],
      themePalette: colors.brandPalette.indigo,
      icon: 'icons/histo.svg',
      questionCount: 0,
      videoLessonCount: 80,
      classroomDescription: `All the high-yield immunology concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!`,
      educators: [
        {
          firstName: 'Dr. Roviso',
          avatar: 'images/inbde/anthony_roviso.gif',
        },
        {
          firstName: 'Katie',
          avatar: 'images/katie_buerk.gif',
        },
        {
          firstName: 'Audra',
          avatar: 'images/inbde/audra_boehm.gif',
        },
      ],
      contentTypes: [
        {
          name: `Immunology Videos`,
          route: 'videos',
          content: [
            {
              name: 'Videos',
              type: 'Course',
              content: [
                {
                  name: 'Lymphoid Tissue',
                  route: 'lymphoid-tissue',
                  id: '04e59278-55ff-4dbc-acbe-fe416760a671'
                },
                {
                  name: 'Innate vs Adaptive Immunity',
                  route: 'innate-vs-adaptive-immunity',
                  id: 'a615ad49-87f9-451b-b1e5-2c81f3574ed2'
                },
                {
                  name: 'Acute Inflammatory Response',
                  route: 'acute-inflammatory-response',
                  id: '50053b3c-792a-456d-90bc-fe28c7835448'
                },
                {
                  name: 'Cytokines',
                  route: 'cytokines',
                  id: '44321afd-ee0c-4a60-a8d3-a1282060b903'
                },
                {
                  name: 'T-cells',
                  route: 't-cells',
                  id: '533ea0de-1399-468a-85df-213d61246b9d'
                },
                {
                  name: 'B-cells',
                  route: 'b-cells',
                  id: 'e65ce497-2251-4342-b53b-959e35425891'
                },
                {
                  name: 'Antibodies',
                  route: 'antibodies',
                  id: '1f414663-c332-4a25-bef9-1ed1e6ee4cf5'
                },
                {
                  name: 'Complement',
                  route: 'complement',
                  id: 'aff5735e-7c78-4218-b28e-a80c436d7aa1'
                },
                {
                  name: 'Vaccinations',
                  route: 'vaccinations',
                  id: '12e0bdad-f4e8-40fb-b430-f2341ce79015'
                },
                {
                  name: 'Immunodeficiency Syndromes',
                  route: 'immunodeficiency-syndromes',
                  id: '9943f414-38ac-461e-b8e7-18e634ba4dfc'
                },
                {
                  name: 'Hypersensitivities',
                  route: 'hypersensitivities',
                  id: '20e04bc3-7764-4acb-806d-ee04b1b651f7'
                },
                {
                  name: 'Blood Transfusion Reactions',
                  route: 'blood-transfusion-reactions',
                  id: '41f48a78-d269-449e-978b-f7719a9b8d2d'
                },
                {
                  name: 'Transplant Rejection',
                  route: 'transplant-rejection',
                  id: 'b568a92f-972b-4f05-8642-e8e10f9c5217'
                },
              ]
            },
          ]
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Immunology',
                  id: 'e0ba78ab-36d8-457a-9860-dc8c539b21d9',
                  route: 'immunology'
                },
                {
                  name: 'Microbiology',
                  id: 'b411e150-e960-47a8-aa18-acbca370cb0a',
                  route: 'microbiology'
                },
                {
                  name: 'Pathology',
                  id: '29d4d442-8188-460a-8a57-e1ed80d0e016',
                  route: 'pathology'
                },
                {
                  name: 'Pharmacology',
                  id: '239623b4-2cac-43f8-b102-9a2cce37d25a',
                  route: 'pharmacology'
                },
              ]
            }
          ]
        },
        {
          name: 'Immuno Bites',
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Lymphoid Tissue',
                  route: 'lymphoid-tissue',
                  id: 'b5247d14-c8a8-47d7-86a9-ab671974246e'
                },
                {
                  name: 'Innate vs Adaptive Immunity',
                  route: 'innate-vs-adaptive-immunity',
                  id: 'e4ade753-482a-4abc-8222-bd7171d4da31'
                },
                {
                  name: 'Acute Inflammatory Response',
                  route: 'acute-inflammatory-response',
                  id: 'fea485f9-58c1-4de7-becc-29cd3b6d70f8'
                },
                {
                  name: 'Cytokines',
                  route: 'cytokines',
                  id: '94aab045-f20d-4284-9edb-db13d705f2be',
                },
                {
                  name: 'T-cells',
                  route: 't-cells',
                  id: '349598ad-03df-4655-a010-c63a6589e58a'
                },
                {
                  name: 'B-cells',
                  route: 'b-cells',
                  id: '7607d7b5-a78c-4193-89a4-67d937233f76'
                },
                {
                  name: 'Antibodies',
                  route: 'antibodies',
                  id: 'dd78ba76-2bf1-4241-a80e-56f93a61bb87'
                },
                {
                  name: 'Complement',
                  route: 'complement',
                  id: 'f16d5769-edff-4ec0-9bb6-f27b6fb19ca4'
                },
                {
                  name: 'Vaccinations',
                  route: 'vaccinations',
                  id: '6f38269e-2ad7-491d-b54c-e737fd6d5a85'
                },
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Psychiatry',
      route: 'psychiatry',
      section: 'step1',
      tagId: 'e3999743-fedd-43bd-bea3-dbb9e79a28b9',
      themePalette: colors.brandPalette.indigo,
      questionCount: 40,
      videoLessonCount: 30,
      classroomDescription: `All the high-yield psychiatry concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!`,
      educators: [
        {
          firstName: 'Jake',
          avatar: 'images/jacobgarcia.gif',
        },
        {
          firstName: 'Jim',
          avatar: 'images/jim.gif',
        },
        {
          firstName: 'Kathak',
          avatar: 'images/kathak.gif',
        },
      ],
      contentTypes: [
        {
          name: `Psychiatry Videos`,
          route: 'videos',
          content: [
            {
              name: 'Psychiatry Videos',
              type: 'Course',
              content: [
                {
                  name: 'Psychology',
                  route: 'psychology',
                  id: '83d809c4-cfa0-4408-ba10-176eee281a01'
                },
                {
                  name: 'Developmental Disorders',
                  route: 'developmental-disorders',
                  id: 'd21d69f7-1191-4774-9723-953326e0b0f6'
                },
                {
                  name: 'Memory, Dissociation, and Orientation',
                  route: 'memory-dissociation-and-orientation',
                  id: '38891345-0431-46af-8915-6514fc6d857a'
                },
                {
                  name: 'Anxiety, Trauma, and Stress Disorders',
                  route: 'anxiety-trauma-and-stress-disorders',
                  id: 'ed5750d5-0102-4161-aa99-c7d32d0c304c'
                },
                {
                  name: 'Personality Disorders',
                  route: 'personality-disorders',
                  id: '782f356b-1708-4886-b14e-864627f2e26c'
                },
                {
                  name: 'Psychotic and Mood Disorders',
                  route: 'psychotic-and-mood-disorders',
                  id: 'f4e3dddd-29f7-46a1-baba-3efbf15a7d2b'
                },
              ]
            }
          ]
        },
        {
          name: `Psych Bites`,
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Psychology',
                  route: 'psychology',
                  id: '9dd8d3b5-119c-4db4-bdf3-d9d1b7af85d6'
                },
                {
                  name: 'Developmental Disorders',
                  route: 'developmental-disorders',
                  id: 'cae3c1aa-7fa2-4045-805b-3505ee67c2a5'
                },
                {
                  "name": "Memory, Dissociation, and Orientation",
                  "route": "memory-dissociation-and-orientation",
                  "id": "5b259892-3672-420b-a2a0-74d60a5a6b5b"
                },
                {
                  "name": "Anxiety, Trauma, and Stress Disorders",
                  "route": "anxiety-trauma-and-stress-disorders",
                  "id": "ce9757c5-f5c1-441a-9c27-91e31fa3f56b"
                }
              ]
            }
          ]
        },
      ]
    },
    {
      name: 'Hematology & Oncology',
      route: 'hematology-and-oncology',
      section: 'step1',
      tagId: '', // c7cc2866-4c86-42a2-8860-af9cc6852006 TODO add this tagId when we have qbanks in this subject
      themePalette: colors.brandPalette.indigo,
      questionCount: 0,
      videoLessonCount: 48,
      showVideoProgress: true,
      classroomDescription: `All the high-yield heme & onc concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!`,
      educators: [
        {
          firstName: 'Dr. Roviso',
          avatar: 'images/inbde/anthony_roviso.gif',
        },
        {
          firstName: 'Jonathan',
          avatar: 'images/jonathanhunter.gif',
        },
      ],
      contentTypes: [
        {
          name: `Heme & Onc Videos`,
          route: 'videos',
          content: [
            {
              name: 'Heme & Onc Videos',
              type: 'Course',
              content: [
                {name: 'Embryology', route: 'embryology', id: 'de495996-515d-4d31-ac8d-39f0bb5be759'},
                {name: 'Blood Cells', route: 'blood-cells', id: '5bc1f772-e145-4337-97e1-2cac8ebc21b0'},
                {name: 'Microcytic Anemia', route: 'microcytic-anemia', id: '1bd1d311-9afc-41f6-ab64-b9439728a3b0'},
                {name: 'Normocytic Anemia', route: 'normocytic-anemia', id: '34687b83-6900-471d-9660-f9e98c23b2e7'},
                {name: 'Macrocytic Anemia', route: 'macrocytic-anemia', id: '7ae22c51-f1ce-4601-b473-237681b9c1be'},
                {name: 'Platelets', route: 'platelets', id: '6cbbb79a-55aa-453a-bda0-19dfc8bc7739'},
                {name: 'Coagulation and Fibrinolysis', route: 'coagulation-and-fibrinolysis', id: '919a439b-2228-49ee-b734-beca32cfcdde'},
              ]
            }
          ]
        },
      ]
    },
    {
      name: 'Dermatology',
      route: 'dermatology',
      classroomDescription: 'All the high-yield dermatology concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!',
      section: 'step1',
      tagIds: ['03368b16-4e2f-4db7-8de6-44937f453f91', 'cb030e2b-4777-4c94-9eeb-1783a9591f1e'],
      themePalette: colors.brandPalette.cerulean,
      icon: 'icons/neck.svg', // TODO add gross anatomy icon
      questionCount: 0,
      videoLessonCount: 53,
      educators: [
        {
          firstName: 'Jake',
          avatar: 'images/jacobgarcia.gif',
        },
        {
          firstName: 'Brooke',
          avatar: 'images/brooke_nelson.gif',
        },
        {
          firstName: 'Jim',
          avatar: 'images/jim.gif',
        },
      ],
      contentTypes: [
        {
          name: `Dermatology Videos`,
          route: 'videos',
          content: [
            {
              name: 'Videos',
              type: 'Course',
              content: [
                {
                  name: 'Skin Structure and Function',
                  route: 'skin-structure-and-function',
                  id: 'c28a6bfb-9912-4eb9-a623-85c407137c42'
                },
                {
                  name: 'Histopathology',
                  route: 'histopathology',
                  id: '44728323-8ac8-47f6-a1bd-d7cea5df4505'
                },
                {
                  name: 'Skin Lesions',
                  route: 'skin-lesions',
                  id: 'f69bace8-d22e-4967-849c-e638916362e6'
                },
                {
                  name: 'Bacterial Infectious Diseases',
                  route: 'bacterial-infectious-diseases',
                  id: '7071a622-65f6-41ee-802d-c660eaf0d6c4'
                },
                {
                  name: 'Herpes Simplex and Varicella Infections',
                  route: 'herpes-simplex-and-varicella-infections',
                  id: 'd398bc87-5e00-46a7-be10-b469391852ae'
                },
                {
                  name: 'Viral Infectious Conditions',
                  route: 'viral-infectious-conditions',
                  id: '6107cb1a-8edf-496e-bfda-32be61c843af'
                },
                {
                  name: 'Candida',
                  route: 'candida',
                  id: 'c8ad97ab-4801-4077-b41c-5baf0e6d83fd'
                },
                {
                  name: 'Fungal Infectious Conditions',
                  route: 'fungal-infectious-conditions',
                  id: '291d8046-7f79-4422-b3aa-b86705935707'
                },
                {
                  name: 'Autoimmune Blistering Conditions',
                  route: 'autoimmune-blistering-conditions',
                  id: '8f4a3efe-955e-4acf-b8fc-29c84f7d200d'
                },
                {
                  name: 'Reactive Disease',
                  route: 'reactive-disease',
                  id: '0a0f6a20-655e-4bc3-9730-5a3490b8a232'
                },
                {
                  name: 'Burns',
                  route: 'burns',
                  id: 'ada6afe2-350e-490e-8be6-dcd559d3b384'
                },
                {
                  name: 'Skin Cancer',
                  route: 'skin-cancer',
                  id: '3a821d28-6d77-4cf1-8608-588181385168'
                },
                {
                  name: 'Melanoma',
                  route: 'melanoma',
                  id: '28747933-bdec-4c54-b364-73564defcde9'
                },
                {
                  name: 'Vascular Tumors',
                  route: 'vascular-tumors',
                  id: 'bb700370-7773-4828-bc89-c57b95be5ab8'
                },
                {
                  name: 'Atopic and Allergic Disease',
                  route: 'atopic-and-allergic-disease',
                  id: 'a97ff3fb-3dec-436c-9417-9538fefd39a1'
                },
                {
                  name: 'Pustular Disease',
                  route: 'pustular-disease',
                  id: '85ee781d-e4d9-454d-a49c-59cb0ad4265f'
                },
                {
                  name: 'Benign Dark Skin Lesions',
                  route: 'benign-dark-skin-lesions',
                  id: 'cb2b8200-c165-4c01-9e45-e9d81c79e419'
                },
                {
                  name: 'Red Plaque Disease',
                  route: 'red-plaque-disease',
                  id: 'fd4c7895-84ec-4cba-ae3f-e7ba06b25370'
                },
              ]
            }
          ]
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Immunology',
                  id: 'd8822373-d2dd-460d-9b1a-6f147a5bbae3',
                  route: 'immunology'
                },
                {
                  name: 'Microbiology',
                  id: '90ce973f-87f6-4aa2-98db-b00829fed972',
                  route: 'microbiology'
                },
                {
                  name: 'Pathology',
                  id: '6370e3f3-bccd-48ed-8d17-558c19620f54',
                  route: 'pathology'
                },
                {
                  name: 'Pathophysiology',
                  id: '41dd4e05-912a-41b0-9347-dc523416244c',
                  route: 'pathophysiology'
                },
                {
                  name: 'Pharmacology',
                  id: '41194efa-a320-456b-b0ef-e6886e960312',
                  route: 'pharmacology'
                },
              ]
            }
          ]
        },
        {
          name: 'Derm Bites',
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Skin Structure and Function',
                  route: 'skin-structure-and-function',
                  id: 'f62353b2-3365-46e7-aa5d-0146b7581247'
                },
                {
                  name: 'Histopathology',
                  route: 'histopathology',
                  id: 'c209a420-655f-4384-ba1e-8b9473654912'
                },
                {
                  name: 'Skin Lesions',
                  route: 'skin-lesions',
                  id: '1317939f-a619-4b59-b1c2-6fde553f61d5'
                },
                {
                  name: 'Bacterial Infectious Diseases',
                  route: 'bacterial-infectious-diseases',
                  id: '2bcb0484-8403-4122-9c84-fba079d3c6a9'
                },
                {
                  name: 'Herpes Simplex and Varicella Infections',
                  route: 'herpes-simplex-and-varicella-infections',
                  id: '22dc0478-de31-454b-9aaf-e768f4bb7854'
                },
                {
                  name: 'Viral Infectious Conditions',
                  route: 'viral-infectious-conditions',
                  id: 'edc523df-0775-4656-bfa8-5ffb13ba5b7d'
                },
                {
                  name: 'Candida',
                  route: 'candida',
                  id: 'cf3f790c-8e22-44b0-8e90-89b9de41e8c6'
                },
                {
                  name: 'Fungal Infectious Conditions',
                  route: 'fungal-infectious-conditions',
                  id: '5048617e-a4c0-4a8b-a1b5-5c532ff1e099'
                },
                {
                  name: 'Autoimmune Blistering Conditions',
                  route: 'autoimmune-blistering-conditions',
                  id: 'd935e657-5a22-4921-88ac-c5e5af3266e9'
                },
                {
                  name: 'Reactive Skin Disease',
                  route: 'reactive-skin-disease',
                  id: '9099ce40-c69c-48c5-9ab0-bed9f65a377e'
                },
                {
                  name: 'Burns',
                  route: 'burns',
                  id: '98ef32f0-7482-41d3-8179-6004cfaec785'
                },
                {
                  name: 'Skin Cancer',
                  route: 'skin-cancer',
                  id: '6c3f8f41-07f0-4a02-9055-1077a5ea8187'
                },
                {
                  name: 'Melanoma',
                  route: 'melanoma',
                  id: 'cef3c08b-d2de-4837-ac79-9b7ff19b0a3a'
                },
                {
                  name: 'Vascular Tumors',
                  route: 'vascular-tumors',
                  id: 'f70e5b61-5026-432b-9096-ac9000af58c0'
                },
                {
                  name: 'Atopic and Allergic Disease',
                  route: 'atopic-and-allergic-disease',
                  id: '5c5352f3-a0e7-441b-a06b-31d749b267ab'
                },
                {
                  name: 'Pustular Disease',
                  route: 'pustular-disease',
                  id: '65d8b53e-cab3-4e92-a489-9325b5cbc018'
                },
                {
                  name: 'Benign Dark Skin Lesions',
                  route: 'benign-dark-skin-lesions',
                  id: 'a02e62a7-952e-44f1-a8a9-6e15deec6e45'
                },
                {
                  name: 'Red Plaque Disease',
                  route: 'red-plaque-disease',
                  id: '5622842e-d9ad-454e-83c6-38b90a23258a'
                },
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Nephrology',
      route: 'nephrology',
      section: 'step1',
      tagIds: ['8f5e08ad-f30b-4a64-b0af-24b12ad97497', '09012e37-514e-4fef-ba98-28a574d90375'],
      themePalette: colors.brandPalette.indigo,
      questionCount: 0,
      videoLessonCount: 54,
      classroomDescription: `All the high-yield renal concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!`,
      educators: [
        {
          firstName: 'David',
          avatar: 'images/david_gallacher.gif',
        },
        {
          firstName: 'Shiraz',
          avatar: 'images/shiraz.gif',
        },
      ],
      contentTypes: [
        {
          name: `Renal Videos`,
          route: 'videos',
          content: [
            {
              name: 'Renal Videos',
              type: 'Course',
              content: [
                {
                  name: 'Anatomy & Embryology',
                  route: 'anatomy-and-embryology',
                  id: 'fcc74edc-0e97-4104-9a23-770d383c6303'
                },
                {
                  name: 'Fluid & Filtration Physiology',
                  route: 'fluid-and-filtration-physiology',
                  id: '498e6323-3cbe-473c-afcd-817a1b4967ec'
                },
                {
                  name: 'Nephron Transporters',
                  route: 'nephron-transporters',
                  id: 'dda9deb1-024f-4b8e-8233-a60da2487f73'
                },
                {
                  name: 'Renin-Angiotensin-Aldosterone System',
                  route: 'renin-angiotensin-aldosterone-system',
                  id: '56685d89-b9af-403d-aea2-a51784965c2c'
                },
                {
                  name: 'Electrolytes',
                  route: 'electrolytes',
                  id: '420db2fe-f439-4966-80aa-e5954cdf83cc'
                },
                {
                  name: 'Acid-Base Physiology',
                  route: 'acid-base-physiology',
                  id: 'bcb4658f-5fe9-41ac-82bb-42e7e906cd8b'
                },
              ]
            }
          ]
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Histology',
                  id: '279f1705-0d1c-4d3f-9ce3-d0aa0312c3ae',
                  route: 'histology'
                },
                {
                  name: 'Immunology',
                  id: '678e6f56-74c8-4a07-8505-d6ceb6ac5c6a',
                  route: 'immunology'
                },
                {
                  name: 'Pathology',
                  id: '2b4f1c32-a586-426f-8a47-fef7b7784731',
                  route: 'pathology'
                },
                {
                  name: 'Pathophysiology',
                  id: '43608475-65fe-4ddd-9379-7df3fd01e96f',
                  route: 'pathophysiology'
                },
                {
                  name: 'Pharmacology',
                  id: 'c3bbd1d9-bfa3-4078-9430-6b6cb8d305eb',
                  route: 'pharmacology'
                },
              ]
            }
          ]
        },
        {
          name: 'Nephro Bites',
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Anatomy & Embryology',
                  route: 'anatomy-and-embryology',
                  id: '97fc58bf-2611-4c16-b25d-089e2ae25f04'
                },
                {
                  name: 'Fluid & Filtration Physiology',
                  route: 'fluid-and-filtration-physiology',
                  id: 'a1f4f8ae-9642-41ab-87ac-be86272cd7b7'
                },
                {
                  name: 'Nephron Transporters',
                  route: 'nephron-transporters',
                  id: '69118396-9b27-4370-b1f0-d4340ac4a03c'
                },
                {
                  name: 'Renin-Angiotensin-Aldosterone System',
                  route: 'renin-angiotensin-aldosterone-system',
                  id: '718f6295-a0d2-4411-a838-8b4a0e9264c1'
                },
                {
                  name: 'Electrolytes',
                  route: 'electrolytes',
                  id: '00554e94-ae10-42c8-92b4-4188d9a418c9'
                },
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Gastroenterology',
      route: 'gastroenterology',
      section: 'step1',
      tagIds: ['bfb4f691-60c1-45f1-a2c5-d9cf67e3d927', '7eea0976-b3bb-4b5b-ad09-250e02ea8cfb'],
      themePalette: colors.brandPalette.indigo,
      questionCount: 0,
      videoLessonCount: 23,
      classroomDescription: `All the high-yield GI concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!`,
      educators: [
        {
          firstName: 'David',
          avatar: 'images/david_cornwell.gif',
        },
        {
          firstName: 'Christian',
          avatar: 'images/christian_drkulec.gif',
        },
        {
          firstName: 'Toby',
          avatar: 'images/toby_mathew.gif',
        },
      ],
      contentTypes: [
        {
          name: `GI Videos`,
          route: 'videos',
          content: [
            {
              name: 'GI Videos',
              type: 'Course',
              content: [
                {
                  name: 'Visceral Anatomy',
                  route: 'visceral-anatomy',
                  id: 'ba824be3-b868-4bdf-8881-9951f28b231c'
                },
                {
                  name: 'Non-visceral Anatomy',
                  route: 'non-visceral-anatomy',
                  id: 'd208fbc7-4d32-4d05-87b9-db5cae3da5b1'
                },
                {
                  name: 'Physiology',
                  route: 'physiology',
                  id: 'e4c3e7be-cc1d-4750-94f2-278f074cb498'
                },
              ]
            }
          ]
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Biochemistry',
                  id: '98d2e9e3-4ae1-4b95-bb4c-1f4511482cc6',
                  route: 'biochemistry'
                },
                {
                  name: 'Microbiology',
                  id: '71b4860f-b4aa-4f17-91e3-6336e7daf595',
                  route: 'microbiology'
                },
                {
                  name: 'Pathology',
                  id: 'c2651167-04fe-4367-a949-f8211492ab87',
                  route: 'pathology'
                },
                {
                  name: 'Pathophysiology',
                  id: '90fc5923-021e-4694-8e87-24afcee361e7',
                  route: 'pathophysiology'
                },
                {
                  name: 'Pharmacology',
                  id: '6e0925a7-f99e-478c-95e5-a0005676ac54',
                  route: 'pharmacology'
                },
              ]
            }
          ]
        },
        {
          name: 'Gastro Bites',
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Visceral Anatomy',
                  route: 'visceral-anatomy',
                  id: '61fdfbdc-b7f6-4f11-9e8c-ba320af93b67'
                },
                {
                  name: 'Non-visceral Anatomy',
                  route: 'non-visceral-anatomy',
                  id: '9a1d43b3-ac71-4a3e-a1d1-13c51f663e17'
                }
              ]
            }
          ]
        }
      ]
    },
  ],
  studyTools: [
    {
      name: 'Bookmarks',
      route: 'bookmarks',
      themePalette: colors.brandPalette.royal,
      showOnHome: true,
    },
  ],
  meta: {
    siteTitle: 'Med School Bootcamp',
    subjectIds: [...grossAnatomyClassrooms, ...step1Classrooms].reduce((acc, {tagIds, tagId}) => tagId ? [...acc, tagId] : tagIds ? [...acc, ...tagIds] : acc, [])
  }
};
function getMedSchoolConfigObject(classroom, chapter, contentSlug) {
}

const anatomyConfigReducer = () => {}

export {getMedSchoolConfigObject, anatomyConfigReducer};
export default config;
