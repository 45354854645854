import React, {Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Switch, useLocation, Redirect, Route} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {AppRoutes} from './routes';
import ModalProvider, {Modal} from  '../contexts/Modal';

import MasteryReviewSelect from '../bootcamps/dat/MasteryReviewSelect';
import TBCSignup from '../bootcamps/dat/TBCSignup';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
// TBC ROUTERS
const AppRouter = () => (
  <Router>
    <ScrollToTop />
    <ModalProvider>
      <Suspense fallback={<div />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
          {AppRoutes}
        </Switch>
      </Suspense>
      <Modal/>
    </ModalProvider>
  </Router>
);

export {AppRouter};
