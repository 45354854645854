import React from 'react';

const Cerebrospinal = () => (
	<table>
		<thead>
			<tr>
				<th class="labName">Cerebrospinal Fluid</th>
				<th class="refRange">Reference Range</th>
				<th class="SI">SI Reference</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Cell count</td>
				<td class="refRange">0–5/mm<sup>3</sup></td>
				<td class="SI">0–5 x 10<sup>6</sup>/L</td>
			</tr>
			<tr>
				<td>Chloride</td>
				<td class="refRange">118–132 mEq/L</td>
				<td class="SI">118–132 mmol/L</td>
			</tr>
			<tr>
				<td>Gamma globulin</td>
				<td class="refRange">3%–12% total proteins</td>
				<td class="SI">0.03–0.12</td>
			</tr>
			<tr>
				<td>Glucose</td>
				<td class="refRange">40–70 mg/dL</td>
				<td class="SI">2.2–3.9 mmol/L</td>
			</tr>
			<tr>
				<td>Pressure</td>
				<td class="refRange">70–180 mm H<sub>2</sub>O</td>
				<td class="SI">70–180 mm H<sub>2</sub>O</td>
			</tr>
			<tr>
				<td>Proteins, total</td>
				<td class="refRange">&lt;40 mg/dL</td>
				<td class="SI">&lt;0.40 g/L</td>
			</tr>
		</tbody>
	</table>
);

export default Cerebrospinal;