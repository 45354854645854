import React from 'react';
import styled from 'styled-components';

import {Column} from '@bootcamp/web/src/components/Grid';

import {Sidebar} from '../../QBank/Sidebar';



const Backdrop = styled.div`
  background: ${({showingSidebar}) => showingSidebar ? 'rgba(21, 26, 30, 0.5)' : 'transparent'};
  width: ${({showingSidebar}) => showingSidebar ? '100%' : '0%'};
  transition: background .2s ease-in 0s${({showingSidebar}) => !showingSidebar && `, width .1s ease-in .3s`};
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
`;

const Container = styled(Column)`
  background: ${({background}) => background};
  width: 256px;
  box-shadow: ${({theme}) => theme.elevation.shadow.high};
  transition: right .2s ease-in 0s${({showingSidebar}) => !showingSidebar && `, height .1s ease-in .3s`};
  position: absolute;
  z-index: 10;
  height: ${({showingSidebar}) => showingSidebar ? '100vh' : '0px'};
  top: 0;
  right: ${({showingSidebar}) => showingSidebar ? 0 : -288}px;
`;

const PositionWrapper = props => {
  const [showingSidebar, setShowingSidebar] = props.sidebarState;
  return (
    <Backdrop showingSidebar={showingSidebar} onClick={() => setShowingSidebar(false)}>
      <Container showingSidebar={showingSidebar} background={props.themePalette.default} onClick={e => e.stopPropagation()}>
        <Sidebar {...props} showExitButton={false} hide={['toggleDarkMode', ...(props?.hide || [])]}/>
      </Container>
    </Backdrop>
  );
}


export default PositionWrapper;
