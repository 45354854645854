import React from 'react';

import {ReactComponent as Smile} from '../assets/smile.svg';
import {ReactComponent as Click} from '../assets/click.svg';
import {ReactComponent as Videos} from '../assets/videos.svg';

import {
  UpgradeCallout,
  UpgradeHeader,
  UpgradeSubHeader,
  UpgradeFeaturesTable,
  UpgradeFeaturesTableRow,
  UpgradeFeaturesTableRowIconContainer,
  UpgradeFeaturesTableRowText,
} from './shared';


export const anatomyFeatures = (
  <>
    <UpgradeCallout>
      <UpgradeHeader>
        Get everything you need to ace anatomy in one place
      </UpgradeHeader>
      <UpgradeSubHeader>
        Get instant access to everything below and more 👇
      </UpgradeSubHeader>
    </UpgradeCallout>
    <UpgradeFeaturesTable>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Smile/>
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          <strong>Thousands of practice questions</strong> and <strong>practical exams</strong> you need to ace your exam.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Click />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          <strong>Hundreds of high-yield review videos</strong>, detailed lesson notes, and atlases.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Videos />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          <strong>iOS and Android mobile app</strong> to study on the go. Watch videos, answer questions from wherever.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
    </UpgradeFeaturesTable>
  </>
);
export const anatomyTrialFeatures = (
  <>
    <UpgradeCallout>
      <UpgradeHeader>
        Your free trial ends soon
      </UpgradeHeader>
      <UpgradeSubHeader>
        Continue using Anatomy Bootcamp after your free trial period by upgrading your membership. Imagine having to go back to the old way of studying... 👇
      </UpgradeSubHeader>
    </UpgradeCallout>
    <UpgradeFeaturesTable>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Smile/>
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          You'd miss out on the <strong>thousands of practice questions</strong> and <strong>practical exams</strong> you need to ace your exam.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Click />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          You'd have to go back to disjointed YouTube searches instead of using <strong>our hundreds of high-yield review videos</strong>.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Videos />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          and you'd only be able to get your questions answered during class instead of using <strong>our 24-hour TA support chat</strong>.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
    </UpgradeFeaturesTable>
  </>
);
