import React from 'react';
import PrometricBank from '@bootcamp/web/src/components/PrometricBank';
import {TestNavigatorProvider} from '@bootcamp/web/src/contexts/TestNavigator';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

const PracticeTestWrapper = ({history, match, template=null, testId=null}) => {
  const {IS_OAT_STUDENT} = useUserDataContext();
  const OAT_MAP = {
    "f15c4c19-e7cd-4244-acc5-45dadf497de2": "2570c6c9-5076-4534-8b00-add4ca6402ad",
    "ecd3f6d9-3972-4a63-aa2d-86abc0b0ac36": "6b763a91-de1e-4ee5-82ea-79ddbadc4c4d",
    "e3648af9-7455-4541-a261-79bd0ce3972b": "a0a8e639-a2dc-478b-8531-7ae08f5680c7",
    "554dba23-ba86-4bce-8c8e-7f6903a4440e": "00c837f3-b53d-40e0-abe0-7097bcfcf4a5",
    "7d7e38ed-befd-4d77-8814-7884fcdae11d": "046cbf8d-d1be-444f-a7da-997ceea49dc8",
    "bacaa280-ce5d-43b9-925f-95537900f2ca": "8bccb028-96f8-4e78-9368-df2e0dc3eaeb",
    "7206da8b-963b-40a1-9df0-b63cb85c311b": "648ed263-4fbb-4928-9e97-e84cd6daaf37",
    "f0e25200-b0cf-4bbd-9b68-4388c1b7d451": "9e86637b-efdb-4452-a332-bba4ba58c81f",
    "3a95013b-6bd8-4490-af0d-b1ab84c9c8db": "d746d21e-a13b-4065-a8f3-ecf0bc212887",
    "2604323b-ffe2-465c-aa44-b594dbef8c30": "b6b56746-1ebf-42eb-b479-60db4a3e8124"
  }
  return (
    <TestNavigatorProvider match={match} template={template || 'practiceTest'} testId={IS_OAT_STUDENT ? OAT_MAP[testId] : testId}>
      <PrometricBank history={history} match={match} />
    </TestNavigatorProvider>
  );
}

export default PracticeTestWrapper;