import React, {useMemo, useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {IntercomAPI} from 'react-intercom';

import Highlightable from '../../../../components/Highlightable';
import {useModalContext} from '../../../../contexts/Modal';
import {useUserDataContext} from '../../../../contexts/UserData';
import {useTestNavigatorContext} from '../../../../contexts/TestNavigator';
import QuestionFAQs, {BootcampBot} from '../../../../components/Modal/components/QuestionFAQs';
import useResizeObserver from "use-resize-observer";

import arrow from '@bootcamp/shared/src/assets/nclex/arrow.png';
import correctIcon from '@bootcamp/shared/src/assets/nclex/correct.png';
import incorrectIcon from '@bootcamp/shared/src/assets/nclex/incorrect.png';
import {DraggablePopup} from '../../shared';
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";

const PromptContainer = styled.div`
  display: flex;
  padding: 0px 0px 16px;
  ${({type}) => type?.match('matrix') ? css`padding-bottom: 0px;` : type?.match('highlight') ? css`ul{margin-bottom:0 !important}`:null}
`;
const PromptRoot = styled.div`
  * {
    max-width: 100%;
    font-size: 16px !important;
    line-height: 24px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0;
  }
  p {
    margin: 0;
  }
  ol, ul {
    margin: 1em 0;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  img {
    height: auto !important;
  }
  .fr-highlight {
    cursor: pointer;
    ${({theme}) => theme.mediaQueries.tablet} {
      background: ${({theme}) => theme.colors.neutralsPalette.nclex.lightYellow};
    }
    &:hover {
      background: #FFFF00;
      opacity: 0.5;
    }
  }
  ${({type}) => type?.match('highlight') && css`
    cursor: not-allowed;
  `}
  .fr-highlight-selected {
    background: ${({theme}) => theme.colors.neutralsPalette.nclex.yellow};
    &:hover {
      background: ${({theme}) => theme.colors.neutralsPalette.nclex.yellow};
      opacity: 1;
    }
  }
  .fitb-input {
    border: 1px solid rgb(37, 46, 51);
    background: white;
    height: 24px;
    ::-webkit-inner-spin-button, ::-webkit-inner-spin-button {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }
    margin-top: 16px;
    &:focus {
      outline: none;
    }
  }

  {/* this input is targeting our matrix question types */}
  .matrix-input-container {
    display: flex;
    text-align: left;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;
  }
  .matrix-checkbox-input, .matrix-radio-input {
    min-width: 20px;
    height: 20px;
    margin: 0 ${({theme}) => theme.layouts.spacing.s};
    vertical-align: middle;
  }
  .matrix-score-container, .matrixMultipleChoice-score-container {
    position: absolute;
    top: 0;
    bottom: 0;
    img {
      top: 3px;
      bottom: 0;
      position: absolute;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .multipleResponseGrouping-score-container {
    img {
      margin: 0;
    }
  }
  .score-icon-correct {
    min-width: 18px;
    max-width: 18px;
    margin-left: -18px;
  }
  .score-icon-incorrect {
    min-width: 14px;
    max-width: 14px;
    margin-left: -14px;
  }

  .score-icon-empty {
    min-width: 14px;
    max-width: 14px;
  }

  .multipleResponseGrouping-score-container {
    margin-left: 5px;
    .score-icon-correct {
      margin-left: -4px;
    }
    .score-icon-incorrect {
      margin-left: 0px;
    }
  }

  ${({answered}) => answered && css`
    .fr-highlight {
      background: ${({theme}) => theme.colors.neutralsPalette.nclex.lightYellow};
    }
    .fr-highlight-selected {
      background: ${({theme}) => theme.colors.neutralsPalette.nclex.yellow};
    }
    .fr-highlight-correct:before {
      content: url(${correctIcon});
      background: white;
      padding-right: 6px;
    }
    .fr-highlight-incorrect:before{
      content: url(${incorrectIcon});
      background: white;
      padding-right: 6px;
    }
  `}

  td {
    position: relative !important;
    padding: 5px !important;
    &:first-child {
      text-align: left;
    }
  }
  tr {
    &:first-child {
      background: #e8f2ff !important;
      color: initial !important; */
    }

    &:nth-child(even) {
      background: #f2f2f2 !important;
    }
  }

  ${({type}) => ['matrixMultipleChoice', 'matrix', 'multipleResponseGrouping', 'dropdown', 'dropdownTable', 'dropdownCloze'].includes(type) && css`
    table {
      margin-top: 16px;
      width: 100%;
      ${['dropdown', 'dropdownTable', 'dropdownCloze'].includes(type) && css`
        td {
          padding: 18px !important;
        }
      `}
    }
  `}

  .dropdown-score-wrapper-active {
    display: inline-flex;
    position: relative;
    img {
      position: absolute;
      top: 0px;
      left: 5px;
      bottom: 0;
      margin: auto;
    }

    select {
      padding-left: 24px;
      padding-right: 12px;
      appearance: none;
      background-color: transparent;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBmaWxsPSJncmF5IiBkPSJNMTAsMjMyLjdsNDkwLDUzNC41bDQ5MC01MzQuNUgxMHoiIC8+DQo8L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: right;
      background-size: 12px;
    }
  }
  table .dropdown-score-wrapper-active {
    display: inline-flex;
    width: 100%;
    img {
      position: absolute;
      top: 0px;
      bottom: 0;
      margin: auto;
    }
  }

  table select {
    width: 100%;
  }
  select {
    padding: 0px;
    &:disabled{
      opacity: 1;
      color: #252E33;
    }
  }
  max-width: 100%;
  width: 100%;
  font-size: 18px !important;
  line-height: 1.5em;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  .hanging-indent-table {
    width: auto !important;
    text-align: left;
    margin-left: initial !important;

    tr {
      color: black !important;
      &:first-child {
        background: none !important;
        border: none !important;
      }
      &:nth-child(even) {
        background: none !important;
        border: none !important;
      }
    }

    td {
      font-weight: normal !important;
      background: none !important;
      border: none !important;
      vertical-align: top;
      width: auto !important;
      padding: 5px 10px;
    }
  }
  .unstyled-table {
    tr {
      color: black !important;
      font-weight: normal !important;
      background: white !important;
    }
    td {
      border: none;
    }
  }

`;
const Icon = styled.img`
  width: 14px;
  height: 24px;
  margin: 0px 12px 0 0 !important;
`;

const LastUpdatedText = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.ms} 0;
  font-weight: bold;

  span {
    font-weight: normal;
  }
`;

const TagRow = styled.div`
  display: flex;
  padding: ${({theme}) => theme.layouts.spacing.ms} 0px;
  margin-bottom: 16px;
  `;
const TagWrapper = styled.div`
  flex: 1;
`;
const TagTop = styled.div`
`;
const TagBottom = styled.div`
  font-size: 14px !important;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;

export const Prompt = React.forwardRef((props, ref) => {
  return (
    <PromptContainer type={props?.type}>
      {!props.hideArrow && <Icon src={arrow}/>}
      <PromptRoot ref={ref} {...props}/>
    </PromptContainer>
  );
});

const ChoiceContainer = styled.label`
  min-height: 30px;
  height: auto;
  border-radius: 2px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* background-color: ${({isCorrect, isIncorrect}) => isCorrect ? 'rgba(123, 213, 110, 0.5)' : isIncorrect ? 'rgba(251, 126, 122, 0.5)' : null}; */
  /* font-weight: ${({isCorrect}) => isCorrect && 'bold'}; */
  margin-bottom: 6px;
  user-select: none;
  text-decoration: ${({isCrossed}) => isCrossed ? 'line-through' : 'none'};

  input {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin: 3px ${({theme}) => theme.layouts.spacing.s} 0 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  cursor: ${({showingExplanation}) => showingExplanation ? 'default' : 'pointer'};
`
const Option = styled.input.attrs(({type}) => ({name: 'answer', type}))`
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
`
const LabelText = styled.span`
  padding-left: 5px;
  * {
    max-width: 100%;
    font-size: 16px !important;
    display: inline;
  }
  p {
    margin: 0;
  }
  img {
    height: auto;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
    line-height: 100% !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-dib, .fr-dii {
    margin: 0 !important;
    display: inline !important;
    vertical-align: middle !important;
  }
  max-width: calc(100% - 60px);
  font-size: 16px !important;
  display: inline;
`;

const ScoreIcon = styled.div`
  min-width: 18px;
  max-width: 18px;
  height: 40px;
  margin-right: ${({theme}) => theme.layouts.spacing.s} !important;
  display: flex;
  align-items: flex-start;
  img {
    width: 100%;
    margin-top: ${({isCorrect}) => isCorrect ? '6px' : '4px'};
  }
`;

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.toLowerCase().split('');

const AnswerChoice = ({answer, isCrossed, isSelected, setAnswerState, updateCrossedAnswerIndexes, showingExplanation, answerIndex, type}) => {
  const {searchUserInteractions} = useUserDataContext();
  const [answerText, answerCorrect] = answer;

  const isCorrect = answerCorrect && showingExplanation;
  const isIncorrect = !answerCorrect && isSelected && showingExplanation;

  function handleContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    if (showingExplanation) return;
    isSelected && setAnswerState()
    updateCrossedAnswerIndexes();
  }

  const letteredAnswerText = `${answerText}`;
  const inputType = ['multipleResponse', 'multipleResponseSelectN'].includes(type) ? 'checkbox' : 'radio';
  const prometricDelay = searchUserInteractions('prometricDelay') === true;

  return (
    <ChoiceContainer
      isCorrect={isCorrect}
      isIncorrect={isIncorrect}
      isCrossed={isCrossed && !prometricDelay}
      showingExplanation={showingExplanation}
      onContextMenu={handleContextMenu}>
      <ScoreIcon isCorrect={isCorrect}>
        {showingExplanation ? <img src={isCorrect ? correctIcon : isSelected ? incorrectIcon : null}/> : null}
      </ScoreIcon>
      <Option
        type={inputType}
        disabled={showingExplanation}
        checked={isSelected}
        onClick={() => {
          setAnswerState();
          isCrossed && !prometricDelay && updateCrossedAnswerIndexes();
        }}
      />
      <LabelText style={{marginRight: '16px'}}>{answerIndex+1}.</LabelText>
      {useMemo(() => <LabelText className={'fr-view'} dangerouslySetInnerHTML={{__html: letteredAnswerText}} />, [letteredAnswerText])}
    </ChoiceContainer>
  )
}

const AnswerBoxContainer = styled.form`
  border-radius: 5px;
  clear: both;
  width: 100%;
`;

export const AnswerBox = ({
  answers,
  answerState,
  setAnswerState,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
  type,
}) => {

  const choiceLimit = answers.filter(([text, correct]) => !!correct).length;

  return (
    <AnswerBoxContainer>
      {answers.map((answer, index) => {
        return (
          <AnswerChoice
            key={`answerindex-${index}`}
            setAnswerState={() => ['multipleResponse', 'multipleResponseSelectN'].includes(type)
              ? setAnswerState(answers => answers.includes(index) ? answers.filter(i => i !== index) : type === 'multipleResponseSelectN' && answers.length === choiceLimit ? answers : [...answers, index])
              : answerState.includes(index) ? setAnswerState([]) : setAnswerState([index])}
            updateCrossedAnswerIndexes={() => updateCrossedAnswerIndexes(index)}
            isSelected={answerState?.length ? answerState.includes(index) : answerState === index}
            isCrossed={crossedAnswerIndexes.indexOf(index) > -1}
            answer={answer}
            showingExplanation={showingExplanation}
            answerIndex={index}
            type={type}
          />
        )
      })}
    </AnswerBoxContainer>
  )
}

const SolutionRoot = styled.div`
  * {
    max-width: 100%;
    font-size: 16px !important;
    line-height: 24px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0;
  }
  p {
    margin: 0;
    text-indent: 0 !important;
    padding: 0 !important;
  }
  ol, ul {
    margin: 1em 0;
    padding-left: 40px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  hr {
    margin: 40px 0 30px;
    border: 0;
    border-bottom: 1px solid #eee;
    height: 1px;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  max-width: 100%;
  font-size: 16px !important;
  line-height: 1.6em;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  img {
    height: auto !important;
  }
  .img-airtable-import {
    max-width: 75%;
  }
  table {
    margin: 0 auto;
  }
  blockquote.callout {
    padding: 15px;
    margin: 0 0 1em 0;
    font-family: 'proxima-nova', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #F9f9f9;
    color: #333;
    border-left: solid 5px #0F6292;
  }
  blockquote.callout p {
      margin-bottom: 0 !important;
  }
  .toggle-trigger {
    color: grey;
    text-decoration: underline;
  }
  table {
    margin: 0 auto;
  }
  .fr-hyperlink {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    font-weight: normal;
    &:active {
      color: red;
    }
  }
`
const SolutionHeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.light};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
const SolutionHeaderWrapper = styled.div`
  position: relative;

  * {
    line-height: 24px !important;
  }
`;
const SolutionHeader = styled.div`
  padding: 8px ${({theme}) => theme.layouts.spacing.m};
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.light};
  border-radius: 8px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;
const SolutionBox = styled.div`
  border-radius: 5px;
  width: 100%;
  position: relative;
  padding-bottom: 72px;

  td {
    &:first-child {
      text-align: left;
    }
  }
  tr {
    &:first-child {
      background: #e8f2ff !important;
      color: initial !important; */
    }

    &:nth-child(even) {
      background: #f2f2f2 !important;
    }
  }
`
const TopicText = styled.span`
  display: block;
  font-weight: 600;
  margin: 1em 0em;
`;
const IntercomButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;

  > div {
    font-size: initial !important;
  }
`;

const IntercomButton = styled.div`
  font-family: proxima-nova;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: .75em;
  border: 1px solid #e1e8ed;
  border-radius: 1em;
  margin-right: 2em;
  color: #777;
  text-decoration: none;
  flex-grow: 1;
  text-align: left;
  cursor: pointer;
  margin-right: 2em;
  &:last-child {
    margin-right: 0;
  }
  opacity: .75;
  transition: opacity .4s;
  &:hover {
    opacity: 1;
    transition: opacity .4s;
  }
`;
const HyperlinkModalContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
  overflow: auto;
`;
const BorderBlock = styled.div`
  height: 4px;
  position: absolute;
  bottom: -2px;
  left: 1px;
  right: 1px;
  background: white;
`;

function getQuestionReviewUrl(questionId, answerIndex) { // to load question review route for tbc locally use /question-review instead
  return `https://admin.bootcamp.com/tbc-question-review?questionId=${questionId}&answerIndex=${answerIndex}`;
}
const HyperlinkImage = styled.div`
  p, img {
    width: auto !important;
    max-width: 100%;
    max-height: calc(${({containerHeight, defaultHeight}) => containerHeight ? `${containerHeight}px` : '80vh'} - 83px);
    /* max-height: calc(80vh - 83px); */
    margin: 0;
    height: auto !important;
  }
  p {
    align-self: flex-start;
    width: 90% !important;
    margin: auto;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  color: black;
  ${({tableWidth}) => tableWidth && css`width: 100%; max-width: 950px !important; overflow: auto !important; margin: auto; justify-content: flex-start !important; padding: 60px 0px;`}

  table {
    border-spacing: 0;
    border-collapse: collapse;
    min-width: 70% !important;
    margin: 0 !important;
    background: white;
    color: ${({theme}) => theme.colors.neutralsPalette.darkTextColor};
  }
  td {
    padding: 4px;
    position: relative !important;
    border: 1px solid #ddd;
    &:first-child {
      text-align: left;
    }
  }
  tr {
    &:first-child {
      background: #e8f2ff !important;
      color: initial !important; */
    }

    &:nth-child(even) {
      background: #f2f2f2 !important;
    }
  }
`;

const CitationContainer = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.ms} 0;
  p {
    margin: 0;
  }
`;
const CitationHeader = styled.span`
  text-decoration: underline;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  font-weight: bold;
  cursor: pointer;
`;

const CitationsText = ({text}) => {
  const [toggled, setToggled] = useState(false);

  return (
    <CitationContainer>
      <CitationHeader onClick={() => setToggled(!toggled)}>View Source(s): </CitationHeader>
      {toggled && <div dangerouslySetInnerHTML={{__html: text?.replace(/<a /g, '<a target="_blank" ')}}></div>}
    </CitationContainer>
  );
}

export const Solution = ({
  solution,
  citations,
  answerData,
  correctPercentage,
  score,
  didNotAnswer,
  answerState,
  showFeedback,
  questionId,
  tags,
  didMark,
  time,
  updatedAt,
  lastUpdated,
  hyperlinks,
  setHighlights,
  highlights,
  enableHighlighting,
  solutionRef,
}) => {
  const {modalDispatch} = useModalContext();
  const {subject} = tags.find(tag => tag.contentType === 'nclexSubject') || {};
  const {topic, system} = tags.find(tag => tag.subject === subject && !!tag.topic && !!tag.system && tag.contentType !== 'auxiliaryAnatomy') || {};
  const questionReviewUrl = getQuestionReviewUrl(questionId, answerState);
  const parsedSolution = solution;
  const {variables: {customTestConfig}} = useTestNavigatorContext();

  useEffect(() => {
    const handler = event => {
      if (event.target.classList.contains('toggle-trigger')) {
        event.target.parentNode.querySelector('.toggle-content').style.display = 'block';
      }
    }
    solutionRef.current?.addEventListener('click', handler);
    return () => {
      solutionRef.current?.removeEventListener('click', handler);
      modalDispatch({type: 'close'})
    }
  }, []);
  useEffect(() => {
    const handler = event => {
      const hyperlinkNode = event?.target?.parentNode?.classList?.contains('fr-hyperlink')
        ? event.target.parentNode
        : event?.target?.classList?.contains('fr-hyperlink')
        ? event.target
        : null;

      if (hyperlinkNode || event.target.tagName === 'IMG') {

        const associatedHyperlink = event.target.tagName === 'IMG'
          ? event.target.outerHTML
          : hyperlinks?.[alphabet.indexOf(hyperlinkNode.dataset.hrefIndex?.toLowerCase())];

        const HyperlinkRenderer = ({close}) => {
          const {ref, height} = useResizeObserver();
          if (associatedHyperlink?.startsWith('<table')) {
            return (
              <DraggablePopup fullSize innerRef={ref} close={close} headerContent={<div>Exhibit: Display</div>} footerContent={<div/>}>
                <HyperlinkImage tableWidth={associatedHyperlink?.startsWith('<table')} containerHeight={height} dangerouslySetInnerHTML={{__html: associatedHyperlink}} />
              </DraggablePopup>
            )
          }
          return (
            <DraggablePopup fullSize innerRef={ref} close={close} headerContent={<div>Exhibit: Display</div>} footerContent={<div/>}>
              <TransformWrapper doubleClick={{mode: 'reset'}}>
                <TransformComponent
                  wrapperStyle={{minHeight: '200px', overflow: 'hidden', width: '100%', height: '100%'}}
                  contentStyle={{background: 'url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-small.gif") no-repeat center', width: '100%', minHeight: '100%', justifyContent: 'center', alignItems: 'center'}}
                  >
                  <HyperlinkImage tableWidth={associatedHyperlink?.startsWith('<table')} containerHeight={height} dangerouslySetInnerHTML={{__html: associatedHyperlink}} />
                </TransformComponent>
              </TransformWrapper>
            </DraggablePopup>
        )}
        modalDispatch({
          type: 'open',
          component: HyperlinkRenderer,
          enableClickClose: false,
          modalContainerStyle: theme => `background: transparent;`
        })
      }
    }
    solutionRef.current?.addEventListener('click', handler);
    return () => solutionRef.current?.removeEventListener('click', handler);
  }, [hyperlinks]);

  const citationsBox = citations ? `
    <br />
    <div class="closed toggle-trigger-container">
      <h4 class="toggle-trigger" style="cursor: pointer;">View Source(s)</h4>
      <div class="toggle-content" style="display: none;">
      ${citations}
      </div>
    </div>
  ` : '';

  const intercomQuestionString = `I have content questions or feedback on ${subject} ${questionReviewUrl}. Specifically:`;
  const hideSystemTopicTags = !customTestConfig?.config
  return (
    <SolutionBox>
      <SolutionHeaderContainer>
        <SolutionHeaderWrapper>
          <SolutionHeader>Explanation</SolutionHeader>
          <BorderBlock/>
        </SolutionHeaderWrapper>
      </SolutionHeaderContainer>
      <Highlightable setHighlights={setHighlights} highlights={highlights} disable={!enableHighlighting} disableToolTip>
        <SolutionRoot ref={solutionRef} className={'fr-view'} dangerouslySetInnerHTML={{ __html: parsedSolution }} />
      </Highlightable>
        {lastUpdated && <LastUpdatedText>Version <span>{new Date().getFullYear()}</span></LastUpdatedText>}
        {citations && <CitationsText text={citations}/>}
        <TagRow>
          <TagWrapper>
            <TagTop>{subject}</TagTop>
            <TagBottom>Subject</TagBottom>
          </TagWrapper>
          {!hideSystemTopicTags && [<TagWrapper>
            <TagTop>{system}</TagTop>
            <TagBottom>System</TagBottom>
          </TagWrapper>,
          <TagWrapper>
            <TagTop>{topic}</TagTop>
            <TagBottom>Topic</TagBottom>
          </TagWrapper>]}
        </TagRow>
        {showFeedback && (
          <BootcampBot questionId={questionId} intercomQuestionString={intercomQuestionString} />
        )}
        <IntercomButtons>
          <IntercomButton onClick={() => IntercomAPI('showNewMessage', intercomQuestionString)}>Still have a question? Contact our team →</IntercomButton>
        </IntercomButtons>
      </SolutionBox>
  );
}

export const QuestionHeader = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  p {
    margin: 0;
    line-height: 24px;
  }
`;

export const SubmitButton = styled.div.attrs(props => ({children: <span><span style={{textDecoration: 'underline'}}>S</span>ubmit</span>}))`
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;

  span {
    font-size: initial !important;
  }

  background: #006DAA;
  margin: 16px 0px;
  cursor: pointer;
  ${({submitPadding}) => submitPadding && css`
    margin-left: 26px;
  `}
  &:hover {
    color: #FFFF00;
    path {
      fill: #FFFF00;
    }
    rect {
      stroke: #FFFF00;
    }
  }
`;

export const ScoreIconContainer = styled.div`
  display: ${({hide}) => hide ? 'none' : 'flex'};
  align-items: center;
  margin-right: 8px;

  img {
    width: ${({correct}) => correct ? '18px' : '14px'};
  }
`;

export const CorrectIcon = styled.img.attrs(props => ({src: correctIcon}))`
`;

export const IncorrectIcon = styled.img.attrs(props => ({src: incorrectIcon}))`
`;