import React, {useEffect} from 'react';
import useDocumentTitle from '@rehooks/document-title';
import {useTestContext} from '../../../contexts/TestBase';
import {useUserDataContext} from '../../../contexts/UserData';

import QuestionBank from '../../../components/QBank';
import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {colors} from '@bootcamp/shared/src/styles/theme';

import USMLEBank from '../../../components/USMLEBank';
import PrometricBank from '../../../components/PrometricBank';
import NCLEXBank from '../../../components/NCLEXQBank';

const qbankComponentByBootcamp = {
  'med-school': USMLEBank,
  'nclex': NCLEXBank,
  'inbde': PrometricBank,
};

const getAllTestIds = (config) => config
  .classrooms
  .filter(({name}) => name !== 'Simulation Exam')
  .reduce((acc, { contentTypes }) => [
    ...acc,
    ...contentTypes
      .filter(({name}) => name !== 'Mental Dental Bites')
      .reduce((acc, { content }) => [
        ...acc,
        ...content
          .reduce((acc, { name, content }) => ['Question Banks', 'Identify Structures', 'Lecture Style Questions'].includes(name) // only use QB questions for Quick Study
            ? [
              ...acc,
              ...content.filter(({ name }) => !name.match('Passage')) // filter out passage banks
                .reduce((acc, { lessons, lectures, id }) => id ? [...acc, id] : (lessons || lectures)
                  ? [
                    ...acc,
                    ...([...(lessons || []), ...(lectures || [])]).map(({ testId, id }) => testId || id)
                  ]
                  : acc, [])
            ]
            : acc, [])
      ], [])
  ], [])

const QuickReview = props => {
  const {config} = useBootcampConfigContext();

  const {bootcamp, cognitoUser, userModel} = useUserDataContext();
  const username = cognitoUser?.attributes?.name || cognitoUser?.attributes?.email || userModel?.id;
  const {test, composeQuickReview, composeQuickStudy, composeCustomTest} = useTestContext();

  useDocumentTitle(`${config.meta.siteTitle} | Quick Study`);

  useEffect(() => {
    if (!userModel?.id || !config?.meta?.subjectIds || !!test) return;

    if (['med-school', 'dental-school'].includes(bootcamp)) {
      composeQuickReview(config.meta.subjectIds);
    } else if (['inbde'].includes(bootcamp)) {
      composeCustomTest({
        testTitle: 'Daily Warmup',
        testIds: getAllTestIds(config),
        masteryLevel: 'all',
        type: 'quickReview',
        testLength: 10,
        bootcamp: 'inbde',
        tutorMode: true,
        timed: false,
      });
    } else {
      composeQuickStudy(config.meta.subjectIds);
    }

  }, [userModel?.id, config]);

  const Bank = qbankComponentByBootcamp[bootcamp] || QuestionBank;
  const contextProps = ['inbde'].includes(bootcamp)
    ? {template: 'customTest', skipStartBlock: true}
    : {template: 'quickReview', skipStartBlock: true};

  return (
    <TestNavigatorProvider
      {...props}
      {...contextProps}
    >
      <Bank themePalette={colors.brandPalette.orange} username={username} enableBookmarking />
    </TestNavigatorProvider>
  )
}

export default QuickReview;
