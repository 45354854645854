import React from 'react';
import styled, {css} from 'styled-components';
import {Column, Row} from '../Grid';

const GutterContainer = styled(Column)`
  justify-content: center;
  ${({background}) => {
    switch (background) {
      case 'white':
        return css`
          background-color: white;
        `
      case 'blue':
        return css`
          background-color: #0F6292;
        `
      case 'grey':
        return css`
          background-color: #F5F8FA;
        `
      default:
        return
    }
  }}
`;

const GutterInner = styled.div`
  max-width: 1000px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  position: relative;
`;

const GutterWrapper = ({children, ...props}) => (
  <GutterContainer {...props}>
    <GutterInner>
      {children}
    </GutterInner>
  </GutterContainer>
);

const GutterRow = ({children, ...props}) => (
  <GutterWrapper {...props}>
    <Row>
      {children}
    </Row>
  </GutterWrapper>
);

const GutterColumn = ({children, ...props}) => (
  <GutterWrapper {...props}>
    <Column>
      {children}
    </Column>
  </GutterWrapper>
);

const SpacedGutterRow = styled(GutterRow)`
  ${GutterInner} > ${Row} {
    align-items: center;
    justify-content: space-between;
    ${({rowStyles}) => rowStyles}
  }
`;

const titleBarPadding = {
  small: 1.5,
  medium: 2,
  large: 3
};

const TitleBar = styled(Row)`
  align-self: center;
  justify-content: space-between;
  padding: ${({titleSize}) => titleSize ? titleBarPadding[titleSize] : titleBarPadding['large']}em 0px;
  width: 100%;
  padding-top: ${({titleSize}) => titleSize ? titleBarPadding[titleSize] * 2 : titleBarPadding['large']}em;
  /* Consecutive TitleBars with titleSize will have padding-top doubled */
  &:first-child {
    padding-top: ${({titleSize}) => titleSize ? titleBarPadding[titleSize] : titleBarPadding['large']}em;
  }
`;

export {
  GutterInner,
  GutterContainer,
  GutterWrapper,
  TitleBar,
  SpacedGutterRow,
  GutterColumn,
  GutterRow
}
