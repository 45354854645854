import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {IntercomAPI} from 'react-intercom';

import Carousel, {CarouselContainer} from '../../Carousel';
import {preventDefault} from '../helpers';
import {Row} from '../../Grid';
import {H2, Body3} from '../../Typography';
import {Button} from '../../Branding/Buttons';
import useScrollBlock from '../../../hooks/useScrollBlock';
import {useUserDataContext} from '../../../contexts/UserData';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';

const SuperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1152px;
  min-height: 640px;
  border-radius: 10px;
  line-height: normal;

  ${({theme}) => theme.mediaQueries.laptop} {
    height: auto;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    height: 100%;
  }
`;
const UpgradeContainer = styled(Row)`
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  transition: transform .4s, opacity .75s;
  ${({visible}) => visible
    ? `
      transform: none;
      opacity: 1;
    `
    : `
      transform: translateY(100%);
      opacity: 0;
    `
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    ${({fixedOnMobile}) => fixedOnMobile && `
      flex-direction: column;
      height: auto;
    `}
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    ${({fixedOnMobile, theme}) => !fixedOnMobile
      ? `
        margin-top: auto;
        height: auto;
        padding-bottom: 64px;
        background: white;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
      `
      : `
        margin-top: ${theme.layouts.spacing.xxl};
      `
  }
  }
`;

const Side = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({theme}) => theme.layouts.spacing.xxl};
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.xl} ${({theme}) => theme.layouts.spacing.m};
  }
`

const Callout = styled(Side)`
`;

const CalloutHeading = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const CalloutSubheading = styled(Body3)`
  font-weight: 400;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;

const FeaturesList = styled.div`
  min-height: 264px;
  width: 100%;
  margin: ${({theme}) => theme.layouts.spacing.xl} 0;
  ${({theme}) => theme.mediaQueries.laptop} {
    min-height: 0px;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;

const FeatureRow = styled(Row)`
  height: auto;
  align-items: center;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  &:last-child {
    margin-bottom: 0;
  }
`;

const FeatureIcon = styled.div`
  width: 48px;
  height: 48px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;

const FeatureText = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  text-align: left;
`;

const Interactions = styled(Row)`
  height: auto;
  margin-top: ${({theme}) => theme.layouts.spacing.xl};
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

const InteractionItem = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  padding: 0px ${({theme}) => theme.layouts.spacing.l};
  &:first-child {
    border-right: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  }
  &:last-child {
    border-right: none;
  }
  cursor: pointer;
`;

const MobileInteraction = styled(InteractionItem)`
  margin-top: ${({theme}) => theme.layouts.spacing.xl};
`;

const Reviews = styled(Side)`
  background: ${({theme}) => theme.colors.brandPalette.indigo.dark};
  ${({theme}) => theme.mediaQueries.tablet} {
    padding-bottom: 100px;
  }
`;

const ReviewHeading = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`

const ReviewCarousel = styled(Carousel)`
  height: auto;
  ${CarouselContainer} {
    min-height: 360px;
  }
`;

const ButtonContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.laptop} {
    border-radius: 0px 0px 10px 10px;
    z-index: 2;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    position: fixed;
    bottom: 10%;
    left: 10%;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0px;
    padding-bottom: ${({theme}) => theme.layouts.spacing.m};
    padding-top: ${({theme}) => theme.layouts.spacing.xxl};
  }
`;

const StyledButton = styled(Button)`
  ${({theme}) => theme.mediaQueries.tablet} {
    ${({fixedOnMobile, theme}) => fixedOnMobile && `
      svg > path {
        fill: ${theme.colors.brandPalette.indigo.dark};
      }
      background: white;
      color: ${theme.colors.brandPalette.indigo.dark};
      &:hover {
        background: rgba(255,255,255,.9);
      }
    `}
  }
`;

const BootcampLockdown = ({className, close, config, isExpiredSubscription, isPremiumFeature, fixedOnMobile=true, showClose=false, showInteractions=true, showReviews=true, showFeatures=true, headerText, subheaderText, upgradeText}) => {
  const [visible, setVisible] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [{isLaptop, isTablet}, setBreakpoints] = useState({isLaptop: document.documentElement.clientWidth < 1024, isTablet: document.documentElement.clientWidth < 768});
  const {bootcamp} = useUserDataContext();

  const {push} = useHistory();

  const updateBreakpoints = () => setBreakpoints({isLaptop: document.documentElement.clientWidth < 1024, isTablet: document.documentElement.clientWidth < 768});

  useEffect(() => {
    setVisible(true);
    blockScroll();
    window.addEventListener('resize', updateBreakpoints);
    return () => {
      window.removeEventListener('resize', updateBreakpoints);
      allowScroll();
      close();
    }
  }, []);

  return (
    <SuperContainer className={className}>
      <UpgradeContainer onClick={preventDefault} visible={visible} fixedOnMobile={fixedOnMobile}>
        <Callout>
          <CalloutHeading>
            {headerText || (isPremiumFeature ? `We’re your secret to getting ahead in anatomy class` : isExpiredSubscription ? 'Your subscription has ended' : 'Your free trial has ended')}
          </CalloutHeading>
          <CalloutSubheading>
            {subheaderText || (isPremiumFeature ? `Get instant access to everything on Anatomy Bootcamp by upgrading your membership 👇` : `Upgrade your membership in order to keep access to:`)}
          </CalloutSubheading>
          {(showFeatures || isTablet) &&
            <FeaturesList
              children={config?.features?.map(({icon, text}, index) => (
                <FeatureRow key={`feature-${index}`}>
                  <FeatureIcon children={icon} />
                  <FeatureText children={text} />
                </FeatureRow>
              ))}
            />
          }
          {isTablet && fixedOnMobile &&
            <MobileInteraction onClick={() => {
              push('/account');
              IntercomAPI('showNewMessage', `I have a question about my account: `);
            }}>
              Send us a message
            </MobileInteraction>
          }
          <ButtonContainer fixedOnMobile={fixedOnMobile}>
            <StyledButton
              children={upgradeText || 'Upgrade Now'}
              color={isTablet ? 'indigo' : 'indigo'}
              size={'medium'}
              fixed={true}
              fixedOnMobile={fixedOnMobile}
              onClick={() => window.location = `https://bootcamp.com/${bootcamp}/upgrade`} // useHistory
              iconConfig={{
                position: 'left',
                icon: Badge,
                size: 12,
              }}
            />
          </ButtonContainer>
          {
            showInteractions && (
              <Interactions>
                <InteractionItem onClick={() => {
                  push('/account');
                  IntercomAPI('showNewMessage', `I have a question about my account: `);
                }}>
                  Send us a message
                </InteractionItem>
                <InteractionItem onClick={() => push('/account')}>
                  View your account
                </InteractionItem>
              </Interactions>
            )
          }
          {
            showClose && (
              <Interactions>
                <InteractionItem onClick={close}>
                  Go Back
                </InteractionItem>
              </Interactions>
            )
          }

        </Callout>
        {showReviews &&
          <Reviews>
            <ReviewHeading>
              {config.reviewHeading}
            </ReviewHeading>
            {isLaptop ? config.reviews : <ReviewCarousel children={config.reviews} />}
          </Reviews>
        }
      </UpgradeContainer>
    </SuperContainer>
  );
}

export default BootcampLockdown;
