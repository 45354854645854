import styled from 'styled-components';

import {H1, H2, H4, H6, Body2} from '../../../components/Typography';
import {Row} from '../../../components/Grid';
import {Button} from '../../../components/Branding/Buttons';

import {CloudDownloadOutline} from '@styled-icons/evaicons-outline/CloudDownloadOutline';

export const OverviewText = styled(H1)`
  font-size: 36px;
  line-height: 120%;
  color: white;
  font-size: 48px;
  line-height: normal;
  letter-spacing: 0.25px;
  ${({theme}) => theme.mediaQueries.laptop} {
    font-size: 36px;
    line-height: 44px;
  }
`;
export const InstructorDetails = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  margin: 32px 0px;
  height: auto;
`;
export const InstructorDetailsImage = styled.img`
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  border: 2px solid white;
`;
export const InstructorDetailsText = styled.div`
  margin-left: 32px;
  max-width: 640px;
`;
export const InstructorDetailsTextHeading = styled(H2)`
  margin-bottom: 16px;
  color: white;
`;

export const StyledBody2 = styled(Body2)`
  color: ${({theme}) => theme.overlays.opacity.light._500};
`;

export const GuidesContainer = styled(Row)`
  height: auto;
`;
export const GuideWell = styled.div`
  background: ${({theme}) => theme.colors.special.naturalDark};
  border: .5px solid ${({theme}) => theme.overlays.opacity.dark._100};
  box-shadow: ${({theme}) => theme.elevation.inner.progressLight};
  border-radius: 8px;
  padding: ${({theme}) => theme.layouts.spacing.l};
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }
`;

export const GuideDownloadButton = styled(Button)`
  margin-top: 24px;
`;
export const StyledDownloadIcon = styled(CloudDownloadOutline).attrs(props => ({size: 16}))`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-right: 4px;
`;

export const Testimonial = styled.div`
  margin-top: 32px;
`;
export const TestimonialRow = styled(Row)`
  height: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
`;
export const TestimonialImage = styled.img`
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 100px;
`;
export const TestimonialAuthor = styled.div`
  margin-left: 10px;
  text-align: left;
`;
export const TestimonialName = styled(H4)`
  color: white;
  margin-bottom: 4px;
`;
export const TestimonialByline = styled(H6)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;
