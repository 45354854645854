import React from 'react';
import styled from 'styled-components';
import {H1, Body2} from '@bootcamp/web/src/components/Typography';
import {ReactComponent as BootcampLogo} from '@bootcamp/shared/src/assets/svg/bootcamp_white_text_logo.svg';

const StyledBootcampLogo = styled(BootcampLogo)`
  max-width: 250px;
  margin-right: 10px;
`;

const Background = styled.div`
  background: ${({theme}) => theme.colors.brandPalette.royal.gradient};
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled(H1)`
  color: white;
  display: flex;
  align-items: center;
`;

const Status = styled(Body2)`
  color: white;
`;

const Maintenance = ({adminConfig}) => {
  return (
    <Background>
      <Heading><StyledBootcampLogo /> Status Page</Heading>
      {adminConfig?.statusHTML ? <Status dangerouslySetInnerHTML={{__html: adminConfig.statusHTML}}/> : (
        <Status>
          Bootcamp.com is undergoing maintenance. Pardon our dust!
        </Status>
      )}
    </Background>
  )
}

export default Maintenance;
