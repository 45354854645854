import styled from 'styled-components';
import '@bootcamp/shared/src/assets/fonts/proxima-nova/proxima-nova.css';

export const Hero = styled.h1`
  font-family: proxima-nova;
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 64px;
  line-height: 72px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: 56px;
    line-height: 64px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const H1 = styled.h1`
  font-family: proxima-nova;
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: ${({theme}) => theme.font.size.xsuper};
  line-height: ${({theme}) => theme.font.size.xsuper.split('px')[0] * theme.font.multipliers.small}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.super};
    line-height: ${({theme}) => theme.font.size.super.split('px')[0] * theme.font.multipliers.small}px;
  }
`;

export const H2 = styled.h2`
  font-family: proxima-nova;
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: ${({theme}) => theme.font.size.xxlarge};
  line-height: ${({theme}) => theme.font.size.xxlarge.split('px')[0] * theme.font.multipliers.small}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.xlarge};
    line-height: ${({theme}) => theme.font.size.xlarge.split('px')[0] * theme.font.multipliers.small}px;
  }
`;

export const H3 = styled.h3`
  font-family: ${() => window.location.pathname.includes('inbde/custom-test') ? 'Arial' : 'proxima-nova'};
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: ${({theme}) => theme.font.size.regular};
  line-height: ${({theme}) => theme.font.size.large.split('px')[0] * theme.font.multipliers.small}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.size.regular.split('px')[0] * theme.font.multipliers.small}px;
  }
`;

export const H4 = styled.h4`
  font-family: proxima-nova;
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: ${({theme}) => theme.font.size.medium};
  line-height: ${({theme}) => theme.font.size.medium.split('px')[0] * theme.font.multipliers.small}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.small};
    line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.small}px;
  }
`;

export const H5 = styled.h5`
  font-family: proxima-nova;
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: ${({theme}) => theme.font.size.small};
  line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.small}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.smaller};
    line-height: ${({theme}) => theme.font.size.smaller.split('px')[0] * theme.font.multipliers.small}px;
  }
`;

export const H6 = styled.h6`
  font-family: proxima-nova;
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: ${({theme}) => theme.font.size.smaller};
  line-height: ${({theme}) => theme.font.size.smaller.split('px')[0] * theme.font.multipliers.small}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.smallest};
    line-height: ${({theme}) => theme.font.size.smallest.split('px')[0] * theme.font.multipliers.small}px;
  }
`;

export const Body1 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.xlarge};
  line-height: ${({theme}) => theme.font.size.xlarge.split('px')[0] * theme.font.multipliers.large}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.regular};
    line-height: ${({theme}) => theme.font.size.regular.split('px')[0] * theme.font.multipliers.large}px};
  }
`;

export const Body2 = styled.p`
  font-family: ${() => window.location.pathname.includes('inbde/custom-test') ? 'Arial' : 'proxima-nova'};
  font-size: ${({theme}) => theme.font.size.regular};
  line-height: ${({theme}) => theme.font.size.regular.split('px')[0] * theme.font.multipliers.large}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.size.medium.split('px')[0] * theme.font.multipliers.large}px;
  }
`;
export const Body3 = styled.p`
  font-family: ${() => window.location.pathname.includes('inbde/custom-test') ? 'Arial' : 'proxima-nova'};
  font-size: ${({theme}) => theme.font.size.medium};
  line-height: ${({theme}) => theme.font.size.medium.split('px')[0] * theme.font.multipliers.large}px};
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.small};
    line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.large}px};
  }
`;
export const Body3Mobile = styled(Body3)`
  font-size: ${({theme}) => theme.font.size.small};
  line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.large}px};
`;
export const Body4 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.small};
  line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.large}px};
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};


  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.smaller};
    line-height: ${({theme}) => theme.font.size.smaller.split('px')[0] * theme.font.multipliers.large}px};
  }
`;


export const Label1 = styled.div`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.larger};
  line-height: ${({theme}) => theme.font.size.larger.split('px')[0] * theme.font.multipliers.none}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  text-transform: uppercase;

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.regular};
    line-height: ${({theme}) => theme.font.size.regular.split('px')[0] * theme.font.multipliers.none}px;
  }
`;

export const Label2 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.regular};
  line-height: ${({theme}) => theme.font.size.regular.split('px')[0] * theme.font.multipliers.none}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  text-transform: uppercase;
  letter-spacing: 1.5px;

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.small};
    line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.none}px;
  }
`;

export const Label3 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.small};
  line-height: ${({theme}) => theme.font.size.small.split('px')[0] * theme.font.multipliers.none}px;
  margin: 0;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  text-transform: uppercase;
  letter-spacing: 1px;

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.smaller};
    line-height: ${({theme}) => theme.font.size.smaller.split('px')[0] * theme.font.multipliers.none}px
  }
`;

export const KeyboardShortcutLabel = styled.div`
  width: 18px;
  height: 18px;
  background: #F6F6F6;
  border-radius: 4px;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #85868E;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
`
