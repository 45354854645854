import React, {useEffect, useRef, memo} from 'react';
import styled, {css} from 'styled-components';

import {Column, Row} from '../Grid';
import {Loader} from '../Branding';
import {H4} from '../Typography';
import {useTestNavigatorContext} from '../../contexts/TestNavigator';
import {useUserDataContext} from '../../contexts/UserData';

import {useTimerContext} from '../../contexts/TestTimer';

import {Checkmark} from '@styled-icons/fluentui-system-filled/Checkmark';
import {Dismiss as X} from '@styled-icons/fluentui-system-filled/Dismiss';
import {CircleHalfFill as Partial} from '@styled-icons/fluentui-system-filled/CircleHalfFill'
import {RadioButtonOn} from '@styled-icons/evaicons-solid/RadioButtonOn';
import {RadioButtonOff} from '@styled-icons/evaicons-solid/RadioButtonOff';
import {Bookmark} from '@styled-icons/heroicons-solid/Bookmark';
import useScrollBlock from '../../hooks/useScrollBlock';
import MasteryIcon from '../../bootcamps/components/MasteryIcon';
import {ReactComponent as BookmarkTag} from '@bootcamp/shared/src/assets/icons/MasteryIcon/bookmark-icon.svg';
import {ChartBarSquare as ChartSquareBar} from '@styled-icons/heroicons-outline/ChartBarSquare';
import {MagnifyingGlass as Search} from '@styled-icons/heroicons-outline/MagnifyingGlass';

import {updateQuestionProgressData} from '@bootcamp/web/src/helpers';
import {getQuestionParts} from '@bootcamp/shared/src/util';

import {Label2} from '@bootcamp/web/src/components/Typography/next';
import baseTheme from '@bootcamp/shared/src/styles/theme';


import Top from './SidebarTop';

const Backdrop = styled.div`
  height: 100%;
  width: ${({showingSidebar}) => showingSidebar ? '256px' : '0%'};
  background: ${({showingSidebar, theme}) => showingSidebar ? theme.overlays.opacity.dark._300 : 'transparent'};
  transition: width .3s;
  ${({theme}) => theme.mediaQueries.laptop} {
    width: ${({showingSidebar}) => showingSidebar ? '100%' : '0%'};
    transition: background .2s ease-in 0s${({showingSidebar}) => !showingSidebar && `, width .1s ease-in .3s`};
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
  }
`;

const Container = styled(Column)`
  width: 256px;
  background: ${({background, theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfacePrimary : background};
  height: 100%;

  ${({theme}) => theme.mediaQueries.laptop} {
    width: 256px;
    box-shadow: ${({theme}) => theme.elevation.shadow.high};
    transition: left .2s ease-in 0s${({showingSidebar}) => !showingSidebar && `, height .1s ease-in .3s`};
    position: absolute;
    z-index: 10;
    height: ${({showingSidebar}) => showingSidebar ? '100vh' : '0px'};
    top: 0;
    left: ${({showingSidebar}) => showingSidebar ? 0 : -288}px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
`;

// CONTENTS

const ContentElementWrapper = styled(Column)`
  border-radius: 8px;
  overflow: hidden;
`;
const QuestionTitle = styled.div`
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.overlays.opacity.light._100};
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: ${({theme}) => theme.overlays.opacity.light._400};
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  ${({isAnswered}) => isAnswered && css`
    background: ${({theme}) => theme.overlays.opacity.dark._50};
    border: 1px solid rgba(0, 0, 0, 0.05);
  `}

  ${({isCurrent}) => isCurrent && css`
    background: white;
  `}
`;

const ContentElementContainer = styled.div`
  height: auto;
  width: 100%;
  padding: 4px ${({theme, withPadding}) => withPadding ? theme.layouts.spacing.m : '0px'};
  flex-shrink: 0;
  cursor: pointer;
  position: relative;

  &:hover {
    ${QuestionTitle} {
      ${({isCurrent}) => !isCurrent && `background: rgba(255, 255, 255, 0.2);`}
    }
  }
`;

const TopAreaWrapper = styled(Column)`
  height: auto;
`;

const ContentLabel = styled(Row)`
  align-items: center;
  flex: 1;
  width: auto;
`;

const QuestionLabel = styled(H4)`
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  line-height: 100%;
  font-weight: 600;
  font-size: 14px;
  color: ${({isCurrent}) => isCurrent ? baseTheme.colors.neutralsPalette.extraDark : 'white'};
`;

const QuestionListWrapper = styled.div`
  display: ${({grid}) => grid ? 'flex' : 'inline-block'};
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  padding: ${({grid}) => grid ? '16px' : '12px 0px'};
  gap: ${({grid}) => grid ? '8px' : '0px'};
  background: ${({theme}) => theme.overlays.opacity.dark._100};
  box-shadow: inset -4px 4px 12px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  z-index: 1;
`;
const Content = styled(Column)`
  flex: 1;
  overflow: hidden;
`;

const CheckIcon = styled(Checkmark).attrs(props => ({size: 14}))`
  width: 14px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  color: white;

  ${({isCurrent}) => isCurrent && css`
    color: ${({color}) => color};
  `}
`;

const XIcon = styled(X).attrs(props => ({size: 11}))`
  width: 14px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  color: white;

  ${({isCurrent}) => isCurrent && css`
    color: ${({color}) => color};
  `}
`;

const PartialCreditIcon = styled(Partial).attrs(props => ({size: 14}))`
  width: 14px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  color: white;

  ${({isCurrent}) => isCurrent && css`
    color: ${({color}) => color};
  `}
`;

const AnsweredIcon = styled(RadioButtonOn).attrs(props => ({size: 14}))`
  color: white;
  width: 14px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  ${({isCurrent}) => isCurrent && css`
    color: ${({color}) => color};
  `}
`;

const ScoreReportIcon = styled(ChartSquareBar).attrs(props => ({size: 14}))`
  color: white;
  width: 14px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  ${({isCurrent}) => isCurrent && css`
    color: ${({color}) => color};
  `}
`;

const UnansweredIcon = styled(RadioButtonOff).attrs(props => ({size: 14}))`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  width: 14px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
`;

const MasteryTag = styled(MasteryIcon)`
  display: ${({level}) => !!level ? 'inline-block' : 'none'};
  max-width: 16px;
  height: 16px;
`;

const LoadingSpinner = styled(Loader)`
  width: 100%;
  margin: 12px auto;
  overflow: initial;
`;

const GridElementContainer = styled.div`
  flex-shrink: 0;
  cursor: pointer;
`;

const GridElementContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  height: 38px;
  width: 38px;
  padding: 8px 16px;
  overflow: hidden;

  background: ${({theme}) => theme.overlays.opacity.light._100};
  border: 1px solid ${({theme}) => theme.overlays.opacity.light._100};
  &:hover {
    ${({isCurrent}) => !isCurrent && `background: rgba(255, 255, 255, 0.2);`}
  }

  ${({isChecked, masteryLevel}) => (isChecked || masteryLevel !== 'none') && css`
    background: ${({theme}) => theme.overlays.opacity.dark._100};
    border: 1px solid ${({theme}) => theme.overlays.opacity.dark._50};
  `}
  ${({isCurrent}) => isCurrent && css`
    border: none;
    background: ${({theme}) => theme.colors.special.pearl};
    color: ${({themePalette}) => themePalette.default};
  `}
`;

const TagHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 1;
  background: ${({theme, masteryLevel}) => {
    switch (masteryLevel) {
      case 'learning':
        return theme.colors.interfacePalette.red.hover;
      case 'reviewing':
        return theme.colors.interfacePalette.yellow.hover;
      case 'mastered':
        return theme.colors.interfacePalette.green.hover;
      default:
        return 'transparent';
    }
  }};
`;

const BookmarkHeader = styled(Bookmark)`
  color: white;
  height: 14px;
  width: 14px;
  position: absolute;
  top: -4px;
  right: 4.5px;
  z-index: 2;

  ${({isCurrent}) => isCurrent && css`
    fill:  ${({theme}) => theme.colors.neutralsPalette.extraDark};
  `}
`;

const BookmarkIcon = styled(BookmarkTag)`
  margin-right: 8px;
  width: 16px;
  height: 16px;

  path {
    &:nth-child(2) {
      fill: ${({themePalette, isCurrent}) => isCurrent ? themePalette.default : 'white'};
      stroke: ${({themePalette, isCurrent}) => isCurrent ? themePalette.dark : 'white'};
    }
  }
`;

const SequentialSetIndicator = styled.div`
  position: absolute;
  width: 5px;
  height: ${({isSequentialEnd}) => isSequentialEnd ? '0px' : '42px'};
  border-top: ${({isSequentialEnd}) => isSequentialEnd ? '0px' : '1px'} solid ${({theme}) => theme.overlays.opacity.light._200};
  border-left: 1px solid ${({theme}) => theme.overlays.opacity.light._200};
  border-bottom: ${({isSequentialEnd}) => isSequentialEnd ? '1px' : '0px'} solid ${({theme}) => theme.overlays.opacity.light._200};
  left: 10px;
  top: 20px;
`;

export const ContentElement = memo(({
  themePalette,
  onClick,
  isCurrent,
  isAnswered,
  isBookmarked,
  masteryLevel,
  questionIndex,
  isCorrect,
  grid,
  template,
  label,
  isChecked,
  isPartialCredit,
  withPadding=true,
  isSequentialSet,
  isSequentialStart,
  isSequentialEnd,
}) => {
  const ref = useRef();
  const showMasteryLevel = ['anatomyCaseReview', 'tbcSavedBank', 'tbcQuestionBank', 'quickReview', 'masteryReview', 'testReview', 'bookmarkedQuestionReview', 'customTest'].includes(template);

  useEffect(() => {
    if (ref.current && isCurrent) {

      try {
        const scrollContainer = ref.current.parentElement;
        const dims = ref.current.getBoundingClientRect();
        const topBarHeight = scrollContainer.parentElement.parentElement.children[0].getBoundingClientRect().height;
        const updatedScrollTop = (dims.top - topBarHeight - (grid ? 50 : 30)) + scrollContainer.scrollTop - (grid ? 0 : 12);

        scrollContainer.scroll({
          top: updatedScrollTop,
          behavior: 'smooth',
        });
      } catch (error) {
        // temporarily adding this for cross-browswer testing
        console.log('Error scrolling to sidebar element', error);
      }
    }
  }, [ref, isCurrent])

  if (grid) {
    return (
      <GridElementContainer ref={ref} onClick={onClick}>
        <GridElementContent themePalette={themePalette} isCurrent={isCurrent} isAnswered={isAnswered || isChecked} masteryLevel={masteryLevel}>
          <TagHeader masteryLevel={masteryLevel} />
          {isBookmarked && <BookmarkHeader isCurrent={isCurrent}/>}
          {questionIndex + 1}
        </GridElementContent>
      </GridElementContainer>
    );
  }

  return (
    <ContentElementContainer ref={ref} themePalette={themePalette} onClick={onClick} isCurrent={isCurrent} withPadding={withPadding}>
      {isSequentialSet && <SequentialSetIndicator isSequentialStart={isSequentialStart} isSequentialEnd={isSequentialEnd}/>}
      <ContentElementWrapper themePalette={themePalette}>
        <QuestionTitle themePalette={themePalette} isCurrent={isCurrent} isAnswered={isAnswered || isChecked}>
          <ContentLabel isCurrent={isCurrent} children={label ? label : [
            !isCurrent && !isChecked
              ? <UnansweredIcon />
              : isCurrent && !isChecked
              ? <AnsweredIcon isCurrent={isCurrent} color={themePalette.default} />
              : isPartialCredit
              ? <PartialCreditIcon isCurrent={isCurrent} color={themePalette.default} />
              : isCorrect
              ? <CheckIcon isCurrent={isCurrent} color={themePalette.default} />
              : <XIcon isCurrent={isCurrent} color={themePalette.default} />,
            <QuestionLabel isCurrent={isCurrent} children={`Question ${questionIndex + 1}`} />
          ]}/>
          {isBookmarked && <BookmarkIcon size={24} themePalette={themePalette} isCurrent={isCurrent}/>}
          {showMasteryLevel && <MasteryTag level={masteryLevel} squared />}
        </QuestionTitle>
      </ContentElementWrapper>
    </ContentElementContainer>
  );
}, (prevProps, nextProps) => (
  (prevProps.isCurrent === nextProps.isCurrent) &&
  (prevProps.isAnswered === nextProps.isAnswered) &&
  (prevProps.isChecked === nextProps.isChecked) &&
  (prevProps.masteryLevel === nextProps.masteryLevel) &&
  (prevProps.questionIndex === nextProps.questionIndex) &&
  (prevProps.onClick === nextProps.onClick) &&
  (prevProps.isBookmarked === nextProps.isBookmarked)
));

const EmptyElement = styled.div`
  width: 40px;
  height: 40px;
`;

const ContentElementGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`;

const SectionTitle = styled(H4)`
  padding: ${({grid}) => grid ? `16px 24px` : `16px 16px 6px`};
  width: 100%;
  color: white;
`;

const NothingFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SearchIcon = styled(Search)`
  width: 20px;
  height: 20px;
  color: white;
  margin-bottom: 12px;
`;

const NothingFound = () => (
  <NothingFoundContainer>
    <SearchIcon />
    <Label2>Nothing found</Label2>
  </NothingFoundContainer>
);

export const Sidebar = ({title, sidebarState, themePalette, progressBar, classroom, showExitButton, hide=[]}) => {
  const [showingSidebar, setShowingSidebar] = sidebarState;
  const [blockScroll, allowScroll] = useScrollBlock();

  const {saveUserInteraction, searchUserInteractions, bootcamp} = useUserDataContext();
  const grid = searchUserInteractions('sidebarGridView') === true;

  const setGrid = (newValue) => saveUserInteraction('sidebarGridView', newValue);

  useEffect(() => {
    if (showingSidebar) {
      blockScroll();
    } else {
      allowScroll();
    }

    return allowScroll; // make sure to enable scroll when component unmounts
  }, [showingSidebar]);

  const {
    methods: {
      setCurrentIndex,
      updateQuizProgress,
      setNavigationFilter,
    },
    variables: {
      quizProgress,
      template,
      blockIndex,
      updating,
      navigationFilter,
      test,
      shuffled,
      customTestConfig,
      tutorMode,
      type
    }
  } = useTestNavigatorContext();
  const isReadinessExam = type === 'readinessExam';
  const {questionStartTime} = useTimerContext();
  const isFullLengthTestReview = test?.config?.title?.includes('Full Length');
  const blockTitleMap = isFullLengthTestReview && test?.config?.title.includes('OAT') ? {
    1: 'Biology',
    2: 'General Chemistry',
    3: 'Organic Chemistry',
    6: 'Reading Comprehension #1',
    7: 'Reading Comprehension #2',
    8: 'Reading Comprehension #3',
    12: 'Physics',
    15: 'Quantitative Reasoning'
  } : isFullLengthTestReview ? {
    1: 'Biology',
    2: 'General Chemistry',
    3: 'Organic Chemistry',
    6: 'Perceptual Ability',
    10: 'Reading Comprehension #1',
    11: 'Reading Comprehension #2',
    12: 'Reading Comprehension #3',
    15: 'Quantitative Reasoning'
  } : {}


  const hideQuestionList = !customTestConfig
    ? false
    : !customTestConfig?.config?.tutorMode && !customTestConfig?.submitted;

  const blocks = quizProgress.reduce((acc, {questions}, blockIndex) => {
    if (questions?.length === 0) return acc;
    return [
      ...acc, {
        blockTitle: blockTitleMap[blockIndex],
        questions,
        blockIndex
      }
    ]
  }, []);

  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const currentQuestionIndex = (questionProgresses || []).findIndex(({current}) => current);

  let overallQuestionIndex = 0;
  const sidebarContent = blocks.reduce((acc, {blockTitle, questions, blockIndex}, blockArrayIndex, blockArray) => {
    const reducedQuestions = (questions || []).reduce((acc, question, questionIndex, array) => {
      if (navigationFilter.key === 'search') {
        const {prompt, answers, explanation, questionHeader} = getQuestionParts(question.question);
        const questionText = `${prompt} ${answers} ${explanation} ${questionHeader}`;
        if (!questionText.toLowerCase().includes(navigationFilter.value.toLowerCase())) return acc;
      }

      if (
        // mastery level doesn't match current mastery level filter
        (navigationFilter.key === 'masteryLevel' && navigationFilter.value !== question.masteryLevel) ||
        // question answer outcome doesn't match answered filter
        (navigationFilter.key === 'fullCredit' && (!question?.fullCredit || (tutorMode && !question?.didCheck))) ||
        (navigationFilter.key === 'partialCredit' && (!question?.partialCredit || (tutorMode && !question?.didCheck))) ||
        (navigationFilter.key === 'noCredit' && !question?.noCredit) ||
        (navigationFilter.key === 'didSelectCorrectAnswer' && (navigationFilter.value !== question.didSelectCorrectAnswer || (!question?.didCheck && template != 'testReview' && !customTestConfig?.submitted))) ||
        (navigationFilter.key === 'bookmarked' && !question.bookmarked) ||
        (navigationFilter.key === 'topic' && question.topic !== navigationFilter.value) ||
        (navigationFilter.key === 'subject' && question.subject !== navigationFilter.value) ||
        (navigationFilter.key === 'subjectTopic' && question.subjectTopic !== navigationFilter.value)
      ) return acc;
      acc.push(
        <ContentElement
          key={`${blockIndex}-${shuffled ? questionIndex : question.questionIndex}`}
          grid={grid}
          themePalette={themePalette}
          isCurrent={question.current}
          isChecked={(template === 'testReview') ? question.selectedAnswerIndex >= 0 : (customTestConfig?.submitted) ? true : question.didCheck}
          isCorrect={question.fullCredit || question.didSelectCorrectAnswer}
          isPartialCredit={question.partialCredit}
          isAnswered={question.selectedAnswerIndex >= 0 || question.answerState?.answerState}
          isBookmarked={question.bookmarked}
          masteryLevel={question.masteryLevel}
          questionIndex={shuffled ? question.question.overallIndex : !isFullLengthTestReview ? overallQuestionIndex + question.questionIndex : question.questionIndex}
          template={template}
          isSequentialSet={question.isSequentialSet}
          isSequentialStart={question.isSequentialStart}
          isSequentialEnd={question.isSequentialEnd}
          onClick={() => {
            template === 'customTest' && !customTestConfig?.submitted && !questionProgresses?.[currentQuestionIndex]?.didCheck && updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, currentQuestionIndex);
            setCurrentIndex(blockIndex, questionIndex)
          }}
        />
      );
      return acc;
    }, []);


    overallQuestionIndex += (questions || []).length
    const rowRequired = blockArray[blockArrayIndex + 1]?.blockTitle || blockArrayIndex === blockArray.length - 2;
    // if isFullLengthTestReview, only look at questions in this block
    // else, include overallQuestionIndex
    const totalGridSectionLength = isFullLengthTestReview ? reducedQuestions.length : overallQuestionIndex + reducedQuestions.length;
    const rowFillRequired = totalGridSectionLength % 7 !== 0;
    const fillCount = 7 - (totalGridSectionLength % 7);
    if (grid && rowRequired && rowFillRequired) reducedQuestions.push(...Array.from({length: fillCount}, () => <EmptyElement />));
    reducedQuestions.length > 0 && blockTitle && acc.push(<SectionTitle grid={grid}>{blockTitle}</SectionTitle>);
    acc.push(grid && isFullLengthTestReview ? <ContentElementGrid children={reducedQuestions} /> : reducedQuestions);
    return acc;
  }, []);

  return (
    <Wrapper>
      <TopAreaWrapper>
        <Top
          title={title}
          showingSidebar={showingSidebar}
          setShowingSidebar={setShowingSidebar}
          grid={grid}
          setGrid={setGrid}
          progressBar={progressBar}
          themePalette={themePalette}
          classroom={classroom}
          showExitButton={showExitButton}
          hideQuestionList={hideQuestionList}
          hide={hide}
        />
      </TopAreaWrapper>
      <Content>
        {!hide?.includes('questions') &&
          <QuestionListWrapper grid={grid}>
            {
              ['testReview'].includes(template) && !(isReadinessExam && bootcamp === 'inbde') && (
                <ContentElement
                  key={`sidebar-element-testReview`}
                  themePalette={themePalette}
                  onClick={() => {
                    setCurrentIndex(0, 0);
                    setNavigationFilter({});
                  }}
                  isCurrent={blockIndex === 0}
                  isAnswered={false}
                  withPadding={!grid}
                  label={(
                    <ContentLabel isCurrent={blockIndex === 0}>
                      <ScoreReportIcon isCurrent={blockIndex === 0} color={'#2F3037'} />
                      <QuestionLabel isCurrent={blockIndex === 0} children={`Score Report`} lightMode={blockIndex === 0} />
                    </ContentLabel>
                  )}
                />
              )
            }
            {grid && !isFullLengthTestReview ? <ContentElementGrid children={sidebarContent} /> : sidebarContent}
            {navigationFilter && navigationFilter.key && sidebarContent?.flat()?.length === 0 && <NothingFound />}
            {updating && <LoadingSpinner active={updating} />}
            {/* {currentBlock.testBlock.nextToken && <Waypoint onEnter={() => loadMoreForBlock(currentBlock.testBlock, blockIndex)} />} */}
          </QuestionListWrapper>
        }
      </Content>
    </Wrapper>
  )
}

const PositionWrapper = props => {
  const [showingSidebar, setShowingSidebar] = props.sidebarState;
  return (
    <Backdrop showingSidebar={showingSidebar} onClick={() => window.innerWidth < 1024 && setShowingSidebar(false)}>
      <Container showingSidebar={showingSidebar} background={props.themePalette.dark} onClick={e => e.stopPropagation()}>
        <Sidebar {...props} />
      </Container>
    </Backdrop>
  );
}

export default PositionWrapper;
