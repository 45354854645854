import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';

import API, {graphqlOperation} from '@aws-amplify/api';
import awsconfig from '../aws-exports';

import {handleError} from './error';
import {LambdaClient, InvokeCommand} from '@aws-sdk/client-lambda';
import {queries, mutations, customQueries, customMutations, customSubscriptions} from '../gql';
import {getInObj, asyncFetchWithS3Cache} from '../util';
import moment from 'moment';
// TODO there might be a better way of handling this...
Amplify.configure(awsconfig);

async function setupLambdaClient() {
  try {
    const credentials = await Auth.currentCredentials();
    const lambdaClient = new LambdaClient({
      region: awsconfig.aws_project_region,
      credentials: Auth.essentialCredentials(credentials),
    });
    return lambdaClient;
  } catch (error) {
    console.error("Error setting up Lambda client:", error);
    return null;
  }
}

export const invokeLambda = async (functionName, payload) => {
  try {
    const lambdaClient = await setupLambdaClient();
    if (!lambdaClient) return null;

    const command = new InvokeCommand({
      FunctionName: functionName,
      Payload: JSON.stringify(payload),
    });
    const response = await lambdaClient.send(command);
    return JSON.parse(Buffer.from(response.Payload).toString());
  } catch (error) {
    console.error("Error invoking Lambda function:", error);
    return {};
  }
};

const sleep = m => new Promise(r => setTimeout(r, m));

async function graphqlRetryHandler (args, retryCount=1) {
  try {
    return await API.graphql(args);
  } catch (error) {
    handleError('network error', error);
    if (error.response && error.response.status >= 500 && retryCount < 32) {
      await sleep(retryCount ** 2 * 500);
      return await graphqlRetryHandler(args, retryCount + 1);
    } else {
      throw error;
    }
  }
}

async function queryVimeoApi(vimeoId, skipCache=false) {
  try {
    return await graphqlRetryHandler({query: mutations.queryVimeoApi, variables: {vimeoId, skipCache}})
  } catch (error) {
    console.log(`Error querying vimeo api for ${vimeoId}`, error);
  }
}

async function getExam(id) {
  try {
    return await API.graphql(graphqlOperation(queries.getExam, {id}))
  } catch (error) {
    console.log(`Error fetching exam ${id}`, error);
  }
}


async function getTag(id) {
  try {
    return await API.graphql(graphqlOperation(customQueries.getTag, {id}));
  } catch (error) {
    handleError('Error fetching tags', error);
  }
}

async function listTags(limit=1000) {
  try {
    return await API.graphql(graphqlOperation(customQueries.listTags, {limit}));
  } catch (error) {
    handleError('Error fetching tags', error);
  }
}

async function listExams(limit=200) {
  try {
    return await API.graphql(graphqlOperation(customQueries.listExams, {limit}));
  } catch (error) {
    handleError('Error fetching exams', error);
  }
}

async function fetchNewQuestionBase(input={}) {
  try {
    const {data} = await API.graphql({query: customMutations.createQuestionBase, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    return data.createQuestionBase.id;
  } catch(error) {
    handleError('Error creating question base', error)
  }
}

async function updateQuestionBase(input={}) {
  try {
    const {data} = await API.graphql({query: customMutations.updateQuestionBase, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    return data;
  } catch (error) {
    handleError('Error updating question base', error);
  }
}

async function updateQuestionTags(questionBaseId, tagIds) {
  try {
    const input = {
      questionBaseId: questionBaseId,
      tagIds: JSON.stringify(tagIds)
    };

    await API.graphql({query: mutations.updateQuestionTags, variables: input, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error updating question tags', error);
  }
}

async function createTag(input={}) {
  try {
    return await API.graphql({query: mutations.createTag, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error creating tag', error);
  }
}

async function updateTag(input={}) {
  try {
    return await API.graphql({query: mutations.updateTag, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error updating tag', error);
  }
}

async function createQuestionRevision(questionBaseId, prompt, answers, explanation, userName="", additionalComponents=[]) {
  try {
    const input = {
      questionBaseQuestionRevisionsId: questionBaseId,
      questionComponents: [
        {index: 0, renderType: 'prompt', contents: prompt || null},
        {index: 1, renderType: 'answers', contents: JSON.stringify(answers)},
        {index: 2, renderType: 'explanation', contents: explanation || null},
        ...additionalComponents
      ],
      revisedBy: userName
    };

    return await graphqlRetryHandler({query: mutations.createQuestionRevision, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error creating revision', error);
  }
}

async function getRevision(revisionId) {
  return await graphqlRetryHandler({query: customQueries.getQuestionRevision, variables: {id: revisionId}, authMode: 'AWS_IAM'});
}

async function createAnatomyQuestionRevision(questionBaseId, s3Key, anatomyImageTransform, prompt, answers, explanation, userName="") {
  try {
    const input = {
      questionBaseQuestionRevisionsId: questionBaseId,
      questionComponents: [
        {index: 0, renderType: 'prompt', contents: prompt},
        {index: 1, renderType: 'answers', contents: JSON.stringify(answers)},
        {index: 2, renderType: 'explanation', contents: explanation || null},
        {index: 3, renderType: 'anatomyImage', contents: s3Key || null},
        {index: 4, renderType: 'anatomyImageTransform', contents: JSON.stringify(anatomyImageTransform)}
      ],
      revisedBy: userName
    };

    return await graphqlRetryHandler({query: mutations.createQuestionRevision, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error creating revision', error);
  }
}

async function deleteQuestionAndConnections(id) {
  try {
    return await graphqlRetryHandler({query: mutations.deleteQuestion, variables: {questionBaseId: id}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error deleting question base', error);
  }
}

async function createExam(title) {
  try {
    return await API.graphql(graphqlOperation(mutations.createExam, {input: {title}}));
  } catch (error) {
    handleError('Error creating exam', error);
  }
}

async function updateExam(id, updateFields={}) {
  try {
    return await API.graphql(graphqlOperation(mutations.updateExam, {input: {id, ...updateFields}}))
  } catch (error) {
    handleError('Error updating exam', error);
  }
}

async function deleteExam(id) {
  try {
    await API.graphql(graphqlOperation(mutations.deleteExam, {input: {id}}));
  } catch (error) {
    handleError('Error deleting exam', error);
  }
}

async function createExamComponent(questionId, examId) {
  try {
    return await API.graphql(graphqlOperation(mutations.addUniqueQuestionToExam, {examId, questionId}));
  } catch(error) {
    handleError('Error adding question to exam', error);
  }
}

async function deleteExamComponent(id) {
  try {
    return await API.graphql(graphqlOperation(mutations.deleteExamComponent, {input: {id}}));
  } catch(error) {
    handleError('Error removing question from exam', error);
  }
}

async function updateExamComponentOrder(examComponents) {
  try {
    const examComponentIndexPairs = JSON.stringify(examComponents.map(({id, index}) => ([id, index])));

    return await API.graphql(graphqlOperation(mutations.updateExamComponentOrder, {examComponentIndexPairs}));
  } catch (error) {
    handleError('Error updating exam components order', error);
  }
}

async function getQuestionBase(id, fetchTests=false) {
  try {
    return graphqlRetryHandler({query: fetchTests ? customQueries.getQuestionBaseWithTests : customQueries.getQuestionBase, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base', error)
  }
}
async function getQuestionBaseTestBlocks(id) {
  try {
    return graphqlRetryHandler({query: customQueries.getQuestionBaseTestBlocks, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base', error)
  }
}

async function getQuestionBaseTagsOnly(id) {
  try {
    return graphqlRetryHandler({query: customQueries.getQuestionBaseTagsOnly, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base', error)
  }
}

async function getQuestionForTest(id, withBlocks=false) {
  try {
    return graphqlRetryHandler({query: withBlocks ? customQueries.getQuestionForTestWithBlocks : customQueries.getQuestionForTest, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base', error)
  }
}
async function getQuestionBaseWithConnections(id) {
  try {
    return graphqlRetryHandler({query: customQueries.getQuestionBaseWithConnections, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base', error)
  }
}

async function getQuestionBaseProgresses(id, limit) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getQuestionBaseProgresses, variables: {id, limit}});
    if (result.data.getQuestionBase.progresses) {
      let progressNextToken = result.data.getQuestionBase.progresses.nextToken;
      while (progressNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getQuestionBaseProgresses, variables: {id, limit, progressNextToken}});
        progressNextToken = nextResult.data.getQuestionBase.progresses.nextToken;
        result.data.getQuestionBase.progresses.items.push(...nextResult.data.getQuestionBase.progresses.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error fetching question base progresses', error);
  }
}

async function getQuestionBaseWithFaqOnly(id) {
  try {
    return API.graphql({query: customQueries.getQuestionBaseWithFaqOnly, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base with faq', error)
  }
}

async function getQuestionBaseWithFaq(id) {
  try {
    return API.graphql({query: customQueries.getQuestionBaseWithFaq, variables: {id}});
  } catch(error) {
    handleError('Error fetching question base with faq', error)
  }
}

async function listQuestions(input={}) {

  try {
    return await graphqlRetryHandler({query: customQueries.listQuestionBases, variables: input});
  } catch (error) {
    handleError('Error fetching questions', error);
  }
}

async function searchQuestions(input={}, fetchBlocks=false) {

  try {
    const {data: {searchQuestionBases: {items, nextToken}}} = await graphqlRetryHandler({query: fetchBlocks ? customQueries.searchQuestionBasesAndBlocks : customQueries.searchQuestionBases, variables: input});
    return {items, nextToken}
  } catch (error) {
    handleError('Error fetching questions', error);
  }
}

async function getContentQueue(name) {
  try {
    return await graphqlRetryHandler({query: queries.getContentQueue, variables: {name}})
  } catch (error) {
    console.log(`Error fetching ${name} content queue`, error);
  }
}

async function getTest(id, query='getTest') {
  // const s3Key = `json/backups/tests/${query}/${id}.json`;
  // const maxAge = moment().subtract(7, 'days')
  // const fetchFunction = () => graphqlRetryHandler({query: customQueries[query], variables: {id}});
  try {
    // return asyncFetchWithS3Cache(s3Key, maxAge, fetchFunction);
    return graphqlRetryHandler({query: customQueries[query], variables: {id}});
  } catch(error) {
    handleError('Error fetching test', error)
    try {
      const result = await fetch(`https://dekni9rgkrcu0.cloudfront.net/backups/tests/${id}.json`);
      return {data: {getTest: await result.json()}};
    } catch (e) {
      console.log('backup fetch error', e);
    }
  }
}

async function createTest(input) {
  try {
    return await graphqlRetryHandler({query: customMutations.createTest, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error creating test', error);
  }
}


async function listTestBlockConnections(limit=200, withConnections=false) {
  const query = customQueries.listTestBlockConnections;
  try {
    const result = await graphqlRetryHandler({query: query, variables: {limit}});
    if (result.data.listTestBlockConnections) {
      let nextToken = result.data.listTestBlockConnections.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: query, variables: {limit, nextToken}});
        nextToken = nextResult.data.listTestBlockConnections.nextToken;
        result.data.listTestBlockConnections.items.push(...nextResult.data.listTestBlockConnections.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error fetching tests', error);
  }
}

async function listQuestionConnections(limit=200, withConnections=false) {
  const query = customQueries.listQuestionConnections;
  try {
    const result = await graphqlRetryHandler({query: query, variables: {limit}});
    if (result.data.listQuestionConnections) {
      let nextToken = result.data.listQuestionConnections.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: query, variables: {limit, nextToken}});
        nextToken = nextResult.data.listQuestionConnections.nextToken;
        result.data.listQuestionConnections.items.push(...nextResult.data.listQuestionConnections.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error fetching tests', error);
  }
}

async function listTests(limit=100, withConnections=false) {
  const query = withConnections ? customQueries.listTestsWithConnections : customQueries.listTests
  try {
    const result = await graphqlRetryHandler({query: query, variables: {limit}});
    if (result.data.listTests) {
      let nextToken = result.data.listTests.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: query, variables: {limit, nextToken}});
        nextToken = nextResult.data.listTests.nextToken;
        result.data.listTests.items.push(...nextResult.data.listTests.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error fetching tests', error);
    try {
      const result = await fetch('https://dekni9rgkrcu0.cloudfront.net/backups/testLists/ALL.json');
      return {data: {listTests: {items: await result.json()}}}
    } catch (e) {
      console.log('backup fetch error', e);
    }
  }
}

async function searchBlocks(filter, limit=200) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.listTestBlocks, variables: {filter, limit}});
    if (result.data.listTestBlocks) {
      let nextToken = result.data.listTestBlocks.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.listTestBlocks, variables: {filter, limit, nextToken}});
        nextToken = nextResult.data.listTestBlocks.nextToken;
        result.data.listTestBlocks.items.push(...nextResult.data.listTestBlocks.items);
      }
    }
    return result

  } catch (error) {
    handleError('Error fetching blocks', error);
  }
}

async function searchUsers(filter, limit=200) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.listUsers, variables: {filter, limit}});
    if (result.data.listUsers) {
      let nextToken = result.data.listUsers.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.listUsers, variables: {filter, limit, nextToken}});
        nextToken = nextResult.data.listUsers.nextToken;
        result.data.listUsers.items.push(...nextResult.data.listUsers.items);
      }
    }
    return result

  } catch (error) {
    handleError('Error searching users', error);
  }
}


async function updateTest(id, updateFields={}) {
  try {
    return await graphqlRetryHandler({query: customMutations.updateTest, variables: {input: {id, ...updateFields}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error updating test', error);
  }
}


async function updateTestBlock(input) {
  try {
    return await graphqlRetryHandler({query: customMutations.updateTestBlock, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error updating test block', error);
  }
}

async function getTestBlock(id) {
  try {
    return await API.graphql(graphqlOperation(customQueries.getTestBlock, {id}));
  } catch (error) {
    handleError('Error fetching question comment', error);
    return error;
  }
}
async function getTestBlockWithQuestionIds(id) {
  try {
    return await API.graphql(graphqlOperation(customQueries.getTestBlockWithQuestionIds, {id}));
  } catch (error) {
    handleError('Error fetching question comment', error);
    return error;
  }
}

async function deleteTest(id) {
  try {
    await graphqlRetryHandler({query: mutations.deleteTest, variables: {input: {id}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error deleting test', error);
  }
}

async function deleteTestBlockConnection(id) {
  try {
    await graphqlRetryHandler({query: customMutations.deleteTestBlockConnection, variables: {input: {id}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error deleting test block connection', error);
  }
}

async function createTestBlockConnection(input) {
  try {
    return await graphqlRetryHandler({query: customMutations.createTestBlockConnection, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error creating test block connection', error);
  }
}
async function updateTestBlockConnection(input) {
  try {
    return await graphqlRetryHandler({query: customMutations.updateTestBlockConnection, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error updating test block connection', error);
  }
}

async function createTestBlock(input) {
  try {
    return await graphqlRetryHandler({query: customMutations.createTestBlock, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch(error) {
    handleError('Error creating test block', error);
  }
}

async function createQuestionConnection(questionId, testBlockId) {
  try {
    return await graphqlRetryHandler({query: mutations.addUniqueQuestionToTestBlock, variables: {questionId, testBlockId}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch(error) {
    handleError('Error adding question to test block', error);
  }
}

async function deleteQuestionConnection(id) {
  try {
    return await graphqlRetryHandler({query: customMutations.deleteQuestionConnection, variables: {input: {id}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch(error) {
    handleError('Error deleting question connection', error);
  }
}

async function updateQuestionConnectionOrder(questionConnections) {
  try {
    const questionConnectionIndexPairs = JSON.stringify(questionConnections.map(({id, index}) => ([id, index])));

    return await graphqlRetryHandler({query: mutations.updateQuestionConnectionOrder, variables: {questionConnectionIndexPairs}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error updating question connection order', error);
  }
}

async function createTestProgress(id, wordPressUserDataTestProgressesId, testId, retryCount=0) {
  try {
    return await graphqlRetryHandler({
      query: customMutations.createTestProgress,
      variables: {
        input: {
          id,
          wordPressUserDataTestProgressesId,
          testId,
          userIdHashTestId: `${wordPressUserDataTestProgressesId}#${testId}`,
        }
      }
    });
  } catch (error) {
    handleError('Error creating test progress', error);
  }
}
export async function createTestProgressWithBlocks(id, testId) {
  try {
    return await graphqlRetryHandler({
      query: customMutations.createTestProgressWithBlocks,
      variables: {
        input: {
          id,
          testId,
        }
      }
    });
  } catch (error) {
    handleError('Error creating test progress', error);
  }
}

export async function createTestProgressWithBlocksAndQuestions(id, testId) {
  try {
    await graphqlRetryHandler({
      query: customMutations.createTestProgressWithBlocksAndQuestions,
      variables: {
        input: {
          id,
          testId,
        }
      }
    });
  } catch (error) {
    console.log(error)
    handleError('Error creating test progress', error);
  }
}

async function createBlockProgress(id, testProgressBlockProgressesId, blockId, blockProgress, blockIndex, retryCount=0) {
  try {
    try {
      return await graphqlRetryHandler({query: customMutations.createBlockProgress, variables: {input: {id, testProgressBlockProgressesId, blockId, ...blockProgress, index: blockIndex}}});
    } catch (error) {
      return await graphqlRetryHandler({query: customMutations.updateBlockProgress, variables: {input: {id, testProgressBlockProgressesId, blockId, ...blockProgress, index: blockIndex}}});
    }
  } catch (error) {
    handleError('Error creating block progress', error);
  }
}
async function getBlockProgress(id) {
  try {
    return await graphqlRetryHandler({query: customQueries.getBlockProgress, variables: {id}});
  } catch (error) {
    handleError('Error getting block progress', error);
  }
}

async function updateBlockProgress(blockProgressId, blockProgress) {
  try {
    return await graphqlRetryHandler({query: customMutations.updateBlockProgress, variables: {input: {id: blockProgressId, ...blockProgress}}});
  } catch (error) {
    handleError('Error updating block result', error);
  }
}

async function createQuestionProgress(id, blockProgressQuestionProgressesId, questionProgressQuestionId, questionRevisionId, questionProgress, questionIndex, retryCount=0) {
  try {
    return await graphqlRetryHandler({
      query: customMutations.createQuestionProgress,
      variables: {
        input: {
          id,
          blockProgressQuestionProgressesId,
          questionProgressQuestionId,
          questionRevisionId,
          ...questionProgress,
          index: questionIndex
        }
      }});
  } catch (error) {
    handleError('Error creating question progress', error);
  }
}

async function updateQuestionProgress(questionProgressId, questionProgress) {
  try {
    return await graphqlRetryHandler({query: customMutations.updateQuestionProgress, variables: {input: {id: questionProgressId, ...questionProgress}}});
  } catch (error) {
    handleError('Error updating question result', error);
  }
}

async function createWordPressUserData(wpUserId) {
  try {
    return await graphqlRetryHandler({query: customMutations.createWordPressUserData, variables: {input: {wpUserId}}});
  } catch (error) {
    handleError('Error creating wordpress user data', error);
    return error;
  }
}

async function getWordPressUserData(wpUserId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getWordPressUserData, variables: {wpUserId}});
    if (result.data.getWordPressUserData) {
      let masteryNextToken = result.data.getWordPressUserData.questionMastery.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getWordPressUserData, variables: {wpUserId, masteryNextToken}});
        masteryNextToken = nextResult.data.getWordPressUserData.questionMastery.nextToken;
        result.data.getWordPressUserData.questionMastery.items.push(...nextResult.data.getWordPressUserData.questionMastery.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting wordpress user data', error);
    return error;
  }
}

async function getQuestionMasteryByWpUserId(wpUserId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.questionMasteryByWpUserId, variables: {wpUserId}});
    if (result.data.getWordPressUserData) {
      let masteryNextToken = result.data.getWordPressUserData.questionMastery.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.questionMasteryByWpUserId, variables: {wpUserId, masteryNextToken}});
        masteryNextToken = nextResult.data.getWordPressUserData.questionMastery.nextToken;
        result.data.getWordPressUserData.questionMastery.items.push(...nextResult.data.getWordPressUserData.questionMastery.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting question mastery data', error);
    return error;
  }
}

async function getAllQuestionMasteryQuestionsByUserId(wpUserId, filter={}) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getAllQuestionMasteryQuestionsByUserId, variables: {limit: 500, wpUserId}});
    if (result.data.QuestionMasteryByWpUserId) {
      let masteryNextToken = result.data.QuestionMasteryByWpUserId.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getAllQuestionMasteryQuestionsByUserId, variables: {limit: 500, wpUserId, masteryNextToken}});
        masteryNextToken = nextResult.data.QuestionMasteryByWpUserId.nextToken;
        result.data.QuestionMasteryByWpUserId.items.push(...nextResult.data.QuestionMasteryByWpUserId.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting all question mastery data', error);
    return error;
  }
}


async function getAllQuestionMasteryIdsByUserId(wpUserId, filter={}) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getAllQuestionMasteryIdsByUserId, variables: {limit: 500, wpUserId}});
    if (result.data.QuestionMasteryByWpUserId) {
      let masteryNextToken = result.data.QuestionMasteryByWpUserId.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getAllQuestionMasteryIdsByUserId, variables: {limit: 500, wpUserId, masteryNextToken}});
        masteryNextToken = nextResult.data.QuestionMasteryByWpUserId.nextToken;
        result.data.QuestionMasteryByWpUserId.items.push(...nextResult.data.QuestionMasteryByWpUserId.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting all question mastery data', error);
    return error;
  }
}

async function getAllQuestionMasteryIdsTagsByUserId(wpUserId, filter={}) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getAllQuestionMasteryIdsTagsByUserId, variables: {limit: 500, wpUserId, filter}});
    if (result.data.QuestionMasteryByWpUserId) {
      let masteryNextToken = result.data.QuestionMasteryByWpUserId.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getAllQuestionMasteryIdsTagsByUserId, variables: {limit: 500, wpUserId, masteryNextToken, filter}});
        masteryNextToken = nextResult.data.QuestionMasteryByWpUserId.nextToken;
        result.data.QuestionMasteryByWpUserId.items.push(...nextResult.data.QuestionMasteryByWpUserId.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting all question mastery data', error);
    return error;
  }
}

async function getQuestionMasteryWithQuestionsByWpUserId(wpUserId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.questionMasteryWithQuestionsByWpUserId, variables: {wpUserId}});
    if (result.data.getWordPressUserData) {
      let masteryNextToken = result.data.getWordPressUserData.questionMastery.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.questionMasteryWithQuestionsByWpUserId, variables: {wpUserId, masteryNextToken}});
        masteryNextToken = nextResult.data.getWordPressUserData.questionMastery.nextToken;
        result.data.getWordPressUserData.questionMastery.items.push(...nextResult.data.getWordPressUserData.questionMastery.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting question mastery data', error);
    return error;
  }
}
const allBootcamps = {userIdHashTestId: {beginsWith: ''}};
// const justApp = {wpUserId: {contains: '@'}}

async function getQuestionMasteryByQuestionIdForFixing(questionMasteryQuestionId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.questionMasteryByQuestionIdForFixing, variables: {limit: 2000, filter: allBootcamps, questionMasteryQuestionId}});
    if (result.data.QuestionMasteryByQuestionId) {
      let nextToken = result.data.QuestionMasteryByQuestionId.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.questionMasteryByQuestionIdForFixing, variables: {limit: 2000, filter: allBootcamps, questionMasteryQuestionId, nextToken}});
        nextToken = nextResult.data.QuestionMasteryByQuestionId.nextToken;
        result.data.QuestionMasteryByQuestionId.items.push(...nextResult.data.QuestionMasteryByQuestionId.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting question mastery data by question id', error);
    return error;
  }
}
const topicExistsFilter = {userIdHashTopicTagId: {beginsWith: ''}};

async function getQuestionMasteryByQuestionId(questionMasteryQuestionId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.questionMasteryByQuestionId, variables: {limit: 2000, filter: topicExistsFilter, questionMasteryQuestionId}});
    if (result.data.QuestionMasteryByQuestionId) {
      let nextToken = result.data.QuestionMasteryByQuestionId.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.questionMasteryByQuestionId, variables: {limit: 2000, filter: topicExistsFilter, questionMasteryQuestionId, nextToken}});
        nextToken = nextResult.data.QuestionMasteryByQuestionId.nextToken;
        result.data.QuestionMasteryByQuestionId.items.push(...nextResult.data.QuestionMasteryByQuestionId.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting question mastery data by question id', error);
    return error;
  }
}

async function getQuestionMasteryWithQuestionTagsByWpUserId(wpUserId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.questionMasteryWithQuestionTagsByWpUserId, variables: {wpUserId}});
    if (result.data.getWordPressUserData) {
      let masteryNextToken = result.data.getWordPressUserData.questionMastery.nextToken;
      while (masteryNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.questionMasteryWithQuestionTagsByWpUserId, variables: {wpUserId, masteryNextToken}});
        masteryNextToken = nextResult.data.getWordPressUserData.questionMastery.nextToken;
        result.data.getWordPressUserData.questionMastery.items.push(...nextResult.data.getWordPressUserData.questionMastery.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting question mastery data', error);
    return error;
  }
}

async function getTestProgress(id) {
  try {
    return await graphqlRetryHandler({query: customQueries.getTestProgress, variables: {id}});
  } catch (error) {
    handleError('Error getting test progress data', error);
    return error;
  }
}


export async function getTestProgressWithoutQuestion(id) {
  try {
    return await graphqlRetryHandler({query: customQueries.getTestProgressWithoutQuestion, variables: {id}});
  } catch (error) {
    handleError('Error getting test progress data', error);
    return error;
  }
}
export async function getTestProgressSimple(id) {
  try {
    return await graphqlRetryHandler({query: customQueries.getTestProgressSimple, variables: {id}});
  } catch (error) {
    handleError('Error getting test progress data', error);
    return error;
  }
}
export async function getTestProgressPerformance(id) {
  try {
    return await graphqlRetryHandler({query: customQueries.getTestProgressPerformance, variables: {id}});
  } catch (error) {
    handleError('Error getting test progress data', error);
    return error;
  }
}

async function getTestProgressCount(id) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressesCount, variables: {id}});
    return getInObj(['data', 'getUser', 'testProgresses', 'items'], result, []).length;
  } catch (error) {
    handleError('Error getting test progress count', error);
    return error;
  }
}

async function getTestProgressByUserIdHashTestId(userIdHashTestId, withData, sortDirection = 'DESC') {
  const query = withData ? customQueries.getTestProgressByTestIdFull : customQueries.getTestProgressByTestId;

  try {
    return await graphqlRetryHandler({query, variables: {userIdHashTestId, limit: 1, sortDirection}});
  } catch (error) {
    handleError('Error getting test progress by userId testId hash key', error);
    return error;
  }
}
async function getTestProgressByUserIdHashTestIdForScoring(userIdHashTestId, isDAT, sortDirection = 'DESC', nextToken = null) {
  const query = isDAT ? customQueries.getTestProgressByTestIdFullForDATScoring : customQueries.getTestProgressByTestIdFullForScoring;

  try {
    return await graphqlRetryHandler({query, variables: {userIdHashTestId, limit: 1, sortDirection, nextToken}});
  } catch (error) {
    handleError('Error getting test progress by userId testId hash key', error);
    return error;
  }
}

function createTestProgressSubscription() {
  try {
    return graphqlRetryHandler({query: customSubscriptions.onCreateTestProgress});
  } catch (error) {
    handleError('Error subscribing to test progress creation', error);
  }
}

function updateBlockProgressSubscription() {
  try {
    return graphqlRetryHandler({query: customSubscriptions.onUpdateBlockProgress});
  } catch (error) {
    handleError('Error subscribing to block progress update', error);
  }
}

function updateQuestionProgressSubscription() {
  try {
    return graphqlRetryHandler({query: customSubscriptions.onUpdateQuestionProgress});
  } catch (error) {
    handleError('Error subscribing to question progress update', error);
  }
}

async function createQuestionMastery(wpUserId, questionBaseId, masteryLevel, bookmarked) {
  try {
    return await graphqlRetryHandler({
      query: customMutations.createQuestionMastery,
      variables: {
        input: {
          wordPressUserDataQuestionMasteryId: wpUserId,
          userIdHashQuestionBaseId: `${wpUserId}#${questionBaseId}`,
          wpUserId,
          questionMasteryQuestionId: questionBaseId,
          masteryLevel,
          bookmarked
        }
      }});
  } catch (error) {
    handleError('Error creating question mastery', error);
  }
}

async function updateQuestionMastery(input, create) {
  const query = create ? customMutations.createQuestionMastery : customMutations.updateQuestionMastery;

  try {
    return await graphqlRetryHandler({query, variables: {input}});
  } catch (error) {
    handleError('Error updating question mastery', error);
  }
}

async function getQuestionMastery(variables, queryKey, bootcamp='') {
  const query = {
    subject: customQueries.questionMasteryBySubjectTagId,
    subjectWithQuestion: customQueries.questionMasteryBySubjectTagIdWithQuestion,
    subjectWithQuestionBaseId: customQueries.questionMasteryBySubjectTagIdWithQuestionBaseId,
    topic: customQueries.questionMasteryByTopicTagId,
    test: customQueries.questionMasteryByTestId,
    masteryLevel: ['anatomy', 'med-school', 'dental-school'].includes(bootcamp) ? customQueries.questionMasteryByMasteryLevelAnatomy : customQueries.questionMasteryByMasteryLevel,
  }[queryKey] || customQueries.getQuestionMasteryByQuestionBaseId;

  try {
    const result = await graphqlRetryHandler({query, variables});

    return result;
  } catch (error) {
    handleError('Error fetching question mastery', error);
  }
}

async function loadMastery(masteryLevel, tagIds, userId, nextToken, bootcamp='', bookmarksOnly=false, type) {
  const filterQueryKey = type === 'topicReview'
    ? 'userIdHashTopicTagId'
    : 'userIdHashSubjectTagId';

  const filter = tagIds.length
    ? {
      filter: {
        ...(bookmarksOnly ? {bookmarked: {eq: true}} : {}),
        or: tagIds.map(tagId => ({[filterQueryKey]: {eq:  `${userId}#${tagId}`}}))
      }
    }
    : {};

  const masteryLevelFilter = masteryLevel === 'all' || bookmarksOnly
    ? {}
    : {masteryLevel: {eq: masteryLevel}}

  try {
    const mastery = await getQuestionMastery({
      wpUserId: userId,
      limit: 500,
      nextToken,
      ...masteryLevelFilter,
      ...filter,
    }, 'masteryLevel', bootcamp);

    const {items, nextToken: nextNextToken} = mastery.data['QuestionMasteryByMasteryLevel'];

    return {
      mastery: items,
      nextToken: nextNextToken,
    }
  } catch (error) {
    handleError('Error fetching question mastery', error);
  }
}

async function getAllQuestionMastery(variables, queryKey) {
  const {query, queryTitle} = {
    subject: {
      query: customQueries.questionMasteryBySubjectTagId,
      queryTitle: 'QuestionMasteryBySubjectTagId'
    },
    subjectWithQuestion: {
      query: customQueries.questionMasteryBySubjectTagIdWithQuestion,
      queryTitle: 'QuestionMasteryBySubjectTagId'
    },
    subjectWithQuestionBaseId: {
      query: customQueries.questionMasteryBySubjectTagIdWithQuestionBaseId,
      queryTitle: 'QuestionMasteryBySubjectTagId'
    },
    subjectWithQuestionTags: {
      query: customQueries.questionMasteryBySubjectTagIdWithQuestionTags,
      queryTitle: 'QuestionMasteryBySubjectTagId'
    },
    topic: {
      query: customQueries.questionMasteryByTopicTagId,
      queryTitle: 'QuestionMasteryByTopicTagId',
    },
    topicWithQuestion: {
      query: customQueries.questionMasteryByTopicTagIdWithQuestion,
      queryTitle: 'QuestionMasteryByTopicTagId',
    },
    test: {
      query: customQueries.questionMasteryByTestId,
      queryTitle: 'QuestionMasteryByTestId'
    },
    testSmall: {
      query: customQueries.questionMasteryByTestIdSmall,
      queryTitle: 'QuestionMasteryByTestId'
    },
    customTest: {
      query: customQueries.questionMasteryByTestIdCustomTest,
      queryTitle: 'QuestionMasteryByTestId'
    },
    customTestWithTestId: {
      query: customQueries.questionMasteryByTestIdCustomTestWithTestId,
      queryTitle: 'QuestionMasteryByTestId'
    }
  }[queryKey] || {
    query: customQueries.getQuestionMasteryByQuestionBaseId,
    queryTitle: 'GetQuestionMastery'
  }

  try {
    const result = await graphqlRetryHandler({query, variables: {...variables}});
    if (result.data[queryTitle]) {
      let nextToken = result.data[queryTitle].nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query, variables: {...variables, nextToken}});
        nextToken = nextResult.data[queryTitle].nextToken;
        result.data[queryTitle].items.push(...nextResult.data[queryTitle].items);
      }
    }
    return result;
  } catch (error) {
    handleError('Error fetching question mastery', error);
  }
}

async function getTestProgressesByWpUserId(wpUserId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressesByWpUserId, variables: {wpUserId}});

    if (result.data.getWordPressUserData) {
      let testProgressesNextToken = result.data.getWordPressUserData.testProgresses.nextToken;
      while (testProgressesNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getTestProgressesByWpUserId, variables: {wpUserId, testProgressesNextToken}});
        testProgressesNextToken = nextResult.data.getWordPressUserData.testProgresses.nextToken;
        result.data.getWordPressUserData.testProgresses.items.push(...nextResult.data.getWordPressUserData.testProgresses.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting wordpress user data', error);
    return error;
  }
}

async function getPaginatedTestProgressesByWpUserId(wpUserId, limit, nextToken) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressesByWpUserId, variables: {wpUserId, nextToken, limit}});
    // if (result.data.getWordPressUserData) {
    //   let testProgressesNextToken = result.data.getWordPressUserData.testProgresses.nextToken;
    //   while (testProgressesNextToken) {
    //     const nextResult = await graphqlRetryHandler({query: customQueries.getTestProgressesByWpUserId, variables: {wpUserId, testProgressesNextToken}});
    //     testProgressesNextToken = nextResult.data.getWordPressUserData.testProgresses.nextToken;
    //     result.data.getWordPressUserData.testProgresses.items.push(...nextResult.data.getWordPressUserData.testProgresses.items);
    //   }
    // }
    return result
  } catch (error) {
    handleError('Error getting wordpress user data', error);
    return error;
  }
}

async function getTestProgressesByUserId(id) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressesByUserId, variables: {id}});
    if (result.data.getUser) {
      let testProgressesNextToken = result.data.getUser.testProgresses.nextToken;
      while (testProgressesNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getTestProgressesByUserId, variables: {id, testProgressesNextToken}});
        testProgressesNextToken = nextResult.data.getUser.testProgresses.nextToken;
        result.data.getUser.testProgresses.items.push(...nextResult.data.getUser.testProgresses.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting user data', error);
    return error;
  }
}

export async function getTestProgressIdsByUserId(id) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressIds, variables: {id, sortDirection: 'DESC'}});
    // if (result.data.getUser) {
    //   let testProgressesNextToken = result.data.getUser.testProgresses.nextToken;
    //   while (testProgressesNextToken) {
    //     const nextResult = await graphqlRetryHandler({query: customQueries.getTestProgressesByUserId, variables: {id, testProgressesNextToken}});
    //     testProgressesNextToken = nextResult.data.getUser.testProgresses.nextToken;
    //     result.data.getUser.testProgresses.items.push(...nextResult.data.getUser.testProgresses.items);
    //   }
    // }
    return result
  } catch (error) {
    handleError('Error getting user data', error);
    return error;
  }
}


export async function getTestProgressIdsByIdOverride(id) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressIdsByWpUserId, variables: {wpUserId: id, sortDirection: 'DESC'}});
    // if (result.data.getUser) {
    //   let testProgressesNextToken = result.data.getUser.testProgresses.nextToken;
    //   while (testProgressesNextToken) {
    //     const nextResult = await graphqlRetryHandler({query: customQueries.getTestProgressesByUserId, variables: {id, testProgressesNextToken}});
    //     testProgressesNextToken = nextResult.data.getUser.testProgresses.nextToken;
    //     result.data.getUser.testProgresses.items.push(...nextResult.data.getUser.testProgresses.items);
    //   }
    // }
    return result
  } catch (error) {
    handleError('Error getting user data', error);
    return error;
  }
}

async function getPaginatedTestProgressesByUserId(id, limit, nextToken) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getTestProgressesByUserId, variables: {id, limit, testProgressesNextToken: nextToken}});
    // if (result.data.getUser) {
    //   let testProgressesNextToken = result.data.getUser.testProgresses.nextToken;
    //   while (testProgressesNextToken) {
    //     const nextResult = await graphqlRetryHandler({query: customQueries.getTestProgressesByUserId, variables: {id, testProgressesNextToken}});
    //     testProgressesNextToken = nextResult.data.getUser.testProgresses.nextToken;
    //     result.data.getUser.testProgresses.items.push(...nextResult.data.getUser.testProgresses.items);
    //   }
    // }
    return result
  } catch (error) {
    handleError('Error getting user data', error);
    return error;
  }
}

async function getFilteredBootcampTestProgresses(id, testId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getFilteredBootcampTestProgresses, variables: {id, testId}});
    if (result.data.getUser) {
      let testProgressesNextToken = result.data.getUser.testProgresses.nextToken;
      while (testProgressesNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getFilteredBootcampTestProgresses, variables: {id, testId, testProgressesNextToken}});
        testProgressesNextToken = nextResult.data.getUser.testProgresses.nextToken;
        result.data.getUser.testProgresses.items.push(...nextResult.data.getUser.testProgresses.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting user data', error);
    return error;
  }
}

async function getFilteredTestProgresses(wpUserId, testId) {
  try {
    const result = await graphqlRetryHandler({query: customQueries.getFilteredTestProgresses, variables: {wpUserId, testId}});
    if (result.data.getWordPressUserData) {
      let testProgressesNextToken = result.data.getWordPressUserData.testProgresses.nextToken;
      while (testProgressesNextToken) {
        const nextResult = await graphqlRetryHandler({query: customQueries.getFilteredTestProgresses, variables: {wpUserId, testId, testProgressesNextToken}});
        testProgressesNextToken = nextResult.data.getWordPressUserData.testProgresses.nextToken;
        result.data.getWordPressUserData.testProgresses.items.push(...nextResult.data.getWordPressUserData.testProgresses.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error getting wordpress user data', error);
    return error;
  }
}

async function listQuestionComments(limit=200) {
  try {
    const result = await API.graphql(graphqlOperation(queries.listQuestionComments, {limit, sortDirection: 'ASC'}));
    if (result.data.listQuestionComments) {
      let nextToken = result.data.listQuestionComments.nextToken;
      while (nextToken) {
        const nextResult = await graphqlRetryHandler({query: queries.listQuestionComments, variables: {limit, sortDirection: 'ASC', nextToken}});
        nextToken = nextResult.data.listQuestionComments.nextToken;
        result.data.listQuestionComments.items.push(...nextResult.data.listQuestionComments.items);
      }
    }
    return result
  } catch (error) {
    handleError('Error fetching question comments', error);
    return error;
  }
}

async function getQuestionComment(id) {
  try {
    return await API.graphql(graphqlOperation(queries.getQuestionComment, {id}));
  } catch (error) {
    handleError('Error fetching question comment', error);
    return error;
  }
}

async function createQuestionComment(input) {
  try {
    const result = await API.graphql({query: customMutations.createQuestionComment, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    return result
  } catch (error) {
    handleError('Error creating question comment', error);
    return error;
  }
}

async function updateQuestionComment(input) {
  try {
    const result = await API.graphql({query: mutations.updateQuestionComment, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    return result;
  } catch (error) {
    handleError('Error updating question comment', error);
    return error;
  }
}

async function deleteQuestionComment(id) {
  try {
    const result = await API.graphql({query: mutations.deleteQuestionComment, variables: {input: {id}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
    return result
  } catch (error) {
    handleError('Error deleting question comment', error);
    return error;
  }
}

async function getUser(id, authMode='AMAZON_COGNITO_USER_POOLS') {
  try {
    return await API.graphql({query: customQueries.getUser, variables: {id}, authMode});
  } catch (error) {
    handleError('Error getting user', error);
    return error;
  }
}

async function createUser(id, idOverride, email) {
  try {
    return await graphqlRetryHandler({query: mutations.createUser, variables: {input: {id, idOverride, email}}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (error) {
    handleError('Error creating user', error);
    return error;
  }
}

async function createMembership(input) {
  try {
    return await graphqlRetryHandler({
      query: mutations.createMembership,
      variables: {input},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  } catch (error) {
    handleError('Error creating membership', error);
    return error;
  }
}

async function updateMembership(input) {
  try {
    return await graphqlRetryHandler({
      query: customMutations.updateMembership,
      variables: {input},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  } catch (error) {
    handleError('Error updating membership', error);
    return error;
  }
}

async function updateUser(input) {
  try {
    return await graphqlRetryHandler({
      query: customMutations.updateUser,
      variables: {input},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  } catch (error) {
    handleError('Error updating user', error);
    return error;
  }
}

async function getPriceConfig(bootcamp) {
  
  try {
    const result = await invokeLambda('tbc-Stripe', {bootcamp, path: '/stripe/get-active-prices'});
    return result.body.activePrices;
  } catch (error) {
    console.log('error fetching price config', error);
    return {client_secret: null};
  }
};
async function getPrice(priceId) {

  try {
    const result = await invokeLambda('tbc-Stripe', {priceId, path: '/stripe/get-price'});
    return result.body.price;
  } catch (error) {
    console.log('error fetching price config', error);
    return {client_secret: null};
  }
};

export async function updateActivationCode(input) {
  try {
    const result = await API.graphql({query: customMutations.updateActivationCode, variables: {input}});
    return getInObj(['data', 'updateActivationCode'], result);
  } catch (error) {
    handleError('Error updating Activation Code', error);
    return null;
  }
}

export async function getActivationCode(id) {
  try {
    const result = await API.graphql({query: customQueries.getActivationCode, variables: {id}});
    return getInObj(['data', 'getActivationCode'], result);
  } catch (error) {
    handleError('Error getting Activation Code', error);
    return null;
  }
}

async function saveTestProgress(input) {
try {
    const lambdaClient = await setupLambdaClient();
    if (!lambdaClient) throw new Error('Failed to setup lambda client');
    const saveCommand = new InvokeCommand({
      FunctionName: 'saveTestProgress-tbc-2',
      Payload: new TextEncoder().encode(JSON.stringify({body: JSON.stringify(input)})),
    });
    const response = await lambdaClient.send(saveCommand);
    if (response.StatusCode !== 200) throw new Error('Failed to save test progress');
    return response;
  } catch (error) {
    try {
      await fetch('https://x7h44q3mpdubxjvpfjxwh7mure0ikjmm.lambda-url.us-east-1.on.aws', {
        method: 'POST',
        body: JSON.stringify(input),
      });
    } catch (error) {
      handleError('Error saving test progress', error);
      return;
    }
    handleError('Error saving test progress', error);
    return;
  }
}

async function getIntercomUserAttributes(email) {
  try {
    const {body} = await API.post('tbcPublicAPI', '/intercom/users', {
      body: {
        email
      }
    });

    return body.user;
  } catch (error) {
    console.log('error fetching intercom user attributes', JSON.stringify(error));
    return {custom_attributes: null};
  }
};

async function getQuestionProgressesFromS3(uploadKey) {
  try {
    // check for existing data in S3
    const existingDataFetch = await Storage.get(uploadKey, {
      download: true,
      cacheControl: 'no-cache',
      contentType: 'application/json',
    });

    const existingProgresses = await existingDataFetch.Body?.text();

    if (existingProgresses) return JSON.parse(existingProgresses);

  } catch (e) {
    console.log('error fetching from s3', e);
  }

}

async function getQuestionAnswerData(questionId, uploadKey, formatter) {
  if (!questionId) return;

  const answerMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
  };

  try {
    const fetchAndParseAnswerData = async () => {
      try {
        const {data: {getQuestionBase: {progresses}}} = await getQuestionBaseProgresses(questionId, 3000);

        if (!progresses?.items?.length) {
          return {};
        } else if (typeof formatter === 'function') {
          return formatter(progresses?.items);
        }

        const answerData = progresses?.items?.reduce((acc, progress) => {
          let answerChoice = answerMap[progress.selectedAnswerIndex];

          if (progress.didSelectCorrectAnswer) {
            answerChoice += ' (CORRECT)';
          }

          if (!answerChoice) return acc;

          return {
            ...acc,
            total: {
              ...acc.total,
              [answerChoice]: acc.total?.[answerChoice] ? acc.total?.[answerChoice] + 1 : 1
            },
            [progress.questionRevisionId]: {
              ...(acc[progress.questionRevisionId] || []),
              [answerChoice]: acc?.[progress.questionRevisionId]?.[answerChoice] ? acc?.[progress.questionRevisionId]?.[answerChoice] + 1 : 1
            }
          }
        }, {total: {}});

        return answerData;

      } catch (error) {
        // raise error to prevent s3 storage from taking place
        throw new Error();
      }
    };

    const data = await asyncFetchWithS3Cache(uploadKey, moment().subtract(1, 'days'), fetchAndParseAnswerData);

    return data;

  } catch (error) {
    return {};
  }
}

async function getClassroomCourseBlockCounts (bootcamp, testsByClassroom) {
  const classroomCourseBlockCountsStorageKey = `json/${bootcamp}/lambdaClassroomCourseBlockCounts.json`;
  const fetchCounts = async () => {
    const classroomCourseBlockCounts = await Object.keys(testsByClassroom).reduce(async (acc, route) => {
      try {
        const blockCount = await testsByClassroom[route].reduce(async (acc, id) => {
          const testConfig = await getTest(id, 'getTestNoQuestions');
          const blocks = getInObj(['data', 'getTest', 'blockConnections', 'items'], testConfig, []);
          return (await acc) + blocks?.length - 2;
        }, 0);

        return {
          ...(await acc),
          [route]: blockCount
        }
      } catch (e) {
        return await acc
      }
    }, {});

    if ((bootcamp === 'med-school' && testsByClassroom['general-chemistry']) || Object.keys(classroomCourseBlockCounts).length === 0 || (Object.keys(testsByClassroom)?.length !== Object.keys(classroomCourseBlockCounts)?.length)) throw new Error('no keys = no count = no storage');

    return {classroomCourseBlockCounts, storedAt: moment()}
  }

  try {
    const result = await asyncFetchWithS3Cache(classroomCourseBlockCountsStorageKey, moment().subtract(7, 'days'), fetchCounts);

    if (result && Object.keys(result?.classroomCourseBlockCounts).length === 0) {
      await Storage.remove(classroomCourseBlockCountsStorageKey);
    }
    return result;
  } catch (e) {
    return {courseBlockCounts: {}}
  }
}

async function getClassroomQuestionCounts (bootcamp, testsByClassroom, plusTests=false) {
  const classroomQuestionCountsStorageKey = `json/${bootcamp}/lambdaClassroomQuestionCounts${plusTests ? 'Plus' : ''}.json`;
  const fetchCounts = async () => {
    const classroomQuestionCounts = await Object.keys(testsByClassroom).reduce(async (acc, route) => {
      try {
        const questionCount = await testsByClassroom[route].reduce(async (acc, id) => {
          const testConfig = await getTest(id, 'getTestConfig');
          const questionCount = getInObj(['data', 'getTest', 'config', 'questionCount'], testConfig, 0);
          return (await acc) + questionCount
        }, 0);
        return {
          ...(await acc),
          [route]: questionCount
        }
      } catch (e) {
        return await acc
      }
    }, {});

    if (Object.keys(classroomQuestionCounts).length === 0 || (Object.keys(testsByClassroom)?.length !== Object.keys(classroomQuestionCounts)?.length)) throw new Error('no keys = no count = no storage');

    return {classroomQuestionCounts, storedAt: moment()}
  }
  try {
    const result = await Storage.get(classroomQuestionCountsStorageKey, {
      download: true,
      cacheControl: 'no-cache',
    });
    return await new Response(result.Body).json();
    // const result = await asyncFetchWithS3Cache(classroomQuestionCountsStorageKey, moment().subtract(7, 'days'), fetchCounts);
    //
    // if (result && Object.keys(result?.classroomQuestionCounts).length === 0) {
    //   await Storage.remove(classroomQuestionCountsStorageKey);
    // }
    // return result;
  } catch (e) {
    console.log(e);
    return {classroomQuestionCounts: {}}
  }
}

async function getMembership(id) {
  try {
    return await API.graphql(graphqlOperation(queries.getMembership, {id}));
  } catch (error) {
    handleError('Error fetching membership', error);
    return error;
  }
}

export async function trackQuestionPerformance(DEFAULT_USER_ID, subjectId, topicId, addAttempts, addCorrect, testProgressBlockProgressesId) {
  const blockProgressQuestionProgressesId = `${DEFAULT_USER_ID}#${subjectId}#${topicId}`;
  try {
    // query blockProgress
    const result = await API.graphql(graphqlOperation(customQueries.getBlockProgress, {
      id: blockProgressQuestionProgressesId
    }));
    const {status} = result.data.getBlockProgress;
    const {attempts, correct} = JSON.parse(status || '{"attempts": 0, "correct": 0}');
    await API.graphql(graphqlOperation(customMutations.updateBlockProgress, {
      input: {
        id: blockProgressQuestionProgressesId,
        status: JSON.stringify({
          attempts: attempts + addAttempts,
          correct: correct + addCorrect
        })
      }
    }))
  } catch (error) {
    await API.graphql(graphqlOperation(customMutations.createBlockProgress, {
      input: {
        id: blockProgressQuestionProgressesId,
        blockId: 'null',
        testProgressBlockProgressesId,
        status: JSON.stringify({
          attempts: addAttempts,
          correct: addCorrect
        })
      }
    }))
  }
}

export async function trackFirstQuestionAttempt(username, testId, questionId, questionRevisionId, questionProgress) {
  try {
    const blockProgressId = `tracking#${username}#${testId}`;
    const qbankProgressId = `tracking#${username}#${testId}#${questionId}`;

    await createQuestionProgress(
      qbankProgressId,
      blockProgressId,
      questionId,
      questionRevisionId,
      questionProgress,
      questionProgress.index,
    );
  } catch (error) {
    console.log('can ignore this error', error);
  }
}

// TODO update here for nclex
export async function trackNCLEXQuizProgressPerformance (userId, quizProgress, testProgressBlockProgressesId) {
  const allQuestions = quizProgress.reduce((acc, {questions}) => !questions ? acc : [...acc, ...questions.reduce((acc, {question, answerState}) => !question || !answerState?.score ? acc : [...acc, {question, answerState}], [])], []);
  // reduce allQuestions into an object keyed by subject & topic tags
  const performanceByTag = allQuestions.reduce((acc, {question, answerState}) => {
    const {tags} = question;
    const {tag: {id: subject}} = (tags.items || []).find(({tag}) => tag.contentType === 'nclexSubject') || {};
    const {tag: {id: topic}} = (tags.items || []).find(({tag}) => !!tag.test && !!tag?.subject && !!tag.system && !tag.topic && !tag.contentType) || {};
    const subjectTopic = `${subject}#${topic}`;
    const {attempts, correct} = acc[subjectTopic] || {attempts: 0, correct: 0};
    return {
      ...acc,
      [subjectTopic]: {attempts: attempts + answerState.maxScore, correct: correct + answerState.score}
    };
  }, {});

  for (const [tag, {attempts, correct}] of Object.entries(performanceByTag)) {
    const [subject, topic] = tag.split('#');
    await trackQuestionPerformance(userId, subject, topic, attempts, correct, testProgressBlockProgressesId);
  }
}

// TODO update here for nclex
export async function trackQuizProgressPerformance (userId, quizProgress, testProgressBlockProgressesId) {
  const allQuestions = quizProgress.reduce((acc, {questions}) => !questions ? acc : [...acc, ...questions.reduce((acc, {question, didSelectCorrectAnswer, selectedAnswerIndex}) => !question || selectedAnswerIndex == -1 ? acc : [...acc, {question, didSelectCorrectAnswer}], [])], []);
  // reduce allQuestions into an object keyed by subject & topic tags
  const performanceByTag = allQuestions.reduce((acc, {didSelectCorrectAnswer, question}) => {
    const {tags} = question;
    const {tag: {id: subject}} = (tags.items || []).find(({tag}) => !!tag.subject && !tag.topic) || {};
    const {tag: {id: topic}} = (tags.items || []).find(({tag}) => !!tag.topic) || {};
    const subjectTopic = `${subject}#${topic}`;
    const {attempts, correct} = acc[subjectTopic] || {attempts: 0, correct: 0};
    return {
      ...acc,
      [subjectTopic]: {attempts: attempts + 1, correct: correct + (didSelectCorrectAnswer ? 1 : 0)}
    };
  }, {});

  for (const [tag, {attempts, correct}] of Object.entries(performanceByTag)) {
    const [subject, topic] = tag.split('#');
    await trackQuestionPerformance(userId, subject, topic, attempts, correct, testProgressBlockProgressesId);
  }
}


async function getQuestionData(bootcamp) {
  if (!['med-school', 'inbde', 'nclex', 'anatomy'].includes(bootcamp)) return {}

  const storageKey = `json/${bootcamp}/lambdaQuestionConfigIdsIndexedByTest.json`;

  try {
    const fetchConfigQuestionIds = await fetch(`https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com/public/${storageKey}`, {cache: "no-store"});
    const configQuestionIds = await fetchConfigQuestionIds.json();

    //NOTE intermittent formatting step - this will be done in the cloud before release
    const indexedByTest = Object.entries(configQuestionIds)?.reduce((acc, curr) => ({
      ...acc,
      [curr[0]]: curr[1].reduce((acc, curr) => ({
        ...acc,
        [curr]: true
      }), {})
    }), {})

    return indexedByTest;
  } catch (error) {
    console.log('error getting question counts', error);
    return {};
  }
}

export {
  handleError,
  getQuestionData,
  getQuestionAnswerData,
  getQuestionComment,
  getContentQueue,
  getPriceConfig,
  getPrice,
  getTag,
  listTags,
  listExams,
  listQuestions,
  searchQuestions,
  fetchNewQuestionBase,
  getQuestionBase,
  getQuestionBaseTestBlocks,
  getQuestionBaseTagsOnly,
  getQuestionBaseWithFaq,
  getQuestionBaseWithFaqOnly,
  updateQuestionBase,
  updateQuestionTags,
  createQuestionRevision,
  createAnatomyQuestionRevision,
  createTestBlock,
  deleteQuestionAndConnections,
  getExam,
  createExam,
  updateExam,
  deleteExam,
  createExamComponent,
  deleteExamComponent,
  updateExamComponentOrder,
  getTest,
  createTest,
  listTests,
  searchBlocks,
  updateTest,
  updateTestBlock,
  getTestBlock,
  getTestBlockWithQuestionIds,
  deleteTest,
  createTestBlockConnection,
  updateTestBlockConnection,
  deleteTestBlockConnection,
  createQuestionConnection,
  deleteQuestionConnection,
  updateQuestionConnectionOrder,
  queryVimeoApi,
  createTestProgress,
  createBlockProgress,
  getBlockProgress,
  updateBlockProgress,
  createQuestionProgress,
  updateQuestionProgress,
  getWordPressUserData,
  getTestProgressesByWpUserId,
  getPaginatedTestProgressesByWpUserId,
  getTestProgressesByUserId,
  getPaginatedTestProgressesByUserId,
  getTestProgress,
  getTestProgressCount,
  createWordPressUserData,
  createTestProgressSubscription,
  updateBlockProgressSubscription,
  updateQuestionProgressSubscription,
  createQuestionMastery,
  updateQuestionMastery,
  getAllQuestionMastery,
  getQuestionMastery,
  loadMastery,
  getQuestionMasteryByWpUserId,
  getAllQuestionMasteryIdsByUserId,
  getAllQuestionMasteryIdsTagsByUserId,
  getQuestionMasteryWithQuestionsByWpUserId,
  getQuestionMasteryWithQuestionTagsByWpUserId,
  getQuestionMasteryByQuestionId,
  getQuestionMasteryByQuestionIdForFixing,
  listQuestionComments,
  createQuestionComment,
  updateQuestionComment,
  deleteQuestionComment,
  createTag,
  updateTag,
  getFilteredBootcampTestProgresses,
  getFilteredTestProgresses,
  getUser,
  createUser,
  createMembership,
  updateMembership,
  getTestProgressByUserIdHashTestId,
  getTestProgressByUserIdHashTestIdForScoring,
  getQuestionBaseProgresses,
  getQuestionForTest,
  getQuestionBaseWithConnections,
  listTestBlockConnections,
  listQuestionConnections,
  saveTestProgress,
  searchUsers,
  updateUser,
  getIntercomUserAttributes,
  getAllQuestionMasteryQuestionsByUserId,
  getClassroomQuestionCounts,
  getClassroomCourseBlockCounts,
  getRevision,
  getMembership,
}
