import {getTest, getAllQuestionMastery, getQuestionForTest, getQuestionBaseWithConnections, getTestBlockWithQuestionIds} from '@bootcamp/shared/src/requests';
import {getRandom, shuffle} from '@bootcamp/shared/src/util';
import Storage from '@aws-amplify/storage';

import {nanoid} from 'nanoid';
import firstBy from 'thenby';
import {calculateMaxScore} from '@bootcamp/shared/src/util/scoring/NCLEX'
import {getQuestionParts} from '@bootcamp/shared/src/util';

export const formatTestFromQuestions = async (config, selectedQuestions, testId, testIds) => {
  const questions = await Promise
  .all(selectedQuestions.map(async question => {
    const {data: {getQuestionBase}} = await getQuestionForTest(question.id);
    return {...question, question: {...getQuestionBase, questionBaseId: question.id, originalTestId: question.testId, masteryLevel: config.masteryLevel, ...question}}
  }));
  // if config doesnt have a max score, calculate max score now
  if (!config.maxScore) {
    const maxScore = questions.reduce((acc, questionContainer) => {
      try {
        const {question} = questionContainer || {};
        if (question) {
          const {prompt, type, answers, answerGroups, answerMatrix} = getQuestionParts(question);
          return acc + calculateMaxScore(prompt, type, answers, answerGroups, answerMatrix);
        }
        return acc;
        } catch (error) {
          return acc;
        }
    }, 0)
    config.maxScore = maxScore;
    await Storage.put(config.testStorageKey, JSON.stringify({config, selectedQuestions, testId, testIds}), {contentType: 'application/json'});
  }
  return {
    config,
    id: testId,
    blockConnections: {
      items: [
        {testBlock: {type: 'startCustomTest'}, index: 0},
        {
          testBlock: {
            type: 'questionSet',
            questionConnections: {
              items: questions
            }
          },
          index: 1
        },
        {testBlock: {type: 'endBlock'}, index: 2}
      ]
    }
  }
}

export const restoreCustomTest = async (testStorageKey) => {
  try {
    const result = await Storage.get(testStorageKey, {
      download: true,
      cacheControl: 'no-cache',
      contentType: 'application/json',
    });
    const json = await new Response(result.Body).json()
    const {config, selectedQuestions, testId, ...otherConfig} = json;
    const formattedTest = await formatTestFromQuestions({...config, ...otherConfig}, selectedQuestions, testId);

    return [formattedTest, json];
  } catch (e) {
    console.log(e);
  }
}

export const composeCustomTest = async (config, userId) => {
  try {
    const testId = `${nanoid()}_${userId}`;
    const {testIds, masteryLevel, testLength, bootcamp, type, onlyCaseStudies} = config;
    shuffle(testIds);
    const allQuestionMastery = await Promise.all(testIds.map(testId => getAllQuestionMastery({userIdHashTestId: `${userId}#${testId}`}, type === 'quickReview' ? 'customTestWithTestId' : 'customTest')));
    const allQuestionMasteryItems = allQuestionMastery.reduce((acc, masteryResult) => [...acc, ...masteryResult.data.QuestionMasteryByTestId.items], []);
    const excludedQuestionIds = allQuestionMasteryItems.filter(({masteryLevel}) => !!masteryLevel && masteryLevel != 'none').map(({userIdHashQuestionBaseId}) => userIdHashQuestionBaseId.replace(`${userId}#`, ''));

    async function getQuestionIdsByTestId () {
      if (!['untagged', 'all'].includes(masteryLevel)) {
        return allQuestionMastery.reduce((acc, masteryResult, index) => ({...acc, [testIds[index]]: masteryResult.data.QuestionMasteryByTestId.items.filter(({masteryLevel: taggedMasteryLevel, bookmarked}) => masteryLevel === 'bookmarked' ? !!bookmarked : taggedMasteryLevel === masteryLevel).map(({userIdHashQuestionBaseId}) => userIdHashQuestionBaseId.replace(`${userId}#`, ''))}), {})
      } else {
        const results = await Promise.all(
          testIds.map(testId => getTest(testId, 'getTestQuestionIds'))
        );

        const questionIds = results
          ?.reduce((acc, {data: {getTest: {id: testId, blockConnections}}}) => ({
            ...acc,
            [testId]: blockConnections.items
            .reduce((acc, {testBlock}) => testBlock?.questionConnections ? [
              ...acc,
              ...testBlock.questionConnections.items
                .filter(({question}) => question && question?.status !== 'draft' && (!excludedQuestionIds.includes(question.id) || masteryLevel === 'all'))
                .map(({question}) => question.id)
            ] : acc, [])
          }), {});

        const totalQuestions = Object.values(questionIds).flat().length;


        if (totalQuestions < testLength && type === 'quickReview') {
          const difference = Math.abs(totalQuestions - testLength);
          const learningReviewing = shuffle(allQuestionMasteryItems)
            .slice(0, difference)
            .reduce((acc, curr) => {

              const testId = curr?.userIdHashTestId?.split('#')[1];
              const questionId = curr?.userIdHashQuestionBaseId?.split('#')[1];

              return {
                ...acc,
                [testId]: [
                  ...(acc[testId] || []),
                  questionId,
                ],
              }
            }, {});

          return Object.keys({...questionIds, ...learningReviewing})
            .reduce((acc, testId) => ({
              ...acc,
              [testId]: [
                ...(acc[testId] || []),
                ...(learningReviewing[testId] || []),
                ...(questionIds[testId] || []),
              ]
            }), {})

        }
        return questionIds;
      }
    }

    const questionIdsByTestId = await getQuestionIdsByTestId();

    const selectedQuestions = [];

    async function draftRound() {
      // Wrap the main logic in a function that can be called for each testId
      async function processTestId(testId) {
        const testQuestionPool = questionIdsByTestId[testId];
        if (testQuestionPool.length === 0) return null;

        const selectedQuestion = getRandom(testQuestionPool, 1)[0];

        if (['untagged', 'learning', 'reviewing', 'mastered'].includes(masteryLevel)) {
          const selectedQuestionMastery = await getAllQuestionMastery({userIdHashQuestionBaseId: `${userId}#${selectedQuestion}`});
          const {masteryLevel: savedMasteryLevel} = selectedQuestionMastery?.data?.getQuestionMastery || {};
          // if savedMasteryLevel does not match the current masteryLevel, skip this question
          const shouldSkip = masteryLevel === 'untagged' ? ['learning', 'reviewing', 'mastered'].includes(savedMasteryLevel) : ['learning', 'reviewing', 'mastered'].includes(savedMasteryLevel) && savedMasteryLevel !== masteryLevel;
          if (shouldSkip) {
            questionIdsByTestId[testId] = testQuestionPool.filter(question => question !== selectedQuestion);
            return null;
          }
        }
        const {data: {getQuestionBase: {status, connections: unfilteredConnections}}} = await getQuestionBaseWithConnections(selectedQuestion);
        if (status === 'draft') {
          questionIdsByTestId[testId] = testQuestionPool.filter(question => question !== selectedQuestion);
          return null;
        }
        const connections = unfilteredConnections?.items?.filter(({block}) => block?.blockConnections?.items?.length > 0);
        const sequentialBlock = connections?.find(({block}) => JSON.parse(block.components?.find(component => component.renderType === 'config')?.contents || 'null')?.isSequentialSet);

        if (sequentialBlock) {
          const {data: {getTestBlock: {questionConnections}}} = await getTestBlockWithQuestionIds(sequentialBlock.block.id);
          const sequentialQuestions = questionConnections?.items.sort(firstBy('index')).map(connection => connection?.question?.id);

          
          questionIdsByTestId[testId] = testQuestionPool.filter(question => !sequentialQuestions.includes(question));
          const currentTestLengthWithSequential = sequentialQuestions.length + selectedQuestions.length;

          if (currentTestLengthWithSequential <= testLength || bootcamp === 'nclex') {
            return sequentialQuestions.map((id, index) => ({
              id,
              testId,
              isSequentialSet: true,
              isSequentialStart: index === 0,
              isSequentialEnd: (sequentialQuestions.length - 1) === index
            }));
          }
        } else {
          questionIdsByTestId[testId] = testQuestionPool.filter(question => question !== selectedQuestion);
          return [{id: selectedQuestion, testId}];
        }

        return null;
      }

      // Use Promise.all to process all testIds in parallel
      const results = await Promise.all(testIds.map(processTestId));
      // Flatten the results and filter out nulls
      let questionsToAdd = results.reduce((acc, result) => result ? acc.concat(result.filter(Boolean)) : acc, []);

      // Make sure that questionsToAdd + selectedQuestions abides by the 35% case study ratio for NCLEX
      if (bootcamp === 'nclex' && !onlyCaseStudies && !testIds.includes('226a76c1-1a85-4b97-909d-f2f5d764a2d7')) {
        // Calculate the maximum number of questions with isSequentialSet === true
        const maxSequentialQuestions = Math.max(Math.round(testLength * 0.35), 6);

        // Count the current number of questions with isSequentialSet === true
        const currentSequentialQuestions = selectedQuestions.filter(question => question.isSequentialSet).length;

        // Calculate the remaining number of questions that can be added with isSequentialSet === true
        const remainingSequentialQuestions = Math.max(0, maxSequentialQuestions - currentSequentialQuestions);

        // Filter out the questionsToAdd that have isSequentialSet === true and limit the number of questions to be added
        const sequentialQuestionsToAdd = questionsToAdd.filter(question => question.isSequentialSet).slice(0, remainingSequentialQuestions);

        // Filter out the questionsToAdd that have isSequentialSet === false
        const nonSequentialQuestionsToAdd = questionsToAdd.filter(question => !question.isSequentialSet);

        // Combine the sequential and non-sequential questions to be added
        questionsToAdd = [...sequentialQuestionsToAdd, ...nonSequentialQuestionsToAdd];

        const questionsToAddByTestId = questionsToAdd.reduce((acc, question) => ({...acc, [question.testId]: [...(acc[question.testId] || []), question]}), {});

        // shuffle the values of questionsToAddByTestId and flatten
        questionsToAdd = shuffle(Object.values(questionsToAddByTestId)).flat();
      }

      // Add all the selected questions from this round to the main array
      selectedQuestions.push(...questionsToAdd);

      // Truncate selectedQuestions if it exceeds the testLength,
      // ensuring that sequential sets are not split
      const testLengthIndex = testLength - 1;
      const currentCutoff = selectedQuestions[testLengthIndex];
      let truncateIndex = testLengthIndex;

      if (selectedQuestions.length > testLength && currentCutoff?.isSequentialSet) {
        // Find the appropriate truncation point
        truncateIndex = selectedQuestions.findIndex((question, index) => index >= testLengthIndex && question.isSequentialEnd);
        // Truncate the selectedQuestions array at the found index
      }
      const truncatedTestLength = truncateIndex + 1;
      selectedQuestions.length = Math.min(truncatedTestLength, selectedQuestions.length);
    }
    while (selectedQuestions.length < testLength && Object.values(questionIdsByTestId).reduce((acc, testIds) => [...acc, ...testIds], []).length !== 0) await draftRound();

    config.testStorageKey = `userdata/${userId}/${bootcamp}/${new Date().toISOString()}_${nanoid()}.json`;
    config.createdAt = new Date();
    config.testLength = selectedQuestions.length;

    delete config.testIds;

    const customTestConfig = {config, selectedQuestions, testId, testIds};

    await Storage.put(config.testStorageKey, JSON.stringify(customTestConfig), {contentType: 'application/json'});

    const formattedTest = await formatTestFromQuestions(config, selectedQuestions, testId, testIds);

    return [formattedTest, customTestConfig];
  } catch (e) {
    console.log(e);
  }

}
