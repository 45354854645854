import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';

import {H4} from '../../../../components/Typography';
import {ReactComponent as Grid} from '@bootcamp/shared/src/assets/icons/grid-icon.svg';
import Folder from './Folder';
import {OpenIndicator} from '@bootcamp/web/src/components/Branding';

const Container = styled.div`
  padding: 0 16px;
  cursor: pointer;
`;
const Wrapper = styled.div`
  border-radius: 8px;
  background: ${({theme}) => theme.overlays.opacity.light._50};
`;
const CurrentlyStudying = styled(H4)`
  padding: ${({theme}) => theme.layouts.spacing.ms};
  font-size: 16px;
  display: flex;
  align-items: center;
  color: white;
  white-space: nowrap;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const MenuContainer = styled.div`
  position: relative;
`;
const MenuWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  z-index: 1;
  background: white;
  border-radius: 8px;
  overflow: hidden;
`;
const GridIcon = styled(Grid)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-right: 10px;
  width: 20px;
`;

const trackOptions = {
  '🦷 Dentistry': [
    {name: 'DAT Bootcamp', details: 'Ace your DAT', route: 'dat'},
    {name: 'INBDE Bootcamp', details: 'Crush the INBDE', route: 'inbde'},
    {name: 'Dental School Bootcamp', details: 'Ace your D1 / D2 classes', route: 'dental-school'},
  ],
  '🩺 Medical': [
    {name: 'Med School Bootcamp', details: 'Ace your classes and boards', route: 'med-school'},
  ],
  '⚕️ Nursing': [
    {name: 'NCLEX Bootcamp', details: 'Pass the NCLEX', route: 'nclex'},
  ],
  '👓 Optometry': [
    {name: 'OAT Bootcamp', details: 'Ace your OAT', route: 'oat'},
  ],
  '🥽 Natural Sciences': [
    {name: 'Anatomy Bootcamp', details: 'Ace your Anatomy class', route: 'anatomy'},
    {name: 'Chemistry Bootcamp', details: 'Ace your Chemistry class', route: 'chemistry'},
  ]
};

const TrackSelect = ({bootcamp}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const containerRef = useRef();

  const activeBootcamp = Object
    .values(trackOptions)
    .reduce((acc, curr) => [...acc, ...curr], [])
    .find(config => config.route === bootcamp);

  useEffect(() => {
    if (!containerRef?.current) return;

    const handleClick = event => {
      const isClickInsideContainer = containerRef?.current?.contains(event?.target);

      if (isClickInsideContainer) return;

      setMenuOpen(false);
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);

  }, [containerRef.current]);
  if (!activeBootcamp) return null;
  return (
    <Container ref={containerRef}>
      <Wrapper>
        <CurrentlyStudying onClick={() => setMenuOpen(currentState => !currentState)}>
          <GridIcon/>
          <span>{activeBootcamp.name}</span>
          <OpenIndicator size={16} open={menuOpen} />
        </CurrentlyStudying>
        {menuOpen && <MenuComponent bootcamp={bootcamp}/>}
      </Wrapper>
    </Container>
  )
};

export const MenuComponent = ({className, bootcamp}) => {
  const activeBootcamp = Object
    .values(trackOptions)
    .reduce((acc, curr) => [...acc, ...curr], [])
    .find(config => config.route === bootcamp);
  return (
    <MenuContainer>
      <MenuWrapper className={className}>
        {Object
          .keys(trackOptions)
          .map(track => (
            <Folder
              name={track}
              options={trackOptions[track]}
              activeBootcamp={activeBootcamp.route}
              defaultOpen={trackOptions[track].some(option => option.route === activeBootcamp.route)}
            />
          ))
        }
      </MenuWrapper>
    </MenuContainer>
  )
}

export {trackOptions};
export default TrackSelect;
