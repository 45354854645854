import {Row} from '../../../../../components/Grid';
import {Label3, Body2} from '../../../../../components/Typography';
import styled from 'styled-components';


export const Container = styled.div`
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  border-radius: 8px;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  max-width: 640px;
  width: 100%;
`;

export const TitleBar = styled(Row)`
  align-items: center;
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  ${({theme}) => theme.mediaQueries.tablet} {
    padding-left: ${({theme}) => theme.layouts.spacing.l};
  }
  min-height: 56px;
`;

export const Content = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.l};
  }
`;
export const Detail = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;
export const DetailLabel = styled(Label3)`
  color: ${({theme, invalid}) => invalid ? theme.colors.interfacePalette.red.dark : theme.colors.neutralsPalette.grey};
  margin-bottom: ${({theme}) => theme.layouts.spacing.xs};
  font-weight: ${({theme}) => theme.font.weight.semibold};
`;
export const DetailContent = styled(Body2)`
  display: inline-block;
  padding: ${({theme}) => theme.layouts.spacing.s};
  background: ${({theme, invalid}) => invalid ? theme.colors.interfacePalette.red.light : theme.colors.neutralsPalette.extraLight};
  border-radius: 8px;
`;