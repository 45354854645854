import React from 'react';
import {useHistory} from 'react-router-dom';

import {Button, LinkButton, IconButton} from '../Branding/Buttons';

import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {ArrowBackOutline} from '@styled-icons/evaicons-outline/ArrowBackOutline';
import {RefreshOutline} from '@styled-icons/evaicons-outline/RefreshOutline';
import {CheckmarkOutline} from '@styled-icons/evaicons-outline/CheckmarkOutline';
import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';
import {ArrowPath as Refresh} from '@styled-icons/heroicons-outline/ArrowPath';

import styled from 'styled-components';
import useHotkeys from "@reecelucas/react-use-hotkeys";

export const backButtonWidth = 56;


export const StyledBackButton = styled(Button)`
  align-self: flex-start;
  width: auto;
  z-index: 1;
  border: 1px solid ${({theme}) => theme.overlays.opacity.light._400};
  color: ${({theme}) => theme.overlays.opacity.light._400};

  button {
    font-weight: 500;
    width: ${backButtonWidth}px;
    margin-bottom: ${({theme}) => theme.layouts.spacing.l};

    ${({theme}) => theme.mediaQueries.mobileL} {
      padding: ${({theme}) => theme.layouts.spacing.m};
      margin-bottom: ${({theme}) => theme.layouts.spacing.s};
      justify-content: flex-start;

      svg {
        fill: ${({theme, inMenu}) => !inMenu ? theme.colors.neutralsPalette.grey : theme.colors.neutralsPalette.white};
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const BackButton = ({
  inMenu,
  className,
  onClick
}) => {

  const {goBack} = useHistory();

  return(
    <StyledBackButton
      className={className}
      onClick={onClick ? onClick : goBack ? goBack : null}
      type={'ghostWhite'}
      children={'Exit'}
      inMenu={inMenu}
      iconConfig={{
        position: 'left',
        icon: ArrowBackOutline,
        size: 16,
      }}
      />
  );
}


const StyledStartButton = styled(Button)`
  white-space: nowrap;
`;

const StyledRetakeTestButton = styled(Button)`
  margin-left: 16px;
  background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.colors.neutralsPalette.white};
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  box-shadow: 0px 2px 0px ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  white-space: nowrap;

  &:hover {
    background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : theme.colors.neutralsPalette.white};
  }
`;

export const StartButton = ({color, colorConfig, onClick, children='Begin Attempt', ...props}) => {
  useHotkeys('space', onClick, [onClick]);

  return (
    <StyledStartButton
      onClick={onClick}
      themeColor={color}
      colorConfig={colorConfig}
      children={children}
      fixed
      {...props}
    />
  );
}

export const RetakeTestButton = ({color, onClick, children='Begin Attempt'}) => (
  <StyledRetakeTestButton
    onClick={onClick}
    themeColor={color}
    children={children}
    iconConfig={{
      position: 'left',
      icon: Refresh,
      size: 16
    }}
    fixed
    />
);

const StyledStartReviewButton = styled(StyledStartButton)`
  width: ${({fullWidth}) => fullWidth ? '100%' : '50%'} ;
`;

export const StartReviewButton = ({color, onClick, fullWidth}) => (
  <StyledStartReviewButton
    onClick={onClick}
    themeColor={color}
    fullWidth={fullWidth}
    children={'Begin Review'}
    iconConfig={{
      position: 'right',
      icon: ArrowForwardOutline,
      size: 16
    }}
    />
);

const StyledLinkButton = styled(LinkButton)`
  width: 50%;
  margin-right: 12px;

  button {
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    border: 1px solid ${({themeColor}) => themeColor};
    color: ${({themeColor}) => themeColor};
  }
`;

export const RetakeQuizButton = ({color}) => (
  <StyledLinkButton
    to={(location) => location.pathname}
    themeColor={color}
    type={'ghost'}
    children={'Retake Quiz'}
    iconConfig={{
      position: 'left',
      icon: RefreshOutline,
      size: 16,
    }}
    fixed
    />
);


const CloseButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  align-self: flex-start;
  z-index: 1;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => theme.layouts.spacing.m};
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
    justify-content: flex-start;

    svg {
      stroke: ${({theme}) => theme.colors.neutralsPalette.grey};
      width: 24px;
      height: 24px;
    }
  }
`;

const CloseIcon = styled(X).attrs(props => ({size: 28, color: 'white'}))``;

export const ExitQuizButton = ({className, onClick}) => {
  const {push, action, goBack} = useHistory();

  const canGoBack = action !== 'POP';
  const urlParts = window.location.pathname.split('/');
  const previousPath = urlParts
    .slice(0, 2)
    .join('/');

  const handleGoBack = () => {
    if (canGoBack) {
      goBack();
    } else {
      push(previousPath);
    }
  };

  return (
    <CloseButton
      className={className}
      onClick={handleGoBack}
      type={'secondary'}
      size={'large'}
      children={<CloseIcon />}
    />
  );

}

const StyledExhibitButton = styled(Button)`
  box-shadow: none;
  background: ${({theme, themePalette, active}) => active ? themePalette.default : theme.overlays.opacity.light._100};
  margin-left: ${({theme}) => theme.layouts.spacing.s};

  &:hover {
    transform: none;
    background: ${({theme, themePalette, active}) => active ? themePalette.hover : theme.overlays.opacity.light._200};
  }
  &:disabled {
    background: ${({theme}) => theme.overlays.opacity.light._100};
  }
`;
const StyledPreviousButton = styled(Button)`
  box-shadow: none;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  margin-right: ${({theme}) => theme.layouts.spacing.s};

  &:hover {
    transform: none;
    background: ${({theme}) => theme.overlays.opacity.light._200};
  }
  &:disabled {
    background: ${({theme}) => theme.overlays.opacity.light._100};
  }
`;
const StyledCheckButton = styled(Button)`
  background: ${({themePalette}) => themePalette.default};
  &:hover {
    background: ${({themePalette}) => themePalette.hover};
  }
  &:disabled {
    background: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    transition: none;
    box-shadow: none;
  }
`;
export const ExhibitButton = (props) => {
  return (
    <StyledExhibitButton
      {...props}
    />
  );
}
export const PreviousButton = (props) => {
  return (
    <StyledPreviousButton
      {...props}
      iconConfig={{
        position: 'left',
        icon: ArrowBackOutline,
        size: 16,
      }}
    />
  );
}
export const CheckButton = (props) => {
  return (
    <StyledCheckButton
      {...props}
      iconConfig={{
        position: 'right',
        icon: CheckmarkOutline,
        size: 16,
      }}
    />
  );
}
export const NextButton = (props) => {
  return (
    <StyledCheckButton
      {...props}
      iconConfig={{
        position: 'right',
        icon: ArrowForwardOutline,
        size: 16
      }}
    />
  );
}
