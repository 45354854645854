import React from 'react';
import {useHistory} from 'react-router-dom';

import {Button, LinkButton, IconButton} from '../Branding/Buttons';

import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {ArrowBackOutline} from '@styled-icons/evaicons-outline/ArrowBackOutline';
import {RefreshOutline} from '@styled-icons/evaicons-outline/RefreshOutline';
import {CheckmarkOutline} from '@styled-icons/evaicons-outline/CheckmarkOutline';
import {Bookmark} from '@styled-icons/heroicons-outline/Bookmark';
import {Calculator} from '@styled-icons/heroicons-outline/Calculator';
import {RectangleGroup as Template} from '@styled-icons/heroicons-outline/RectangleGroup';
import {Home} from '@styled-icons/heroicons-outline/Home';
import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';

import styled, {css} from 'styled-components';
import useHotkeys from "@reecelucas/react-use-hotkeys";
import {useHotkeys as oldUseHotkeys} from "react-hotkeys-hook";

export const backButtonWidth = 56;


export const StyledBackButton = styled(Button)`
  align-self: flex-start;
  width: auto;
  z-index: 1;
  border: 1px solid ${({theme}) => theme.overlays.opacity.light._400};
  color: ${({theme}) => theme.overlays.opacity.light._400};

  button {
    font-weight: 500;
    width: ${backButtonWidth}px;
    margin-bottom: ${({theme}) => theme.layouts.spacing.l};

    ${({theme}) => theme.mediaQueries.mobileL} {
      padding: ${({theme}) => theme.layouts.spacing.m};
      margin-bottom: ${({theme}) => theme.layouts.spacing.s};
      justify-content: flex-start;

      svg {
        fill: ${({theme, inMenu}) => !inMenu ? theme.colors.neutralsPalette.grey : theme.colors.neutralsPalette.white};
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const BackButton = ({
  inMenu,
  className,
  onClick
}) => {

  const {goBack} = useHistory();

  return(
    <StyledBackButton
      className={className}
      onClick={onClick ? onClick : goBack ? goBack : null}
      type={'ghostWhite'}
      children={'Exit'}
      inMenu={inMenu}
      iconConfig={{
        position: 'left',
        icon: ArrowBackOutline,
        size: 16,
      }}
      />
  );
}


const StyledStartButton = styled(Button)`
  background: ${({themeColor}) => themeColor};

  &:hover {
    background: ${({themeColor}) => themeColor};
  }
`;

export const StartButton = ({color, onClick, children='Begin Attempt', disabled}) => (
  <StyledStartButton
    onClick={onClick}
    themeColor={color}
    children={children}
    disabled={disabled}
    iconConfig={{
      position: 'right',
      icon: ArrowForwardOutline,
      size: 16
    }}
    fixed
    />
);

const StyledStartReviewButton = styled(StyledStartButton)`
  width: ${({fullWidth}) => fullWidth ? '100%' : '50%'} ;
`;

export const StartReviewButton = ({color, onClick, fullWidth}) => (
  <StyledStartReviewButton
    onClick={onClick}
    themeColor={color}
    fullWidth={fullWidth}
    children={'Begin Review'}
    iconConfig={{
      position: 'right',
      icon: ArrowForwardOutline,
      size: 16
    }}
    />
);

const StyledLinkButton = styled(LinkButton)`
  width: 50%;
  margin-right: 12px;

  button {
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    border: 1px solid ${({themeColor}) => themeColor};
    color: ${({themeColor}) => themeColor};
  }
`;

export const RetakeQuizButton = ({color}) => (
  <StyledLinkButton
    to={(location) => location.pathname}
    themeColor={color}
    type={'ghost'}
    children={'Retake Quiz'}
    iconConfig={{
      position: 'left',
      icon: RefreshOutline,
      size: 16,
    }}
    fixed
    />
);


const CloseButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  align-self: flex-start;
  z-index: 1;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => theme.layouts.spacing.m};
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
    justify-content: flex-start;

    svg {
      stroke: ${({theme}) => theme.colors.neutralsPalette.grey};
      width: 24px;
      height: 24px;
    }
  }
`;

const CloseIcon = styled(X).attrs(props => ({size: 28, color: 'white'}))``;

export const ExitQuizButton = ({className, onClick}) => {
  const {goBack} = useHistory();

  return (
    <CloseButton
      className={className}
      onClick={onClick || goBack}
      type={'secondary'}
      size={'large'}
      children={<CloseIcon />}
    />
  );

}

const HomeIcon = styled(Home).attrs(props => ({size: 28, color: 'white'}))``;

export const HomeButton = ({className, destination='/'}) => {
  const {push} = useHistory();

  return (
    <CloseButton
      className={className}
      onClick={() => push(destination)}
      type={'secondary'}
      size={'large'}
      children={<HomeIcon />}
    />
  );

}

const StyledExhibitButton = styled(Button)`
  box-shadow: none;
  background: ${({theme, themePalette, active}) => active ? themePalette.default : theme.overlays.opacity.light._100};
  margin-left: ${({theme}) => theme.layouts.spacing.s};

  &:hover {
    transform: none;
    background: ${({theme, themePalette, active}) => active ? themePalette.hover : theme.overlays.opacity.light._200};
  }
  &:disabled {
    background: ${({theme}) => theme.overlays.opacity.light._100};
  }
`;
const StyledPreviousButton = styled(Button)`
  box-shadow: none;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  margin-right: ${({theme}) => theme.layouts.spacing.s};

  &:hover {
    transform: none;
    background: ${({theme}) => theme.overlays.opacity.light._200};
  }
  &:disabled {
    background: ${({theme}) => theme.overlays.opacity.light._100};
  }
`;
const StyledNavButton = styled(Button)`
  box-shadow: none;
  background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.colors.neutralsPalette.white};
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  color: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.neutralsPalette.extraDark : theme.colors.neutralsPalette.dark};
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  box-shadow: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : 'rgb(200 201 206)'} 0px 2px 0px;

  &:hover {
    transform: none;
    background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : theme.overlays.opacity.light._200};
  }
  &:disabled {
    background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfacePrimary : theme.overlays.opacity.light._100};
    color: ${({theme}) => theme.colors.neutralsPalette.light};
    ${({theme}) => theme.darkModeEnabled && css`
      opacity: 0.5;
    `}
  }
  &:last-child {
    margin-right: 0;
  }
`;
const StyledCheckButton = styled(Button)`
  &:disabled {
    background: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    transition: none;
    box-shadow: none;
  }
`;
const StyledUpdatedCheckButton = styled(Button)`
  margin-right: ${({theme}) => theme.layouts.spacing.s};
`;
const StyledBookmarkButton = styled(Button)`
  box-shadow: #c8c9ce 0px 2px 0px;
  background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.colors.neutralsPalette.white};
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  color: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.neutralsPalette.extraDark : theme.colors.neutralsPalette.dark};
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  box-shadow: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : 'rgb(200 201 206)'} 0px 2px 0px;

  &:hover {
    background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : theme.overlays.opacity.light._200};
  }

  svg > path {
    fill: ${({theme, bookmarked}) => bookmarked ? theme.colors.neutralsPalette.dark : 'none'};
  }
  &:last-child {
    margin-right: 0;
  }
`;
export const ExhibitButton = (props) => {
  return (
    <StyledExhibitButton
      {...props}
    />
  );
}
export const PreviousButton = (props) => {
  return (
    <StyledPreviousButton
      {...props}
      iconConfig={{
        position: 'left',
        icon: ArrowBackOutline,
        size: 16,
      }}
    />
  );
}
export const UpdatedPreviousButton = (props) => {
  oldUseHotkeys('left', !props.disabled ? props.onClick : () => true, [props.onClick]);

  return (
    <StyledNavButton
      {...props}
      iconConfig={{
        position: 'left',
        icon: ArrowBackOutline,
        size: 20,
      }}
    />
  );
}
export const CheckButton = (props) => {
  useHotkeys(['c', 'enter'], !props.disabled ? props.onClick : () => true, [props.onClick]);

  return (
    <StyledCheckButton
      {...props}
      iconConfig={{
        position: 'right',
        icon: CheckmarkOutline,
        size: 16,
      }}
    />
  );
}
export const UpdatedCheckButton = (props) => {
  useHotkeys(['c', 'enter'], !props.disabled && !props.hotkeyDisabled ? props.onClick : () => true, [props.onClick]);

  return (
    <StyledUpdatedCheckButton
      {...props}
    />
  );
}
export const NextButton = (props) => {
  return (
    <StyledCheckButton
      {...props}
      iconConfig={{
        position: 'right',
        icon: ArrowForwardOutline,
        size: 16
      }}
    />
  );
}
export const UpdatedNextButton = (props) => {
  oldUseHotkeys('right', !props.disabled ? props.onClick : () => true, [props.onClick]);

  return (
    <StyledNavButton
      {...props}
      iconConfig={{
        position: 'right',
        icon: ArrowForwardOutline,
        size: 20
      }}
    />
  );
}

export const BookmarkButton = (props) => {
  useHotkeys('b', props.onClick, [props.onClick]);

  return (
    <StyledBookmarkButton
      {...props}
      iconConfig={{
        position: 'left',
        icon: Bookmark,
        size: 16
      }}
    />
  );
}

export const UpdatedExhibitButton = ({exhibitType, ...props}) => {
  useHotkeys('e', props.onClick, [props.onClick]);
  const icon = exhibitType === 'calculator' ? Calculator : Template;

  return (
    <StyledNavButton
      {...props}
      as={Button}
      iconConfig={{
        position: 'left',
        size: 16,
        addSpacing: false,
        icon,
      }}
    />
  );
}
