import React, {createContext, useEffect, useState, useCallback, useContext} from 'react';
import styled from 'styled-components';
import useDocumentTitle from '@rehooks/document-title';

import Container from '@bootcamp/web/src/bootcamps/pages/PageContainer';
import {H1, H2} from '@bootcamp/web/src/components/Typography';
import Breadcrumbs from '@bootcamp/web/src/components/NavBar/components/Breadcrumbs';
import {Element as FAQElement} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';


import Overall from './Overall';
import Subject from './Subject';

import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';

import {getTestsByClassroom, getNCLEXSubjectSystemTags} from '@bootcamp/shared/src/util';
import {useMasteryArray, useTestProgressArray} from '@bootcamp/web/src/hooks';
import {createTestProgressWithBlocks, createTestProgressWithBlocksAndQuestions, getTestProgressSimple, getTestProgressPerformance, getClassroomQuestionCounts, getTag, createBlockProgress, trackQuestionPerformance, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {formatClassroomMasteryWithTopic, formatClassroomVideoProgress} from '@bootcamp/shared/src/util/formatting';

import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import emily from '@bootcamp/shared/src/assets/educators/emily_giddings.jpeg';
import anthony from '@bootcamp/shared/src/assets/images/inbde/anthony_roviso.jpeg';

import {useAsync} from '@bootcamp/web/src/hooks';

import {Chiclet} from '@bootcamp/web/src/components/UI';
import {PlayCircle} from '@styled-icons/fluentui-system-filled/PlayCircle'

const Title = styled(H1)`
  color: white;
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const FAQHeader = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;


const educatorsByBootcamp = {
  inbde: ari,
  nclex: emily,
  'med-school': anthony,
};

const scorePercentageBySubjectByBootcamp = {
  'inbde' : {
    'Fields of Dentistry': 64,
    'Oral Pathology and Oral Radiology': 65,
    'Medicine': 60,
    'Pharmacology': 58,
    'Patient Management': 62,
    'Anatomy and Physiology': 64,
    'Research': 58,
    'Case Sets':65,
    'Simulation Exam': 65
  },
  'med-school': {
    'Biochemistry': 56,
    'Biostatistics': 56,
    'Cardiology': 54,
    'Dermatology': 56,
    'Endocrinology': 58,
    'Gastroenterology': 54,
    'Hematology & Oncology': 64,
    'Immunology': 65,
    'Microbiology': 55,
    'Musculoskeletal': 55,
    'Nephrology': 52,
    'Neurology': 55,
    'Psychiatry': 60,
    'Public Health Sciences': 58,
    'Pulmonology': 58,
    'Reproduction': 60,
    'Genetics': 55,
  },
  'nclex': {
    'Adult Health': 61,
    'Child Health': 59,
    'Critical Care': 59,
    'Fundamentals': 63,
    'Maternal & Newborn Health': 54,
    'Mental Health': 62,
    'Pharmacology': 60,
    'Management of Care': 68,
  }
};

const faqItems = (bootcamp) => {
  switch (bootcamp) {
    case 'inbde':
    case 'med-school':
      return [
        {
          title: 'How is my score calculated?',
          text: 'Your score is calculated based on the result of every question you attempt (excluding Bites questions). You can answer each question as many times as you’d like. Every correct answer will increase your average score.'
        },
        {
          title: 'Are Bites / Video Quiz Questions counted towards my score or questions tagged?',
          text: 'Not at the moment. Bites / Video Quiz questions are meant to reinforce your understanding of concepts, but are not counted towards your score or questions tagged targets on this page.'
        },
        // {
        //   title: 'How can I improve my score in each subject?',
        //   text: 'Focus on strengthening weak topics, indicated by a red icon in the Topic Breakdown table, to increase your overall subject score over time.'
        // },
        // {
        //   title: 'What if I meet less than 10 targets?',
        //   text: 'That\'s OK! Many students pass without meeting 10 targets. Aim to meet at least 7 targets before your INBDE.'
        // },
        {
          title: 'Can I reset my score for each subject?',
          text: 'Not at the moment. However, you can answer each question as many times as you’d like. Every correct answer will increase your average score.'
        },
        {
          title: 'Can I reset or untag my tagged questions?',
          text: "Not at the moment. Once a question is tagged, it remains tagged. However, you can answer each question as many times as you'd like and change the tag each time."
        }
      ]
    case 'nclex':
      return [
        {
          title: 'How is my score calculated?',
          text: 'Your score is calculated based on the result of every question you attempt. You can answer each question as many times as you’d like. Every correct answer will increase your average score.'
        },
        // {
        //   title: 'What if I meet less than 10 targets?',
        //   text: 'That\'s OK! Many students pass without meeting 10 targets. Aim to meet at least 7 .'
        // },
        {
          title: 'Can I reset my score for each subject?',
          text: 'Not at the moment. However, you can answer each question as many times as you’d like. Every correct answer will increase your average score.'
        },
        {
          title: 'Can I reset or untag my tagged questions?',
          text: "Not at the moment. Once a question is tagged, it remains tagged. However, you can answer each question as many times as you'd like and change the tag each time."
        },
        {
          title: 'Are Readiness Exam included in my performance?',
          text: "Not at the moment. If you'd like to review your performance on the Readiness Exams, go to the Readiness Exams page."
        },
    ]
    default:
      return [];
  }
}

// this goes classroom by classroom, but we need to incorporate case studies into
function formatPerformanceObject(config, mastery, progress, questionCounts, tags, masteryLoaded, bootcamp) {
  return config.reduce((acc, classroom, index) => {
    try {
      const { tagId, tagIds, contentTypes } = classroom;

      if (!tagId && !tagIds) return null;

      const allTags = (tagIds ? [tagId, ...tagIds.filter(tagId => ![
        '47dc56f4-1159-45f1-8f2c-b863e3858fd4',
        'cedc26ad-70e2-4fc7-bd4b-d89924f86e86',
        'cb030e2b-4777-4c94-9eeb-1783a9591f1e',
        '2e707878-33ba-48f7-8e0f-79be39da176b',
        'bfb4f691-60c1-45f1-a2c5-d9cf67e3d927',
        '1505dd17-a88a-4dde-a7bf-a3a79dfafb4d',
        'bf8d83c5-0ce3-4902-bd38-d2aba900301a',
        '25e3bcd7-2ac8-4c89-ac33-88825924d965',
        '738b7ff7-3502-4894-8d4d-4193f939766e',
        '8f5e08ad-f30b-4a64-b0af-24b12ad97497',
        '163640c8-3e6e-450c-8edd-6935175d224f',
        '7e8f2c34-eed0-4439-a797-8fa5b141630e',
        'e3999743-fedd-43bd-bea3-dbb9e79a28b9', // remove tagId
        '94fb101c-ee2f-4182-95d9-7c55299a0569',
        'fe4787d2-942d-4d6f-95a9-da5a67a49bf7',
        '0843ec65-11e2-4217-a12a-f726af76b082'
      ].includes(tagId))] : [tagId]).filter((id, index) => !!id);

      const totalQuestions = questionCounts?.classroomQuestionCounts?.[classroom?.route];
      const classroomPerformance = allTags.reduce((acc, subjectTagId) => {
          const topicPerformance = progress?.[subjectTagId] || {};
          const masteryStats = bootcamp === 'nclex'
            ? mastery[subjectTagId]?.bySystem || {}
            : mastery[subjectTagId]?.byTopic || {}

          const combinedTopicKeys = Object.keys({...(topicPerformance || {}), ...(masteryStats || {})});

          const combinedTopicPerformance = combinedTopicKeys.reduce((acc, topicId) => {
            const topicName = tags?.[topicId]?.topic;
            const systemName = tags?.[topicId]?.system;

            if (topicName?.match('N/A')) return acc;
            return {
              ...acc,
              [topicId]: {
                ...acc[topicId],
                performance: (topicPerformance[topicId] || {}),
                mastery: (masteryStats?.[topicId] || {}),
                name: bootcamp === 'nclex' ? systemName?.replace('(N/A) ', '') : topicName,
              }
            };
          }, {});

          const subjectPerformance = Object.values(combinedTopicPerformance).reduce((acc, curr) => ({
            ...acc,
            mastery: {
              learning: (acc?.mastery?.learning || 0) + (curr?.mastery?.learning || 0),
              reviewing: (acc?.mastery?.reviewing || 0) + (curr?.mastery?.reviewing || 0),
              mastered: (acc?.mastery?.mastered || 0) + (curr?.mastery?.mastered || 0),
              total: (acc?.mastery?.total || 0) + (curr?.mastery?.total || 0),
              bookmarked: (acc?.mastery?.bookmarked || 0) + (curr?.mastery?.bookmarked || 0),
            },
            performance: {
              correct: (acc?.performance?.correct || 0) + (curr?.performance?.correct || 0),
              attempts: (acc?.performance?.attempts || 0) + (curr?.performance?.attempts || 0),
            },
          }), {mastery: {}, performance: {}});

          return {
            ...acc,
            mastery: {
              learning: (acc?.mastery?.learning || 0) + (subjectPerformance?.mastery?.learning || 0),
              reviewing: (acc?.mastery?.reviewing || 0) + (subjectPerformance?.mastery?.reviewing || 0),
              mastered: (acc?.mastery?.mastered || 0) + (subjectPerformance?.mastery?.mastered || 0),
              total: (acc?.mastery?.total || 0) + (subjectPerformance?.mastery?.total || 0),
              bookmarked: (acc?.mastery?.bookmarked || 0) + (subjectPerformance?.mastery?.bookmarked || 0),
            },
            performance: {
              correct: (acc?.performance?.correct || 0) + (subjectPerformance?.performance?.correct || 0),
              attempts: (acc?.performance?.attempts || 0) + (subjectPerformance?.performance?.attempts || 0),
            },
            byTopic: {
              ...(acc?.byTopic || {}),
              ...combinedTopicPerformance
            }
          }
        }, {});

        return {
          ...acc,
          overall: {
            performanceLoaded: !!progress,
            masteryLoaded: masteryLoaded,
            attempts: acc.overall.attempts + (classroomPerformance?.performance?.attempts || 0),
            correct: acc.overall.correct + (classroomPerformance?.performance?.correct || 0),
            mastery: {
              learning: acc.overall.mastery.learning + (classroomPerformance?.mastery?.learning || 0),
              reviewing: acc.overall.mastery.reviewing + (classroomPerformance?.mastery?.reviewing || 0),
              mastered: acc.overall.mastery.mastered + (classroomPerformance?.mastery?.mastered || 0),
              total: acc.overall.mastery.total + (classroomPerformance?.mastery?.learning || 0) + (classroomPerformance?.mastery?.reviewing || 0) + (classroomPerformance?.mastery?.mastered || 0),
            },
            totalQuestions: acc.overall.totalQuestions + (totalQuestions || 0),
          },
          byClassroom: [
            ...acc.byClassroom,
            {
              name: classroom.name,
              route: classroom.route,
              icon: classroom.icon,
              index,
              masteryLoaded,
              performanceLoaded: !!progress,
              totalQuestions,
              targets: {
                totalTagged: Math.round((totalQuestions * .9) || 0),
                averageScorePercentage: scorePercentageBySubjectByBootcamp[bootcamp][classroom.name],
              },
              ...classroomPerformance
            }
          ]
        }
    } catch (error) {
      // progress or mastery not loaded yet
      return {
        ...acc,
        byClassroom: [
          ...acc.byClassroom,
          {name: classroom.name, index, mastery: {}, performance: {}}
        ]
      }
    }
  }, {
    byClassroom: [],
    overall: {
      attempts: 0,
      correct: 0,
      totalQuestions: 0,
      mastery: {
        learning: 0,
        reviewing: 0,
        mastered: 0,
        total: 0
      }
    },
  });
}

async function fetchTags(performance, callback) {
  try {
    const tagIds = performance
      .reduce((acc, curr) => ([...acc, ...Object.keys(curr?.byTopic)]), [])
      .filter(key => key !== 'undefined')
      .map(async key => getTag(key));

    const tags = await Promise.all(tagIds);
    const formattedTags = tags.reduce((acc, curr) => {
      const tag = curr?.data?.getTag;

      return {
        ...acc,
        [tag.id]: tag,
      }
    }, {});

    callback(formattedTags);

  } catch (error) {
    // console.log('error fetching tags', error);
    callback([]);
  }
}

export function filterClassroomsByBootcampForPerformance(bootcamp, config, filter) {
  switch (bootcamp) {
    case 'inbde':
      return config.classrooms.filter(({route, name}) => !['review-videos'].includes(route) && (filter ? filter.includes(name) : true));
    case 'med-school':
      return config.classrooms.filter(({route}) => !['gross-anatomy', 'neuroanatomy', 'histology', 'embryology', 'high-yield-step-1-anatomy', 'pathology', 'pharmacology', 'omm'].includes(route));
    case 'nclex':
      return config.classrooms.filter(({testing, name, route}) => !testing && !['next-gen-strategy-course', 'free-questions'].includes(route));
    default:
      return config.classrooms;
  }
}

export function filterClassroomsByBootcampForDisplay(bootcamp, config, filter) {
  switch (bootcamp) {
    case 'inbde':
      return config.classrooms.filter(({route, name}) => !['review-videos'].includes(route) && (filter ? filter.includes(name) : true));
    case 'med-school':
      return config.classrooms.filter(({route}) => !['gross-anatomy', 'neuroanatomy', 'histology', 'embryology', 'high-yield-step-1-anatomy', 'pathology', 'pharmacology', 'omm', 'osteopathy'].includes(route));
    case 'nclex':
      return config.classrooms.filter(({testing, name, route}) => !testing && !['next-gen-strategy-course', 'free-questions', 'next-gen-cases'].includes(route));
    default:
      return config.classrooms;
  }
}

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({theme}) => theme.layouts.spacing.xl};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding: 72px 0px 0px;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    border-radius: 8px 8px 0px 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgb(0,0,0,.25);
  width: 100%;
  /* height: 100%; */
  max-width: 960px;
  aspect-ratio: 16 / 9;
  width: 100%;
  border-radius: 8px;
  /* overflow: hidden; */
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 20px;
  /* transition: all 120ms cubic-bezier(0.175, 0.885, 0.320, 1.275); easeOutBack */
  /* transform: ${({mounted}) => mounted ? 'scale(1)' : 'scale(.95)'}; */
  iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    border-radius: 8px 8px 0px 0px;
  }
`;

const VideoPlayer = () => (
  <VideoContainer>
    <Wrapper onClick={e => e.stopPropagation()}>
      <iframe src="https://www.youtube.com/embed/GpzMTwiF9Lg" title="INBDE Bootcamp Performance Tutorial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </Wrapper>
  </VideoContainer>
)

const StyledButton = styled(Chiclet)`
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: row;

  ${({theme}) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    margin-bottom: 0px;
    flex-direction: column;
  }
`;

export const getFAQs = (bootcamp) => faqItems(bootcamp).map((item, index) => <FAQElement key={index} {...item} />);

const PerformanceContext = createContext();

export const PerformanceProvider = ({children, classroomFilter}) => {
  const [testProgress, setTestProgress] = useState(null);
  const [masteryLoaded, setMasteryLoaded] = useState(false);
  const [tags, setTags] = useState(null);
  const {modalDispatch} = useModalContext();
  const {bootcamp, DEFAULT_USER_ID, isAdmin} = useUserDataContext();
  const [userId, setUserId] = useState(DEFAULT_USER_ID);
  const {config, questionCounts: questionCountsByTest} = useBootcampConfigContext();

  useDocumentTitle(`${config.meta.siteTitle} | Performance`);
  const performanceClassrooms = filterClassroomsByBootcampForPerformance(bootcamp, config, classroomFilter);
  const displayClassrooms = filterClassroomsByBootcampForDisplay(bootcamp, config, classroomFilter);

  // question count loading
  const testsByClassroom =   getTestsByClassroom(performanceClassrooms, [], [], false, ['Question Banks', 'Board Style Questions', 'Subject Case Studies']);
  // const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);

  // const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);
  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom), []);

  // const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const classroomTestIds = Object.values(testsByClassroom).flat();
  // const classroomCourseIds = Object.values(coursesByClassroom).flat();

  const questionCountsByTestByClassroom = Object.keys(testsByClassroom).reduce((acc, classroomName) => {
    const testIds = testsByClassroom[classroomName];
    const classroomQuestionCounts = testIds.reduce((acc1, testId) => {
      const testQuestionCounts = Object.values(questionCountsByTest?.[testId] || {}).length;
      return acc1 + testQuestionCounts;
    }, 0);

    return {
      ...acc,
      classroomQuestionCounts: {
        ...acc.classroomQuestionCounts,
        [classroomName]: classroomQuestionCounts,
      }
    }
  }, {classroomQuestionCounts: {}});

  // mastery loading
  const testMastery = useMasteryArray(classroomTestIds, 'test', data => formatClassroomMasteryWithTopic(data, 'test'), null, () => setMasteryLoaded(true), userId);
  // const videoLessonProgress = useTestProgressArray(classroomCourseIds, formatClassroomVideoProgress, DEFAULT_USER_ID);

  // const videoLessonPerformance = {
  //   complete: videoLessonProgress[0],
  //   total: Object.values(courseBlockCounts?.classroomCourseBlockCounts || {})?.reduce((acc, curr) => acc + curr, 0),
  // };


  const subjectMastery = classroomTestIds.reduce((acc, id) => {
    const masteryObj = testMastery?.[id];

    const newAcc = {...acc};

    const mastery = bootcamp === 'nclex'
      ? masteryObj?.bySystem || {}
      : masteryObj?.byTopic || {};

    Object.entries(mastery).forEach(([masteryId, topicMastery]) => {
      const subjectTagId = topicMastery?.tag?.subjectTagId;
      const masteryKey = bootcamp === 'nclex'
        ? 'bySystem'
        : 'byTopic';

      newAcc[subjectTagId] = {
        ...(acc[subjectTagId] || {}),
        learning: (newAcc?.[subjectTagId]?.learning || 0) + (topicMastery?.learning || 0),
        reviewing: (newAcc?.[subjectTagId]?.reviewing || 0) + (topicMastery?.reviewing || 0),
        mastered: (newAcc?.[subjectTagId]?.mastered || 0) + (topicMastery?.mastered || 0),
        total: (newAcc?.[subjectTagId]?.total || 0) + (topicMastery?.total || 0),
        [masteryKey]: {
          ...(newAcc?.[subjectTagId]?.[masteryKey] || {}),
          [masteryId]: {
            learning: (newAcc?.[subjectTagId]?.[masteryKey]?.[masteryId]?.learning || 0) + (topicMastery?.learning || 0),
            reviewing: (newAcc?.[subjectTagId]?.[masteryKey]?.[masteryId]?.reviewing || 0) + (topicMastery?.reviewing || 0),
            mastered: (newAcc?.[subjectTagId]?.[masteryKey]?.[masteryId]?.mastered || 0) + (topicMastery?.mastered || 0),
            total: (newAcc?.[subjectTagId]?.[masteryKey]?.[masteryId]?.total || 0) + (topicMastery?.total || 0),
          }
        },
      };
    });

    return newAcc;
  }, {});

  // loading flag
  const loadingPerformance = !testProgress || !masteryLoaded;

  // performance object formatting
  const performance = formatPerformanceObject(displayClassrooms, subjectMastery, testProgress, bootcamp === 'med-school' ? questionCountsByTestByClassroom : questionCounts, tags, masteryLoaded, bootcamp);

  useEffect(() => {
    if (loadingPerformance || tags) return;

    // once tags are fetched, we can set loading false
    fetchTags(performance?.byClassroom, setTags);
  }, [loadingPerformance]);

  useEffect(() => {
    if (userId === DEFAULT_USER_ID) return;
    setUserId(DEFAULT_USER_ID)
  }, [DEFAULT_USER_ID]);

  useEffect(() => {
    if (!!testProgress || !userId) return;

    async function init() {
      const trackingTestProgressId = `${userId}#${bootcamp}_Tracking`
      try {
        const result = await getTestProgressSimple(trackingTestProgressId);
        if (!result.data.getTestProgress.id) throw new Error('no data');
      } catch (error) {
        // THIS SHOULD ONLY RUN ONCE PER USER
        // create blockProgress for each testId in testsByClassroom object values
        let blockProgressIds = Object.values(testsByClassroom).reduce((acc, curr) => ([...acc, ...curr]), []).map(testId => `tracking#${userId}#${testId}`);
        if (bootcamp === 'nclex') {
          // need to add more testIds for nclex
          // because we condensed a bunch of tests
          blockProgressIds = [...blockProgressIds, ...[
            "48cb0b76-23dd-483d-ad57-fcaf2aaab9cc",
            "81b0fbfe-a247-4b21-b86e-9a6c320c8647",
            "8ad8a606-ab59-4264-b0fd-fbe621476b8f",
            "f8e918fb-fd39-4bc8-a9ac-ddba9e30c0e9",
            "83586273-c651-40d9-8c05-1ee94c08fba4",
            "a155a0b0-4d57-4ede-8950-2f9517146db9",
            "383b2a45-2311-4a3e-8057-f8d2a0cb5f38",
            "ba7355b6-8be5-4712-802a-f54327688dbf",
            "e7480c0c-183a-4684-a4d5-c9a139fd3041",
            "0e87ce48-af62-468b-a656-e44f1848d41b",
            "5492fb01-749e-4c91-8cfe-a188b100d697",
            "b76abd5e-e4c9-42f6-b873-5045be1fde8c",
            "3d2af34b-3679-4985-a2f3-32ca336d94e0",
            "f3c0598b-b8d3-483d-bd19-840f0c230ca7",
            "ead2020c-0f6d-4eac-80b2-e99a8800b87c",
            "dae69d9e-1bd9-4862-b043-e3c805804737",
            "31ba7476-1f0b-4040-a40c-2e1bd09ebb41",
            "d54aa6d5-eb12-4fbc-bb96-54b4e90ac1e8",
            "7747f976-565c-4c02-b674-73ff6ba8bd9d",
            "83e31c24-3a1e-4d62-a6a7-04c80de65017",
            "dcdb8918-fda9-4a7e-8fec-68ca31ba5b9f",
            "bf5b9541-12f4-49d1-874e-a5a761c6ca3d",
            "3cebd8eb-8556-43ab-9e6c-be90dc5c2629",
            "7fbfe429-78c1-4950-9d4d-3e6b54a47479",
            "30b8a74f-62fa-46df-9daa-84f14e9a0710",
            "2efe2c30-c7b0-4f69-af1a-b451f6e99fe7",
            "7348e65a-01a2-4f50-9f3b-43c3b1bf43fb",
            "b1c3b1f5-5e58-498b-9293-ba0b9e7d4ed8",
            "c287115b-d7b7-4dd8-94bb-73a0adc7cba3",
            "34874ffc-ba94-4356-b34e-399d1847f6e3",
          ].map(testId => `tracking#${userId}#${testId}`)]
        }
        const promises = blockProgressIds.map(blockProgressId => createBlockProgress(blockProgressId, trackingTestProgressId, 'null'));
        await Promise.all(promises);
        // create testProgress for trackingTestProgressId
        // now that we have a testProgress, we can consolidate and update blockProgresses for each subject#topic
        await createTestProgressWithBlocksAndQuestions(trackingTestProgressId, `null`);
      }

      const testProgressId = `${userId}#${bootcamp}_Performance`;
      let testProgress;
      try {
        const fetchResult = await getTestProgressPerformance(testProgressId);
        testProgress = fetchResult.data.getTestProgress;
        if (!testProgress) throw new Error('no data');
      } catch (error) {
        const createTestProgressResult = await createTestProgressWithBlocks(testProgressId, testProgressId);
        testProgress = createTestProgressResult.data.createTestProgress;
        // console.log(error)
      } finally {
        // each blockProgress in testProgress.blockProgresses.items has an ID in the format subject#topic
        // create an object such that each subject has an array of topic objects
        // each topic object should contain the parsed json status of the blockProgress, containing the properties attempts and correct
        const subjectTopics = {};
        testProgress.blockProgresses.items.forEach(blockProgress => {
          const [userId, subject, topic] = blockProgress.id.split('#');
          if (!subjectTopics[subject]) {
            subjectTopics[subject] = {};
          }
          subjectTopics[subject][topic] = JSON.parse(blockProgress.status);
        });
        setTestProgress(subjectTopics)
      }
    }
    init()
  }, [userId]);

  return <PerformanceContext.Provider value={{isAdmin, setTestProgress, setTags, setUserId, loadingPerformance, bootcamp, displayClassrooms, performance}} children={children} />
}

export const usePerformance = () => useContext(PerformanceContext);

const Performance = ({history}) => {
  const {isAdmin, setTestProgress, setTags, setUserId, loadingPerformance, bootcamp, displayClassrooms, performance} = usePerformance();

  return (
    <Container>
      <StyledBreadcrumbs history={history} lockAtDepth={2} />
      <Header>
        <Title onContextMenu={() => {
          if (!isAdmin) return;
          setTestProgress(null);
          setTags(null)
          setUserId(window.prompt('Enter User ID to fetch'))
        }}>Performance</Title>
        {/* {bootcamp === 'inbde' && <StyledButton dark iconPosition="left" IconComponent={<PlayCircle color="white" size="20" />} onClick={() => modalDispatch({type: 'open', component: VideoPlayer, enableClickClose: true})}>Watch Tutorial</StyledButton>} */}
      </Header>
      <Overall performanceData={performance} loading={loadingPerformance} educator={educatorsByBootcamp[bootcamp]} />
      <Subject config={displayClassrooms} performanceData={performance} loading={loadingPerformance} educator={educatorsByBootcamp[bootcamp]} />
      <FAQHeader>FAQs</FAQHeader>
      {getFAQs(bootcamp)}
    </Container>
  );
};

const PerformanceWrapper = ({history}) => (
  <PerformanceProvider>
    <Performance history={history}/>
  </PerformanceProvider>
);

export default PerformanceWrapper;