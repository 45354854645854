import React from 'react';
import styled from 'styled-components';
import Chapter from '../Cards/Chapter';
import Callout from '../Cards/Callout';
import {BeginPracticeButton} from '../Cards/components/Buttons';

const Container = styled.div`
`;

const Wrapper = styled.a`
  cursor: pointer;
  display: flex;
  transition: all 100ms ease;
  text-decoration: none;
  &:hover {
      transform: scale(1.01);
    }
`;
const Card = styled(Chapter)`
  pointer-events: none;
`;

const Trainer = ({content, themePalette, calloutText}) => {
  return (
    <Container>
      {calloutText && <Callout children={calloutText}/>}
      <Wrapper href={`/dat/perceptual-ability/generators/trainer`}>
        <Card
          name={'Play the PAT Trainer Game'}
          id={`generator-trainer`}
          index={null}
          showProgress={false}
          themePalette={themePalette}
          actions={<BeginPracticeButton children="Start Game" themePalette={themePalette}/>}
        />
      </Wrapper>

    </Container>
  );
};

Trainer.defaultProps = {};
Trainer.propTypes = {};

export default Trainer;
