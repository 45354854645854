import React from 'react';

import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import alli from '../../../components/Modal/components/Upgrade/assets/alli.jpg';

import {
  OverviewText,
  InstructorDetails,
  InstructorDetailsImage,
  InstructorDetailsText,
  InstructorDetailsTextHeading,
  StyledBody2,
  GuidesContainer,
  GuideWell,
  GuideDownloadButton,
  StyledDownloadIcon,
  Testimonial,
  TestimonialRow,
  TestimonialImage,
  TestimonialAuthor,
  TestimonialName,
  TestimonialByline,
} from './shared';

const studyGuide = (
  <>
    <OverviewText>Ari’s Study Guide for DAT Domination</OverviewText>
    <InstructorDetails>
      <InstructorDetailsImage src={ari} />
      <InstructorDetailsText>
        <InstructorDetailsTextHeading>
          The Step-by-Step Guide on How to Ace the {(new Date()).getFullYear()} DAT
        </InstructorDetailsTextHeading>
        <StyledBody2>
          I'm Ari, creator of DAT Bootcamp, and in this guide I will outline a study plan that has worked for me and many other students.
        </StyledBody2>
      </InstructorDetailsText>
    </InstructorDetails>
    <GuidesContainer>
      <GuideWell>
        <StyledBody2>
          The <strong>Study Guide</strong> breaks down all of the DAT-specific tips and test-taking strategies to get you up to speed. I recommend <strong>reading this first</strong>.
        </StyledBody2>
        <GuideDownloadButton color="blue" onClick={() => window.open('https://drive.google.com/file/d/1fpylXSKDSssHIYClFJzfuczWWaXKr-Xq/view', '_blank')}>
          <StyledDownloadIcon /> Get Ari's Study Guide
        </GuideDownloadButton>
      </GuideWell>
      <GuideWell>
        <StyledBody2>
          The <strong>Study Schedule</strong> is an updated day-by-day manual that covers exactly what materials to study and when to study them to best prepare you for the DAT.
        </StyledBody2>
        <GuideDownloadButton color="blue" onClick={() => window.open('https://drive.google.com/file/d/1581bvpidp0U_JytpMU_7w9P9iKYbtA1t/view', '_blank')}>
          <StyledDownloadIcon /> Get Ari's Study Schedule
        </GuideDownloadButton>
      </GuideWell>
    </GuidesContainer>
    <Testimonial>
      <StyledBody2 style={{textAlign: 'center'}}>
        "I studied for 8 weeks following Ari’s 2-month study schedule, and <strong>I could not have been happier</strong> with the pacing; it allowed me enough time to take advantage of <strong>all of the excellent resources on DAT Bootcamp</strong>, without dragging out the timeline beyond what was necessary.”
      </StyledBody2>
      <TestimonialRow>
        <TestimonialImage src={alli}/>
        <TestimonialAuthor>
          <TestimonialName>Alli Jepsen - <strong>26 AA</strong></TestimonialName>
          <TestimonialByline>Happy DAT Bootcamp Student</TestimonialByline>
        </TestimonialAuthor>
      </TestimonialRow>
    </Testimonial>
    </>
  );

export default studyGuide;
