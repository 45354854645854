import React, {Fragment, useState, useEffect, useRef, useCallback} from 'react';

import {useTestNavigatorContext, TestNavigatorProvider} from '../../contexts/TestNavigator';
import {FullPageSpinner} from '../../components/Branding';
import {TimerProvider} from '../../contexts/TestTimer';
import {useModalContext} from '../../contexts/Modal';

import {SubmitAnatomyCase} from '../Modal';
import Sidebar from './components/Sidebar';
import Block from './components/Block';
import Controls from './components/Controls';
import TopBar from './components/TopBar';

import styled, {ThemeProvider} from 'styled-components';
import theme from '@bootcamp/shared/src/styles/theme';
import useDocumentTitle from '@rehooks/document-title';
import {useUserDataContext} from '../../contexts/UserData';

const Transition = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  opacity: ${({loading}) => loading ? 0 : 1};
  transition: opacity .5s;
  min-width: 0;
`;
const ContentArea = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  max-width: 100%;
  min-width: 0;
`;
const Loader = styled(FullPageSpinner)`
  background: ${({themePalette}) => themePalette.dark};
`;

const LoadingWrapper = ({loading, children, themePalette}) => {
  const [blockIndexChecked, setBlockIndexChecked] = useState(false);
  const {
    methods: {
      setCurrentIndex,
    },
    variables: {
      loading: testLoading,
      quizProgress,
      testProgressId
    }
  } = useTestNavigatorContext();

  const isLoading = loading || testLoading || !blockIndexChecked || !testProgressId;
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!isLoading) {
      const blockIndex = parseInt(urlParams.get('index'));
      const blockId = urlParams.get('blockId');
      const matchedBlockIdIndex = quizProgress?.findIndex(({blockProgressId, blockId: testBlockId}) => blockProgressId.includes(blockId) || blockId === testBlockId);
      const indexToLoad = matchedBlockIdIndex !== -1 ? matchedBlockIdIndex : blockIndex;

      if (indexToLoad) {
        setCurrentIndex(indexToLoad, 0);
        document.getElementById(`sidebar-item-${indexToLoad - 1}`) && document.getElementById(`sidebar-item-${indexToLoad - 1}`).scrollIntoView();
      }
    }
    setBlockIndexChecked(true);
  }, [isLoading]);

  return (
    <Fragment>
      <Loader active={isLoading} themePalette={themePalette}/>
      <Transition loading={isLoading}>
        {!isLoading && children}
      </Transition>
    </Fragment>
  );
}

function getNextBlock(blocks, blockIndex, filter) {
  if (filter.key === 'blockFilter')  {
    const nextIndex = blocks.findIndex(({testBlock}, index) => (((filter.value === 'videos' && testBlock.type === 'lesson') || (filter.value === 'quizzes' && testBlock.type === 'questionSet')) && index > blockIndex));
    return blocks?.[nextIndex]?.testBlock || {type: 'endBlock'};
  }

  return blocks?.[blockIndex + 1]?.testBlock;
}

const CoursePlayer = ({match, loading, classroomName, chapterName, themePalette=theme.colors.brandPalette.violet}) => {
  const [showingSidebar, setShowingSidebar] = useState(window.innerWidth > 768);
  const {darkModeEnabled, getDarkModeTheme} = useUserDataContext();
  const {modalDispatch} = useModalContext();
  const sidebarProps = {showingSidebar, setShowingSidebar};
  const blockRef = useRef();

  const localTheme = darkModeEnabled ? getDarkModeTheme() : theme;

  const [showingExhibit, setShowingExhibit] = useState(false);

  const {
    methods: {
      transitionBlock,
    },
    variables: {
      blockIndex,
      quizProgress,
      testBlockConnections,
      navigationFilter,
    }
  } = useTestNavigatorContext();

  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses?.findIndex(({current}) => current);
  const nextBlock = getNextBlock(testBlockConnections, blockIndex, navigationFilter) || {};
  const {type: nextBlockType} = nextBlock;

  const periodicTableSubjects = ['general-chemistry'];
  const calculatorSubjects = ['quantitative-reasoning'];
  const subject = match?.params?.classroom;

  const exhibit = periodicTableSubjects?.includes(subject)
    ? 'periodic'
    : calculatorSubjects?.includes(subject)
    ? 'calculator'
    : null

  const toggleExhibit = () => setShowingExhibit(showing => !showing);
  const exhibitProps = {exhibit, showingExhibit, toggleExhibit};

  useDocumentTitle(`Bootcamp.com | ${classroomName} | Course: ${chapterName}`);

  const handleNextLesson = useCallback(() => {
    if (nextBlockType === 'endBlock') {
      modalDispatch({
        type: 'custom',
        key: 'open',
        component: SubmitAnatomyCase,
        enableClickClose: true,
        componentProps: {
          headerText: 'Nice work! You\'ve reached the end of this course.',
          bodyText: 'Would you like to keep reviewing or complete your review?',
          cancelConfig: {
            text: 'Keep Reviewing',
            onCancel: () => {
              modalDispatch({type: 'close'});
            },
            shouldRedirect: false,
          },
          confirmConfig: {
            text: 'Complete Review',
            colorConfig: themePalette,
            onConfirm: () => {},
            shouldRedirect: true,
            redirect: location => location.pathname.split('/').slice(0, -1).join('/')
          }
        },
      })
    } else {
      transitionBlock('nextFilterActive');
    }
  }, [navigationFilter, nextBlockType, testBlockConnections, blockIndex])

  function handlePreviousLesson () {
    transitionBlock('previousFilterActive');
  }

  return (
    <ThemeProvider theme={localTheme}>
      <TimerProvider stopwatch questionIndex={questionIndex}>
        <LoadingWrapper loading={loading} themePalette={themePalette}>
          <Sidebar {...sidebarProps} classroomName={classroomName} chapterName={chapterName} themePalette={themePalette} blockRef={blockRef} />
          <ContentArea ref={blockRef}>
            <TopBar {...sidebarProps} match={match} themePalette={themePalette} blockRef={blockRef} />
            <Block themePalette={themePalette} chapterName={chapterName} blockRef={blockRef} {...exhibitProps} handleNextLesson={handleNextLesson} />
            <Controls {...sidebarProps} themePalette={themePalette} {...exhibitProps} handleNextLesson={handleNextLesson} handlePreviousLesson={handlePreviousLesson} />
          </ContentArea>
        </LoadingWrapper>
      </TimerProvider>
    </ThemeProvider>
  );
}


const Wrapper = ({testId=null, testProgressId=null, ...props}) => {
  return (
    <TestNavigatorProvider
      match={props.match}
      template={'coursePlayer'}
      testId={testId}
      progressId={testProgressId}
      enableReloadBlocker={false}
    >
      <CoursePlayer {...props}/>
    </TestNavigatorProvider>

  );
}

CoursePlayer.propTypes = {};
CoursePlayer.defaultProps = {};

export default Wrapper;
