import React, {useState, useEffect} from 'react';
import API from '@aws-amplify/api';
import styled from 'styled-components';

import {Body3, H4} from '../../../../../components/Typography'
import {Loader as LoadingIndicator} from '../../../../../components/Branding';
import {ChevronDown} from '@styled-icons/heroicons-outline/ChevronDown';
import {getFormattedStripeAmount} from '@bootcamp/shared/src/util';

import moment from 'moment';
import {invokeLambda} from '@bootcamp/shared/src/requests';

const Container = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  cursor: pointer;
`;
const History  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: max-height ${({viewing}) => viewing ? '450ms ease-in' : '150ms ease-out'};
  max-height: ${({viewing}) => viewing ? '1000px' : '0px'};
  overflow: hidden;
  cursor: text;
`;
const Toggle = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({disabled}) => disabled ? 0.4 : 1};
`;
const Arrow = styled(ChevronDown)`
  transform: ${({viewing}) => viewing ? 'rotateX(180deg)' : 'none'};
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;
const Text = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const Loader = styled(LoadingIndicator)`
  fill: ${({theme}) => theme.colors.brandPalette.royal.default};
`;
const Table = styled.table`
  flex: 1;
  align-self: stretch;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  border-radius: 8px;
  overflow: hidden;
  border-spacing: 0px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};

  tr {
    height: 56px;

    &:nth-child(odd) {
      background-color: ${({theme}) => theme.colors.neutralsPalette.extraLight};
    }
  }
  td {
    font-weight: normal;
    text-align: center;
    width: 25%;
  }
`;
const SubscriptionTable = ({payments}) => (
  <Table>
    <tr>
      <H4 as={'th'}>Type</H4>
      <H4 as={'th'}>Amount Paid</H4>
      <H4 as={'th'}>Payment Method</H4>
      <H4 as={'th'}>Charged On</H4>
    </tr>
    {payments?.map(({type, amountPaid, date, last4}) => (
      <tr>
        <H4 as={'td'}>{type}</H4>
        <H4 as={'td'}>{amountPaid}</H4>
        <H4 as={'td'}>{`**** **** **** ${last4}`}</H4>
        <H4 as={'td'}>{date}</H4>
      </tr>
    ))}
  </Table>
);
const DefaultTable = ({payments}) => (
  <Table>
    <tr>
      <th>Type</th>
      <th>Amount Paid</th>
      <th>Payment Method</th>
      <th>Charge Date</th>
    </tr>
    {payments?.map(({type, amountPaid, date, last4}) => (
      <tr>
        <td>{type}</td>
        <td>{amountPaid}</td>
        <td>{`**** **** **** ${last4}`}</td>
        <td>{date}</td>
      </tr>
    ))}
  </Table>
);

async function fetchInvoicesForSubscription(customerId, subscriptionId) {
  try {
    const result = await invokeLambda('tbc-Stripe', {path: '/stripe/invoices/list', customerId, subscriptionId, expand: ['data.charge']});
    return result.body.invoices;
  } catch (error) {
    console.log('error fetching billing details', error);
    return {client_secret: null};
  }
};
async function fetchPaymentIntent(membership) {
  try {
    const result = await invokeLambda('tbc-Stripe', {path: '/stripe/get-payment-intent', intentId: membership.id});
    if (!result.body.paymentIntent) throw new Error('No payment intent found');
    return result.body.paymentIntent;
  } catch (error) {
    console.log('error fetching payment intent', error);
    return {paymentIntent: false};
  }
}
async function loadOneTimePaymentHistory(membership) {
  const memberships = [membership, ...membership.extensions];
  const paymentIntents = await Promise.all(memberships.map(fetchPaymentIntent));
  return paymentIntents;

}
async function loadSubscriptionPaymentHistory(membershipId, subscriptionDetails) {
  const invoices = await fetchInvoicesForSubscription(subscriptionDetails.customerId, membershipId);
  return invoices;
}
function formatSubscriptionPaymentHistory(invoices) {
  return invoices?.data?.map(({amount_paid, created, charge}) => ({
    type: 'Subscription Invoice',
    amountPaid: `$${getFormattedStripeAmount(amount_paid)}`,
    date: moment.unix(created).format('MMM Do, YYYY'),
    last4: charge?.payment_method_details?.card?.last4,
  }));
}
function formatOneTimePaymentHistory(paymentIntents) {
  return paymentIntents
    .filter(({paymentIntent}) => !!paymentIntent)
    .map(({paymentIntent: {metadata, amount, created, charges}}) => ({
      type: metadata?.title,
      amountPaid: `$${getFormattedStripeAmount(amount)}`,
      date: moment.unix(created).format('MMM Do, YYYY'),
      last4: charges?.data[0]?.payment_method_details?.card?.last4,
    }));
}

const PaymentHistory = ({subscriptionDetails, membership, parentLoading}) => {
  const [loading, setLoading] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [payments, setPayments] = useState(null);

  const onToggle = () => !parentLoading && setViewing(state => !state);

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const request = membership.type === 'subscription'
        ? () => loadSubscriptionPaymentHistory(membership.id, subscriptionDetails)
        : () => loadOneTimePaymentHistory(membership);

      const format = membership.type === 'subscription'
        ? formatSubscriptionPaymentHistory
        : formatOneTimePaymentHistory;

      const payments = await request();
      const formatted = format(payments) || [];

      setPayments(formatted);
      setLoading(false);
    };

    if (viewing && !payments && !loading) load();

  }, [viewing, payments, loading]);

  const TableComponent = membership.type === 'subscription' ? SubscriptionTable : DefaultTable;

  return (
    <Container onClick={onToggle}>
      <History viewing={viewing} onClick={e => e.stopPropagation()}>
      {viewing && loading
        ? <Loader size={18} active={true}/>
        : <TableComponent payments={payments}/>
      }
      </History>
      <Toggle disabled={parentLoading}>
        <Arrow size={18} viewing={viewing}/>
        <Text>
          {viewing ? 'Hide Payment History' : 'Show Payment History'}
        </Text>
      </Toggle>
    </Container>
  );
};

PaymentHistory.defaultProps = {};
PaymentHistory.propTypes = {};

export default PaymentHistory;