import React, {useEffect, useState} from 'react';
import styled, {css, ThemeProvider} from 'styled-components';

import {useUserDataContext} from '../../contexts/UserData';
import {useTestNavigatorContext} from '../../contexts/TestNavigator';
import {TimerProvider} from '../../contexts/TestTimer';
import {useReloadBlockerContext} from '../../contexts/ReloadBlocker';

import Exhibit from '../../components/Exhibit';
import {FullPageSpinner, Button} from '../Branding';
import {Body4} from '../Typography';
import {Column, FlexBox} from '../Grid';
import QuestionSet from '../TestBlocks/QuestionSet';
import {RetakeTestButton, StartButton} from './Buttons';

import CaseImage from '../../bootcamps/anatomy/Case/components/CaseImage';
import CaseQuestion from '../../bootcamps/anatomy/Case/components/Question'
import ImagePrefetcher from '../../bootcamps/anatomy/Case/components/ImagePrefetcher';

import StartBlock from './StartBlock';
import StartMasteryReview from '../../bootcamps/components/Blocks/StartMasteryReview';
import StartBookmarkedQuestionReview from '../../bootcamps/components/Blocks/StartBookmarkedQuestionReview';
import StartReview from '../TestBlocks/StartReview';
import DailyReviewIntroBlock from './DailyReviewIntroBlock';
import Question from './Question';
import ProgressBar from '../ProgressBar';
import TopBar from './TopBar';
import Sidebar from './Sidebar';

import QuestionStats from './QuestionStats';

import debounce from 'lodash.debounce';
import moment from 'moment';

import {deSlugify, getStreakDetails, changeIntercomDisplay, findByRenderType, getTestTitle} from '@bootcamp/shared/src/util';
import {useModalContext} from '../../contexts/Modal';
import {SubmitAnatomyCase, SubmitModal, FullScreenAnimation} from '../../components/Modal';
import FinishedDWUAnimation from '@bootcamp/shared/src/assets/animations/FinishedDWUAnimation.json';
import {useBootcampConfigContext} from '../../contexts/BootcampConfig';
import {Tooltip} from '@bootcamp/web/src/components/Tooltip';

import baseTheme from '@bootcamp/shared/src/styles/theme';


const Container = styled(Column)`
  justify-content: center;
  position: relative;
  background: ${({themePalette}) => themePalette.dark};

  .highlight {
    ${({theme}) => theme.darkModeEnabled && css`
      color: ${baseTheme.colors.neutralsPalette.extraDark} !important;
    `}
  }
`;
const ContentArea = styled(Column)`
  opacity: ${({loading, template, reviewType}) => (loading && (template === 'testReview' || (template === 'quickReview' && reviewType === 'quick-study'))) ? 0 : 1};
  transition: opacity .5s;
`;
const ControlWrapper = styled.div`
  padding: ${({theme, isShowingImage}) => isShowingImage ? `0 0 0` : `${theme.layouts.spacing.xl} ${theme.layouts.spacing.xl} 0px`};
  position: relative;
  width: 100%;
  height: ${({isShowingImage}) => isShowingImage ? 'calc(100% - 81px)' : 'auto'};
  background: ${({theme}) => theme.colors.neutralsPalette.white};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding-top: ${({theme, isShowingImage}) => isShowingImage ? '0' : theme.layouts.spacing.m};
    padding-bottom: 160px;
  }
`;
const QuestionSetContainer = styled(FlexBox)`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  flex-direction: row;
`;
const QuestionSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  z-index: 3;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
`;
const CaseImageWrapper = styled.div`
`;
const QuestionSetInner = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  ${CaseImageWrapper} {
    display: ${({isShowingImage}) => isShowingImage ? 'flex' : 'none'};
    min-width: ${({isShowingImage}) => isShowingImage ? '50%' : '0'};
    max-width: ${({isShowingImage}) => isShowingImage ? '50%' : '0'};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: ${({isShowingImage}) => isShowingImage ? 'column' : 'row'};
  }
`;
const Loader = styled(FullPageSpinner)`
  background: ${({color}) => color};
`;

const ProgressLabel = styled(Body4)`
  color: ${baseTheme.colors.neutralsPalette.white};
`;

const ControlsWrapper = styled.div`
  display: flex;
  height: 88px;
  justify-content: space-between;
  align-items: center;
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.xl};
  position: fixed;
  bottom: 0;
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  left: ${({sidebarState}) => !sidebarState ? 0 : `calc(256px)`};
  right: 0;
  transition: left .3s;
  z-index: 10;


  ${({theme}) => theme.mediaQueries.laptop} {
    left: 0;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 8px 16px 16px 16px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  &:last-child {
    margin-right: 0px;
  }

  /* this is a fragile target for mastery button specific styling */
  > div {
    button {
      margin-top: 0;
      margin-bottom: 0;
      width: calc(100% - 8px);
    }
  }
`;
const StatWrapper = styled.div`
  display: none;
  margin: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.m}`};

  ${({theme}) => theme.mediaQueries.mobileL} {
    display: flex;
  }
`;

const CaseQuestionSetWrapper = styled.div`
  ${({theme}) => theme.mediaQueries.custom(2350)} {
    padding: ${({theme}) => theme.layouts.spacing.xl};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding-top: 0;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 0px 16px 32px 16px;
  }
`;

const Controls = ({sidebarState}) => {
  const {
    variables: {
      leftButtons,
      rightButtons,
      quizProgress,
      blockIndex
    }
  } = useTestNavigatorContext();
  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);
  return (
    <ControlsWrapper sidebarState={sidebarState}>
      <ButtonWrapper>
        {questionProgresses?.[questionIndex]?.didCheck &&
          <Tooltip dismissalInteractionKey="dismissedMasteryTagTutorial"/>
        }
        {leftButtons
          .filter(button => !!button)
          .map(button => (
            button.component
              ? button.component
              : button.children
                ? <Button {...button}/>
                : null
        ))}
      </ButtonWrapper>
      <ButtonWrapper>
        {rightButtons
          .filter(button => !!button)
          .map(button => (
            button.component
              ? button.component
              : button.children
                ? <Button {...button}/>
                : null
        ))}
      </ButtonWrapper>
    </ControlsWrapper>
  );
}

const QBank = ({
  match,
  themePalette,
  username,
  title,
  startBlockTitle,
  educators,
  showScore,
  totalQuestions,
  handleEndBlockUpdate,
  enableBookmarking,
  alignQuestion,
  testRoute,
  name,
}) => {

  const [showingExhibit, setShowingExhibit] = useState(false);
  const [exhibitType, setExhibitType] = useState();
  const sidebarState = useState(window.innerWidth > 768);

  // state variables for anatomy cases
  const [caseImageProps, setCaseImageProps] = useState(null);
  const [caseImageSolutionProps, setCaseImageSolutionProps] = useState(null);

  const {
    methods: {
      setLeftButtons,
      transitionBlock,
    },
    variables: {
      test,
      blockIndex,
      testBlockConnections,
      startBlockIndex,
      endBlockIndex,
      loading,
      template,
      quizProgress,
      filterActive,
      skipStartBlock,
      customTestConfig,
      type
    }
  } = useTestNavigatorContext();

  const {modalDispatch} = useModalContext();
  const {cognitoUser, interactions, saveUserInteraction, bootcamp, testProgress, darkModeEnabled, getDarkModeTheme} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  const {toggleReloadBlocker} = useReloadBlockerContext();
  const localTheme = darkModeEnabled ? getDarkModeTheme() : baseTheme;

  const questions = quizProgress.map(block => block.questions || []).flat();
  const questionCount = questions.length;
  const questionCountThusFar = questions.findIndex(({current}) => current) + 1;
  const contentType = match?.params?.contentType;
  const reviewType = match?.params?.reviewType;

  const testTitle = getTestTitle(name, contentType, test?.config);

  const score = quizProgress
    .map(({questions}) => questions || [])
    .flat()
    .reduce(({correct, incorrect, total}, {didSelectCorrectAnswer, didCheck}) => ({
      correct: correct + (template === 'testReview'? didSelectCorrectAnswer : didCheck && didSelectCorrectAnswer),
      incorrect: incorrect + (template === 'testReview'? !didSelectCorrectAnswer : didCheck && !didSelectCorrectAnswer),
      total: total + 1
    }), {correct: 0, incorrect: 0, total: 0});

  const mastery = questions.reduce((acc, {masteryLevel}) => ({
      ...acc,
      ...((masteryLevel && masteryLevel !== 'none') ? {[masteryLevel]: acc[masteryLevel] + 1, total: acc.total + 1} : {}),
    }), {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    total: 0
  });


  const blockRenderer = (testBlockConnection) => {
    const defaultBlocksForTemplate = {
      masteryReview: {testBlock: {type: 'startMasteryReview'}},
      tbcQuestionBank: {testBlock: {type: 'startBlock'}},
      tbcSavedBank: {testBlock: {type: 'startBlock'}},
      bookmarkedQuestionReview: {testBlock: {type: 'startBookmarkedQuestionReview'}},
      quickReview: {testBlock: {type: 'startQuickReview'}},
    }[template] || {testBlock: {}};

    const {testBlock} = testBlockConnection || defaultBlocksForTemplate;

    switch (testBlock.type) {
      case 'startReview':
        const isFullLength = !!test?.config?.title.match('Full Length');
        const {createdAt} = testProgress || {createdAt: null};
        const result = quizProgress.filter(blockProgress => blockProgress.questions && blockProgress.questions.length);

        const masteryBar = (
          <ProgressBar
            showLabel={false}
            total={questionCount}
            withMargin={false}
            segments={[
              {count: mastery.mastered},
              {count: mastery.reviewing},
              {count: mastery.learning},
            ]}
          />
        )

        return (
          <QuestionSetContainer>
            <Sidebar title={testTitle} sidebarState={sidebarState} themePalette={themePalette} progressBar={masteryBar} />
            <QuestionSetWrapper>
              <TopBar
                themePalette={themePalette}
                sidebarState={sidebarState}
                questionStats={score}
                onExit={null}
                children={masteryBar}
                showScore={showScore}
              />
              <ControlWrapper>
                <StatWrapper>
                  <QuestionStats lightMode {...score}/>
                </StatWrapper>
                <div style={{paddingBottom: '100px'}}>
                  <StartReview
                    result={result}
                    dateTaken={createdAt}
                    testTitle={test?.config?.title}
                    isFullLength={isFullLength}
                    testType={test?.config?.type}
                    isOAT={bootcamp === 'oat'}
                  />
                </div>
                <Controls sidebarState={sidebarState[0]}/>
              </ControlWrapper>
            </QuestionSetWrapper>
          </QuestionSetContainer>
        );
      case 'startQuickReview':
        if (skipStartBlock) return null;
        return (
          <DailyReviewIntroBlock
            header={testTitle}
            subheader={startBlockTitle}
            loading={loading}
            testId={test?.id}
            themePalette={themePalette}
            totalQuestions={questionCount}
            buttons={(color) => ([
              <StartButton children={'Begin Review'} onClick={() => transitionBlock('next')} colorConfig={themePalette}/>
            ])}
            username={username}
          />
        );
      case 'startMasteryReview':
        if (skipStartBlock) return null;
        return (
          <StartMasteryReview
            loading={loading}
            masteryLevel={match.params.masteryLevel}
            classroom={match.params.classroom}
            themePalette={themePalette}
            totalQuestions={totalQuestions}
            username={username}
            buttons={(color) => ([
              <StartButton onClick={() => transitionBlock('next')} children={'Study Now'} colorConfig={themePalette} loading={loading} disabled={loading || !totalQuestions}/>
            ])}
            educators={educators}
            showProgress={false}
          />
        );
      case 'startBookmarkedQuestionReview':
        if (skipStartBlock) return null;
        return (
          <StartBookmarkedQuestionReview
            loading={loading}
            masteryLevel={match.params.masteryLevel}
            classroom={match.params.classroom}
            themePalette={themePalette}
            totalQuestions={totalQuestions}
            username={username}
            buttons={(color) => ([
              <StartButton onClick={() => transitionBlock('next')} children={'Study Now'} colorConfig={themePalette} loading={loading} disabled={loading || !totalQuestions}/>
            ])}
            educators={educators}
            showProgress={false}
          />
        );
      case 'startBlock':
        if (skipStartBlock) return null;
        const descriptionPreText = window.location.pathname.includes('histology-practical-1') || window.location.pathname.includes('histology-practical-2')
          ? 'This practical exam includes topics from chapters 1-11. '
          : window.location.pathname.includes('histology-practical-3') || window.location.pathname.includes('histology-practical-4')
            ? 'This practical exam includes topics from chapters 12-21. '
            : ''
        return (
          <StartBlock
            header={testTitle}
            subheader={startBlockTitle || 'Question Bank'}
            loading={loading}
            testId={test?.id}
            themePalette={themePalette}
            questionCount={questions?.length}
            descriptionHeader={`Ready to start, ${username || '____'}?`}
            descriptionText={`${descriptionPreText}Once you've completed this untimed question bank, make sure to review your answers and tag each question so that we can better help you learn the material.`}
            buttons={(color, progress) => {
              const buttonText = progress?.find(({count}) => count > 0) ? 'Jump Back In' : 'Start Studying';
              return [
                <StartButton onClick={() => transitionBlock('nextUntagged')} colorConfig={themePalette} loading={loading || !progress} disabled={loading} children={buttonText}/>,
              ]
            }}
            educators={educators}
          />
        );
      case 'passage':
      case 'questionSet':
      case 'caseQuestionSet':
        const showFinishedModal = () => {
          modalDispatch({
            type: 'open',
            component: FullScreenAnimation,
            componentProps: {
              lottieConfig: {
                style: {
                  width: '100%',
                  height: '100%'
                },
                config: {
                  animationData: FinishedDWUAnimation
                },
                lottieEventListeners: [
                  {
                    name: 'complete',
                    callback: () => window.location.pathname = `/${bootcamp}`
                  }
                ]
              }
            },
            modalContainerStyle: theme => `
              background: ${theme.overlays.opacity.dark._400};
              padding: 10vh 10vw;
              align-items: center;
              ${theme.mediaQueries.laptop} {
                background: ${theme.overlays.opacity.dark._400};
                padding: 0;
              }
            `
          })
        }

        const interactionKey = `Streak-DWU-${bootcamp}`;
        const previousInteraction = interactions.find(({id}) => id === `${cognitoUser.getUsername()}-${interactionKey}`);
        const questionSetExplanation = testBlock.components && findByRenderType(testBlock.components, 'explanation');

        const {streak, longestStreak, date: streakDate} = JSON.parse((previousInteraction || {}).value || '{}');

        const {today, streakCompletedToday, streakActive} = getStreakDetails(streak, streakDate);

        const onExit = template === 'quickReview' && !streakCompletedToday
          ? () => modalDispatch({
            type: 'open',
            component: SubmitModal,
            componentProps: {
              headerText: `Before you go — want to mark your Daily Warmup as complete?`,
              bodyText: `You’re one click away from increasing your Daily Streak. Come back tomorrow to keep it going!`,
              cancelConfig: {
                text: 'Exit to Home',
                onCancel: history => history.push(`/${bootcamp}`)
              },
              confirmConfig: {
                text: 'Mark Complete',
                colorConfig: themePalette,
                onConfirm: history => {
                  const streakData = {
                    streak: streak > 0 && streakActive ? streak + 1 : 1,
                    date: today.format('M/D/YYYY'),
                    longestStreak: streak + 1 > longestStreak && streakActive ? streak + 1 : (longestStreak || streak + 1)
                  }
                  saveUserInteraction(interactionKey, JSON.stringify(streakData))
                  showFinishedModal()
                }
              }
            },
          })
          : null

        const progressCount = questions.filter(question => question.selectedAnswerIndex > -1 && question.didCheck).length;

        const progressBar = ['quickReview', 'masteryReview'].includes(template) ? (
          <ProgressBar
            showLabel={false}
            withMargin={false}
            label={<ProgressLabel><strong>{progressCount || 0}</strong> of {totalQuestions || questionCount} questions answered</ProgressLabel>}
            total={totalQuestions || questionCount}
            segments={[{
              count: progressCount,
              color: themePalette.default,
            }]}
          />
        ) : (
          <ProgressBar
            showLabel={false}
            withMargin={false}
            label={<ProgressLabel><strong>{mastery.total || 0}</strong> of {questionCount} questions tagged</ProgressLabel>}
            total={questionCount}
            segments={[
              {count: mastery.mastered},
              {count: mastery.reviewing},
              {count: mastery.learning},
            ]}
          />
        )


        return (
          <QuestionSetContainer>
            <ImagePrefetcher />
            <Sidebar title={testTitle} sidebarState={sidebarState} themePalette={themePalette} progressBar={progressBar} classroom={match?.params?.classroom} />
            <QuestionSetWrapper>
              {(showingExhibit && exhibitType) && <Exhibit type={exhibitType} onCloseExhibit={() => setShowingExhibit(false)} defaultPosition={{x: 0, y: 0}} />}
              <TopBar
                themePalette={themePalette}
                sidebarState={sidebarState}
                title={template === 'masteryReview'
                  ? <span style={{fontWeight: 'normal'}}>Studying your <strong>{deSlugify(match.params.masteryLevel)}</strong> Bank</span>
                  : testTitle
                }
                questionStats={score}
                onExit={onExit}
                questionCount={questionCount}
                questionCountThusFar={questionCountThusFar}
              />
              <ControlWrapper isShowingImage={!!caseImageProps} includesQuestionSet={!caseImageProps}>
                {/* <StatWrapper>
                  <QuestionStats lightMode {...score}/>
                </StatWrapper> */}
                <QuestionSetInner isShowingImage={!!caseImageProps}>
                  {caseImageProps &&
                    <CaseImage caseImageProps={caseImageProps} caseImageSolutionProps={caseImageSolutionProps} mobileDisplay/>
                  }
                  <QuestionSet
                    // key={testBlock.id}
                    themePalette={themePalette}
                    enableBookmarking={enableBookmarking}
                    setShowingExhibit={setShowingExhibit}
                    setExhibitType={setExhibitType}
                    maxWidth={1000}
                    match={match}
                    alignQuestion={alignQuestion}
                    scrollable={!!caseImageProps}
                    scrollToTop={() => document.querySelector(`.${QuestionSetWrapper.styledComponentId}`).scroll(0,0)}
                    renderQuestion={({testBlock, questionIndex, blockParts, ...props}) => {
                      const {masteryLevel} = testBlock.questionConnections.items[questionIndex] || {};
                      const isCaseQuestion = !!props.anatomyImage;
                      const anatomyImageSolution = (((testBlock?.components || []).find(component => component.renderType === 'anatomyImageSolution')) || {}).contents;
                      const {explanation} = blockParts || {};

                      return isCaseQuestion
                        ? <CaseQuestionSetWrapper>
                            <Question
                              key={`${testBlock.id}-${props.questionId}`}
                              questionIndex={questionCountThusFar}
                              themePalette={themePalette}
                              setCaseImageProps={setCaseImageProps}
                              setCaseImageSolutionProps={setCaseImageSolutionProps}
                              showQuestionNumber={false}
                              questionSetExplanation={explanation || questionSetExplanation}
                              blockParts={blockParts || {anatomyImageSolution}}
                              masteryLevel={masteryLevel}
                              bootcamp={bootcamp}
                              showingTags={['quickReview', 'masteryReview'].includes(template)}
                              {...props}
                            />
                        </CaseQuestionSetWrapper>
                        : <Question
                            key={`${testBlock.id}-${questionIndex}`}
                            questionIndex={questionCountThusFar}
                            themePalette={themePalette}
                            showingTags={['quickReview', 'masteryReview'].includes(template)}
                            masteryLevel={masteryLevel}
                            bootcamp={bootcamp}
                            {...props}
                          />
                    }}
                  />
                  <CaseImageWrapper>
                    {caseImageProps &&
                      <CaseImage caseImageProps={caseImageProps} caseImageSolutionProps={caseImageSolutionProps} overrideMobileStyles/>
                    }
                  </CaseImageWrapper>
                </QuestionSetInner>
                <Controls sidebarState={sidebarState[0]}/>
              </ControlWrapper>
            </QuestionSetWrapper>
          </QuestionSetContainer>
        );
      default:
        return null;
    }
  }

  const examComponent = blockRenderer(testBlockConnections[blockIndex]);

  useEffect(() => {
    switch (blockIndex) {
      case startBlockIndex:
        setLeftButtons([
          {component: <StartButton colorConfig={themePalette} children={'Begin Review'} onClick={() => transitionBlock('next')} />},
          template === 'testReview' && testRoute
            ? {
                component: (
                  <RetakeTestButton
                    colorConfig={themePalette}
                    children={'Retake Test'}
                    onClick={() => modalDispatch({
                      type: 'open',
                      enableClickClose: true,
                      component: SubmitAnatomyCase,
                      componentProps: {
                        headerText: 'Retake this test?',
                        bodyText: <span>If you continue, your <strong>current saved test result</strong> will be <strong>replaced by your</strong> next completed attempt. You can review all past attempts from the Past Test Results page.</span>,
                        cancelConfig: {
                          text: 'No, Go Back',
                          onCancel: () => modalDispatch({type: 'close'}),
                          shouldRedirect: false,

                        },
                        confirmConfig: {
                          text: 'Retake This Test',
                          colorConfig: themePalette,
                          onConfirm: () => {
                            toggleReloadBlocker(false);
                            window.location.pathname = testRoute
                          },
                          shouldRedirect: false,
                        }
                      },
                    })}
                  />
                )
              }
            : null
        ]);
        return;
      case endBlockIndex:
        if (handleEndBlockUpdate) {
          modalDispatch({
            type: 'open',
            enableClickClose: true,
            component: SubmitAnatomyCase,
            componentProps: {
              headerText: 'Nice work! You\'ve reached the end of your review.',
              bodyText: 'Would you like to keep reviewing or complete your review?',
              cancelConfig: {
                text: 'Keep Reviewing',
                onCancel: () => {
                  modalDispatch({type: 'close'});
                  // filterActive ? transitionBlock('firstFilterActive') : transitionBlock('firstQuestionSet');
                },
                shouldRedirect: false,

              },
              confirmConfig: {
                text: 'Complete Review',
                colorConfig: themePalette,
                onConfirm: () => toggleReloadBlocker(false),
                shouldRedirect: true,
                redirect: location => location.pathname.split('/').slice(0, -1).join('/')
              }
            },
          });

        }
        return;
      default:
        break;
    }
  }, [blockIndex, loading]);

  const updateSidebarState = debounce(event => {
    const setSidebarState = sidebarState[1];
    setSidebarState(!(window.innerWidth < 768));
  }, 150);

  useEffect(() => {
    window.addEventListener('resize', updateSidebarState);
    return () => window.removeEventListener('resize', updateSidebarState);
  }, []);


  useEffect(() => {
    changeIntercomDisplay('none');
    return () => changeIntercomDisplay('block');
  }, []);

  const timeLimit = !customTestConfig?.config?.timed
    ? null
    : customTestConfig?.suspended
    ? moment.duration(customTestConfig?.timeLeft).asMinutes()
    : type === 'readinessExam'
    ? 200
    : (parseInt(customTestConfig?.config?.testLength) * 1.5 || null);

  return (
    <ThemeProvider theme={localTheme}>
      <TimerProvider
        stopwatch={!timeLimit}
        timeLimit={timeLimit}
        autoStart={!!customTestConfig}
        disabled={customTestConfig?.submitted}
      >
        <Container themePalette={baseTheme.colors.brandPalette.royal}>
          <Loader color={baseTheme.colors.brandPalette.royal} active={loading && (template === 'testReview' || skipStartBlock)} />
          <ContentArea children={examComponent} loading={loading} template={template} reviewType={reviewType} />
        </Container>
      </TimerProvider>
    </ThemeProvider>
  );
}

QBank.defaultProps = {
  showScore: true,
  enableBookmarking: false
};

export default QBank;
