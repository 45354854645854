import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const htmlString = `
<div id="content" class="exam">

		<div class="posts">


			<article>

				<div class="box-wrap">

						<div id="introduction">

							<div class="bartop">

							<div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Top Front End Generator</h2></div></div></div>

							<div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

							<div class="instructions-container">

								<p>This is the top front end generator. Please read the following before starting:</p>

								<p>1. <strong>Please note:</strong> the difficulty of the real PAT TFE section is more similar to the TFE question banks and practice tests. Use this TFE generator to <strong>get daily TFE reps</strong>, and then head to the question banks and practice tests for test-representative practice.</p>
								<p>2. It is strongly recommended you <strong>use Google Chrome on Windows or Safari on Mac</strong> for the best performance.</p>
								<p>3. After selecting an answer choice, you can rotate the 3D image that appears using your mouse. Click and drag to rotate the shape. Look at the shape from the top, front, and end views to understand why the lines appear either solid or dotted.</p>
								<p>4. I highly recommend the <a href="/dat/perceptual-ability/videos/tfe" target="_blank">PAT Academy TFE Tutorial too</a>. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">TFE instructions</a>. </p>

								<p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

								<p style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;" onclick="document.getElementById('generator').style.display='block'; document.getElementById('introduction').style.display='none';">Start Generator</a></p>


								<div id="instructions" style="display: none;">

								<p style="margin-top: 50px;"><strong>Official TFE Instructions</strong></p>

								<p>The pictures that follow are top, front, and end views of various solid objects. The views are without perspective. That is, the points in the viewed surface are viewed along parallel lines of vision. The projection looking DOWN on it is shown in the upper left-hand corner (TOP VIEW).  The projection looking at the object from the FRONT is shown in the lower left-hand corner (FRONT VIEW). The projection looking at the object from the END is shown in the lower right-hand corner (END VIEW). These views are ALWAYS in the same positions and are labeled accordingly.</p>

								<img src="https://s3.amazonaws.com/datbootcamp/tfeexample1.png" style="max-width: 560px; margin: 35px 0px;">

								<p>If there were a hole in the block, the views would look like this:</p>

								<img src="https://s3.amazonaws.com/datbootcamp/tfeexample2.png" style="max-width: 560px; margin: 35px 0px;">

								<p>Note that the lines cannot be seen on the surface in some particular view are DOTTED in that view. In the problems that follow, two views will be shown, with four alternatives to complete the set. You are to select the correct one with your mouse.</p></div>

								<div style="display: block; height: 100px;"></div>

							</div>

						</div>

							<div id="generator" style="display: none;">


								<div>

								        <iframe src=${process.env.PUBLIC_URL}/lib/tfe/index.html width="100%" height="700px" style="position: relative; z-index: 3;border: none;"></iframe>

								</div>

							</div>

				</div>

			</article>



		</div>

	</div>
`


const Tfe = () => (
  <Container dangerouslySetInnerHTML={{__html: htmlString}} />
);

export default Tfe;
