import React, {useEffect, useRef} from 'react';

import {useUserDataContext} from '../../../../contexts/UserData';
import {useModalContext} from '../../../../contexts/Modal';
import {useTestNavigatorContext} from '../../../../contexts/TestNavigator';
import {findByRenderType, parseVideoDuration} from '@bootcamp/shared/src/util';
import {H3, H4, H5, Body4} from '../../../../components/Typography';
import {FlexBox, Row} from '../../../../components/Grid';
import BootcampLockdown from '../../../../components/Modal/components/BootcampLockdown';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg'
import {ReactComponent as Checkmark} from '@bootcamp/shared/src/assets/icons/checkmark-outline.svg';
import {School} from '@styled-icons/material-rounded/School';
import {Play} from '@styled-icons/heroicons-solid/Play';
import {Checkmark as CheckmarkCircle2} from '@styled-icons/fluentui-system-filled/Checkmark';
import {RadioButton as RadioButtonOn} from '@styled-icons/fluentui-system-filled/RadioButton';
import {RadioButtonOff} from '@styled-icons/evaicons-solid/RadioButtonOff';

import {TaskListSquareLtr} from '@styled-icons/fluentui-system-filled/TaskListSquareLtr';

import baseTheme from '@bootcamp/shared/src/styles/theme';

import styled, {css} from 'styled-components';
import Top from '@bootcamp/web/src/components/QBank/SidebarTop';
import ProgressBar from '../../../../components/ProgressBar';

const sidebarWidth = '256px';

const Background = styled.div`
  height: 100%;
  min-width: ${sidebarWidth};
  background: transparent;
  transition: min-width .3s;
  z-index: 3;
  position: relative;
  ${({showingSidebar}) => !showingSidebar && css`
  min-width: 0%;
  `}

  ${({theme}) => theme.mediaQueries.laptop} {
    height: 100%;
    width: 100%;
    min-width: 0;
    position: fixed;
    background: ${({theme}) => theme.overlays.opacity.dark._400};
    transition: background .3s;
    ${({showingSidebar}) => !showingSidebar && css`
      transition: background .3s linear 0s, width .1s linear .3s;
      background: transparent;
      width: 0%;
    `}
  }
`;

const Container = styled.div`
  width: ${sidebarWidth};
  height: 100%;
  transition: left .3s;
  color: white;
  display: flex;
  flex-direction: column;
  background: ${({theme, themePalette}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfacePrimary : themePalette ? themePalette.dark : theme.colors.brandPalette.violet.dark};
  position: absolute;
  top: 0;
  ${({showingSidebar}) => showingSidebar ? css`left: 0;` : css`left: -${sidebarWidth};`}
  overflow-y: auto;
  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
    ${({showingSidebar}) => showingSidebar ? css`left: 0;` : css`left: -100vw;`}
  }
  ${({theme}) => theme.mediaQueries.mobileM} {
    width: 86.7%;
  }
`;

const List = styled.div`
  overflow-y: scroll;
  min-height: 200px;
  position: relative;
  flex: 1;
  padding: 12px 0px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset -4px 4px 12px rgba(0, 0, 0, 0.15);
`;

const StyledItem = styled.div`
  cursor: pointer;

  background: ${({active, isComplete, theme}) =>  isComplete && active ? theme.colors.special.pearl : active ? 'white' : isComplete ? 'rgba(0, 0, 0, 0.05)' : theme.overlays.opacity.light._100};
  border-radius: 8px;
  border: ${({isComplete}) => isComplete ? '1px solid rgba(0, 0, 0, 0.05)' : '1px solid rgba(255, 255, 255, .1)'};
  transition: 100ms border;

  &:hover {
    transition: 100ms border;
    background: ${({theme, active}) => active ? 'white' : 'rgba(255, 255, 255, .2)'};
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 12px;

  ${H3} {
    margin: 0;
  }
`;
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const ItemTitle = styled(H4)`
  color: ${({lightMode}) => lightMode ? baseTheme.colors.neutralsPalette.extraDark : 'white'};
  margin-left: 0 !important;
  font-weight: 600;
  line-height: 100%;
  font-size: 14px;
`;
const QuizItemTitle = styled(ItemTitle)`
  margin-bottom: 4px;
`;
const Complete = styled(Checkmark)`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 13px;
  height: 13px;
  z-index: 2;

  circle {
    fill: ${({theme}) => theme.overlays.opacity.light._400};
  }

  path {
    fill: ${({theme, themePalette}) => themePalette ? themePalette.dark : theme.colors.brandPalette.violet.dark};
  }
`;

const StyledBootcampLockdown = styled(BootcampLockdown)`
  height: auto;
  width: 80%;
  display: flex;
  align-items: center;
  max-width: 560px;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: none;
  }
`

function getItemData(testBlock) {
  const title = testBlock && testBlock.title;
  const number = findByRenderType(testBlock.components, 'number', null);
  const video = findByRenderType(testBlock.components, 'video', '{}');
  const passage = findByRenderType(testBlock.components, 'passage', '{}');
  const contentType = findByRenderType(testBlock.components, 'contentType', null);
  const {id: videoId, duration: rawDuration} = JSON.parse(video);
  const duration = parseVideoDuration(rawDuration);

  return {title, number, videoId, duration, passage, contentType}
}

const Introduction = ({testBlock, active, isComplete, themePalette}) => {
  const Icon = isComplete
    ? Complete
    : null;

  return (
    <Container>
      <FlexBox>
        <ItemTitle lightMode={active}>Chapter Overview</ItemTitle>
        {Icon && <Icon themePalette={themePalette}/>}
      </FlexBox>
    </Container>
  );
}

const DetailText = styled(H5)`
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  color: ${({lightMode, theme, themePalette}) => !lightMode ? theme.overlays.opacity.light._400 : baseTheme.colors.neutralsPalette.grey};
  line-height: 100%;
`;

const PlayIcon = styled(Play).attrs(props => ({size: 32}))`
  opacity: .3;
  ${({isActive, themePalette}) => isActive && css`
    opacity: 1;
    color: ${themePalette.default};
  `}
`;

const SchoolIcon = styled(School).attrs(props => ({size: 32}))`
  opacity: .3;
  ${({isActive, themePalette}) => isActive && css`
    opacity: 1;
    color: ${themePalette.default};
  `}
`;

const LockedIcon = styled(Badge)`
  width: 16px;
  path {
    fill: white;
    ${({isActive, themePalette}) => isActive && css`
      opacity: 1;
      fill: ${themePalette.default};
    `}
  }
`;

const LessonNumber = styled(H4)`
  color: ${({theme, isActive}) => isActive ? baseTheme.colors.neutralsPalette.grey : 'white'};
  margin-right: 8px;
  font-size: 14px;
  line-height: 100%;
  width: 14px;
`;
const LessonDetails = styled(Row)`
  align-items: center;
`;
const Lesson = ({testBlock, active, isComplete, themePalette, locked}) => {
  const {title, number, duration} = getItemData(testBlock);

  return (
    <ItemContainer>
      <Thumbnail
        locked={locked}
        Icon={PlayIcon}
        isComplete={isComplete}
        isActive={active}
        themePalette={themePalette}
      />
      <LessonNumber isActive={active}>
        {number}
      </LessonNumber>
      <Content>
        <ItemTitle lightMode={active}>{title}</ItemTitle>
        <DetailText lightMode={active}>
          <LessonDetails>
            {duration}
          </LessonDetails>
        </DetailText>
      </Content>
    </ItemContainer>
  );
}

const ThumbnailContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const CheckIcon = styled(CheckmarkCircle2).attrs(props => ({size: 14}))`
  color: ${({isActive, themePalette}) => isActive ? themePalette.default : 'white'};
  width: 14px;
  border-radius: 100%;
`

const AnsweredIcon = styled(RadioButtonOn).attrs(props => ({size: 14}))`
  color: ${({themePalette}) => themePalette.default};
  width: 14px;
`;

const UnansweredIcon = styled(RadioButtonOff).attrs(props => ({size: 14}))`
  color: rgba(255,255,255,.7);
  width: 14px;
`;


const Thumbnail = ({Icon, isComplete, isActive, themePalette, locked}) => (
  <ThumbnailContainer isActive={isActive} themePalette={themePalette}>
    {locked
      ? <LockedIcon isActive={isActive} themePalette={themePalette}/>
      : isComplete
        ? (
          <CheckIcon isActive={isActive} themePalette={themePalette} />
        )
        : isActive
          ? (
            <AnsweredIcon themePalette={themePalette} />
          )
          : (
            <UnansweredIcon />
          )
    }
  </ThumbnailContainer>
);
const StyledQuizIcon = styled(TaskListSquareLtr).attrs(props => ({size: 14}))`
  color: ${({theme, lightMode}) => lightMode ? baseTheme.colors.neutralsPalette.grey : theme.overlays.opacity.light._400};
  margin-right: 8px;
`;
const ProgressRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Quiz = ({testBlock, active, isComplete, themePalette, locked, blockProgress}) => {
  const {title} = getItemData(testBlock);
  const quizNumber = title.split(' ')[0].includes('.') ? title.split(' ')[0] : <StyledQuizIcon />;

  const progress = blockProgress
    ?.questions
    ?.reduce((acc, {masteryLevel}) => ({
      ...acc,
      [masteryLevel]: acc[masteryLevel] ? acc[masteryLevel] + 1 : 1,
      total: acc.total ? acc.total + 1 : 1,
    }), {});

  const totalQuestions = blockProgress?.questions?.length;
  const taggedQuestions = blockProgress?.questions?.filter(({masteryLevel}) => !!masteryLevel && masteryLevel != 'none')?.length;
  return (
    <ItemContainer>
      <Thumbnail
        locked={locked}
        Icon={SchoolIcon}
        isComplete={isComplete}
        isActive={active}
        themePalette={themePalette}
      />
      <LessonNumber>
        <StyledQuizIcon lightMode={active}/>
      </LessonNumber>
      <Content>
        <QuizItemTitle lightMode={active}>{title.replace(`${quizNumber} `, '').replace(' Quiz', '').replace('Concept Review', '').replace(' (Quiz)', '')} Quiz</QuizItemTitle>
        <ProgressRow>
          <ProgressBar
            showLabel={false}
            withMargin={false}
            total={progress?.total}
            segments={[{ count: progress?.mastered}, { count: progress?.reviewing}, { count: progress?.learning}]}
            segmentHeight={'6px'}
          />
          <DetailText style={{whiteSpace: 'nowrap', width: 'auto', marginLeft: '8px', marginTop: 0, display: 'flex', alignItems: 'center', lineHeight: 'normal'}} lightMode={active}>
            {taggedQuestions}/{totalQuestions}
          </DetailText>
        </ProgressRow>
      </Content>
    </ItemContainer>
  );
}

const Text = ({testBlock, active, isComplete, themePalette, locked}) => {
  const {title, contentType} = getItemData(testBlock);
  return (
    <ItemContainer>
      <Thumbnail
        isComplete={isComplete}
        isActive={active}
        themePalette={themePalette}
        locked={locked}
      />
      <LessonNumber isActive={active}>
        <StyledQuizIcon lightMode={active} />
      </LessonNumber>
      <Content>
        <ItemTitle lightMode={active}>{title}</ItemTitle>
        {contentType && <DetailText lightMode={active}>{contentType === 'Info' ? 'PDF' : contentType}</DetailText>}
      </Content>
    </ItemContainer>
  );
}

const Item = ({testBlock, active, isComplete, onClick, themePalette, locked, blockProgress}) => {
  const ItemComponent = {
    lesson: Lesson,
    questionSet: Quiz,
    text: Text,
    passage: Quiz,
    startBlock: Introduction,
    endBlock: () => <div>end</div>,
  }[testBlock.type];

  return (
    <StyledItem active={active} isComplete={isComplete} onClick={onClick} themePalette={themePalette}>
      <ItemComponent testBlock={testBlock} active={active} isComplete={isComplete} themePalette={themePalette} locked={locked} blockProgress={blockProgress}/>
    </StyledItem>
  );
}

const Group = styled.div`
  padding: 4px 16px;
`;
const ProgressLabel = styled(Body4)`
  color: ${() => baseTheme.colors.neutralsPalette.white};
`;

const Sidebar = ({showingSidebar, setShowingSidebar, classroomName, chapterName, themePalette, blockRef, hide=[]}) => {
  const {isUpgraded, bootcamp} = useUserDataContext();

  const {
    methods: {
      setBlockIndex,
      setCurrentIndex,
    },
    variables: {
      testBlockConnections,
      blockIndex,
      quizProgress,
      template,
      navigationFilter
    }
  } = useTestNavigatorContext();

  const {modalDispatch} = useModalContext();

  const completeLessons = quizProgress.reduce((acc, blockProgress) => blockProgress.status === 'complete' ? acc + 1 : acc, 0);
  const totalLessons = testBlockConnections.length - 2;
  const progressBar = (
    <ProgressBar
      showLabel={false}
      withMargin={false}
      label={<ProgressLabel><strong>{completeLessons}</strong> of {totalLessons} lessons completed</ProgressLabel>}
      total={totalLessons}
      segments={[{count: completeLessons}]}
    />
  )
  return (
    <Background showingSidebar={showingSidebar} onClick={() => setShowingSidebar(false)}>
      <Container themePalette={themePalette} showingSidebar={showingSidebar} onClick={e => e.stopPropagation()}>
        <Top
          progressBar={progressBar}
          title={chapterName}
          parentTitle={classroomName}
          showingSidebar={showingSidebar}
          setShowingSidebar={setShowingSidebar}
          themePalette={themePalette}
        />
        <List>
          {testBlockConnections.reduce((acc, {testBlock}, index) => {
            if (testBlock.type === 'startBlock' || index === testBlockConnections.length - 1) return acc;
            if (navigationFilter.key === 'blockFilter' && navigationFilter.value === 'quizzes' && testBlock.type !== 'questionSet') return acc;
            if (navigationFilter.key === 'blockFilter' && navigationFilter.value === 'videos' && testBlock.type !== 'lesson') return acc;
            if (navigationFilter.key === 'search' && !testBlock.questions && !testBlock.title?.toLowerCase().includes(navigationFilter.value?.toLowerCase())) return acc;
            const locked = bootcamp === 'nclex' ? false : bootcamp === 'dat' && ['Molecules and Fundamentals of Biology', 'General and Lab Concepts Review', 'Bonding and Molecular Geometry', 'Keyholes', 'RC Questions', 'Functions and Expressions'].includes(chapterName) ? false : bootcamp !== 'inbde' && !isUpgraded && index > 2 && !window.location.pathname.includes('application-services');
            const isComplete = quizProgress[index].status === 'complete';
            const onClick = locked
              ? () => {
                modalDispatch({
                  type: 'open',
                  component: StyledBootcampLockdown,
                  renderInContainer: blockRef.current,
                  enableClickClose: true,
                  componentProps: {
                    isPremiumFeature: true,
                    showInteractions: false,
                    showReviews: false,
                    showFeatures: false,
                    fixedOnMobile: false,
                    headerText:  'This content is for Bootcamp upgraded members only.',
                    subheaderText: 'Upgrade your membership and get instant access to all content and much more.',
                    upgradeText: 'Upgrade Your Membership',
                    showClose: true,
                  },
                  modalContainerStyle: theme => `
                    background: ${theme.overlays.opacity.dark._300};
                    position: absolute;
                    align-items: center;
                    z-index: 2;
                    height: auto;

                    ${theme.mediaQueries.tablet} {
                      padding: 0;
                      overflow: hidden;
                    }
                  `
                });
              }
              : ['questionSet', 'passage'].includes(testBlock.type)
                ? () => setCurrentIndex(index, 0)
                : () => setBlockIndex(index, 0)

            return [
              ...acc,
              <ScrollOnActive active={index === blockIndex} id={`sidebar-item-${index}`} key={`sidebar-item-${index}`}>
                <Item
                  active={index === blockIndex}
                  testBlock={testBlock}
                  isComplete={isComplete}
                  themePalette={themePalette}
                  blockProgress={quizProgress[index]}
                  locked={locked}
                  onClick={() => {
                    onClick()
                    !locked && window.innerWidth <= 768 && setShowingSidebar(false);
                  }}
                />
              </ScrollOnActive>
            ];
          }, [])}
        </List>
      </Container>
    </Background>
  );
}

Sidebar.propTypes = {};
Sidebar.defaultProps = {};

export default Sidebar;

const ScrollOnActive = (props) => {
  const ref = useRef();
  const active = props.active;
  useEffect(() => {
    if (ref.current && active) {
      try {
        const scrollContainer = ref.current.parentElement;
        const dims = ref.current.getBoundingClientRect();

        const topBarHeight = 375 // hardcoded top bar heights
        const updatedScrollTop = (dims.top - topBarHeight) + scrollContainer.scrollTop;

        scrollContainer.scroll({
          top: updatedScrollTop,
          behavior: 'smooth',
        });
      } catch (error) {
        // temporarily adding this for cross-browswer testing
        console.log('Error scrolling to sidebar element', error);
      }
    }
  }, [ref, active])
  return <Group ref={ref} {...props} />
}