import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';

import Highlightable from '@bootcamp/web/src/components/Highlightable';

import SettingsSidebar from '../../PrometricBank/components/Sidebar';
import Question, {ChoicesContainer, ChoiceContainer, ChoiceText, CheckButton, ChoiceInput, ChoiceFeedback, Prompt, AnswerLetter} from '../../USMLEBank/Question';
import {IntercomButton} from '@bootcamp/web/src/components/QBank/Question/Solution.js'

import TopBar from './TopBar';
import BottomBar from './BottomBar';
import Navigator from './Navigator';

import {findByRenderType} from '@bootcamp/shared/src/util';
import theme from '@bootcamp/shared/src/styles/theme';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

const HighlightWrapper = styled(Highlightable)`
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
const QuestionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  `;
  const PassageContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  flex: 1;
  overflow: hidden;

  .strikethrough {
    text-decoration: line-through;
    color: unset;
    user-select: auto !important;
  }

  `;
  const PassageWrapper = styled.div`
  * {
    font-family: Verdana !important;
  }
  max-width: calc(50% - 13px);
  display: ${({showingPassage}) => showingPassage ? 'flex' : 'none'};
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 32px;
  ${({theme}) => theme.mediaQueries.tablet} {
    max-width: 100%;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 16px;
  }
`;
const PassageText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;
const PassageTitle = styled.div`
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 28.5px */
`
const QuestionWrapper = styled.div`
  display: ${({showingPassage}) => !showingPassage ? 'flex' : 'none'};
  border-left: 25px solid ${({theme}) => theme.colors.prometricPalette.mcat.dark};
  flex: 1;
  * {
    font-family: Verdana;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    border-left: none;
  }
`;
const StyledQuestion = styled(Question)`
  background: white;
  display: flex;
  flex-direction: column;
  padding: 32px;

  ${Prompt} {
    margin-bottom: 8px;
  }
  ${ChoiceFeedback} {
    top: 2px;
  }

  ${IntercomButton} {
    margin-bottom: 75px;
  }

  ${ChoicesContainer} {
    border: none;
    padding-left: 0;
  }
  ${ChoiceContainer} {

  }
  ${ChoiceInput} {
    accent-color: black;
    outline-offset: 1.5px;
    margin-top: 6px;
    align-self: flex-start;
    margin-right: 12px;
    &:checked {
      outline: 1px solid black;
    }
  }
  ${AnswerLetter} {
    font-weight: bold;
    align-self: flex-start;
  }
  ${CheckButton} {
    width: 100px;
    height: 35px;
    font-size: 16px;
    font-weight: 400;
  }
  ${ChoiceText} {
    display: flex;
    flex-grow: 1;
  }
  ${ChoiceText} > span {
    font-weight: bold;
    margin-right: 10px;
  }

  blockquote {
    padding: 15px;
    margin: 0 0 1em 0;
    font-family: 'proxima-nova', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #F9f9f9;
    color: #333;
    border-left: solid 5px #0F6292;
  }
  blockquote p {
    margin-bottom: 0 !important;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 16px;
  }
`;

const themePalette = theme.colors.brandPalette.indigo;

const ViewSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`;
const ViewOption = styled.div`
  font-family: Verdana;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 40px;
  cursor: pointer;
  background: ${({theme, selected}) => !selected ? theme.colors.neutralsPalette.light : 'white'};
  color: ${({theme, selected}) => selected ? theme.colors.prometricPalette.mcat.dark : theme.colors.neutralsPalette.mcat.dark};
`;

const PassageBlock = ({testBlock, settingsSidebarState, handleNavigation, finishedOnClick, handleSuspendClick, passageTitle, highlightMode, setHighlightMode}) => {
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  const [showingPassage, setShowingPassage] = useState(true);
  const {methods: {updateQuizProgress}, variables: {quizProgress, blockIndex}} = useTestNavigatorContext();
  const {highlights} = quizProgress?.[blockIndex] || {};
  const questionSetExplanation = testBlock.components && findByRenderType(testBlock.components, 'explanation');
  const passageBlock = testBlock.type === 'passage';
  const passage = passageBlock && testBlock.components.find(component => component.renderType === 'passage').contents;

  const passageHtml = passage.replace(/<p><br><\/p>/g, '');

  const tooltipRef = useRef(null);
  const strikethroughTooltipRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(document.documentElement.clientWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  if (width <= 768) {
    return (
      <QuestionContainer>
        <TopBar highlightTooltipRef={tooltipRef} strikethroughTooltipRef={strikethroughTooltipRef} highlightMode={highlightMode} settingsSidebarState={settingsSidebarState} />
        <ViewSelectorContainer>
          <ViewOption theme={theme} selected={showingPassage} onClick={() => setShowingPassage(true)}>Passage</ViewOption>
          <ViewOption theme={theme} selected={!showingPassage} onClick={() => setShowingPassage(false)}>Question</ViewOption>
        </ViewSelectorContainer>
        <HighlightWrapper htmlString={passageHtml} type={'question'} highlights={highlights} setHighlights={updateQuizProgress} strikethroughTooltipRef={strikethroughTooltipRef} tooltipRef={tooltipRef} enableKeyboardShortcuts onModeChange={setHighlightMode}>
          <PassageContainer>
            <PassageWrapper showingPassage={showingPassage}>
              <PassageTitle>{passageTitle}</PassageTitle>
              <PassageText dangerouslySetInnerHTML={{__html: passageHtml}} />
            </PassageWrapper>
            <QuestionWrapper showingPassage={showingPassage}>
              <StyledQuestion themePalette={themePalette} tooltipRef={tooltipRef} strikethroughTooltipRef={strikethroughTooltipRef} disableHighlightToolTip={false} showingQuestionIndex disableHighlighting blockId={testBlock.id} />
            </QuestionWrapper>
          </PassageContainer>
        </HighlightWrapper>
        <BottomBar settingsSidebarState={settingsSidebarState} navigatorComponent={props => <Navigator {...props} />} handleNavigation={handleNavigation} finishedOnClick={finishedOnClick} handleSuspendClick={handleSuspendClick} />
        <SettingsSidebar sidebarState={settingsSidebarState} themePalette={themePalette} hide={['questions']}/>
      </QuestionContainer>
    );
  }
  return (
    <QuestionContainer>
      <TopBar highlightTooltipRef={tooltipRef} strikethroughTooltipRef={strikethroughTooltipRef} highlightMode={highlightMode} settingsSidebarState={settingsSidebarState}/>
      <HighlightWrapper htmlString={passageHtml} type={'question'} highlights={highlights} setHighlights={updateQuizProgress} strikethroughTooltipRef={strikethroughTooltipRef} tooltipRef={tooltipRef} enableKeyboardShortcuts onModeChange={setHighlightMode}>
        <PassageContainer>
          <PassageWrapper showingPassage>
            <PassageTitle>{passageTitle}</PassageTitle>
            <PassageText dangerouslySetInnerHTML={{__html: passageHtml}} />
          </PassageWrapper>
          <QuestionWrapper>
            <StyledQuestion themePalette={themePalette} tooltipRef={tooltipRef} strikethroughTooltipRef={strikethroughTooltipRef} disableHighlightToolTip={false} showingQuestionIndex disableHighlighting blockId={testBlock.id} />
          </QuestionWrapper>
        </PassageContainer>
      </HighlightWrapper>
      <BottomBar settingsSidebarState={settingsSidebarState} navigatorComponent={props => <Navigator {...props} />} handleNavigation={handleNavigation} finishedOnClick={finishedOnClick} handleSuspendClick={handleSuspendClick} />
      <SettingsSidebar sidebarState={settingsSidebarState} themePalette={themePalette} hide={['questions']}/>
    </QuestionContainer>
  );
};

export default PassageBlock;