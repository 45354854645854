import React, {useEffect, useRef, useState} from 'react';

import Results from './Results';
import {H5} from '../../../Typography';
import styled from 'styled-components';
import {useHotkeys} from 'react-hotkeys-hook';
import {useBootcampConfigContext} from '../../../../contexts/BootcampConfig';
import {useDebounce} from '@bootcamp/shared/src/util/hooks';
import {MagnifyingGlass as Search} from '@styled-icons/heroicons-outline/MagnifyingGlass';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 24px;
  transition: .2s;
  line-height: 0;
  height: 42px;
  padding: 8px 24px;
  background: rgba(255,255,255,.1);
  border: .5px solid rgba(255,255,255,.05);
  flex: 1;
  position: relative;
`;
const Icon = styled(Search).attrs(props => ({size: 16}))`
  position: absolute;
  z-index: 10;
  user-select: none;
  left: 16px;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
  color: ${({theme}) => theme.overlays.opacity.light._400};
  ${({theme}) => theme.mediaQueries.tablet} {
    left: 24px;
  }
`;
const Input = styled.input`
  font-family: proxima-nova;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 0px;
  background: none;
  border: none;
  caret-color: white;
  color: white;
  outline: none;
  padding: 16px 24px 16px 16px;
  ::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding-right: 0px;
  }
`;

const KeyboardShortcutPill = styled(H5)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 8px;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.7);
  ${({theme}) => theme.mediaQueries.mobileL} {
    display: none;
  }

  opacity: 1;
  ${({active}) => active && `
    opacity: 0;
  `}
  transition: opacity .5s;
`

const ConfigSearch = ({className}) => {
  const [search, setSearch] = useState('');
  const [hits, setHits] = useState([]);
  const [active, setActive] = useState(false);
  const {indexedConfig} = useBootcampConfigContext();
  const debouncedSearch = useDebounce(search, 150);

  const inputRef = useRef(null);

  const ref = useRef();
  const resultsRef = useRef();


  function handleClick(event) {
    if (!active) return;

    const canDoWork = ref && ref.current && ref.current.contains && event && event.target;
    const isInside = canDoWork && ref.current.contains(event.target);

    if (!isInside) setActive(false);
  }

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [active]);

  useHotkeys('cmd+j,ctrl+j', event => {

    event?.preventDefault();
    event?.stopPropagation();

    setActive(true);
    inputRef.current?.focus();
  }, [active]);

  useHotkeys('esc', () => {
    if (!active) return;
    setActive(false);
    inputRef.current?.blur();
  }, [active]);

  useEffect(() => {
    if (!inputRef.current) return;

    active
      ? inputRef.current.focus()
      : inputRef.current.blur();

  }, [active, inputRef])

  // Map step: Generate intermediate data structure
  const mapStep = (indexedConfig, processedSearch) => {
    return Object.keys(indexedConfig).map(key => {
      const lowerKey = key.toLowerCase();
      const match = processedSearch.every(word => lowerKey.includes(word));
      const subject = indexedConfig[key].subject;
      return {key, match, subject};
    });
  };

  // Reduce step: Combine intermediate data into final list of hits
  const reduceStep = (intermediateData, indexedConfig) => {
    let subjects = {};
    let hits = [];

    for (let {key, match, subject} of intermediateData) {
      if (match) {
        if (subject && !subjects[subject]) {
          subjects[subject] = true;
          hits.push(indexedConfig[subject]);
        } else if (!subjects[key]) {
          subjects[key] = true;
        }
        hits.push(indexedConfig[key]);
      }
    }

    return hits;
  };

  useEffect(() => {
    try {
      if (!search.length || debouncedSearch.length < 3) return setHits([]);
  
      const processedSearch = search
        .toLowerCase()
        .split(' ')
        .filter(word => !['the', 'a', 'an', 'of', 'to', 'or', 'and'].includes(word));
  
      // Map step
      const intermediateData = mapStep(indexedConfig, processedSearch);
  
      // Reduce step
      const hits = reduceStep(intermediateData, indexedConfig);
  
      setHits(hits);
    } catch (error) {
      setHits([]);
    }
  }, [debouncedSearch]);
  return (
    <Container ref={ref} className={className}>
      <Wrapper onClick={e => setActive(true)}>
        <Icon/>
        <Input placeholder={"Jump to a page, video, or qbank"}
          ref={inputRef}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={event => {
            if (hits.length > 0 && event.keyCode === 40) {
              event.preventDefault();
              event.target.blur();
              resultsRef.current?.children[0]?.focus();
              return false;
            }
          }}
          value={search}
        />
        <KeyboardShortcutPill active={active}>
          {/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'ctrl'} + J
        </KeyboardShortcutPill>
      </Wrapper>
      {active && <Results items={hits} resultsRef={resultsRef} />}
    </Container>
  );
};

ConfigSearch.defaultProps = {};
ConfigSearch.propTypes = {};

export default ConfigSearch;
