import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Pie } from 'react-chartjs-2';

import Chart from 'chart.js/auto';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  `;
const Wrapper = styled.div`
  align-self: center;
  height: 320px;
  width: 60%;
`;
const Title = styled.h3`
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutralsPalette.lightGrey};
  color: ${({ theme }) => theme.colors.neutralsPalette.grey};
`;

const PieChart = ({ data, title }) => {

  const dataset = {
    labels: data.slice(1).map(([label, value]) => label),
    datasets: [{
      label: 'Time Spent',
      data: data.slice(1).map(([label, value]) => value),
    }]
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'left'
      }
    },
  };

  return (
    <Container>
      {title && <Title children={title} />}
      <Wrapper>
        <Pie data={dataset} options={options} />
      </Wrapper>
    </Container>
  );
};

PieChart.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
};

PieChart.defaultProps = {
  data: {
    labels: ['Bob', 'Jenny'],
    datasets: [
      {
        label: 'Age',
        data: [22, 34],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  },
};

export default PieChart;
