import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {Column} from '../../Grid';

import ChangePassword from '../../../components/Form/Auth/ChangePassword';

const ModalContainer = styled(Column)`
  max-width: 528px;
  position: relative;
  height: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    transition: transform .5s, opacity .75s;
    ${({visible}) => visible
      ? `
        transform: translateY(0%);
        opacity: 1;
        margin-top: auto;
      `
      : `
        transform: translateY(100%);
        opacity: 0;
      `
    }
  }
`;

const ChangePasswordModal = ({close}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <ModalContainer onClick={e => {
      e.stopPropagation();
    }} visible={visible}>
      <ChangePassword onSubmit={close} />
    </ModalContainer>
  )
}
export default ChangePasswordModal;
