import React from 'react';
import PropTypes from 'prop-types';

import {FlexBox} from '../Grid';
import {H4} from '../Typography';
import {IconButton} from '../Branding/Buttons';
import {Collapse} from '@styled-icons/evaicons-solid/Collapse';
import {ArrowMaximize as Expand} from '@styled-icons/fluentui-system-filled/ArrowMaximize';
import {useTimerContext} from '../../contexts/TestTimer';
import {useTestNavigatorContext} from '../../contexts/TestNavigator';
import {useModalContext} from '../../contexts/Modal';
import {useUserDataContext} from '../../contexts/UserData';
import {Cog} from '@styled-icons/heroicons-solid/Cog';
import styled from 'styled-components';


const Container = styled(FlexBox)`
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  padding: 8px 32px;
  height: 81px;
  min-height: 81px;
  position: relative;
  align-items: center;

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => `${theme.layouts.spacing.s} ${theme.layouts.spacing.m}`};
    height: 56px;
    min-height: auto;
  }
`;

const ToggleSidebarButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 16px;
  z-index: 4;
  display: ${({showingSidebar}) => showingSidebar ? 'none' : 'flex'};
  margin-right: 16px;

  svg {
    fill: ${({theme}) => theme.colors.neutralsPalette.dark};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }


  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-right: 8px;
  }
  box-shadow: none;
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
`;
const ToggleSidebarOpenIcon = styled(Expand)`
  fill: white;
`;
const ToggleSidebarClosedIcon = styled(Collapse)`
  fill: white;
`;
const ToggleSettingsButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 16px;
  background: white;
  z-index: 4;
  border: 1px solid rgb(237, 237, 240);
  border-radius: 100%;
  position: relative;
  svg {
    fill: ${({theme}) => theme.colors.neutralsPalette.dark};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }


  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 8px;
  }
  box-shadow: none;
`;
const ToggleSettingsButtonIcon = styled(Cog)`
  fill: #85868E;
`;
const NotificationDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 2px solid white;
  background: ${({theme}) => theme.colors.interfacePalette.red.dark};
  position: absolute;
  top: 0;
  right: 0;
`;

const Header = styled(H4)`
  font-weight: normal;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const RightContainer = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TimerText = styled(Header)`
  display: flex;
  cursor: pointer;

  span {
    display: block;
    width: 85px;

    &:first-child {
      width: auto;
      margin-right: 5px;
    }
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    span {
      width: 75px;
    }
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    flex-direction: column;
  }

`;

const ReviewToggleSettingsButton = styled(ToggleSettingsButton)`
  margin-left: auto;
`;

const TopBar = ({title, themePalette, sidebarState, questionStats, onExit, showScore, questionCount, questionCountThusFar}) => {
  const [showingSidebar, setShowingSidebar] = sidebarState;
  const {timeElapsed, toggleTimer, timerActive, longTimeLeft} = useTimerContext();
  const {variables: {template, blockIndex, customTestConfig}} = useTestNavigatorContext();
  const {modalDispatch} = useModalContext();
  const {saveUserInteraction, searchUserInteractions} = useUserDataContext();
  const hideTimer = searchUserInteractions('hideTimer') === true;
  const timed = customTestConfig?.config?.timed;

  if (['testReview'].includes(template)) return (
    <Container background={themePalette.dark}>
      <ToggleSidebarButton
        type={'primary'}
        size={'large'}
        themePalette={themePalette}
        children={(showingSidebar && window.innerWidth >= 768) ? <ToggleSidebarClosedIcon size={20}/> : <ToggleSidebarOpenIcon size={20}/>}
        onClick={() => setShowingSidebar(!showingSidebar)}
        showingSidebar={showingSidebar}
      />
      {questionCount && (
        <Header>
          Question {questionCountThusFar} of {questionCount}
        </Header>
      )}
      {blockIndex === 0 && (
        <Header>
          Score Report
        </Header>
      )}
    </Container>
  )
  return (
    <Container background={themePalette.dark}>
      <ToggleSidebarButton
        type={'primary'}
        size={'large'}
        themePalette={themePalette}
        children={(showingSidebar && window.innerWidth >= 768) ? <ToggleSidebarClosedIcon size={20}/> : <ToggleSidebarOpenIcon size={20}/>}
        onClick={() => setShowingSidebar(!showingSidebar)}
        showingSidebar={showingSidebar}
      />
      <Header>
        Question {questionCountThusFar} of {questionCount}
      </Header>
      <RightContainer>
        {!hideTimer &&
          <TimerText onClick={() => toggleTimer()}>
            <span>{timerActive && timed ? 'Time Remaining' : timerActive ? 'Time Taken:' : 'Timer Paused:'}</span>
            <span>{timed ? longTimeLeft : timeElapsed}</span>
          </TimerText>
        }
      </RightContainer>
    </Container>
  );
}

TopBar.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};
TopBar.defaultProps = {
  title: '',
  color: '',
};

export default TopBar;
