import * as keys from './keys';
import firstBy from 'thenby';
import moment from 'moment';
import Storage from '@aws-amplify/storage';
import {getRevision, getAllQuestionMastery} from '@bootcamp/shared/src/requests';
import ChemistryConfig from '@bootcamp/shared/src/config/updatedChemistryConfig';
import * as Sentry from '@sentry/react'; // TODO set up sentry for app.bootcamp
import {calculateMaxScore} from './scoring/NCLEX';
import {updateUserInteraction, createUserInteraction, listUserInteractions} from '@bootcamp/shared/src/requests/User';

const defaultQuestionParts = {
  prompt: '',
  answers: [],
  explanation: '',
  caseset: '',
  citations: '',
  xray: '',
  clinicalPhoto: '',
  tags: [],
  comments: [],
  anatomyImage: '',
  updatedAt: ''
};

function delay(time) {
    return new Promise(resolve => {
        document.body.style.setProperty('cursor', 'wait', 'important');
        setTimeout(() => {
            document.body.style.cursor = 'default';
            resolve();
        }, time);
    });
}
function getFormattedStripeAmount(amount) {
  return (parseInt(amount) / 100)
    .toFixed(2)
    .replace(".00", "");
}

function deSlugify(slug) {
  if (!slug) return '';
  const parts = slug.split('-');

  return parts
    .map(([firstLetter, ...rest]) => firstLetter.toUpperCase() + rest.join(''))
    .join(' ');
}

export const getQbankType = (classroom, template) => {
  switch (template) {
    case 'testReview':
      return 'Test Review';
    case 'customTest':
      return 'Created Test';
    default:
      return classroom ? deSlugify(classroom) : 'Question Bank';
  }
};

export function getTestTitle(title, contentType, config) {

  if (!title) return config?.title;

  const contentTypePrefixes = {
    'clinical-cases': 'Clinical Cases',
    'application': 'Application',
    'lecture-style-questions': 'Lecture Style',
    'identify-structures': 'Identification',
    'practicals': 'Practicals',
    'bio-bites': 'Bio Bites',
    'bites': 'Bites',
    'rbites': 'Reaction Bites',
    'plus-packs': 'Plus Pack',
  };

  const prefix = contentTypePrefixes[contentType] ? `${contentTypePrefixes[contentType]}:` : '';

  return `${prefix} ${title}`;
}

export const getTitleConfig = (title, classroom, template, customTestConfig) => {
  return template === 'customTest' ?
    {
      type: 'Bootcamp.com',
      title: `${customTestConfig?.config?.tutorMode ? 'Tutored' : 'Untutored'}, ${customTestConfig?.config?.timed ? 'Timed' : 'Untimed'}`
    } : {
      type: getQbankType(classroom, template),
      title: title?.trim()
    }
}

function slugify(string) {
  if (!string) return '';

  return string
    .toLowerCase()
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'');
}

function shuffle(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function sortByDate(revisions) {
  return revisions.sort(firstBy('createdAt'));
}

function parseVideoDuration(rawDuration) {
  if (!rawDuration) return null;

  const hasMinutes = !!rawDuration.match('m');
  const hasSeconds = !!rawDuration.match('s');

  const minutes = (hasMinutes && rawDuration.split('m')[0]);
  const seconds = hasMinutes && hasSeconds
    ? rawDuration.replace('s', '').split('m')[1]
    : hasSeconds
    ? rawDuration.split('s')[0]
    : '';

  return hasMinutes && hasSeconds
    ? `${minutes}:${seconds.length === 1 ? 0 : ''}${seconds}`
    : hasMinutes
    ? `${minutes}:00`
    : hasSeconds
    ? `00:${seconds.length === 1 ? 0 : ''}${seconds}`
    : null;
}

function findByRenderType(componentsArray, renderType, defaultValue='') {
  if (!componentsArray) return defaultValue;

  return (componentsArray.find(component => component.renderType === renderType) || {}).contents || defaultValue;
};

function getRuntime(testBlocks) {
  const runtime = testBlocks.filter(testBlock => testBlock.type === 'lesson').reduce((acc, testBlock) => {
    const videoData = findByRenderType(testBlock.components, 'video', '{}');
    const {duration} = JSON.parse(videoData);
    if (!duration) return acc;

    const parsedDuration = `00:${parseVideoDuration(duration)}`;
    return acc.add(parsedDuration);
  }, moment.duration(0));

  const totalRuntime = runtime.asSeconds() >= 3600 ? moment.utc(runtime.asMilliseconds()).format('H[h] mm[m] ss[s]') : moment.utc(runtime.asMilliseconds()).format('m[m] s[s]');

  return runtime.asMilliseconds() > 0 ? ` | Total runtime: ${totalRuntime}` : '';
}

function getQuestionType(question) {
  const tags = getInObj(['tags', 'items'], question, []);
  const {tag: {test, topic}} = tags.find(({tag}) => !!tag.test && !!tag.subject && !!tag.topic) || {tag: {test: 'DAT', subject: '', topic: ''}};

  return test === 'Anatomy'
    ? 'anatomy'
    : topic.match('Passage')
    ? 'passage'
    : 'default';
}

function getBlockParts (block) {
  const anatomyImageSolution = findByRenderType(block.components, 'anatomyImageSolution');
  const explanation = findByRenderType(block.components, 'explanation');
  return {
    anatomyImageSolution,
    explanation
  }
}
function getQuestionParts(question, revisionId=null) {
  if (!question || !question.latestRevisions || !question.latestRevisions.items.length) return defaultQuestionParts;

  let revisions = getRevisions(question);

  const {id: latestRevisionId, questionComponents, revisedBy, createdAt} = revisionId
    ? revisions.find(({id}) => id === revisionId) || revisions[question.latestRevisions.items.length - 1]
    : revisions[question.latestRevisions.items.length - 1];

  // use whichever is more recent btw qb updatedAT & latest revisions createdAt
  const questionBaseUpdatedAt = moment(question?.updatedAt);
  const latestRevisionUpdatedAt = moment(createdAt);

  const updatedAt = questionBaseUpdatedAt.isAfter(latestRevisionUpdatedAt) ? questionBaseUpdatedAt : latestRevisionUpdatedAt;
  const lastUpdated = updatedAt.format('YYYY');


  const prompt = findByRenderType(questionComponents, 'prompt');
  const questionHeader = findByRenderType(questionComponents, 'questionHeader');
  const answers = findByRenderType(questionComponents, 'answers', '[]');
  const explanation = findByRenderType(questionComponents, 'explanation');
  const caseset = findByRenderType(questionComponents, 'caseset');
  const xray = findByRenderType(questionComponents, 'xray')?.replace(/testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com\/public\//g, 'dekni9rgkrcu0.cloudfront.net/');
  const clinicalPhoto = findByRenderType(questionComponents, 'clinicalPhoto').replace(/testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com\/public\//g, 'dekni9rgkrcu0.cloudfront.net/');
  const clinicalExam = findByRenderType(questionComponents, 'clinicalExam').replace(/testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com\/public\//g, 'dekni9rgkrcu0.cloudfront.net/');
  const customExhibitName = findByRenderType(questionComponents, 'customExhibitName');
  const customExhibit = findByRenderType(questionComponents, 'customExhibit');
  const anatomyImage = findByRenderType(questionComponents, 'anatomyImage');
  const citations = findByRenderType(questionComponents, 'citations');
  const relatedVideos = findByRenderType(questionComponents, 'relatedVideos');
  const meta = findByRenderType(questionComponents, 'meta');
  const anatomyImageTransform = findByRenderType(questionComponents, 'anatomyImageTransform', '{}');
  const hotSpotImage =  findByRenderType(questionComponents, 'hotSpotImage');
  const hotSpotImageSelection = findByRenderType(questionComponents, 'hotSpotImageSelection', '{}');
  const type = findByRenderType(questionComponents, 'type') || 'multipleChoice';
  const caseHeader = findByRenderType(questionComponents, 'caseHeader');
  const clientDataJson = findByRenderType(questionComponents, 'clientDataTabs');
  const {clientDataTabs, clientDataTabsTypes} = JSON.parse(clientDataJson || '{}');
  const answerGroups = questionComponents.filter(({renderType}) => renderType.match('answer-group'));
  const answerMatrix = questionComponents.filter(({renderType}) => renderType.match('answerMatrix'));
  const hyperlinks = findByRenderType(questionComponents, 'hyperlinks', '[]');
  const dndHeaderLeft = findByRenderType(questionComponents, 'dndHeaderLeft', '');
  const dndHeaderRight = findByRenderType(questionComponents, 'dndHeaderRight', '');
  const connectedTestBlock = question.connections && question.connections.items && (question.connections.items[0] || {}).block;

  return {
    id: question.id,
    prompt,
    questionHeader,
    answers: JSON.parse(answers),
    explanation,
    caseset,
    xray,
    clinicalPhoto,
    clinicalExam,
    customExhibitName,
    customExhibit,
    citations,
    tags: question.tags.items.map(({id, tag}) => ({id, ...tag})),
    status: question.status || 'published',
    comments: question.comments ? question.comments.items : [],
    anatomyImage,
    revisionCreatedAt: createdAt,
    updatedAt,
    anatomyImageTransform: JSON.parse(anatomyImageTransform),
    blockParts: connectedTestBlock && getBlockParts(connectedTestBlock),
    revisedBy,
    relatedVideos,
    meta,
    type,
    hotSpotImageSelection: JSON.parse(hotSpotImageSelection),
    hotSpotImage,
    caseHeader,
    answerGroups,
    answerMatrix,
    clientDataTabs,
    clientDataTabsTypes,
    hyperlinks: JSON.parse(hyperlinks),
    dndHeaderLeft,
    dndHeaderRight,
    latestRevisionId,
    lastUpdated
  };
}


async function getQuestionPartsAsync(question, revisionId=null) {
  if (!question || !question.latestRevisions || !question.latestRevisions.items.length) return defaultQuestionParts;

  let revisions = getRevisions(question);
  if (revisionId && revisions.length === 1 && !revisions.find(({id}) => id === revisionId)) {
    async function fetchRevision () {
      try {
        const {data: {getQuestionRevision}} = await getRevision(revisionId);
        return getQuestionRevision;
      } catch (e) {
        Sentry.captureException({stringified: JSON.stringify(e)});
        throw e;
      }
    }
    const questionRevision = await fetchRevision(); //await asyncFetchWithS3Cache(`json/backups/questions/${question.id}/${revisionId}.json`, moment().subtract(7, 'days'), fetchRevision);
    question.latestRevisions.items = [questionRevision];
    revisions = [questionRevision]
  }

  return getQuestionParts(question, revisionId);
}

function stripPunctuation(string) {
  if (!string) return '';

  const whitelist = [','];
  return whitelist.reduce((acc, curr) => acc.replace(new RegExp(curr, 'g'), ''), string);
}

function getQuestionTitle(questionId, tags) {
  const {subject, topic} = tags.find(tag => !!tag.test && !!tag.subject && !!tag.topic) || {};
  const modifier = questionId.substr(0,5)

  const formattedSubject = stripPunctuation(subject);
  const formattedTopic = stripPunctuation(topic);

  return [
    ...(formattedSubject ? formattedSubject.split(' ') : []),
    ...(formattedTopic ? formattedTopic.split(' ') : []),
    modifier
    ].join('-').toLowerCase();
}

function getRevisions(question) {
  const revisions = (question && question.latestRevisions && question.latestRevisions.items.slice()) || [];
  return sortByDate(revisions);
}

function reorder (list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function insertAtIndex(array, index, item) {
  return [
    ...array.slice(0, index),
    item,
    ...array.slice(index + 1)
  ];
}

const getTestBlockQuestions = (testBlock, allowDraft=false) => {
  const questionConnectionItems = (!!testBlock && testBlock.questionConnections && testBlock.questionConnections.items.slice().sort(firstBy('index'))) || [];
  return questionConnectionItems.reduce((acc, {question, index, masteryLevel, bookmarked}) => {
    return question && (question.status !== 'draft' || allowDraft)
      ? acc.concat({...question, masteryLevel, bookmarked, index})
      : acc
  }, []);
}

const determineColorFromTitle = title => {
  if (title.includes('Biology')) {
    return 'blue'
  }
  if (title.includes('General Chemistry')) {
    return 'green';
  }
  if (title.includes('Organic Chemistry')) {
    return 'melon';
  }
  if (title.includes('Perceptual Ability')) {
    return 'purple';
  }
  if (title.includes('Quantitative Reasoning')) {
    return 'pink';
  }
  if (title.includes('Reading Comprehension')) {
    return 'red';
  }
  if (title.includes('Full Length')) {
    return 'blue';
  }
  if (title.includes('Physics')) {
    return 'orange';
  }
  return 'blue';
}

function getTagType(tag) {
  return !!tag.test && !tag.subject && !!tag.topic && tag.contentType
    ? 'category'
    : !!tag.test && !!tag.subject && !!tag.topic
    ? 'topic'
    : !!tag.test && !!tag.subject && !!tag.system && !tag.topic
    ? 'system'
    : !!tag.test && !!tag.subject && !tag.topic
    ? 'subject'
    : !!tag.test && !tag.subject && !tag.topic
    ? 'bootcamp'
    : null;
}

function getTestBlockFromJson(testBlock) {
  return (testBlock && JSON.parse(testBlock)) || {blockName: '', testBlockConnectionTestId: null};
};

function getInObj(path, obj, defaultValue=null) {
  if (!obj) return defaultValue;
  return path.reduce((acc, x) => (acc && acc[x]) ? acc[x] : defaultValue, obj);
}

function saveFile(data, filename){
  if(typeof data === "object"){
      data = JSON.stringify(data, null, 2)
  }

  var blob = new Blob([data], {type: 'text/json'}),
      e    = document.createEvent('MouseEvents'),
      a    = document.createElement('a')

  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  a.dataset.downloadurl =  ['text/json', a.download, a.href].join(':')
  e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}
function getFilterFields(filterType, filterFields) {
  if (!filterType || !filterFields) return {};

  const filters = JSON.parse(filterFields);

  return {[filterType]: filters[filterType]};
}


function getUnixTimestamp(date) {
  return Math.round((new Date(date)).getTime() / 1000);
}

function getMembershipExpirationDate(startDate, duration) {
  const start = new Date(startDate);
  start.setDate(start.getDate() + parseInt(duration));
  return getUnixTimestamp(start);
}

function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
      return arr
        // throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

function getStreakDetails(streak=0, streakDate='1/1/2001') {
  const current = moment();

  const today = current.hour() >= 4 ? current : moment(current).subtract(1, 'day');
  const yesterday = moment(today).subtract(1, 'day');
  
  const streakActive = moment(streakDate, 'MM/DD/YYYY').isSameOrAfter(yesterday, 'day');
  const streakCompletedToday = moment(streakDate, 'MM/DD/YYYY').isSame(today, 'day');

  const streakLength = streak > 0 && streakActive ? streak : 0;

  const tomorrow = moment(today).add(1, 'days').startOf('day').hour(4);
  const preciseHoursUntilTomorrow = tomorrow.diff(moment(), 'hours', {precision:true});
  const minutesUntilTomorrow = tomorrow.diff(moment(), 'minutes');
  const timeUntilTomorrow = preciseHoursUntilTomorrow >= 1 ? `${Math.round(preciseHoursUntilTomorrow)}h` : `${minutesUntilTomorrow}m`;

  const timeRunningOut = streak > 0 && !streakCompletedToday && (today.hour() <= 4 || today.hour() >= 20) && timeUntilTomorrow;

  return {
    today,
    streakCompletedToday,
    timeRunningOut,
    streakLength,
    streakActive
  }
}
const preventDefault = event => {
  event.preventDefault();
  event.stopPropagation();
};

const getTestBlockConnections = (test) => {
  return (!!test && !!test.blockConnections && test.blockConnections.items.slice().sort(firstBy('index'))) || [];
};

function changeIntercomDisplay (mode, selector, styles=[]) {
  const className = selector || '.intercom-launcher';
  const stylesArray = styles?.length ? styles : [`display: ${mode};`];

  const intercomLauncher = document.querySelector(className);

  if (intercomLauncher) {
    stylesArray.forEach((style) =>  intercomLauncher.style = style);
  }

};

function capitalize(string) {
  return string
    .split(' ')
    .map(part => `${part[0].toUpperCase()}${part.split('').slice(1).join('')}`)
    .join(' ');
}

function padNumber(number) {
  return number < 10 ? `0${number}` : number;
}

function findStartAndEndDates(memberships, bootcampMembershipGroup) {
  try {
    const activeMemberships = memberships?.items.filter(membership => membership.groups.includes(bootcampMembershipGroup) && membership.status === 'active');
    if (!activeMemberships || activeMemberships.length === 0) return {};

    // we need to find when this membership should start
    // based on the ultimate end date of our current memberships
    let latestEnd;
    let earliestStart;
    for (const membership of activeMemberships) {
      if (!latestEnd) latestEnd = membership;
      if (!earliestStart) earliestStart = membership;

      const membershipDate = new Date(membership.startDate);
      const membershipEndDate = membershipDate.setDate(membershipDate.getDate() + membership.duration);

      const earliestStartDate = new Date(earliestStart.startDate);
      if (membershipDate < earliestStartDate) {
        earliestStart = membership;
      }

      const endDate = new Date(latestEnd.startDate);
      const latestEndDate = endDate.setDate(endDate.getDate() + latestEnd.duration);
      if (membershipEndDate > latestEndDate) {
        latestEnd = membership;
      }
    }
    const startDate = new Date(earliestStart.startDate);
    const endDate = new Date(latestEnd.startDate);
    const latestEndDate = endDate.setDate(endDate.getDate() + latestEnd.duration);

    return {earliestStart, startDate, latestEnd, endDate: new Date(latestEndDate)};
  } catch (e) {
    console.error('findStartAndEndDates err', e);
    return {}
  }
}

function calculateAnswerPercentage(answerData, answerLetter, answerCorrect, noLimit=false) {
  if (!answerData) return;
  try {
    const answerTuples = Object.entries(answerData);
    const totalAnswers = answerTuples.reduce((acc, [name, count]) => acc += count, 0);

    // short circuit percentage calculation if has been answered fewer than 50 times
    if (totalAnswers < 10 && !noLimit) return 0;

    const answeredCorrectly = answerData[answerCorrect ? `${answerLetter} (CORRECT)` : answerLetter]

    const correctPercentage = ((answeredCorrectly || 0) / totalAnswers) * 100;

    return Math.round(correctPercentage) || 0;

  } catch (e) {
    console.log(e)
    return 0;
  }
}

function calculateNCLEXAnswerPercentage(answerData, answerState) {
  return '20%';
  // placeholder, we'll need to work on an nclex specific answer percentage calculation per question type
}

async function asyncFetchWithS3Cache (s3Key, cacheExpiration, fetchFunction) {

  try {

    const existingDataFetch = await Storage.get(s3Key, {
      download: true,
      cacheControl: 'no-cache',
      contentType: 'application/json',
    });

    if (moment(existingDataFetch.LastModified).isBefore(cacheExpiration)) throw new Error();

    return await new Response(existingDataFetch.Body).json();
  } catch (e) {
    const result = await fetchFunction();

    await Storage.put(s3Key, JSON.stringify(result), {contentType: 'application/json'});

    return result;
  }
}

export function getTestsByClassroom(classrooms, filterArray=[], contentTypeFilterArray=[], full=false, contentTypeNameFilterArray=[]) {
  return classrooms
    ?.filter(({name}) => !filterArray.includes(name))
    ?.reduce((acc, {route, contentTypes}) => ({
      ...acc,
        [route]: contentTypes
          ?.filter(({name}) => !contentTypeNameFilterArray.length ? true : contentTypeNameFilterArray.includes(name))
          ?.reduce((acc, {content}) => [
            ...acc,
            ...content
              ?.filter(({type}) => !contentTypeFilterArray.length ? true : contentTypeFilterArray.includes(type))
              ?.reduce((acc, {content}) => [
                  ...acc,
                  ...content
                    ?.filter(({id, lessons}) => !!id || !!lessons)
                    ?.reduce((acc, {id, lessons, ...rest}) => lessons ? [...acc, ...lessons.map(({testId, id}) => testId || id)] : [...acc, full ? {id, ...rest} : id] , []) || []
            ], [])
          ], [])
    }), {});
}

function formatDurationString(duration) {
  if (!duration) return '';
  // duration passed as XmXs eg. 12m13s
  if (!duration.includes('m')) return `0:${duration.replace('s', '').padStart(2, 0)}`;

  const [minutes, seconds] = duration?.replace('s', '').split('m');
  return `${minutes}:${seconds.padStart(2, 0)}`;
}

function formatTimeSpent(time) {
  const duration = moment.duration(time * 1000);

  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`;
  if (minutes > 0) return `${minutes}m ${seconds}s`;

  return `${seconds}s`;
};

function flatten(arr) {
  let flattenArr = [];
  arr.forEach(el => {
    if(Array.isArray(el)){
      const result = flatten(el);
      result.forEach(el => flattenArr.push(el));
    } else {
      flattenArr.push(el);
    }
  });
  return flattenArr;
}

function findInConfigById(config, targetId, useParentTitle) {
  // recursively search through config looking for id
  try {
    // check current iteration's config object for an id match
    const targetMatch = config?.id === targetId || config?.testId === targetId;

    // // return with content name on match
    if (targetMatch) {
      return config;
    };

    // otherwise try to derive a target array out of the current config's properties (this may need to be expanded)
    const targetArray = config?.classrooms || config?.contentTypes || config?.content || config?.lessons;

    const useParent = ['Chapter Bank Dropdown'].includes(config?.type);
    // attempt to reduce over current iteration's config
    return targetArray?.reduce((acc, currentConfig) => {
      if (acc) {
        return acc;
      } else {
        const hit = findInConfigById(currentConfig, targetId, useParent);

        if (hit) {
          return flatten([hit, currentConfig]);
        }

        return hit;
      }
    }, '');
  } catch (error) {
    console.log('error finding in config', error);
  }
}

async function getBootcampConfig (bootcamp) {
  async function fetchConfig (bootcamp) {
    const fetchedConfig = await fetch(`https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com/public/json/config/${bootcamp}.json`, {cache: "no-store"});
    return await fetchedConfig.json();
  }
  switch (bootcamp) {
    case 'dat':
    case 'oat':
    case 'inbde':
    case 'nclex':
      return await fetchConfig(bootcamp);
    case 'anatomy': {
      const [anatomyConfig, dentalSchoolConfig] = await Promise.all(['anatomy', 'dental-school'].map(fetchConfig));
      anatomyConfig.classrooms.push(dentalSchoolConfig.classrooms.find(({name}) => name === 'Dental Anatomy and Occlusion'));
      return anatomyConfig;
    }
    case 'dental-school': {
      const [anatomyConfig, dentalSchoolConfig] = await Promise.all(['anatomy', 'dental-school'].map(fetchConfig));
      dentalSchoolConfig.classrooms.push(...anatomyConfig.classrooms);
      return dentalSchoolConfig;
    }
    case 'med-school': {
      try {
        const [anatomyConfig, medSchoolConfig] = await Promise.all(['anatomy', 'med-school'].map(fetchConfig))
        const medicalAnatomy = anatomyConfig.classrooms.filter(({name}) => !['Anatomy and Physiology'].includes(name))
        medSchoolConfig.classrooms.push(...medicalAnatomy);
        return medSchoolConfig;
      } catch (e) {
        console.log(e);
      } finally {

      }
    }
    case 'chemistry':
      return ChemistryConfig;
    default:
      return {classrooms: [], meta: {}};
  }
}

function getNCLEXSubjectSystemTags(tags) {
  try {
    // subject tag
    const subjectTag = tags?.find(tag => tag.contentType == "nclexSubject") || {};
    // system tag
    const systemTag = tags?.find(tag => !!tag.test && !!tag?.subject && !!tag.system && !tag.topic && !tag.contentType) || {};
    return {subject: subjectTag?.id, system: systemTag?.id, subjectTag, systemTag};
  } catch (error) {
    console.log(error)
    return {};
  }
}

function getScorePercentage(questionProgresses, overridingMaxScore) {
  const {score, maxScore} = questionProgresses.reduce((acc, curr) => {
    const answerState = JSON.parse(curr?.answerState || "{}");
    return {
      score: acc.score + (answerState.score || (curr.didSelectCorrectAnswer && 1) || 0),
      maxScore: acc.maxScore + (answerState.maxScore || 1)
    }
  }, {score: 0, maxScore: 0});

  return `${Math.round(((score / (overridingMaxScore || maxScore)) || 0) * 100)}%`;
}

const retrieveQuestionProgresses = testData => {
  const {blockProgresses: {items: blockProgressItems}} = testData || {blockProgresses: {items: []}};
  return blockProgressItems.reduce((acc, blockProgress) => [...acc, ...(blockProgress.questionProgresses.items || [])], []);
}

function getCustomTestTableData (testData, testResults, testName) {
  const type = (() => {
    switch (testData.config.type) {
      case 'preclinical':
        return 'Step 1 - Boards Style';
      case 'bites':
        return 'Bites';
      case 'anatomy':
        return 'Anatomy Bootcamp';
      default:
        return 'Custom';
    }
  })()
  const {createdAt, maxScore} = testData.config;
  const dateTaken = moment(createdAt).format('MMM Do, YYYY');
  const settings = `${testData.config.timed ? 'Timed' : 'Untimed'}, ${testData.config.tutorMode ? 'Tutored' : 'Untutored'}`
  const questions = testData.selectedQuestions.length;
  const {customTitle} = testData.config;

  const {data: {TestProgressByTestId: {items}}} = testResults;
  const {submitted} = testData || {};
  let score;
  if (items[0]) {
    const questionProgresses = retrieveQuestionProgresses(items[0]);
    score = submitted ? getScorePercentage(questionProgresses, maxScore) : 'Paused';
  }

  return {testData, testResults, type, dateTaken, settings, testName: customTitle || testName, score, questions};
}

function getMaxScore(question) {
  const {prompt, type, answers, answerGroups, answerMatrix} = getQuestionParts(question);

  return calculateMaxScore(prompt, type, answers, answerGroups, answerMatrix);
}

async function getMasteryMap(questions, userId) {
  try {
    const questionMasteryPromises = questions.map(({question}) => getAllQuestionMastery({userIdHashQuestionBaseId: `${userId}#${question.id}`}));
    const userQuestionMasteryResult = await Promise.all(questionMasteryPromises);
    const masteryMap = userQuestionMasteryResult.reduce((acc, {data: {getQuestionMastery: questionMastery}}) => questionMastery ? {...acc, [questionMastery.userIdHashQuestionBaseId.replace(`${userId}#`, '')]: questionMastery} : acc, {});
    return masteryMap;
  } catch (error) {
    return {};
  }
}

function getTags(bootcamp, tags) {
  switch (bootcamp) {
    case 'nclex':
      return [
        // subject tag
        tags.find(({tag}) => !!tag.test && !!tag?.subject && !tag.topic && tag.contentType === 'nclexSubject')?.tag?.subject,
        // system tag
        tags.find(({tag}) => !!tag.test && !!tag?.subject && !!tag.system && !tag.topic && !tag.contentType)?.tag?.system
      ];
    default:
      return [
        // subject tag
        tags.find(({tag}) => !!tag.test && !!tag?.subject && !tag.topic)?.tag?.subject,
        // system tag
        tags.find(({tag}) => !!tag.test && !!tag?.subject && !!tag.topic)?.tag?.topic
      ];
  }
}

function getScore(bootcamp, progress) {
  switch (bootcamp) {
    case 'nclex':
      let {score, maxScore} = JSON.parse(progress?.answerState || '{}');

      return {
        score: score || 0,
        maxScore: maxScore || getMaxScore(progress?.question),
      };
    default:
      return {
        score: progress.didSelectCorrectAnswer ? 1 : 0,
        maxScore: 1
      };
  }
}

async function formatScoringBreakdown(progress, config, userId, bootcamp) {
  if (!progress) return {};

  const questions = progress?.blockProgresses?.items?.reduce((acc, curr) => ([...(curr?.questionProgresses?.items || []), ...acc]), []);
  const masteryMap = await getMasteryMap(questions, userId);

  const breakdowns = questions
    ?.sort(firstBy(progress => progress?.index))
    ?.reduce((acc, curr, index) => {
      const isSequential = config?.selectedQuestions?.[index]?.isSequentialSet;
      const isSequentialStart = config?.selectedQuestions?.[index]?.isSequentialStart;
      const isSequentialEnd = config?.selectedQuestions?.[index]?.isSequentialEnd;

      const questionId = curr?.question?.id;

      const [subject, system] = getTags(bootcamp, curr?.question?.tags?.items);

      const masteryLevel = masteryMap[questionId]?.masteryLevel;
      const bookmarked = masteryMap[questionId]?.bookmarked;
      const timeSpent = parseFloat(curr?.time || 0);

      const {score, maxScore} = getScore(bootcamp, curr);

      const defaultStatObj = {score: 0, maxScore: 0, timeSpent: 0, totalQuestions: 0, avgTime: 0, avgScore: 0};

      const subjectStats = acc.subjectBreakdown?.[subject] || defaultStatObj;
      const systems = subjectStats?.systems || {};
      const systemStats = systems?.[system] || defaultStatObj;

      const caseIndex = !isSequential ? null : isSequentialStart ? questionId : acc.caseIndex
      const caseStats = acc?.caseBreakdown?.[caseIndex] || defaultStatObj;
      const caseQuestionStats = caseStats?.questions || {};

      const questionStatistics = JSON.parse(curr?.question?.statistics || '{"summedScore": "2", "totalRecords": "5", "timeSpent2": "0"}');
      const questionAvg = parseInt(questionStatistics?.summedScore) / parseInt(questionStatistics?.totalRecords || '1');
      const questionAvgTime = parseInt(questionStatistics?.timeSpent2) / parseInt(questionStatistics?.totalRecordsTimeReset || '1');
      const questionSnippet = isSequential
        ? curr?.question?.latestRevisions?.items?.[0]?.questionComponents?.find(({renderType}) => renderType === 'caseHeader')
        : '';

      return {
        ...acc,
        overview: {
          ...acc.overview,
          score: acc.overview.score + score,
          maxScore: acc.overview.maxScore + maxScore,
          avgScore: acc.overview.avgScore + questionAvg,
          avgTime: acc.overview.avgTime + questionAvgTime,
          totalTime: acc.overview.totalTime + timeSpent,
          totalQuestions: index + 1,
        },
        subjectBreakdown: {
          ...acc.subjectBreakdown,
          [subject]: {
            ...subjectStats,
            score: subjectStats.score + score,
            maxScore: subjectStats.maxScore + maxScore,
            avgScore: subjectStats.avgScore + questionAvg,
            timeSpent: subjectStats.timeSpent + timeSpent,
            avgTime: subjectStats.avgTime + questionAvgTime,
            totalQuestions: subjectStats.totalQuestions + 1,
            mastery: {
              ...subjectStats?.mastery,
              [masteryLevel]: (subjectStats?.mastery?.[masteryLevel] || 0) + 1
            },
            systems: {
              ...systems,
              [system]: {
                ...systemStats,
                score: systemStats.score + score,
                maxScore: systemStats.maxScore + maxScore,
                avgScore: systemStats.avgScore + questionAvg,
                timeSpent: systemStats.timeSpent + timeSpent,
                totalQuestions: systemStats.totalQuestions + 1,
                mastery: {
                  ...systemStats?.mastery,
                  [masteryLevel]: (systemStats?.mastery?.[masteryLevel] || 0) + 1
                }
              }
            }
          }
        },
        caseIndex,
        caseBreakdown: {
          ...acc.caseBreakdown,
          ...(isSequential
            ? {
              [caseIndex]: {
                ...caseStats,
                questionSnippet: caseStats.questionSnippet || questionSnippet,
                index: caseStats.hasOwnProperty('index') ? caseStats.index : index,
                score: caseStats.score + score,
                maxScore: caseStats.maxScore + maxScore,
                timeSpent: caseStats.timeSpent + timeSpent,
                subject: caseStats?.subject || subject,
                system: caseStats?.system && caseStats?.system !== system
                  ? 'Multi-system'
                  : system,
                questions: {
                  ...caseQuestionStats,
                  [questionId]: {
                    index,
                    score,
                    maxScore,
                    masteryLevel,
                    bookmarked,
                    subject,
                    system,
                    timeSpent,
                  }
                }
              }
            } : {}
          )
        },
        questionBreakdown: {
          ...acc.questionBreakdown,
          ...(!isSequential
            ? {
              [questionId]: {
                index,
                score,
                maxScore,
                masteryLevel,
                bookmarked,
                subject,
                system,
                timeSpent,
              }
            } : {}
          )
        }
      };
    }, {
      overview: {
        score: 0,
        maxScore: 0,
        avgScore: 0,
        avgTime: 0,
        totalTime: 0,
        totalQuestions: 0,
      },
      caseIndex: null,
      subjectBreakdown: {},
      caseBreakdown: {},
    });

  return breakdowns;
}

async function formatReadinessExamScoringBreakdown(progress, config, userId, bootcamp) {
  if (!progress) return {};

  const questions = progress?.blockProgresses?.items?.reduce((acc, curr) => ([...(curr?.questionProgresses?.items || []), ...acc]), []);
  const questionMap = progress?.blockProgresses?.items?.reduce((acc, curr) => ({...(curr?.questionProgresses?.items?.reduce((acc2, curr2) => ({...acc2, [curr2?.question?.id]: {...curr2, blockId: curr?.blockId}}), {}) || {}), ...acc}), {});
  const masteryMap = await getMasteryMap(questions, userId);

  const breakdowns = config?.selectedQuestions
    ?.map(id => questionMap[id])
    ?.reduce((acc, curr, index) => {
      const isSequential = curr?.isSequentialSet;
      const isSequentialStart = curr?.isSequentialStart;
      const isSequentialEnd = curr?.isSequentialEnd;
      if (!curr?.question) {
        return acc;
      }
      const questionId = curr?.question?.id;

      const [subject, system] = getTags(bootcamp, curr?.question?.tags?.items);

      const masteryLevel = masteryMap[questionId]?.masteryLevel;
      const bookmarked = masteryMap[questionId]?.bookmarked;
      const timeSpent = parseFloat(curr?.time || 0);
      const blockId = curr.blockId;

      const {score, maxScore} = getScore(bootcamp, curr);

      const defaultStatObj = {score: 0, maxScore: 0, timeSpent: 0, totalQuestions: 0, avgTime: 0, avgScore: 0};

      const subjectStats = acc.subjectBreakdown?.[subject] || defaultStatObj;
      const systems = subjectStats?.systems || {};
      const systemStats = systems?.[system] || defaultStatObj;

      const caseIndex = !isSequential ? null : isSequentialStart ? questionId : acc.caseIndex
      const caseStats = acc?.caseBreakdown?.[caseIndex] || defaultStatObj;
      const caseQuestionStats = caseStats?.questions || {};

      const questionStatistics = JSON.parse(curr?.question?.statistics || '{"summedScore": "2", "totalRecords": "5", "timeSpent2": "0"}');
      const questionAvg = parseInt(questionStatistics?.summedScore) / parseInt(questionStatistics?.totalRecords || '1');
      const questionAvgTime = parseInt(questionStatistics?.timeSpent2 || '1') / parseInt(questionStatistics?.totalRecordsTimeReset || '1');
      const questionSnippet = isSequential
        ? curr?.question?.latestRevisions?.items?.[0]?.questionComponents?.find(({renderType}) => renderType === 'caseHeader')
        : '';

      return {
        ...acc,
        overview: {
          ...acc.overview,
          score: acc.overview.score + score,
          maxScore: acc.overview.maxScore + maxScore,
          avgScore: acc.overview.avgScore + questionAvg,
          avgTime: acc.overview.avgTime + questionAvgTime,
          totalTime: acc.overview.totalTime + timeSpent,
          totalQuestions: index + 1,
        },
        subjectBreakdown: {
          ...acc.subjectBreakdown,
          [subject]: {
            ...subjectStats,
            score: subjectStats.score + score,
            maxScore: subjectStats.maxScore + maxScore,
            avgScore: subjectStats.avgScore + questionAvg,
            avgTime: subjectStats.avgTime + questionAvgTime,
            timeSpent: subjectStats.timeSpent + timeSpent,
            totalQuestions: subjectStats.totalQuestions + 1,
            mastery: {
              ...subjectStats?.mastery,
              [masteryLevel]: (subjectStats?.mastery?.[masteryLevel] || 0) + 1
            },
            systems: {
              ...systems,
              [system]: {
                ...systemStats,
                score: systemStats.score + score,
                maxScore: systemStats.maxScore + maxScore,
                avgScore: systemStats.avgScore + questionAvg,
                timeSpent: systemStats.timeSpent + timeSpent,
                totalQuestions: systemStats.totalQuestions + 1,
                mastery: {
                  ...systemStats?.mastery,
                  [masteryLevel]: (systemStats?.mastery?.[masteryLevel] || 0) + 1
                }
              }
            }
          }
        },
        caseIndex,
        caseBreakdown: {
          ...acc.caseBreakdown,
          ...(isSequential
            ? {
              [caseIndex]: {
                ...caseStats,
                questionSnippet: caseStats.questionSnippet || questionSnippet,
                index: caseStats.hasOwnProperty('index') ? caseStats.index : index,
                score: caseStats.score + score,
                maxScore: caseStats.maxScore + maxScore,
                timeSpent: caseStats.timeSpent + timeSpent,
                subject: caseStats?.subject || subject,
                blockId,
                system: caseStats?.system && caseStats?.system !== system
                  ? 'Multi-system'
                  : system,
                questions: {
                  ...caseQuestionStats,
                  [questionId]: {
                    index,
                    score,
                    maxScore,
                    masteryLevel,
                    bookmarked,
                    subject,
                    system,
                    timeSpent,
                    blockId,
                  }
                }
              }
            } : {}
          )
        },
        questionBreakdown: {
          ...acc.questionBreakdown,
          ...(!isSequential
            ? {
              [questionId]: {
                index,
                score,
                maxScore,
                masteryLevel,
                bookmarked,
                subject,
                system,
                timeSpent,
                blockId
              }
            } : {}
          )
        }
      };
    }, {
      overview: {
        score: 0,
        maxScore: 0,
        avgScore: 0,
        avgTime: 0,
        totalTime: 0,
        totalQuestions: 0,
      },
      caseIndex: null,
      subjectBreakdown: {
      },
      caseBreakdown: {},
    });

  return breakdowns;
}

export async function updateCustomTestConfig(customTestConfig, values) {
  const testStorageKey = customTestConfig?.config?.testStorageKey;
  if (!testStorageKey) return;

  try {
    const updatedConfig = {...customTestConfig, ...values};

    await Storage.put(testStorageKey, JSON.stringify(updatedConfig), {contentType: 'application/json'});

    return updatedConfig
  } catch (error) {
    console.log('error updating custom test config', error);
  }
}
// when we're creating a test for a free user, we're matching the testIds that were used to create the test, with the
// test id's in the free classroom - if there is a mismatch there, that means a free user is trying to load a test
// with premium content. From there we'll set the test as 'invalid' and the create test page will throw an error
// resulting in a 404 redirect
export const validateCustomTest = (config, isUpgraded, freeTestIds) => {
  if (isUpgraded) return true;

  const freeClassroom = config
    ?.classrooms
    ?.find(({route}) => route === 'free-questions')
    ?.contentTypes?.[0]?.content?.[0]?.content.every(({id}) => freeTestIds.indexOf(id) > -1);

  const freeContent = config
    ?.additionalContent
    ?.find(({name}) => name === 'Free Tests')
    ?.testIds?.every(id => freeTestIds.indexOf(id) > -1);

  return freeClassroom || freeContent;
}

export const clampValue = (value, max) => {
  const numberValue = parseInt(value);
  return Math.floor(Math.min(Math.max(1, numberValue), max)) || 1;
};

export const updateStreak = async (bootcamp, currentUser, interactions) => {
  try {
    const interactionKey = `Streak-DWU-${bootcamp}`;
    const {streak, longestStreak, date: streakDate} = JSON.parse((interactions?.find(interaction => interaction.id === `${currentUser?.username}-${interactionKey}`) || {})?.value || '{}');
    const {today, streakCompletedToday, streakActive} = getStreakDetails(streak, streakDate);
    const streakData = {
      streak: streak > 0 && streakActive ? streak + 1 : 1,
      date: today.format('M/D/YYYY'),
      longestStreak: streak + 1 > longestStreak && streakActive ? streak + 1 : (longestStreak || streak + 1)
    }
    if (!streakCompletedToday) {
      await updateUserInteraction({id: `${currentUser?.username}-${interactionKey}`, value: JSON.stringify(streakData)});
      // update interaction
      const updatedInteraction = interactions.find(interaction => interaction.id === `${currentUser?.username}-${interactionKey}`);
      updatedInteraction.value = JSON.stringify(streakData);
      interactions = interactions.map(interaction => interaction.id === `${currentUser?.username}-${interactionKey}` ? updatedInteraction : interaction);
    }

    return interactions;
  } catch (error) {
    console.log('error updating streak', error); r
  }
}
export async function getUserInteractions(username) {
  try {
    const data = await listUserInteractions(username);
    return getInObj(['data', 'UserId', 'items'], data, []);
  } catch (error) {
    console.log('error fetching user interactions', error);
    return [];
  }
}



export {
  getMasteryMap,
  getScore,
  getTags,
  formatScoringBreakdown,
  formatReadinessExamScoringBreakdown,
  getCustomTestTableData,
  defaultQuestionParts,
  getInObj,
  capitalize,
  findInConfigById,
  shuffle,
  findByRenderType,
  getQuestionParts,
  getQuestionPartsAsync,
  getQuestionTitle,
  getQuestionType,
  getRevisions,
  reorder,
  insertAtIndex,
  getTestBlockQuestions,
  slugify,
  deSlugify,
  determineColorFromTitle,
  keys,
  getTagType,
  getTestBlockFromJson,
  saveFile,
  getUnixTimestamp,
  getMembershipExpirationDate,
  getRandom,
  getStreakDetails,
  preventDefault,
  parseVideoDuration,
  getFilterFields,
  getTestBlockConnections,
  changeIntercomDisplay,
  padNumber,
  findStartAndEndDates,
  getFormattedStripeAmount,
  asyncFetchWithS3Cache,
  calculateAnswerPercentage,
  calculateNCLEXAnswerPercentage,
  formatDurationString,
  formatTimeSpent,
  getBootcampConfig,
  getNCLEXSubjectSystemTags,
  delay,
  getRuntime
};
