import React from 'react';

import MasteryReview from '../../components/MasteryReview';

import {useMastery} from '../../../hooks';
import {useUserDataContext} from '../../../contexts/UserData';

import config from '../config';

import styled from 'styled-components';

const Container = styled.div`
  max-width: 1152px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

function formatCardLink(baseUrl, subjectRoute, masteryLevel) {
  return subjectRoute
    ? `${baseUrl}?subject=${subjectRoute}&masteryLevel=${masteryLevel}`
    : `${baseUrl}?masteryLevel=${masteryLevel}`
}

function formatClassroomMastery({data}, masteryType) {
  const queryLookup = [masteryType[0].toUpperCase(), ...masteryType.slice(1)].join('');
  const mastery = data
    && data[`QuestionMasteryBy${queryLookup}TagId`]
    && data[`QuestionMasteryBy${queryLookup}TagId`].items;

  if (!mastery) return [];

  return mastery.filter(({masteryLevel}) => !!masteryLevel && masteryLevel !== 'none').reduce((acc, {masteryLevel}) => ({...acc, [masteryLevel]: acc[masteryLevel] + 1, total: acc.total + 1}), {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    total: 0,
  });
}

const MasteryReviewSelect = props => {
  const {IS_OAT_STUDENT} = useUserDataContext();

  const [bioProgress] = useMastery(config.classrooms[0].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [gcProgress] = useMastery(config.classrooms[1].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [ocProgress] = useMastery(config.classrooms[2].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [paProgress] = useMastery(config.classrooms[3].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [qrProgress] = useMastery(config.classrooms[4].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  // const [rcProgress] = useMastery(config.classrooms[5].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [physProgress] = useMastery(config.classrooms[6].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));

  const progressMap = {
    'Biology': bioProgress,
    'General Chemistry': gcProgress,
    'Organic Chemistry': ocProgress,
    'Perceptual Ability': paProgress,
    // 'Reading Comprehension': rcProgress,
    'Quantitative Reasoning': qrProgress,
    'Physics': physProgress
  };

  const classroomMasteryConfig = config.classrooms
    .filter(({locked}) => !locked)
    .filter(({name}) => IS_OAT_STUDENT ? name !== 'Perceptual Ability' : name !== 'Physics')
    .map(({name}) => ({name, route: name, progress: progressMap[name]}));

  return (
    <Container>
      <MasteryReview
        config={classroomMasteryConfig}
        loading={!bioProgress && !gcProgress && ocProgress && !paProgress && !qrProgress && !physProgress}
        baseUrl={'/review-bank'}
        formatCardLink={formatCardLink}
        inRouter={false}
        withHeader={false}
        datStyling
        title={'Bootcamp Mastery Review'}
        />
    </Container>
  );
}

MasteryReviewSelect.propTypes = {};
MasteryReviewSelect.defaultProps = {};

export default MasteryReviewSelect;
