import React from 'react';
import styled from 'styled-components';
import {useUserDataContext} from '../../../contexts/UserData';
import {Row} from '../../../components/Grid';

const AdminRow = styled(Row)`
  padding: 10px 0px;
  height: auto;
`;
const AdminLink = styled.a.attrs({target: '_blank'})`
  margin-right: 25px;
  text-decoration: none;
  color: ${({theme}) => theme.brandBlue};
`;

const Alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

const AdminButtons = ({questionId, correctAnswerIndex}) => {
  const {HAS_EDIT_CAPABILITY, bootcamp} = useUserDataContext();

  // NOTE hiding admin buttons in mcat for now b/c they'll be recording videos soon
  if (!HAS_EDIT_CAPABILITY || !questionId || ['mcat']?.includes(bootcamp)) return null;

  return (
    <AdminRow>
      <AdminLink href={`https://admin.bootcamp.com/questions/${questionId}?preview=true`}>View this Question</AdminLink>
      <AdminLink href={`https://admin.bootcamp.com/questions/${questionId}`}>Edit this Question</AdminLink>
      <div>Correct Answer: {Alphabet[correctAnswerIndex] || correctAnswerIndex}</div>
    </AdminRow>
  )
}

export default AdminButtons;
