import React, {useState, useEffect, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';

import VideoPlayer from '../../../../components/VideoPlayer';
import {IntercomAPI} from 'react-intercom';

import AdminButtons from '../../../../components/QuestionView/components/AdminButtons';
import {TagLinks} from '../../../../components/Tags';
import {Column} from '../../../../components/Grid';
import {H3, H4, Body2} from '../../../../components/Typography';

import styled from 'styled-components';
import {determineColorFromTitle} from '@bootcamp/shared/src/util';

import {CheckCircle} from '@styled-icons/material-rounded/CheckCircle';
import {useModalContext} from '../../../../contexts/Modal';
import QuestionFAQs from '../../../../components/Modal/components/QuestionFAQs';

const ChoiceContainer = styled(Body2)`
  padding: 12px ${({theme}) => theme.layouts.spacing.ml};
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${({isCorrect}) => isCorrect && 'bold'};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  user-select: none;
  text-decoration: ${({isCrossed}) => isCrossed ? 'line-through' : 'none'};
  opacity: ${({isCrossed}) => isCrossed ? 0.6 : 1};
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  }
  cursor: pointer;

  ${({checked, theme}) => checked &&
    `
      background: ${theme.colors.brandPalette.blue.light};
      border: 1px solid ${theme.colors.brandPalette.blue.default};
    `
  }

  ${({isCorrect, theme}) => isCorrect &&
    `
      background: ${theme.colors.interfacePalette.green.light};
      border: 1px solid ${theme.colors.interfacePalette.green.default};
    `
  }
  ${({isIncorrect, theme}) => isIncorrect &&
    `
      background: ${theme.colors.interfacePalette.red.light};
      border: 1px solid ${theme.colors.interfacePalette.red.default};
    `
  }
`;


// const Option = styled.input.attrs({ type: 'radio', name: 'answer' })`
//   cursor: pointer;
// `;

const OptionOuter = styled.span`
  width: 13px;
  height: 13px;
  padding: 1px;
  border-radius: 7px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};

  ${({theme, checked}) => checked
    ? `
        border: 1px solid ${theme.colors.brandPalette.blue.default};
      `
    : `
        border: 1px solid ${theme.colors.neutralsPalette.light};
      `
  }

  ${({theme, isIncorrect}) => isIncorrect && `
      border: 1px solid ${theme.colors.interfacePalette.red.default};
    `
  }
`;
const OptionInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${({theme, checked}) => checked ? theme.colors.brandPalette.blue.default : 'none'};

    ${({theme, isIncorrect}) => isIncorrect && `
      background: ${theme.colors.interfacePalette.red.default};
    `
  }
`;
const OptionChecked = styled(CheckCircle)`
  width: 13px;
  height: 13px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  fill: ${({theme}) => theme.colors.interfacePalette.green.default};
`;

const Option = ({showingExplanation, checked, isCorrect, isIncorrect, onClick}) => {
  return isCorrect && showingExplanation
    ? <OptionChecked/>
    : <OptionOuter checked={checked} onClick={onClick} isIncorrect={isIncorrect} isCorrect={isCorrect}>
        <OptionInner checked={checked} isIncorrect={isIncorrect} isCorrect={isCorrect}/>
      </OptionOuter>
}

const LabelText = styled.span`
  * {
    max-width: 100%;
    font-size: 16px !important;
    display: inline;
  }
  p {
    margin: 0;
  }
  img {
    height: auto;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-dib, .fr-dii {
    margin: 0 !important;
    display: inline !important;
    vertical-align: middle !important;
  }
  max-width: 100%;
  font-size: 16px !important;
  display: inline;
`;

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const AnswerChoice = ({answer, isCrossed, isSelected, setSelectedAnswerIndex, updateCrossedAnswerIndexes, showingExplanation, answerIndex}) => {
  const [answerText, answerCorrect] = answer;

  const isCorrect = answerCorrect && showingExplanation;
  const isIncorrect = !answerCorrect && isSelected && showingExplanation;

  function handleContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    updateCrossedAnswerIndexes();
  }

  const answerLetter = alphabet[answerIndex];

  const letteredAnswerText = `${answerLetter}. ${answerText}`;

  return (
    <ChoiceContainer
      as={'label'}
      isCorrect={isCorrect}
      isIncorrect={isIncorrect}
      isCrossed={isCrossed}
      checked={isSelected}
      onContextMenu={handleContextMenu}
      onClick={() => {
        !showingExplanation && setSelectedAnswerIndex();
        !showingExplanation && isCrossed && updateCrossedAnswerIndexes();
      }}
      >
      <Option checked={isSelected} isCorrect={isCorrect} isIncorrect={isIncorrect} showingExplanation={showingExplanation}/>
      {useMemo(() => <LabelText className={'fr-view'} dangerouslySetInnerHTML={{__html: letteredAnswerText}} />, [letteredAnswerText])}
    </ChoiceContainer>
  )
}

const AnswerBoxContainer = styled.form`
  clear: both;
  width: 100%;
`;

const AnswerBox = ({
  answers,
  selectedAnswerIndex,
  setSelectedAnswerIndex,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
}) => {

  return (
    <AnswerBoxContainer>
      {answers.map((answer, index) => {
        return (
          <AnswerChoice
            key={`answerindex-${index}`}
            setSelectedAnswerIndex={() => setSelectedAnswerIndex(index)}
            updateCrossedAnswerIndexes={() => updateCrossedAnswerIndexes(index)}
            isSelected={selectedAnswerIndex === index}
            isCrossed={crossedAnswerIndexes.indexOf(index) > -1}
            answer={answer}
            showingExplanation={showingExplanation}
            answerIndex={index}
          />
        )
      })}
    </AnswerBoxContainer>
  )
}


const Container = styled(Column)`
  transform: translate3d(0,0,0);
  padding: 1em 0;
  align-items: flex-start;
  height: auto;
  padding-bottom: 150px;
`;
const Prompt = styled(H3)`
  margin: 1em 0px;
  font-weight: ${({theme}) => theme.font.weight.normal};

`;
const Solution = styled.div`
  padding: 18px;
  border: 1px solid rgb(237, 237, 240);
  border-radius: 8px;
  * {
    max-width: 100%;
    font-size: 16px !important;
    line-height: 1.6em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0;
  }
  p {
    margin: 0;
  }
  ol, ul {
    margin: 1em 0;
    padding-left: 40px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  hr {
    margin: 40px 0 30px;
    border: 0;
    border-bottom: 1px solid #eee;
    height: 1px;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  max-width: 100%;
  font-size: 16px !important;
  line-height: 1.6em;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  img {
    height: auto;
  }
  blockquote.callout {
    padding: 15px;
    margin: 0 0 1em 0;
    font-family: 'proxima-nova', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #F9f9f9;
    color: #333;
    border-left: solid 5px #0F6292;
  }
  blockquote.callout p {
     margin-bottom: 0 !important;
  }
`;
const SolutionBox = styled.div`
  width: 100%;
  position: relative;
  margin: 32px 0 28px;
`;
const SolutionText = styled(Body2)`
  color: ${({correct}) => correct ? '#7BD46F' : '#FB7E7B'};
  font-weight: bold;
  margin-bottom: 15px;
`;
const TopicText = styled.span`
  display: block;
  font-weight: 600;
  margin: 32px 0;
`;
const IntercomButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const IntercomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: .75em;
  border: 1px solid #e1e8ed;
  border-radius: 1em;
  margin-right: 2em;
  color: #777;
  text-decoration: none;
  flex-grow: 1;
  text-align: left;
  font: 400 16px;
  cursor: pointer;
  margin-right: 2em;
  &:last-child {
    margin-right: 0;
  }
  opacity: .75;
  transition: opacity .4s;
  &:hover {
    opacity: 1;
    transition: opacity .4s;
  }
`;


function getQuestionReviewUrl(questionId, answerIndex) {
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;
  // const port = window.location.port ? `:${window.location.port}` : '';
  // const route = 'tbc-question-review'; // to load question review route for tbc locally use /question-review instead
  return `https://admin.bootcamp.com/tbc-question-review/?questionId=${questionId}&answerIndex=${answerIndex}`
}

const SolutionContainer = ({solution, didSelectCorrectAnswer, didNotAnswer, selectedAnswerIndex, showFeedback, questionId, tags, didMark, time, solutionRef, questionSetExplanation}) => {
  const {subject, topic} = tags.find(tag => !!tag.topic) || {};
  const questionReviewUrl = getQuestionReviewUrl(questionId, selectedAnswerIndex);
  const {modalDispatch} = useModalContext();
  return (
    <SolutionBox>
      <SolutionText correct={didSelectCorrectAnswer}>{didNotAnswer ? 'Not Answered' : didSelectCorrectAnswer ? 'Correct' : 'Incorrect'}</SolutionText>
      <Solution ref={solutionRef} className={'fr-view'} dangerouslySetInnerHTML={{__html: solution + questionSetExplanation}} />
      {topic && <TopicText>Topic: {topic}</TopicText>}
      {showFeedback && (
        <IntercomButtons>
          <IntercomButton onClick={() => {
            try {
              modalDispatch({type: 'open', component: QuestionFAQs, componentProps: {questionId, intercomQuestionString: `I have content questions or feedback on ${subject} ${questionReviewUrl}. Specifically:`}, enableClickClose: true, modalContainerStyle: theme => `
                align-items: flex-start;
                background: transparent;
                justify-content: flex-end;
                z-index: 20;
              `})
            } catch (e) {
              IntercomAPI('showNewMessage', `I have content questions or feedback on ${subject} ${questionReviewUrl}. Specifically:`)
            }
          }}>
            I have questions or feedback about this explanation
          </IntercomButton>
        </IntercomButtons>
      )}
    </SolutionBox>
  );
}

const QuestionTitle = styled(Body2)`
  font-weight: bold;
  color: ${({color}) => color};
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${({theme}) => theme.layouts.spacing.xl};
  ${({theme}) => theme.mediaQueries.mobileL} {
    max-width: 100%;
    padding: 0 16px;
  }
`;

const TagDisplay = styled(H4)`
  font-weight: normal;
  border-left: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  padding-left: ${({theme}) => theme.layouts.spacing.m};
  margin-top: ${({theme}) => theme.layouts.spacing.xxl};
`;

const MasteryLevelWrapper = styled.span`
  font-weight: bold;
  color: ${({level, theme}) => {
    switch (level) {
      case 'learning':
        return theme.colors.interfacePalette.red.default;
      case 'reviewing':
        return theme.colors.interfacePalette.yellow.default;
      case 'mastered':
        return theme.colors.interfacePalette.green.default;
      default:
        return theme.colors.neutralsPalette.extraDark;
    }
    }}
`;

const masteryLevelMap = {
  learning: 'Learning',
  reviewing: 'Reviewing',
  mastered: 'Mastered'
};


const Question = ({
  anatomyImage,
  anatomyImageTransform,
  prompt,
  answers,
  explanation,
  tags,
  didSubmitAnswer,
  testBlockName,
  alwaysShowingExplanation,
  onSelectAnswerIndex,
  didSubmitAnswerIndex,
  showFeedback,
  questionId,
  enableHighlighting,
  disableVideos,
  setHighlights,
  highlights,
  questionIndex,
  didMark,
  time,
  savedCrossedAnswerIndexes,
  onCrossedAnswerIndexesUpdate,
  viewKey,
  setCaseImageProps,
  setCaseImageSolutionProps,
  blockParts,
  themePalette,
  questionSetExplanation,
  showingTags,
  masteryLevel,
  showQuestionNumber=true,
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(didSubmitAnswerIndex);
  const [crossedAnswerIndexes, setCrossedAnswerIndexes] = useState(savedCrossedAnswerIndexes);
  const solutionRef = useRef();

  const correctAnswerIndex = answers.map(([answerText,answerCorrect]) => answerCorrect).indexOf(true);
  // const correctAnswerLetter = alphabet[correctAnswerIndex];
  const didSelectCorrectAnswer = selectedAnswerIndex === correctAnswerIndex;
  const showingExplanation = didSubmitAnswer || alwaysShowingExplanation;
  const didNotAnswer = selectedAnswerIndex === -1;

  // set case image
  useEffect(() => {
    setCaseImageProps && anatomyImage && anatomyImageTransform && setCaseImageProps({anatomyImage, anatomyImageTransform});
    if (setCaseImageSolutionProps && blockParts) {
      const {anatomyImageSolution} = blockParts;
      anatomyImageSolution && setCaseImageSolutionProps({anatomyImageSolution});
    }
    return () => {
      setCaseImageProps(null);
      setCaseImageSolutionProps && setCaseImageSolutionProps(null);
    }
  }, [])

  // selectedAnswerIndex hook
  useEffect(() => {
    if (selectedAnswerIndex !== -1) {
      onSelectAnswerIndex(selectedAnswerIndex, didSelectCorrectAnswer);
    }

    alwaysShowingExplanation && setSelectedAnswerIndex(didSubmitAnswerIndex || correctAnswerIndex);
  }, [selectedAnswerIndex]);

  function updateCrossedAnswerIndexes(index) {
    if (showingExplanation) return;

    const indexes = crossedAnswerIndexes.indexOf(index) > -1
      ? crossedAnswerIndexes.filter(i => i !== index)
      : [...crossedAnswerIndexes, index];
    onCrossedAnswerIndexesUpdate(indexes);
    setCrossedAnswerIndexes(indexes);
  }

  useEffect(() => {
    if (!disableVideos && solutionRef.current && showingExplanation) {
      const vimeoNodes = solutionRef.current.querySelectorAll(':not(del) > .video-thumb');

      const vimeoIds = new Set([...vimeoNodes].map(node => node.dataset.vimeoId));

      [...vimeoIds].forEach(async vimeoId => {
        vimeoNodes.forEach((node, index) => {
          if (node.dataset.vimeoId === vimeoId) {
            const videoContainer = document.createElement('div');
            videoContainer.className = "videoContainer";
            videoContainer.style.marginBottom = '1em';
            if (node.classList.contains('video-lock')) {
              node.insertAdjacentElement('afterEnd',videoContainer);
            } else {
              if (node.parentNode && node.parentNode.children.length === 1 && node.parentNode.children[0].classList.contains('video-thumb') && node.parentNode !== solutionRef.current) {
                node.parentNode.remove();
              } else {
                node.remove();
              }

              if (solutionRef.current) solutionRef.current.insertAdjacentElement('afterBegin',videoContainer);
            }
            const videoPlayer = <VideoPlayer playerId={`${vimeoId}-${index}-${viewKey}`} vimeoId={vimeoId} />;
            ReactDOM.render(videoPlayer, videoContainer);
          }
        })
      })
      const gifs = solutionRef.current.querySelectorAll('.rubgif');

      [...gifs].forEach(gif => {
        gif.style.display = 'none';
        const gifPlayer = document.createElement('gif-player');
        const gifSrc = gif.getAttribute('src');
        gifPlayer.setAttribute('src', gifSrc+'?version=gifplayer');
        gifPlayer.setAttribute('speed', '1');
        gifPlayer.setAttribute('play', true);
        gif.insertAdjacentElement('afterend', gifPlayer);
      });
    }
    return () => {
      if (solutionRef.current) {
        solutionRef.current.querySelectorAll('.videoContainer').forEach(node => node.remove());
      }
    }
  }, [explanation, showingExplanation]);

  const {topic} = tags.find(tag => !!tag.topic) || {};

  return (
    <Container>
      {testBlockName &&
        <TagLinks
          tags={[{
            title: testBlockName,
            color: determineColorFromTitle(testBlockName),
            as: 'span',
          }]}
        />
      }
      <Wrapper>
        <AdminButtons questionId={questionId} />
        <QuestionTitle color={themePalette.dark}>
          {showQuestionNumber && `Question ${questionIndex}`}
        </QuestionTitle>
        <Prompt dangerouslySetInnerHTML={{__html: prompt || 'Identify the tagged structure'}}/>
        <AnswerBox
          answers={answers}
          selectedAnswerIndex={selectedAnswerIndex}
          setSelectedAnswerIndex={setSelectedAnswerIndex}
          showingExplanation={showingExplanation}
          crossedAnswerIndexes={crossedAnswerIndexes}
          updateCrossedAnswerIndexes={updateCrossedAnswerIndexes}
        />
      </Wrapper>
      {showingExplanation &&
        <Wrapper>
          <SolutionContainer
            solution={explanation}
            didNotAnswer={didNotAnswer}
            didSelectCorrectAnswer={didSelectCorrectAnswer}
            selectedAnswerIndex={selectedAnswerIndex}
            showFeedback={showFeedback}
            questionId={questionId}
            tags={tags}
            didMark={didMark}
            time={time}
            solutionRef={solutionRef}
            questionSetExplanation={questionSetExplanation}
          />
        </Wrapper>
      }
      {
        showingTags && (
          <Wrapper>
            <TagDisplay>
              Question from <strong>{topic}</strong> currently tagged as <MasteryLevelWrapper level={masteryLevel}>{masteryLevelMap[masteryLevel] || 'nothing'}</MasteryLevelWrapper>
            </TagDisplay>
          </Wrapper>
        )
      }
    </Container>
  )
}

Question.defaultProps = {
  prompt: '',
  answers: '[]',
  explanation: '',
  onSelectAnswerIndex: () => true,
  alwaysShowingExplanation: false,
  didSubmitAnswerIndex: -1,
  showFeedback: false,
  questionId: '',
  tags: [],
  enableHighlighting: false,
  setHighlights: () => {},
  highlights: null, // this should be a serialized highlight string,
  didMark: false,
  time: "",
  savedCrossedAnswerIndexes: [],
  onCrossedAnswerIndexesUpdate: () => true,
  questionSetExplanation: ''
};

export default Question;
