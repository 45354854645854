import {colors} from '../styles/theme';

const config = {
  classrooms: [
    {
      name: 'Cardiology',
      section: 'step1',
      tagIds: ['cedc26ad-70e2-4fc7-bd4b-d89924f86e86', 'e52db2c9-926e-4ce4-9383-99cf90de03cb'],
      route: 'cardiology',
      themePalette: colors.brandPalette.pumpkin,
      classroomDescription: 'All the high-yield cardiology concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!',
      questionCount: 625,
      comingSoon: false,
      icon: 'icons/cardio.svg',
      educators: [
        {
          firstName: 'Dr. Roviso',
          avatar: 'images/inbde/anthony_roviso.gif',
        },
        {
          firstName: 'Kristen',
          avatar: 'images/kristen_clark.gif',
        },
        {
          firstName: 'Jonathan',
          avatar: 'images/jonathanhunter.gif',
        },
      ],
      contentTypes: [
        {
          name: 'Cardiology Videos',
          route: 'videos',
          content:  [
            {
              name: 'Cardiology Videos',
              type: 'Course',
              description: '',
              content: [
                {
                  name: 'Embryology',
                  id: 'af87f47c-0df3-410d-9d4d-0e5b20cd35af',
                  route: 'embryology',
                },
                {
                  name: 'Anatomy',
                  id: 'e65a0ca4-18ac-4b8b-927c-f69bd89284c2',
                  route: 'anatomy',
                },
                {
                  name: 'Vascular System',
                  id: '5076fa38-1d0c-48b5-aa01-d6fdfef857ed',
                  route: 'vascular-system',
                },
                {
                  name: 'Cardiac Parameters of Physiologic Function',
                  id: '6769e381-b925-4d08-8403-9f862c289e1b',
                  route: 'cardiac-parameters-of-physiologic-function',
                },
                {
                  name: 'Cardiac Function and Venous Return Curves',
                  id: '6bdc6c02-f5d6-4147-9a35-a0a38526eefe',
                  route: 'cardiac-function-and-venous-return-curves',
                },
                {
                  name: 'Pressure-Volume Loops',
                  id: '598b8d0e-46cd-46f7-bf46-e1ab8099474f',
                  route: 'pressure-volume-loops',
                },
                {
                  name: 'Vasodilation and Vasoconstriction',
                  id: 'f880e672-9a44-41ce-82fe-f71f85b514a3',
                  route: 'vasodilation-and-vasoconstriction',
                },
                {
                  name: 'Pressure and Flow Physiology',
                  id: 'adb6a0af-4622-4abe-a63f-42b75ab49372',
                  route: 'pressure-and-flow-physiology',
                },
                {
                  name: 'Cardiac Cycle',
                  id: '728fb8a5-1437-4135-b342-c9f948c38de7',
                  route: 'cardiac-cycle',
                },
                {
                  name: 'Renin-Angiotensin-Aldosterone System',
                  id: 'fca6a028-3d07-440a-af64-e1ed3ab6a026',
                  route: 'renin-angiotensin-aldosterone-system',
                },
                {
                  name: 'Exercise Physiology',
                  id: 'd4e17ac4-6c7d-46c4-8674-b285fe494a0a',
                  route: 'Exercise Physiology',
                },
                {
                  name: 'Cardiac Conductive Physiology',
                  id: '37c50291-eeab-4513-a5b5-4915817e755e',
                  route: 'Cardiac Conductive Physiology',
                },
                {
                  name: 'Antiarrhythmics',
                  id: 'f5c24faa-eaf5-4b39-8c5a-a7fc516e6d7c',
                  route: 'Antiarrhythmics',
                },
                {
                  name: 'Atrial Arrhythmias',
                  id: '6e5f5d6d-defc-4018-86d4-01d34856af1d',
                  route: 'atrial-arrhythmias',
                },
                {
                  name: 'Ventricular Arrhythmias',
                  id: '80d23ff8-73b7-4033-af84-710cba56d376',
                  route: 'ventricular-arrhythmias',
                },
                {
                  name: 'Conduction Blocks',
                  id: '20250ae2-3ae4-42c6-b924-66a8cd2b16df',
                  route: 'conduction-blocks',
                },
                {
                  name: 'Heart Failure',
                  id: 'e659e621-23a0-418f-aa3b-ad091189bdb6',
                  route: 'heart-failure',
                },
                {
                  name: 'Cardiomyopathy',
                  id: 'aa785966-34f6-4f95-b305-14119961c488',
                  route: 'cardiomyopathy',
                },
                {
                  name: 'Aortic Disease',
                  id: '892a420a-d035-4e57-b5b4-d4c62b8bf736',
                  route: 'aortic-disease',
                },
                {
                  name: 'Valvular Disease',
                  id: '03b6d572-1c32-425f-94c6-b074b99ef688',
                  route: 'valvular-disease',
                },
                {
                  name: 'Acyanotic Congenital Heart Defects',
                  id: '2f29e116-0b73-4fdb-9ebe-e9e91ebbebdf',
                  route: 'acyanotic-congenital-heart-defects',
                },
                {
                  name: 'Cyanotic Congenital Heart Defects',
                  id: '7b8c2959-b7b9-4953-9d15-c065b92c0291',
                  route: 'cyanotic-congenital-heart-defects',
                },
                {
                  name: 'Stable Angina and Atherosclerosis',
                  id: '2836a8aa-1cca-46b0-bfdb-f458858e25bd',
                  route: 'stable-angina-and-atherosclerosis',
                },
                {
                  name: 'Myocardial Infarction',
                  id: '562c1c3d-3f1f-4159-bd47-091ce50c7bbc',
                  route: 'myocardial-infarction',
                },
                {
                  name: 'Peripheral Venous and Arterial Disease',
                  id: '066b8374-87ec-425d-a23d-f4c277c10702',
                  route: 'peripheral-venous-and-arterial-disease',
                },
                {
                  name: 'Hypertension',
                  id: 'd8698608-7bcf-4573-9fc2-9f4a493d3f59',
                  route: 'hypertension',
                },
                {
                  name: 'Pericardial Disease',
                  id: 'ae1c92dd-f799-413c-9126-93f03f70a2e7',
                  route: 'pericardial-disease',
                },
                {
                  name: 'Shock',
                  id: 'c534d424-1e09-4cf1-b7b8-74ab18ec6a21',
                  route: 'shock',
                },
              ]
            }
          ],
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Anatomy',
                  id: 'e83db12e-8aa9-4f6b-b743-7b5457adc68f',
                  route: 'anatomy'
                },
                {
                  name: 'Pathology',
                  id: '11ccd07b-50a6-435a-9edb-72a5f8b066ea',
                  route: 'pathology'
                },
                {
                  name: 'Pharmacology',
                  id: '56ae5ecb-2a96-4551-b6c4-1e10d532da2f',
                  route: 'pharmacology'
                },
                // {
                //   name: 'Biochemistry',
                //   id: '251a0b30-1765-414c-8398-d966ab65994a',
                //   route: 'biochemistry'
                // },
                // {
                //   name: 'Genetics',
                //   id: '2de49cc9-4447-45e7-81cd-aa0c63f7818e',
                //   route: 'genetics'
                // },
                {
                  name: 'Microbiology',
                  id: '219cbf7d-a7b0-49d5-86dd-d79920628cf0',
                  route: 'microbiology'
                },
                {
                  name: 'Physiology',
                  id: '9e2ae8de-5e52-490f-9e2b-b2127aa78b55',
                  route: 'physiology'
                },
              ]
            }
          ]
        },
        {
          name: 'Cardio Bites',
          route: 'qbanks',
          calloutText: 'Cardio Bites are bite-sized questions that cover what you learn in Dr. Roviso’s videos. Use them to test your recall and switch on active learning!',
          // icon: <StarIcon/>,
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Embryology',
                  id: 'dc08fc46-bffa-48d2-b6d5-17f7a0e44f2c',
                  route: 'embryology',
                },
                {
                  name: 'Anatomy',
                  id: '38b04cb7-199d-4eaa-bf11-2a7734544d15',
                  route: 'anatomy',
                },
                {
                  name: 'Vascular System',
                  id: '1a1810e8-51d2-4408-a27a-b215cf20981c',
                  route: 'vascular-system',
                },
                {
                  name: 'Cardiac Parameters of Physiologic Function',
                  id: '67c9d021-2627-4320-a240-4bd2e381c56d',
                  route: 'cardiac-parameters-of-physiologic-function',
                },
                {
                  name: 'Cardiac Function and Venous Return Curves',
                  id: 'e269c30a-6b36-4fb8-b12e-92c446362dae',
                  route: 'cardiac-function-and-venous-return-curves',
                },
                {
                  name: 'Pressure-Volume Loops',
                  id: 'a5a8028e-93e9-4627-a5e5-69f2d61d4fe9',
                  route: 'pressure-volume-loops',
                },
                {
                  name: 'Vasodilation and Vasoconstriction',
                  id: 'eba82859-360a-49a5-94a8-15d53f3fbe05',
                  route: 'vasodilation-and-vasoconstriction',
                },
                {
                  name: 'Pressure and Flow Physiology',
                  id: '0d241817-cbdb-4db6-9db9-a1426233ccd3',
                  route: 'pressure-and-flow-physiology',
                },
                {
                  name: 'Cardiac Cycle',
                  id: '7851fd81-fd65-45b4-b444-4c915babb440',
                  route: 'cardiac-cycle',
                },
                {
                  name: 'Renin-Angiotensin-Aldosterone System',
                  id: '4620a248-56c4-4e7b-a92c-1eccd38895b2',
                  route: 'renin-angiotensin-aldosterone-system',
                },
                {
                  name: 'Exercise Physiology',
                  id: '064fa8f9-196d-43ce-b2ae-0178d2a0829f',
                  route: 'Exercise Physiology',
                },
                {
                  name: 'Cardiac Conductive Physiology',
                  id: '0e574379-66e1-4720-9e6c-9d3ec1948692',
                  route: 'Cardiac Conductive Physiology',
                },
                {
                  name: 'Antiarrhythmics',
                  id: '80af692f-0010-44e6-ab2a-422cad159699',
                  route: 'Antiarrhythmics',
                },
                {
                  name: 'Atrial Arrhythmias',
                  id: '501f270d-eb88-425f-bf61-de523e63c47c',
                  route: 'atrial-arrhythmias',
                },
                {
                  name: 'Ventricular Arrhythmias',
                  id: '14128d34-8176-4e70-9009-aba7dc429e56',
                  route: 'ventricular-arrhythmias',
                },
                {
                  name: 'Conduction Blocks',
                  id: '7abea0d8-7b64-4474-9ebf-62a9da780ef7',
                  route: 'conduction-blocks',
                },
                {
                  name: 'Heart Failure',
                  id: '3a6fbf48-e172-4dd6-ac68-1efd179ecb3d',
                  route: 'heart-failure',
                },
                {
                  name: 'Cardiomyopathy',
                  id: '7752627a-b291-4b0f-abf4-bdab1d903a5e',
                  route: 'cardiomyopathy',
                },
                {
                  name: 'Aortic Disease',
                  id: '4b3d9b93-02b0-4096-a90a-e9e04f40b35a',
                  route: 'aortic-disease',
                },
                {
                  name: 'Valvular Disease',
                  id: 'ece0d035-c8dd-4313-a7d3-6c1be8c02aa5',
                  route: 'valvular-disease',
                },
                {
                  name: 'Acyanotic Congenital Heart Defects',
                  id: '4aff18d1-e9f8-473c-83af-f69a957b9d4f',
                  route: 'acyanotic-congenital-heart-defects',
                },
                {
                  name: 'Cyanotic Congenital Heart Defects',
                  id: '8cbde7f6-cdb3-4f33-8018-2b36272ae82e',
                  route: 'cyanotic-congenital-heart-defects',
                },
                {
                  name: 'Stable Angina and Atherosclerosis',
                  id: 'd9c782f7-0d0f-4337-b791-dc7205465100',
                  route: 'stable-angina-and-atherosclerosis',
                },
                {
                  name: 'Myocardial Infarction',
                  id: 'c1f2c440-be49-482b-9cb9-7c57749ec199',
                  route: 'myocardial-infarction',
                },
                {
                  name: 'Peripheral Venous and Arterial Disease',
                  id: '1f57ef8c-4bd2-4c10-abf7-677b4878027c',
                  route: 'peripheral-venous-and-arterial-disease',
                },
                {
                  name: 'Hypertension',
                  id: 'c1f9b72a-43b7-4dc2-83ce-6b92ba6bcfd8',
                  route: 'hypertension',
                },
                {
                  name: 'Pericardial Disease',
                  id: '5338a7c3-1cb9-42df-ae6c-3c0e11d19414',
                  route: 'pericardial-disease',
                },
                {
                  name: 'Shock',
                  id: '80a795ac-bbee-4cbb-b163-3a61d1e9f15d',
                  route: 'shock',
                },
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Neurology',
      section: 'step1',
      tagIds: ['163640c8-3e6e-450c-8edd-6935175d224f', '51765dae-449a-4035-8c34-5331477bc4ce'],
      route: 'neurology',
      themePalette: colors.brandPalette.magenta,
      classroomDescription: 'All the high-yield neurology concepts you need to know in one place. Watch the videos and answer the practice questions to ace this section on test day!',
      questionCount: 690,
      icon: 'icons/inbde/ethics-and-behavioral-sciences.svg',
      educators: [
        {
          firstName: 'Dr. Roviso',
          avatar: 'images/inbde/anthony_roviso.gif',
        },
        {
          firstName: 'Tyler',
          avatar: 'images/tyler.gif',
        },
        {
          firstName: 'Jia',
          avatar: 'images/jia.gif',
        },
      ],
      contentTypes: [
        {
          name: 'Neurology Videos',
          route: 'videos',
          content:  [
            {
              name: 'Neurology Videos',
              type: 'Course',
              description: 'review stuff',
              content: [
                {
                  name: 'Embryology',
                  id: '167d52f8-98aa-424a-9aef-c3398d10672e',
                  route: 'embryology',
                  index: 1
                },
                {
                  name: 'Cellular Function',
                  id: '17233ff3-f5ae-4498-8442-3c444032e67a',
                  route: 'cellular-function',
                  index: 2
                },
                {
                  name: 'Ascending Spinal Cord Tracts',
                  id: '9d47f4d6-3277-4651-a7a8-19c847c852d8',
                  route: 'ascending-spinal-cord-tracts',
                  index: 3
                },
                {
                  name: 'Descending Spinal Cord Tracts',
                  id: '767f2aa0-4fae-410c-a6ac-3048ad21b05c',
                  route: 'descending-spinal-cord-tracts',
                  index: 4
                },
                {
                  name: 'Brainstem Anatomy',
                  id: 'fc1f81f8-7d84-4fe0-809c-13e7d9feba44',
                  route: 'brainstem-anatomy',
                  index: 5
                },
                {
                  name: 'Cerebral Cortex',
                  id: '85b144bc-4775-4583-8033-db7839a2ad66',
                  route: 'cerebral-cortext',
                  index: 6
                },
                {
                  name: 'Radiculopathy',
                  id: '7f3ba4fb-1c2e-4b24-a85f-46d58c4e5333',
                  route: 'radiculopathy',
                  index: 7
                },
                {
                  name: 'Spinal Cord Syndromes',
                  id: 'dbdde3cd-38f5-4284-931f-e21ea515f555',
                  route: 'spinal-cord-syndromes',
                  index: 8
                },
                {
                  name: 'Invasive Spinal Cord Disease',
                  id: '74def73a-8f3e-4883-adcf-91ae9dab349e',
                  route: 'invasive-spinal-cord-disease',
                  index: 9
                },
                {
                  name: 'Demyelinating Disease',
                  id: '4f5b47af-0069-4212-af9c-6a27be2d5589',
                  route: 'demyelinating-disease',
                  index: 10
                },
                {
                  name: 'Conduction Physiology',
                  id: 'cd270c1b-c73e-4768-9189-a5649244960c',
                  route: 'conduction-physiology',
                  index: 11
                },
                {
                  name: 'Neuromuscular Junction (NMJ)',
                  id: '62a118b4-782d-4ada-b63e-5351f385aa96',
                  route: 'neuromuscular-junction-nmj',
                  index: 12
                },
                {
                  name: 'Cranial Nerves 1-6',
                  id: '255da5b0-0626-460d-9599-4fa89a74a188',
                  route: 'cranial-nerves-1-6',
                  index: 13
                },
                {
                  name: 'Cranial Nerves 7-12',
                  id: '0e4865f9-fd48-45fa-bf63-54a1ff88c6de',
                  route: 'cranial-nerves-7-12',
                  index: 14
                },
                {
                  name: 'Vision',
                  id: '4523083b-a275-435c-adc7-c77190748b75',
                  route: 'vision',
                  index: 15
                },
                {
                  name: 'Auditory Sensation',
                  id: 'ccabbc0a-cb58-4a53-86d0-5c653494f9cd',
                  route: 'auditory-sensation',
                  index: 16
                },
                {
                  name: 'Vertigo',
                  id: 'a8906d3a-10c1-41eb-9e55-8da083407879',
                  route: 'vertigo',
                  index: 17
                },
                {
                  name: 'Pediatric Brain Tumors',
                  id: '91a2e29b-febc-40a0-ae6a-422a066bb6c3',
                  route: 'pediatric-brain-tumors',
                  index: 18
                },
                {
                  name: 'Adult Primary Brain Tumors',
                  id: '6115fcf8-6864-4e6b-b201-caa5a81f7e2a',
                  route: 'adult-primary-brain-tumors',
                  index: 19
                },
                {
                  name: 'Ischemic Cerebrovascular Accidents',
                  id: '26ab6dd6-5065-465f-8cee-4cb250a214bd',
                  route: 'ischemic-cerebrovascular-accidents',
                  index: 20
                },
                {
                  name: 'Aneurysms and Intracranial Hemorrhage',
                  id: 'e1cad664-701f-43c6-aa5f-40ad1351f81a',
                  route: 'aneurysms-and-intracranial-hemorrhage',
                  index: 21
                },
                {
                  name: 'Cerebellum and Ventricles',
                  id: 'd0e52b66-566a-4618-8d68-2369b405569e',
                  route: 'cerebellum-and-ventricles',
                  index: 22
                },
                {
                  name: 'Diencephalon',
                  id: 'bbd6ca5c-667d-48a3-ab4b-b6de4e26119e',
                  route: 'diencephalon',
                  index: 23
                },
                {
                  name: 'Basal Ganglia',
                  id: '36f85117-3701-4c08-9446-1939c73414cc',
                  route: 'basal-ganglia',
                  index: 24
                },
                {
                  name: 'Neurotransmitter Activity in Psychiatric Disease',
                  id: '50321894-7010-48c7-980e-516b75ef8aa0',
                  route: 'neurotransmitter-activity-in-psychiatric-disease',
                  index: 25
                },
                {
                  name: 'Dementia',
                  id: '2fbd657b-69bf-4b1c-8b85-a480d380d03f',
                  route: 'dementia',
                  index: 26
                },
                {
                  name: 'Headache',
                  id: '44781f72-b5c9-4214-995d-7339f54218dc',
                  route: 'headache',
                  index: 27
                },
                {
                  name: 'Seizures',
                  id: '821ec6fe-4b82-4f54-a1d1-d6107c133f6d',
                  route: 'seizures',
                  index: 28
                },
                {
                  name: 'Traumatic Brain Injuries',
                  id: 'b8c5f8d9-4f9c-4226-8a2d-27ad15f38669',
                  route: 'traumatic-brain-injuries',
                  index: 29
                },
              ]
            }
          ],
        },
        {
          name: 'Board Style Questions',
          route: 'board-banks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Anatomy',
                  id: '770ae7dc-3a7f-4198-84fc-0c14f583b18e',
                  route: 'anatomy'
                },
                {
                  name: 'Pathology',
                  id: 'd03123fb-29c3-4f2d-a0ec-7995e2ab4371',
                  route: 'pathology'
                },
                {
                  name: 'Pharmacology',
                  id: '65d8c2c1-89bc-4dc6-9e6e-f9f659582e87',
                  route: 'pharmacology'
                },
                {
                  name: 'Biochemistry',
                  id: '790d2841-6967-4b03-b4c4-ef207a16c86f',
                  route: 'biochemistry'
                },
                {
                  name: 'Microbiology',
                  id: 'bd53a4d7-2f3a-4556-bfe4-991de33f62de',
                  route: 'microbiology'
                },
                // {
                //   name: 'Genetics',
                //   id: '70fddc4b-1953-4ce4-a264-f2978956b195',
                //   route: 'genetics'
                // },
                // {
                //   name: 'Physiology',
                //   id: '2a0e9e74-cc41-4d4e-9571-954fa002f4a8',
                //   route: 'physiology'
                // },
                // {
                //   name: 'Pathophysiology',
                //   id: '68c86eca-807d-405d-85d9-7735a59acfa1',
                //   route: 'pathophysiology'
                // },
                // {
                //   name: 'Embryology',
                //   id: '8450ca7d-9a83-4aa5-b32b-3595c3e6ac9e',
                //   route: 'embryology'
                // },
              ]
            }
          ]
        },
        {
          name: 'Neuro Bites',
          // icon: <StarIcon/>,
          calloutText: 'Neuro Bites are bite-sized questions that cover what you learn in Dr. Roviso’s videos. Use them to test your recall and switch on active learning!',
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  name: 'Embryology',
                  route: 'embryology',
                  id: '0af841bb-3570-428c-b207-528ca79bb5e4',
                },
                {
                  name: 'Cellular Function',
                  route: 'cellular-function',
                  id: '84964351-b0b2-41c4-8201-ddf9793847f0',
                },
                {
                  name: 'Ascending Spinal Cord Tracts',
                  route: 'ascending-spinal-cord-tracts',
                  id: '10569bcf-f74d-435e-b024-abdf35611413',
                },
                {
                  name: 'Descending Spinal Cord Tracts',
                  route: 'descending-spinal-cord-tracts',
                  id: 'c8234d01-b2db-480f-94f5-2eb6806b8bdd',
                },
                {
                  name: 'Brainstem Anatomy',
                  route: 'brainstem-anatomy',
                  id: '2170e457-4f27-43a5-8de8-62a8e2530190',
                },
                {
                  name: 'Cerebral Cortex',
                  route: 'cerebral-cortex',
                  id: '43210a5e-5772-4acc-afed-ca514218883a',
                },
                {
                  name: 'Radiculopathy',
                  route: 'radiculopathy',
                  id: 'f1628fa8-e2ea-4a60-91a0-b06eed0a598b',
                },
                {
                  name: 'Spinal Cord Syndromes',
                  route: 'spinal-cord-syndromes',
                  id: 'a87c93a3-1c91-4c86-b5c2-3913d7bdc35d',
                },
                {
                  name: 'Invasive Spinal Cord Disease',
                  route: 'invasive-spinal-cord-disease',
                  id: '1962878b-6098-4576-84cc-e31985c24247',
                },
                {
                  name: 'Demyelinating Disease',
                  route: 'demyelinating-disease',
                  id: '1a65cb63-980b-4ffb-aaba-f31f250e2bf2',
                },
                {
                  name: 'Conduction Physiology',
                  route: 'conduction-physiology',
                  id: '74a58338-9c86-4850-b95c-6d820bf7552f',
                },
                {
                  name: 'Neuromuscular Junction',
                  route: 'neuromuscular-junction',
                  id: '8a2154bd-98c8-483e-9976-29b939e7b356',
                },
                {
                  name: 'Cranial Nerves 1-6',
                  route: 'cranial-nerves-1-6',
                  id: '4a841de8-9bef-49aa-9d1d-8f2e0822e0e5',
                },
                {
                  name: 'Cranial Nerves 7-12',
                  route: 'cranial-nerves-7-12',
                  id: '043915ef-035d-48ac-892c-836898d1d01a',
                },
                {
                  name: 'Vision',
                  route: 'vision',
                  id: '77d417e7-f4eb-41c3-8cbc-a9e2a0e344b6',
                },
                {
                  name: 'Auditory',
                  route: 'auditory',
                  id: '579edfd1-9df3-498a-bfe7-8d56a19089c9',
                },
                {
                  name: 'Vertigo',
                  route: 'vertigo',
                  id: 'd0186d03-14ff-4454-82c5-d9285a6eed0e',
                },
                {
                  name: 'Pediatric Brain Tumors',
                  route: 'pediatric-brain-tumors',
                  id: 'ced83a8a-3c94-4864-9789-bed29f04ae4b',
                },
                {
                  name: 'Adult Primary Brain Tumors',
                  route: 'adult-primary-brain-tumors',
                  id: '66ee23bb-e6b5-4d76-b622-6eb59108d37a',
                },
                {
                  name: 'Ischemic Cerebrovascular Accidents',
                  route: 'ischemic-cerebrovascular-accidents',
                  id: '5c41ad11-1c3d-4ee3-bd13-881446d089dc',
                },
                {
                  name: 'Aneurysms and Intracranial Hemorrhage',
                  route: 'aneurysms-and-intracranial-hemorrhage',
                  id: '8d49e739-6a82-493b-b0e9-d9107ea86a8b',
                },
                {
                  name: 'Cerebellum and Ventricles',
                  route: 'cerebellum-and-ventricles',
                  id: 'e416e870-9225-4f16-b885-a8424884dc7a',
                },
                {
                  name: 'Diencephalon',
                  route: 'diencephalon',
                  id: '7b9ba624-acd9-44b0-aa38-0a289627e2ea',
                },
                {
                  name: 'Basal Ganglia',
                  route: 'basal-ganglia',
                  id: '1fc4ba3d-30d9-4852-9fb3-2f51012e1571',
                },
                {
                  name: 'Neurotransmitter Activity in Psychiatric Disease' ,
                  route: 'neurotransmitter-activity-in-psychiatric-disease' ,
                  id: '77e72a3f-1efa-4559-9556-80c875d6fc44',
                },
                {
                  name: 'Dementia',
                  route: 'dementia',
                  id: '849655c0-9929-42e0-9246-15125aa3b1dc',
                },
                {
                  name: 'Headache',
                  route: 'headache',
                  id: '0dd2b22f-5f59-45da-afcf-f0df0f5c1f82',
                },
                {
                  name: 'Seizures',
                  route: 'seizures',
                  id: '48137373-5b10-4560-8cc7-277099ef5234',
                },
                {
                  name: 'Traumatic Brain Injuries',
                  route: 'traumatic-brain-injuries',
                  id: 'c89c4eb8-d207-4e00-bcf4-8ebd4ffb28da'
                }
              ],
            },
          ]
        },
      ]
    },
    {
      name: 'Endocrinology',
      tagId: '',
      route: 'endocrinology',
      section: 'step1',
      themePalette: colors.brandPalette.blue,
      classroomDescription: 'All the high-yield details you need to know for Step 1 Endocrinology in one place. Watch Dr. R’s videos and answer the practice questions to ace this section on test day!',
      questionCount: 0,
      comingSoon: true,
      icon: 'inbde/development-structure-function.svg',
      educators: [
        {
          firstName: 'Dr. Roviso',
          avatar: 'images/inbde/anthony_roviso.gif',
        },
        {
          firstName: 'Jonathan',
          avatar: 'images/jonathanhunter.gif',
        },
        {
          firstName: 'Brandon',
          avatar: 'images/brandonmuncan.gif',
        },
      ],
      contentTypes: [
        {
          name: 'Dr. Roviso\'s Videos',
          route: 'videos',
          content:  [
          ],
        },
        {
          name: 'Endocrinology Bites',
          // icon: <StarIcon/>,
          route: 'qbanks',
          content: [
          ]
        },
      ]
    },
  ],
  studyTools: [
    // {
    //   name: 'Past Test Results',
    //   route: 'past-test-results',
    //   themePalette: colors.brandPalette.royal,
    //   showOnHome: true,
    // },
    {
      name: 'Bookmarks',
      route: 'bookmarks',
      themePalette: colors.brandPalette.royal,
      showOnHome: true,
    },
    // {
    //   name: 'Download iPhone App',
    //   route: '#',
    //   themePalette: colors.brandPalette.royal,
    // },
    // {
    //   name: 'Download Android App',
    //   route: '#',
    //   themePalette: colors.brandPalette.royal,
    // },
  ],
  // upNext: [
  //
  // ],
  meta: {
    siteTitle: 'Step 1 Bootcamp',
    subjectIds: [
      '163640c8-3e6e-450c-8edd-6935175d224f', // Neurology
    ]
  }
};
function getOATConfigObject(classroom, chapter, contentSlug) {
}

export {getOATConfigObject};
export default config;
