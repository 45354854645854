import styled, {css} from 'styled-components';
import {Check2} from '@styled-icons/bootstrap/Check2';
import {X} from '@styled-icons/bootstrap/X';
import Draggable from 'react-draggable';

import {ReactComponent as CalculatorSVG} from './assets/Calculator.svg';
import {ReactComponent as FlagSVG} from './assets/Flag.svg';
import {ReactComponent as ClockSVG} from './assets/Clock.svg';
import {ReactComponent as SettingsSVG} from './assets/Settings.svg';
import {ReactComponent as QuestionIconSVG} from './assets/QuestionIcon.svg';
import {ReactComponent as EndSVG} from './assets/End.svg';
import {ReactComponent as NavigatorSVG} from './assets/Navigator.svg';
import {ReactComponent as ArrowSVG} from './assets/Arrow.svg';
import {ReactComponent as CloseSVG} from './assets/Close.svg';
import {ReactComponent as AlertSVG} from './assets/Alert.svg';
import {ReactComponent as FlagsFilledSVG} from './assets/FlagsFilled.svg';
import {ReactComponent as FlagFilledSVG} from './assets/FlagFilled.svg';
import {ReactComponent as ReviewIncompleteSVG} from './assets/ReviewIncomplete.svg';
import {ReactComponent as PauseSVG} from './assets/Pause.svg';
import {useHotkeys} from 'react-hotkeys-hook';
export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #006DAA;
  padding: 4px;
  ${({theme}) => theme.mediaQueries.tablet} {
    overflow: auto;
  }
`;

export const BoxSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
`;

export const BoxSubsection = styled(BoxSection)`
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding: 4px;

  svg {
    width: 26px;
    height: 26px;
  }

  &:hover {
    background-color: #283294;
  }
`;

const IconText = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: white;
  white-space: nowrap;
  margin-top: 4px;
  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
`;

export const IconButton = ({className, Icon, text, onClick}) => (
  <IconContainer className={className} onClick={onClick}>
    <Icon />
    <IconText children={text} />
  </IconContainer>
)

export const MenuText = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  color: white;
  white-space: nowrap;
`;

export const XIcon = styled(X)`
  color: #FF0000;
`;
export const CheckIcon = styled(Check2)`
  color: #02A604;
`;
export const ButtonIcon = styled.img.attrs(props => ({draggable: false}))`
  user-select: none;
  cursor: pointer;
`
const ButtonIconStyle = `
  user-select: none;
  cursor: pointer;
`;

export const FlagIcon = styled(FlagSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  margin: 0 8px;
  ${({marked}) => marked && css`
    fill: white;
  `}
  ${({withColor}) => withColor && css`fill: #E05E57;`}
`
export const ClockIcon = styled(ClockSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  margin: 0 8px;
`
export const SettingsIcon = styled(SettingsSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  margin: 0 8px;
`
export const QuestionIcon = styled(QuestionIconSVG)`
  ${ButtonIconStyle}
  margin: 0 8px;
`
export const CalculatorIcon = styled(CalculatorSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  margin-right: 8px;
`;
export const EndIcon = styled(EndSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  width: 20px;
  margin-right: 4px;
`;
export const CloseIcon = styled(CloseSVG)`
  ${ButtonIconStyle}
  margin-right: 4px;
`;
export const NavigatorIcon = styled(NavigatorSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  margin-right: 4px;
`;
export const AlertIcon = styled(AlertSVG).attrs(props => ({draggable: false}))`
  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;
  margin-right: 16px;
`;
export const FlagsFilledIcon = styled(FlagsFilledSVG).attrs(props => ({draggable: false}))`
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
  margin-right: 8px;
`;
export const FlagFilledIcon = styled(FlagFilledSVG).attrs(props => ({draggable: false}))`
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
  margin-right: 8px;
`;
export const ReviewIncompleteIcon = styled(ReviewIncompleteSVG).attrs(props => ({draggable: false}))`
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
  margin-right: 8px;
`;
export const PauseIcon = styled(PauseSVG).attrs(props => ({draggable: false}))`
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
`;
export const LeftArrowIcon = styled(ArrowSVG).attrs(props => ({draggable: false}))`${ButtonIconStyle} margin-right: 4px;`;
export const RightArrowIcon = styled(ArrowSVG).attrs(props => ({draggable: false}))`
  ${ButtonIconStyle}
  transform: rotate(180deg);
  margin-right: 0px;
  margin-left: 4px;
`;
export const Button = styled.div`
  padding: 10px 8px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  border-left: 1px solid white;
  background: ${({theme, color}) => color ? theme.colors.interfacePalette[color].default : 'transparent'};
  ${({color}) => color && css`
    &:hover {
      background: ${({theme, color}) => theme.colors.interfacePalette[color].dark};
    }
  `}
  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 56px;
    height: 56px;
    justify-content: center;
  }
`;
export const HoverButton = styled(Button)`
  &:hover {
    color: #FFFF00;
    path {
      fill: #FFFF00;
    }
    rect {
      stroke: #FFFF00;
    }
  }
`;
const AlertContainer = styled.div`
  padding: 4px 8px;
  background: ${() => window.location.pathname.includes('med-school') ? 'rgb(56, 82, 164)' : window.location.pathname.includes('inbde/custom-test') ? 'rgb(15, 98, 146)' : '#006DAA'};
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.24);
  color: white;
  resize: both;
  overflow: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-width: 475px;
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin: 0px 8px;
  }
`;
const AlertHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  font-size: 16px;
  border-bottom: 1px solid white;
`;
const AlertBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
`;
const AlertText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: white;
  flex: 1;
`;
const AlertButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 16px;
`;
const AlertButton = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border: 1px solid white;
  padding: 6px 14px;
  margin-right: 16px;
  cursor: pointer;
  &:last-child {
    margin-right: 0px;
  }
  &:hover {
    opacity: 1;
  }
  ${({faded}) => faded && css`opacity: 0.5;`}
`
export const AlertPopup = ({close, headerContent, alertText, buttonConfig}) => {
  useHotkeys('esc', close);
  return (
    <AlertContainer onClick={e => e.stopPropagation()}>
      <AlertHeader>
        {headerContent}
        <PopupButton style={{display: 'flex'}}><CloseIcon style={{marginRight: '0px'}} onClick={close} /></PopupButton>
      </AlertHeader>
      <AlertBody>
        <AlertIcon/>
        <AlertText children={alertText}/>
      </AlertBody>
      <AlertButtons>
        {buttonConfig.map(props => <AlertButton {...props} />)}
      </AlertButtons>
    </AlertContainer>
  )
}
export const DraggablePopup = ({innerRef, close, headerContent, footerContent, children, fullSize=false, noSize=false, className}) => {
  useHotkeys('esc', close);
  return (
    <Draggable handle=".handle">
      <PopupContainer ref={innerRef} onClick={e => e.stopPropagation()} noSize={noSize} fullSize={fullSize}  className={className}>
        <PopupHeader className="handle">
          {headerContent}
          <PopupButton style={{display: 'flex'}}><CloseIcon style={{marginRight: '0px'}} onTouchEnd={close} onClick={close} /></PopupButton>
        </PopupHeader>
        {children}
        <PopupFooter className="draggable-popup-footer">
          {footerContent}
          <PopupButton onClick={close}><CloseIcon /> Close</PopupButton>
        </PopupFooter>
      </PopupContainer>
    </Draggable>
  )
}

const PopupButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #FFFF00;
    path {
      fill: #FFFF00;
    }
    rect {
      stroke: #FFFF00;
    }
  }
`;

const PopupContainer = styled.div`
  background: white;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  color: white;
  resize: both;
  overflow: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  ${({noSize}) => !noSize && css`
    width: 50%;
    min-height: 283px;
    min-width: 375px;
    max-height: 80vh;
    max-width: 80vw;
  `}
  ${({fullSize}) => fullSize && css`
    height: 80vh;
    width: 80vw;
  `}
  font-size: 14px;
`;

const PopupHeader = styled.div`
  background: #006DAA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  cursor: grabbing;
  font-size: 16px;
`;
const PopupFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  background: #006DAA;
`;