import React, {createContext, useContext, useState} from 'react';
import {useBeforeUnload} from '@bootcamp/shared/src/util/hooks';
import {Prompt} from 'react-router-dom';


const ReloadBlockerContext = createContext();

function ReloadBlocker({reloadBlocked}) {
  return <Prompt when={reloadBlocked} message={'Are you sure you want to leave?'} />
}

const ReloadBlockerProvider = props => {
  const [enabled, setEnabled] = useState(false);

  useBeforeUnload(enabled);

  // this ReloadBlocker component is a fallback react-router url state changes.
  const children = (
    <>
      <ReloadBlocker reloadBlocked={enabled}/>
      {props.children}
    </>
  );


  return <ReloadBlockerContext.Provider value={{toggleReloadBlocker: setEnabled}} children={children} />
}

const useReloadBlockerContext = () => useContext(ReloadBlockerContext);

export {ReloadBlockerContext, ReloadBlockerProvider, useReloadBlockerContext};
