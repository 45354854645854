import React from 'react';
import PropTypes from 'prop-types';

import {RaisedButton} from '../Branding';
import {Row} from '../Grid';
import {GutterInner} from '../Spacing';

import styled from 'styled-components';

const buttonConfigDefaults = {
  color: 'blue',
};

const ButtonGroup = ({buttons, template, groupIndex}) => (
  <Group template={template} groupIndex={groupIndex}>
    {buttons.map((config, index) => {
      if (!config) return null;

      if (config?.component && !config?.children) return (
        <ButtonWrapper key={`button-group-button-${index}`}>
          {config.component}
        </ButtonWrapper>
      )
      const ButtonComponent = Button;

      const props = {
        ...buttonConfigDefaults,
        ...config,
      };

      return (
        <ButtonWrapper key={`button-group-button-${index}`} >
          <ButtonComponent {...props}/>
        </ButtonWrapper>
      );
    })}
  </Group>
);

// If we're rendering a full-length test, we need to make sure the "center" section doesn't move when "right" section changes width.
// So we'll use a static width and row-reverse to handle the gymnastics.
const ButtonBar = ({buttonGroups, color, template}) => (
  <Container color={color}>
    <Wrapper>
      {buttonGroups.map((group, index) => !!group.length &&
        <ButtonGroup groupIndex={index} template={template} key={`button-group-${index}`} buttons={template === 'fullLengthTest' && index === 2 ? group.reverse() : group}/>
      )}
    </Wrapper>
  </Container>
);


const Container = styled.div`
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.lightBorderColor};
  background: ${({theme, color}) => theme[color] || theme.lightBarBackground};
  padding: 26px 0px;
  height: auto;
  transform: translate3d(0,0,0);
`;

const Wrapper = styled(GutterInner)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: row wrap;
  height: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;

const Group = styled(Row)`
  align-items: center;
  width: auto;
  flex-flow: row;
  height: auto;
  ${({template, groupIndex}) => template === 'fullLengthTest' &&`
    width: 122px;
  `}
  ${({template, groupIndex}) => template === 'fullLengthTest' && groupIndex === 2 && `
    flex-flow: row-reverse;
  `}

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
    margin-bottom: 15px;

    &:last-child {
      margin: 0px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    margin: 0px 0px 15px 0px;
  }
`;

const Button = styled(RaisedButton)`
  width: 100%;
  min-width: 150px;
  text-transform: uppercase;
  font-weight: 600;
`;

ButtonBar.propTypes = {
  buttonGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          component: PropTypes.component,
          onClick: PropTypes.func,
          children: PropTypes.node,
          disabled: PropTypes.bool,
        }),
        PropTypes.bool,
      ])
    )
  )
};

ButtonBar.defaultProps = {
  buttonGroups: [],
};

export default ButtonBar;
