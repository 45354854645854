import React from 'react';

import {useTestNavigatorContext} from '../../../../contexts/TestNavigator';
import {IconButton} from '../../../../components/Branding/Buttons';
import {findByRenderType} from '@bootcamp/shared/src/util';
import {H3, H4, Body3} from '../../../../components/Typography';
import {ArrowMaximize as Expand} from '@styled-icons/fluentui-system-filled/ArrowMaximize';
import QuestionStats from '../../../QuestionBank/QuestionStats';
import QBankSettings from '../../../../components/Modal/components/QBankSettings';
import {useModalContext} from '../../../../contexts/Modal';
import {useTimerContext} from '../../../../contexts/TestTimer';
import {useUserDataContext} from '../../../../contexts/UserData';

import {Cog} from '@styled-icons/heroicons-solid/Cog';

import styled, {css} from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 81px;
  width: 100%;
  padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.xl}`};
  background: white;
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  position: relative;
  z-index: 2;
   ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => `${theme.layouts.spacing.s} ${theme.layouts.spacing.m}`};
    height: 56px;
    min-height: auto;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(100% - 350px);
  width: auto;
  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100% - 200px);
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    max-width: 100%;
  }
`;
const Heading = styled.div`
  color: ${({theme, themePalette}) => themePalette ? themePalette.default : theme.colors.brandPalette.violet.default};
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Header = styled(H4)`
  font-weight: normal;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const Type = styled(H3)`
  font-weight: normal;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  font-size: 16px;
  line-height: 19.2px;
`;

const BlockTitle = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  flex: 1;

  ${({theme}) => theme.mediaQueries.laptop} {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
  }
`;

function getBlockTypeDisplay(type) {
  return type === 'lesson'
    ? 'Lesson'
    : type === 'questionSet'
    ? 'Quiz'
    : type === 'startBlock'
    ? 'Introduction'
    : '';
}

const ToggleSidebarButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 15px;
  z-index: 4;
  display: ${({showingSidebar}) => showingSidebar ? 'none' : 'flex'};
  margin-right: 16px;
  svg {
    fill: ${({theme}) => theme.colors.neutralsPalette.dark};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }


  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-right: 8px;
  }
  box-shadow: none;
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};

`;

const ToggleSidebarIcon = styled(Expand).attrs(props => ({size: 20}))`
  ${({lightMode}) => lightMode ? css`
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  ` : css`
    color: white;
  `}
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 24px;
    width: 24px;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 18px;
    width: 18px;
  }
`;

const StyledQuestionStats = styled(QuestionStats)`
  background-color: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const RightContainer = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TimerText = styled(Header)`
  display: flex;
  cursor: pointer;

  span {
    display: block;
    width: 85px;

    &:first-child {
      width: auto;
      margin-right: 5px;
    }
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    span {
      width: 75px;
    }
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    flex-direction: column;
  }

`;
const ToggleSettingsButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 15px;
  background: white;
  z-index: 4;
  border: 1px solid rgb(237, 237, 240);
  border-radius: 100%;
  svg {
    fill: ${({theme}) => theme.colors.neutralsPalette.dark};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }


  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 8px;
  }
  box-shadow: none;
`;
const ToggleSettingsButtonIcon = styled(Cog)`
  fill: #85868E;
`;


const TopBar = ({match, showingSidebar, setShowingSidebar, themePalette, blockRef}) => {

  const {
    variables: {
      blockIndex,
      testBlockConnections,
      quizProgress,
    }
  } = useTestNavigatorContext();
  const {modalDispatch} = useModalContext();
  const {searchUserInteractions} = useUserDataContext();
  const hideTimer = searchUserInteractions('hideTimer') === true;

  const activeBlock = testBlockConnections[blockIndex]?.testBlock;
  const {type: blockType, title: blockTitle, components} = activeBlock || {};

  const blockTypeDisplay = getBlockTypeDisplay(blockType);
  const number = findByRenderType(components, 'number', null);

  const darkMode = false;

  const {questions} = quizProgress[blockIndex];
  const currentIndex = (questions?.findIndex(({current}) => current) || 0) + 1;

  const score = questions ? questions
    .reduce(({correct, incorrect, total}, {didSelectCorrectAnswer, didCheck}) => ({
      correct: correct + (didCheck && didSelectCorrectAnswer),
      incorrect: incorrect + (didCheck && !didSelectCorrectAnswer),
      total: total + 1
    }), {correct: 0, incorrect: 0, total: 0}) : {};

  const {timeElapsed, toggleTimer, timerActive} = useTimerContext();
  return (
    <Container type={blockType}>
      <ToggleSidebarButton showingSidebar={showingSidebar} lightMode={!darkMode} type={'primary'} size={'large'} children={<ToggleSidebarIcon lightMode={!darkMode}/>} onClick={() => setShowingSidebar(!showingSidebar)} />
      <TitleWrapper>
        {!['questionSet', 'passage'].includes(blockType) && (
          <Heading type={blockType} themePalette={themePalette}>
            <Type themePalette={themePalette}>{blockTitle === 'startBlock' ? `Chapter Outline` : blockTitle}</Type>
          </Heading>
        )}
        {/* <BlockTitle type={blockType}>
          {blockTitle === 'startBlock' ? `Chapter Outline` : blockTitle}
        </BlockTitle> */}
      </TitleWrapper>
      {(['questionSet', 'passage'].includes(blockType)) && (
        <Header>Question {currentIndex} of {questions.length}</Header>
      )}
      {(['questionSet', 'passage'].includes(blockType)) && (
        <RightContainer>
          {!hideTimer &&
            <TimerText onClick={() => toggleTimer()}>
              <span>{timerActive ? 'Time Taken:' : 'Timer Paused:'}</span>
              <span>{timeElapsed}</span>
            </TimerText>
          }
          {/* <ToggleSettingsButton
            type={'primary'}
            size={'large'}
            themePalette={themePalette}
            children={<ToggleSettingsButtonIcon size={28}/>}
            onClick={() => {
              modalDispatch({
                type: 'open',
                component: QBankSettings,
                componentProps: {
                  themePalette,
                },
                enableClickClose: true,
                modalContainerStyle: theme => `
                background: rgba(94, 110, 141, 0.9);
                padding: 10vh 10vw;
                align-items: center;
                ${theme.mediaQueries.laptop} {
                  background: rgba(94, 110, 141, 0.9);
                  padding: 0;
                }
                `
              })
            }}
          /> */}
        </RightContainer>
      )}
    </Container>
  );
}

TopBar.propTypes = {};
TopBar.defaultProps = {};

export default TopBar;
