import React, {useState, useEffect} from 'react';

import {ChevronRight} from '@styled-icons/feather/ChevronRight';
import {H1, H3, Body3} from '../../components/Typography';
import {Label3} from '../../components/Typography/next';
import {Column, Row} from '../../components/Grid';
import {ProfilePicture} from '../pages/AccountPage/components/Cards/PersonalDetails';
import {CalendarLtr} from '@styled-icons/fluentui-system-filled/CalendarLtr';

import {Tooltip} from 'react-tooltip';
import {IntercomAPI} from 'react-intercom';

import styled, {css} from 'styled-components';

import {useUserDataContext} from '../../contexts/UserData';
import {getStreakDetails} from '@bootcamp/shared/src/util';
import {LocalFireDepartment} from '@styled-icons/material/LocalFireDepartment';
import {CheckmarkOutline} from '@styled-icons/evaicons-outline/CheckmarkOutline';
import {getUnixTimestamp, findStartAndEndDates} from '@bootcamp/shared/src/util';
import moment from 'moment';
import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {useHistory} from 'react-router-dom';

const Title = styled(H1)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 32px;
  width: 100%;

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 8px;
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  }
`;
const Wrapper = styled.div`
`;
const PillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 40px;
`;
const StyledDefaultProfilePicture = styled(ProfilePicture)`
`;
const CardContainer = styled.a`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.special.pearl};
  padding: ${({theme}) => theme.layouts.spacing.ms} ${({theme}) => theme.layouts.spacing.m};
  width: 100%;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  transition: all 100ms ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    box-shadow: ${({theme}) => theme.elevation.shadow.high};
  }


  ${({complete}) => complete && css`
    background: transparent;
    border: 1px solid ${({theme}) => theme.overlays.opacity.light._200};
    box-shadow: none;
    &:hover {
      transform: scale(1);
      background: ${({theme}) => theme.overlays.opacity.light._50};
      box-shadow: none;
    }

    ${IconText} {
      color: white;
    }
    ${TextWrapper} {
      display: none;
    }
  `}

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: 0px;
    box-shadow: none;
  }

`;
const ContentWrapper = styled.div`
  margin-left: ${({theme}) => theme.layouts.spacing.s};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const StyledIcon = styled.svg`
  width: 24px;
  height: 24px;
  min-width: 24px;
  fill: ${({theme, themeColor}) => themeColor ? theme.colors.brandPalette[themeColor].dark : theme.colors.neutralsPalette.extraDark};
`;
const IconText = styled(H3)`
`;
const TextWrapper = styled(Body3)`
  color: #86858E;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;


  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const Arrow = styled(ChevronRight).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-left: auto;
`;

const Checkmark = styled(CheckmarkOutline).attrs(props => ({size: 24}))`
  color: white;
  margin-left: auto;
`;

const BadgeIcon = styled(Badge).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-right: 8px;
  height: 16px;
  width: 16px;
  path {
    fill: white;
  }

`;

const Pill = styled(Label3)`
  background: ${({theme}) => theme.overlays.opacity.light._100};
  padding: 8px 12px;
  height: 32px;
  border-radius: 100px;
  color: ${({theme}) => theme.overlays.opacity.light._500};
  display: flex;
  align-items: center;
  transition: all 100ms ease 0s;
  cursor: pointer;

  &:hover {
    background: rgba(255,255,255,.2);
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 8px 16px;
  }
`;

const TitleBar = styled(Row)`
  align-items: center;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const DateString = styled(Pill)`
  ${({theme}) => theme.mediaQueries.tablet} {
    border: none;
    padding: 0;
    background: transparent;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const StreakWrapper = styled.div`
`;

const StreakString = styled(Pill)`
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.tablet} {
    ${({left}) => left && css`
      border-radius: 8px 0px 0px 8px;
    `}
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

const StreakFailWarning = styled(Pill)`
  color: ${({theme}) => theme.colors.brandPalette.orange.default};
  margin-left: ${({theme}) => theme.layouts.spacing.s};
  height: 50px;

  ${({theme}) => theme.mediaQueries.tablet} {
    height: 46px;
    margin-left: 0;
    border-radius: 0px 8px 8px 0px;
  }
`;

const FireIcon = styled(LocalFireDepartment).attrs(props => ({size: 18}))`
  color: ${({theme, streakActive}) => streakActive ? theme.colors.brandPalette.orange.default : `rgba(255, 255, 255, 1)`};
  margin-right: 8px;
`;

const TestDateContainer = styled(Pill)`
  position: relative;
  input {
    width: 95%;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    border: none;
    outline: none;
  }
`;
const CalendarIcon = styled(CalendarLtr).attrs(props => ({size: 18}))`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-right: 8px;
`;

const TestDatePicker = () => {
  const {bootcamp, searchUserInteractions, saveUserInteraction} = useUserDataContext();

  const testDateKey = ['nclex'].includes(bootcamp) ? `${bootcamp}_testDate` : 'testDate'
  const currentTestDate = searchUserInteractions(testDateKey);
  const hasCurrentTestDate = typeof currentTestDate === 'string';

  const [testDate, setTestDate] = useState(currentTestDate);
  const ref = React.useRef(null);

  function handleClick() {
    ref?.current?.showPicker();
  }

  function formatDate(date) {
    const formattedDate = moment(date).format('MMM Do');
    return formattedDate;
  }

  async function handleInputChange(e) {
    const updatedTestDate = e.target.value;

    setTestDate(updatedTestDate);

    try {
      await saveUserInteraction(testDateKey, JSON.stringify(updatedTestDate));
      await IntercomAPI('update', { [`${bootcamp}_test_date`]: getUnixTimestamp(updatedTestDate)});
    } catch (error) {
      console.log('erroring', error);
    }
  }

  useEffect(() => {
    hasCurrentTestDate && setTestDate(currentTestDate);
  }, [currentTestDate]);

  return (
    <TestDateContainer onClick={handleClick}>
      <CalendarIcon size={18} />
      {hasCurrentTestDate ? `Test date: ${formatDate(testDate)}` : 'Add Test Date'}
      <input ref={ref} type={'date'} onChange={handleInputChange} min={moment().format('YYYY-MM-DD')} />
    </TestDateContainer>
  )
}


const Card = ({icon, text, interactionKey, containerProps, checkIfComplete}, index) => {
  const {interactions, cognitoUser} = useUserDataContext();
  // if (!cognitoUser || Object.keys(cognitoUser).length === 0) return;
  // const interaction = interactions?.find(({id}) => id === `${cognitoUser && cognitoUser?.getUsername()}-${interactionKey}`) || {};
  const complete = false;
  const Icon = icon.component;

  return (
    <CardContainer key={interactionKey} {...containerProps} complete={complete}>
      <StyledIcon as={Icon} size={24} themeColor={icon.themeColor}/>
      <ContentWrapper>
        <IconText themeColor={icon.themeColor} children={icon.text}/>
        <TextWrapper title={text} children={text}/>
      </ContentWrapper>
      {complete
        ? <Checkmark />
        : <Arrow />
      }
    </CardContainer>
  );
}

const StyledColumn = styled(Column)`
  height: auto;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 1000000000;
  background: rgba(21,26,30,0.6);
`;

const currentHour = new Date().getHours();
const greetingMessage =
  currentHour >= 4 && currentHour < 12 ? // after 4:00AM and before 12:00PM
  'Good morning' :
  currentHour >= 12 && currentHour <= 17 ? // after 12:00PM and before 6:00pm
  'Good afternoon' :
  currentHour > 17 || currentHour < 4 ? // after 5:59pm or before 4:00AM (to accommodate night owls)
  'Good evening' :
  'Hello!'

const GettingStarted = ({title=greetingMessage, actions, hideStreak}) => {
  const {interactions, bootcamp, searchUserInteractions, cognitoUser, saveUserInteraction, userModel, bootcampMembershipGroup} = useUserDataContext();
  const {streak, date} = searchUserInteractions(`Streak-DWU-${bootcamp}`);
  const name = cognitoUser?.attributes?.name;
  const {push} = useHistory();
  if (!interactions) return null;

  const {streakLength, timeRunningOut} = getStreakDetails(streak, date);

  const currentDate = moment().format('MMMM').length <= 4 // if full formatted date is 4 characters or fewer don't format with "."
    ? moment().format('MMM Do')
    : moment().format('MMM. Do')

  const {endDate, latestEnd} = findStartAndEndDates(userModel?.memberships, bootcampMembershipGroup);

  return !!actions.length &&
    <StyledColumn>
      <TitleBar>
        <StyledDefaultProfilePicture size={78} showPrompt={false} imgComponent={AvatarImage}/>
        <Wrapper>
          <Title children={`${title}${name ? `, ${name}!` : '!'}`} />
          <PillWrapper>
            <TestDatePicker bootcamp={bootcamp} saveUserInteraction={saveUserInteraction}/>
            {latestEnd && <StreakWrapper onClick={() => push(`/${bootcamp}/account/memberships`)}>
              <DateString>
                <BadgeIcon />Membership expires: {moment(endDate).format('MMM Do, YYYY')}</DateString>
            </StreakWrapper>}
            {!hideStreak &&
              <StreakWrapper>
                <StreakString left={timeRunningOut} data-tooltip-id={'streak-tooltip'}>
                  <FireIcon streakActive={streakLength > 0} /> {streakLength} day streak
                  <StyledTooltip id={'streak-tooltip'} content={'Log in each day to keep your streak going!'} place={'right'}/>
                </StreakString>
              </StreakWrapper>
            }
          </PillWrapper>
        </Wrapper>
      </TitleBar>
      <Container>
        {actions.map((props) => !props.hide && <Card {...props} />)}
      </Container>
    </StyledColumn>
}

GettingStarted.propTypes = {};
GettingStarted.defaultProps = {
  title: greetingMessage
};

export default GettingStarted;
