/* eslint-disable */
// Generated by CoffeeScript 1.7.1
module.exports = function($) {
  var Bisect, Circle, Cubes, Eyeballer, Holes, MAIN_HEIGHT, MAIN_WIDTH, Midpoint, PADDING, Parallelogram, QUIZZES, Quiz, ROUNDS, RightAngle, THICKNESS, TwoAngles, clearQuiz, createQuiz, dist, erase, gameOver, getAngle, nextQuestion, questionText, randomInt, reset, round, sanitize, scores, screenLine, screenMove, startTimer, step, timeReset, timeUsed, timer,
    __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  MAIN_WIDTH = 500;

  MAIN_HEIGHT = 500;

  ROUNDS = 3;

  QUIZZES = 8;

  THICKNESS = 1.7;

  step = 0;

  round = 1;

  scores = [];

  timeUsed = 0;

  timer = {};
  let layerIds = ["layer-1", "layer-2", "layer-3", "layer-4"];
  let questionIds = ["amount-1", "amount-2", "amount-3", "amount-4", "amount-5"];

  PADDING = 10;
  let numberWord = function(n) {
  switch (n) {
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
  }
};

  let Page = (function() {
    function Page(structure) {
      this.structure = structure;
      this.answer = "z";
      this.options = [];
      this.paintQuery();
      this.sequentialQuestions();
    }

    Page.prototype.paintQuery = function() {
      var pq, valid, _i, _len, _ref;
      while (true) {
        valid = true;
        this.sidesQty = randomInt(5) + 1;
        _ref = this.structure.paintQueries;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          pq = _ref[_i];
          if (this.sidesQty === pq) {
            valid = false;
            break;
          }
        }
        if (!valid) {
          continue;
        }
        this.getTally();
        if (this.tally !== 0) {
          break;
        }
      }
      return this.structure.paintQueries.push(this.sidesQty);
    };

    Page.prototype.populateQuestions = function() {
      var candidate, history, hst, i, valid, _i, _len, _results;
      this.options.push(this.tally);
      history = [];
      i = 0;
      while (i < questionIds.length) {
        while (true) {
          candidate = randomInt(5) + 1;
          if (Math.random() < 0.5) {
            candidate = -candidate;
          }
          if (candidate + this.tally < 1 || candidate + this.tally > this.structure.cubes.length) {
            continue;
          }
          valid = true;
          for (_i = 0, _len = history.length; _i < _len; _i++) {
            hst = history[_i];
            if (candidate === hst) {
              valid = false;
              break;
            }
          }
          if (!valid) {
            continue;
          }
          history.push(candidate);
          this.options.push(this.tally + candidate);
          break;
        }
        i++;
      }
      this.options.sort(function(a, b) {
        return a - b;
      });
      i = 0;
      _results = [];
      while (i < questionIds.length) {
        if (this.options[i] === this.tally) {
          this.correctOption = i;
        }
        _results.push(i++);
      }
      return _results;
    };

    Page.prototype.sequentialQuestions = function() {
      var choices, i, _i, _results;
      choices = this.tally;
      if (choices > 5) {
        choices = 5;
      }
      this.correctOption = randomInt(choices);
      _results = [];
      for (i = _i = 0; _i <= 4; i = ++_i) {
        _results.push(this.options.push(this.tally - this.correctOption + i));
      }
      return _results;
    };

    Page.prototype.displayQuestions = function() {
      var i, _results;
      document.getElementById("query").innerHTML = numberWord(this.sidesQty);
      i = 0;
      _results = [];
      while (i < questionIds.length) {
        document.getElementById(questionIds[i]).innerHTML = questionText(this.options[i]);
        _results.push(i++);
      }
      return _results;
    };

    Page.prototype.getTally = function() {
      var count, sc, _i, _len, _ref;
      count = 0;
      _ref = this.structure.cubes;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        sc = _ref[_i];
        if (sc.painted === this.sidesQty) {
          count++;
        }
      }
      return this.tally = count;
    };

    Page.prototype.display = function() {
      var c, ctx;
      this.displayQuestions();
      c = document.getElementById('puzzle');
      ctx = c.getContext("2d");
      return this.structure.printCubes(ctx);
    };

    Page.prototype.explicate = function() {
      var c, color, ctx, i, sc, _i, _len, _ref, _results;
      i = 0;
      _results = [];
      while (i < layerIds.length) {
        c = document.getElementById(layerIds[i]);
        ctx = c.getContext("2d");
        _ref = this.structure.cubes;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          sc = _ref[_i];
          if (sc.y === i) {
            color = "white";
            if (sc.painted === this.sidesQty) {
              color = "#5F5";
            }
            sc.print(ctx, this.structure.solutionScale, this.structure.perspective, color, this.structure.xOff, this.structure.yOff);
          } else if (sc.y > i) {
            color = "#EEE";
            sc.print(ctx, this.structure.solutionScale, this.structure.perspective, color, this.structure.xOff, this.structure.yOff);
          }
        }
        _results.push(i++);
      }
      return _results;
    };

    return Page;

  })();
	let pe = function (k) {
		switch (true) {
			case (k < 0.1): return 0; break;
			case (k < 1.3): return 99.6; break;
			case (k < 1.6): return 99.3; break;
			case (k < 2.0): return 99.1; break;
			case (k < 2.4): return 98.7; break;
			case (k < 2.8): return 98.4; break;
			case (k < 3.2): return 97.8; break;
			case (k < 3.6): return 97.4; break;
			case (k < 4.0): return 96.5; break;
			case (k < 4.4): return 96.1; break;
			case (k < 4.8): return 95.7; break;
			case (k < 5.2): return 94.5; break;
			case (k < 5.6): return 93.8; break;
			case (k < 6.0): return 93.3; break;
			case (k < 6.4): return 92.8; break;
			case (k < 6.8): return 92.2; break;
			case (k < 7.2): return 91.7; break;
			case (k < 12.8): return 86.4; break;
			case (k < 18.8): return 71.6; break;
			default : return 68.7; break;
		}
	};

  gameOver = function() {
    var tot;
    tot = 0;
    if (scores.length > 0) {
      tot = (scores.reduce(function(x, y) {
        return x + y;
      })) / scores.length;
      tot = Math.round(tot * 100) / 100;
    }
		// if (datbootcamp_plugin_obj.logged_in) {
		// 	$.ajax({
		// 		url: datbootcamp_plugin_obj.ajaxurl,
		// 		method: "POST",
		// 		data: {
		// 			action: 'submit_leaderboard_record',
		// 			score: tot,
		// 			leaderboardTitle: 'PAT Trainer'
		// 		},
		// 		success:function( response ) {
		// 		},
		// 	});
		// }
    $('#position').text('Click "Reset" to Start New Exam');
		$("#display-area").html('<p style="text-align: center; font-size: 1.3em; margin: 20px 0 20px 0;">Training Complete</p>');
		$("#display-area").append('<p style="text-align: center; font-size: 18px; margin: 20px 0;">Average Deviation: ' + tot + ' <span style="font-size: 14px;"> (lower is better)</span> - ' + pe(tot) + '%</p>');
		$("#display-area").append('<p style="text-align: center;">Score distribution of last 1000 games:</p>');
					$("#display-area").append('<p style="text-align: center;"><img style="cursor:pointer;" id="fb-share" src="/wp-includes/images/fb-share.png"></p>');
		$("#display-area").append('<img style="display: block; margin: 30px auto;" src="https://s3.amazonaws.com/datbootcamp/trainergraph.png">');
		// $("#fb-share").click(function(){
		// 	var w = 600;
		// 	var h = 436;
		// 	var l = Number((screen.width / 2) - (w / 2));
		// 	var t = Number((screen.height / 2) - (h / 2));
		// 	var u = window.location.href;
		// 	var s = 'I scored a '+tot+' on the PAT Trainer Game!';
		// 	if (tot <= 18.8) s = 'I scored better than '+pe(tot)+'% on the PAT Trainer Game!';
		// 	window.open( 'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(u)+'&title='+encodeURIComponent(s)+'&description='+encodeURIComponent('Studying for the DAT? Use this PAT Trainer Game to sharpen your perceptual skills.')+'&picture='+encodeURIComponent('https://s3.amazonaws.com/datbootcamp/fb/ptrainerfb.png'),'facebookshare','width='+w+',height='+h+',top='+t+',left='+l);
		// });
		$('#display-area').append('<p style="text-align: center; font-size: 1em;">Time Used: ' + timeUsed + '</p>');
		// $('#display-area').append('<p style="text-align: center; font-size: 1em; margin: 100px 0 0 0;">Did you make the leaderboards? Remember, lower is better!</p>');
		// $('#display-area').append('<p style="text-align: center; font-size: 1em; margin: 20px auto;"><a style="float: none !important;" class="nextbutton leaderboard-trigger">Show Leaderboards</a></p>');
		// $('.leaderboard-trigger').click(function () {
    //   window.displayLeaderboard('today');
		// 	$('#leaderboard-modal').modal({
		// 		fadeDuration: 250
		// 	});
		// });
		$('#next').hide();
		return
  };

  nextQuestion = function() {
		if (scores.length != (step + 1) + ((round - 1) * QUIZZES)) {
			return
		}
    clearQuiz();
    ++step;
    if (step >= QUIZZES) {
      step = 0;
      ++round;
    }
		if (round == ROUNDS + 1 && step == 0) {
			++step;
			return gameOver();
		} else if (round > ROUNDS) {
      return
    } else {
      return createQuiz(step);
    }
  };

  window.onload = function() {
    $('#next').on("click", nextQuestion);
    $('#game-reset').on("click", reset);
    sanitize();
    return createQuiz(step);
  };

  Quiz = (function() {
    function Quiz() {
      this.mouseX = 0;
      this.mouseY = 0;
      this.solutionX = 0;
      this.solutionY = 0;
      startTimer();
    }

    Quiz.prototype.getRandomPoint = function(dim) {
      return Math.round(Math.random() * (dim - 40)) + 20;
    };

    Quiz.prototype.setTitle = function(text) {
      return $('#position').text(text);
    };

    return Quiz;

  })();

  Holes = (function(_super) {
    var ANS_MULT, Fold, FoldedSet, Line, OptionObject, Polygon, PunchedHoleList, QUES_MULT, TableSpot, Vertex, answerCanvases, clearSteps, createAndDisplayFolds, executeFold, explicateStep, foldLines, folds, freshSheet, freshTable, intersection, listPoints, locateHole, outlineList, pointMatch, populateFoldLines, printList, randomInt, relocateSpots, revealExplication, stepCanvases, tableSpots;

    __extends(Holes, _super);

    foldLines = [];

    answerCanvases = ["can-A", "can-B", "can-C", "can-D", "can-E"];

    stepCanvases = ["step0", "step1", "step2", "step3", "step4"];

    QUES_MULT = 11;

    ANS_MULT = 11;

    folds = [];

    tableSpots = {};

    function Holes() {
      this.handleIncorrect = __bind(this.handleIncorrect, this);
      this.handleCorrect = __bind(this.handleCorrect, this);
      var can, dashed, el, elem, elem2, i, smHeight, wrap, _i, _j, _k, _l, _len, _len1, _m, _ref;
      startTimer();
      this.setTitle("Click on the correct Pattern");
      smHeight = 91;
      elem = $('<div></div>');
      elem.width(MAIN_WIDTH);
      elem.height(smHeight);
      elem.css({
        margin: "60px 0 100px"
      });
      $('#display-area').html(elem);
      for (_i = 0, _len = stepCanvases.length; _i < _len; _i++) {
        el = stepCanvases[_i];
        wrap = $('<div></div>');
        wrap.width(smHeight);
        wrap.height(smHeight);
        wrap.css({
          position: "relative"
        });
        wrap.css({
          display: "inline-block"
        });
        wrap.css({
          marginLeft: "7px"
        });
        dashed = $('<div></div>');
        dashed.width(smHeight);
        dashed.height(smHeight);
        dashed.css({
          boxSizing: "border-box"
        });
        dashed.css({
          position: "absolute"
        });
        dashed.css({
          border: "3px black dashed"
        });
        can = $('<canvas></canvas>');
        can.width(smHeight);
        can.height(smHeight);
        can.attr("width", "" + smHeight);
        can.attr("height", "" + smHeight);
        can.css({
          position: "relative"
        });
        can.attr("id", el);
        wrap.append(dashed);
        wrap.append(can);
        elem.append(wrap);
      }
      elem2 = $('<div></div>');
      elem2.width(MAIN_WIDTH);
      elem2.height(smHeight);
      elem2.css({
        margin: "60px 0 140px 2px"
      });
      elem2.attr("id", "answers");
      for (_j = 0, _len1 = answerCanvases.length; _j < _len1; _j++) {
        el = answerCanvases[_j];
        can = $('<canvas></canvas>');
        can.width(smHeight);
        can.height(smHeight);
        can.attr("width", "" + smHeight);
        can.attr("height", "" + smHeight);
        can.attr("id", el);
        can.css({
          border: "thin solid"
        });
        can.css({
          marginLeft: "5px"
        });
        can.css({
          cursor: "pointer"
        });
        elem2.append(can);
      }
      $('#display-area').append(elem2);
      $('#display-area').append('<h5 id="single-result" style="margin: 0; padding: 0;"></h5>');
      populateFoldLines();
      this.answer = "z";
      for (i = _k = 0; _k <= 2; i = ++_k) {
        folds[i] = false;
      }
      this.correctOption = randomInt(5);
      tableSpots = freshTable();
      this.options = [];
      for (i = _l = 0, _ref = answerCanvases.length - 1; 0 <= _ref ? _l <= _ref : _l >= _ref; i = 0 <= _ref ? ++_l : --_l) {
        this.options[i] = new OptionObject();
      }
      createAndDisplayFolds(this);
      this.generateFalseAnswers();
      this.printAnswerChoices();
      $('#answers > canvas').hover(function() {
        return $(this).css({
          backgroundColor: "#EEE"
        });
      }, function() {
        return $(this).css({
          backgroundColor: "transparent"
        });
      });
      for (i = _m = 0; _m <= 4; i = ++_m) {
        if (i === this.correctOption) {
          $("#" + answerCanvases[i]).on("click", this.handleCorrect);
        } else {
          $("#" + answerCanvases[i]).on("click", this.handleIncorrect);
        }
      }
    }

    Holes.prototype.handleCorrect = function() {
      return this.handleResult(0, "Correct, 0 points");
    };

    Holes.prototype.handleIncorrect = function() {
      return this.handleResult(5, "Incorrect, 5 points");
    };

    Holes.prototype.handleResult = function(r, text) {
      var cell, table, tot;
      $('#answers > canvas').unbind();
      $('#answers > canvas').css({
        cursor: "inherit"
      });
      revealExplication(this);
      clearInterval(timer);
      $('#single-result').text(text);
      table = $('table')[0];
      cell = table.rows[step].cells[round];
      $(cell).text(r);
      scores.push(r);
      tot = 0;
      if (scores.length > 0) {
        tot = (scores.reduce(function(x, y) {
          return x + y;
        })) / scores.length;
        tot = Math.round(tot * 100) / 100;
      }
      $('#average').text(tot);
      return $('#position').text('Click "Next" Button to Continue');
    };

    randomInt = function(scale) {
      return Math.floor(Math.random() * scale);
    };

    createAndDisplayFolds = function(page) {
      var c, ctx, i, paperStages, _i, _ref;
      paperStages = [];
      paperStages.push(freshSheet());
      for (i = _i = 0, _ref = folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        c = document.getElementById(stepCanvases[i]);
        ctx = c.getContext("2d");
        if (i !== 0) {
          printList(ctx, paperStages[i]);
        }
        folds[i] = new Fold();
        folds[i].generate(tableSpots, paperStages[i]);
        paperStages[i + 1] = executeFold(folds[i], paperStages[i]);
        relocateSpots(tableSpots, folds[i]);
      }
      c = document.getElementById(stepCanvases[3]);
      ctx = c.getContext("2d");
      printList(ctx, paperStages[3]);
      locateHole(page);
      c = document.getElementById(stepCanvases[4]);
      ctx = c.getContext("2d");
      return outlineList(ctx, paperStages[3], false, page);
    };

    relocateSpots = function(table, fold) {
      var l, lVal, mir, sVal, subject, tmi, tmj, tmk, _i, _len, _results;
      l = foldLines[fold.location];
      lVal = l.v1.mode[l.angle];
      _results = [];
      for (_i = 0, _len = table.length; _i < _len; _i++) {
        tmi = table[_i];
        if (tmi.members.length === 0) {
          continue;
        }
        subject = tmi;
        sVal = subject.mode[l.angle];
        if ((sVal > lVal && fold.orientation) || (sVal < lVal && !fold.orientation)) {
          mir = subject.mirror(fold.location);
          _results.push((function() {
            var _j, _k, _len1, _len2, _ref, _results1;
            _results1 = [];
            for (_j = 0, _len1 = table.length; _j < _len1; _j++) {
              tmj = table[_j];
              if (tmj.identical(mir)) {
                _ref = subject.members;
                for (_k = 0, _len2 = _ref.length; _k < _len2; _k++) {
                  tmk = _ref[_k];
                  tmj.addSpot(tmk);
                }
                subject.clear();
                break;
              } else {
                _results1.push(void 0);
              }
            }
            return _results1;
          })());
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    };

    printList = function(ctx, polyList) {
      var pl, _i, _len, _results;
      ctx.lineWidth = 2;
      ctx.strokeStyle = "black";
      _results = [];
      for (_i = 0, _len = polyList.length; _i < _len; _i++) {
        pl = polyList[_i];
        _results.push(pl.print(ctx));
      }
      return _results;
    };

    outlineList = function(ctx, polyList, holes, page) {
      var pl, _i, _len, _results;
      _results = [];
      for (_i = 0, _len = polyList.length; _i < _len; _i++) {
        pl = polyList[_i];
        if (pl.queue.length > 2) {
          _results.push(pl.outlineAroundHoles(ctx, holes, page));
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    };

    executeFold = function(fld, oldList) {
      var cnt, i, newList, tempSet, _i, _j, _k, _ref, _ref1, _ref2;
      tempSet = [];
      newList = [];
      cnt = oldList.length;
      for (i = _i = 0, _ref = cnt - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        tempSet[i] = new FoldedSet(fld, oldList[i]);
      }
      for (i = _j = 0, _ref1 = cnt - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
        newList[i] = tempSet[i].base;
      }
      for (i = _k = 0, _ref2 = cnt - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; i = 0 <= _ref2 ? ++_k : --_k) {
        newList[i + cnt] = tempSet[cnt - i - 1].flap;
      }
      i = 0;
      while (i < newList.length) {
        if (newList[i].queue.length < 1) {
          newList.splice(i, 1);
        } else {
          i++;
        }
      }
      return newList;
    };

    intersection = function(v1, v2, fold) {
      var foldPoints, segPoints, sp, _i, _len;
      segPoints = listPoints(v1, v2);
      foldPoints = listPoints(foldLines[fold].v1, foldLines[fold].v2);
      for (_i = 0, _len = segPoints.length; _i < _len; _i++) {
        sp = segPoints[_i];
        if (pointMatch(sp, foldPoints)) {
          return sp;
        }
      }
      return false;
    };

    pointMatch = function(candidate, set) {
      var s, _i, _len;
      for (_i = 0, _len = set.length; _i < _len; _i++) {
        s = set[_i];
        if (candidate.identical(s)) {
          return true;
        }
      }
      return false;
    };

    listPoints = function(v1, v2) {
      var deltaX, deltaY, quant, result, xCur, yCur;
      result = new Array();
      quant = 0;
      deltaX = v2.x - v1.x;
      deltaY = v2.y - v1.y;
      if (deltaX !== 0) {
        quant = Math.abs(deltaX);
      } else {
        quant = Math.abs(deltaY);
      }
      result.push(v1);
      while (result.length < quant) {
        xCur = result[result.length - 1].x;
        yCur = result[result.length - 1].y;
        xCur += deltaX < 0 ? -1 : deltaX > 0 ? 1 : 0;
        yCur += deltaY < 0 ? -1 : deltaY > 0 ? 1 : 0;
        result.push(new Vertex(xCur, yCur));
      }
      result.push(v2);
      return result;
    };

    revealExplication = function(page) {
      var c, ctx, holeList, i, paperStages, punchedHoles, tableSpots2, _i, _ref;
      clearSteps();
      holeList = [];
      paperStages = [];
      paperStages.push(freshSheet());
      tableSpots2 = freshTable();
      punchedHoles = tableSpots[page.holeSpot].members;
      for (i = _i = 0, _ref = folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        c = document.getElementById(stepCanvases[i]);
        ctx = c.getContext("2d");
        holeList = explicateStep(punchedHoles, tableSpots2);
        outlineList(ctx, paperStages[i], holeList, page);
        paperStages[i + 1] = executeFold(folds[i], paperStages[i]);
        relocateSpots(tableSpots2, folds[i]);
      }
      c = document.getElementById(stepCanvases[3]);
      ctx = c.getContext("2d");
      outlineList(ctx, paperStages[3], false, page);
      c = document.getElementById(stepCanvases[4]);
      ctx = c.getContext("2d");
      return outlineList(ctx, paperStages[3], false, page);
    };

    clearSteps = function() {
      var arrLength, c, ctx, dimension, i, _i, _ref, _results;
      dimension = ANS_MULT * 8 + 3;
      arrLength = stepCanvases.length;
      _results = [];
      for (i = _i = 0, _ref = arrLength - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        c = document.getElementById(stepCanvases[i]);
        ctx = c.getContext("2d");
        _results.push(ctx.clearRect(0, 0, dimension, dimension));
      }
      return _results;
    };

    explicateStep = function(punchedHoles, tSpots) {
      var inThere, result, tb, _i, _len;
      result = [];
      inThere = function(arr, spots) {
        var sp1, sp2, _i, _j, _len, _len1, _ref;
        _ref = arr.members;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          sp1 = _ref[_i];
          for (_j = 0, _len1 = spots.length; _j < _len1; _j++) {
            sp2 = spots[_j];
            if (sp1.identical(sp2)) {
              return true;
            }
          }
        }
        return false;
      };
      for (_i = 0, _len = tSpots.length; _i < _len; _i++) {
        tb = tSpots[_i];
        if (inThere(tb, punchedHoles)) {
          result.push(tb);
        }
      }
      return result;
    };

    Fold = (function() {
      function Fold() {
        this.location = 0;
        this.orientation = false;
      }

      Fold.prototype.generate = function(table, polyList) {
        var affected, flip, l, lCandidate, lVal, mir, oCandidate, over, sVal, tbl, under, validity, _i, _len;
        lCandidate = 0;
        oCandidate = false;
        while (true) {
          lCandidate = randomInt(20);
          flip = Math.random() * 20;
          oCandidate = flip < 10;
          validity = true;
          affected = false;
          l = foldLines[lCandidate];
          lVal = l.v1.mode[l.angle];
          over = false;
          under = false;
          for (_i = 0, _len = table.length; _i < _len; _i++) {
            tbl = table[_i];
            if (tbl.members.length < 1) {
              continue;
            }
            sVal = tbl.mode[l.angle];
            if (sVal > lVal) {
              over = true;
              if (oCandidate) {
                mir = tbl.mirror(lCandidate);
                if (!mir.valid()) {
                  validity = false;
                  break;
                }
                if (mir.x !== tbl.x || mir.y !== tbl.y) {
                  affected = true;
                }
              }
            }
            if (sVal < lVal) {
              under = true;
              if (!oCandidate) {
                mir = tbl.mirror(lCandidate);
                if (!mir.valid()) {
                  validity = false;
                  break;
                }
                if (mir.x !== tbl.x || mir.y !== tbl.y) {
                  affected = true;
                }
              }
            }
          }
          if (validity && over && under && affected) {
            break;
          }
        }
        this.location = lCandidate;
        return this.orientation = oCandidate;
      };

      return Fold;

    })();

    FoldedSet = (function() {
      function FoldedSet(fld, poly) {
        var cand, flapPoly, i, j, l, lVal, pVal, poly1, poly2, tempPoint, testHist, testLine, tl, topFold, trimmedPoly, _i, _j, _k, _l, _len, _m, _ref, _ref1, _ref2, _ref3;
        if (poly.queue.length === 0) {
          this.base = new Polygon();
          this.flap = new Polygon();
        } else {
          trimmedPoly = {};
          flapPoly = {};
          testLine = [];
          testHist = [];
          for (i = _i = 0, _ref = poly.queue.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
            j = i + 1;
            if (j >= poly.queue.length) {
              j = 0;
            }
            tempPoint = intersection(poly.queue[i], poly.queue[j], fld.location);
            if (tempPoint === false) {
              continue;
            }
            for (_j = 0, _len = testLine.length; _j < _len; _j++) {
              tl = testLine[_j];
              if (tl.identical(tempPoint)) {
                tempPoint = false;
                break;
              }
            }
            if (tempPoint === false) {
              continue;
            }
            testLine.push(tempPoint);
            testHist.push(i);
          }
          if (testLine.length !== 2 || poly.edgeCase(fld)) {
            l = foldLines[fld.location];
            lVal = l.v1.mode[l.angle];
            pVal = fld.orientation ? poly.key[l.angle].mode[l.angle] : poly.antiKey[l.angle].mode[l.angle];
            if ((pVal > lVal && fld.orientation) || (pVal < lVal && !fld.orientation)) {
              flapPoly = poly;
              trimmedPoly = new Polygon();
            } else {
              trimmedPoly = poly;
              flapPoly = new Polygon();
            }
          } else {
            poly1 = new Polygon();
            poly2 = new Polygon();
            for (i = _k = 0, _ref1 = poly.queue.length - 1; 0 <= _ref1 ? _k <= _ref1 : _k >= _ref1; i = 0 <= _ref1 ? ++_k : --_k) {
              poly1.addVertex(poly.queue[i]);
              if (i === testHist[0]) {
                break;
              }
            }
            poly1.addVertex(testLine[0]);
            poly1.addVertex(testLine[1]);
            cand = testHist[1] + 1;
            if (cand < poly.queue.length) {
              for (i = _l = cand, _ref2 = poly.queue.length - 1; cand <= _ref2 ? _l <= _ref2 : _l >= _ref2; i = cand <= _ref2 ? ++_l : --_l) {
                poly1.addVertex(poly.queue[i]);
              }
            }
            poly2.addVertex(testLine[0]);
            cand = testHist[0] + 1;
            if (cand <= testHist[1]) {
              for (i = _m = cand, _ref3 = testHist[1]; cand <= _ref3 ? _m <= _ref3 : _m >= _ref3; i = cand <= _ref3 ? ++_m : --_m) {
                poly2.addVertex(poly.queue[i]);
              }
            }
            poly2.addVertex(testLine[1]);
            poly1.sanitize();
            poly2.sanitize();
            if ((fld.orientation && poly2.contains(poly.key[foldLines[fld.location].angle])) || (!fld.orientation && poly1.contains(poly.key[foldLines[fld.location].angle]))) {
              trimmedPoly = poly1;
              flapPoly = poly2;
            } else {
              trimmedPoly = poly2;
              flapPoly = poly1;
            }
          }
          topFold = flapPoly.mirror(fld.location);
          this.base = trimmedPoly;
          this.flap = topFold;
        }
      }

      return FoldedSet;

    })();

    Vertex = (function() {
      function Vertex(x, y) {
        this.x = x;
        this.y = y;
        this.mode = [8 - y, x, x + (8 - y), (8 - x) + (8 - y)];
      }

      Vertex.prototype.print = function(ctx, fillColor, multiplier, stroked) {
        var radius;
        ctx.fillStyle = fillColor;
        radius = multiplier / 1.7;
        ctx.beginPath();
        ctx.arc(this.x * multiplier + 1.5, this.y * multiplier + 1.5, radius, 0, 2 * Math.PI);
        ctx.fill();
        if (stroked) {
          ctx.lineWidth = 3;
          return ctx.stroke();
        }
      };

      Vertex.prototype.fill = function(ctx, multiplier) {
        var radius;
        radius = multiplier / 1.7;
        ctx.beginPath();
        ctx.arc(this.x * multiplier + 1.5, this.y * multiplier + 1.5, radius, 0, 2 * Math.PI);
        return ctx.fill();
      };

      Vertex.prototype.outline = function(ctx, multiplier) {
        var radius;
        radius = multiplier / 1.7;
        ctx.beginPath();
        ctx.arc(this.x * multiplier + 1.5, this.y * multiplier + 1.5, radius, 0, 2 * Math.PI);
        return ctx.stroke();
      };

      Vertex.prototype.valid = function() {
        if (this.x >= 0 && this.x <= 8 && this.y >= 0 && this.y <= 8) {
          return true;
        } else {
          return false;
        }
      };

      Vertex.prototype.mirror = function(fNum) {
        var delta, l, newX, newY;
        l = foldLines[fNum];
        switch (l.angle) {
          case 0:
            newY = this.y + (2 * (l.v1.y - this.y));
            return new Vertex(this.x, newY);
          case 1:
            newX = this.x + (2 * (l.v1.x - this.x));
            return new Vertex(newX, this.y);
          case 2:
            delta = l.v1.mode[l.angle] - this.mode[l.angle];
            return new Vertex(this.x + delta, this.y - delta);
          case 3:
            delta = l.v1.mode[l.angle] - this.mode[l.angle];
            return new Vertex(this.x - delta, this.y - delta);
          default:
            return false;
        }
        return false;
      };

      Vertex.prototype.identical = function(alter) {
        return (this.x === alter.x) && (this.y === alter.y);
      };

      return Vertex;

    })();

    TableSpot = (function(_super1) {
      __extends(TableSpot, _super1);

      function TableSpot(spot) {
        TableSpot.__super__.constructor.call(this, spot.x, spot.y);
        this.members = [];
        this.members[0] = spot;
      }

      TableSpot.prototype.addSpot = function(spot) {
        return this.members.push(spot);
      };

      TableSpot.prototype.clear = function() {
        return this.members = [];
      };

      return TableSpot;

    })(Vertex);

    Line = (function() {
      function Line(v1, v2) {
        this.v1 = v1;
        this.v2 = v2;
        if (v1.y === v2.y) {
          this.angle = 0;
        } else if (v1.x === v2.x) {
          this.angle = 1;
        } else if ((v1.x - v2.x) === (v1.y - v2.y)) {
          this.angle = 2;
        } else {
          this.angle = 3;
        }
      }

      return Line;

    })();

    Polygon = (function() {
      function Polygon() {
        this.queue = [];
        this.key = [];
        this.antiKey = [];
      }

      Polygon.prototype.mirror = function(fNum) {
        var qu, result, _i, _len, _ref;
        result = new Polygon();
        _ref = this.queue;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          qu = _ref[_i];
          result.addVertex(qu.mirror(fNum));
        }
        return result;
      };

      Polygon.prototype.contains = function(candidate) {
        var qu, _i, _len, _ref;
        _ref = this.queue;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          qu = _ref[_i];
          if (candidate.identical(qu)) {
            return true;
          }
        }
        return false;
      };

      Polygon.prototype.print = function(ctx) {
        var canv, nctx;
        if (this.queue.length > 0) {
          canv = document.createElement('canvas');
          canv.width = QUES_MULT * 8 + 3;
          canv.height = QUES_MULT * 8 + 3;
          nctx = canv.getContext('2d');
          nctx.strokeStyle = "black";
          nctx.lineWidth = 2;
          nctx.lineCap = "round";
          nctx.globalCompositeOperation = "source-over";
          this.outline(nctx);
          nctx.fillStyle = "white";
          nctx.globalCompositeOperation = "destination-over";
          this.fill(nctx);
          return ctx.drawImage(canv, 0, 0);
        }
      };

      Polygon.prototype.fill = function(ctx) {
        var OFFSET, qu, _i, _len, _ref;
        OFFSET = 1.5;
        if (this.queue.length > 0) {
          ctx.beginPath();
          ctx.moveTo(this.queue[0].x * QUES_MULT + OFFSET, this.queue[0].y * QUES_MULT + OFFSET);
          _ref = this.queue;
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            qu = _ref[_i];
            ctx.lineTo(qu.x * QUES_MULT + OFFSET, qu.y * QUES_MULT + OFFSET);
          }
          ctx.closePath();
          return ctx.fill();
        }
      };

      Polygon.prototype.outline = function(ctx) {
        var OFFSET, i, j, _i, _ref, _results;
        OFFSET = 1.5;
        if (this.queue.length > 0) {
          ctx.lineCap = "round";
          _results = [];
          for (i = _i = 0, _ref = this.queue.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
            j = i + 1;
            if (j >= this.queue.length) {
              j = 0;
            }
            ctx.beginPath();
            ctx.moveTo(this.queue[i].x * QUES_MULT + OFFSET, this.queue[i].y * QUES_MULT + OFFSET);
            ctx.lineTo(this.queue[j].x * QUES_MULT + OFFSET, this.queue[j].y * QUES_MULT + OFFSET);
            _results.push(ctx.stroke());
          }
          return _results;
        }
      };

      Polygon.prototype.outlineAroundHoles = function(ctx, holes, currentPage) {
        var canvases, ctxes, hl, i, _i, _j, _len, _len1, _ref;
        if (holes === false) {
          holes = [];
          holes.push(tableSpots[currentPage.holeSpot]);
        }
        canvases = [];
        ctxes = [];
        _ref = [0, 1];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          i = _ref[_i];
          canvases[i] = document.createElement('canvas');
          canvases[i].width = QUES_MULT * 8 + 3;
          canvases[i].height = QUES_MULT * 8 + 3;
          ctxes[i] = canvases[i].getContext('2d');
          ctxes[i].strokeStyle = "black";
          ctxes[i].lineCap = "butt";
          ctxes[i].lineWidth = 2;
          ctxes[i].globalCompositeOperation = "source-over";
        }
        this.outline(ctxes[0]);
        ctxes[0].globalCompositeOperation = "destination-out";
        for (_j = 0, _len1 = holes.length; _j < _len1; _j++) {
          hl = holes[_j];
          hl.fill(ctxes[0], QUES_MULT);
          hl.outline(ctxes[1], QUES_MULT);
        }
        ctxes[1].globalCompositeOperation = "source-in";
        this.fill(ctxes[1]);
        ctxes[1].fillStyle = "white";
        ctxes[1].globalCompositeOperation = "destination-over";
        this.fill(ctxes[1]);
        ctx.drawImage(canvases[1], 0, 0);
        return ctx.drawImage(canvases[0], 0, 0);
      };

      Polygon.prototype.addVertex = function(vert) {
        var i, _i, _results;
        this.queue.push(vert);
        _results = [];
        for (i = _i = 0; _i <= 3; i = ++_i) {
          if (this.queue.length > 1) {
            if (vert.mode[i] > this.key[i].mode[i]) {
              this.key[i] = vert;
            }
            if (vert.mode[i] < this.antiKey[i].mode[i]) {
              _results.push(this.antiKey[i] = vert);
            } else {
              _results.push(void 0);
            }
          } else {
            this.key[i] = vert;
            _results.push(this.antiKey[i] = vert);
          }
        }
        return _results;
      };

      Polygon.prototype.edgeCase = function(fld) {
        var foldPoints, fp, hit1, hit2, i, j, _i, _j, _len, _ref;
        foldPoints = listPoints(foldLines[fld.location].v1, foldLines[fld.location].v2);
        for (i = _i = 0, _ref = this.queue.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          j = i + 1;
          if (j >= this.queue.length) {
            j = 0;
          }
          hit1 = false;
          hit2 = false;
          for (_j = 0, _len = foldPoints.length; _j < _len; _j++) {
            fp = foldPoints[_j];
            if (this.queue[i].identical(fp)) {
              hit1 = true;
            }
            if (this.queue[j].identical(fp)) {
              hit2 = true;
            }
          }
          if (hit1 && hit2) {
            return true;
          }
        }
        return false;
      };

      Polygon.prototype.sanitize = function() {
        var i, j, _results;
        i = 0;
        _results = [];
        while (i < this.queue.length) {
          j = i + 1;
          if (j >= this.queue.length) {
            j = 0;
          }
          if (this.queue[i].identical(this.queue[j])) {
            _results.push(this.queue.splice(j, 1));
          } else {
            _results.push(i++);
          }
        }
        return _results;
      };

      return Polygon;

    })();

    PunchedHoleList = (function(_super1) {
      __extends(PunchedHoleList, _super1);

      function PunchedHoleList() {
        PunchedHoleList.__super__.constructor.call(this);
      }

      PunchedHoleList.prototype.generateOptionObject = function() {
        var i, result, x, y, _i, _len;
        result = new OptionObject();
        for (_i = 0, _len = this.length; _i < _len; _i++) {
          i = this[_i];
          x = (i.x - 1) / 2;
          y = (i.y - 1) / 2;
          result.answerSpots[x][y] = true;
        }
        return result;
      };

      PunchedHoleList.prototype.checkValidity = function() {
        var i, _i, _len;
        for (_i = 0, _len = this.length; _i < _len; _i++) {
          i = this[_i];
          if (!i.valid()) {
            return false;
          }
        }
        if (this.length < 2) {
          return false;
        }
        return true;
      };

      return PunchedHoleList;

    })(Array);

    OptionObject = (function() {
      function OptionObject() {
        var i, j, _i, _j, _ref, _ref1;
        this.answerSpots = new Array(4);
        for (i = _i = 0, _ref = this.answerSpots.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          this.answerSpots[i] = new Array(4);
          for (j = _j = 0, _ref1 = this.answerSpots[i].length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
            this.answerSpots[i][j] = false;
          }
        }
      }

      OptionObject.prototype.checkUniqueness = function(optObj) {
        var i, j, _i, _j, _ref, _ref1;
        for (i = _i = 0, _ref = optObj.answerSpots.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          for (j = _j = 0, _ref1 = optObj.answerSpots[i].length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
            if (this.answerSpots[i][j] !== optObj.answerSpots[i][j]) {
              return true;
            }
          }
        }
        return false;
      };

      OptionObject.prototype.print = function(ctx, mult) {
        var i, j, radius, x, y, _i, _ref, _results;
        ctx.fillStyle = "black";
        _results = [];
        for (i = _i = 0, _ref = this.answerSpots.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          _results.push((function() {
            var _j, _ref1, _results1;
            _results1 = [];
            for (j = _j = 0, _ref1 = this.answerSpots[i].length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
              x = mult + i * mult * 2;
              y = mult + j * mult * 2;
              radius = mult / 1.7;
              ctx.beginPath();
              ctx.arc(x, y, radius, 0, 2 * Math.PI);
              if (this.answerSpots[i][j]) {
                ctx.fill();
              }
              ctx.lineWidth = 2;
              _results1.push(ctx.stroke());
            }
            return _results1;
          }).call(this));
        }
        return _results;
      };

      return OptionObject;

    })();

    freshSheet = function() {
      var myPoly, mySet;
      myPoly = new Polygon();
      myPoly.addVertex(new Vertex(0, 0));
      myPoly.addVertex(new Vertex(8, 0));
      myPoly.addVertex(new Vertex(8, 8));
      myPoly.addVertex(new Vertex(0, 8));
      mySet = [];
      mySet[0] = myPoly;
      return mySet;
    };

    freshTable = function() {
      var cnt, i, j, tSpots, x, y, _i, _j;
      tSpots = [];
      cnt = 0;
      for (i = _i = 0; _i <= 3; i = ++_i) {
        for (j = _j = 0; _j <= 3; j = ++_j) {
          x = (i * 2) + 1;
          y = (j * 2) + 1;
          tSpots[cnt] = new TableSpot(new Vertex(x, y));
          cnt++;
        }
      }
      return tSpots;
    };

    populateFoldLines = function() {
      var i, l, v1, v1x, v1y, v2, v2x, v2y, _i, _results;
      _results = [];
      for (i = _i = 0; _i <= 4; i = ++_i) {
        v1 = new Vertex(i + 2, 0);
        v2 = new Vertex(i + 2, 8);
        l = new Line(v1, v2);
        foldLines[i] = l;
        v1 = new Vertex(0, i + 2);
        v2 = new Vertex(8, i + 2);
        l = new Line(v1, v2);
        foldLines[i + 5] = l;
        v1x = -4 + 2 * i;
        if (v1x < 0) {
          v1x = 0;
        }
        v1y = 4 - 2 * i;
        if (v1y < 0) {
          v1y = 0;
        }
        v2x = 4 + 2 * i;
        if (v2x > 8) {
          v2x = 8;
        }
        v2y = 12 - 2 * i;
        if (v2y > 8) {
          v2y = 8;
        }
        v1 = new Vertex(v1x, v1y);
        v2 = new Vertex(v2x, v2y);
        l = new Line(v1, v2);
        foldLines[i + 10] = l;
        v1x = -4 + 2 * i;
        if (v1x < 0) {
          v1x = 0;
        }
        v1y = 4 + 2 * i;
        if (v1y > 8) {
          v1y = 8;
        }
        v2x = 4 + 2 * i;
        if (v2x > 8) {
          v2x = 8;
        }
        v2y = -4 + 2 * i;
        if (v2y < 0) {
          v2y = 0;
        }
        v1 = new Vertex(v1x, v1y);
        v2 = new Vertex(v2x, v2y);
        l = new Line(v1, v2);
        _results.push(foldLines[i + 15] = l);
      }
      return _results;
    };

    locateHole = function(page) {
      var cnt, max, ts, _i, _len, _results;
      max = 2;
      if (Math.random() < 0.4) {
        for (_i = 0, _len = tableSpots.length; _i < _len; _i++) {
          ts = tableSpots[_i];
          cnt = ts.members.length;
          if (cnt > max) {
            max = cnt;
          }
        }
      }
      _results = [];
      while (true) {
        page.holeSpot = randomInt(16);
        if (tableSpots[page.holeSpot].members.length >= max) {
          break;
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    };

    Holes.prototype.punchHole = function(ctx) {
      return tableSpots[this.holeSpot].print(ctx, "white", QUES_MULT, false);
    };

    Holes.prototype.falseMirror = function() {
      var falseFold, falsified, flsPg, fp, j, newFldLine, orientation, _i, _j, _len, _ref, _ref1;
      while (true) {
        flsPg = new Page();
        for (j = _i = 0, _ref = folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; j = 0 <= _ref ? ++_i : --_i) {
          flsPg.folds[j] = folds[j];
        }
        flsPg.holeSpot = this.holeSpot;
        falseFold = randomInt(3);
        newFldLine = flsPg.folds[falseFold].location + randomInt(3) - 1;
        orientation = flsPg.folds[falseFold].orientation;
        if (Math.random() < 0.5) {
          orientation = !orientation;
        }
        if (newFldLine < 0) {
          newFldLine = 1;
        }
        if (newFldLine > 19) {
          newFldLine = 18;
        }
        flsPg.folds[falseFold] = new Fold();
        flsPg.folds[falseFold].location = newFldLine;
        flsPg.folds[falseFold].orientation = orientation;
        _ref1 = flsPg.folds;
        for (_j = 0, _len = _ref1.length; _j < _len; _j++) {
          fp = _ref1[_j];
          relocateSpots(flsPg.tableSpots, fp);
        }
        falsified = flsPg.copyOfPunchedHoles();
        if (falsified.checkValidity()) {
          return falsified.generateOptionObject();
        }
      }
    };

    Holes.prototype.falseRelocate = function() {
      var deltaX, deltaY, falseCandidate, falsified, mover, newSpot, unmovable;
      falsified = this.copyOfPunchedHoles();
      unmovable = falsified[0].identical(tableSpots[this.holeSpot]) ? 1 : 0;
      while (true) {
        mover = randomInt(falsified.length - unmovable) + unmovable;
        deltaX = 2 * randomInt(3) - 2;
        deltaY = 2 * randomInt(3) - 2;
        if (falsified.length < 3 && Math.random() < 0.5) {
          deltaX = 2 * deltaX;
          deltaY = 2 * deltaY;
        }
        newSpot = new Vertex(falsified[mover].x + deltaX, falsified[mover].y + deltaY);
        if (!newSpot.valid()) {
          continue;
        }
        falseCandidate = this.copyOfPunchedHoles();
        falseCandidate[mover] = newSpot;
        return falseCandidate.generateOptionObject();
      }
    };

    Holes.prototype.generateFalseAnswers = function() {
      var i, j, unique, _i, _ref, _results;
      this.options[this.correctOption] = this.copyOfPunchedHoles().generateOptionObject();
      _results = [];
      for (i = _i = 0, _ref = answerCanvases.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        if (i !== this.correctOption) {
          _results.push((function() {
            var _j, _ref1, _results1;
            _results1 = [];
            while (true) {
              unique = true;
              this.options[i] = Math.random() < 0.5 ? this.falseRelocate() : this.falseRelocate();
              for (j = _j = 0, _ref1 = this.options.length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
                if (j !== i) {
                  if (!this.options[i].checkUniqueness(this.options[j])) {
                    unique = false;
                    break;
                  }
                }
              }
              if (unique) {
                break;
              } else {
                _results1.push(void 0);
              }
            }
            return _results1;
          }).call(this));
        }
      }
      return _results;
    };

    Holes.prototype.copyOfPunchedHoles = function() {
      var result, spt, spts, _i, _len;
      result = new PunchedHoleList();
      spts = tableSpots[this.holeSpot].members;
      for (_i = 0, _len = spts.length; _i < _len; _i++) {
        spt = spts[_i];
        result.push(new Vertex(spt.x, spt.y));
      }
      return result;
    };

    Holes.prototype.printAnswerChoices = function() {
      var c, ctx, i, _i, _ref, _results;
      _results = [];
      for (i = _i = 0, _ref = answerCanvases.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        c = document.getElementById(answerCanvases[i]);
        ctx = c.getContext("2d");
        _results.push(this.options[i].print(ctx, ANS_MULT));
      }
      return _results;
    };

    return Holes;

  })(Quiz);

  Cubes = (function(_super) {
    var COLUMNS, Cube, HEIGHT, LAYER_X, LAYER_Y, Point, ROWS, Structure;

    __extends(Cubes, _super);

    COLUMNS = 3;

    HEIGHT = 2;

    ROWS = 3;

    LAYER_X = 100;

    LAYER_Y = 100;

    function Cubes() {
      this.handleIncorrect = __bind(this.handleIncorrect, this);
      this.handleCorrect = __bind(this.handleCorrect, this);
      var c, ctx, i, opt, pzlHeight, pzlWidth, spt, _i, _len, _ref;
      startTimer();
      this.structure = new Structure();
      this.answer = "z";
      this.options = [];
      this.paintQuery();
      this.sequentialQuestions();
      this.setTitle("How many cubes have " + questionText(this.sidesQty) + " painted?");
      $('#display-area').html('<div><canvas></canvas><canvas></canvas></div><canvas id="puzzle"></canvas><div><h1>1</h1><h1>2</h1><h1>3</h1><h1>4</h1><h1>5</h1></div><h5 id="single-result" style="margin: 0; padding: 0;"></h5>');
      pzlHeight = 3 * MAIN_WIDTH / 4;
      pzlWidth = 3 * MAIN_WIDTH / 4;
      $('#display-area > div').first().css({
        float: "left"
      });
      $('#display-area > div').first().width(MAIN_WIDTH - pzlWidth);
      $('#display-area > div').first().height(pzlHeight);
      $('#display-area > div').first().find('canvas').each(function() {
        $(this).width(MAIN_WIDTH - pzlWidth);
        $(this).height(MAIN_WIDTH - pzlHeight);
        $(this).attr("width", "" + (MAIN_WIDTH - pzlWidth));
        return $(this).attr("height", "" + (MAIN_WIDTH - pzlHeight));
      });
      $('#puzzle').height(pzlHeight);
      $('#puzzle').width(pzlWidth);
      $('#puzzle + div').width(MAIN_WIDTH);
      $('#puzzle + div').height(MAIN_WIDTH - pzlHeight);
      $('#puzzle + div > h1').width(MAIN_WIDTH / 5 - 10);
      $('#puzzle + div > h1').css({
        display: "inline-block"
      });
      $('#puzzle + div > h1').css({
        textAlign: "center"
      });
      $('#puzzle + div > h1').css({
        border: "thin solid #EEE"
      });
      $('#puzzle + div > h1').css({
        marginLeft: "6px"
      });
      $('#puzzle + div > h1').css({
        borderRadius: "6px"
      });
      $('#puzzle + div > h1').css({
        cursor: "pointer"
      });
      $('#puzzle + div > h1').hover(function() {
        return $(this).css({
          backgroundColor: "#EEE"
        });
      }, function() {
        return $(this).css({
          backgroundColor: "transparent"
        });
      });
      _ref = this.options;
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        opt = _ref[i];
        spt = $('#puzzle + div > h1').eq("" + i);
        spt.text(opt);
        if (opt === this.tally) {
          spt.on("click", this.handleCorrect);
        } else {
          spt.on("click", this.handleIncorrect);
        }
      }
      c = document.getElementById("puzzle");
      c.width = pzlWidth;
      c.height = pzlHeight;
      ctx = c.getContext("2d");
      this.structure.printCubes(ctx);
    }

    Cubes.prototype.paintQuery = function() {
      var pq, valid, _i, _len, _ref;
      while (true) {
        valid = true;
        this.sidesQty = randomInt(5) + 1;
        _ref = this.structure.paintQueries;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          pq = _ref[_i];
          if (this.sidesQty === pq) {
            valid = false;
            break;
          }
        }
        if (!valid) {
          continue;
        }
        this.getTally();
        if (this.tally !== 0) {
          break;
        }
      }
      return this.structure.paintQueries.push(this.sidesQty);
    };

    Cubes.prototype.populateQuestions = function() {
      var candidate, history, hst, i, valid, _i, _len, _results;
      this.options.push(this.tally);
      history = [];
      i = 0;
      while (i < questionIds.length) {
        while (true) {
          candidate = randomInt(5) + 1;
          if (Math.random() < 0.5) {
            candidate = -candidate;
          }
          if (candidate + this.tally < 1 || candidate + this.tally > this.structure.cubes.length) {
            continue;
          }
          valid = true;
          for (_i = 0, _len = history.length; _i < _len; _i++) {
            hst = history[_i];
            if (candidate === hst) {
              valid = false;
              break;
            }
          }
          if (!valid) {
            continue;
          }
          history.push(candidate);
          this.options.push(this.tally + candidate);
          break;
        }
        i++;
      }
      this.options.sort(function(a, b) {
        return a - b;
      });
      i = 0;
      _results = [];
      while (i < questionIds.length) {
        if (this.options[i] === this.tally) {
          this.correctOption = i;
        }
        _results.push(i++);
      }
      return _results;
    };

    Cubes.prototype.sequentialQuestions = function() {
      var choices, i, _i, _results;
      choices = this.tally;
      if (choices > 5) {
        choices = 5;
      }
      this.correctOption = randomInt(choices);
      _results = [];
      for (i = _i = 0; _i <= 4; i = ++_i) {
        _results.push(this.options.push(this.tally - this.correctOption + i));
      }
      return _results;
    };

    Cubes.prototype.displayQuestions = function() {
      var i, _results;
      document.getElementById("query").innerHTML = numberWord(this.sidesQty);
      i = 0;
      _results = [];
      while (i < questionIds.length) {
        document.getElementById(questionIds[i]).innerHTML = questionText(this.options[i]);
        _results.push(i++);
      }
      return _results;
    };

    Cubes.prototype.getTally = function() {
      var count, sc, _i, _len, _ref;
      count = 0;
      _ref = this.structure.cubes;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        sc = _ref[_i];
        if (sc.painted === this.sidesQty) {
          count++;
        }
      }
      return this.tally = count;
    };

    Cubes.prototype.display = function() {
      var c, ctx;
      this.displayQuestions();
      c = document.getElementById('puzzle');
      ctx = c.getContext("2d");
      return this.structure.printCubes(ctx);
    };

    Cubes.prototype.explicate = function() {
      return $('#display-area > div').first().find('canvas').each((function(_this) {
        return function(index, element) {
          var c, color, ctx, sc, _i, _len, _ref, _results;
          c = element;
          ctx = c.getContext("2d");
          _ref = _this.structure.cubes;
          _results = [];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            sc = _ref[_i];
            if (sc.y === index) {
              color = "white";
              if (sc.painted === _this.sidesQty) {
                color = "#5F5";
              }
              _results.push(sc.print(ctx, _this.structure.solutionScale, _this.structure.perspective, color, _this.structure.xOff, _this.structure.yOff));
            } else if (sc.y > index) {
              color = "#EEE";
              _results.push(sc.print(ctx, _this.structure.solutionScale, _this.structure.perspective, color, _this.structure.xOff, _this.structure.yOff));
            } else {
              _results.push(void 0);
            }
          }
          return _results;
        };
      })(this));
    };

    Cubes.prototype.handleResult = function(r, text) {
      var cell, table, tot;
      $('#puzzle + div > h1').unbind();
      $('#puzzle + div > h1').css({
        cursor: "inherit"
      });
      this.explicate();
      clearInterval(timer);
      $('#single-result').text(text);
      table = $('table')[0];
      cell = table.rows[step].cells[round];
      $(cell).text(r);
      scores.push(r);
      tot = 0;
      if (scores.length > 0) {
        tot = (scores.reduce(function(x, y) {
          return x + y;
        })) / scores.length;
        tot = Math.round(tot * 100) / 100;
      }
      $('#average').text(tot);
      return $('#position').text('Click "Next" Button to Continue');
    };

    Cubes.prototype.handleCorrect = function() {
      return this.handleResult(0, "Correct, 0 points");
    };

    Cubes.prototype.handleIncorrect = function() {
      return this.handleResult(5, "Incorrect: The correct answer is " + this.tally + ", 5 points");
    };

    Point = (function() {
      function Point(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
      }

      Point.prototype.screenPoint = function(view, xOff, yOff) {
        var deltaX, result;
        result = [];
        deltaX = this.z * 0.42;
        if (view >= 1) {
          deltaX = -deltaX + COLUMNS / 2;
        }
        result[0] = this.x - deltaX + xOff;
        result[1] = this.y - this.z * 0.38 + yOff;
        return result;
      };

      return Point;

    })();

    Cube = (function() {
      function Cube(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.painted = 5;
      }

      Cube.prototype.print = function(ctx, mult, view, color, xOff, yOff) {
        ctx.fillStyle = color;
        this.fill(ctx, mult, view, xOff, yOff);
        return this.outline(ctx, mult, view, xOff, yOff);
      };

      Cube.prototype.fill = function(ctx, mult, view, xOff, yOff) {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.closePath();
        ctx.fill();
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        ctx.closePath();
        ctx.fill();
        if (view === 0) {
          ctx.beginPath();
          screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
          ctx.closePath();
          return ctx.fill();
        } else {
          ctx.beginPath();
          screenMove(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
          ctx.closePath();
          return ctx.fill();
        }
      };

      Cube.prototype.outline = function(ctx, mult, view, xOff, yOff) {
        ctx.lineCap = "round";
        ctx.lineWidth = 1;
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        if (view === 0) {
          ctx.beginPath();
          screenMove(ctx, new Point(this.x, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
          ctx.stroke();
        } else {
          ctx.beginPath();
          screenMove(ctx, new Point(this.x + 1, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
          ctx.stroke();
        }
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
        if (view === 0) {
          ctx.beginPath();
          screenMove(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
          return ctx.stroke();
        } else {
          ctx.beginPath();
          screenMove(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
          screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
          return ctx.stroke();
        }
      };

      return Cube;

    })();

    Structure = (function() {
      function Structure() {
        this.xOff = 0;
        this.yOff = 0;
        this.perspective = randomInt(2);
        this.paintQueries = [];
        while (true) {
          this.cubes = [];
          this.seed();
          this.createPattern();
          if (this.visible()) {
            break;
          }
        }
        this.scale();
        this.tallySides();
        this.order();
      }

      Structure.prototype.visible = function() {
        var cb, front, h, i, j, lip, obstuctColumn, side, wall, _i, _j, _k, _len, _ref, _ref1, _ref2;
        for (i = _i = 0, _ref = COLUMNS - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          for (j = _j = 0, _ref1 = ROWS - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
            h = this.height(i, j);
            if (h < HEIGHT) {
              _ref2 = this.cubes;
              for (_k = 0, _len = _ref2.length; _k < _len; _k++) {
                cb = _ref2[_k];
                if (cb.y < h && cb.z <= j) {
                  if (this.perspective === 0 && i - cb.x === 1) {
                    return false;
                  }
                  if (this.perspective === 1 && cb.x - i === 1) {
                    return false;
                  }
                }
              }
            }
            front = this.height(i, j - 1);
            if (front <= h && front < HEIGHT) {
              side = this.perspective === 0 ? this.height(i - 1, j) : this.height(i + 1, j);
              if (side <= h && side < HEIGHT) {
                lip = side > front ? side : front;
                wall = this.height(i, j + 1);
                if (wall <= lip) {
                  if (this.height(i, j - 1) < lip || this.height(i, j - 2) < lip) {
                    obstuctColumn = this.perspective === 0 ? i - 1 : i + 1;
                    if (this.height(obstuctColumn, j - 1) >= lip && this.height(obstuctColumn, j - 2) >= lip) {
                      return false;
                    }
                  }
                }
              }
            }
          }
        }
        return true;
      };

      Structure.prototype.height = function(x, z) {
        var cb, h, _i, _len, _ref;
        h = HEIGHT;
        _ref = this.cubes;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          cb = _ref[_i];
          if ((cb.x === x && cb.z === z) && (cb.y < h)) {
            h = cb.y;
          }
        }
        return h;
      };

      Structure.prototype.tallySides = function() {
        var cb1, cb2, div, _i, _len, _ref, _results;
        _ref = this.cubes;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          cb1 = _ref[_i];
          _results.push((function() {
            var _j, _len1, _ref1, _results1;
            _ref1 = this.cubes;
            _results1 = [];
            for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
              cb2 = _ref1[_j];
              if (cb2 !== cb1) {
                if (cb1.x === cb2.x && cb1.y === cb2.y) {
                  div = cb1.z - cb2.z;
                  if (div === 1) {
                    _results1.push(cb1.painted--);
                  } else if (div === -1) {
                    _results1.push(cb1.painted--);
                  } else {
                    _results1.push(void 0);
                  }
                } else if (cb1.x === cb2.x && cb1.z === cb2.z) {
                  div = cb1.y - cb2.y;
                  if (div === 1) {
                    _results1.push(cb1.painted--);
                  } else {
                    _results1.push(void 0);
                  }
                } else if (cb1.z === cb2.z && cb1.y === cb2.y) {
                  div = cb1.x - cb2.x;
                  if (div === 1) {
                    _results1.push(cb1.painted--);
                  } else if (div === -1) {
                    _results1.push(cb1.painted--);
                  } else {
                    _results1.push(void 0);
                  }
                } else {
                  _results1.push(void 0);
                }
              }
            }
            return _results1;
          }).call(this));
        }
        return _results;
      };

      Structure.prototype.push = function(cube) {
        return this.cubes.push(cube);
      };

      Structure.prototype.printCubes = function(ctx) {
        var cb, _i, _len, _ref, _results;
        _ref = this.cubes;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          cb = _ref[_i];
          _results.push(cb.print(ctx, this.multiplier, this.perspective, "white", this.xOff + 2, this.yOff + 2));
        }
        return _results;
      };

      Structure.prototype.order = function() {
        var cb, hldr, holder, holder2, i, j, result, _i, _j, _k, _l, _len, _len1, _ref, _ref1, _ref2, _ref3;
        result = [];
        holder = [];
        for (i = _i = 0, _ref = HEIGHT - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          holder.push([]);
        }
        _ref1 = this.cubes;
        for (_j = 0, _len = _ref1.length; _j < _len; _j++) {
          cb = _ref1[_j];
          holder[cb.y].push(cb);
        }
        i = HEIGHT - 1;
        while (i >= 0) {
          holder2 = [];
          for (j = _k = 0, _ref2 = ROWS - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; j = 0 <= _ref2 ? ++_k : --_k) {
            holder2.push([]);
          }
          _ref3 = holder[i];
          for (_l = 0, _len1 = _ref3.length; _l < _len1; _l++) {
            hldr = _ref3[_l];
            holder2[hldr.z].push(hldr);
          }
          j = ROWS - 1;
          while (j >= 0) {
            if (this.perspective === 0) {
              holder2[j].sort(function(a, b) {
                return b.x - a.x;
              });
            } else {
              holder2[j].sort(function(a, b) {
                return a.x - b.x;
              });
            }
            result = result.concat(holder2[j]);
            j--;
          }
          i--;
        }
        return this.cubes = result;
      };

      Structure.prototype.seed = function() {
        var x, z;
        x = randomInt(COLUMNS);
        z = randomInt(ROWS);
        return this.push(new Cube(x, HEIGHT - 1, z));
      };

      Structure.prototype.scale = function() {
        var b, bottom, cube, l, left, r, right, t, top, xScale, yScale, _i, _len, _ref;
        top = 10000;
        bottom = -10000;
        right = -10000;
        left = 10000;
        _ref = this.cubes;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          cube = _ref[_i];
          t = new Point(cube.x, cube.y, cube.z + 1).screenPoint(this.perspective, this.xOff, this.yOff)[1];
          if (t < top) {
            top = t;
          }
          b = new Point(cube.x, cube.y + 1, cube.z).screenPoint(this.perspective, this.xOff, this.yOff)[1];
          if (b > bottom) {
            bottom = b;
          }
          if (this.perspective === 0) {
            r = new Point(cube.x + 1, cube.y, cube.z).screenPoint(this.perspective, this.xOff, this.yOff)[0];
            if (r > right) {
              right = r;
            }
            l = new Point(cube.x, cube.y, cube.z + 1).screenPoint(this.perspective, this.xOff, this.yOff)[0];
            if (l < left) {
              left = l;
            }
          } else {
            r = new Point(cube.x + 1, cube.y, cube.z + 1).screenPoint(this.perspective, this.xOff, this.yOff)[0];
            if (r > right) {
              right = r;
            }
            l = new Point(cube.x, cube.y, cube.z).screenPoint(this.perspective, this.xOff, this.yOff)[0];
            if (l < left) {
              left = l;
            }
          }
        }
        xScale = LAYER_X / (right - left);
        yScale = LAYER_Y / (bottom - top);
        if (xScale > yScale) {
          this.solutionScale = LAYER_Y / (bottom - top);
        } else {
          this.solutionScale = LAYER_X / (right - left);
        }
        this.multiplier = 40;
        this.xOff = 0 - left;
        return this.yOff = 0 - top;
      };

      Structure.prototype.createPattern = function() {
        var a, cb, iter, j, upperCubes, valid, x, z, _i, _j, _len, _len1, _ref, _ref1;
        upperCubes = [];
        while (true) {
          a = randomInt(this.cubes.length);
          x = this.cubes[a].x;
          z = this.cubes[a].z;
          switch (randomInt(4)) {
            case 0:
              x--;
              break;
            case 1:
              x++;
              break;
            case 2:
              z++;
              break;
            default:
              z--;
          }
          if (x >= COLUMNS || x < 0 || z >= ROWS || z < 0) {
            continue;
          }
          valid = true;
          _ref = this.cubes;
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            cb = _ref[_i];
            if (x === cb.x && z === cb.z) {
              valid = false;
              break;
            }
          }
          if (valid) {
            this.push(new Cube(x, HEIGHT - 1, z));
          }
          if (this.cubes.length > 6) {
            break;
          }
          if (!(this.cubes.length < (2 + randomInt(3) + randomInt(3) + randomInt(3) + randomInt(3)))) {
            break;
          }
        }
        while (true) {
          upperCubes = [];
          _ref1 = this.cubes;
          for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
            cb = _ref1[_j];
            iter = randomInt(HEIGHT);
            x = cb.x;
            z = cb.z;
            j = 0;
            while (j < iter) {
              upperCubes.push(new Cube(x, HEIGHT - (2 + j), z));
              j++;
            }
          }
          if (!(upperCubes.length + this.cubes.length < 4 || upperCubes.length + this.cubes.length > 15)) {
            break;
          }
        }
        return this.cubes = this.cubes.concat(upperCubes);
      };

      Structure.prototype.testStructure = function() {
        var i, j, k, _i, _ref, _results;
        this.cubes = [];
        _results = [];
        for (i = _i = 0, _ref = COLUMNS - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          _results.push((function() {
            var _j, _ref1, _results1;
            _results1 = [];
            for (j = _j = 0, _ref1 = HEIGHT - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
              _results1.push((function() {
                var _k, _ref2, _results2;
                _results2 = [];
                for (k = _k = 0, _ref2 = ROWS - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; k = 0 <= _ref2 ? ++_k : --_k) {
                  _results2.push(this.push(new Cube(i, j, k)));
                }
                return _results2;
              }).call(this));
            }
            return _results1;
          }).call(this));
        }
        return _results;
      };

      return Structure;

    })();

    return Cubes;

  })(Quiz);

  TwoAngles = (function(_super) {
    __extends(TwoAngles, _super);

    function TwoAngles() {
      this.handleIncorrect = __bind(this.handleIncorrect, this);
      this.handleCorrect = __bind(this.handleCorrect, this);
      var c, i, wdth, _i;
      startTimer();
      wdth = MAIN_WIDTH / 2;
      this.setTitle("Click on the Largest Angle");
      $('#display-area').html('<canvas id="screen-1" style="width: ' + wdth + 'px; height: 500px;"></canvas><canvas id="screen-2" style="width: ' + wdth + 'px; height: 500px;"></canvas><h5 id="single-result" style="margin: 0; padding: 0;"></h5>');
      for (i = _i = 1; _i <= 2; i = ++_i) {
        c = document.getElementById("screen-" + i);
        c.width = wdth;
        c.height = MAIN_HEIGHT;
      }
      $('#screen-1').add('#screen-2').hover(function() {
        return $(this).css({
          "background-color": "#F9F9F9"
        });
      }, function() {
        return $(this).css({
          "background-color": "transparent"
        });
      });
      this.getBaseStructure();
      this.initialDisplay();
    }

    TwoAngles.prototype.getBaseStructure = function() {
      var angle, l, newangle, originangle, rand;
      angle = 0;
      while (true) {
        this.x0 = this.getRandomPoint(MAIN_WIDTH / 2);
        this.y0 = this.getRandomPoint(MAIN_HEIGHT);
        this.x1 = this.getRandomPoint(MAIN_WIDTH / 2);
        this.y1 = this.getRandomPoint(MAIN_HEIGHT);
        this.x2 = this.getRandomPoint(MAIN_WIDTH / 2);
        this.y2 = this.getRandomPoint(MAIN_HEIGHT);
        if (dist(this.x0, this.y0, this.x1, this.y1) < 20000) {
          continue;
        }
        if (dist(this.x2, this.y2, this.x1, this.y1) < 20000) {
          continue;
        }
        angle = getAngle(this.x0, this.y0, this.x1, this.y1, this.x2, this.y2);
        if (angle < 0.2 * Math.PI) {
          continue;
        }
        if (angle > 0.8 * Math.PI) {
          continue;
        }
        break;
      }
      while (true) {
        this.x3 = this.getRandomPoint(MAIN_WIDTH / 2);
        this.y3 = this.getRandomPoint(MAIN_HEIGHT);
        this.x4 = this.getRandomPoint(MAIN_WIDTH / 2);
        this.y4 = this.getRandomPoint(MAIN_HEIGHT);
        if (dist(this.x3, this.y3, this.x4, this.y4) < 20000) {
          continue;
        }
        l = this.getRandomPoint(MAIN_HEIGHT - 140) + 120;
        originangle = Math.atan2(this.y3 - this.y4, this.x3 - this.x4);
        if (originangle < 0) {
          originangle = 2 * Math.PI + originangle;
        }
        newangle = originangle + angle - 0.0175;
        if (newangle > (2 * Math.PI)) {
          newangle -= 2 * Math.PI;
        }
        this.x5 = l * Math.cos(newangle) + this.x4;
        this.y5 = l * Math.sin(newangle) + this.y4;
        if (this.x5 < 5 || this.x5 > MAIN_WIDTH / 2 - 5) {
          continue;
        }
        if (this.y5 < 5 || this.y5 > MAIN_HEIGHT - 5) {
          continue;
        }
        break;
      }
      rand = Math.random();
      if (rand < 0.5) {
        this.correctOnRight = true;
      } else {
        this.correctOnRight = false;
      }
      if (this.correctOnRight) {
        $('#screen-2').on("click", this.handleCorrect);
        return $('#screen-1').on("click", this.handleIncorrect);
      } else {
        $('#screen-1').on("click", this.handleCorrect);
        return $('#screen-2').on("click", this.handleIncorrect);
      }
    };

    TwoAngles.prototype.handleCorrect = function() {
      return this.handleResult(0, "Correct, 0 points");
    };

    TwoAngles.prototype.handleIncorrect = function() {
      return this.handleResult(5, "Incorrect: The selected angle is smaller, 5 points");
    };

    TwoAngles.prototype.handleResult = function(r, text) {
      var cell, table, tot;
      $('#screen-1').add('#screen-2').unbind();
      clearInterval(timer);
      $('#single-result').text(text);
      table = $('table')[0];
      cell = table.rows[step].cells[round];
      $(cell).text(r);
      scores.push(r);
      tot = 0;
      if (scores.length > 0) {
        tot = (scores.reduce(function(x, y) {
          return x + y;
        })) / scores.length;
        tot = Math.round(tot * 100) / 100;
      }
      $('#average').text(tot);
      return $('#position').text('Click "Next" Button to Continue');
    };

    TwoAngles.prototype.initialDisplay = function() {
      var c, ctx;
      c = document.getElementById(this.correctOnRight ? "screen-2" : "screen-1");
      ctx = c.getContext("2d");
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.x1, this.y1);
      ctx.lineTo(this.x2, this.y2);
      ctx.stroke();
      c = document.getElementById(this.correctOnRight ? "screen-1" : "screen-2");
      ctx = c.getContext("2d");
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(this.x3, this.y3);
      ctx.lineTo(this.x4, this.y4);
      ctx.lineTo(this.x5, this.y5);
      return ctx.stroke();
    };

    return TwoAngles;

  })(Quiz);

  Eyeballer = (function(_super) {
    __extends(Eyeballer, _super);

    function Eyeballer() {
      this.mouseUp = __bind(this.mouseUp, this);
      this.mouseMove = __bind(this.mouseMove, this);
      this.mouseDown = __bind(this.mouseDown, this);
      Eyeballer.__super__.constructor.apply(this, arguments);
      $('#display-area').html('<canvas id="screen" style="width: 500px; height: 500px;"></canvas><h5 id="single-result" style="margin: 0; padding: 0;"></h5>');
      sanitize();
      this.getBaseStructure();
      this.initialDisplay();
      $("#screen").on("mousedown", this.mouseDown);
    }

    Eyeballer.prototype.getCursor = function(e) {
      var c, rect;
      c = document.getElementById("screen");
      rect = c.getBoundingClientRect();
      this.mouseX = Math.round(e.clientX - rect.left);
      return this.mouseY = Math.round(e.clientY - rect.top);
    };

    Eyeballer.prototype.mouseDown = function(e) {
      this.getCursor(e);
      $("#screen").unbind("mousedown");
      $('#screen').css('cursor', 'none');
      this.drawDynamic();
      $('#screen').on("mousemove", this.mouseMove);
      return $('#screen').on("mouseup", this.mouseUp);
    };

    Eyeballer.prototype.mouseMove = function(e) {
      this.getCursor(e);
      return this.drawDynamic();
    };

    Eyeballer.prototype.mouseUp = function(e) {
      this.getCursor(e);
      $('#screen').css('cursor', 'default');
      $('#screen').unbind();
      this.drawFinal();
      return this.handleResult(this.getResult());
    };

    Eyeballer.prototype.squarePointer = function(xa, ya, ctx, color) {
      var SIZE, startX, startY;
      SIZE = 16;
      startX = xa - SIZE / 2 + 0.5;
      startY = ya - SIZE / 2 + 0.5;
      ctx.strokeStyle = color;
      ctx.lineWidth = 1;
      return ctx.strokeRect(startX, startY, SIZE, SIZE);
    };

    Eyeballer.prototype.crosshairPointer = function(xa, ya, ctx, color) {
      var SIZE, startX, startY;
      SIZE = 10;
      startX = xa - SIZE / 2 + 0.5;
      startY = ya - SIZE / 2 + 0.5;
      ctx.strokeStyle = color;
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(startX, ya + 0.5);
      ctx.lineTo(startX + SIZE, ya + 0.5);
      ctx.moveTo(xa + 0.5, startY);
      ctx.lineTo(xa + 0.5, startY + SIZE);
      return ctx.stroke();
    };

    Eyeballer.prototype.dotPointer = function(xa, ya, ctx, color) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(xa, ya, 5, 0, 2 * Math.PI);
      return ctx.fill();
    };

    Eyeballer.prototype.drawDynamic = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      return this.drawBase(ctx);
    };

    Eyeballer.prototype.initialDisplay = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      this.mouseX = Math.round(this.solutionX - 15);
      this.mouseY = Math.round(this.solutionY - 15);
      return this.drawBase(ctx);
    };

    Eyeballer.prototype.getResult = function() {
      return Math.sqrt(dist(this.solutionX, this.solutionY, this.mouseX, this.mouseY));
    };

    Eyeballer.prototype.handleResult = function(r) {
      var cell, sane, table, text, tot;
      clearInterval(timer);
      sane = Math.round(r * 100) / 100;
      text = "Accurate to " + sane + " units";
      $('#single-result').text(text);
      table = $('table')[0];
      cell = table.rows[step].cells[round];
      $(cell).text(sane);
      scores.push(sane);
      tot = 0;
      if (scores.length > 0) {
        tot = (scores.reduce(function(x, y) {
          return x + y;
        })) / scores.length;
        tot = Math.round(tot * 100) / 100;
      }
      $('#average').text(tot);
      return $('#position').text('Click "Next" Button to Continue');
    };

    return Eyeballer;

  })(Quiz);

  Circle = (function(_super) {
    __extends(Circle, _super);

    function Circle() {
      Circle.__super__.constructor.apply(this, arguments);
      this.setTitle("Find Center of Circle");
    }

    Circle.prototype.getBaseStructure = function() {
      var _results;
      _results = [];
      while (true) {
        this.solutionX = this.getRandomPoint(MAIN_WIDTH);
        this.solutionY = this.getRandomPoint(MAIN_HEIGHT);
        this.radius = Math.random() * MAIN_HEIGHT / 2;
        if (this.radius < 40) {
          continue;
        }
        if (this.solutionX - this.radius < 20) {
          continue;
        }
        if (this.solutionY - this.radius < 20) {
          continue;
        }
        if (this.solutionX + this.radius > MAIN_WIDTH) {
          continue;
        }
        if (this.solutionY + this.radius > MAIN_HEIGHT) {
          continue;
        }
        break;
      }
      return _results;
    };

    Circle.prototype.drawBase = function(ctx) {
      erase(ctx);
      ctx.strokeStyle = "black";
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.arc(this.solutionX, this.solutionY, this.radius, 0, 2 * Math.PI);
      ctx.stroke();
      this.crosshairPointer(this.mouseX, this.mouseY, ctx, "blue");
      return this.squarePointer(this.mouseX, this.mouseY, ctx, "blue");
    };

    Circle.prototype.drawFinal = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      erase(ctx);
      this.drawBase(ctx);
      this.crosshairPointer(this.solutionX, this.solutionY, ctx, "green");
      return this.squarePointer(this.solutionX, this.solutionY, ctx, "green");
    };

    return Circle;

  })(Eyeballer);

  Bisect = (function(_super) {
    __extends(Bisect, _super);

    function Bisect() {
      Bisect.__super__.constructor.apply(this, arguments);
      this.setTitle("Bisect Angle");
    }

    Bisect.prototype.getBaseStructure = function() {
      var angle, deltaX, deltaY, ghostX, ghostY, ratio, segment1, segment2, _results;
      _results = [];
      while (true) {
        this.x0 = this.getRandomPoint(MAIN_WIDTH);
        this.y0 = this.getRandomPoint(MAIN_HEIGHT);
        this.x1 = this.getRandomPoint(MAIN_WIDTH);
        this.y1 = this.getRandomPoint(MAIN_HEIGHT);
        this.x2 = this.getRandomPoint(MAIN_WIDTH);
        this.y2 = this.getRandomPoint(MAIN_HEIGHT);
        if (dist(this.x0, this.y0, this.x1, this.y1) < 20000) {
          continue;
        }
        if (dist(this.x2, this.y2, this.x1, this.y1) < 20000) {
          continue;
        }
        angle = getAngle(this.x0, this.y0, this.x1, this.y1, this.x2, this.y2) / Math.PI;
        if (angle < .2) {
          continue;
        }
        if (angle > .8) {
          continue;
        }
        segment1 = Math.sqrt(dist(this.x0, this.y0, this.x1, this.y1));
        segment2 = Math.sqrt(dist(this.x2, this.y2, this.x1, this.y1));
        ratio = segment1 / segment2;
        deltaX = (this.x2 - this.x1) * ratio;
        deltaY = (this.y2 - this.y1) * ratio;
        ghostX = this.x1 + deltaX;
        ghostY = this.y1 + deltaY;
        this.solutionX = Math.round((this.x0 + ghostX) / 2);
        this.solutionY = Math.round((this.y0 + ghostY) / 2);
        break;
      }
      return _results;
    };

    Bisect.prototype.drawBase = function(ctx) {
      erase(ctx);
      ctx.strokeStyle = "black";
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.x1, this.y1);
      ctx.lineTo(this.x2, this.y2);
      ctx.stroke();
      ctx.strokeStyle = "blue";
      ctx.beginPath();
      ctx.moveTo(this.x1, this.y1);
      ctx.lineTo(this.mouseX, this.mouseY);
      ctx.stroke();
      return this.squarePointer(this.mouseX, this.mouseY, ctx, "blue");
    };

    Bisect.prototype.drawFinal = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      erase(ctx);
      this.drawBase(ctx);
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.moveTo(this.x1, this.y1);
      ctx.lineTo(this.solutionX, this.solutionY);
      ctx.strokeStyle = "green";
      ctx.stroke();
      return this.squarePointer(this.solutionX, this.solutionY, ctx, "green");
    };

    Bisect.prototype.getResult = function() {
      var angChos, angCorr;
      angCorr = getAngle(this.x0, this.y0, this.x1, this.y1, this.x2, this.y2) * 100;
      angChos = getAngle(this.x0, this.y0, this.x1, this.y1, this.mouseX, this.mouseY) * 200;
      return Math.abs(angCorr - angChos);
    };

    return Bisect;

  })(Eyeballer);

  Midpoint = (function(_super) {
    __extends(Midpoint, _super);

    function Midpoint() {
      Midpoint.__super__.constructor.apply(this, arguments);
      this.setTitle("Locate Midpoint of Line Segment");
    }

    Midpoint.prototype.getBaseStructure = function() {
      var deltaX, deltaY, _results;
      _results = [];
      while (true) {
        this.x0 = this.getRandomPoint(MAIN_WIDTH);
        this.y0 = this.getRandomPoint(MAIN_HEIGHT);
        this.x1 = this.getRandomPoint(MAIN_WIDTH);
        this.y1 = this.getRandomPoint(MAIN_HEIGHT);
        if (dist(this.x0, this.y0, this.x1, this.y1) < 20000) {
          continue;
        }
        deltaX = this.x1 - this.x0;
        deltaY = this.y1 - this.y0;
        if (deltaX % 2 !== 0) {
          continue;
        }
        if (deltaY % 2 !== 0) {
          continue;
        }
        this.solutionX = this.x0 + deltaX / 2;
        this.solutionY = this.y0 + deltaY / 2;
        break;
      }
      return _results;
    };

    Midpoint.prototype.drawBase = function(ctx) {
      erase(ctx);
      this.dotPointer(this.x0, this.y0, ctx, "black");
      this.dotPointer(this.x1, this.y1, ctx, "black");
      ctx.strokeStyle = "blue";
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.mouseX, this.mouseY);
      ctx.lineTo(this.x1, this.y1);
      ctx.stroke();
      this.crosshairPointer(this.mouseX, this.mouseY, ctx, "blue");
      return this.squarePointer(this.mouseX, this.mouseY, ctx, "blue");
    };

    Midpoint.prototype.drawFinal = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      erase(ctx);
      this.drawBase(ctx);
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.moveTo(this.x1, this.y1);
      ctx.lineTo(this.solutionX, this.solutionY);
      ctx.lineTo(this.x0, this.y0);
      ctx.strokeStyle = "green";
      ctx.stroke();
      this.crosshairPointer(this.solutionX, this.solutionY, ctx, "green");
      return this.squarePointer(this.solutionX, this.solutionY, ctx, "green");
    };

    return Midpoint;

  })(Eyeballer);

  Parallelogram = (function(_super) {
    __extends(Parallelogram, _super);

    function Parallelogram() {
      Parallelogram.__super__.constructor.apply(this, arguments);
      this.setTitle("Create a Parallelogram");
    }

    Parallelogram.prototype.getBaseStructure = function() {
      var angle, deltaX, deltaY, _results;
      _results = [];
      while (true) {
        this.x0 = this.getRandomPoint(MAIN_WIDTH);
        this.y0 = this.getRandomPoint(MAIN_HEIGHT);
        this.x1 = this.getRandomPoint(MAIN_WIDTH);
        this.y1 = this.getRandomPoint(MAIN_HEIGHT);
        this.x2 = this.getRandomPoint(MAIN_WIDTH);
        this.y2 = this.getRandomPoint(MAIN_HEIGHT);
        if (dist(this.x0, this.y0, this.x1, this.y1) < 20000) {
          continue;
        }
        if (dist(this.x2, this.y2, this.x1, this.y1) < 20000) {
          continue;
        }
        deltaX = this.x1 - this.x0;
        deltaY = this.y1 - this.y0;
        this.solutionX = this.x2 - deltaX;
        this.solutionY = this.y2 - deltaY;
        if (this.solutionX < 50 || this.solutionX > MAIN_WIDTH - 50 || this.solutionY < 50 || this.solutionY > MAIN_HEIGHT - 50) {
          continue;
        }
        angle = getAngle(this.x0, this.y0, this.x1, this.y1, this.x2, this.y2) / Math.PI;
        if (angle < .25) {
          continue;
        }
        if (angle > .75 && angle < 1.25) {
          continue;
        }
        if (angle > 1.75) {
          continue;
        }
        break;
      }
      return _results;
    };

    Parallelogram.prototype.drawBase = function(ctx) {
      erase(ctx);
      ctx.strokeStyle = "black";
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.x1, this.y1);
      ctx.lineTo(this.x2, this.y2);
      ctx.stroke();
      ctx.strokeStyle = "blue";
      ctx.beginPath();
      ctx.moveTo(this.x2, this.y2);
      ctx.lineTo(this.mouseX, this.mouseY);
      ctx.lineTo(this.x0, this.y0);
      ctx.stroke();
      return this.squarePointer(this.mouseX, this.mouseY, ctx, "blue");
    };

    Parallelogram.prototype.drawFinal = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      erase(ctx);
      this.drawBase(ctx);
      ctx.lineWidth = THICKNESS;
      ctx.beginPath();
      ctx.moveTo(this.x2, this.y2);
      ctx.lineTo(this.solutionX, this.solutionY);
      ctx.lineTo(this.x0, this.y0);
      ctx.strokeStyle = "green";
      ctx.stroke();
      return this.squarePointer(this.solutionX, this.solutionY, ctx, "green");
    };

    return Parallelogram;

  })(Eyeballer);

  RightAngle = (function(_super) {
    __extends(RightAngle, _super);

    function RightAngle() {
      RightAngle.__super__.constructor.apply(this, arguments);
      this.setTitle("Create a Right Angle");
    }

    RightAngle.prototype.getBaseStructure = function() {
      var deltaX, deltaY, _results;
      _results = [];
      while (true) {
        this.x0 = this.getRandomPoint(MAIN_WIDTH);
        this.y0 = this.getRandomPoint(MAIN_HEIGHT);
        this.x1 = this.getRandomPoint(MAIN_WIDTH);
        this.y1 = this.getRandomPoint(MAIN_HEIGHT);
        if (dist(this.x0, this.y0, this.x1, this.y1) < 20000) {
          continue;
        }
        deltaX = this.x1 - this.x0;
        deltaY = this.y1 - this.y0;
        this.solutionX = this.x0 - deltaY;
        this.solutionY = this.y0 + deltaX;
        if (this.solutionX < 20) {
          continue;
        }
        if (this.solutionX > MAIN_WIDTH - 20) {
          continue;
        }
        if (this.solutionY < 20) {
          continue;
        }
        if (this.solutionY > MAIN_HEIGHT - 20) {
          continue;
        }
        break;
      }
      return _results;
    };

    RightAngle.prototype.drawBase = function(ctx) {
      erase(ctx);
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.x1, this.y1);
      ctx.stroke();
      ctx.strokeStyle = "blue";
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.mouseX, this.mouseY);
      ctx.stroke();
      return this.squarePointer(this.mouseX, this.mouseY, ctx, "blue");
    };

    RightAngle.prototype.drawFinal = function() {
      var c, ctx;
      c = document.getElementById("screen");
      ctx = c.getContext("2d");
      erase(ctx);
      this.drawBase(ctx);
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(this.x0, this.y0);
      ctx.lineTo(this.solutionX, this.solutionY);
      ctx.strokeStyle = "green";
      ctx.stroke();
      return this.squarePointer(this.solutionX, this.solutionY, ctx, "green");
    };

    RightAngle.prototype.getResult = function() {
      var angChos;
      angChos = getAngle(this.x1, this.y1, this.x0, this.y0, this.mouseX, this.mouseY);
      if (angChos > Math.PI) {
        angChos = 2 * Math.PI - angChos;
      }
      return Math.abs(Math.PI / 2 - angChos) * 100;
    };

    return RightAngle;

  })(Eyeballer);

  createQuiz = function(n) {
    switch (n) {
      case 0:
        return new Parallelogram();
      case 1:
        return new Midpoint();
      case 2:
        return new Bisect();
      case 3:
        return new Circle();
      case 4:
        return new RightAngle();
      case 5:
        return new TwoAngles();
      case 6:
        return new Cubes();
      case 7:
        return new Holes();
    }
  };

  getAngle = function(a, b, c, d, e, f) {
    var angle1, angle2, angle3, p1x, p1y, p2x, p2y;
    p1x = a - c;
    p1y = b - d;
    p2x = e - c;
    p2y = f - d;
    angle1 = Math.atan2(p1y, p1x);
    angle2 = Math.atan2(p2y, p2x);
    angle3 = Math.abs(angle1 - angle2);
    if (angle3 > Math.PI) {
      angle3 = 2 * Math.PI - angle3;
    }
    return angle3;
  };

  dist = function(xa, ya, xb, yb) {
    var deltaX, deltaY;
    deltaX = xb - xa;
    deltaY = yb - ya;
    return deltaX * deltaX + deltaY * deltaY;
  };

  randomInt = function(scale) {
    return Math.floor(Math.random() * scale);
  };

  screenMove = function(ctx, p, mult) {
    var x, y;
    x = Math.floor(p[0] * mult) + PADDING;
    y = Math.floor(p[1] * mult) + PADDING;
    return ctx.moveTo(x, y);
  };

  screenLine = function(ctx, p, mult) {
    var x, y;
    x = Math.floor(p[0] * mult) + PADDING;
    y = Math.floor(p[1] * mult) + PADDING;
    return ctx.lineTo(x, y);
  };

  questionText = function(n) {
    if (n === 1) {
      return "1 side";
    } else {
      return "" + n + " sides";
    }
  };

  sanitize = function() {
    var c;
    c = document.getElementById("screen");
    c.width = MAIN_WIDTH;
    return c.height = MAIN_HEIGHT;
  };

  clearQuiz = function() {
    clearInterval(timer);
    $('#screen').unbind();
    return $('#single-result').text("");
  };

  erase = function(ctx) {
    return ctx.clearRect(0, 0, MAIN_WIDTH, MAIN_HEIGHT);
  };

  reset = function() {
		$('#next').show();
    var cell, i, j, table, _i, _j, _ref;
    step = 0;
    round = 1;
    scores = [];
    clearQuiz();
    timeReset();
    table = $('table')[0];
    for (j = _i = 0, _ref = QUIZZES - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; j = 0 <= _ref ? ++_i : --_i) {
      for (i = _j = 1; 1 <= ROUNDS ? _j <= ROUNDS : _j >= ROUNDS; i = 1 <= ROUNDS ? ++_j : --_j) {
        cell = table.rows[j].cells[i];
        $(cell).text("---");
      }
    }
    $('#average').text("0");
    return createQuiz(step);
  };

  startTimer = function() {
    clearInterval(timer);
    return timer = setInterval((function() {
      ++timeUsed;
      return $('#time-display').html(timeUsed);
    }), 1000);
  };

  timeReset = function() {
    timeUsed = 0;
    return $('#time-display').html(timeUsed);
  };

}
