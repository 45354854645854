import React, {useState, useRef} from 'react';
import styled, {css} from 'styled-components';
import {useHotkeys} from 'react-hotkeys-hook';

import {updateQuestionProgress as updateQbQuestionProgress, trackNCLEXQuizProgressPerformance} from '@bootcamp/shared/src/requests';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {insertAtIndex, getTestBlockQuestions, getQuestionParts} from '@bootcamp/shared/src/util';

import {normalizeHighlights} from '@bootcamp/web/src/helpers';

import MasteryIcon from '@bootcamp/web/src/bootcamps/components/MasteryIcon';
import {Tag} from '@styled-icons/feather/Tag';
import {Tooltip} from '@bootcamp/web/src/components/Tooltip';

import {ReactComponent as Navigation} from '../assets/navigation.svg';
import {ReactComponent as ArrowLeft} from '../assets/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../assets/arrow-right.svg';
import {ReactComponent as End} from '../assets/end.svg';
import {ReactComponent as PTIconImage} from '../assets/pt.svg';


import {
  Box,
  Button,
  HoverButton as HoverButtonBase,
  DraggablePopup,
  FlagIcon,
  FlagsFilledIcon,
  FlagFilledIcon,
  ReviewIncompleteIcon,
  PauseIcon,
  SettingsIcon
} from '@bootcamp/web/src/components/NCLEXQBank/shared';

const Container = styled(Box)`
  height: 40px;
  position: relative;
  padding: 0px;
  overflow: visible;
  * {
    font-family: Tahoma;
  }
`;
const StyledSettingsIcon = styled(SettingsIcon)`
  margin-left: 0;
`;
const HoverButton = styled(HoverButtonBase)`
  font-family: Verdana;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 10px;

  &:focus {
    border: 1px solid white;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 100%;
    padding: 0px 11px;
  }
  &:hover {
    color: #FFFF00;

    path {
      fill: #FFFF00;
      stroke: #FFFF00;
    }
    rect {
      stroke: #FFFF00;
    }
  }
`;
const SettingsHoverButton = styled(HoverButton)`
  &:hover {
    path {
      fill: inherit;
      stroke: #FFFF00;
    }
  }
`;
const MobileHoverButton = styled(HoverButton)`
  display: none;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
    height: 100%;
    padding: 0px 11px;
  }
`;
const MasteryButtonContainer = styled(Button)`
  font-family: Verdana;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  svg {
    width: 16px;
    height: 16px;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 100%;
    ${({mobile}) => mobile ? css`
      display: flex;
      width: 100%;
      div {
        border-left: none;
      }

    ` : css`
      display: none;
    `}
    svg {
      margin-right: 0px;
    }
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 100%;
    &:first-child > div {
      border-left: none;
    }
  }
`;
const EndIcon = styled(End).attrs(props => ({draggable: false}))`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: 0px;
  }
`;
const PTIcon = styled(PTIconImage).attrs(props => ({draggable: false}))`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: 0px;
  }
`;
const LeftArrowIcon = styled(ArrowLeft).attrs(props => ({draggable: false}))`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: 0px;
  }
  `;
const RightArrowIcon = styled(ArrowRight).attrs(props => ({draggable: false}))`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-left: 0px;
  }
`;
const NavigatorIcon = styled(Navigation).attrs(props => ({draggable: false}))`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: 0px;
  }
`;

const TagIcon = styled(Tag).attrs(props => ({size: 22, draggable: false}))`
  stroke-width: 3;
  margin-right: 4px;
  color: white;
  > path {
    fill: transparent !important;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-right: 0px;
  }
`;
const MobileTagIcon = styled(Tag).attrs(props => ({size: 16, draggable: false}))`
  stroke-width: 3;
  color: white;
  > path {
    fill: transparent !important;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-right: 0px;
  }
`;

const ButtonText = styled.span`
  ${({theme, hideLaptop}) => hideLaptop ? theme.mediaQueries.laptop : theme.mediaQueries.tablet} {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
`;
const UnderlineSpan = styled.span`
  text-decoration: underline;
`
const MasteryTag = styled(MasteryIcon)`
  display: ${({level}) => !!level ? 'inline-block' : 'none'};
  max-width: 20px;
  height: 20px;
`;
const MasteryTextContainer = styled.div`
  border-left: 1px solid white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 11px;
`;
const MasteryButton = ({label, onClick, color, hotkey, mobile}) => {
  useHotkeys(hotkey, onClick, [onClick]);

  return (
    <MasteryButtonContainer mobile={mobile} color={color} onClick={onClick}>
      <MasteryTextContainer>
        <TagIcon /><ButtonText hideLaptop><UnderlineSpan>{label.slice(0,1)}</UnderlineSpan>{label.slice(1)}</ButtonText>
      </MasteryTextContainer>
    </MasteryButtonContainer>
  )
}
const MobileMasteryBar = styled.div`
  display: none;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  width: 100%;
`
const Controls = ({themePalette, alwaysShowingExplanation, settingsSidebarState, className, navigatorComponent, handleNavigation, finishedOnClick, handleSuspendClick}) => {
  const [showingMasteryControls, setShowingMasteryControls] = useState(false);
  const startTime = useRef(new Date());

  const [showingSidebar, toggleSidebar] = settingsSidebarState;

  const {
    methods: {
      transitionBlock,
      setCurrentIndex,
      updateQuizProgress,
      saveQuestionProgresses,
      setNavigationFilter
    },
    variables: {
      blockIndex,
      quizProgress,
      testBlockConnections,
      customTestConfig,
      tutorMode,
      type,
    }
  } = useTestNavigatorContext();

  const {modalDispatch} = useModalContext();

  const isReadinessExam = type === 'readinessExam';


  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('review');

  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses.findIndex(({current}) => current);
  const testBlock = testBlockConnections?.[blockIndex]?.testBlock;
  const questions = getTestBlockQuestions(testBlock);
  const currentQuestion = questions[questionIndex];

  // update qp locally
  const updateQuestionProgress = params => {
    const updatedQuestionProgress = {...questionProgresses[questionIndex], ...params};
    updateQuizProgress({
      questions: insertAtIndex(
        questionProgresses,
        questionIndex,
        updatedQuestionProgress
      )
    });
    return updatedQuestionProgress;
  };

  // udpate qp in backend
  const updateQuestionProgressData = async () => {
    if (customTestConfig?.submitted || questionProgresses?.[questionIndex]?.didCheck) {
      startTime.current = new Date();
      return;
    };
    const timeElapsed = new Date(new Date() - startTime.current);
    const time = (parseFloat(questionProgresses[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    startTime.current = new Date();

    const {
      blockArrayIndex,
      questionProgressId,
      questionBaseId,
      questionRevisionId,
      current,
      crossedAnswerIndexes,
      bookmarked,
      originalTestId,
      questionIndex: currentQuestionIndex,
      subject,
      topic,
      subjectTopic,
      question,
      isSequentialStart,
      isSequentialEnd,
      isSequentialSet,
      answerState,
      seen,
      fullCredit,
      partialCredit,
      noCredit,
      highlights, // not saving these for now b/c there's an issue with restoration from the new object format they're being saved in
      ...questionProgress
    } = updateQuestionProgress({time});

    const stringifiedAnswerState = JSON.stringify(answerState || '');
    const stringifiedHighlights = JSON.stringify(normalizeHighlights(highlights) || '');

    const updatedProgress = await updateQbQuestionProgress(questionProgressId, {...questionProgress, answerState: stringifiedAnswerState, highlights: stringifiedHighlights});

    return updatedProgress;
  }

  const ultimateQuestionReached = questionIndex === questions.length - 1 && blockIndex === testBlockConnections.length - 2;


  const updateMastery = (masteryLevel) => {
    const updatedQuestionProgress = updateQuestionProgress({masteryLevel, question: {...currentQuestion, masteryLevel}});
    saveQuestionProgresses([updatedQuestionProgress]);
  }

  const onClickMasteryButton = (masteryLevel) => {
    updateMastery(masteryLevel);
    setShowingMasteryControls(false);
    handleNavigation('next');
  }

  function validateAnswerState(answerState, question) {
    try {
      if (question) {
        const {type} = getQuestionParts(question);

        if (type === 'matrixMultipleChoice') {
          return !!answerState?.answerState?.every(answerRow => answerRow.some(el => !!el))

        } else if (type === 'bowtie') {
          return true;
        }
      }
      return answerState?.answerState;
    } catch (error) {
      return false;
    }
  }

  const showNavigator = () => {
    if (!isReview) {

      return modalDispatch({
        type: 'open',
        component: navigatorComponent
          ? ({close}) => navigatorComponent({quizProgress, isReadinessExam, updateQuestionProgressData, setCurrentIndex, close, alwaysShowingExplanation})
          : ({ close }) => {
          const questionRows = quizProgress.reduce((acc, { questions, isSequentialSet }, blockIndex) => [...acc, ...(questions || []).map((question, index) => (
            <NavigatorTableRow current={question.current} isSequentialSet={isSequentialSet} onClick={() => {
              !isReadinessExam && updateQuestionProgressData();
              setCurrentIndex(blockIndex, index);
              close();
            }} even={index % 2 === 0}>
              <NavigatorTableRowCell>
                {acc.length + index + 1} {question.didMark || question.bookmarked ? <FlagIcon withColor /> : null}
              </NavigatorTableRowCell>
              <NavigatorTableRowCell isRed={!question.didCheck && !alwaysShowingExplanation && !validateAnswerState(question?.answerState)}>
                {(question.didCheck || alwaysShowingExplanation || validateAnswerState(question?.answerState, question?.question)) ? 'Complete' : question.seen ? 'Incomplete' : 'Unseen'}
              </NavigatorTableRowCell>
              <NavigatorTableRowCell>
                <MasteryTag level={question.masteryLevel} squared />
              </NavigatorTableRowCell>
            </NavigatorTableRow>
          ))], [])
          return (
            <DraggablePopup
              close={close}
              headerContent={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <NavigatorIcon />Na<UnderlineSpan>v</UnderlineSpan>igation
                </div>
              }
              footerContent={
                <div>
                  {questionRows.length} Questions, {alwaysShowingExplanation ? 0 : quizProgress.reduce((acc, {questions}) => [...acc, ...(questions || [])], [])?.filter(({seen}) => !seen)?.length} Unseen
                </div>
              }
            >
              <NavigatorTableHeader>
                <NavigatorTableHeaderCell>Question #</NavigatorTableHeaderCell>
                <NavigatorTableHeaderCell>Status</NavigatorTableHeaderCell>
                <NavigatorTableHeaderCell>Tag</NavigatorTableHeaderCell>
              </NavigatorTableHeader>
              <NavigatorTable>
                {questionRows}
              </NavigatorTable>
            </DraggablePopup>
          )
        },
        enableClickClose: false,
        componentProps: {},
        modalContainerStyle: theme => `
          background: transparent;
        `
      })
    }
    return toggleSidebar();
  }

  const isSectionReview = testBlock.type === 'endBlock';
  useHotkeys('option+v', isSectionReview ? () => true : showNavigator, [showNavigator]);
  useHotkeys('option+e', finishedOnClick, [finishedOnClick]);

  if (isSectionReview) {
    const allQuestionProgresses = quizProgress.reduce((acc, {questions}) => [...acc, ...(questions || [])], []);
    return (
      <Container className={className}>
        <ButtonContainer>
          <HoverButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={finishedOnClick}>
            <EndIcon/>
            <ButtonText>
              <UnderlineSpan>E</UnderlineSpan>nd
            </ButtonText>
          </HoverButton>
        </ButtonContainer>
        <ButtonContainer>
          <HoverButton onClick={() => setCurrentIndex(1, 0)}>
            <FlagsFilledIcon />
            <ButtonText>Review All</ButtonText>
          </HoverButton>
          <HoverButton onClick={() => {
            if (allQuestionProgresses.findIndex(({selectedAnswerIndex}) => selectedAnswerIndex === -1) === -1) return;
            setNavigationFilter({key: 'selectedAnswerIndex', value: -1})
            transitionBlock('reviewIncomplete');
          }}>
            <ReviewIncompleteIcon/>
            <ButtonText>Review Incomplete</ButtonText>
          </HoverButton>
          <HoverButton onClick={() => {
            if (allQuestionProgresses.findIndex(({didMark}) => didMark) === -1) return;
            setNavigationFilter({key: 'didMark', value: true})
            transitionBlock('reviewMarked');
          }}>
            <FlagFilledIcon/>
            <ButtonText>Review Flagged</ButtonText>
          </HoverButton>
        </ButtonContainer>
      </Container>
    )
  }
  return (
    <Container className={className}>
      {showingMasteryControls && (
        <MobileMasteryBar>
          <ButtonContainer>
            <MasteryButton mobile hotkey="1, l, option+l" label="Learning" color="red" onClick={() => onClickMasteryButton('learning')} />
            <MasteryButton mobile hotkey="2, r, option+r" label="Reviewing" color="yellow" onClick={() => onClickMasteryButton('reviewing')} />
            <MasteryButton mobile hotkey="3, m, option+m" label="Mastered" color="green" onClick={() => onClickMasteryButton('mastered')} />
          </ButtonContainer>
        </MobileMasteryBar>
      )}
      <ButtonContainer>
      {
        tutorMode
          ? <HoverButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={finishedOnClick}>
              <EndIcon/>
              <ButtonText>
                <UnderlineSpan>E</UnderlineSpan>nd
              </ButtonText>
            </HoverButton>
          : <HoverButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={() => transitionBlock('review')}><ButtonText>Review Screen</ButtonText></HoverButton>
      }
        {/* <SettingsHoverButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={() => toggleSidebar(true)}>
          <StyledSettingsIcon  />
          <ButtonText>Settings</ButtonText>
        </SettingsHoverButton> */}
        {/* <HoverButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={() => {
          modalDispatch({
            type: 'open',
            component: ({close}) => {
              return (
                <DraggablePopup
                  close={close}
                  headerContent={
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <PTIcon />Periodic Table
                    </div>
                  }
                >
                  <img src="https://assets.bemoacademicconsulting.com/files/6DYeAolAMQbarKbldPyybGYAN7XsptiiJjRdxlK1.jpg.webp" style={{width: '100%', height: 'calc(100vh - 200px)'}} />
                </DraggablePopup>
              )
            },
            enableClickClose: false,
            componentProps: {},
            modalContainerStyle: theme => `
              background: transparent;
            `
          })
        }}>

          <PTIcon/>
          <ButtonText>Periodic Table</ButtonText>

        </HoverButton> */}
        <MobileHoverButton onClick={() => handleSuspendClick()} style={{borderLeft: 'none', borderRight: '1px solid white'}}><PauseIcon/><ButtonText>Pause</ButtonText></MobileHoverButton>
      </ButtonContainer>
      <ButtonContainer style={{justifyContent: 'flex-end'}}>
        {((questionProgresses?.[questionIndex]?.didCheck && tutorMode) || alwaysShowingExplanation) ? [
          <Tooltip dismissalInteractionKey="dismissedMasteryTagTutorial"/>,
          <MobileHoverButton onClick={() => setShowingMasteryControls(!showingMasteryControls)}><MobileTagIcon/></MobileHoverButton>,
          <MasteryButton hotkey="1, l, option+l" label="Learning" color="red" onClick={() => onClickMasteryButton('learning')} />,
          <MasteryButton hotkey="2, r, option+r" label="Reviewing" color="yellow" onClick={() => onClickMasteryButton('reviewing')} />,
          <MasteryButton hotkey="3, m, option+m" label="Mastered" color="green" onClick={() => onClickMasteryButton('mastered')} />,
          <HoverButton onClick={() => handleNavigation('previous')}>
            <LeftArrowIcon /><ButtonText><UnderlineSpan>P</UnderlineSpan>revious</ButtonText>
          </HoverButton>,
          <HoverButton onClick={showNavigator}>
            <NavigatorIcon /><ButtonText>Na<UnderlineSpan>v</UnderlineSpan>igation</ButtonText>
          </HoverButton>,
          <HoverButton onClick={() => handleNavigation('next')}>
            <ButtonText><UnderlineSpan>N</UnderlineSpan>ext</ButtonText><RightArrowIcon />
          </HoverButton>,
          ] : [
        <HoverButton onClick={() => handleNavigation('previous')}>
          <LeftArrowIcon /><ButtonText><UnderlineSpan>P</UnderlineSpan>revious</ButtonText>
        </HoverButton>,
        <HoverButton onClick={showNavigator}>
          <NavigatorIcon /><ButtonText>Na<UnderlineSpan>v</UnderlineSpan>igation</ButtonText>
        </HoverButton>,
        <HoverButton onClick={() => handleNavigation('next')}>
          <ButtonText><UnderlineSpan>N</UnderlineSpan>ext</ButtonText><RightArrowIcon />
        </HoverButton>
        ]}
      </ButtonContainer>
    </Container>
  )
}

const NavigatorTableHeader = styled.div`
  display: flex;
  align-content: center;
  /* nclex/ui-light-blue */

  background: #80AEE1;
  /* nclex/ui-dark-blue */

  border-bottom: 0.5px solid #006DAA;
`;

const NavigatorTableHeaderCell = styled.div`
  width: 50%;
  padding: 12px 16px;
  color: white;
`;
const NavigatorTable = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
const NavigatorTableRow = styled.div`
  display: flex;
  background: ${({even, current}) => current ? '#FFFF67' : even ? 'white' : '#EEEEEE'};
  &:hover {
    cursor: pointer;
    background: #FFFF67;
  }
  ${({isSequentialSet}) => isSequentialSet && css`
    border-left: 5px solid #3385ff;
  `}
`;

const NavigatorTableRowCell = styled.div`
  width: 50%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  color: ${({isRed}) => !isRed ? '#404040' : '#E05E57'};
`;

export default Controls;
