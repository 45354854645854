import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {ChevronRight} from '@styled-icons/feather/ChevronRight';
import {FlexBox, Column} from '../../../components/Grid';
import {H3, Body3} from '../../../components/Typography';
import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '../../../contexts/UserData';
import {Upgrade} from '../../../components/Modal';
import {Right, Middle, Left} from './Chapter';
import {StyledUpgradeButton} from './components/Buttons';

import {DocumentArrowDown} from '@styled-icons/fluentui-system-filled/DocumentArrowDown';

const Container = styled(FlexBox)`
  cursor: pointer;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  user-select: none;
  padding: 16px 24px;
  background: ${({theme}) => theme.colors.special.pearl};
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  transition: all 100ms ease;
  ${({locked, theme}) => locked ? css`
    opacity: 0.7;
  ` : css`
    &:hover {
      transform: scale(1.01);
    }
  `}
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => theme.layouts.spacing.m}
  }
`;
const Title = styled(H3)`
`;
const Description = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  max-width: 630px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  ${({theme}) => theme.mediaQueries.mobileXl} {
    flex-direction: column;
  }
`;

const StyledDocumentArrowDown = styled(DocumentArrowDown)`
  color: ${({theme, themePalette}) => themePalette?.default || theme.colors.neutralsPalette.lightGrey};
  width: 42px;
  height: 42px;
  padding: 6px;
  border-radius: 64px;
  background: ${({themePalette}) => themePalette?.light || '#f6f9fc'} ;
`;

const Download = ({className, name, description, label, onClick, locked, themePalette, actions={}, rawRoute=false, icon}) => {
  const {modalDispatch} = useModalContext();

  if (locked) {
    return (
      <Container onClick={() => modalDispatch({
        type: 'open',
        component: Upgrade,
        enableClickClose: true,
        modalContainerStyle: theme => `
            padding: 10vh 10vw;
            align-items: flex-start;
            ${theme.mediaQueries.tablet} {
              padding: 0;
            }
          `
      })}>
        <Left>
          <StyledDocumentArrowDown themePalette={themePalette} size={20} />
        </Left>
        <Wrapper>
          <Middle>
            <Title>{name}</Title>
            {description && (
              <Description>
                {description}
              </Description>
            )}
          </Middle>
          <Right>
            <StyledUpgradeButton />
          </Right>
        </Wrapper>
      </Container>
    )
  }
  return (
    <Container onClick={onClick}>
      <Left>
        {icon || <StyledDocumentArrowDown themePalette={themePalette} size={20}/>}
      </Left>
      <Wrapper>
        <Middle>
          <Title>{name}</Title>
          {description && (
            <Description>
              {description}
            </Description>
          )}
        </Middle>
        <Right>
          {actions}
        </Right>
      </Wrapper>
    </Container>
  );
}

Download.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.element)
};

Download.defaultProps = {
  actions: [<ChevronRight size={32}/>]
}

export default Download;
