import React from 'react';
import styled from 'styled-components';

import {Link} from 'react-router-dom';

import {H2, H3, H4} from '@bootcamp/web/src/components/Typography';
import {Heading, Blurb, BlurbText} from '@bootcamp/web/src/bootcamps/pages/CustomTestReview/components/shared';
import ProgressBar from '../../../components/ProgressBar';
import {ChevronRight} from '@styled-icons/feather/ChevronRight';

import {useUserDataContext} from '../../../contexts/UserData';
import {useModalContext} from '../../../contexts/Modal';
import {resolveIconPath} from '@bootcamp/web/src/helpers';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';

import theme from '@bootcamp/shared/src/styles/theme';

import fieldsOfDentistry from '@bootcamp/shared/src/assets/icons/inbde/fields_of_dentistry.png';
import oralPathology from '@bootcamp/shared/src/assets/icons/inbde/oral_pathology.png';
import medicine from '@bootcamp/shared/src/assets/icons/inbde/medicine.png';
import pharmacology from '@bootcamp/shared/src/assets/icons/inbde/pharmacology.png';
import patientManagement from '@bootcamp/shared/src/assets/icons/inbde/patient_management.png';
import anatomyPhysiology from '@bootcamp/shared/src/assets/icons/inbde/anatomy_physiology.png';
import research from '@bootcamp/shared/src/assets/icons/inbde/research.png';
import {TargetArrow} from '@styled-icons/fluentui-system-filled/TargetArrow';

import AdultHealthImg from '@bootcamp/shared/src/assets/icons/nclex/AdultHealth.png'
import ChildHealthImg from '@bootcamp/shared/src/assets/icons/nclex/ChildHealth.png'
import CriticalCareImg from '@bootcamp/shared/src/assets/icons/nclex/CriticalCare.png'
import FundamentalsImg from '@bootcamp/shared/src/assets/icons/nclex/Fundamentals.png'
import MaternalandNewbornHealthImg from '@bootcamp/shared/src/assets/icons/nclex/MaternalandNewbornHealth.png'
import MentalHealthImg from '@bootcamp/shared/src/assets/icons/nclex/MentalHealth.png'
import PharmacologyImg from '@bootcamp/shared/src/assets/icons/nclex/Pharmacology.png'
import ManagementofCareImg from '@bootcamp/shared/src/assets/icons/nclex/ManagementofCare.png'


const classroomIconsByBootcamp = {
  'inbde': [
    fieldsOfDentistry,
    oralPathology,
    medicine,
    pharmacology,
    patientManagement,
    anatomyPhysiology,
    research,
  ],
  'nclex': [
    AdultHealthImg,
    ChildHealthImg,
    CriticalCareImg,
    FundamentalsImg,
    MaternalandNewbornHealthImg,
    MentalHealthImg,
    PharmacologyImg,
    ManagementofCareImg
  ]
};

const feedbackByBootcamp = {
  'inbde': '98% of INBDE Bootcamp students who meet both targets pass the INBDE. Performance only tracks INBDE question banks, not Bites.',
  'med-school': '98% of Med School Bootcamp students who meet both targets pass Step 1. Performance only tracks Step 1 Qbanks, not Bites.',
  'nclex': '99% of NCLEX Bootcamp students who meet both targets pass the NCLEX.'
};

const Container = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;
const StyledBlurb = styled(Blurb)`
  margin-top: ${({theme}) => theme.layouts.spacing.m};
  ${BlurbText} {
    margin: 0;
  }
`;
const StyledLabel = styled(H2)`
  color: white;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const OverallWrapper = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;
const CardHeader = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.ms};
`;
const CardTitle = styled(H3)`
  text-decoration: none;
`;
const CardIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;
const CardIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  flex: 2;
`;
const BarContainer = styled.div`
  display: flex;
  gap: ${({theme}) => theme.layouts.spacing.m};
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const BarWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const BarTopLabel = styled.span`
  font-family: 'proxima-nova';
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const BarBottomLabel = styled.div`
  display: flex;
  font-family: 'proxima-nova';
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  margin-top: 8px;
`;
const TargetHeader = styled(H4)`
  margin-bottom: 4px;
`;
const TargetBody = styled.div`
  text-align: center;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background: ${({complete, theme}) => complete ? theme.colors.interfacePalette.green.light : theme.colors.neutralsPalette.offWhite};
  align-self: center;
  border-radius: 8px;
  padding: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.m}`};
  font-family: 'proxima-nova';

  ${TargetHeader} {
    color: ${({complete, theme}) => complete ? theme.colors.interfacePalette.green.dark : theme.colors.neutralsPalette.extraDark};
  }

  ${TargetBody} {
    color: ${({complete, theme}) => complete ? theme.colors.interfacePalette.green.default : theme.colors.neutralsPalette.grey};
  }
`;
const Chevron = styled(ChevronRight)`
  width: 24px;
  height: 24px;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin: auto 0 auto ${({theme}) => theme.layouts.spacing.m};
`;
const CardContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  background: white;
  padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`};
  border-radius: 8px;
  text-decoration: none;
  transition: all 100ms ease;

  ${({isOverallCard}) => !isOverallCard ? `
    &:hover {
      transform: scale(1.01);
    }
  ` : `
    cursor: default;
  `}

  ${CardHeader} {
    ${CardIcon} {
      display: none;
    }
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;

    ${CardIcon} {
      display: none;
    }

    ${Right} {
      margin-top: 24px;
      width: 100%;
    }

    ${BarContainer} {
      margin: 0;
    }

    ${CardHeader} {
      display: flex;
      align-items: center;

      ${CardIcon} {
        display: flex;
        margin-right: 4px;
      }
    }

    ${Chevron} {
      display: none;
    }
  }
`;

const SmallCardContainer = styled(CardContainer)`
  flex: 1;
  cursor: default;
  transform: none;

  &:hover {
    transform: none;
  }


  ${BarTopLabel}  {
    font-weight: 700;
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  }
`;

const SmallCard = ({
  name,
  barSegments,
  total,
  label,
  loading
}) => {

  return (
    <SmallCardContainer as={'div'}>
      <BarWrapper>
        <BarTopLabel>{name}</BarTopLabel>
        <ProgressBar
          segments={barSegments}
          total={total}
          label={
            <BarBottomLabel>
              {loading ? 'loading...' :label}
            </BarBottomLabel>
          }
          lightMode
          bareLabel
          loading={loading}
          withMargin={false}
          // targetNumber={targets?.averageScorePercentage}
          // targetLabel={!stillCollecting && `${targets?.averageScorePercentage}%`}
          // targetMet={!stillCollecting && scoreTargetMet}
        />
      </BarWrapper>
    </SmallCardContainer>

  );
}

export const Card = ({
  name,
  route,
  totalQuestions=463,
  mastery,
  performance,
  targets,
  index,
  masteryLoaded,
  performanceLoaded,
  icon,
  className,
  disableClick
}) => {
  const {bootcamp} = useUserDataContext();

  const percentage = Math.round(((performance?.correct / performance?.attempts) || 0) * 100);
  const scoreTargetMet = percentage >= targets?.averageScorePercentage;
  const taggedQuestionTargetMet = mastery?.total >= targets?.totalTagged;

  // NOTE this is a little misleading b/c we track max points in attempts for nclex so 3 points on a 3 pointer = 3 attempts
  const stillCollecting = performance?.attempts < 10;
  const targetsMet = [scoreTargetMet && !stillCollecting, taggedQuestionTargetMet].filter(target => !!target).length;

  const targetText = targetsMet === 2
    ? "Nice work! You've met the targets."
    : targetsMet === 1 && taggedQuestionTargetMet
    ? "Try to improve your score!"
    : targetsMet === 1 && scoreTargetMet
    ? "You’re doing great! Keep going!"
    : "Just getting started. You got this!";

  const {modalDispatch} = useModalContext();
  const openCreateTest = (subject, system) => modalDispatch({
    type: 'open',
    component: CreateTest,
    componentProps: {defaults: {subject, system}},
    enableClickClose: true,
  })
  const isOverallCard = name === 'Overall'

  return (
    <CardContainer className={className} isOverallCard={isOverallCard || disableClick} as={bootcamp === 'nclex' || isOverallCard || disableClick ? 'div' : Link} key={name} to={route ?? '#'} onClick={() => bootcamp === 'nclex' && !isOverallCard ? openCreateTest(name) : null}>
      {!isOverallCard && <CardIconWrapper>
        <CardIcon src={classroomIconsByBootcamp?.[bootcamp]?.[index] || resolveIconPath(icon)}/>
      </CardIconWrapper>}
      <Left>
        <CardHeader>
          <CardIcon src={classroomIconsByBootcamp?.[bootcamp]?.[index] || resolveIconPath(icon)}/>
          <CardTitle>{name}</CardTitle>
        </CardHeader>
        <BarContainer>
          <BarWrapper>
            <BarTopLabel>Your Score</BarTopLabel>
            <ProgressBar
              segments={[!stillCollecting && {count: percentage, color: theme.colors.brandPalette.blue.gradient}]}
              total={100}
              label={
                <BarBottomLabel>
                  {!performanceLoaded
                    ? 'loading...'
                    : stillCollecting
                    ? <span>answer <strong>{10 - performance?.attempts}</strong> more questions</span>
                    : <>
                        <span><strong>{percentage}%</strong></span>
                      </>
                  }
                </BarBottomLabel>
              }
              lightMode
              bareLabel
              loading={!performanceLoaded}
              withMargin={false}
              targetNumber={targets?.averageScorePercentage}
              targetLabel={!stillCollecting && `${targets?.averageScorePercentage}%`}
              targetMet={!stillCollecting && scoreTargetMet}
            />
          </BarWrapper>
          <BarWrapper>
            <BarTopLabel>Questions Tagged</BarTopLabel>
            <ProgressBar
              segments={[{count: mastery?.mastered}, {count: mastery?.reviewing}, {count: mastery?.learning}]}
              total={totalQuestions}
              label={
                <BarBottomLabel>
                  {!masteryLoaded
                    ? 'loading...'
                    : <span><strong>{Math.min(mastery?.total, totalQuestions) || 0}</strong> of {totalQuestions}</span>
                  }
                </BarBottomLabel>
              }
              lightMode
              bareLabel
              loading={!masteryLoaded}
              withMargin={false}
              targetNumber={(targets?.totalTagged / totalQuestions) * 100}
              targetLabel={targets?.totalTagged}
              targetMet={taggedQuestionTargetMet}
            />
          </BarWrapper>
        </BarContainer>
      </Left>
      {isOverallCard && <Right complete={scoreTargetMet && taggedQuestionTargetMet}>
        <TargetHeader>{targetsMet} of 2 targets met</TargetHeader>
        <TargetBody>{targetText}</TargetBody>
      </Right>}
      {(!isOverallCard && !disableClick) && <Chevron/>}
    </CardContainer>
  );
}

const Overall = ({performanceData, educator}) => {
  const {bootcamp} = useUserDataContext();
  const feedback = feedbackByBootcamp[bootcamp];

  const {byClassroom: performance, overall, videos} = performanceData;

  const allTargets = performance.reduce((acc, record) => {
    const {targets, performance, mastery} = record;
    const percentage = Math.round(((performance?.correct / performance?.attempts) || 0) * 100);
    const scoreTargetMet = percentage >= targets?.averageScorePercentage;
    const taggedQuestionTargetMet = mastery?.total >= targets?.totalTagged;

    const stillCollecting = performance?.attempts < 10;
    const targetsMet = [scoreTargetMet && !stillCollecting, taggedQuestionTargetMet].filter(target => !!target).length;
    return acc + targetsMet;
  }, 0);

  const totalTaggedTargets = performance.reduce((acc, {targets}) => acc + targets.totalTagged, 0);
  const overallAverageScorePercentage = Math.round(performance.reduce((acc, {targets}) => acc + targets.averageScorePercentage, 0) / performance.length);
  const overallTargets = (overall.mastery.total >= totalTaggedTargets ? 1 : 0) + ((Math.round((overall.correct / overall.attempts) * 100)) >= overallAverageScorePercentage ? 1 : 0);

  return (
    <Container>
      <Heading>
        <StyledLabel>{['med-school']?.includes(bootcamp) ? 'Step 1 - ' : ''}Pass Targets</StyledLabel>
        <StyledBlurb icon={educator} text={<span>You've met <strong>{overallTargets}</strong> of 2 overall targets (<TargetArrow size="16" color="white" style={{marginBottom: '4px'}} />). {feedback}</span>} lightMode />
      </Heading>
      <OverallWrapper>
        <Card
          name={'Overall'}
          performance={overall}
          targets={{averageScorePercentage: overallAverageScorePercentage, totalTagged: totalTaggedTargets}}
          {...overall}
        />
      </OverallWrapper>
      <Heading>
        <StyledLabel>{['med-school']?.includes(bootcamp) ? 'Step 1 - ' : ''}Subject Performance</StyledLabel>
        {/* <StyledBlurb icon={educator} text={<span>You've met <strong>{allTargets}</strong> of {performance.length * 2} targets (<TargetArrow size="16" color="white" style={{marginBottom: '4px'}} />).</span>} lightMode /> */}
      </Heading>
      <Wrapper>
        {performance.map(Card)}
      </Wrapper>
    </Container>
  );
};

Overall.defaultProps = {};
Overall.propTypes = {};

export default Overall;