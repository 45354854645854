import React, {useState} from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';

import {Body2} from '../../../components/Typography';
import {SubTitle as Title} from '../../components/Cards/Overview';
import PracticeTestCard from '../../components/Cards/PracticeTest';
import {useUserDataContext} from '../../../contexts/UserData';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import PageTabs from '../../components/PageTabs';

import Container from '../PageContainer';
import theme from '@bootcamp/shared/src/styles/theme';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const Overview = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;
  max-width: 750px;
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0px;
  }
`;

const Body = styled(Body2)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;

const FullLengthTestDirectory = ({history, match}) => {
  const [activeContentType, setActiveContentType] = useState('Full Length Tests')
  const {config} = useBootcampConfigContext();
  const {name, content} = config.studyTools.find(classroom => classroom.route === 'full-length');
  const {isUpgraded, isBootcampPlusStudent, bootcamp} = useUserDataContext();
  const themePalette = theme.colors.brandPalette.royal;
  const tabs = isBootcampPlusStudent && bootcamp === 'dat' ? [
    {
      name: 'Full Length Tests',
      route: 'full-length'
    },
    {
      name: 'FL Plus Tests',
      route: 'full-length-plus'
    }
  ] : [
    {
      name: 'Full Length Tests',
      route: 'full-length'
    }
  ]
  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Overview>
        <StyledBreadcrumbs history={history} lockAtDepth={2}/>
        <Title>
          Full Length Practice Tests
        </Title>
        <Body>
          Each Full Length Test is a compilation of the Practice Tests from every subject. For example, if you've taken Biology Test #2, you'll see the same biology questions in Full Length Test #2.
        </Body>
      </Overview>
      <PageTabs
        tabs={tabs}
        activeContentType={activeContentType}
        setActiveContentType={setActiveContentType}
        themePalette={themePalette}
      />
      {content.filter(item => match.url.includes('plus') ? isBootcampPlusStudent && item.plusPack : !item.plusPack).map((item, index) => (
        <PracticeTestCard
          key={item.id}
          route={`full-length/${item.route}`}
          name={item.name}
          id={item.id}
          index={match.url.includes('plus') ? index + 11 : index + 1}
          showProgress={false}
          themePalette={themePalette}
          showTestResults
          showText={'Review Previous Attempts'}
          hideText={'Hide Previous Attempts'}
          locked={index > 0 && !isUpgraded}
        />
      ))}
    </Container>
  );
}

FullLengthTestDirectory.propTypes = {};
FullLengthTestDirectory.defaultProps = {};

export default FullLengthTestDirectory;
