import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useUserDataContext} from '../../../contexts/UserData';

import {H2} from '../../../components/Typography';
import Select, {TopicSelect} from './Select';
import MasteryCard from './Card';

import styled, {css} from 'styled-components';
import {colors} from '@bootcamp/shared/src/styles/theme';

const Container = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  }

  ${({datStyling}) => datStyling && css`
    width: 100%;
    margin-bottom: 0;
  `}
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }

  ${({datStyling}) => datStyling && css`
    padding: 1.167%;
  `}

`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
    align-items: flex-start;
    flex-direction: column;
  }

  ${({datStyling}) => datStyling && css`
    margin: 0em 0em 1em;
  `}
`;
const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  }

  ${({datStyling}) => datStyling && css`
    font: 600 24px 'proxima-nova', sans-serif;
    text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  `}
`;
const SelectWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

function defaultFormatCardLink(baseUrl, subjectRoute, masteryLevel) {
  return subjectRoute
    ? `${baseUrl}/${subjectRoute}/tagged-questions/${masteryLevel}`
    : `${baseUrl}/tagged-questions/${masteryLevel}`;
}



const MasteryReview = ({config, loading, inRouter, match, baseUrl, formatCardLink, datStyling, title}) => {
  const {bootcamp} = useUserDataContext();
  const [filter, setFilter] = useState(null);

  const masteryLevelProgress = useMemo(() => config.reduce((acc, {name, progress}) => {
    const shouldFilter = filter && filter !== 'all' && filter !== name;

    if (!name || !progress || shouldFilter) return acc;

    return {
      learning: acc.learning + (progress.learning || 0),
      reviewing: acc.reviewing + (progress.reviewing || 0),
      mastered: acc.mastered + (progress.mastered || 0),
      bookmarked: acc.bookmarked + (progress.bookmarked || 0),
      total: acc.total + (progress.total || 0),
    }
  }, {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    bookmarked: 0,
    total: 10,
  }),
    [config, filter]
  );

  const activeClassroomFilter = filter !== 'all' ? config.find(({name}) => name === filter) : null;

  return (
    <Container datStyling={datStyling}>
      <Header datStyling={datStyling}>
        <Title>Tagged Questions</Title>
        <Select config={config} onChange={setFilter}/>
      </Header>
      <Wrapper datStyling={datStyling}>
        <MasteryCard
          color={colors.interfacePalette.red.default}
          backgroundColor={colors.interfacePalette.red.gradient}
          count={masteryLevelProgress.learning}
          level={'learning'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(baseUrl, activeClassroomFilter?.route, 'learning')}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
        <MasteryCard
          color={colors.interfacePalette.yellow.default}
          backgroundColor={colors.interfacePalette.yellow.gradient}
          count={masteryLevelProgress.reviewing}
          level={'reviewing'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(baseUrl, activeClassroomFilter?.route, 'reviewing')}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
        <MasteryCard
          color={colors.interfacePalette.green.default}
          backgroundColor={colors.interfacePalette.green.gradient}
          count={masteryLevelProgress.mastered}
          level={'mastered'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(baseUrl, activeClassroomFilter?.route, 'mastered')}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
        <MasteryCard
          color={colors.brandPalette.royal.default}
          backgroundColor={colors.interfacePalette.green.gradient}
          count={masteryLevelProgress.bookmarked}
          level={'bookmarked'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(baseUrl, activeClassroomFilter?.route, 'bookmarked')}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
      </Wrapper>
    </Container>
  );
};

export const MasteryReviewWithTopic = ({config, loading, inRouter, match, baseUrl, formatCardLink, datStyling, title}) => {
  const {bootcamp} = useUserDataContext();
  const [topicFilter, setTopicFilter] = useState(null);
  const [filter, setFilter] = useState(null);

  const classroomProgress = useMemo(() => config.reduce((acc, {name, progress}) => {
    const shouldFilter = filter && filter !== 'all' && filter !== name;

    if (!name || !progress || shouldFilter) return acc;

    return {
      learning: acc.learning + (progress.learning || 0),
      reviewing: acc.reviewing + (progress.reviewing || 0),
      mastered: acc.mastered + (progress.mastered || 0),
      bookmarked: acc.bookmarked + (progress.bookmarked || 0),
      total: acc.total + (progress.total || 0),
    }
  }, {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    bookmarked: 0,
    total: 10,
  }),
    [config, filter]
  );

  const activeClassroomFilter = filter !== 'all' ? config.find(({ name }) => name === filter) : null;

  const masteryLevelProgress = activeClassroomFilter && topicFilter && topicFilter !== 'all'
    ? activeClassroomFilter.progress.byTopic[topicFilter]
    : classroomProgress;

  function setSubjectFilter(subjectFilter) {
    setTopicFilter(null);
    setFilter(subjectFilter);
  }

  return (
    <Container datStyling={datStyling}>
      <Header datStyling={datStyling}>
        <Title>Tagged Questions</Title>
        <SelectWrapper>
          <Select config={config} onChange={setSubjectFilter} loading={loading} />
          {activeClassroomFilter && !loading &&
            <TopicSelect
              subject={activeClassroomFilter.name}
              config={Object.values(activeClassroomFilter.progress.byTopic)}
              onChange={setTopicFilter}
            />
          }
        </SelectWrapper>
      </Header>
      <Wrapper datStyling={datStyling}>
        <MasteryCard
          color={colors.interfacePalette.red.default}
          backgroundColor={colors.interfacePalette.red.gradient}
          count={masteryLevelProgress.learning}
          level={'learning'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(`${bootcamp}/${activeClassroomFilter?.route}`, 'learning', topicFilter)}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
        <MasteryCard
          color={colors.interfacePalette.yellow.default}
          backgroundColor={colors.interfacePalette.yellow.gradient}
          count={masteryLevelProgress.reviewing}
          level={'reviewing'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(`${bootcamp}/${activeClassroomFilter?.route}`, 'reviewing', topicFilter)}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
        <MasteryCard
          color={colors.interfacePalette.green.default}
          backgroundColor={colors.interfacePalette.green.gradient}
          count={masteryLevelProgress.mastered}
          level={'mastered'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(`${bootcamp}/${activeClassroomFilter?.route}`, 'mastered', topicFilter)}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
        <MasteryCard
          color={colors.brandPalette.royal.default}
          backgroundColor={colors.interfacePalette.green.gradient}
          count={masteryLevelProgress.bookmarked}
          level={'bookmarked'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatCardLink(`${bootcamp}/${activeClassroomFilter?.route}`, 'bookmarked', topicFilter)}
          as={inRouter ? null : 'a'}
          datStyling={datStyling}
        />
      </Wrapper>
    </Container>
  );
};

MasteryReview.defaultProps = {
  loading: true,
  inRouter: true,
  baseUrl: 'home',
  formatCardLink: defaultFormatCardLink,
  datStyling: false,
  title: 'Review Banks',
};

MasteryReviewWithTopic.defaultProps = {
  loading: true,
  inRouter: true,
  baseUrl: 'home',
  formatCardLink: defaultFormatCardLink,
  datStyling: false,
  title: 'Review Banks',
};

MasteryReview.propTypes = {
  progress: PropTypes.shape({}),
  loading: PropTypes.bool,
  inRouter: PropTypes.bool,
  baseUrl: PropTypes.string,
  formatCardLink: PropTypes.func,
  datStyling: PropTypes.bool,
  title: PropTypes.string,
};

export default MasteryReview;
