import React from 'react';
import styled from 'styled-components';


import {CheckmarkCircle} from '@styled-icons/fluentui-system-filled/CheckmarkCircle';

import {
  UpgradeCallout,
  UpgradeHeader,
  UpgradeSubHeader,
  UpgradeFeaturesTable,
  UpgradeFeaturesTableRow,
  UpgradeFeaturesTableRowIconContainer,
  UpgradeFeaturesTableRowText,
} from './shared';


const Checkmark = styled(CheckmarkCircle)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
  margin-right: 8px;
`;


export const nclexFeatures = (
  <>
    <UpgradeCallout>
      <UpgradeHeader>
      Pass the NCLEX®. Guaranteed.
      </UpgradeHeader>
      <UpgradeSubHeader>
        Upgrade your membership and get instant access to all content and features 👇
      </UpgradeSubHeader>
    </UpgradeCallout>
    <UpgradeFeaturesTable>
      <UpgradeFeaturesTableRow>
        <Checkmark/>
        <UpgradeFeaturesTableRowText>
          <strong>1,500+ high-yield questions</strong> based on the real NCLEX®
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <Checkmark/>
        <UpgradeFeaturesTableRowText>
          <strong>250+ NGN case study questions</strong> with video explanations
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <Checkmark/>
        <UpgradeFeaturesTableRowText>
          <strong>4 readiness exams</strong> that predict your chances of passing
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <Checkmark/>
        <UpgradeFeaturesTableRowText>
          <strong>Bootcamp AI</strong> to instantly clarify any concept
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <Checkmark/>
        <UpgradeFeaturesTableRowText>
          Access to our <strong>4.9+ rated mobile app</strong>
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <Checkmark/>
        <UpgradeFeaturesTableRowText>
          Feel confident in our <strong>Pass Guarantee</strong>
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
    </UpgradeFeaturesTable>
  </>
);

