import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const hoverTransitionTime = '.5s';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: ${hoverTransitionTime};
  cursor: pointer;
  line-height: 0;

  ${({size, theme}) => {
    switch (size) {
      case 'small':
        return `
          width: 48px;
          height: 48px;
          padding: 12px;
          ${theme.mediaQueries.tablet} {
            width: 32px;
            height: 32px;
            padding: 5px;
          }
        `;
      case 'large':
        return `
          width: 64px;
          height: 64px;
          padding: 16px;
          ${theme.mediaQueries.tablet} {
            width: 48px;
            height: 48px;
            padding: 10px;
          }
        `;
      default:
        return `
          width: 48px;
          height: 48px;
          padding: 12px;
          ${theme.mediaQueries.tablet} {
            width: 32px;
            height: 32px;
            padding: 5px;
          }
        `;
    }
  }}
  ${({type, theme}) => {
    switch (type) {
      case 'primary':
        return `
          transition: all 50ms ease;
          background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.colors.neutralsPalette.white};
          box-shadow: ${theme.elevation.shadow.low};

          &:hover {
            transform: scale(1.02);
            ${theme.elevation.shadow.medium};
          }


        `
      case 'secondary':
        return `
          background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.overlays.opacity.light._100};
          border: .5px solid ${theme.overlays.opacity.light._50};
        `
      default:
        return `
          background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.colors.neutralsPalette.white};
          box-shadow: ${theme.elevation.shadow.medium};
        `
    }
  }}
  ${({disabled, type, theme}) => disabled && `
    opacity: .5;
    background: ${() => {
      switch (type) {
        case 'primary':
          return theme.colors.neutralsPalette.light;
        case 'secondary':
          return theme.overlays.opacity.light._100;
        default:
        return theme.colors.neutralsPalette.light;
      }
    }};
    cursor: not-allowed;
  `}

  &:hover {
    ${({type, theme}) => {
      switch (type) {
        case 'primary':
          return `
            background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : theme.colors.neutralsPalette.white};
            box-shadow: ${theme.elevation.shadow.high};
          `
        case 'secondary':
          return `
            background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : theme.overlays.opacity.light._200};
          `
        default:
          return `
            background: ${theme.colors.neutralsPalette.white};
            box-shadow: ${theme.elevation.shadow.high};
          `
      }
    }}
    transition: ${hoverTransitionTime};
  }
`;

const IconButton = ({children, ...props}) => (
  <Container {...props}>
    {children}
  </Container>
);

IconButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'large']),
  disabled: PropTypes.bool
};

IconButton.defaultProps = {
  type: 'primary',
  size: 'large',
  disabled: false
};


export default IconButton;
