import React from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';

const Container = styled.div`
  .responsive {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
const Thumbnail = styled.img`
  width: 100%;
`;

const YouTubePlayer = ({className, responsive, locked, autoplay=0, ...props}) => {
  return (
    <Container className={className}>
      {locked && <Thumbnail src={`http://img.youtube.com/vi/${props.videoId}/0.jpg`} alt=""/>}
      {!locked && <YouTube opts={{playerVars:{rel: 0, autoplay}}} onEnd={console.log} containerClassName={responsive ? "responsive" : ""} {...props}/>}
    </Container>
  )
}

export default YouTubePlayer;
