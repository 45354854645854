import React, {createContext, useState, useContext} from 'react';
import firstBy from 'thenby';
import {useUserDataContext} from '../UserData';
import {useBootcampConfigContext} from '../BootcampConfig';

import {listTests, getTest} from '@bootcamp/shared/src/requests';
import * as composers from '@bootcamp/shared/src/helpers/testComposers';
import * as customTestComposers from '@bootcamp/shared/src/createTest/composers';
import {validateCustomTest} from '@bootcamp/shared/src/util';

const TestContext = createContext();

const alphabeticalTitleWithoutNumeral = test =>  {
  const title = test.config.title.trim().match(/([^#.]+)/);
  return title ? title[0] : '';
};
const numericalNumeral = test => {
  const numeral = test.config.title.trim().match(/[#.]([0-9]*)/);
  return numeral ? parseInt(numeral[1]) : '';
};
const byTestNameAndNumeral = firstBy(alphabeticalTitleWithoutNumeral).thenBy(numericalNumeral);

const useTest = () => {
  const [test, setTest] = useState(null);
  const [tests, setTests] = useState([]);
  const [customTestConfig, setCustomTestConfig] = useState(null);

  const {DEFAULT_USER_ID, bootcamp, isUpgraded, refreshAndSearchUserInteractions, saveUserInteraction} = useUserDataContext();
  const {config} = useBootcampConfigContext() || {};

  const filterTests = (filter, filterRequired=false, excludeIds=[]) => {
    if (filterRequired && !filter) return [];

    return tests
      .sort(firstBy('createdAt', -1))
      .filter(test => {
        if (excludeIds.some(id => id === test.id)) return false;

        return filter
          ? (
              filter
                .toLowerCase()
                .split(' ')
                .every(el => test.config.title.toLowerCase().includes(el)) ||
                test.id.includes(filter.toLowerCase())
            )
          : true
      })
      .sort(byTestNameAndNumeral);
  }

  const fetchTest = async (id, query="getTest") => {
    setTest(null);
    if (id) {
      const {data: {getTest: test}} = await getTest(id, query);
      test.blockConnections.items = test.blockConnections.items.filter(({testBlock}) => ['questionSet'].includes(testBlock?.type) && testBlock?.questionConnections ? !testBlock.questionConnections?.items.every(({question}) => question?.status && question?.status === 'draft') : true);
      setTest(test);
    }
  }

  const fetchTests = async () => {
    if (tests.length === 0) {
      const result = await listTests();
      const tests = !!result ? result.data.listTests.items.sort(firstBy(t => t.config.title)) : [];
      setTests(tests);
    }
  };

  const reset = () => {
    setTest(null);
    setCustomTestConfig(null);
  }

  const composeQuickStudyByContentType = async (subjectIds, contentType, testLength=10) => {

    setTest(null);

    const composedTest = await composers.composeQuickStudyByContentType(config, DEFAULT_USER_ID, bootcamp, subjectIds, contentType, testLength, window.location.reload);

    setTest(composedTest);
  };

  const composeQuickStudy = async (subjectIds, testLength=10) => {
    setTest(null);

    const composedTest = await composers.composeQuickStudy(config, DEFAULT_USER_ID, bootcamp, subjectIds, testLength, !isUpgraded);

    setTest(composedTest);
  }

  const composeQuickReview = async (subjectIds, testLength=10) => {
    setTest(null);

    const composedTest = await composers.composeQuickReview(config, DEFAULT_USER_ID, bootcamp, subjectIds, testLength);

    setTest(composedTest);
  }

  const composeCustomTest = async (config, testLength=10) => {
    setTest(null);


    const [composedTest, customConfig] = await customTestComposers.composeCustomTest(config, DEFAULT_USER_ID);

    const savedTestsInteractionKey = `${DEFAULT_USER_ID}_${bootcamp}_savedTests`;
    const savedTests = await refreshAndSearchUserInteractions(savedTestsInteractionKey);
    await saveUserInteraction(savedTestsInteractionKey, JSON.stringify({testList: [...(savedTests?.testList || []), composedTest.config.testStorageKey]}));

    setTest(composedTest);
    setCustomTestConfig(customConfig);

    return composedTest.config.testStorageKey
  }


  const restoreCustomTest = async (testStorageKey) => {
    setTest(null);
    setCustomTestConfig(null);

    const [composedTest, customConfig] = await customTestComposers.restoreCustomTest(testStorageKey);

    const isValid = validateCustomTest(config, isUpgraded, customConfig.testIds || []);

    if (!isValid) {
      return setTest({valid: false});
    }

    setTest(composedTest);
    setCustomTestConfig(customConfig);
  }

  return {
    test,
    setTest,
    fetchTest,
    tests,
    fetchTests,
    filterTests,
    reset,
    customTestConfig,
    composeQuickReview,
    composeQuickStudy,
    composeQuickStudyByContentType,
    composeCustomTest,
    restoreCustomTest
  }

}

const TestProvider = ({match, children, template}) => {
  return (
    <TestContext.Provider value={useTest()}>
      {children}
    </TestContext.Provider>
  );
}

const useTestContext = () => useContext(TestContext);

export {useTestContext, TestProvider};
