// TALKING POINT this is hardcoded for now. Do we want to introduce a 'section' container to the data model? Being able to organize blocks into sections could be a nice feature for content creators.
export function getSectionTitle(blockIndex) {
  return blockIndex === 8
    ? 'Section Break'
    : blockIndex < 5
    ? 'Survey of Natural Sciences'
    : blockIndex < 8
    ? 'Perceptual Ability Test'
    : blockIndex < 14
    ? 'Reading Comprehension'
    : blockIndex < 17
    ? 'Quantitative Reasoning'
    : '';
}

export function getOATSectionTitle(blockIndex) {
  return blockIndex === 10
    ? 'Section Break'
    : blockIndex < 5
    ? 'Survey of Natural Sciences'
    : blockIndex < 10
    ? 'Reading Comprehension'
    : blockIndex < 14
    ? 'Physics'
    : blockIndex < 17
    ? 'Quantitative Reasoning'
    : '';
}

export function getSectionTimeLimit(sectionTitle, accommodation) {
  const accomodationMultiplier = 1.5;
  const timeLimit = {
    'Survey of Natural Sciences': 90,
    'Perceptual Ability Test': 60,
    'Reading Comprehension': 60,
    'Quantitative Reasoning': 45,
    'Physics': 50
  }[sectionTitle];

  return accommodation ? timeLimit * accomodationMultiplier : timeLimit;
}

export function handleCloseClick (match) {
  const {params: {classroom, contentType, bootcamp}} = match;
  const redirect = window.confirm('Would you like to end the test and return to the Classroom page?');
  if (redirect && window.location.pathname.startsWith(`/${bootcamp}/full-length/`)) {
    return window.location.pathname = `/${bootcamp}/full-length`
  };
  if (redirect) window.location.pathname = `/${bootcamp}/${classroom}/${contentType}`;
}

export const barHeight = 70;
