import React, {useRef} from 'react';
import {breakdown} from './data/datScoringBreakdown';
import {Bar} from 'react-chartjs-2';

import Chart from 'chart.js/auto';
import styled from 'styled-components';

import theme from '@bootcamp/shared/src/styles/theme';


const BarContainer = styled.div`
  position: relative;
  background: ${({theme}) => theme.darkModeEnabled ? 'transparent' : 'white'};
  height: 320px;
`;
const YourScore = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 80px;
  font-size: 14px;
  font-family: proxima-nova;
`;
const Swath = styled.div`
  width: 38px;
  height: 14px;
  background: ${({theme}) => theme.colors.interfacePalette.green.default};
  margin-right: 8px;
`;

const ScoreDistributionChart = ({name, options, score}) => {
  const ref = useRef(null);
  // Calculate frequencies
  let {totalAttempts, totalQuestions, ...frequencies} = breakdown[name] || {};

  const scores = Object
    .entries(frequencies)
  const summedAttempts = scores.reduce((acc, curr) => acc + curr[1], 0);

  const filledScores = scores
    .reduce((acc, [score, occurance]) => {
      return [...acc, ...Array(occurance).fill(score)];
    }, [])

  let labels = Object.keys(frequencies).sort((a, b) => a - b);
  let frequencyData = labels.map(label => frequencies[label] / summedAttempts);

  // Calculate mean and standard deviation
  let mean = Object.entries(frequencies).reduce((acc, [score, occurance]) => acc + (score * occurance), 0) / summedAttempts;
  let stdDev = Math.sqrt(filledScores.map(x => Math.pow(x - mean, 2)).reduce((acc, val) => acc + val, 0) / filledScores.length);

  // Normal Distribution Data Calculation
  const calculateNormalDistribution = () => {
    // let min = mean - 3 * stdDev;
    // let max = mean + 3 * stdDev;

    let min = 0
    let max = scores.length;

    let step = (max - min) / scores.length; // Adjust the step based on your data density

    let normalDistribution = [];
    for (let x = min; x <= max; x += step) {
      let pdfValue = (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
      normalDistribution.push(pdfValue);
    }

    return normalDistribution;
  };

  const backgroundColors = labels.map((value, index) => {
    if (parseInt(value) === parseInt(score)) {
      return theme.colors.interfacePalette.green.default;
    } else {
      return `${theme.colors.brandPalette.royal.default}99`;
    }
  });

  const hoverBackgroundColors = labels.map((value, index) => {
    if (parseInt(value) === parseInt(score)) {
      return theme.colors.interfacePalette.green.dark;
    } else {
      return theme.colors.brandPalette.royal.dark;
    }
  });

  const borderColors = labels.map((value, index) => {
    if (parseInt(value) === parseInt(score)) {
      return theme.colors.interfacePalette.green.dark;
    } else {
      return 'transparent';
    }
  });


  const borderWidths = labels.map((value, index) => {
    return 1;
  });


  const data = {
    labels,
    datasets: [
      {
        label: 'Frequency',
        data: frequencyData,
        borderWidth: borderWidths,
        type: 'bar',
        backgroundColor: backgroundColors,
        hoverBackgroundColor: hoverBackgroundColors,
        borderColor: borderColors,
      },
      {
        label: 'Normal Distribution',
        data: calculateNormalDistribution(),
        pointRadius: 0,
        type: 'line',
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.1
      },
      {
        label: '', // handling this manually for better positioning,
        data: [],
        type: 'line',
        fill: false,
        backgroundColor: theme.colors.interfacePalette.green.default,
        borderWidth: 0,
        tension: 0.1
      }
    ]
  };


  return (
    <BarContainer>
      <Bar
        ref={ref}
        data={{
          ...data,
          datasets: data.datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.map(value => value * 100),
          }))
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'top',
              align: 'end',
              onClick: () => {},
              labels: {
                filter: (legendItem) => legendItem.text === 'Your Score',
                borderWidth: 0,
                drawBorder: false,
                font: {
                  family: 'proxima-nova',
                  size: 12
                }
              }
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' + context.parsed.y.toFixed(2) + '%';
                },
                footer: function(context) {
                  return context[0].parsed.x === score ? 'Your Score' : '';
                }
              }
            }
          },
          scales: {
            y: {
              title: {
                display: true,
                text: 'Frequency',
                font: {
                  family: 'proxima-nova',
                  size: 12
                }
              },
              grid: {
                display: false, // Hide the gridlines on the y-axis
              },
              ticks: {
                callback: function (value, index, values) {
                  return value + '%';
                }
              }
            },
            x: {
              title: {
                display: true,
                text: 'Total Correct',
                font: {
                  family: 'proxima-nova',
                  size: 12
                }
              },
              grid: {
                display: false, // Hide the gridlines on the x-axis
              },
              point: {
                radius: 0,
              }
            },
          }
        }}
      />
      <YourScore>
        <Swath/> Your Total Correct
      </YourScore>
    </BarContainer>
  );
}

export default ScoreDistributionChart;
