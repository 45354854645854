import React, {useState} from 'react';
import {Helmet} from 'react-helmet';

import styled, {css} from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import ChapterCard, {Right} from '@bootcamp/web/src/bootcamps/components/Cards/Chapter'
import Container from '../../pages/PageContainer';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {StyledResetButton, StyledBeginPracticeButton, StyledUpgradeButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';
import {Heading, FreeQuestionsCard} from '../shared';
import {useContentTypeProgress} from '../../api/progress/contentType';

const StyledChapterCard = styled(ChapterCard)`
  background: white;
  border: none;
  overflow: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;



const NextGenCases = ({match, history, location}) => {
  const [resetInitialized, setResetInitialized] = useState({});
  const {isUpgraded} = useUserDataContext();

  const {config} = useBootcampConfigContext();

  const name = 'Case Studies';
  const filteredClassrooms = config?.classrooms?.find(({route}) => route.match('next-gen-cases'));

  const testsByClassroom = getTestsByClassroom([filteredClassrooms], [], [], true);
  const contentTypeProgress = useContentTypeProgress(filteredClassrooms.contentTypes[0], 'next-gen-cases');
  const {progress, counts} = contentTypeProgress?.['qbanks-next-gen-cases'] || {};

  const {modalDispatch} = useModalContext();

  const openCreateTest = (subject, system) => modalDispatch({
    type: 'open',
    component: CreateTest,
    componentProps: {defaults: {subject, system}},
    enableClickClose: true,
  });


  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading
        name={name}
        description={'The case studies below are based on the real Next Gen NCLEX. Practice with these so you\'re ready for the real exam.'}
        educators={[
          {firstName: 'Dr. Cortney Olejniczak, DNP', avatar: 'educators/cortney_olejniczak.png'},
          {firstName: 'Crysta Bliss, MSN', avatar: 'educators/crysta_bliss.png'},
        ]}
        />
      <Wrapper>
        {testsByClassroom['next-gen-cases'].map((test, index) => {
          const isLocked = !isUpgraded && index > 2;
          return (
            <StyledChapterCard
              route={`qbanks/${test.route}`}
              name={test.name}
              fullWidthProgress
              progress={progress && progress.byTest ? progress.byTest[test.id] : 'loading'}
              questionCount={counts ? counts[test?.id] : 'loading'}
              actions={!isLocked
                ? [
                  progress?.byTest?.[test.id] && <StyledResetButton testId={test.id} setInitialized={setResetInitialized}/>,
                  <StyledBeginPracticeButton hideOnMobile={!resetInitialized?.[test.id]} small children={'Start'}/>
                ] : [
                  <StyledUpgradeButton size={'small'} round children={'Upgrade'}/>
              ]}
              defaultLocked={isLocked}
              index={index+1}
            />
        )})}
      </Wrapper>
    </Container>
  );
}

NextGenCases.propTypes = {};
NextGenCases.defaultProps = {};

export default NextGenCases;
