import React from 'react';
import styled, {css} from 'styled-components';

import {Row} from '../Grid';
import {Body3, H4} from '../Typography';

import {resolveIconPath} from '@bootcamp/web/src/helpers';

const imageDimension = 42;
const imageSpacingFactor = 2;

function getImagePosition(index, total, alignImages) {
  const scaledDimension = imageDimension - (index * 6);

  const styleByIndex = alignImages === 'blurb'
    ? [
      css`
      width: ${scaledDimension}px;
      height: ${scaledDimension}px;
    `,
      css`
      width: ${scaledDimension}px;
      height: ${scaledDimension}px;
      top: ${total === 2 ? 'unset' : `${imageDimension / 4}px`};
      bottom: 0;
      left: ${total === 2 ? (imageDimension * .75) : imageDimension}px;
    `,
      css`
      width: ${scaledDimension}px;
      height: ${scaledDimension}px;
      left: ${imageDimension / 2}px;
      bottom: 0;
    `,
    ] : [
      css`
      width: ${imageDimension}px;
      height: ${imageDimension}px;
    `,
      css`
      width: ${imageDimension}px;
      height: ${imageDimension}px;
      left: ${imageDimension - 12}px;
    `,
      css`
      width: ${imageDimension}px;
      height: ${imageDimension}px;
      left: ${(imageDimension * 2) - 24}px;
    `,
    ]

  return styleByIndex[index];
}

function getContainerHeight(total, alignImages) {

  return alignImages === 'row'
    ? css`
      height: ${imageDimension}px;
      min-width: ${(imageDimension * total) - (12 * (total - 1))}px;
    `
    : total === 3
    ? css`
      height: 70px;
      width: 80px;
    `
    : total === 2
    ? css`
      height: 70px;
      width: 70px;
    `
    : css`
      height: 42px;
      width: 42px;
    `
}

const Container = styled(Row)`
  height: auto;
  align-items: ${({orientation}) => orientation === 'row' ? 'center' : 'flex-start'};
  flex-direction: ${({orientation}) => orientation};

  ${({theme}) => theme.mediaQueries.laptop} {
    align-items: ${({orientation}) => orientation === 'column' ? 'flex-end' : 'center'};
  }
`;

const Images = styled(Row)`
  position: relative;
  width: auto;
  height: auto;
  margin-right: ${({theme, total, orientation}) => total === 1 && orientation === 'column' ? '0': theme.layouts.spacing.s};
  ${({total, alignImages}) => getContainerHeight(total, alignImages)};
`;

const ImageContainer = styled.div`
  ${({index, total, alignImages}) => getImagePosition(index, total, alignImages)}
  position: absolute;
  display: flex;
  z-index: 2;

  &:first-child {
    margin-left: 0;
  }

  ${({index, total}) => `&:nth-child(${index + 1}) {
    z-index: ${total - index};
  }`}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  transition: background .2s;
  padding: 2px;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  border: 0.5px solid rgba(255, 255, 255, 0.05);

  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 32px;
    height: 32px;
  }
`;

const Text = styled(Body3)`
  align-self: ${({alignPreface}) => alignPreface === 'left' ? 'flex-start' : 'flex-end'};
  ${({lightMode}) => lightMode && css`
    color: ${({theme}) => theme.overlays.opacity.light._400};
  `}
  ${({whiteMode}) => whiteMode && css`
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
  `}
`;

const TextContainer = styled.div`
  display: flex;
  width: auto;
  flex-flow: ${({textPosition, ledByTextOrientation}) =>  ledByTextOrientation === 'column' ? 'column' : textPosition === 'left' ? 'row' : 'row wrap'};
  align-items: ${({ledByOrientation, ledByTextOrientation}) => ledByOrientation === 'column' ? 'flex-start' : ledByTextOrientation === 'column' ? 'flex-end' : 'center'};
  flex-direction: ${props => props.ledByTextOrientation || props.ledByOrientation};
  margin-right: ${({textPosition, theme}) =>  textPosition === 'left' ? theme.layouts.spacing.m : '0px'};
  white-space: ${({textPosition, theme}) =>  textPosition === 'left' ? 'nowrap' : 'normal'};
  /* width: 100%; */

  ${({withBorder}) => withBorder && css`
    padding: ${({theme}) => theme.layouts.spacing.m} 0;
    border-top: 1px solid ${({theme}) => theme.overlays.opacity.dark._100};
  `}

  ${Text} {
    margin-right: ${({theme, ledByOrientation, ledByTextOrientation, textPosition}) => (ledByTextOrientation !== 'column' && ledByOrientation === "row") ? '5px' : "0px"};
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    border: none;
    padding-top: ${({theme}) => theme.layouts.spacing.s};
    align-items: ${({ledByTextOrientation}) => ledByTextOrientation === 'column' ? 'flex-end' : 'center'};
  }
`
const NameStringWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({layout}) => layout === 'row'
    ? 'flex-flow: row wrap;'
    : 'flex-direction: column;'
  }

  h4 {
    margin-right: 4px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;
const NameString = styled(H4)`
  white-space: nowrap;
  font-weight: bold;
  color: ${({theme, themeColor, whiteMode}) => whiteMode ? theme.colors.neutralsPalette.extraDark : (themeColor || theme.colors.neutralsPalette.white)};
`;

const nameStringFormatter = (names, withPunctuation=true) => names.length === 1
  ? names
  : names.map((name, index) => {
    if (index === names.length - 1) {
      return `${withPunctuation ? '& ' : ''}${name}`;
    }
    if (names.length > 2) {
      return `${name}${withPunctuation ? ',' : ''}`;
    }
    return `${name}`;
  });

export const ExpertEducators = ({className, educators, lightMode, whiteMode, size, themeColor, orientation, ledByOrientation, preface, textPosition, ledByTextOrientation, educatorNameLayout, alignPreface, alignImages}) => {
  // NOTE: limiting this to 3 for now - I think we'll only ever have a max of 3 so config should be updated accordingly
  const slicedEducators = educators.slice(0, 3);
  const educatorNames = nameStringFormatter(slicedEducators.slice(0, 3).map(educator => educator.firstName));

  const educatorAvatars = slicedEducators.slice(0, 3).map((educator, index, all) => (
    <ImageContainer key={`educator-${index}`} index={index} total={all.length} alignImages={alignImages}>
      <Image src={`https://dekni9rgkrcu0.cloudfront.net/assets/${educator.avatar}`} size={size} index={index} total={all.length}/>
    </ImageContainer>
  ));

  return (
    <Container className={className} orientation={orientation}>
      {textPosition === 'left' &&
        <TextContainer orientation={'row'} ledByOrientation={orientation}  textPosition={'left'} ledByTextOrientation={ledByTextOrientation}>
          <Text alignPreface={alignPreface} ledByOrientation={ledByOrientation} lightMode={lightMode} whiteMode={whiteMode}>{preface}</Text>
          <NameStringWrapper layout={educatorNameLayout}>
            {educatorNames?.map(name => (
              <NameString key={name} themeColor={themeColor} whiteMode={whiteMode}>
                {name}
              </NameString>
            ))}
          </NameStringWrapper>
        </TextContainer>
      }
      {orientation === 'column' &&
        <TextContainer orientation={'column'} ledByOrientation={orientation} withBorder  ledByTextOrientation={ledByTextOrientation}>
          <Text alignPreface={alignPreface} ledByOrientation={ledByOrientation} lightMode={lightMode} whiteMode={whiteMode}>{preface}</Text>
          <NameStringWrapper layout={educatorNameLayout}>
            {educatorNames?.map(name => (
              <NameString key={name} themeColor={themeColor} whiteMode={whiteMode}>
                {name}
              </NameString>
            ))}
          </NameStringWrapper>
        </TextContainer>
      }
      <Images total={slicedEducators.length} children={educatorAvatars} orientation={orientation} alignImages={alignImages}/>
      {orientation === 'row' && textPosition !== 'left' &&
        <TextContainer ledByOrientation={ledByOrientation}  ledByTextOrientation={ledByTextOrientation}>
          <Text alignPreface={alignPreface} ledByOrientation={ledByOrientation} lightMode={lightMode} whiteMode={whiteMode}>{preface}</Text>
          <NameStringWrapper layout={educatorNameLayout}>
            {educatorNames?.map(name => (
              <NameString key={name} themeColor={themeColor} whiteMode={whiteMode}>
                {name}
              </NameString>
            ))}
          </NameStringWrapper>
        </TextContainer>
      }
    </Container>
  )
};

ExpertEducators.defaultProps = {
  educators: [
    {
      firstName: 'Whitney',
      avatar: 'educators/whitney.png',
    },
    {
      firstName: 'Ari',
      avatar: 'educators/ari.gif',
    }
  ],
  displayAsCount: false,
  lightMode: false,
  size: 'large',
  ledByOrientation: 'row',
  ledByTextOrientation: 'row',
  orientation: 'row',
  preface: 'Content Experts',
  alignPreface: 'right',
  alignImages: 'row',
  educatorNameLayout: 'row',
}
