import React, {useEffect} from 'react';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {UpdatedPreviousButton, UpdatedCheckButton, UpdatedNextButton, UpdatedExhibitButton, BookmarkButton} from '@bootcamp/web/src/components/QuestionBank/Buttons';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import {insertAtIndex, getTestBlockQuestions} from '@bootcamp/shared/src/util';
import styled from 'styled-components';
import {SubmitAnatomyCase} from '@bootcamp/web/src/components/Modal';
import {MasteryButton, MasteryButtonText} from '@bootcamp/web/src/components/Branding/Buttons/MasteryButton';
import theme from '@bootcamp/shared/src/styles/theme';
import {KeyboardShortcutLabel} from '@bootcamp/web/src/components/Typography';
import {updateQuestionProgress} from '@bootcamp/shared/src/requests';

const Container = styled.div`
  width: 100%;
  height: 88px;
  padding: 16px 32px;
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  position: absolute;
  bottom: 0;
  left: 0;
  transition: left .3s;
  display: flex;
  ${({theme}) => theme.mediaQueries.laptop} {
    left: 0;
    position: fixed;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 8px 16px 16px 16px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    button {
      margin-top: 0;
      margin-bottom: 0;
      width: calc(100% - 8px);
    }
    &:last-child {
      button {
        width: 100%;
      }
    }
  }

`;

const StyledPreviousButton = styled(UpdatedPreviousButton)`
  ${({theme}) => theme.mediaQueries.laptop} {
    padding: 19px;
    margin-right: 8px;
  }
`;
const StyledNextButton = styled(UpdatedNextButton)`
  ${({theme}) => theme.mediaQueries.laptop} {
    padding: 19px;
    margin-right: 8px;
  }
`;
const StyledCheckButton = styled(UpdatedCheckButton)`
  ${({theme}) => theme.mediaQueries.laptop} {
    padding: 19px 24px;
    margin-right: 8px;
  }
`;

function getNextBlock(blocks, blockIndex) {
  return blocks?.[blockIndex + 1]?.testBlock;
}

const Controls = ({showingSidebar, themePalette, exhibit, showingExhibit, toggleExhibit, handleNextLesson, handlePreviousLesson}) => {

  const {
    methods: {
      setLeftButtons: setButtons,
      updateQuizProgress,
      transitionBlock,
      setCurrentIndex,
      saveBlockProgress,
      saveQuestionProgresses,
      setNavigationFilter
    },
    variables: {
      blockIndex,
      testBlockConnections,
      leftButtons: buttons,
      quizProgress,
      navigationFilter
    }
  } = useTestNavigatorContext();

  const {toggleTimer, timerActive, questionStartTime} = useTimerContext();
  const {bootcamp, searchUserInteractions} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  const autoTagEnabled = true;
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;

  const {questions: blockQuestionProgress} = {questions: [], ...quizProgress[blockIndex]};
  const questions = getTestBlockQuestions(testBlockConnections[blockIndex]?.testBlock);
  const questionIndex = blockQuestionProgress.findIndex(({current}) => current);
  const currentQuestionProgress = blockQuestionProgress[questionIndex] || {};

  const nextBlock = getNextBlock(testBlockConnections, blockIndex) || {};
  const {type: nextBlockType} = nextBlock;


  const checkQuestion = async (question, index) => {

    const timeElapsed = new Date(new Date() - questionStartTime);
    const time = (parseFloat(blockQuestionProgress[index].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    const credit = {
      full: (!!currentQuestionProgress?.answerState?.score && !!currentQuestionProgress?.answerState?.maxScore && currentQuestionProgress?.answerState?.score === currentQuestionProgress?.answerState?.maxScore) || currentQuestionProgress?.didSelectCorrectAnswer,
      partial: currentQuestionProgress?.answerState?.score > 0 && (currentQuestionProgress?.answerState?.score !== currentQuestionProgress?.answerState?.maxScore),
      none: (!currentQuestionProgress?.answerState?.score || currentQuestionProgress?.answerState?.score === 0),
    };

    const autoTag = credit.full
      ? 'mastered'
      : credit.partial
      ? 'reviewing'
      : 'learning';

    updateQuizProgress({
      questions: insertAtIndex(
        quizProgress[blockIndex].questions,
        index,
        {...question, didCheck: true, time, masteryLevel: autoTag},
      )
    });
    await updateQuestionProgress(question.questionProgressId, {time, didCheck: true, masteryLevel: autoTag, didSelectCorrectAnswer: credit.full, selectedAnswerIndex: currentQuestionProgress.selectedAnswerIndex});
    // auto tag question if we're on nclex or med school
    const update = {
      ...currentQuestionProgress,
      masteryLevel: autoTag,
      question: {...question.question, masteryLevel: autoTag},
    };

    await saveQuestionProgresses([update]);

    timerActive && toggleTimer();
  };

  useEffect(() => {
    const {testBlock} = testBlockConnections[blockIndex] || {testBlock: {}};


    switch (testBlock.type) {
      case 'passage':
      case 'questionSet':
        const blockQuestions = quizProgress?.[blockIndex]?.questions;

        if (!blockQuestions || blockQuestions.length === 0) return;

        const currentQuestion = blockQuestions.find(({current}) => !!current);

        if (!currentQuestion) return;
        const currentQuestionIndex = blockQuestions.findIndex(({current}) => !!current);
        const currentQuestionIsLast = currentQuestionIndex === blockQuestions.length - 1;
        const question = questions[currentQuestionIndex] || {};

        function updateQuestionTime() {
          const timeElapsed = new Date(new Date() - questionStartTime);
          const time = (parseFloat(blockQuestionProgress[currentQuestionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);
          updateQuestionProgress({time});
        };

        function handlePreviousQuestion () {
          updateQuestionTime();
          setCurrentIndex(blockIndex, currentQuestionIndex - 1)
        }

        function handleNextQuestion () {
          updateQuestionTime();
          setCurrentIndex(blockIndex, currentQuestionIndex + 1)
        }

        const updateMastery = (masteryLevel) => {
          const updatedQuestionProgress = {...currentQuestion, masteryLevel, question: {...question, masteryLevel}};
          updateQuizProgress({
            questions: insertAtIndex(
              blockQuestions,
              currentQuestionIndex,
              updatedQuestionProgress
            )
          })
          saveQuestionProgresses([updatedQuestionProgress]);
        }
        const updateQuestionProgress = params => {

          if (currentQuestionIndex === -1) return;

          const updatedQuestionProgress = {...blockQuestionProgress[currentQuestionIndex], ...params};

          updateQuizProgress({
            questions: insertAtIndex(
              blockQuestionProgress,
              currentQuestionIndex,
              updatedQuestionProgress
            )
          });
          if (params.didCheck) {
            timerActive && toggleTimer();
          }
          return updatedQuestionProgress;
        };
        const onClickMasteryButton = async (masteryLevel) => {
          updateMastery(masteryLevel);
          if (currentQuestionIsLast) {
            await saveBlockProgress({status: 'complete'});
            handleNextLesson();
          } else {
            setCurrentIndex(blockIndex, currentQuestionIndex + 1)
          }
        }
        const bookmarkStatus = blockQuestionProgress?.[currentQuestionIndex]?.bookmarked;

        const masteryButtons = [
          (
            <UpdatedPreviousButton
              key={'previousQuestion'}
              onClick={currentQuestionIndex === 0 ? () => handlePreviousLesson() : handlePreviousQuestion}
            />
          ),
          !currentQuestionIsLast ? (
            <UpdatedNextButton
              key={'nextQuestion'}
              // children={'Next Question'}
              themePalette={themePalette}
              onClick={handleNextQuestion}
            />
          ) : (
            <UpdatedNextButton
              key={'nextLesson'}
              themePalette={themePalette}
              onClick={async () => {
                await saveBlockProgress({status: 'complete'});
                handleNextLesson();
              }}
            />
          ),
          (
            <MasteryButton
              key={currentQuestion.id+'learning'}
              hotkey={'1'}
              children={<MasteryButtonText>Learning{showKeyboardShortcuts && <KeyboardShortcutLabel children="1" />}</MasteryButtonText>}
              onClick={() => onClickMasteryButton('learning')}
              colorConfig={theme.colors.interfacePalette.red}
            />
          ),
          (
            <MasteryButton
              key={currentQuestion.id+'reviewing'}
              hotkey={'2'}
              children={<MasteryButtonText>Reviewing{showKeyboardShortcuts && <KeyboardShortcutLabel children="2" />}</MasteryButtonText>}
              onClick={() => onClickMasteryButton('reviewing')}
              colorConfig={theme.colors.interfacePalette.yellow}
            />
          ),
          (
            <MasteryButton
              key={currentQuestion.id+'mastered'}
              hotkey={'3'}
              children={<MasteryButtonText>Mastered{showKeyboardShortcuts && <KeyboardShortcutLabel children="3" />}</MasteryButtonText>}
              onClick={() => onClickMasteryButton('mastered')}
              colorConfig={theme.colors.interfacePalette.green}
            />
          ),
          (
            <BookmarkButton
              key={currentQuestion.id+'bookmark'}
              onClick={() => {
                const updatedQuestionProgress = updateQuestionProgress({bookmarked: !bookmarkStatus, question: {...question, bookmarked: !bookmarkStatus}});
                saveQuestionProgresses([updatedQuestionProgress]);
              }}
              bookmarked={bookmarkStatus}
              colorConfig={bookmarkStatus ? theme.colors.interfacePalette.yellow : theme.colors.neutralsPalette.light}
              children={showKeyboardShortcuts && <KeyboardShortcutLabel children="B" />}
            />
          )
        ]
        if (currentQuestion.didCheck) {
          setButtons([
            ...(!window.location.pathname.includes('reading-comprehension') ? masteryButtons : [
              (
                <UpdatedPreviousButton
                  children={'Previous'}
                  key={'previousQuestion'}
                  onClick={currentQuestionIndex === 0 ? () => handlePreviousLesson() : handlePreviousQuestion}
                />
              ),
              !currentQuestionIsLast ? (
                <UpdatedNextButton
                  key={'nextQuestion'}
                  children={'Next Question'}
                  themePalette={themePalette}
                  onClick={handleNextQuestion}
                />
              ) : (
                <UpdatedNextButton
                  key={'nextLesson'}
                  children={'Next Lesson'}
                  themePalette={themePalette}
                  onClick={async () => {
                    await saveBlockProgress({status: 'complete'});
                    handleNextLesson();
                  }}
                />
              )
            ]),
            !!exhibit && (
              <UpdatedExhibitButton
                key={currentQuestion?.id+'exhibit'}
                onClick={toggleExhibit}
                exhibitType={exhibit}
                type={'secondary'}
                children={showKeyboardShortcuts && <KeyboardShortcutLabel children="E" />}
              />
            )
          ]);
        } else {
          setButtons([
            (
              <UpdatedPreviousButton
                key={'previousQuestion'}
                onClick={currentQuestionIndex === 0 ? () => handlePreviousLesson() : handlePreviousQuestion}
              />
            ),
            !currentQuestionIsLast ? (
              <UpdatedNextButton
                key={'nextQuestion'}
                // children={'Next Question'}
                themePalette={themePalette}
                onClick={handleNextQuestion}
              />
            ) : (
              <UpdatedNextButton
                key={'nextLesson'}
                themePalette={themePalette}
                onClick={async () => {
                  await saveBlockProgress({status: 'complete'});
                  handleNextLesson();
                }}
              />
            ),
            (
              <UpdatedCheckButton
                key={'checkButton'}
                onClick={async () => await checkQuestion({...currentQuestion, question}, currentQuestionIndex)}
                children={<span style={{display: 'flex', alignItems: 'center'}}>Check Answer{showKeyboardShortcuts && <KeyboardShortcutLabel children="C" />}</span>}
                colorConfig={themePalette}
                disabled={
                  !!blockQuestionProgress &&
                  blockQuestionProgress[currentQuestionIndex] &&
                  blockQuestionProgress[currentQuestionIndex].selectedAnswerIndex === -1
                }
              />
            ),
            !!exhibit && (
              <UpdatedExhibitButton
                onClick={toggleExhibit}
                exhibitType={exhibit}
                type={'secondary'}
                children={showKeyboardShortcuts && <KeyboardShortcutLabel children="E" />}
              />
            )
          ])
        }
        return;
      case 'text':
      case 'lesson':
        setButtons([
          <StyledPreviousButton
            key={'previousQuestion'}
            disabled={blockIndex === 1}
            onClick={() => handlePreviousLesson()}
          />,
          <StyledNextButton
            key={'nextQuestion'}
            themePalette={themePalette}
            onClick={handleNextLesson}
          />,
          <StyledCheckButton
            key={'checkButton'}
            onClick={async () => {
              await saveBlockProgress({status: 'complete'});
              handleNextLesson()
            }}
            children={<span style={{display: 'flex', alignItems: 'center'}}>Complete and Continue{showKeyboardShortcuts && <KeyboardShortcutLabel children="C" />}</span>}
            colorConfig={themePalette}
          />,
          !!exhibit && (
            <UpdatedExhibitButton
              onClick={toggleExhibit}
              exhibitType={exhibit}
              type={'secondary'}
              children={showKeyboardShortcuts && <KeyboardShortcutLabel children="E" />}
            />
          )
        ]);
      break;
      default:
        setButtons([]);
        return;
    }

  }, [blockIndex, quizProgress, showingExhibit, showKeyboardShortcuts, questionStartTime]);
  if (!buttons || !buttons.length) return null;
  return (
    <Container showingSidebar={showingSidebar}>
      <ButtonWrapper>
        {buttons.filter(button => button)}
      </ButtonWrapper>
    </Container>
  );
}

Controls.propTypes = {};
Controls.defaultProps = {};

export default Controls;
