import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {ReactComponent as BootcampLogo} from '@bootcamp/shared/src/assets/svg/bootcamp_white_text_logo.svg';
import StarLogo from '@bootcamp/shared/src/assets/icons/logo/white_star.png';
import TextLogo from '@bootcamp/shared/src/assets/icons/logo/white_text.png';
import Links from './Links';
import TrackSelect, {MenuComponent} from './TrackSelect';
import Upgrade from './Upgrade';

import CreateTestButton from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/CreateTestButton';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {ChevronDown} from '@styled-icons/heroicons-outline/ChevronDown';

const sideBarWidth = 256;

const Container = styled.div`
  position: fixed;
  width: ${sideBarWidth}px;
  height: 100%;
  background: ${({theme}) => theme.colors.special.tuxedo};
  box-shadow: inset -8px 0 5px -5px rgb(18, 23, 27);
  z-index: 10000000;
  transition: all 200ms ease;
  overflow: hidden;

  ${({theme: {mediaQueries}}) => mediaQueries.laptop} {
    width: 0;
    display: none;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: ${({withBottomPadding}) => withBottomPadding ? '212px' : 0};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => theme.layouts.spacing.m};
  height: 72px;
`;
const StyledBootcampLogo = styled(BootcampLogo)`
  max-width: 172px;
  height: 30px;
`;

const Details = styled.div`
  padding: 8px 28px;
`;

const DetailsText = styled.div`
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.overlays.opacity.light._400};
  text-decoration: none;
  a {
    text-decoration: none;
    color: ${({theme}) => theme.overlays.opacity.light._400};
  }
`;

const StyledCreateTestButton = styled(CreateTestButton)`
  margin: 0px 16px 8px;
`;

const bootcampMap = {
  'inbde': 'INBDE Bootcamp',
  'nclex': 'NCLEX Bootcamp',
  'med-school': 'Med School Bootcamp',
  'dental-school': 'Dental Bootcamp',
  'dat': 'DAT Bootcamp',
  'oat': 'OAT Bootcamp',
  'anatomy': 'Anatomy Bootcamp',
  'chemistry': 'Chemistry Bootcamp',
}
const BootcampTitle = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-top: 1px;
  color: #C8C9CE;
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledChevron = styled(ChevronDown)`
  display: inline;
  opacity: 0;
  transform: ${({trackSelectOpen}) => trackSelectOpen ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: all 0.2s ease;
  ${StyledLink}:hover & {
    display: inline;
    opacity: 1;
  }
`

const StyledTrackSelectMenuComponent = styled(MenuComponent)`
  top: 0px;
  left: 15px;
  right: 15px;
`;
const SideBar = ({className, children, ...props}) => {
  const {isUpgraded, trialMembership, bootcamp} = useUserDataContext();
  const [trackSelectOpen, setTrackSelectOpen] = useState(false);
  const containerRef = useRef();
  useEffect(() => {
    if (!containerRef?.current) return;

    const handleClick = event => {
      const isClickInsideContainer = containerRef?.current?.contains(event?.target);

      if (isClickInsideContainer) return;

      setTrackSelectOpen(false);
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);

  }, [containerRef.current]);

  return (
    <Container className={className}>
      <Wrapper withBottomPadding={!isUpgraded || trialMembership}>
        <div ref={containerRef}>
          <LogoWrapper>
            <StyledLink to={`/${bootcamp}`}>
              <img src={StarLogo} style={{height: '36px', width: '36px', marginRight: 8}}/>
              <div onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setTrackSelectOpen(!trackSelectOpen)
                return false;
              }}>
                <img style={{width: 120}} src={TextLogo} />
                <BootcampTitle>{bootcampMap[bootcamp]} <StyledChevron trackSelectOpen={trackSelectOpen} size={14} /></BootcampTitle>
              </div>
            </StyledLink>
            {children}
          </LogoWrapper>
          {trackSelectOpen && <StyledTrackSelectMenuComponent bootcamp={bootcamp} />}
        </div>
        {['inbde', 'nclex', 'med-school', 'anatomy'].includes(bootcamp) && <StyledCreateTestButton/>}
        <Links {...props} />
        <Details>
          <DetailsText as={'a'} href="https://bootcamp.com" target={"_blank"} rel={"noreferrer"}>
            © {(new Date()).getFullYear()} Bootcamp.com™
          </DetailsText>
          <DetailsText>
            <a href={'https://bootcamp.com/terms'} target={'_blank'} rel={"noreferrer"}>
              Terms
            </a>
            &nbsp;·&nbsp;
            <a href={'https://bootcamp.com/privacy'} target={'_blank'} rel={"noreferrer"}>
              Privacy
            </a>
          </DetailsText>
        </Details>
      </Wrapper>
      {(!isUpgraded || trialMembership) && <Upgrade bootcamp={bootcamp} trialMembership={trialMembership} />}
    </Container>
  );
};

SideBar.defaultProps = {};
SideBar.propTypes = {};

export {sideBarWidth};
export default SideBar;
