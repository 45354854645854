import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {PageSection} from '../ClassroomContentWrapper';
import {DownloadButton} from '../Cards/components/Buttons';
import Download from '../Cards/Download';

import API from '@aws-amplify/api';
import { Cache } from 'aws-amplify';

const Container = styled.div`
`;

const Blogposts = ({subject, themePalette}) => {
  // const [loading, setLoading] = useState(true); // TODO this loading state isn't currently used
  const [blogposts, setBlogposts] = useState(Cache.getItem(`${subject}-blogposts`) || []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await API.get('webflow', '/posts', {
          'queryStringParameters': {
            'offset': '0',
            'subject': subject
          }
        });
        const blogposts = result?.items?.items || [];
        setBlogposts(blogposts);

        if (blogposts.length > 0) {
          const expires = (new Date()).getTime() + (60 * 1000) // in a minute
          Cache.setItem(`${subject}-blogposts`, blogposts, {expires});
        }
      } catch (e) {
        console.log(e);
      } finally {
        // setLoading(false);
      }
    }
    blogposts.length === 0 && fetch();
  }, []);

  return (
    <Container>
      <PageSection>
        {blogposts.map((item, index) => (
          <Download
            name={item.name}
            description={item['post-summary']}
            onClick={() => window.open(`https://bootcamp.com/blog/${item.slug}`, '_blank')}
            actions={[
              <DownloadButton themePalette={themePalette} children={'View'} />
            ]}
          />
        ))}
      </PageSection>
    </Container>
  );
};

Blogposts.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
Blogposts.defaultProps = {
  content: [],
  data: {},
};

export default Blogposts;
