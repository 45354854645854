import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {H4, Body3} from './Typography/next';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {Info} from '@styled-icons/fluentui-system-filled/Info';
import {Dismiss} from '@styled-icons/fluentui-system-filled/Dismiss';

const TooltipPosition = styled.div`
  position: absolute;
  ${({position}) => position === 'above' ? 'bottom' : 'top'} : 100%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 8px 32px;
`;
const TooltipContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.brandPalette.indigo.default};
  color: #fff;
  padding: 16px 12px;
  border-radius: 4px;
  width: auto;
  position: relative;
  width: auto;
  max-width: 100%;
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.mobileL} {
    min-width: 100%;
  }
`;
const TooltipContent = styled.div`
`;
const CloseIconContainer = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  padding: 9px;
  border-radius: 100%;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;
const CloseIcon = styled(Dismiss)`
  color: white;
`;
const InfoIcon = styled(Info)`
  color: white;
  opacity: 70%;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 12px;
`;
const Header = styled(H4)`
  margin-bottom: 4px;
`;
const Body = styled(Body3)`
  color: rgba(255, 255, 255, 0.7);
`;

const BaseTooltip = ({dismissalInteractionKey, children, dismissAfterUnmounting=true, position="above"}) => {
  const [closed, setClosed] = useState(false);
  const {saveUserInteraction, searchUserInteractions} = useUserDataContext();
  const didDismissTooltip = searchUserInteractions(dismissalInteractionKey) === true;

  useEffect(() => {
    if (didDismissTooltip) return;
    return () => dismissAfterUnmounting && saveUserInteraction(dismissalInteractionKey, true)
  }, []);

  if (didDismissTooltip || closed) return null;

  return (
    <TooltipPosition position={position} onClick={() => saveUserInteraction(dismissalInteractionKey, true)}>
      <TooltipContainer position={position}>
        <InfoIcon size={20} onClick={() => setClosed(true)}/>
        <TooltipContent>
          {children}
        </TooltipContent>
        <CloseIconContainer onClick={() => setClosed(true)}>
          <CloseIcon size={14}/>
        </CloseIconContainer>
      </TooltipContainer>
    </TooltipPosition>
  )
};

export const Tooltip = props => {
  return (
    <BaseTooltip {...props}>
      <Wrapper>
        <Header>What do the learning, reviewing, and mastered tags do?</Header>
        <Body>
          Bootcamp.com's tagging system lets you mark questions by understanding, helping you concentrate on areas needing improvement.
        </Body>
      </Wrapper>
    </BaseTooltip>
  );
}
