import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom'

import styled from 'styled-components';
import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useDebounce} from '@bootcamp/shared/src/util/hooks';
import {resolveIconPath} from '@bootcamp/web/src/helpers';
import {useHistoryState} from '@bootcamp/web/src/hooks';
import SearchBar from '@bootcamp/web/src/bootcamps/components/SearchBar';
import {H1, H2, Body3} from '@bootcamp/web/src/components/Typography';
import Result from './Result';
import {ScrollContainer, Wrapper} from '@bootcamp/web/src/bootcamps/pages/PageContainer';
import {Waypoint} from 'react-waypoint';
import {LoadingSpinner} from '@bootcamp/web/src/components/Branding';
import {IntercomAPI} from 'react-intercom';
import firstBy from 'thenby';
import Breadcrumbs from '@bootcamp/web/src/components/NavBar/components/Breadcrumbs';
import {IconButton} from '../../../components/Branding/Buttons';

import {ChevronRight} from '@styled-icons/heroicons-outline/ChevronRight';
import {ChevronLeft} from '@styled-icons/heroicons-outline/ChevronLeft';
import useDocumentTitle from '@rehooks/document-title';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const Container = styled(ScrollContainer)`
  padding-left: 0;
  padding-right: 0;
`;
const StyledPageWrapper = styled(Wrapper)`
  padding-left: 0;
  padding-right: 0;
`;
const HorizontalPaddingWrapper = styled.div`
  width: 100%;
  padding: 0 ${({theme}) => theme.layouts.spacing.xl};

  ${({theme: {mediaQueries}}) => mediaQueries.tablet} {
    padding: 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;

const Heading = styled(H1)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.laptop} {
    font-size: 36px;
    line-height: 44px;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const ResultGridContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
  padding: ${({theme}) => theme.layouts.spacing.m} 0px ${({theme}) => theme.layouts.spacing.s};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.custom(820)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ResultRowContainer = styled.div`
  display: flex;
  width: 100%;
  `;
const ResultRowWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding: ${({theme}) => theme.layouts.spacing.m} 12px ${({theme}) => theme.layouts.spacing.s};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  margin-left: -12px;
`;
const StyledSearchBar = styled(SearchBar)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;
const Chiclets = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 36px;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;
const ChicletContainer = styled.div`
  padding: 6px;
  border-radius: 64px;
  background: rgba(255, 255, 255, 0.1);
  /* opacities / light-100 */

  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 12px;
  margin-right: 10px;
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: white;

  &:hover {
    background: ${({theme}) => theme.overlays.opacity.light._200};
  }

`;
const ChicletIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const assetPathMap = {
  'Cardiology': 'icons/msb/heart.png',
  'Dermatology': 'icons/msb/hair.png',
  'Embryology': 'icons/msb/embryo.png',
  'Gastroenterology': 'icons/msb/intestine.png',
  'Gross Anatomy': 'icons/msb/anatomy.png',
  'Hematology & Oncology': 'icons/msb/blood.png',
  'Histology': 'icons/msb/histo.png',
  'Immunology': 'icons/msb/virus.png',
  'Nephrology': 'icons/msb/nephro.png',
  'Neuroanatomy': 'icons/msb/brain.png',
  'Neurology': 'icons/msb/brain.png',
  'Psychiatry': 'icons/msb/psych.png',
  'Pulmonology': 'icons/msb/lung.png',
  'Biostatistics': 'icons/msb/biostatistics.png',
  'Endocrinology': 'icons/msb/endo.png',
  'Genetics': 'icons/msb/genetics.png',
  'Reproduction': 'icons/womb.png',
  'Musculoskeletal': 'icons/musculoskeletal.png',
  'Public Health Sciences': 'icons/pubhealthsci.png',
  'Biochemistry': 'icons/chemistry/oc.png',
  'Microbiology': 'icons/msb/microbio.png',
  'Pathology': 'icons/chemistry/gc.png'
};

const Chiclet = ({subject, onClick}) => {
  function getIcon () {
    try {
      return assetPathMap[subject] ? <ChicletIcon src={resolveIconPath(assetPathMap[subject])} /> : null
    } catch (e) {
      return null
    }
  }
  return (
    <ChicletContainer onClick={onClick}>
      {getIcon()} {subject}
    </ChicletContainer>
  )
}

const SubjectHeading = styled(H2)`
  display: flex;
  flex-direction: column;
  color: white;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const SubjectSection = styled.div`

  padding-left : ${({theme}) => theme.layouts.spacing.xl};
  padding-right: ${({grid, theme}) => grid ? theme.layouts.spacing.xl : 0};

  ${({theme: {mediaQueries}}) => mediaQueries.tablet} {
    padding-left: ${({theme}) => theme.layouts.spacing.m};
    padding-right: ${({grid, theme}) => grid ? theme.layouts.spacing.m : 0};
  }
`;
const ClassroomLink = styled(Link)`
  text-decoration: none;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-left: 0;
  }
  `;
const ClassroomLinkText = styled(Body3)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  font-weight: 400;

  &:hover {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.neutralsPalette.white};
  }
`;
const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-right: ${({theme}) => theme.layouts.spacing.xl};

  ${({theme: {mediaQueries}}) => mediaQueries.tablet} {
    margin-right: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const ScrollButton = styled(IconButton)`
  width: 40px;
  height: 40px;

  &:first-child {
    margin-right: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalloutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.05);
`;

const CalloutText = styled(Body3)`
  color: white;
  a {
    color: white;
  }
`;

const ResultSectionGrid = ({subject, route, results}) => {
  return (
    <SubjectSection grid>
      <ClassroomLink as={Link} to={`${route}`}>
          <SubjectHeading>
            {subject}
              <ClassroomLinkText>
                View all videos and question banks
              </ClassroomLinkText>
          </SubjectHeading>
        </ClassroomLink>
      <ResultGridContainer>
        {results.map((props, index) => <Result key={props.name} layoutType={'grid'} {...props}/>)}
      </ResultGridContainer>
    </SubjectSection>
  );

}
const ResultSectionRow = ({subject, route, results}) => {
  const [scrollPosition, setScrollPosition] = useState();
  const [containerDimensions, setContainerDimensions] = useState();
  const scrollRef = useRef();
  const leftScrollDisabled = !scrollPosition || scrollPosition <= 0;
  const rightScrollDisabled = (scrollPosition + containerDimensions?.width) >= containerDimensions?.scrollWidth;
  const sectionScrollEnabled = Math.round(containerDimensions?.scrollWidth) > Math.round(containerDimensions?.width);

  const handleButtonClick = direction => {
    const currentScrollPosition = scrollRef?.current?.scrollLeft;
    const updatedScrollPosition = direction === 'right'
      ? currentScrollPosition + 324
      : Math.max(currentScrollPosition - 324, 0);

    setScrollPosition(updatedScrollPosition);

    scrollRef?.current?.scrollTo({
      left: updatedScrollPosition,
      behavior: 'smooth',
    })
  };

  useEffect(() => {
    const scrollWidth = scrollRef?.current?.scrollWidth;
    const {width} = scrollRef?.current?.getBoundingClientRect() || {};

    scrollWidth && width && setContainerDimensions({scrollWidth, width});

  }, [scrollRef?.current]);

  return (
    <SubjectSection>
      <SectionHeader>
        <ClassroomLink as={Link} to={`${route}`}>
          <SubjectHeading>
            {subject}
            <ClassroomLinkText>
              View all videos and question banks
            </ClassroomLinkText>
          </SubjectHeading>
        </ClassroomLink>
        {sectionScrollEnabled &&
          <ButtonWrapper>
            <ScrollButton onMouseDown={() => handleButtonClick('left')} type={'secondary'} size={'small'} disabled={leftScrollDisabled}>
              <ChevronLeft color={'white'} size={22}/>
            </ScrollButton>
            <ScrollButton onMouseDown={() => handleButtonClick('right')} type={'secondary'} size={'small'} disabled={rightScrollDisabled}>
              <ChevronRight color={'white'} size={22}/>
            </ScrollButton>
          </ButtonWrapper>
        }
      </SectionHeader>
      <ResultRowContainer>
        <ResultRowWrapper ref={scrollRef}>
          {results.map((props, index) => <Result key={props.name} layoutType={'row'} {...props}/>)}
        </ResultRowWrapper>
      </ResultRowContainer>
    </SubjectSection>
  );

}

const ExplorePage = props => {
  useDocumentTitle('Med School Bootcamp | Explore')
  const {intercomUserAttributes, refreshIntercomUserAttributes} = useUserDataContext();

  const [search, setSearch] = useHistoryState('search', '');
  const [hits, setHits] = useHistoryState('hits', []);
  const [limit, setLimit] = useState(51);
  const {indexedConfig, config} = useBootcampConfigContext();
  const debouncedSearch = useDebounce(search, 250);

  function getResults (search) {
    setLimit(51);
    return Object
      .keys(indexedConfig)
      .filter(key => search ? (search.toLowerCase().split(' ').every(el => key.toLowerCase().includes(el)) && !!indexedConfig[key].video) : key.includes('medschooldefault') && !!indexedConfig[key].video).map(key => indexedConfig[key])
  }

  useEffect(() => {
    setHits(getResults(search));
  }, [debouncedSearch, indexedConfig]);

  const subjects = (config?.classrooms.map(({name, route}) => ({name, route})) || []).sort();

  const resultsSplitBySubject = hits.sort(firstBy('subject')).slice(0, limit)
    .reduce((acc, hit) => acc[hit.subject] ? {...acc, [hit.subject]: [...acc[hit.subject], hit]} : {...acc, [hit.subject]: [hit]}, {})

  const SectionComponent = search ? ResultSectionGrid : ResultSectionRow;

  const resultsBySubject = Object.keys(resultsSplitBySubject).map((subject, index) => {
    return (
      <SectionComponent
        key={`${subject}-${index}`}
        subject={subject}
        route={subjects.find(({name}) => name === subject)?.route}
        results={resultsSplitBySubject[subject]}
        type={search ? 'column' : 'row'}
      />
    );
  })

  const clear = () => {
    setSearch('')
    setHits(Object
      .keys(indexedConfig)
      .filter(key => key.includes('medschooldefault') && !!indexedConfig[key].video).map(key => indexedConfig[key]))
  }

  return (
    <Container>
      <StyledPageWrapper>
        <HorizontalPaddingWrapper>
          <StyledBreadcrumbs history={props.history} lockAtDepth={2}/>
          <Heading onClick={clear}>Explore</Heading>
          <StyledSearchBar
            placeholder="Start searching..."
            onChange={e => setSearch(e.target.value)}
            clear={clear}
            value={search}
          />
          <Chiclets children={subjects.map(({name}) => <Chiclet subject={name} onClick={e => {
            setHits(getResults(name));
            setSearch(name);
          }} />)} />
          {!Object.keys(indexedConfig).length && <LoadingContainer><LoadingSpinner size="24" color="white" /></LoadingContainer>}
        </HorizontalPaddingWrapper>

        {resultsBySubject}

        <Waypoint onEnter={() => hits.length > limit && setLimit(limit => limit + 12)} />
        <HorizontalPaddingWrapper>
          {search && Object.keys(indexedConfig).length > 0 && hits.length === 0 && (
            <CalloutContainer>
              <CalloutText>
                Don't see what you're looking for? <a href="#" onClick={() => IntercomAPI('showNewMessage', `I'm looking for: ${search}`)}>Submit a content request.</a>
              </CalloutText>
            </CalloutContainer>
          )}
        </HorizontalPaddingWrapper>
      </StyledPageWrapper>
    </Container>

  )
}

export default ExplorePage;
