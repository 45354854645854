import React from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import Chapter from '@bootcamp/web/src/bootcamps/components/Cards/Chapter'
import Container from '@bootcamp/web/src/bootcamps/pages/PageContainer';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import {RoundButton, UpgradeButton} from '@bootcamp/web/src/components/UI';
import {ResetButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';
import {Heading} from '@bootcamp/web/src/bootcamps/nclex/shared';
import {useContentTypeProgress} from '@bootcamp/web/src/bootcamps/api/progress/contentType';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;

const CARSPassages = ({match, history, location}) => {
  const {isUpgraded} = useUserDataContext();

  const {config} = useBootcampConfigContext();

  const name = 'CARS Passages';
  const filteredClassrooms = config?.classrooms?.find(({route}) => route.match('cars-passages'));

  const testsByClassroom = getTestsByClassroom([filteredClassrooms], [], [], true);
  const contentTypeProgress = useContentTypeProgress(filteredClassrooms.contentTypes[0], 'qbanks');
  const {progress, counts} = contentTypeProgress?.['qbanks-qbanks'] || {};

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading
        name={name}
        description={'The Passages below are based on real MCAT CARS Passages. Practice with these so you\'re ready for the real thing.'}
        showEducators={false}
        />
      <Wrapper>
        {testsByClassroom['cars-passages'].map((test, index) => {
          const isLocked = !isUpgraded && index > 2;
          return (
            <Chapter
              route={`${test.route}`}
              name={test.name}
              fullWidthProgress
              progress={progress && progress.byTest ? progress.byTest[test.id] : 'loading'}
              questionCount={counts ? counts[test?.id] : 'loading'}
              actions={!isLocked ? [<ResetButton testId={test.id} />,<RoundButton small children={'Start'}/>] : [<UpgradeButton size={'small'} round children={'Upgrade'}/>]}
              defaultLocked={isLocked}
              index={index+1}
            />
        )})}
      </Wrapper>
    </Container>
  );
}

CARSPassages.propTypes = {};
CARSPassages.defaultProps = {};

export default CARSPassages;
