import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import VideoLessonCard from '../Cards/VideoLesson';
import SearchBar from '../SearchBar';
import {useDebounce} from '@bootcamp/shared/src/util/hooks';
import styled, {css} from 'styled-components';
import Callout from '../Cards/Callout';
const Container = styled.div`
`;

// const Callout = styled(Body2)`
//   background: rgba(255, 255, 255, 0.1);
//   border: 0.5px solid rgba(255, 255, 255, 0.05);
//   margin-bottom: 16px;
//   border-radius: 8px;
//   padding: ${({theme}) => theme.layouts.spacing.xl};
//   color: white;
// `;
const SectionSelectorOption = styled.div`
  font-family: 'proxima-nova';
  font-style: normal;
  font-size: 14px;
  line-height: 110%;
  background: none;
  border-radius: 24px;
  color: white;
  background: #FFFFFF1A;
  border-radius: 8px;
  height: 32px;
  padding: 8px 12px;
  margin-bottom: 16px;
  margin-right: 8px;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  ${({active}) => active && css`
    background: white;
    color: #2F3037;
  `}

  &:last-child {
    margin-right: 0;
  }
`;

const VideoLessons = ({content, themePalette, data, calloutText}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const courseContent = content.find(({type}) => type === 'Course');

  const {content: lessonsArray, callouts, startingIndex} = courseContent;
  const indexPadding = startingIndex !== undefined
    ? startingIndex
    : 1;

  const urlParams = new URLSearchParams(window.location.search)
  const selectedSection = urlParams.get('section')

  const history = useHistory();
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (!searchTerm || !selectedSection) return;

    history.replace({
      pathname: window.location.pathname,
      search: `?${urlParams}`
    });
  }, [debouncedSearchTerm]);



  const SearchResults = useMemo(() => lessonsArray.map((item, index) => (
    <VideoLessonCard
      config={item}
      chapterIndex={item.index || index + indexPadding}
      showProgress={false}
      themePalette={themePalette}
      saveBlockProgress={data.saveBlockProgress}
      videoLessonData={data.videoLessonData ? data.videoLessonData[item.id] : {}}
      searchTerm={debouncedSearchTerm}
      showText={`Show all${data.videoLessonData?.[item.id] ? ` ${data.videoLessonData?.[item.id].test.blockConnections.items.length - 2}` : ''} lessons`}
      hideText={`Hide lessons`}
    />
  )), [debouncedSearchTerm, data]);

  return (
    <Container>
      {calloutText && <Callout key={`callout`} children={calloutText} />}
      {callouts && callouts.map((text, index) => <Callout key={`callout-${index}`}>{text}</Callout>)}
      <SearchBar onChange={e => setSearchTerm(e.target.value)} value={searchTerm} placeholder={`Start typing to filter the lessons`} clear={() => setSearchTerm('')}/>
      {window.location.pathname.includes('gross-anatomy') && <Container>
        <SectionSelectorOption onClick={() => {
          history.replace({
            pathname: window.location.pathname,
            search: `?section=all`
          });
          setSearchTerm('Clinical')
        }} active={searchTerm === 'Clinical'} children="Clinical"/>
        <SectionSelectorOption onClick={() => {
          history.replace({
            pathname: window.location.pathname,
            search: `?section=all`
          });
          setSearchTerm('Radiology')
        }} active={searchTerm === 'Radiology'} children="Radiology"/>
      </Container>}
      {SearchResults}
    </Container>
  );
};

VideoLessons.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
VideoLessons.defaultProps = {
  content: [],
  data: {},
};

export default VideoLessons;
