import React from 'react';

import useDocumentTitle from '@rehooks/document-title';
import {Link} from 'react-router-dom';

import Grid from '../../components/Grid';
import MasteryReview from '../../components/MasteryReview';
import GettingStarted from '../../components/GettingStarted';
import FAQs from '../../components/WebflowFAQs';

import {H1, H2} from '../../../components/Typography';
import FullClassroomCard from '../../components/Cards/Classroom';

import {useMasteryArray} from '../../../hooks';

import {ReactComponent as Coffee} from '@bootcamp/shared/src/assets/svg/coffee.svg';

import {formatClassroomMastery} from '@bootcamp/shared/src/util/formatting'
import {getStreakDetails} from '@bootcamp/shared/src/util';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import Container from '../../pages/PageContainer';

import styled from 'styled-components';

const PageTitle = styled(H1)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  font-size: 48px;
  line-height: normal;
  letter-spacing: 0.25px;
  ${({theme}) => theme.mediaQueries.laptop} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
    font-size: 36px;
    line-height: 44px;
  }
`;
const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledClassroomCard = styled(FullClassroomCard)`
  flex: 1;
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  color: black;

  &:last-child {
    margin-right: 0;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    margin-right: 0px;
    margin-bottom: 0 0 ${({theme}) => theme.layouts.spacing.m};
  }

  svg {
    width: 42px;
    height: 42px;
  }
`;

const Home = props => {
  const {config} = useBootcampConfigContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'step-1';

  const todayActions = [
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      icon: {
        component: Coffee,
        text: 'Daily Warmup', themeColor: 'royal'
      },
      text: 'New questions brewed daily just for you',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study`,
      },
      checkIfComplete: interactionObj => {
        const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        return streakCompletedToday;
      }
    },
  ];

  // const {DEFAULT_USER_ID} = useUserDataContext();
  // const [testProgressCount, setTestProgressCount] = useState(null);

  // wire this up to progress load
  const progresses = useMasteryArray(config.classrooms.map(({tagId}) => tagId), 'subject', data => formatClassroomMastery(data, 'subject'));
  const loading = !progresses;

  const classroomMasteryConfig = config.classrooms
    .filter(({locked}) => !locked)
    .map(({name, route, tagId}) => ({name, route, progress: progresses[tagId]}));

  //
  // const totalBookmarked = !loading
  //   ? Object.values(progresses).reduce((acc, {bookmarked}) => bookmarked ? acc += bookmarked : acc, 0)
  //   : 0;
  //
  // const studyToolDisplayData = {
  //   bookmarks: {
  //     count: totalBookmarked,
  //     text: totalBookmarked === 1 ? 'Question' : 'Questions',
  //   },
  //   'past-test-results': {
  //     count: testProgressCount > 100 ? `${testProgressCount}+ ` : testProgressCount,
  //     text: testProgressCount === 1 ? 'Attempt' : 'Attempts',
  //   }
  // };
  //
  // const studyToolConfig = config.studyTools.map(({route, ...rest}) => ({route, ...studyToolDisplayData[route], ...rest}));
  // useEffect(() => {
  //   if(!DEFAULT_USER_ID) return;
  //
  //   async function fetchTestProgressCount() {
  //     const count = await getTestProgressCount(DEFAULT_USER_ID);
  //     setTestProgressCount(count);
  //   }
  //
  //   fetchTestProgressCount();
  //
  // }, [DEFAULT_USER_ID]);

  return (
    <Container>
      <PageTitle>
        Home
      </PageTitle>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Step 1 Subjects
      </Title>
      <Grid
        items={config.classrooms}
        itemsPerRow={3}
        breakpoints={{
          1440: 3
        }}
        renderItem={({icon, themePalette, name, route, tagId, locked, comingSoon, questionCount, educators}) => (
          <StyledClassroomCard
            key={tagId}
            icon={icon}
            themePalette={themePalette}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={comingSoon}
            comingSoon={comingSoon}
            questionCount={questionCount}
            showProgress={true}
            progress={progresses[tagId]}
            showExpertEducators
            educatorLayout={'column'}
            educators={educators}
            detailsBorder={false}
            withHeaderPadding={false}
          />
        )}
      />
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
