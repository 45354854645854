import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import firstBy from 'thenby';
import debounce from 'lodash.debounce';

import {FlexBox} from '../../../components/Grid';

const Container = styled(FlexBox)`
  height: auto;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  &:last-child {
    margin-bottom: 0;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

const Placeholder = styled.div`
  flex: 1;
`;

function findMatchingBreakpoint(breakpoints) {
  const match = Object
    .keys(breakpoints)
    .sort(firstBy(Number, 'asc'))
    .find(breakpoint => window.innerWidth < parseInt(breakpoint));

    return breakpoints[parseInt(match)];
}

function getPlaceholders(numItems, itemsPerRow) {
  return Array.from(new Array(itemsPerRow - numItems)).map(() => <Placeholder/>);
}

const Grid = ({items, itemsPerRow, renderItem, breakpoints}) => {
  // TODO extend this to work for 'items' array with odd numbers
  const initialPerRow = findMatchingBreakpoint(breakpoints) || itemsPerRow;
  const [perRow, setPerRow] = useState(initialPerRow);

  useEffect(() => {
    // don't set event listeners if there are no breakpoints to listen for
    if (!Object.keys(breakpoints).length) return;

    const onResize = debounce(() => {
      const updatedPerRow = findMatchingBreakpoint(breakpoints) || itemsPerRow;

      if (updatedPerRow === perRow) return;

      setPerRow(updatedPerRow);

    }, 275);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);

  }, [breakpoints, perRow, setPerRow]);

  const numRows = Math.ceil(items.length / perRow);
  const createRow = (n, index) => items.slice(index * perRow, (index * perRow) + perRow);

  const rows = Array
    .from(new Array(numRows))
    .map(createRow);
  return (
    <Container>
      {rows.map((items, index) => (
        <Row key={`grid-row-${index}`}>{[...items.map(renderItem), ...getPlaceholders(items.length, perRow)]}</Row>
      ))}
    </Container>
  );
};

Grid.propTypes = {
  items: PropTypes.array,
  itemsPerRow: PropTypes.number,
  renderItem: PropTypes.func,
  breakpoints: PropTypes.object,
};
Grid.defaultProps = {
  items: [],
  itemsPerRow: 2,
  renderItem: () => null,
  breakpoints: {},
};

export default Grid;
