import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import highlightIcon from '@bootcamp/shared/src/assets/icons/highlight_icon.png';

import {
  clearSelection,
  setupEventListeners,
  getTooltipPosition,
  createHighlighter,
} from './helpers';

import styled, {css} from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';


const Container = styled.div`
  position: relative;
  outline: none;
  width: 100%;

  span.highlight {
    cursor: ${({bootcamp}) => bootcamp === 'mcat' ? 'text' : 'pointer'};
  }
`;
const Tooltip = styled.img.attrs({
  src: highlightIcon,
})`
  display: flex;
  background: ${({position: {transparent}}) => transparent ? 'transparent' : 'yellow'};
  width: ${({position: {width}}) => width ? width : 26}px;
  height: ${({position: {transparent}}) => transparent ? 32 : 26}px;
  position: ${({position: {transparent}}) => transparent ? 'fixed' : 'absolute'};
  top: ${({position: {top, transparent}}) => transparent ? top + 5 : top}px;
  left: ${({position: {left}}) => left}px;

  visibility: ${({position}) => Object.keys(position).length ? 'visible' : 'hidden'};
  color: black;
  border: ${({position: {transparent}}) => transparent ? 'none' : '1px solid red'};
  cursor: pointer;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;
const StrikethroughTooltip = styled(Tooltip)`
  display: flex;
  width: ${({position: {width}}) => width}px;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Left = styled.div`
  width: 32px;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

`;
const Right = styled.div`
  flex: 1;
`;
const HighlightDropdown = styled.div`
  position: absolute;
  visibility: ${({showingDropdown}) => showingDropdown ? 'visible' : 'hidden'};
  top: 100%;
  left: 0;
  right: 0;
  width: 202px;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.dark};
  display: flex;
  flex-direction: column;
  padding: 4px;
`;
const Add = styled.div`
  width: 23px;
  height: 23px;
  background: yellow;
  margin-bottom: 2px;
  margin-right: 6px;
`;
const Remove = styled(Add)`
  background: transparent;
  border: 2px solid white;
`;
const OptionWrapper = styled.div`
  display: flex;
  color: white;
  align-items: center;
  padding: 2px;
`;

const HighlightMenuTooltip = ({onTooltipClick, highlighter, active, mode, setMode, showingDropdown, setShowingDropdown, tooltipRef, ...props}) => {
  const handleLeftClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowingDropdown(!showingDropdown);
    return false;
  }

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const preventDefaults = {
    onMouseDown: handleClick,
    onMouseUp: handleClick,
    onClick: handleClick,
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onTooltipClick(null, 'highlight', false, 'add');
    setShowingDropdown(!showingDropdown);
    setMode('add');
  };

  const handleRightMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const onlyRemove = mode === 'remove';
    onTooltipClick(null, 'highlight', onlyRemove, mode);
    setShowingDropdown(false);
  };

  const handleRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onTooltipClick(null, 'highlight', true, 'remove');
    setShowingDropdown(!showingDropdown);
    setMode('remove');
  };

  const handleMouseOver = (event) => {
    if (!tooltipRef?.current) return;
    tooltipRef.current.style.color = 'yellow';
  }
  const handleMouseLeave = (event) => {
    if (!tooltipRef?.current) return;
    tooltipRef.current.style.color = 'unset';
  }

  useEffect(() => {
    if (!active) {
      setShowingDropdown(false);
    }
  }, [active]);

  return (
    <Tooltip {...props} {...preventDefaults}>
      <TooltipWrapper>
        <Left {...preventDefaults} onMouseUp={handleLeftClick}>
          {
            <HighlightDropdown {...preventDefaults} showingDropdown={showingDropdown}>
              <OptionWrapper onClick={handleMouseUp}>
                <Add/>
              </OptionWrapper>
              <OptionWrapper onClick={handleRemove}>
                <Remove/> Remove Highlight
              </OptionWrapper>
            </HighlightDropdown>
          }
        </Left>
        <Right onMouseUp={handleRightMouseUp} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}></Right>
      </TooltipWrapper>
    </Tooltip>
  );
};

const Highlightable = ({
  className,
  children,
  htmlString,
  setHighlights,
  type,
  disableToolTip=false,
  highlights,
  tooltipRef,
  strikethroughTooltipRef,
  onlyQbankProp,
  enableKeyboardShortcuts=false,
  onModeChange=() => {},
}) => {
  const containerRef = useRef(null);
  const containerId = `highlightable-${type}`;
  const {bootcamp} = useUserDataContext();

  const [mode, setMode] = useState('add');
  const [active, setActive] = useState(false);
  const [tooltipMenuActive, setTooltipMenuActive] = useState(false);
  const [highlighter, setHighlighter] = useState(createHighlighter(containerId));
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [strikethroughTooltipPosition, setStrikethroughTooltipPosition] = useState({});

  const tooltipActive = !tooltipRef?.current && Object.keys(tooltipPosition).length;
  const strikethroughTooltipActive = Object.keys(strikethroughTooltipPosition).length;

  useEffect(() => { // reset highlighter whenever the question changes
    const freshHighlighter = createHighlighter(containerId);
    const highlightsToRestore = typeof highlights === 'object' ? highlights?.[containerId] : highlights;

    if (highlightsToRestore) {
      try {
        freshHighlighter.restoreHighlights(highlightsToRestore);
      } catch (error) {
        // console.log('error restoring highlights', error);
      }
    }

    setHighlighter(freshHighlighter);

  }, [htmlString]);


  const handleTooltipClick = (event, className='highlight', onlyRemove, selectedMode) => {
    if (!tooltipRef?.current) {
      setTooltipPosition({});
      setStrikethroughTooltipPosition({});
    }

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (onlyRemove || selectedMode === 'remove') {
      highlighter.removeHighlights(className);
    } else {
      highlighter.highlight(className);
    }

    if (bootcamp === 'nclex') {
      const previous = typeof highlights === 'object' ? highlights : {};
      setHighlights({highlights: {...previous, [containerId]: highlighter.getSerializedHighlights()}});
    } else {
      setHighlights({highlights: highlighter.getSerializedHighlights()});
    }
  };

  const elementInBounds = target => {
    return (
      containerRef &&
      containerRef.current &&
      containerRef.current.contains &&
      containerRef.current.contains(target)
    ) || (
      containerRef &&
      containerRef.current &&
      (containerRef.current === target)
    )
  };

  const handleMouseUp = (event) => {
    if (!active || event.target?.nodeName === 'INPUT') return;
    setActive(false);
    setTooltipMenuActive(false);

    const selection = window.getSelection();
    const focusNode = selection.focusNode;
    const hasMadeSelection = selection.toString().length;

    if (!elementInBounds(event.target) || (focusNode && !elementInBounds(focusNode))) {
      if (!tooltipRef?.current) {
        setTooltipPosition({});
        setStrikethroughTooltipPosition({});
      }
      clearSelection();

      return;
    };
    // if we have an active tooltip (implying a click on a selected area outside of the tooltip) OR
    // the selection type isn't Range (implying a single click on an already highlighted range)
    // dehighlight the range / reset selection and toolitp
    if (tooltipActive || selection.type !== 'Range' || !hasMadeSelection) {

      if (!tooltipRef?.current) {
        setTooltipPosition({});
        setStrikethroughTooltipPosition({});
      }
      clearSelection();

      if (tooltipRef?.current || strikethroughTooltipRef?.current) return;

      highlighter.dehighlight(event);

      if (bootcamp === 'nclex') {
        const previous = typeof highlights === 'object' ? highlights : {};
        setHighlights({highlights: {...previous, [containerId]: highlighter.getSerializedHighlights()}});
      } else {
        setHighlights({highlights: highlighter.getSerializedHighlights()});
      }

      return;
    }

    if (disableToolTip) {
      return handleTooltipClick(event);
    } else if (tooltipRef?.current) {
      return;
    }

    // otherwise set the tooltip position (offset by container ref's left / top positions)
    const {left, top} = containerRef.current.getBoundingClientRect();
    setTooltipPosition(getTooltipPosition(event, left, top));
  };

  const handleMouseDown = (event, id) => {
    // right now the event.detail > 2 condition is preventing the entire block of text
    // from being selected (with a quad or triple) click because errant elements
    // outside of the highlightable container were being included in the selection and were
    // subsequently being highlighted - just need to decide whether or not this is good UI
    // there might be a better way of handling this;
    if (event.target?.nodeName === 'INPUT') return;
    if (tooltipActive || event.detail > 2) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleSetMode = (mode) => {
    setMode(mode);
    onModeChange(mode);
  };

  useEffect(() => {
    if (strikethroughTooltipRef?.current) {
      const {left, top, width} = strikethroughTooltipRef.current.getBoundingClientRect();
      setStrikethroughTooltipPosition({top: top - 6, left, width, transparent: true});
    }

    if (tooltipRef?.current) {
      const {left, top, width} = tooltipRef.current.getBoundingClientRect();
      setTooltipPosition({top: top - 6, left, width, transparent: true});
    }
  }, [tooltipRef?.current, strikethroughTooltipRef?.current])

  useEffect(() => setupEventListeners('mouseup', handleMouseUp), [tooltipActive, strikethroughTooltipActive, active]);
  useEffect(() => setupEventListeners('mousedown', handleMouseDown), [tooltipActive, strikethroughTooltipActive, active]);

  useHotkeys('option+h', event => enableKeyboardShortcuts && handleTooltipClick(null, 'highlight', mode === 'remove', mode), [mode]);
  useHotkeys('option+s', event => enableKeyboardShortcuts && handleTooltipClick(null, 'strikethrough'), []);

  return (
    <Container id={containerId} ref={containerRef} onMouseDown={() => setActive(true)} className={className} bootcamp={bootcamp}>
      {tooltipRef?.current
        ? <HighlightMenuTooltip
          as='div'
          position={tooltipPosition}
          onTooltipClick={handleTooltipClick}
          active={tooltipMenuActive}
          highlighter={highlighter}
          mode={mode}
          setMode={handleSetMode}
          showingDropdown={tooltipMenuActive}
          setShowingDropdown={setTooltipMenuActive}
          tooltipRef={tooltipRef}
          />
        : <Tooltip as={tooltipPosition?.transparent ? 'div' : 'img'} position={tooltipPosition} onMouseUp={handleTooltipClick}/>
      }
      {!!strikethroughTooltipActive &&
        <StrikethroughTooltip
          as={strikethroughTooltipPosition?.transparent ? 'div' : 'img'}
          position={strikethroughTooltipPosition}
          onMouseUp={e => handleTooltipClick(e, 'strikethrough')}
          onMouseOver={(event) => {
            if (!strikethroughTooltipRef?.current) return;
            strikethroughTooltipRef.current.style.color = 'yellow';
            try {
              const svg = strikethroughTooltipRef?.current?.getElementsByTagName('path')?.[0];
              svg.style.stroke = 'yellow';
            } catch (error) {
              // console.log('error setting fill', error);
            }

          }}
          onMouseLeave={(event) => {
            if (!strikethroughTooltipRef?.current) return;
            strikethroughTooltipRef.current.style.color = 'unset';
            try {
              const svg = strikethroughTooltipRef?.current?.getElementsByTagName('path')?.[0];
              svg.style.stroke = 'white';
            } catch (error) {
              // console.log('error setting fill', error);
            }
          }}
          />
        }
      {children}
    </Container>
  );
};

const Wrapper = ({disable, ...props}) => disable
  ? props.children
  : <Highlightable {...props}/>;


Highlightable.defaultProps = {
  children: null,
  type: 'text',
  htmlString: '',
  setHighlights: () => {},
};

Highlightable.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  htmlString: PropTypes.string,
  setHighlights: PropTypes.func, // used for coordinating storage of highlights in parent components
};

export default Wrapper;
