import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

import {H4} from '../../../components/Typography';

import {Home} from '@styled-icons/fluentui-system-filled/Home';
import {DocumentText} from '@styled-icons/fluentui-system-filled/DocumentText';
import {HatGraduation as AcademicCap} from '@styled-icons/fluentui-system-filled/HatGraduation';
import {ChatMultiple} from '@styled-icons/fluentui-system-filled/ChatMultiple';
import {Search} from '@styled-icons/fluentui-system-filled/Search';
import {DocumentBulletListMultiple as ChartSquareBar} from '@styled-icons/fluentui-system-filled/DocumentBulletListMultiple'

import {Heart} from '@styled-icons/fluentui-system-filled/Heart';
import {Body} from '@styled-icons/boxicons-regular/Body';
import {Star} from '@styled-icons/fluentui-system-filled/Star';
import {ThumbLike} from '@styled-icons/fluentui-system-filled/ThumbLike';
import {Lightbulb as TargetArrow} from '@styled-icons/fluentui-system-filled/Lightbulb';
import {Stethoscope} from '@styled-icons/fluentui-system-regular/Stethoscope';
import {Stack} from '@styled-icons/fluentui-system-filled/Stack';
import {Lightbulb} from '@styled-icons/fluentui-system-filled/Lightbulb';
import {CalendarLtr} from '@styled-icons/fluentui-system-filled/CalendarLtr';
import {PlayCircle} from '@styled-icons/fluentui-system-filled/PlayCircle'
import {DocumentArrowDown} from '@styled-icons/fluentui-system-filled/DocumentArrowDown'
import {BriefcaseMedical} from '@styled-icons/fluentui-system-filled/BriefcaseMedical'
import {People} from '@styled-icons/fluentui-system-filled/People'
import {DocumentBulletListMultiple} from '@styled-icons/fluentui-system-filled/DocumentBulletListMultiple'
import {QuestionCircle} from '@styled-icons/fluentui-system-filled/QuestionCircle';
import {DataHistogram} from '@styled-icons/fluentui-system-filled/DataHistogram';
import {TargetArrow as Target} from '@styled-icons/fluentui-system-filled/TargetArrow';
import {Laptop} from '@styled-icons/fluentui-system-filled/Laptop';
import {TagMultiple} from '@styled-icons/fluentui-system-filled/TagMultiple';
import {ClipboardTask} from '@styled-icons/fluentui-system-filled/ClipboardTask';
import {ArrowUpRight} from '@styled-icons/fluentui-system-filled/ArrowUpRight';
import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import {IntercomAPI} from 'react-intercom';
import {useUserDataContext} from '../../../contexts/UserData';
import {useModalContext} from '../../../contexts/Modal';
import {OpenIndicator} from '@bootcamp/web/src/components/Branding';

const Section = styled.div`
  // border-bottom: 1px solid ${({theme}) => theme.overlays.opacity.light._100};
  padding: ${({theme}) => theme.layouts.spacing.s} 0px;

  // &:first-child {
  //   margin-top: ${({index}) => index === 0 ? '0' : '4px'};
  // }
`;
const StyledOpenInNew = styled(ArrowUpRight)`
  margin-left: auto;
  margin-right: 0px;
  min-height: 16px !important;
  min-width: 16px !important;
`;

const LinkRowContainer = styled.div`
  border-radius: 8px;
  padding: ${({theme}) => theme.layouts.spacing.ms};
  color: ${({theme}) => theme.overlays.opacity.light._400};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  user-select: none;
  height: 42px;
  margin-bottom: 0px;
  cursor: pointer;
  ${StyledOpenInNew} {
    display: none;
  }
  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background: ${({theme}) => theme.overlays.opacity.light._50};
    color: white;
    ${StyledOpenInNew} {
      display: block;
    }
  }
  svg {
    min-width: 20px;
    min-height: 20px;
  }

  ${({isActive}) => isActive && css`
    background: ${({theme}) => theme.overlays.opacity.light._50};

    ${Name} {
      font-weight: bold;
      color: white;
    }

    svg {
      color: white;
    }
    `}
  ${({comingSoon}) => comingSoon && css`
    opacity: .25;
    &:hover {
      opacity: .45;
      background: none;
      cursor: default;
    }
  `}
`;

const Name = styled(H4)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  line-height: normal;
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
`;

const StyledBadge = styled(Badge)`
  width: 16px;
  height: 16px;
  margin-left: auto;

  path {
    fill: ${({theme}) => theme.overlays.opacity.light._400};
  }
`;


const LinkRow = withRouter(({location, name, route, href, Icon, onClick, comingSoon, locked}) => {
  const isActive = name === 'Home' ? location.pathname === route : location.pathname.startsWith(route);

  if (comingSoon) {
    return (
      <LinkRowContainer key={`sidebar-link-${name}`} comingSoon>
        {Icon && <Icon size={20} />}
        <Name title={name} children={name} />
        {<StyledBadge/>}
      </LinkRowContainer>
    )
  }

  if (onClick) {
    return (
      <LinkRowContainer key={`sidebar-link-${name}`} onClick={onClick}>
        {Icon && <Icon size={20} />}
        <Name title={name} children={name} />
        {locked && <StyledBadge/>}
      </LinkRowContainer>
    )
  }


  if (href) {
    return (
      <LinkRowContainer key={`sidebar-link-${name}`} as={'a'} href={href} target={'_blank'}>
        {Icon && <Icon size={20} />}
        <Name title={name} children={name} />
        <StyledOpenInNew color="rgba(255, 255, 255, .5)" size={16} />
        {locked && <StyledBadge/>}
      </LinkRowContainer>
    )
  }

  return (
    <LinkRowContainer key={`sidebar-link-${name}`} as={Link} to={route} isActive={isActive}>
      {Icon && <Icon size={20} />}
      <Name title={name} children={name} />
      {locked && <StyledBadge/>}
    </LinkRowContainer>
  )
});

const LinksContainer = styled.div`
  padding: 0 ${({theme}) => theme.layouts.spacing.m};
`;

const FolderContainer = styled.div`
  margin: 0px;
  margin-bottom: 4px;
`;

const FolderHeading = styled(LinkRowContainer)`
  cursor: pointer;
`;

const FolderBody = styled.div`
  padding-left: 20px;
`;
const StoreIcon = styled.img`
  &:first-child {
    margin-right: 4px;
  }
  width: 50%;
`;
const IconWrapper = styled.div`
  display: flex;
`;

const MobileAppStoreIcons = () => {
  return (
    <IconWrapper>
      <StoreIcon onClick={() => window.open('https://apps.apple.com/us/app/bootcamp-mobile/id1569082154', '_blank')} src={require('@bootcamp/shared/src/assets/images/appstore.png').default}/>
      <StoreIcon onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bootcampmobile&hl=en_US', '_blank')} src={require('@bootcamp/shared/src/assets/images/playstore.png').default}/>
    </IconWrapper>
  );
}


const LinkFolder = ({title, config, icon}) => {
  const savedState = JSON.parse(window.localStorage.getItem(`linkFolder-${title}-state`));

  const [open, setOpen] = useState(savedState);

  const isActive = config.some(({name, route}) => name === 'Home' ? window.location.pathname === route : window.location.pathname.startsWith(route));

  useEffect(() => {
    if (isActive) {
      setOpen(true);
      window.localStorage.setItem(`linkFolder-${title}-state`, true);
    }
  }, [isActive]);

  return (
    <FolderContainer>
      <FolderHeading
        isActive={!open && isActive}
        onClick={() => {
          setOpen(!open)
          window.localStorage.setItem(`linkFolder-${title}-state`, !open);
        }}>
        {icon}
        <Name title={title} children={title}/>
        <OpenIndicator size={20} open={open} />
      </FolderHeading>
      {open && <FolderBody children={config.map(LinkRow)} />}
    </FolderContainer>
  )
}

const Links = props => {
  const {config} = useBootcampConfigContext();
  const {isUpgraded, bootcamp} = useUserDataContext();
  const {classrooms} = config;

  const oatLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
    ],
    [
      {
        component: (
          <LinkFolder
            key={'subjects'}
            title={'Subjects'}
            icon={<AcademicCap size={20} />}
            config={classrooms.map(({route, icon, name}) => ({route: `/${bootcamp}/${route}`, name}))}
          />
        )
      },
      {
        name: 'Full Length Tests',
        route: `/${bootcamp}/full-length`,
        Icon: DocumentText,
      },
    ],
    [
      {
        name: 'Previous Tests',
        route: `/${bootcamp}/past-test-results`,
        Icon: ChartSquareBar,
      },

      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
    ],
    [
      {
        name: 'Study Guide',
        href: `https://bootcamp.com/oat/study-guide-schedule`,
        Icon: CalendarLtr,
      },
      {
        name: 'Study Strategy',
        href: 'https://www.bootcamp.com/oat/blog',
        Icon: TargetArrow,
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.facebook.com/groups/oatbootcamp',
        Icon: People,
      },
      {
        name: 'After the OAT',
        href: 'https://www.bootcamp.com/oat/after-the-oat',
        Icon: Heart,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];
  const datLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
    ],
    [
      {
        component: (
          <LinkFolder
            key={'subjects'}
            title={'Subjects'}
            icon={<AcademicCap size={20} />}
            config={classrooms.filter(({hideOnHome}) => !hideOnHome).map(({route, icon, name}) => ({route: `/${bootcamp}/${route}`, name}))}
          />
        )
      },
      {
        name: 'Full Length Tests',
        route: `/${bootcamp}/full-length`,
        Icon: Laptop,
      },
    ],
    [
      {
        name: 'Previous Tests',
        route: `/${bootcamp}/past-test-results`,
        Icon: ChartSquareBar,
      },
      {
        name: 'Performance',
        route: `/${bootcamp}/performance`,
        Icon: DataHistogram,
      },
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
      isUpgraded && {
        name: 'Search Questions',
        route: `/${bootcamp}/search`,
        Icon: Search,
      },
      {
        name: 'Crash Courses',
        href: 'https://bootcamp.com/dat/crash-courses',
        Icon: PlayCircle,
      },
    ],
    [
      {
        name: 'Study Guide',
        href: 'https://bootcamp.com/dat/aris-study-guide-for-dat-domination',
        Icon: CalendarLtr,
      },
      {
        name: 'Study Strategy',
        href: 'https://www.bootcamp.com/dat/blog',
        Icon: TargetArrow,
      },
      {
        name: 'Success Stories',
        href: 'https://www.bootcamp.com/dat/success-stories',
        Icon: Star,
      },
      {
        name: 'Application Services',
        route: `/${bootcamp}/application-services`,
        Icon: ClipboardTask,
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.facebook.com/groups/datbootcamp',
        Icon: People,
      },
      {
        name: 'After the DAT',
        href: 'https://www.bootcamp.com/dat/after-the-dat',
        Icon: Heart,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ],
  ];
  const inbdeLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
    ],
    [
      {
        component: (
          <LinkFolder
            key={'subjects'}
            title={'Subjects'}
            icon={<AcademicCap size={20} />}
            config={classrooms.filter(({route}) => route !== 'review-videos' && route !== 'simulation-exam').map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))}
          />
        )
      },
      {
        name: 'Simulation Exam',
        route: `/${bootcamp}/simulation-exam`,
        Icon: ClipboardTask
      },
      {
        name: 'Mental Dental Videos',
        route: `/${bootcamp}/review-videos`,
        Icon: PlayCircle,
      },
      {
        name: 'Cheat Sheets',
        route: `/${bootcamp}/cheat-sheets`,
        Icon: DocumentArrowDown,
      },
    ],
    [
      {
        name: 'Previous Tests',
        route: `/${bootcamp}/previous-tests`,
        Icon: ChartSquareBar
      },
      {
        name: 'Performance',
        route: `/${bootcamp}/performance`,
        Icon: DataHistogram,
      },
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
      isUpgraded && {
        name: 'Search Questions',
        route: `/${bootcamp}/search`,
        Icon: Search,
      },
    ],
    [
      {
        name: 'Study Guide',
        href: `https://bootcamp.com/inbde/study-schedule`,
        Icon: CalendarLtr,
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.facebook.com/groups/inbdebootcamp', // TODO bootcamp agnosticism
        Icon: People,
      },
      {
        name: 'After the INBDE',
        href: 'https://www.bootcamp.com/inbde/after-the-inbde',
        Icon: Heart,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];
  const msbLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
    ],
    [
      {
        component: (
          <LinkFolder
            key={'step-1'}
            title={'Step 1 - Preclinical'}
            icon={<Stethoscope size={20} />}
            config={classrooms.filter(({route, section}) => section === 'step1' && route !== 'review-videos').map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))}
          />
        )
      },
      {
        component: (
          <LinkFolder
            key={'courses'}
            title={'Anatomy Bootcamp'}
            icon={<Body size={20} />}
            config={classrooms.filter(({route, section}) => section !== 'step1' && route !== 'review-videos').map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))}
          />
        )
      },
    ],
    [
      isUpgraded && {
        name: 'Previous Tests',
        route: `/${bootcamp}/previous-tests`,
        Icon: ChartSquareBar
      },
      isUpgraded && {
        name: 'Self Assessment',
        route: `/${bootcamp}/self-assessment`,
        Icon: ClipboardTask
      },
      {
        name: 'Performance',
        route: `/${bootcamp}/performance`,
        Icon: DataHistogram
      },
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
    ],
    [
      {
        name: 'Step 1 Study Schedule',
        href: `https://bootcamp.com/blog/9-week-step-1-study-schedule`,
        Icon: CalendarLtr,
      },
      {
        name: 'Anki Deck',
        href: `https://bootcamp.com/blog/med-school-bootcamp-anki-deck`,
        Icon: Stack,
      },
      {
        name: 'Question of the Day',
        href: `https://bootcamp.com/med-school/usmle-step-1-question-of-the-day`,
        Icon: Lightbulb,
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.reddit.com/r/step1',
        Icon: People,
      },
      {
        name: 'After Step 1',
        href: 'https://bootcamp.com/med-school/after-step-1 ',
        Icon: Heart,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];

  const mcatLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },

    ],
    // {
    //   component: (
    //     <LinkFolder
    //       key={'subjects'}
    //       title={'Subjects'}
    //       icon={<AcademicCap size={20} />}
    //       config={ }
    //     />
    //   )
    // },
    // [
    // ],
    [
      ...classrooms.map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))
      // isUpgraded && {
      //   name: 'Previous Tests',
      //   route: `/${bootcamp}/previous-tests`,
      //   Icon: ChartSquareBar
      // },
      // isUpgraded && {
      //   name: 'Self Assessment',
      //   route: `/${bootcamp}/self-assessment`,
      //   Icon: ClipboardTask
      // },
      // {
      //   name: 'Performance',
      //   route: `/${bootcamp}/performance`,
      //   Icon: DataHistogram
      // },
      // isUpgraded && {
      //   name: 'Tagged Questions',
      //   route: `/${bootcamp}/tagged-questions`,
      //   Icon: TagMultiple
      // },
    ],
    [
      // {
      //   name: 'Step 1 Study Schedule',
      //   href: `https://bootcamp.com/blog/9-week-step-1-study-schedule`,
      //   Icon: CalendarLtr,
      // },
      // {
      //   name: 'Anki Deck',
      //   href: `https://bootcamp.com/blog/med-school-bootcamp-anki-deck`,
      //   Icon: Stack,
      // },
      // {
      //   name: 'Question of the Day',
      //   href: `https://bootcamp.com/med-school/usmle-step-1-question-of-the-day`,
      //   Icon: Lightbulb,
      // },
    ],
    [
      // {
      //   name: 'Study Group',
      //   href: 'https://www.reddit.com/r/step1',
      //   Icon: People,
      // },
      // {
      //   name: 'After Step 1',
      //   href: 'https://bootcamp.com/med-school/after-step-1 ',
      //   Icon: Heart,
      // },
      // {
      //   name: 'FAQs',
      //   route: `/${bootcamp}/faqs`,
      //   Icon: QuestionCircle,
      // },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      // {
      //   name: '',
      //   onClick: () => {},
      //   Icon: MobileAppStoreIcons
      // },
    ]
  ];

  const dsbLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
    ],
    [
      {
        component: (
          <LinkFolder
            key={'courses'}
            title={'Anatomy'}
            icon={<Body size={20} />}
            config={classrooms.filter(({route, section}) => section !== 'step1' && route !== 'review-videos').map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))}
          />
        )
      },
    ],
    [
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.facebook.com/groups/301028890595392',
        Icon: People,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];

  const anatomyLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
      {
        component: (
          <LinkFolder
            key={'courses'}
            title={'Courses'}
            icon={<AcademicCap size={20} />}
            config={classrooms.filter(({route}) => route !== 'review-videos').map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))}
          />
        )
      }
    ],
    [
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
      {
        name: 'Previous Tests',
        route: `/${bootcamp}/previous-tests`,
        Icon: ChartSquareBar
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.tiktok.com/@medschoolbootcamp', // TODO bootcamp agnosticism
        Icon: People,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];
  const chemistryLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
      {
        component: (
          <LinkFolder
            key={'courses'}
            title={'Courses'}
            icon={<AcademicCap size={20} />}
            config={classrooms.filter(({route}) => route !== 'review-videos').map(({route, icon, name, comingSoon}) => ({route: `/${bootcamp}/${route}`, name, comingSoon}))}
          />
        )
      },
    ],
    [
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
    ],
    [
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },
      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];
  const nclexLinks = [
    [
      {
        name: 'Home',
        route: `/${bootcamp}`,
        Icon: Home,
      },
    ],
    [
      {
        name: 'Case Studies',
        route: `/${bootcamp}/next-gen-cases`,
        Icon: BriefcaseMedical,
      },
      {
        name: 'Standalone Questions',
        route: `/${bootcamp}/next-gen-standalone`,
        Icon: Stethoscope,
      },
      {
        name: 'Readiness Exams',
        route: `/${bootcamp}/next-gen-readiness-exams`,
        Icon: Target,
      },
      {
        name: 'Cheat Sheets',
        route: `/${bootcamp}/cheat-sheets`,
        Icon: DocumentArrowDown,
      },
    ],
    [
      {
        name: 'Previous Tests',
        route: `/${bootcamp}/previous-tests`,
        Icon: DocumentBulletListMultiple
      },
      {
        name: 'Performance',
        route: `/${bootcamp}/performance`,
        Icon: DataHistogram
      },
      isUpgraded && {
        name: 'Tagged Questions',
        route: `/${bootcamp}/tagged-questions`,
        Icon: TagMultiple
      },
      {
        name: 'Next Gen Course',
        route: `/${bootcamp}/next-gen-strategy-course/videos`,
        Icon: PlayCircle,
      },
    ],
    [
      {
        name: 'Study Guide',
        href: 'https://bootcamp.com/nclex/study-guide',
        Icon: DocumentText,
      },
      {
        name: 'Study Schedule',
        href: 'https://bootcamp.com/nclex/study-schedule',
        Icon: CalendarLtr,
      },
      {
        name: 'Question of the Day',
        href: `https://bootcamp.com/nclex/question-of-the-day`,
        Icon: Lightbulb,
      },
      {
        name: 'Student Reviews',
        href: 'https://bootcamp.com/nclex/student-reviews',
        Icon: ThumbLike
      },
      {
        name: 'Success Stories',
        href: 'https://bootcamp.com/nclex/success-stories',
        Icon: Star
      },
    ],
    [
      {
        name: 'Study Group',
        href: 'https://www.facebook.com/groups/2169829559872032/',
        Icon: People,
      },
      {
        name: 'After the NCLEX',
        href: 'https://bootcamp.com/nclex/after-the-nclex',
        Icon: Heart,
      },
      {
        name: 'FAQs',
        route: `/${bootcamp}/faqs`,
        Icon: QuestionCircle,
      },

      {
        name: 'Contact',
        onClick: () => IntercomAPI('showNewMessage'),
        Icon: ChatMultiple,
      },
      {
        name: '',
        onClick: () => {},
        Icon: MobileAppStoreIcons
      },
    ]
  ];
  const linksConfig = {
    dat: datLinks,
    inbde: inbdeLinks,
    oat: oatLinks,
    anatomy: anatomyLinks,
    chemistry: chemistryLinks,
    'med-school': msbLinks,
    'nclex': nclexLinks,
    'dental-school': dsbLinks,
    'mcat': mcatLinks,
  }[bootcamp];

  if (['dat', 'oat', 'inbde', 'med-school', 'nclex', 'mcat'].includes(bootcamp)) {
    const headings = [
      'Home',
      'Study',
      'Review',
      'Resources',
      'Help'
    ]
    return (
      <LinksContainer>
        {linksConfig.map((sections, index) => [
          index > 0 && <Heading>{headings[index]}</Heading>,
          sections &&
            <Section index={index} key={`sidebar-section-${index}`}>
              {sections
                ?.filter(link => !!link)
                ?.map(props => props.component ? props.component : <LinkRow {...props}/>)
              }
            </Section>
        ])}
      </LinksContainer>
    )
  }
  return (
    <LinksContainer>
      {linksConfig.map((sections, index) => (
        sections &&
          <Section index={index} key={`sidebar-section-${index}`}>
            {sections
              ?.filter(link => !!link)
              ?.map(props => props.component ? props.component : <LinkRow {...props}/>)
            }
          </Section>
      ))}
    </LinksContainer>
  )
};

const Heading = styled.div`
  font-family: proxima-nova;
  font-size: 10px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 600;
  color: #85868E;
  margin: 0;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 0px;
  cursor: default;
`
export default Links;
