import React from 'react';

import Upgrade from './components/Upgrade';
import MoveQuestionToTest from './components/MoveQuestionToTest';
import SubmitAnatomyCase from './components/SubmitAnatomyCase';
import SubmitModal from './components/SubmitModal';
import FullScreenAnimation from './components/FullScreenAnimation';
import TestSettings from './components/TestSettings';

const TimeUp = ({onConfirm, prometricStyling}) => {
  return (
    <SubmitAnatomyCase
      prometricStyling={prometricStyling}
      headerText={'No time remaining.'}
      bodyText={'You have no more time remaining.'}
      confirmConfig={{
        onConfirm,
        text: 'View Test Results'
      }}
      cancelConfig={{
        text: null
      }}
    />
  )
}


const SectionTimeUp = ({onConfirm, prometricStyling}) => (
  <SubmitAnatomyCase
    prometricStyling={prometricStyling}
    headerText={'The section timer has expired.'}
    bodyText={'You will now move to the next section.'}
    confirmConfig={{
      onConfirm,
      color: '#0F6292',
      text: 'Proceed'
    }}
    cancelConfig={{
      text: null
    }}
  />
);

const ProceedToSection = ({history, close, onConfirm, testId, canDismiss, prometricStyling}) => (
  <SubmitAnatomyCase
    prometricStyling={prometricStyling}
    headerText={'Proceed to the next section.'}
    bodyText={'Are you sure you want to proceed to the next section? You will not be able to return to this section or change your answers.'}
    confirmConfig={{
      onConfirm,
      color: '#0F6292',
      text: 'Proceed'
    }}
    cancelConfig={{
      text: 'Not Yet'
    }}
  />
);

function onErrorDefault () {return false;}

const Submit = ({history, close, onConfirm, onError=onErrorDefault, prometricStyling}) => {
  return (
    <SubmitAnatomyCase
      prometricStyling={prometricStyling}
      headerText={'End test and show results?'}
      bodyText={"You’ll be able to review your test results on the next page."}
      cancelConfig={{
        text: 'Return to Test',
        onCancel: close,
        shouldRedirect: false,
      }}
      confirmConfig={{
        text: 'End Test',
        color: '#0F6292',
        onConfirm,
        onError,
        errorText: error => (
          <>
            <p>
              Something went wrong, but we're ready to help.
            </p>
            <p>
              <a href="mailto:team@bootcamp.com?Subject=Bootcamp Test Submission Issue">Click here to send an email to Bootcamp support.</a>
            </p>
            <p>
              Please attach the manual test submission file to the email.
            </p>
            <p>
              <a href="#" onClick={() => onError(error)}>
                Click here to re-download the manual test submission file.
              </a>
            </p>
          </>
        ),
      }}
    />
  )
}

export {TimeUp, Submit, SectionTimeUp, ProceedToSection, Upgrade, MoveQuestionToTest, SubmitAnatomyCase, SubmitModal, FullScreenAnimation, TestSettings};
