import React from 'react';
import styled from 'styled-components';
import {useUserDataContext} from '../../../../contexts/UserData';
import {IntercomAPI} from 'react-intercom';
import TagManager from 'react-gtm-module';

import {Container, Header, Body} from '../../';
import {Body3} from '../../../Typography';
import {Button} from '../../../Branding/Buttons';


const StyledContainer = styled(Container)`
  overflow: hidden;
  max-height: 500px;
`;
const StyledBody = styled(Body)`
  overflow-y: auto;
`;
const StyledHeader = styled(Header)`
`;
const SubHeader = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l}
`;
const SelectButton = styled(Button)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;

const SelectBootcamp = ({setRedirect}) => {
  const {setBootcamp, cognitoUser} = useUserDataContext();

  const handleBootcampSelect = (bootcamp) => {
    IntercomAPI('trackEvent', 'Signed up', {bootcamp});
    IntercomAPI('update', {bootcamp, email: cognitoUser?.attributes?.email})
    TagManager.dataLayer({
      dataLayer: {
        email: cognitoUser?.attributes?.email,
        event: 'Sign Up',
        bootcamp
      }
    });
    let redirectUrl = `/${bootcamp}`;
    if (bootcamp === 'nclex') {
      redirectUrl += '/next-gen-cases'
    }
    setRedirect(redirectUrl);
    setBootcamp(bootcamp);
  };

  return (
    <StyledContainer>
      <StyledBody>
        <StyledHeader>What are you studying?</StyledHeader>
        <SubHeader>Select a Bootcamp below to begin studying</SubHeader>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('dat')}>
          DAT
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('oat')}>
          OAT
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('inbde')}>
          INBDE
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('med-school')}>
          Med School
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('dental-school')}>
          Dental School
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('anatomy')}>
          Anatomy
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('chemistry')}>
          Chemistry
        </SelectButton>
        <SelectButton color={'indigo'} fixed onClick={() => handleBootcampSelect('nclex')}>
          NCLEX
        </SelectButton>
      </StyledBody>
  </StyledContainer>
  );
};

SelectBootcamp.defaultProps = {};
SelectBootcamp.propTypes = {};

export default SelectBootcamp;
