/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryVimeoApi = /* GraphQL */ `
  mutation QueryVimeoApi($vimeoId: String, $skipCache: Boolean) {
    queryVimeoApi(vimeoId: $vimeoId, skipCache: $skipCache)
  }
`;
export const saveTestProgress = /* GraphQL */ `
  mutation SaveTestProgress(
    $quizProgress: String
    $testProgressId: ID
    $testId: ID
    $userId: ID
  ) {
    saveTestProgress(
      quizProgress: $quizProgress
      testProgressId: $testProgressId
      testId: $testId
      userId: $userId
    )
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest($input: DeleteTestInput!) {
    deleteTest(input: $input) {
      id
      createdAt
      config {
        title
        type
        timeLimit
        status
        questionCount
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      groupsCanRead
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteTestBlock = /* GraphQL */ `
  mutation DeleteTestBlock($input: DeleteTestBlockInput!) {
    deleteTestBlock(input: $input) {
      id
      title
      type
      components {
        index
        renderType
        contents
      }
      createdAt
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      questionConnections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestionBase = /* GraphQL */ `
  mutation DeleteQuestionBase($input: DeleteQuestionBaseInput!) {
    deleteQuestionBase(input: $input) {
      id
      oldId
      createdAt
      status
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      searchString
      progresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      updatedAt
      groupsCanRead
      connections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      comments {
        items {
          id
          index
          content
          createdBy
          createdAt
          updatedAt
          type
          questionBaseCommentsId
        }
        nextToken
      }
      questionRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
      latestRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createExam = /* GraphQL */ `
  mutation CreateExam($input: CreateExamInput!) {
    createExam(input: $input) {
      id
      examComponents {
        items {
          id
          index
          examComponentQuestionId
          questionRevisionId
          extra {
            index
            renderType
            contents
          }
          exam {
            id
            examComponents {
              items {
                id
                index
                examComponentQuestionId
                questionRevisionId
                extra {
                  index
                  renderType
                  contents
                }
                exam {
                  id
                  createdAt
                  title
                  active
                  updatedAt
                }
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            createdAt
            title
            active
            updatedAt
          }
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      title
      active
      updatedAt
    }
  }
`;
export const updateExam = /* GraphQL */ `
  mutation UpdateExam($input: UpdateExamInput!) {
    updateExam(input: $input) {
      id
      examComponents {
        items {
          id
          index
          examComponentQuestionId
          questionRevisionId
          extra {
            index
            renderType
            contents
          }
          exam {
            id
            examComponents {
              items {
                id
                index
                examComponentQuestionId
                questionRevisionId
                extra {
                  index
                  renderType
                  contents
                }
                exam {
                  id
                  createdAt
                  title
                  active
                  updatedAt
                }
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            createdAt
            title
            active
            updatedAt
          }
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      title
      active
      updatedAt
    }
  }
`;
export const deleteExam = /* GraphQL */ `
  mutation DeleteExam($input: DeleteExamInput!) {
    deleteExam(input: $input) {
      id
      examComponents {
        items {
          id
          index
          examComponentQuestionId
          questionRevisionId
          extra {
            index
            renderType
            contents
          }
          exam {
            id
            examComponents {
              items {
                id
                index
                examComponentQuestionId
                questionRevisionId
                extra {
                  index
                  renderType
                  contents
                }
                exam {
                  id
                  createdAt
                  title
                  active
                  updatedAt
                }
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            createdAt
            title
            active
            updatedAt
          }
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      title
      active
      updatedAt
    }
  }
`;
export const createExamComponent = /* GraphQL */ `
  mutation CreateExamComponent($input: CreateExamComponentInput!) {
    createExamComponent(input: $input) {
      id
      index
      examComponentQuestionId
      questionRevisionId
      extra {
        index
        renderType
        contents
      }
      exam {
        id
        examComponents {
          items {
            id
            index
            examComponentQuestionId
            questionRevisionId
            extra {
              index
              renderType
              contents
            }
            exam {
              id
              examComponents {
                items {
                  id
                  index
                  examComponentQuestionId
                  questionRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              title
              active
              updatedAt
            }
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        title
        active
        updatedAt
      }
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateExamComponent = /* GraphQL */ `
  mutation UpdateExamComponent($input: UpdateExamComponentInput!) {
    updateExamComponent(input: $input) {
      id
      index
      examComponentQuestionId
      questionRevisionId
      extra {
        index
        renderType
        contents
      }
      exam {
        id
        examComponents {
          items {
            id
            index
            examComponentQuestionId
            questionRevisionId
            extra {
              index
              renderType
              contents
            }
            exam {
              id
              examComponents {
                items {
                  id
                  index
                  examComponentQuestionId
                  questionRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              title
              active
              updatedAt
            }
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        title
        active
        updatedAt
      }
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteExamComponent = /* GraphQL */ `
  mutation DeleteExamComponent($input: DeleteExamComponentInput!) {
    deleteExamComponent(input: $input) {
      id
      index
      examComponentQuestionId
      questionRevisionId
      extra {
        index
        renderType
        contents
      }
      exam {
        id
        examComponents {
          items {
            id
            index
            examComponentQuestionId
            questionRevisionId
            extra {
              index
              renderType
              contents
            }
            exam {
              id
              examComponents {
                items {
                  id
                  index
                  examComponentQuestionId
                  questionRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              title
              active
              updatedAt
            }
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        title
        active
        updatedAt
      }
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createTagJoin = /* GraphQL */ `
  mutation CreateTagJoin($input: CreateTagJoinInput!) {
    createTagJoin(input: $input) {
      id
      createdAt
      updatedAt
      tag {
        id
        test
        subject
        topic
        contentType
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTagJoin = /* GraphQL */ `
  mutation UpdateTagJoin($input: UpdateTagJoinInput!) {
    updateTagJoin(input: $input) {
      id
      createdAt
      updatedAt
      tag {
        id
        test
        subject
        topic
        contentType
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTagJoin = /* GraphQL */ `
  mutation DeleteTagJoin($input: DeleteTagJoinInput!) {
    deleteTagJoin(input: $input) {
      id
      createdAt
      updatedAt
      tag {
        id
        test
        subject
        topic
        contentType
        createdAt
        updatedAt
      }
    }
  }
`;
export const createContentQueue = /* GraphQL */ `
  mutation CreateContentQueue($input: CreateContentQueueInput!) {
    createContentQueue(input: $input) {
      name
      startDate
      currentIndex
      contentType
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateContentQueue = /* GraphQL */ `
  mutation UpdateContentQueue($input: UpdateContentQueueInput!) {
    updateContentQueue(input: $input) {
      name
      startDate
      currentIndex
      contentType
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteContentQueue = /* GraphQL */ `
  mutation DeleteContentQueue($input: DeleteContentQueueInput!) {
    deleteContentQueue(input: $input) {
      name
      startDate
      currentIndex
      contentType
      content
      createdAt
      updatedAt
    }
  }
`;
export const createWordPressUserData = /* GraphQL */ `
  mutation CreateWordPressUserData($input: CreateWordPressUserDataInput!) {
    createWordPressUserData(input: $input) {
      wpUserId
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWordPressUserData = /* GraphQL */ `
  mutation UpdateWordPressUserData($input: UpdateWordPressUserDataInput!) {
    updateWordPressUserData(input: $input) {
      wpUserId
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWordPressUserData = /* GraphQL */ `
  mutation DeleteWordPressUserData($input: DeleteWordPressUserDataInput!) {
    deleteWordPressUserData(input: $input) {
      wpUserId
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBootcampUserData = /* GraphQL */ `
  mutation CreateBootcampUserData($input: CreateBootcampUserDataInput!) {
    createBootcampUserData(input: $input) {
      bootcampUserId
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBootcampUserData = /* GraphQL */ `
  mutation UpdateBootcampUserData($input: UpdateBootcampUserDataInput!) {
    updateBootcampUserData(input: $input) {
      bootcampUserId
      createdAt
      updatedAt
    }
  }
`;
export const deleteBootcampUserData = /* GraphQL */ `
  mutation DeleteBootcampUserData($input: DeleteBootcampUserDataInput!) {
    deleteBootcampUserData(input: $input) {
      bootcampUserId
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionMastery = /* GraphQL */ `
  mutation CreateQuestionMastery($input: CreateQuestionMasteryInput!) {
    createQuestionMastery(input: $input) {
      userIdHashQuestionBaseId
      wpUserId
      masteryLevel
      bookmarked
      questionRevisionId
      questionMasteryQuestionId
      userIdHashTestTagId
      userIdHashSubjectTagId
      userIdHashTopicTagId
      userIdHashTestId
      questionBaseIdHashTestId
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionMastery = /* GraphQL */ `
  mutation UpdateQuestionMastery($input: UpdateQuestionMasteryInput!) {
    updateQuestionMastery(input: $input) {
      userIdHashQuestionBaseId
      wpUserId
      masteryLevel
      bookmarked
      questionRevisionId
      questionMasteryQuestionId
      userIdHashTestTagId
      userIdHashSubjectTagId
      userIdHashTopicTagId
      userIdHashTestId
      questionBaseIdHashTestId
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionMastery = /* GraphQL */ `
  mutation DeleteQuestionMastery($input: DeleteQuestionMasteryInput!) {
    deleteQuestionMastery(input: $input) {
      userIdHashQuestionBaseId
      wpUserId
      masteryLevel
      bookmarked
      questionRevisionId
      questionMasteryQuestionId
      userIdHashTestTagId
      userIdHashSubjectTagId
      userIdHashTopicTagId
      userIdHashTestId
      questionBaseIdHashTestId
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createTestProgress = /* GraphQL */ `
  mutation CreateTestProgress($input: CreateTestProgressInput!) {
    createTestProgress(input: $input) {
      id
      testId
      userIdHashTestId
      blockProgresses {
        items {
          id
          blockId
          questionProgresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          highlights
          status
          index
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      wordPressUserDataTestProgressesId
      updatedAt
    }
  }
`;
export const updateTestProgress = /* GraphQL */ `
  mutation UpdateTestProgress($input: UpdateTestProgressInput!) {
    updateTestProgress(input: $input) {
      id
      testId
      userIdHashTestId
      blockProgresses {
        items {
          id
          blockId
          questionProgresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          highlights
          status
          index
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      wordPressUserDataTestProgressesId
      updatedAt
    }
  }
`;
export const deleteTestProgress = /* GraphQL */ `
  mutation DeleteTestProgress($input: DeleteTestProgressInput!) {
    deleteTestProgress(input: $input) {
      id
      testId
      userIdHashTestId
      blockProgresses {
        items {
          id
          blockId
          questionProgresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          highlights
          status
          index
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      wordPressUserDataTestProgressesId
      updatedAt
    }
  }
`;
export const createBlockProgress = /* GraphQL */ `
  mutation CreateBlockProgress($input: CreateBlockProgressInput!) {
    createBlockProgress(input: $input) {
      id
      blockId
      questionProgresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      highlights
      status
      index
      createdAt
      updatedAt
    }
  }
`;
export const updateBlockProgress = /* GraphQL */ `
  mutation UpdateBlockProgress($input: UpdateBlockProgressInput!) {
    updateBlockProgress(input: $input) {
      id
      blockId
      questionProgresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      highlights
      status
      index
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlockProgress = /* GraphQL */ `
  mutation DeleteBlockProgress($input: DeleteBlockProgressInput!) {
    deleteBlockProgress(input: $input) {
      id
      blockId
      questionProgresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      highlights
      status
      index
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionProgress = /* GraphQL */ `
  mutation CreateQuestionProgress($input: CreateQuestionProgressInput!) {
    createQuestionProgress(input: $input) {
      id
      selectedAnswerIndex
      didSelectCorrectAnswer
      didSkip
      didMark
      didCheck
      time
      highlights
      masteryLevel
      questionRevisionId
      index
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionProgress = /* GraphQL */ `
  mutation UpdateQuestionProgress($input: UpdateQuestionProgressInput!) {
    updateQuestionProgress(input: $input) {
      id
      selectedAnswerIndex
      didSelectCorrectAnswer
      didSkip
      didMark
      didCheck
      time
      highlights
      masteryLevel
      questionRevisionId
      index
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionProgress = /* GraphQL */ `
  mutation DeleteQuestionProgress($input: DeleteQuestionProgressInput!) {
    deleteQuestionProgress(input: $input) {
      id
      selectedAnswerIndex
      didSelectCorrectAnswer
      didSkip
      didMark
      didCheck
      time
      highlights
      masteryLevel
      questionRevisionId
      index
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionTags = /* GraphQL */ `
  mutation UpdateQuestionTags($questionBaseId: ID, $tagIds: String) {
    updateQuestionTags(questionBaseId: $questionBaseId, tagIds: $tagIds)
  }
`;
export const addUniqueQuestionToExam = /* GraphQL */ `
  mutation AddUniqueQuestionToExam($examId: ID, $questionId: ID) {
    addUniqueQuestionToExam(examId: $examId, questionId: $questionId)
  }
`;
export const addUniqueQuestionToTestBlock = /* GraphQL */ `
  mutation AddUniqueQuestionToTestBlock($testBlockId: ID, $questionId: ID) {
    addUniqueQuestionToTestBlock(
      testBlockId: $testBlockId
      questionId: $questionId
    )
  }
`;
export const updateQuestionConnectionOrder = /* GraphQL */ `
  mutation UpdateQuestionConnectionOrder(
    $questionConnectionIndexPairs: String
  ) {
    updateQuestionConnectionOrder(
      questionConnectionIndexPairs: $questionConnectionIndexPairs
    )
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($questionBaseId: String) {
    deleteQuestion(questionBaseId: $questionBaseId)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      idOverride
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        items {
          id
          productId
          groups
          startDate
          duration
          status
          userMembershipsId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      idOverride
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        items {
          id
          productId
          groups
          startDate
          duration
          status
          userMembershipsId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      email
      idOverride
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        items {
          id
          productId
          groups
          startDate
          duration
          status
          userMembershipsId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      id
      productId
      groups
      startDate
      duration
      status
      userMembershipsId
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      id
      productId
      groups
      startDate
      duration
      status
      userMembershipsId
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership($input: DeleteMembershipInput!) {
    deleteMembership(input: $input) {
      id
      productId
      groups
      startDate
      duration
      status
      userMembershipsId
      type
      createdAt
      updatedAt
    }
  }
`;
export const createUserInteraction = /* GraphQL */ `
  mutation CreateUserInteraction($input: CreateUserInteractionInput!) {
    createUserInteraction(input: $input) {
      id
      userId
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInteraction = /* GraphQL */ `
  mutation UpdateUserInteraction($input: UpdateUserInteractionInput!) {
    updateUserInteraction(input: $input) {
      id
      userId
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserInteraction = /* GraphQL */ `
  mutation DeleteUserInteraction($input: DeleteUserInteractionInput!) {
    deleteUserInteraction(input: $input) {
      id
      userId
      value
      createdAt
      updatedAt
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest($input: CreateTestInput!) {
    createTest(input: $input) {
      id
      createdAt
      config {
        title
        type
        timeLimit
        status
        questionCount
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      groupsCanRead
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest($input: UpdateTestInput!) {
    updateTest(input: $input) {
      id
      createdAt
      config {
        title
        type
        timeLimit
        status
        questionCount
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      groupsCanRead
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createTestBlockConnection = /* GraphQL */ `
  mutation CreateTestBlockConnection($input: CreateTestBlockConnectionInput!) {
    createTestBlockConnection(input: $input) {
      id
      index
      testBlockConnectionTestBlockId
      createdAt
      updatedAt
      test {
        id
        createdAt
        config {
          title
          type
          timeLimit
          status
          questionCount
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groupsCanRead
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      testBlock {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTestBlockConnection = /* GraphQL */ `
  mutation UpdateTestBlockConnection($input: UpdateTestBlockConnectionInput!) {
    updateTestBlockConnection(input: $input) {
      id
      index
      testBlockConnectionTestBlockId
      createdAt
      updatedAt
      test {
        id
        createdAt
        config {
          title
          type
          timeLimit
          status
          questionCount
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groupsCanRead
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      testBlock {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTestBlockConnection = /* GraphQL */ `
  mutation DeleteTestBlockConnection($input: DeleteTestBlockConnectionInput!) {
    deleteTestBlockConnection(input: $input) {
      id
      index
      testBlockConnectionTestBlockId
      createdAt
      updatedAt
      test {
        id
        createdAt
        config {
          title
          type
          timeLimit
          status
          questionCount
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groupsCanRead
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      testBlock {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createTestBlock = /* GraphQL */ `
  mutation CreateTestBlock($input: CreateTestBlockInput!) {
    createTestBlock(input: $input) {
      id
      title
      type
      components {
        index
        renderType
        contents
      }
      createdAt
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      questionConnections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateTestBlock = /* GraphQL */ `
  mutation UpdateTestBlock($input: UpdateTestBlockInput!) {
    updateTestBlock(input: $input) {
      id
      title
      type
      components {
        index
        renderType
        contents
      }
      createdAt
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      questionConnections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createQuestionConnection = /* GraphQL */ `
  mutation CreateQuestionConnection($input: CreateQuestionConnectionInput!) {
    createQuestionConnection(input: $input) {
      id
      index
      questionConnectionQuestionId
      pinnedRevisionId
      createdAt
      updatedAt
      block {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionConnection = /* GraphQL */ `
  mutation UpdateQuestionConnection($input: UpdateQuestionConnectionInput!) {
    updateQuestionConnection(input: $input) {
      id
      index
      questionConnectionQuestionId
      pinnedRevisionId
      createdAt
      updatedAt
      block {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionConnection = /* GraphQL */ `
  mutation DeleteQuestionConnection($input: DeleteQuestionConnectionInput!) {
    deleteQuestionConnection(input: $input) {
      id
      index
      questionConnectionQuestionId
      pinnedRevisionId
      createdAt
      updatedAt
      block {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createQuestionBase = /* GraphQL */ `
  mutation CreateQuestionBase($input: CreateQuestionBaseInput!) {
    createQuestionBase(input: $input) {
      id
      oldId
      createdAt
      status
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      searchString
      progresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      updatedAt
      groupsCanRead
      connections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      comments {
        items {
          id
          index
          content
          createdBy
          createdAt
          updatedAt
          type
          questionBaseCommentsId
        }
        nextToken
      }
      questionRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
      latestRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuestionBase = /* GraphQL */ `
  mutation UpdateQuestionBase($input: UpdateQuestionBaseInput!) {
    updateQuestionBase(input: $input) {
      id
      oldId
      createdAt
      status
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      searchString
      progresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      updatedAt
      groupsCanRead
      connections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      comments {
        items {
          id
          index
          content
          createdBy
          createdAt
          updatedAt
          type
          questionBaseCommentsId
        }
        nextToken
      }
      questionRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
      latestRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createQuestionComment = /* GraphQL */ `
  mutation CreateQuestionComment($input: CreateQuestionCommentInput!) {
    createQuestionComment(input: $input) {
      id
      index
      content
      createdBy
      createdAt
      updatedAt
      type
      questionBaseCommentsId
    }
  }
`;
export const updateQuestionComment = /* GraphQL */ `
  mutation UpdateQuestionComment($input: UpdateQuestionCommentInput!) {
    updateQuestionComment(input: $input) {
      id
      index
      content
      createdBy
      createdAt
      updatedAt
      type
      questionBaseCommentsId
    }
  }
`;
export const deleteQuestionComment = /* GraphQL */ `
  mutation DeleteQuestionComment($input: DeleteQuestionCommentInput!) {
    deleteQuestionComment(input: $input) {
      id
      index
      content
      createdBy
      createdAt
      updatedAt
      type
      questionBaseCommentsId
    }
  }
`;
export const createQuestionRevision = /* GraphQL */ `
  mutation CreateQuestionRevision($input: CreateQuestionRevisionInput!) {
    createQuestionRevision(input: $input) {
      id
      questionComponents {
        index
        renderType
        contents
      }
      scorePossible
      createdAt
      revisedBy
      questionBaseQuestionRevisionsId
      updatedAt
    }
  }
`;
export const updateQuestionRevision = /* GraphQL */ `
  mutation UpdateQuestionRevision($input: UpdateQuestionRevisionInput!) {
    updateQuestionRevision(input: $input) {
      id
      questionComponents {
        index
        renderType
        contents
      }
      scorePossible
      createdAt
      revisedBy
      questionBaseQuestionRevisionsId
      updatedAt
    }
  }
`;
export const deleteQuestionRevision = /* GraphQL */ `
  mutation DeleteQuestionRevision($input: DeleteQuestionRevisionInput!) {
    deleteQuestionRevision(input: $input) {
      id
      questionComponents {
        index
        renderType
        contents
      }
      scorePossible
      createdAt
      revisedBy
      questionBaseQuestionRevisionsId
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      test
      subject
      topic
      contentType
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      test
      subject
      topic
      contentType
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
      test
      subject
      topic
      contentType
      createdAt
      updatedAt
    }
  }
`;
