import AdultHealthImg from '@bootcamp/shared/src/assets/icons/nclex/AdultHealth.png'
import ChildHealthImg from '@bootcamp/shared/src/assets/icons/nclex/ChildHealth.png'
import CriticalCareImg from '@bootcamp/shared/src/assets/icons/nclex/CriticalCare.png'
import FundamentalsImg from '@bootcamp/shared/src/assets/icons/nclex/Fundamentals.png'
import MaternalandNewbornHealthImg from '@bootcamp/shared/src/assets/icons/nclex/MaternalandNewbornHealth.png'
import MentalHealthImg from '@bootcamp/shared/src/assets/icons/nclex/MentalHealth.png'
import PharmacologyImg from '@bootcamp/shared/src/assets/icons/nclex/Pharmacology.png'
import ManagementofCareImg from '@bootcamp/shared/src/assets/icons/nclex/ManagementofCare.png'


export const items = [
  {
    name: 'Asthma',
    description: '',
    route: 'https://drive.google.com/file/d/1nzfgwmyfjTLMKfd2jQlKqpeKFcCDnHPQ/view?usp=sharing',
    icon: ChildHealthImg
  },
  {
    name: 'Bipolar Disorders',
    description: '',
    route: 'https://drive.google.com/file/d/11rhC4yJxAQMNn3olSQn3DJBPWj_OX4oD/view?usp=sharing',
    icon: MentalHealthImg
  },
  {
    name: 'Burns',
    description: '',
    route: 'https://drive.google.com/file/d/1QJEqd3TZQWDmwWjszVsJosoQJd1ho7iP/view?usp=sharing',
    icon: AdultHealthImg
  },
  {
    name: 'Cholecystitis & Pancreatitis',
    description: '',
    route: 'https://drive.google.com/file/d/15EhJ7-VwSazj7MOg_9ebxgRZxxYh6mkU/view?usp=sharing',
    icon: AdultHealthImg
  },
  {
    name: 'Chronic Obstructive Pulmonary Disease (COPD)',
    description: '',
    route: 'https://drive.google.com/file/d/1pixZb00aBa5eohA7e6ZqRLj8fIXEirN4/view?usp=sharing',
    icon: AdultHealthImg
  },
  {
    name: 'Congenital Heart Defects',
    description: '',
    route: 'https://drive.google.com/file/d/1A0tdlmBznpwYqVphGz3Zy1n8_m_8hSz6/view?usp=sharing',
    icon: ChildHealthImg
  },
  {
    name: 'Croup, Epiglottitis, & Tonsillitis',
    description: '',
    route: 'https://drive.google.com/file/d/1u5PonJ8gzcCi46x5IOqF43H9gpt4gu0B/view?usp=sharing',
    icon: ChildHealthImg
  },
  {
    name: 'Delegation and Assignment',
    description: '',
    route: 'https://drive.google.com/file/d/1h7bd0bUDJQWgZDTqyLDJIPwQePROPnam/view?usp=sharing',
    icon: ManagementofCareImg
  },
  {
    name: 'Depression & Suicide',
    description: '',
    route: 'https://drive.google.com/file/d/1uwIhcGCE8AhefUvRzy8cb3CcfjTcjlqp/view?usp=sharing',
    icon: MentalHealthImg
  },
  {
    name: 'Diabetes',
    description: '',
    route: 'https://drive.google.com/file/d/1LzFLS-VVih6s7Blu37CrfO1Uw14D67fi/view?usp=sharing',
    icon: AdultHealthImg
  },
  {
    name: 'Growth & Development',
    description: '',
    route: 'https://drive.google.com/file/d/1ABXGBcZKrPA9v3ZMACvijhhM0IhZ7wce/view?usp=sharing',
    icon: ChildHealthImg
  },
  {
    name: 'Infection Control',
    description: '',
    route: 'https://drive.google.com/file/d/1w6O_7iQYdJhmtpQC0vwIP0g9StkudW1y/view?usp=sharing',
    icon: FundamentalsImg
  },
  {
    name: 'Placenta Previa & Placental Abruption',
    description: '',
    route: 'https://drive.google.com/file/d/1QLjSJ0R-i50uo2HtDGBmniuPHeWheiKG/view?usp=sharing',
    icon: MaternalandNewbornHealthImg
  },
  {
    name: 'Postpartum Hemorrhage (PPH)',
    description: '',
    route: 'https://drive.google.com/file/d/1MQJ9tsB9brYdXWKUN25FjNMBBrUJFi3i/view?usp=sharing',
    icon: MaternalandNewbornHealthImg
  },
  {
    name: 'Preeclampsia',
    description: '',
    route: 'https://drive.google.com/file/d/1fEHDTBuZhKhmAr5bqry5ipdpcEgLaA3b/view?usp=sharing',
    icon: MaternalandNewbornHealthImg
  },
  {
    name: 'Prioritization',
    description: '',
    route: 'https://drive.google.com/file/d/1cEschueX0S719x-4242KPa3I5Jl10cKg/view?usp=sharing',
    icon: ManagementofCareImg
  },
  {
    name: 'Safety',
    description: '',
    route: 'https://drive.google.com/file/d/1R155s1hqwpWQfyH5so1FMso6cb3KDMjW/view?usp=sharing',
    icon: FundamentalsImg
  },
  {
    name: 'Stroke',
    description: '',
    route: 'https://drive.google.com/file/d/1vo_AvOXs2yyHyz-LcDZp0YQF_3Cdx94q/view?usp=sharing',
    icon: AdultHealthImg
  },
  {
    name: 'Schizophrenia',
    description: '',
    route: 'https://drive.google.com/file/d/1El49Ayu9k1OHlUOapS1sdtdsArNXr75K/view?usp=sharing',
    icon: MentalHealthImg
  },
  {
    name: 'Therapeutic Communication',
    description: '',
    route: 'https://drive.google.com/file/d/1hXORgePG8EaigmyPN66GtoAAvxANO4jd/view?usp=sharing',
    icon: MentalHealthImg
  },
];