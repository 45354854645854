import React from 'react';
import styled from 'styled-components';

import FAQs from '../../components/WebflowFAQs';
import Container from '../PageContainer';
import Overview from '../../components/Cards/Overview';
import PageTabs from '../../components/PageTabs';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import useDocumentTitle from '@rehooks/document-title';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const StyledOverview = styled(Overview)`
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  margin-bottom: 16px;
`;

const FAQ = ({history}) => {
  const {config} = useBootcampConfigContext();
  useDocumentTitle(`${config.meta.siteTitle} | FAQs`);

  return (
    <Container>
      <StyledBreadcrumbs history={history} lockAtDepth={2} />
      <StyledOverview
        subTitle={'FAQs'}
        body={'Don’t see your question answered here? Just send us a message by clicking the Contact link in the sidebar or click the blue chat button at the bottom-right of your screen.'}
        title={'Classroom'}
        showProgress={false}
      />
      <FAQs
        heading={''}
      />
    </Container>
  );
}

export default FAQ;
