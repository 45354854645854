import {createGlobalStyle} from 'styled-components/macro';


export default createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    -webkit-overflow-scrolling: touch;
  }
  .strikethrough {
    text-decoration:line-through;
    color: #888;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  html {
    box-sizing: border-box;
    color: ${({theme}) => theme.darkTextColor};
    height: 100%;
  }

  #root, #test-root {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  #test-root, #subject-root, #search-root {
    font-family: proxima-nova, Helvetica, sans-serif;
    min-height: auto;
    height: auto;
  }

  body, #root, #wp-root, #team-bootcamp-full-page, #team-bootcamp-full-page #test-root, #team-bootcamp-full-page #subject-root, #team-bootcamp-full-page #search-root {
    position: relative;
    height: 100%;
    min-height: 100%;
    margin: 0;
  }
  .intercom-launcher-frame {
    bottom: 25px !important;
  }
  .header-collapsed {
    overflow: visible;
  }

  .explanationheader {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 20px 0px;
      padding: 10px 15px;
      background: #55addc;
      font-weight: 700;
  }

  .detailheader {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 40px 0px 20px 0px;
      padding: 10px 15px;
      background: #55addc;
      font-weight: 700;
  }

  .explanationheader p,
  .detailheader p {
      margin-bottom: 0px!important;
      color: #FFF!important;
  }

  .explanationheader span,
  .detailheader span {
      font-weight: 400;
  }

  .explanation {
      padding: 15px;
  }

  span.incorrect {
      font-weight: 400!important;
      color: red!important;
  }

  span.correct {
      font-weight: 400!important;
      color: #00e500!important;
  }

  .detailcontainer ol li {
      list-style-type: decimal !important;
      font-family: 'Open Sans', arial, sans-serif;
      font-size: 16px !important;
      margin-bottom: 1em;
  }

  .detailcontainer ol {
      margin-bottom: 1.2em !important;
      padding-left: 30px;
  }

  .sub {
    font-size: 80%;
    line-height: normal;
  }

  .aligncenter {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  blockquote.callout {
    padding: 15px;
    margin: 0 0 1em 0;
    font-family: 'proxima-nova',sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #F9f9f9;
    color: inherit;
    border-left: solid 5px #0F6292;
  }
  .unstyled-table {
    tr {
      font-weight: normal !important;
      background: white !important;
    }
  }
  .fr-view table {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
  }

  .fr-view table td {
    padding: 4px;
  }
  .fr-view table th {
    padding: 4px 10px;
  }
  .fr-view table > :first-child > :first-child {
    background-color: rgb(102, 102, 102);
    color: white;
    font-weight: bold;
  }

  .fr-case-table {
    width: 100%;
    border: 1px solid rgb(65,65,65);
    border-spacing: 0px;
    text-align: left !important;
  }
  .fr-case-table tr:first-child > * {
    background-color: rgb(203,219,248) !important;
    font-weight: bold;
  }
  .fr-case-table tr {
    width: 100%;
  }
  .fr-case-table td {
    border-bottom: 1px solid black;
  }
  .fr-case-table tr:last-child td {
    border-bottom: 0px;
  }
  .fr-case-table tr:nth-child(odd) {
    background-color: rgb(203,219,248);
    font-weight: bold;
  }
  .fr-case-table td {
    padding: 8px !important;
    font-size: 14px;
  }
  .fr-case-table p {
    margin: 0px !important;
  }
  .dropdownDisplay {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid black;
    z-index: 10;
    width: 100%;
  }
  .dropdownDisplay > * {
    padding: 5px 10px;
    cursor: not-allowed;
    position: relative;
    padding-left: 26px;
    text-align: left;
    &:hover {
      background-color: #EFEFEF;
    }
  }
  .dropdownCover {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 9;
    width: 100%;
    height: 100%;
  }
  .fr-hanging-indent {
    padding-left: 64px;
    text-indent: -64px;
  }
  .video-thumb {
    display: none !important;
  }
`;
