import React from 'react';

import MasteryIcon from '../MasteryIcon';
import StartBlock from '../../../components/QBank/StartBlock';

import {deSlugify} from '@bootcamp/shared/src/util';
import {colors} from '@bootcamp/shared/src/styles/theme';
import styled from 'styled-components';


const Subheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
`;
const Icon = styled(MasteryIcon)`
  width: 48px;
  height: 48px;
  margin-right: 14px;
`;
const TextWrapper = styled.div`
  height: 20px;
  width: 100%;
  border-radius: 8px;
  ${({theme, loading}) => theme.animation.loadingBackground({loading})};
`;
const TotalQuestionsWrapper = styled.span`
  min-width: 20px;
  height: 100%;
`;

const slugOverrides = {
  'upper-and-lower-limbs-back': 'Upper & Lower Limbs, Back',
  'gluteal-region-posterior-thigh-popliteal-fossa': 'Gluteal Region, Posterior Thigh, Popliteal Fossa'
};

function getMasteryLevelParam () {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get('masteryLevel');
}

const StartMasteryReview = ({masteryLevel, classroom, username, totalQuestions, themePalette, loading, ...props}) => (
  <StartBlock
    header={`${masteryLevel === 'all' ? 'Tagged Question' : deSlugify(masteryLevel)} Bank`}
    headerColor={masteryLevel === 'learning'
      ? colors.interfacePalette.red.default
      : masteryLevel === 'reviewing'
        ? colors.interfacePalette.yellow.default
        : masteryLevel === 'mastered'
          ? colors.interfacePalette.red.default
          : null
    }
    subheader={
      <Subheader>
        <Icon color={themePalette.default} level={masteryLevel || getMasteryLevelParam()}/>
        <TextWrapper loading={loading}>
          {masteryLevel === 'all'
            ? !loading && <>
              <TotalQuestionsWrapper>{totalQuestions}</TotalQuestionsWrapper> questions tagged in {classroom && <strong>{slugOverrides[classroom] || deSlugify(classroom)}</strong>}
            </>
            : !loading && <>
              <TotalQuestionsWrapper>{totalQuestions}</TotalQuestionsWrapper> questions tagged as <strong> {deSlugify(masteryLevel || getMasteryLevelParam())} </strong> {classroom && <br/>} {classroom && 'from'} {classroom && <strong>{slugOverrides[classroom] || deSlugify(classroom)}</strong>}
            </>
          }
        </TextWrapper>
      </Subheader>
    }
    overrideHeaderLoading
    loading={loading}
    themePalette={themePalette}
    descriptionHeader={`Ready to start, ${username || '____'}?`}
    descriptionText={!loading && !totalQuestions ? `Come back when you've tagged some questions!` : 'Study your tagged questions and check each answer as you go. You can end the review session whenever you’d like. Best of luck!'}
    {...props}
  />
);

StartMasteryReview.defaultProps = {};
StartMasteryReview.propTypes = {};

export default StartMasteryReview;
