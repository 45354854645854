import React from 'react';
import PropTypes from 'prop-types';

import {Auth} from 'aws-amplify';
import {useFormContext} from 'react-hook-form';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {Container, Header, Body, FieldGroup, SubmitContainer, SubmitButton} from '../../';

const FormSubmit = ({children}) => {
  const {formState} = useFormContext();

  return (
    <SubmitContainer>
      <SubmitButton type={'submit'} disabled={!formState.isValid}>
        {children}
      </SubmitButton>
    </SubmitContainer>
  );
}

const ChangePassword = ({onSubmit}) => {

  return (
    <FormWrapper onSubmit={async values => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const {oldPassword, confirmPassword} = values;
        await Auth.changePassword(user, oldPassword, confirmPassword);
      } catch (error) {
        console.log('error changing password', error);
      } finally {
        onSubmit();
      }
    }}>
      <Container>
        <Body>
          <Header>Change your password</Header>
          <FieldGroup children={<FieldRenderer config={CHANGE_PASSWORD_FORM_CONFIG} />}/>
        </Body>
        <FormSubmit children={'Change My Password'}/>
      </Container>
    </FormWrapper>
  );
}

const CHANGE_PASSWORD_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'Old Password',
    name: 'oldPassword',
    inputProps: {
      type: 'password'
    },
    validation: () => ({
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters'
      }
    })
  },
  {
    type: 'Text',
    label: 'New Password',
    name: 'newPassword',
    additionalInfo: '8+ characters',
    inputProps: {
      type: 'password'
    },
    validation: () => ({
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters'
      }
    })
  },
  {
    type: 'Text',
    label: 'Confirm New Password',
    name: 'confirmPassword',
    inputProps: {
      type: 'password',
    },
    validation: watch => ({
      validate: value => (watch('newPassword') === value) || 'passwords must match',
    })
  }
];

ChangePassword.propTypes = {
  onSubmit: PropTypes.func,
};

ChangePassword.defaultProps = {
  onSubmit: () => true,
};

export default ChangePassword;
