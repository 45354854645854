import React, {useState, useEffect, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';

import VideoPlayer from '../../VideoPlayer';

import {useTimerContext} from '../../../contexts/TestTimer';

import AdminButtons from '../../QuestionView/components/AdminButtons';
import {TagLinks} from '../../Tags';
import {Column, FlexBox} from '../../Grid';
import {H3, Body2, Body3, KeyboardShortcutLabel} from '../../Typography';

import styled, {css} from 'styled-components';
import baseTheme from '@bootcamp/shared/src/styles/theme';
import {determineColorFromTitle, calculateAnswerPercentage} from '@bootcamp/shared/src/util';
import Highlightable from '../../Highlightable';
import Solution from './Solution';

import {CheckCircle} from '@styled-icons/material-rounded/CheckCircle';
import {Times} from '@styled-icons/fa-solid/Times';

import Draggable from 'react-draggable';

import useHotkeys from "@reecelucas/react-use-hotkeys";
import {useTestNavigatorContext} from '../../../contexts/TestNavigator';
import {useUserDataContext} from '../../../contexts/UserData';
import {CheckButton} from '@bootcamp/web/src/components/PrometricBank/components/shared';
import {getQuestionBaseTestBlocks} from '@bootcamp/shared/src/requests';

const ChoiceContainer = styled(Body2)`
  padding: 12px ${({theme}) => theme.layouts.spacing.m};
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${({isCorrect}) => isCorrect && 'bold'};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  user-select: none;
  text-decoration: ${({isCrossed}) => isCrossed ? 'line-through' : 'none'};
  opacity: ${({isCrossed}) => isCrossed ? 0.6 : 1};
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
    ${({isCrossed, checked, showingExplanation, theme}) => (isCrossed || showingExplanation || checked || window.location.pathname.includes('inbde/custom-test')) ? '' : `background: ${theme.colors.neutralsPalette.offWhite}`}
  }
  cursor: pointer;

  ${({checked, theme, themePalette}) => checked &&
    `
      background: ${theme?.darkModeEnabled ? 'rgba(98, 85, 229,.1)' : themePalette?.light ? themePalette.light : theme.colors.brandPalette.blue.light}};
      border: 1px solid ${themePalette?.default ? themePalette.default : theme.colors.brandPalette.blue.default};
    `
  }

  ${({isCorrect, theme}) => isCorrect &&
    `
      background: ${theme?.darkModeEnabled ? 'rgba(102, 172, 92,.1)' : theme.colors.interfacePalette.green.light}};
      border: 1px solid ${theme.colors.interfacePalette.green.default};
    `
  }
  ${({isIncorrect, theme}) => isIncorrect &&
    `
      background: ${theme?.darkModeEnabled ? 'rgba(224, 94, 87,.1)' : theme.colors.interfacePalette.red.light}};
      border: 1px solid ${theme.colors.interfacePalette.red.default};
    `
  }
  ${({simpleContainer}) => simpleContainer && css`
    border: none;
    background: none;
    padding: 0px;
  `}
`;
// const Option = styled.input.attrs({ type: 'radio', name: 'answer' })`
//   cursor: pointer;
// `;
const AnswerPercentage = styled(Body3)`
  max-width: ${({loading}) => loading ? 0 : '200px'};
  min-width: ${({loading}) => loading ? 0 : '50px'};
  transition: max-width 400ms;
  margin-left: 8px;
  overflow: hidden;
  ${({hidden}) => hidden && `display: none;`}
`;

const OptionOuter = styled.span`
  width: 14px;
  min-width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 16px;
  margin-right: 12px;
  color: blue;

  ${({theme, themePalette, checked}) => theme.darkModeEnabled
    ? `border: 1px solid ${theme.colors.darkModePalette.borderPrimary};`
    : checked && !!themePalette && themePalette.default
    ? `border: 1px solid ${themePalette.default};`
    : checked
    ? `border: 1px solid ${theme.colors.brandPalette.blue.default};`
    : `border: 1px solid ${theme.colors.neutralsPalette.light};`
  }

  ${({theme, isIncorrect}) => isIncorrect && `
      border: 1px solid ${theme.colors.interfacePalette.red.default};
    `
  }
`;
const OptionInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${({theme, checked, themePalette}) => checked && !!themePalette && themePalette.default
    ? themePalette.default
    : checked
    ? theme.colors.brandPalette.blue.default
    : 'none'
  };

    ${({theme, isIncorrect}) => isIncorrect && `
      background: ${theme.colors.interfacePalette.red.default};
    `
  }
`;
const OptionChecked = styled(CheckCircle)`
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin-right: 12px;
  fill: ${({theme}) => theme.colors.interfacePalette.green.default};
`;

const Option = ({showingExplanation, checked, isCorrect, isIncorrect, onClick, themePalette}) => {
  return isCorrect && showingExplanation
    ? <OptionChecked/>
    : <OptionOuter themePalette={themePalette} checked={checked} onClick={onClick} isIncorrect={isIncorrect} isCorrect={isCorrect}>
        <OptionInner themePalette={themePalette} checked={checked} isIncorrect={isIncorrect} isCorrect={isCorrect}/>
      </OptionOuter>
}

const LabelText = styled.span`
  * {
    max-width: 100%;
    font-size: 16px;
    display: inline;
  }
  p {
    margin: 0;
    span {
      font-size: inherit !important;
      color: inherit !important;
      font-weight: inherit !important;
    }
  }
  img {
    height: auto;
  }
  .sup, .sub, sup, sub {
    font-size: 12px;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-dib, .fr-dii {
    margin: 0 !important;
    display: inline !important;
    vertical-align: middle !important;
  }
  max-width: 100%;
  font-size: 16px;
  display: inline;
  user-select: none;
`;

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const AnswerChoice = ({answer, isCrossed, isSelected, setSelectedAnswerIndex, updateCrossedAnswerIndexes, showingExplanation, answerIndex, answerData, themePalette}) => {
  const {searchUserInteractions, bootcamp} = useUserDataContext();
  const {variables: {template}} = useTestNavigatorContext();
  const [answerText, answerCorrect] = answer;

  const isCorrect = answerCorrect && showingExplanation;
  const isIncorrect = !answerCorrect && isSelected && showingExplanation;

  function handleContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    updateCrossedAnswerIndexes();
  }

  const answerLetter = alphabet[answerIndex];
  const answerPercentage = calculateAnswerPercentage(answerData, answerLetter, answerCorrect);

  const letteredAnswerText = `${answerLetter}. ${(answerText === null || answerText === undefined) ? '' : answerText}`;
  const onClickAnswerChoice = () => {
    !showingExplanation && isCrossed && updateCrossedAnswerIndexes();
    !showingExplanation && setSelectedAnswerIndex();
  }

  useHotkeys(`${answerIndex+1}`, onClickAnswerChoice, [onClickAnswerChoice]);
  const hideAnswerData = bootcamp === 'chemistry' || searchUserInteractions('hideAnswerData') === true || Object.keys(answerData || {}).length === 0;
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;

  return (
    <ChoiceContainer
      as={'label'}
      isCorrect={isCorrect}
      isIncorrect={isIncorrect}
      isCrossed={isCrossed && !(isSelected && showingExplanation)}
      checked={isSelected}
      showingExplanation={showingExplanation}
      onContextMenu={handleContextMenu}
      themePalette={themePalette}
      onClick={onClickAnswerChoice}
      simpleContainer={bootcamp === 'inbde' && template === 'customTest'}
    >
      <Option themePalette={themePalette} checked={isSelected} isCorrect={isCorrect} isIncorrect={isIncorrect} showingExplanation={showingExplanation}/>
      {useMemo(() => <LabelText className={'fr-view'} dangerouslySetInnerHTML={{__html: letteredAnswerText}} />, [letteredAnswerText])}
      {showKeyboardShortcuts && !showingExplanation && <KeyboardShortcutLabel children={answerIndex + 1} />}
      <AnswerPercentage hidden={hideAnswerData || !showingExplanation} loading={!showingExplanation}>{`(${answerPercentage}%)`}</AnswerPercentage>
    </ChoiceContainer>
  )
}

const AnswerBoxContainer = styled.form`
  clear: both;
  width: 100%;
`;

const AnswerBox = ({
  answers,
  selectedAnswerIndex,
  setSelectedAnswerIndex,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
  isMultipleSelectActive,
  themePalette,
  answerData,
}) => {

  const answerTuples = Object.entries(answerData || {});
  const totalAnswers = answerTuples.reduce((acc, [name, count]) => acc += count, 0);

  return (
    <AnswerBoxContainer>
      {answers.map((answer, index) => {
        return (
          <AnswerChoice
            key={`answerindex-${index}`}
            themePalette={themePalette}
            setSelectedAnswerIndex={() => {
              if (selectedAnswerIndex === -1) { // if unanswered, override -1
                setSelectedAnswerIndex(index);
              } else if (`${selectedAnswerIndex}`.includes(index)) { // if index was previously selected
                if (`${selectedAnswerIndex}`.length === 1) { // if index was only selected, set -1
                  setSelectedAnswerIndex(-1);
                } else { // otherwise replace index with empty string and parse
                  setSelectedAnswerIndex(`${selectedAnswerIndex}`.replace(index, ''));
                }
              } else if (isMultipleSelectActive) { // otherwise, append index to selectedAnswerIndex string
                setSelectedAnswerIndex(`${selectedAnswerIndex}${index}`);
              } else {
                setSelectedAnswerIndex(`${index}`);
              }
            }}
            updateCrossedAnswerIndexes={() => updateCrossedAnswerIndexes(index)}
            isSelected={selectedAnswerIndex === -1 ? false : `${selectedAnswerIndex}`.includes(index)}
            isCrossed={crossedAnswerIndexes.indexOf(index) > -1}
            answer={answer}
            showingExplanation={showingExplanation}
            answerIndex={index}
            answerData={totalAnswers >= 10 ? answerData : {}}
          />
        )
      })}
    </AnswerBoxContainer>
  )
}

const SuperContainer = styled(FlexBox)`
  transform: translate3d(0,0,0);
  align-items: flex-start;
  height: auto;
  padding-bottom: ${({hasPassage}) => hasPassage ? '50px' : '150px'};
  flex-direction: row;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
  &:focus-visible {
    outline: none;
  }
`;
const Container = styled(Column)`
  overflow: hidden;

  img {
    display: block;
    height: auto;
    ${({theme}) => theme.darkModeEnabled && css`
      background: white;
    `}
  }
`;
const Prompt = styled(H3)`
  margin: 0 0 16px 0;
  font-weight: ${({theme}) => theme.font.weight.normal};
  line-height: 28px;

  img {
    display: block;
    height: auto;
    ${({theme}) => theme.darkModeEnabled && css`
      background: white;
    `}
  }
  p {
    margin: 0;
    span {
      font-size: inherit !important;
      color: inherit !important;
    }
  }
  ol, ul {
    margin: 1em 0;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-view table p {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  ${({minimize}) => minimize && css`max-width: 668px;`}

  img {
    max-width: 100%;
  }
`;

// const TagDisplay = styled(H4)`
//   font-weight: normal;
//   border-left: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
//   padding-left: ${({theme}) => theme.layouts.spacing.m};
//   margin-top: ${({theme}) => theme.layouts.spacing.xxl};
// `;

// const MasteryLevelWrapper = styled.span`
//   font-weight: bold;
//   color: ${({level, theme}) => {
//     switch (level) {
//       case 'learning':
//         return theme.colors.interfacePalette.red.default;
//       case 'reviewing':
//         return theme.colors.interfacePalette.yellow.default;
//       case 'mastered':
//         return theme.colors.interfacePalette.green.default;
//       default:
//         return theme.colors.neutralsPalette.extraDark;
//     }
//     }}
// `;

// const masteryLevelMap = {
//   learning: 'Learning',
//   reviewing: 'Reviewing',
//   mastered: 'Mastered'
// };

const INBDEImagesContainer = styled.div`
  margin-right: 32px;
  max-width: 300px;
  min-width: 30%;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 24px;
  }
`;

const ImageButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  overflow-x: auto;
`;

const ImageButton = styled.div`
  text-transform: uppercase;
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : 'black'};
  padding: 8px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: 12px;

  ${({theme}) => theme.darkModeEnabled && css`
    color: ${baseTheme.colors.neutralsPalette.offWhite};
    &:hover {
      background: ${theme.colors.darkModePalette.surfaceTertiary};
    }
  `}

  &:first-child {
    margin-left: auto;
  }
`;

const CaseImage = styled.div`
  .fr-case-table tr:nth-child(even) {
    ${({theme}) => theme.darkModeEnabled && css`
      color: ${baseTheme.colors.neutralsPalette.offWhite};
    `}
  }
  img {
    user-select: none;
    user-drag: none;
    max-width: 50vw;
  }
  p {
    margin: 0px;
  }
`;

const ExhibitWrapper = styled.div`
  position: absolute;
  z-index: 10;
  /* &:hover {
    > div {
      transform: scale(1.05);
      transition: transform .3s;
    }
  }
  > div {
    transition: transform .3s;
  } */
`;

const CloseButton = styled(Times)`
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  text-align: center;
  background: red;
  color: white;
  border-radius: 3px;
  cursor: pointer;
`;
const Exhibit = ({onClose, defaultPosition, children}) => {

  useHotkeys('Escape', onClose, [onClose]);

  return (
    <Draggable defaultPosition={defaultPosition || {x: 50, y: 0}} bounds={{top: 0, left: 0}}>
      <ExhibitWrapper>
        <div>
          <CloseButton onClick={onClose} />
          {children}
        </div>
      </ExhibitWrapper>
    </Draggable>
  );
}

const TutorModeCheckButton = styled.div`
  margin-top: 24px;
`;

function insertVideoElements(container, viewKey) {
  const vimeoNodes = container.current.querySelectorAll(':not(del) > .video-thumb');
  const vimeoIds = new Set([...vimeoNodes].map(node => node.dataset.vimeoId));

  [...vimeoIds].forEach(async vimeoId => {
    vimeoNodes.forEach((node, index) => {
      if (node.dataset.vimeoId === vimeoId) {
        const videoContainer = document.createElement('div');
        videoContainer.className = "videoContainer";
        videoContainer.style.marginBottom = '1em';
        if (node.classList.contains('video-lock')) {
          node.insertAdjacentElement('afterEnd',videoContainer);
        } else {
          // if (node.parentNode && node.parentNode.children.length === 1 && node.parentNode.children[0].classList.contains('video-thumb') && node.parentNode !== container.current) {
          //   node.parentNode.remove();
          // } else {
          //   node.remove();
          // }
          if (container.current) container.current.insertAdjacentElement('afterBegin',videoContainer);
        }
        const videoPlayer = <VideoPlayer playerId={`${vimeoId}-${index}-${viewKey}`} vimeoId={vimeoId} />;
        ReactDOM.render(videoPlayer, videoContainer);
      }
    })
  });
}

function insertGifElements(container) {
  const gifs = container.current.querySelectorAll('.rubgif');

  [...gifs].forEach(gif => {
    gif.style.display = 'none';
    const gifPlayer = document.createElement('gif-player');
    const gifSrc = gif.getAttribute('src');
    gifPlayer.setAttribute('src', gifSrc+'?version=gifplayer');
    gifPlayer.setAttribute('speed', '1');
    gifPlayer.setAttribute('play', true);
    gif.insertAdjacentElement('afterend', gifPlayer);
  });
}

const Question = ({
  prompt,
  answers,
  explanation,
  lastUpdated,
  tags,
  caseset,
  xray,
  citations,
  clinicalPhoto,
  clinicalExam,
  customExhibitName,
  customExhibit,
  passage,
  relatedVideos,
  didSubmitAnswer,
  testBlockName,
  alwaysShowingExplanation,
  onSelectAnswerIndex,
  didSubmitAnswerIndex,
  showFeedback,
  questionId,
  questionRevisionId,
  enableHighlighting,
  disableVideos,
  setHighlights,
  highlights,
  questionIndex,
  didMark,
  didCheck,
  time,
  savedCrossedAnswerIndexes,
  onCrossedAnswerIndexesUpdate,
  viewKey,
  themePalette,
  questionSetExplanation,
  showingTags,
  masteryLevel,
  latestRevisionId,
  anatomyImage,
  anatomyImageTransform,
  setCaseImageProps,
  setCaseImageSolutionProps,
  blockParts,
  hyperlinks,
  checkOnClick,
  blockId,
  isDraft,
}) => {

  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(didSubmitAnswerIndex);
  const [crossedAnswerIndexes, setCrossedAnswerIndexes] = useState(savedCrossedAnswerIndexes);
  const [selectedPromptImage, setSelectedPromptImage] = useState();
  const containerRef = useRef();
  const solutionRef = useRef();
  const hyperlinksRef = useRef();
  const {timerActive, toggleTimer} = useTimerContext();
  const {methods: {getAnswerDataForQuestion}, variables: {answerData, customTestConfig, template}} = useTestNavigatorContext();
  const {searchUserInteractions, bootcamp} = useUserDataContext();
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;

  const correctAnswerIndex = answers.reduce((acc, [answerText, answerCorrect], index) => answerCorrect ? `${acc}${index}` : acc, '') || -1;
  const isMultipleSelectActive = correctAnswerIndex > 0 && `${correctAnswerIndex}`.length > 1;
  // const correctAnswerLetter = alphabet[correctAnswerIndex];
  const didSelectCorrectAnswer = parseInt(selectedAnswerIndex) == parseInt(correctAnswerIndex);
  const showingExplanation = didSubmitAnswer || alwaysShowingExplanation;
  const didNotAnswer = selectedAnswerIndex == -1;
  const questionAnswerData = answerData[questionId];
  const correctPercentage = calculateAnswerPercentage(questionAnswerData, alphabet[correctAnswerIndex], true);

  useHotkeys(`e`, () => (xray || clinicalPhoto) && setSelectedPromptImage((selectedPromptImage !== xray || selectedPromptImage !== clinicalPhoto) ? (xray || clinicalPhoto) : ''), [xray, clinicalPhoto, selectedPromptImage]);
  useHotkeys(`f`, () => clinicalExam && setSelectedPromptImage(selectedPromptImage !== clinicalExam ? clinicalExam : '', [clinicalExam, selectedPromptImage]));
  useHotkeys(`g`, () => customExhibitName && customExhibit && setSelectedPromptImage(selectedPromptImage !== customExhibit ? customExhibit : '', [customExhibit, selectedPromptImage]));

  // set case image
  useEffect(() => {
    setCaseImageProps && anatomyImage && anatomyImageTransform && setCaseImageProps({anatomyImage, anatomyImageTransform});
    if (setCaseImageSolutionProps && blockParts) {
      const {anatomyImageSolution} = blockParts;
      anatomyImageSolution && setCaseImageSolutionProps({anatomyImageSolution});
    }
    return () => {
      setCaseImageProps && setCaseImageProps(null);
      setCaseImageSolutionProps && setCaseImageSolutionProps(null);
    }
  }, [])

  useEffect(() => {
    async function fetchAnatomyImageSolution() {
      try {
        const result = await getQuestionBaseTestBlocks(questionId);
        const blocks = result.data.getQuestionBase.connections.items.map(item => item.block);
        const testBlock = blocks.find(testBlock => (testBlock.components || []).find(component => component.renderType === 'anatomyImageSolution'))
        const anatomyImageSolution = (((testBlock?.components || []).find(component => component.renderType === 'anatomyImageSolution')) || {}).contents;
        anatomyImageSolution && setCaseImageSolutionProps({anatomyImageSolution});
      } catch (error) {
        console.log(error);
      }
    }
    if (template === 'customTest' && anatomyImage && didSubmitAnswer) {
      fetchAnatomyImageSolution();
    }
  }, [didSubmitAnswer]);

  useEffect(() => {
    // load answer data for question if need be
    getAnswerDataForQuestion(questionId, questionRevisionId || latestRevisionId);
  }, [questionRevisionId]);

  // selectedAnswerIndex hook
  useEffect(() => {
    if (!answers?.length) return;
    alwaysShowingExplanation && setSelectedAnswerIndex(didSubmitAnswerIndex || correctAnswerIndex);
  }, [selectedAnswerIndex]);

  const updateSelectedAnswerIndex = (index) => {
    setSelectedAnswerIndex(index);
    const didSelectCorrectAnswer = parseInt(index) == parseInt(correctAnswerIndex);
    onSelectAnswerIndex(index, didSelectCorrectAnswer);
  }

  useEffect(() => {
    containerRef?.current?.focus();
    if (didCheck) {
      timerActive && toggleTimer();
      return;
    }
    !!questionId && !didSubmitAnswer && !timerActive && toggleTimer();
  }, [])

  function updateCrossedAnswerIndexes(index) {
    if (showingExplanation) return;

    const indexes = crossedAnswerIndexes.indexOf(index) > -1
      ? crossedAnswerIndexes.filter(i => i !== index)
      : [...crossedAnswerIndexes, index];
    onCrossedAnswerIndexesUpdate(indexes);
    setCrossedAnswerIndexes(indexes);
  }

  useEffect(() => {

    if (!disableVideos && solutionRef.current && showingExplanation) {

      // insert video player for solution
      insertVideoElements(solutionRef, viewKey);

      // insert gif elements
      insertGifElements(solutionRef, )
    }

    return () => {
      if (solutionRef.current) {
        solutionRef.current.querySelectorAll('.videoContainer').forEach(node => node.remove());
      }
    }
  }, [explanation, showingExplanation]);

  // const {topic} = tags.find(tag => !!tag.topic) || {};

  function localizeContentForBootcamp(string) {
    try {
      if (['anatomy', 'chemistry'].includes(bootcamp)) {
        // replace case where DAT is followed by space or period
        string = string.replace(/DAT /g, 'Exam ').replace(/DAT[.]/g, 'Exam.').replace(/DAT[,]/g, 'Exam,');
      }
      if (bootcamp === 'oat') {
        // replace case where DAT is followed by space or period
        string = string.replace(/DAT /g, 'OAT ').replace(/DAT[:]/g, 'OAT: ').replace(/DAT[.]/g, 'OAT.').replace(/DAT[,]/g, 'OAT,').replace(/DATs/g, 'OATs');
      }

      if (bootcamp !== 'dat') {
        string = string.replace('DAT Pro', 'Class Pro');
      }

    } catch (error) {
      // console.log('error localizing content', error);
    }

    return string;
  }

  if (!prompt && !questionId && !explanation) {
    return (
      <SuperContainer hasPassage={passage}>
        <Prompt>We're always updating Bootcamp to give you a high-yield study experience. This question has been moved to another question bank or test for balancing purposes. Please retake this test to review updated questions!</Prompt>
      </SuperContainer>
    )
  }
  const hasINBDEContent = (xray || clinicalPhoto || clinicalExam || (customExhibit && customExhibitName) || caseset);
  return (
    <SuperContainer hasPassage={passage} ref={containerRef} tabIndex={"0"}>
      {selectedPromptImage && (
        <Exhibit onClose={() => setSelectedPromptImage()}>
          <CaseImage dangerouslySetInnerHTML={{__html: selectedPromptImage}} />
        </Exhibit>
      )}
      {bootcamp === 'inbde' && hasINBDEContent && (
        <INBDEImagesContainer>
            <ImageButtonsContainer>
              {xray && xray?.match('<img') && <ImageButton onClick={() => setSelectedPromptImage(selectedPromptImage !== xray ? xray : '')}>X-Rays{showKeyboardShortcuts && <KeyboardShortcutLabel children={'E'} />}</ImageButton>}
              {clinicalPhoto && clinicalPhoto?.match('<img') && <ImageButton onClick={() => setSelectedPromptImage(selectedPromptImage !== clinicalPhoto ? clinicalPhoto : '')}>Photos{showKeyboardShortcuts && <KeyboardShortcutLabel children={'E'} />}</ImageButton>}
              {clinicalExam && clinicalExam?.match('<img') && <ImageButton onClick={() => setSelectedPromptImage(selectedPromptImage !== clinicalExam ? clinicalExam : '')}>Clinical Exam{showKeyboardShortcuts && <KeyboardShortcutLabel children={'F'} />}</ImageButton>}
              {customExhibitName && customExhibit?.match('<img') && <ImageButton onClick={() => setSelectedPromptImage(selectedPromptImage !== customExhibit ? customExhibit : '')}>{customExhibitName}{showKeyboardShortcuts && <KeyboardShortcutLabel children={'G'} />}</ImageButton>}
            </ImageButtonsContainer>
          {caseset && <CaseImage dangerouslySetInnerHTML={{__html: caseset}} />}
        </INBDEImagesContainer>
      )}
      <Container>
        <AdminButtons questionId={questionId} correctAnswerIndex={correctAnswerIndex} />
        {testBlockName &&
          <TagLinks
            tags={[{
              title: testBlockName,
              color: determineColorFromTitle(testBlockName),
              as: 'span',
            }]}
          />
        }
        <Wrapper minimize={bootcamp === 'inbde' && !hasINBDEContent}>
          <Highlightable
            type={'stem'}
            htmlString={prompt}
            setHighlights={setHighlights}
            highlights={highlights}
            disable={!enableHighlighting}>
            <Prompt className={'fr-view'} dangerouslySetInnerHTML={{__html: prompt || 'Loading...'}}/>
          </Highlightable>

          <AnswerBox
            answers={answers}
            selectedAnswerIndex={selectedAnswerIndex}
            setSelectedAnswerIndex={updateSelectedAnswerIndex}
            showingExplanation={showingExplanation}
            crossedAnswerIndexes={crossedAnswerIndexes}
            updateCrossedAnswerIndexes={updateCrossedAnswerIndexes}
            isMultipleSelectActive={isMultipleSelectActive}
            themePalette={themePalette}
            answerData={questionAnswerData}
          />
          {!showingExplanation && customTestConfig?.config?.tutorMode && bootcamp === 'inbde' && <TutorModeCheckButton><CheckButton onClick={checkOnClick} /></TutorModeCheckButton>}
        </Wrapper>
        {showingExplanation && explanation &&
          <Wrapper minimize={bootcamp === 'inbde' && !hasINBDEContent}>
            <Solution
              solution={localizeContentForBootcamp(explanation)}
              citations={citations}
              didNotAnswer={didNotAnswer}
              correctAnswerIndex={correctAnswerIndex}
              didSelectCorrectAnswer={didSelectCorrectAnswer}
              selectedAnswerIndex={selectedAnswerIndex}
              showFeedback={showFeedback}
              questionId={questionId}
              tags={tags}
              didMark={didMark}
              time={time}
              solutionRef={solutionRef}
              hyperlinksRef={hyperlinksRef}
              lastUpdated={lastUpdated}
              questionSetExplanation={questionSetExplanation}
              correctPercentage={correctPercentage}
              relatedVids={relatedVideos}
              questionRevisionId={questionRevisionId}
              hyperlinks={hyperlinks?.map(localizeContentForBootcamp)}
              blockId={blockId}
            />
          </Wrapper>
        }
      </Container>
    </SuperContainer>
  )
}

Question.defaultProps = {
  prompt: '',
  answers: '[]',
  explanation: '',
  citations: '',
  onSelectAnswerIndex: () => true,
  alwaysShowingExplanation: false,
  didSubmitAnswerIndex: -1,
  showFeedback: false,
  questionId: '',
  tags: [],
  enableHighlighting: false,
  setHighlights: () => {},
  highlights: null, // this should be a serialized highlight string,
  didMark: false,
  time: "",
  savedCrossedAnswerIndexes: [],
  onCrossedAnswerIndexesUpdate: () => true,
  questionSetExplanation: ''
};

export default Question;
export {insertVideoElements}