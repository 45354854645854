import styled from 'styled-components';

const ScrollContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  max-width: 1152px;
  z-index: 1;
  width: 100%;
  margin: auto;
  padding: 16px 32px 80px;

  ${({theme: {mediaQueries}}) => mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.m};
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding-bottom: ${({theme}) => theme.layouts.spacing.xxl};
  }
`;

const PageContainer = ({children, className}) => (
  <ScrollContainer className={className}>
    <Wrapper>{children}</Wrapper>
  </ScrollContainer>
);

function resetScrollPosition() {
  document?.querySelector(`.${ScrollContainer.styledComponentId}`)?.scroll(0,0);
}

export {resetScrollPosition, ScrollContainer, Wrapper};
export default PageContainer;
