import useBeforeUnload from './useBeforeUnload';
import {useRef, useState, useCallback, useEffect} from 'react';
import debounce from "debounce";

import {getAllQuestionMastery, getTestProgressByUserIdHashTestId} from '@bootcamp/shared/src/requests';
import {VIMEO_ACCESS_TOKEN} from '@bootcamp/shared/src/util/keys';

export const useVimeoVideoUrl = (videoId) => {
  const requestUrl = `https://api.vimeo.com/videos/${videoId}`;
  const fetchRequest = useCallback(async () => {
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${VIMEO_ACCESS_TOKEN}`,
      },
    });

    const videoMeta = await response.json();
    const videoFile = videoMeta.play.hls;

    return {
      url: videoFile && videoFile.link,
      aspectRatio: 9/16,
    };
  }, [requestUrl]);
  const {value} = useAsync(fetchRequest);
  return value || {}
}

export const useDebouncedState = (initialState, durationInMs = 250) => {
  const [internalState, setInternalState] = useState(initialState);
  const debouncedFunction = useCallback(debounce(setInternalState, durationInMs), []);
  return [internalState, debouncedFunction];
};

export const useAsync = (asyncFunction, immediate = true) => {
  const [pending, setPending] = useState(immediate);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setPending(true);
    setValue(null);
    setError(null);
    return asyncFunction()
      .then(response => setValue(response))
      .catch(error => setError(error))
      .finally(() => setPending(false));
  }, [asyncFunction]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
};

export const useTestProgress = (testId, formatter, userId) => {
  const ready = !!userId;

  const fetchTestProgress = useCallback(async () => !ready ? [] : getTestProgressByUserIdHashTestId(`${userId}#${testId}`, true), [ready]);
  const {pending, value: progress} = useAsync(fetchTestProgress);
  const returnValue = pending ? [] : progress;
  return formatter(returnValue);
}

export const useTestProgressArray = (testIds, formatter, userId) => {
  const ready = testIds?.length > 0 && userId != 0;
  const fetchTestProgresses = useCallback(async () => !ready ? [] : await Promise.all(testIds.map(testId => getTestProgressByUserIdHashTestId(`${userId}#${testId}`, true))), [ready]);
  const {pending, value: progresses} = useAsync(fetchTestProgresses);
  const returnValue = pending ? [] : progresses;
  return [formatter(returnValue)];
};

export const useMastery = (contentId, contentType, formatter, userId) => {
  const ready = !!userId;

  const hashKey = {
    subject: 'userIdHashSubjectTagId',
    subjectWithQuestionBaseId: 'userIdHashSubjectTagId',
    topic: 'userIdHashTopicTagId',
    test: 'userIdHashTestId',
    testSmall: 'userIdHashTestId'
  }[contentType];

  const fetchMastery = useCallback(async () => !ready ? [] : getAllQuestionMastery({[hashKey]: `${userId}#${contentId}`}, contentType), [ready]);
  const {pending, value: progress} = useAsync(fetchMastery);
  const returnValue = pending ? [] : progress;
  return [formatter(returnValue)]
}

export const useMasteryArray = (contentIds, contentType, formatter, type, callback, DEFAULT_USER_ID, loading) => {
  const [progresses, setProgresses] = useState({});

  const hashKey = {
    subject: 'userIdHashSubjectTagId',
    subjectWithQuestionBaseId: 'userIdHashSubjectTagId',
    subjectWithQuestionTags: 'userIdHashSubjectTagId',
    topic: 'userIdHashTopicTagId',
    test: 'userIdHashTestId',
    testSmall: 'userIdHashTestId'
  }[contentType];
  const userId = DEFAULT_USER_ID;

  async function fetchProgress() {
    if (!userId) return setProgresses({});

    const results = await contentIds.reduce(async (acc, contentId) => {
      const data = await getAllQuestionMastery({
        [hashKey]: `${userId}#${contentId}`,
        limit: 1000
      }, contentType);
      return {...(await acc), [contentId]: formatter ? formatter(data) : data}
    }, {});

    const update = type ? {...progresses, [type]: results} : results;

    setProgresses(update);

    // hooking in for componennt loading states
    if (typeof callback === 'function') {
      callback();
    }
  }

  useEffect(() => {
    if (loading || !contentIds || progresses[type]) return;

    fetchProgress();
  }, [userId, loading, type]);

  return progresses;
}
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export {
  useBeforeUnload
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  );

  return debouncedValue;
}
