import React from 'react';
import styled, {css} from 'styled-components';
import {H3, H2, Body4} from '../../components/Typography';

export const Well = styled.div`
  background: ${({theme}) => theme.colors.special.naturalDark};
  border: .5px solid ${({theme}) => theme.overlays.opacity.dark._100};
  box-shadow: ${({theme}) => theme.elevation.inner.progressLight};
  border-radius: 8px;
  padding: ${({theme}) => theme.layouts.spacing.xl};
  margin-bottom: 48px;
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 24px 16px;
  }
`;
export const Title = styled(H3)`
  margin: 0 0 4px;
  color: ${({theme}) => theme.colors.neutralsPalette.white};
`;
export const Body = styled(Body4)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  color: ${({theme}) => theme.overlays.opacity.light._400};
  max-width: 528px;
`;

const ClassroomContentWrapper = ({className, title, body, children}) => (
  <Well className={className}>
    {title && <Title children={title}/>}
    {body && <Body children={body}/>}
    {children}
  </Well>
);

const SectionWrapper = styled.div`
`;
const SectionTitle = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};

  ${({hasTitle}) => hasTitle && css`
    padding: ${({theme}) => `${theme.layouts.spacing.l} 0`};
  `}
`;

// TODO we may deprecate clasroom content wrapper with this at some point
const PageSection = ({innerRef, title, children}) => {
  return (
    <SectionWrapper ref={innerRef}>
      <SectionTitle hasTitle={!!title}>{title}</SectionTitle>
      {children}
    </SectionWrapper>
  );
};

export {PageSection};
export default ClassroomContentWrapper;
