import React, {useRef} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import {useHotkeys} from 'react-hotkeys-hook';

const VideoPlayer = ({vimeoId, locked, autoplay=false, color=null, onEnd}) => {
  const playerRef = useRef();

  useHotkeys('space', async () => {
    try {
      if (await playerRef.current.getPaused()) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [playerRef]);

  const onReady = player => {
    playerRef.current = player;
    const defaultPlaybackRate = parseFloat(window.localStorage.getItem('jwplayer-playbackRate')) || 1;
    playerRef.current.setPlaybackRate(defaultPlaybackRate);

    const defaultQuality = window.localStorage.getItem('jwplayer-quality') || 'auto';
    playerRef.current.setQuality(defaultQuality);

    const defaultText = JSON.parse(window.localStorage.getItem('jwplayer-textTrack') || '{}');
    defaultText?.language && playerRef.current.enableTextTrack(defaultText.language, defaultText.kind);
  }

  const onPlaybackRateChange = async () => {
    if (!playerRef.current) return;
    const playbackRate = await playerRef.current.getPlaybackRate();
    window.localStorage.setItem('jwplayer-playbackRate', playbackRate);
  }

  const onTextTrackChange = async textTrack => {
    if (!playerRef.current) return;
    window.localStorage.setItem('jwplayer-textTrack', JSON.stringify(textTrack));
  }

  const onPlaying = async () => {
    if (!playerRef.current) return;
    const quality = await playerRef.current.getQuality();
    window.localStorage.setItem('jwplayer-quality', quality);
  }

  const onError = e => console.log(e);

  return (
    <Vimeo
      key={vimeoId}
      onReady={onReady}
      onPlaying={onPlaying}
      onPlaybackRateChange={onPlaybackRateChange}
      onTextTrackChange={onTextTrackChange}
      onEnd={onEnd}
      onError={onError}
      video={vimeoId}
      controls={!locked}
      responsive
      speed
      autoplay={autoplay}
      color={color?.replace('#','')}
    />
  )
}

export default VideoPlayer;
