import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StreakAlert from '../StreakAlert';

const BarContainer = styled.div`
  width: 100%;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
`;

const BarInner = styled(BarContainer)`
  width: ${({progress, total}) => !total ? 0 : (progress/total) * 100}%;
  background-color: #43E5B4;
  transition: width 2s;
  position: relative;
`;

const NumericContainer = styled.div`
  color: white;
  font-weight: 600;
`;

const Bar = ({progress, answerStats, total}) => (
  <BarContainer>
    <BarInner progress={progress} total={total || 0}>
      <StreakAlert progress={answerStats} />
    </BarInner>
  </BarContainer>
);

const Numeric = ({progress, total}) => (
  <NumericContainer>
    {`${progress + 1} of ${total}`}
  </NumericContainer>
);

const Progress = ({displayStyle, answerStats, progress, total}) => {
  const ProgressComponent = displayStyle === 'bar' ? Bar : Numeric;
  return <ProgressComponent progress={progress} total={total} answerStats={answerStats}/>;
}


Progress.propTypes = {
  displayStyle: PropTypes.oneOf(['bar', 'numeric']),
  answerStats: PropTypes.shape({}).isRequired,
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

Progress.defaultProps = {
  displayStyle: 'bar',
};

export default Progress;
