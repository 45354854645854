import React from 'react';
import styled from 'styled-components';
import {GutterRow} from '../../../../components/Spacing';

const LastPageContainer = styled(GutterRow)``;

const TextContainer = styled.div`
  line-height: 1.75;
  font-size: 28px;
`;

const StatisticText = styled.span`
  color: #5096E4;
  font-weight: 500;
  margin: 0 5px;
  border-bottom: 1px dotted #5096E4;
`;

const LastPage = ({progress, startTime, endTime}) => {
  const questionsCorrect = progress.reduce((correctCount, {didSubmitCorrectAnswer}) => didSubmitCorrectAnswer ? ++correctCount : correctCount, 0);
  const timeElapsed = new Date(endTime.current - startTime.current);
  const minutesElapsed = timeElapsed.toISOString().substr(14, 2);
  const secondsElapsed = timeElapsed.toISOString().substr(17, 2);
  const averagePace = Math.round((timeElapsed.getTime() / 1000) / progress.length);
  return (
    <LastPageContainer>
      <TextContainer>
        Way to go! You finished today's warmup. You answered <StatisticText>{questionsCorrect}</StatisticText> question{questionsCorrect === 1 ? '' : 's'} correctly in <StatisticText>{!!parseInt(minutesElapsed) && `${minutesElapsed}m`} {secondsElapsed}s</StatisticText>, with an average pace of <StatisticText>{averagePace}</StatisticText> seconds per question. See you tomorrow!
      </TextContainer>
    </LastPageContainer>
  )

}

export default LastPage;
