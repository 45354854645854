import React from 'react';
import styled from 'styled-components';
import Auth from '@aws-amplify/auth';

import {Link, withRouter} from 'react-router-dom';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {FormSubmit} from '../';
import {Container, Header, Body, FieldGroup, LinkButton} from '../../';
import {Body3} from '../../../Typography';

const ErrorText = styled(Body3)`
  color: ${({theme}) => theme.colors.interfacePalette.red.default};
  margin-top: 4px;
  text-align: center;
`;

const SignIn = ({location, setRedirect}) => {

  async function onSubmitHandler (values, formMethods, secondAttempt=false) {
    const {username: usernameAsSubmitted, password} = values;

    try {
      // try lowercase username by default
      const username = secondAttempt ? usernameAsSubmitted : usernameAsSubmitted.toLowerCase();

      const user = await Auth.signIn(username, password);

      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // You need to get the code from the UI inputs
        // and then trigger the following function with a button click
        const code = window.prompt(`Enter authenticator code:`);

        if (!code) return setRedirect('/not-found');
        const mfaType = user.challengeName;
        // If MFA is enabled, sign-in should be confirmed with the confirmation code
        await Auth.confirmSignIn(
          user,   // Return object from Auth.signIn()
          code,   // Confirmation code
          mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );
      }

    } catch (e) {
      if (e.code === "UserNotFoundException") {
        if (secondAttempt) return formMethods.setError('username', e.code, `${e.message} User not found.`);
        // if this was the first attempt, rerun to try without forcing lowercase username
        return onSubmitHandler(values, formMethods, true);
      }
      if (e.code === "NotAuthorizedException") {
        if (e.message === "Password attempts exceeded") {
          // TODO: handle password overentry error
          return formMethods.setError('password', e.code, "You've been locked out for 15 minutes. If you forgot your password, reset your password or send us an email at team@bootcamp.com.");
        } else {
          return formMethods.setError('password', e.code, e.message);
        }
      }
    }
  }
  const limit = window.location.href.includes('?limit=true');

  return (
    <FormWrapper onSubmit={onSubmitHandler}>
      <Container>
        <Body>
          <Header>Jump back in</Header>
          {limit && (
            <ErrorText>
              You've been logged out because you've reached the max number of devices that can log into this account. Please log in again.
            </ErrorText>
          )}
          <FieldGroup children={<FieldRenderer config={SIGNIN_FORM_CONFIG} />}/>
          <LinkButton color={'indigo'} children={<Link to={{
            pathname: `/join`,
            search: location.search
          }}>Create an account</Link>} />
        </Body>
        <FormSubmit setRedirect={setRedirect}>
          Log In
        </FormSubmit>
      </Container>
    </FormWrapper>
  );
}


export default withRouter(SignIn);

const ForgotPasswordLink = styled(Link)`
  color: ${({theme}) => theme.colors.brandPalette.indigo.default};
  cursor: pointer;
  text-decoration: none;
`;

const SIGNIN_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'Email / username',
    name: 'username',
    additionalInfo: '(Case Sensitive)',
    inputProps: {
      autoFocus: true,
      type: 'email',
      autoComplete: 'username'
    },
    validation: () => ({
      required: 'Email / username required',
    })
  },
  {
    type: 'Password',
    label: 'Password',
    name: 'password',
    additionalInfo: <ForgotPasswordLink to={`/forgot`}>Forgot Password</ForgotPasswordLink>,
    inputProps: {
      autoComplete: 'current-password'
    },
    validation: () => ({
      required: true,
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters'
      }
    })
  }
]
