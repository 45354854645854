import React from 'react';
import styled from 'styled-components';

import {ArrowMinimize as Collapse} from '@styled-icons/fluentui-system-filled/ArrowMinimize';
import IconButton from './IconButton';


const ToggleSidebarButton = styled(IconButton)`
  height: 56px;
  width: 56px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
  }
`;
const ToggleSidebarIcon = styled(Collapse).attrs(props => ({size: 20}))`
  color: white;
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 20px;
    width: 20px;
  }
`;

export const ToggleSidebar = ({className, showingSidebar, onClick}) => (
  <ToggleSidebarButton
    className={className}
    type={'secondary'}
    size={'large'}
    children={<ToggleSidebarIcon />}
    showingSidebar={showingSidebar}
    onClick={onClick}
  />
);