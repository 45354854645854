import React, {useMemo, useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import useForm from 'react-hook-form';

import {H4} from '../../../components/Typography';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import Overview from '../../components/Cards/Overview';

import {LoadingSpinner} from '../../../components/Branding';
import {useTable, usePagination, useSortBy} from 'react-table';
import {getTestProgress, getTest, getTestProgressIdsByUserId, getTestProgressIdsByIdOverride} from '@bootcamp/shared/src/requests';

import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {getInObj} from '@bootcamp/shared/src/util';
import moment from 'moment';
import {Helmet} from 'react-helmet';

import {findInConfigById} from '@bootcamp/shared/src/util';
import {getResults, aggregateBySection, aggregateByOATSection, getAcademicAverage, getOATAcademicAverage} from '@bootcamp/shared/src/util/scoring';
import {restoreQuizProgress} from '../../dat/Performance';

import {ChevronLeft} from '@styled-icons/fluentui-system-filled/ChevronLeft';
import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';
import {theme} from '@bootcamp/shared/src/styles/theme';

import Container, { resetScrollPosition } from '../PageContainer';
import {firstBy} from 'thenby';

import {BareSelect} from '../../components/MasteryReview/Select';
import { Label3 } from '../../../components/Typography/next';

const retrieveQuestionProgresses = testData => {
  const {blockProgresses: {items: blockProgressItems}} = testData || {blockProgresses: {items: []}};
  return blockProgressItems.reduce((acc, blockProgress) => [...acc, ...(blockProgress.questionProgresses.items || [])], []);
}

async function getTestProgresses(userId) {
  const data = await getTestProgressIdsByUserId(userId);
  return getInObj(['data', 'getUser', 'testProgresses', 'items'], data, []);
}

async function getDBCTestProgresses(userId) {
  const data = await getTestProgressIdsByIdOverride(userId);
  return getInObj(['data', 'getWordPressUserData', 'testProgresses', 'items'], data, []);
}

function filterAndSortProgresses(items, bootcamp, config) {
  try {
    switch (bootcamp) {
      case 'dat':
      case 'oat':
        return items
          ?.sort(firstBy('createdAt', -1))
          ?.filter(({ testId }) => {

            const configHit = findInConfigById(config, testId);
            const fltConfigHit = [config?.studyTools?.[0]?.content?.find(({id}) => id === testId)];

            const match = configHit || fltConfigHit;

            return !!match?.[0]?.name?.match('Test #');
          });
      default:
        return items;
    }

  } catch (error) {
    return items;
  }
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const StyledOverview = styled(Overview)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0px;
  }
`;

const SubjectPill = styled(Label3)`
  background: ${({palette}) => palette.light};
  color: ${({palette}) => palette.default};
  padding: 8px  12px;
  border-radius: 256px;
  width: auto;
  display: inline-block;
`;

const PastTestResults = ({history, match}) => {
  const {DEFAULT_USER_ID, userModel, bootcamp} = useUserDataContext();

  const {config} = useBootcampConfigContext();

  const [currentPage, setCurrentPage] = useState({pageIndex: 0, pageSize: 10});
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  const [testsById, setTestsById] = useState({});
  const [progressById, setProgressById] = useState({});

  const [baseProgress, setBaseProgress] = useState([]);
  const [loading, setLoading] = useState(true);

  const pageCount =   Math.ceil(baseProgress?.length / currentPage.pageSize);

  const {register, watch} = useForm();

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function fetchTests(results) {
    // fetch tests
    const testIdsToFetch = results.reduce((acc, {testId}) => (acc.includes(testId) || testsById[testId]) ? acc : [...acc, testId], []);
    const testPromises = testIdsToFetch.map(testId => getTest(testId, 'getTestConfig'));

    // fetch progress
    const progressIdsToFetch = results.reduce((acc, {id}) => (acc.includes(id) || progressById[id]) ? acc : [...acc, id], []);
    const progressPromises = progressIdsToFetch.map(id => getTestProgress(id, 'getTestProgress'));

    // short circuit if nothing to fetch
    if (!testIdsToFetch.length && !progressIdsToFetch.length) return;

    setLoading(true);

    const testPromiseResults = await Promise.all(testPromises);
    const progressPromiseResults = await Promise.all(progressPromises);

    const fetchedProgress = progressPromiseResults.reduce((acc, curr) => {
      const progress = curr?.data?.getTestProgress;

      return progress?.id
        ? {...acc, [progress?.id]: progress}
        : acc
    }, {});

    const fetchedTests = testPromiseResults.reduce((acc, curr) => {
      const test = curr?.data?.getTest;

      return test?.id
        ? {...acc, [test?.id]: test}
        : acc
    }, {});

    setTestsById({...testsById, ...fetchedTests});
    setProgressById({...progressById, ...fetchedProgress});
    setLoading(false);
  }

  async function handleNextPage() {
    // when we're moving to the next page, just fetch the next page of test
    const nextPageIndex = currentPage.pageIndex + 1;
    const nextPage = baseProgress?.slice(nextPageIndex * currentPage.pageSize, (nextPageIndex + 1) * currentPage.pageSize);

    setCurrentPage({pageIndex: nextPageIndex, pageSize: currentPage.pageSize})
    fetchTests(nextPage);
  }

  async function handlePreviousPage() {
    setCurrentPage({pageIndex: currentPage.pageIndex - 1, pageSize: currentPage.pageSize});
  }

  async function handlePageSize(newPageSize) {
    const nextPage = baseProgress?.slice(0 * newPageSize, (1) * newPageSize);

    await fetchTests(nextPage);

    setCurrentPage({pageIndex: 0, pageSize: newPageSize});
  }

  function getScore(questionProgresses) {
    return (questionProgresses.filter(({didSelectCorrectAnswer}) => didSelectCorrectAnswer).length / questionProgresses.length) * 100;
  }

  function getSubjectFromTestTitle(testTitle) {
    const subjectString = testTitle?.split('Test #')[0];
    const subject = subjectString?.match('Full Length') ? 'Multiple' : subjectString;

    return subject?.trim();
  }

  const columns = useMemo(
    () => [
      {
        Header: '#',
        Cell: ({row}) => {
          return baseProgress.length - row.index - (currentPage.pageIndex*currentPage.pageSize);
        }
      },
      {
        Header: 'Raw Score',
        Cell: ({row}) => {
          const questionProgresses = retrieveQuestionProgresses(row.original);
          return `${Math.round(getScore(questionProgresses))}%`
        },
      },
      {
        Header: 'Name',
        Cell: ({row}) => {
          const {testTitle} = row.original;
          return testTitle;
        }
      },
      {
        Header: 'Subject',
        Cell: ({row}) => {
          const {testTitle} = row.original;
          const subject = getSubjectFromTestTitle(testTitle);

          const palette = subject === 'Multiple'
            ? { default: theme.colors.neutralsPalette.grey, light: theme.colors.neutralsPalette.extraLight}
            : config?.classrooms?.find(({name}) => name === subject)?.themePalette;

          return <SubjectPill palette={palette}>{subject}</SubjectPill>

        },
      },
      {
        Header: 'Estimated Score',
        Cell: ({row}) => {
          const {testTitle} = row.original;
          const questionProgresses = retrieveQuestionProgresses(row.original);
          const score = getScore(questionProgresses);
          const subject = getSubjectFromTestTitle(testTitle);

          if (subject === 'Multiple') {
            const quizProgress = restoreQuizProgress(row.original);
            const subjectScores = bootcamp === 'dat'
              ? aggregateBySection(quizProgress)
              : aggregateByOATSection(quizProgress);

            const fullLengthAcademicAverage = bootcamp === 'dat'
              ? getAcademicAverage(testTitle, subjectScores.statistics)
              : getOATAcademicAverage(testTitle, subjectScores.statistics);

            return fullLengthAcademicAverage;
          }

          const academicAverage = getResults(score, testTitle, subject);
          return academicAverage?.score;
        },
      },
      {
        Header: 'Date Taken',
        Cell: ({row}) => {
          const {createdAt} = row.original;
          return moment(createdAt).format('ll');
        },
        accessor: row => new Date(row.createdAt),
        sortType: 'datetime'
      },
      {
        Header: ' ',
        Cell: () => <ChevronRight size={24} color="#C8C9CE" />
      }
    ].filter(column => containerWidth < 768 ? !['Subject', 'Date Taken', ' '].includes(column.Header) : true),
    [baseProgress, currentPage, containerWidth]
  );

  const data = baseProgress
    ?.slice(currentPage.pageIndex * currentPage.pageSize, (currentPage.pageIndex + 1) * currentPage.pageSize)
    ?.reduce((acc, data, index) => {

      const {id, testId} = data;

      const progress = progressById[id];
      const test = testsById[testId];

      if (!progress || !data) return acc;

      const { config: { title: testTitle } } = test || { config: { title: '' } };

      // filters
      const { date, subject, score, search } = watch();

      const testDateOutsideFilter = moment(progress?.createdAt) < moment().subtract(date, 'days');
      if (date && testDateOutsideFilter) return acc;

      if (subject && !testTitle.match(subject)) return acc;

      try {

        const questionProgresses = retrieveQuestionProgresses(progress);
        const testScore = (questionProgresses.filter(({ didSelectCorrectAnswer }) => didSelectCorrectAnswer).length / questionProgresses.length).toFixed(3) * 100;
        if (score && testScore < score) return acc;

        if (search && !JSON.stringify(progress).match(search)) return acc;

        return [
          ...acc,
          {
            ...test,
            ...progress,
            testTitle
          }
        ]
      } catch (error) {
        return acc;
      }
    }, []);

  useEffect(() => {
    if (!DEFAULT_USER_ID) return;

    async function fetch() {
      const results = userModel.idOverride ? await getDBCTestProgresses(userModel.idOverride) : await getTestProgresses(DEFAULT_USER_ID);

      const filteredItems = filterAndSortProgresses(results, bootcamp, config);

      setBaseProgress(filteredItems);

      const firstPage = filteredItems?.slice(0, 10);

      fetchTests(firstPage);
    }

    fetch();

  }, [userModel, DEFAULT_USER_ID]);


  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | Previous Tests</title>
        <meta name="description" content={`${config.meta.siteTitle} Previous Tests`}></meta>
      </Helmet>
      <StyledBreadcrumbs history={history} lockAtDepth={2}/>
      <StyledOverview
        subTitle={'Previous Tests'}
        body={''}
        actions={[]}
        showProgress={false}
        educators={[]}
      />
      <Table
        columns={columns}
        data={data}
        loading={loading}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageSize={handlePageSize}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        />
    </Container>
  )
};

const TableStyles = styled.div`
  width: 100%;
  position: relative;
  background: white;
  border-radius: 8px;
  min-height: 400px;

  table {
    min-height: 400px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    border-collapse: collapse;

    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);

    th {
      cursor: default;
      background: #FAFAFA;

      &:first-child {
        font-weight: 700;
        color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
        text-align: center;
        font-size: 16px;
      }

      font: bold 16px 'proxima-nova', sans-serif;
      text-shadow: none;
      border-width: 0;
      padding: 15px;
      min-height: 74px;
      text-align: left;
      border-bottom: 1px solid #EDEDF0;
      font-weight: 600;
    }
    tr {
      cursor: pointer;
      &:last-child {
        td {
          border-bottom: none;
        }
      }
      &:hover {
        background-color: ${({theme}) => theme.colors.neutralsPalette.offWhite};
      }
    }
    td {
      min-width: 50px;
      font-size: 16px;

      &:first-child {
        font-weight: 700;
        color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
        text-align: center;
      }

      height: 50px;
      padding: 8px 15px;
      vertical-align: middle;
      font: 400 16px 'proxima-nova', sans-serif;
      text-align: left;
      border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
      position: relative;
      .editButton {
        position: absolute;
        margin: auto 8px;
        display: none;
      }
      &:hover {
        .editButton {
          display: inline;
        }
      }
    }
  }
`;

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  z-index: ${({visible}) => visible ? 1 : -1};
  overflow: hidden;
  transition: all 250ms ease;

  ${LoadingSpinner} {
    position: absolute;
  }
  `;

const LoadingOverlay = styled.div`
  flex: 1;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  opacity: ${({visible}) => visible ? 0.6 : 0};
  transition: all 250ms ease;
  width: 100%;
  height: 100%;
`;

const ResultsMessage = styled(H4)`
  text-align: center;
  padding: 25px;
  width: 100%
`;

const TableWrapper = styled.div`
`;

const PaginationWrapper = styled.div`
  padding-top: ${({theme}) => theme.layouts.spacing.l};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;

  button {
    height: 42px;
    width: 42px;
    border-radius: 100%;
    border: 1px solid ${({theme}) => theme.overlays.opacity.light._100};
    background: ${({theme}) => theme.overlays.opacity.light._100};
    color: white;
    cursor: pointer;
    margin-right: 8px;

    &:hover {
      background: ${({theme}) => theme.overlays.opacity.light._200};
    }
    &:disabled {
      cursor: not-allowed;
      border: 1px solid ${({theme}) => theme.overlays.opacity.light._50};
      background: ${({theme}) => theme.overlays.opacity.light._50};
      color: ${({theme}) => theme.overlays.opacity.light._400};
      &:hover {
        background: ${({theme}) => theme.overlays.opacity.light._50};
      }
    }
  }
  span {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

`;

const StyledSelect= styled(BareSelect)`
  margin-left: 8px;
  select {
    padding: 12px;
    margin-right: 28px;
  }
`;

const Table = ({columns, data, loading, pageCount, currentPage, onPreviousPage, onNextPage, onPageSize}) => {
  const {bootcamp} = useUserDataContext();
  const tableRef = useRef(null);
  const [height, setHeight] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage.pageIndex, sortBy: [{id: 'Date Taken', desc: true}] },
      disableSortRemove: true,
      pageCount,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {push} = useHistory();

  function handlePreviousPage() {
    previousPage();
    onPreviousPage();
  }

  function handleNextPage() {
    nextPage();
    onNextPage();
  }

  const hasResults = data.length > 0;

  useEffect(() => {
    if (!tableRef?.current || loading) return;

    setHeight(tableRef.current.clientHeight);

  }, [tableRef?.current?.clientHeight, loading]);

  useEffect(() => {
    resetScrollPosition();
  }, [pageIndex, pageSize]);

  return (
    <TableWrapper>
      <TableStyles style={{height: loading ? Math.min(height, 550) : 'auto'}}>
        <table {...getTableProps()} ref={tableRef} style={{height: loading ? Math.min(height, 550) : 'auto'}}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              const {id: progressId, testId} = row.original;

              prepareRow(row);

              return (
                <tr {...row.getRowProps()} onClick={() => push({pathname: `/${bootcamp}/test-review`, search: `?id=${encodeURIComponent(testId)}&testProgressId=${encodeURIComponent(progressId)}`})}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {!hasResults && !loading
          ? <ResultsMessage>Nothing to see here yet!</ResultsMessage>
          : null
        }
        <LoadingSpinnerContainer visible={loading}>
          <LoadingOverlay visible={loading}/>
          <LoadingSpinner color={'royal'} size={24} />
        </LoadingSpinnerContainer>
      </TableStyles>
      <PaginationWrapper>
        <span>
          Page&nbsp;
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button onClick={handlePreviousPage} disabled={!canPreviousPage || loading}>
          <ChevronLeft size={20} color="white" />
        </button>
        <button onClick={handleNextPage} disabled={!canNextPage || loading}>
          <ChevronRight size={20} color="white" />
        </button>
        <StyledSelect
          value={pageSize}
          loading={loading}
          config={[
            { name: 'Show 10', value: 10 },
            { name: 'Show 20', value: 20 },
            { name: 'Show 30', value: 30 },
            { name: 'Show 40', value: 40 },
            { name: 'Show 50', value: 50 },
          ]}
          onChange={async value => {
            await onPageSize(value);
            setPageSize(value);
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </StyledSelect>
      </PaginationWrapper>
    </TableWrapper>
  );
}

export {TableStyles};
export default PastTestResults;
