import React from 'react';
import styled from 'styled-components';
import 'jquery-ui';

const Container = styled.div`
`;

const newHtmlString = `
	<div id="content" class="exam">

		<div class="posts">

			<article>

				<div class="box-wrap">

					<div id="introduction">

						<div class="bartop">

							<div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Pattern Folding Practice Generator</h2></div></div></div>

						<div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

						<div class="instructions-container">

							<p>This is the pattern folding generator. Please read the following before starting:</p>

							<p>1. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">pattern folding instructions</a>.<br />
								2. If you're having trouble, check out my <a href="/dat/perceptual-ability/videos/pattern-folding?index=1" target="_blank">pattern folding tutorial video</a>.<br />
								3. This generator creates entirely new, unique questions on the fly, so practice as much as you'd like!</p>

							<p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

							<p style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;" id="start-generator">Start Generator</a></p>


							<div id="instructions" style="display: none;">
								<p style="margin-top: 50px;"><strong>Official Pattern Folding Instructions</strong></p>

								<p>A flat pattern will be presented. This pattern is to be folded into a three dimensional figure. The correct figure is one of the four given at the right of the pattern. There is only one correct figure in each set. The outside of the pattern is what is seen at the left. The outside of the pattern must remain on the outside of the three dimensional figure.</p>

								<p>Here's an example:</p>

								<img src="https://s3.amazonaws.com/datbootcamp/patternfoldingexample.png" style="max-width: 560px; margin: 35px 0px;">

									<p>One of the above figures (A, B, C, or D) can be formed from the flat pattern given at the left. The only figure that corresponds to the pattern is [D]. If the shaded surfaces are looked at as the sides of the box, then all four sides must be shaded, while the top and bottom are white.</p></div>

							<div style="display: block; height: 100px;"></div>

						</div>

					</div>

					<div id="generator" style="display: none;">

						<div class="bartop">
							<div class="bartopcontainer">
								<div class="column-center"><h2>Pattern Folding Practice Generator</h2></div>
								<div class="column-left">
									<p id="position">Question <span id="location" style="font-weight: 600;">1</span> of <span id="total" style="font-weight: 600;">1</span></p></div><div class="column-right"><p id="time-box" style="float: right;">Time Remaining: <span id="time-display" style="font-weight: 600;">30</span><span id="time-button" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">On/Off</span></p>
									<p style="float: right;">Average Time: <span id="average-time" style="font-weight: 600;">0</span><span style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Seconds</span></p>
								</div>
							</div>
						</div>

						<div class="generator-container">

							<div id="container">
								<div style="height: 264px; margin-top: 15px; position: relative;">
									<div class="thinBorder" style="width: 264px; height: 264px; display: inline-block; float: left;">
										<canvas style="width: 270px; height: 245px;"></canvas>
										<p id="clickToDrag" style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 0.8em; opacity: 0.5; margin-bottom: 10px; margin-top:0;">Click and drag to rotate</p>
									</div>
									<div class="thinBorder" id="firstfold" style="width: 159px; height: 264px; border-left: none; float: left;">
										<canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										<p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">A</p>
									</div>
									<div class="thinBorder" id="secondfold" style="width: 159px; height: 264px; border-left: none; float: left;">
										<canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										<p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">B</p>
									</div>
									<div class="thinBorder" id="thirdfold" style="width: 159px; height: 264px; border-left: none; float: left;">
										<canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										<p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">C</p>
									</div>
									<div class="thinBorder" id="fourthfold" style="width: 159px; height: 264px; border-left: none; float: left;">
										<canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										<p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">D</p>
									</div>
								</div>
								<div style="clear: both;"></div>
								<div style="height: 50px;"><div id="animation-controls" style="margin: 12px 0px 0px 0px; min-height: 35px;">
									<button type="button" id="pause" style="border-top: thin solid #CDCDCD; border-left: thin solid #CDCDCD; border-right: thin solid #E1E1E1; border-bottom: 2px solid #AAA; cursor: pointer; background-color: rgb(16, 99, 148); min-width: 100px; color: white; text-transform: uppercase; box-shadow: 0px 0px 0px rgb(255, 255, 255); padding: 4px 10px 3px; margin: 4px; font-weight: bold; font-size: .7em; display: none;">Pause</button>
									<button type="button" id="reverse" style="border-top: thin solid #CDCDCD; border-left: thin solid #CDCDCD; border-right: thin solid #E1E1E1; border-bottom: 2px solid #AAA; cursor: pointer; background-color: rgb(16, 99, 148); min-width: 100px; color: white; text-transform: uppercase; box-shadow: 0px 0px 0px rgb(255, 255, 255); padding: 4px 10px 3px; margin: 4px; font-weight: bold; font-size: .7em; display: none;">Reverse</button>
									<span>Folds: </span>
									<button type="button" id="previousButton" style="border-top: thin solid #CDCDCD; border-top-left-radius: 10px; border-bottom-left-radius: 10px; border-left: thin solid #CDCDCD; border-right: thin solid #E1E1E1; border-bottom: 2px solid #AAA; cursor: pointer; background-color: rgb(16, 99, 148); color: white; text-transform: uppercase; box-shadow: 0px 0px 0px rgb(255, 255, 255); padding: 4px 10px 3px; margin: 4px; font-weight: bold; font-size: .7em;"><div class="arrow-left"></div></button>
									<button type="button" id="nextButton" style="border-top: thin solid #CDCDCD; border-top-right-radius: 10px; border-bottom-right-radius: 10px; border-left: thin solid #CDCDCD; border-right: thin solid #E1E1E1; border-bottom: 2px solid #AAA; cursor: pointer; background-color: rgb(16, 99, 148); color: white; text-transform: uppercase; box-shadow: 0px 0px 0px rgb(255, 255, 255); padding: 4px 10px 3px; margin: 4px; font-weight: bold; font-size: .7em;"><div class="arrow-right"></div></button>
									<div style="display: none;" id="slider"></div>
								</div>
								</div>
								<div id="grades" style="font-size: 16px; margin-top: 10px; border: 1px solid #E8E8E8; padding: 5px;">
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="A"><div style="display: inline-block;"><label>A</label></div>
											<div id="grade-A" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
									</div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="B"><div style="display: inline-block;"><label>B</label></div>
											<div id="grade-B" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
									</div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="C"><div style="display: inline-block;"><label>C</label></div>
											<div id="grade-C" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
									</div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="D"><div style="display: inline-block;"><label>D</label></div>
											<div id="grade-D" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
									</div>
								</div>


								<div class="mobileexamfix" style="clear: both; height: 25px; margin: 30px 0px;"></div>

								<div class="barbottom">

									<div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; padding: 15px 0px; justify-content: space-between;">

										<div style="display: flex; flex-direction: row; width: 33%;"><button type="button" id="previous" class="nextbutton" style="margin-right: 15px!important;">Previous</button><button type="button" id="next" class="nextbutton">Next</button></div>

										<div style="display: flex; flex-direction: column; width: 67%; text-align: right; color: #FFF;"><div id="scoreboard">Correct: <span id="correct">0</span> | Incorrect: <span id="incorrect">0</span> |<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span> | Current Difficulty: <select name="Difficulty" id="setdifficulty"><option value="0">DAT Mode</option><option value="1">Beginner Mode</option></select></div></div>

									</div>

								</div>

							</div>

						</div>

						<div id="log1"></div>
						<div id="log2"></div>

					</div>

			</article>

		</div>

	</div>
`;

const htmlString = `
<div id="content" class="exam">

		<div class="posts">

			<article>

				<div class="box-wrap">

						<div id="introduction">

							<div class="bartop">

							<div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Pattern Folding Practice Generator</h2></div></div></div>

							<div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

							<div class="instructions-container">

								<p>This is the pattern folding generator. Please read the following before starting:</p>

								<p>1. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">pattern folding instructions</a>.<br />
								2. If you're having trouble, check out my <a href="/dat/perceptual-ability/videos/pattern-folding?index=1" target="_blank">pattern folding tutorial video</a>.<br />
								3. This generator creates entirely new, unique questions on the fly, so practice as much as you'd like!</p>

								<p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

								<p style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;" onclick="document.getElementById('generator').style.display='block'; document.getElementById('introduction').style.display='none';">Start Generator</a></p>


								<div id="instructions" style="display: none;">
								<p style="margin-top: 50px;"><strong>Official Pattern Folding Instructions</strong></p>

								<p>A flat pattern will be presented. This pattern is to be folded into a three dimensional figure. The correct figure is one of the four given at the right of the pattern. There is only one correct figure in each set. The outside of the pattern is what is seen at the left. The outside of the pattern must remain on the outside of the three dimensional figure.</p>

								<p>Here's an example:</p>

								<img src="https://s3.amazonaws.com/datbootcamp/patternfoldingexample.png" style="max-width: 560px; margin: 35px 0px;">

								<p>One of the above figures (A, B, C, or D) can be formed from the flat pattern given at the left. The only figure that corresponds to the pattern is [D]. If the shaded surfaces are looked at as the sides of the box, then all four sides must be shaded, while the top and bottom are white.</p></div>

								<div style="display: block; height: 100px;"></div>

							</div>

						</div>

							<div id="generator" style="display: none;">

								<div class="bartop">
									<div class="bartopcontainer">
										<div class="column-center"><h2>Pattern Folding Practice Generator</h2></div>
										<div class="column-left"><p id="position">Question <span id="location" style="font-weight: 600;">1</span> of <span id="total" style="font-weight: 600;">1</span></p></div><div class="column-right"><p id="time-box" style="float: right;">Time Remaining: <span id="time-display" style="font-weight: 600;">30</span><span id="time-button" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">On/Off</span></p></div></div>
								</div>

								<div class="generator-container">

								        <div id="container">
										  <div style="height: 264px; margin-top: 15px; border: thin solid; position: relative;">
										    <div style="width: 264px; height: 264px; display: inline-block; float: left;"><canvas style="width: 260px; height: 260px;"></canvas></div>
										    <div id="firstfold" style="width: 159px; height: 264px; border-left: thin solid; float: left;">
										      <canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										      <p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">A</p>
										    </div>
										    <div id="secondfold" style="width: 159px; height: 264px; border-left: thin solid; float: left;">
										      <canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										      <p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">B</p>
										    </div>
										    <div id="thirdfold" style="width: 159px; height: 264px; border-left: thin solid; float: left;">
										      <canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										      <p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">C</p>
										    </div>
										    <div id="fourthfold" style="width: 159px; height: 264px; border-left: thin solid; float: left;">
										      <canvas style="width: 155px; height: 155px; margin-top: 50px;"></canvas>
										      <p style="width: 100%; bottom: 0; left: 0; text-align: center; font-size: 1.6em; margin-bottom: 10px; margin-top:0;">D</p>
										    </div>
										  </div>
										<div style="clear: both;"></div>
										  <div style="height: 50px;"><div id="animation-controls" style="margin: 12px 0px 0px 0px; min-height: 35px;">
												<button type="button" id="pause" style="border-top: thin solid #CDCDCD; border-left: thin solid #CDCDCD; border-right: thin solid #E1E1E1; border-bottom: 2px solid #AAA; cursor: pointer; background-color: rgb(16, 99, 148); min-width: 100px; color: white; text-transform: uppercase; box-shadow: 0px 0px 0px rgb(255, 255, 255); padding: 4px 10px 3px; margin: 4px; font-weight: bold; font-size: .7em;">Pause</button>
												<button type="button" id="reverse" style="border-top: thin solid #CDCDCD; border-left: thin solid #CDCDCD; border-right: thin solid #E1E1E1; border-bottom: 2px solid #AAA; cursor: pointer; background-color: rgb(16, 99, 148); min-width: 100px; color: white; text-transform: uppercase; box-shadow: 0px 0px 0px rgb(255, 255, 255); padding: 4px 10px 3px; margin: 4px; font-weight: bold; font-size: .7em;">Reverse</button>
												<div id="slider"></div>
											</div>
										</div>
										<div id="grades" style="font-size: 16px; margin-top: 10px; border: 1px solid #E8E8E8; padding: 5px;">
										<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="A"><div style="display: inline-block;"><label>A</label></div>
										<div id="grade-A" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
										</div>
										<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="B"><div style="display: inline-block;"><label>B</label></div>
										<div id="grade-B" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
										</div>
										<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="C"><div style="display: inline-block;"><label>C</label></div>
										<div id="grade-C" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
										</div>
										<div class="grade" style="margin: 3px 3px 8px 3px;">
										<input type="radio" name="answer" value="D"><div style="display: inline-block;"><label>D</label></div>
										<div id="grade-D" style="margin: -14px 0px 0px 15px; display: inline-block;"></div>
										</div>
										</div>


									<div class="mobileexamfix" style="clear: both; height: 25px; margin: 30px 0px;"></div>

									<div class="barbottom">

										<div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; padding: 15px 0px; justify-content: space-between;">

											<div style="display: flex; flex-direction: row; width: 33%;"><button type="button" id="previous" class="nextbutton" style="margin-right: 15px!important;">Previous</button><button type="button" id="next" class="nextbutton">Next</button></div>

											<div style="display: flex; flex-direction: column; width: 67%; text-align: right; color: #FFF;"><div id="scoreboard">Correct: <span id="correct">0</span> | Incorrect: <span id="incorrect">0</span> |<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span> | Current Difficulty: <select name="Difficulty" id="setdifficulty"><option value="0">DAT Mode</option><option value="1">Beginner Mode</option></select></div></div>

										</div>

									</div>

								</div>

							</div>

							<div id="log1"></div>
							<div id="log2"></div>

				</div>

			</article>

		</div>

	</div>
`;

const PatternFolding = () => (
  <Container dangerouslySetInnerHTML={{__html: newHtmlString}}/>
);

export default PatternFolding;
