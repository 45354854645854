import React, {useState} from 'react';

import API from '@aws-amplify/api';
import {IntercomAPI} from 'react-intercom';
import moment from 'moment';

import {Container, Body, Header, SubHeader, SubmitContainer, SubmitButton} from '../index';
import {Button} from '../../../components/Branding/Buttons';
import Sequence from '../../../components/Sequence';
import {Loader} from '../../Branding';
import {Lottie} from '@crello/react-lottie';

import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '../../../contexts/UserData';

import styled from 'styled-components';
import checkoutCompleteAnimation from './checkoutComplete.json';
import UpdatePaymentMethod from './UpdatePaymentMethod';
import {invokeLambda} from '@bootcamp/shared/src/requests';

const AnimationContainer = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.xl} 0px;
`;
const StyledSequence = styled(Sequence)`
  height: 100vh;
`;
const CancelButton = styled(Button)`
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  border-color: ${({theme}) => theme.colors.interfacePalette.red.default};
  color: ${({theme}) => theme.colors.interfacePalette.red.default};
`;
const ContactButton = styled(Button)`
`;
const ResumeMembership = styled(Button)`
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;

const FinishedAnimation = () => (
  <AnimationContainer>
    <Lottie
      style={{maxHeight: '150px', maxWidth: '150px'}}
      config={{animationData: checkoutCompleteAnimation}}
      />
  </AnimationContainer>
);

const Finished = ({start, text}) => (
  <Container>
    <Body>
      <Header>{text}</Header>
      {start && <FinishedAnimation/>}
    </Body>
    <SubmitContainer>
      <SubmitButton>
        Back to Account
      </SubmitButton>
    </SubmitContainer>
  </Container>
);

const ManageMembership = ({membership, billingDetails, onFinished}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {modalDispatch} = useModalContext();
  const {refreshSession} = useUserDataContext();

  const preventDefault = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const pendingCancellation = membership.status === 'cancelAtEndOfPeriod';
  const bodyText = error
    ? error
    : pendingCancellation
    ? `Your membership is pending cancellation and will be active until ${moment.unix(billingDetails.nextInvoice).format('MMM Do, YYYY')}`
    : 'You currently have an upgraded membership. As always, feel free to contact us if you ever have questions about your account.';

  const handleCancelClick = async () => {
    setLoading(true);

    try {
      await invokeLambda('tbc-Stripe', {path: '/stripe/cancel-subscription', subscriptionId: membership.id});

      await refreshSession();

      await IntercomAPI('trackEvent', 'Membership Cancelled', {
        subscriptionId: membership.id,
        userId: membership.userId,
      });

      onFinished(`Cancellation successful. Your membership will be active until ${moment.unix(billingDetails.nextInvoice).format('MMM Do, YYYY')}.`);


    } catch (error) {
      setLoading(false);
      setError('Oops, looks like something went wrong. If this problem persists, send us a message on Intercom and we\'ll get it sorted!');
    }
  };

  const handleResumeClick = async () => {
    setLoading(true);

    try {
      await invokeLambda('tbc-Stripe', {path: '/stripe/resume-subscription', subscriptionId: membership.id});

      await refreshSession();
      onFinished('Your membership has been re-enabled!');

    } catch (error) {
      setLoading(false);
      setError('Oops, looks like something went wrong. If this problem persists, send us a message on Intercom and we\'ll get it sorted!');
    }
  };

  const handleContactClick = () => {
    modalDispatch({type: 'close'});
    IntercomAPI('showNewMessage', `I have a question about my membership.`);
  };

  const handleUpdatePaymentMethod = () => {
    modalDispatch({
      type: 'open',
      component: UpdatePaymentMethod,
      enableClickClose: true,
      componentProps: {
        customerId: billingDetails.customerId,
        latestInvoiceId: billingDetails.latestInvoice,
        subscriptionId: membership.id,
        membershipStatus: membership.status,
        showAccountLink: false,
        headerText: membership.status === 'awaitingPayment'
          ? 'Sorry, there was an issue charging your card.'
          : 'Update Payment Method',
        subheaderText: membership.status === 'awaitingPayment'
          ? 'No worries, just try again or update your payment method with a new card to re-activate your account.'
          : 'Enter new card details below to update your default payment method',
      }
    });
  };

  return (
    <Container onClick={preventDefault}>
      <Body>
        <Header>Manage Your Membership</Header>
        <SubHeader>{bodyText}</SubHeader>
      </Body>
      {loading ? null : (
        <SubmitContainer>
          <ResumeMembership
            key={'membership-contact-button'}
            onClick={handleUpdatePaymentMethod}
            children={'Update Payment Method'}
          />
        </SubmitContainer>
      )}
      <SubmitContainer>
        {loading
          ? <Loader color={'indigo'} size={16}/>
          : [
              pendingCancellation
                ? <ResumeMembership
                    key={'membership-resume-button'}
                    onClick={handleResumeClick}
                    children={'Resume Membership'}
                    />
                : <CancelButton
                    key={'membership-cancel-button'}
                    type={'ghost'}
                    onClick={handleCancelClick}
                    children={'Cancel Membership'}
                    />,
              <ContactButton
                key={'membership-contact-button'}
                type={'ghost'}
                onClick={handleContactClick}
                children={'Contact Us'}
                />
            ]
        }
      </SubmitContainer>
    </Container>
  );
}

const Wrapper = ({membership, billingDetails}) => {
  return (
    <StyledSequence>
      {[
        ({next}) => (
          <ManageMembership
            membership={membership}
            billingDetails={billingDetails}
            onFinished={(text) => {
              next({stepSize: 1, activeProps: {finished: true, text}})
            }}
            />
        ),
        ({finished, text}) => <Finished start={finished} text={text}/>
      ]}
    </StyledSequence>
  );
}

ManageMembership.propTypes = {};
ManageMembership.defaultProps = {};

export default Wrapper;
