import React, {useMemo} from 'react';
import styled from 'styled-components';

import {Container, Label, Count, Header, CustomInputWrapper, InputCheck, InputHoverWrapper, TextTooltip} from './shared';
import {useCreateTestState} from '../context';

import {capitalize} from '@bootcamp/shared/src/util';
import {useUserDataContext} from '../../../../contexts/UserData';


const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  width: 150px;
`;
const Input = styled.input`
  margin: 0;
  opacity: 0;
  height: 0;
  width: 0;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  ${Header} {
    margin-bottom: 0;
  }
`;
const StyledCustomInputWrapper = styled(CustomInputWrapper)`
  border-radius: 18px;
`;
const Row = styled.div`
  display: flex;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-wrap: wrap;
    max-width: 75%;
  }
`;

const values = [
  'untagged',
  'learning',
  'reviewing',
  'mastered',
  'bookmarked',
  'all',
];

const Tags = () => {
  const {
    methods: {
      setMasteryFilter,
    },
    variables: {
      loading,
      masteryFilter,
      totals,
    }
  } = useCreateTestState();

  const {bootcamp} = useUserDataContext();

  const row1 = values.slice(0, 4);
  const row2 = values.slice(4);

  const tooltipText = bootcamp === 'nclex'
    ? <>
      <strong>Unused:</strong> new / unanswered questions.
      <br/>
      <strong>Learning:</strong> questions you received no credit.
      <br/>
      <strong>Reviewing:</strong> questions you received partial credit.
      <br/>
      <strong>Mastered:</strong> questions you received full credit.
      <br/>
      <strong>Bookmarked:</strong> questions you marked.
    </>
    : <>
      <strong>Unused:</strong> new / unanswered questions.
      <br/>
      <strong>Learning:</strong> questions you answered incorrectly.
      <br/>
      <strong>Reviewing:</strong> questions you tagged as reviewing.
      <br/>
      <strong>Mastered:</strong> questions you answered correctly.
      <br/>
      <strong>Bookmarked:</strong> questions you marked.
    </>

  return (
    <Container>
      <HeaderWrapper>
        <Header>Question Pool </Header>
        <TextTooltip id={'question-pool-tooltip'}>{tooltipText}</TextTooltip>
      </HeaderWrapper>
      <Row>
      {row1.map(value => (
        <InputWrapper onClick={() => !!totals[value] && setMasteryFilter(value)}>
        <InputHoverWrapper active={value === masteryFilter} disabled={!totals[value] || loading}>
          <StyledCustomInputWrapper htmlFor={value} checked={value === masteryFilter}  disabled={!totals[value] || loading}>
            <Input type={'radio'} value={value} checked={value === masteryFilter} disabled={!totals[value] || loading}/>
            {value === masteryFilter && <InputCheck/>}
          </StyledCustomInputWrapper>
          <Label  disabled={!totals[value] || loading}>{value === 'untagged' ? 'Unused' : capitalize(value)}</Label>
        </InputHoverWrapper>
          <Count masteryLevel={value} loading={loading}>{loading ? '' : totals[value]}</Count>
        </InputWrapper>
      ))}
      </Row>
      <Row>
      {row2.map(value => (
        <InputWrapper onClick={() => !!totals[value] && setMasteryFilter(value)}>
        <InputHoverWrapper active={value === masteryFilter} disabled={!totals[value] || loading}>
          <StyledCustomInputWrapper htmlFor={value} checked={value === masteryFilter}  disabled={!totals[value] || loading}>
            <Input type={'radio'} value={value} checked={value === masteryFilter} disabled={!totals[value] || loading}/>
            {value === masteryFilter && <InputCheck/>}
          </StyledCustomInputWrapper>
          <Label  disabled={!totals[value] || loading}>{capitalize(value)}</Label>
        </InputHoverWrapper>
          <Count masteryLevel={value} loading={loading}>{loading ? '' : totals[value]}</Count>
        </InputWrapper>
      ))}
      </Row>
    </Container>
  );
};

export default Tags;