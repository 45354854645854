import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';
import {FlexBox} from '../../Grid';
import {H2, Body3} from '../../Typography';
import {Button} from '../../Branding/Buttons';
import {LoadingSpinner} from '../../Branding';

import styled from 'styled-components';

const Container = styled.div`
  background: ${({theme}) => theme.colors.neutralsPalette.white};

  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.shadow.high};

  max-width: 528px;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.mobileL} {
    transition: transform .5s, opacity .75s;
    border-radius: 8px 8px 0px 0px;
    ${({visible}) => visible
      ? `
        transform: translateY(0%);
        opacity: 1;
        margin-top: auto;
      `
      : `
        transform: translateY(100%);
        opacity: 0;
      `
    }
  }
`;
const Wrapper = styled.div`
  text-align: center;
  padding: ${({theme}) => `${theme.layouts.spacing.xl} ${theme.layouts.spacing.xxl}`};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.xl}`};
  }
`;
const Header = styled(H2)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xs};
`;
const Body = styled(Body3)`
`;
const ButtonWrapper = styled(FlexBox)`
  position: relative;
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  padding: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.xxl}`};
  align-items: center;


  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`};
  }
`;
const Cancel = styled(Button)`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  color: ${({theme, themeColor}) => themeColor || theme.colors.neutralsPalette.grey};
  border: 1px solid ${({theme, themeColor}) => themeColor || theme.colors.neutralsPalette.grey};
  box-shadow: none;

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    box-shadow: none;
    color: ${({theme}) => theme.colors.neutralsPalette.dark}
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    transform: none;
  }
`;

const Confirm = styled(Button)`
  background: ${({themeColor}) => themeColor};
  color: ${({theme}) => theme.colors.white};

  &:hover {
    background: ${({themeColor}) => themeColor};
  }
`;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 10;
`;

const Loader = styled(LoadingSpinner)`
  fill: ${({fill}) => fill};
`;


const SubmitModal = ({headerText, bodyText, history, confirmConfig, cancelConfig}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const {color} = confirmConfig;

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleConfirmClick = async (event) => {
    setLoading(true);
    confirmConfig.onConfirm && await confirmConfig.onConfirm(history);
  };

  const handleCancelClick = async (event) => {
    setLoading(true)
    cancelConfig.onCancel && await cancelConfig.onCancel(history);
  };

  return (
    <Container visible={visible}>
      <Wrapper>
        <Header>{headerText}</Header>
        <Body>{bodyText}</Body>
      </Wrapper>
      <ButtonWrapper>
        {loading &&
          <LoaderWrapper loading={loading}>
            <Loader size={24} fill={color}/>
          </LoaderWrapper>
        }
        <Cancel fixed themeColor={cancelConfig.color} onClick={handleCancelClick}>
          {cancelConfig.text}
        </Cancel>
        <Confirm fixed themeColor={confirmConfig.color} onClick={handleConfirmClick}>
          {confirmConfig.text}
        </Confirm>
      </ButtonWrapper>
    </Container>
  );
}

SubmitModal.propTypes = {
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  cancelConfig: PropTypes.shape({
    onCancel: PropTypes.func,
    text: PropTypes.string,
  }),
  confirmConfig: PropTypes.shape({
    onConfirm: PropTypes.func,
    text: PropTypes.string,
    color: PropTypes.string,
  }),
};
SubmitModal.defaultProps = {
  headerText: 'Nice work Michael! Ready to submit your quiz?',
  bodyText: 'You successfully answered every question. Now it\'s time to see how you did!',
  cancelConfig: {
    text: 'Not Yet',
  },
  confirmConfig: {
    text: 'Submit Now',
    color: 'blue',
  },
};

export default withRouter(SubmitModal);
