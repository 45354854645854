import React from 'react';

import {ReactComponent as Smile} from '../assets/smile.svg';
import {ReactComponent as Click} from '../assets/click.svg';
import {ReactComponent as Videos} from '../assets/videos.svg';

import {
  UpgradeCallout,
  UpgradeHeader,
  UpgradeSubHeader,
  UpgradeFeaturesTable,
  UpgradeFeaturesTableRow,
  UpgradeFeaturesTableRowIconContainer,
  UpgradeFeaturesTableRowText,
} from './shared';


export const inbdeFeatures = (
  <>
    <UpgradeCallout>
      <UpgradeHeader>
        Pass the INBDE. Guaranteed.
      </UpgradeHeader>
      <UpgradeSubHeader>
        Get instant access to everything below and more 👇
      </UpgradeSubHeader>
    </UpgradeCallout>
    <UpgradeFeaturesTable>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Smile/>
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          You’ll get <strong>2,500+ of the most representative practice questions available that cover everything you need to know</strong> and nothing you don’t.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Click />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          You’ll get our <strong>full simulation exam that simulates the look, feel, and content of the actual INBDE.</strong>
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Videos />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          You’ll get our <strong>premium mobile app to study on the go.</strong> Watch videos, answer questions from wherever.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
    </UpgradeFeaturesTable>
  </>
);

