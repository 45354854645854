import React, {useState} from 'react';

import {Auth} from 'aws-amplify';
import {useFormContext} from 'react-hook-form';
import {Redirect} from 'react-router-dom';

import {Link} from 'react-router-dom';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {Container, Header, SubHeader, Body, FieldGroup, SubmitContainer, SubmitButton, LinkButton} from '../../';


async function sendResetPasswordCode(values, callback) {
  const {username} = values;

  try {
    await Auth.forgotPassword(username);
    callback(username);
  } catch (error) {
    // attempt a lowercased version of username
    const secondTry = await Auth.forgotPassword(username.toLowerCase());

    // if second try is successful - proceed
    return secondTry?.CodeDeliveryDetails?.AttributeName
      ? callback(username.toLowerCase())
      // otherwise fail silently
      : false;
  }
}

const FormSubmit = ({children}) => {
  const {formState} = useFormContext();

  return (
    <SubmitContainer>
      <SubmitButton loading={formState.isSubmitting} disabled={!formState.isValid}>
        {children}
      </SubmitButton>
    </SubmitContainer>
  );
}

const ForgotPassword = () => {
  const [{redirecting, urlParams}, setRedirect] = useState({redirecting: false, urlParams: ''});

  if (redirecting) return <Redirect to={`/reset?username=${encodeURIComponent(urlParams)}`} />

  const redirect = (email) => setRedirect({redirecting: true, urlParams: email});

  return (
    <FormWrapper onSubmit={(values) => sendResetPasswordCode(values, redirect)}>
      <Container>
        <Body>
          <Header>Want to reset your password?</Header>
          <SubHeader>We'll send a reset code to your contact email</SubHeader>
          <FieldGroup children={<FieldRenderer config={FORGOT_PASSWORD_FORM_CONFIG} />}/>
          <LinkButton
            children={<Link to={`/signin`}>Go back to login</Link>}
          />
        </Body>
        <FormSubmit children={'Send Me A Code'}/>
      </Container>
    </FormWrapper>
  );
}


export default ForgotPassword;

const FORGOT_PASSWORD_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'Original email / username used to sign up (case sensitive)',
    name: 'username',
    inputProps: {
      type: 'email',
      autoComplete: 'username'
    }
  }
];
