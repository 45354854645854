import React from 'react';
import PropTypes from 'prop-types';

import {Menu} from '../Form';


function renderTestOptions(tags) {
  return [
    <option key={'tag-option-default'} hidden value={''}>Add a team tag</option>,
    <option key={'tag-option-default-1'} value={''} disabled>Add a team tag</option>,
    ...tags.map((tag, index) => (
      <option
        key={`tag-menu-${tag.id}-${index}`}
        value={JSON.stringify(tag)}>
        {tag.test}
      </option>
    ))
  ];
}


function renderTopicOptions(tags) {
  const qbTags = tags.filter(tag => tag.contentType === 'questionBank');
  const ptTags = tags.filter(tag => tag.contentType === 'practiceTest');
  const otherTags = tags.filter(tag => !['questionBank', 'practiceTest'].includes(tag.contentType));

  return [
    <option key={'tag-option-default'} hidden value={''}>Add a topic tag</option>,
    <option key={'tag-option-default-1'} value={''} disabled>Add a topic tag</option>,
    ...otherTags.map((tag, index) => (
        <option
          key={`tag-menu-${tag.id}-${index}`}
          value={JSON.stringify(tag)}>
          {tag.topic}
        </option>
      )),
    qbTags.length && <option key={'tag-option-default-2'} value={''} disabled>Question Bank</option>,
    ...qbTags.map((tag, index) => (
        <option
          key={`tag-menu-${tag.id}-${index}`}
          value={JSON.stringify(tag)}>
          {tag.topic}
        </option>
      )),
    ptTags.length && <option key={'tag-option-default-3'} value={''} disabled>Practice Test</option>,
    ...ptTags.map((tag, index) => (
        <option
          key={`tag-menu-${tag.id}-${index}`}
          value={JSON.stringify(tag)}>
          {tag.topic}
        </option>
      )),
  ];
}

function renderSubjectOptions(tags) {
  return [
    <option key={'tag-option-default'} hidden value={''}>Add a subject tag</option>,
    <option key={'tag-option-default-1'} value={''} disabled>Add a subject tag</option>,
    ...tags.map((tag, index) => (
      <option
        key={`tag-menu-${tag.id}-${index}`}
        value={JSON.stringify(tag)}>
        {tag.subject}
      </option>
    ))
  ];
}

function renderCategoryOptions(tags) {
  return [
    <option key={'tag-option-default'} hidden value={'{}'}>Add a category tag</option>,
    <option key={'tag-option-default-1'} value={''} disabled>Add a category tag</option>,
    ...tags.map((tag, index) => (
      <option
        key={`tag-menu-${tag.id}-${index}`}
        value={JSON.stringify(tag)}>
        {tag.topic}
      </option>
    ))
  ];
}

const renderers = {
  test: renderTestOptions,
  subject: renderSubjectOptions,
  topic: renderTopicOptions,
  category: renderCategoryOptions,
};

const TagMenu = ({type, tags, name, disabled, minWidth}) => {
  return (
    <Menu
      name={name}
      disabled={disabled}
      options={tags}
      renderOptions={(options) => renderers[type](options)}
      minWidth={minWidth}
    />
  );
}

TagMenu.propTypes = {
  type: PropTypes.oneOf(['test', 'subject', 'topic']),
  disabled: PropTypes.bool,
  tags: PropTypes.array,
  name: PropTypes.string,
  minWidth: PropTypes.number,
};

TagMenu.defaultProps = {
  type: 'test',
  disabled: false,
  tags: [],
  name: 'default',
  minWidth: 0,
};

export default TagMenu;
