import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  flex-flow: row wrap;
  white-space: nowrap;
  padding: 4px;
  background-color: ${({ theme }) => theme.lightGrey};
  border-radius: 10px;
`;
const BaseTag = styled.div`
  border-radius: 10px;
  color: white;
  margin: 2px;
  font-size: 10px;
  padding: 6px;
  font-weight: 500;
  letter-spacing: .2px;
  ${({color}) => {
    switch (color) {
      case 'blue':
        return css`
          background: #5096E4;
        `
      case 'purple':
        return css`
          background: #7869E1;
        `
      case 'melon':
        return css`
          background: #F49400;
        `
      case 'green':
        return css`
          background: #00A170;
        `
      case 'red':
        return css`
          background: #E45051;
        `
      case 'pink':
        return css`
          background: #BA5BC7;
        `
      case 'orange':
        return css`
          background: #FFBD68;
        `
      case 'grey':
        return css`
          background: #8F8F8F;
        `
      default:
        return css`
          background: #5096E4;
        `
    }
  }}
`;
const TestTag = styled(BaseTag)`
  background: #22A8F0;
`;
const SubjectTag = styled(BaseTag)`
  background: #3547F2;
`;
const TopicTag = styled(BaseTag)`
  background: #838EF5;
`;
const CategoryTag = styled(BaseTag)`
  background: #838EF5;
`;
const ExamTag = styled(BaseTag)`
  background-color: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkTextColor};
`;
const StatusTag = styled(BaseTag)`
  background-color: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkTextColor};
`;

const Tag = ({tag, className}) => {
  return (
    <Container className={className}>
      {tag.test && <TestTag>{tag.test}</TestTag>}
      {tag.subject && <SubjectTag>{tag.subject}</SubjectTag>}
      {tag.topic && <TopicTag>{tag.topic}</TopicTag>}
      {tag.category && <CategoryTag>{tag.category}</CategoryTag>}
      {tag.exam && <ExamTag>{tag.exam}</ExamTag>}
      {tag.status && <StatusTag>{tag.status}</StatusTag>}
    </Container>
  );
}

const TagLinksContainer = styled(Container)`
  flex: 1;
`;

export const TagLinks = ({tags}) => (
  <TagLinksContainer>
    {tags.map(tag => (
      <BaseTag key={tag.id} as={tag.as || Link} color={tag.color} to={tag.link}>
        {tag.title}
      </BaseTag>
    ))}
  </TagLinksContainer>
)

export const DefaultTag = ({tagName, className}) => (
  <Container className={className}>
    <BaseTag color={'green'} children={tagName}/>
  </Container>
)

export default Tag;
