import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  background: ${props => (props.checked ? '#4CD771' : '#EDF0F2')};
  border-radius: 3px;
  transition: all 100ms;
  padding: 10px;
  border-radius: 10px;
  transition: 200ms ease;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #EDF0F250;
  }

  ${Icon} {
    /* visibility: ${props => (props.checked ? 'visible' : 'hidden')} */
    stroke: ${props => (props.checked ? 'white' : '#6E7577')}
  }
  &:hover {
    background: ${({theme, checked}) => checked ? '#4CD77150' : theme.greyButtonHover};
    transition: 200ms ease;
  }


`

const Checkbox = ({ className, checked, innerRef, name, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox name={name} ref={innerRef} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  </label>
)

export default Checkbox
