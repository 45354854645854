import React from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import Chapter from '@bootcamp/web/src/bootcamps/components/Cards/Chapter';
import PracticeTest from '../../components/Cards/PracticeTest';
import Container from '../../pages/PageContainer';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {RoundButton, UpgradeButton} from '@bootcamp/web/src/components/UI';

import {Heading} from '../shared';
import {useContentTypeProgress} from '../../api/progress/contentType';
import {H2} from '@bootcamp/web/src/components/Typography';
import {Element as FAQElement} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';
import {getReadinessExamGrade} from '../../pages/CustomTestReview/components/Score';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;

const FAQHeader = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;

const faqItems = [
  {
    title: 'What are NCLEX® Bootcamp Readiness Exams?',
    text: "Readiness exams simulate the exact look, feel, and content of the real NCLEX®. At the end of each exam, you’ll get an accurate prediction of how likely you are to pass the NCLEX® and recommendations on how to improve your chances of passing.",
  },
  {
    title: 'How many questions are in each NCLEX® Bootcamp Readiness Exam?',
    text: 'Readiness exams include 100 unique NGN and traditional questions based on the NCLEX-RN test plan.'
  },
  {
    title: 'How long are the NCLEX® Bootcamp Readiness Exams?',
    text: "You will have 3 hours and 20 minutes to complete 100 questions, similar to the pace you should answer questions on the real NCLEX®."
  },
  {
    title: 'How accurate are the NCLEX® Bootcamp Readiness Exams?',
    text: `<span>We use statistical models to compare your performance against previous students who passed the NCLEX® to determine your readiness level. As shown in <a target="_blank" href="https://www.facebook.com/groups/nclexbootcampstudentgroup/posts/2390915884430064/">our Facebook study group</a>, many students have confirmed the Readiness Exams accurately predicted their NCLEX® results.</span>`
  },
  {
    title: 'When should I take my first NCLEX® Bootcamp Readiness Exam?',
    text: "We highly recommend taking a readiness exam at the start of your studying to see where your strengths and weaknesses are, and then practicing with the question bank to improve your weaker subjects. Take another readiness exam at the mid-point of your studying, and another one just before the NCLEX® to gauge your readiness."
  },
  {
    title: 'Can I pause a Readiness Exam and come back to it later?',
    text: "You cannot pause a Readiness Exam because it simulates the actual NCLEX® experience. You won't be able to pause during the actual NCLEX®."
  },
]

const NextGenReadinessExams = ({match, history, location}) => {
  const {isUpgraded} = useUserDataContext();

  const {config} = useBootcampConfigContext();

  const name = 'Readiness Exams';
  const filteredClassrooms = config?.classrooms?.find(({route}) => route.match('next-gen-readiness-exams'));

  const testsByClassroom = getTestsByClassroom([filteredClassrooms], [], [], true);
  const contentTypeProgress = useContentTypeProgress(filteredClassrooms.contentTypes[0], 'next-gen-readiness-exams');
  const {progress, counts} = contentTypeProgress?.['qbanks-next-gen-readiness-exams'] || {};

  function formatResultDetails(attempt) {
    return null;
  }

  function formatScoreText(score) {
    if (!score) return;
    const {gradeLabel} = getReadinessExamGrade(score.correct, score.total, 'nclex');
    return {score: gradeLabel, label: 'Chance'};
  }

  function formatScore(attempt) {
    const questionProgresses = attempt
      ?.map(({questionProgresses}) => questionProgresses?.items)
      ?.flat();

    const scoreDetails = questionProgresses?.reduce((acc, {answerState}) => {
      try {
        const {score, maxScore} = JSON.parse(answerState || "{}");
        return {...acc, score: acc.score + (score || 0), maxScore: acc.maxScore + (maxScore || 0)};
      } catch (error) {
        return acc;
      }
    }, {score: 0, maxScore: 0});

    return {correct: scoreDetails.score, total: scoreDetails.maxScore};
  }

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading
        name={name}
        description={'These readiness exams simulate the look, feel, and content of the real Next Gen NCLEX. Use them to gauge your readiness to pass the NCLEX!'}
        showEducators={false}
        />
      <Wrapper>
        {testsByClassroom['next-gen-readiness-exams'].map((test, index) => {
          const isLocked = !isUpgraded && index > 0;

          return (
            <PracticeTest
              route={`next-gen-readiness-exams/qbanks/${test.route}`}
              id={test.id}
              name={test.name}
              testReviewRoute={'next-gen-readiness-exams/review'}
              testRouteParam={`nclex/next-gen-readiness-exams/qbanks/readiness-exam-${index + 1}`}
              formatResultDetails={formatResultDetails}
              formatScoreText={formatScoreText}
              formatScore={formatScore}
              fullWidthProgress
              showTestResults
              startText={'Start Exam'}
              reviewText={'Review'}
              progress={progress && progress.byTest ? progress.byTest[test.id] : 'loading'}
              questionCount={counts ? counts[test?.id] : 'loading'}
              // actions={[!isLocked ? <RoundButton small children={'Start'}/> : <UpgradeButton size={'small'} round children={'Upgrade'}/>]}
              defaultLocked={isLocked}
              index={index+1}
            />
        )})}
      </Wrapper>
      <FAQHeader>FAQs</FAQHeader>
      {faqItems.map(({title, text}) => <FAQElement key={title} title={title} text={text} />)}
    </Container>
  );
}

NextGenReadinessExams.propTypes = {};
NextGenReadinessExams.defaultProps = {};

export default NextGenReadinessExams;
