import React, {useState, useRef, useMemo, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {Draggable, Droppable, DragDropContext} from 'react-beautiful-dnd';
import nanoid from 'nanoid';

import {AlertPopup} from '../../shared';
import {Prompt, SubmitButton, ScoreIconContainer, CorrectIcon, IncorrectIcon} from './shared';
import Highlightable from '../../../Highlightable';
import {useModalContext} from '../../../../contexts/Modal';


const Container = styled.div`
  position: relative;
  ${({answered}) => !answered && css`padding-bottom: 72px;`}
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const BowtieDropZoneContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  width: 100%;
`;
const AnswerPoolsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
const DropZoneGrouping = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SpacerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 24px;
  width: 24px;
  padding: 30px 0px;
  position: relative;
`;
const SpacerLine = styled.div`
  position: absolute;
  border-right: 2px solid #d8e6f5;
  z-index: -1;
  height: 68px;
  left: 11px;
  top: ${({position}) => ['leftTop', 'rightTop'].includes(position) ? '60px' : '120px'};
  transform: ${({position}) => ['leftTop', 'rightBottom'].includes(position) ? 'rotate(-20deg)' : 'rotate(20deg)'};
`;
const DragItem = styled.div`
  display: flex;
  border: 1px solid black;
  padding: 4px 6px;
  /* height: 63px; */
  border: 1px solid #d8e6f5;
  border-radius: 10px;
  margin-bottom: 12px;
  text-decoration: none;
  color: initial;

  &:hover {
    border: 1px solid #0000FF;
  }

  ${({asTable}) => asTable && css`
    border-radius: 0;
    margin-bottom: 4px;
    background: ${({color}) => color || '#d6e6f7'};
  `}
`;

const BowtieDragItem = styled(DragItem)`
  margin: -1px;
  align-items: center;
  /* height: 61px;
  border: ${({isDragging, isDraggingOver}) => isDragging || isDraggingOver ? '1px solid #d5d8d9' : 'none'}; */
  border-radius: 0px;
  flex: 1;
  background: ${({color}) => color};
`;

const DragHandle = styled.div`
  z-index: 1;
`;
const BowtieDragHandle = styled.div`
  display: flex;
  width: 100%;
`;
const BowtieDropContainer = styled.div`
  width: 100%;
`;
const DropContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:nth-child(2) {
    margin: 0 12px;
  }

  ${({asTable}) => asTable && css`
    border: 1px solid black;
  `}
`;
const BowtieDropWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 200px;
  border: 1px solid ${({isDraggingOver}) => isDraggingOver ? '#0000FF' : '#d5d8d9'};
  background: ${({color}) => color};
  justify-content: center;
  height: 63px;
  margin: 30px 0px;
  align-items: center;
  color: #808080;

  &:hover {
    border: 1px solid blue;
  }
`;
const DropWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({asTable}) => asTable && css`
    padding: 12px 6px 4px 6px;
  `}
`;
const ListHeader = styled.div`
  ${({asTable}) => asTable && css`
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid black;
    padding: ${({theme}) => theme.layouts.spacing.s};
    background: ${({color}) => color || '#d6e6f7'};
  `}
`;
const LimitPlaceholder = styled.div`
  flex: 1;
  background: ${({visible}) => visible ? '#d6e6f7' : 'none'};
  margin-bottom: 4px;
`;

const Spacer = ({position}) => (
  <SpacerContainer>
    <SpacerLine position={`${position}Top`}/>
    <SpacerLine position={`${position}Bottom`}/>
  </SpacerContainer>
);


const DropZone = ({answered, droppableId, list, header, asTable, limit, color, onlyShowCorrectScoreIcon}) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={limit && limit === list.length}>
      {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
        <DropContainer asTable={asTable}>
          <ListHeader asTable={asTable}>{header}</ListHeader>
          <DropWrapper ref={innerRef} asTable={asTable} isDraggingOver={isDraggingOver} {...droppableProps}>
            {list.map(([id, text, correct], index) => (
              <Draggable key={`draggable-${droppableId}-${id}`} draggableId={id} index={index} isDragDisabled={answered}>
                {({ innerRef, draggableProps, dragHandleProps }) => (
                  <DragHandle ref={innerRef} {...draggableProps} {...dragHandleProps}>
                    <DragItem key={`drag-item-${droppableId}-${id}`} color={color} asTable={asTable}>
                      {answered &&
                        <ScoreIconContainer hide={onlyShowCorrectScoreIcon && !correct} correct={correct}>
                          {correct ? <CorrectIcon /> : <IncorrectIcon />}
                        </ScoreIconContainer>
                      }
                      <span dangerouslySetInnerHTML={{ __html: text }}></span>
                    </DragItem>
                  </DragHandle>
                )}
              </Draggable>
            ))}
            {limit && !isDraggingOver && Array.from(new Array(Math.max(limit - list.length, 0))).map((item, index) => <LimitPlaceholder key={`limit-placeholder-${index}`} visible={index < limit}/>)}
            {/* {placeholder} */}
          </DropWrapper>
        </DropContainer>
      )}
    </Droppable>
  );
}

const DropTarget = ({answered, droppableId, item, placeholderText, color}) => {
  const [id, text, correct] = item || [];

  return (
    <Droppable droppableId={droppableId} isDropDisabled={answered}>
      {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
        <BowtieDropContainer>
          <BowtieDropWrapper ref={innerRef} isDraggingOver={isDraggingOver} {...droppableProps} color={color}>
            {!!item?.length ?
              <Draggable key={`draggable-${droppableId}-${id}`} draggableId={`draggable-${droppableId}-${id}`} index={0} isDragDisabled={answered}>
                {({ innerRef, draggableProps, dragHandleProps }, {isDragging}) => (
                  <BowtieDragHandle ref={innerRef} {...draggableProps} {...dragHandleProps}>
                    <BowtieDragItem key={`drag-item-${droppableId}-${id}`} isDragging={isDragging} isDraggingOver={isDraggingOver} color={color}>
                      {answered &&
                        <ScoreIconContainer correct={correct}>
                          {correct ? <CorrectIcon/> : <IncorrectIcon/>}
                        </ScoreIconContainer>
                      }
                      <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </BowtieDragItem>
                  </BowtieDragHandle>
                )}
              </Draggable>
              : placeholderText
            }
            {/* {placeholder} */}
          </BowtieDropWrapper>
        </BowtieDropContainer>
      )}
    </Droppable>
  );
}

const BowtieDropZone = ({answered, selections}) => (
  <BowtieDropZoneContainer>
    <DropZoneGrouping>
      <DropTarget
        droppableId={'drop-zone-0-0'}
        item={selections['drop-zone-0-0'] || []}
        placeholderText={'Action to Take'}
        color={'#EEF6F4'}
        answered={answered}
      />
      <DropTarget
        droppableId={'drop-zone-1-0'}
        item={selections['drop-zone-1-0'] || []}
        placeholderText={'Action to Take'}
        color={'#EEF6F4'}
        answered={answered}
      />
    </DropZoneGrouping>
    <Spacer position={'left'}/>
    <DropZoneGrouping>
      <DropTarget
        droppableId={'drop-zone-2-1'}
        item={selections['drop-zone-2-1'] || []}
        placeholderText={'Potential Condition'}
        color={'#C3E5EF'}
        answered={answered}
      />
    </DropZoneGrouping>
    <Spacer position={'right'}/>
    <DropZoneGrouping>
      <DropTarget
        droppableId={'drop-zone-3-2'}
        item={selections['drop-zone-3-2'] || []}
        placeholderText={'Parameter to Monitor'}
        color={'#eceff3'}
        answered={answered}
      />
      <DropTarget
        droppableId={'drop-zone-4-2'}
        item={selections['drop-zone-4-2'] || []}
        placeholderText={'Parameter to Monitor'}
        color={'#eceff3'}
        answered={answered}
      />
    </DropZoneGrouping>
  </BowtieDropZoneContainer>
);

const AnswerPools = ({answered, answerPools, selections}) => (
  <AnswerPoolsContainer>
    <DropZone
      droppableId={'answer-pool-0'}
      list={answerPools[0].filter(([id, value]) => !Object.values(selections).find(([targetId, targetValue]) => (targetId === id) || value === targetValue))}
      asTable
      header={'Actions to Take'}
      color={'#EEF6F4'}
      answered={answered}
      onlyShowCorrectScoreIcon
      showScoreIcon
    />
    <DropZone
      droppableId={'answer-pool-1'}
      list={answerPools[1].filter(([id, value]) => !Object.values(selections).find(([targetId, targetValue]) => (targetId === id) || value === targetValue))}
      asTable
      header={'Potential Conditions'}
      color={'#C3E5EF'}
      answered={answered}
      onlyShowCorrectScoreIcon
      showScoreIcon
    />
    <DropZone
      droppableId={'answer-pool-2'}
      list={answerPools[2].filter(([id, value]) => !Object.values(selections).find(([targetId, targetValue]) => (targetId === id) || value === targetValue))}
      asTable
      header={'Parameters to Monitor'}
      color={'#EDEFF3'}
      answered={answered}
      onlyShowCorrectScoreIcon
      showScoreIcon
    />
  </AnswerPoolsContainer>
);

const Bowtie = ({
  prompt,
  setHighlights,
  highlights,
  enableHighlighting,
  questionIndex,
  didSubmitAnswer,
  alwaysShowingExplanation,
  answers,
  questionHeader,
  answerState,
  setAnswerState,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
  showAnswerBox,
  type,
  answerGroups,
  checkOnClick,
  tutorMode,
}) => {

  const [selections, setSelections] = useState({});

  const answerPools = useMemo(() => answerGroups?.map(group => JSON.parse(group.contents)?.map(answer => [nanoid(), ...answer])), []);

  const promptRef = useRef();

  const {modalDispatch} = useModalContext();

  function handleCheck(props) {
    if (Object.keys(answerState).length !== 5) {
      return modalDispatch({
        type: 'open',
        modalContainerStyle: theme => `
          background: transparent;
        `,
        component: ({close}) => {
          return (
            <AlertPopup
              close={close}
              headerContent={"Question Incomplete"}
              alertText={"You must respond to all rows before submitting."}
              buttonConfig={[
                {
                  children: 'Return to Question',
                  onClick: close
                }
              ]}
            />
          )
        },
        enableClickClose: false,
      })
    } else {
      checkOnClick(props);
    }
  }

  function onDragEnd({source, destination, draggableId}) {
    if (!destination || didSubmitAnswer || alwaysShowingExplanation) return;

    const sourceIndex = source.droppableId.split('-').slice(-1)[0];
    const destinationIndex = destination.droppableId.split('-').slice(-1)[0];

    // bail if we're attempting to drag to dissimilar groups (target group index needs to match pool group index)
    if (sourceIndex !== destinationIndex) return;

    // otherwise handle the drag interaction
    if (source.droppableId.match('answer-pool') && destination.droppableId.match('zone')) {
      // handling drag from answer pool to target
      setSelections({...selections, [destination.droppableId]: answerPools[sourceIndex].find(([id]) => id === draggableId)});
    } else if (source.droppableId.match('zone') && destination.droppableId.match('zone')) {
      // handling drag from target to target
      // delete from source
      const updatedSelections = {...selections};
      delete updatedSelections[source.droppableId];

      // add to destination
      updatedSelections[destination.droppableId] = selections[source.droppableId];

      setSelections(updatedSelections);
    } else {
      // handling drag from target to answers
      const updatedSelections = {...selections};
      delete updatedSelections[source.droppableId]
      setSelections(updatedSelections);
    }
  }

  useEffect(() => {
    setAnswerState(selections);
  }, [selections]);
  useEffect(() => {
    setSelections(answerState);
  }, []);
  return (
    <Container answered={didSubmitAnswer || alwaysShowingExplanation}>
      <Highlightable
        type={'stem'}
        htmlString={prompt}
        setHighlights={setHighlights}
        highlights={highlights}
        disable={!enableHighlighting}>
        {questionHeader && (
          <Prompt
            className={'fr-view'}
            dangerouslySetInnerHTML={{__html: questionHeader}}
            hideArrow
          />
        )}
        <Prompt
          ref={promptRef}
          className={'fr-view'}
          dangerouslySetInnerHTML={{ __html: prompt }}
          answered={didSubmitAnswer || alwaysShowingExplanation}
        />
      </Highlightable>
      <Wrapper>
      <DragDropContext onDragEnd={onDragEnd} isDragDisabled={didSubmitAnswer || alwaysShowingExplanation}>
        <BowtieDropZone selections={selections} answered={didSubmitAnswer || alwaysShowingExplanation}/>
        <AnswerPools answerPools={answerPools} selections={selections} answered={didSubmitAnswer || alwaysShowingExplanation}/>
      </DragDropContext>
      </Wrapper>
      {!didSubmitAnswer && tutorMode && !alwaysShowingExplanation && (
        <SubmitButton onClick={handleCheck}/>
      )}
    </Container>
  );
}

Bowtie.defaultProps = {};
Bowtie.propTypes = {};

export default Bowtie;