import React from 'react';
import styled from 'styled-components';
import useDocumentTitle from '@rehooks/document-title';

import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import Container from '@bootcamp/web/src/bootcamps/pages/PageContainer';
import Overview from '@bootcamp/web/src/bootcamps/components/Cards/Overview';
import Breadcrumbs from '@bootcamp/web/src/components/NavBar/components/Breadcrumbs';
import Download from '@bootcamp/web/src/bootcamps/components/Cards/Download';

import {DownloadButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';
import theme from '@bootcamp/shared/src/styles/theme';


import Callout from '@bootcamp/web/src/bootcamps/components/Cards/Callout';
import {Info} from '@styled-icons/fluentui-system-filled/Info';

import {items} from '@bootcamp/shared/src/config/nclexCheatSheets';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const StyledOverview = styled(Overview)`
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  margin-bottom: 16px;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin: auto;
`
const CheatSheets = ({history}) => {
  const {config} = useBootcampConfigContext();
  const {isUpgraded, bootcamp} = useUserDataContext();
  useDocumentTitle(`${config.meta.siteTitle} | Cheat Sheets`);

  const gatesUp = !isUpgraded && bootcamp === 'inbde';
  return (
    <Container>
      <StyledBreadcrumbs history={history} lockAtDepth={2} />
      <StyledOverview
        subTitle={'NCLEX Cheat Sheets'}
        body={"Everything you need to pass the NCLEX®, and nothing you don't. NCLEX Bootcamp's Cheat Sheets condense everything you need to know about a topic into a single, digestible resource."}
        title={'Classroom'}
        showProgress={false}
      />
      <Callout
        icon={<Info size={20} color={'white'} style={{marginRight: 8}} />}
      >
        {`<strong>More coming soon!</strong> Cheat Sheets will be released as they become available.`}
      </Callout>

      {(gatesUp ? items.sort((a, b) => a.freeAccess ? -1 : 1) : items).map((item, index) => (
        <Download
          key={`${item.name}-${index}`}
          name={item.name}
          description={item.description}
          themePalette={theme.colors.brandPalette.royal}
          locked={gatesUp && !item.freeAccess}
          onClick={() => window.open(item.route, '_blank')}
          icon={<Icon src={item.icon}/>}
          actions={[
            <DownloadButton />
          ]}
        />
      ))}
    </Container>
  );
}

export default CheatSheets;
