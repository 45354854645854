import React, {useEffect, useState, useRef, useMemo} from 'react';
import ReactDOM from 'react-dom';
import styled, {css} from 'styled-components';
import {Draggable, Droppable, DragDropContext} from 'react-beautiful-dnd';
import nanoid from 'nanoid';

import {Prompt, SubmitButton, ScoreIconContainer, CorrectIcon, IncorrectIcon} from './shared';
import Highlightable from '@bootcamp/web/src/components/Highlightable';

import {reorder} from '@bootcamp/shared/src/util';
import {firstBy} from 'thenby';

import arrow from '../assets/arrow-button.png';
import arrowActive from '../assets/arrow-button-active.png';


const Container = styled.div`
  position: relative;
  ${({answered}) => !answered && css`padding-bottom: 72px;`}
  .dnd-input-container {
    display: inline-block;
    margin: 2px;
    min-width: 250px;
    vertical-align: middle;
  }

  .dnd-input {
    background: ${({theme}) => theme.colors.neutralsPalette.nclex.lightBlue} !important;
    font-size: 0 !important;
    padding: 10px;
    border: none;
    width: 100%;
    height: 36px;
  }
`;
const Wrapper = styled.div`
  display: flex;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
    gap: ${({theme}) => theme.layouts.spacing.l};
  }
`;
const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 66px;
  padding: 2px;
`;
const DragItem = styled.div`
  display: flex;
  text-decoration: none;
  color: inherit;
  padding: 5px 10px;

  ${({asTable}) => !asTable && css`
    border-radius: 25px;
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.dark};
    background: white;
  `}

  ${({asTable}) => asTable && css`
    border-radius: 0;
    margin-bottom: 4px;
    background: ${({color, theme}) => color || theme.colors.neutralsPalette.nclex.lightBlue};
  `}
`;
const ArrowButton = styled.button`
  background-image: url(${({active}) => active ? arrowActive : arrow});
  width: 23px;
  height: 23px;
  outline: none;
  margin: none;
  border: none;
  border-radius: 23px;
  transform: ${({direction}) => direction === 'left'
    ? 'rotate(180deg)'
    : direction === 'right'
    ? 'rotate(0deg)'
    : direction === 'up'
    ? 'rotate(270deg)'
    : 'rotate(90deg)'
  };
`;

const ClozeDragItem = styled(DragItem)`
  align-items: center;
  border: none;
  padding: 0 6px;
  height: 100%;
`;
const DragItemWrapper = styled.div`
  background: ${({active}) => active ? '#dff0d8' : 'none'};
  ${({asTable}) => asTable && css`
    flex: 0;
    border-bottom: none;
  `}
`;
const DragHandle = styled.div`
  ${({isDragging}) => isDragging && css`
    p {
      margin: 0 !important;
    }
  `}
  z-index: 1;
  flex: 1;
  margin-bottom: -1px;
  height: 100%;

  ${({asTable}) => !asTable && css`
    border-bottom: ${({theme, isDragging}) => !isDragging ? `1px solid ${theme.colors.neutralsPalette.nclex.light}` : 'none'};
    padding: 10px 15px;
  `}
`;

const ClozeDropContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const DropContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${({height}) => height ? `${height}px` : 'auto'};
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
  ${({asTable}) => asTable && css`
    border: 1px solid black;
    max-width: 500px;
  `}

  &:first-child {
    margin-right: ${({theme, withButtons}) => withButtons ? 0 : theme.layouts.spacing.l};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const DropAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 280px;
`;

const ClozeDropWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({isDraggingOver}) => isDraggingOver ? 'lightgrey' : 'none'};
`;

const DropWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({asTable}) => asTable && css`
    padding: 8px;
  `}

  ${({asTable}) => !asTable && css`
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.light};
  `}
`;

const ListHeader = styled.div`
  ${({asTable}) => !asTable && css`
    padding: 12px 0px 24px 0px;
    text-align: center;
  `}

  ${({asTable}) => asTable && css`
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid black;
    padding: ${({theme}) => theme.layouts.spacing.l};
    background: ${({color, theme}) => color || theme.colors.neutralsPalette.nclex.lightBlue};
  `}
`;

const LimitPlaceholder = styled.div`
  flex: 1;
  background: ${({visible, theme}) => visible ?  theme.colors.neutralsPalette.nclex.lightBlue : 'none'};
  margin-bottom: 4px;
  max-height: 61px;
`;
const CorrectResponse = styled.div`
  span {
    color: ${({theme}) => theme.colors.interfacePalette.green.default};
  }
`;
const IncorrectResponse = styled.div`
  span {
    color: ${({theme}) => theme.colors.interfacePalette.red.default};
  }
`;

// QUESTIONS
// do table headers for SATA & Select N need to be dynamic or are they always the same?

// here we need to update the logic and styling a bit depending on the type

// ORDERED RESPONSE - we need to be able to drag from one side to the other and reorder within each list (which is more or less what we have now) ** ORDER MATTERS

// SATA - need to be able to drag from one side to the other / order doesn't matter (pretty much what we have now)

// SELECT N - drag is disabled after limit is filled, this can be derived from the number of correct answers given the dnd select n question type

const DropZone = ({answered, droppableId, list, header, asTable, limit, color, renderButtons, onTargetClick, selectedTarget, showScoreIcon, onlyShowCorrectScoreIcon, controlHeight}) => {
  const [initialHeight, setInitialHeight] = useState(null);
  const containerRef = useRef();

  useEffect(() => {
    if (initialHeight || !containerRef.current || !controlHeight) return;
    setInitialHeight(containerRef?.current?.clientHeight);
  }, [containerRef.current])

  return (
    <Droppable droppableId={droppableId} isDropDisabled={(limit && limit === list.length)}>
      {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
        <DropContainer ref={containerRef} asTable={asTable} withButtons={!!renderButtons} height={initialHeight}>
          <ButtonWrapper>
            <DropAreaWrapper>
              <ListHeader asTable={asTable} color={color}>{header}</ListHeader>
              <DropWrapper ref={innerRef} asTable={asTable} isDraggingOver={isDraggingOver} {...droppableProps}>
                {list?.map(([id, text, correct], index) => (
                  <Draggable key={`draggable-${droppableId}-${id}`} draggableId={id} index={index} isDragDisabled={answered}>
                    {({ innerRef, draggableProps, dragHandleProps }, { isDragging, draggingOver }) => (
                      <DragItemWrapper isDragging={isDragging} isDraggingOver={draggingOver} asTable={asTable} onClick={() => !answered && typeof onTargetClick === 'function' && onTargetClick(id)} active={selectedTarget === id}>
                        <DragHandle ref={innerRef} asTable={asTable} isDragging={isDragging} {...draggableProps} {...dragHandleProps}>
                          <DragItem key={`drag-item-${droppableId}-${id}`} color={color} asTable={asTable}>
                            {answered && showScoreIcon &&
                              <ScoreIconContainer hide={onlyShowCorrectScoreIcon && !correct} correct={correct}>
                                {correct ? <CorrectIcon/> : <IncorrectIcon/>}
                              </ScoreIconContainer>
                            }
                            <span dangerouslySetInnerHTML={{ __html: text }}></span>
                          </DragItem>
                        </DragHandle>
                      </DragItemWrapper>
                    )}
                  </Draggable>
                ))}
                {!!limit && !isDraggingOver && Array.from(new Array(Math.max(limit - list.length, 0))).map((item, index) => <LimitPlaceholder key={`limit-placeholder-${index}`} visible={index < limit} />)}
                {placeholder}
              </DropWrapper>
            </DropAreaWrapper>
            {renderButtons && renderButtons()}
          </ButtonWrapper>
        </DropContainer>
      )}
    </Droppable>
  );
}

const DropTarget = ({droppableId, item, answered, onlyShowCorrectScoreIcon, orderMatters}) => {
  const [id, text, correct, targetId] = item || [];
  const targetIndex = targetId?.split('-')?.slice(-1)[0];
  const droppableIndex = droppableId?.split('-')?.slice(-2)[0];

  const isCorrect = orderMatters ? correct && targetIndex === droppableIndex : correct;
  return (
    <Droppable droppableId={droppableId}>
      {({ innerRef, droppableProps, placeholder }, { isDraggingOver, ...rest}) =>(
        <ClozeDropContainer>
          <ClozeDropWrapper ref={innerRef} isDraggingOver={isDraggingOver} {...droppableProps}>
            {item &&
              <Draggable key={`draggable-${droppableId}-${id}`} draggableId={`draggable-${droppableId}-${id}`} index={0} isDragDisabled={answered}>
                {({ innerRef, draggableProps, dragHandleProps }) => (
                  <DragHandle ref={innerRef} asTable {...draggableProps} {...dragHandleProps}>
                    <ClozeDragItem asTable key={`drag-item-${droppableId}-${id}`} isDraggingOver={isDraggingOver}>
                      {answered &&
                        <ScoreIconContainer hide={onlyShowCorrectScoreIcon && !correct} correct={isCorrect}>
                          {isCorrect ? <CorrectIcon/> : <IncorrectIcon/>}
                        </ScoreIconContainer>
                      }
                      <span  dangerouslySetInnerHTML={{ __html: text }}></span>
                    </ClozeDragItem>
                  </DragHandle>
                )}
              </Draggable>}
              {placeholder}
          </ClozeDropWrapper>
        </ClozeDropContainer>
        )}
    </Droppable>
  );
}

const PromptDragTargets = ({promptRef, selections, answered}) => {
  const [targets, setTargets] = useState([]);

  useEffect(() => {

    if (!promptRef.current) return;

    const dragTargets = promptRef.current.querySelectorAll('[data-drag-target=""]');

    setTargets(Array.from(dragTargets));

  }, [promptRef.current]);

  return targets.map((target, index) => {
    const groupIndex = Object.keys(
      Object
        .assign({}, target?.dataset))
        .find(key => key.match('dragTarget-'))
        .slice(-1)[0]



    const droppableId = `drag-target-${index}${groupIndex ? `-${groupIndex}` : ''}`;
    return ReactDOM.createPortal(<DropTarget droppableId={droppableId} item={selections[droppableId]} answered={answered}/>, target)
  });
}

const DndDefault = ({
  answers,
  answered,
  headerLeft,
  headerRight,
  dropLimit,
  asTable=false,
  setAnswerState,
  answerState,
  withButtons=true,
  answeredAnswersLeft,
  answeredAnswersRight,
  showScoreIcon,
  controlHeight
}) => {
  const [selectedTarget, setSelectedTarget] = useState(null);

  const [answersLeft, setAnswersLeft] = useState(answers?.map(answer => [nanoid(), ...answer]).filter(answer => !answerState.map(([_, answer]) => answer).includes(answer[1])));
  const [answersRight, setAnswersRight] = useState(answerState || []);

  function onDragEnd({source, destination}) {
    if (!destination) return;

    setSelectedTarget(null);

    if (source.droppableId === destination.droppableId) {
      const [answers, setter] = source.droppableId.match('left')
        ? [answersLeft, setAnswersLeft]
        : [answersRight, setAnswersRight];

      const reorderedBlocks = reorder(
        answers,
        source.index,
        destination.index
      );

      setter(reorderedBlocks);
    } else {

      const [to, setTo, from, setFrom] = destination.droppableId.match('right')
        ? [answersRight, setAnswersRight, answersLeft, setAnswersLeft]
        : [answersLeft, setAnswersLeft, answersRight, setAnswersRight];

      const newAnswersTo = [...to];
      newAnswersTo.splice(destination.index, 0, from[source.index]);

      const newAnswersFrom = from.filter((answer, index) => index !== source.index);

      setTo(newAnswersTo);
      setFrom(newAnswersFrom);
    }
  }

  function onTargetClick(id) {
    setSelectedTarget(id);
  }

  function onLeftRightClick(direction) {
    if (!selectedTarget || answered) return;

    const [to, setTo, from, setFrom] = direction === 'left'
      ? [answersLeft, setAnswersLeft, answersRight, setAnswersRight]
      : [answersRight, setAnswersRight, answersLeft, setAnswersLeft]

    const target = from.find(([id]) => id === selectedTarget);
    const filtered = from.filter(([id]) => id !== selectedTarget);

    if (!target) return;

    setFrom(filtered);
    setTo([...to, target]);
    setSelectedTarget(null);
  }

  function onUpDownClick(direction) {
    if (!selectedTarget || answered) return;

    const targetIndex = answersRight.findIndex(([id]) => id === selectedTarget);

    if (targetIndex === -1) return;

    const newIndex = direction === 'up' ? targetIndex - 1 : targetIndex + 1;

    if (newIndex < 0) return;

    const reordered = reorder(answersRight, targetIndex, newIndex);

    setAnswersRight(reordered);
  }

  function isButtonActive(direction) {
    if (answered) return;

    if (direction === 'left') {
      return answersRight.find(([id]) => id === selectedTarget)
    } else if (direction === 'right') {
      return answersLeft.find(([id]) => id === selectedTarget)
    } else if (direction === 'up') {
      return answersRight.findIndex(([id]) => id === selectedTarget) > 0
    } else if (direction === 'down') {
      const index = answersRight.findIndex(([id]) => id === selectedTarget);
      return index >= 0 && index < answersRight.length - 1;
    }
  }

  function renderButtonArea(directions=[], onClick) {
    return (
      <ButtonArea>
        {directions.map((direction, index) => (
          <ArrowButton
            key={`${direction}-${index}`}
            direction={direction}
            active={isButtonActive(direction)}
            onClick={() => onClick(direction)}>
          </ArrowButton>
        ))}
      </ButtonArea>
    );
  }

  useEffect(() => {
    answered && selectedTarget && setSelectedTarget(null);
  }, [answered])

  useEffect(() => {
    setAnswerState(answersRight);
  }, [answersRight]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={() => onTargetClick(null)}>
      <DropZone
        asTable={asTable}
        droppableId={'answer-left'}
        list={answered ? (answeredAnswersLeft || answersLeft) : answersLeft}
        header={headerLeft}
        onTargetClick={onTargetClick}
        renderButtons={withButtons ? () => renderButtonArea(['left', 'right'], onLeftRightClick) : null}
        selectedTarget={selectedTarget}
        answered={answered}
        showScoreIcon={showScoreIcon}
        onlyShowCorrectScoreIcon
        controlHeight={controlHeight}
      />
      <DropZone
        asTable={asTable}
        droppableId={'answers-right'}
        list={answered ? (answeredAnswersRight || answersRight) : answersRight}
        header={headerRight}
        limit={dropLimit}
        total={answersLeft.length}
        onTargetClick={onTargetClick}
        renderButtons={withButtons ? () => renderButtonArea(['up', 'down'], onUpDownClick) : null}
        selectedTarget={selectedTarget}
        answered={answered}
        showScoreIcon={showScoreIcon}
        controlHeight={controlHeight}
      />
    </DragDropContext>
  );

};

const Cloze = ({answers, answered, promptRef, setAnswerState, answerState}) => {
  const [clozeSelections, setClozeSelections] = useState(answerState || {});
  const header = 'Word Choices';

  const answerPool = useMemo(() => answers?.map(answer => [nanoid(), ...answer]), []);
  function onDragEnd({source, destination, draggableId}) {
    if (!destination) return;

    let updatedSelections = {...clozeSelections};
    if (source.droppableId.match('answer-pool') && destination.droppableId.match('target')) {
      // handling drag from answers to target
      updatedSelections[destination.droppableId] = answerPool.find(([id]) => id === draggableId)
      setClozeSelections(updatedSelections);

    } else if (source.droppableId.match('target') && destination.droppableId.match('target')) {
      // handling drag from target to target
      // delete from source
      delete updatedSelections[source.droppableId];

      // add to destination
      updatedSelections[destination.droppableId] = clozeSelections[source.droppableId];

      setClozeSelections(updatedSelections);
    } else {
      // handling drag from target to answers
      delete updatedSelections[source.droppableId]
      setClozeSelections(updatedSelections);
    }
  }
  useEffect(() => {
    setAnswerState(clozeSelections);
  }, [clozeSelections]);

  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      <DropZone
        asTable
        droppableId={'answer-pool'}
        list={answerPool.filter(([id, answer]) => !Object.values(clozeSelections).find(([targetId, targetAnswer]) => targetId === id || answer === targetAnswer))}
        header={header}
        answered={answered}
        showScoreIcon
      />
      <PromptDragTargets promptRef={promptRef} selections={clozeSelections} answered={answered}/>
    </DragDropContext>
  );

};

const onBeforeCapture = (beforeCapture) => {
  const draggableElement = document.querySelectorAll(`[data-rbd-draggable-id='${beforeCapture?.draggableId}']`)?.[0];
  if (!draggableElement) return;
  draggableElement.style.width = '250px';
  draggableElement.style.height = '36px';
};

const Rationale = ({answerGroups, answered,  promptRef, headerLeft, headerRight, setAnswerState, answerState}) => {
  const [rationaleSelections, setRationaleSelections] = useState(answerState || {});
  const answerPools = useMemo(() => answerGroups?.map(({contents}) => JSON.parse(contents).map(answer => [nanoid(), ...answer])), []);
  const [poolHeaderLeft, poolHeaderRight] = [headerLeft || 'Condition', headerRight || 'Cause'];

  function onDragEnd({source, destination, draggableId}) {
    if (!destination) return;

    const sourceIndex = source.droppableId.split('-').slice(-1)[0];
    const destinationIndex = destination.droppableId.split('-').slice(-1)[0];

    // bail if we're attempting to drag to dissimilar groups (target group index needs to match pool group index)
    if (sourceIndex !== destinationIndex) return;
    let updatedSelections = {...rationaleSelections};
    // otherwise handle the drag interaction
    if (source.droppableId.match('answer-pool') && destination.droppableId.match('target')) {
      // handling drag from answer pool to target
      updatedSelections[destination.droppableId] = answerPools[sourceIndex].find(([id]) => id === draggableId);
      setRationaleSelections(updatedSelections);
    } else if (source.droppableId.match('target') && destination.droppableId.match('target')) {
      // handling drag from target to target
      // delete from source
      delete updatedSelections[source.droppableId];

      // add to destination
      updatedSelections[destination.droppableId] = rationaleSelections[source.droppableId];

      setRationaleSelections(updatedSelections);
    } else {
      // handling drag from target to answers
      delete updatedSelections[source.droppableId]
      setRationaleSelections(updatedSelections);
    }
  }
  useEffect(() => {
    setAnswerState(rationaleSelections);
  }, [rationaleSelections]);

  return (
    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd}>
      <DropZone
        asTable
        droppableId={'answer-pool-0'}
        list={answerPools?.[0]?.filter(([id, answer]) => !Object.values(rationaleSelections).find(([targetId, targetAnswer]) => targetId === id || answer === targetAnswer))}
        header={poolHeaderLeft}
        onlyShowCorrectScoreIcon
        answered={answered}
        showScoreIcon
      />
      <DropZone
        asTable
        droppableId={'answer-pool-1'}
        list={answerPools?.[1]?.filter(([id, answer]) => !Object.values(rationaleSelections).find(([targetId, targetAnswer]) => targetId === id || answer === targetAnswer))}
        header={poolHeaderRight}
        onlyShowCorrectScoreIcon
        answered={answered}
        showScoreIcon
      />
      <PromptDragTargets promptRef={promptRef} selections={rationaleSelections} answered={answered} />
    </DragDropContext>

  );

};

const OrderedResponse = ({answers, answerState, headerLeft, headerRight, ...props}) => {
  // NOTE we'll probably need to pass up the ordering of the answers to the scoring handler
  const orderedAnswers = [...answers]?.sort(firstBy(([a, b, index]) => index));
  const answeredCorrectly = answers?.every(([a, b, orderIndex], index) => orderIndex === answerState?.[index]?.[3]);

  const [headerL, headerR] = props.answered && answeredCorrectly
    ? [
        'Unordered Options',
        <CorrectResponse>Your Response / <span>Correct Response</span></CorrectResponse>
      ]
    : props.answered && !answeredCorrectly
    ? [
        <IncorrectResponse>Your Response / <span>Incorrect Response</span></IncorrectResponse>,
        <CorrectResponse><span>Correct Response</span></CorrectResponse>
      ]
    : [
        'Unordered Response',
        'Ordered Response'
      ];

  const [answeredAnswersLeft, answeredAnswersRight] = answeredCorrectly
    ? [
      orderedAnswers?.map((answer, index) => [`${index}`, ...answer]),
      answerState,
    ]
    : [
      answerState,
      answers?.map((answer, index) => [`${index}`, ...answer]),
    ];

  return (
    <DndDefault
      answers={orderedAnswers}
      headerLeft={headerL}
      headerRight={headerR}
      answerState={answerState}
      answeredAnswersLeft={answeredAnswersLeft}
      answeredAnswersRight={answeredAnswersRight}
      controlHeight
      {...props}
    />
  );
};

const SelectN = props => {
  const dropLimit = props?.answers?.filter(([text, correct]) => !!correct).length;
  const [headerLeft, headerRight] = [props.headerLeft || 'Client Findings', props.headerRight || `Top ${dropLimit} Findings`];

  return (
    <DndDefault
      {...props}
      dropLimit={dropLimit}
      headerLeft={headerLeft}
      headerRight={headerRight}
      withButtons={false}
      showScoreIcon
      controlHeight
      asTable
    />
  );
};

const SATA = props => {
  return (
    <DndDefault
      {...props}
      withButtons={false}
      showScoreIcon
      asTable
      controlHeight
    />
  );
};


const componentMap = {
  dragAndDropCloze: Cloze,
  dragAndDropRationale: Rationale,
  dragAndDropSelectN: SelectN,
  dragAndDropSATA: SATA,
  dragAndDropOrderedResponse: OrderedResponse,
};

const DragAndDrop = ({
  prompt,
  setHighlights,
  highlights,
  enableHighlighting,
  questionIndex,
  didSubmitAnswer,
  alwaysShowingExplanation,
  answers,
  questionHeader,
  answerState,
  setAnswerState,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
  showAnswerBox,
  type,
  answerGroups,
  dndHeaderLeft,
  dndHeaderRight,
  checkOnClick,
  tutorMode
}) => {

  const promptRef = useRef();

  const DragComponent = componentMap[type];

  return (
    <Container answered={didSubmitAnswer || alwaysShowingExplanation}>
      <Highlightable
        type={'stem'}
        htmlString={prompt}
        setHighlights={setHighlights}
        highlights={highlights}
        disable={!enableHighlighting}>
        {questionHeader && (
          <Prompt
            hideArrow
            className={'fr-view'}
            dangerouslySetInnerHTML={{__html: questionHeader}}
          />
        )}
        <Prompt
          ref={promptRef}
          className={'fr-view'}
          dangerouslySetInnerHTML={{ __html: prompt }}
          answered={didSubmitAnswer || alwaysShowingExplanation}
        />
      </Highlightable>
      <Wrapper>
        <DragComponent
          answers={answers}
          answerGroups={answerGroups}
          headerLeft={dndHeaderLeft}
          headerRight={dndHeaderRight}
          promptRef={promptRef}
          setAnswerState={setAnswerState}
          answerState={answerState}
          answered={didSubmitAnswer || alwaysShowingExplanation}
        />
      </Wrapper>
      {!didSubmitAnswer && tutorMode && !alwaysShowingExplanation && (
        <SubmitButton onClick={checkOnClick} />
      )}
    </Container>
  );
}


DragAndDrop.defaultProps = {};
DragAndDrop.propTypes = {};

export default DragAndDrop;