import React, {useEffect, useState, useRef} from 'react';

import {H2, H4, Body3, Label3} from '@bootcamp/web/src/components/Typography/next';
import {Link} from 'react-router-dom';
import {Add} from '@styled-icons/fluentui-system-filled/Add';
import {Subtract} from '@styled-icons/fluentui-system-filled/Subtract';
import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';

import styled from 'styled-components';

export const Card = styled.div`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  border-radius: 8px;
  margin-bottom: 48px;
  overflow: hidden;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled(H4)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
export const Title = styled(H2)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  `;
export const SubTitle = styled(Body3)`
  margin-top: 4px;
  color: ${({theme}) => theme.colors.typographyPalette.transparent};
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.colors.neutralsPalette.light};
  margin: ${({theme}) => `${theme.layouts.spacing.l} 0`};
`;
export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  h3 {
    margin-bottom: 0;
  }
`;

const BlurbContainer = styled.div`
  position: relative;
  padding-left: ${({icon}) => icon ? '32px' : '0px'}
`;
export const BlurbText = styled(Body3)`
  background: ${({theme, lightMode}) => lightMode || theme.darkModeEnabled ? theme.overlays.opacity.light._100 : theme.colors.brandPalette.royal.light};
  color: ${({theme, lightMode}) => lightMode ? 'white' : 'initial'};
  border-radius: 8px;
  padding: ${({theme, icon}) => `${theme.layouts.spacing.ml} ${theme.layouts.spacing.l}`};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => `${theme.layouts.spacing.ms} ${theme.layouts.spacing.l}`};
  }
`;
export const BlurbIcon = styled.img`
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  border: 3px solid ${({theme}) => theme.overlays.opacity.light._100};
  top: calc(50% - 21px);
  left: 0;
`;

const ExpandContainer = styled(Body3)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
`;
const ExpandIcon = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

export const Expand = ({expanded, setExpanded}) => {
  return (
    <ExpandContainer onClick={() => setExpanded(!expanded)}>
      {expanded ? <ExpandIcon as={Subtract} /> : <ExpandIcon as={Add} />} {expanded ? 'Collapse All' : 'Expand All'}
    </ExpandContainer>
  );
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  font-family: 'proxima-nova';
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  table-layout: ${({fixed}) => fixed ? 'fixed' : 'auto'};

  tr {
    border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  }
  tr:last-child {
    border-bottom: none;
  }

  tr:first-child {
    background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
    border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};

    td {
      font-weight: 600;
      color: ${({theme}) => theme.colors.neutralsPalette.grey};
    }
  }
`;
export const Icon = styled.div`
  width: 16px;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  cursor: pointer;
`;
const Chevron = styled(Icon)`
  width: 20px;
`;
export const TableRow = styled.tr`
  cursor: ${({parent, onClick}) => onClick || parent ? 'pointer' : 'auto'};
  box-shadow: ${({nested, isExpanded, theme}) => nested || isExpanded ? `3px 0px inset rgb(237, 237, 240);` : `none` };
  background: ${({theme, isExpanded}) => isExpanded ? theme.colors.neutralsPalette.offWhite : 'none'};

  &:hover {
    background: ${({onClick, theme, parent}) => !!onClick || parent ? theme.colors.neutralsPalette.offWhite : 'none'};

    ${Icon} {
      color: ${({theme}) => theme.colors.brandPalette.royal.default};
    }
  }
`;
export const Cell = styled.td`
  color: ${({theme, disabled}) => disabled && theme.colors.neutralsPalette.lightGrey};
  padding: ${({width, theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.m}`};
  text-align: ${({width}) => width ? `center` : 'left'};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  ${({background}) => background && `background: ${background};`}

  &:first-child {
    width: 48px;
  }

  &:last-child {
    text-align: right;
    width: ${({hasContent}) => hasContent ? 'auto' : '48px'};
  }
`;

export const Blurb = ({className, icon, bareIcon, text, lightMode, children}) => (
  <BlurbContainer className={className} icon={icon}>
    {icon && <BlurbIcon src={icon} />}
    <BlurbText lightMode={lightMode} icon={icon}>
      {bareIcon && bareIcon}
      {text}
      {children}
    </BlurbText>
  </BlurbContainer>
);

const CellContent = styled.span`
  color: ${({theme, disabled}) => disabled ? theme.colors.neutralsPalette.lightGrey : theme.colors.neutralsPalette.extraDark};
  text-decoration: ${({as}) => as === 'span' ? 'none' : 'underline'};

  ${({theme}) => theme.mediaQueries.laptopL} {
    word-wrap: ${({breakWord}) => breakWord ? 'break-word' : 'normal'};

  }
  ${({theme}) => theme.mediaQueries.tablet} {
    word-wrap: normal;
  }
`;
export const Table = ({rows, className, expandAll, hideChevrons=false, fixed=true}) => {
  const [expandedRows, setExpandedRows] = useState({});

  function handleRowExpansion(index) {
    const isExpanded = expandedRows[index];

    setExpandedRows({...expandedRows, [index]: !isExpanded});
  }

  function renderRow(row, index) {
    if (row.hasOwnProperty('cells')) {
      return (
        <TableRow key={`table-row-${index}`} onClick={row.onClick}>
          {row?.cells?.map(({content, background, to, disabled}, cellIndex) => {
            const contentLength = typeof content === 'string' ? content.length : 0;
            const spaces = typeof content === 'string' ? content?.split(' ').length - 1 : 0;
            const breakWord = contentLength > 20 && spaces === 0;

            return (
              <Cell key={`table-cell-${cellIndex}`} hasContent={!!content} background={background}><CellContent as={to ? Link : 'span'} breakWord={breakWord} to={to} disabled={disabled} target="_blank">{content}</CellContent></Cell>
            );
          })}
        </TableRow>
      );
    } else {
      // here we're assuming a row is passed as an object with nested rows
      const {parent, nested} = row;
      const isExpanded = expandedRows[index];

      return (
        <>
          <TableRow key={`table-row-${index}`} isExpanded={isExpanded} onClick={() => handleRowExpansion(index)} parent>
            <Cell key={`table-cell-parent-toggle`}>
              {isExpanded ? <Icon as={Subtract}/> : <Icon as={Add}/>}
            </Cell>
            {parent?.map(({content}, cellIndex) => (
              <Cell key={`table-cell-${cellIndex}`} onClick={() => cellIndex === 0 && handleRowExpansion(index)}>{content}</Cell>
            ))}
            <Cell key={`table-cell-parent-placeholder`}></Cell>
          </TableRow>

          {isExpanded &&
            nested?.map(({cells, onClick}) => (
              <TableRow key={`table-row-nested-${index}`} onClick={onClick} nested>
                {cells?.map(({content, disabled}, index) => (
                  <Cell key={`table-cell-nested-${index}`} disabled={disabled}>{content}</Cell>
                ))}
                <Cell key={`table-cell-parent-toggle`}>
                  {!hideChevrons && <Chevron as={ChevronRight}/>}
                </Cell>
              </TableRow>
            ))
          }
        </>
      );
    }

  }

  useEffect(() => {
    if (expandAll) {
      setExpandedRows(rows.reduce((acc, curr, index) => ({...acc, [index]: true}), {}));
    } else {
      setExpandedRows({});
    }
  }, [expandAll, rows])

  return (
    <StyledTable className={className} fixed={fixed}>
      {rows?.map(renderRow)}
    </StyledTable>
  );
}

const DivergentBarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${({height}) => height}px;
  overflow: hidden;
`;
const Bar = styled.div`
  width: ${({percentage}) => percentage}%;
  height: 100%;
  background: ${({theme, color}) => theme.colors.interfacePalette[color].gradient};
`;
const Left = styled.div`
  padding: 4px 0px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 100%;

`;
const Right = styled.div`
  padding: 4px 0px;
  flex: 1;
  height: 100%;
`;
const BarDivider = styled.div`
  background: ${({theme}) => theme.colors.borderPalette.secondary};
  width: ${({width}) => width}px;
  height: 100%;
`;

export const DivergentBar = ({reference, value, height=28, dividerWidth=3}) => {
  const disparity = Math.abs(reference - value);
  const percentage = Math.round((disparity / reference) * 100);

  return (
    <DivergentBarContainer height={height}>
      <Left>
        {reference > value && <Bar color={'green'} percentage={percentage}/>}
      </Left>
      <BarDivider width={dividerWidth}/>
      <Right>
        {reference < value && <Bar color={'red'} percentage={percentage}/>}
      </Right>
    </DivergentBarContainer>
  );
};

export const LineLabelContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
`;
export const LineLabelWrapper = styled(Label3)`
  position: absolute;
  color: ${({theme}) => theme.colors.typographyPalette.secondary};
  white-space: nowrap;
`;