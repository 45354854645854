import React, {useEffect} from 'react';

import {useUserDataContext} from '../../../../contexts/UserData';
import {useTestNavigatorContext} from '../../../../contexts/TestNavigator';
import {useTimerContext} from '../../../../contexts/TestTimer';

import Exhibit from '../../../../components/Exhibit';
import YouTubePlayer from '../../../../components/VideoPlayer/YouTubePlayer';
import VideoPlayer from '../../../../components/VideoPlayer';
import QuestionSet from '../../../../components/TestBlocks/QuestionSet';
import Question from '../../../../components/QBank/Question';
import QuestionStats from '../../../QuestionBank/QuestionStats';
import {FlexBox, Row} from '../../../../components/Grid';
import TextBlock from './Text';
import baseTheme from '@bootcamp/shared/src/styles/theme';

import {findByRenderType, changeIntercomDisplay} from '@bootcamp/shared/src/util';

import {IntercomAPI} from 'react-intercom';
import styled, {css} from 'styled-components';

const Container = styled.div`
  position: relative;
  height: calc(100vh - 169px);
  background: ${({background, theme}) => theme.colors.neutralsPalette.white};
  overflow-y: auto;
  padding-bottom: ${({noPadding}) => noPadding ? '0px' : '100px'};
  position: relative;

  .highlight {
    ${({theme}) => theme.darkModeEnabled && css`
      color: ${baseTheme.colors.neutralsPalette.extraDark} !important;
    `}
  }
`;
const VideoWrapper = styled.div`
  position: relative;
  background: ${({theme}) => theme.darkModeEnabled ? '#121212' : 'transparent'};
  height: 100%;
  overflow: hidden;
  /* max-width: 133vh;
  margin: auto; */
`;
const YouTube = styled(YouTubePlayer)`
  flex: 1;
  width: 100%;
  iframe {
    max-height: calc(100vh - 250px);
  }
`;
const VimeoWrapper = styled.div`
  flex: 1;
  width: 100%;
  iframe {
    max-height: calc(100vh - 250px);
  }
`
const StatWrapper = styled.div`
  display: none;
  width: 90%;
  margin: ${({theme}) => `${theme.layouts.spacing.l} auto`};

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }
`;

const VideoRow = styled(Row)`
  height: auto;
  align-items: center;
  justify-content: center;
  background: #121212;
  max-height: calc(100vh - 250px);
`;

const VideoDetailsRow = styled(FlexBox)`
  height: auto;
  padding: 16px 32px;
  align-items: center;
  flex-direction: row;
  ${({theme}) => theme.mediaQueries.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
// const IntercomButton = styled(Button)`
//   margin-right: 0;
//   margin-left: auto;
//   ${({theme}) => theme.mediaQueries.mobileL} {
//     padding: 10px 24px;
//     margin-left: 0;
//     margin-top: 16px;
//
//   }
// `;
const IntercomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: .75em;
  border : 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : '#e1e8ed'};
  border-radius: 1em;
  color: ${({theme}) => theme.darkModeEnabled ? theme.colors.typographyPalette.secondary : '#777'};
  text-decoration: none;
  flex-grow: 1;
  text-align: left;
  font: proxima-nova 400 16px;
  cursor: pointer;
  width: 100%;
  opacity: .75;
  transition: opacity .4s;
  &:hover {
    opacity: 1;
    transition: opacity .4s;
  }
`;

const VideoSizer = styled.div`
  width: 100%;
  max-width: calc(100% - 10vw);
  margin: auto;
  ${({theme}) => theme.mediaQueries.tablet} {
    max-width: 100%;
  }
`;

const VideoPlayerComponent = ({type, props, ref, locked, title, themePalette, blockIndex, onEnd}) => {
  // use this to implement different players for different video types
  const VideoElement = type === 'YouTube'
    ? <YouTube videoId={props.id} responsive locked={locked} autoplay={1}/>
    : (
      <VimeoWrapper>
        <VideoPlayer vimeoId={props.id} playerId={props.id} locked={locked} autoplay color={themePalette.default} onEnd={onEnd}/>
      </VimeoWrapper>
    )

  return (
    <Container noPadding>
      <VideoWrapper>
        <VideoRow>
          <VideoSizer>
            {VideoElement}
          </VideoSizer>
        </VideoRow>
        <VideoDetailsRow>
          {/* <VideoDetails>
            <VideoTitle>
              {title}
            </VideoTitle>
            <VideoDuration>
              {duration}
            </VideoDuration>
          </VideoDetails> */}
          <IntercomButton
            children={<span>I have questions or feedback about this video</span>}
            onClick={() => IntercomAPI('showNewMessage', `I have questions or feedback about this video: ${window.location.origin + window.location.pathname + `?index=${blockIndex}`} - Video Title: ${title}. Specifically: `)}
          />
        </VideoDetailsRow>
      </VideoWrapper>
    </Container>
  )
}

const Block = ({themePalette, showingExhibit, exhibit, toggleExhibit, blockRef, chapterName, handleNextLesson}) => {
  const {isUpgraded, bootcamp, searchUserInteractions} = useUserDataContext();
  const {resetTimer, toggleTimer} = useTimerContext();
  const autoPlayEnabled = searchUserInteractions('autoPlayEnabled') === true;

  const {
    methods: {
      transitionBlock,
      saveBlockProgress
    },
    variables: {
      blockIndex,
      testBlockConnections,
      quizProgress,
    }
  } = useTestNavigatorContext();

  const onLockedBlockIndex = bootcamp === 'nclex' ? false : bootcamp === 'dat' && ['Molecules and Fundamentals of Biology', 'General and Lab Concepts Review', 'Bonding and Molecular Geometry', 'Keyholes', 'RC Questions', 'Functions and Expressions'].includes(chapterName) ? false : bootcamp !== 'inbde' && blockIndex > 2 && !isUpgraded && !window.location.pathname.includes('application-services');

  useEffect(() => {
    changeIntercomDisplay('none');
    return () => changeIntercomDisplay('block');
  }, []);

  useEffect(() => {
    if (onLockedBlockIndex) {
      transitionBlock('start');
    }
    resetTimer();
    const {testBlock} = testBlockConnections[blockIndex] || {testBlock: {}};
    const {questions} = quizProgress[blockIndex];
    const currentQuestion = questions?.find(({current}) => current);
    if (['questionSet', 'passage'].includes(testBlock.type) && !currentQuestion?.didCheck) toggleTimer();

    blockIndex && window.history.replaceState(null, '', `${window.location.pathname}?index=${blockIndex}`)
  }, [blockIndex, isUpgraded]);

  const blockRenderer = (testBlockConnection) => {
    const {testBlock} = testBlockConnection || {testBlock: {}};

    switch (testBlock.type) {
      case 'startBlock':
        transitionBlock('next');
        return null;
      case 'lesson':
        changeIntercomDisplay('none');
        const {contents} = (testBlock.components && testBlock.components.find(({renderType}) => renderType === 'video')) || {contents: '{}'};
        const {type, ...props} = JSON.parse(contents);
        return (
          <>
            {showingExhibit && <Exhibit type={exhibit} onCloseExhibit={toggleExhibit} defaultPosition={{x: 0, y: 0}} />}
            <VideoPlayerComponent
              type={type}
              props={props}
              ref={blockRef}
              locked={onLockedBlockIndex}
              title={testBlock.title}
              themePalette={themePalette}
              blockIndex={blockIndex}
              onEnd={async () => {
                if (!autoPlayEnabled) return;

                await saveBlockProgress({status: 'complete'});
                handleNextLesson();
              }}
            />
          </>
        )
      case 'text':
        const blockContentType = findByRenderType(testBlock.components, 'contentType');
        const isQuizLinkBlock = testBlock?.type === 'text' && blockContentType === 'Quiz';

        let content = findByRenderType(testBlock.components, 'content');

        if (bootcamp === 'med-school') {
          content = content.replace('step-1', 'med-school')
        }

        if (window.location.pathname.match('pathology/videos/')) {
          content = content.replace('https://drive.google.com/file/d/1IZhWCEtxBzJXAP0CuGkWDNc0fwQHYukm/view?usp=drivesdk', 'https://drive.google.com/file/d/1Ia8SzbkXQ0K-S8Jt9TVLM4MJXkubev6W/view')
          content = content.replace('https://drive.google.com/file/d/1Lqz8swQsvQvmy7ptgs428Jn-4cwHmqqF/view?usp=sharing', 'https://drive.google.com/file/d/1Ia8SzbkXQ0K-S8Jt9TVLM4MJXkubev6W/view')
        }
        const raw = !testBlock?.title?.match('Downloads') && !testBlock?.title?.match('Resources') && !testBlock?.title?.match('Updates') && !isQuizLinkBlock;

        return (
          <Container>
            <TextBlock key={`${testBlock?.title}-${blockContentType}`}  html={content} title={testBlock?.title} raw={raw}/>
          </Container>
        )
      case 'passage':
      case 'questionSet':
        changeIntercomDisplay('block');
        const {questions} = quizProgress[blockIndex];
        const score = questions ? questions
          .reduce(({correct, incorrect, total}, {didSelectCorrectAnswer, didCheck}) => ({
            correct: correct + (didCheck && didSelectCorrectAnswer),
            incorrect: incorrect + (didCheck && !didSelectCorrectAnswer),
            total: total + 1
          }), {correct: 0, incorrect: 0, total: 0}) : {};

        return (
          <Container background="extraLight" style={{padding: '32px 32px 150px'}}>
            {showingExhibit && <Exhibit type={exhibit} onCloseExhibit={toggleExhibit} defaultPosition={{x: 0, y: 0}}/>}
            <QuestionSet
              template={'coursePlayer'}
              scrollToTop={() => document.querySelector(`.${Container.styledComponentId}`).scroll(0,0)}
              renderQuestion={({testBlock, questionIndex, lastUpdated, ...props}) => (
                <Question
                  key={`${testBlock.id}-${questionIndex}`}
                  themePalette={themePalette}
                  questionIndex={questionIndex + 1}
                  testBlock={testBlock}
                  bootcamp={bootcamp}
                  {...props}
                />
              )}
            />
          </Container>
        );
      case 'endBlock':
        window.location.pathname = window.location.pathname.split('/').slice(0, -1).join('/');
        return <Container />
      default:
        return <Container />
    }
  }

  return blockRenderer(testBlockConnections[blockIndex]);
}

Block.propTypes = {};
Block.defaultProps = {};

export default Block;
