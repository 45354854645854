import React from 'react';
import styled from 'styled-components';

import Chapter from '@bootcamp/web/src/bootcamps/components/Cards/Chapter'
import {H4} from '../../../components/Typography';
import Generic  from '../Cards/Generic';
import TagIcon from '../MasteryIcon';
import CreateTestButton from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/CreateTestButton';

import {Tag} from '@styled-icons/fluentui-system-filled/Tag';
import {Bookmark} from '@styled-icons/fluentui-system-filled/Bookmark'

import {capitalize} from '@bootcamp/shared/src/util';


const Card = styled(Generic)`
  display: block;
  margin-right: 24px;
  &:last-child {
    margin-right: 0px;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: 0;
  }
`;

const InactiveText = styled(H4)`
  color: ${({theme}) => theme.overlays.opacity.light._500};
  font-weight: 500;

  span {
    color: ${({color}) => color};
  }
`;
const StyledChapterCard = styled(Chapter)`
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`;
const TagContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${({color}) => color};
  color: white;
  padding: 4px;
  border-radius: 6px;
`;

const MasteryCard = ({color, backgroundColor, level, count, activeFilter, bootcamp, textLayout, linkTo, datStyling}) => {
  return (
    <Card
      color={color}
      backgroundColor={backgroundColor}
      active={!!count}
      icon={<TagIcon level={level}/>}
      text={count
        ? `${count} Question${count === 1 ? '' : 's'}`
        : <InactiveText color={color}>Once you tag questions as <span>{capitalize(level)}</span> you can study them here.</InactiveText>
      }
      subText={!!count && capitalize(level)}
      linkTo={linkTo}
      textLayout={textLayout}
      useAnchor={datStyling}
    />
  );
}

export const NCLEXMasteryCard = ({
  color,
  level,
  count,
  questionCount,
  onClick,
}) => {

  const buttonProps = !count
    ? {disabled: true, children: 'No Questions', IconComponent: null}
    : {}

  return (
    <StyledChapterCard
      name={capitalize(level)}
      route={'#'}
      icon={
        <TagContainer color={color}>
          {level === 'bookmarked' ? <Bookmark/> : <Tag/>}
        </TagContainer>
      }
      questionCount={questionCount}
      progressSegments={[{count, color}]}
      showProgress
      actions={[<CreateTestButton disableModal {...buttonProps}/>]}
      rawRoute
      disabled={!count}
      onClick={!!count ? onClick : () => {}}
    />
  );
}

MasteryCard.defaultProps = {};
MasteryCard.propTypes = {};

export default MasteryCard;
