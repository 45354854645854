import React from 'react';
import Auth from '@aws-amplify/auth';

import {useUserDataContext} from '../../../../contexts/UserData';

import {useFormContext} from 'react-hook-form';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {Container, Header, Body, FieldGroup, SubmitContainer, SubmitButton} from '../../';

const FormSubmit = props => {
  const {formState} = useFormContext();
  return (
    <SubmitContainer>
      <SubmitButton disabled={!formState.isValid}>
        Verify Email
      </SubmitButton>
    </SubmitContainer>
  )
}

const VerifyEmail = ({onSubmit}) => {
  const {refreshSession} = useUserDataContext();

  return (
    <FormWrapper onSubmit={async (values, formMethods) => {
      const {code} = values;
      try {
        await Auth.verifyCurrentUserAttributeSubmit('email', code);
        refreshSession(true);
      } catch (e) {
        console.log(e);
      } finally {
        onSubmit();
      }
    }}>
      <Container>
        <Body>
          <Header>Verify your Email</Header>
          <FieldGroup children={<FieldRenderer config={VERIFY_EMAIL_FORM_CONFIG} />}/>
        </Body>
        <FormSubmit />
      </Container>
    </FormWrapper>
  )
}


export default VerifyEmail;

VerifyEmail.defaultProps = {
  onSubmit: () => true
};

const VERIFY_EMAIL_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'Confirmation Code',
    name: 'code',
    inputProps: {
      autoFocus: true,
      placeholder: 'Enter code...'
    },
    validation: () => ({
      required: 'Confirmation code required.'
    })
  }
]
