import styled from 'styled-components';
import {Check2} from '@styled-icons/bootstrap/Check2';
import {X} from '@styled-icons/bootstrap/X';

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #3852A4;
  padding: 4px;
`;

export const BoxSection = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BoxSubsection = styled(BoxSection)`
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding: 4px;

  svg {
    width: 26px;
    height: 26px;
  }

  &:hover {
    background-color: #283294;
  }
`;

const IconText = styled.div`
  font-family: Arial;
  font-size: 12px;
  line-height: 100%;
  color: white;
  white-space: nowrap;
  margin-top: 4px;
  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
`;

export const IconButton = ({className, Icon, text, onClick}) => (
  <IconContainer className={className} onClick={onClick}>
    <Icon />
    <IconText children={text} />
  </IconContainer>
)

export const MenuText = styled.div`
  font-family: Arial;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  color: white;
  white-space: nowrap;
`;

export const XIcon = styled(X)`
  color: #FF0000;
`;
export const CheckIcon = styled(Check2)`
  color: #02A604;
`;

export const Button = styled.div`
  width: fit-content;
  margin: auto;
  background: linear-gradient(#5490CC, #004976);
  color: white;
  font-weight: normal;
  border-radius: 3px;
  border: 1px solid #004976;
  cursor: pointer;
  font-family: "Open Sans", Verdana, sans-serif;
  padding: 0 8px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  white-space: nowrap;
  &:hover {
    background: linear-gradient(#004976, #5490CC);
  }
  ${({disabled}) => disabled && `
    background: #ccc;
    border: 1px solid #ccc;
    cursor: loading;
    &:hover {
      background: #ccc;
    }
  `};
`;
