import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import useHotkeys from "@reecelucas/react-use-hotkeys";

import {useAsync} from '@bootcamp/web/src/hooks';
import {getTestBlockQuestions, changeIntercomDisplay, calculateAnswerPercentage, getQuestionPartsAsync, defaultQuestionParts} from '@bootcamp/shared/src/util';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {XIcon, CheckIcon} from './shared';
import {insertVideoElements} from '@bootcamp/web/src/components/QBank/Question';
import Solution, {IntercomButton, Solution as SolutionInner} from '@bootcamp/web/src/components/QBank/Question/Solution';
import Highlightable from '@bootcamp/web/src/components/Highlightable';
import {Button} from '@bootcamp/web/src/components/Branding/Buttons';
import theme from '@bootcamp/shared/src/styles/theme';
import {updateQuestionProgress as updateQbQuestionProgress, createQuestionProgress, trackQuestionPerformance} from '@bootcamp/shared/src/requests';

import {useTimerContext} from '../../contexts/TestTimer';
import {Body3} from '../Typography';
import AdminButtons from '../QuestionView/components/AdminButtons';
import {updateQuestionProgressState} from '../../helpers';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #FCFCFC 0%, #D7DCED 100%);
  padding: ${({theme}) => theme.layouts.spacing.l};
  padding-bottom: 75px;
  overflow-y: auto;
`;

const ContainerSubsection = styled.div`
  max-width: 1000px;
  width: 100%;
`;

const Text = styled.div`
  font-family: Arial;
  font-size: 16px;
  line-height: 1.6em;
  /* or 150% */
  color: #000000;
`;

export const Prompt = styled(Text)`
  margin-bottom: 12px;
  * {
    font-family: Arial;
    font-size: 16px;
    /* or 150% */
    color: #000000;
  }
  img {
    margin: 0 auto;
    display: block;
    height: auto;
    max-width: 700px;
  }
  img.unlockedDimensions {
    width: auto !important;
    max-height: calc(100vh - 112px) !important;
  }
  p {
    margin: 0;
    span {
      font-size: 16px !important;
      color: inherit !important;
    }
  }
  ol, ul {
    margin: 1em 0;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-view table p {
    margin: 0;
  }
`;

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const ChoiceContainer = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  position: relative;

  &:hover {
    background: rgba(0,0,0,.05);
  }
`;
export const ChoiceInput = styled.input.attrs(props => ({type: 'radio'}))`
  margin: 0;
  margin-right: 8px;
`;

export const ChoiceText = styled.div`
  * {
    max-width: 100%;
    font-size: 16px;
    display: inline;
  }
  p {
    margin: 0;
    span {
      font-size: 16px !important;
      color: inherit !important;
    }
  }
  img {
    height: auto;
  }
  .sup, .sub, sup, sub {
    font-size: 12px;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-dib, .fr-dii {
    margin: 0 !important;
    display: inline !important;
    vertical-align: middle !important;
  }
  max-width: 100%;
  font-size: 16px;
  display: inline;
  user-select: ${({selectEnabled}) => selectEnabled ? 'auto' : 'none'};
  text-decoration: ${({isCrossed}) => isCrossed ? 'line-through' : 'none'};
`;
export const AnswerLetter = styled.span`
`;
export const ChoiceFeedback = styled.div`
  position: absolute;
  width: 24px;
  left: -20px;
`;
const AnswerPercentage = styled(Body3)`
  max-width: ${({loading}) => loading ? 0 : '200px'};
  transition: max-width 400ms;
  margin-left: 8px;
  ${({hidden}) => hidden && `display: none;`}
`;
const StyledSolution = styled(Solution)`
  ${SolutionInner} {
    padding: 0px;
    border: 0px
  }
  ${IntercomButton} {
    margin-top: ${({theme}) => theme.layouts.spacing.l};
    background: white;
    opacity: 1;

    &:hover {
      background: #F6F6F6;
    }
  }
  table {
    background: transparent !important;
    border-color: #252E33;
    th {
      background-color: transparent !important;
      color: #252E33 !important;
      border-color: #252E33;
    }
    tr {
      background-color: transparent !important;
      color: #252E33 !important;
      border-color: #252E33;
    }
    td {
      background: transparent !important;
      border-color: #252E33;
    }
  }
  img {
    background: white;
  }
`;

const KeyboardShortcut = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 8px;
  font-family: 'proxima-nova';
`;

const Choice = ({answerData, answer, index, updateCrossedAnswerIndexes, isCrossed, showingExplanation}) => {
  const {searchUserInteractions, bootcamp} = useUserDataContext();
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;
  const hideAnswerData = searchUserInteractions('hideAnswerData') === true || Object.keys(answerData || {}).length === 0;

  const {
    methods: {
      updateQuizProgress,
    },
    variables: {
      blockIndex,
      quizProgress,
    }
  } = useTestNavigatorContext();

  function handleContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    questionProgresses[questionIndex]?.selectedAnswerIndex !== index && updateCrossedAnswerIndexes();
  }

  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const updateQuestionProgress = params => {
    return updateQuestionProgressState(updateQuizProgress, questionProgresses, questionIndex, params)
  };

  const [answerText, answerCorrect] = answer;

  const updateSelectedAnswerChoice = () => {
    !showingExplanation && isCrossed && updateCrossedAnswerIndexes();
    !showingExplanation && updateQuestionProgress({selectedAnswerIndex: index, didSelectCorrectAnswer: answerCorrect})
  }

  useHotkeys(`${index+1}`, updateSelectedAnswerChoice, [updateSelectedAnswerChoice]);

  const answerLetter = alphabet[index];
  const letteredAnswerText = `${answerText}`;
  const answerPercentage = calculateAnswerPercentage(answerData, answerLetter, answerCorrect);

  return (
    <ChoiceContainer
      onClick={updateSelectedAnswerChoice}
      onContextMenu={handleContextMenu}
    >
      {showingExplanation &&
        <ChoiceFeedback
          children={
            answerCorrect
              ? <CheckIcon />
              : questionProgresses[questionIndex]?.selectedAnswerIndex === index
                ? <XIcon />
                : null
          }
        />
      }
      <ChoiceInput checked={questionProgresses[questionIndex]?.selectedAnswerIndex == index} type="radio" />
      <AnswerLetter>{answerLetter}.&nbsp;</AnswerLetter>
      <ChoiceText isCrossed={isCrossed} dangerouslySetInnerHTML={{__html: letteredAnswerText}} selectEnabled={bootcamp === 'mcat'} />
      {showKeyboardShortcuts && !showingExplanation &&  <KeyboardShortcut>[{index+1}]</KeyboardShortcut>}
      <AnswerPercentage hidden={hideAnswerData || !showingExplanation} loading={!showingExplanation}>{`(${answerPercentage}%)`}</AnswerPercentage>
    </ChoiceContainer>
  )
}

export const ChoicesContainer = styled.div`
  display: inline-block;
  min-width: 300px;
  border: 1px solid #5490CC;
  border-bottom-width: 4px;
  padding: 8px 24px;
`;

const Choices = ({className, answerData, answers, updateQuestionProgress, showingExplanation, savedCrossedAnswerIndexes}) => {
  const [crossedAnswerIndexes, setCrossedAnswerIndexes] = useState(savedCrossedAnswerIndexes || [])

  function updateCrossedAnswerIndexes(index) {
    const indexes = crossedAnswerIndexes.indexOf(index) > -1
      ? crossedAnswerIndexes.filter(i => i !== index)
      : [...crossedAnswerIndexes, index];
    updateQuestionProgress({indexes});
    setCrossedAnswerIndexes(indexes);
  }

  const answerTuples = Object.entries(answerData || {});
  const totalAnswers = answerTuples.reduce((acc, [name, count]) => acc += count, 0);

  return (
    <ChoicesContainer className={className}>
      {answers.map((answer, index) => (
        <Choice
          updateCrossedAnswerIndexes={() => updateCrossedAnswerIndexes(index)}
          isCrossed={crossedAnswerIndexes.indexOf(index) > -1}
          answer={answer}
          answerData={totalAnswers >= 10 ? answerData : {}}
          index={index}
          showingExplanation={showingExplanation}
        />
      ))}
    </ChoicesContainer>
  )
}

const MasteryButtons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  div {
    margin-right: 8px;
  }
`;

export const CheckButton = styled(Button)`
  max-width: 100px;
  max-height: 30px;
  border-radius: 0px;
  background: #0167a9;
  box-shadow: 0px 2px 0px #013d65;

  &:hover {
    background: #0167a9;
    color: #fcfd0b;
  }
`;
const QuestionIndexContainer = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  margin-bottom: 12px;
`;
const Question = ({themePalette, className, tooltipRef, strikethroughTooltipRef, disableHighlightToolTip, showingQuestionIndex, disableHighlighting}) => {
  const {searchUserInteractions, cognitoUser, DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;
  const autoTagEnabled = true;

  const {timerActive, toggleTimer, questionStartTime} = useTimerContext();

  const {
    methods: {
      updateQuizProgress,
      getAnswerDataForQuestion,
      saveQuestionProgresses
    },
    variables: {
      blockIndex,
      quizProgress,
      testBlockConnections,
      template,
      answerData,
      tutorMode,
      customTestConfig,
      test,
      shuffled
    }
  } = useTestNavigatorContext();

  const solutionRef = useRef();
  const {testBlock} = testBlockConnections[blockIndex];
  const testQuestions = getTestBlockQuestions(testBlock, template === 'customTest');

  const {questions: questionProgresses} = quizProgress[blockIndex];

  const questionIndex = questionProgresses.findIndex(({current}) => current);
  const currentQuestionProgress = questionProgresses[questionIndex] || {};
  const currentQuestionIndex = shuffled ? questionIndex : `${questionProgresses.find(({current}) => current)?.questionIndex}` || questionIndex;
  const currentQuestion = template === 'testReview' || shuffled ? questionProgresses[currentQuestionIndex]?.question || {} : testQuestions[currentQuestionIndex] || {};
  const latestRevisionId = currentQuestionProgress.questionRevisionId;
  const globalQuestionIndex = quizProgress?.reduce((acc, {questions}, index) => index >= blockIndex ? acc : acc + (questions?.length || 0), 0) + questionIndex;

  const {value, error} = useAsync(useCallback(() => getQuestionPartsAsync(currentQuestion, currentQuestionProgress.questionRevisionId), [currentQuestionIndex, blockIndex, shuffled]));
  let questionParts = error || !value ? defaultQuestionParts : value;

  const {prompt, answers, explanation, citations, lastUpdated, tags, relatedVideos, hyperlinks} = questionParts;
  const questionAnswerData = answerData[currentQuestion?.id];
  const showingExplanation = questionProgresses[questionIndex]?.didCheck || customTestConfig?.submitted || template === 'testReview';

  useEffect(() => {
    changeIntercomDisplay('none');
    return () => changeIntercomDisplay('block');
  }, []);

  useEffect(() => {
    // load answer data for question if need be
    getAnswerDataForQuestion(currentQuestion?.id, latestRevisionId);
  }, [currentQuestion?.id]);

  useEffect(() => {
    if (solutionRef.current && showingExplanation) {
      insertVideoElements(solutionRef, 'solution');
    }
  }, [explanation, showingExplanation])
  const correctAnswerIndex = answers.reduce((acc, [answerText, answerCorrect], index) => answerCorrect ? `${acc}${index}` : acc, '') || -1;
  const correctPercentage = calculateAnswerPercentage(questionAnswerData, alphabet[correctAnswerIndex], true, false);

  const updateQuestionProgress = params => {
    return updateQuestionProgressState(updateQuizProgress, questionProgresses, questionIndex, params)
  };

  const onClickCheckButton = (e) => {
    if (!tutorMode || currentQuestionProgress?.selectedAnswerIndex == -1) return;
    // NOTE question progress creation is happening for any question with 'truthy' answer state, we may want to validate
    // the answerState before submitting (similar to the selectedAnswerIndex !== -1 conditional)
    const timeElapsed = new Date(new Date() - questionStartTime);
    const time = (parseFloat(questionProgresses[questionIndex]?.time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    e.preventDefault();

    const autoTag = currentQuestionProgress?.didSelectCorrectAnswer
      ? 'mastered'
      : 'learning';

    const {
      questionProgressId,
      questionBaseId,
      questionRevisionId,
      current,
      originalTestId,
      crossedAnswerIndexes,
      bookmarked,
      questionIndex: currentQuestionIndex,
      subject,
      topic,
      question,
      isSequentialStart,
      isSequentialEnd,
      isSequentialSet,
      indexes,
      answerState,
      seen,
      subjectTopic,
      ...questionProgress
    } = updateQuestionProgress({
      ...(autoTagEnabled ? {masteryLevel: autoTag} : {}),
      didCheck: true,
      time,
    });

    questionProgress.questionRevisionId = questionRevisionId;

    if (questionProgress.selectedAnswerIndex !== -1 && ['customTest', 'tbcSavedBank'].includes(template)) {

      updateQbQuestionProgress(questionProgressId, questionProgress);

    }

    if (autoTagEnabled) {
      const update = {
        questionProgressId,
        questionBaseId,
        questionRevisionId,
        current,
        crossedAnswerIndexes,
        bookmarked,
        originalTestId,
        questionIndex: currentQuestionIndex,
        subject,
        topic,
        subjectTopic,
        question: { ...question, masteryLevel: autoTag },
        isSequentialStart,
        isSequentialEnd,
        isSequentialSet,
        answerState,
        seen,
        ...questionProgress
      };

      saveQuestionProgresses([update]);
    }


    // BlockProgress
    // id - username#originaltestid#tracking
    // QuestionProgress
    // id - username#originaltestid#questionbaseid#tracking
    // create IFF not exists:
    // qbankprogress
    try {
      const blockProgressId = `tracking#${cognitoUser.getUsername()}#${originalTestId || test.id}`
      const qbankProgressId = `tracking#${cognitoUser.getUsername()}#${originalTestId || test.id}#${questionBaseId}`
      createQuestionProgress(
        qbankProgressId,
        blockProgressId,
        questionBaseId,
        questionRevisionId,
        {...questionProgress, time},
        currentQuestionIndex,
      );
    } catch (error) {
      console.log(error)
    }

    try {
      const {id: subject} = (tags || []).find(tag => !!tag.subject && !tag.topic) || {};
      const {id: topic} = (tags || []).find(tag => !!tag.topic) || {};
      trackQuestionPerformance(DEFAULT_USER_ID, subject, topic, 1, questionProgress.didSelectCorrectAnswer ? 1 : 0, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
    } catch (error) {
      console.log(error)
    }

    toggleTimer(false);
  }
  useHotkeys('c', onClickCheckButton, [onClickCheckButton])


  useEffect(() => {
    if (questionProgresses[questionIndex]?.didCheck) {
      toggleTimer(false);
    } else {
      toggleTimer(true);
    }
  }, [questionIndex])
  if (!answers?.length) return <Container />;

  return (
    <Container key={currentQuestion?.id} className={className}>
      <ContainerSubsection>
        <AdminButtons questionId={currentQuestion?.id} correctAnswerIndex={correctAnswerIndex} />
        {showingQuestionIndex && <QuestionIndexContainer>Question {DEFAULT_USER_ID !== 'matthewdempsey' && (globalQuestionIndex + 1)}</QuestionIndexContainer>}
        <Highlightable
          type={'stem'}
          htmlString={prompt}
          setHighlights={updateQuestionProgress}
          highlights={questionProgresses[questionIndex]?.highlights}
          tooltipRef={tooltipRef}
          strikethroughTooltipRef={strikethroughTooltipRef}
          disableToolTip={disableHighlightToolTip}
          disable={disableHighlighting}
        >
          <Prompt dangerouslySetInnerHTML={{__html: prompt}}/>
        </Highlightable>
        <Choices answers={answers} updateQuestionProgress={updateQuestionProgress} answerData={questionAnswerData} showingExplanation={showingExplanation} savedCrossedAnswerIndexes={questionProgresses[questionIndex]?.indexes}/>
        {showingExplanation &&
          <StyledSolution
            solution={explanation}
            correctAnswerIndex={correctAnswerIndex}
            correctPercentage={correctPercentage}
            citations={citations}
            didSelectCorrectAnswer={questionProgresses[questionIndex]?.didSelectCorrectAnswer}
            didNotAnswer={questionProgresses[questionIndex]?.selectedAnswerIndex == -1}
            selectedAnswerIndex={questionProgresses[questionIndex]?.selectedAnswerIndex}
            alwaysShowingExplanation={true}
            showFeedback
            questionId={currentQuestion?.id}
            tags={tags}
            didMark={questionProgresses[questionIndex]?.didMark}
            time={questionProgresses[questionIndex]?.time}
            solutionRef={solutionRef}
            questionSetExplanation=''
            lastUpdated={lastUpdated}
            relatedVids={relatedVideos}
            hyperlinks={hyperlinks}
          />
        }
      </ContainerSubsection>
      <MasteryButtons>
        {tutorMode && !showingExplanation &&
          <CheckButton
            key={currentQuestion?.id+questionIndex+'check'}
            questionId={currentQuestion?.id}
            onClick={onClickCheckButton}
            colorConfig={theme.colors.brandPalette.blue}
            children={showKeyboardShortcuts ? <span>Submit<KeyboardShortcut>[C]</KeyboardShortcut></span> : 'Submit'}
            disabled={questionProgresses[questionIndex]?.selectedAnswerIndex === -1}
          />
        }
      </MasteryButtons>
    </Container>
  );
}

export default Question;
