import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';

import NavBar from '../../../components/NavBar/Admin';
import TbcNavBar from '../../../components/NavBar/Tbc';

import Footer from '../../../components/Footer';
import {FullPageSpinner} from '../../../components/Branding';

import {useUserDataContext} from '../../../contexts/UserData';

import {colors} from '@bootcamp/shared/src/styles/theme';

import styled from 'styled-components';

import chemistryConfig, {getChemistryConfigObject} from '@bootcamp/shared/src/config/chemistry';
import anatomyConfig, {getAnatomyConfigObject} from '@bootcamp/shared/src/config/anatomy';

import {ReactComponent as AnatomyTitle} from '@bootcamp/shared/src/assets/svg/anatomy-logo.svg';
import {ReactComponent as ChemistryTitle} from '@bootcamp/shared/src/assets/svg/chemistry-logo.svg';
import moment from 'moment';
import {getStreakDetails} from '@bootcamp/shared/src/util'
const anatomyRouteStyles = [];

const Fade = styled.div`
  width: 100%;
  height: 50vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.17) 100%);
`;
const RouteContainer = styled.div`
  width: 100%;
  min-height: calc(100% - 224px); // this was making my life easier but may not need it
`;

const NavBarRouteContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${({theme, color}) => color || theme.colors.brandPalette.indigo.default};
  padding: ${({withoutPadding}) => withoutPadding ? '72px' : '136px'} ${({theme}) => theme.layouts.spacing.xxl} ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme: {mediaQueries}}) => mediaQueries.tablet} {
    padding:  ${({withoutPadding, theme}) => withoutPadding ? '56px' : '88px'} ${({theme}) => theme.layouts.spacing.m} 0px;
  }
`;

const UpgradeRouteContainer = styled.div`
  width: 100%;
  min-height: ${({fullHeight}) => fullHeight ? '100vh' : 'auto'};
  height: ${({fullHeight}) => fullHeight ? 'auto' : '100%;'};
  background: ${({theme, color}) => color || theme.colors.brandPalette.indigo.default};
  padding: ${({fullHeight}) => fullHeight ? '144px' : '0px'} 0px ${({theme, fullHeight}) => fullHeight ? theme.layouts.spacing.xxl : '0px'};

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({fullHeight}) => fullHeight ? '80px' : '0px'} 0px ${({theme, fullHeight}) => fullHeight ? theme.layouts.spacing.xl : '0px'};
  }
`;

const RouteBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({theme, color}) => color || theme.colors.brandPalette.indigo.dark};
  ${({theme}) => theme.mediaQueries.tablet} {
    height: auto;
  }
`;

const Loader = styled(FullPageSpinner)`
  background: ${({background}) => background};
`;

function checkRoutePermissions(user, allow, bootcamp, userModel) {
  try {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    const isFreeTrial = ['med-school', 'dental-school', 'nclex'].includes(bootcamp) && userModel?.createdAt && moment().diff(moment(userModel?.createdAt), 'hours', true) <= 72
    const authenticated = !!allow.find(group => groups.includes(group)) || groups.includes('Admin') || isFreeTrial || window.location.pathname.includes('generators/angle-ranking');

    if (bootcamp === 'anatomy' && groups.includes('MedSchoolBootcamp') && !groups.includes('AnatomyBootcamp')) {
      const pathArgs = window.location.pathname.split('/');
      pathArgs[1] = 'med-school';
      window.location.pathname = pathArgs.join('/')
    }
    return {authenticated, redirectTo: {pathname: `/${bootcamp}/upgrade`}}
  } catch (error) {
    return {authenticated: false}
  }
}

const ProtectedRoute = ({allow, routeProps, color}) => {
  // TODO update this to use cognitoUser from state and make sure that anytime we update the user (logout / checkout / membership expiry)
  // we call refreshSession
  const {refreshSession, loading, cognitoUser, bootcamp, setBootcamp, userModel, searchUserInteractions, saveUserInteraction} = useUserDataContext();

  const defaultAuthState = {
    authenticated: null,
    redirectTo: {
      pathname: '/auth',
      search: `#/signin?redirectTo=${routeProps.location.pathname}${routeProps.location.search}`,
    },
  };

  const [{authenticated, redirectTo}, setAuthState] = useState(defaultAuthState);
  const {location, computedMatch} = routeProps;
  const routeBootcamp = computedMatch?.params?.bootcamp;

  const authenticate = async () => {
    const user = cognitoUser;

    if (!user) return setAuthState(prevState => ({...prevState, authenticated: false}));

    const {streak, longestStreak, date: streakDate} = searchUserInteractions(`Streak-DWU-${bootcamp}`)
    const {today, streakCompletedToday, streakActive} = getStreakDetails(streak, streakDate);
    if (!streakCompletedToday) {
      const streakData = {
        streak: streak > 0 && streakActive ? streak + 1 : 1,
        date: today.format('M/D/YYYY'),
        longestStreak: streak + 1 > longestStreak && streakActive ? streak + 1 : (longestStreak || streak + 1)
      }
      saveUserInteraction(`Streak-DWU-${bootcamp}`, JSON.stringify(streakData))
    }

    const permissionProps = checkRoutePermissions(user, allow, bootcamp, userModel);
    setAuthState(prevState => ({...prevState, ...permissionProps}));
  };

  useEffect(() => {
    // authenticate after loading UserData
    !loading && authenticate();
  }, [loading]);

  useEffect(() => {
    if (!routeBootcamp) return;

    setBootcamp(routeBootcamp);
  }, [routeBootcamp]);

  useEffect(() => {
    // refresh session when path changes IFF we're not currently loading UserData,
    // i.e. on initial page load
    !loading && authenticated && refreshSession();
  }, [location.pathname]);

  if (!allow) return <Route {...routeProps} />;


  if ((!!bootcamp && !!routeBootcamp) && bootcamp !== routeBootcamp) return null;

  return authenticated !== false
    ? <Route {...routeProps}/>
    : <Redirect to={redirectTo} />
};

const TBCRoute = ({allow, color, ...routeProps}) => {
  const isProtected = !!allow || (Array.isArray(allow) && !!allow.length);

  // if we aren't enforcing any auth on this via the allow prop, return the route
  // TLDR; if a TBCRoute isn't passed an allow prop, it is a public route
  return isProtected
    ? <ProtectedRoute allow={allow} routeProps={routeProps} color={color}/>
    : <Route {...routeProps}/>
};

const AnatomyRoute = (props) => {
  const routeBlacklist = [];
  const freeRoutes = ['bank-1', 'lesson-1', 'lesson-2', 'lecture-1', 'practical-1'];
  const {themePalette} = anatomyRouteStyles.find(({route}) => props.computedMatch.url.match(route)) || {themePalette: colors.brandPalette.indigo};

  const {classroom, chapter, lesson} = props.computedMatch.params;
  const depth = props.location.pathname.split('/').length;

  const isContentRoute = props.path.match('home');
  const isValidRoute = (depth < 5 && props.path.match('home/review')) || (depth < 6 && getAnatomyConfigObject(classroom, chapter, lesson)) || (lesson && lesson.match('lesson-1'));
  const isLockedRoute = !!routeBlacklist.find(route => route === classroom);

  if (isContentRoute && props.path !== '/home' && (isLockedRoute || !isValidRoute)) return <Redirect to={'/not-found'}/>;
  if (chapter === 'practicals' && !lesson) return <Redirect to={`/home/${classroom}`} />

  const allow = classroom === 'physiology' ? ['Free'] : !!freeRoutes.find(route => route === lesson) ? ['Free'] : props.allow;

  return (
    <RouteBackground color={themePalette.dark}>
      <TBCRoute {...props} allow={allow} color={themePalette.dark}/>
      {!lesson && <Fade/>}
    </RouteBackground>
  );
};

const TbcRootRoute = ({themePalette, ...props}) => {
  const {DEFAULT_USER_ID, loading} = useUserDataContext();

  if (!loading && DEFAULT_USER_ID) return <Redirect to={'/home'}/>;

  return loading
    ? <Loader active={true} background={colors.brandPalette.indigo.dark}/>
    : <Route themePalette={themePalette} {...props}/>
};

const OCRoute = ({themePalette, noFade, ...props}) => {
  const freeRoutes = ['bank-1', 'lesson-1', 'lesson-2'];

  const {classroom, chapter, contentSlug} = props.computedMatch.params;
  const isContentRoute = props.path.match('home');
  const depth = props.location.pathname.split('/').length;
  const isValidRoute = (depth < 5 && props.path.match('home/review')) || (depth < 6 && getChemistryConfigObject(classroom, chapter, contentSlug));

  if (isContentRoute && props.path !== '/home' && !isValidRoute) return <Redirect to={'/not-found'}/>;

  const allow = !!freeRoutes.find(route => route === contentSlug) ? ['Free'] : props.allow;

  return (
    <RouteBackground color={themePalette.dark}>
      <TBCRoute {...props} allow={allow} color={themePalette.dark}/>
      {noFade ? null : <Fade/>}
    </RouteBackground>
  );
};

const AdminRoute = props => <TBCRoute allow={['Admin']} {...props}/>;

const AdminNavBarRoute = (props) => ([
  <NavBar key={'nav-bar'} activeRoute={props.path}/>,
  <RouteContainer key={'route-container'}>
    <AdminRoute {...props}/>
  </RouteContainer>,
  <Footer key={'footer'}/>
]);

const OCNavBarRoute = ({location, match, themePalette, ...props}) => {
  return ([
    <TbcNavBar key={'nav-bar'} themePalette={themePalette} config={chemistryConfig} titleComponent={<ChemistryTitle/>}/>,
    <NavBarRouteContainer key={'route-container'} color={themePalette.dark}>
      <OCRoute themePalette={themePalette} location={location} match={match} {...props}/>
    </NavBarRouteContainer>
  ]);
};

const AnatomyNavBarRoute = ({location, match, ...props}) => {
  const {themePalette} = anatomyRouteStyles.find(({route}) => location.pathname.match(route)) || {themePalette: colors.brandPalette.indigo};

  return ([
    <TbcNavBar key={'nav-bar'} themePalette={themePalette} config={anatomyConfig} titleComponent={<AnatomyTitle/>}/>,
    <NavBarRouteContainer key={'route-container'} color={themePalette.dark}>
      <AnatomyRoute location={location} match={match} {...props}/>
    </NavBarRouteContainer>
  ]);
};

const UpgradeRoute = ({location, match, hideMenu, withHomeButton, fullHeight=true, ...props}) => {
  const {themePalette} = anatomyRouteStyles.find(({route}) => location.pathname.match(route)) || {themePalette: colors.brandPalette.royal};

  return ([
    <TbcNavBar key={'nav-bar'} themePalette={themePalette} config={anatomyConfig} hideMenu={hideMenu} withHomeButton={withHomeButton}/>,
    <UpgradeRouteContainer key={'route-container'} color={themePalette.dark} fullHeight={fullHeight}>
      <TBCRoute location={location} match={match} {...props}/>
    </UpgradeRouteContainer>
  ]);
};

export {AdminRoute, AdminNavBarRoute, TbcRootRoute, TBCRoute, AnatomyRoute, RouteBackground, AnatomyNavBarRoute, OCNavBarRoute, OCRoute, UpgradeRoute, Fade, ProtectedRoute};
