import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';

import {FlexBox, Column, Row} from '../../../components/Grid';
import {H3, Body3} from '../../../components/Typography';
import ProgressBar from '../../../components/ProgressBar';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '../../../contexts/UserData';
import {Upgrade, SubmitAnatomyCase} from '../../../components/Modal';
import { Label2 } from '../../../components/Typography/next';


const LinkWrapper = styled(Link)`
  width: 100%;
  margin: auto;
  text-decoration: none;
  cursor: ${({loading}) => loading ? 'not-allowed' : 'pointer'};
  user-select: none;
  position: relative;
  display: inline-block;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  transition: all 100ms ease;
  border-radius: 8px;
  overflow: hidden;
  background: ${({theme}) => theme.colors.special.pearl};
  ${({locked, theme}) => locked ? css`
    opacity: 0.7;
  ` : css`
    &:hover {
      transform: scale(1.01);
    }
  `}
`;
const Container = styled(FlexBox)`
  padding: 16px 24px;
  align-items: ${({showProgress}) => showProgress ? 'flex-start' : 'center'};
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => theme.layouts.spacing.m}
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  ${({theme}) => theme.mediaQueries.mobileXl} {
    flex-direction: column;
  }
`;
const ChapterTitle = styled(H3)`
  margin-bottom: ${({theme, showProgress}) => showProgress ? theme.layouts.spacing.s : 0};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({showProgress}) => showProgress ? '8px' : 0};
  }
`;
export const ChapterNumber = styled(H3)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;
export const Left = styled(Column)`
  width: auto;
  height: auto;
  align-items: center;
  align-self: ${({showProgress}) => showProgress ? 'stretch' : 'center'};
  justify-content: space-between;
`;
export const Middle = styled(Column)`
  flex: 1;
  padding: 0px 16px;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 0px 16px;
    flex-direction: column;
  }
`;
export const Right = styled(Row)`
  width: auto;
  height: auto;
  align-self: center;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};

  ${({theme}) => theme.mediaQueries.mobileXl} {
    width: 100%;
    align-items: normal;
    justify-content: normal;
    padding: 0px 16px;
    margin-top: 8px;
  }
`;
const ProgressBarWrapper = styled.div`
  width: 100%;
  ${({fullWidthProgress}) => !fullWidthProgress && `max-width: 630px;`}
  ${({hidden}) => hidden && `visibility: hidden;`}
`;

const defaultProgress = {
  reviewing: 0,
  learning: 0,
  mastered: 0,
  total: 0,
};

const UpgradeCover = styled(Row)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: ${({theme}) => theme.overlays.opacity.light._300};
  backdrop-filter: blur(0px);
  border-radius: 8px;
  transition: opacity .1s;
  z-index: 1;
  &:hover {
    backdrop-filter: blur(10px);
    opacity: 1;
  }
`;
const UpgradeText = styled(Row)`
  height: auto;
  justify-content: center;
  align-items: center;
  font-family: 'proxima-nova';
  font-weight: ${({theme}) => theme.font.size.semibold};
  font-size: ${({theme}) => theme.font.size.regular};
  line-height: ${({theme}) => theme.font.height.regular};
  color: ${({themePalette}) => themePalette.default};
`;

const FooterText = styled(Body3)`
  color: ${({themePalette}) => themePalette.dark};
`;

const UpgradeBadge = styled(Badge)`
  ${({large}) => large ? css`
    height: 24px;
    width: 24px;
    path {
      fill: ${({themePalette}) => themePalette.dark};
    }
  ` : css`
    height: 16px;
    width: 16px;
    margin-right: 8px;
    path {
      fill: ${({themePalette}) => themePalette.default};
    }
  `}
`;
const Details = styled(Label2)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-top: ${({theme}) => theme.layouts.spacing.s};
`;


const Chapter = ({
  className,
  name,
  route,
  progress,
  index,
  icon,
  questionCount,
  videoLessonCount,
  showVideoProgress,
  comingSoon,
  defaultLocked,
  simLocked,
  themePalette,
  label,
  progressSegments,
  showProgress=true,
  actions={},
  rawRoute=false,
  loading,
  fullWidthProgress,
  showPercentage,
  onClick,
  children,
  details
}) => {
  const {modalDispatch} = useModalContext();
  const {bootcamp} = useUserDataContext();
  const loadingProgress = progress === 'loading';

  const {learning, reviewing, mastered} = progress || defaultProgress;


  const segments = progressSegments || [
    {count: mastered},
    {count: reviewing},
    {count: learning}
  ];
  if (comingSoon) {
    return (
      <LinkWrapper as={'div'} className={className}>
        <Container>
          <Left>
            {icon}
            <ChapterNumber>
              {index}
            </ChapterNumber>
          </Left>
          <Middle>
            <ChapterTitle>In Progress: {name}</ChapterTitle>
            <MobileVisible>{children}</MobileVisible>
          </Middle>
          <MobileHidden>{children}</MobileHidden>
        </Container>
      </LinkWrapper>
    )
  }

  if (defaultLocked) {

    const onClickHandler = simLocked ?
      () => modalDispatch({
        type: 'open',
        component: SubmitAnatomyCase,
        componentProps: {
          headerText: 'This content has been locked by your university.',
          bodyText: `Stay tuned for more updates from your university for when this content will be available to you.`,
          confirmConfig: {
            text: 'OK'
          },
          cancelConfig: {
            text: null
          }
        },
        enableClickClose: true,
        modalContainerStyle: theme => `
        background: ${theme.overlays.opacity.dark._300};
        padding: 10vh 10vw;
        align-items: flex-start;
        ${theme.mediaQueries.tablet} {
          background: ${theme.overlays.opacity.dark._400};
          padding: 0;
        }
      `
      })
    : ['oat', 'inbde', 'dat', 'nclex'].includes(bootcamp)
      ? () => modalDispatch({
          type: 'open',
          component: Upgrade,
          enableClickClose: true,
          modalContainerStyle: theme => `
            padding: 10vh 10vw;
            align-items: flex-start;
            ${theme.mediaQueries.tablet} {
              padding: 0;
            }
          `
        })
      : () => window.location = `https://bootcamp.com/${bootcamp}/upgrade`;

    return (
      <LinkWrapper as={'a'} className={className} to={'#'} onClick={(loadingProgress || loading) ? () => {} : onClickHandler} loading={loadingProgress}>
        <Container>
          <Left showProgress={showProgress}>
            {icon}
            <ChapterNumber>
              {index}
            </ChapterNumber>
          </Left>
          <Wrapper>
            <Middle>
              <ChapterTitle showProgress={showProgress}>{name}</ChapterTitle>
              {showProgress &&
                <ProgressBarWrapper fullWidthProgress={fullWidthProgress}>
                  <ProgressBar
                    loading={loadingProgress}
                    segments={segments}
                    total={questionCount}
                    label={label}
                    lightMode
                  />
                </ProgressBarWrapper>
              }
              {children}
            </Middle>
            <Right>
              {actions}
            </Right>
          </Wrapper>
        </Container>
      </LinkWrapper>
    )
  }

  return (
    <LinkWrapper className={className} to={loading || onClick ? '#' : rawRoute ? route : ({pathname}) => `${pathname}/${route}`} onClick={(loadingProgress || loading) ? () => {} : onClick}>
      <Container showProgress={showProgress}>
        <Left showProgress={showProgress}>
          {icon}
          <ChapterNumber>
            {index}
          </ChapterNumber>
        </Left>
        <Wrapper>
          <Middle>
            <ChapterTitle showProgress={showProgress}>{name}</ChapterTitle>
            {showProgress &&
              <ProgressBarWrapper fullWidthProgress={fullWidthProgress}>
                <ProgressBar
                  loading={loadingProgress}
                  segments={segments}
                  total={showVideoProgress ? videoLessonCount : questionCount}
                  label={
                    showVideoProgress
                      ? <span>{<strong>{`${progress?.mastered || 0}`}</strong>} of {`${videoLessonCount} videos completed`}</span>
                      : label
                  }
                  lightMode
                  showPercentage={showPercentage}
                />
              </ProgressBarWrapper>
            }
            <MobileVisible>{children}</MobileVisible>
            {details && <Details>{details}</Details>}
          </Middle>
          <MobileHidden>{children}</MobileHidden>
          <Right>
            {actions}
          </Right>
        </Wrapper>
      </Container>
    </LinkWrapper>
  );
}
const MobileHidden = styled.div`
  align-self: center;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`
const MobileVisible = styled.div`
  display: none;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
    width: 100%;
    align-self: center;
  }
`
Chapter.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.element)
};

Chapter.defaultProps = {
  actions: [<ChevronRight size={20}/>]
}

export default Chapter;
