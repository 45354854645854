import {colors} from '@bootcamp/shared/src/styles/theme';

// TODO in this (and all) config files we need to completely DE-REACTIFY/DE-WEBIFY
// in other words: no markup or react web tools in these files
// this will also make our lives easier down the line when we move these
// config files into the cloud

const config = {
  classrooms: [
    {
      name: 'CARS Passages',
      tagId: 'fa65df0d-4cbe-4a25-8537-e8bc05c51f47',
      route: 'cars-passages',
      themePalette: colors.brandPalette.royal,
      classroomDescription: "It’s like the SAT all over again, except a little easier. Watch RC Academy, try a few untimed passages, and jump into the practice tests. You got this!",
      questionCount: 1038,
      icon: 'icons/rc.svg',
      educators: [
        {
          firstName: 'Sam',
          avatar: 'images/saml.jpeg',
        },
        {
          firstName: 'Winston',
          avatar: 'images/winstonc.gif',
        },
        {
          firstName: 'Katherine',
          avatar: 'images/katheriner.gif',
        }
      ],
      contentTypes: [
        {
          name: 'Question Banks',
          // icon: <StarIcon/>,
          calloutText: '',
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  id: 'f4497437-a868-415e-8e7f-25419fbc1579',
                  name: 'All Passages',
                  route: 'all-passages'
                },
                {
                  id: '2cc3158f-97cc-4e49-8a15-b7ddab026422',
                  name: 'Strategy Course FOC',
                  route: 'strategy-course-foc'
                },
                {
                  id: '9b8c8ff8-4186-4ee0-b36a-4548c3580ad1',
                  name: 'Strategy Course RBT',
                  route: 'strategy-course-rbt'
                },
                {
                  id: 'b5e09e43-ae08-4c1c-bd7b-f3b3cf8856f9',
                  name: 'Strategy Course RWT',
                  route: 'strategy-course-rwt'
                },

              ]
            }
          ],
        },
      ],
    },
  ],
  studyTools: [],
  upNext: [],
  meta: {
    siteTitle: 'MCAT Bootcamp',
    subjectIds: []
  }
};


export default config;
