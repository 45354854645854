import React, {useRef, useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';

import {Link, useLocation} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {Waypoint} from 'react-waypoint';

import {sideBarWidth} from '../../../router/components/SideBar';

import {H3} from '../../../components/Typography';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

const Title = styled(H3)`
  ${({active}) => !active && 'color: white'};
`;
const Active = styled.span`
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  bottom: 0px;
  border-radius: 8px 8px 2px 2px;
`;
const ItemContainer = styled(Link)`
  position: relative;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  padding: 16px 0px;
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  background: none;
  border: none;

  svg {
    width: 15px;
    stroke: ${({themePalette, active}) => active ? themePalette.default : 'white'};
    fill: ${({themePalette, active}) => active ? themePalette.default : 'white'};
  }

  &:last-child {
    margin-right: 0;
  }

  ${Title} {
    color: ${({theme, active}) => active ? theme.colors.neutralsPalette.white : theme.overlays.opacity.light._400};

    &:hover {
      color: ${({theme}) => theme.colors.neutralsPalette.white};
    }
  }

  ${Active} {
    display: none;
    ${({active}) => active && 'display: initial;'};
  }
`;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1152px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  overflow-x: auto;
  border-bottom: 1px solid ${({theme}) => theme.overlays.opacity.dark._100};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: 16px;
  }
  ${({sticky}) => sticky && css`
    position: fixed;
    top: 60px;
    z-index: 10;
    left: ${sideBarWidth}px;
    right: 0;
    margin: auto;
    padding: 0 ${({theme}) => theme.layouts.spacing.xl};

    ${({theme}) => theme.mediaQueries.laptop} {
      left: 0;
    }

    ${({theme}) => theme.mediaQueries.tablet} {
      top: 56px;
      padding: 0 ${({theme}) => theme.layouts.spacing.m};
    }
  `}
`;
const Placeholder = styled.div`
  margin-bottom: 44px;
`;

const Item = ({name, route, externalLink, themePalette, index}) => {
  const {pathname} = useLocation();
  const {bootcamp} = useUserDataContext();
  const pathParts = pathname.split('/');
  const currentPath = pathParts[pathParts.length - 1];
  const rootPath = pathParts
    .slice(0, -1)
    .join('/');

  const active = (currentPath && currentPath === route) || (!currentPath && index === 0);

  const urlParams =  new URLSearchParams(window.location.search);
  const linkTo = `${rootPath}/${route}` + (['videos', 'identify-structures', 'lecture-style-questions', 'atlas'].includes(route) ? `?${urlParams}` : '');

  function handleExternalLinkClick(e) {
    e.preventDefault();

    window.open(externalLink, '_blank')
  }

  return (
    <ItemContainer to={linkTo} active={active} themePalette={themePalette} onClick={externalLink && handleExternalLinkClick}>
      <Title active={active}>{bootcamp === 'med-school' && name === 'Board Style Questions' ? 'Step 1 Qbank' : name}</Title>
      <Active active={active}/>
    </ItemContainer>
  );
};

const PageTabs = ({tabs, themePalette}) => {
  const ref = useRef();

  const [sticky, setSticky] = useState(false);
  const [dragging, setDragging] = useState(false);

  // handler for making the tab nav 'sticky', called using the waypoint 'onLeave' callback
  const stickTabs = () => {
    if (dragging) return;
    document.getElementById('route-container').style.paddingTop = "122px";
    document.getElementById('tab-container').style.position = "fixed";

    setSticky(true)
  };

  // handler for 'unsticking' the tab nav, called using the waypoint 'onEnter' callback
  const unstickTabs = () => {
    document.getElementById('route-container').style.paddingTop = "72px";
    document.getElementById('tab-container').style.position = "relative";

    setSticky(false)
  };

  const handleMouseDown = useCallback((e) => {
    setDragging(true);
  }, []);

  const handleMouseUp = useCallback((e) => {
    const shouldSetSticky = ref?.current?._previousPosition !== 'inside' && window.innerWidth > 768;

    shouldSetSticky && stickTabs();

    setDragging(false);
  }, []);

  useEffect(() => {
    // adding these listeners to handle the scroll bar drag edge case. In a nutshell,
    // just defer any waypoint 'onLeave' events until the drag event is complete. We
    // let 'onEnter' events fire as nromal
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    }
  }, [])

  return ([
    window.innerWidth > 768 &&
      <Waypoint
        key={'waypoint-sticky-trigger'}
        ref={ref}
        onLeave={stickTabs}
        onEnter={unstickTabs}
        topOffset={"-10px"}
      />,
    <Container id="tab-container" key={'tab-container'} sticky={sticky} themePalette={themePalette}>
      {tabs.filter(el => el?.name).map(({name, route, externalLink}, index) => (
        <Item
          key={`sidebar-item-${index}`}
          name={name}
          route={route}
          index={index}
          themePalette={themePalette}
          externalLink={externalLink}
        />
      ))}
    </Container>,
    sticky && <Placeholder key={'tab-placeholder'}/>
  ]);
};

PageTabs.defaultProps = {
  tabs: PropTypes.shape({
    name: PropTypes.string,
    route: PropTypes.string,
  }),
};
PageTabs.propTypes = {};

export default PageTabs;
