import React from 'react';

import StartBlock from '../../../components/QBank/StartBlock';
import {Bookmark} from '@styled-icons/heroicons-outline/Bookmark';
import {Icon, IconWrapper} from '../Cards/ClassroomBookmark';

import {deSlugify} from '@bootcamp/shared/src/util';

import styled from 'styled-components';


const Subheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
`;
const StyledIconWrapper = styled(IconWrapper)`
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;
const StyledBookmark = styled(Bookmark)`
    stroke: ${({theme}) => theme.overlays.opacity.light._400};
`;
const TextWrapper = styled.span`
  height: 20px;
  width: 100%;
  border-radius: 8px;
  ${({theme, loading}) => theme.animation.loadingBackground({loading})};
`;

const slugOverrides = {};

const StartBookmarkedQuestionReview = ({classroom, username, totalQuestions, themePalette, loading, ...props}) => (
  <StartBlock
    header={`Bookmark Bank`}
    subheader={
      <Subheader>
        <StyledIconWrapper>
          <Icon color={themePalette.gradient} children={<StyledBookmark/>}/>
        </StyledIconWrapper>
        <TextWrapper loading={loading}>
          {!loading &&
            <>
              {totalQuestions} bookmarked {totalQuestions === 1 ? 'question' : 'questions'} {classroom && 'from'} {classroom && <strong>{slugOverrides[classroom] || deSlugify(classroom)}</strong>}
            </>
          }
        </TextWrapper>
      </Subheader>
    }
    loading={loading}
    themePalette={themePalette}
    descriptionHeader={`Ready to start, ${username || '____'}?`}
    descriptionText={`Study your tagged questions and check each answer as you go. You can end the review session whenever you’d like. Best of luck!`}
    overrideHeaderLoading
    {...props}
  />
);

StartBookmarkedQuestionReview.defaultProps = {};
StartBookmarkedQuestionReview.propTypes = {};

export default StartBookmarkedQuestionReview;
