import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {LoadingSpinner, Toggle, StyledLabel} from './shared';
import blurredBackground from '@bootcamp/shared/src/assets/images/dat-performance.png';
const SUBJECTS = ['Biology', 'General Chemistry', 'Organic Chemistry', 'Perceptual Ability', 'Reading Comprehension', 'Quantitative Reasoning']

const subjectColorMap = {
  'Biology': '#53A3DF',
  'General Chemistry': '#45BCBA',
  'Organic Chemistry': '#ED9625',
  'Perceptual Ability': '#BA3996',
  'Reading Comprehension': '#DC517C',
  'Quantitative Reasoning': '#6946BC',
}
const ChartBackground = styled.div`
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  padding: ${({theme}) => theme.layouts.spacing.s};
  border-radius: 8px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SubjectSelect = styled.select`
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border-radius: 8px;
  border: none;
  height: 32px;
  padding: 8px 12px;
  color: white;
  cursor: pointer;
  font-family: proxima-nova;
  font-size: 14px;
  // custom caret with two-line arrow
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20d%3D%22M2.81322%205.31334C2.90697%205.21971%203.03405%205.16711%203.16655%205.16711C3.29905%205.16711%203.42614%205.21971%203.51989%205.31334L7.99989%209.79334L12.4799%205.31334C12.5747%205.22502%2012.7%205.17694%2012.8296%205.17922C12.9591%205.18151%2013.0827%205.23398%2013.1743%205.32559C13.2659%205.4172%2013.3184%205.54079%2013.3207%205.67033C13.323%205.79986%2013.2749%205.92522%2013.1866%206.02001L8.35322%2010.8533C8.25947%2010.947%208.13239%2010.9996%207.99989%2010.9996C7.86738%2010.9996%207.7403%2010.947%207.64655%2010.8533L2.81322%206.02001C2.71959%205.92626%202.66699%205.79917%202.66699%205.66667C2.66699%205.53417%202.71959%205.40709%202.81322%205.31334Z%22%20fill%3D%22%23FAFAFA%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  &:focus {
    outline: none;
  }

`

export const PerformanceChart = ({averages, fetching, subjectOverride=''}) => {
  const {searchUserInteractions, bootcamp, saveUserInteraction} = useUserDataContext();
  const [selectedSubject, setSelectedSubject] = useState(subjectOverride);
  const [gradient, setGradient] = useState();
  const chartRef = useRef();

  const {sortOrder} = searchUserInteractions(`performance_order`, 'DESC');
  const performanceRecord = searchUserInteractions(`${bootcamp}_performance`);
  const testResultsOverTime = performanceRecord.results?.map(({testSubject, testNumber, score, createdAt}) => ({testSubject, testNumber, score, createdAt})) || [];
  const data = [...testResultsOverTime].sort((a, b) => sortOrder === 'DESC' ? new Date(b.createdAt) - new Date(a.createdAt) : new Date(a.createdAt) - new Date(b.createdAt));
  const filteredTestResultsOverTime = data.filter(({testSubject}) => testSubject === selectedSubject || selectedSubject === 'All');

  useEffect(() => {
    if (fetching) return;
    if (!selectedSubject) {
      for (const subject of SUBJECTS) {
        const firstSubject = data.find(({testSubject}) => testSubject === subject);
        if (firstSubject) {
          setSelectedSubject(firstSubject.testSubject);
          break;
        }
      }
      setSelectedSubject(SUBJECTS[0])
    }

    const ctx = chartRef.current?.ctx;
    let gradient;
    if (ctx && selectedSubject) {
      // create a similar linear gradient to the following:
      // linear-gradient(180deg, rgba(98, 85, 229, 0.20) 27.22%, rgba(98, 85, 229, 0.05) 100%);
      gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current.chartArea.bottom - chartRef.current.chartArea.top);
      gradient.addColorStop(0, `${subjectColorMap[selectedSubject]}AA`);
      gradient.addColorStop(1, `${subjectColorMap[selectedSubject]}10`);
      setGradient(gradient)
    }

  }, [fetching, selectedSubject, chartRef.current]);

  const dataset = {
    label: selectedSubject,
    data: filteredTestResultsOverTime.reduce((acc, {testSubject: ts, score, createdAt, testNumber}) => {
      if (ts !== selectedSubject) return acc;
      return [
        ...acc,
        {x: createdAt, y: score, dataLabel: `${ts} #${testNumber}: ${score}`}
      ];
    }, []),
    borderColor: subjectColorMap[selectedSubject],
    pointColor: subjectColorMap[selectedSubject],
    pointBackgroundColor: subjectColorMap[selectedSubject],
    pointRadius: 5,
    pointHoverRadius: 6,
    hoverBorderWidth: 5,
    hitRadius: 8,
    borderWidth: 3,
    cubicInterpolationMode: 'monotone',
    fill: true,
    backgroundColor: gradient,
  }

  const chartData = {
    labels: filteredTestResultsOverTime.map(({createdAt}) => createdAt),
    datasets: [dataset]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'line',
    normalized: true,
    scales: {
      x: {
        type: 'timeseries',
        time: {
          unit: 'day',
        },
        ticks: {
          font: {
            family: 'proxima-nova',
            size: 14,
            weight: 'bold',
          },
          color: '#C8C9CE'
        },
        title: {
          display: false,
          text: 'Date',
          font: {
            family: 'proxima-nova',
            size: 16,
            weight: 'bold',
          },
          color: '#C8C9CE'
        },
        grid: {
          display: false, // Hide the gridlines on the y-axis
        },
      },
      y: {
        min: 10,
        suggestedMax: 30,
        ticks: {
          font: {
            family: 'proxima-nova',
            size: 16,
            weight: 'bold',
          },
          color: '#C8C9CE'
        },
        border: {
          display: false,
          dash: [2, 2],
        },
        grid: {
          color: '#C8C9CE',
          tickBorderDash: [2, 2],
          tickBorderDashOffset: 0,
        },
        title: {
          display: true,
          text: `${selectedSubject} Score`,
          font: {
            family: 'proxima-nova',
            size: 16,
            weight: 'bold',
          },
          color: '#C8C9CE'
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: 'proxima-nova',
          weight: 'normal',
        },
        bodyFont: {
          family: 'proxima-nova',
          weight: 'bold',
        },
        footerAlign: 'center',
        footerFont: {
          family: 'proxima-nova',
          weight: 'normal'
        },
        footerMarginTop: 12,
        callbacks: {
          label: context => {
            // display dataLabel
            if (context.dataset.data[context.dataIndex].dataLabel) {
              return context.dataset.data[context.dataIndex].dataLabel;
            }
          },
          footer: context => {
            // display average for each subject in tooltip using averages array
            return context.map(({dataset}) => {
              const subject = dataset.label;
              const subjectIndex = SUBJECTS.indexOf(subject);
              const subjectAverage = averages[subjectIndex].content;
              return `${subject} Avg: ${subjectAverage}`
            })
          }
        }
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          font: {
            family: 'proxima-nova',
          }
        }
      },
    },
  };

  return (
    <>
      <StyledLabel>Performance Over Time</StyledLabel>
      <div style={{display: 'flex', flexDirection: 'row', gap: 8, marginBottom: 16}}>
        <Toggle value={sortOrder === 'DESC'} setValue={() => saveUserInteraction(`performance_order`, JSON.stringify({sortOrder: sortOrder === 'ASC' ? 'DESC' : 'ASC'}))} />
        {!subjectOverride && (
          <SubjectSelect value={selectedSubject} onChange={e => {
            setSelectedSubject(e.target.value);
          }}>
            <option disabled selected>Select a subject...</option>
            {SUBJECTS.map(subject => <option value={subject}>{subject}</option>)}
          </SubjectSelect>
        )}
      </div>
      <ChartBackground>
        {fetching ? <LoadingSpinner color="royal" active /> : filteredTestResultsOverTime?.length < 2 ? (
          <div style={{
            height: '100%',
            width: '100%',
            background: `url(${blurredBackground}) no-repeat center center`,
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 8}}>
            <h3 style={{fontFamily: 'proxima-nova', fontSize: 18, fontWeight: 'bold', color: '#85868E'}}>Not enough data to display chart yet</h3>
            <p style={{fontFamily: 'proxima-nova', fontSize: 16, fontWeight: 'normal', color: '#85868E'}}>Come back when you've completed at least 2 practice tests!</p>
          </div>
        ) : <Line ref={chartRef} data={chartData} options={chartOptions} />}
      </ChartBackground>
    </>
  )
}