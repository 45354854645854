import styled from 'styled-components';

import baseTheme from '@bootcamp/shared/src/styles/theme';


export const Hero = styled.span`
  font-family: proxima-nova;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: -1px;
`;
export const H1 = styled.h1`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.xsuper};
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`;
export const H2 = styled.h2`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.xlarge};
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
`;
export const H3 = styled.h3`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.large};
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
`;

export const H4 = styled.h4`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.regular};
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
`;

export const Label1 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.regular};
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin: 0;
  color: ${baseTheme.colors.neutralsPalette.extraDark};
`;

export const Label2 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
  color: ${baseTheme.colors.neutralsPalette.white};
`;

export const Label3 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.small};
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
  color: ${baseTheme.colors.neutralsPalette.white};
`;

export const Body2 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.regular};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`;

export const Body3 = styled.p`
  font-family: proxima-nova;
  font-size: ${({theme}) => theme.font.size.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`;