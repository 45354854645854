import firstBy from 'thenby';
/*
  0/1
  bowtie
  dragAndDropCloze
  dragAndDropSelectN
  dragAndDropOrderedResponse
  dropdown
  dropdownTable
  fillInTheBlank
  hotSpot
  matrixMultipleChoice
  multipleChoice
  multipleResponseSelectN

  +/-
  dragAndDropSATA
  matrix
  multipleResponse
  multipleResponseGrouping
  highlight
  highlightSelectN (NOTE no example questions)

  rationale
  dragAndDropRationale (NOTE using percentage here for now)
  dropdownRationale
*/

export function getScoringRule (type) {
  switch (type) {
    case 'bowtie':
    case 'dragAndDropCloze':
    case 'dragAndDropSelectN':
    case 'dragAndDropOrderedResponse':
    case 'dropdown':
    case 'dropdownCloze':
    case 'dropdownTable':
    case 'fillInTheBlank':
    case 'hotSpot':
    case 'matrixMultipleChoice':
    case 'multipleChoice':
    case 'multipleResponseSelectN':
      return 'zeroOne'
    case 'dragAndDropSATA':
    case 'matrix':
    case 'multipleResponse':
    case 'multipleResponseGrouping':
    case 'highlight':
    case 'highlightSelectN':
      return 'plusMinus'
    case 'dragAndDropRationale':
    case 'dropdownRationale':
      return 'rationale'
    default:
      return ''
  }
}

export function calculateMaxScore(prompt, type, answers, answerGroups, answerMatrix) {
  switch (type) {
    case 'bowtie':
      return 5;
    case 'dragAndDropCloze':
      return answers?.filter(answer => answer[1])?.length;
    case 'dragAndDropRationale':
      const dndRationaleMaxScore = answerGroups.reduce((acc, curr) => {
        const groupContents = JSON.parse(curr.contents || '[]');
        return acc + groupContents.filter(group => group?.[1]).length;
      }, 0)
      return dndRationaleMaxScore - 1;
    case 'dragAndDropSATA':
      return answers?.filter(answer => answer[1])?.length;
    case 'dragAndDropSelectN':
      return answers?.filter(answer => answer[1])?.length;
    case 'dropdown':
      return answerGroups?.length;
    case 'dropdownRationale':
      return answerGroups?.length - 1;
    case 'dropdownTable':
      return answerGroups?.length;
    case 'highlight':
    case 'highlightSelectN':
      return prompt?.match(/.fr-highlight-correct/g)?.length || 0;
    case 'matrix':
    case 'matrixMultipleChoice':
    case 'multipleResponseGrouping':
      // count how many "trues" exist in answers 2d matrix or array
      const matrixAnswers = JSON.parse(answerMatrix[0].contents || '[]');

      return matrixAnswers.reduce((acc, answerGroup) => {
        return acc + answerGroup.reduce((acc, answerGroup) => (
          Array.isArray(answerGroup)
            ? acc + answerGroup.reduce((acc2, group) => acc2 + group, 0)
            : answerGroup === true
              ? acc + 1
              : acc
        ), 0);
      }, 0)
    case 'multipleChoice':
      return answers.reduce((acc, answerGroup) => {
        if (answerGroup[1] === true) acc += 1
        return acc;
      }, 0)
    case 'multipleResponse':
    case 'multipleResponseSelectN':
      return answers?.filter(answer => answer[1])?.length;
    default:
      return 1;
  }
}

export function getScores(prompt, type, answerState=[], answers, answerGroups, hotSpotImageSelection, answerMatrix) {
  function calculateScore() {
    let summedScore = 0;

    switch (type) {
      // 0/1 SCORING RULES
      // 1 point for every correct answer
      // 0 point for every incorrect answer
      case 'bowtie':
        if (Object.entries(answerState).length !== 5) return 0;
        return Object.values(answerState).filter(answer => answer[2]).length
      case 'dragAndDropCloze':
        return Object.values(answerState || {}).filter(answer => answer[2]).length;
      case 'dragAndDropSelectN': // SELECT N - can receive +1 for each selected
        return answerState?.filter(answer => answer[2]).length;
      case 'dragAndDropOrderedResponse':
        return answers?.every(([a, b, orderIndex], index) => orderIndex === answerState?.[index]?.[3]) ? 1 : 0
      case 'dropdown':
      case 'dropdownTable':
        return answerState?.filter(answer => answer?.[1])?.length;
      case 'fillInTheBlank':
        // answerState will be string
        const isCorrect = parseFloat(answerState) === parseFloat(answers?.[0]?.[0]);
        return isCorrect ? 1 : 0;
      case 'hotSpot':
        // answerState will be true/false, updated on click event
        const [x, y] = answerState;
        return (
          x >= hotSpotImageSelection.x &&
          x <= hotSpotImageSelection.x + hotSpotImageSelection.width &&
          y >= hotSpotImageSelection.y &&
          y <= hotSpotImageSelection.y + hotSpotImageSelection.height
        ) ? 1 : 0;
      case 'matrixMultipleChoice':
        if (!answerState?.length) return 0;
        const matrixMultipleChoiceAnswers = JSON.parse(answerMatrix[0].contents || '[]');
        const matrixMultipleChoiceScore = answerState?.reduce((acc, row, rowIndex) => {
          const selectedIndex = row.findIndex(value => !!value);
          let newTotal = acc;
          if (matrixMultipleChoiceAnswers?.[rowIndex]?.[selectedIndex]) newTotal++;
          return newTotal;
        }, 0);
        return matrixMultipleChoiceScore;
      case 'multipleChoice':
        return answerState.some(answer => answers?.[answer]?.[1]) ? 1 : 0
      case 'multipleResponseSelectN': // SELECT N - can receive +1 for each selected
        return answerState.filter(answer => answers?.[answer]?.[1]).length

      // +/- SCORING RULES
      case 'dragAndDropSATA':
        if (!answerState?.length) return 0;
        summedScore = answerState.reduce((acc, answer) => answer[2] ? acc += 1 : acc -= 1, 0);
        return Math.max(summedScore, 0);
      case 'highlight':
      case 'highlightSelectN':
        summedScore = answerState?.reduce((acc, answer) => answer[1] ? acc += 1 : acc -= 1, 0);
        return Math.max(summedScore, 0);
      case 'matrix': // Matrix Multiple Response
        if (!answerState?.length) return 0;
        const matrixAnswers = JSON.parse(answerMatrix[0].contents || '[]');
        const columnAnswerState = answerState[0].map((col, i) => answerState.map(row => row[i]));
        const matrixColumns = matrixAnswers[0].map((col, i) => matrixAnswers.map(row => row[i]));
        const matrixScore = columnAnswerState?.reduce((acc, column, columnIndex) => {
          summedScore = column.reduce((acc, value, index) => value && matrixColumns[columnIndex][index] === value ? acc += 1 : value ? acc -= 1 : acc, 0);
          return acc + Math.max(summedScore, 0);
        }, 0);
        return Math.max(matrixScore, 0);
      case 'multipleResponse': // Multiple Response SATA
        summedScore = answerState.reduce((acc, answer) => answers[answer][1] ? acc += 1 : acc -= 1, 0);
        return Math.max(summedScore, 0)
      case 'multipleResponseGrouping': // Multiple Response SATA, scored by grouping
        const mrgMatrixAnswers = JSON.parse(answerMatrix[0].contents || '[]');
        // calculate +/- score per row, minimum of 0
        const multipleResponseGroupingScore = answerState?.reduce((acc, row, rowIndex) => {
          summedScore = row[0].reduce((acc, value, index) => value && mrgMatrixAnswers[rowIndex][0][index] === value ? acc += 1 : value ? acc -= 1 : acc, 0)
          return acc + Math.max(summedScore, 0);
        }, 0);
        return multipleResponseGroupingScore;

      // RATIONALE SCORING RULES
      case 'dragAndDropRationale':
        // make sure that answer keys are ordered
        const sortedAnswerState = Object
          .keys(answerState || {})
          .sort(firstBy(key => parseInt(key?.split('-').slice(-2)[0])))
          .map(key => answerState[key]);

        if (sortedAnswerState?.length < 2) {
          return 0;
        } else if (sortedAnswerState.length === 2) {
          return sortedAnswerState.every(answer => answer?.[2]) ? 1 : 0;
        } else if (sortedAnswerState.length === 3) {
          const firstIsCorrect = sortedAnswerState?.[0]?.[2];
          // only score if first of triad is correct
          if (!firstIsCorrect) return 0;
          // otherwise can us dyad score - both right = max score (2) one right = partial credit (1)
          return sortedAnswerState.slice(1).filter(answer => answer[2])?.length;
        } else {
          return 0;
        }
      case 'dropdownRationale':
        if (answerState?.length < 2) {
          return 0;
        } else if (answerGroups.length === 2) {
          return answerState?.every(answer => answer[1]) ? 1 : 0;
        } else if (answerGroups.length === 3) {
          const firstIsCorrect = answerState?.[0]?.[1];
          // only score if first of triad is correct
          if (!firstIsCorrect) return 0;
          // otherwise can us dyad score - both right = max score (2) one right = partial credit (1)
          return answerState.slice(1).filter(answer => answer[1])?.length;
        } else {
          return 0;
        }
      default:
        return 0;
    }
  }

  return {
    score: calculateScore(),
    maxScore: calculateMaxScore(prompt, type, answers, answerGroups, answerMatrix)
  }
}

const typeFormatters = {
  multipleChoice: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');
    const selectedAnswerIndex = parsedAnswerState?.[0];

    if (selectedAnswerIndex === undefined) return acc;

    const updatedTotals = acc?.totals?.[selectedAnswerIndex] ? acc?.totals[selectedAnswerIndex] + 1 : 1;
    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      ...acc,
      totals: {
        ...acc?.totals,
        [selectedAnswerIndex]: updatedTotals
      },
      numCorrect,
      totalAnswered,
      percentage: numCorrect / totalAnswered,
    };
  },
  multipleResponse: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      ...acc,
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    };
  },
  fillInTheBlank: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": ""}');

    if (parsedAnswerState === "" || !parsedAnswerState) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      percentage: numCorrect / totalAnswered,
    };
  },
  hotSpot: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (parsedAnswerState.length !== 2) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      percentage: numCorrect / totalAnswered,
    }
  },
  matrix: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  matrixMultipleChoice: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  multipleResponseSelectN: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');
    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  multipleResponseGrouping: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dragAndDropSATA: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dragAndDropCloze: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!Object.keys(parsedAnswerState)?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dragAndDropRationale: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!Object.keys(parsedAnswerState)?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dragAndDropOrderedResponse: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      percentage: numCorrect / totalAnswered,
    }
  },
  dragAndDropSelectN: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dropdown: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dropdownRationale: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  dropdownTable: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
  bowtie: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!Object.keys(parsedAnswerState)?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + Math.min(score, 1);

    return {
      numCorrect,
      totalAnswered,
      percentage: numCorrect / totalAnswered,
    }
  },
  highlight: (acc, answerState) => {
    const {answerState: parsedAnswerState, score} = JSON.parse(answerState || '{"answerState": []}');

    if (!parsedAnswerState?.length) return acc;

    const totalAnswered = (acc?.totalAnswered || 0) + 1;
    const numCorrect = (acc?.numCorrect || 0) + score;

    return {
      numCorrect,
      totalAnswered,
      avgScore: numCorrect / totalAnswered,
    }
  },
};

export function answerDataFormatter(type, progresses) {
  const defaultFormatter = answerState => answerState;
  const typeFormatter = typeFormatters[type] || defaultFormatter;

  return progresses.reduce((acc, progress, index) => {
    try {
      return {
        ...acc,
        [progress.questionRevisionId]: typeFormatter(acc[progress.questionRevisionId], progress.answerState, index)
      }
    } catch (error) {
      return acc;
    }
  }, [])
}

export function checkAnswerState(answerState) {
  try {
    return !(!answerState || ['[]', '{}'].includes(JSON.stringify(answerState)));
  } catch (error) {
    return false;
  }
}