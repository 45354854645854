import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {PageSection} from '../ClassroomContentWrapper';
import {DownloadButton} from '../Cards/components/Buttons';
import Download from '../Cards/Download';
import Callout from '../Cards/Callout';

const Container = styled.div`
`;

const Anki = ({content, themePalette}) => {
  const decks = content.find(({name}) => name === 'Anki').content;
  return (
    <Container>
      <Callout children={`New to studying with <strong>Anki decks</strong>? <a style={{color: 'white'}} href="https://www.bootcamp.com/blog/studying-with-anki-decks" rel="noreferrer" target="_blank">Click here</a> to learn how to use Anki.`}/>
      <PageSection>
        {decks.map((item, index) => (
          <Download
            key={`${item.name}-${index}`}
            name={item.name}
            description={item.description}
            onClick={() => window.open(item.route, '_blank')}
            actions={[
              <DownloadButton themePalette={themePalette} children={'Study Anki Deck'} />
            ]}
          />
        ))}
      </PageSection>
    </Container>
  );
};

Anki.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
Anki.defaultProps = {
  content: [],
  data: {},
};

export default Anki;
