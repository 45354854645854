import React from 'react';
import styled from 'styled-components';
import useHotkeys from '@reecelucas/react-use-hotkeys';

import MasteryIcon from '@bootcamp/web/src/bootcamps/components/MasteryIcon';
import {Tooltip} from '@bootcamp/web/src/components/Tooltip';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';


import {capitalize} from '@bootcamp/shared/src/util';

import {Box, IconButton, MenuText, BoxSubsection} from './shared';
import {ReactComponent as StopSVG} from './assets/Stop.svg';
import {ReactComponent as SuspendSVG} from './assets/Suspend.svg';


const TimeWrapper = styled.div`
  padding: 4px;
`;
const MasteryWrapper = styled.div`
`;
const ButtonWrapper = styled.div`
  display: flex;
`;
const TimeLeft = styled(MenuText)`
  margin-bottom: 4px;
`;
const TimedText = styled(MenuText)`
  font-size: 14px;
`;

const StopIcon = styled(StopSVG)``;

const EndButton = styled(IconButton)`
`;
const SuspendButton = styled(IconButton)`
  color: ${({theme}) => theme.colors.interfacePalette.yellow.default};
  margin-left: auto;
  margin-right: 20px;
`;
const MasteryButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  color: white;
  padding: 2px 4px;

  &:hover {
    background: rgb(40, 50, 148);
  }

  img {
    width: 26px;
    height: 26px;
    margin-bottom: 2px;
  }
`;
const KeyboardShortcut = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 3px;
  font-family: 'proxima-nova';
`;

const MasteryButton = ({masteryLevel, onClick, hotkey}) => {
  const {searchUserInteractions} = useUserDataContext();
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;

  useHotkeys(hotkey, onClick, [onClick]);

  return (
    <MasteryButtonWrapper onClick={onClick}>
      <MasteryIcon level={masteryLevel} squared/>
      <span>
        {capitalize(masteryLevel)}
        {showKeyboardShortcuts && <KeyboardShortcut>[{hotkey}]</KeyboardShortcut>}
      </span>
    </MasteryButtonWrapper>
  );
}

const Controls = ({handleSubmitClick, handleSuspendClick, onClickMasteryButton}) => {
  const {timeElapsed, longTimeLeft} = useTimerContext();

  const {
    variables: {
      quizProgress,
      blockIndex,
      customTestConfig,
      type,
      template
    }
  } = useTestNavigatorContext();

  const isReadinessExam = type === 'readinessExam';
  const {searchUserInteractions} = useUserDataContext();
  const hideTimer = searchUserInteractions('hideTimer') === true && !isReadinessExam;

  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const showingExplanation = questionProgresses[questionIndex]?.didCheck;
  const submitted = customTestConfig?.submitted || template === 'testReview';
 
  return (
    <Box style={{position: 'relative', padding: '2px 8px'}}>
      <TimeWrapper>
        {!hideTimer && !submitted && <TimeLeft>Block Time Elapsed: {customTestConfig?.config?.timed || isReadinessExam ? longTimeLeft : timeElapsed}</TimeLeft>}
        <TimedText>{submitted ? 'REVIEW' : customTestConfig?.config?.timed || isReadinessExam ? 'TIMED' : 'UNTIMED'}</TimedText>
      </TimeWrapper>
      <MasteryWrapper>
        {(showingExplanation || submitted) &&
          <div style={{width: '100%', display: 'flex'}}>
            <Tooltip dismissalInteractionKey="dismissedMasteryTagTutorial"/>
            <BoxSubsection>
              <MasteryButton
                hotkey={'1'}
                masteryLevel={'learning'}
                onClick={() => onClickMasteryButton('learning')}
              />
            </BoxSubsection>
            <BoxSubsection>
              <MasteryButton
                hotkey={'2'}
                masteryLevel={'reviewing'}
                onClick={() => onClickMasteryButton('reviewing')}
              />
            </BoxSubsection>
            <BoxSubsection>
              <MasteryButton
                hotkey={'3'}
                masteryLevel={'mastered'}
                onClick={() => onClickMasteryButton('mastered')}
              />
            </BoxSubsection>
          </div>
        }
      </MasteryWrapper>
      <ButtonWrapper>
        {!customTestConfig?.submitted && customTestConfig?.config && <SuspendButton Icon={SuspendSVG} text="Pause" onClick={handleSuspendClick} />}
        <EndButton Icon={StopIcon} text="End Block" onClick={handleSubmitClick} />
      </ButtonWrapper>
    </Box>
  )
}

export default Controls;
