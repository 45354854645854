import React from 'react';
import {Link} from 'react-router-dom';
import {H5, H6, Label3} from '../../../Typography';

import styled, {css} from 'styled-components';
import {useHotkeys} from 'react-hotkeys-hook';

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 71px;
  flex-direction: column;
  background: white;
  border: 1px solid white;
  border-radius: 8px;
  overflow: auto;
  max-height: 400px;
  width: 100%;
  max-width: 400px;
  z-index: 10;
  ${({theme}) => theme.mediaQueries.mobileL} {
    top: 55px;
    left: 0;
  }
`;

const ResultWrapper = styled(Link)`
  padding: 16px;
  padding-left: ${({tabbed}) => tabbed ? '32px' : '16px'};
  text-decoration: none;
  &:focus, &:hover {
    background: ${({theme}) => theme.overlays.opacity.dark._100};
  }

  ${({themePalette}) => !!themePalette && css`
    border-top: 1px solid ${({theme}) => theme.overlays.opacity.dark._50};
    border-bottom: 1px solid ${({theme}) => theme.overlays.opacity.dark._50};
    background: ${themePalette.light};
    &:focus, &:hover {
      background: ${themePalette.light}80;
    }
    &:first-child {
      border-top: none;
    }
  `}
`;

const ResultTitle = styled(H5)`
  color: ${({theme, themePalette}) => !!themePalette ? themePalette.dark : theme.colors.neutralsPalette.darkGrey};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
`;

const ContentType = styled(H6)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
`;
const BlockType = styled.span`
  font-size: 12px;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;

const blockTypeMap = {
  questionSet: '(Quiz)',
  lesson: ''
};

const Result = ({name, contentTypeName, route, themePalette, index, type}) => {
  const blockType = blockTypeMap[type];

  return (
    <ResultWrapper key={`${name}-${contentTypeName}-${route}-${index}`} to={route} themePalette={themePalette} tabbed={contentTypeName}>
      <ResultTitle themePalette={themePalette}>{name} {blockType && <BlockType>{blockType}</BlockType>} </ResultTitle>
      {contentTypeName &&
        <ContentType>
          {contentTypeName}
        </ContentType>
      }
    </ResultWrapper>
  );
}


const Results = ({items, resultsRef}) => {

  useHotkeys('up,down', (event, handler) => {
    event.preventDefault();
    if (document.activeElement.parentNode === resultsRef.current) {
      const targetElement = handler.key === 'down' ? document.activeElement.nextSibling : document.activeElement.previousSibling;
      targetElement?.focus();
    } else {
      resultsRef.current.children[0]?.focus();
    }
  }, []);

  if (!items.length) return null;

  return (
    <Container ref={resultsRef}>
      {items
        .filter(({type}) => type !== 'questionSet')
        .map(Result)
      }
    </Container>
  );
};

Results.defaultProps = {};
Results.propTypes = {};

export default Results;
