import React from 'react';
import styled from 'styled-components';

import {ReactComponent as Reviews} from './assets/reviews.svg';
import {ReactComponent as Home} from './assets/home.svg';
import {Star} from '@styled-icons/heroicons-solid/Star';
import {MagnifyingGlass} from '@styled-icons/heroicons-outline/MagnifyingGlass';

import {FlexBox} from '../../Grid';

const Circle = styled(FlexBox)`
  width: auto;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Circle)`
  svg {
    width: 64px;
    height: 64px;
  }
`;

export const HomeIcon = ({className}) => (
  <Icon className={className} children={<Home/>}/>
);

export const StudentReviewsIcon = ({className}) => (
  <Icon className={className} children={<Reviews/>}/>
);

export const StarIcon = ({className}) => (
  <Icon className={className} children={<Star/>}/>
);
export const SearchIcon = ({className}) => (
  <Icon className={className} children={<MagnifyingGlass/>}/>
);
