// // TODO this can actually probably live in web since it's only for Admin
// export const froalaKey = window.location.hostname.includes('datbootcamp.com')
//   ? 'XAG4eG3A2B10C9C5D6C-11ecwfjiskhgH-9F4nablA6F6D5D4A1D3C7A3D5A5=='
//   : window.location.hostname.includes('bootcamp.com')
//     ? 'UBB7jE6C3A4A2H3C10aD-16A-13mhlH3fjiskhgH-9F4nablA6F6D5D4A1D3C7A3D5A5=='
//     : 'XAG4eG3A2B10C9C5D6C-11ecwfjiskhgH-9F4nablA6F6D5D4A1D3C7A3D5A5=='
const SENTRY_DSN = '';
const QUIZLET_CLIENT_ID = '';
const VIMEO_ACCESS_TOKEN = '';

export {
  SENTRY_DSN,
  QUIZLET_CLIENT_ID,
  VIMEO_ACCESS_TOKEN,
};
