import React from 'react';
import styled from 'styled-components';
import {GutterRow, GutterInner} from '../Spacing';
import {Row} from '../Grid';

const FooterContainer = styled(GutterRow)`
  height: auto;
  ${GutterInner} {
    border-top: 2px solid ${({theme}) => theme.lightBorderColor};
    padding: 2em 0;
    margin-top: 65px;
  }
  ${GutterInner} > ${Row} {
    justify-content: space-between;
  }
`
const BrandNote = styled.div`

`
const GuidesLink = styled.a`
  color: ${({theme}) => theme.lightTextColor};
`

const Footer = () => {
  return (
    <FooterContainer>
      <BrandNote>🙂 Create an amazing study experience with Team Bootcamp</BrandNote>
      <GuidesLink href={'https://3.basecamp.com/3951843/buckets/6618722/vaults/2098990294'}>Guides</GuidesLink>
    </FooterContainer>
  );
}

export default Footer;
