import React from 'react';
import styled from 'styled-components';

import {H2} from '../../../../../components/Typography';
import {useUserDataContext} from '../../../../../contexts/UserData';
import MembershipCard from './Card';
import extensionPriceIdMap from '../../extensionPriceIds';
import Callout from '../../../../components/Cards/Callout';
import {firstBy} from 'thenby';

const Container = styled.div`
`;
const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.layouts.spacing.m};

  &:first-child {
    margin-top: 0;
  }
`;
const Title = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;

const trackGroups = {
  Dentistry: ['DATBootcamp', 'INBDEBootcamp', 'DentalSchoolBootcamp'],
  Chemistry: ['ChemistryBootcamp'],
  Anatomy: ['AnatomyBootcamp'],
  Optometry: ['OATBootcamp'],
  'Medical School': ['Step1Bootcamp', 'MedSchoolBootcamp'],
  'Nursing': ['NCLEXBootcamp'],
};

function consolidateExtensions(memberships) {
  return memberships.map(membership => {
    const extensions = memberships
      .filter(({productId}) => extensionPriceIdMap[membership.productId] === productId)

    return {
      ...membership,
      extensions,
      isExtension: Object.values(extensionPriceIdMap).some(id => id === membership.productId && !['price_1MMu4SD2xOfNJKfx3n9BAaui', 'price_1MMtErD2xOfNJKfxEFchnNp1', 'price_1MMtjSD2xOfNJKfx1m1w0v1L'].includes(membership.productId)),
    }
  });
}

function getMembershipsByBootcamp(memberships) {
  if (!memberships) return {};

  return consolidateExtensions(memberships).reduce((acc, curr) => {
    if (curr.isExtension) return acc; // skip extensions for now

    const type = Object
      .keys(trackGroups)
      .find(track => (
        trackGroups[track] // iterate through track groups to find matching group in membership groups
          .some(trackGroup => (
            curr.groups
            .some(group => trackGroup === group)
          ))
      ));

    const accType = acc[type] || [];

    return {
      ...acc,
      [type]: [...accType, curr].sort(firstBy('createdAt', -1)) // sort by most recently created
    };
  }, {});
}

const Memberships = () => {
  const {userModel} = useUserDataContext();

  const membershipsByTrack = getMembershipsByBootcamp(userModel?.memberships?.items);

  return (
    <Container>
      <Callout>Have a question about your membership? Open a chat with us using the blue button at the bottom-right of your screen! We'll respond ASAP.</Callout>
      {Object
        .keys(membershipsByTrack)
        .map(track => (
          <Section key={`track-section-${track}`}>
            <Title>{track || ''}</Title>
            {membershipsByTrack[track].map(membership => <MembershipCard key={membership.id} membership={membership} {...membership}/>)}
          </Section>
        ))
      }
    </Container>
  );
};

Memberships.defaultProps = {};
Memberships.propTypes = {};

export default Memberships;
