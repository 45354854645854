import React from 'react';
import styled from 'styled-components';

import {
  Container,
  Wrapper,
  Heading,
  HeadingResultContainer,
  Comment,
  StudentImageContainer,
  Rating,
  UpdatedStudentImage,
  Name,
  SubHeader,
} from './shared';

import {H3, Body3} from '../../../../Typography/next';

import tiffanie from '../assets/tiffanie_hilson.jpeg';
import alex from '../assets/alex_mendez.jpeg';
import caroline from '../assets/caroline_jetel.jpeg';
import brittany_prude from '../assets/brittany_prude.jpeg';
import isabella_trybula from '../assets/isabella_trybula.jpeg';
import octavio_ordaz from '../assets/octavio_ordaz.jpeg';
import raksha_pampliega from '../assets/raksha_pampliega.jpeg';
import kelley_campbell from '../assets/kelley_campbell.jpeg';


const outcome1 = (
  <Container>
    <Rating/>
    <Comment>
      "I took the NCLEX and saw a few questions that had the <strong>exact topics that I practiced on Bootcamp.</strong> The case studies are almost identical to the real NCLEX."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={tiffanie} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Tiffanie Hilson, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);
const outcome2 = (
  <Container>
    <Rating/>
    <Comment>
      "I am a repeat test taker, and I would not have passed my NCLEX this time around if it weren’t for Bootcamp. <strong>Bootcamp looked almost identical to the actual exam.</strong>"
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={alex} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Alex Mendez, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome3 = (
  <Container>
    <Rating/>
    <Comment>
      "When I found Bootcamp I felt like I had found heaven. <strong>It is so well organized and simplified and even has study schedules available</strong> which I found to be one of my favorite parts of the entire site!"
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={caroline} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Caroline Jetel, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome4 = (
  <Container>
    <Rating/>
    <Comment>
      "I passed on the first try with 85 questions. I printed the study calendar and did that for 30 days. To me, the NCLEX felt like I was working the Bootcamp case studies."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={brittany_prude} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Brittany Prude, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome5 = (
  <Container>
    <Rating/>
    <Comment>
      "NCLEX Bootcamp's questions resembled the NCLEX almost identically. Bootcamp is what I now recommend to my friends who are also preparing for the NCLEX."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={isabella_trybula} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Isabella Trybula, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome6 = (
  <Container>
    <Rating/>
    <Comment>
      "Bootcamp's questions are exactly like the real NCLEX. I was surprised to see the similarity in the topics and the wording of the questions between Bootcamp and the real thing."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={octavio_ordaz} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Octavio Ordaz, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome7 = (
  <Container>
    <Rating/>
    <Comment>
      "When I sat down to take the actual NCLEX, it honestly felt like I was just answering my daily Bootcamp questions. I was so amazed at how Bootcamp made it easy for me."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={raksha_pampliega} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Raksha Pampliega, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome8 = (
  <Container>
    <Rating/>
    <Comment>
      "NCLEX Bootcamp literally saved my life. Their questions were so similar to the actual NCLEX, I felt so at ease when I took my exam. I don’t think I would’ve passed if it wasn’t for Bootcamp."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={kelley_campbell} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Kelley Campbell, RN
        </Name>
        <HeadingResultContainer>
          <SubHeader>Passed - Next Gen NCLEX®</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

export const nclexOutcomes = [
  outcome1,
  outcome2,
  outcome3,
  outcome8,
  outcome7,
  outcome6,
  outcome5,
  outcome4,
];