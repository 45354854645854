import React, {useState, createContext, useEffect, useContext, useCallback} from 'react';

import Auth from '@aws-amplify/auth';
import {Hub} from '@aws-amplify/core';
import {IntercomAPI} from 'react-intercom';

import {
  createWordPressUserData,
  getQuestionMasteryByWpUserId,
  getQuestionMasteryWithQuestionTagsByWpUserId,
  getQuestionMasteryWithQuestionsByWpUserId,
  getTestProgress,
  createUser,
  getUser,
  updateUser,
  getIntercomUserAttributes,
  // getQuestionBaseTagsOnly,
  // updateQuestionMastery,
} from '@bootcamp/shared/src/requests';

import {createUserInteraction, updateUserInteraction, listUserInteractions} from '@bootcamp/shared/src/requests/User';
import {
  getInObj,
  getUnixTimestamp,
  findStartAndEndDates,
  // getTagType
} from '@bootcamp/shared/src/util';
import {useAsync} from '@bootcamp/shared/src/util/hooks';

import moment from 'moment';

const VALID_BOOTCAMPS = [
  'oat',
  'inbde',
  'anatomy',
  'chemistry',
  'step-1',
  'dental-school',
  'med-school',
  'dat',
  'nclex',
  'mcat'
]

const BOOTCAMP_MEMBERSHIP_GROUPS = {
  'dat' : 'DATBootcamp',
  'oat' : 'OATBootcamp',
  'chemistry': 'ChemistryBootcamp',
  'inbde': 'INBDEBootcamp',
  'anatomy': 'AnatomyBootcamp',
  'step-1': 'Step1Bootcamp',
  'med-school': 'MedSchoolBootcamp',
  'nclex': 'NCLEXBootcamp',
  'dental-school': 'DentalSchoolBootcamp'
}

function determineBootcamp() {
  const validSubdomains = [
    'anatomy',
    'chemistry',
    'app',
    'app-staging',
    'app-monorepo-merge', // TODO remove this after app-monorepo-merge is merged
    'monorepo',
    'monorepo-staging'
  ];

  const subdomain = window.location.host.split('.')[0];
  const isValidSubdomain = validSubdomains.includes(subdomain);
  const isAppSubdomain = subdomain.includes('app');

  let bootcamp;

  if (!isValidSubdomain) {
    // deriving bootcamp from legacy domain bootcamps [bootcamp]bootcamp.com eg. anatomybootcamp.com
    bootcamp = subdomain.split('bootcamp')[0];
  } else if (isAppSubdomain || subdomain === 'monorepo-staging') {
    // deriving bootcamp from path on app.bootcamp subdomain app.bootcamp.com/[bootcamp] eg. app.bootcamp.com/oat
    bootcamp = window.location.pathname?.split('/')?.slice(1, 2)[0];
  } else {
    // deriving bootcamp from legacy subdomain bootcamps [bootcamp].bootcamp.com eg. anatomy.bootcamp.com
    bootcamp = subdomain; // bootcamp subdomain anatomy || chemistry
  }

  return VALID_BOOTCAMPS.includes(bootcamp) ? bootcamp : '';
}

const useUserData = (setDarkMode, darkModeEnabled, getDarkModeTheme) => {
  const [cognitoUser, setCognitoUser] = useState({getUsername: () => ''});
  const [userModel, setUserModel] = useState({});
  const [userQuestionMastery, setUserQuestionMastery] = useState([]);
  const [testProgress, setTestProgress] = useState(null);
  const [loadedUserData, setLoadedUserData] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [DEFAULT_USER_ID, setUserId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [intercomUserAttributes, setIntercomUserAttributes] = useState(null);

  const [bootcamp, setBootcamp] = useState(determineBootcamp());
  const [bootcampLoading, setBootcampLoading] = useState(true);

  const bootcampMembershipGroup = BOOTCAMP_MEMBERSHIP_GROUPS[bootcamp];

  const groups = getInObj(['signInUserSession', 'accessToken', 'payload', 'cognito:groups'], cognitoUser, []);
  if (['med-school', 'dental-school', 'anatomy', 'chemistry', 'nclex'].includes(bootcamp) && userModel?.createdAt && ((bootcamp !== 'nclex' ||  moment(userModel?.createdAt).isBefore(moment('2024-04-15 09:00:00'))) && moment().diff(moment(userModel?.createdAt), 'hours', true) <= 72) && !groups.includes('FreeTrial')) groups.push('FreeTrial');

  // async function updateQuestionMasteryRecords (questionMastery) {
  //   if (!questionMastery) return;
  //
  //   const promises = questionMastery.map(async mastery => {
  //     const {data: {getQuestionBase: question}} = await getQuestionBaseTagsOnly(mastery.userIdHashQuestionBaseId.replace(`${DEFAULT_USER_ID}#`, ''));
  //
  //     const tagIds = question.tags.items.reduce((acc, {tag}) => ({[getTagType(tag)]: tag.id, ...acc}), {});
  //
  //     const updateInput = {
  //       wordPressUserDataQuestionMasteryId: DEFAULT_USER_ID,
  //       userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${question.id}`,
  //       userIdHashTestTagId: `${DEFAULT_USER_ID}#${tagIds.bootcamp}`,
  //       userIdHashSubjectTagId: `${DEFAULT_USER_ID}#${tagIds.subject}`,
  //       userIdHashTopicTagId: `${DEFAULT_USER_ID}#${tagIds.topic}`,
  //       wpUserId: DEFAULT_USER_ID,
  //       questionMasteryQuestionId: question.id
  //     };
  //
  //     await updateQuestionMastery(updateInput, false);
  //   });
  //   return await Promise.all(promises);
  // }

  async function fetchTestProgress(testProgressId) {

    if (!DEFAULT_USER_ID) return;

    const {data: {getTestProgress: testProgressResult}} = await getTestProgress(testProgressId);

    setTestProgress(testProgressResult);
  }

  async function getCognitoUser() {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (e) {
      console.log('Error fetching current authenticated user', e);
    }
  }

  async function getIntercomVerificationHash(email) {
    try {
      const fetchResult = await fetch('https://gfp4txa2e7.execute-api.us-east-1.amazonaws.com/default/IntercomVerificationFunction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email
        })
      });
      const {hash} = await fetchResult.json();
      return hash
    } catch (e) {
      console.log(e);
    }
  }

  async function runMasteryUpdater(DEFAULT_USER_ID, isUpgraded) {
    if (!isUpgraded) return;
    try {
      const fetchResult = await fetch('https://6apt3jemyrkpwneqkqzxjtndfy0rtorz.lambda-url.us-east-1.on.aws', {
        method: 'POST',
        body: JSON.stringify({
          request: 'masteryUpdate',
          DEFAULT_USER_ID,
          bootcamp
        })
      });
      return fetchResult.json();
    } catch (e) {
      console.log(e);
    }
  }

  async function getUserModel(username) {
    try {
      const [{data: {getUser: user}}, cognitoUser] = await Promise.all([getUser(username), getCognitoUser()]);
      const email = cognitoUser && cognitoUser.attributes && cognitoUser.attributes.email;

      if (user) {
        if (!user.email && email) {
          await updateUser({id: username, email});
          user.email = email;
        }
        return user;
      } else {
        const {data: {createUser: newUser}} = await createUser(username, null, email);
        return newUser;
      }

    } catch (e) {
      console.log('Error fetching tbc user model', e);
    }
  }

  async function saveUserInteraction(key, value) {
    const interactionId = `${userModel.id}-${key}`;
    if (!interactions || !userModel.id) return;
    const existingInteraction = interactions.find(({id}) => id === interactionId);
    if (existingInteraction) {
      try {
        if (existingInteraction.value === value) return; // don't update interaction if nothing changed
        await updateUserInteraction({id: interactionId, value});
        setInteractions(interactions => [...(interactions || []).filter(({id}) => id !== interactionId), {id: interactionId, value}])
      } catch (error) {
        console.log('error updating user interaction', error);
      }
    } else {
      try {
        await createUserInteraction({id: interactionId, userId: userModel.id, value});
        setInteractions(interactions => [...interactions, {id: interactionId, value}]);
      } catch (error) {
        console.log('error saving user interaction', error);
      }
    }
  }

  async function getUserInteractions(userId) {
    const data = await listUserInteractions(userId);
    return getInObj(['data', 'UserId', 'items'], data, []);
  }

  async function loginLimitCheck(cognitoUser, userInteractions, onSignIn) {
    const username = cognitoUser.getUsername();

    const loginsInteractionId = `${username}-login-limit`;
    const loginsInteraction = userInteractions.find(({id}) => id === loginsInteractionId);
    let logins = JSON.parse((loginsInteraction || {}).value || '[]');

    const currentAuthTime = cognitoUser.signInUserSession?.accessToken?.payload?.auth_time;

    if (onSignIn) {
      const LOGIN_LIMIT = 3;

      if (logins.length >= LOGIN_LIMIT) {
        // remove oldest auth_time in logins array
        logins = logins.sort().reverse().slice(0, LOGIN_LIMIT - 1)
      }

      logins = [...logins, currentAuthTime];

      if (userInteractions.find(({id}) => id === loginsInteractionId)) {
        try {
          await updateUserInteraction({id: loginsInteractionId, value: JSON.stringify(logins)});
          setInteractions(interactions => [...interactions.filter(({id}) => id !== loginsInteractionId), {id: loginsInteractionId, value: JSON.stringify(logins)}])
        } catch (error) {
          console.log('error updating user interaction', error);
        }
      } else {
        try {
          await createUserInteraction({id: loginsInteractionId, userId: username, value: JSON.stringify(logins)});
          setInteractions(interactions => [...interactions, {id: loginsInteractionId, value: JSON.stringify(logins)}]);
        } catch (error) {
          console.log('error saving user interaction', error);
        }
      }
    } else {
      await handleLoginProtection(logins, cognitoUser);
    }
  }

  async function refreshIntercomUserAttributes() {
    const email = cognitoUser && cognitoUser.attributes && cognitoUser.attributes.email;
    const intercomUser = await getIntercomUserAttributes(email);
    setIntercomUserAttributes(intercomUser?.custom_attributes);
  }

  async function initializeTbcUser(onSignIn=false) {
    setLoading(true);
    try {
      const cognitoUser = await getCognitoUser();

      if (!cognitoUser) { // logged out
        setLoading(false);
        return;
      };

      const username = cognitoUser.getUsername();
      const [userModel, interactions] = await Promise.all([getUserModel(username), getUserInteractions(username)]);
      const {endDate, latestEnd} = findStartAndEndDates(userModel?.memberships, bootcampMembershipGroup);

      userModel && setUserModel({...userModel, activeMembership: latestEnd, membershipExpirationDate: getUnixTimestamp(endDate)});
      setCognitoUser(cognitoUser);
      setInteractions(interactions);

      if (userModel?.idOverride) {
        setUserId(userModel.idOverride);
      } else {
        setUserId(username);
      }

      const email = cognitoUser && cognitoUser.attributes && cognitoUser.attributes.email;
      const user_hash = cognitoUser.signInUserSession.idToken.payload['custom:IntercomHash'] || await getIntercomVerificationHash(email);

      let intercomParams = {
        name: username,
        email,
        user_hash,
        bootcamp: bootcamp || (interactions.find(interaction => interaction.id === `${username}-recent-bootcamp`) || {}).value
      }
      
      if (window?.location?.search) {
        const urlParams = new URLSearchParams(window.location.search);
        const verificationCode = urlParams.get('verificationCode');
  
        if (cognitoUser?.attributes?.sub?.includes(verificationCode)) {
          intercomParams.optInVerified = true;
        }
      }

      IntercomAPI('update', intercomParams);

      const streakDetails = JSON.parse((interactions.find(interaction => interaction.id === `${cognitoUser.username}-Streak-DWU-${bootcamp}`) || {}).value || '{}');
      dailyUpdate(streakDetails, userModel, cognitoUser);

      await loginLimitCheck(cognitoUser, interactions, onSignIn);

      if (!bootcamp) {
        const recentBootcamp = (interactions.find(interaction => interaction.id === `${username}-recent-bootcamp`) || {}).value;

        if (recentBootcamp) setBootcamp(recentBootcamp);

        setBootcampLoading(false);
      }

    } catch (e) {
      console.error(e);
      setLoading(false);
      setBootcampLoading(false);
      throw e;
    }
  }

  async function handleLoginProtection (logins, cognitoUser) {
    const currentAuthTime = cognitoUser?.signInUserSession?.accessToken?.payload?.auth_time;
    const recognizedUser = logins?.includes(currentAuthTime);
    // if recognized, no action currently required
    // otherwise, clear out!

    if (!recognizedUser && cognitoUser.getUsername() !== 'bootcamp-admin') {
      await IntercomAPI('trackEvent', 'login-limit-exceeded');
      await Auth.signOut();
      clearCurrentUser();
      window.location.href = logins.length === 0 ? '/auth#/signin' : '/auth#/signin?limit=true'
    }
  }

  async function dailyUpdate (streakDetails, userModel, cognitoUser) {
    try {
      const {streak, longestStreak} = streakDetails;
      const lastIntercomUpdate = localStorage.getItem('lastIntercomUpdate');
      const currentTime = (new Date()).getTime();
      const MINIMUM_UPDATE_DELAY = 1000 * 60 * 60 * 24 // 24 hrs
      const {earliestStart, endDate} = findStartAndEndDates(userModel.memberships, bootcampMembershipGroup);
      const {startDate, duration} = earliestStart || {};
      const membershipActive = !!getUserGroups(cognitoUser).find(group => group === bootcampMembershipGroup || group === 'Admin');

      if (!lastIntercomUpdate || ((currentTime - lastIntercomUpdate) > MINIMUM_UPDATE_DELAY)) {
        const username = cognitoUser.getUsername();
        const masteryUpdate = await runMasteryUpdater(userModel?.idOverride || username, membershipActive);

        const intercomUserAttributes = {
          [`${bootcampMembershipGroup}_membership_Status`]: membershipActive ? 'active' : 'inactive',
          [`${bootcampMembershipGroup}_membership_Duration`]: duration,
          [`${bootcampMembershipGroup}_Start_at`]: startDate ? getUnixTimestamp(startDate) : null,
          [`${bootcampMembershipGroup}_Expiration_at`]: endDate ? getUnixTimestamp(endDate) : null,
          dwuStreak: streak,
          longestDWUStreak: longestStreak || 1,
          bootcamp,
          totalTaggedQuestions: masteryUpdate?.tagCount
        };

        IntercomAPI('update', intercomUserAttributes);

        localStorage.setItem('lastIntercomUpdate', currentTime);
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function refreshSession(bypassCache=false) {
    try {
      setLoading(true);
      const refreshedCognitoUser = await Auth.currentAuthenticatedUser({bypassCache});
      const session = await Auth.currentSession();

      await refreshedCognitoUser.refreshSession(session.refreshToken, () => true);

      const username = refreshedCognitoUser.getUsername();
      const refreshUserModel = await getUserModel(username);
      const refreshInteractions = await getUserInteractions(username);

      const {latestEnd, endDate} = findStartAndEndDates(refreshUserModel?.memberships, bootcampMembershipGroup);

      refreshUserModel && setUserModel({...refreshUserModel, activeMembership: latestEnd, membershipExpirationDate: getUnixTimestamp(endDate)});
      setCognitoUser(refreshedCognitoUser);
      setInteractions(refreshInteractions);

      const logins = JSON.parse((refreshInteractions.find(interaction => interaction.id === `${username}-login-limit`) || {}).value || '[]');

      await handleLoginProtection(logins, refreshedCognitoUser)

      if (refreshUserModel?.idOverride) {
        setUserId(refreshUserModel.idOverride);
      } else {
        setUserId(username);
      }

      const streakDetails = JSON.parse((refreshInteractions.find(interaction => interaction.id === `${cognitoUser.username}-Streak-DWU-${bootcamp}`) || {}).value || '{}');
      dailyUpdate(streakDetails, refreshUserModel, refreshedCognitoUser);

      setLoading(false);

      return refreshedCognitoUser;
    } catch (error) {
      setLoading(false);
      // user isn't logged in
      return false;
    }
  }

  function clearCurrentUser() {
    setCognitoUser({});
    setUserModel({});
    setUserId(null);
    setInteractions(null);
    IntercomAPI('shutdown');
    setBootcamp(determineBootcamp());
    setBootcampLoading(true);
    setLoading(false);
  }

  async function handleAuthEvent(data) {
    switch (data.payload.event) {
      case 'signIn':
        await initializeTbcUser(true);
        break;
      case 'signOut':
        clearCurrentUser();
        break;
      default:
        break;
    }
  }

  function getUserGroups(user) {
    const callWithUser = user || cognitoUser;
    return getInObj(['signInUserSession', 'accessToken', 'payload', 'cognito:groups'], callWithUser, []);
  }

  // TODO make sure this works!
  function searchUserInteractions(key, defaultValue={}) {
    try {
      const interaction = interactions?.find(interaction => interaction.id === `${cognitoUser.username}-${key}`);

      return interaction
        ? JSON.parse(interaction.value)
        : defaultValue

    } catch (error) {
      // console.log('error parsing user interaction', error, interactions);
    }
  }
  async function refreshAndSearchUserInteractions(key) {
    try {
      const interactions = await getUserInteractions(cognitoUser.getUsername());
      setInteractions(interactions);
      return JSON.parse((interactions?.find(interaction => interaction.id === `${cognitoUser.username}-${key}`) || {}).value || '{}');
    } catch (error) {
      // console.log('error parsing user interaction', error, interactions);
    }
  }

  async function toggleDarkMode(value) {
    setDarkMode(value);
    await saveUserInteraction('darkModeEnabled', value);
  }

  useEffect(() => {
    Hub.listen('auth', handleAuthEvent);
    initializeTbcUser();
  }, [])

  useEffect(() => {
    const finishedLoading = !!DEFAULT_USER_ID && !!cognitoUser && !!userModel && !!interactions && !bootcampLoading

    if (finishedLoading) {
      setLoading(false);
    }

  }, [DEFAULT_USER_ID, cognitoUser, userModel, interactions, bootcampLoading]);

  useEffect(() => {
    if (bootcamp && DEFAULT_USER_ID) {
      setBootcampLoading(false);
      saveUserInteraction('recent-bootcamp', bootcamp);
    }
  }, [bootcamp, DEFAULT_USER_ID]);

  const isAdmin = groups.find(group => group === 'Admin');
  const isFreeTrial = groups.find(group => group === 'FreeTrial');
  const isBootcampPlusStudent = groups.find(group => group === 'PlusPackAccess') || isAdmin;
  const isUpgraded = isAdmin || isFreeTrial || groups.find(group => group === bootcampMembershipGroup) || (userModel && userModel.memberships && userModel.memberships.items.find(membership => membership.groups.includes(bootcampMembershipGroup) && (membership.status === 'active' || membership.status === 'cancelAtEndOfPeriod')));
  const isAwaitingPayment = userModel && userModel.memberships && userModel.memberships.items.find(membership => membership.groups.includes(bootcampMembershipGroup) && (membership.status === 'awaitingPayment'));
  const upgradedMembership = userModel && userModel.memberships && userModel.memberships.items && userModel.memberships.items.find(membership => membership.productId !== 'trial' && (membership.status === 'active' || membership.status === 'cancelAtEndOfPeriod'));
  const trialMembership = !isAdmin && !upgradedMembership && (isFreeTrial || userModel?.memberships?.items?.find(membership => membership.productId === 'trial' && membership.status === 'active'));
  const loggedIn = !!cognitoUser.username;

  const USER_ID = cognitoUser?.attributes?.sub;
  const fetchProfilePicture = useCallback(async () => {
    if (!USER_ID) return;
    const personalProfilePictureUrl = `https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.us-east-1.amazonaws.com/public/users/${USER_ID}/profile/profilePicture`;
    const personalProfilePicture = await fetch(personalProfilePictureUrl, {method: 'HEAD', cache: 'no-cache'});
    if (personalProfilePicture.status !== 200) throw new Error();
    return personalProfilePictureUrl;
  }, [USER_ID]);

  const {value: profPicUrl, error: fetchError, pending: fetching} = useAsync(fetchProfilePicture);

  return {
    isAdmin,
    refreshSession,
    getUserGroups,
    cognitoUser,
    setBootcamp,
    userModel,
    userQuestionMastery,
    loadedUserData,
    testProgress,
    fetchTestProgress,
    loading,
    setLoading,
    DEFAULT_USER_ID,
    saveUserInteraction,
    interactions,
    isUpgraded,
    isAwaitingPayment,
    bootcampMembershipGroup,
    trialMembership,
    loggedIn,
    bootcamp,
    intercomUserAttributes,
    searchUserInteractions,
    refreshAndSearchUserInteractions,
    validBootcamps: VALID_BOOTCAMPS,
    setTestProgress,
    HAS_EDIT_CAPABILITY: isAdmin,
    isBootcampPlusStudent,
    refreshIntercomUserAttributes,
    profPicUrl: fetching ? null : fetchError ? 'error' : profPicUrl,
    toggleDarkMode,
    darkModeEnabled,
    getDarkModeTheme,
  }
}

const UserDataContext = createContext();

const UserDataProvider = ({children, toggleDarkMode, darkModeEnabled, getDarkModeTheme}) => {
  const value = useUserData(toggleDarkMode, darkModeEnabled, getDarkModeTheme);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

const useUserDataContext = () => useContext(UserDataContext);

export {useUserDataContext, UserDataProvider};
