import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import {Link} from 'react-router-dom';

// import MasteryReview from '../../components/MasteryReview';
import GettingStarted from '../../components/GettingStarted';

import {H2} from '../../../components/Typography';
import FAQs from '../../components/WebflowFAQs';
import Grid from '../../components/Grid';
import ClassroomCardFull from '../../components/Cards/Classroom';

import {useMastery, useTestProgressArray} from '../../../hooks';

import {ReactComponent as DailyWarmupIcon} from '@bootcamp/shared/src/assets/svg/coffee.svg';
import {People} from '@styled-icons/fluentui-system-filled/People'

import {formatClassroomMastery, formatClassroomVideoProgress} from '@bootcamp/shared/src/util/formatting';
import {getStreakDetails, getTestsByClassroom} from '@bootcamp/shared/src/util';
import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import Container from '../../pages/PageContainer';
import {Bolt} from '@styled-icons/heroicons-solid/Bolt';
import styled from 'styled-components';
import theme from '@bootcamp/shared/src/styles/theme';

const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const StyledClassroomCard = styled(ClassroomCardFull)`
  flex: 1;
  margin-right: ${({theme}) => theme.layouts.spacing.l};

  &:last-child {
    margin-right: 0;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    margin-right: 0px;
    margin-bottom: 0 0 ${({theme}) => theme.layouts.spacing.m};
  }

`;
const StyledBookmark = styled(Bolt)`
  fill: rgba(254, 205, 108, 1);
`;

const Home = props => {
  const {isUpgraded, isBootcampPlusStudent} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'dat';
  const bootcampTitle = bootcamp.toUpperCase();

  const testsByClassroom = getTestsByClassroom(config?.classrooms);
  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);
  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom, isBootcampPlusStudent), [isBootcampPlusStudent]);
  const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);

  const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const todayActions = [
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      icon: {
        component: DailyWarmupIcon,
        text: 'Daily Warmup', themeColor: 'royal'
      },
      text: 'New questions brewed daily',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/warmup`,
      },
      checkIfComplete: interactionObj => {
        const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        return streakCompletedToday;
      }
    },
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      icon: {
        component: StyledBookmark,
        text: 'Quick Study', themeColor: 'royal'
      },
      text: isUpgraded ? 'Study 10 unused questions' : 'Upgrade for unlimited access',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study`,
      },
      checkIfComplete: interactionObj => {
        const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        return streakCompletedToday;
      }
    },
    {
      icon: {
        component: People,
        text: 'Study Group', themeColor: 'royal'
      },
      text: `Join 28k fellow students`,
      containerProps: {
        as: 'a',
        href: 'https://www.facebook.com/groups/datbootcamp',
        target: '_blank'
      }
    },
  ];

  const [biologyProgress] = useMastery(config.classrooms[0].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [gcProgress] = useMastery(config.classrooms[1].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [ocProgress] = useMastery(config.classrooms[2].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [patProgress] = useMastery(config.classrooms[3].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [rcProgress] = useMastery(config.classrooms[4].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [qrProgress] = useMastery(config.classrooms[5].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));

  const [biologyVideoProgress] = useTestProgressArray(coursesByClassroom['biology'], formatClassroomVideoProgress);
  const [gcVideoProgress] = useTestProgressArray(coursesByClassroom['general-chemistry'], formatClassroomVideoProgress);
  const [ocVideoProgress] = useTestProgressArray(coursesByClassroom['organic-chemistry'], formatClassroomVideoProgress);
  const [patVideoProgress] = useTestProgressArray(coursesByClassroom['perceptual-ability'], formatClassroomVideoProgress);
  const [rcVideoProgress] = useTestProgressArray(coursesByClassroom['reading-comprehension'], formatClassroomVideoProgress);
  const [qrVideoProgress] = useTestProgressArray(coursesByClassroom['quantitative-reasoning'], formatClassroomVideoProgress);

  const videoProgressMap = {
    'Biology': biologyVideoProgress,
    'General Chemistry': gcVideoProgress,
    'Organic Chemistry': ocVideoProgress,
    'Perceptual Ability': patVideoProgress,
    'Reading Comprehension': rcVideoProgress,
    'Quantitative Reasoning': qrVideoProgress,
  };

  const loading = !biologyProgress || !gcProgress || !ocProgress || !patProgress || !qrProgress || !rcProgress;

  const progressMap = {
    'Biology': biologyProgress,
    'General Chemistry': gcProgress,
    'Organic Chemistry': ocProgress,
    'Perceptual Ability': patProgress,
    'Reading Comprehension': rcProgress,
    'Quantitative Reasoning': qrProgress,
  };

  const classroomMasteryConfig = config.classrooms
    .filter(({locked, hideOnHome, route}) => !locked && !hideOnHome && !['reading-comprehension'].includes(route))
    .map(({name, route}) => ({name, route, progress: progressMap[name]}));

  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Subjects
      </Title>
      {/* {config
        .classrooms
        .filter(({hideOnHome}) => !hideOnHome)
        .map(({icon, themePalette, name, route, tagId, locked, educators}) => (
          <StyledClassroomCard
            key={tagId}
            iconPath={icon}
            themePalette={themePalette}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={locked}
            questionCount={questionCounts?.classroomQuestionCounts?.[route] || null}
            showProgress={true}
            progress={progressMap[name]}
            videoLessonProgress={videoProgressMap[name]}
            videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts?.[route] || null}
            showExpertEducators
            showVideoProgress
            expertEducatorOrientation={'column'}
            educatorLayout={'column'}
            educators={educators}
            detailsBorder={false}
            withHeaderPadding={false}
          />
        ))
      } */}
      <Grid
        items={config.classrooms.filter(({hideOnHome}) => !hideOnHome)}
        itemsPerRow={3}
        breakpoints={{
          1440: 2
        }}
        renderItem={({icon, themePaletteString, name, route, tagId, locked, educators}) => (
          <StyledClassroomCard
            key={tagId}
            icon={icon}
            themePalette={theme.colors.brandPalette[themePaletteString]}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={locked}
            questionCount={questionCounts?.classroomQuestionCounts?.[route] || null}
            showProgress={true}
            progress={progressMap[name]}
            videoLessonProgress={videoProgressMap[name]}
            videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts?.[route] || null}
            showExpertEducators
            showVideoProgress
            expertEducatorOrientation={'column'}
            educatorLayout={'column'}
            educators={educators}
            detailsBorder={false}
            withHeaderPadding={false}
          />
        )}
      />
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
