import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import ChapterCard from '../Cards/Chapter';
import FreeQuestionsCard from '../Cards/FreeQuestions';

import Callout from '../Cards/Callout';
import {PageSection} from '../ClassroomContentWrapper';
import {StyledBeginPracticeButton, StyledUpgradeButton, StyledResetButton} from '../Cards/components/Buttons'
import QBankCollection from '../Cards/QBankCollection';
import CreateTest from '../../pages/CreateTest';

import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useModalContext} from '../../../contexts/Modal';

import styled from 'styled-components';

const Container = styled.div`
`;

const StyledChapterCard = styled(ChapterCard)`
  background: white;
  border: none;
  overflow: inherit;
`;

const QuestionBanks = ({content, themePalette, data, calloutText, allow, subject}) => {
  const plusRef = useRef();
  const {userModel, isUpgraded, bootcamp} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  const {modalDispatch} = useModalContext();
  const [resetInitialized, setResetInitialized] = useState({});

  const activeMembershipGroups = userModel?.memberships?.items?.reduce((acc, membership) => (
    membership.status === 'active' ? [...acc, ...membership.groups] : acc
  ), []) || [];

  const {counts, progress, collectionData} = data;
  const [banks] = content;
  const isPlusPack = window.location.pathname.match('plus');
  const isQbank = window.location.pathname.match('qbanks') || window.location.pathname.match('board-banks') || window.location.pathname.match('bites') || window.location.pathname.match('lectures');

  const alwaysLocked = ['nclex'].includes(bootcamp);
  const openInModal = [''].includes(bootcamp) && isQbank;

  const hasAccess = !allow || allow?.some(group => activeMembershipGroups.includes(group));
  const freeBanks = config?.additionalContent?.find(({name}) => name === 'Free Tests');

  const openTestInModal = (system) => modalDispatch({
    type: 'open',
    component: CreateTest,
    componentProps: {defaults: {subject, system}},
    enableClickClose: true,
  });

  const urlParams = new URLSearchParams(window.location.search)
  const selectedSection = urlParams.get('section') || 'all'

  useEffect(() => {
    if (isPlusPack) {
      plusRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [window.location.pathname]);

  const filteredContent = banks.content.filter(item => selectedSection === 'all' || item.section === selectedSection || window.location.pathname.includes('med-school/microbiology/board-banks'));

  return (
    <Container>
      <PageSection title={''}>
        {calloutText && <Callout children={calloutText}/>}
        {!isUpgraded && !['inbde'].includes(bootcamp) && freeBanks && freeBanks?.testIds?.map((id) => (
          <FreeQuestionsCard id={id} themePalette={themePalette}/>
        ))}
        {filteredContent.map((item, index) => (
          item?.lessons
            ? <QBankCollection
                key={`${item.name}-${item?.section}`}
                config={item}
                chapterIndex={item.index || index + 1}
                showProgress={false}
                themePalette={themePalette}
                saveBlockProgress={collectionData?.saveBlockProgress}
                qbankCollectionData={collectionData?.qbankCollectionData}
                showText={'Show question banks'}
                hideText={'Hide question banks'}
              />
            : <StyledChapterCard
                key={item.id}
                route={`${item.route}`}
                name={item.name}
                onClick={openInModal ? () => openTestInModal(item.name) : null}
                id={item.id}
                comingSoon={item.comingSoon}
                index={
                  banks?.hasOwnProperty('startingIndex')
                  ? banks?.startingIndex + index
                  : item?.hasOwnProperty('index')
                  ? item.index
                  : index + 1
                }
                questionCount={counts[item.id]}
                progress={progress && progress.byTest ? progress.byTest[item.id] : 'loading'}
                themePalette={themePalette}
                defaultLocked={alwaysLocked ? !isUpgraded : (!isUpgraded && ((index > 0) || item.upgradeRequired)) || !hasAccess}
                actions={!hasAccess ? <StyledUpgradeButton /> : [
                  ((isQbank || isPlusPack) && (isUpgraded || index === 0)) && <StyledResetButton hideOnMobile={!progress?.byTest?.[item.id]} themePalette={themePalette} testId={item.id} setInitialized={setResetInitialized}/>,
                  (alwaysLocked ? !isUpgraded : !isUpgraded && (index > 0 || item.upgradeRequired)) &&
                  <StyledUpgradeButton/>,
                  (isUpgraded || (index === 0 && !item.upgradeRequired && !alwaysLocked)) &&
                  <StyledBeginPracticeButton hideOnMobile={!resetInitialized?.[item.id]} themePalette={themePalette}/>
                ]}
              />

        ))}
      </PageSection>
    </Container>
  );
};

QuestionBanks.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({
    counts: PropTypes.shape({}),
    progress: PropTypes.shape({})
  }),
};
QuestionBanks.defaultProps = {
  content: [],
  data: {counts: {}, progress: {}},
};

export default QuestionBanks;
