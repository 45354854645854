import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {useTestNavigatorContext} from '../../contexts/TestNavigator';
import {TimerProvider} from '../../contexts/TestTimer';

import {useHistory} from 'react-router-dom';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {handleGoBack, updateQuestionProgressData, updateQuestionProgressState} from '../../helpers';
import {useReloadBlockerContext} from '@bootcamp/web/src/contexts/ReloadBlocker';

import {trackQuizProgressPerformance} from '@bootcamp/shared/src/requests';
import {SubmitAnatomyCase as SubmitModal} from '../Modal';
import useHotkeys from "@reecelucas/react-use-hotkeys";

import Exhibit from '../../components/Exhibit';
import {FullPageSpinner} from '../Branding';
import {Column, FlexBox} from '../Grid';
import {StartButton} from '../QBank/Buttons';

import StartBlock from '../QBank/StartBlock';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import Question from './Question';
import Sidebar from './Sidebar';
import LabValues from './LabValues';
import SettingsSidebar from '../PrometricBank/components/Sidebar';
import {Button} from './shared';

import {changeIntercomDisplay, getTestBlockQuestions, getQuestionParts, updateCustomTestConfig} from '@bootcamp/shared/src/util';

import theme from '@bootcamp/shared/src/styles/theme';

import debounce from 'lodash.debounce';


const Container = styled(Column)`
  justify-content: center;
  position: relative;
  background: ${({theme}) => theme.colors.brandPalette.royal.dark};
`;
const ContentArea = styled(Column)`
  opacity: ${({loading, template}) => (loading && (template === 'testReview' || template === 'quickReview')) ? 0 : 1};
  transition: opacity .5s;
`;
const QuestionSetContainer = styled(FlexBox)`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  flex-direction: row;
`;
const QuestionSetWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: white;
`;
const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;
const Loader = styled(FullPageSpinner)`
  background: ${({color}) => color};
`;
const BreakBlockContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
`;

const BreakInformationContainer = styled.div`
  width: 800px;
  max-width: 100%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 0px 16px;
`;
const BreakInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const BreakBlock = ({transitionBlock, blockIndex}) => {
  const breakTimeStarted = useRef(Date.now());

  const {variables: {timeMultiplier}} = useTestNavigatorContext();
  const timeLimit = 300000 * timeMultiplier;
  // start a five minute timer
  // when the timer ends, transition to the next block

  // use state to render the time remaining
  const [timeRemaining, setTimeRemaining] = useState(moment(timeLimit - (Date.now() - breakTimeStarted.current)).format('m:ss') || '0:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const timeRemaining = moment(timeLimit - (Date.now() - breakTimeStarted.current)).format('m:ss') || '0:00';
      setTimeRemaining(timeRemaining);
      if (timeRemaining === '0:00') {
        transitionBlock('next');
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const nextBlock = (blockIndex + 2) / 2;
  const blockTime = 60 * timeMultiplier;
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours}h ${minutes}m 0s`;
  };

  const formattedBlockTime = formatTime(blockTime);
  return (
    <BreakBlockContainer>
      <div style={{maxWidth: 800}}>
        <h1>Authorized Break</h1>
        <h4>Click the button when you are ready to start the next block.</h4>
        <Button onClick={() => transitionBlock('next')}>
          Start Next Block
        </Button>
        <br/>
        <h3>Break Information</h3>
        <BreakInformationContainer>
          <BreakInformation>
            <div>Length of Next Block: ({nextBlock} of 4)</div>
            <div>{formattedBlockTime}</div>
          </BreakInformation>
          <BreakInformation>
            <div>Break Time Remaining</div>
            <div>{timeRemaining}</div>
          </BreakInformation>
        </BreakInformationContainer>

        {/* <h3>Day Time Information</h3>
        <BreakInformationContainer>
          <BreakInformation>
            <div>Time Remaining</div>
            <div>{formattedBlockTime}</div>
          </BreakInformation>
          <BreakInformation>
            <div>Elapsed Time</div>
            <div>{formattedBlockTime}</div>
          </BreakInformation>
          <BreakInformation>
            <div>Total Time</div>
            <div>{formattedBlockTime}</div>
          </BreakInformation>
        </BreakInformationContainer> */}

        <h3>Exam Overview</h3>
        <BreakInformationContainer>
          <BreakInformation>
            <div style={{width: 14}}>✓</div>
            <div>1</div>
            <div>Exam Section</div>
            <div>{formattedBlockTime}</div>
            <div>40 items</div>
          </BreakInformation>
          <BreakInformation>
            <div style={{width: 14}}>{nextBlock > 2 ? '✓' : ''}</div>
            <div>2</div>
            <div>Exam Section</div>
            <div>{formattedBlockTime}</div>
            <div>40 items</div>
          </BreakInformation>
          <BreakInformation>
            <div style={{width: 14}}>{nextBlock > 3 ? '✓' : ' '}</div>
            <div>3</div>
            <div>Exam Section</div>
            <div>{formattedBlockTime}</div>
            <div>40 items</div>
          </BreakInformation>
          <BreakInformation>
            <div style={{width: 14}}> </div>
            <div>4</div>
            <div>Exam Section</div>
            <div>{formattedBlockTime}</div>
            <div>40 items</div>
          </BreakInformation>
        </BreakInformationContainer>
      </div>
    </BreakBlockContainer>
  )
}

const QBank = ({username, themePalette, startBlockTitle, educators, handleEndBlockUpdate, blockRenderers={}, timeLimit}) => {
  const [showingLabValues, setShowingLabValues] = useState(false);
  const [showingExhibit, setShowingExhibit] = useState(false);
  const sidebarState = useState(window.innerWidth > 768);
  const settingsSidebarState = useState(false);
  const {modalDispatch} = useModalContext();
  const history = useHistory();
  const {toggleReloadBlocker} = useReloadBlockerContext();
  const {toggleTimer, updateTimeLimit, timeLeft, questionStartTime, timeUp} = useTimerContext();
  const {searchUserInteractions, DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const hideTimer = searchUserInteractions('hideTimer') === true;

  const prometricTheme = theme.colors.prometricPalette['med-school'];

  const {
    methods: {
      transitionBlock,
      setCurrentIndex,
      autoTagOnSubmit,
      updateQuizProgress,
      saveQuestionProgresses
    },
    variables: {
      test,
      blockIndex,
      testBlockConnections,
      loading,
      template,
      quizProgress,
      skipStartBlock,
      customTestConfig,
      navigationFilter,
      timeMultiplier,
      type
    }
  } = useTestNavigatorContext();

  const isReadinessExam = type === 'readinessExam';
  const testTitle = test?.config?.title;
  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses?.findIndex(({current}) => current);
  const testBlock = testBlockConnections?.[blockIndex]?.testBlock;
  const questions = getTestBlockQuestions(testBlock, template === 'customTest');
  const currentQuestion = questions[questionIndex];

  const displayEndBlockNavigation = (cancelable=true) => {
    if (!cancelable) {
      return modalDispatch({
        type: 'open',
        component: ({close}) => (
          <SubmitModal
            prometricStyling
            headerText={'No Time Remaining'}
            bodyText={'You have no more time remaining for this block.'}
            confirmConfig={{
              text: 'Proceed',
              onConfirm: async () => {
                if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                setCurrentIndex(blockIndex + 1, 0);
              }
            }}
            cancelConfig={{}}
          />
        )
      })
    }
    modalDispatch({
      type: 'open',
      component: ({close}) => (
        <SubmitModal
          prometricStyling
          headerText={'Warning - You are about to end the block!'}
          bodyText={'If you end this block you will not be able to return.'}
          confirmConfig={{
            text: 'End Block',
            onConfirm: async () => {
              if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
              }

              setCurrentIndex(blockIndex + 1, 0);
            }
          }}
          cancelConfig={{
            text: 'Remain in Block',
            onCancel: close
          }}
        />
      )
    })
  }

  const setQuestionIndex = index => {
    // TODO maybe move this logic into the test navigator...
    const blockTransition = index < 0
      ? 'previous'
      : index === questions.length
      ? 'next'
      : null;

    if (!blockTransition) {
      return setCurrentIndex(blockIndex, index);
    }

    if (isReadinessExam && template !== 'testReview') {
      if (blockTransition === 'next') {
        return displayEndBlockNavigation();
      } else if (blockTransition === 'previous') return;
    }

    transitionBlock(blockTransition);
  };

  const handleNextQuestion = ( ) => {
    if (navigationFilter.key && navigationFilter.value !== 'all') {
      const nextIndex = questionProgresses.findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
        }
        if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
          return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex && (progressObject.didCheck || template === 'testReview' || customTestConfig?.submitted);
        }
        return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
      });
      if (nextIndex !== -1) {
        setQuestionIndex(nextIndex)
      } else {
        transitionBlock('nextFilterActive');
      }
    } else {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
    }
  };

  const handlePreviousQuestion = () => {
    if (navigationFilter.key) {
      const prevIndex = (questions.length - 1) - questionProgresses.slice().reverse().findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > ((questions.length - 1) - questionIndex);
        }
        if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
          return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex) && (progressObject.didCheck || template === 'testReview' || customTestConfig?.submitted);
        }
        return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex)
      });
      if (prevIndex !== -1 && prevIndex !== questions.length) {
        setQuestionIndex(prevIndex);
      } else {
        setQuestionIndex(-1);
      }
    } else {
      const prevIndex = questionIndex - 1;
      setQuestionIndex(prevIndex);
    }
  };

  const handleNavigation = (direction) => {
    if (timeUp) return;
    // navigation handle wrapper for syncing question progress state
    const handler = direction === 'previous' ? handlePreviousQuestion : ultimateQuestionReached ? handleSubmitClick : handleNextQuestion;

    // only update question progresses for custom tests for now
    if (['tbcSavedBank', 'customTest'].includes(template) && !customTestConfig?.submitted && !questionProgresses?.[questionIndex]?.didCheck) {
      updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
    }

    handler();
  };

  const handleSuspendClick = async () => {
    const {headerText, bodyText, confirmText, cancelText} = customTestConfig?.submitted
      ? {
        headerText: 'Would you like to end your review?',
        bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
        confirmText: 'End Review',
        cancelText: 'Return to Review'
      } : {
        headerText: 'Pause Test',
        bodyText: 'Do you want to pause this test? Once paused you can resume this test from the previous tests page.',
        confirmText: 'Pause Test',
        cancelText: 'Return to Test'
      }
    modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
        background: transparent;
      `,
      component: ({close}) => {
        return (
          <SubmitModal
            headerText={headerText}
            bodyText={bodyText}
            cancelConfig={{
              text: cancelText,
              onCancel: close,
            }}
            confirmConfig={{
              text: confirmText,
              onConfirm: async () => {
                await updateCustomTestConfig(customTestConfig, {
                  suspended: true,
                  timeLeft: timeLeft?.length <= 5 ? `00:${timeLeft}` : timeLeft,
                  questionIndex,
                });

                if (template === 'customTest' && !customTestConfig?.submitted && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                toggleReloadBlocker(false);
                setTimeout(() => history.push(`/med-school/previous-tests`), 100);
              }
            }}
          />
        )
      },
      enableClickClose: true,
    })
  };

  const handleSubmitClick = async () => {
    if (type === 'readinessExam' && template !== 'testReview') {
      if (blockIndex !== 7) {
        return displayEndBlockNavigation();
      }
    }
    const {headerText, bodyText, confirmText, cancelText} = ['quickReview', 'testReview'].includes(template) ?
      {
        headerText: `Nice work! You’ve reached the end of your review.`,
        bodyText: `Would you like to keep reviewing or complete your review?`,
        confirmText: `Complete Review`,
        cancelText: `Keep Reviewing`
      }
      : (customTestConfig?.submitted || !customTestConfig) && type !== 'readinessExam'
        ? {
          headerText: 'Would you like to end your review?',
          bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
          confirmText: 'End Review',
          cancelText: 'Return to Review'
        } : {
          headerText: 'End Test',
          bodyText: 'Do you want to end this test? Once ended you can review your test results from the previous tests page.',
          confirmText: 'End Test',
          cancelText: 'Return to Test'
        }
    return modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
          background: transparent;
        `,
      component: ({close}) => {
        return (
          <SubmitModal
            headerText={headerText}
            bodyText={bodyText}
            prometricStyling
            cancelConfig={{
              text: cancelText,
              onCancel: close,
            }}
            confirmConfig={{
              text: confirmText,
              onConfirm: async () => {
                await updateCustomTestConfig(customTestConfig, {
                  suspended: false,
                  timeLeft: null,
                  submitted: true,
                });

                // make sure current question progress is saved on end
                if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                const shouldAutoTag = ((customTestConfig?.config && !customTestConfig?.submitted) || (isReadinessExam && template !== 'testReview'));
                const shouldTrackPerformance = shouldAutoTag && !customTestConfig?.config?.tutorMode;

                if (shouldAutoTag) {
                  if (shouldTrackPerformance) {
                    await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                  }
                  await autoTagOnSubmit();
                }

                toggleReloadBlocker(false);
                setTimeout(() => handleGoBack(history), 100);
              }
            }}
          />
        )
      },
      enableClickClose: true,
    })
  };

  const filterActive = navigationFilter && navigationFilter.key;
  const nextFilteredIndex = questionProgresses?.findIndex((progressObject, index) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck && index > questionIndex;
    }
    return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
  });

  const nextBlocksMatchFilter = quizProgress.slice(blockIndex + 1).map(({questions}) => questions || []).flat().findIndex((progressObject) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase());
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck;
    }

    return progressObject[navigationFilter.key] === navigationFilter.value
  }) !== -1;

  const ultimateQuestionReached = (questionIndex === questions.length - 1 && blockIndex === testBlockConnections.length - 2) || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter);


  const onClickMasteryButton = (masteryLevel) => {
    const updatedQuestionProgress = updateQuestionProgressState(updateQuizProgress, questionProgresses, questionIndex, {masteryLevel, question: {...currentQuestion, masteryLevel}});
    saveQuestionProgresses([updatedQuestionProgress]);
    handleNavigation('next');
  };

  useEffect(() => {
    toggleTimer(!hideTimer);
  }, [hideTimer]);

  useEffect(() => {
    if (timeUp && isReadinessExam) {
      if (blockIndex === 7) {
        modalDispatch({
          type: 'open',
          component: SubmitModal,
          enableClickClose: false,
          componentProps: {
            headerText: 'No Time Remaining',
            prometricStyling: true,
            bodyText: 'You have no more time remaining.',
            cancelConfig: {
              text: ''
            },
            confirmConfig: {
              text: 'View Test Results',
              colorConfig: themePalette,
              onConfirm: async () => {
                await updateCustomTestConfig(customTestConfig, {
                  suspended: false,
                  timeLeft: null,
                  submitted: true,
                });

                if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                const shouldAutoTag = ((customTestConfig?.config && !customTestConfig?.submitted) || (isReadinessExam && template !== 'testReview'));
                const shouldTrackPerformance = shouldAutoTag && !customTestConfig?.config?.tutorMode;

                if (shouldAutoTag) {
                  if (shouldTrackPerformance) {
                    await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                  }
                  await autoTagOnSubmit();
                }

                toggleReloadBlocker(false);
                setTimeout(() => handleGoBack(history), 100);
              },
              shouldRedirect: false,
            }
          },
        })
      } else {
        displayEndBlockNavigation(false);
      }
      return;
    }

    if (customTestConfig?.config?.timed && timeUp) {
      modalDispatch({
        type: 'open',
        component: SubmitModal,
        enableClickClose: false,
        componentProps: {
          headerText: 'No Time Remaining',
          prometricStyling: true,
          bodyText: 'You have no more time remaining. You can review your test results from the previous tests page.',
          cancelConfig: {
            text: ''
          },
          confirmConfig: {
            text: 'View Test Results',
            colorConfig: themePalette,
            onConfirm: async () => {
              await updateCustomTestConfig(customTestConfig, {
                suspended: false,
                timeLeft: null,
                submitted: true,
              });

              if (template === 'customTest' && !questionProgresses?.[questionIndex]?.didCheck) {
                await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
              }

              const shouldAutoTag = ((customTestConfig?.config && !customTestConfig?.submitted) || (isReadinessExam && template !== 'testReview'));
              const shouldTrackPerformance = shouldAutoTag && !customTestConfig?.config?.tutorMode;

              if (shouldAutoTag) {
                if (shouldTrackPerformance) {
                  await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                }
                await autoTagOnSubmit();
              }

              toggleReloadBlocker(false);
              setTimeout(() => handleGoBack(history), 100);
            },
            shouldRedirect: false,
          }
        },
      })
    };
  }, [timeUp]);

  useEffect(() => {
    if (isReadinessExam) {
      if ([1,3,5,7].includes(blockIndex)) {
        updateTimeLimit(60 * timeMultiplier);
        toggleTimer(true);
      } else {
        toggleTimer(false);
      }
    }
  }, [blockIndex]);

  useHotkeys('ArrowLeft', () => handleNavigation('previous'), [handleNavigation]);
  useHotkeys('ArrowRight', () => handleNavigation('next'), [handleNavigation]);

  const blockRenderer = (testBlockConnection) => {
    const defaultBlocksForTemplate = {
      masteryReview: {testBlock: {type: 'startMasteryReview'}},
      tbcQuestionBank: {testBlock: {type: 'startBlock'}},
      bookmarkedQuestionReview: {testBlock: {type: 'startBookmarkedQuestionReview'}},
      quickReview: {testBlock: {type: 'startQuickReview'}},
      // customTest: {testBlock: {type: 'startQuickReview'}},
    }[template] || {testBlock: {}};

    const totalQuestionBlocks = testBlockConnections.reduce((acc, {testBlock}) => testBlock?.questionConnections?.items?.length > 0 ? acc + 1 : acc, 0);
    const {testBlock} = testBlockConnection || defaultBlocksForTemplate;

    if (blockRenderers[testBlock.type]) {
      const rendererProps = {
        testTitle,
        testBlock,
        questions,
        sidebarState,
        settingsSidebarState,
        setShowingExhibit,
        handleNavigation,
        showingLabValues,
        setShowingLabValues,
        handleSubmitClick,
        handleSuspendClick,
        onClickMasteryButton,
        timeLimit,
        totalQuestionBlocks
      };

      return blockRenderers[testBlock.type](rendererProps);
    }

    switch (testBlock.type) {
      case 'startQuickReview':
        return null;
      case 'startBlock':
        return (
          <StartBlock
            header={testTitle}
            subheader={startBlockTitle || 'Question Bank'}
            loading={loading}
            testId={test?.id}
            themePalette={themePalette}
            questionCount={questions?.length}
            descriptionHeader={`Ready to start, ${username || '____'}?`}
            descriptionText={`Once you've completed this untimed question bank, make sure to review your answers and tag each question so that we can better help you learn the material.`}
            buttons={(color, progress) => {
              const buttonText = progress?.find(({count}) => count > 0) ? 'Jump Back In' : 'Start Studying';
              return [
                <StartButton onClick={() => transitionBlock('nextUntagged')} colorConfig={themePalette} loading={loading || !progress} disabled={loading} children={buttonText}/>,
              ]
            }}
            educators={educators}
          />
        );
      case 'passage':
      case 'questionSet':
      case 'caseQuestionSet':
        return (
          <QuestionSetContainer>
            <Sidebar sidebarState={sidebarState} />
            <QuestionSetWrapper>
              <TopBar
                settingsSidebarState={settingsSidebarState}
                sidebarState={sidebarState}
                themePalette={prometricTheme}
                setShowingExhibit={setShowingExhibit}
                handleNavigation={handleNavigation}
                setShowingLabValues={setShowingLabValues}
                />
              {showingExhibit && <Exhibit type={'calculator'} onCloseExhibit={() => setShowingExhibit(false)} defaultPosition={{x: window.innerWidth - 350, y: 0}} />}
              <QuestionWrapper>
                <Question themePalette={themePalette} disableHighlightToolTip/>
                <LabValues active={showingLabValues}/>
              </QuestionWrapper>
              <BottomBar sidebarState={sidebarState} handleSubmitClick={handleSubmitClick} handleSuspendClick={handleSuspendClick} onClickMasteryButton={onClickMasteryButton}/>
            </QuestionSetWrapper>
            <SettingsSidebar sidebarState={settingsSidebarState} themePalette={prometricTheme}/>
          </QuestionSetContainer>
        );
      case 'sectionBreak':
          return (
            <BreakBlock blockIndex={blockIndex} transitionBlock={transitionBlock} />
          )
      default:
        return null;
    }
  }

  const examComponent = blockRenderer(testBlockConnections[blockIndex]);

  const updateSidebarState = debounce(event => {
    const setSidebarState = sidebarState[1];
    setSidebarState(!(window.innerWidth < 768));
  }, 150);

  useEffect(() => {
    window.addEventListener('resize', updateSidebarState);
    return () => window.removeEventListener('resize', updateSidebarState);
  }, []);

  useEffect(() => {

    // NOTE annoyingly intercom launcher classname / dom element hierarchy varies from production to staging/local
    // changing the styles for the .intercom-launcher class doesn't work on staging/local but should work in production
    // (it's what we use in the Qbank component to hide the launcher on mount)
    changeIntercomDisplay('', '.intercom-launcher', ['bottom: 64px !important;']);

    return () => {
      changeIntercomDisplay('', '.intercom-launcher', ['bottom: 20px !important;']);
    }
  }, []);

  const width = window.innerWidth;

  return (
    <Container themePalette={themePalette} onClick={e => {
      const setSidebarState = sidebarState[1];
      if (width <= 1200) setSidebarState(false);
    }}>
      <Loader color={themePalette.dark} active={loading && (template === 'testReview' || skipStartBlock)}/>
      <ContentArea children={examComponent} loading={loading} template={template} />
    </Container>
  );
}

const WrappedQbank = props => {
  const {
    variables: {
      quizProgress,
      customTestConfig,
      type,
      blockIndex,
      template,
      timeMultiplier
    }
  } = useTestNavigatorContext();
  const isReadinessExam = type === 'readinessExam';
  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses?.findIndex(({current}) => current);

  const timeLimit = isReadinessExam ? 60 : customTestConfig?.suspended
    ? moment.duration(customTestConfig?.timeLeft).asMinutes()
    : (parseInt(customTestConfig?.config?.testLength) * 1.5 || null);

  const submitted = customTestConfig?.submitted || template === 'testReview';

  return (
    <TimerProvider stopwatch={(!customTestConfig || !customTestConfig?.config?.timed) && !isReadinessExam} timeLimit={timeLimit} autoStart disabled={submitted} questionIndex={questionIndex}>
      <QBank {...props} timeLimit={timeLimit} />
    </TimerProvider>
  )
}

QBank.defaultProps = {
  showScore: true,
  enableBookmarking: false
};

export default WrappedQbank;
