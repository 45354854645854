import React from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import PracticeTest from '../../components/Cards/PracticeTest';
import Container from '../../pages/PageContainer';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';

import {Heading} from '@bootcamp/web/src/bootcamps/nclex/shared';
import {useContentTypeProgress} from '../../api/progress/contentType';
import {H2} from '@bootcamp/web/src/components/Typography';
import {Element as FAQElement} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';
import {getReadinessExamGrade} from '../../pages/CustomTestReview/components/Score';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;

const FAQHeader = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;

const faqItems = [
  {title: "What is the Med School Bootcamp Step 1 Self-Assessment?", text: "The Self-Assessment simulates the exact look, feel, and content of the real Step 1 exam. At the end of your exam, you’ll get an accurate prediction of how likely you’ll pass Step 1, and personalized recommendations on how to improve your chances of passing."},
  {title: "How many questions are in the Step 1 Self-Assessment?", text: "The Self-Assessment consists of 4 blocks of 40 questions each, for a total of 160 questions. It is presented in the NBME's official timed format."},
  {title: "How long is the Step 1 Self-Assessment?", text: "The Self-Assessment follows the same time restrictions as the real Step 1. You have 60 minutes to complete each block, and a 5 minute break between blocks. Set aside at least 4 hours to complete the Self-Assessment."},
  {title: "Do I have to complete all 4 blocks in one sitting?", text: "Yes, in order to provide the most accurate pass prediction, you must complete all 4 blocks in one sitting. Set aside at least 4 hours to complete the Self-Assessment."},
  {title: "Are these questions repeated in the question bank?", text: "No, these are unique questions only found in the Self-Assessment. We removed these questions from the question bank a few months ago so you can test yourself with fresh questions. If you have been preparing for a while, you may see a repeat."},
  {title: "When will I get my score report?", text: "Immediately after you submit your final block, you will receive a detailed score analysis, pass prediction, and be able to review all your answers. We recommend taking time to throughly read the explanations and learn from your mistakes, this is the best way to improve your chances of passing Step 1."},
  {title: "Is the distribution of topics the same as the actual Step 1 exam?", text: "Yes, we followed the <a target=\"_blank\" href=\"https://www.usmle.org/prepare-your-exam/step-1-materials/step-1-content-outline-and-specifications\">official content outline</a> announced by the USMLE and carefully selected questions to reflect the relative distribution of topics on the actual Step 1 exam."},
  {title: "How accurate is the pass prediction?", text: "We used statistical models to compare your performance against previous students who have passed USMLE Step 1 to determine your readiness level."},
  {title: "When should I take the Step 1 Self-Assessment?", text: "We recommend taking the Self-Assessment at the beginning of your dedicated study period to identify your weak areas, and use the courses and question bank to strengthen those areas."},
]

const SelfAssessments = ({match, history, location}) => {
  const {isUpgraded} = useUserDataContext();

  const {config} = useBootcampConfigContext();

  const name = 'Self-Assessment';
  const filteredClassrooms = [
    {
      "name": "Board Style Questions",
      "route": "qbanks",
      "content": [
        {
          "name": "Question Banks",
          "content": [
            {
              id: 'b21f76dd-8a20-4a71-9bf6-839e7b4d7faa',
              name: 'Self-Assessment 1',
              route: 'self-assessment-1'
            }
          ]
        }
      ]
    }
  ]

  const testsByClassroom = [
    {
      id: 'b21f76dd-8a20-4a71-9bf6-839e7b4d7faa',
      name: 'Self-Assessment 1',
      route: 'self-assessment-1'
    }
  ]
  const contentTypeProgress = useContentTypeProgress(filteredClassrooms[0], 'self-assessments');
  const {progress, counts} = contentTypeProgress?.['qbanks-self-assessments'] || {};

  function formatResultDetails(attempt) {
    return null;
  }

  function formatScoreText(score) {
    if (!score) return;
    const {gradeLabel} = getReadinessExamGrade(score.correct, score.total, 'med-school');
    return {score: gradeLabel, label: 'Chance'};
  }

  function formatScore(attempt) {
    const questionProgresses = attempt
      ?.map(({questionProgresses}) => questionProgresses?.items)
      ?.flat();

    const scoreDetails = questionProgresses?.reduce((acc, {didSelectCorrectAnswer}) => {
      try {
        return {...acc, score: acc.score + (didSelectCorrectAnswer ? 1 : 0), maxScore: acc.maxScore + 1};
      } catch (error) {
        return acc;
      }
    }, {score: 0, maxScore: 0});

    return {correct: scoreDetails.score, total: scoreDetails.maxScore};
  }

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading
        name={name}
        description={'Simulate the look, feel, and content of Step 1. Use this to gauge your readiness!'}
        showEducators={false}
        showEmily={false}
        />
      <Wrapper>
        {testsByClassroom.map((test, index) => {
          const isLocked = !isUpgraded && index > 0;

          return (
            <PracticeTest
              route={`self-assessment/${test.route}`}
              id={test.id}
              name={test.name}
              testReviewRoute={'self-assessment/review'}
              testRouteParam={`med-school/self-assessment/self-assessment-${index + 1}`}
              formatResultDetails={formatResultDetails}
              formatScoreText={formatScoreText}
              formatScore={formatScore}
              fullWidthProgress
              showTestResults
              startText={'Start Exam'}
              reviewText={'Review'}
              progress={progress && progress.byTest ? progress.byTest[test.id] : 'loading'}
              questionCount={counts ? counts[test?.id] : 'loading'}
              // actions={[!isLocked ? <RoundButton small children={'Start'}/> : <UpgradeButton size={'small'} round children={'Upgrade'}/>]}
              defaultLocked={isLocked}
              index={index+1}
            />
        )})}
      </Wrapper>
      <FAQHeader>FAQs</FAQHeader>
      {faqItems.map(({title, text}) => <FAQElement key={title} title={title} text={text} />)}
    </Container>
  );
}

SelfAssessments.propTypes = {};
SelfAssessments.defaultProps = {};

export default SelfAssessments;
