import React from 'react';

import {
  Container,
  Wrapper,
  Heading,
  HeadingName,
  HeadingSchool,
  HeadingResultContainer,
  HeadingResultScore,
  HeadingResultPercentile,
  Comment,
  StudentImageContainer,
  StudentImage,
  StudentImageIconContainer,
  StudentImageIcon,
} from './shared';

import abby from '../assets/abbypuckett.png';
import alli from '../assets/allijepsen.png';
import ali from '../assets/abdullahali.png';

const outcome1 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={abby} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Abby Puckett
        </HeadingName>
        <HeadingResultContainer>
          <HeadingResultScore>
            29 AA
          </HeadingResultScore>
          <HeadingResultPercentile>
            (99.9%)
          </HeadingResultPercentile>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      "By far the most useful study tool that DAT Bootcamp provided were the <strong>full-length practice tests</strong>. Each week I would take a practice test and focus on the weakest subject areas in the following week. This strategy really helped me bring up my scores in my weaker subjects.”
    </Comment>
  </Container>
);

const outcome2 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={alli} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Alli Jepsen
        </HeadingName>
        <HeadingResultContainer>
          <HeadingResultScore>
            26 AA
          </HeadingResultScore>
          <HeadingResultPercentile>
            (98.8%)
          </HeadingResultPercentile>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      "I studied for 8 weeks following <strong>Ari’s study schedule</strong>, and I could not have been happier with the pacing; it allowed me enough time to take advantage of all of the excellent resources on DAT Bootcamp, without dragging out the timeline beyond what was necessary."
    </Comment>
  </Container>
);

const outcome3 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={ali} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Abdullah Ali
        </HeadingName>
        <HeadingResultContainer>
          <HeadingResultScore>
            23 AA
          </HeadingResultScore>
          <HeadingResultPercentile>
            (94.7%)
          </HeadingResultPercentile>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      "The <strong>only resource</strong> that I used was DAT Bootcamp which prepared for all sections on the DAT, and the main thing I would say that prepared me were the practice tests.”
    </Comment>
  </Container>
);

export const datOutcomes = [
  outcome1,
  outcome2,
  outcome3,
];