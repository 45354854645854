import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';

import {
  PrometricWrapper,
  NextText,
  NextButton,
  YesButton,
  NoButton,
  PreviousButton,
  EndButton,
  ExhibitButton,
  MarkButton,
  MarkedButton,
  ReviewAllButton,
  ReviewMarkedButton,
  ReviewIncompleteButton,
  Passage,
  Section,
  CenterSection,
  Heading,
  MouseImage,
  ScrollImage,
  ReviewScreenImage,
  TimeLimitImage
} from './shared';

import SectionBreakImage from '../assets/SectionBreak.png'
import PerceptualImage from '../assets/perceptual.gif'
import PrometricView from '../components/QuestionView';
import {SubmitAnatomyCase} from '@bootcamp/web/src/components/Modal';
import {Settings} from './StartBlock';


const QuestionView = styled(PrometricView).attrs(props => ({tutorialMode: true}))``;

const IntroContainer = styled(Section)`
  height: 100%;
  margin: 0;
  padding-top: 12vh;
  `;
const IntroWrapper = styled(PrometricWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  height: 45%;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0 auto;
`;
const ConfidentialityContainer = styled(Section)`
  padding: 64px;
`;
const IntroConfirmation = styled(Section)`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 40%;
  margin: 32px auto;
  justify-content: center;
`;
const BoldSection = styled(Section)`
  font-weight: bold;
  margin-bottom: 0;
`;

const Intro = () => {
  const {bootcamp, DEFAULT_USER_ID, cognitoUser} = useUserDataContext();
  const testTitle = `${bootcamp === 'dat' ? 'Dental' : 'Optometry'} Admission Test`
  return (
    <IntroContainer>
      <IntroWrapper>
        <CenterSection>
          <p>
            {cognitoUser?.getUsername() || DEFAULT_USER_ID}
            <br />
            {testTitle}
          </p>
        </CenterSection>
        <CenterSection>
          <strong>
            This exam contains copyrighted material, which is reprinted with permission of the copyright owner. All rights reserved.
          </strong>
        </CenterSection>
      </IntroWrapper>
      <IntroConfirmation>
        <strong>If this information is correct, click the "Yes" button with the mouse.</strong>
        <strong>If this information is NOT correct, click the "No" button with the mouse and contact the administrator.</strong>
        <Settings/>
      </IntroConfirmation>
    </IntroContainer>
  )
}
const Confidentiality = () => {
  return (
    <ConfidentialityContainer>
      <p>
        <strong>
          Read the following Notice and press "Next" button at the bottom of the screen, verifying that you agree to its contents.
        </strong>
      </p>

      <CenterSection>
        CONFIDENTIALITY AGREEMENT and
        <br/>
        NOTICE CONCERNING IRREGULARITIES
      </CenterSection>

      <p>
        In applying to sit for the Dental Admission Test, you agreed to abide by regulations controlling the conduct of the test and abide by all instructions, whether oral or written. The DAT is a secure test and is protected by U.S. copyright law. You are required to maintain the confidentiality of the test. You may not reproduce or attempt to reproduce test material through memorization, recording, or other means. You may not provide information relating to test content to others.
      </p>

      <p>
        <strong>If a violation of test regulations is noted during the test or evidence of an irregularity is disclosed during the processing of test responses or at a later time, your test results may be withheld, voided and/or considered invalid.</strong> You may also be directed to leave the test center before you have completed the test. If your scores are withheld as the result of an irregularity, you may be prohibited from testing for up to two years. Unauthorized disclosure of test content could also result in civil liability and/or criminal penalties.
      </p>

      <p>
        If you experience problems with testing conditions, computer equipment or the display of test items or exhibits, you should immediately report the problem to the test administrator. If corrective action is not taken immediately, you should inform the Department of Testing Services within five business days of the testing appointment.
      </p>
      <p>
        <strong>
          If you have any personal belongings (cell phone, pencil, wallet, food items, etc.) with you at this time, inform the test administrator immediately and return the items to your locker before proceeding.
        </strong>
      </p>
    </ConfidentialityContainer>
  )
}
const Welcome = () => (
  <PrometricWrapper>
    <Heading>
      Welcome to the tutorial
    </Heading>
    <Section>
      This tutorial demonstrates options and functions used in your test and allows you to try them without risk, before using them in items that will be scored. You will be instructed on how to use the mouse and the different parts of the screen.
    </Section>
    <Section>
      Note the clock in the upper right-hand corner showing the time limit for this tutorial.  This tutorial has a 15 minute time limit.
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Mouse = () => (
  <PrometricWrapper>
    <Heading>
      Using the mouse
    </Heading>
    <Section>
      <table>
        <tr>
          <td>
            <MouseImage />
          </td>
          <td style={{verticalAlign: 'top'}}>
            The mouse pointer moves when you move the mouse around on a surface. Although it can assume different shapes, the arrow shown at left is common. To point with the mouse, move the pointer until it rests on the desired object. To click something, point to it and then press and quickly release the left mouse button.
          </td>
        </tr>
      </table>
    </Section>
    <Section>
      Practice:
    </Section>
    <ol>
      <li>
        Rest your hand lightly on the mouse and move it. Note how the pointer moves as your hand does.
      </li>
      <li>
        Point to the correct answer (A) on the sample question below and click. You can click anywhere in the answer. Notice that the open circle next to the answer you chose is now filled in.
      </li>
    </ol>
    <BoldSection>
      Sample Question
    </BoldSection>
    <Section>
      <QuestionView
        key={'Mouse'}
        prompt={'The perimeter of a square is 20. Which of the following represents the area?'}
        answers={[
          ['25', true],
          ['100', false],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Navigation = () => (
  <PrometricWrapper>
    <Heading>
      Navigating through the test
    </Heading>
    <Section>
      You can use the mouse to move through the test one question at a time. Buttons appear at the bottom of the screen.
    </Section>
    <Section>
      <PreviousButton style={{float: 'right'}}/>
      <strong>To move backward</strong>
      <br />
      Click the <strong>Previous</strong> button
    </Section>
    <Section>
      <NextButton style={{float: 'right'}}/>
      <strong>To move forward</strong>
      <br />
      Click the <strong>Next</strong> button
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Scroll = () => (
  <PrometricWrapper>
    <Heading>
      Using the scroll function
    </Heading>
    <Section>
      <table>
        <tr>
          <td>
            <ScrollImage />
          </td>
          <td style={{verticalAlign: 'top'}}>
            <Section>
              A scroll bar appears when a question doesn't fit on a single screen.  Scroll bars may be horizontal or vertical.
            </Section>
            <Section>
              To scroll through the screen contents, click the scroll arrow that points in the appropriate direction to move a short distance. To move up or down one screen at a time, click within the scroll bar above or below the scroll box.
            </Section>
            <Section>
              The scroll bars in the image are not operational, but will be operational in the test.
            </Section>
          </td>
        </tr>
      </table>
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Exhibit = () => (
  <PrometricWrapper>
    <Heading>
      Exhibit Practice
    </Heading>
    <Section>
      Some test items require an exhibit (image) to answer the question.
    </Section>
    <Section>
      Practice:
    </Section>
    <Section>
      <ol>
        <li>
          Click the Exhibit button below
        </li>
        <li>
          You may resize the window to view this screen and the exhibit at the same time
        </li>
        <li>
          Click Close in the window when you have finished viewing the exhibit to return to the question
        </li>
        <li>
          A calculator will be available as an exhibit for the Quantitative Reasoning portion of the test.  Clicking on the “Calculator” button will display a calculator. The calculator is operational by using the mouse, not the numeric keypad.
        </li>
      </ol>
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Mark = () => (
  <PrometricWrapper>
    <Heading>
      Marking questions for later review
    </Heading>
    <Section>
      Click the Exhibit button below
      You may resize the window to view this screen and the exhibit at the same time
      Click Close in the window when you have finished viewing the exhibit to return to the question.
      A calculator will be available as an exhibit for the Quantitative Reasoning portion of the test.  Clicking on the “Calculator” button will display a calculator. The calculator is operational by using the mouse, not the numeric keypad.
    </Section>
    <Section>
      <MarkButton style={{float: 'right'}}/>
      <strong>To mark a question</strong>
      <br />
      Click the <strong>Mark</strong> button
    </Section>
    <Section>
      <MarkedButton style={{float: 'right'}}/>
      <strong>To unmark a question</strong>
      <br />
      Click the <strong>Marked</strong> button
    </Section>
    <Section>
      Unmark each question when you are satisfied with your answer choice. If you forget to unmark the question, your score will not be affected. The scoring process does not differentiate between marked and unmarked questions.
    </Section>
    <Section>
      You can also leave a question unanswered (incomplete).  Marked and Incomplete questions will be highlighted in a Review Questions screen before you exit the test, and you will have the opportunity to go back to those if you have time remaining.
    </Section>
    <NextText />
  </PrometricWrapper>
)
const MultipleChoice = () => (
  <PrometricWrapper>
    <Heading>
      Multiple choice items
    </Heading>
    <Section>
      This test uses multiple choice items.
    </Section>
    <Section>
      To answer each multiple choice question in your test, click on your answer.
    </Section>
    <Section>
      Practice on the sample question by doing the following:
    </Section>
    <Section>
      <ol>
        <li>
          Select an answer. Notice that the open circle next to the answer you chose is now filled in.
        </li>
        <li>
          Change your answer by selecting different choices.
        </li>
      </ol>
    </Section>
    <BoldSection>
      Sample Question
    </BoldSection>
    <Section>
      <QuestionView
        key={'MultipleChoice'}
        prompt={'What is the color of the sky?'}
        answers={[
          ['Purple', false],
          ['Yellow', false],
          ['Green', false],
          ['Blue', true],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Samples = () => {
  const {bootcamp} = useUserDataContext();
  return (
    <PrometricWrapper>
      <Section>
        In the following eight screens you will see at least one sample item from each of the four components in the {bootcamp === 'dat' ? 'Dental' : 'Optometry'} Admission Test battery.
      </Section>
      <Section>Survey of the Natural Sciences</Section>
      <Section>{bootcamp === 'dat' ? 'Perceptual Ability' : 'Reading Comprehension'}</Section>
      <Section>{bootcamp === 'dat' ? 'Reading Comprehension' : 'Physics'}</Section>
      <Section style={{marginBottom: 0}}>Quantitative Reasoning</Section>
    </PrometricWrapper>
  )
}
const SNSBiology = () => (
  <PrometricWrapper>
    <Heading>
      Survey of the Natural Sciences - Biology
    </Heading>
    <Section>
      <QuestionView
        key={'SNSBiology'}
        prompt={'Two identical tubes of yeast are growing in a sugar solution. One tube is sealed, one is left open to the air. The sealed tube will differ from the open one in that the yeast in the sealed tube will produce more:'}
        answers={[
          ['acetyl CoA', false],
          ['ATP per glucose', false],
          ['ethanol', true],
          ['lactic acid', false],
          ['CO2 per glucose', false],
        ]}
      />
    </Section>

    <NextText />
  </PrometricWrapper>
)
const SNSGenChem = () => (
  <PrometricWrapper>
    <Heading>
      Survey of the Natural Sciences - General Chemistry
    </Heading>
    <Section>
      <QuestionView
        key={'SNSGenChem'}
        prompt={'Which of the following solutions will be the best conductor of electrical current?'}
        answers={[
          ['ethylene glycol, C2H6O2(aq)', false],
          ['glucose, C6H12O6(aq)', false],
          ['bromine, Br2(aq)', false],
          ['methyl alcohol, CH3OH(aq)', false],
          ['potassium chloride, KCl(aq)', true],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const SNSOrgoChem = () => (
  <PrometricWrapper>
    <Heading>
      Survey of the Natural Sciences - Organic Chemistry
    </Heading>
    <Section>
      <QuestionView
        key={'SNSOrgoChem'}
        prompt={'According to IUPAC rules, which functional group is given the highest priority when numbering the parent chain?'}
        answers={[
          ['Amines', false],
          ['Alkenes', false],
          ['Aldehydes', false],
          ['Amides', false],
          ['Acid Anhydrides', false],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const PAT = () => (
  <PrometricWrapper>
    <Heading>
      Perceptual Ability
    </Heading>
    <Section>
      <img src={PerceptualImage} />
      <QuestionView
        key={'PAT'}
        prompt={'Select the letter of the box that best represents the first view.'}
        answers={[
          ['A', false],
          ['B', false],
          ['C', false],
          ['D', false],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const SectionBreak = () => (
  <PrometricWrapper>
    <Heading>
      The following message will appear after the Perceptual Ability Section
    </Heading>
    <CenterSection>
      <h2>Section Break</h2>
      <p style={{textAlign: 'left'}}>
        STOP! Please read this message. This is an optional break. The amount of time you have for this break is shown in the "Time Remaining" box in the upper right corner of the screen. Clicking the NEXT button will terminate the break immediately and start the next section of the test. Click NEXT to continue before the break period expires.
      </p>
      <p style={{textAlign: 'left'}}>
        After the break time expires, testing will resume automatically.
      </p>
    </CenterSection>
    <NextText />
  </PrometricWrapper>
)
const OATSectionBreak = () => (
  <PrometricWrapper>
    <Heading>
      The following message will appear after the Physics Section
    </Heading>
    <CenterSection>
      <img src={SectionBreakImage} />
    </CenterSection>
    <NextText />
  </PrometricWrapper>
)
const RCPassage = `
<h3 style="text-align: center;">The Threat of Comets and Asteroids</h3>
<b>(1). </b>Scientists believe that somewhere between four and five billion years ago, our solar system, the Milky Way, was formed from gases and dust that grouped together to form planetesimals. These planetesimals joined together to form planets, made of molten materials so hot that lighter, essential materials, such as water, carbon dioxide, and other gases, boiled off. As with other planets in the system, the Earth's surface then began to cool, and a solid crust formed around its circumference. Life began on Earth only after water was brought to it, later to cover two thirds of its surface. The water arrived to the young planet by cold meteors, which carried ice from the outlying regions of the solar system.
<br><br>
<b>(2). </b>Comets still inhabit the farthest edges of the Milky Way. When the system was forming, comets evolved from the matter thrown away by the planetesimals and new planets. Forming in the outlying parts of the solar system, far from the sun's heat, comets were born cold, some as cold as -260°C. They still carry gases, snow, and ice as they travel throughout the Milky Way's outer regions.
<br><br>
<b>(3). </b>One well-known cluster of comets is known as the Kuiper belt, named for its discoverer. The comets of the Kuiper belt have their orbits between the orbits of Neptune and Pluto. Thirty-one comets have been seen and identified in the belt so far, and scientists now even consider Pluto to be the largest of the Kuiper comets, because its unusually elliptical orbit more closely resembles the orbit of a comet than that of a planet.
<br><br>
<b>(4). </b>The orbits of comets in the Kuiper belt are so oddly shaped that they travel both in the outlying regions of the solar system and near the sun. Orbit lengths for these comets range from ten to one hundred years. Scientists have surmised that a collision with any one of these comets would be disastrous, for several reasons. First, comets' orbits are often steeply inclined in relation to the Earth's orbit, and some comets even orbit in the opposite direction to the Earth. This means that these comets travel at a very high velocity compared to the Earth, along the order of fifty to seventy-five kilometers per second. Scientists reason that the shock from an impact caused by such a fast-moving comet would be cataclysmic for life on the Earth.
<br><br>
<b>(5). </b>Asteroids also formed from materials thrown out by early planetary activity. Unlike comets, asteroids are the leftover matter that remained within the middle parts of the system. Most asteroids today inhabit the space between the first and fifth planets, Mars and Jupiter. Since asteroids formed near the sun, they began at very hot temperatures, and their lighter gases boiled off. Today, asteroids contain mostly carbon and other heavy metals.
<br><br>
<b>(6). </b>Asteroids normally have very stable orbits. However, there are so many asteroids in the Milky Way solar system that it is possible for them to collide with one another. When this happens, debris thrown out from a collision forms a new orbit. Some of these new asteroid orbits <i>resonate</i> with the orbit of the giant planet Jupiter. That is, Jupiter's gravitational pull influences the new asteroid and causes the asteroid's orbit to become erratic. These erratic orbits open up the possibility of a collision with one of the other inner planets, including Earth.
<br><br>
<b>(7). </b>Some of the asteroids that penetrate the space closer to the sun from Jupiter are small, less than fifty meters across. These are small enough to be melted by the Earth's atmosphere before they ever reach the planet's surface, so danger of collision from these smaller asteroids is slim to none. Larger asteroids, measuring sixty meters and more across, do pose a threat to Earth because they are too big to be destroyed by the Earth's atmosphere.
<br><br>
<b>(8). </b>In 1908, a large asteroid did reach the Earth's surface, above Siberia in Russia. Although the sixty-five-meter asteroid did not leave a crater in the Earth's surface, its heat left a ring of burnt trees and grass fifty meters across. Because of the length of time since that asteroid collision, scientists estimate that the timing of an Earthly invasion by an asteroid is about once every hundred years. Further, since the Earth's surface is made up largely of bodies of water, it is likely that a collision would occur with a water-covered surface rather than with land. On the other hand, while scientists regard the chances of an asteroid collision as low, the likely damage from such a collision is enormous, especially if the asteroid were to hit at or near a populated area. In addition, the heavier the metals in the asteroid, the worse the impact of the collision would be. Meteor Crater, in Arizona, was caused by a relatively small, thirty-meter asteroid made of heavy metals. The crater itself is more than a kilometer across.
<br><br>
<b>(9). </b>Large asteroids, from one kilometer in diameter, pose a great danger to the Earth, although the chances that an asteroid of that size will collide with the Earth are estimated at about once every three-hundred thousand years. However, research has been devoted to studying the likely harm since it is theoretically possible.
<br><br>
<b>(10). </b>An asteroid of one kilometer or more, traveling at its average speed of twenty kilometers per second, would hit the Earth with a shock equal to millions more than the impact caused by the atomic bomb dropped on Hiroshima during World War II. While an asteroid collision would not include the nuclear radiation caused by a nuclear bomb, the physical impact alone would disrupt the Earth's atmosphere and cause large dust clouds to form and block out the sun, causing a permanent winter to descend over the Earth, marked by subzero temperatures, violent windstorms, and uninterrupted darkness.
<br><br>
<b>(11). </b>Scientists have uncovered evidence that an unusually large asteroid, probably between ten and twenty kilometers in diameter, collided with the Earth around sixty-five million years ago, touching down on Mexico's Yucatan Peninsula. This event marks the end of the Cretaceous period, when dinosaurs and other species inhabited the Earth, and the beginning of the next period, the Tertiary. The crater, measuring about one hundred seventy kilometers across, cannot be seen from the Earth's surface, but photographs taken by the space shuttle Endeavor, and information collected during oil drillings, have showed scientists where the crater lies.
<br><br>
<b>(12). </b>Scientists have surmised the following scenario from evidence of the ancient crater: first, the explosion caused rocks and steam to be cast into the Earth's atmosphere, cracking open the Earth's crust and causing massive earthquakes and tsunamis across the planet. Huge dust clouds, containing particles of Earth and asteroid, covered the Earth's surface, causing uninterrupted darkness for months. Next, acid rain fell, dropping the dust from the clouds onto the Earth's surface and forming a layer of several centimeters over the entire planet. Scientists have found dinosaur and other fossils below this layer of sediment, but none above, indicating that the collision and its aftermath wiped out about three-quarters of the Earth's species.
<br><br>
<b>(13). </b>Next came a deep freeze over the Earth, followed by a gradual warming, lasting several centuries. One twist on the theory of the demise of the dinosaurs holds that what killed these species was not the initial impact of the asteroid, or the prolonged freeze, but the intense warming of the Earth's surface that followed.
<br><br>
`;
const RCIntro = () => (
  <PrometricWrapper>
    <Heading>
      The next two screens are examples of Reading Comprehension items.
    </Heading>
    <Section>
      The Reading Comprehension component of this test contains reading passages that can be viewed on the same screen as the question. The reading passages are longer than the screen, so they have been put into a window and a scroll bar will appear to the right of the window to allow you to scroll down as you read the passage.
    </Section>
    <Section>
      To view the reading passage, click the scroll bar attached to the passage to scroll through the passage.
    </Section>
    <Passage dangerouslySetInnerHTML={{__html: RCPassage}} />
    <NextText />
  </PrometricWrapper>
)
const RCFirst = () => (
  <PrometricWrapper>
    <Heading>Reading Comprehension</Heading>
    <Section>
      <QuestionView
        key={'RCFirst'}
        prompt={'Approximately how many millions of years ago did the dinosaurs vanish from the Earth?'}
        answers={[
          ['65.0', false],
          ['6.5', false],
          ['30.5', false],
        ]}
      />
      <Passage dangerouslySetInnerHTML={{__html: RCPassage}} />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const RCSecond = () => (
  <PrometricWrapper>
    <Heading>Reading Comprehension</Heading>
    <Section>
      <QuestionView
        key={'RCSecond'}
        prompt={'Which of the following causes an asteroid\'s orbit to become unstable?'}
        answers={[
          ['Proximity with the Earth and moon', false],
          ['Heat from the sun', false],
          ['Gravitational pull from Jupiter', false],
        ]}
      />
      <Passage dangerouslySetInnerHTML={{__html: RCPassage}} />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Physics = () => (
  <PrometricWrapper>
    <Heading>Physics</Heading>
    <Section>
      <QuestionView
        key={'Physics'}
        prompt={'A vector quantity is best described as having:'}
        answers={[
            ['a direction only', false],
            ['a magnitude and a direction', true],
            ['a direction only', false],
            ['units only', false],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const QR = () => (
  <PrometricWrapper>
    <Heading>Quantitative Reasoning</Heading>
    <Section>
      <QuestionView
        key={'QR'}
        prompt={'If y = (x + 2)/x, then x equals which of the following?'}
        answers={[
            ['2/(y-1)', false],
            ['2/(y+1)', false],
            ['(2y+1)/y', false],
            ['2y-1', false],
            ['y/(y+2)', false],
        ]}
      />
    </Section>
    <NextText />
  </PrometricWrapper>
)
const Reviewing = () => {
  const {bootcamp} = useUserDataContext();
  return (
    <PrometricWrapper>
      <Heading>Reviewing items</Heading>
      <Section>
        <ReviewScreenImage />
      </Section>
      <Section>
        At the end of each of the four {bootcamp === 'dat' ? 'DAT' : 'OAT'} components, you will see a scrollable listing of all the question numbers. This list displays each question number and indicates if the question has been marked for review, completed, or skipped. Keep in mind that when you complete the item review and exit each test section, you will not be able to return to that section of the test.
      </Section>
      <Section>
        <strong>To review questions</strong>
        <br />
        <ReviewAllButton style={{float: 'right'}} />
        Click on the <strong>Review All</strong> button. You will be moved to the first question. Clicking on the <strong>Next</strong> button will move you to the next question. You can also double-click on the question number in the list to move to a particular question.
      </Section>
      <Section>
        <strong>To review marked questions</strong>
        <br />
        <ReviewMarkedButton style={{float: 'right'}} />
        Click on the <strong>Review Marked</strong> button. You will be moved to the first marked question. Clicking on the <strong>Next</strong> button will move you to the next marked question.
      </Section>
      <Section>
        <strong>To review incomplete questions</strong>
        <br />
        <ReviewIncompleteButton style={{marginLeft: '10px', float: 'right'}} />
        Click on the <strong>Review Incomplete</strong> button. You will be moved to the first incomplete question. Clicking on the <strong>Next</strong> button will move you to the next incomplete question.
      </Section>
      <NextText />
    </PrometricWrapper>
  )
}
const Time = () => {
  const {bootcamp} = useUserDataContext();
  return (
    <PrometricWrapper>
      <Section>
        <TimeLimitImage style={{margin: 'auto'}}/>
      </Section>
      <Section>
        If the time limit for any of the {bootcamp === 'dat' ? 'DAT' : 'OAT'} components (tests) expires before you complete it, you will be notified by means of the "Time Expired" message. Your only option is to proceed to the next test, or if it is the last test, to finish the testing process.
      </Section>
      <NextText />
    </PrometricWrapper>
  )
}
const End = () => {
  const {bootcamp} = useUserDataContext();
  return (
    <PrometricWrapper>
      <Heading>End of tutorial</Heading>
      <Section>
        This concludes the tutorial. If you need assistance during the examination, please contact the Test Center Administrator.
      </Section>
      <Section>
        Upon request, the Test Center Administrator will provide note boards and low-odor, fine-tip markers. All materials must be returned to the Test Center Administrator. No scratch paper is allowed in the secure testing area.
      </Section>
      <Section>
        Your test will consist of the following four sections:
      </Section>
      <Section>
        Survey of the Natural Sciences<br />
        {bootcamp === 'dat' ? 'Perceptual Ability' : 'Reading Comprehension'}<br />
        {bootcamp === 'dat' ? 'Reading Comprehension' : 'Physics'}<br />
        Quantitative Reasoning
      </Section>
      <Section>
        Detailed information will precede each section.
      </Section>
      <Section>
        Examinees are responsible for protecting the integrity of their responses. If cheating is detected, those involved, whether they are copiers or those copied from, will have their tests voided.
      </Section>
      <Section>
        Good luck with the test.
      </Section>
      <BoldSection>
        Click the "End" button to begin the test.
      </BoldSection>
    </PrometricWrapper>
  )
}

const DATpages = [
  Intro,
  Confidentiality,
  Welcome,
  Mouse,
  Scroll,
  Navigation,
  Mark,
  Exhibit,
  MultipleChoice,
  Samples,
  SNSBiology,
  SNSGenChem,
  SNSOrgoChem,
  PAT,
  SectionBreak,
  RCIntro,
  RCFirst,
  RCSecond,
  QR,
  Reviewing,
  Time,
  End,
]

const OATpages = [
  Intro,
  Welcome,
  Mouse,
  Scroll,
  Navigation,
  Mark,
  Exhibit,
  MultipleChoice,
  Samples,
  SNSBiology,
  SNSGenChem,
  SNSOrgoChem,
  RCIntro,
  RCFirst,
  RCSecond,
  OATSectionBreak,
  Physics,
  QR,
  Reviewing,
  Time,
  End,
]

const FullLengthTutorial = ({setExhibitType, setShowingExhibit, setTopLeftText, setTopRightText}) => {
  const [tutorialIndex, setTutorialIndex] = useState(0);

  const {
    methods: {
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
      transitionBlock,
    }
  } = useTestNavigatorContext();
  const {modalDispatch} = useModalContext();
  const {toggleTimer, updateTimeLimit} = useTimerContext();
  const {searchUserInteractions, bootcamp} = useUserDataContext();

  const timeAccommodation = searchUserInteractions('timeAccommodation') === true;
  const tutorialSet = bootcamp === 'dat' ? DATpages : OATpages;

  useEffect(() => {
    return () => {
      // When this component dismounts...
      setCenterButtons([]);
      setTopLeftText();
    }
  }, [])

  const EndButtonComponent = (
    <EndButton onClick={() => {
      transitionBlock('next');
      const timeLimit = timeAccommodation ? 135 : 90;
      updateTimeLimit(timeLimit);
      toggleTimer();
    }} />
  )
  useEffect(() => {
    setShowingExhibit(false);
    if (tutorialIndex === 0) {
      setTopRightText(' ')
    }
    if (tutorialIndex > 0) {
      setTopLeftText(`Page ${tutorialIndex} of {pages.length}`);
    }
    if (tutorialIndex === 2) {
      setTopRightText();
      const timeLimit = timeAccommodation ? 22.5 : 15;
      updateTimeLimit(timeLimit);
      toggleTimer();
    }
    if (tutorialIndex === 7) {
      setExhibitType('periodic');
    }



    if (tutorialIndex === 0) { // this case should now be for yes/no clicks in the first two intro blocks
      setLeftButtons([{component: <YesButton onClick={() => setTutorialIndex(tutorialIndex => tutorialIndex + 1)} />}])
      setRightButtons([
        {
          component: (
            <NoButton
              onClick={() => modalDispatch({
                  type: 'open',
                  component: SubmitAnatomyCase,
                  enableClickClose: true,
                  componentProps: {
                    prometricStyling: true,
                    headerText: 'Would you like to exit the test?',
                    bodyText: 'Your test is in progress. If you exit now, your test results will NOT be saved.',
                    cancelConfig: {
                      text: `Return to the Test`,
                      onCancel: () => {
                        modalDispatch({type: 'close'});
                      },
                      shouldRedirect: false,

                    },
                    confirmConfig: {
                      text: 'Exit Test',
                      color: '#0F6292',
                      onConfirm: () => {},
                      shouldRedirect: true,
                      redirect: location => location.pathname.split('/').slice(0, -1).join('/')
                    }
                  },
                })}
            />
          )
        }
      ]);
    } else if (tutorialIndex === 1) {
      setLeftButtons([{component: <div />}])
      setCenterButtons([
        {
          component: <NextButton onClick={() => setTutorialIndex(tutorialIndex => tutorialIndex + 1)} />
        }
      ]);
      setRightButtons([{component: <div />}])
    } else if (tutorialIndex === 2) {
      setLeftButtons([{component: <div />}])
      setCenterButtons([
        {
          component: <NextButton onClick={() => setTutorialIndex(tutorialIndex => tutorialIndex + 1)} />
        }
      ]);
      setRightButtons([{component: EndButtonComponent}])

    } else if (tutorialIndex === tutorialSet.length - 1) { // case for last block in the set
      setLeftButtons([{component: <PreviousButton onClick={() => setTutorialIndex(tutorialIndex => tutorialIndex - 1)}/>}])
      setCenterButtons([]);
      setRightButtons([{component: EndButtonComponent}])
    } else {
      setLeftButtons([{component: <PreviousButton onClick={() => setTutorialIndex(tutorialIndex => tutorialIndex - 1)}/>}]);
      setCenterButtons([
        {
          component: <NextButton onClick={() => setTutorialIndex(tutorialIndex => tutorialIndex + 1)} />
        }
      ]);
      setRightButtons([
        tutorialIndex === 6 && {component: <MarkButton />},
        tutorialIndex === 7 && {component: <ExhibitButton onClick={() => setShowingExhibit(showingExhibit => !showingExhibit)} />},
        {component: EndButtonComponent}
      ]);
    }
  }, [tutorialIndex])

  return tutorialSet[tutorialIndex]();
}

export default FullLengthTutorial;
