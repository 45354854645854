import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import {Link} from 'react-router-dom';

import MasteryReview from '../components/MasteryReview';
import GettingStarted from '../components/GettingStarted';
import FAQs from '../components/WebflowFAQs';

import {H2} from '../../components/Typography';
import ClassroomCard from '../components/Cards/ClassroomRow';

import {useMasteryArray, useTestProgressArray} from '../../hooks';

import {ReactComponent as Coffee} from '@bootcamp/shared/src/assets/svg/coffee.svg';

import {getStreakDetails, getTestsByClassroom} from '@bootcamp/shared/src/util';
import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';

import {formatClassroomMastery, formatClassroomVideoProgress} from '@bootcamp/shared/src/util/formatting';
import {useBootcampConfigContext} from '../../contexts/BootcampConfig';

import Container from '../pages/PageContainer';

import styled from 'styled-components';


const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledClassroomCard = styled(ClassroomCard)`
  flex: 1;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;

const Home = props => {
  const {config} = useBootcampConfigContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'anatomy';

  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);
  const testsByClassroom = getTestsByClassroom(config?.classrooms);

  const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);
  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom), [])

  const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const todayActions = [
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      icon: {
        component: Coffee,
        text: 'Daily Warmup', themeColor: 'royal'
      },
      text: 'New questions brewed daily just for you',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study`,
      },
      checkIfComplete: interactionObj => {
        const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        return streakCompletedToday;
      }
    },
  ];

  const progresses = useMasteryArray(config.classrooms.reduce((acc, {tagId, tagIds}) => tagIds ? [...acc, ...tagIds] :  [...acc, tagId], []), 'subject', data => formatClassroomMastery(data, 'subject'));
  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Anatomy Courses
      </Title>
      <CardWrapper>
        {config?.classrooms?.map(({icon, themePalette, name, route, tagId, tagIds, comingSoon, educators}) => (
          <StyledClassroomCard
            key={tagId}
            iconPath={icon}
            themePalette={themePalette}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={comingSoon}
            comingSoon={comingSoon}
            questionCount={questionCounts?.classroomQuestionCounts ? questionCounts?.classroomQuestionCounts?.[route] || 0 : 'loading'}
            showProgress={true}
            showVideoProgress
            courses={coursesByClassroom[route]}
            videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts ? courseBlockCounts?.classroomCourseBlockCounts?.[route] || 0 : 'loading'}
            progress={
              Object.keys(progresses).length === 0
              ? null
              : tagIds?.length
              ? tagIds?.reduce((acc, tagId) => ({learning: acc.learning + progresses[tagId].learning, reviewing: acc.reviewing + progresses[tagId].reviewing, mastered: acc.mastered + progresses[tagId].mastered}), {learning: 0, reviewing: 0, mastered: 0})
              : progresses[tagId]
            }
            showExpertEducators
            educatorLayout={'column'}
            educators={educators}
            detailsBorder={false}
            withHeaderPadding={false}
          />
        ))}
      </CardWrapper>
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
