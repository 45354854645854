import React from 'react';

import {ReactComponent as Smile} from '../assets/smile.svg';
import {ReactComponent as Click} from '../assets/click.svg';
import {ReactComponent as Videos} from '../assets/videos.svg';

import {
  UpgradeCallout,
  UpgradeHeader,
  UpgradeSubHeader,
  UpgradeFeaturesTable,
  UpgradeFeaturesTableRow,
  UpgradeFeaturesTableRowIconContainer,
  UpgradeFeaturesTableRowText,
} from './shared';


export const oatFeatures = (
  <>
    <UpgradeCallout>
      <UpgradeHeader>
        Get everything you need to ace the OAT <u>in one place</u>
      </UpgradeHeader>
      <UpgradeSubHeader>
        Get instant access to everything below and more 👇
      </UpgradeSubHeader>
    </UpgradeCallout>
    <UpgradeFeaturesTable>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Smile/>
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          <strong>Largest set of study materials</strong> with more practice tests, question banks, and video lessons than anyone else.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Click />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          <strong>Proven OAT study schedule</strong> to help you stay on track and study only what you absolutely need to know.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
      <UpgradeFeaturesTableRow>
        <UpgradeFeaturesTableRowIconContainer>
          <Videos />
        </UpgradeFeaturesTableRowIconContainer>
        <UpgradeFeaturesTableRowText>
          <strong>Premium mobile app</strong> to study on the go. Watch videos, answer questions from wherever.
        </UpgradeFeaturesTableRowText>
      </UpgradeFeaturesTableRow>
    </UpgradeFeaturesTable>
  </>
);

