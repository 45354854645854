import React from 'react';
import styled from 'styled-components';
import {Body3} from '../../../components/Typography';

const CalloutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.05);
`;

const CalloutText = styled(Body3)`
  color: white;
  a {
    color: white;
  }
`;

const Callout = props => (
  <CalloutContainer>
    {props.icon && <div>{props.icon}</div>}
    <CalloutText dangerouslySetInnerHTML={{__html: props.children}} />
  </CalloutContainer>
)

export default Callout;
