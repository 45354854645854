import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Doughnut} from 'react-chartjs-2';

import {Label2,} from '@bootcamp/web/src/components/Typography/next';
import {LineLabelContainer, LineLabelWrapper} from './shared';

import theme from '@bootcamp/shared/src/styles/theme';


const Container = styled.div`
`;
const Wrapper = styled.div`
  position: relative;
  height: 242px;
  canvas {
    width: 266px !important;
  }
`;
const Label = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Top = styled.div`
  color: ${({theme}) => theme.colors.brandPalette.blue.default};
  font-family: proxima-nova;
  font-size: 52.878px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 52.878px */
  letter-spacing: -1.259px;
  text-align: center;
`;
const Bottom = styled(Label2)`
  text-align: center;
  color: ${({theme}) => theme.colors.brandPalette.blue.default};
  font-weight: 700;
`;


const overlap = 0;
const circumference = 290;
const rotation = circumference / 2;

function easeOutQuad(t) {
  return t * (2 - t);
}

function easeInQuad(t) {
  return t * t;
}

function interpolateValue(x, value = 10) {
  const minX = 125;
  const midX = 270;
  const maxX = 415;

  let t;

  if (x < minX) {
      return value; // Below the minimum range
  } else if (x <= midX) {
      // Ease-out from value to 0
      t = (x - minX) / (midX - minX);
      t = easeOutQuad(t);
      return value + t * (0 - value);
  } else if (x <= maxX) {
      // Ease-in from 0 to value
      t = (x - midX) / (maxX - midX);
      t = easeInQuad(t);
      return 0 + t * (value - 0);
  } else {
      return value; // Above the maximum range
  }
}
const averageLinePlugin = (avgScore) => ({
  id: 'averageLine',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;

    const percentage = (avgScore / 100);
    const value = (circumference * percentage);
    const circumfrenceOffset = (360 - circumference) / 2;
    const angle = rotation + value - 20;
    const chartHeight = chart.height + circumfrenceOffset;

    // interpolate value to get more accurate avg line draw
    const offset = interpolateValue(angle, 12);

    ctx.beginPath();
    ctx.moveTo(chart.width / 2, ((chartHeight) / 2));

    const radians = angle * (Math.PI / (180)); // 45 degrees in radians
    const lineLength = 118 - offset // (Math.min(chart.width, chart.height) / 2) - offset;

    const endX = chart.width / 2 + Math.cos(radians) * (lineLength);
    const endY = (chartHeight / 2) + Math.sin(radians) * (lineLength);


    const secondEndX = (endX + (Math.cos(radians)) * 19);
    const secondEndY = (endY + (Math.sin(radians)) * 19);

    ctx.moveTo(endX, endY);
    ctx.lineTo(secondEndX, secondEndY)

    ctx.strokeStyle = theme.colors.borderPalette.secondary; // Set the color of the line
    ctx.lineWidth = 2; // Set the width of the line
    ctx.stroke();

    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    ctx.globalCompositeOperation = 'destination-over';
  }
});

const LineLabel = ({avgScore}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref?.current) return;

    const refDimensions = ref.current.getBoundingClientRect();
    const chart = {width: 266, height: 242};
    const percentage = (avgScore / 100);
    const value = (circumference * percentage);
    const circumfrenceOffset = (360 - circumference) / 2;
    const angle = rotation + value - 20;
    const chartHeight = chart.height + circumfrenceOffset;

    const labelOffsetX  = percentage <= .45
      ? -(refDimensions.width) - 4
      : percentage >= .55
      ? 4
      : -(refDimensions.width / 2);

    const labelOffsetY  = percentage <= .45
      ? (percentage / .5) + refDimensions.height + 4
      : percentage >= .55
      ? (percentage / .5) + refDimensions.height + 4
      : refDimensions.height + 8;

    const radians = angle * (Math.PI / (180)); // 45 degrees in radians
    const lineLength = (Math.min(chart.width, chart.height) / 2);

    const endX = chart.width / 2 + Math.cos(radians) * (lineLength);
    const endY = (chartHeight / 2) + Math.sin(radians) * (lineLength);

    const secondEndX = (endX + (Math.cos(radians)) * 19);
    const secondEndY = (endY + (Math.sin(radians)) * 19);

    ref.current.style.top = (secondEndY - labelOffsetY) + 'px';
    ref.current.style.left = (secondEndX + labelOffsetX) + 'px';

  }, [ref?.current]);

  return (
    <LineLabelWrapper ref={ref}>
      Avg. Score {avgScore}%
    </LineLabelWrapper>
  );
}

function getGradient(ctx, value) {
    const orientation = value > 50
      ? [0, 0, ctx.chart.width , 0]
      : [0, ctx.chart.height, 0 , 0]
    const gradient = ctx.chart.ctx.createLinearGradient(...orientation);
    const {gradientStart, gradientMid, dark} = theme.colors.brandPalette.blue;

    gradient.addColorStop(0, dark);
    gradient.addColorStop(.5, gradientMid);
    gradient.addColorStop(1, gradientStart);

    return [gradient, theme.colors.neutralsPalette.light];
}
const GaugeChart = ({
  value,
  avgScore,
  showLineLabel,
}) => {
  // const [avgScore, setAvgScore] = useState(avgScore);
  const scoreSegment = value === 0 ? 0 : Math.max(value, 5);
  const areaSegment = value === 100 ? 0 : Math.max(4, 100 - value);

  const plugin = averageLinePlugin(avgScore);

  const data = {
    datasets: [
      {
        data: [scoreSegment, areaSegment],
        backgroundColor: ctx => getGradient(ctx, value),
        borderColor: ctx => getGradient(ctx, value),
        cutout: '85%',
        rotation: -(rotation),
        spacing: -(overlap),
        circumference,
        hoverOffset: 0, // Remove hover effect
        borderRadius: [
          {outerStart: 10, innerStart: 10, innerEnd: value === 100 ? 10 : 0, outerEnd: value === 100 ? 10 : 0},
          {outerStart: value === 0 ? 10 : 0, outerEnd: 10, innerEnd: 10, innerStart: value === 0 ? 10 : 0}
        ]
      },
    ],
  };

  const options = {
    responsive: true,
    labels: [],
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: 0
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false, // hide legends
      },
    },
  };

  return (
    <Container>
      <Wrapper>
        <Doughnut data={data} options={options} plugins={[plugin]} />
        <Label>
          <Top>{value}%</Top>
          <Bottom>Your Score</Bottom>
        </Label>
        {showLineLabel && <LineLabelContainer>
          <LineLabel avgScore={avgScore} />
        </LineLabelContainer>}
      </Wrapper>
      {/* <button onClick={() => setAvgScore(Math.max(0, avgScore - 1))}>down</button>
      <button onClick={() => setAvgScore(Math.max(0, avgScore + 1))}>up</button> */}
    </Container>
  );
};

export default GaugeChart;