import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {withRouter, Link} from 'react-router-dom';
import SVG from 'react-inlinesvg';

import {useUserDataContext} from '../../../contexts/UserData';
import {resolveIconPath} from '@bootcamp/web/src/helpers';

import {Label3, H4} from '../../Typography';
import Menu, {MenuIcon, MenuButton} from '../components/Menu';
import {Close} from '../components/Buttons';
import {HomeIcon, SearchIcon} from '../../Branding/Classroom/Icons';
import ConfigSearch from '../components/ConfigSearch';
import {trackOptions} from '../../../router/components/SideBar/TrackSelect';

import {colors} from '@bootcamp/shared/src/styles/theme';
import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {ChevronLeft} from '@styled-icons/fluentui-system-filled/ChevronLeft';
import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';

import {
  barHeight,
  barHeightMobile,
  Container,
  ContainerBackground,
  Content,
  Left,
  Right,
  LoggedIn,
  LoggedOut,
  Loader,
} from '../components';


const ChevronButton = styled.div`
  border-radius: 64px;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 0;
  &:hover {
    background: ${({theme}) => theme.overlays.opacity.light._200};
    cursor: pointer;
  }
  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
`;
const StyledChevronLeft = styled(ChevronLeft)`
  color: white;
`;
const StyledChevronRight = styled(ChevronRight)`
  color: white;
`;

const StyledConfigSearch = styled(ConfigSearch)`
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const StyledContainer = styled(Container)`
  padding: 0;
`;
const Section = styled.div`
  width: 100%;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
const SectionTitle = styled(Label3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  padding: 0px 2px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  font-weight: ${({theme}) => theme.font.weight.semibold};

  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
    padding: 0px;
  }
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  width: calc(25% - 4px);
  height: 64px;
  align-items: center;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: ${({theme}) => theme.layouts.spacing.m} 12px;
  margin: 2px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  border-radius: 8px;
  text-decoration: none;

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    width: calc(33.3333% - 4px);
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
    margin: 0px 0px 4px 0px;
  }
`;
const IconWrapper = styled.span`
  display: flex;
  background: ${({color}) => color};
  padding: 8px;
  border-radius: 24px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};

  svg {
    width: 20px;
    height: 20px;
    color: white;
  }
`;
const ItemTitle = styled(H4)`
  font-weight: ${({theme}) => theme.font.weight.normal};
`;
const StyledMenu = styled(Menu)`
  background: ${({theme}) => theme.colors.neutralsPalette.light};
  padding: 24px 22px;
`;

const StyledMenuButton = styled(MenuButton)`
  display: none;

  ${({theme}) => theme.mediaQueries.laptop} {
    display: initial;
  }
`;
const StyledContent = styled(Content)`
    padding: 0 32px;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0;
  }
`;

const ArrowForward = styled(ArrowForwardOutline).attrs(props => ({size: 20}))`
  color: #B5B4B2;
  margin-left: auto;
`;

function renderMenuItems(onMenuItemClick, history, config, bootcamp, setBootcamp) {
  const sectionTitles = {
    classrooms: 'Classrooms',
    studyTools: 'Study Tools',
    upNext: 'Up Next',
  };

  const ignore = ['meta'];

  const fromConfig = Object
    .keys(config)
    .filter(key => !ignore.includes(key))
    .map(key => (
      <Section key={`nav-menu-section-${key}`}>
        <SectionTitle>{sectionTitles[key]}</SectionTitle>
        <ItemWrapper>
          {key === 'classrooms' &&
            <Item key={`nav-menu-item-${bootcamp}`} to={`/${bootcamp}`}>
              <IconWrapper color={colors.brandPalette.indigo.default}>
                <HomeIcon/>
              </IconWrapper>
              <ItemTitle>Home</ItemTitle>
            </Item>
          }
          {key === 'studyTools' && bootcamp === 'med-school' &&
            <Item key={`nav-menu-item-${bootcamp}-explore`} to={`/${bootcamp}/explore`}>
              <IconWrapper color={colors.brandPalette.indigo.default}>
                <SearchIcon/>
              </IconWrapper>
              <ItemTitle>Explore</ItemTitle>
            </Item>
          }
          {Array.isArray(config[key]) && config[key].map(({name, route, icon, themePalette, href}, index) => href ? (
            <Item as={'a'} key={`nav-menu-item-${href}`} href={href}>
              {icon &&
                <IconWrapper color={themePalette.default}>
                  {<SVG src={resolveIconPath(icon)}/>}
                </IconWrapper>}
              <ItemTitle>{name}</ItemTitle>
              {!icon && <ArrowForward />}
            </Item>
          ) : (
            <Item key={`nav-menu-item-${route}-${index}`} to={`/${bootcamp}/${route}`} onClick={onMenuItemClick}>
              {icon &&
                <IconWrapper color={themePalette.default}>
                  {<SVG src={resolveIconPath(icon)}/>}
                </IconWrapper>}
              <ItemTitle>{name}</ItemTitle>
              {!icon && <ArrowForward />}
            </Item>
          ))}
        </ItemWrapper>
      </Section>
    ));

  return [
    ...fromConfig,
    <Section key={`nav-menu-section-bootcamps`}>
      <SectionTitle>Bootcamps</SectionTitle>
      <ItemWrapper>
        {Object.values(trackOptions).reduce((acc, curr) => [...acc, ...curr], []).map(({name, route}, index) => (
          <Item as={Link} to={`/${route}`} key={`nav-menu-item-${route}-${index}`} onClick={() => setBootcamp(route)}>
            <ItemTitle>{name}</ItemTitle>
          </Item>
        ))}
      </ItemWrapper>
    </Section>
  ];
}

const NavBar = withRouter(({className, history, themePalette, withoutBorder, titleComponent, lockAtDepth}) => {
  const {cognitoUser, loading, isUpgraded, trialMembership, setBootcamp, bootcamp} = useUserDataContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const showLogo = history.location.pathname.split('/').length < 3;
  const loggedIn = !!cognitoUser.username;
  const AccountPage = useCallback(() => (
    <Right showLogo={showLogo} loggedIn={loggedIn}>
      {loggedIn ? <LoggedIn /> : <LoggedOut/>}
    </Right>
  ), [loggedIn]);
  return (
    <StyledContainer id={'app-nav-bar'} className={className} withoutBorder={withoutBorder}>
      <ContainerBackground color={'transparent'} />
      <StyledContent>
        {bootcamp === 'nclex' ? (
          <Left>
            <StyledMenuButton
              type={'secondary'} size={'small'}
              children={menuOpen ? <Close /> : <MenuIcon />}
              onClick={() => loggedIn && setMenuOpen(!menuOpen)}
              disabled={!loggedIn}
            />
            <StyledMenu
              loggedIn={loggedIn}
              isUpgraded={isUpgraded}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              barHeight={barHeight}
              barHeightMobile={barHeightMobile}
              renderMenuItems={(...itemProps) => renderMenuItems(...itemProps, setBootcamp)}
              themePalette={themePalette}
              fullWidth
            />
            <ChevronButton title="Back" onClick={history.goBack}>
              <StyledChevronLeft size="24" style={{paddingRight: '2px'}} />
            </ChevronButton>
            <ChevronButton title="Forward" onClick={history.goForward}>
              <StyledChevronRight size="24" style={{paddingLeft: '2px'}} />
            </ChevronButton>
          </Left>
        ) : (
          <Left>
            <StyledMenuButton
              type={'secondary'} size={'small'}
              children={menuOpen ? <Close /> : <MenuIcon />}
              onClick={() => loggedIn && setMenuOpen(!menuOpen)}
              disabled={!loggedIn}
            />
            <StyledMenu
              loggedIn={loggedIn}
              isUpgraded={isUpgraded}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              barHeight={barHeight}
              barHeightMobile={barHeightMobile}
              renderMenuItems={(...itemProps) => renderMenuItems(...itemProps, setBootcamp)}
              themePalette={themePalette}
              fullWidth
            />
            <StyledConfigSearch />
          </Left>
        )}
        <AccountPage />
      </StyledContent>
    </StyledContainer>
  )
});

export default NavBar;
