import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {TimerContext, useTimerContext, useStopwatch, useMinuteTimer} from '@bootcamp/shared/src/util/contexts/Timer';

const Timer = styled.div`
  cursor: pointer;
  text-align: right;
`;

const Time = styled.span`
  /* width: 45px; */
  text-align: left;
  display: inline-block;
`;

const HideMobile = styled.span`
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`

const TestTimer = () => {
  const {timeLeft, toggleTimer, timerActive, stopwatch, timeElapsed, updateTimeLimit, disabled} = useTimerContext();
  if (disabled) return null;

  return (
    <Timer onClick={() => toggleTimer()} onContextMenu={e => {
      const newTimer = window.prompt('Set new timer duration (minutes), or click cancel');
      if (!newTimer || !parseInt(newTimer) || parseInt(newTimer) <= 0 || stopwatch) return;
      updateTimeLimit(newTimer);
      toggleTimer();
    }}>
      <HideMobile>{timerActive ?`Time ${stopwatch ? 'elapsed' : 'remaining'}:` : `Timer paused:`}</HideMobile> <Time>{timeLeft || timeElapsed}</Time>
    </Timer>
  );
}


const TimerProvider = ({timeLimit, stopwatch, children, autoStart = false, disabled, questionIndex}) => {
  const [questionStartTime, setQuestionStartTime] = useState(null);

  const useStopwatchProps = useStopwatch(disabled);
  const useMinuteTimerProps = useMinuteTimer(timeLimit, autoStart, disabled);

  const timerProps = stopwatch ? useStopwatchProps : useMinuteTimerProps;
  const contextValue = {...timerProps, questionStartTime, stopwatch, timeLimit};

  useEffect(() => {
    if (!disabled && timerProps.timerActive) {
      setQuestionStartTime(new Date());
    }
  }, [questionIndex, timerProps.timerActive]);

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
    </TimerContext.Provider>
  );
}

export {TestTimer, useTimerContext, TimerProvider};
