import React, {useRef} from 'react';
import styled from 'styled-components';

import {ChevronDown} from '@styled-icons/fluentui-system-filled/ChevronDown';

import {H4} from '../../../components/Typography';

const Container = styled.div`
  display: inline-flex;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border: 1px solid ${({theme}) => theme.overlays.opacity.light._50};
  border-radius: 8px;
  position: relative;
  z-index: 2;
`;
const Input = styled(H4)`
  padding: ${({theme}) => theme.layouts.spacing.m};
  background: transparent;
  color: white;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  flex: 1;
  font-size: ${({theme}) => theme.font.size.small};
  line-height: ${({theme}) => theme.font.height.small2};
  font-weight: ${({theme}) => theme.font.size.semibold};
  padding-right: calc(${({theme}) => theme.layouts.spacing.m} + 44px);
  z-index: 1;
  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 100%;
  }
`;
const Option = styled.option`
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
`;
const Icon = styled(ChevronDown)`
  position: absolute;
  stroke: white;
  top: calc(50% - 10px); /* calculating 50% - half the icon height to center*/
  right: 12px;
  cursor: pointer;
  z-index: -1;
  opacity: ${({disabled}) => disabled ? 0.7 : 1};
`;

export const BareSelect = ({className, config, onChange, loading}) => {
  const inputRef = useRef();

  return (
    <Container className={className}>
      <Input ref={inputRef} as={'select'} onChange={event => onChange(event.target.value)} disabled={loading}>
        {config.map(({name, value}) => (
          <Option key={`option-${name}`} value={value}>{name}</Option>
        ))}
      </Input>
      <Icon size={20} color={'white'} disabled={loading}/>
    </Container>
  );
};

const Select = ({className, config, onChange, loading}) => {
  const inputRef = useRef();

  return (
    <Container className={className}>
      <Input ref={inputRef} as={'select'} onChange={event => onChange(event.target.value)} disabled={loading}>
        <Option value={'all'}>{loading ? 'Loading...' : 'Filter By: All Subjects'}</Option>
        {config.map(({name}) => (
          <Option key={`progress-filter-${name}`} value={name}>Filter By: {name}</Option>
        ))}
      </Input>
      <Icon size={20} color={'white'}/>
    </Container>
  );
};

export const TopicSelect = ({className, config, onChange, subject}) => {
  const inputRef = useRef();

  return (
    <Container className={className} onClick={() => inputRef?.current?.click()} key={subject}>
      <Input ref={inputRef} as={'select'} onChange={event => onChange(event.target.value)}>
        <Option value={'all'}>Filter By: All Topics</Option>
        {config.map(({tag}) => (
          <Option key={`progress-filter-${tag.topic}`} value={tag.id}>Filter By: {tag.topic}</Option>
        ))}
      </Input>
      <Icon size={20}/>
    </Container>
  );
};

Select.defaultProps = {};
Select.propTypes = {};

export default Select;
