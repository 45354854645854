import React, {useEffect} from 'react';
import useDocumentTitle from '@rehooks/document-title';
import {useTestContext} from '../../../contexts/TestBase';
import {useUserDataContext} from '../../../contexts/UserData';

import QuestionBank from '../../../components/QBank';
import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {colors} from '@bootcamp/shared/src/styles/theme';

const GrossAnatomy = props => {
  const {config} = useBootcampConfigContext();

  const {bootcamp, cognitoUser, userModel} = useUserDataContext();
  const username = cognitoUser?.attributes?.name || cognitoUser?.attributes?.email || userModel?.id;
  const {composeQuickStudyByContentType} = useTestContext();

  const interactionKey = `Streak-${bootcamp}-Review-Anatomy`;

  useDocumentTitle(`${config.meta.siteTitle} | Quick Study | Gross Anatomy`);

  useEffect(() => {
    if (!userModel?.id || !config?.meta?.subjectIds) return;
    // add more specific subject id parsing here
    composeQuickStudyByContentType(config?.meta?.subjectIds, 'anatomy');

  }, [userModel?.id, config]);

  return (
    <TestNavigatorProvider
      {...props}
      template={'quickReview'}
      interactionKey={interactionKey}
      skipStartBlock
    >
      <QuestionBank match={props.match} themePalette={colors.brandPalette.royal} username={username} enableBookmarking />
    </TestNavigatorProvider>
  )
}

export default GrossAnatomy;
