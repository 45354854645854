import React from 'react';

const UrineBMI = () => (
	<table>
		<thead>
			<tr>
				<th class="labName">Urine</th>
				<th class="refRange">Reference Range</th>
				<th class="SI">SI Reference</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Calcium</td>
				<td class="refRange">100–300 mg/24 h</td>
				<td class="SI">2.5–7.5 mmol/24 h</td>
			</tr>
			<tr>
				<td>Osmolality</td>
				<td class="refRange">50–1200 mOsmol/kg H<sub>2</sub>O</td>
				<td class="SI">50–1200 mOsmol/kg H<sub>2</sub>O</td>
			</tr>
			<tr>
				<td>Oxalate</td>
				<td class="refRange">8–40 µg/mL</td>
				<td class="SI">90–445 µmol/L</td>
			</tr>
			<tr>
				<td>Proteins, total</td>
				<td class="refRange">&lt;150 mg/24 h</td>
				<td class="SI">&lt;0.15 g/24 h</td>
			</tr>
			<tr>
				<td rowspan="2">17-Hydroxycorticosteroids</td>
				<td class="refRange">Male: 3.0–10.0 mg/24 h</td>
				<td class="SI">8.2–27.6 µmol/24 h</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 2.0–8.0 mg/24 h</td>
				<td class="SI">5.5–22.0 µmol/24 h</td>
			</tr>
			<tr>
				<td rowspan="2">17-Ketosteroids, total</td>
				<td class="refRange">Male: 8–20 mg/24 h</td>
				<td class="SI">28–70 µmol/24 h</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 6–15 mg/24 h</td>
				<td class="SI">21–52 µmol/24 h</td>
			</tr>
		</tbody>
		<thead>
			<tr>
				<th class="labName">Body Mass Index (BMI)</th>
				<td colspan="2">Adult: 19–25 kg/m<sup>2</sup></td>
			</tr>
		</thead>
	</table>
);

export default UrineBMI;