import React, {useState, useCallback, useMemo} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import styled from 'styled-components';

import MasteryCard, { NCLEXMasteryCard } from '../../components/MasteryReview/Card';
import MasterySelect, {TopicSelect} from '../../components/MasteryReview/Select';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import Overview from '../../components/Cards/Overview';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';

import {useMasteryArray} from '../../../hooks';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import {getClassroomQuestionCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/shared/src/util/hooks';
import {formatClassroomMasteryWithTopic, formatMasteryCardLink} from '@bootcamp/shared/src/util/formatting';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';

import {Blurb} from '../CustomTestReview/components/shared';

import {colors} from '@bootcamp/shared/src/styles/theme';

import emily from '@bootcamp/shared/src/assets/educators/emily_giddings.jpeg';
import ari from '@bootcamp/shared/src/assets/educators/ari.gif';

import Container from '../PageContainer';
import { useUserDataContext } from '../../../contexts/UserData';
import { useModalContext } from '../../../contexts/Modal';

import {H1} from '../../../components/Typography';

const Title = styled(H1)`
  color: white;
  margin-bottom: 12px;
`;

const StyledOverview = styled(Overview)`
  margin-bottom: 0;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;

  /* copy / pasted from the Hero typography element for easy testing */

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0px;
  }
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const Wrapper = styled.div`
`;
const StyledMasterySelect = styled(MasterySelect)`
  margin: 0px ${({theme}) => theme.layouts.spacing.s} ${({theme}) => theme.layouts.spacing.xl} 0px;
`;
const NCLEXMasterySelect = styled(StyledMasterySelect)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  select {
    color: ${({theme}) => theme.colors.neutralsPalette.white};
    font-weight: normal;
    padding: 8px 12px;
  }
  svg {
    stroke: ${({theme}) => theme.colors.neutralsPalette.white};
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection};
  width: 100%;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const StyledBlurb = styled(Blurb)`
  margin: ${({theme}) => theme.layouts.spacing.s} 0px ${({theme}) => theme.layouts.spacing.m};
`;

const NCLEXReviewBanks = ({bootcamp, config, isUpgraded}) => {
  const [filter, setFilter] = useState(null);
  const [topicFilter, setTopicFilter] = useState(null);

  const filteredClassrooms = config
    ?.classrooms
    ?.filter(({testing, free, route}) => !testing && !(isUpgraded && free) && !['next-gen-strategy-course'].includes(route));

  const testsByClassroom = getTestsByClassroom(filteredClassrooms);
  const {modalDispatch} = useModalContext();

  const getQuestionCounts = useCallback(() =>  getClassroomQuestionCounts(bootcamp, testsByClassroom),[])
  let {value: questionCounts, pending} = useAsync(getQuestionCounts);
  if (isUpgraded) {
    delete questionCounts?.classroomQuestionCounts?.['free-questions'];
  }
  const totalQuestions = Object
    .values(questionCounts?.classroomQuestionCounts || {})
    .reduce((acc, curr) => acc + curr, 0);

  const testIds = Object.values(testsByClassroom)?.reduce((acc, testIds) => [...acc, ...testIds], []);

  const progresses = useMasteryArray(testIds, 'test');
  const activeClassroomFilter = filter !== 'all' ? filteredClassrooms.find(({name}) => name === filter) : null;
  const loading = !Object.keys(progresses || {}).length || pending;

  const progressIndexedByTestId = useMemo(() => {
    return Object.keys(progresses)
      ?.reduce((acc, key) => ([
        ...acc,
        ...progresses[key]?.data?.QuestionMasteryByTestId?.items
      ]), [])
      ?.reduce((acc, data) => {
        const testId = data?.userIdHashTestId?.split('#')[1];

        const name = Object
          .keys(testsByClassroom)
          .find(key => testsByClassroom[key].includes(testId));

        const masteryLevel = data?.masteryLevel;
        const bookmarked = data?.bookmarked;

        return {
          ...acc,
          [name]: {
            ...(acc[name] || {}),
            [masteryLevel]: (acc?.[name]?.[masteryLevel] || 0) + 1,
            bookmarked: (acc?.[name]?.bookmarked || 0) + bookmarked,
          }
        };
      }, {});

  }, [progresses]);

  const classroomProgress = filteredClassrooms
    .filter(({name}) => !activeClassroomFilter ? true : activeClassroomFilter?.name === name)
    .map(({name, route}) => ({
      name,
      route,
      progress: progressIndexedByTestId[route],
    }))
    .reduce((acc, {name, progress}) => {
      if (!name || !progress) return acc;

      return {
        learning: acc.learning + (progress?.learning || 0),
        reviewing: acc.reviewing + (progress?.reviewing || 0),
        mastered: acc.mastered + (progress?.mastered || 0),
        bookmarked: acc.bookmarked + (progress?.bookmarked || 0),
        total: acc.total + (progress?.total || 0),
      }
    }, {
      learning: 0,
      reviewing: 0,
      mastered: 0,
      bookmarked: 0,
      total: 0,
    });

  // this component isn't using topic filter currently, but leaving in case we decide to implement
  // in the future
  const masteryLevelProgress = activeClassroomFilter && topicFilter && topicFilter !== 'all'
    ? progresses?.[activeClassroomFilter?.tagId]?.byTopic[topicFilter]
    : classroomProgress;

  function setSubjectFilter(subjectFilter) {
    setTopicFilter(null);
    setFilter(subjectFilter);
  }

  function handleCardClick(masteryLevel) {
    modalDispatch({
      type: 'open',
      component: CreateTest,
      enableClickClose: true,
      componentProps:{
        defaults: {
          subject: activeClassroomFilter?.name,
          masteryLevel
        }
      }
    });
  }

  return (
    <Wrapper>
      <NCLEXMasterySelect config={filteredClassrooms} onChange={setSubjectFilter} loading={loading} />
      {/* {activeClassroomFilter && activeClassroomFilter?.tagId && !loading && progresses?.[activeClassroomFilter?.tagId] &&
        <TopicSelect
          subject={activeClassroomFilter.name}
          config={Object.values(progresses?.[activeClassroomFilter?.tagId]?.byTopic || {})}
          onChange={setTopicFilter}
        />
      } */}
      <CardWrapper flexDirection={bootcamp === 'nclex' ? 'column' : 'row'}>
        <NCLEXMasteryCard
          color={colors.interfacePalette.red.gradient}
          level={'learning'}
          bootcamp={bootcamp}
          activeFilter={activeClassroomFilter}
          count={masteryLevelProgress.learning}
          questionCount={totalQuestions}
          onClick={() => handleCardClick('learning')}
        />
        <NCLEXMasteryCard
          color={colors.interfacePalette.yellow.gradient}
          level={'reviewing'}
          bootcamp={bootcamp}
          activeFilter={activeClassroomFilter}
          count={masteryLevelProgress.reviewing}
          questionCount={totalQuestions}
          onClick={() => handleCardClick('reviewing')}
        />
        <NCLEXMasteryCard
          color={colors.interfacePalette.green.gradient}
          level={'mastered'}
          bootcamp={bootcamp}
          activeFilter={activeClassroomFilter}
          count={masteryLevelProgress.mastered}
          questionCount={totalQuestions}
          onClick={() => handleCardClick('mastered')}
        />
        <NCLEXMasteryCard
          color={colors.brandPalette.blue.gradient}
          count={masteryLevelProgress.bookmarked}
          questionCount={totalQuestions}
          level={'bookmarked'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          onClick={() => handleCardClick('bookmarked')}
        />
      </CardWrapper>
    </Wrapper>
  );
}

const DefaultReviewBanks = ({config, bootcamp}) => {
  const [filter, setFilter] = useState(null);
  const [topicFilter, setTopicFilter] = useState(null);

  const filteredClassrooms = config
    ?.classrooms
    ?.filter(({locked, route}) => !locked && !['reading-comprehension'].includes(route))

  const subjectTagIds = filteredClassrooms?.reduce((acc, {tagId, tagIds}) => tagIds ? [...acc, ...tagIds] : tagId ? [...acc, tagId] : acc, []);

  const progresses = useMasteryArray(subjectTagIds, 'subjectWithQuestionTags', data => formatClassroomMasteryWithTopic(data, 'subject'));
  const activeClassroomFilter = filter !== 'all' ? filteredClassrooms.find(({name}) => name === filter) : null;
  const loading = !Object.keys(progresses || {}).length;

  const classroomProgress = filteredClassrooms
    .map(({name, route, tagId, tagIds}) => ({
      name,
      route,
      progress: tagIds?.length > 0
        ? Object.keys(progresses)
          .reduce((acc, tagId) => tagIds.includes(tagId)
            ? {
                learning: (acc.learning || 0) + progresses[tagId].learning,
                reviewing: (acc.reviewing || 0) + progresses[tagId].reviewing,
                mastered: (acc.mastered || 0) + progresses[tagId].mastered,
                bookmarked: (acc.bookmarked || 0) + progresses[tagId].bookmarked,
              }
            : acc, {})
        : progresses[tagId]}))
    .reduce((acc, {name, progress}) => {
      const shouldFilter = filter && filter !== 'all' && filter !== name;

      if (!name || !progress || shouldFilter) return acc;

      return {
        learning: acc.learning + progress.learning,
        reviewing: acc.reviewing + progress.reviewing,
        mastered: acc.mastered + progress.mastered,
        bookmarked: acc.bookmarked + progress.bookmarked,
        total: acc.total + progress.total,
      }
    }, {
      learning: 0,
      reviewing: 0,
      mastered: 0,
      bookmarked: 0,
      total: 10,
    });


  const masteryLevelProgress = activeClassroomFilter && topicFilter && topicFilter !== 'all'
    ? progresses?.[activeClassroomFilter?.tagId]?.byTopic[topicFilter]
    : classroomProgress;

  function setSubjectFilter(subjectFilter) {
    setTopicFilter(null);
    setFilter(subjectFilter);
  }

  return (
    <Wrapper>
      <StyledMasterySelect config={filteredClassrooms.filter(({ route }) => !['reading-comprehension', 'review-videos', 'application-services'].includes(route))} onChange={setSubjectFilter} loading={loading} />
      {activeClassroomFilter && activeClassroomFilter?.tagId && !loading && progresses?.[activeClassroomFilter?.tagId] &&
        <TopicSelect
          subject={activeClassroomFilter.name}
          config={Object.values(progresses?.[activeClassroomFilter?.tagId]?.byTopic || {})}
          onChange={setTopicFilter}
        />
      }
      <CardWrapper flexDirection={bootcamp === 'nclex' ? 'column' : 'row'}>
        <MasteryCard
          color={colors.interfacePalette.red.default}
          backgroundColor={colors.interfacePalette.red.gradient}
          level={'learning'}
          bootcamp={bootcamp}
          activeFilter={activeClassroomFilter}
          count={masteryLevelProgress?.learning}
          linkTo={formatMasteryCardLink(activeClassroomFilter?.route, 'learning', topicFilter)}
          textLayout={'column'}
        />
        <MasteryCard
          color={colors.interfacePalette.yellow.default}
          backgroundColor={colors.interfacePalette.yellow.gradient}
          level={'reviewing'}
          bootcamp={bootcamp}
          activeFilter={activeClassroomFilter}
          count={masteryLevelProgress?.reviewing}
          linkTo={formatMasteryCardLink(activeClassroomFilter?.route, 'reviewing', topicFilter)}
          textLayout={'column'}
        />
        <MasteryCard
          color={colors.interfacePalette.green.default}
          backgroundColor={colors.interfacePalette.green.gradient}
          level={'mastered'}
          bootcamp={bootcamp}
          activeFilter={activeClassroomFilter}
          count={masteryLevelProgress?.mastered}
          linkTo={formatMasteryCardLink(activeClassroomFilter?.route, 'mastered', topicFilter)}
          textLayout={'column'}
        />
        <MasteryCard
          color={colors.brandPalette.royal.default}
          backgroundColor={colors.interfacePalette.green.gradient}
          count={masteryLevelProgress?.bookmarked}
          level={'bookmarked'}
          activeFilter={activeClassroomFilter}
          bootcamp={bootcamp}
          linkTo={formatMasteryCardLink(activeClassroomFilter?.route, 'bookmarked', topicFilter)}
          textLayout={'column'}
        />
      </CardWrapper>
    </Wrapper>
  );
}

const ReviewBanks = props => {
  const {isUpgraded, bootcamp} = useUserDataContext();
  const {config} = useBootcampConfigContext();

  useDocumentTitle(` ${config.meta.siteTitle} |  Tagged Questions`);

  const ReviewBankComponent = bootcamp === 'nclex'
    ? NCLEXReviewBanks
    : DefaultReviewBanks;

  return (
    <Container>
      <StyledBreadcrumbs history={props.history} lockAtDepth={2}/>
      <Title>Tagged Questions</Title>
      <StyledBlurb
        text={bootcamp !== 'nclex' ? 'Once you tag questions you’ll be able to easily study them by mastery level using the review banks below.' : 'NCLEX Bootcamp\'s question tagging system lets you rate your understanding of each question. Tagging helps optimize review time, so you can focus on learning new NCLEX material instead of rehashing known topics. Review your tagged questions below.'}
        lightMode
        icon={bootcamp !== 'nclex' ? ari : emily}
      />
      <ReviewBankComponent
        bootcamp={bootcamp}
        config={config}
        isUpgraded={isUpgraded}
      />
    </Container>
  );
}

ReviewBanks.propTypes = {};
ReviewBanks.defaultProps = {};

export default ReviewBanks;
