import React, {useState, useEffect} from 'react';
import {useHistory, Link} from 'react-router-dom';
import API from '@aws-amplify/api';
import styled from 'styled-components';
import moment from 'moment';

import {useModalContext} from '../../../../../contexts/Modal';
import {H3, H4, Body3} from '../../../../../components/Typography';
import {Button} from  '../../../../../components/Branding/Buttons';
import UpdatePaymentMethod from '../../../../../components/Form/Stripe/UpdatePaymentMethod';
import {getFormattedStripeAmount, findStartAndEndDates} from '@bootcamp/shared/src/util';
import PaymentHistory from './PaymentHistory';
import extensionPriceIdMap, {extensionIdsByBootcamp} from '../../extensionPriceIds';
import ManageMembership from '../../../../../components/Form/Stripe/ManageMembership';
import {Dismiss} from '@styled-icons/fluentui-system-filled/Dismiss';
import {RoundButton} from '@bootcamp/web/src/components/UI';
import {invokeLambda} from '@bootcamp/shared/src/requests';
const ModalContainer = styled.div`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  box-shadow: ${({theme}) => theme.elevation.shadow.high};
  border-radius: 8px;
  height: auto;
  max-width: 491px;
  margin: auto;
  align-items: center;
  color: #2F3037;
  width: 90%;
`
const ModalSide = styled.div`
  padding: 16px 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalHeader = styled(ModalSide)`
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`;

const ModalFooter = styled(ModalSide)`
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`;

const CloseModal = styled(Dismiss)`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 16px;
  width: 16px;
  color: ${({theme}) => theme.colors.special.tuxedo};
  cursor: pointer;
`

const ModalBody = styled.div`
  padding: 16px 24px;
`

const ExtensionInput = styled.input`
  cursor: pointer;
  accent-color: ${({theme}) => theme.colors.brandPalette.royal.default};
  border-color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;

const ExtensionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  &:hover {
    background: #FAFAFA;
  }
  ${({selected, theme}) => selected && `
    border: 1px solid ${theme.colors.brandPalette.royal.default};
    background: ${theme.colors.brandPalette.royal.light};
    &:hover {
      background: ${theme.colors.brandPalette.royal.light};
    }
  `}
  &:last-child {
    margin-bottom: 0;
  }
`
const ExtensionLabel = styled.div`
  margin-left: 8px;
  ${Body3} {
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
    font-size: 16px;
    line-height: 150%;
    margin-top: 4px;
    font-weight: 400;
  }
`
const ExtensionPrice = styled.div`
  margin-left: auto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 22.5px */
`

const SubmitButton = styled(RoundButton)``;

const SelectExtensionOptionModal = ({close, extensions}) => {
  const [selectedExtension, setSelectedExtension] = useState(extensions[1].priceId);
  const {push} = useHistory();
  return (
    <ModalContainer onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }}>
      <ModalHeader>
        <H3>
          Extend Your Membership
        </H3>
        <CloseModal onClick={close} />
      </ModalHeader>
      <ModalBody>
        {extensions.map(extension => (
          <ExtensionContainer selected={selectedExtension === extension.priceId} key={extension.id} onClick={() => setSelectedExtension(extension.priceId)}>
            <ExtensionInput
              type={'radio'}
              name={'extension'}
              checked={selectedExtension === extension.priceId}
              onChange={() => setSelectedExtension(extension.priceId)}
            />
            <ExtensionLabel>
              <H3>{extension.duration} Days</H3>
              <Body3>Extends to {extension.expirationDate}</Body3>
            </ExtensionLabel>
            <ExtensionPrice>
              ${extension.price}
            </ExtensionPrice>
          </ExtensionContainer>
        ))}
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          full
          onClick={() => {
            push(`/stripe-checkout?priceId=${selectedExtension}&mode=payment&afterpay=false`);
          }}
          disabled={!selectedExtension}
        >
          Proceed to Payment
        </SubmitButton>
      </ModalFooter>
    </ModalContainer>
  );
}

const Container = styled.div`
  background: ${({theme}) => theme.colors.special.pearl};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  border-radius: 8px;
  overflow: hidden;
  transition: all 100ms ease 0s;
  &:hover {
    transform: scale(1.01);
  }
`;
const Wrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 1;
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};
  cursor: pointer;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: ${({theme}) => theme.colors.brandPalette.royal.default};
  border-radius: 8px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;
const IconText = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  font-size: 12px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const MembershipTitle = styled(H3)`
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;
const MembershipDetails = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const Action = styled(Button)`
  margin-left: auto;
`;

// this map should be updated based on our new price id setup in stripe
const titleMap = {
  MedSchoolBootcamp: 'Med School',
  DentalSchoolBootcamp: 'Dental School',
};

async function fetchBillingDetails(subscriptionId) {
  try {
    const result = await invokeLambda('tbc-Stripe', {path: '/stripe/billing', subscriptionId});
    return result.body.billingInformation;
  } catch (error) {
    console.log('error fetching billing details', error);
    return {client_secret: null};
  }
};
function getMembershipBootcamp(groups) {
  const bootcamp = groups.find(group => group.match('Bootcamp'));
  return `${titleMap[bootcamp] || bootcamp.split('Bootcamp')[0]} Bootcamp`;
}
function getBootcampSlug(groups) {
  const bootcamp = groups.find(group => group.match('Bootcamp'));
  return bootcamp.split('Bootcamp')[0].toLowerCase();
}
function formatDuration(duration) {
  const now = moment();
  const expiration = moment().add(duration + 5, 'days');

  const years = expiration.diff(now, 'years');
  const months = expiration.diff(now, 'months');

  return years
    ? `${years} Year Access`
    : months
    ? `${months} Month Access`
    : `${duration} Day Access`;
}
function getMembershipType(groups, type, duration) {
  const hasPlusPackAccess = groups.find(group => group.match('Plus'));
  const plusPackAccessType = hasPlusPackAccess ? 'Plus' : 'Pro';
  const usesProPlus = groups.find(group => group.match('DAT') || group.match('OAT') || group.match('Dental'));

  const membershipType = usesProPlus
    ? plusPackAccessType
    : formatDuration(duration);

    // if subscription just say monthly access
  return usesProPlus
    ? `${membershipType}${type === 'subscription' ? ' Subscription' : ''}`
    : type === 'subscription'
    ? 'Monthly Access'
    : membershipType;
}
function getExpirationDate(startDate, duration) {
  return moment(startDate).add(duration, 'days');
}
function getNextInvoiceDate(subscriptionDetails, status) {
  if (!subscriptionDetails) return '';
  if (status === 'cancelled') {
    return `Cancelled`
  }
  if (status === 'cancelAtEndOfPeriod') {
    return `Cancelled - Membership expires ${moment.unix(subscriptionDetails?.nextInvoice).format('MMM Do, YYYY')}`
  }
  return `Next Invoice: $${getFormattedStripeAmount(subscriptionDetails?.lastAmountPaid)} on ${moment.unix(subscriptionDetails?.nextInvoice).format('MMM Do, YYYY')}`;
}
function getActionForStatus(membership, subscriptionDetails, modalDispatch, history) {
  const activeExtension = membership?.extensions?.find(({status}) => status === 'active');
  const membershipStatus = activeExtension?.status || membership?.status;
  const proposedEndDate = moment(membership.endDate).isBefore(moment()) ? moment() : moment(membership.endDate);
  const openExtensionModal = () => modalDispatch({
    type: 'open',
    component: SelectExtensionOptionModal,
    enableClickClose: true,
    componentProps: {
      extensions: membership.groups.includes('NCLEXBootcamp') ? [
        {
          priceId: 'price_1Pihe3D2xOfNJKfxmdhn6YML',
          duration: 15,
          price: 29,
          expirationDate: moment(proposedEndDate).add(15, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1N8MR9D2xOfNJKfx1VGXW2wE',
          duration: 30,
          price: 49,
          expirationDate: moment(proposedEndDate).add(30, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PihheD2xOfNJKfxTqpUHg4M',
          duration: 60,
          price: 69,
          expirationDate: moment(proposedEndDate).add(60, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1Pihj0D2xOfNJKfx2PETQqcA',
          duration: 90,
          price: 89,
          expirationDate: moment(proposedEndDate).add(90, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PiiNuD2xOfNJKfxTMRAlUnK',
          duration: 180,
          price: 129,
          expirationDate: moment(proposedEndDate).add(180, 'days').format('MMMM Do, YYYY')
        },
      ] : membership.groups.includes('INBDEBootcamp') ? [
        {
          priceId: 'price_1Pife5D2xOfNJKfxs0WDRB49',
          duration: 15,
          price: 59,
          expirationDate: moment(proposedEndDate).add(15, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1OkByWD2xOfNJKfxL89fpy82',
          duration: 30,
          price: 119,
          expirationDate: moment(proposedEndDate).add(30, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PigzpD2xOfNJKfxwhJOsL6t',
          duration: 60,
          price: 209,
          expirationDate: moment(proposedEndDate).add(60, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1Pih1fD2xOfNJKfxamVFqLHs',
          duration: 90,
          price: 299,
          expirationDate: moment(proposedEndDate).add(90, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PihAQD2xOfNJKfxRX4J2a7k',
          duration: 180,
          price: 399,
          expirationDate: moment(proposedEndDate).add(180, 'days').format('MMMM Do, YYYY')
        },
      ] : membership.groups.includes('PlusPackAccess') ? [
        {
          priceId: 'price_1PMD5iD2xOfNJKfxE1OCYuHr',
          duration: 15,
          price: 99,
          expirationDate: moment(proposedEndDate).add(15, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1OoV3AD2xOfNJKfxI3T4ANXc',
          duration: 30,
          price: 179,
          expirationDate: moment(proposedEndDate).add(30, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PMD7pD2xOfNJKfxTq38j7Bm',
          duration: 60,
          price: 339,
          expirationDate: moment(proposedEndDate).add(60, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PMD9BD2xOfNJKfxlFAzrYgT',
          duration: 90,
          price: 499,
          expirationDate: moment(proposedEndDate).add(90, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PMDAOD2xOfNJKfxJyo00dYc',
          duration: 180,
          price: 849,
          expirationDate: moment(proposedEndDate).add(180, 'days').format('MMMM Do, YYYY')
        },
      ] : [
        {
          priceId: 'price_1PMCz7D2xOfNJKfxieqaF07K',
          duration: 15,
          price: 99,
          expirationDate: moment(proposedEndDate).add(15, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1OoV1fD2xOfNJKfxOkPcPdRK',
          duration: 30,
          price: 179,
          expirationDate: moment(proposedEndDate).add(30, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PMD0yD2xOfNJKfxxENLIIlJ',
          duration: 60,
          price: 339,
          expirationDate: moment(proposedEndDate).add(60, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PMD2CD2xOfNJKfxDMtZl5sY',
          duration: 90,
          price: 499,
          expirationDate: moment(proposedEndDate).add(90, 'days').format('MMMM Do, YYYY')
        },
        {
          priceId: 'price_1PMD3OD2xOfNJKfxvLxumYml',
          duration: 180,
          price: 849,
          expirationDate: moment(proposedEndDate).add(180, 'days').format('MMMM Do, YYYY')
        },
      ]
    }
  });
  switch (membershipStatus) {
    case 'awaitingPayment':
      return {
        actionText: 'Update Billing Details',
        action: async () => {
          const result = await invokeLambda('tbc-Stripe', {path: '/stripe/create-customer-portal', customer: subscriptionDetails.customerId, return_url: window.location.href});
          window.location.href = result.body.result.url;
        }
      };
    case 'cancelAtEndOfPeriod':
    case 'active':
      if (membership.type === 'subscription') {
        return {
          actionText: 'Manage',
          action: async () => {
            const result = await invokeLambda('tbc-Stripe', {path: '/stripe/create-customer-portal', customer: subscriptionDetails.customerId, return_url: window.location.href});
            window.location.href = result.body.result.url;
          }
        }
      } else {
        const bootcampSlug = getBootcampSlug(membership.groups);

        const slugMap = {
          'medschool': 'med-school',
          'dentalschool': 'dental-school',
          'step1': 'step-1'
        };

        const bootcampRoute = slugMap[bootcampSlug] || bootcampSlug;

        const actionConfig = ['med-school'].includes(bootcampRoute)
          ? {
            externalActionLink: 'https://bootcamp.com/med-school/upgrade'
          } : {
            actionLink: ['admin_created', bootcampRoute].includes(membership.productId)
              ? `/${bootcampRoute}/stripe-checkout?priceId=${extensionIdsByBootcamp[bootcampRoute]}&mode=payment`
              : `/${bootcampRoute}/stripe-checkout?priceId=${extensionPriceIdMap[membership.productId] || membership.productId}&mode=payment`
          };

        if (membership.groups.includes('DATBootcamp') || membership.groups.includes('INBDEBootcamp') || membership.groups.includes('NCLEXBootcamp')) {
          return {
            actionText: 'Extend',
            action: openExtensionModal
          }
        }

        return {
          actionText: 'Extend',
          ...actionConfig,
        };
      }
    case 'cancelled':
      return {};
    default:
      const bootcampSlug = getBootcampSlug(membership.groups);
      const slugMap = {
        'medschool': 'med-school',
        'dentalschool': 'dental-school',
        'step1': 'step-1'
      }
      const bootcampRoute = slugMap[bootcampSlug] || bootcampSlug;
      const endDate = membership.endDate || getExpirationDate(membership.startDate, membership.duration)
      if (moment().isBefore(moment(endDate).add(7, 'days'))) {
        if (membership.groups.includes('DATBootcamp') || membership.groups.includes('INBDEBootcamp') || membership.groups.includes('NCLEXBootcamp')) {
          return {
            actionText: 'Extend',
            action: openExtensionModal
          }
        }
        const actionConfig = membership.productId === 'admin_created' || ['med-school', 'nclex'].includes(bootcampRoute)
          ? {
            externalActionLink: (['anatomy', 'chemistry'].includes(bootcampSlug)
              ? `https://${slugMap[bootcampSlug] || bootcampSlug}.bootcamp.com/upgrade`
              : `https://bootcamp.com/${slugMap[bootcampSlug] || bootcampSlug}/upgrade`)
          } : {
            actionLink: ['inbde'].includes(bootcampRoute)
              ? `/${bootcampRoute}/stripe-checkout?priceId=${extensionIdsByBootcamp.inbde}&mode=payment`
              : `/${bootcampRoute}/stripe-checkout?priceId=${extensionPriceIdMap[membership.productId] || membership.productId}&mode=payment`
          }
          return {
            actionText: 'Extend',
            ...actionConfig,
          };
      }
      return {
        actionText: 'Upgrade',
        externalActionLink: ['anatomy', 'chemistry'].includes(bootcampSlug)
          ? `https://${slugMap[bootcampSlug] || bootcampSlug}.bootcamp.com/upgrade`
          : `https://bootcamp.com/${slugMap[bootcampSlug] || bootcampSlug}/upgrade`
      };
  }
}

const Card = ({membership, id, duration, productId, startDate, status, type, groups, extensions}) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const {modalDispatch} = useModalContext();
  const history = useHistory();

  const loading = type === 'subscription' && !subscriptionDetails;

  const membershipType = getMembershipType(groups, type, duration);
  const membershipGroup = groups.find(group => group.match('Bootcamp'));
  const {endDate} = findStartAndEndDates({items: [membership, ...extensions]}, membershipGroup);

  useEffect(() => {
    (async function() {
      if (type !== 'subscription') return;

      const details = await fetchBillingDetails(id);
      setSubscriptionDetails(details);

    })();
  }, [type]);
  const {actionText, action, actionLink, externalActionLink} = getActionForStatus({id, duration, productId, startDate, status, type, groups, extensions, endDate}, subscriptionDetails, modalDispatch, history);

  const wrapperProps = actionLink
    ? {to: actionLink}
    : externalActionLink
    ? {as: 'a', href: externalActionLink}
    : {as: 'div', onClick: !loading ? action : () => {}};

  return (
    <Container>
      <Wrapper {...wrapperProps}>
        <Body>
          <MembershipTitle>{getMembershipBootcamp(groups)}</MembershipTitle>
          <MembershipDetails>
            {`${membershipType} - ${status === 'inactive'
              ? `Membership paused with ${membership.duration} days remaining`
              : status === 'cancelled'
              ? 'Cancelled'
              : status === 'awaitingPayment'
                ? 'Awaiting Payment'
                : type !== 'subscription'
                  ? `Expires ${moment(endDate || getExpirationDate(startDate, duration)).format('MMMM Do YYYY, h:mm a')}`
                  : getNextInvoiceDate(subscriptionDetails, status)}`
            }
          </MembershipDetails>
        </Body>
        {actionText && (
          <Action color={'royal'}>
            {loading ? 'Loading...' : actionText}
          </Action>
        )}
      </Wrapper>
      <PaymentHistory
        parentLoading={loading}
        subscriptionDetails={subscriptionDetails}
        membership={{id, productId, extensions, type}}
        />
    </Container>
  );
};

Card.defaultProps = {};
Card.propTypes = {};

export default Card;
