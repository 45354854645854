import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {useHotkeys} from 'react-hotkeys-hook';

import {useUserDataContext} from '../../../contexts/UserData';


import {H5} from '../../Typography';
import Menu, {MenuIcon, MenuButton} from '../components/Menu/legacy';
import {Close} from '../components/Buttons';
import Breadcrumbs from '../components/Breadcrumbs';

import {FlexBox} from '../../Grid';
import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';

import {HomeIcon, StudentReviewsIcon} from '../../Branding/Classroom/Icons';
import {colors} from '@bootcamp/shared/src/styles/theme';

import {
  barHeight,
  barHeightMobile,
  Container,
  ContainerBackground,
  Content,
  Left,
  Center,
  Right,
  LoggedIn,
  LoggedOut,
  StyledLink,
  Loader,
} from '../components';

import {HomeButton} from '../../QuestionBank/Buttons';

const TitleWrapper = styled.span`
  display: flex;
  svg {
    width: 180px;

    ${({theme}) => theme.mediaQueries.tablet} {
      height: 24px;
    }

  }
`;
const Locked = styled.span`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  font-size: ${({theme}) => theme.font.size.smaller};
  line-height: 15px;
`;
const Arrow = styled(ArrowForwardOutline)`
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  color: ${({theme}) => theme.colors.neutralsPalette.white};
`;
const ListItem = styled(FlexBox)`
  padding: ${({theme}) => theme.layouts.spacing.s};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  align-items: center;
  border-radius: 100px;
  background: ${({active, theme}) => active ? theme.colors.neutralsPalette.extraLight : theme.colors.neutralsPalette.white};
  min-height: 50px;

  &:hover {
    background: ${({hoverColor, theme, locked}) => hoverColor || theme.colors.brandPalette.indigo.light};

    ${Arrow} {
      color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
const ItemLink = styled(H5)`
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
`;
const Current = styled.span`
  display: ${({active}) => active ? 'inline-block' : 'none'};
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  font-size: ${({theme}) => theme.font.size.smaller};
  line-height: 15px;
`;
const IconWrapper = styled.span`
  background: ${({color, theme}) => color || theme.colors.brandPalette.indigo.dark};
  border-radius: 25px;
  padding: 6px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};

  svg {
    width: 20px;
    height: 20px;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 10px;

    svg {
      width: 25px;
      height: 25px;
    }
  }
`;
const ItemNameWrapper = styled.div`
`;
const StyledHomeButton = styled(HomeButton)`
  margin: 0 12px 0;
  align-self: center;
  width: 40px;
  height: 40px;
  padding: 10px;
`;
const StyledRight = styled(Right)`
  flex: 1;
`;


function isRouteActive(history, route) {
  const pathname = history.location.pathname.split('/').slice(-1)[0];

  return pathname === route;
}

function renderMenuItems(onMenuItemClick, history, config, bootcamp) {
  const includeStudentReviewLink = bootcamp === 'anatomy';
  const listItemConfig = [
    {route: 'home', name: 'Home', icon: <HomeIcon/>, themePalette: {}},
    ...config,
    ...(includeStudentReviewLink ? [{href: 'https://blog.anatomybootcamp.com/student-reviews', name: 'Student Reviews', icon: <StudentReviewsIcon/>, themePalette: colors.brandPalette.purple}] : []),
  ];

  return listItemConfig.map(({route, href, themePalette, icon, name, locked, releaseDate}, index) => {
    const active = !href && isRouteActive(history, route);
    const elementProps = locked
      ? {as: 'div', onClick: () => {}}
      : href
      ? {as: 'a', href, target: '_blank', onClick: onMenuItemClick}
      : {as: Link, to: index === 0 ? `/${route}` : `/home/${route}`, onClick: onMenuItemClick}

    return (
      <ListItem key={`jump-menu-item-${route}`} hoverColor={themePalette.light} active={active}>
        <ItemLink
          {...elementProps}
          children={
            <Fragment>
              <IconWrapper
                color={themePalette.default}
                children={icon}
              />
              <ItemNameWrapper>
                <div>{name}</div>
                {locked
                  ? <Locked children={releaseDate}/>
                  : <Current active={active} children={'Current'}/>
                }
              </ItemNameWrapper>
              {!locked && <Arrow/>}
            </Fragment>
          }
          />
      </ListItem>
    )});
}

const NavBar = withRouter(({history, themePalette, config, withoutMobileBorder, titleComponent, lockAtDepth, hideMenu=false, withHomeButton=false}) => {
  const {cognitoUser, loading, isUpgraded, trialMembership} = useUserDataContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const showLogo = history.location.pathname.split('/').length < 3;
  const loggedIn = !!cognitoUser.username;

  useHotkeys('cmd+j', () => setMenuOpen(!menuOpen), [menuOpen]);

  return (
    <Container withoutMobileBorder={withoutMobileBorder}>
      <ContainerBackground color={themePalette.dark} />
      <Content>
        <Left>
          {!hideMenu &&
            <MenuButton
              type={'secondary'} size={'small'}
              children={menuOpen ? <Close /> : <MenuIcon />}
              onClick={() => loggedIn && setMenuOpen(!menuOpen)}
              disabled={!loggedIn}
            />
          }
          {!hideMenu &&
            <Menu
              loggedIn={loggedIn}
              isUpgraded={isUpgraded}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              barHeight={barHeight}
              barHeightMobile={barHeightMobile}
              renderMenuItems={renderMenuItems}
              themePalette={themePalette}
              config={config}
            />
          }
          {withHomeButton &&
            <StyledHomeButton/>
          }
          <Breadcrumbs history={history} lockAtDepth={lockAtDepth}/>
        </Left>
        {showLogo &&
          <Center>
            <StyledLink to={'/home'}>
              <TitleWrapper>
                {titleComponent}
              </TitleWrapper>
            </StyledLink>
          </Center>
        }
        {loading
          ? <Loader/>
          : <StyledRight showLogo={showLogo} loggedIn={loggedIn}>
            {loggedIn ? <LoggedIn showUpgradeButton={(!isUpgraded || !!trialMembership) && !window.location.pathname.includes('checkout')}/> : <LoggedOut/>}
            </StyledRight>
        }
      </Content>
    </Container>
  )
});

export default NavBar;
