import React, {useState} from 'react';
import styled from 'styled-components';

import {useHistory} from 'react-router-dom';

import {H2} from '@bootcamp/web/src/components/Typography';
import {Heading, Blurb, BlurbText, Table, Cell, TableRow} from '@bootcamp/web/src/bootcamps/pages/CustomTestReview/components/shared';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {TextTooltip} from '../CreateTest/components/shared';

import {useUserDataContext} from '../../../contexts/UserData';
import {useModalContext} from '../../../contexts/Modal';

import {slugify} from '@bootcamp/shared/src/util';

import {Info} from '@styled-icons/fluentui-system-filled/Info';


const Container = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;
const StyledBlurb = styled(Blurb)`
  ${BlurbText} {
    margin: 0;
  }
`;
const StyledLabel = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  color: ${({theme, alert}) => alert ? theme.colors.interfacePalette.red.default : theme.overlays.opacity.light._300};
  margin-right: ${({theme, alert}) => alert ? 0 : theme.layouts.spacing.m};
`;
const ScoreWrapper = styled.span`
  display: flex;
  align-items: center;
`;
const InfoIconWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 10px;
  margin: 0px 4px;

  ${InfoIcon} {
    position: absolute;
    width: 16px;
    height: 16px;
    top: -2px;
    left: -3px;
    bottom: 0;
    right: 0;
  }
`;
const StyledTable = styled(Table)`
  border-radius: 8px;
  overflow: hidden;
  background: white;

  ${TableRow} {
    svg {
      width: 16px;
    }

    &:first-child {
      ${Cell} {
        span {
          color: ${({theme}) => theme.colors.neutralsPalette.grey} !important;
        }
      }
    }
    &:nth-child(2){
      background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
      font-weight: 600;
    }
  }

`;

export const AlertIcon = () => (
  <InfoIconWrapper>
    <InfoIcon alert/>
  </InfoIconWrapper>
);

const Subject = ({performanceData, educator, config, hideHeader=false, defaultExpanded=false, headerText, headerTitle}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const {bootcamp} = useUserDataContext();
  const {modalDispatch} = useModalContext();
  const history = useHistory();

  const {byClassroom: performance, overall} = performanceData;

  function handleRowClick(subject, topic) {
    if (bootcamp === 'nclex') {
      modalDispatch({
        type: 'open',
        component: CreateTest,
        componentProps: {defaults: {subject, system: topic}},
        enableClickClose: true,
      })
    } else if (bootcamp === 'med-school') {
      history.push(`/${bootcamp}/${slugify(subject)}/board-banks/${slugify(topic)}`);
    } else if (bootcamp === 'inbde' && subject === 'Case Sets') {
      history.push(`/${bootcamp}/${slugify(subject)}/qbanks/case-set-1`);
    } else if (bootcamp === 'inbde' && subject === 'Simulation Exam') {
      history.push(`/${bootcamp}/${slugify(subject)}/qbanks`);
    } else {
      history.push(`/${bootcamp}/${slugify(subject)}/qbanks/${slugify(topic)}`);
    }
  }

  const rows = performance
    .filter(({name, mastery, performance, byTopic, totalQuestions}) => !!name && !!Object.keys(byTopic || {}).length)
    .map(({name, mastery, performance, byTopic, totalQuestions, route}) => ({
      parent: [
        {content: name},
        {content: ''},
        {content: `${performance?.correct} / ${performance?.attempts} (${Math.round((performance?.correct / performance?.attempts) * 100) || 0}%)`},
      ],
      nested: Object
        .values(byTopic || {})
        .filter(({name: topicName, performance}) => !!topicName && !!Object.keys(performance).length)
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map(({name: topicName, performance}) => {
          const scorePercentage = Math.round((performance?.correct / performance?.attempts) * 100) || 0;
          const topicExists = config.find(({name: subject}) => name === subject)?.contentTypes?.find(({name}) => ['Board Style Questions'].includes(name))?.content?.[0]?.content?.find(({name}) => name === topicName)
          if (bootcamp === 'med-school' && !topicExists) return {
            cells: [
              {content: ''},
              {content: name, disabled: true},
              {content: topicName, disabled: true},
              {content: `${performance?.correct} / ${performance.attempts} (${scorePercentage}%) `},
            ]
          }
          return {
            onClick: () => handleRowClick(name, topicName),
            cells: [
              {content: ''},
              {content: name, disabled: true},
              {content: (<span>{topicName} {scorePercentage < 50 && <AlertIcon/>}</span>)},
              {content: `${performance?.correct} / ${performance.attempts} (${scorePercentage}%) `},
            ]
          };
        })
    }));

  const totalRow = {cells: [
    {content: ''},
    {content: 'Total'},
    {content: ''},
    {content: `${overall.correct} / ${overall.attempts} (${Math.round((overall.correct / overall.attempts) * 100) || 0}%)`},
    {content: ''},
  ]}

  return (
    <Container>
        <Heading>
          {headerTitle && <StyledLabel>{headerTitle}</StyledLabel>}
          {!headerTitle && <StyledLabel>{['med-school']?.includes(bootcamp) ? 'Step 1 - ' : ''}Subject Breakdown</StyledLabel>}
          <StyledBlurb
            icon={educator}
            text={
              headerText ||
              <span>
                Increase your score in each subject by focusing on topics marked with a red  <AlertIcon/> - that's where you can improve the most!
              </span>
            }
            lightMode
          />
        </Heading>
      <StyledTable
        expandAll={expanded}
        rows={[
          {cells: [{content: ''},
          {content: 'Subject'},
          {content: 'Topic'},
          {content: (
            <ScoreWrapper>
              Score
              <TextTooltip id={'performance-score-tooltip'}>
                <strong>Score</strong> tracks every question attempt including reattempts.
              </TextTooltip>
            </ScoreWrapper>)},
          {content: ''}
          ]},
          totalRow,
          ...rows
        ]}
      />
    </Container>
  );
};

Subject.defaultProps = {};
Subject.propTypes = {};

export default Subject;