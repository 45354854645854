import React from 'react';

import {
  Container,
  Wrapper,
  Heading,
  HeadingName,
  HeadingSchool,
  HeadingResultContainer,
  HeadingResultScore,
  HeadingResultPercentile,
  Comment,
  StudentImageContainer,
  StudentImage,
  StudentImageIconContainer,
  StudentImageIcon,
  Rating,
  UpdatedStudentImage,
  Name,
  SubHeader,
} from './shared';

import tehaum from '../assets/tehaum.png';
import sara from '../assets/sara.png';
import julie from '../assets/julie.png';

const outcome1 = (
  <Container>
    <Rating/>
    <Comment>
      "Bootcamp is the best resource out there in my honest opinion. <strong>I got some similar questions on my actual board that I saw on Bootcamp.</strong>"
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={tehaum} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Tehaum Saood
        </Name>
        <HeadingResultContainer>
          <SubHeader>Pass - 1st Attempt</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome2 = (
  <Container>
    <Rating/>
    <Comment>
      "There were <strong>less than 5 questions on the whole exam that I felt like I hadn't seen the answers on Bootcamp,</strong> I really felt as prepared as I could have been."
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={sara} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Sara Henry
        </Name>
        <HeadingResultContainer>
          <SubHeader>Pass - 1st Attempt</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

const outcome3 = (
  <Container>
    <Rating/>
    <Comment>
      "I can’t thank Bootcamp and Dr. Ryan enough for helping me get through the study process. <strong>There were even some questions on my exam that were similar to the ones I had done in Bootcamp.</strong>"
    </Comment>
    <Wrapper>
      <StudentImageContainer>
        <UpdatedStudentImage src={julie} />
      </StudentImageContainer>
      <Heading>
        <Name>
          Julie Suarez
        </Name>
        <HeadingResultContainer>
          <SubHeader>Pass - 1st Attempt</SubHeader>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
  </Container>
);

export const inbdeOutcomes = [
  outcome1,
  outcome2,
  outcome3,
];