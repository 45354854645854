import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {Body3} from '../../../components/Typography';

import Grid from '../../components/Grid';
import ClassroomCard from '../../components/Cards/ClassroomBookmark';
import ClassroomCardDefault from '../../components/Cards/Classroom';
import {getAllQuestionMasteryIdsTagsByUserId} from '@bootcamp/shared/src/requests';
import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import BookmarkQuestionBank from './QuestionBank';
import Container from '../PageContainer';

import Overview from '../../components/Cards/Overview';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import PageTabs from '../../components/PageTabs';
import {colors} from '@bootcamp/shared/src/styles/theme';
import {Helmet} from 'react-helmet';

import {Bookmark} from '@styled-icons/heroicons-outline/Bookmark';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const StyledClassroomCard = styled(ClassroomCard)`
  flex: 1;
  margin-right: ${({theme}) => theme.layouts.spacing.xl};
  color: black;

  &:last-child {
    margin-right: 0;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    margin-right: 0px;
    margin-bottom: 0 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;

const StyledOverview = styled(Overview)`
  margin-bottom: 0;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;
  max-width: 750px;
  /* copy / pasted from the Hero typography element for easy testing */

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0px;
  }
`;

const StyledBookmark = styled(Bookmark)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;

const BookmarkLabel = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;

function renderCards(config, bootcamp, bookmarkCounts) {
  const gridLayout = bootcamp === 'inbde';

  return gridLayout
    ? (
      <Grid
        items={config.classrooms}
        itemsPerRow={3}
        breakpoints={{
          1440: 2
        }}
        renderItem={({icon, themePalette, name, route, tagId, locked, questionCount, educators}) => (
          <StyledClassroomCard
            as={ClassroomCardDefault}
            key={tagId}
            icon={icon}
            themePalette={themePalette}
            name={name}
            route={`/${bootcamp}/bookmarks/${route}`}
            tagId={tagId}
            locked={!bookmarkCounts[name]}
            showProgress={false}
            withHeaderPadding={false}
            detailsBorder={false}
            whiteMode
            label={<BookmarkLabel><strong>{bookmarkCounts[name]}</strong> questions bookmarked</BookmarkLabel>}
          />
      )}
    />
    ) : (
      config.classrooms.map(({icon, themePalette, name, route, tagId, locked, questionCount}) => (
        <StyledClassroomCard
          key={tagId}
          icon={<StyledBookmark />}
          themePalette={themePalette}
          name={name}
          route={`/${bootcamp}/bookmarks/${route}`}
          locked={!bookmarkCounts[name]}
          questionCount={bookmarkCounts[name]}
          showProgress={false}
          lightMode
        />
      ))
    )
}

const Bookmarks = ({history, match}) => {
  const [userData, setUserData] = useState([]);
  const {DEFAULT_USER_ID} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  const bootcamp = match?.params?.bootcamp;
  const subjects = config.classrooms.map(({name}) => name);

  const grossAnatomyTagIds = config.classrooms.find(({name}) => name === 'Gross Anatomy')?.tagIds;

  const bookmarkCounts = userData.filter(({bookmarked}) => bookmarked).reduce((acc, {bookmarked, question}) => {
    const {subject: defaultSubject, id} = (question?.tags?.items?.find(({tag}) => !!tag.subject) || {tag: {}})?.tag || {};

    const subject = grossAnatomyTagIds?.some(tagId => tagId === id) ? 'Gross Anatomy' : defaultSubject;

    return {
      ...acc,
      [subject]: acc[subject] + 1
    }
  }, subjects.reduce((acc, subject) => ({...acc, [subject]: 0}), {}));


  useEffect(() => {
    async function fetchUserData () {
      if (!DEFAULT_USER_ID) return;
      const filter = {bookmarked: {eq: true}};
      try {
        const {data: {QuestionMasteryByWpUserId: {items: progressData}}} = await getAllQuestionMasteryIdsTagsByUserId(DEFAULT_USER_ID, filter);
        setUserData(progressData);
      } catch (e) {
        console.log(e);
      }
    }
    fetchUserData();
  }, [DEFAULT_USER_ID]);

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | Bookmarked Questions</title>
        <meta name="description" content={`${config.meta.siteTitle} Bookmarked Questions`}></meta>
      </Helmet>
      <StyledBreadcrumbs history={history} lockAtDepth={2}/>
      <StyledOverview
        subTitle={'Bookmarks'}
        body={'Questions you bookmark will be saved here. Click on a subject below to start studying.'}
        actions={[]}
        showProgress={false}
        educators={[]}
      />
      <PageTabs
        tabs={[
          {
            name: 'Study By Subject',
            route: 'bookmarks'
          }
        ]}
        themePalette={colors.brandPalette.royal}
      />
      {renderCards(config, bootcamp, bookmarkCounts)}
    </Container>
  );
};

Bookmarks.defaultProps = {};
Bookmarks.propTypes = {};

export {BookmarkQuestionBank};
export default Bookmarks;
