import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ChapterCard, {Left, Wrapper} from '../Cards/Chapter';
import Callout from '../Cards/Callout';
import {PageSection} from '../ClassroomContentWrapper';
import {StyledBeginPracticeButton, StyledUpgradeButton, StyledResetButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';

import {useUserDataContext} from '../../../contexts/UserData';

import styled from 'styled-components';

const Container = styled.div`
`;
const StyledChapterCard = styled(ChapterCard)`
  background: white;
  border: none;
  overflow: inherit;
  align-items: center;

  ${Wrapper} {
    align-items: center;
  }

  ${Left} {
    ${({theme}) => theme.mediaQueries.mobileXl} {
      align-self: flex-start;
    }
  }
`;

const PracticalExams = ({content, themePalette, data, calloutText, allow}) => {
  const {userModel, isUpgraded} = useUserDataContext();
  const [resetInitialized, setResetInitialized] = useState({});
  const activeMembershipGroups = userModel?.memberships?.items?.reduce((acc, membership) => (
    membership.status === 'active' ? [...acc, ...membership.groups] : acc
  ), []) || [];

  const practicals = content[0].content;
  const {counts} = data;
  const hasAccess = !allow || allow?.some(group => activeMembershipGroups.includes(group));

  return (
    <Container>
      <PageSection title={''}>
        {calloutText && <Callout children={calloutText}/>}
        {practicals.map((item, index) => (
          <StyledChapterCard
            route={`${item.route}`}
            name={item.name}
            id={item.id}
            comingSoon={item.comingSoon}
            index={item.index || index + 1}
            questionCount={counts[item.id]}
            showProgress={false}
            themePalette={themePalette}
            defaultLocked={(!isUpgraded && index > 0) || !hasAccess}
            actions={!hasAccess ? <StyledUpgradeButton /> : [
              <StyledResetButton themePalette={themePalette} testId={item.id} hideOnMobile={!resetInitialized?.[item.id]} setInitialized={setResetInitialized}/>,
              (!isUpgraded && index > 0) &&
              <StyledUpgradeButton/>,
              (isUpgraded || index === 0) &&
              <StyledBeginPracticeButton hideOnMobile={!resetInitialized?.[item.id]} themePalette={themePalette}/>
            ]}
          />
        ))}
      </PageSection>
    </Container>
  );
};

PracticalExams.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({
    counts: PropTypes.shape({}),
    progress: PropTypes.shape({})
  }),
};
PracticalExams.defaultProps = {
  content: [],
  data: {counts: {}}
};

export default PracticalExams;
