import React, {useState, useEffect} from 'react';

import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import {TestStateContextProvider} from './context';

import Header from './components/Header';
import Mode from './components/Mode';
import Type from './components/Type';
import Tags from './components/Tags';
import Questions from './components/Questions';
import Count from './components/Count';
import Submit from './components/Submit';

import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({theme}) => theme.layouts.spacing.xl};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 72px 0px 0px;
    border-radius: 8px 8px 0px 0px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  width: 100%;
  height: 100%;
  max-width: 780px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 20px;
  /* transition: all 120ms cubic-bezier(0.175, 0.885, 0.320, 1.275); easeOutBack */
  /* transform: ${({mounted}) => mounted ? 'scale(1)' : 'scale(.95)'}; */
  ${({theme}) => theme.mediaQueries.tablet} {
    border-radius: 8px 8px 0px 0px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 ${({theme}) => theme.layouts.spacing.l} ${({theme}) => theme.layouts.spacing.l};
  font-family: proxima-nova;
  font-size: 16px;
  font-weight: 400;
`;

const CreateTest = () => {
  const {bootcamp} = useUserDataContext();
  const [mounted, setMounted] = useState(false);

  function handleWrapperClick(event) {
    event.stopPropagation();
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Container mounted={mounted}>
      <Wrapper mounted={mounted} onClick={handleWrapperClick}>
        <Header/>
        <ContentWrapper>
          <Mode/>
          {['med-school'].includes(bootcamp) && <Type bootcamp={bootcamp}/>}
          <Tags/>
          <Questions/>
          <Count/>
        </ContentWrapper>
        <Submit/>
      </Wrapper>
    </Container>
  );
};

const CreateTestWrapper = ({defaults}) => {
  return (
    <TestStateContextProvider defaults={defaults}>
      <CreateTest/>
    </TestStateContextProvider>
  );
}

export default CreateTestWrapper;
