import React from 'react';
import styled from 'styled-components';
import Auth from '@aws-amplify/auth';
import {Link, withRouter} from 'react-router-dom';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {FormSubmit} from '../';
import {Container, Header, Body, FieldGroup, LinkButton} from '../../';

const TermsOfUse = styled.div`
  margin-bottom: 24px;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  padding: 0px ${({theme}) => theme.layouts.spacing.m};
  text-align: center;
  a {
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
    &:visited {
      color: ${({theme}) => theme.colors.neutralsPalette.grey};
    }
  }
`;

const Join = ({location, history, setRedirect, setCreatingAccount}) => {
  return (
    <FormWrapper onSubmit={async (values, formMethods) => {
      const {email, password} = values;
      if (email.endsWith('bootcamp.com')) {
        return formMethods.setError('email', 'InvalidEmail', 'Invalid email address, please use your personal email address.');
      }
      try {
        setCreatingAccount(true);

        await Auth.signUp({
          username: email.toLowerCase(),
          password,
          attributes: {
            email
          }
        });

        // auto log user in (account verification is happening in preSignUp lambda trigger)
        await Auth.signIn(email.toLowerCase(), password);


      } catch (e) {
        setCreatingAccount(false);
        if (e.code === "UsernameExistsException") {
          return formMethods.setError('email', e.code, 'User already exists, try logging in');
        }
        if (e.code === "UserLambdaValidationException") {
          return formMethods.setError('email', e.code, e.message?.replace('PreSignUp failed with error ', ''));
        }
      }
    }}>
      <Container>
        <Body>
          <Header>Create an account and start studying</Header>
          <FieldGroup children={<FieldRenderer config={JOIN_FORM_CONFIG} />}/>
          <LinkButton
            children={<Link to={{
              pathname: `/signin`,
              search: location.search
            }}>I already have an account</Link>}
          />
        </Body>
        <FormSubmit setRedirect={setRedirect}>
          Create My Free Account
        </FormSubmit>
        <TermsOfUse>
          By clicking above, I agree to the <a href={"https://bootcamp.com/terms"} target={"_blank"} rel={"noreferrer"}>Terms of Use</a>.
        </TermsOfUse>
      </Container>
    </FormWrapper>
  )
}

export default withRouter(Join);

const JOIN_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'Email',
    name: 'email',
    additionalInfo: '',
    inputProps: {
      autoFocus: true,
      type: 'email',
      autoComplete: 'username'
    },
    validation: () => ({
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Email must be valid'
      }
    })
  },
  {
    type: 'Password',
    label: 'Password',
    name: 'password',
    additionalInfo: '8+ characters',
    inputProps: {
      autoComplete: 'new-password'
    },
    validation: () => ({
      required: true,
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters'
      }
    })
  }
]
