import React from 'react';
import styled from 'styled-components';

import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {PerformanceProvider, getFAQs, usePerformance} from '@bootcamp/web/src/bootcamps/pages/Performance';
import {H2} from '@bootcamp/web/src/components/Typography';

import Subject, {AlertIcon} from '@bootcamp/web/src/bootcamps/pages/Performance/Subject';
import {Card} from '@bootcamp/web/src/bootcamps/pages/Performance/Overall';

const Container = styled.div`
`;

const StyledCard = styled(Card)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;

const Header = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;


const SubjectPerformance = () => {
  const {bootcamp} = useUserDataContext();
  const {performance, displayClassrooms, loadingPerformance,} = usePerformance();
  const subject = performance?.byClassroom?.[0] || {};

  return (
    <Container>
      <Header>Subject Performance</Header>
      <StyledCard
        {...subject}
        loading={loadingPerformance}
        disableClick
      />
      <Subject
        config={displayClassrooms}
        performanceData={performance}
        loading={loadingPerformance}
        defaultExpanded
        headerTitle={'Topic Performance'}
        headerText={
          <span>
            Topics marked with a red  <AlertIcon/> should receive additional attention.
          </span>
        }
      />
      <Header>FAQs</Header>
      {getFAQs(bootcamp)}
    </Container>
  );
};

const ContextWrapper = (props) => {
  return (
    <PerformanceProvider classroomFilter={[props.subject]}>
      <SubjectPerformance/>
    </PerformanceProvider>
  );
};

export default ContextWrapper;