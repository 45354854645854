/* eslint-disable */
module.exports = function($) {
  var BlankSquare, BlankTriangle, BlankTriangleInv1, BlankTriangleInv2, BlankTriangleInv3, Choice, Coffin, DIMENSION, DIMENSION0, DblMarkSquare, DiagonalRect, DieFace, El, ElInv, Face, Fold, FourSquare, HexSection, HexSection10, HexSection2, HexSection3, HexSection4, HexSection5, HexSection6, HexSection7, HexSection8, HexSection9, HorizRect, HorizRectFour, HorizRectThree, HorseShoe, HorseShoeSideways, InnerRect, InnerTriangle, InnerTriangleInv, Normal, OFFSET, OPTIONS, Pattern, Pentagon, PentagonInv, Point, ProtoRect, Queue, SECONDS, SQ3, SoftL, SoftLInv, SoftLInv2, SoftLSide, Spin, Square, SquareDiamond, SquareFor12, StripedRect, StripedSquare, Structure1, Structure10, Structure11, Structure12, Structure13, Structure14, Structure15, Structure16, Structure17, Structure18, Structure19, Structure2, Structure20, Structure21, Structure22, Structure23, Structure24, Structure25, Structure3, Structure4, Structure5, Structure6, Structure7, Structure8, Structure9, TippedHexSection, TippedHexSectionInv, TippedRect, Triangle, TriangleSideways, TwoPentagon, TwoSquare, VertPentagon, VertPentagonInv, VertRect, VertRectFour, VertRectThree, ZInverted, ZShape, addControls, animDir, animOn, animPause, animloop, answerGrades, answerValues, clearCanvas, clearExplication, clearGrades, clearRadios, compareFaces, currentPage, erasePage, folder, gameReset, getBounds, getCtx, getScaling, getStructure, newPage, pagePosition, pauseAction, povs, previousPage, radioChangeEvent, randInt, render, restoreCurrentPage, revealExplication, reverseAction, sanitizeCanvases, serializedPages, setGrades, setPageNumber, shuffle, startTimer, tallyScoreboard, testing, timeChangeEvent, timeLeft, timeOut, timeReset, timer, timerOn,
    __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  testing = false;

  DIMENSION0 = 260;

  DIMENSION = 155;

  OFFSET = 8;

  OPTIONS = 4;

  SECONDS = 60;

  SQ3 = Math.sqrt(3);

  animOn = false;

  animDir = true;

  animPause = {};

  timerOn = false;

  timeLeft = SECONDS;

  timer = {};

  serializedPages = [];

  pagePosition = 0;

  currentPage = {};

  answerGrades = ["#grade-A", "#grade-B", "#grade-C", "#grade-D"];

  answerValues = ["A", "B", "C", "D"];

  povs = [[-80, -70, -200], [80, -70, -200], [80, 70, -200], [-80, 70, -200]];

  sanitizeCanvases = function() {
    var first;
    $('canvas').each(function() {
      $(this)[0].width = DIMENSION;
      return $(this)[0].height = DIMENSION;
    });
    first = $('canvas')[0];
    first.width = DIMENSION0;
    return first.height = DIMENSION0;
  };

  clearCanvas = function(n) {
    var ctx, dim;
    dim = DIMENSION;
    if (n === 0) {
      dim = DIMENSION0;
    }
    ctx = getCtx(n);
    return ctx.clearRect(0, 0, dim, dim);
  };

  getCtx = function(n) {
    var ctx;
    ctx = $('canvas')[n].getContext("2d");
    return ctx;
  };

  getScaling = function(bounds, cDim, cMargin) {
    var biggest, height, scale, size, width, xOff, yOff;
    size = cDim - cMargin * 2;
    width = bounds.maxX - bounds.minX;
    height = bounds.maxY - bounds.minY;
    biggest = width > height ? width : height;
    scale = size / biggest;
    xOff = ((cDim - width * scale) / 2) - (bounds.minX * scale);
    yOff = ((cDim - height * scale) / 2) - (bounds.minY * scale);
    return {
      scale: scale,
      xOff: xOff,
      yOff: yOff
    };
  };

  compareFaces = function(array1, array2) {
    var face1, face2, unique, _i, _j, _len, _len1;
    if (array1.length !== array2.length) {
      return false;
    }
    for (_i = 0, _len = array1.length; _i < _len; _i++) {
      face1 = array1[_i];
      unique = true;
      for (_j = 0, _len1 = array2.length; _j < _len1; _j++) {
        face2 = array2[_j];
        if (face1.compare(face2)) {
          unique = false;
        }
      }
      if (unique) {
        return false;
      }
    }
    return true;
  };

  randInt = function(scale) {
    return Math.floor(Math.random() * scale);
  };

  animloop = function() {
    requestAnimationFrame(animloop);
    if (animOn) {
      return render();
    }
  };

  window.requestAnimationFrame || (window.requestAnimationFrame = window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function(callback, element) {
    return window.setTimeout(function() {
      return callback(+new Date());
    }, 1000 / 60);
  });

  render = function() {};

  getBounds = function(faces, pov) {
    var face, maxX, maxY, minX, minY, point, scr, _i, _j, _len, _len1, _ref;
    minX = 1000;
    minY = 1000;
    maxX = -1000;
    maxY = -1000;
    for (_i = 0, _len = faces.length; _i < _len; _i++) {
      face = faces[_i];
      _ref = face.ar;
      for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
        point = _ref[_j];
        scr = point.screenPoint(pov);
        if (scr[0] < minX) {
          minX = scr[0];
        }
        if (scr[0] > maxX) {
          maxX = scr[0];
        }
        if (scr[1] < minY) {
          minY = scr[1];
        }
        if (scr[1] > maxY) {
          maxY = scr[1];
        }
      }
    }
    return {
      minX: minX,
      maxX: maxX,
      minY: minY,
      maxY: maxY
    };
  };

  folder = function(mode, faces, dv, axis) {
    var ax, rad, result;
    rad = Math.PI / dv;
    ax = (function() {
      if (axis.length === 1) {
        switch (mode) {
          case 0:
            return [[axis[0], 0, 0], [axis[0], 10, 0]];
          case 1:
            return [[0, axis[0], 0], [10, axis[0], 0]];
        }
      } else {
        return [[axis[0], axis[1], 0], [axis[0], axis[1], 10]];
      }
    })();
    result = new Fold(faces, rad, ax);
    return result;
  };

  shuffle = function(a) {
    var i, j, t;
    i = a.length;
    while (--i > 0) {
      j = ~~(Math.random() * (i + 1));
      t = a[j];
      a[j] = a[i];
      a[i] = t;
    }
    return a;
  };

  Queue = (function() {
    function Queue(args) {
      this.ar = args;
    }

    return Queue;

  })();

  Point = (function(_super) {
    __extends(Point, _super);

    function Point(args) {
      this.changeAngle = __bind(this.changeAngle, this);
      Point.__super__.constructor.call(this, args);
      if (this.ar.length < 3) {
        this.ar.push(0);
      }
    }

    Point.prototype.clone = function() {
      return new Point([this.ar[0], this.ar[1], this.ar[2]]);
    };

    Point.prototype.compare = function(point) {
      var cushion, diff, i, _i;
      cushion = 0.001;
      for (i = _i = 0; _i <= 2; i = ++_i) {
        diff = this.ar[i] - point.ar[i];
        diff = Math.abs(diff);
        if (diff > cushion) {
          return false;
        }
      }
      return true;
    };

    Point.prototype.line = function(ctx, pov, scaling, mode) {
      var scr, x, y;
      scr = this.screenPoint(pov);
      x = scaling.xOff + scr[0] * scaling.scale;
      y = scaling.yOff + scr[1] * scaling.scale;
      if (mode) {
        return ctx.lineTo(x, y);
      } else {
        return ctx.moveTo(x, y);
      }
    };

    Point.prototype.screenPoint = function(pov) {
      var delta, newX, newY;
      delta = this.ar[2] / (this.ar[2] - pov[2]);
      newX = this.ar[0] + (pov[0] - this.ar[0]) * delta;
      newY = this.ar[1] + (pov[1] - this.ar[1]) * delta;
      return [newX, newY];
    };

    Point.prototype.translate = function(x, y, z) {
      this.ar[0] += x;
      this.ar[1] += y;
      return this.ar[2] += z;
    };

    Point.prototype.describe = function() {
      return $('#log').append('<h6>' + this.ar[0] + " " + this.ar[1] + " " + this.ar[2] + '</h6>');
    };

    Point.prototype.sanitize = function() {
      var i, _i, _len, _ref, _results;
      _ref = [0, 1, 2];
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        i = _ref[_i];
        _results.push(this.ar[i] = Math.round(this.ar[i] * 1000) / 1000);
      }
      return _results;
    };

    Point.prototype.changeAngle = function(theta, a, b) {
      var newa, newb;
      newa = this.ar[a] * Math.cos(theta) - this.ar[b] * Math.sin(theta);
      newb = this.ar[b] * Math.cos(theta) + this.ar[a] * Math.sin(theta);
      this.ar[a] = newa;
      return this.ar[b] = newb;
    };

    Point.prototype.getAngle = function(a, b) {
      return Math.atan2(this.ar[b], this.ar[a]);
    };

    Point.prototype.spin = function(axis, rad) {
      var angles, axle, cusion, deltas, i, _i, _j, _k, _results;
      cusion = 0.00001;
      deltas = [];
      angles = [0, 0];
      for (i = _i = 0; _i <= 2; i = ++_i) {
        deltas[i] = axis[1][i] - axis[0][i];
      }
      axle = new Point(deltas);
      for (i = _j = 0; _j <= 2; i = ++_j) {
        this.ar[i] -= axis[0][i];
      }
      if (Math.abs(axle.ar[1]) > cusion) {
        angles[0] = 0 - axle.getAngle(0, 1);
        axle.changeAngle(angles[0], 0, 1);
        this.changeAngle(angles[0], 0, 1);
      }
      if (Math.abs(axle.ar[0]) > cusion) {
        angles[1] = Math.PI / 2 - axle.getAngle(0, 2);
        this.changeAngle(angles[1], 0, 2);
      }
      this.changeAngle(rad, 0, 1);
      if (Math.abs(angles[1]) > cusion) {
        this.changeAngle(-angles[1], 0, 2);
      }
      if (Math.abs(angles[0]) > cusion) {
        this.changeAngle(-angles[0], 0, 1);
      }
      _results = [];
      for (i = _k = 0; _k <= 2; i = ++_k) {
        _results.push(this.ar[i] += axis[0][i]);
      }
      return _results;
    };

    Point.prototype.distance = function(pov) {
      var a, b, c;
      a = this.ar[0] - pov[0];
      b = this.ar[1] - pov[1];
      c = this.ar[2] - pov[2];
      return a * a + b * b + c * c;
    };

    return Point;

  })(Queue);

  Normal = (function(_super) {
    __extends(Normal, _super);

    function Normal() {
      return Normal.__super__.constructor.apply(this, arguments);
    }

    Normal.prototype.facing = function(pov) {
      return this.ar[0].distance(pov) < this.ar[1].distance(pov);
    };

    return Normal;

  })(Queue);

  Fold = (function() {
    function Fold(faces, degrees, axis) {
      this.faces = faces;
      this.degrees = degrees;
      this.axis = axis;
    }

    return Fold;

  })();

  Spin = (function() {
    function Spin(axis, rad) {
      this.axis = axis;
      this.rad = rad;
    }

    return Spin;

  })();

  Choice = (function(_super) {
    __extends(Choice, _super);

    function Choice(args) {
      Choice.__super__.constructor.call(this, args);
      this.pov = [];
    }

    return Choice;

  })(Queue);

  Face = (function(_super) {
    __extends(Face, _super);

    function Face(args) {
      var extension;
      Face.__super__.constructor.call(this, args);
      if (args.length > 0) {
        this.marks = [];
        extension = new Point([this.ar[0].ar[0], this.ar[0].ar[1], this.ar[0].ar[2] + 1]);
        this.normal = new Normal([this.ar[0], extension]);
        this.explication = false;
        this.type = 0;
      }
    }

    Face.prototype.clone = function() {
      var cln;
      cln = new Face([]);
      this.copyTo(cln);
      return cln;
    };

    Face.prototype.copyTo = function(destination) {
      var face, mks, p, points, _i, _j, _len, _len1, _ref, _ref1;
      points = [];
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];
        points.push(p.clone());
      }
      mks = [];
      _ref1 = this.marks;
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        face = _ref1[_j];
        mks.push(face.clone());
      }
      destination.ar = points;
      destination.marks = mks;
      destination.normal = new Normal([destination.ar[0], this.normal.ar[1].clone()]);
      destination.explication = this.explication;
      return destination.type = this.type;
    };

    Face.prototype.compare = function(face) {
      var face1, face2, point1, point2, unique, _i, _j, _k, _l, _len, _len1, _len2, _len3, _ref, _ref1, _ref2, _ref3;
      if (this.ar.length !== face.ar.length) {
        return false;
      }
      if (this.marks.length !== face.marks.length) {
        return false;
      }
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point1 = _ref[_i];
        unique = true;
        _ref1 = face.ar;
        for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
          point2 = _ref1[_j];
          if (point1.compare(point2)) {
            unique = false;
          }
        }
        if (unique) {
          return false;
          return true;
        }
      }
      _ref2 = this.marks;
      for (_k = 0, _len2 = _ref2.length; _k < _len2; _k++) {
        face1 = _ref2[_k];
        unique = true;
        _ref3 = face.marks;
        for (_l = 0, _len3 = _ref3.length; _l < _len3; _l++) {
          face2 = _ref3[_l];
          if (face1.compare(face2)) {
            unique = false;
          }
        }
        if (unique) {
          return false;
        }
      }
      return true;
    };

    Face.prototype.fill = function(n, pov, scaling, style) {
      var ctx, point, _i, _len, _ref;
      ctx = getCtx(n);
      ctx.fillStyle = style;
      ctx.beginPath();
      this.ar[0].line(ctx, pov, scaling, false);
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        if (point !== this.ar[0]) {
          point.line(ctx, pov, scaling, true);
        }
      }
      ctx.closePath();
      ctx.fill();
      return ctx.stroke();
    };

    Face.prototype.printBack = function(n, pov, scaling) {
      if (!this.normal.facing(pov)) {
        return this.fill(n, pov, scaling, "gray");
      }
    };

    Face.prototype.printFront = function(n, pov, scaling) {
      var mark, _i, _len, _ref, _results;
      if (this.normal.facing(pov)) {
        this.fill(n, pov, scaling, "white");
        _ref = this.marks;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          mark = _ref[_i];
          _results.push(mark.fill(n, pov, scaling, "gray"));
        }
        return _results;
      }
    };

    Face.prototype.accentuate = function(n, pov, scaling) {
      var mark, _i, _len, _ref, _results;
      if (this.normal.facing(pov)) {
        this.fill(n, pov, scaling, "chartreuse");
        _ref = this.marks;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          mark = _ref[_i];
          _results.push(mark.fill(n, pov, scaling, "darkgray"));
        }
        return _results;
      }
    };

    Face.prototype.describe = function() {
      var point, _i, _len, _ref;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        point.describe();
      }
      return this.normal.ar[1].describe();
    };

    Face.prototype.sanitize = function() {
      var f, p, point, _i, _j, _k, _len, _len1, _len2, _ref, _ref1, _ref2;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        point.sanitize();
      }
      _ref1 = this.marks;
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        f = _ref1[_j];
        _ref2 = f.ar;
        for (_k = 0, _len2 = _ref2.length; _k < _len2; _k++) {
          p = _ref2[_k];
          p.sanitize();
        }
      }
      return this.normal.ar[1].sanitize();
    };

    Face.prototype.translate = function(x, y, z) {
      var f, p, point, _i, _j, _k, _len, _len1, _len2, _ref, _ref1, _ref2;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        point.translate(x, y, z);
      }
      _ref1 = this.marks;
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        f = _ref1[_j];
        _ref2 = f.ar;
        for (_k = 0, _len2 = _ref2.length; _k < _len2; _k++) {
          p = _ref2[_k];
          p.translate(x, y, z);
        }
      }
      return this.normal.ar[1].translate(x, y, z);
    };

    Face.prototype.preSpin = function(axis, rad) {
      var p, _i, _len, _ref;
      _ref = this.markVertices;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];
        p.spin(axis, rad);
      }
      return this.normal.ar[1].spin(axis, rad);
    };

    Face.prototype.preTranslate = function(x, y, z) {
      var point, _i, _len, _ref;
      _ref = this.markVertices;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        point.translate(x, y, z);
      }
      return this.normal.ar[1].translate(x, y, z);
    };

    Face.prototype.spin = function(axis, rad) {
      var f, p, _i, _j, _len, _len1, _ref, _ref1, _results;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];
        p.spin(axis, rad);
      }
      this.normal.ar[1].spin(axis, rad);
      _ref1 = this.marks;
      _results = [];
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        f = _ref1[_j];
        _results.push(f.spin(axis, rad));
      }
      return _results;
    };

    Face.prototype.extremity = function(pov) {
      var dim, ext, point, scr, _i, _j, _len, _len1, _ref;
      ext = 0;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        scr = point.screenPoint(pov);
        for (_j = 0, _len1 = scr.length; _j < _len1; _j++) {
          dim = scr[_j];
          if (dim > ext) {
            ext = dim;
          }
        }
      }
      return ext;
    };

    Face.prototype.getTops = function() {
      var point, topX, topY, topZ, _i, _len, _ref;
      topX = 1000;
      topY = 1000;
      topZ = 1000;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        if (point.ar[0] < topX) {
          topX = point.ar[0];
        }
        if (point.ar[1] < topY) {
          topY = point.ar[1];
        }
        if (point.ar[2] < topZ) {
          topZ = point.ar[2];
        }
      }
      return [topX, topY, topZ];
    };

    Face.prototype.getBoundingCube = function() {
      var point, x0, x1, y0, y1, z0, z1, _i, _len, _ref;
      x0 = 1000;
      y0 = 1000;
      z0 = 1000;
      x1 = -1000;
      y1 = -1000;
      z1 = -1000;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        point = _ref[_i];
        if (point.ar[0] < x0) {
          x0 = point.ar[0];
        }
        if (point.ar[1] < y0) {
          y0 = point.ar[1];
        }
        if (point.ar[2] < z0) {
          z0 = point.ar[2];
        }
        if (point.ar[0] > x1) {
          x1 = point.ar[0];
        }
        if (point.ar[1] > y1) {
          y1 = point.ar[1];
        }
        if (point.ar[2] > z1) {
          z1 = point.ar[2];
        }
      }
      return [[x0, y0, z0], [x1, y1, z1]];
    };

    Face.prototype.imposter = function() {
      var result;
      result = this.clone();
      result.marks = [];
      result.getMarks();
      return result;
    };

    Face.prototype.populateMainMarkVertices = function() {
      var p, _i, _len, _ref, _results;
      _ref = this.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];
        _results.push(this.markVertices.push(p));
      }
      return _results;
    };

    Face.prototype.populateMidRectVertices = function() {
      var i, j, l, x, y, z, _i, _ref;
      l = this.ar.length;
      for (i = _i = 0, _ref = l - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        j = i + 1;
        if (j >= l) {
          j = 0;
        }
        x = (this.ar[i].ar[0] + this.ar[j].ar[0]) / 2;
        y = (this.ar[i].ar[1] + this.ar[j].ar[1]) / 2;
        z = (this.ar[i].ar[2] + this.ar[j].ar[2]) / 2;
        this.markVertices.push(new Point([x, y, z]));
      }
      x = (this.ar[0].ar[0] + this.ar[2].ar[0]) / 2;
      y = (this.ar[0].ar[1] + this.ar[2].ar[1]) / 2;
      z = (this.ar[0].ar[2] + this.ar[2].ar[2]) / 2;
      return this.markVertices.push(new Point([x, y, z]));
    };

    Face.prototype.addMark = function(list) {
      var l, points, _i, _len;
      points = [];
      for (_i = 0, _len = list.length; _i < _len; _i++) {
        l = list[_i];
        points.push(this.markVertices[l]);
      }
      return this.marks.push(new Face(points));
    };

    Face.prototype.getRectMarks = function(index) {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.1) {
        this.addMark([0, 1, 2]);
        return this.type = 2 + index;
      } else if (rand < 0.2) {
        this.addMark([1, 2, 3]);
        return this.type = 2 + index;
      } else if (rand < 0.3) {
        this.addMark([2, 3, 0]);
        return this.type = 2 + index;
      } else if (rand < 0.4) {
        this.addMark([3, 0, 1]);
        return this.type = 2 + index;
      } else if (rand < 0.5) {
        this.addMark([0, 4, 8, 7]);
        return this.type = 1 + index;
      } else if (rand < 0.6) {
        this.addMark([1, 5, 8, 4]);
        return this.type = 1 + index;
      } else if (rand < 0.7) {
        this.addMark([2, 6, 8, 5]);
        return this.type = 1 + index;
      } else if (rand < 0.8) {
        this.addMark([3, 7, 8, 6]);
        return this.type = 1 + index;
      } else if (rand < 0.9) {
        this.type = 3 + index;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0 + index;
      }
    };

    Face.prototype.twist = function(rad) {
      var x, y, z;
      x = this.ar[0].ar[0];
      y = this.ar[0].ar[1];
      z = this.ar[0].ar[2];
      return this.spin([[x, y, z], [x, y, z + 10]], rad);
    };

    Face.prototype.getCenter = function() {
      var i, l, x, y, z, _i, _ref;
      x = 0;
      y = 0;
      z = 0;
      l = this.ar.length;
      for (i = _i = 0, _ref = l - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        x += this.ar[i].ar[0];
        y += this.ar[i].ar[1];
        z += this.ar[i].ar[2];
      }
      x = x / l;
      y = y / l;
      z = z / l;
      return [x, y, z];
    };

    Face.prototype.populateInnerMarkVertices = function() {
      var center, delta, draw, i, j, l, result, start, _i, _j, _results;
      center = this.getCenter();
      draw = 0.5;
      l = this.ar.length - 1;
      _results = [];
      for (i = _i = 0; 0 <= l ? _i <= l : _i >= l; i = 0 <= l ? ++_i : --_i) {
        result = [];
        for (j = _j = 0; _j <= 2; j = ++_j) {
          start = this.ar[i].ar[j];
          delta = start - center[j];
          result.push(start - delta * draw);
        }
        _results.push(this.markVertices.push(new Point(result)));
      }
      return _results;
    };

    return Face;

  })(Queue);

  ProtoRect = (function(_super) {
    __extends(ProtoRect, _super);

    function ProtoRect(x, y, x1, y1) {
      ProtoRect.__super__.constructor.call(this, [new Point([x, y]), new Point([x + x1, y]), new Point([x + x1, y + y1]), new Point([x, y + y1])]);
      this.getMarks();
    }

    ProtoRect.prototype.clone = function() {
      var result;
      result = new ProtoRect(0, 0, 1, 1);
      this.copyTo(result);
      return result;
    };

    ProtoRect.prototype.getMarks = function() {
      return this.getRectMarks(4);
    };

    return ProtoRect;

  })(Face);

  Square = (function(_super) {
    __extends(Square, _super);

    function Square(x, y) {
      Square.__super__.constructor.call(this, x, y, 1, 1);
    }

    Square.prototype.getMarks = function() {
      return this.getRectMarks(0);
    };

    Square.prototype.clone = function() {
      var result;
      result = new Square(0, 0);
      this.copyTo(result);
      return result;
    };

    return Square;

  })(ProtoRect);

  DblMarkSquare = (function(_super) {
    __extends(DblMarkSquare, _super);

    function DblMarkSquare(x, y) {
      DblMarkSquare.__super__.constructor.call(this, x, y, 1, 1);
    }

    DblMarkSquare.prototype.getMarks = function() {
      var rand, x, y, z;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      x = (this.ar[0].ar[0] + this.ar[2].ar[0]) / 2;
      y = (this.ar[0].ar[1] + this.ar[2].ar[1]) / 2;
      z = (this.ar[0].ar[2] + this.ar[2].ar[2]) / 2;
      this.markVertices.push(new Point([x, y, z]));
      rand = Math.random();
      if (rand < 0.2) {
        this.addMark([0, 4, 8, 7]);
        this.addMark([2, 6, 9, 5]);
        return this.type = 34;
      } else if (rand < 0.4) {
        this.addMark([4, 1, 5, 8]);
        this.addMark([6, 3, 7, 9]);
        return this.type = 34;
      } else if (rand < 0.6) {
        this.addMark([0, 1, 8]);
        this.addMark([2, 3, 9]);
        return this.type = 35;
      } else if (rand < 0.8) {
        this.addMark([1, 2, 8]);
        this.addMark([3, 0, 9]);
        return this.type = 35;
      } else if (rand < 0.9) {
        this.type = 3;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0;
      }
    };

    DblMarkSquare.prototype.clone = function() {
      var result;
      result = new DblMarkSquare(0, 0);
      this.copyTo(result);
      return result;
    };

    return DblMarkSquare;

  })(ProtoRect);

  SquareDiamond = (function(_super) {
    __extends(SquareDiamond, _super);

    function SquareDiamond(x, y) {
      SquareDiamond.__super__.constructor.call(this, x, y, 1, 1);
    }

    SquareDiamond.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.15) {
        this.addMark([0, 1, 8]);
        return this.type = 24;
      } else if (rand < 0.3) {
        this.addMark([1, 2, 8]);
        return this.type = 24;
      } else if (rand < 0.45) {
        this.addMark([2, 3, 8]);
        return this.type = 24;
      } else if (rand < 0.6) {
        this.addMark([3, 0, 8]);
        return this.type = 24;
      } else if (rand < 0.8) {
        this.type = 3;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0;
      }
    };

    SquareDiamond.prototype.clone = function() {
      var result;
      result = new SquareDiamond(0, 0);
      this.copyTo(result);
      return result;
    };

    return SquareDiamond;

  })(ProtoRect);

  DieFace = (function(_super) {
    __extends(DieFace, _super);

    function DieFace(x, y) {
      DieFace.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 1, y + 1]), new Point([x, y + 1])]);
    }

    DieFace.prototype.getMarks = function() {
      return this.addDieSpots(randInt(6) + 1);
    };

    DieFace.prototype.clone = function() {
      var result;
      result = new DieFace(0, 0);
      this.copyTo(result);
      return result;
    };

    DieFace.prototype.addDieSpots = function(spots) {
      var deltaHX, deltaHY, deltaHZ, deltaVX, deltaVY, deltaVZ, i, j, pushVertexArray, start, vertexArrays, x, y, z, _i, _j, _k, _l;
      this.markVertices = [];
      deltaHX = this.ar[1].ar[0] - this.ar[0].ar[0];
      deltaHY = this.ar[1].ar[1] - this.ar[0].ar[1];
      deltaHZ = this.ar[1].ar[2] - this.ar[0].ar[2];
      deltaVX = this.ar[3].ar[0] - this.ar[0].ar[0];
      deltaVY = this.ar[3].ar[1] - this.ar[0].ar[1];
      deltaVZ = this.ar[3].ar[2] - this.ar[0].ar[2];
      for (i = _i = 0; _i <= 5; i = ++_i) {
        x = this.ar[0].ar[0] + deltaVX / 7 * (i + 1);
        y = this.ar[0].ar[1] + deltaVY / 7 * (i + 1);
        z = this.ar[0].ar[2] + deltaVZ / 7 * (i + 1);
        for (j = _j = 0; _j <= 5; j = ++_j) {
          x += deltaHX / 7;
          y += deltaHY / 7;
          z += deltaHZ / 7;
          this.markVertices.push(new Point([x, y, z]));
        }
      }
      vertexArrays = [];
      for (i = _k = 0; _k <= 2; i = ++_k) {
        for (j = _l = 0; _l <= 2; j = ++_l) {
          start = 12 * i + 2 * j;
          vertexArrays.push([start, start + 1, start + 7, start + 6]);
        }
      }
      pushVertexArray = (function(_this) {
        return function(vertexArray) {
          var n, _len, _m, _results;
          _results = [];
          for (_m = 0, _len = vertexArray.length; _m < _len; _m++) {
            n = vertexArray[_m];
            _results.push(_this.addMark(vertexArrays[n]));
          }
          return _results;
        };
      })(this);
      switch (spots) {
        case 1:
          this.type = 1;
          return pushVertexArray([4]);
        case 2:
          this.type = 2;
          return pushVertexArray([2, 6]);
        case 3:
          this.type = 3;
          return pushVertexArray([0, 4, 8]);
        case 4:
          this.type = 4;
          return pushVertexArray([0, 2, 6, 8]);
        case 5:
          this.type = 5;
          return pushVertexArray([0, 2, 4, 6, 8]);
        case 6:
          this.type = 6;
          return pushVertexArray([0, 2, 3, 5, 6, 8]);
      }
    };

    return DieFace;

  })(Face);

  FourSquare = (function(_super) {
    __extends(FourSquare, _super);

    function FourSquare(x, y) {
      FourSquare.__super__.constructor.call(this, x, y, 4, 4);
    }

    FourSquare.prototype.getMarks = function() {
      return this.type = 14;
    };

    FourSquare.prototype.clone = function() {
      var result;
      result = new FourSquare(0, 0);
      this.copyTo(result);
      return result;
    };

    return FourSquare;

  })(ProtoRect);

  TwoSquare = (function(_super) {
    __extends(TwoSquare, _super);

    function TwoSquare(x, y) {
      TwoSquare.__super__.constructor.call(this, x, y, 2, 2);
    }

    TwoSquare.prototype.getMarks = function() {
      return this.getRectMarks(0);
    };

    return TwoSquare;

  })(ProtoRect);

  SquareFor12 = (function(_super) {
    __extends(SquareFor12, _super);

    function SquareFor12() {
      return SquareFor12.__super__.constructor.apply(this, arguments);
    }

    SquareFor12.prototype.clone = function() {
      var result;
      result = new SquareFor12(0, 0);
      this.copyTo(result);
      return result;
    };

    SquareFor12.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.15) {
        this.addMark([0, 1, 2]);
        return this.type = 2;
      } else if (rand < 0.3) {
        this.addMark([1, 2, 3]);
        return this.type = 2;
      } else if (rand < 0.45) {
        this.addMark([2, 3, 0]);
        return this.type = 2;
      } else if (rand < 0.6) {
        this.addMark([3, 0, 1]);
        return this.type = 2;
      } else if (rand < 0.8) {
        this.type = 3;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0;
      }
    };

    return SquareFor12;

  })(TwoSquare);

  BlankSquare = (function(_super) {
    __extends(BlankSquare, _super);

    function BlankSquare() {
      return BlankSquare.__super__.constructor.apply(this, arguments);
    }

    BlankSquare.prototype.clone = function() {
      var result;
      result = new BlankSquare(0, 0);
      this.copyTo(result);
      return result;
    };

    BlankSquare.prototype.getMarks = function() {
      var rand;
      rand = Math.random();
      if (rand < 0.5) {
        this.markVertices = [];
        this.populateMainMarkVertices();
        this.type = 3;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0;
      }
    };

    return BlankSquare;

  })(Square);

  StripedSquare = (function(_super) {
    __extends(StripedSquare, _super);

    function StripedSquare() {
      return StripedSquare.__super__.constructor.apply(this, arguments);
    }

    StripedSquare.prototype.clone = function() {
      var result;
      result = new StripedSquare(0, 0);
      this.copyTo(result);
      return result;
    };

    StripedSquare.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.15) {
        this.addMark([0, 1, 5, 7]);
        return this.type = 23;
      } else if (rand < 0.3) {
        this.addMark([1, 2, 6, 4]);
        return this.type = 23;
      } else if (rand < 0.45) {
        this.addMark([2, 3, 7, 5]);
        return this.type = 23;
      } else if (rand < 0.6) {
        this.addMark([3, 0, 4, 6]);
        return this.type = 23;
      } else if (rand < 0.8) {
        this.type = 3;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0;
      }
    };

    return StripedSquare;

  })(Square);

  HorizRect = (function(_super) {
    __extends(HorizRect, _super);

    function HorizRect(x, y) {
      HorizRect.__super__.constructor.call(this, x, y, 2, 1);
    }

    return HorizRect;

  })(ProtoRect);

  StripedRect = (function(_super) {
    __extends(StripedRect, _super);

    function StripedRect() {
      return StripedRect.__super__.constructor.apply(this, arguments);
    }

    StripedRect.prototype.clone = function() {
      var result;
      result = new StripedRect(0, 0);
      this.copyTo(result);
      return result;
    };

    StripedRect.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      rand = Math.random();
      if (rand < 0.15) {
        this.addMark([0, 1, 2]);
        return this.type = 30;
      } else if (rand < 0.3) {
        this.addMark([1, 2, 3]);
        return this.type = 30;
      } else if (rand < 0.45) {
        this.addMark([2, 3, 0]);
        return this.type = 30;
      } else if (rand < 0.6) {
        this.addMark([3, 0, 1]);
        return this.type = 30;
      } else if (rand < 0.8) {
        this.type = 7;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 4;
      }
    };

    return StripedRect;

  })(HorizRect);

  TippedRect = (function(_super) {
    __extends(TippedRect, _super);

    function TippedRect() {
      return TippedRect.__super__.constructor.apply(this, arguments);
    }

    TippedRect.prototype.clone = function() {
      var result;
      result = new TippedRect(0, 0);
      this.copyTo(result);
      return result;
    };

    TippedRect.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.1) {
        this.addMark([0, 1, 2]);
        return this.type = 30;
      } else if (rand < 0.2) {
        this.addMark([1, 2, 3]);
        return this.type = 30;
      } else if (rand < 0.3) {
        this.addMark([2, 3, 0]);
        return this.type = 30;
      } else if (rand < 0.4) {
        this.addMark([3, 0, 1]);
        return this.type = 30;
      } else if (rand < 0.5) {
        this.addMark([0, 4, 7]);
        return this.type = 33;
      } else if (rand < 0.6) {
        this.addMark([1, 5, 4]);
        return this.type = 33;
      } else if (rand < 0.7) {
        this.addMark([2, 6, 5]);
        return this.type = 33;
      } else if (rand < 0.8) {
        this.addMark([3, 7, 6]);
        return this.type = 33;
      } else if (rand < 0.9) {
        this.type = 7;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 4;
      }
    };

    return TippedRect;

  })(HorizRect);

  InnerRect = (function(_super) {
    __extends(InnerRect, _super);

    function InnerRect() {
      return InnerRect.__super__.constructor.apply(this, arguments);
    }

    InnerRect.prototype.clone = function() {
      var result;
      result = new InnerRect(0, 0);
      this.copyTo(result);
      return result;
    };

    InnerRect.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateInnerMarkVertices();
      rand = Math.random();
      if (rand < 0.3) {
        this.type = 28;
        return this.addMark([4, 5, 6, 7]);
      } else if (rand < 0.5) {
        this.type = 7;
        return this.addMark([0, 1, 1, 2, 3]);
      } else {
        return this.type = 0;
      }
    };

    return InnerRect;

  })(HorizRect);

  DiagonalRect = (function(_super) {
    __extends(DiagonalRect, _super);

    function DiagonalRect(x, y) {
      DiagonalRect.__super__.constructor.call(this, x, y, 1, Math.sqrt(2));
    }

    return DiagonalRect;

  })(ProtoRect);

  HorizRectThree = (function(_super) {
    __extends(HorizRectThree, _super);

    function HorizRectThree(x, y) {
      HorizRectThree.__super__.constructor.call(this, x, y, 3, 1);
    }

    return HorizRectThree;

  })(ProtoRect);

  VertRectThree = (function(_super) {
    __extends(VertRectThree, _super);

    function VertRectThree(x, y) {
      VertRectThree.__super__.constructor.call(this, x, y, 1, 3);
    }

    return VertRectThree;

  })(ProtoRect);

  HorizRectFour = (function(_super) {
    __extends(HorizRectFour, _super);

    function HorizRectFour(x, y) {
      HorizRectFour.__super__.constructor.call(this, x, y, 4, 1);
    }

    return HorizRectFour;

  })(ProtoRect);

  VertRectFour = (function(_super) {
    __extends(VertRectFour, _super);

    function VertRectFour(x, y) {
      VertRectFour.__super__.constructor.call(this, x, y, 1, 4);
    }

    return VertRectFour;

  })(ProtoRect);

  VertRect = (function(_super) {
    __extends(VertRect, _super);

    function VertRect(x, y) {
      VertRect.__super__.constructor.call(this, x, y, 1, 2);
    }

    return VertRect;

  })(ProtoRect);

  Triangle = (function(_super) {
    __extends(Triangle, _super);

    function Triangle(x, y) {
      Triangle.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 2, y + 2]), new Point([x - 2, y + 2])]);
      this.reorient();
      this.getMarks();
    }

    Triangle.prototype.reorient = function() {};

    Triangle.prototype.getMarks = function() {
      var i, j, rand, x, y, z, _i;
      this.markVertices = [];
      this.populateMainMarkVertices();
      for (i = _i = 0; _i <= 2; i = ++_i) {
        j = i + 1;
        if (i === 2) {
          j = 0;
        }
        x = (this.ar[i].ar[0] + this.ar[j].ar[0]) / 2;
        y = (this.ar[i].ar[1] + this.ar[j].ar[1]) / 2;
        z = (this.ar[i].ar[2] + this.ar[j].ar[2]) / 2;
        this.markVertices.push(new Point([x, y, z]));
      }
      rand = Math.random();
      if (rand < 0.25) {
        this.type = 15;
        return this.addMark([0, 1, 4]);
      } else if (rand < 0.5) {
        this.type = 15;
        return this.addMark([0, 4, 2]);
      } else if (rand < 0.75) {
        this.type = 16;
        return this.addMark([0, 1, 1, 2]);
      } else {
        return this.type = 17;
      }
    };

    Triangle.prototype.clone = function() {
      var result;
      result = new Triangle(0, 0);
      this.copyTo(result);
      return result;
    };

    return Triangle;

  })(Face);

  InnerTriangle = (function(_super) {
    __extends(InnerTriangle, _super);

    function InnerTriangle(x, y) {
      InnerTriangle.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 2, y]), new Point([x + 1, y + 2])]);
      this.reorient();
      this.getMarks();
    }

    InnerTriangle.prototype.reorient = function() {};

    InnerTriangle.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateInnerMarkVertices();
      rand = Math.random();
      if (rand < 0.3) {
        this.type = 29;
        return this.addMark([3, 4, 5]);
      } else if (rand < 0.5) {
        this.type = 16;
        return this.addMark([0, 1, 1, 2]);
      } else {
        return this.type = 17;
      }
    };

    InnerTriangle.prototype.clone = function() {
      var result;
      result = new InnerTriangle(0, 0);
      this.copyTo(result);
      return result;
    };

    return InnerTriangle;

  })(Face);

  InnerTriangleInv = (function(_super) {
    __extends(InnerTriangleInv, _super);

    function InnerTriangleInv() {
      return InnerTriangleInv.__super__.constructor.apply(this, arguments);
    }

    InnerTriangleInv.prototype.reorient = function() {
      var x, y, z;
      x = this.ar[0].ar[0];
      y = this.ar[0].ar[1];
      z = this.ar[0].ar[2];
      return this.spin([[x, y, z], [x, y, z + 10]], Math.PI);
    };

    return InnerTriangleInv;

  })(InnerTriangle);

  TriangleSideways = (function(_super) {
    __extends(TriangleSideways, _super);

    function TriangleSideways() {
      return TriangleSideways.__super__.constructor.apply(this, arguments);
    }

    TriangleSideways.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI / 2);
    };

    return TriangleSideways;

  })(Triangle);

  BlankTriangle = (function(_super) {
    __extends(BlankTriangle, _super);

    function BlankTriangle() {
      return BlankTriangle.__super__.constructor.apply(this, arguments);
    }

    BlankTriangle.prototype.clone = function() {
      var result;
      result = new BlankTriangle(0, 0);
      this.copyTo(result);
      return result;
    };

    BlankTriangle.prototype.getMarks = function() {
      var rand;
      rand = Math.random();
      if (rand < 0.5) {
        this.markVertices = [];
        this.populateMainMarkVertices();
        this.type = 16;
        return this.addMark([0, 1, 1, 2]);
      } else {
        return this.type = 17;
      }
    };

    return BlankTriangle;

  })(Triangle);

  BlankTriangleInv1 = (function(_super) {
    __extends(BlankTriangleInv1, _super);

    function BlankTriangleInv1() {
      return BlankTriangleInv1.__super__.constructor.apply(this, arguments);
    }

    BlankTriangleInv1.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI / 2);
    };

    return BlankTriangleInv1;

  })(BlankTriangle);

  BlankTriangleInv2 = (function(_super) {
    __extends(BlankTriangleInv2, _super);

    function BlankTriangleInv2() {
      return BlankTriangleInv2.__super__.constructor.apply(this, arguments);
    }

    BlankTriangleInv2.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI);
    };

    return BlankTriangleInv2;

  })(BlankTriangle);

  BlankTriangleInv3 = (function(_super) {
    __extends(BlankTriangleInv3, _super);

    function BlankTriangleInv3() {
      return BlankTriangleInv3.__super__.constructor.apply(this, arguments);
    }

    BlankTriangleInv3.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], -Math.PI / 2);
    };

    return BlankTriangleInv3;

  })(BlankTriangle);

  Pentagon = (function(_super) {
    __extends(Pentagon, _super);

    function Pentagon(x, y) {
      var i, j, z, _i, _j;
      Pentagon.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 2, y]), new Point([x + 3, y]), new Point([x + 4, y])]);
      for (i = _i = 0; _i <= 2; i = ++_i) {
        x = this.ar[3 - i].ar[0];
        y = this.ar[3 - i].ar[1];
        z = this.ar[3 - i].ar[2];
        for (j = _j = 0; 0 <= i ? _j <= i : _j >= i; j = 0 <= i ? ++_j : --_j) {
          this.ar[4 - j].spin([[x, y, z], [x, y, z + 10]], -Math.PI * 2 / 5);
        }
      }
      this.reorient();
      this.getMarks();
    }

    Pentagon.prototype.reorient = function() {};

    Pentagon.prototype.getMarks = function() {
      var i, rand, x, y, z, _i, _ref;
      this.markVertices = [];
      this.populateMainMarkVertices();
      x = 0;
      y = 0;
      z = 0;
      for (i = _i = 0, _ref = this.ar.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        x += this.ar[i].ar[0];
        y += this.ar[i].ar[1];
        z += this.ar[i].ar[2];
      }
      x = x / 5;
      y = y / 5;
      z = z / 5;
      this.markVertices.push(new Point([x, y, z]));
      rand = Math.random();
      if (rand < 0.1) {
        this.type = 18;
        return this.addMark([0, 1, 5]);
      } else if (rand < 0.2) {
        this.type = 18;
        return this.addMark([1, 2, 5]);
      } else if (rand < 0.3) {
        this.type = 18;
        return this.addMark([2, 3, 5]);
      } else if (rand < 0.4) {
        this.type = 18;
        return this.addMark([3, 4, 5]);
      } else if (rand < 0.5) {
        this.type = 18;
        return this.addMark([4, 0, 5]);
      } else if (rand < 0.7) {
        this.type = 19;
        return this.addMark([0, 1, 2, 2, 3, 4]);
      } else {
        return this.type = 20;
      }
    };

    Pentagon.prototype.clone = function() {
      var result;
      result = new Pentagon(0, 0);
      this.copyTo(result);
      return result;
    };

    return Pentagon;

  })(Face);

  VertPentagon = (function(_super) {
    __extends(VertPentagon, _super);

    function VertPentagon(x, y) {
      var i, j, z, _i, _j;
      VertPentagon.__super__.constructor.call(this, [new Point([x, y]), new Point([x, y + 1]), new Point([x, y + 2]), new Point([x, y + 3]), new Point([x, y + 4])]);
      for (i = _i = 0; _i <= 2; i = ++_i) {
        x = this.ar[3 - i].ar[0];
        y = this.ar[3 - i].ar[1];
        z = this.ar[3 - i].ar[2];
        for (j = _j = 0; 0 <= i ? _j <= i : _j >= i; j = 0 <= i ? ++_j : --_j) {
          this.ar[4 - j].spin([[x, y, z], [x, y, z + 10]], -Math.PI * 2 / 5);
        }
      }
      this.reorient();
      this.getMarks();
    }

    VertPentagon.prototype.reorient = function() {};

    VertPentagon.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.05) {
        this.type = 31;
        return this.addMark([0, 1, 2, 7]);
      } else if (rand < 0.1) {
        this.type = 31;
        return this.addMark([0, 4, 3, 7]);
      } else if (rand < 0.15) {
        this.type = 31;
        return this.addMark([1, 2, 3, 8]);
      } else if (rand < 0.2) {
        this.type = 31;
        return this.addMark([1, 0, 4, 8]);
      } else if (rand < 0.25) {
        this.type = 31;
        return this.addMark([2, 3, 4, 9]);
      } else if (rand < 0.3) {
        this.type = 31;
        return this.addMark([2, 1, 0, 9]);
      } else if (rand < 0.35) {
        this.type = 31;
        return this.addMark([3, 4, 0, 5]);
      } else if (rand < 0.4) {
        this.type = 31;
        return this.addMark([3, 2, 1, 5]);
      } else if (rand < 0.45) {
        this.type = 31;
        return this.addMark([4, 0, 1, 6]);
      } else if (rand < 0.5) {
        this.type = 31;
        return this.addMark([4, 3, 2, 6]);
      } else if (rand < 0.75) {
        this.type = 19;
        return this.addMark([0, 1, 2, 2, 3, 4]);
      } else {
        return this.type = 20;
      }
    };

    VertPentagon.prototype.clone = function() {
      var result;
      result = new VertPentagon(0, 0);
      this.copyTo(result);
      return result;
    };

    return VertPentagon;

  })(Face);

  VertPentagonInv = (function(_super) {
    __extends(VertPentagonInv, _super);

    function VertPentagonInv(x, y) {
      VertPentagonInv.__super__.constructor.call(this, x, y);
    }

    VertPentagonInv.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI);
    };

    return VertPentagonInv;

  })(VertPentagon);

  TwoPentagon = (function(_super) {
    __extends(TwoPentagon, _super);

    function TwoPentagon(x, y) {
      var i, j, z, _i, _j;
      TwoPentagon.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 2, y]), new Point([x + 4, y]), new Point([x + 6, y]), new Point([x + 8, y])]);
      for (i = _i = 0; _i <= 2; i = ++_i) {
        x = this.ar[3 - i].ar[0];
        y = this.ar[3 - i].ar[1];
        z = this.ar[3 - i].ar[2];
        for (j = _j = 0; 0 <= i ? _j <= i : _j >= i; j = 0 <= i ? ++_j : --_j) {
          this.ar[4 - j].spin([[x, y, z], [x, y, z + 10]], -Math.PI * 2 / 5);
        }
      }
      this.getMarks();
    }

    TwoPentagon.prototype.getMarks = function() {
      return this.type = 26;
    };

    TwoPentagon.prototype.clone = function() {
      var result;
      result = new TwoPentagon(0, 0);
      this.copyTo(result);
      return result;
    };

    return TwoPentagon;

  })(Face);

  HexSection = (function(_super) {
    __extends(HexSection, _super);

    function HexSection(x, y) {
      var z;
      HexSection.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 2, y])]);
      x = this.ar[1].ar[0];
      y = this.ar[1].ar[1];
      z = this.ar[1].ar[2];
      this.ar[2].spin([[x, y, z], [x, y, z + 10]], -Math.PI * 2 / 3);
      this.reorient();
      this.getMarks();
    }

    HexSection.prototype.reorient = function() {};

    HexSection.prototype.clone = function() {
      var result;
      result = new HexSection(0, 0);
      this.copyTo(result);
      return result;
    };

    HexSection.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      rand = Math.random();
      if (rand < 0.5) {
        this.type = 21;
        return this.addMark([0, 1, 1, 2]);
      } else {
        return this.type = 22;
      }
    };

    return HexSection;

  })(Face);

  TippedHexSection = (function(_super) {
    __extends(TippedHexSection, _super);

    function TippedHexSection(x, y) {
      var z;
      TippedHexSection.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 2, y]), new Point([x + 4, y])]);
      x = this.ar[1].ar[0];
      y = this.ar[1].ar[1];
      z = this.ar[1].ar[2];
      this.ar[2].spin([[x, y, z], [x, y, z + 10]], -Math.PI * 2 / 3);
      this.reorient();
      this.getMarks();
    }

    TippedHexSection.prototype.reorient = function() {};

    TippedHexSection.prototype.clone = function() {
      var result;
      result = new TippedHexSection(0, 0);
      this.copyTo(result);
      return result;
    };

    TippedHexSection.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      this.populateMidRectVertices();
      rand = Math.random();
      if (rand < 0.2) {
        this.type = 32;
        return this.addMark([0, 3, 5]);
      } else if (rand < 0.4) {
        this.type = 32;
        return this.addMark([1, 4, 3]);
      } else if (rand < 0.6) {
        this.type = 32;
        return this.addMark([2, 5, 4]);
      } else if (rand < 0.8) {
        this.type = 21;
        return this.addMark([0, 1, 1, 2]);
      } else {
        return this.type = 22;
      }
    };

    return TippedHexSection;

  })(Face);

  TippedHexSectionInv = (function(_super) {
    __extends(TippedHexSectionInv, _super);

    function TippedHexSectionInv() {
      return TippedHexSectionInv.__super__.constructor.apply(this, arguments);
    }

    TippedHexSectionInv.prototype.reorient = function() {
      return this.twist(Math.PI);
    };

    return TippedHexSectionInv;

  })(TippedHexSection);

  HexSection2 = (function(_super) {
    __extends(HexSection2, _super);

    function HexSection2() {
      return HexSection2.__super__.constructor.apply(this, arguments);
    }

    HexSection2.prototype.reorient = function() {
      return this.twist(Math.PI * 1 / 3);
    };

    return HexSection2;

  })(HexSection);

  HexSection3 = (function(_super) {
    __extends(HexSection3, _super);

    function HexSection3() {
      return HexSection3.__super__.constructor.apply(this, arguments);
    }

    HexSection3.prototype.reorient = function() {
      return this.twist(Math.PI * 2 / 3);
    };

    return HexSection3;

  })(HexSection);

  HexSection4 = (function(_super) {
    __extends(HexSection4, _super);

    function HexSection4() {
      return HexSection4.__super__.constructor.apply(this, arguments);
    }

    HexSection4.prototype.reorient = function() {
      return this.twist(Math.PI);
    };

    return HexSection4;

  })(HexSection);

  HexSection5 = (function(_super) {
    __extends(HexSection5, _super);

    function HexSection5() {
      return HexSection5.__super__.constructor.apply(this, arguments);
    }

    HexSection5.prototype.reorient = function() {
      return this.twist(-Math.PI * 1 / 3);
    };

    return HexSection5;

  })(HexSection);

  HexSection6 = (function(_super) {
    __extends(HexSection6, _super);

    function HexSection6() {
      return HexSection6.__super__.constructor.apply(this, arguments);
    }

    HexSection6.prototype.reorient = function() {
      return this.twist(-Math.PI * 2 / 3);
    };

    return HexSection6;

  })(HexSection);

  HexSection7 = (function(_super) {
    __extends(HexSection7, _super);

    function HexSection7() {
      return HexSection7.__super__.constructor.apply(this, arguments);
    }

    HexSection7.prototype.reorient = function() {
      return this.twist(Math.PI / 2);
    };

    HexSection7.prototype.getMarks = function() {
      var i, j, rand, x, y, z, _i;
      this.markVertices = [];
      this.populateMainMarkVertices();
      for (i = _i = 0; _i <= 2; i = ++_i) {
        j = i + 1;
        if (i === 2) {
          j = 0;
        }
        x = (this.ar[i].ar[0] + this.ar[j].ar[0]) / 2;
        y = (this.ar[i].ar[1] + this.ar[j].ar[1]) / 2;
        z = (this.ar[i].ar[2] + this.ar[j].ar[2]) / 2;
        this.markVertices.push(new Point([x, y, z]));
      }
      rand = Math.random();
      if (rand < 0.1) {
        this.type = 15;
        return this.addMark([0, 1, 4]);
      } else if (rand < 0.2) {
        this.type = 15;
        return this.addMark([0, 4, 2]);
      } else if (rand < 0.3) {
        this.type = 15;
        return this.addMark([0, 3, 2]);
      } else if (rand < 0.4) {
        this.type = 15;
        return this.addMark([3, 1, 2]);
      } else if (rand < 0.5) {
        this.type = 15;
        return this.addMark([1, 2, 5]);
      } else if (rand < 0.6) {
        this.type = 15;
        return this.addMark([0, 1, 5]);
      } else if (rand < 0.8) {
        this.type = 16;
        return this.addMark([0, 1, 1, 2]);
      } else {
        return this.type = 17;
      }
    };

    HexSection7.prototype.clone = function() {
      var result;
      result = new HexSection7(0, 0);
      this.copyTo(result);
      return result;
    };

    return HexSection7;

  })(HexSection);

  HexSection8 = (function(_super) {
    __extends(HexSection8, _super);

    function HexSection8() {
      return HexSection8.__super__.constructor.apply(this, arguments);
    }

    HexSection8.prototype.reorient = function() {
      return this.twist(-Math.PI / 2);
    };

    return HexSection8;

  })(HexSection7);

  HexSection9 = (function(_super) {
    __extends(HexSection9, _super);

    function HexSection9() {
      return HexSection9.__super__.constructor.apply(this, arguments);
    }

    HexSection9.prototype.reorient = function() {};

    return HexSection9;

  })(HexSection7);

  HexSection10 = (function(_super) {
    __extends(HexSection10, _super);

    function HexSection10() {
      return HexSection10.__super__.constructor.apply(this, arguments);
    }

    HexSection10.prototype.reorient = function() {
      return this.twist(Math.PI);
    };

    return HexSection10;

  })(HexSection7);

  PentagonInv = (function(_super) {
    __extends(PentagonInv, _super);

    function PentagonInv(x, y) {
      PentagonInv.__super__.constructor.call(this, x, y);
    }

    PentagonInv.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI);
    };

    return PentagonInv;

  })(Pentagon);

  ZShape = (function(_super) {
    __extends(ZShape, _super);

    function ZShape(x, y) {
      ZShape.__super__.constructor.call(this, [new Point([x, y]), new Point([2 + x, y]), new Point([2 + x, 1 + y]), new Point([3 + x, 1 + y]), new Point([3 + x, 2 + y]), new Point([1 + x, 2 + y]), new Point([1 + x, 1 + y]), new Point([x, 1 + y])]);
      this.reorient();
      this.getMarks();
    }

    ZShape.prototype.reorient = function() {};

    ZShape.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      rand = Math.random();
      if (rand < 0.5) {
        this.type = 9;
        return this.addMark([0, 1, 2, 2, 3, 4, 5, 6, 7]);
      } else {
        return this.type = 10;
      }
    };

    ZShape.prototype.clone = function() {
      var result;
      result = new ZShape(0, 0);
      this.copyTo(result);
      return result;
    };

    return ZShape;

  })(Face);

  ZInverted = (function(_super) {
    __extends(ZInverted, _super);

    function ZInverted(x, y) {
      ZInverted.__super__.constructor.call(this, x, y);
    }

    ZInverted.prototype.reorient = function() {
      var extension, x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      this.spin([[x1, y1, z1], [x1, y1 + 10, z1]], Math.PI);
      extension = new Point([this.ar[0].ar[0], this.ar[0].ar[1], this.ar[0].ar[2] + 1]);
      return this.normal = new Normal([this.ar[0], extension]);
    };

    return ZInverted;

  })(ZShape);

  SoftL = (function(_super) {
    __extends(SoftL, _super);

    function SoftL(x, y) {
      SoftL.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 1, y + 3]), new Point([x - 2, y + 3]), new Point([x - 2, y + 2]), new Point([x - 1, 2 + y]), new Point([x, 1 + y])]);
      this.reorient();
      this.getMarks();
    }

    SoftL.prototype.reorient = function() {};

    SoftL.prototype.getMarks = function() {
      var rand, x, y, z;
      this.markVertices = [];
      this.populateMainMarkVertices();
      x = (this.ar[5].ar[0] + this.ar[6].ar[0]) / 2;
      y = (this.ar[5].ar[1] + this.ar[6].ar[1]) / 2;
      z = (this.ar[5].ar[2] + this.ar[6].ar[2]) / 2;
      this.markVertices.push(new Point([x, y, z]));
      rand = Math.random();
      if (rand < 0.25) {
        this.type = 11;
        return this.addMark([0, 1, 2, 7, 6]);
      } else if (rand < 0.5) {
        this.type = 11;
        return this.addMark([2, 3, 4, 5, 7]);
      } else if (rand < 0.75) {
        this.type = 12;
        return this.addMark([0, 1, 2, 2, 3, 4, 5, 6]);
      } else {
        return this.type = 13;
      }
    };

    SoftL.prototype.clone = function() {
      var result;
      result = new SoftL(0, 0);
      this.copyTo(result);
      return result;
    };

    return SoftL;

  })(Face);

  El = (function(_super) {
    __extends(El, _super);

    function El(x, y) {
      El.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 1, y + 2]), new Point([x - 1, y + 2]), new Point([x - 1, y + 1]), new Point([x, y + 1])]);
      this.getMarks();
    }

    El.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      rand = Math.random();
      if (rand < 0.5) {
        this.addMark([0, 1, 1, 2, 3, 4, 5]);
        return this.type = 42;
      } else {
        return this.type = 25;
      }
    };

    El.prototype.clone = function() {
      var result;
      result = new El(0, 0);
      this.copyTo(result);
      return result;
    };

    return El;

  })(Face);

  ElInv = (function(_super) {
    __extends(ElInv, _super);

    function ElInv(x, y) {
      ElInv.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 1, y + 1]), new Point([x + 2, y + 1]), new Point([x + 2, y + 2]), new Point([x, y + 2])]);
      this.getMarks();
    }

    ElInv.prototype.getMarks = function() {
      var rand;
      this.markVertices = [];
      this.populateMainMarkVertices();
      rand = Math.random();
      if (rand < 0.5) {
        this.addMark([0, 1, 1, 2, 3, 4, 5]);
        return this.type = 42;
      } else {
        return this.type = 25;
      }
    };

    ElInv.prototype.clone = function() {
      var result;
      result = new ElInv(0, 0);
      this.copyTo(result);
      return result;
    };

    return ElInv;

  })(Face);

  SoftLInv = (function(_super) {
    __extends(SoftLInv, _super);

    function SoftLInv(x, y) {
      SoftLInv.__super__.constructor.call(this, x, y);
    }

    SoftLInv.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI);
    };

    return SoftLInv;

  })(SoftL);

  SoftLInv2 = (function(_super) {
    __extends(SoftLInv2, _super);

    function SoftLInv2(x, y) {
      SoftLInv2.__super__.constructor.call(this, x, y);
    }

    SoftLInv2.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], -Math.PI / 2);
    };

    return SoftLInv2;

  })(SoftL);

  SoftLSide = (function(_super) {
    __extends(SoftLSide, _super);

    function SoftLSide(x, y) {
      SoftLSide.__super__.constructor.call(this, x, y);
    }

    SoftLSide.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], -Math.PI / 2);
    };

    return SoftLSide;

  })(SoftL);

  HorseShoe = (function(_super) {
    __extends(HorseShoe, _super);

    function HorseShoe(x, y) {
      HorseShoe.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 1, y]), new Point([x + 1, y + 1]), new Point([x + 2, y + 1]), new Point([x + 2, y]), new Point([x + 3, y]), new Point([x + 3, y + 2]), new Point([x, y + 2])]);
      this.reorient();
      this.getMarks();
    }

    HorseShoe.prototype.reorient = function() {};

    HorseShoe.prototype.getMarks = function() {
      var rand, x, y, z;
      this.markVertices = [];
      this.populateMainMarkVertices();
      x = (this.ar[2].ar[0] + this.ar[3].ar[0]) / 2;
      y = (this.ar[2].ar[1] + this.ar[3].ar[1]) / 2;
      z = (this.ar[2].ar[2] + this.ar[3].ar[2]) / 2;
      this.markVertices.push(new Point([x, y, z]));
      x = (this.ar[6].ar[0] + this.ar[7].ar[0]) / 2;
      y = (this.ar[6].ar[1] + this.ar[7].ar[1]) / 2;
      z = (this.ar[6].ar[2] + this.ar[7].ar[2]) / 2;
      this.markVertices.push(new Point([x, y, z]));
      rand = Math.random();
      if (rand < 0.2) {
        this.type = 36;
        return this.addMark([0, 1, 2, 8, 9, 7]);
      } else if (rand < 0.4) {
        this.type = 36;
        return this.addMark([8, 3, 4, 5, 6, 9]);
      } else if (rand < 0.7) {
        this.type = 37;
        return this.addMark([0, 1, 2, 2, 3, 4, 5, 6, 7]);
      } else {
        return this.type = 38;
      }
    };

    HorseShoe.prototype.clone = function() {
      var result;
      result = new HorseShoe(0, 0);
      this.copyTo(result);
      return result;
    };

    return HorseShoe;

  })(Face);

  HorseShoeSideways = (function(_super) {
    __extends(HorseShoeSideways, _super);

    function HorseShoeSideways() {
      return HorseShoeSideways.__super__.constructor.apply(this, arguments);
    }

    HorseShoeSideways.prototype.reorient = function() {
      var x1, y1, z1;
      x1 = this.ar[0].ar[0];
      y1 = this.ar[0].ar[1];
      z1 = this.ar[0].ar[2];
      return this.spin([[x1, y1, z1], [x1, y1, z1 + 10]], Math.PI / 2);
    };

    return HorseShoeSideways;

  })(HorseShoe);

  Coffin = (function(_super) {
    __extends(Coffin, _super);

    function Coffin(x, y) {
      var extension, i, j, z, _i, _j, _k, _ref;
      Coffin.__super__.constructor.call(this, [new Point([x, y]), new Point([x + 2, y]), new Point([x + 4, y]), new Point([x + 6, y]), new Point([x + 8, y]), new Point([x + 10, y]), new Point([x + 12, y]), new Point([x + 12, y + 2])]);
      for (i = _i = 1; _i <= 4; i = ++_i) {
        x = this.ar[i].ar[0];
        y = this.ar[i].ar[1];
        z = this.ar[i].ar[2];
        for (j = _j = 0, _ref = i - 1; 0 <= _ref ? _j <= _ref : _j >= _ref; j = 0 <= _ref ? ++_j : --_j) {
          this.ar[j].spin([[x, y, z], [x, y, z + 10]], -Math.PI * 2 / 6);
        }
      }
      x = this.ar[5].ar[0];
      y = this.ar[5].ar[1];
      z = this.ar[5].ar[2];
      for (j = _k = 0; _k <= 4; j = ++_k) {
        this.ar[j].spin([[x, y, z], [x, y, z + 10]], Math.PI / 6);
      }
      extension = new Point([this.ar[0].ar[0], this.ar[0].ar[1], this.ar[0].ar[2] + 1]);
      this.normal = new Normal([this.ar[0], extension]);
      this.getMarks();
    }

    Coffin.prototype.getMarks = function() {
      var rand, x, y, z;
      this.markVertices = [];
      this.populateMainMarkVertices();
      x = (this.ar[2].ar[0] + this.ar[3].ar[0]) / 2;
      y = (this.ar[2].ar[1] + this.ar[3].ar[1]) / 2;
      z = (this.ar[2].ar[2] + this.ar[3].ar[2]) / 2;
      this.markVertices.push(new Point([x, y, z]));
      x = (this.ar[6].ar[0] + this.ar[7].ar[0]) / 2;
      y = (this.ar[6].ar[1] + this.ar[7].ar[1]) / 2;
      z = (this.ar[6].ar[2] + this.ar[7].ar[2]) / 2;
      this.markVertices.push(new Point([x, y, z]));
      rand = Math.random();
      if (rand < 0.2) {
        this.type = 39;
        return this.addMark([7, 0, 1, 2, 8, 9]);
      } else if (rand < 0.4) {
        this.type = 39;
        return this.addMark([8, 3, 4, 5, 6, 9]);
      } else if (rand < 0.7) {
        this.type = 40;
        return this.addMark([0, 1, 2, 2, 3, 4, 5, 6, 7]);
      } else {
        return this.type = 41;
      }
    };

    Coffin.prototype.clone = function() {
      var result;
      result = new Coffin(0, 0);
      this.copyTo(result);
      return result;
    };

    return Coffin;

  })(Face);

  Pattern = (function(_super) {
    __extends(Pattern, _super);

    function Pattern() {
      this.moveSlide = __bind(this.moveSlide, this);
      var _i, _results;
      Pattern.__super__.constructor.apply(this, arguments);
      this.allowedRotations = (function() {
        _results = [];
        for (_i = 0; _i <= 23; _i++){ _results.push(_i); }
        return _results;
      }).apply(this);
      this.disallowedPairs = [];
      this.folds = [];
      this.currentPace = 10;
      this.stepsExecuted = 0;
      this.choices = [];
      this.correctChoice = randInt(OPTIONS);
      this.answer = "z";
      this.boundingCube = [];
      this.workingCopy = {};
      this.mainOffset = OFFSET;
      this.horizPentAxes = [];
      this.vertPentAxes = [];
      this.vertTriangleAxes = [];
      this.horizTriangleAxes = [];
      this.sliding = false;
      this.slidePosition = 0;
    }

    Pattern.prototype.clone = function() {
      var cln, f, faces, _i, _len, _ref;
      faces = [];
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        f = _ref[_i];
        faces.push(f.clone());
      }
      cln = new Pattern(faces);
      cln.folds = this.folds;
      cln.currentPace = this.currentPace;
      cln.rotation = this.rotation;
      cln.totalSteps = this.totalSteps;
      cln.pov = this.pov;
      cln.boundingCube = this.boundingCube;
      cln.screenBounds = this.screenBounds;
      return cln;
    };

    Pattern.prototype.toArray = function() {
      var c, f, m, p, result, _i, _j, _k, _l, _len, _len1, _len2, _len3, _len4, _len5, _len6, _m, _n, _o, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6;
      result = [];
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        f = _ref[_i];
        _ref1 = f.ar;
        for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
          p = _ref1[_j];
          _ref2 = p.ar;
          for (_k = 0, _len2 = _ref2.length; _k < _len2; _k++) {
            c = _ref2[_k];
            result.push(c);
          }
        }
        _ref3 = f.marks;
        for (_l = 0, _len3 = _ref3.length; _l < _len3; _l++) {
          m = _ref3[_l];
          _ref4 = m.ar;
          for (_m = 0, _len4 = _ref4.length; _m < _len4; _m++) {
            p = _ref4[_m];
            _ref5 = p.ar;
            for (_n = 0, _len5 = _ref5.length; _n < _len5; _n++) {
              c = _ref5[_n];
              result.push(c);
            }
          }
        }
        _ref6 = f.normal.ar[1].ar;
        for (_o = 0, _len6 = _ref6.length; _o < _len6; _o++) {
          c = _ref6[_o];
          result.push(c);
        }
      }
      return result;
    };

    Pattern.prototype.restoreFromArray = function(storage) {
      var c, f, i, m, p, _i, _j, _k, _l, _len, _len1, _len2, _len3, _m, _n, _ref, _ref1, _ref2, _ref3, _results;
      i = 0;
      _ref = this.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        f = _ref[_i];
        _ref1 = f.ar;
        for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
          p = _ref1[_j];
          for (c = _k = 0; _k <= 2; c = ++_k) {
            p.ar[c] = storage[i];
            ++i;
          }
        }
        _ref2 = f.marks;
        for (_l = 0, _len2 = _ref2.length; _l < _len2; _l++) {
          m = _ref2[_l];
          _ref3 = m.ar;
          for (_m = 0, _len3 = _ref3.length; _m < _len3; _m++) {
            p = _ref3[_m];
            for (c = _n = 0; _n <= 2; c = ++_n) {
              p.ar[c] = storage[i];
              ++i;
            }
          }
        }
        _results.push((function() {
          var _o, _results1;
          _results1 = [];
          for (c = _o = 0; _o <= 2; c = ++_o) {
            f.normal.ar[1].ar[c] = storage[i];
            _results1.push(++i);
          }
          return _results1;
        })());
      }
      return _results;
    };

    Pattern.prototype.setPov = function() {
      var l, rand;
      l = this.allowedRotations.length;
      rand = randInt(l);
      this.rotation = this.allowedRotations[rand];
      this.povn = this.getPovn();
      return this.pov = povs[this.povn];
    };

    Pattern.prototype.getPovn = function() {
      var allowed, pair, povn, _i, _len, _ref;
      povn = 0;
      while (true) {
        allowed = true;
        povn = randInt(4);
        _ref = this.disallowedPairs;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          pair = _ref[_i];
          if (pair[0] === this.rotation && pair[1] === povn) {
            allowed = false;
            break;
          }
        }
        if (allowed) {
          break;
        }
      }
      return povn;
    };

    Pattern.prototype.getCorrectAnswer = function() {
      var faces, i, l, result, _i;
      faces = [];
      l = this.workingCopy.ar.length - 1;
      for (i = _i = 0; 0 <= l ? _i <= l : _i >= l; i = 0 <= l ? ++_i : --_i) {
        if (this.workingCopy.ar[i].normal.facing(this.pov)) {
          faces.push(this.workingCopy.ar[i].clone());
          this.workingCopy.ar[i].explication = true;
        }
      }
      result = new Choice(faces);
      result.pov = this.pov;
      return result;
    };

    Pattern.prototype.populateChoices = function() {
      var archive, blank, correctPossibilities, face, i, j, perspectives, storePossibility, _i, _j, _k, _l, _len, _m, _n, _ref, _ref1, _ref2, _ref3, _ref4, _ref5;
      perspectives = [];
      for (i = _i = 0, _ref = OPTIONS - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        perspectives[i] = i !== this.correctChoice ? this.getPovn() : this.povn;
      }
      archive = this.workingCopy.toArray();
      correctPossibilities = [];
      storePossibility = (function(_this) {
        return function(n) {
          var correctAnswer, face, _j, _len, _ref1;
          correctAnswer = [];
          _ref1 = _this.workingCopy.ar;
          for (_j = 0, _len = _ref1.length; _j < _len; _j++) {
            face = _ref1[_j];
            if (face.normal.facing(povs[n])) {
              correctAnswer.push(face.clone());
            }
          }
          return correctPossibilities[n].push(correctAnswer);
        };
      })(this);
      for (i = _j = 0, _ref1 = povs.length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
        correctPossibilities[i] = [];
        if (__indexOf.call(perspectives, i) >= 0) {
          storePossibility(i);
        }
      }
      for (i = _k = 0, _ref2 = this.allowedRotations.length - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; i = 0 <= _ref2 ? ++_k : --_k) {
        if (this.allowedRotations[i] > 0) {
          this.workingCopy.rotate(this.allowedRotations[i], 1);
          this.workingCopy.reposition();
          for (j = _l = 0, _ref3 = povs.length - 1; 0 <= _ref3 ? _l <= _ref3 : _l >= _ref3; j = 0 <= _ref3 ? ++_l : --_l) {
            if (__indexOf.call(perspectives, j) >= 0) {
              storePossibility(j);
            }
          }
          this.workingCopy.restoreFromArray(archive);
        }
      }
      if (this.rotation > 0) {
        this.workingCopy.rotate(this.rotation, 1);
      }
      this.workingCopy.reposition();
      this.choices = [];
      for (i = _m = 0, _ref4 = OPTIONS - 1; 0 <= _ref4 ? _m <= _ref4 : _m >= _ref4; i = 0 <= _ref4 ? ++_m : --_m) {
        if (i === this.correctChoice) {
          this.choices.push(this.getCorrectAnswer());
        } else {
          this.workingCopy.pov = povs[perspectives[i]];
          this.choices.push(this.getFalseCandidate(correctPossibilities[perspectives[i]]));
        }
      }
      blank = true;
      _ref5 = this.choices[this.correctChoice].ar;
      for (_n = 0, _len = _ref5.length; _n < _len; _n++) {
        face = _ref5[_n];
        if (face.marks.length > 0) {
          blank = false;
        }
      }
      if (blank) {
        return this.postInit();
      }
    };

    Pattern.prototype.getTypes = function() {
      var face, i, result, _i, _j, _len, _ref;
      result = [];
      for (i = _i = 0; _i <= 42; i = ++_i) {
        result.push(0);
      }
      _ref = this.ar;
      for (_j = 0, _len = _ref.length; _j < _len; _j++) {
        face = _ref[_j];
        result[face.type] += 1;
      }
      return result;
    };

    Pattern.prototype.print = function(n, cDim, cMargin) {
      var face, scaling, _i, _j, _len, _len1, _ref, _ref1, _results;
      scaling = getScaling(this.screenBounds, cDim, cMargin);
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        face.printBack(n, this.pov, scaling);
      }
      _ref1 = this.ar;
      _results = [];
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        face = _ref1[_j];
        _results.push(face.printFront(n, this.pov, scaling));
      }
      return _results;
    };

    Pattern.prototype.explicate = function(n, cDim, cMargin) {
      var face, scaling, _i, _j, _len, _len1, _ref, _ref1;
      scaling = getScaling(this.screenBounds, cDim, cMargin);
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        face.printBack(n, this.pov, scaling);
      }
      _ref1 = this.ar;
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        face = _ref1[_j];
        if (face.explication) {
          face.accentuate(n, this.pov, scaling);
        } else {
          face.printFront(n, this.pov, scaling);
        }
      }
      if (!this.sliding) {
        return $('#slider').slider("value", this.stepsExecuted);
      }
    };

    Pattern.prototype.printFaceArray = function(n, choice, cDim, cMargin) {
      var bounds, face, scaling, _i, _len, _ref, _results;
      bounds = getBounds(choice.ar, choice.pov);
      scaling = getScaling(bounds, cDim, cMargin);
      _ref = choice.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        _results.push(face.printFront(n, choice.pov, scaling));
      }
      return _results;
    };

    Pattern.prototype.display = function() {
      var i, n, _i, _results;
      if (testing) {
        return this.workingCopy.print(0, DIMENSION0, this.offset);
      } else {
        this.print(0, DIMENSION0, this.mainOffset);
        n = this.choices.length - 1;
        _results = [];
        for (i = _i = 0; 0 <= n ? _i <= n : _i >= n; i = 0 <= n ? ++_i : --_i) {
          _results.push(this.printFaceArray(i + 1, this.choices[i], DIMENSION, this.offset));
        }
        return _results;
      }
    };

    Pattern.prototype.describe = function() {
      var face, _i, _len, _ref, _results;
      _ref = this.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        _results.push(face.describe());
      }
      return _results;
    };

    Pattern.prototype.sanitize = function() {
      var face, _i, _len, _ref, _results;
      _ref = this.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        _results.push(face.sanitize());
      }
      return _results;
    };

    Pattern.prototype.translate = function(x, y, z) {
      var face, _i, _len, _ref, _results;
      _ref = this.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        _results.push(face.translate(x, y, z));
      }
      return _results;
    };

    Pattern.prototype.reposition = function() {
      var face, topX, topY, topZ, tops, _i, _len, _ref;
      topX = 1000;
      topY = 1000;
      topZ = 1000;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        tops = face.getTops();
        if (tops[0] < topX) {
          topX = tops[0];
        }
        if (tops[1] < topY) {
          topY = tops[1];
        }
        if (tops[2] < topZ) {
          topZ = tops[2];
        }
      }
      return this.translate(-topX, -topY, -topZ);
    };

    Pattern.prototype.fold = function() {
      var f, n, _i, _len, _ref, _results;
      _ref = this.folds;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        f = _ref[_i];
        _results.push((function() {
          var _j, _len1, _ref1, _results1;
          _ref1 = f.faces;
          _results1 = [];
          for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
            n = _ref1[_j];
            _results1.push(this.ar[n].spin(f.axis, f.degrees));
          }
          return _results1;
        }).call(this));
      }
      return _results;
    };

    Pattern.prototype.spin = function(axis, rad) {
      var face, _i, _len, _ref, _results;
      _ref = this.ar;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        _results.push(face.spin(axis, rad));
      }
      return _results;
    };

    Pattern.prototype.animate = function(n) {
      var currentFold, dv, f, fc, rad, thisStep, _i, _len, _ref;
      clearCanvas(n);
      this.explicate(n, DIMENSION0, this.mainOffset);
      if (this.stepsExecuted >= this.totalSteps) {
        animDir = false;
      }
      if (this.stepsExecuted <= 0) {
        animDir = true;
      }
      thisStep = this.stepsExecuted;
      thisStep += animDir ? 1 : 0;
      if (thisStep <= (this.currentPace * this.folds.length)) {
        currentFold = Math.floor((thisStep - 1) / this.currentPace);
        f = this.folds[currentFold];
        rad = f.degrees / this.currentPace;
        if (!animDir) {
          rad = -rad;
        }
        _ref = f.faces;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          fc = _ref[_i];
          this.ar[fc].spin(f.axis, rad);
        }
      } else if (thisStep <= this.totalSteps) {
        dv = this.totalSteps - (this.currentPace * this.folds.length);
        if (!animDir) {
          dv = -dv;
        }
        this.rotate(this.rotation, dv);
      }
      this.stepsExecuted += animDir ? 1 : -1;
      if (((this.stepsExecuted >= this.totalSteps) || (this.stepsExecuted <= 0)) && !this.sliding) {
        animPause = setTimeout((function() {
          return animOn = true;
        }), 2000);
        animOn = false;
        animDir = !animDir;
        clearCanvas(n);
        return this.explicate(n, DIMENSION0, this.mainOffset);
      }
    };

    Pattern.prototype.moveSlide = function(event, ui) {
      this.slidePosition = ui.value;
      if (!this.sliding) {
        animOn = false;
        $('#pause').text("Play");
        this.sliding = true;
        return this.slideLoop();
      }
    };

    Pattern.prototype.slideLoop = function() {
      var _results;
      _results = [];
      while (this.sliding) {
        if (this.stepsExecuted === this.slidePosition) {
          _results.push(this.sliding = false);
        } else {
          animDir = this.stepsExecuted < this.slidePosition;
          _results.push(render());
        }
      }
      return _results;
    };

    Pattern.prototype.getFalseCandidate = function(correctPossibilities) {
      var answer, archive, blank, duplicate, endTypes, excessType, face, faces, falseSpot, finalFaces, i, l, result, startTypes, _i, _j, _k, _l, _len, _len1, _len2, _len3, _m, _n, _ref, _ref1, _ref2, _ref3;
      archive = [];
      for (i = _i = 0, _ref = this.workingCopy.ar.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        archive[i] = this.workingCopy.ar[i];
      }
      excessType = -1;
      l = this.workingCopy.ar.length;
      startTypes = this.workingCopy.getTypes();
      while (true) {
        duplicate = false;
        falseSpot = randInt(l);
        if (excessType >= 0) {
          if (this.workingCopy.ar[falseSpot].type !== excessType) {
            continue;
          }
        }
        this.workingCopy.ar[falseSpot] = this.workingCopy.ar[falseSpot].imposter();
        excessType = -1;
        endTypes = this.workingCopy.getTypes();
        for (i = _j = 0, _ref1 = endTypes.length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
          if (endTypes[i] > startTypes[i]) {
            excessType = i;
          }
        }
        if (excessType >= 0) {
          continue;
        }
        faces = [];
        blank = true;
        _ref2 = this.workingCopy.ar;
        for (_k = 0, _len = _ref2.length; _k < _len; _k++) {
          face = _ref2[_k];
          if (!(face.normal.facing(this.workingCopy.pov))) {
            continue;
          }
          faces.push(face);
          if (face.marks.length > 0) {
            blank = false;
          }
        }
        if (blank) {
          continue;
        }
        for (_l = 0, _len1 = correctPossibilities.length; _l < _len1; _l++) {
          answer = correctPossibilities[_l];
          if (compareFaces(answer, faces)) {
            duplicate = true;
          }
        }
        _ref3 = this.choices;
        for (_m = 0, _len2 = _ref3.length; _m < _len2; _m++) {
          answer = _ref3[_m];
          if (compareFaces(answer.ar, faces)) {
            duplicate = true;
          }
        }
        if (duplicate === false) {
          break;
        }
      }
      this.workingCopy.ar = archive;
      finalFaces = [];
      for (_n = 0, _len3 = faces.length; _n < _len3; _n++) {
        face = faces[_n];
        finalFaces.push(face.clone());
      }
      result = new Choice(finalFaces);
      result.pov = this.workingCopy.pov;
      return result;
    };

    Pattern.prototype.getDieFalseCandidate = function(correctPossibilities) {
      var answer, archive, duplicate, face, faces, finalFaces, fs1, fs2, i, result, type1, type2, _i, _j, _k, _l, _len, _len1, _len2, _len3, _m, _ref, _ref1, _ref2;
      archive = [];
      for (i = _i = 0, _ref = this.workingCopy.ar.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        archive[i] = this.workingCopy.ar[i];
      }
      while (true) {
        duplicate = false;
        fs1 = randInt(6);
        fs2 = randInt(6);
        if (fs1 === fs2) {
          continue;
        }
        type1 = this.workingCopy.ar[fs1].type;
        type2 = this.workingCopy.ar[fs2].type;
        this.workingCopy.ar[fs1] = this.workingCopy.ar[fs1].clone();
        this.workingCopy.ar[fs1].marks = [];
        this.workingCopy.ar[fs1].addDieSpots(type2);
        this.workingCopy.ar[fs2] = this.workingCopy.ar[fs2].clone();
        this.workingCopy.ar[fs2].marks = [];
        this.workingCopy.ar[fs2].addDieSpots(type1);
        faces = [];
        _ref1 = this.workingCopy.ar;
        for (_j = 0, _len = _ref1.length; _j < _len; _j++) {
          face = _ref1[_j];
          if (face.normal.facing(this.workingCopy.pov)) {
            faces.push(face);
          }
        }
        for (_k = 0, _len1 = correctPossibilities.length; _k < _len1; _k++) {
          answer = correctPossibilities[_k];
          if (compareFaces(answer, faces)) {
            duplicate = true;
          }
        }
        _ref2 = this.choices;
        for (_l = 0, _len2 = _ref2.length; _l < _len2; _l++) {
          answer = _ref2[_l];
          if (compareFaces(answer.ar, faces)) {
            duplicate = true;
          }
        }
        if (duplicate === false) {
          break;
        }
      }
      this.workingCopy.ar = archive;
      finalFaces = [];
      for (_m = 0, _len3 = faces.length; _m < _len3; _m++) {
        face = faces[_m];
        finalFaces.push(face.clone());
      }
      result = new Choice(finalFaces);
      result.pov = this.workingCopy.pov;
      return result;
    };

    Pattern.prototype.getBoundingCube = function() {
      var corners, deltaX, deltaY, deltaZ, dim, face, spacer, x0, x1, y0, y1, z0, z1, _i, _len, _ref;
      x0 = 1000;
      y0 = 1000;
      z0 = 1000;
      x1 = -1000;
      y1 = -1000;
      z1 = -1000;
      _ref = this.ar;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        face = _ref[_i];
        corners = face.getBoundingCube();
        if (corners[0][0] < x0) {
          x0 = corners[0][0];
        }
        if (corners[0][1] < y0) {
          y0 = corners[0][1];
        }
        if (corners[0][2] < z0) {
          z0 = corners[0][2];
        }
        if (corners[1][0] > x1) {
          x1 = corners[1][0];
        }
        if (corners[1][1] > y1) {
          y1 = corners[1][1];
        }
        if (corners[1][2] > z1) {
          z1 = corners[1][2];
        }
      }
      deltaX = x1 - x0;
      deltaY = y1 - y0;
      deltaZ = z1 - z0;
      dim = deltaX;
      if (deltaY > dim) {
        dim = deltaY;
      }
      if (deltaZ > dim) {
        dim = deltaZ;
      }
      spacer = (dim - deltaX) / 2;
      x0 = x0 - spacer;
      x1 = x1 + spacer;
      spacer = (dim - deltaY) / 2;
      y0 = y0 - spacer;
      y1 = y1 + spacer;
      spacer = (dim - deltaZ) / 2;
      z0 = z0 - spacer;
      z1 = z1 + spacer;
      return [[x0, y0, z0], [x1, y1, z1]];
    };

    Pattern.prototype.getHorizPentAxes = function(key, boundingCube) {
      var axis, end1, end2, endy, endz, i, j, k, result, start, x, _i;
      x = (boundingCube[0][0] + boundingCube[1][0]) / 2;
      result = [];
      for (i = _i = 0; _i <= 4; i = ++_i) {
        axis = [];
        start = this.ar[key].ar[i].ar;
        j = i + 2;
        if (j > 4) {
          j -= 5;
        }
        k = i + 3;
        if (k > 4) {
          k -= 5;
        }
        end1 = this.ar[key].ar[j].ar;
        end2 = this.ar[key].ar[k].ar;
        endy = (end1[1] + end2[1]) / 2;
        endz = (end1[2] + end2[2]) / 2;
        axis.push([x, start[1], start[2]]);
        axis.push([x, endy, endz]);
        result.push(axis);
      }
      return result;
    };

    Pattern.prototype.getVertPentAxes = function(key, boundingCube) {
      var axis, end1, end2, endx, endz, i, j, k, result, start, y, _i;
      y = (boundingCube[0][1] + boundingCube[1][1]) / 2;
      result = [];
      for (i = _i = 0; _i <= 4; i = ++_i) {
        axis = [];
        start = this.ar[key].ar[i].ar;
        j = i + 2;
        if (j > 4) {
          j -= 5;
        }
        k = i + 3;
        if (k > 4) {
          k -= 5;
        }
        end1 = this.ar[key].ar[j].ar;
        end2 = this.ar[key].ar[k].ar;
        endx = (end1[0] + end2[0]) / 2;
        endz = (end1[2] + end2[2]) / 2;
        axis.push([start[0], y, start[2]]);
        axis.push([endx, y, endz]);
        result.push(axis);
      }
      return result;
    };

    Pattern.prototype.getVertTriangleAxes = function(key, boundingCube) {
      var axis, end1, end2, endx, endz, i, j, k, result, start, y, _i;
      y = (boundingCube[0][1] + boundingCube[1][1]) / 2;
      result = [];
      for (i = _i = 0; _i <= 2; i = ++_i) {
        axis = [];
        start = this.ar[key].ar[i].ar;
        j = i + 1;
        if (j > 2) {
          j -= 3;
        }
        k = i + 2;
        if (k > 2) {
          k -= 3;
        }
        end1 = this.ar[key].ar[j].ar;
        end2 = this.ar[key].ar[k].ar;
        endx = (end1[0] + end2[0]) / 2;
        endz = (end1[2] + end2[2]) / 2;
        axis.push([start[0], y, start[2]]);
        axis.push([endx, y, endz]);
        result.push(axis);
      }
      return result;
    };

    Pattern.prototype.getHorizTriangleAxes = function(key, boundingCube) {
      var axis, end1, end2, endy, endz, i, j, k, result, start, x, _i;
      x = (boundingCube[0][0] + boundingCube[1][0]) / 2;
      result = [];
      for (i = _i = 0; _i <= 2; i = ++_i) {
        axis = [];
        start = this.ar[key].ar[i].ar;
        j = i + 1;
        if (j > 2) {
          j -= 3;
        }
        k = i + 2;
        if (k > 2) {
          k -= 3;
        }
        end1 = this.ar[key].ar[j].ar;
        end2 = this.ar[key].ar[k].ar;
        endy = (end1[1] + end2[1]) / 2;
        endz = (end1[2] + end2[2]) / 2;
        axis.push([x, start[1], start[2]]);
        axis.push([x, endy, endz]);
        result.push(axis);
      }
      return result;
    };

    Pattern.prototype.getRadians = function(n) {
      var m;
      m = n - 1;
      switch (m) {
        case -1:
          return 0;
        case 0:
          return Math.PI / 2;
        case 1:
          return Math.PI;
        case 2:
          return -Math.PI / 2;
        case 3:
          return Math.PI / 2;
        case 4:
          return Math.PI;
        case 5:
          return -Math.PI / 2;
        case 6:
          return Math.PI / 2;
        case 7:
          return Math.PI;
        case 8:
          return -Math.PI / 2;
        case 9:
          return Math.PI;
        case 10:
          return Math.PI;
        case 11:
          return Math.PI;
        case 12:
          return Math.PI;
        case 13:
          return Math.PI;
        case 14:
          return Math.PI;
        case 15:
          return Math.PI * 2 / 3;
        case 16:
          return -Math.PI * 2 / 3;
        case 17:
          return Math.PI * 2 / 3;
        case 18:
          return -Math.PI * 2 / 3;
        case 19:
          return Math.PI * 2 / 3;
        case 20:
          return -Math.PI * 2 / 3;
        case 21:
          return Math.PI * 2 / 3;
        case 22:
          return -Math.PI * 2 / 3;
        case 23:
          return Math.PI * 2 / 5;
        case 24:
          return Math.PI * 4 / 5;
        case 25:
          return -Math.PI * 2 / 5;
        case 26:
          return -Math.PI * 4 / 5;
        case 27:
          return Math.PI / 3;
        case 28:
          return Math.PI * 2 / 3;
        case 29:
          return -Math.PI / 3;
        case 30:
          return -Math.PI * 2 / 3;
        case 31:
          return Math.PI / 3;
        case 32:
          return -Math.PI / 3;
        case 33:
          return Math.PI;
        case 34:
          return Math.PI;
        case 35:
          return Math.PI;
        case 36:
          return Math.PI;
        case 37:
          return Math.PI;
        case 38:
          return Math.PI * 2 / 5;
        case 39:
          return Math.PI * 4 / 5;
        case 40:
          return -Math.PI * 2 / 5;
        case 41:
          return -Math.PI * 4 / 5;
        case 42:
          return Math.PI;
        case 43:
          return Math.PI;
        case 44:
          return Math.PI;
        case 45:
          return Math.PI;
        case 46:
          return Math.PI;
        case 47:
          return Math.PI;
        case 48:
          return Math.PI;
        case 49:
          return Math.PI;
        case 50:
          return Math.PI;
        case 51:
          return 2 * Math.PI / 3;
        case 52:
          return -2 * Math.PI / 3;
        case 53:
          return Math.PI;
        case 54:
          return Math.PI;
        case 55:
          return Math.PI;
        case 56:
          return Math.PI;
        case 57:
          return Math.PI;
        case 58:
          return Math.PI;
        case 59:
          return 2 * Math.PI / 3;
        case 60:
          return -2 * Math.PI / 3;
      }
    };

    Pattern.prototype.rotate = function(n, dv) {
      var m, rad, x0, x1, x5, y0, y1, y5, z0, z1, z5;
      rad = this.getRadians(n);
      x0 = this.boundingCube[0][0];
      y0 = this.boundingCube[0][1];
      z0 = this.boundingCube[0][2];
      x1 = this.boundingCube[1][0];
      y1 = this.boundingCube[1][1];
      z1 = this.boundingCube[1][2];
      x5 = (x1 + x0) / 2;
      y5 = (y1 + y0) / 2;
      z5 = (z1 + z0) / 2;
      m = n - 1;
      switch (m) {
        case 0:
          return this.spin([[x5, y5, z0], [x5, y5, z1]], rad / dv);
        case 1:
          return this.spin([[x5, y5, z0], [x5, y5, z1]], rad / dv);
        case 2:
          return this.spin([[x5, y5, z0], [x5, y5, z1]], rad / dv);
        case 3:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 4:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 5:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 6:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 7:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 8:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 9:
          return this.spin([[x0, y5, z0], [x1, y5, z1]], rad / dv);
        case 10:
          return this.spin([[x0, y5, z1], [x1, y5, z0]], rad / dv);
        case 11:
          return this.spin([[x5, y0, z0], [x5, y1, z1]], rad / dv);
        case 12:
          return this.spin([[x5, y0, z1], [x5, y1, z0]], rad / dv);
        case 13:
          return this.spin([[x0, y0, z5], [x1, y1, z5]], rad / dv);
        case 14:
          return this.spin([[x0, y1, z5], [x1, y0, z5]], rad / dv);
        case 15:
          return this.spin([[x0, y0, z0], [x1, y1, z1]], rad / dv);
        case 16:
          return this.spin([[x0, y0, z0], [x1, y1, z1]], rad / dv);
        case 17:
          return this.spin([[x1, y0, z0], [x0, y1, z1]], rad / dv);
        case 18:
          return this.spin([[x1, y0, z0], [x0, y1, z1]], rad / dv);
        case 19:
          return this.spin([[x0, y0, z1], [x1, y1, z0]], rad / dv);
        case 20:
          return this.spin([[x0, y0, z1], [x1, y1, z0]], rad / dv);
        case 21:
          return this.spin([[x1, y0, z1], [x0, y1, z0]], rad / dv);
        case 22:
          return this.spin([[x1, y0, z1], [x0, y1, z0]], rad / dv);
        case 23:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 24:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 25:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 26:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 27:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 28:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 29:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 30:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 31:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 32:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 33:
          return this.spin(this.horizPentAxes[0], rad / dv);
        case 34:
          return this.spin(this.horizPentAxes[1], rad / dv);
        case 35:
          return this.spin(this.horizPentAxes[2], rad / dv);
        case 36:
          return this.spin(this.horizPentAxes[3], rad / dv);
        case 37:
          return this.spin(this.horizPentAxes[4], rad / dv);
        case 38:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 39:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 40:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 41:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 42:
          return this.spin(this.vertPentAxes[0], rad / dv);
        case 43:
          return this.spin(this.vertPentAxes[1], rad / dv);
        case 44:
          return this.spin(this.vertPentAxes[2], rad / dv);
        case 45:
          return this.spin(this.vertPentAxes[3], rad / dv);
        case 46:
          return this.spin(this.vertPentAxes[4], rad / dv);
        case 47:
          return this.spin([[x5, y5, z5], [x5 + 1, y5, z5 + SQ3]], rad / dv);
        case 48:
          return this.spin([[x5, y5, z5], [x5 + SQ3, y5, z5 + 1]], rad / dv);
        case 49:
          return this.spin([[x5, y5, z5], [x5 - 1, y5, z5 + SQ3]], rad / dv);
        case 50:
          return this.spin([[x5, y5, z5], [x5 - SQ3, y5, z5 + 1]], rad / dv);
        case 51:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 52:
          return this.spin([[x5, y0, z5], [x5, y1, z5]], rad / dv);
        case 53:
          return this.spin(this.vertTriangleAxes[0], rad / dv);
        case 54:
          return this.spin(this.vertTriangleAxes[1], rad / dv);
        case 55:
          return this.spin(this.vertTriangleAxes[2], rad / dv);
        case 56:
          return this.spin(this.horizTriangleAxes[0], rad / dv);
        case 57:
          return this.spin(this.horizTriangleAxes[1], rad / dv);
        case 58:
          return this.spin(this.horizTriangleAxes[2], rad / dv);
        case 59:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
        case 60:
          return this.spin([[x0, y5, z5], [x1, y5, z5]], rad / dv);
      }
    };

    Pattern.prototype.postInit = function() {
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      if (!testing) {
        return this.populateChoices();
      }
    };

    return Pattern;

  })(Queue);

  Structure1 = (function(_super) {
    __extends(Structure1, _super);

    function Structure1() {
      var faces, square, total, type1, _i, _len;
      faces = [];
      while (true) {
        faces = [new Square(2, 0), new Square(0, 1), new Square(1, 1), new Square(2, 1), new Square(1, 2), new Square(1, 3)];
        total = 0;
        type1 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          square = faces[_i];
          total += square.marks.length;
          if (square.type === 1) {
            type1 += 1;
          }
        }
        if (total > 1 && type1 < 2) {
          break;
        }
      }
      Structure1.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 42;
      this.folds = [];
      this.folds.push(new Fold([0], -(Math.PI / 2), [[0, 1, 0], [10, 1, 0]]));
      this.folds.push(new Fold([5], Math.PI / 2, [[0, 3, 0], [10, 3, 0]]));
      this.folds.push(new Fold([4, 5], Math.PI / 2, [[0, 2, 0], [10, 2, 0]]));
      this.folds.push(new Fold([1], Math.PI / 2, [[1, 0, 0], [1, 10, 0]]));
      this.folds.push(new Fold([0, 3], -(Math.PI / 2), [[2, 0, 0], [2, 10, 0]]));
      this.postInit();
    }

    return Structure1;

  })(Pattern);

  Structure2 = (function(_super) {
    __extends(Structure2, _super);

    function Structure2() {
      var faces, shape, total, _i, _len;
      faces = [];
      while (true) {
        faces = [new BlankSquare(0, 4), new BlankSquare(1, 4), new BlankSquare(4, 6), new BlankSquare(4, 7), new VertRect(0, 0), new Square(0, 2), new ZShape(1, 2), new HorizRect(2, 4), new ZInverted(4, 5), new Square(4, 5)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
      }
      Structure2.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(new Fold([4, 5, 6], -Math.PI / 2, [[0, 4, 0], [10, 4, 0]]));
      this.folds.push(new Fold([2, 3, 8, 9], Math.PI / 2, [[0, 5, 0], [10, 5, 0]]));
      this.folds.push(new Fold([9, 2, 3], -Math.PI / 2, [[4, 5, 0], [4, 5, 10]]));
      this.folds.push(new Fold([2, 3], -Math.PI / 2, [[4, 0, 1], [4, 10, 1]]));
      this.folds.push(new Fold([3], Math.PI / 2, [[3, 0, 1], [3, 10, 1]]));
      this.folds.push(new Fold([0, 1], Math.PI / 2, [[2, 0, 0], [2, 10, 0]]));
      this.folds.push(new Fold([0], -Math.PI / 2, [[2, 0, 1], [2, 10, 1]]));
      this.folds.push(new Fold([4, 5], -Math.PI / 2, [[1, 4, 0], [1, 4, 10]]));
      this.folds.push(new Fold([4], Math.PI / 2, [[1, 0, 2], [1, 10, 2]]));
      this.postInit();
    }

    return Structure2;

  })(Pattern);

  Structure3 = (function(_super) {
    __extends(Structure3, _super);

    function Structure3() {
      var faces, shape, total, _i, _len;
      faces = [];
      while (true) {
        faces = [new BlankSquare(4, 7), new BlankSquare(6, 5), new DiagonalRect(6, 6), new SoftL(2, 0), new HorizRectThree(0, 3), new HorizRectThree(3, 3), new SoftLInv(4, 7), new Square(6, 4), new Square(3, 7)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
      }
      Structure3.__super__.constructor.call(this, faces);
      this.disallowedPairs = [[4, 3], [6, 0], [12, 1], [13, 2], [16, 2], [19, 0], [21, 3], [22, 1]];
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(0, [0], 2, [4]));
      this.folds.push(new Fold([0, 8], -Math.PI / 2, [[0, 7, 0], [10, 7, 0]]));
      this.folds.push(new Fold([1, 2, 7], Math.PI / 2, [[6, 0, 0], [6, 10, 0]]));
      this.folds.push(new Fold([1, 2], Math.PI / 2, [[6, 5, 0], [6, 5, 10]]));
      this.folds.push(new Fold([2], -Math.PI / 4, [[5, 5, 0], [5, 5, 10]]));
      this.folds.push(new Fold([3, 4], -Math.PI / 2, [[3, 0, 0], [3, 10, 0]]));
      this.folds.push(new Fold([3], Math.PI / 2, [[3, 3, 0], [3, 3, 10]]));
      this.folds.push(new Fold([0, 1, 2, 6, 7, 8], -Math.PI / 2, [[0, 4, 0], [10, 4, 0]]));
      this.postInit();
    }

    return Structure3;

  })(Pattern);

  Structure4 = (function(_super) {
    __extends(Structure4, _super);

    function Structure4() {
      var faces, shape, total, _i, _len;
      faces = [];
      while (true) {
        faces = [new Triangle(2, 0), new Triangle(6, 0), new HorizRectFour(0, 2), new HorizRectFour(4, 2), new FourSquare(4, 3), new VertRectFour(8, 3), new TriangleSideways(11, 5), new VertRectFour(8, 7), new TriangleSideways(11, 9)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
      }
      Structure4.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [0, 1], 2, [2]));
      this.folds.push(folder(0, [0, 2], -2, [4]));
      this.folds.push(folder(1, [0, 1, 2, 3], 2, [3]));
      this.folds.push(folder(0, [6, 8], 2, [9]));
      this.folds.push(folder(1, [7, 8], -2, [7]));
      this.folds.push(folder(0, [5, 6, 7, 8], 2, [8]));
      this.postInit();
    }

    return Structure4;

  })(Pattern);

  Structure5 = (function(_super) {
    __extends(Structure5, _super);

    function Structure5() {
      var faces, n, shape, total, x, x0, x1, y, y0, y1, z, _i, _j, _len;
      faces = [];
      while (true) {
        faces = [new Pentagon(1, 2), new Square(0, 2), new Square(1, 2), new PentagonInv(2, 3), new Square(2, 3), new Square(2, 4), new Square(2, 5)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
        if (testing) {
          break;
        }
      }
      Structure5.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 24, 25, 26, 27, 43, 44, 45, 46, 47];
      for (n = _j = 4; _j <= 6; n = ++_j) {
        this.ar[n].preSpin([[2, 3, 0], [2, 3, 10]], -Math.PI / 10);
      }
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      x0 = this.ar[3].ar[0].ar[0];
      y0 = this.ar[3].ar[0].ar[1];
      z = this.ar[3].ar[0].ar[2];
      x1 = this.ar[3].ar[4].ar[0];
      y1 = this.ar[3].ar[4].ar[1];
      this.folds.push(new Fold([4, 5, 6], Math.PI / 2, [[x0, y0, z], [x1, y1, z]]));
      x = this.ar[3].ar[4].ar[0];
      y = this.ar[3].ar[4].ar[1];
      this.folds.push(folder(2, [5, 6], 5 / 2, [x, y]));
      x = this.ar[3].ar[3].ar[0];
      y = this.ar[3].ar[3].ar[1];
      this.folds.push(folder(2, [6], 5 / 2, [x, y]));
      this.folds.push(folder(1, [3, 4, 5, 6], -2, [3]));
      this.folds.push(folder(1, [0], 2, [2]));
      this.folds.push(folder(0, [1], -2.5, [1]));
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      this.vertPentAxes = this.workingCopy.getVertPentAxes(0, this.boundingCube);
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      this.workingCopy.vertPentAxes = this.workingCopy.getVertPentAxes(0, this.workingCopy.boundingCube);
      if (!testing) {
        this.populateChoices();
      }
    }

    return Structure5;

  })(Pattern);

  Structure6 = (function(_super) {
    __extends(Structure6, _super);

    function Structure6() {
      var faces, shape, total, type1, _i, _len;
      faces = [];
      while (true) {
        faces = [new Square(0, 0), new Square(1, 0), new Square(1, 1), new Square(1, 2), new Square(2, 2), new Square(1, 3)];
        total = 0;
        type1 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
          if (shape.type === 1) {
            type1 += 1;
          }
        }
        if (total > 1 && type1 < 2) {
          break;
        }
        if (testing) {
          break;
        }
      }
      Structure6.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [5], -2, [3]));
      this.folds.push(folder(0, [4], 2, [2]));
      this.folds.push(folder(1, [3, 4, 5], -2, [2]));
      this.folds.push(folder(0, [0], -2, [1]));
      this.folds.push(folder(1, [0, 1], 2, [1]));
      this.postInit();
    }

    return Structure6;

  })(Pattern);

  Structure7 = (function(_super) {
    __extends(Structure7, _super);

    function Structure7() {
      var faces, i, order, _i;
      faces = [new DieFace(2, 0), new DieFace(1, 1), new DieFace(2, 1), new DieFace(0, 2), new DieFace(1, 2), new DieFace(1, 3)];
      Structure7.__super__.constructor.call(this, faces);
      order = shuffle([1, 2, 3, 4, 5, 6]);
      for (i = _i = 0; _i <= 5; i = ++_i) {
        this.ar[i].addDieSpots(order[i]);
      }
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [5], -2, [3]));
      this.folds.push(folder(0, [3], -2, [1]));
      this.folds.push(folder(1, [3, 4, 5], -2, [2]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(0, [0, 2], 2, [2]));
      this.postInit();
    }

    Structure7.prototype.getFalseCandidate = function(correctPossibilities) {
      return this.getDieFalseCandidate(correctPossibilities);
    };

    return Structure7;

  })(Pattern);

  Structure8 = (function(_super) {
    __extends(Structure8, _super);

    function Structure8() {
      var faces, shape, total, _i, _len;
      faces = [];
      while (true) {
        faces = [new BlankTriangle(5, 0), new HorizRectFour(3, 2), new BlankTriangleInv3(0, 5), new VertRectFour(2, 3), new BlankTriangleInv2(5, 5), new BlankTriangleInv3(5, 5), new BlankTriangleInv1(5, 5), new BlankTriangle(5, 5), new VertRectFour(7, 3), new BlankTriangleInv1(10, 5), new HorizRectFour(3, 7), new BlankTriangleInv2(5, 10)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
        if (testing) {
          break;
        }
      }
      Structure8.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [0], 2, [2]));
      this.folds.push(folder(1, [0, 1], 2, [3]));
      this.folds.push(folder(0, [2], -2, [2]));
      this.folds.push(folder(0, [2, 3], -2, [3]));
      this.folds.push(folder(1, [11], -2, [8]));
      this.folds.push(folder(1, [10, 11], -2, [7]));
      this.folds.push(folder(0, [9], 2, [8]));
      this.folds.push(folder(0, [8, 9], 2, [7]));
      this.postInit();
    }

    return Structure8;

  })(Pattern);

  Structure9 = (function(_super) {
    __extends(Structure9, _super);

    function Structure9() {
      var faces, i, shape, total, x, y, _i, _j, _k, _len;
      faces = [];
      while (true) {
        faces = [new HexSection(7, 1), new HexSection2(7, 1), new HexSection3(7, 1), new HexSection4(7, 1), new HexSection5(7, 1), new HexSection6(7, 1), new BlankSquare(1, 2), new BlankSquare(2, 2), new BlankSquare(3, 2), new BlankSquare(4, 2), new BlankSquare(5, 2), new BlankSquare(6, 2), new HexSection(1, 4), new HexSection2(1, 4), new HexSection3(1, 4), new HexSection4(1, 4), new HexSection5(1, 4), new HexSection6(1, 4)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
        if (testing) {
          break;
        }
      }
      Structure9.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 2, 5, 8, 28, 29, 30, 31, 48, 49, 50, 51];
      x = this.ar[11].ar[1].ar[0] - this.ar[1].ar[1].ar[0];
      y = this.ar[11].ar[1].ar[1] - this.ar[1].ar[1].ar[1];
      for (i = _j = 0; _j <= 5; i = ++_j) {
        this.ar[i].preTranslate(x, y, 0);
      }
      x = this.ar[12].ar[2].ar[0] - this.ar[6].ar[3].ar[0];
      y = this.ar[6].ar[3].ar[1] - this.ar[12].ar[2].ar[1];
      for (i = _k = 12; _k <= 17; i = ++_k) {
        this.ar[i].preTranslate(x, y, 0);
      }
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [12, 13, 14, 15, 16, 17], -2, [3]));
      this.folds.push(folder(0, [6, 12, 13, 14, 15, 16, 17], -3, [2]));
      this.folds.push(folder(0, [6, 7, 12, 13, 14, 15, 16, 17], -3, [3]));
      this.folds.push(folder(0, [6, 7, 8, 12, 13, 14, 15, 16, 17], -3, [4]));
      this.folds.push(folder(1, [0, 1, 2, 3, 4, 5], 2, [2]));
      this.folds.push(folder(0, [0, 1, 2, 3, 4, 5, 11], 3, [6]));
      this.folds.push(folder(0, [0, 1, 2, 3, 4, 5, 10, 11], 3, [5]));
      this.postInit();
    }

    return Structure9;

  })(Pattern);

  Structure10 = (function(_super) {
    __extends(Structure10, _super);

    function Structure10() {
      var faces, shape, type15, type23, x0, x1, y0, y1, z, _i, _len;
      faces = [];
      while (true) {
        faces = [new HexSection8(2, 2), new StripedSquare(2, 1), new HexSection7(3, 1), new StripedSquare(1, 2), new StripedSquare(3, 0)];
        if (testing) {
          break;
        }
        type15 = false;
        type23 = false;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 15) {
            type15 = true;
          }
          if (shape.type === 23) {
            type23 = true;
          }
        }
        if (type15 && type23) {
          break;
        }
      }
      Structure10.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 57, 58, 59, 60, 61];
      this.ar[3].preSpin([[2, 2, 0], [2, 2, 10]], Math.PI / 6);
      this.ar[4].preSpin([[3, 1, 0], [3, 1, 10]], Math.PI / 6);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      x0 = this.ar[0].ar[2].ar[0];
      y0 = this.ar[0].ar[2].ar[1];
      z = this.ar[0].ar[2].ar[2];
      x1 = this.ar[0].ar[0].ar[0];
      y1 = this.ar[0].ar[0].ar[1];
      this.folds.push(new Fold([3], Math.PI / 2, [[x0, y0, z], [x1, y1, z]]));
      x0 = this.ar[2].ar[0].ar[0];
      y0 = this.ar[2].ar[0].ar[1];
      z = this.ar[2].ar[0].ar[2];
      x1 = this.ar[2].ar[2].ar[0];
      y1 = this.ar[2].ar[2].ar[1];
      this.folds.push(new Fold([4], -Math.PI / 2, [[x0, y0, z], [x1, y1, z]]));
      this.folds.push(folder(0, [0, 3], 2, [2]));
      this.folds.push(folder(0, [2, 4], -2, [3]));
      this.folds.push(new Fold([0, 1, 2, 3, 4], Math.PI / 3, [[0, 1.5, 0.288675], [10, 1.5, 0.288675]]));
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      this.horizTriangleAxes = this.workingCopy.getHorizTriangleAxes(0, this.boundingCube);
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      this.workingCopy.horizTriangleAxes = this.workingCopy.getHorizTriangleAxes(0, this.workingCopy.boundingCube);
      if (!testing) {
        this.populateChoices();
      }
    }

    return Structure10;

  })(Pattern);

  Structure11 = (function(_super) {
    __extends(Structure11, _super);

    function Structure11() {
      var faces, shape, type24, _i, _len;
      faces = [];
      while (true) {
        faces = [new SquareDiamond(0, 0), new SquareDiamond(0, 1), new SquareDiamond(1, 1), new SquareDiamond(1, 2), new SquareDiamond(2, 2), new SquareDiamond(2, 3)];
        if (testing) {
          break;
        }
        type24 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 24) {
            ++type24;
          }
        }
        if (type24 === 2) {
          break;
        }
      }
      Structure11.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [5], -2, [3]));
      this.folds.push(folder(0, [4, 5], 2, [2]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(0, [0, 1], -2, [1]));
      this.folds.push(folder(1, [0, 1, 2], 2, [2]));
      this.postInit();
    }

    return Structure11;

  })(Pattern);

  Structure12 = (function(_super) {
    __extends(Structure12, _super);

    function Structure12() {
      var faces, shape, type2, type6, _i, _len;
      faces = [];
      while (true) {
        faces = [new HorizRect(4, 0), new HorizRect(0, 2), new HorizRect(4, 1), new HorizRect(0, 3), new El(3, 2), new SquareFor12(4, 2), new ElInv(6, 2), new SquareFor12(2, 4)];
        if (testing) {
          break;
        }
        type6 = 0;
        type2 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 5 || shape.type === 6) {
            ++type6;
          }
          if (shape.type === 2) {
            ++type2;
          }
        }
        if (type2 > 0 && type6 > 0) {
          break;
        }
      }
      Structure12.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 14];
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(0, [6], 2, [6]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(1, [0, 2], 2, [2]));
      this.folds.push(folder(0, [0, 2, 5, 6], 2, [4]));
      this.folds.push(folder(1, [7], -2, [4]));
      this.folds.push(folder(1, [1], 2, [3]));
      this.folds.push(folder(0, [1, 3], -2, [2]));
      this.postInit();
    }

    return Structure12;

  })(Pattern);

  Structure13 = (function(_super) {
    __extends(Structure13, _super);

    function Structure13() {
      var DIV1, DIV2, DIV3, faces, ga, i, shape, type0, type16, type17, type28, type29, type7, x, y, _i, _j, _k, _l, _len, _len1, _ref;
      DIV1 = 4.13916062725;
      DIV2 = 0.92729522;
      DIV3 = 3.565368245885;
      faces = [];
      while (true) {
        faces = [new TwoPentagon(4, 4), new InnerRect(5, 0), new InnerTriangleInv(7, 0), new InnerRect(0, 4), new InnerRect(2, 4), new InnerRect(4, 4), new InnerRect(6, 4), new InnerTriangle(0, 5), new InnerTriangle(2, 5), new InnerTriangle(4, 5), new InnerTriangle(6, 5)];
        if (testing) {
          break;
        }
        type0 = 0;
        type7 = 0;
        type16 = 0;
        type17 = 0;
        type28 = 0;
        type29 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 0) {
            ++type0;
          }
          if (shape.type === 7) {
            ++type7;
          }
          if (shape.type === 16) {
            ++type16;
          }
          if (shape.type === 17) {
            ++type17;
          }
          if (shape.type === 28) {
            ++type28;
          }
          if (shape.type === 29) {
            ++type29;
          }
        }
        if (type28 > 0 && type7 > 0 && type0 > 0 && type29 > 0 && type17 > 0 && type16 > 0) {
          break;
        }
      }
      Structure13.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 24, 25, 26, 27];
      this.disallowedPairs = [[0, 0], [0, 1], [24, 0], [24, 1], [25, 0], [25, 1], [26, 0], [26, 1], [27, 0], [27, 1]];
      this.mainOffset = OFFSET - 5;
      this.currentPace = 30;
      this.offset = 20;
      ga = (function(_this) {
        return function(f0, p0, f1, p1) {
          var axis1, axis2, coord, result, _j, _k, _len1, _len2, _ref, _ref1;
          result = [];
          axis1 = [];
          axis2 = [];
          _ref = _this.ar[f0].ar[p0].ar;
          for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
            coord = _ref[_j];
            axis1.push(coord);
          }
          _ref1 = _this.ar[f1].ar[p1].ar;
          for (_k = 0, _len2 = _ref1.length; _k < _len2; _k++) {
            coord = _ref1[_k];
            axis2.push(coord);
          }
          result.push(axis1);
          result.push(axis2);
          return result;
        };
      })(this);
      x = this.ar[0].ar[3].ar[0] - this.ar[1].ar[3].ar[0];
      y = this.ar[0].ar[3].ar[1] - this.ar[1].ar[3].ar[1];
      for (i = _j = 1; _j <= 2; i = ++_j) {
        this.ar[i].preTranslate(x, y, 0);
      }
      x = this.ar[0].ar[3].ar[0];
      y = this.ar[0].ar[3].ar[1];
      for (i = _k = 1; _k <= 2; i = ++_k) {
        this.ar[i].preSpin([[x, y, 0], [x, y, 10]], Math.PI / 5);
      }
      x = this.ar[9].ar[0].ar[0];
      y = this.ar[9].ar[0].ar[1];
      _ref = [3, 4, 7, 8];
      for (_l = 0, _len1 = _ref.length; _l < _len1; _l++) {
        i = _ref[_l];
        this.ar[i].preSpin([[x, y, 0], [x, y, 10]], -DIV2);
      }
      this.folds = [];
      this.folds.push(new Fold([7], Math.PI / DIV1, ga(3, 2, 3, 3)));
      this.folds.push(new Fold([3, 7], -(2 * Math.PI / 5), ga(3, 1, 3, 2)));
      this.folds.push(new Fold([3, 4, 7], -(Math.PI / DIV1), ga(4, 2, 4, 3)));
      this.folds.push(new Fold([3, 4, 7, 8], -(Math.PI / DIV3), ga(9, 0, 9, 2)));
      this.folds.push(folder(1, [3, 4, 7, 8, 9, 10], -DIV1, [5]));
      this.folds.push(folder(0, [6, 10], 5 / 2, [6]));
      this.folds.push(new Fold([2], -(Math.PI / DIV1), ga(2, 0, 2, 1)));
      this.folds.push(new Fold([1, 2], -(Math.PI / 2), ga(1, 2, 1, 3)));
      this.folds.push(folder(1, [0, 1, 2], 2, [4]));
      this.postInit();
    }

    return Structure13;

  })(Pattern);

  Structure14 = (function(_super) {
    __extends(Structure14, _super);

    function Structure14() {
      var faces, square, total, type1, _i, _len;
      faces = [];
      while (true) {
        faces = [new Square(0, 0), new Square(0, 1), new Square(1, 1), new Square(2, 1), new Square(3, 1), new Square(3, 2)];
        total = 0;
        type1 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          square = faces[_i];
          total += square.marks.length;
          if (square.type === 1) {
            type1 += 1;
          }
        }
        if (total > 1 && type1 < 2) {
          break;
        }
      }
      Structure14.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 42;
      this.folds = [];
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(0, [0, 1], -2, [1]));
      this.folds.push(folder(0, [0, 1, 2], -2, [2]));
      this.folds.push(folder(1, [5], -2, [2]));
      this.folds.push(folder(0, [4, 5], 2, [3]));
      this.postInit();
    }

    return Structure14;

  })(Pattern);

  Structure15 = (function(_super) {
    __extends(Structure15, _super);

    function Structure15() {
      var drop, faces, shape, total, _i, _len;
      drop = 2 - Math.sqrt(2);
      faces = [];
      while (true) {
        faces = [new DiagonalRect(0, drop), new BlankSquare(0, 2), new BlankSquare(2, 8), new Square(0, 3), new SoftL(3, 1), new HorizRectThree(1, 4), new SoftLSide(1, 6), new VertRectThree(4, 5), new Square(3, 8)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
      }
      Structure15.__super__.constructor.call(this, faces);
      this.disallowedPairs = [[0, 2], [1, 1], [2, 0], [3, 3], [5, 1], [8, 3], [14, 0], [15, 2]];
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [0], -4, [2]));
      this.folds.push(folder(1, [0, 1], 2, [3]));
      this.folds.push(folder(0, [0, 1, 3], -2, [1]));
      this.folds.push(folder(1, [0, 1, 3, 4], 2, [4]));
      this.folds.push(folder(1, [0, 1, 3, 4, 5], 2, [5]));
      this.folds.push(folder(0, [2], -2, [3]));
      this.folds.push(folder(1, [2, 8], -2, [8]));
      this.folds.push(folder(0, [7], 2, [4]));
      this.postInit();
    }

    return Structure15;

  })(Pattern);

  Structure16 = (function(_super) {
    __extends(Structure16, _super);

    function Structure16() {
      var faces, shape, type0, type15, _i, _len;
      faces = [];
      while (true) {
        faces = [new HexSection8(1, 1), new Square(1, 0), new Square(1, 1), new Square(1, 2), new HexSection7(2, 2)];
        if (testing) {
          break;
        }
        type15 = false;
        type0 = false;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 15) {
            type15 = true;
          }
          if (shape.type === 2) {
            type0 = true;
          }
          if (shape.type === 1) {
            type0 = true;
          }
        }
        if (type15 && type0) {
          break;
        }
      }
      Structure16.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 57, 58, 59, 60, 61];
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(0, [0], 2, [1]));
      this.folds.push(folder(1, [0, 1], -3 / 2, [1]));
      this.folds.push(folder(0, [4], -2, [2]));
      this.folds.push(folder(1, [3, 4], 3 / 2, [2]));
      this.folds.push(new Fold([0, 1, 2, 3, 4], Math.PI / 3, [[0, 1.5, 0.288675], [10, 1.5, 0.288675]]));
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      this.horizTriangleAxes = this.workingCopy.getHorizTriangleAxes(0, this.boundingCube);
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      this.workingCopy.horizTriangleAxes = this.workingCopy.getHorizTriangleAxes(0, this.workingCopy.boundingCube);
      if (!testing) {
        this.populateChoices();
      }
    }

    return Structure16;

  })(Pattern);

  Structure17 = (function(_super) {
    __extends(Structure17, _super);

    function Structure17() {
      var faces, shape, type30, type31, _i, _len;
      faces = [];
      while (true) {
        faces = [new StripedRect(2, 0), new StripedRect(2, 1), new StripedRect(2, 2), new StripedRect(2, 3), new StripedRect(2, 4), new VertPentagonInv(2, 3), new VertPentagon(4, 4)];
        if (testing) {
          break;
        }
        type30 = false;
        type31 = false;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 30) {
            type30 = true;
          }
          if (shape.type === 31) {
            type31 = true;
          }
        }
        if (type30 && type31) {
          break;
        }
      }
      Structure17.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 34, 35, 36, 37, 38, 39, 40, 41, 42];
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(0, [5], -2, [2]));
      this.folds.push(folder(0, [6], 2, [4]));
      this.folds.push(folder(1, [0], 5 / 2, [1]));
      this.folds.push(folder(1, [0, 1], 5 / 2, [2]));
      this.folds.push(folder(1, [4, 6], -5 / 2, [4]));
      this.folds.push(folder(1, [3, 4, 6], -5 / 2, [3]));
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      this.horizPentAxes = this.workingCopy.getHorizPentAxes(6, this.boundingCube);
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      this.workingCopy.horizPentAxes = this.workingCopy.getHorizPentAxes(6, this.workingCopy.boundingCube);
      if (!testing) {
        this.populateChoices();
      }
    }

    return Structure17;

  })(Pattern);

  Structure18 = (function(_super) {
    __extends(Structure18, _super);

    function Structure18() {
      var faces, shape, type15, type32, type33, x0, x1, y0, y1, z, _i, _len;
      faces = [];
      while (true) {
        faces = [new TippedHexSection(0, 2), new TippedRect(0, 2), new TippedRect(2, 2), new TippedRect(4, 2), new TippedHexSectionInv(4, 3)];
        if (testing) {
          break;
        }
        type15 = false;
        type33 = false;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 32) {
            type32 = true;
          }
          if (shape.type === 33) {
            type33 = true;
          }
        }
        if (type32 && type33) {
          break;
        }
      }
      Structure18.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 52, 53, 54, 55, 56];
      this.currentPace = 30;
      this.offset = 20;
      this.ar[3].preSpin([[4, 3, 0], [4, 3, 10]], 2 * Math.PI / 3);
      this.folds = [];
      x0 = this.ar[4].ar[0].ar[0];
      y0 = this.ar[4].ar[0].ar[1];
      z = this.ar[4].ar[0].ar[2];
      x1 = this.ar[4].ar[2].ar[0];
      y1 = this.ar[4].ar[2].ar[1];
      this.folds.push(new Fold([3], Math.PI / 2, [[x0, y0, z], [x1, y1, z]]));
      this.folds.push(folder(1, [3, 4], -2, [3]));
      this.folds.push(folder(1, [0], 2, [2]));
      this.folds.push(folder(0, [0, 1], -3, [2]));
      this.folds.push(folder(0, [2, 3, 4], 3, [2]));
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      this.vertTriangleAxes = this.workingCopy.getVertTriangleAxes(0, this.boundingCube);
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      this.workingCopy.vertTriangleAxes = this.workingCopy.getVertTriangleAxes(0, this.workingCopy.boundingCube);
      if (!testing) {
        this.populateChoices();
      }
    }

    return Structure18;

  })(Pattern);

  Structure19 = (function(_super) {
    __extends(Structure19, _super);

    function Structure19() {
      var faces, shape, type0, type34, type35, _i, _len;
      faces = [];
      while (true) {
        faces = [new DblMarkSquare(1, 0), new DblMarkSquare(1, 1), new DblMarkSquare(2, 1), new DblMarkSquare(0, 2), new DblMarkSquare(1, 2), new DblMarkSquare(0, 3)];
        if (testing) {
          break;
        }
        type34 = false;
        type35 = false;
        type0 = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 34) {
            type34 = true;
          }
          if (shape.type === 35) {
            type35 = true;
          }
          if (shape.type === 0) {
            ++type0;
          }
        }
        if (type34 && type35 && type0 === 3) {
          break;
        }
      }
      Structure19.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [5], -2, [3]));
      this.folds.push(folder(0, [3, 5], -2, [1]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(0, [2], 2, [2]));
      this.folds.push(folder(1, [0, 1, 2], 2, [2]));
      this.postInit();
    }

    return Structure19;

  })(Pattern);

  Structure20 = (function(_super) {
    __extends(Structure20, _super);

    function Structure20() {
      var faces, shape, total, _i, _len;
      faces = [];
      while (true) {
        faces = [new BlankSquare(3, 0), new BlankSquare(3, 1), new BlankSquare(3, 2), new Square(3, 3), new HorseShoe(0, 4), new VertRect(3, 4), new VertRectThree(3, 6), new HorseShoeSideways(6, 6), new HorizRect(4, 9), new Square(6, 9)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
        if (testing) {
          break;
        }
      }
      Structure20.__super__.constructor.call(this, faces);
      this.currentPace = 30;
      this.mainOffset = OFFSET - 5;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(0, [9], 2, [6]));
      this.folds.push(folder(1, [8, 9], -2, [9]));
      this.folds.push(folder(0, [7, 8, 9], 2, [4]));
      this.folds.push(folder(1, [0], -2, [1]));
      this.folds.push(folder(1, [0, 1], -2, [2]));
      this.folds.push(folder(1, [0, 1, 2], 2, [3]));
      this.folds.push(folder(1, [0, 1, 2, 3], 2, [4]));
      this.folds.push(folder(0, [4], -2, [3]));
      this.folds.push(folder(1, [6, 7, 8, 9], -2, [6]));
      this.postInit();
    }

    return Structure20;

  })(Pattern);

  Structure21 = (function(_super) {
    __extends(Structure21, _super);

    function Structure21() {
      var faces, ga, i, shape, total, x, y, _i, _j, _k, _len, _len1, _len2, _ref, _ref1;
      faces = [];
      while (true) {
        faces = [new VertRect(12, 2), new VertRect(12, 6), new HorizRect(8, 3), new HorizRect(8, 6), new HorizRect(6, 3), new HorizRect(4, 6), new HorizRect(6, 6), new Coffin(0, 4), new VertRect(12, 4), new Coffin(0, 10)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
        if (testing) {
          break;
        }
      }
      Structure21.__super__.constructor.call(this, faces);
      this.allowedRotations = [4, 6, 7, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 21, 22, 23];
      this.mainOffset = OFFSET - 5;
      this.currentPace = 30;
      this.offset = 20;
      ga = (function(_this) {
        return function(f0, p0, f1, p1) {
          var axis1, axis2, coord, result, _j, _k, _len1, _len2, _ref, _ref1;
          result = [];
          axis1 = [];
          axis2 = [];
          _ref = _this.ar[f0].ar[p0].ar;
          for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
            coord = _ref[_j];
            axis1.push(coord);
          }
          _ref1 = _this.ar[f1].ar[p1].ar;
          for (_k = 0, _len2 = _ref1.length; _k < _len2; _k++) {
            coord = _ref1[_k];
            axis2.push(coord);
          }
          result.push(axis1);
          result.push(axis2);
          return result;
        };
      })(this);
      x = this.ar[7].ar[0].ar[0];
      y = this.ar[7].ar[0].ar[1];
      _ref = [3, 5, 6];
      for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
        i = _ref[_j];
        this.ar[i].preSpin([[x, y, 0], [x, y, 10]], -Math.PI / 6);
      }
      x = this.ar[7].ar[5].ar[0];
      y = this.ar[7].ar[5].ar[1];
      _ref1 = [2, 4];
      for (_k = 0, _len2 = _ref1.length; _k < _len2; _k++) {
        i = _ref1[_k];
        this.ar[i].preSpin([[x, y, 0], [x, y, 10]], Math.PI / 6);
      }
      x = this.ar[6].ar[2].ar[0] - this.ar[9].ar[4].ar[0];
      y = this.ar[6].ar[2].ar[1] - this.ar[9].ar[4].ar[1];
      this.ar[9].preTranslate(x, y, 0);
      this.folds = [];
      this.folds.push(folder(1, [0], 2, [4]));
      this.folds.push(folder(1, [1], -2, [6]));
      this.folds.push(folder(0, [0, 1, 8], 2, [12]));
      this.folds.push(new Fold([4], -Math.PI / 3, ga(4, 1, 4, 2)));
      this.folds.push(new Fold([4, 2], -Math.PI / 2, ga(2, 2, 2, 3)));
      this.folds.push(new Fold([9], Math.PI / 2, ga(6, 2, 6, 3)));
      this.folds.push(new Fold([5], -Math.PI / 3, ga(5, 1, 5, 2)));
      this.folds.push(new Fold([5, 6, 9], -Math.PI / 3, ga(3, 0, 3, 3)));
      this.folds.push(new Fold([3, 5, 6, 9], -Math.PI / 2, ga(3, 0, 3, 1)));
      this.postInit();
    }

    return Structure21;

  })(Pattern);

  Structure22 = (function(_super) {
    __extends(Structure22, _super);

    function Structure22() {
      var faces, i, order, _i;
      faces = [new DieFace(0, 0), new DieFace(0, 1), new DieFace(1, 1), new DieFace(2, 1), new DieFace(2, 2), new DieFace(3, 2)];
      Structure22.__super__.constructor.call(this, faces);
      order = shuffle([1, 2, 3, 4, 5, 6]);
      for (i = _i = 0; _i <= 5; i = ++_i) {
        this.ar[i].addDieSpots(order[i]);
      }
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(0, [5], 2, [3]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(0, [0, 1], -2, [1]));
      this.folds.push(folder(0, [0, 1, 2], -2, [2]));
      this.folds.push(folder(1, [4, 5], -2, [2]));
      this.postInit();
    }

    Structure22.prototype.getFalseCandidate = function(correctPossibilities) {
      return this.getDieFalseCandidate(correctPossibilities);
    };

    return Structure22;

  })(Pattern);

  Structure23 = (function(_super) {
    __extends(Structure23, _super);

    function Structure23() {
      var faces, i, order, _i;
      faces = [new DieFace(0, 0), new DieFace(0, 1), new DieFace(1, 1), new DieFace(2, 1), new DieFace(1, 2), new DieFace(1, 3)];
      Structure23.__super__.constructor.call(this, faces);
      order = shuffle([1, 2, 3, 4, 5, 6]);
      for (i = _i = 0; _i <= 5; i = ++_i) {
        this.ar[i].addDieSpots(order[i]);
      }
      this.currentPace = 30;
      this.offset = 20;
      this.folds = [];
      this.folds.push(folder(1, [5], -2, [3]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(1, [4, 5], -2, [2]));
      this.folds.push(folder(0, [3], 2, [2]));
      this.folds.push(folder(0, [0, 1], -2, [1]));
      this.postInit();
    }

    Structure23.prototype.getFalseCandidate = function(correctPossibilities) {
      return this.getDieFalseCandidate(correctPossibilities);
    };

    return Structure23;

  })(Pattern);

  Structure24 = (function(_super) {
    __extends(Structure24, _super);

    function Structure24() {
      var faces, shape, total, _i, _len;
      faces = [];
      while (true) {
        faces = [new BlankSquare(3, 7), new BlankSquare(5, 8), new DiagonalRect(1, 1), new SoftL(2, 0), new VertRectThree(3, 0), new VertRectThree(3, 3), new SoftLInv2(4, 7), new Square(3, 6), new Square(5, 9)];
        total = 0;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          total += shape.marks.length;
        }
        if (total > 1) {
          break;
        }
      }
      Structure24.__super__.constructor.call(this, faces);
      this.disallowedPairs = [[7, 2], [9, 3], [10, 1], [11, 0], [17, 0], [18, 1], [20, 3], [23, 2]];
      this.currentPace = 30;
      this.offset = 20;
      this.ar[2].preSpin([[2, 1, 0], [2, 1, 10]], Math.PI / 4);
      this.folds = [];
      this.folds.push(folder(1, [8], -2, [9]));
      this.folds.push(folder(0, [1, 8], -2, [6]));
      this.folds.push(folder(1, [0], -2, [7]));
      this.folds.push(folder(0, [1, 6, 8], 2, [4]));
      this.folds.push(folder(1, [0, 1, 6, 7, 8], -2, [6]));
      this.folds.push(new Fold([2], Math.PI / 2, [[1, 2, 0], [2, 1, 0]]));
      this.folds.push(folder(0, [2, 3], -2, [3]));
      this.folds.push(folder(1, [2, 3, 4], 2, [3]));
      this.postInit();
    }

    return Structure24;

  })(Pattern);

  Structure25 = (function(_super) {
    __extends(Structure25, _super);

    function Structure25() {
      var faces, shape, type0, type15, x0, x1, y0, y1, z, _i, _len;
      faces = [];
      while (true) {
        faces = [new HexSection9(1, 1), new VertRect(0, 1), new VertRect(1, 1), new HexSection10(2, 3), new HorizRect(2, 3)];
        if (testing) {
          break;
        }
        type15 = false;
        type0 = false;
        for (_i = 0, _len = faces.length; _i < _len; _i++) {
          shape = faces[_i];
          if (shape.type === 15) {
            type15 = true;
          }
          if (shape.type === 6) {
            type0 = true;
          }
          if (shape.type === 5) {
            type0 = true;
          }
        }
        if (type15 && type0) {
          break;
        }
      }
      Structure25.__super__.constructor.call(this, faces);
      this.allowedRotations = [0, 52, 53, 54, 55, 56];
      this.disallowedPairs = [[0, 1], [0, 2], [52, 1], [52, 2], [53, 1], [53, 2], [54, 1], [54, 2], [55, 1], [55, 2], [56, 1], [56, 2]];
      this.ar[4].preSpin([[2, 3, 0], [2, 3, 10]], Math.PI / 6);
      this.currentPace = 30;
      this.offset = 5;
      this.folds = [];
      x0 = this.ar[3].ar[0].ar[0];
      y0 = this.ar[3].ar[0].ar[1];
      z = this.ar[3].ar[0].ar[2];
      x1 = this.ar[3].ar[2].ar[0];
      y1 = this.ar[3].ar[2].ar[1];
      this.folds.push(new Fold([4], Math.PI / 2, [[x0, y0, z], [x1, y1, z]]));
      this.folds.push(folder(1, [3, 4], -2, [3]));
      this.folds.push(folder(1, [0], 2, [1]));
      this.folds.push(folder(0, [1], -6, [1]));
      this.folds.push(folder(0, [0, 2, 3, 4], 2, [1]));
      this.totalSteps = this.currentPace * this.folds.length;
      this.setPov();
      if (this.rotation > 0) {
        this.totalSteps += Math.round(4 * this.currentPace * Math.abs(this.getRadians(this.rotation)) / Math.PI);
      }
      this.screenBounds = getBounds(this.ar, this.pov);
      this.workingCopy = this.clone();
      this.workingCopy.fold();
      this.boundingCube = this.workingCopy.getBoundingCube();
      this.vertTriangleAxes = this.workingCopy.getVertTriangleAxes(0, this.boundingCube);
      if (!testing) {
        this.workingCopy.reposition();
      }
      this.workingCopy.boundingCube = this.workingCopy.getBoundingCube();
      this.workingCopy.vertTriangleAxes = this.workingCopy.getVertTriangleAxes(0, this.workingCopy.boundingCube);
      if (!testing) {
        this.populateChoices();
      }
    }

    return Structure25;

  })(Pattern);

  newPage = function() {
    ++pagePosition;
    if (pagePosition > serializedPages.length) {
      currentPage = getStructure();
      serializedPages.push(currentPage);
      erasePage();
      currentPage.display();
      if (timerOn) {
        return startTimer();
      }
    } else {
      erasePage();
      return restoreCurrentPage();
    }
  };

  getStructure = function() {
    var type;
    type = randInt(25);
    switch (type) {
      case 0:
        return new Structure1();
      case 1:
        return new Structure2();
      case 2:
        return new Structure3();
      case 3:
        return new Structure4();
      case 4:
        return new Structure5();
      case 5:
        return new Structure6();
      case 6:
        return new Structure7();
      case 7:
        return new Structure8();
      case 8:
        return new Structure9();
      case 9:
        return new Structure10();
      case 10:
        return new Structure11();
      case 11:
        return new Structure12();
      case 12:
        return new Structure13();
      case 13:
        return new Structure14();
      case 14:
        return new Structure15();
      case 15:
        return new Structure16();
      case 16:
        return new Structure17();
      case 17:
        return new Structure18();
      case 18:
        return new Structure19();
      case 19:
        return new Structure20();
      case 20:
        return new Structure21();
      case 21:
        return new Structure22();
      case 22:
        return new Structure23();
      case 23:
        return new Structure24();
      case 24:
        return new Structure25();
    }
  };

  previousPage = function() {
    if (pagePosition > 1) {
      --pagePosition;
      erasePage();
      return restoreCurrentPage();
    }
  };

  restoreCurrentPage = function() {
    currentPage = serializedPages[pagePosition - 1];
    if (currentPage.answer === "z") {
      if (timerOn) {
        startTimer();
      }
    } else {
      setGrades();
      if (currentPage.answer === answerValues[currentPage.correctChoice]) {
        revealExplication();
      }
    }
    return currentPage.display();
  };

  radioChangeEvent = function() {
    clearInterval(timer);
    currentPage.answer = $("input[type=radio]:checked").val();
    clearGrades();
    setGrades();
    revealExplication();
    return tallyScoreboard();
  };

  erasePage = function() {
    var i, n, _i;
    clearRadios();
    clearGrades();
    n = OPTIONS;
    for (i = _i = 0; 0 <= n ? _i <= n : _i >= n; i = 0 <= n ? ++_i : --_i) {
      clearCanvas(i);
    }
    clearInterval(timer);
    timeReset();
    setPageNumber();
    return clearExplication();
  };

  setGrades = function() {
    var selector;
    $('input[type=radio]').filter(function() {
      return $(this).val() === currentPage.answer;
    }).prop('checked', true);
    $(answerGrades[currentPage.correctChoice]).html('<img src="http://d102fwow1ltq0.cloudfront.net/game/check.png" />');
    if (answerValues[currentPage.correctChoice] !== currentPage.answer) {
      selector = '#grade-' + currentPage.answer;
      return $(selector).html('<img src="http://d102fwow1ltq0.cloudfront.net/game/x.png" />');
    }
  };

  clearGrades = function() {
    var selector, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = answerGrades.length; _i < _len; _i++) {
      selector = answerGrades[_i];
      _results.push($(selector).html(''));
    }
    return _results;
  };

  revealExplication = function() {
    currentPage.workingCopy.restoreFromArray(currentPage.toArray());
    currentPage.workingCopy.pov = currentPage.pov;
    currentPage.workingCopy.boundingCube = currentPage.boundingCube;
    currentPage.workingCopy.stepsExecuted = 0;
    currentPage.workingCopy.horizPentAxes = currentPage.horizPentAxes;
    currentPage.workingCopy.vertPentAxes = currentPage.vertPentAxes;
    currentPage.workingCopy.vertTriangleAxes = currentPage.vertTriangleAxes;
    currentPage.workingCopy.horizTriangleAxes = currentPage.horizTriangleAxes;
    currentPage.workingCopy.mainOffset = currentPage.mainOffset;
    animDir = true;
    render = function() {
      return currentPage.workingCopy.animate(0);
    };
    animOn = true;
    return addControls();
  };

  clearExplication = function() {
    animOn = false;
    animDir = true;
    render = function() {};
    $('#slider').off("slide");
    return $('#animation-controls').hide();
  };

  timeChangeEvent = function() {
    timerOn = !timerOn;
    if (timerOn) {
      return startTimer();
    } else {
      clearInterval(timer);
      if (timeLeft === 0) {
        return timeReset;
      }
    }
  };

  timeReset = function() {
    timeLeft = SECONDS;
    return $('#time-display').html(timeLeft);
  };

  startTimer = function() {
    clearInterval(timer);
    return timer = setInterval((function() {
      --timeLeft;
      $('#time-display').html(timeLeft);
      if (timeLeft < 1) {
        return timeOut();
      }
    }), 1000);
  };

  timeOut = function() {
    clearInterval(timer);
    if (currentPage.correctChoice !== 0) {
      $('input[type=radio]:eq(0)').prop('checked', true);
    } else {
      $('input[type=radio]:eq(1)').prop('checked', true);
    }
    return radioChangeEvent();
  };

  tallyScoreboard = function() {
    var correct, incorrect, page, _i, _len;
    incorrect = 0;
    correct = 0;
    for (_i = 0, _len = serializedPages.length; _i < _len; _i++) {
      page = serializedPages[_i];
      if (page.answer !== "z") {
        if (page.answer === answerValues[page.correctChoice]) {
          ++correct;
        } else {
          ++incorrect;
        }
      }
    }
    $('#incorrect').html(incorrect);
    return $('#correct').html(correct);
  };

  setPageNumber = function() {
    $('#location').html(pagePosition);
    return $('#total').html(serializedPages.length);
  };

  clearRadios = function() {
    console.log('clearing radios??');
    return $("input[type=radio]").prop('checked', false);
  };

  gameReset = function() {
    timerOn = false;
    clearInterval(timer);
    serializedPages = [];
    pagePosition = 0;
    newPage();
    return tallyScoreboard();
  };

  addControls = function() {
    var steps;

    $('#animation-controls').show();
    console.log('attempting to add controls');

    steps = currentPage.workingCopy.totalSteps;

    $('#slider').slider({
      max: steps
    });

    return $('#slider').on("slide", currentPage.workingCopy.moveSlide);
  };

  pauseAction = function() {
    clearTimeout(animPause);
    animOn = !animOn;
    if (animOn) {
      return $('#pause').text("Pause");
    } else {
      return $('#pause').text("Play");
    }
  };

  reverseAction = function() {
    return animDir = !animDir;
  };

  $(function() {
    sanitizeCanvases();
    $("#next").on("click", newPage);
    $("#previous").on("click", previousPage);
    $("#game-reset").on("click", gameReset);
    $("#time-button").on("click", timeChangeEvent);
    $("input[type=radio]").on("click", radioChangeEvent);
    $("body").on("click", "#pause", pauseAction);
    $("body").on("click", "#reverse", reverseAction);
    newPage();
    return animloop();
  });
};
