import React, {useState, useEffect} from 'react';

import {withRouter} from 'react-router-dom';

import styled from 'styled-components';
import {Lottie} from '@crello/react-lottie';

const Container = styled.div`
  max-width: 528px;
  overflow: hidden;
`;


const FullScreenAnimation = ({lottieConfig, history, location, close}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <Container visible={visible}>
      <Lottie
        {...lottieConfig}
      />
    </Container>
  );
}

FullScreenAnimation.propTypes = {
};
FullScreenAnimation.defaultProps = {
  lottieConfig:{
    style: {maxHeight: '150px', maxWidth: '150px'},
    config: {animationData: null}
  }
};

export default withRouter(FullScreenAnimation);
