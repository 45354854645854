import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import {Link} from 'react-router-dom';

import MasteryReview from '../../components/MasteryReview';
import GettingStarted from '../../components/GettingStarted';

import {H2} from '../../../components/Typography';
import FAQs from '../../components/WebflowFAQs';
import Grid from '../../components/Grid';
import ClassroomCard from '../../components/Cards/Classroom';
import {People} from '@styled-icons/fluentui-system-filled/People'

import {useMastery, useTestProgressArray} from '../../../hooks';

import {ReactComponent as Coffee} from '@bootcamp/shared/src/assets/svg/coffee.svg';
import {ReactComponent as Calendar} from '@bootcamp/shared/src/assets/icons/Calendar-gold.svg';

import {formatClassroomMastery, formatClassroomVideoProgress} from '@bootcamp/shared/src/util/formatting';
import {getStreakDetails, getTestsByClassroom} from '@bootcamp/shared/src/util';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';
import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';

import Container from '../../pages/PageContainer';

import styled from 'styled-components';
import theme from '@bootcamp/shared/src/styles/theme';

const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledClassroomCard = styled(ClassroomCard)`
  flex: 1;
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  color: black;

  &:last-child {
    margin-right: 0;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    margin-right: 0px;
    margin-bottom: 0 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledCalendar = styled(Calendar)`
  fill: transparent !important;
`;

const Home = props => {
  const {isUpgraded, isBootcampPlusStudent} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'oat';
  const bootcampTitle = bootcamp.toUpperCase();

  const testsByClassroom = getTestsByClassroom(config?.classrooms);
  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);

  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom, isBootcampPlusStudent), [isBootcampPlusStudent])
  const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);

  const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const todayActions = [
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      icon: {
        component: Coffee,
        text: 'Daily Warmup', themeColor: 'royal'
      },
      text: isUpgraded ? 'Study 10 unused questions' : 'Free members limited to 100 q\'s',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study`,
      },
      checkIfComplete: interactionObj => {
        const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        return streakCompletedToday;
      }
    },
    {
      icon: {
        component: StyledCalendar,
        text: 'Question of the Day', themeColor: 'royal'
      },
      text: `Try out today's question`,
      containerProps: {
        as: 'a',
        href: `https://bootcamp.com/${bootcamp}/question-of-the-day`,
        target: '_blank'
      }
    },
    {
      icon: {
        component: People,
        text: 'Study Group', themeColor: 'royal'
      },
      text: `Join 1700 fellow students`,
      containerProps: {
        as: 'a',
        href: 'https://www.facebook.com/groups/oatbootcamp',
        target: '_blank'
      }
    },
  ]

  const [biologyProgress] = useMastery(config.classrooms[0].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [gcProgress] = useMastery(config.classrooms[1].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [ocProgress] = useMastery(config.classrooms[2].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [rcProgress] = useMastery(config.classrooms[3].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [physicsProgress] = useMastery(config.classrooms[4].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));
  const [qrProgress] = useMastery(config.classrooms[5].tagId, 'subject', data => formatClassroomMastery(data, 'subject'));

  const progressMap = {
    'Biology': biologyProgress,
    'General Chemistry': gcProgress,
    'Organic Chemistry': ocProgress,
    'Physics': physicsProgress,
    'Quantitative Reasoning': qrProgress,
    'Reading Comprehension': rcProgress,
  };

  const [biologyVideoProgress] = useTestProgressArray(coursesByClassroom['biology'], formatClassroomVideoProgress);
  const [gcVideoProgress] = useTestProgressArray(coursesByClassroom['general-chemistry'], formatClassroomVideoProgress);
  const [ocVideoProgress] = useTestProgressArray(coursesByClassroom['organic-chemistry'], formatClassroomVideoProgress);
  const [rcVideoProgress] = useTestProgressArray(coursesByClassroom['reading-comprehension'], formatClassroomVideoProgress);
  const [qrVideoProgress] = useTestProgressArray(coursesByClassroom['quantitative-reasoning'], formatClassroomVideoProgress);

  const videoProgressMap = {
    'biology': biologyVideoProgress,
    'general-chemistry': gcVideoProgress,
    'organic-chemistry': ocVideoProgress,
    'reading-comprehension': rcVideoProgress,
    'quantitative-reasoning': qrVideoProgress,
  };

  const loading = !biologyProgress || !gcProgress || !ocProgress || !physicsProgress || !qrProgress || !rcProgress;

  const classroomMasteryConfig = config.classrooms
    .filter(({locked}) => !locked)
    .map(({name, route}) => ({name, route, progress: progressMap[name]}));

  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Subjects
      </Title>
      <Grid
        items={config.classrooms}
        itemsPerRow={3}
        breakpoints={{
          1440: 2
        }}
        renderItem={({icon, themePalette, name, route, tagId, locked, themePaletteString, educators}) => (
          <StyledClassroomCard
            key={tagId}
            icon={icon}
            themePalette={theme.colors.brandPalette[themePaletteString]}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={locked}
            questionCount={questionCounts?.classroomQuestionCounts?.[route] || null}
            showProgress={true}
            progress={progressMap[name]}
            videoLessonProgress={videoProgressMap[route]}
            videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts?.[route] || null}
            showVideoProgress={videoProgressMap.hasOwnProperty(route)}
            educatorLayout={'column'}
            expertEducatorOrientation={'column'}
            educators={educators}
            detailsBorder={false}
            withHeaderPadding={false}
            showExpertEducators
          />
        )}
      />
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
