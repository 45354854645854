import React, {useState} from 'react';
import {firstBy} from 'thenby';
import {useHistory} from 'react-router-dom';

import {useUserDataContext} from '../../../../contexts/UserData';
import {useConfigState} from '../context';
import {Column, Table, Heading, Expand, Icon as IconDiv, Card, Title} from './shared';
import MasteryIcon from '../../../components/MasteryIcon';

import {formatTimeSpent} from '@bootcamp/shared/src/util';

import {Bookmark} from '@styled-icons/fluentui-system-filled/Bookmark';
import {Checkmark as Correct} from '@styled-icons/fluentui-system-filled/Checkmark';
import {Dismiss as Incorrect} from '@styled-icons/fluentui-system-filled/Dismiss';
import {CircleHalfFill as Partial} from '@styled-icons/fluentui-system-filled/CircleHalfFill'
import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';
import styled from 'styled-components';



const Container = styled(Column)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};


  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledHeading = styled(Heading)`
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;
const OuterWrapper = styled.div`
  ${({theme}) => theme.mediaQueries.tablet} {
    overflow-x: auto;
  }
  `;
const Wrapper = styled(Card)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};

    margin-right: ${({theme}) => theme.layouts.spacing.m};
    margin-left: ${({theme}) => theme.layouts.spacing.m};
    width: 800px;
  }
`;
const StyledTable = styled(Table)`
  tr:first-child {
    td {
      white-space: nowrap;
    }
  }
`;
const Chevron = styled(IconDiv)`
  width: 20px;
`;
const SnippetContainer = styled.div`
  p {
    display: none;
  }

  div {
    display: none;
  }

  table {
    display: none;
  }

  p:last-child {

    display: flex;
    overflow: hidden;
    max-width: 200px;

    span {
      width: 0;
      height: 0;
      overflow: hidden;
    }

    span:first-child {
      width: auto;
      height: auto;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const RowIndex = styled.span`
  margin-right: 8px;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  font-size: 16px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;
const Mastery = styled(MasteryIcon)`
  width: 20px;
  height: 20px;
`;
const BookmarkIcon = styled(Bookmark)`
    width: 20px;
    min-width: 20px;
    height: 20px;
    color: ${({theme}) => theme.colors.brandPalette.blue.default};
    margin-right: 4px;
`;

const Icon = styled.svg`
  width: 16px;
  height: 16px;
  color: ${({theme, color}) => theme.colors.interfacePalette[color].default};
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  background: ${({theme, color}) => theme.colors.interfacePalette[color].light};
  border-radius: 50%;
`;

const ScoreIcon = ({breakdown}) => {
  const iconConfig = breakdown?.score === breakdown?.maxScore
    ? {component: Correct,  color: 'green'}
    : breakdown?.score === 0
    ? {component: Incorrect, color: 'red'}
    : {component: Partial, color: 'yellow'}

  return (
    <IconWrapper color={iconConfig.color}>
      <Icon as={iconConfig.component} color={iconConfig.color} />
    </IconWrapper>
  );
};

const Question = () => {
  const [expanded, setExpanded] = useState(true);
  const {scoring, config, testRoute, testProgressId} = useConfigState();
  const {bootcamp} = useUserDataContext();

  const history = useHistory();
  const redirectBaseUrl = testRoute
    ? `/${testRoute}?review=true&testProgressId=${encodeURIComponent(testProgressId)}`
    : `/${bootcamp}/custom-test?key=${encodeURIComponent(config?.testStorageKey)}&review=true`;

  const caseTitle = bootcamp === 'nclex'
    ? 'Case Study'
    : bootcamp === 'med-school'
    ? 'USMLE Case'
    : 'Case Set';


  const rows = [...Object.entries(scoring?.caseBreakdown || {}),  ...Object.entries(scoring?.questionBreakdown || {})]
    .sort(firstBy(([testId, breakdown]) => breakdown?.index))
    .map(([questionId, breakdown], caseIndex) => (
      !breakdown?.questions
        ? {
          onClick: () => history.push(`${redirectBaseUrl}&questionId=${questionId}${breakdown?.blockId ? `&blockId=${breakdown?.blockId}` : ''}`),
          cells: [
            { content: <ScoreIcon breakdown={breakdown}/> },
            {
              content: (
                <Content>
                  <RowIndex>{breakdown?.index + 1}</RowIndex>
                  {breakdown.bookmarked ? <BookmarkIcon level={'bookmarked'} squared /> : ''}
                  {/* <Mastery level={['learning', 'reviewing', 'mastered']?.includes(breakdown?.masteryLevel) ? breakdown?.masteryLevel : 'untaggedSelected'} squared /> */}
                </Content>
              )
            },
            { content: breakdown?.subject },
            { content: breakdown?.system },
            { content: `${breakdown?.score} / ${breakdown?.maxScore}` },
            { content: formatTimeSpent(breakdown.timeSpent)},
            { content: <Chevron as={ChevronRight}/> },
          ]
        } : {
          parent: [
            { content: caseTitle},
            { content: breakdown?.subject },
            { content: breakdown?.system },
            { content: `${breakdown?.score} / ${breakdown?.maxScore}` },
            { content: formatTimeSpent(breakdown.timeSpent)},
          ],
          nested: Object
            .entries(breakdown?.questions || {})
            .sort(firstBy(([questionId, breakdown]) => breakdown?.index))
            .map(([questionId, breakdown], index) => ({
              onClick: () => history.push(`${redirectBaseUrl}&questionId=${questionId}${breakdown?.blockId ? `&blockId=${breakdown?.blockId}` : ''}`),
              cells: [
                { content: <ScoreIcon breakdown={breakdown}/> },
                {
                  content: (
                    <Content>
                      <RowIndex>{breakdown?.index + 1}</RowIndex>
                      {breakdown.bookmarked ? <BookmarkIcon level={'bookmarked'} /> : ''}
                      {/* <Mastery level={['learning', 'reviewing', 'mastered']?.includes(breakdown?.masteryLevel) ? breakdown?.masteryLevel : 'untaggedSelected'} squared /> */}
                    </Content>
                  )
                },
                { content: breakdown?.subject, disabled: true },
                { content: breakdown?.system, disabled: true },
                { content: `${breakdown?.score} / ${breakdown?.maxScore}` },
                { content: formatTimeSpent(breakdown.timeSpent)},
              ]
            }))
    }));

  return (
    <Container>
      <StyledHeading>
        <Title>
          Question Breakdown
          <Expand expanded={expanded} setExpanded={setExpanded} />
        </Title>
      </StyledHeading>
      <OuterWrapper>
        <Wrapper>
          <StyledTable
            expandAll={expanded}
            fixed={false}
            rows={[
              { cells: [{ content: '' }, { content: 'Question' }, { content: 'Subject' }, { content: 'System' }, { content: 'Correct / Total' }, { content: 'Time' }, { content: '' }] },
              ...rows
            ]}
          />
        </Wrapper>
      </OuterWrapper>
    </Container>
  );
};

export default Question;