import React from 'react';
import useDocumentTitle from '@rehooks/document-title';

import MasteryBank from '../../../components/MasteryBank';
import QuestionBank from '../../../components/QBank';

import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import {getInObj} from '@bootcamp/shared/src/util';

const BookmarkBank = ({match, location, ...props}) => {
  const {config} = useBootcampConfigContext();
  const {DEFAULT_USER_ID, cognitoUser} = useUserDataContext();

  useDocumentTitle(`${config.meta.siteTitle} | Bookmarked Questions Review`);

  const username = getInObj(['attributes', 'name'], cognitoUser, '');

  const {tagId, tagIds, themePalette} = config.classrooms.find(({route}) => route === match.params.classroom) || {};
  const collectedIds = tagId
    ? [tagId]
    : tagIds ? tagIds
    : config.classrooms.reduce((acc, {tagId, tagIds}) => [...acc, ...(tagIds ? tagIds : [tagId])], []);

  return (
    <MasteryBank match={match} location={location} tagIds={collectedIds} template={'bookmarkedQuestionReview'}>
      {(totalQuestions) => (
        <QuestionBank
          match={match}
          title={'Bookmarked Question Review'}
          startBlockTitle={match.params.classroom}
          withNavSidebar={false}
          themePalette={themePalette}
          username={username || DEFAULT_USER_ID}
          showScore={false}
          totalQuestions={totalQuestions}
          enableBookmarking
        />
      )}
    </MasteryBank>
  );
};

BookmarkBank.defaultProps = {};
BookmarkBank.propTypes = {};

export default BookmarkBank;
