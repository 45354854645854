import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import API, {graphqlOperation} from '@aws-amplify/api';

import {Play} from '@styled-icons/remix-fill/Play';
import {CloudDownloadOutline} from '@styled-icons/evaicons-outline/CloudDownloadOutline';
import {useUserDataContext} from '../../../../contexts/UserData';
import {useModalContext} from '../../../../contexts/Modal';
import {SubmitAnatomyCase} from '../../../../components/Modal';
import {getTestProgressByUserIdHashTestId} from '@bootcamp/shared/src/requests';

import {RoundButton, UpgradeButton as UpgradeButtonUI} from '../../../../components/UI';

import {Button} from '../../../../components/Branding/Buttons';
import theme from '@bootcamp/shared/src/styles/theme';
import {Label3} from '@bootcamp/web/src/components/Typography/next';
import {CheckmarkCircle} from '@styled-icons/fluentui-system-regular/CheckmarkCircle';
import {Info} from '@styled-icons/fluentui-system-filled/Info';

export const PlayButton = styled(Button).attrs(props => ({
  type: 'secondary',
  size: 'small',
  children: 'Lesson Video',
  iconConfig: {position: 'left', icon: Play, size: 16, color: 'white'},
  ...props
}))``;

export const PDFButton = styled(Button).attrs(props => ({
  type: 'secondary',
  size: 'small',
  children: 'PDF Outline',
  iconConfig: {position: 'left', icon: CloudDownloadOutline, size: 16},
  ...props
}))``;

export const AtlasButton = styled(Button).attrs(props => ({
  type: 'secondary',
  size: 'small',
  children: 'View Atlas',
  iconConfig: {position: 'left', icon: CloudDownloadOutline, size: 16},
  ...props
}))``;

const DefaultStyledUpgradeButton = styled(UpgradeButtonUI).attrs(props => ({
  small: true
}))`
`;

export const UpgradeButton = ({className}) => {
  return (
    <DefaultStyledUpgradeButton
      className={className}
      children={'Upgrade'}
      iconPosition={'left'}
      small
      // onClick={onClick}
    />
  );
};
function formatNCLEXScore(attempt) {
  const questionProgresses = attempt
    ?.map(({questionProgresses}) => questionProgresses?.items)
    ?.flat();
  const scoreDetails = questionProgresses?.reduce((acc, {answerState, didCheck}) => {
    try {
      if (!didCheck) return acc;
      const {score, maxScore} = JSON.parse(answerState || "{}");
      return {...acc, score: acc.score + (score || 0), maxScore: acc.maxScore + (maxScore || 0)};
    } catch (error) {
      return acc;
    }
  }, {score: 0, maxScore: 0});
  return {correct: scoreDetails.score, total: scoreDetails.maxScore};
}

function formatScore (attempt) {
  return attempt?.length && attempt
    .map(block => block.questionProgresses.items)
    .flat()
    .reduce((acc, {didSelectCorrectAnswer, didCheck}) => ({correct: acc.correct + (!!didSelectCorrectAnswer && !!didCheck), total: acc.total + !!didCheck}), {correct: 0, total: 0});
}

const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 88px;
  border-left: 1px solid ${({theme}) => theme.colors.borderPalette.primary};
  border-right: 1px solid ${({theme}) => theme.colors.borderPalette.primary};

  ${({theme}) => theme.mediaQueries.mobileXl} {
    display: none;
  }
`;
const ResultItemText = styled(Label3)`
  color: ${({theme}) => theme.colors.typographyPalette.primary};
  font-weight: 700;
  margin-top: 8px;
  `;
const ResultItemSubText = styled(ResultItemText)`
  color: ${({theme}) => theme.colors.typographyPalette.secondary};
  font-weight: 400;
  margin-top: 4px;
`;
const IconWrapper = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  border-radius: 100px;
  width: 16px;
  height: 16px;
`;

const AlertIcon = styled(Info)`
  color: ${({theme}) => theme.colors.interfacePalette.red.default};
`;
const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.interfacePalette.red.default}3A;
  padding: 12px;
  width: fit-content;
  height: auto;
  margin: 0 auto;
  margin-bottom: 16px;
`;

const ResetButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const ResetButton = ({testId, themePalette, className, setInitialized}) => {
  const [testProgressId, setTestProgressId] = useState();
  const [score, setScore] = useState();
  const {DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  useEffect(() => {
    if (!testId || !DEFAULT_USER_ID || !!score) return;

    const fetchTestProgress = async () => {
      if (bootcamp === 'inbde') {
        const progressIdResult = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${testId}`, false);
        const progressId = progressIdResult?.data?.TestProgressByTestId?.items?.[0]?.id;
        setTestProgressId(progressId);
        return;
      }
      const progressIdResult = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${testId}`, true);
      const progressId = progressIdResult?.data?.TestProgressByTestId?.items?.[0]?.id;

      if (!progressId) return;

      setTestProgressId(progressId);
      const attempt = progressIdResult?.data?.TestProgressByTestId?.items?.[0]?.blockProgresses.items;

      if (bootcamp === 'nclex') {
        setScore(formatNCLEXScore(attempt));
      } else {
        setScore(formatScore(attempt));
      }
    };

    fetchTestProgress();
  }, [testId, DEFAULT_USER_ID]);

  useEffect(() => {
    if (!testProgressId || ((score?.total === 0 || !score) && bootcamp !== 'inbde') || typeof setInitialized !== 'function') return;

    setInitialized(state => ({...state, [testId]: true}));

  }, [testProgressId, score]);

  const onClick = () => modalDispatch({
      type: 'open',
      component: SubmitAnatomyCase,
      componentProps: {
        icon: <AlertContainer><AlertIcon size={32} /></AlertContainer>,
        textAlign: 'center',
        headerText: 'Reset this question bank?',
        bodyText: 'This will reset all questions within this question bank back to unanswered. Your question tags will remain.',
        confirmConfig: {
          onConfirm: async () => {
            // use API.graphql to set userIdHashTestId to null
            await API.graphql(graphqlOperation(`
            mutation UpdateTestProgress($input: UpdateTestProgressInput!) {
              updateTestProgress(input: $input) {
                id
              }
            }`, {input: {id: testProgressId, userIdHashTestId: null}}))
            setTestProgressId();
            setInitialized(state => ({...state, [testId]: false}));
          },
          colorConfig: theme.colors.interfacePalette.red,
          text: 'Yes, reset this question bank'
        },
        cancelConfig: {
          text: 'No, don\'t reset'
        },
      },
      enableClickClose: true,
      modalContainerStyle: theme => `
        background: ${theme.overlays.opacity.dark._300};
        padding: 10vh 10vw;
        align-items: flex-start;
        ${theme.mediaQueries.tablet} {
          background: ${theme.overlays.opacity.dark._400};
          padding: 0;
        }
      `
  });

  if (!testProgressId || score?.total == 0) return null;

  if (bootcamp === 'inbde') {
    return (
      <Button
        className={className}
        colorConfig={themePalette}
        round
        type="ghost"
        children={'Reset'}
        size={'small'}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
          return false;
        }}
      />
    )
  }

  return (
    <ResetButtonContainer className={className}>
      <ResultItem>
        <IconWrapper>
          <CheckmarkCircle />
        </IconWrapper>
        <ResultItemText>{Math.round(score?.correct * 100 / (score?.total || 1))}%</ResultItemText>
        <ResultItemSubText>Score</ResultItemSubText>
      </ResultItem>
      <Button
        colorConfig={themePalette}
        round
        type="ghost"
        children={'Reset'}
        size={'small'}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
          return false;
        }}
      />
    </ResetButtonContainer>
  );
};

export const BeginPracticeButton = props => (
  <RoundButton
    children={'Start'}
    iconPosition={'right'}
    small
    // IconComponent={<ArrowForwardOutline size={16} />}
    {...props}
  />
);

export const StyledBeginPracticeButton = styled(BeginPracticeButton)`
  ${({theme}) => theme.mediaQueries.mobileXl} {
    font-size: 14px;
    flex: 1;
    height: 32px;
    padding: 0;
    ${({hideOnMobile}) => hideOnMobile && css`display: none;`}
  }
`;
export const StyledResetButton = styled(ResetButton)`
  ${({theme}) => theme.mediaQueries.mobileXl} {
    flex: 1;
    font-size: 14px;
    height: 32px;
    width: 100%;
    padding: 0;

    button {
      font-size: 14px;
      height: 32px;
      width: 100%;
    }
  }
`;
export const StyledUpgradeButton = styled(UpgradeButton)`
  ${({theme}) => theme.mediaQueries.mobileXl} {
    font-size: 14px;
    flex: 1;
    height: 32px;
    padding: 0;
    ${({hideOnMobile}) => hideOnMobile && css`display: none;`}
  }
`;

export const DownloadButton = ({themePalette, children = 'Download', onClick}) => (
  <StyledBeginPracticeButton
    children={children}
    onClick={onClick}
    themePalette={themePalette}
    small
  />
);
