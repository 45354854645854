import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {PageSection} from '../ClassroomContentWrapper';
import {DownloadButton} from '../Cards/components/Buttons';
import Download from '../Cards/Download';
import Callout from '../Cards/Callout';
import {useUserDataContext} from '../../../contexts/UserData';

const Container = styled.div`
`;


const Downloads = ({content, themePalette, calloutText}) => {
  const downloads = content.find(({name}) => name === 'Downloads').content;
  const urlParams = new URLSearchParams(window.location.search)
  const selectedSection = urlParams.get('section')
  const {isUpgraded, bootcamp} = useUserDataContext();
  const gatesUp = !isUpgraded && bootcamp === 'inbde';

  return (
    <Container>
      <PageSection>
        {calloutText && <Callout children={calloutText}/>}
        {(gatesUp ? downloads.sort((a, b) => a.freeAccess ? -1 : 1) : downloads).filter(item => item.ignoreSections ? true : selectedSection && selectedSection !== 'all' ? item.section === selectedSection : true).map((item, index) => (
          <Download
            key={`${item.name}-${index}`}
            name={item.name}
            description={item.description}
            locked={gatesUp && !item.freeAccess}
            onClick={() => window.open(item.route, '_blank')}
            themePalette={themePalette}
            actions={[
              <DownloadButton themePalette={themePalette} />
            ]}
          />
        ))}
      </PageSection>
    </Container>
  );
};

Downloads.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
Downloads.defaultProps = {
  content: [],
  data: {},
};

export default Downloads;
