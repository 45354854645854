import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import FAQs from '../../components/WebflowFAQs';
import {H1, H2, H3, Body3} from '../../../components/Typography';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {Column} from '../../../components/Grid';
import Chapter from '@bootcamp/web/src/bootcamps/components/Cards/Chapter'
import Container from '../../pages/PageContainer';

import {useContentTypeProgress} from '@bootcamp/web/src/bootcamps/api/progress/contentType'
import styled from 'styled-components';

import CreateTestButton from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/CreateTestButton';

import PlayImg from '@bootcamp/shared/src/assets/icons/nclex/Play.png'
import ReadinessImg from '@bootcamp/shared/src/assets/icons/nclex/Readiness.png'
import FirstAidImg from '@bootcamp/shared/src/assets/icons/nclex/FirstAid.png'
import StethoscopeImg from '@bootcamp/shared/src/assets/icons/nclex/Stethoscope.png'
import {People} from '@styled-icons/fluentui-system-filled/People'
import {CalendarLtr} from '@styled-icons/fluentui-system-filled/CalendarLtr';

import {useMasteryArray} from '@bootcamp/web/src/hooks';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import {useAsync} from '@bootcamp/web/src/hooks';
import {formatClassroomMastery} from '@bootcamp/shared/src/util/formatting';
import {getClassroomQuestionCounts} from '@bootcamp/shared/src/requests';
import {useUserDataContext} from '../../../contexts/UserData';
import moment from 'moment';
import {AddCircle} from '@styled-icons/fluentui-system-filled/AddCircle';
import GettingStarted from '../../components/GettingStarted';
import CreateTest from '../../pages/CreateTest';

import {useModalContext} from '../../../contexts/Modal';


const Date = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;
const TitleBar = styled(Column)`
  jusitfy-content: center;
  margin-bottom: 16px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const Wrapper = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  display: flex;
  flex-direction: column;
`;

const IntroContainer = styled.div`
  width: 100%;
  background: linear-gradient(180deg, rgba(21, 26, 30, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 2px 8px -6px rgba(21, 26, 30, 0.1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;
`;
const IntroHeader = styled(H3)`
  color: white;
  margin-bottom: 8px;
  font-size: 20px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;
const IntroBody = styled(Body3)`
  color: rgba(255,255,255,.9);
  max-width: 750px;
  margin: 0 auto 16px;
`;
const StyledCreateTestButton = styled(CreateTestButton)`
  margin: 0 auto;
  transform: scale(1);
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  transition: all 100ms ease;
  &:hover {
    transform: scale(1.02);
  }
`;
const Intro = () => {
  const {cognitoUser} = useUserDataContext();

  return (
    <IntroContainer>
      <IntroHeader>
        Let's get started{cognitoUser && cognitoUser?.getUsername && `, ${cognitoUser?.getUsername()}`}
      </IntroHeader>
      <IntroBody>
        You have a <strong>free membership</strong>. The best way to get started is to <strong>create a test</strong>. When you're ready, upgrade your membership to get instant access to all content and features.
      </IntroBody>
      <StyledCreateTestButton active dark />
    </IntroContainer>
  )
}
const StyledAddCircle = styled(AddCircle)`
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
  width: 24px;
  height: 24px;
`;
const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledCalendar = styled(CalendarLtr)`
  fill: ${({theme}) => theme.colors.brandPalette.teal.default};
`;

const StyledPeople = styled(People)`
  fill: ${({theme}) => theme.colors.brandPalette.magenta.default};
`;

  const ReadinessExamCard = () => {
    const {isUpgraded} = useUserDataContext();
    const {config} = useBootcampConfigContext();

    const {contentTypes: caseQuestions} = config.classrooms.find(({route}) => route === 'cars-passages');
    const caseQuestionsConfig = caseQuestions?.[0];
    const caseQuestionsData = useContentTypeProgress(caseQuestionsConfig, 'all')['qbanks-all'];

    return (
      <Chapter
        route={`cars-passages`}
        name={'CARS Passages'}
        icon={<img src={ReadinessImg} style={{margin: 'auto', width: '32px', height: '32px'}} />}
        questionCount={caseQuestionsData?.counts?.all}
        fullWidthProgress
        showProgress={!!isUpgraded}
        progress={caseQuestionsData ? caseQuestionsData?.progress?.all : 'loading'}
      />
    )
  }

const Home = () => {
  const {bootcamp} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  const {modalDispatch} = useModalContext();

  const isUpgraded = true;

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  // const {contentTypes: freeQuestions} = config.classrooms.find(({name}) => name === 'Free Questions');
  // const freeQuestionsConfig = freeQuestions?.[0];
  // const {counts} = useContentTypeProgress(freeQuestionsConfig, 'all')?.['qbanks-all'] || {};

  const todayActions = [
    {
      interactionKey: `Streak-${bootcamp}-Create-Test`,
      icon: {
        component: () => <StyledAddCircle/>,
        text: 'Create Test'
      },
      text: isUpgraded ? 'Study up to 85 questions at a time' : `Upgrade for unlimited access`,
      containerProps: {
        onClick: () => modalDispatch({
          type: 'open',
          component: CreateTest,
          componentProps:  !!isUpgraded ? {} : {defaults: {subject: 'Free Questions'}},
          enableClickClose: true,
        }),
      },
    },
    {
      icon: {
        component: StyledCalendar,
        text: 'Question of the Day', themeColor: 'royal'
      },
      text: `Try out today's question`,
      containerProps: {
        as: 'a',
        href: `https://bootcamp.com/${bootcamp}/question-of-the-day`,
        target: '_blank'
      }
    },
    {
      icon: {
        component: StyledPeople,
        text: 'Study Group', themeColor: 'royal'
      },
      text: `Join 6k fellow students`,
      containerProps: {
        as: 'a',
        href: 'https://www.facebook.com/groups/2169829559872032',
        target: '_blank'
      }
    },
  ];
  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      {/* <TitleBar>
        <Date>{moment().format('dddd, MMM D')}</Date>
        <Title>
          Start Studying
        </Title>
      </TitleBar>
      {!isUpgraded && <Intro />} */}
      <Title>MCAT Resources</Title>
      <Wrapper>
        {isUpgraded ? [
          <ReadinessExamCard />,
        ] : [
          <Chapter
            route={`cars-passages`}
            name={'CARS Passages'}
            icon={<img src={ReadinessImg} style={{margin: 'auto', width: '24px', height: '24px'}} />}
            showProgress={false}
          />,
        ]}
      </Wrapper>
      <FAQs heading={'FAQs'} />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
