/* eslint-disable */
module.exports = jQuery => jQuery(document).ready(function(jQuery)
{
        var difficulty = 0; // change to 1 for easy, 2 for very easy
	var g=[];
	var a=[];
	var b=[];
	var p1 = [];
	var p2 = [];
	var c=[];
	var d=[];
	var r=[];
	var m=[];
	var loc =1;
	var total=1;
	var correct=0;
	var incorrect=0;
	var setTime = 30;
	var angleMode = 0;
	var right='';
	var timeon = true;
	var timer = null;
	var sec = setTime;
	var questionStartTime = new Date().getTime();
	var totalTime = 0;
	var totalAnswered = 0;
	var alpha = ['A','B','C','D'];
	var l = [100,100,100,100,100,100,100,100,100,23,100,100,100,100,100,100,100,100,100,100,50,100,100,100,100,33,100,100,100];
	jQuery('.grade input[type=radio]').prop('checked', false);
	jQuery('.grade input[type=radio]').attr('disabled',false);

	function drawangle(n) {
		var temp = [];
		var answer = [];
		var increment = 0;
		a[n]=[];
		b[n]=[];
		p1[n] = [];
		p2[n] = [];
		c[n]=[];
		d[n]=[];
		m[n]=[];
		jQuery('#answers').html('');

		var minRange = 30;
		var maxRange = 179;
		var maxAdded = 48;

		if (difficulty == 0){
			maxAdded = 12;
		}else if (difficulty == 1){
			maxAdded = 18
		}

		if (angleMode == 1){
			maxRange = 89;
		}else if (angleMode == 2){
			minRange = 95;
		}

		maxRange -= maxAdded;

		temp[0] = randomInt(minRange,maxRange);

		for (var i=1;i<=3;i++) {
                        if (difficulty === 0) {
                            increment = randomInt(3,4);
                        } else if (difficulty === 1) {
                            increment = randomInt(5,6);
                        } else {
                            increment = randomInt(15,16);
                        }
			temp[i]=temp[i-1]+increment;
		}
		var angle = temp.slice();
		shuffle(angle);
		var variation = randomInt(0,2);
		var tempa = [];
		var tempb = [];

		for (i=0;i<=3;i++) {
			a[n][i]=[];
			b[n][i]=[];
			p1[n][i]=[];
			p2[n][i]=[];
			c[n][i]=[];
			var canvas = document.getElementById('canvas'+(i+1));
			var ctx = canvas.getContext('2d');
			ctx.clearRect(0, 0, 200, 230);

			var index = temp.indexOf(angle[i]);
			answer[index] = i+1;

			var ax = 0;
			var ay = 0;
			var bx = 0;
			var by = 0;
			if (i<1) {
				ax = randomInt(40,160);
				ay = randomInt(40,180);

				if (ax > 100) {
					bx = ax/2;
				}
				else{
					bx = ((200-ax)/2)+ax;
				}
				if (ay > 100) {
					by = ay/2;
				}
				else{
					by = ((200-ay)/2)+ay;
				}
				tempa['x'] = ax;
				tempa['y'] = ay;
				tempb['x'] = bx;
				tempb['y'] = by;
			}
			else {
				var diff = 0
				if (variation == 0) { // all same
					diff = randomInt(-3,3);
					ax = tempa['x'] + diff;
					diff = randomInt(-3,3);
					ay = tempa['y'] + diff;
					diff = randomInt(-3,3);
					bx = tempb['x'] + diff;
					diff = randomInt(-3,3);
					by = tempb['y'] + diff;
				}
				else if (variation == 1) { // 2 same, 2 opposite
					if (i==1) {
						diff = randomInt(-3,3);
						ax = tempa['x'] + diff;
						diff = randomInt(-3,3);
						ay = tempa['y'] + diff;
						diff = randomInt(-3,3);
						bx = tempb['x'] + diff;
						diff = randomInt(-3,3);
						by = tempb['y'] + diff;
					}
					else if (i==2) {
						ax = randomInt(40,160);
						ay = randomInt(40,180);

						if (ax > 100) {
							bx = ax/2;
						}
						else{
							bx = ((200-ax)/2)+ax;
						}
						if (ay > 100) {
							by = ay/2;
						}
						else{
							by = ((200-ay)/2)+ay;
						}
						tempa['x'] = ax;
						tempa['y'] = ay;
						tempb['x'] = bx;
						tempb['y'] = by;
					}
					else if (i==3) {
						diff = randomInt(-3,3);
						ax = tempa['x'] + diff;
						diff = randomInt(-3,3);
						ay = tempa['y'] + diff;
						diff = randomInt(-3,3);
						bx = tempb['x'] + diff;
						diff = randomInt(-3,3);
						by = tempb['y'] + diff;
					}
				}
				else {
					ax = randomInt(40,160);
					ay = randomInt(40,180);

					if (ax > 100) {
						bx = ax/2;
					}
					else{
						bx = ((200-ax)/2)+ax;
					}
					if (ay > 100) {
						by = ay/2;
					}
					else{
						by = ((200-ay)/2)+ay;
					}
				}
			}

		    ctx.beginPath();
			ctx.setLineDash([]);
			ctx.moveTo(ax,ay);
			ctx.lineTo(bx,by);
			ctx.stroke();
			var lindx = randomInt(0,l.length-1);
			//var len  = 100;
			var len  = l[lindx];
			var dx1=ax-bx;
			var dy1=ay-by;

			var result=Math.atan2(dy1,dx1);
			var degree = Math.round(180-(result* (180/Math.PI)));

			var theta = ((180+angle[i])-degree) * Math.PI / 180;
			var dx2 = len * Math.cos(theta);
			var dy2 = len * Math.sin(theta);

			var cx = ax - dx2;
			var cy = ay - dy2;

			var newG = -dx1 / dy1;
			var r1x = ax + 50;
			var r2x = ax - 50;

			var r1y = ay + (r1x - ax) * newG;
			var r2y = ay + (r2x - ax) * newG;


			ctx.moveTo(ax,ay);
			ctx.lineTo(cx,cy);
			ctx.stroke();

			ctx.clearRect(0, 0,200, 10);
			ctx.clearRect(0, 0, 10, 230);
			ctx.clearRect(190, 0, 200, 230);
			ctx.clearRect(0, 200, 200, 230);

			ctx.font="18px Arial";
			ctx.fillStyle="black";
			ctx.fillText((i+1),95,220);

			a[n][i]['x']=ax;
			a[n][i]['y']=ay;
			b[n][i]['x']=bx;
			b[n][i]['y']=by;
			p1[n][i]['x']=r1x;
			p1[n][i]['y']=r1y;
			p2[n][i]['x']=r2x;
			p2[n][i]['y']=r2y;
			c[n][i]['x']=cx;
			c[n][i]['y']=cy;
			d[n][i]=angle[i];

		}
		right='';
		var choice = choices(answer);
		shuffle(choice);

		for (i=0;i<=3;i++) {
			jQuery('#answers').append(alpha[i]+'. '+choice[i]+'<br />');
			m[n][i]=choice[i];
			if (right==choice[i]) {
				r[n]=alpha[i];
			}
		}

	}
	function redrawangle(n) {
		jQuery('#answers').html('');
		for (i=0;i<=3;i++) {
			jQuery('#answers').append(alpha[i]+'. '+m[n][i]+'<br />');
		}
		for (var i=0;i<=3;i++) {
			var canvas = document.getElementById('canvas'+(i+1));
			var ctx = canvas.getContext('2d');
			var ax = a[n][i]['x'];
			var ay = a[n][i]['y'];
			var bx = b[n][i]['x'];
			var by = b[n][i]['y'];
			var cx = c[n][i]['x'];
			var cy = c[n][i]['y'];

			var r1x = p1[n][i]['x'];
			var r1y = p1[n][i]['y'];
			var r2x = p2[n][i]['x'];
			var r2y = p2[n][i]['y'];


			ctx.clearRect(0, 0, 200, 230);
		    ctx.beginPath();
			ctx.setLineDash([]);
			//ctx.setLineDash([5, 15]);
			ctx.moveTo(ax,ay);
			ctx.lineTo(bx,by);
			ctx.stroke();
			ctx.moveTo(ax,ay);
			ctx.lineTo(cx,cy);
			ctx.stroke();

			ctx.font="18px Arial";
			ctx.fillStyle="black";
			if (g[loc]!=undefined ) {
				ctx.fillText((i+1)+' ('+d[n][i]+'°)',75,220);
				ctx.setLineDash([3, 3]);
				ctx.moveTo(r1x,r1y);
				ctx.lineTo(r2x,r2y);
				ctx.stroke();
			}
			else {
				ctx.fillText((i+1),95,220);
			}

			ctx.clearRect(0, 0,200, 10);
			ctx.clearRect(0, 0, 10, 230);
			ctx.clearRect(190, 0, 200, 230);
			ctx.clearRect(0, 200, 200, 230);
		}
	}

	jQuery('#next').click(function(){
		jQuery('.grade input[type=radio]').attr('disabled',false);
		jQuery('.grade div').html('');
		jQuery('.grade input[type=radio]').prop('checked', false);
		loc++;
		jQuery('#location').text(loc);
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
		questionStartTime = new Date().getTime();
		sec = setTime;
		jQuery('#time-display').text(sec);
		if (loc>total) {
			total=loc;
			jQuery('#total').text(total);
			drawangle(loc);
			if (timeon==true) {
				runTimer();
			}
		}
		else {
			if (g[loc]!=undefined ) {
				ckAnswer(r[loc],g[loc]);
				if (g[loc]!='') jQuery('[name=answer][value='+g[loc]+']').prop('checked',true);
				jQuery('.grade input[type=radio]').attr('disabled',true);
			}
			else {
				if (timeon==true) {
					runTimer();
				}
			}
			redrawangle(loc);
		}


	});
	jQuery('#previous').click(function(){
		if (loc>1) {
			questionStartTime = new Date().getTime();
			jQuery('.grade input[type=radio]').attr('disabled',false);
			jQuery('.grade div').html('');
			if (timer) {
				clearInterval(timer);
				timer = null;
			}
			sec = setTime;
			jQuery('#time-display').text(sec);
			loc--;
			jQuery('.grade input[type=radio]').prop('checked', false);
			jQuery('#location').text(loc);
			if (g[loc]!=undefined ) {
				ckAnswer(r[loc],g[loc]);
				if (g[loc]!='') jQuery('[name=answer][value='+g[loc]+']').prop('checked',true);
				jQuery('.grade input[type=radio]').attr('disabled',true);
			}
			else {
				if (timeon==true) {
					runTimer();
				}
			}
			redrawangle(loc);
		}
	});

	jQuery('.grade input[type=radio]').click(function(){
		var grade = jQuery(this).val();

		g[loc] = grade;
		jQuery('.grade input[type=radio]').attr('disabled',true);
		ckAnswer(r[loc],grade,1);
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
		for (var i=0;i<=3;i++) {
			var r1x = p1[loc][i]['x'];
			var r1y = p1[loc][i]['y'];
			var r2x = p2[loc][i]['x'];
			var r2y = p2[loc][i]['y'];

			var canvas = document.getElementById('canvas'+(i+1));
			var ctx = canvas.getContext('2d');
			ctx.beginPath();
			ctx.setLineDash([3, 3]);
			ctx.moveTo(r1x,r1y);
			ctx.lineTo(r2x,r2y);
			ctx.stroke();

			ctx.clearRect(0, 0,200, 10);
			ctx.clearRect(0, 0, 10, 230);
			ctx.clearRect(190, 0, 200, 230);
			ctx.clearRect(0, 200, 200, 230);

			ctx.fillText((i+1)+' ('+d[loc][i]+'°)',75,220);
		}


	});
	jQuery('#time-button').click(function(){
		if (timeon==true)  {
			if(timer) {
				clearInterval(timer);
				timer = null;
			}
			timeon = false;
		}
		else {
			timeon = true;
			if (g[loc]==undefined ) {
				runTimer();
			}
		}
	});

	jQuery('#start-generator').click(function() {
		document.getElementById('generator').style.display='block';
		document.getElementById('introduction').style.display='none';
		runTimer();
		questionStartTime = new Date().getTime();
	});

	jQuery('#game-reset').click(function(){
		loc =1;
		total=1;
		correct=0;
		incorrect=0;
		right='';
		timeon = true;
		if(timer) {
			clearInterval(timer);
		}
		timer = null;
		sec = setTime;
		jQuery('#location').text(loc);
		jQuery('#total').text(total);
		jQuery('#time-display').text(sec);
		jQuery('.grade input[type=radio]').prop('checked', false);
		jQuery('.grade input[type=radio]').attr('disabled',false);
		jQuery('.grade div').html('');
		jQuery('#correct').text(0);
		jQuery('#incorrect').text(0);
		drawangle(loc);

	});
        jQuery('#setdifficulty').change(function() {
            difficulty = parseInt(jQuery(this).val());
            jQuery('#next').click();
        });

		jQuery('#setTimer').change(function() {
            setTime = parseInt(jQuery(this).val());
			sec = setTime;
			jQuery('#time-display').text(sec);
			//jQuery('#next').click();
        });

		jQuery('#setAngleMode').change(function() {
            angleMode = parseInt(jQuery(this).val());
			//jQuery('#next').click();
        });

	function runTimer() {
		timer = setInterval(function(){
			sec--;
			if(sec<0) {
				clearInterval(timer);
				timer = null;
				jQuery('.grade input[type=radio]').attr('disabled',true);
				/*
				var array = alpha.slice();
				var index = array.indexOf(r[loc]);
				if (index > -1) {
				    array.splice(index, 1);
				}
				shuffle(array);
				jQuery('[name=answer][value='+array[0]+']').trigger( "click" );
				jQuery('[name=answer][value='+array[0]+']').prop('checked',true);
				ckAnswer(r[loc],array[0],1)
				*/
				g[loc] = '';
				ckAnswer(r[loc],g[loc],1);
				for (var i=0;i<=3;i++) {
					var r1x = p1[loc][i]['x'];
					var r1y = p1[loc][i]['y'];
					var r2x = p2[loc][i]['x'];
					var r2y = p2[loc][i]['y'];

					var canvas = document.getElementById('canvas'+(i+1));
					var ctx = canvas.getContext('2d');
					ctx.beginPath();
					ctx.setLineDash([3, 3]);
					ctx.moveTo(r1x,r1y);
					ctx.lineTo(r2x,r2y);
					ctx.stroke();

					ctx.clearRect(0, 0,200, 10);
					ctx.clearRect(0, 0, 10, 230);
					ctx.clearRect(190, 0, 200, 230);
					ctx.clearRect(0, 200, 200, 230);

					ctx.fillText((i+1)+' ('+d[loc][i]+'°)',75,220);
				}
			}
			else {
				jQuery('#time-display').text(sec);
			}
		}, 1000);
	}
	function ckAnswer(r,g,n) {
		totalTime += new Date().getTime() - questionStartTime;
		++totalAnswered;

		var newAverage = (totalTime / (1000* totalAnswered));
		newAverage -= newAverage % 1;

		jQuery('#average-time').text(newAverage);

		if (r==g) {
			jQuery('#grade-'+g).html('<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/155.png" style="width: 12px; padding-bottom: 6px;" />');
			if(n==1) {
				correct++;
				jQuery('#correct').text(correct);
			}
		}
		else {
			jQuery('#grade-'+g).html('<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/361.png" style="width: 12px; padding-bottom: 6px;" />');
			jQuery('#grade-'+r).html('<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/155.png" style="width: 12px; padding-bottom: 6px;" />');
			if(n==1) {
				incorrect++;
				jQuery('#incorrect').text(incorrect);
			}
		}
	}
	function choices(o){
		var c = [];
		c[0] = o[0]+' - '+o[1]+' - '+o[2]+' - '+o[3];
		c[1] = o[0]+' - '+o[1]+' - '+o[3]+' - '+o[2];
		c[2] = o[1]+' - '+o[0]+' - '+o[2]+' - '+o[3];
		c[3] = o[1]+' - '+o[0]+' - '+o[3]+' - '+o[2];

                /**
                 *
                 * Do random choices
                var possibleChoices = permutation([0,1,2,3]); // generate all possible choice combinations
                var correct = possibleChoices.splice(0,1); // the correct one is always the first, we simply remove it.
                var r = shuffleArray(possibleChoices); // now randomize the full array

		c[0] = o[0]+' - '+o[1]+' - '+o[2]+' - '+o[3];
		c[1] = o[r[0][0]]+' - '+o[r[0][1]]+' - '+o[r[0][2]]+' - '+o[r[0][3]];
		c[2] = o[r[1][0]]+' - '+o[r[1][1]]+' - '+o[r[1][2]]+' - '+o[r[1][3]];
		c[3] = o[r[2][0]]+' - '+o[r[2][1]]+' - '+o[r[2][2]]+' - '+o[r[2][3]];
                 */

                /**
                 * Sort choice combinations into categories
                 *
                 * - difficult (more positions are correct)
                 * - medium (one position is correct)
                 * - easy (the rest, random)
                 *
                 *  Examples of difficult:
                 *  - start with a small angle *and* ends with a big angle
                 *
                 *  if (pos[0] = 1 or 2) and (pos[3] = 3 or 4) = difficult
                 *
                 *  1,2,3,4 (correct!)
                 *  1,3,2,4
                 *
                 *  1,2,4,3
                 *  1,4,2,3
                 *
                 *  2,1,3,4
                 *  2,3,1,4
                 *
                 *  2,1,4,3
                 *  2,4,1,3
                 *
                 *
                 *  Medium:
                 *  - start with either a small angle or end with a big angle
                 *
                 * if (pos[0] = 1 or 2) or (pos[3] = 3 or 4) = difficult
                 *
                 *  1,3,4,2
                 *  1,4,3,2
                 *
                 *  2,4,3,1
                 *  2,4,3,1
                 *
                 *  4,1,2,3
                 *  4,2,1,3
                 *
                 *  3,1,2,4
                 *  3,2,1,4
                 *
                 *  Easy:
                 *  - combinations which don't include the correct answers in start or end position
                 *  .. the rest
                 *
                 *  4,3,2,1
                 *  3,4,1,2
                 *
                 */

                var possibleChoices = permutation([0,1,2,3]); // generate all possible choice combinations
                var correct = possibleChoices.splice(0,1); // the correct one is always the first, we simply remove it.

                var a;
                var z;
                var cset;
                var difficult = [];
                var easy = [];
                // iterate over the choice combinations and sort them into a difficult and easy list
                for (var i = 0; i < possibleChoices.length; i++) {
                    cset = possibleChoices[i];
                    a = cset[0];
                    z = cset[3];
                    if ((a === 0 || a === 1) && (z === 2 || z === 3)) {
                        difficult.push(cset);
                    } else if ((a === 0 || a === 1) || (z === 2 || z === 3)) {
                        easy.push(cset);
                    } else {
                        easy.push(cset);
                    }
                }

                difficult = shuffleArray(difficult);
                easy = shuffleArray(easy);

		c[0] = o[0]+' - '+o[1]+' - '+o[2]+' - '+o[3];
		c[1] = o[difficult[0][0]]+' - '+o[difficult[0][1]]+' - '+o[difficult[0][2]]+' - '+o[difficult[0][3]];
		c[2] = o[difficult[1][0]]+' - '+o[difficult[1][1]]+' - '+o[difficult[1][2]]+' - '+o[difficult[1][3]];
		c[3] = o[easy[0][0]]+' - '+o[easy[0][1]]+' - '+o[easy[0][2]]+' - '+o[easy[0][3]];

		right = c[0];
		return c;
	}
	drawangle(loc);
});


function randomInt(min,max)
{
    return Math.floor(Math.random()*(max-min+1)+min);
}
function shuffle(o){
    for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
    return o;
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

function permutation(a){
  var res = [];
  var restA;
  var rest;
  var j;
  var i;
  for(i=0; i<a.length; i++){
    restA = a.slice(0,i).concat(a.slice(i+1));
    rest = permutation( restA);
    if(rest.length === 0){
      res.push([a[i]]);
    }else{
      for(j=0; j<rest.length; j++){
	res.push([a[i]].concat(rest[j]));
      }
    }
  }
  return res;
}
