import styled from 'styled-components';

import {Column, Row} from '../../../../Grid';
import {H1, Body2} from '../../../../Typography/next';


export const UpgradeCallout = styled(Column)`
  height: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    background: ${({theme}) => theme.colors.brandPalette.blue.gradient};
    padding: ${({theme}) => theme.layouts.spacing.l};
  }
`;

export const UpgradeHeader = styled(H1)`
  margin: 0 auto ${({theme}) => theme.layouts.spacing.s};
  white-space: pre-wrap;
  text-align: center;

  ${({theme}) => theme.mediaQueries.laptopL} {
    font-size: ${({theme}) => theme.font.size.super};
    line-height: ${({theme}) => theme.font.height.super};
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    font-size: ${({theme}) => theme.font.size.xsuper};
    line-height: ${({theme}) => theme.font.height.xsuper};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.white};
    font-size: ${({theme}) => theme.font.size.super};
    line-height: ${({theme}) => theme.font.height.super};
  }
`;

export const UpgradeSubHeader = styled(Body2)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  text-align: center;
  margin: 0 auto;

  ${({theme}) => theme.mediaQueries.laptopL} {
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.height.medium};
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    font-size: ${({theme}) => theme.font.size.regular};
    line-height: ${({theme}) => theme.font.height.regular};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.small};
    line-height: ${({theme}) => theme.font.height.small};
    color: white;
  }
`;
export const UpgradeFeaturesTable = styled(Column)`
  height: auto;
  padding: ${({theme}) => theme.layouts.spacing.l} ${({theme}) => theme.layouts.spacing.none};
  background: ${({theme}) => theme.colors.neutralsPalette.white};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.xl} ${({theme}) => theme.layouts.spacing.l};
  }
`;

export const UpgradeFeaturesTableRow = styled(Row)`
  height: auto;
  align-items: center;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  &:last-child {
    margin-bottom: 0;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  }
`;

export const UpgradeFeaturesTableRowIconContainer = styled.div`
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  font-size: 0px;
  ${({theme}) => theme.mediaQueries.tablet} {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const UpgradeFeaturesTableRowText = styled(Body2)`

  ${({theme}) => theme.mediaQueries.laptopL} {
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.height.medium};
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    font-size: ${({theme}) => theme.font.size.regular};
    line-height: ${({theme}) => theme.font.height.regular};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.height.medium};
  }
`;