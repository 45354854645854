import {useRef, useState, useCallback, useEffect} from 'react';
import debounce from "debounce";
import { useHistory } from "react-router-dom";

import {useUserDataContext} from '../contexts/UserData';
import {getAllQuestionMastery, getTestProgressByUserIdHashTestId} from '@bootcamp/shared/src/requests';

export const useDebouncedState = (initialState, durationInMs = 250) => {
  const [internalState, setInternalState] = useState(initialState);
  const debouncedFunction = useCallback(debounce(setInternalState, durationInMs), []);
  return [internalState, debouncedFunction];
};

export const useTestProgress = (testId, formatter) => {
  const [progress, setProgress] = useState(null);
  const {DEFAULT_USER_ID, loading} = useUserDataContext();

  async function fetchTestProgress() {
    if (!DEFAULT_USER_ID) return setProgress([]);

    const data = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${testId}`, true);

    setProgress(formatter(data));
  }

  useEffect(() => {
    if (loading || !testId) return;

    fetchTestProgress();
  }, [DEFAULT_USER_ID, loading]);

  return [progress];

}

export const useTestProgressArray = (testIds, formatter) => {
  const [progresses, setProgresses] = useState(null);
  const {DEFAULT_USER_ID, loading} = useUserDataContext();

  async function fetchTestProgress(testId) {
    if (!DEFAULT_USER_ID) return setProgresses([]);

    const data = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${testId}`, true);

    return data;
  }

  async function fetchTestProgresses() {
    const data = await Promise.all(testIds.map(fetchTestProgress));

    setProgresses(formatter(data));
  }

  useEffect(() => {
    if (loading || !testIds?.length) return;

    fetchTestProgresses();
  }, [DEFAULT_USER_ID, loading]);

  return [progresses];

};

export const useMastery = (contentId, contentType, formatter) => {
  const [progress, setProgress] = useState(null);
  const {DEFAULT_USER_ID, loading} = useUserDataContext();

  const hashKey = {
    subject: 'userIdHashSubjectTagId',
    subjectWithQuestionBaseId: 'userIdHashSubjectTagId',
    subjectWithQuestionTags: 'userIdHashSubjectTagId',
    topic: 'userIdHashTopicTagId',
    test: 'userIdHashTestId',
    testSmall: 'userIdHashTestId',
  }[contentType];

  async function fetchProgress() {
    if (!DEFAULT_USER_ID) return setProgress([]);

    const data = await getAllQuestionMastery({
      [hashKey]: `${DEFAULT_USER_ID}#${contentId}`
    }, contentType);

    setProgress(formatter ? formatter(data) : data);
  }

  useEffect(() => {
    if (loading || !contentId) return;

    fetchProgress();
  }, [DEFAULT_USER_ID, loading, contentId]);

  return [progress];
}

export const useMasteryArray = (contentIds, contentType, formatter, type, callback, userIdOverride=null) => {
  const [progresses, setProgresses] = useState({});
  const {DEFAULT_USER_ID, loading} = useUserDataContext();

  const hashKey = {
    subject: 'userIdHashSubjectTagId',
    subjectWithQuestionBaseId: 'userIdHashSubjectTagId',
    subjectWithQuestionTags: 'userIdHashSubjectTagId',
    topic: 'userIdHashTopicTagId',
    test: 'userIdHashTestId',
    testSmall: 'userIdHashTestId',
  }[contentType];
  const userId = userIdOverride || DEFAULT_USER_ID;

  async function fetchProgress() {
    if (!userId) return setProgresses({});

    const results = await contentIds.reduce(async (acc, contentId) => {
      const data = await getAllQuestionMastery({
        [hashKey]: `${userId}#${contentId}`,
        limit: 1000
      }, contentType);
      return {...(await acc), [contentId]: formatter ? formatter(data) : data}
    }, {});

    const update = type ? {...progresses, [type]: results} : results;

    setProgresses(update);

    // hooking in for componennt loading states
    if (typeof callback === 'function') {
      callback();
    }
  }

  useEffect(() => {
    if (loading || !contentIds || progresses[type]) return;

    fetchProgress();
  }, [userId, loading, type]);

  return progresses;
}


export const useAsync = (asyncFunction, immediate = true) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setPending(true);
    setValue(null);
    setError(null);
    return asyncFunction()
      .then(response => setValue(response))
      .catch(error => setError(error))
      .finally(() => setPending(false));
  }, [asyncFunction]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
};

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export function useHistoryState(key, initialValue) {
  const history = useHistory();
  const [rawState, rawSetState] = useState(() => {
    const value = history.location.state?.[key];
    return value ?? initialValue;
  });
  function setState(value) {
    history.replace({
      ...history.location,
      state: {
        ...history.location.state,
        [key]: value
      }
    });
    rawSetState(value);
  }
  return [rawState, setState];
}
