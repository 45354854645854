import React from 'react';
import styled from 'styled-components';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {PrometricWrapper, Heading, Section, NextText} from './shared';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/shared/src/util/contexts/Timer';
import {getTestBlockConnections, getTestBlockQuestions} from '@bootcamp/shared/src/util';
import {
  SettingsContainer,
  SettingsWrapper,
  SettingsHeader,
  Setting,
  SettingWrapper,
  Toggle,
} from './shared';

import "rc-switch/assets/index.css";

const Container = styled.div`
  width: 100%;
`;
const Citation = styled.p`
`;
const CitationContainer = styled.div`
  font-size: 12px;
  color: #999;
`;
const CitationsContainer = styled.div`
  font-size: 12px;
  color: #999;
  margin-top: 20px;
  line-height: normal;
  a {
    color: #46b1e1;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #248fbf;
    }
  }
`;

const Citations = ({testTitle}) => {
  function getCitations () {
    switch (testTitle) {
      case "Reading Comprehension Exam #6":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Hepcidin<br />
              Michels K, Nemeth E, Ganz T, Mehrad B (2015) Hepcidin and Host Defense against Infectious Diseases. PLoS Pathog 11(8): e1004998. doi:10.1371/journal.ppat.1004998
            </Citation>
            <Citation>
              Passage 2: Is Sleep Essential<br />
              Cirelli C, Tononi G (2008) Is Sleep Essential? PLoS Biol 6(8): e216. doi:10.1371/journal.pbio.0060216
            </Citation>
            <Citation>
              Passage 3: Venom<br />
              Maduwage K, Isbister GK (2014) Current Treatment for Venom-Induced Consumption Coagulopathy Resulting from Snakebite. PLoS Negl Trop Dis 8(10): e3220. doi:10.1371/journal.pntd.0003220
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #7":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Locusts<br />
              Ariel G, Ayali A (2015) Locust Collective Motion and Its Modeling. PLoS Comput Biol 11(12): e1004522. doi:10.1371/journal.pcbi.1004522
            </Citation>
            <Citation>
              Passage 2: Evolution Through Teeth<br />
              Moncrieff J (2015) Antipsychotic Maintenance Treatment: Time to Rethink? PLoS Med 12(8): e1001861. doi:10.1371/journal.pmed.1001861
            </Citation>
            <Citation>
              Passage 3: Antipsychotic Treatment<br />
              Christopher Dean M. Tooth microstructure tracks the pace of human life-history evolution. Proceedings of the Royal Society B: Biological Sciences. 2006;273(1603):2799-2808. doi:10.1098/rspb.2006.3583
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #8":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Wolves<br />
              Dobson AP (2014) Yellowstone Wolves and the Forces That Structure Natural Systems. PLoS Biol 12(12): e1002025. doi:10.1371/journal.pbio.1002025
            </Citation>
            <Citation>
              Passage 2: HIV ART<br />
              Gray GE, Laher F, Doherty T, Abdool Karim S, Hammer S, Mascola J, et al. (2016) Which New Health Technologies Do We Need to Achieve an End to HIV/AIDS? PLoS Biol 14(3): e1002372. doi:10.1371/journal.pbio.1002372
            </Citation>
            <Citation>
              Passage 3: Bacteria Viruses<br />
              Bosch AATM, Biesbroek G, Trzcinski K, Sanders EAM, Bogaert D (2013) Viral and Bacterial Interactions in the Upper Respiratory Tract. PLoS Pathog 9(1): e1003057. doi:10.1371/journal.ppat.1003057
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #9":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: 3D Printing<br />
              Miller JS (2014) The Billion Cell Construct: Will Three-Dimensional Printing Get Us There? PLoS Biol 12(6): e1001882. doi:10.1371/journal.pbio.1001882
            </Citation>
            <Citation>
              Passage 2: Leishmaniasis<br />
              Salam N, Al-Shaqha WM, Azzi A (2014) Leishmaniasis in the Middle East: Incidence and Epidemiology. PLoS Negl Trop Dis 8(10): e3208. doi:10.1371/journal.pntd.0003208
            </Citation>
            <Citation>
              Passage 3: Antibiotic Resistance<br />
              Meek RW, Vyas H, Piddock LJV. Nonmedical Uses of Antibiotics: Time to Restrict Their Use? PLoS Biology. 2015;13(10):e1002266. doi:10.1371/journal.pbio.1002266.
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #10":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Marine Reptiles<br />
              Rasmussen AR, Murphy JC, Ompi M, Gibbons JW, Uetz P (2011) Marine Reptiles. PLoS ONE 6(11): e27373. doi:10.1371/journal.pone.0027373
            </Citation>
            <Citation>
              Passage 2: Strongyloides<br />
              Puthiyakunnon S, Boddu S, Li Y, Zhou X, Wang C, Li J, et al. (2014) Strongyloidiasis—An Insight into Its Global Prevalence and Management. PLoS Negl Trop Dis 8(8): e3018. doi:10.1371/journal.pntd.0003018
            </Citation>
            <Citation>
              Passage 3: Digital Epidemiology<br />
              Salathé M, Bengtsson L, Bodnar TJ, Brewer DD, Brownstein JS, Buckee C, et al. (2012) Digital Epidemiology. PLoS Comput Biol 8(7): e1002616. doi:10.1371/journal.pcbi.1002616
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #11":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Influenza Transmission<br />
              Tellier, Raymond. "Review of aerosol transmission of influenza A virus." Emerging infectious diseases 12.11 (2006): 1657.
            </Citation>
            <Citation>
              Passage 2: Piltdown Man<br />
              Weiner, J.S. "Piltdown Man." Piltdown Man. ClarkU, n.d. Web.
            </Citation>
            <Citation>
              Passage 3: Atmospheric Physics<br />
              Weinberger, Sharon. "Atmospheric Physics: Heating up the Heavens." Nature.com. Nature Publishing Group, 23 Apr. 2008. Web.
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #12":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Dung Beetle Ecology<br />
              Tomas Roslin and Heidi Viljanen Dung Beetle Populations: Structure and Consequences. Ecology and Evolution of Dung Beetles, First Edition. Edited by Leigh W. Simmons and T. James Ridsdill-Smith. © 2011 Blackwell Publishing Ltd. Published 2011 by Blackwell Publishing Ltd
            </Citation>
            <Citation>
              Passage 2: Forensic Analysis of Bony Tissue<br />
              Parks, Connie L., "Oxygen Isotope Analysis of Human Bone and Tooth Enamel: Implications for Forensic Investigations" (2009).
              Theses and Dissertations-Anthropology.
            </Citation>
            <Citation>
              Passage 3: The Net<br />
              From Chapter Three of The Accidental History of the Net; by Howard Rheingold
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #13":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Greenland Shark<br />
              "GEERG - Greenland Shark." GEERG - Greenland Shark. GEERG, n.d. Web.
            </Citation>
            <Citation>
              Passage 2: Mitochondrial DNA<br />
              Scientific American, August 1997; "Mitochondrial DNA in Aging and Disease";
              p. 41 - 43; by Douglas C. Wallace
            </Citation>
            <Citation>
              Passage 3: Celestial Origins<br />
              Excerpt from The Illustrated A Brief History of Time; pp. 147-152; Copyright 1988, 1996 by Stephen Hawking
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #14":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Evolutionary Biology of Penguins<br />
              Fordyce, R., & Ksepka, D. T. (2012). THE STRANGEST BIRD. Scientific American, 307(5), 56-61.
            </Citation>
            <Citation>
              Passage 2: Natural Medicine Extraction<br />
              Lang, Qingyong, and Chien M. Wai. "Supercritical fluid extraction in herbal and natural product studies—a practical review." Talanta 53.4 (2001): 771-782.
            </Citation>
            <Citation>
              Passage 3: Black Swifts<br />
              Nijhuis, M. (2012). Secrets of the Swift. Smithsonian, 43(6), 33-36.
            </Citation>
          </CitationContainer>
        )
      case "Reading Comprehension Exam #15":
        return (
          <CitationContainer>
            <Citation>
              Passage 1: Subatomic Particles and Forces<br />
              Excerpt from The Illustrated A Brief History of Time; pp. 85-87; Copyright 1988, 1996 by Stephen Hawking
            </Citation>
            <Citation>
              Passage 2: Spiral Bacteria in the Human Stomach<br />
              Dubois, Andre. "Spiral bacteria in the human stomach: the gastric helicobacters." Emerging infectious diseases 1.3 (1995): 79.
            </Citation>
            <Citation>
              Passage 3: Issue of Classification<br />
              Excerpt from "And Still We Evolve: A Handbook on the History of Modern Science” (prepared by Ian Johnston of Vancouver Island University)
            </Citation>
          </CitationContainer>
        )
      default:
        return null
    }
  }
  const allCitations = getCitations();
  if (!allCitations) return null;
  return (
    <CitationsContainer>
      <div>The passages found in this practice test were adapted from the following sources:</div>
      {allCitations}
    </CitationsContainer>
  )
}



export const Settings = () => {
  const {saveUserInteraction, searchUserInteractions, bootcamp} = useUserDataContext();
  const prometricDelay = searchUserInteractions('prometricDelay') === true;
  const timeAccommodation = searchUserInteractions('timeAccommodation') === true;

  const {timeLimit, updateTimeLimit} = useTimerContext();

  function handleTimeAccommodationChange() {
    const newaccommodationValue = !timeAccommodation;
    const newTimeLimit = newaccommodationValue ? timeLimit * 1.5 : timeLimit / 1.5;

    updateTimeLimit(newTimeLimit)
    saveUserInteraction('timeAccommodation', newaccommodationValue);
  }

  return (
    <SettingsContainer>
      <SettingsWrapper>
        <SettingsHeader>
          Test Settings
        </SettingsHeader>
        <Setting>
          <Toggle checked={prometricDelay} onChange={() => saveUserInteraction('prometricDelay', !prometricDelay)}/>
          <SettingWrapper>
            <strong>Prometric Delay:</strong> Add a ~2 second delay between questions and when clicking review, similar to the real {bootcamp == 'oat' ? 'OAT' : 'DAT'} at the Prometric Center.
          </SettingWrapper>
        </Setting>
        <Setting>
          <Toggle checked={timeAccommodation} onChange={handleTimeAccommodationChange}/>
          <SettingWrapper>
            <strong>Time Accommodations:</strong> Get an additional 1.5x time for students who have special accommodations approved by the ADA. (If you need more time, right-click the test timer during the test to set a custom time limit).
          </SettingWrapper>
        </Setting>
      </SettingsWrapper>
    </SettingsContainer>
  );
};

const StartBlock = () => {
  const {variables: {test, quizProgress}} = useTestNavigatorContext();
  const {timeLimit: baseTimeLimit} = useTimerContext();
  const testBlocks = getTestBlockConnections(test);
  const questionCount = testBlocks.reduce((acc, {testBlock}) => acc + getTestBlockQuestions(testBlock).length, 0);

  const testTitle = test && test.config.title;
  const testSubject = testTitle && testTitle.match(/([^#]+)/)[0];
  const timeLimit = baseTimeLimit || 30;

  const Instructions = () => {
    switch (testSubject) {
      case "Quantitative Reasoning":
        return (
          <ol>
            <li>You will have {timeLimit} minutes to finish {questionCount} questions.</li>
            <li>Use the on-screen calculator for practice on the real OAT. It's identical to what you'll get test day.</li>
            <li>You will have a chance to review the exam before completing it.</li>
            <li>Your score analysis will appear after finishing the exam.</li>
          </ol>
        )
      case "Reading Comprehension":
        return (
          <ol>
            <li>You will have {timeLimit} minutes to finish {questionCount} questions.</li>
            <li>You will have a chance to review the exam before completing it.</li>
            <li>Your score analysis will appear after finishing the exam.</li>
            <li>On the real OAT, you will be able to highlight text in the passage.</li>
          </ol>
        )
      case "Organic Chemistry":
      case "General Chemistry":
        return (
          <ol>
            <li>You will have {timeLimit} minutes to finish {questionCount} questions.</li>
            <li>A periodic table is provided under the "Exhibit" button.</li>
            <li>You will have a chance to review the exam before completing it.</li>
            <li>Your score analysis will appear after finishing the exam.</li>
          </ol>
        )
      default:
        return (
          <ol>
            <li>You will have {timeLimit} minutes to finish {questionCount} questions.</li>
            <li>You will have a chance to review the exam before completing it.</li>
            <li>Your score analysis will appear after finishing the exam.</li>
          </ol>
        )
    }
  }
  return (
    <Container>
      <PrometricWrapper>
        <Heading>
          This is {testTitle}. Please read the following before starting:
        </Heading>
        <Section>
          <Instructions />
        </Section>
        <NextText />
        <Citations testTitle={testTitle} />
      </PrometricWrapper>
      <Settings/>
    </Container>
  )
};

export default StartBlock;
