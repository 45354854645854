import React from 'react';
import styled from 'styled-components';

import QBank from './components/QBank';
import scrollArrow from '@bootcamp/web/src/components/MCATBank/assets/scroll-arrow.svg';
import scrollArrowUp from '@bootcamp/web/src/components/MCATBank/assets/scroll-arrow-up.svg';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background: white;

  * ::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  * ::-webkit-scrollbar-track {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.50) inset;
    background: white;
  }

  /* Handle */
  * ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.prometricPalette.mcat.light};
  }

  /* Buttons */
  * ::-webkit-scrollbar-button:start:decrement {
    background-image: url(${scrollArrowUp});
    background-color: ${({theme}) => theme.colors.prometricPalette.mcat.light};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
  }
  * ::-webkit-scrollbar-button:start:increment {
    height: 1px;
  }
  * ::-webkit-scrollbar-button:end:increment {
    background-image: url(${scrollArrow});
    background-color: ${({theme}) => theme.colors.prometricPalette.mcat.light};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
  }
  * ::-webkit-scrollbar-button:end:decrement {
    height: 1px;
  }
`;


const MCATQBank = () => {
  return (
    <Container>
      <QBank />
    </Container>
  );
};

export default MCATQBank;