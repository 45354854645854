import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Column, Row} from '../Grid';
import {GutterColumn} from '../Spacing';
import PieChart from '../PieChart';
import moment from 'moment';
import firstBy from 'thenby';
import {Body3, H1, H2, H3} from '@bootcamp/web/src/components/Typography';
import {getInObj} from '@bootcamp/shared/src/util';
import styled, {css} from 'styled-components';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import ProgressBar from '@bootcamp/web/src/components/ProgressBar';
import {TextTooltip} from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/shared';
import {FilterSelector} from '../QBank/SidebarTop';
import {Blurb} from '@bootcamp/web/src/bootcamps/pages/CustomTestReview/components/shared.js'
import baseTheme from '@bootcamp/shared/src/styles/theme';

import {getResults, getOATResults, aggregateByTopic, aggregateByOATSection, aggregateBySection, getAcademicAverage, getOATAcademicAverage, scoreResult} from '@bootcamp/shared/src/util/scoring';
import ScoreDistributionChart from '@bootcamp/web/src/components/Charts/ScoreDistribution';

const ScoreComparisonSubjectSelector = styled(FilterSelector)`
  width: auto;
  min-width: auto;
  margin: 0px 0px 0px 8px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;

function getFullLengthAnalysis(educator, testTitle, statistics, correct, total) {
  const academicAverage = getAcademicAverage(testTitle, statistics);

  return (
    <Blurb
      icon={educator.imgUrl}
      text={
        <AnalysisContent>
          Based on your results, I'd <AnalysisScore>estimate an AA score of {academicAverage}</AnalysisScore>. Don't focus too much on the estimate - instead, continue to review your areas of knowledge.
        </AnalysisContent>
      }
    />
  );
}

function getAnalysis(educator, testTitle, statistics, correct, total) {
  const testSubject = testTitle.match(/([^#]+)( Test)/)[1];
  const percentage = (correct / total) * 100;
  const {score, analysis} = getResults(percentage, testTitle, testSubject);

  return (
    <Blurb
      icon={educator.imgUrl}
      text={
        <AnalysisContent>
          {analysis}<br /><br />
          Based on your results, I'd <AnalysisScore>estimate a DAT {testSubject} score of {score}</AnalysisScore>. Don't focus too much on the estimate - instead, continue to review your areas of knowledge.
        </AnalysisContent>
      }
    />
  )
}

function getOATAnalysis(educator, testTitle, statistics, correct, total) {
  const testSubject = testTitle.match(/([^#]+)( Test)/)[1];
  const percentage = (correct / total) * 100;
  const score = getOATResults(percentage, testTitle, testSubject);
  return (
    <Blurb
      icon={educator.imgUrl}
      text={
        <AnalysisContent>
          Based on your results, I'd <AnalysisScore>estimate an OAT {testSubject} score of {score}</AnalysisScore>. Don't focus too much on the estimate - instead, continue to review your areas of knowledge.
        </AnalysisContent>
      }
    />
  )
}

function getOATFullLengthAnalysis(educator, testTitle, statistics, correct, total) {
  const analysis = `Keep your head up! Review these solutions carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice.`;
  const academicAverage = getOATAcademicAverage(testTitle, statistics);

  return (
    <Blurb
      icon={educator.imgUrl}
      text={
        <AnalysisContent>
          {analysis}<br /><br />
          Based on your results, I'd <AnalysisScore>estimate an OAT score of {academicAverage}</AnalysisScore>. Don't focus too much on the estimate - instead, continue to review your areas of knowledge.
        </AnalysisContent>
      }
    />
  );
}

function getSnsChartData(bio, gc, oc) {
  const bioTime = getInObj(['result', 0, 'questions'], bio, []).reduce((acc, {time}) => acc + parseFloat(time), 0);
  const gcTime = getInObj(['result', 0, 'questions'], gc, []).reduce((acc, {time}) => acc + parseFloat(time), 0);
  const ocTime = getInObj(['result', 0, 'questions'], oc, []).reduce((acc, {time}) => acc + parseFloat(time), 0);

  return [
    ['Subject', 'Time'],
    [`Biology ${moment.utc(bioTime * 1000).format('HH:mm:ss')}`, bioTime],
    [`General Chemistry ${moment.utc(gcTime * 1000).format('HH:mm:ss')}`, gcTime],
    [`Organic Chemistry ${moment.utc(ocTime * 1000).format('HH:mm:ss')}`, ocTime]
  ]
}

function getTopicChartData(statistics) {
  return [
    ['Time Spent', 'Topic'],
    ...statistics.map(({name, timeSpent}) => [`${name} ${moment.utc(timeSpent * 1000).format('HH:mm:ss')}`, timeSpent])
  ];
}

const Educator = ({name, imgUrl}) => (
  <EducatorContainer>
    <CircleImage src={imgUrl}/>
    <EducatorDetail>
      <span>Feedback from <strong>{name}</strong></span>
    </EducatorDetail>
  </EducatorContainer>
);

const Metric = ([name, metric], index) => (
  <MetricWrapper key={`breakdown-metric-${index}`}>
    <MetricTop>{metric}</MetricTop>
    <MetricBottom>{name}</MetricBottom>
  </MetricWrapper>
);

const StatRow = ({statistic, index, onClick}) => {
  const {name, timeSpent, timePerQuestion, total, correct, learning, reviewing, mastered} = statistic;
  const highlight = (parseInt(correct) / parseInt(total)) <= 0.5;

  const rowProps = onClick ? {onClick} : {};

  return (
    <TableRow even={(index % 2) !== 0} hasClickHandler={!!onClick} {...rowProps}>
      <Cell highlight={highlight}>
        <Column>
          {name}
        </Column>
      </Cell>
      <Cell>{moment.utc(timeSpent * 1000).format('HH:mm:ss')}</Cell>
      <Cell><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{width: 42}}>{Math.round((correct / total) * 100)}%</span> {<ProgressBar withMargin={false} showLabel={false} total={100} segments={[{count: Math.round((correct / total) * 100), color: baseTheme.colors.brandPalette.blue.gradient}]} />}</div></Cell>
      <Cell>{moment.utc(timePerQuestion * 1000).format('HH:mm:ss')}</Cell>
    </TableRow>
  );
};

const ScoreReportContainer = styled.div`
  font-family: Arial, Helvetica Neue, sans-serif;
  width: 100%;
  max-width: 864px;
  margin: 0 auto 24px;
  padding: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
  background: white;
`;
const TableCell = styled(Row)`
  word-break: break-word;
`

const HalfRow = styled(Row)`
  width: 50%;
`

const BoldCell = styled(TableCell)`
  width: 130px;
  min-width: 130px;
  font-weight: 700;
`

const ScoreReportSection = styled.div`
  margin-bottom: 24px;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-wrap: wrap;
  }
`;
const ScoreHeader = styled(Row)`
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`
const ScoreHeaderCell = styled(HalfRow)`
  margin-bottom: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
    width: 100%;
  }
`
const ScoreColumn = styled.div`
  text-align: center;
`

const StartReview = ({testTitle, result, isFullLength, dateTaken, isOAT=false}) => {
  const [subjectScoreComparison, setSubjectScoreComparison] = useState('Biology');
  const {cognitoUser, searchUserInteractions, saveUserInteraction, bootcamp, DEFAULT_USER_ID} = useUserDataContext();
  const {
    methods: {
      setNavigationFilter
    },
    variables: {
      test
    }
  } = useTestNavigatorContext();
  const urlParams = new URLSearchParams(window.location.search);
  const testProgressId = urlParams.get('testProgressId');

  const interactionKey = `${bootcamp}_performance`
  const performanceRecord = searchUserInteractions(interactionKey);

  const testNumber = testTitle.match(/#(.*)/)[1];

  useEffect(() => {
    if (!DEFAULT_USER_ID) return;
    if (!performanceRecord.createdAt) return;
    const originalCount = performanceRecord.results.length
    let newPerformanceRecord = {...performanceRecord}

    const testSubject = testTitle.match(/([^#]+)( Test)/)[1];

    const allResults = [];

    if (isFullLength) {
      const bioResult = {testTitle: `Biology Test #${testNumber}`, result: result.slice(0, 1)};
      const gcResult = {testTitle: `General Chemistry Test #${testNumber}`, result: result.slice(1, 2)};
      const ocResult = {testTitle: `Organic Chemistry Test #${testNumber}`, result: result.slice(2, 3)};
      const patResult = {testTitle: `Perceptual Ability Test #${testNumber}`, result: result.slice(3, 4)};
      const rcResult = {testTitle: `Reading Comprehension Test #${testNumber}`, result: result.slice(4, 7)};
      const qrResult = {testTitle: `Quantitative Reasoning Test #${testNumber}`, result: result.slice(7, 8)};
      allResults.push(bioResult, gcResult, ocResult, patResult, rcResult, qrResult);
    } else {
      allResults.push({testTitle, result});
    }

    for (const result of allResults) {
      const {testTitle, result: selectedResult} = result;
      const testSubject = testTitle.match(/([^#]+)( Test)/)[1];

      const relevantTestPerformanceRecord = performanceRecord.results.find(({testSubject: subject, testNumber: number}) => subject === testSubject && number === testNumber);

      if (relevantTestPerformanceRecord && (performanceRecord.sortOrder === 'DESC' ? relevantTestPerformanceRecord.createdAt >= dateTaken : relevantTestPerformanceRecord.createdAt <= dateTaken)) continue;
      newPerformanceRecord.results = newPerformanceRecord.results.filter(({testSubject: subject, testNumber: number}) => subject !== testSubject || number !== testNumber)
      const scoredResult = scoreResult(selectedResult, testTitle, testSubject, testNumber, dateTaken);
      scoredResult.testId = test.id
      scoredResult.testProgressId = testProgressId

      newPerformanceRecord.results.push(scoredResult);
    }

    originalCount != newPerformanceRecord.results.length && saveUserInteraction(interactionKey, JSON.stringify(newPerformanceRecord));
  }, []);

  if (!result.length) return null;

  const IS_OAT_STUDENT = (window.datbootcamp_plugin_obj && window.datbootcamp_plugin_obj.is_oat_student) || isOAT || false;
  const header = (
    <Header>
      <Title>{testTitle}</Title>
      <UserDetails>
        <SubTitle>Name: {cognitoUser.getUsername()}</SubTitle>
        <SubTitle>Date: {moment(dateTaken).format("dddd, MMMM Do")}</SubTitle>
      </UserDetails>
    </Header>
  )
  if (testTitle.includes('Passage Bank')) {
    const {
      correct,
      total,
      markedQuestions,
      statistics,
    } = aggregateByTopic(result);

    const metrics = [
      ['Total Correct', `${correct} / ${total}`],
      ['Questions Marked', markedQuestions],
    ];
    const {name, total: statTotal, correct: statCorrect} = statistics?.[0] || {};
    const highlight = (parseInt(correct) / parseInt(total)) <= 0.5;

    const breakdownTable = (
      <TopicBreakdown>
        <SectionHeader>Topic breakdown</SectionHeader>
        <Table>
          <tbody>
            <TableRow>
              <TableHeader>Topic</TableHeader>
              <TableHeader># Correct</TableHeader>
            </TableRow>
            <TableRow key={`breakdown-table-row-${0}`} even={true}>
              <Cell highlight={highlight}>{name}</Cell>
              <Cell>{`${statCorrect} / ${statTotal}`}</Cell>
            </TableRow>
          </tbody>
        </Table>
      </TopicBreakdown>
    );

    return (
      <Container>
        {header}
        <Scoring>
          <SectionHeader>Score Report</SectionHeader>
          <Row>
            <ScoreBar correctPercentage={correct/total} />
            <MetricContainer>{metrics.map(Metric)}</MetricContainer>
          </Row>
        </Scoring>
        {breakdownTable}
      </Container>
    );
  }

  if (isFullLength) {
    if (!testTitle.includes('OAT')) {
      const {
        educator,
        correct,
        total,
        timeSpent,
        timePerQuestion,
        markedQuestions,
        statistics,
      } = aggregateBySection(result);

      const metrics = [
        ['Academic Average', getAcademicAverage(testTitle, statistics)],
        ['Time Spent', moment.utc(timeSpent * 1000).format('HH:mm:ss')],
        ['Per Question', moment.utc(timePerQuestion * 1000).format('HH:mm:ss')],
        ['Questions Marked', markedQuestions],
      ];

      const bioResult = {name: 'Biology', testTitle: `Biology Test #${testNumber}`, result: result.slice(0, 1)};
      const gcResult = {name: 'General Chemistry', testTitle: `General Chemistry Test #${testNumber}`, result: result.slice(1, 2)};
      const ocResult = {name: 'Organic Chemistry', testTitle: `Organic Chemistry Test #${testNumber}`, result: result.slice(2, 3)};
      const patResult = {name: 'Perceptual Ability', testTitle: `Perceptual Ability Test #${testNumber}`, result: result.slice(3, 4)};
      const rcResult = {name: 'Reading Comprehension', testTitle: `Reading Comprehension Test #${testNumber}`, result: result.slice(4, 7)};
      const qrResult = {name: 'Quantitative Reasoning', testTitle: `Quantitative Reasoning Test #${testNumber}`, result: result.slice(7, 8)};

      const topicScores = [bioResult, gcResult, ocResult, patResult, rcResult, qrResult].map(({name, testTitle, result: selectedResult}) => {
        const {
          correct,
          total,
          statistics: selectedResultStatistics
        } = aggregateByTopic(selectedResult);

        const testSubject = testTitle.match(/([^#]+)( Test)/)[1];
        const percentage = (correct / total) * 100;
        const {score} = getResults(percentage, testTitle, testSubject);

        return {
          name,
          testTitle,
          score,
          testSubject,
          percentage,
          selectedResultStatistics,
          correct,
        }
      });

      const activeSubjectComparison = topicScores.find(({name}) => name === subjectScoreComparison);

      const breakdownTable = (
        <TopicBreakdown>
          <SectionHeader>Section breakdown</SectionHeader>
          <Table>
            <tbody>
              <TableRow>
                <TableHeader>Section</TableHeader>
                <TableHeader>Time Spent</TableHeader>
                <TableHeader>Score</TableHeader>
                <TableHeader>Per Question</TableHeader>
              </TableRow>
              {statistics.map((statistic, index) => <StatRow key={`statistic-row-${index}`} statistic={statistic} index={index}/>)}
            </tbody>
          </Table>
        </TopicBreakdown>
      );

      const topicBreakdowns = topicScores.map(({
        name,
        score,
        testSubject,
        selectedResultStatistics,
      }) => {

        // insert chart
        const addSnsChart = testSubject === 'Organic Chemistry';

        return (
          <TopicBreakdown chartActive={addSnsChart}>
            <SectionHeaderWrap>
              <SectionHeader>{testSubject} breakdown</SectionHeader>
              <SectionHeader>Estimated DAT {testSubject} Score: {score}</SectionHeader>
            </SectionHeaderWrap>
            <Table>
              <tbody>
                <TableRow>
                  <TableHeader>Topic</TableHeader>
                  <TableHeader>Time Spent</TableHeader>
                  <TableHeader>Score</TableHeader>
                  <TableHeader>Per Question</TableHeader>
                </TableRow>
                {selectedResultStatistics
                  .sort(firstBy('total', -1))
                  .map((statistic, index) => <StatRow key={`statistic-row-${index}`} statistic={statistic} index={index} onClick={() => setNavigationFilter({key: 'subjectTopic', value: `${name}##${statistic.name}`})} />)
                }
              </tbody>
            </Table>
            {addSnsChart &&
              <PieChart
                title={'time spent per SNS subject'}
                data={getSnsChartData(bioResult, gcResult, ocResult)}
              />
            }
          </TopicBreakdown>
        )
      });

      return (
        <Container>
          {header}
          <Scoring>
            <SectionHeader>Score Report</SectionHeader>
            <ScoreReportContainer>
              <div style={{textAlign: 'center', fontWeight: 700, fontSize: 24, lineHeight: '30px', marginBottom: 24}}>
                Dental Admission Test<br/>
                Unofficial Score Report
              </div>
              <ScoreReportSection>
                <ScoreHeader>
                  <ScoreHeaderCell>
                    <BoldCell>Name:</BoldCell>
                    <TableCell>{cognitoUser?.attributes?.name || cognitoUser.getUsername()}</TableCell>
                  </ScoreHeaderCell>
                  <ScoreHeaderCell>
                    <BoldCell>Testing Center:</BoldCell>
                    <TableCell>DAT Bootcamp</TableCell>
                  </ScoreHeaderCell>
                </ScoreHeader>
                <ScoreHeader>
                  <ScoreHeaderCell>
                    <BoldCell>DENTPIN®:</BoldCell>
                    <TableCell>******</TableCell>
                  </ScoreHeaderCell>
                  <ScoreHeaderCell>
                    <BoldCell>Date:</BoldCell>
                    <TableCell>{moment(dateTaken).format("dddd, MMMM Do")}</TableCell>
                  </ScoreHeaderCell>
                </ScoreHeader>
              </ScoreReportSection>
              <Row style={{marginBottom: 24}}>
                <HalfRow style={{textAlign: 'center', marginRight: 8}}>
                  <div style={{maxWidth: 300}}>
                    <strong><em>DENTAL ADMISSION TESTING PROGRAM</em></strong><br/>
                    EXAMINEE'S REPORT OF UNOFFICIAL SCORES
                  </div>
                </HalfRow>
                <HalfRow>
                  <div>
                    <strong>American Dental Association</strong><br/>
                    Department of Testing Services<br/>
                    211 East Chicago Avenue<br/>
                    Chicago, Illinois 60611-2637
                  </div>
                </HalfRow>
              </Row>
              <ScoreReportSection>
                <strong>Important Score Reporting Information:</strong> Please note, your scores will not be sent to any school(s) you selected on your DAT application or to AADSAS and/or TMDSAS until your scores are officially posted to your My Account. <strong>Reporting generally takes 2-3 weeks from your exam date, but can take up to 4 weeks.</strong>
              </ScoreReportSection>
              <ScoreReportSection>
                <strong>Exam Retake:</strong> If you decide to retake the exam, you will not be able to submit a new DAT application until your scores are officially posted to your My Account.
              </ScoreReportSection>
              <Row style={{fontWeight: 700, marginBottom: 24}}>
                <HalfRow>
                  <div>
                    <br/>
                    Perceptual Ability<br />
                    Quantitative Reasoning<br />
                    Reading Comprehension<br />
                    Biology<br />
                    General Chemistry<br />
                    Organic Chemistry<br />
                    Total Science<br />
                    Academic Average
                  </div>
                </HalfRow>
                <HalfRow style={{justifyContent: 'center'}}>
                  <ScoreColumn>
                    <span style={{textDecoration: 'underline'}}>Standard Score</span><br/>
                    {topicScores[3].score}<br/>
                    {topicScores[5].score}<br/>
                    {topicScores[4].score}<br/>
                    {topicScores[0].score}<br/>
                    {topicScores[1].score}<br/>
                    {topicScores[2].score}<br/>
                    {Math.round((parseInt(topicScores[0].score) + parseInt(topicScores[1].score) + parseInt(topicScores[2].score)) / 3)}<br/>
                    {getAcademicAverage(testTitle, statistics)}
                  </ScoreColumn>
                </HalfRow>
              </Row>
              <ScoreReportSection>
                <strong>Standard scores</strong> used in the testing program range from 1 to 30. Only the standard scores are reported to the dental schools. Through the use of standard scores, it is possible to compare the performance of examinees taking different editions of the Dental Admission Test.
              </ScoreReportSection>
              <ScoreReportSection>
                The <strong>Academic Average</strong> is the average of five scores rounded to the nearest whole number: Quantitative Reasoning, Reading Comprehension, Biology, General Chemistry, and Organic Chemistry.
              </ScoreReportSection>
              <div>
                The <strong>Total Science</strong> score is a standard score based on all 100 questions in the Biology, General Chemistry, and Organic Chemistry tests. It is not the average of the three science scores.
              </div>
            </ScoreReportContainer>
            {/* <Row>
              <ScoreBar correctPercentage={correct/total} />
              <MetricContainer>{metrics.map(Metric)}</MetricContainer>
            </Row> */}
            {testNumber <= 10 && <DistributionChartContainer>
              <ScoreComparisonSectionHeader>
                Subject Comparison
              <ScoreComparisonSubjectSelector  onChange={event => setSubjectScoreComparison(event.target.value)}>
                <option value="Biology">Biology</option>
                <option value="General Chemistry">General Chemistry</option>
                <option value="Organic Chemistry">Organic Chemistry</option>
                <option value="Perceptual Ability">Perceptual Ability</option>
                <option value="Reading Comprehension">Reading Comprehension</option>
                <option value="Quantitative Reasoning">Quantitative Reasoning</option>
              </ScoreComparisonSubjectSelector>
                <TextTooltip id="score-report-tooltip">See how your subject scores compare to all other students who have taken this test.</TextTooltip>
              </ScoreComparisonSectionHeader>
              <ScoreDistributionChart name={activeSubjectComparison.testTitle} score={activeSubjectComparison.correct} />
            </DistributionChartContainer>}
          </Scoring>
          <Analysis>
            <SectionHeader>Feedback</SectionHeader>
            {getFullLengthAnalysis(educator, testTitle, statistics, correct, total)}
          </Analysis>
          {breakdownTable}
          <TopicBreakdowns>
            {topicBreakdowns}
          </TopicBreakdowns>
          <TopicNeedsReviewNotification>
            Topics marked in red should receive additional attention during your review.
          </TopicNeedsReviewNotification>
        </Container>
      );
    } else {
      const {
        educator,
        correct,
        total,
        timeSpent,
        timePerQuestion,
        markedQuestions,
        statistics,
      } = aggregateByOATSection(result);

      const metrics = [
        ['Academic Average', getOATAcademicAverage(testTitle, statistics)],
        ['Time Spent', moment.utc(timeSpent * 1000).format('HH:mm:ss')],
        ['Per Question', moment.utc(timePerQuestion * 1000).format('HH:mm:ss')],
        ['Questions Marked', markedQuestions],
      ];

      const bioResult = {name: 'Biology', testTitle: `Biology Test #${testNumber}`, result: result.slice(0, 1)};
      const gcResult = {name: 'General Chemistry', testTitle: `General Chemistry Test #${testNumber}`, result: result.slice(1, 2)};
      const ocResult = {name: 'Organic Chemistry', testTitle: `Organic Chemistry Test #${testNumber}`, result: result.slice(2, 3)};
      const rcResult = {name: 'Reading Comprehension', testTitle: `Reading Comprehension Test #${testNumber}`, result: result.slice(3, 6)};
      const physResult = {name: 'Physics', testTitle: `Physics Test #${testNumber}`, result: result.slice(6, 7)};
      const qrResult = {name: 'Quantitative Reasoning', testTitle: `Quantitative Reasoning Test #${testNumber}`, result: result.slice(7, 8)};

      const breakdownTable = (
        <TopicBreakdown>
          <SectionHeader>Section breakdown</SectionHeader>
          <Table>
            <tbody>
              <TableRow>
                <TableHeader>Section</TableHeader>
                <TableHeader>Time Spent</TableHeader>
                <TableHeader>Score</TableHeader>
                <TableHeader>Per Question</TableHeader>
              </TableRow>
              {statistics.map((statistic, index) => <StatRow key={`statistic-row-${index}`} statistic={statistic} index={index}/>)}
            </tbody>
          </Table>
        </TopicBreakdown>
      );

      const topicBreakdowns = [bioResult, gcResult, ocResult, rcResult, physResult, qrResult].map(({name, testTitle, result: selectedResult}) => {
        const {
          correct,
          total,
          statistics: selectedResultStatistics
        } = aggregateByTopic(selectedResult);

        const testSubject = testTitle.match(/([^#]+)( Test)/)[1];
        const percentage = (correct / total) * 100;
        const score = getOATResults(percentage, testTitle, testSubject);

        // insert chart
        const addSnsChart = testSubject === 'Organic Chemistry';

        return (
          <TopicBreakdown chartActive={addSnsChart}>
            <SectionHeaderWrap>
              <SectionHeader>{testSubject} breakdown</SectionHeader>
              <SectionHeader>Estimated OAT {testSubject} Score: {score}</SectionHeader>
            </SectionHeaderWrap>
            <Table>
              <tbody>
                <TableRow>
                  <TableHeader>Topic</TableHeader>
                  <TableHeader>Time Spent</TableHeader>
                  <TableHeader>Score</TableHeader>
                  <TableHeader>Per Question</TableHeader>
                </TableRow>
                {selectedResultStatistics
                  .sort(firstBy('total', -1))
                  .map((statistic, index) => <StatRow key={`statistic-row-${index}`} statistic={statistic} index={index} onClick={() => setNavigationFilter({key: 'subjectTopic', value: `${name}##${statistic.name}`})}/>)
                }
              </tbody>
            </Table>
            {addSnsChart &&
              <PieChart
                title={'time spent per SNS subject'}
                data={getSnsChartData(bioResult, gcResult, ocResult)}
              />
            }
          </TopicBreakdown>
        )
      });

      return (
        <Container>
          {header}
          <Scoring>
            <SectionHeader>Score Report</SectionHeader>
            <Row>
              <ScoreBar correctPercentage={correct/total} />
              <MetricContainer>{metrics.map(Metric)}</MetricContainer>
            </Row>
          </Scoring>
          <Analysis>
            <SectionHeader>Feedback</SectionHeader>
            {getOATFullLengthAnalysis(educator, testTitle, statistics, correct, total)}
          </Analysis>
          {breakdownTable}
          <TopicBreakdowns>
            {topicBreakdowns}
          </TopicBreakdowns>
          <TopicNeedsReviewNotification>
            Topics marked in red should receive additional attention during your review.
          </TopicNeedsReviewNotification>
        </Container>
      );
    }
  } else {
    const {
      educator,
      correct,
      total,
      timeSpent,
      timePerQuestion,
      markedQuestions,
      statistics,
    } = aggregateByTopic(result);

    const showPatChart = testTitle.match('Perceptual');
    const showRcChart = testTitle.match('Reading');

    const testSubject = testTitle.match(/([^#]+)( Test)/)[1];
    const percentage = (correct / total) * 100;
    const {score} = IS_OAT_STUDENT ? getOATResults(percentage, testTitle, testSubject) : getResults(percentage, testTitle, testSubject);

    const metrics = [
      ['Total Correct', `${correct} / ${total}`],
      ['Time Spent', moment.utc(timeSpent * 1000).format('HH:mm:ss')],
      ['Per Question', moment.utc(timePerQuestion * 1000).format('HH:mm:ss')],
      ['Estimated DAT Score', score],
    ];

    const breakdownTable = (
      <TopicBreakdown>
        <SectionHeaderWrap>
          <SectionHeader>Topic breakdown</SectionHeader>
          <SectionHeader>Estimated {testSubject} Score: {score}</SectionHeader>
        </SectionHeaderWrap>
        <Table>
          <tbody>
            <TableRow>
              <TableHeader>Topic</TableHeader>
              <TableHeader>Time Spent</TableHeader>
              <TableHeader>Score</TableHeader>
              <TableHeader>Per Question</TableHeader>
            </TableRow>
            {statistics
              .sort(firstBy('total', -1))
              .map((statistic, index) => (
                <StatRow
                  key={`statistic-row-${index}`}
                  statistic={statistic}
                  index={index}
                  onClick={() => setNavigationFilter({key: 'topic', value: statistic.name})}
                />
              ))
            }
          </tbody>
        </Table>
      </TopicBreakdown>
    );

    return (
      <Container>
        {header}
        <Scoring>
          <SectionHeader>Score Report</SectionHeader>
          <Row>
            <MetricContainer>{metrics.map(Metric)}</MetricContainer>
          </Row>
          {['dat']?.includes(bootcamp) && testNumber <= 10 &&
            <DistributionChartContainer>
              <ScoreComparisonSectionHeader>
                Comparison
                <TextTooltip id="score-report-tooltip">See how your total correct compares to all other Bootcamp students who have taken this test.</TextTooltip>
              </ScoreComparisonSectionHeader>
              <ScoreDistributionChart name={testTitle} score={correct} />
            </DistributionChartContainer>
          }
        </Scoring>
        <Analysis>
          <SectionHeader>Feedback</SectionHeader>
          {IS_OAT_STUDENT ? getOATAnalysis(educator, testTitle, statistics, correct, total) : getAnalysis(educator, testTitle, statistics, correct, total)}
        </Analysis>
        {breakdownTable}
        <TopicNeedsReviewNotification>
          Topics marked in red should receive additional attention during your review.
        </TopicNeedsReviewNotification>
        {showPatChart &&
          <PieChart
            title={'breakdown of time spent per topic'}
            data={getTopicChartData(statistics)}
          />
        }
        {showRcChart &&
          <PieChart
            title={'breakdown of time spent per topic'}
            data={getTopicChartData(statistics)}
          />
        }
      </Container>
    );
  }
};

const TopicNeedsReviewNotification = styled.p`
  margin-bottom: 25px;
  ${({theme}) => theme.darkModeEnabled && css`
    color: ${theme.colors.typographyPalette.secondary};
  `}
`;

const Container = styled(GutterColumn)`
  width: 100%;
  height: 100%;
`;

const Section = styled.section`
  width: 100%;
  margin-bottom: 32px;
`;
const Header = styled(Row)`
  width: 100%;
  margin-bottom: 32px;
  align-items: center;
`;

const Title = styled(H1)`
  font-size: 32px;
  line-height: 43px;
  margin: 25px 0px;
  width: 100%;
`;
const SubTitle = styled(Body3)``;
const SectionHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const SectionHeader = styled(H3)`
  font-size: 18px;
  line-height: 24px;
  margin: 0px 0px 24px 0px;
`;
const ScoreComparisonSectionHeader = styled(SectionHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DistributionChartContainer = styled.div`
  margin-top: 24px;
`;
const Analysis = styled(Section)`
  font-size: 16px;
  line-height: 21px;
  white-space: pre-wrap;
`;
const AnalysisContent = styled.div`
  position: relative;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  border-radius: 8px;
  line-height: 1.5em;
`;
const AnalysisScore = styled.span`
  font-weight: bold;
`;
const EducatorContainer = styled(Row)`
  margin-top: 25px;
  align-items: center;
`;
const EducatorDetail = styled(Column)`
  padding: 10px;
  color: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.extraDark : theme.overlays.opacity.dark._500};
  span {
    font-size: 14px;
  }
  div {
    font-size: 12px;
    line-height: normal;
  }
`;
const CircleImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;
const Scoring = styled(Section)`
`;
const TopicBreakdown = styled(Section)`
  margin-bottom: ${({chartActive}) => chartActive ? '0' : '32px'}
`;
const TopicBreakdowns = styled(Section)`
  border-top: 1px solid #eee;
  padding-top: 2em;
`
const MetricContainer = styled(Row)`
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`;
const MetricWrapper = styled(Column)`
  width: 25%;
  height: auto;
  padding: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    width: 50%;
  }
`;
const MetricTop = styled(H2)`
  font-weight: bold;
  color: ${({theme}) => theme.darkModeEnabled ? baseTheme.colors.neutralsPalette.offWhite : '#2F3037'};
  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: 18px;
  }
`;
const MetricBottom = styled(Body3)`
  color: ${({theme}) => theme.darkModeEnabled ? theme.colors.typographyPalette.secondary : '#585962'};
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
`;
const TableHeader = styled.th`
  text-align: left;
  padding-left: 19px;
  padding-right: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 3em;
  text-shadow: none;
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  box-shadow: inset 0px -1px 0px ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : '#EDEDF0'};
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
`;
const TableRow = styled.tr`
  border-bottom: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.mediumBorderColor};
  cursor: ${({hasClickHandler}) => hasClickHandler ? 'pointer' : 'text'};
  background: ${({theme}) => theme.darkModeEnabled ? 'transparent' : 'white'};

  &:hover {
    td {
      &:first-child {
        text-decoration: ${({hasClickHandler}) => hasClickHandler ? 'underline' : 'text'};
      }
    }
  }
`;
const cellHighlight = `
  background-image: linear-gradient(red,red), linear-gradient(transparent,transparent);
  background-repeat: no-repeat;
  background-size: 3px 100%;
  background-position: left center;
`;
const Cell = styled.td`
  font-family: 'proxima-nova';
  padding: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  ${({highlight}) => highlight ? cellHighlight : ''}
  width: 25%;
`;

const UserDetails = styled(Column)`
  margin-right: 0;
  margin-left: auto;
  white-space: nowrap;
  width: auto;
`;

const ScoreBarContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  margin-right: 32px;
`;

const ToolTip = styled.div`
  bottom: 120%;
  padding: 10px;
  margin-left: -55px;
  left: ${({leftPercent}) => leftPercent}%;
  position: absolute;
  /* green / light */
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
  white-space: nowrap;
  ${({loading, theme}) => theme.animation.loadingBackground({loading})};
  transition: left 1s ease;
`;

const TopToolTip = styled(ToolTip)`
  color: #4C8B43;
  background: #EAF5EA;
  border: 1px solid #66AC5C;
`;
const BottomToolTip = styled(ToolTip)`
  color: #85868E;
  background: #FAFAFA;
  border: 1px solid #85868E;
  bottom: auto;
  top: 120%;
  margin-left: -71px;
`;


const ScoreBar = ({correctPercentage}) => {
  const {variables: {answerData}} = useTestNavigatorContext();
  return null

  const testAverage = Math.round(answerData?.averagePercentCorrect || 0);
  return (
    <ScoreBarContainer>
      <TopToolTip leftPercent={Math.round(correctPercentage * 100)} loading={false}>
        Your Score: {Math.round(correctPercentage * 100)}%
      </TopToolTip>
      <ProgressBar
        segments={[
          {
            count: correctPercentage,
            color: 'linear-gradient(180deg, #776CFE 0%, #6B60E5 51.56%, #4940AF 100%)'
          }
        ]}
        total={1}
        showLabel={false}
      />
      <BottomToolTip leftPercent={!testAverage ? 0 : testAverage > 80 ? 80 : testAverage} loading={!testAverage}>
        Score Avg: {testAverage}%
      </BottomToolTip>
    </ScoreBarContainer>
  )
}

StartReview.displayName = 'StartReview';

StartReview.defaultProps = {
  testId: '',
  testTitle: '',
};

StartReview.propTypes = {
  testId: PropTypes.string,
  testTitle: PropTypes.string,
};

export default StartReview;
