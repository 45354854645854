import firstBy from 'thenby';

export const loadMoreMasteryIntoBlock = (test, blockIndex, masteryArray, nextToken) => {

  let updatedTest = Object.assign({}, test);;

  updatedTest.blockConnections.items[blockIndex].testBlock = {
    ...test.blockConnections.items[blockIndex].testBlock,
    questionConnections: {
      items: [...test.blockConnections.items[blockIndex].testBlock.questionConnections.items, ...masteryArray]
    },
    nextToken,
  };

  return updatedTest;
}

export function getTestReviewBlockConnections (test, quizProgress) {
  const blocks = getTestBlockConnections(test);

  if (!quizProgress) return [];

  return quizProgress.reduce((acc, blockProgress, index) => {
    let blockConnection = {}
    switch (index) {
      case 0:
        blockConnection = {
          testBlock: {type: 'startReview'},
          index
        };
        break;

      case quizProgress.length - 1:
        blockConnection = {
          testBlock: {type: 'endReview'},
          index
        }
        break;

      default:
        const testBlock = (blocks[index] || {}).testBlock || {type: 'questionSet'};

        const savedProgressQuestionConnections = blockProgress.questions.map((questionProgress, index) => (
          {
            index,
            question: questionProgress.question
          }
        ));

        blockConnection = {
          testBlock: {
            ...testBlock,
            questionConnections: {
              items: savedProgressQuestionConnections
            }
          },
          index
        }
        break;
    }
    return [
      ...acc,
      blockConnection
    ];

  }, []);
}

export const getTestBlockConnections = (test) => {
  return (!!test && !!test.blockConnections && test.blockConnections.items.slice().sort(firstBy('index'))) || [];
};
