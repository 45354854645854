import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {firstBy} from 'thenby';

import {useModalContext} from '../../contexts/Modal';
import {useUserDataContext} from '../../contexts/UserData';

import {SubmitAnatomyCase} from '../Modal';
import {Row} from '@bootcamp/web/src/components/Grid';
import {H4, H5, Body3} from '@bootcamp/web/src/components/Typography';
import {TextTooltip} from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/shared';
import Switch from 'rc-switch';
import "rc-switch/assets/index.css";

import {useReloadBlockerContext} from '@bootcamp/web/src/contexts/ReloadBlocker';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';

import {IconButton} from '@bootcamp/web/src/components/Branding/Buttons';
import {ToggleSidebar} from '@bootcamp/web/src/components/Branding/Buttons/iconButtons';

import {CircleHalfFill as Partial} from '@styled-icons/fluentui-system-filled/CircleHalfFill'
import {Dismiss} from '@styled-icons/fluentui-system-filled/Dismiss';
import {Bars4 as ViewList} from '@styled-icons/heroicons-solid/Bars4';
import {Squares2x2 as ViewGrid} from '@styled-icons/heroicons-solid/Squares2x2';
import {Shuffle} from '@styled-icons/ionicons-solid/Shuffle';
import {Settings} from '@styled-icons/fluentui-system-filled/Settings';
import {Timeline} from '@styled-icons/fluentui-system-filled/Timeline';
import {Checkmark} from '@styled-icons/fluentui-system-filled/Checkmark';
import {DismissCircle} from '@styled-icons/fluentui-system-filled/DismissCircle';
import {MagnifyingGlass as Search} from '@styled-icons/heroicons-outline/MagnifyingGlass';
import {Tag} from '@styled-icons/fluentui-system-filled/Tag';
import {ChevronUp} from '@styled-icons/feather/ChevronUp';
import MasteryIcon from '../../bootcamps/components/MasteryIcon';

import styled, {css} from 'styled-components';
import {getTitleConfig} from  '@bootcamp/shared/src/util';
import Storage from '@aws-amplify/storage';

import {updateQuestionProgressData} from '@bootcamp/web/src/helpers';
import {trackQuizProgressPerformance} from '@bootcamp/shared/src/requests';

import {aggregateByTopic, aggregateBySubject} from '@bootcamp/shared/src/util/scoring';

import theme from '@bootcamp/shared/src/styles/theme';


export const CloseButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 16px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
  }
`;

export const CloseIcon = styled(Dismiss).attrs(props => ({size: 20, color: 'white'}))`
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 20px;
    width: 20px;
  }
`;

const Container = styled.div`
  width: 100%;
  background: ${({background}) => background};
  position: relative;
`;

const Type = styled(H4)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-bottom: ${({theme}) => theme.layouts.spacing.xs};
  font-weight: 400;
  line-height: 100%;
`;

const Title = styled(H4)`
  color: ${theme.colors.neutralsPalette.white};
  font-weight: 700;
  line-height: 100%;
  font-size: 18px;
`;

const ButtonsContainer = styled(Row)`
  height: auto;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  padding: 16px 0px 0px;
`;

export const FilterSelector = styled.select`
  cursor: pointer;
  font-family: 'proxima-nova';
  border: none;
  width: 100%;
  min-width: 100%;
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%;
  background-size: 12px;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 0;
  margin-top: 8px;
`;
const IconWrapper = styled.div`
`;
const IncorrectIcon = styled(Dismiss)`
  width: 14px;
`;

const TopicFilterSelector = styled(FilterSelector)`
  margin-top: ${({theme}) => theme.layouts.spacing.xs};
`;

const ProgressContainer = styled(Row)`
  height: auto;
  align-items: flex-start;
  justify-content: center;
`;

const ToggleBackground = styled.div`
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border-radius: 8px;
  padding: 4px;
  height: 32px;
`;

const ToggleContainer = styled(Row)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex: 1;
`;

const ToggleActive = styled.div`
  background: ${({theme}) => theme.overlays.opacity.light._100};
  position: absolute;
  left: ${({value, numOptions}) => value ? `${(100 / numOptions) * value}%` : 0};
  top: 0;
  height: 100%;
  width: ${({numOptions}) => `${100 / numOptions}%`};
  transition: left .3s;
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.shadow.low};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-family: proxima-nova;
  font-weight: 400px;
  font-size: 14px;
`;

export const QbankFilterToggle = ({visibleFilter, setVisibleFilter, setNavigationFilter}) => {
  return (
    <ToggleBackground>
      <ToggleContainer>
        <FilterContainer onClick={() => {
          setVisibleFilter();
          setNavigationFilter({});
        }}>
          All
        </FilterContainer>
        <FilterContainer active={visibleFilter === 'status'} onClick={() => {
          setVisibleFilter('status');
          setNavigationFilter({});
        }}>
          Status
        </FilterContainer>
        <ToggleActive onClick={() => {
          setVisibleFilter();
          setNavigationFilter({});
        }} numOptions={2} value={!visibleFilter ? 0 : 1} />
      </ToggleContainer>
    </ToggleBackground>
  )
}
export const CourseFilterToggle = ({navigationFilter, setNavigationFilter}) => {
  const {key, value} = navigationFilter;
  return (
    <ToggleBackground>
      <ToggleContainer>
        <FilterContainer active={key !== 'blockFilter' || !value} onClick={() => setNavigationFilter({})}>
          All
        </FilterContainer>
        <FilterContainer active={key === 'blockFilter' && value === 'videos'} onClick={() => setNavigationFilter({key: 'blockFilter', value: 'videos'})}>
          Videos
        </FilterContainer>
        <FilterContainer active={key === 'blockFilter' && value === 'quizzes'} onClick={() => setNavigationFilter({key: 'blockFilter', value: 'quizzes'})}>
          Quizzes
        </FilterContainer>
        <ToggleActive numOptions={3} value={key != 'blockFilter' || !value ? 0 : ['', 'videos', 'quizzes'].indexOf(value)} />
      </ToggleContainer>
    </ToggleBackground>
  )
}

export const GridToggle = ({value, setValue, themePalette}) => {
  return (
    <ToggleContainer onClick={() => setValue(!value)}>
      <IconContainer>
        <ListIcon active={!value} themePalette={themePalette} />
      </IconContainer>
      <IconContainer>
        <GridIcon active={value} themePalette={themePalette} />
      </IconContainer>
      <ToggleActive value={value} />
    </ToggleContainer>
  )
}

const GridIcon = styled(ViewGrid).attrs(props => ({size: 18}))`
  color: ${({themePalette, active}) => !active ? 'white' : themePalette.default};
  z-index: 2;
  transition: color .3s;
  width: 18px;
  height: 18px;
`;

const ListIcon = styled(ViewList).attrs(props => ({size: 18}))`
  color: ${({themePalette, active}) => !active ? 'white' : themePalette.default};
  z-index: 2;
  transition: color .3s;
  width: 18px;
  height: 18px;
`;

const SettingsToggle = styled(Settings)`
  color: white;
  margin-right: 8px;
`;

const ProgressCount = styled(H5)`
  white-space: nowrap;
  width: auto;
  margin-left: 8px;
  margin-top: 0;
  display: flex;
  align-items: center;
  line-height: normal;
  color: white;
  font-weight: 400;
  line-height: 100%;
  font-size: 14px;
`;

const Section = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${({theme}) => theme.overlays.opacity.dark._100};
`;

const TitleRow = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const ShuffleContainer = styled.div`
  ${({shuffled, theme}) => shuffled ? css`
      background: white;
      border: 1px solid ${({theme}) => theme.overlays.opacity.dark._50};
      svg {
        color: ${({themePalette}) => '#2F3037'};
        width: 18px;
        height: 18px;
      }
    ` : css`
      background: ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.overlays.opacity.light._100};
      border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.overlays.opacity.dark._50};
      svg {
        color: ${({theme}) => theme.overlays.opacity.light._500};
      }
      &:hover {
        background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : 'rgba(255, 255, 255, 0.2)'}
    }`
  }
  border-radius: 8px;
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
`;

const ShuffleIcon = styled(Shuffle).attrs(props => ({size: 18}))`
`;
const ProgressRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const SettingsArea = styled.div`
  width: 100%;
  max-height: ${({toggled}) => toggled ? '300px' : '0px'};
  overflow: hidden;
  transition: max-height .4s ease-in-out;
  border-bottom: ${({toggled}) => toggled ? `1px solid ${({theme}) => theme.overlays.opacity.dark._100}` : 'none'};
`;
const StyledTextTooltip = styled(TextTooltip)`
  svg {
    height: 14px;
    width: 14px;
  }
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;
const OptionBox = styled.div`
  box-sizing: border-box;
  padding: 8px 16px;
  width: 100%;
`;

const OptionTitle = styled(H4)`
  color: white;
  font-size: 14px;
  font-weight: 400;
`;

const StyledSwitch = styled(Switch)`
  margin-left: auto;
  &:after {
    transform: none !important;
    animation-name: none !important;
  }
`;

const Option = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 0px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const OpenIndicator = styled(ChevronUp)`
  color: white;
  margin-left: auto;
  margin-right: 0;
  transition: .15s transform;
  transform: rotate(${({open}) => open ? '0deg' : '-180deg'});
`;

const FilterSection = styled.div`
  background: #FFFFFF1A;
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  &:first-child {
    margin-top: 0px;
  }
`;

const FilterRow = styled.div`
  padding: 8px 0px;
  border-bottom: 1px solid #0000001A;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({isActive}) => isActive ? 700 : 400};
  line-height: 100%; /* 14px */
  color: white;
  cursor: pointer;
  font-family: proxima-nova;
`;

const FilterText = styled.span`
  margin-left: 8px;
  margin-right: auto;
`;
const FilterCount = styled.span`
  margin-left: auto;
  margin-right: 0;
`
const MasteryTag = styled(MasteryIcon)`
  display: ${({level}) => !!level ? 'inline-block' : 'none'};
  max-width: 16px;
  height: 16px;
`;

const SettingsToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.overlays.opacity.dark._50};
  background: ${({theme, toggled}) => toggled ? 'white' : theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : theme.overlays.opacity.light._100};
  transition: background .2s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  svg {
    width: 18px;
    height: 18px;
    color: ${({toggled, themePalette}) => toggled ? '#2F3037' : 'white'};
  }

  ${({toggled, theme}) => !toggled && css`
    &:hover {
      background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : 'rgba(255, 255, 255, 0.2)'}
    }
  `}
`;
const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const SearchInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 8px;
  padding-left: 32px;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: white;
  font-family: proxima-nova;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  &::placeholder {
    color: ${({theme}) => theme.overlays.opacity.light._400};
  }
`;
const Top = ({title, parentTitle, setShowingSidebar, showingSidebar, progressBar, grid, setGrid, themePalette, classroom, showExitButton = true, hideQuestionList, hide = []}) => {
  const [settingsToggled, setSettingsToggled] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(null);
  const {modalDispatch} = useModalContext();
  const {toggleReloadBlocker} = useReloadBlockerContext();
  const {saveUserInteraction, bootcamp, DEFAULT_USER_ID, searchUserInteractions, toggleDarkMode, darkModeEnabled} = useUserDataContext();
  const hideAnswerData = searchUserInteractions('hideAnswerData') === true;
  const hideTimer = searchUserInteractions('hideTimer') === true;
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;
  const prometricDelay = searchUserInteractions('prometricDelay') === true;
  const autoPlayEnabled = searchUserInteractions('autoPlayEnabled') === true;
  const autoTag = true;

  const {
    methods: {
      setNavigationFilter,
      transitionBlock,
      setCurrentIndex,
      toggleQuestionProgressShuffle,
      updateQuizProgress,
      autoTagOnSubmit
    },
    variables: {
      testBlockConnections,
      navigationFilter,
      blockIndex,
      template,
      type,
      test,
      shuffled,
      customTestConfig,
      quizProgress
    }
  } = useTestNavigatorContext();
  const {questions: questionProgresses} = quizProgress?.[blockIndex] || {};

  const {timeLeft, timeUp, questionStartTime} = useTimerContext();
  const {push, goBack, action} = useHistory();

  const canGoBack = action !== 'POP';
  const urlParts = window.location.pathname.split('/');
  const previousPath = urlParts
    .slice(0, 2)
    .join('/');

  const {questions: blockQuestionProgress} = {questions: [], ...quizProgress[blockIndex]};
  const questionIndex = blockQuestionProgress.findIndex(({current}) => current);

  const isFullLengthTestReview = template === 'testReview' && test?.config?.title.includes('Full Length');
  const isFullLengthOATTestReview = isFullLengthTestReview && test?.config?.title.includes('OAT');
  const [datTopics, datSubjects] = useMemo(() => {
    if (!['oat', 'dat'].includes(bootcamp) || template !== 'testReview' || !quizProgress) return [];
    return [aggregateByTopic(quizProgress), aggregateBySubject(quizProgress)]
  }, []);
  const activeSubjectFilter = navigationFilter.key === 'subject'
    ? datSubjects?.subjectStatistics?.find(({name}) => name === navigationFilter.value)
    : navigationFilter.key === 'subjectTopic'
    ? datSubjects?.subjectStatistics?.find(({name}) => name === navigationFilter.value.split('##')?.[0])
    : [];

  const redirectMap = {
    '/dat': '/dat/past-test-results',
  };
  // METHODS
  const handleGoBack = () => {
    if (canGoBack) {
      goBack();
    } else {
      push(redirectMap[previousPath] || previousPath);
    }
  };

  const questions = quizProgress.map(block => block.questions || []).flat();
  const questionCount = questions.length;

  const mastery = questions.reduce((acc, {masteryLevel}) => ({
    ...acc,
    ...((masteryLevel && masteryLevel !== 'none') ? {[masteryLevel]: acc[masteryLevel] + 1, total: acc.total + 1} : {}),
  }), {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    total: 0
  });

  const completeLessons = quizProgress.reduce((acc, blockProgress) => blockProgress.status === 'complete' ? acc + 1 : acc, 0);
  const totalLessons = testBlockConnections.length - 2;

  const handleFilterChange = event => {
    if (parseInt(event.target.value)) {
      setNavigationFilter({});
      setVisibleFilter(null);
      const blockTarget = parseInt(event.target.value);
      setCurrentIndex(blockTarget, 0);
      return;
    }

    const questionFilter = event.target.value;

    if (questionFilter) {

      const filterKey = questionFilter.match('topic') || questionFilter.match('subject')
        ? questionFilter.split('###')[0]
        : questionFilter === 'bookmarked'
        ? 'bookmarked'
        : (questionFilter === 'correct' || questionFilter === 'incorrect')
          ? 'didSelectCorrectAnswer'
          : ['fullCredit', 'partialCredit', 'noCredit'].includes(questionFilter)
            ? questionFilter
            : 'masteryLevel';

      const filterValue = questionFilter.match('topic') || questionFilter.match('subject')
        ? questionFilter.split('###')[1]
        : filterKey === 'bookmarked'
        ? true
        : filterKey === 'masteryLevel'
          ? questionFilter
          : ['fullCredit', 'partialCredit', 'noCredit'].includes(questionFilter)
            ? true
            : questionFilter === 'correct';
      if (filterKey === navigationFilter.key && filterValue === navigationFilter.value) {
        return setNavigationFilter({});
      }
      if (questionFilter.match('topic') || questionFilter.match('subject')) {
        setVisibleFilter(null);
      }
      setNavigationFilter({ key: filterKey, value: filterValue });
    } else {
      setNavigationFilter({});
    }
  };

  const handleSuspendClick = async () => {
    const testStorageKey = customTestConfig?.config?.testStorageKey;

    try {
      // update custom test config to indicate suspension
      const updatedConfig = {
        ...customTestConfig,
        suspended: true,
        timeLeft: timeLeft?.length <= 5 ? `00:${timeLeft}` : timeLeft,
        questionIndex
      };

      await Storage.put(testStorageKey, JSON.stringify(updatedConfig), { contentType: 'application/json' });

      if (template === 'customTest' && !customTestConfig.submitted && !questionProgresses?.[questionIndex]?.didCheck) {
        await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
      }

      toggleReloadBlocker(false);
      setTimeout(() => push(`/med-school/previous-tests`), 100);
    } catch (err) {
      console.log('error suspending test', err);
    }
  };

  const handleExitClick = async () => {
    const testStorageKey = customTestConfig?.config?.testStorageKey;

    try {
      // update custom test config to indicate suspension
      const updatedConfig = {
        ...customTestConfig,
        suspended: false,
        timeLeft: null,
        submitted: true,
      };

      await Storage.put(testStorageKey, JSON.stringify(updatedConfig), {contentType: 'application/json' });

    } catch (error) {
      console.log('error marking test as submitted', error);
    }

    if (template === 'customTest' && !questionProgresses?.[questionIndex]?.didCheck) {
      await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
    }

    if (customTestConfig?.config && !customTestConfig.submitted) {
      if (!customTestConfig?.config?.tutorMode) {
        await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
      }

      await autoTagOnSubmit();
    }

    toggleReloadBlocker(false);
    setTimeout(() => push(`/${bootcamp}/previous-tests/review?key=${encodeURIComponent(customTestConfig?.config?.testStorageKey)}`), 100);
  };

  // USE EFFECTS
  useEffect(() => {
    // console.log('working here?', navigationFilter, blockIndex);
    if (!navigationFilter || !['masteryLevel', 'fullCredit', 'partialCredit', 'noCredit', 'topic', 'didSelectCorrectAnswer'].includes(navigationFilter.key) || blockIndex === 0) return;
    navigationFilter.value === 'all' ? transitionBlock('firstQuestionSet') : transitionBlock('firstFilterActive');
  }, [navigationFilter.key, navigationFilter.value]);

  useEffect(() => {
    if (timeUp && showExitButton) {
      modalDispatch({
        type: 'open',
        component: SubmitAnatomyCase,
        enableClickClose: true,
        componentProps: {
          headerText: 'No Time Remaining',
          bodyText: 'You have no more time remaining. You can review your test results from the previous tests page.',
          cancelConfig: {
            text: ''
          },
          confirmConfig: {
            text: 'View Test Results',
            colorConfig: themePalette,
            onConfirm: handleExitClick,
            shouldRedirect: false,
          }
        },
      })
    };
  }, [timeUp]);

  const titleConfig = getTitleConfig(title, classroom, template, customTestConfig);

  const exitProps = template === 'quickReview' ?
    {
      headerText: `Nice work! You’ve reached the end of your review.`,
      bodyText: `Would you like to keep reviewing or complete your review?`,
      cancelConfig: {
        text: 'Keep Reviewing',
        onCancel: () => {
          modalDispatch({type: 'close'});
        },
        shouldRedirect: false,

      },
      confirmConfig: {
        text: 'Complete Review',
        colorConfig: themePalette,
        onConfirm: () => {
          toggleReloadBlocker(false);
          setTimeout(() => handleGoBack(), 100);
        },
        shouldRedirect: false,
      }

    }
  : template === 'customTest' && !customTestConfig?.submitted ? {
      headerText: 'End Test',
      bodyText: 'Do you want to end this test? Once ended you can review your test results from the previous tests page.',
      cancelConfig: {
        text: 'Suspend Test',
        onCancel: handleSuspendClick,
        shouldRedirect: false,
      },
      confirmConfig: {
        text: 'End Test',
        colorConfig: themePalette,
        onConfirm: handleExitClick,
        shouldRedirect: false,
      }
    } : {
    headerText: 'Would you like to end your review?',
    bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
    cancelConfig: {
      text: 'Return to Review',
      onCancel: () => {
        modalDispatch({type: 'close'});
      },
      shouldRedirect: false,

    },
    confirmConfig: {
      text: 'End Review',
      colorConfig: themePalette,
      onConfirm: () => {
        toggleReloadBlocker(false);
        setTimeout(() => handleGoBack(), 100);
      },
      shouldRedirect: false,
    }
  };

  const {correct, incorrect, bookmarked, fullCredit, partialCredit, noCredit} = quizProgress.reduce((acc, blockProgress) => blockProgress?.questions ? blockProgress.questions.reduce((acc, question) => ({
    ...acc,
    correct: acc.correct + (question.didSelectCorrectAnswer && (question.didCheck || template == 'testReview' || type === 'readinessExam' || customTestConfig?.submitted) ? 1 : 0),
    incorrect: acc.incorrect + (!question.didSelectCorrectAnswer && (question.didCheck || template == 'testReview' || type === 'readinessExam' || customTestConfig?.submitted) ? 1 : 0),
    bookmarked: acc.bookmarked + (question.bookmarked ? 1 : 0),
    fullCredit: acc.fullCredit + (question.fullCredit && (question.didCheck || template == 'testReview' || type === 'readinessExam' || customTestConfig?.submitted) ? 1 : 0),
    partialCredit: acc.partialCredit + (question.partialCredit && (question.didCheck || template == 'testReview' || type === 'readinessExam' || customTestConfig?.submitted) ? 1 : 0),
    noCredit: acc.noCredit + (question.noCredit ? 1 : 0),
  }), acc) : acc, {
    correct: 0,
    incorrect: 0,
    bookmarked: 0,
    fullCredit: 0,
    partialCredit: 0,
    noCredit: 0,
  });
  return (
    <Container onClick={() => setSettingsToggled(false)}>
      <Section style={{paddingTop: '16px'}}>
        <ButtonsContainer style={{justifyContent: 'space-between'}}>
          {showExitButton &&
            <CloseButton
              onClick={template === 'coursePlayer' ? handleGoBack : () => {
                modalDispatch({
                  type: 'open',
                  component: SubmitAnatomyCase,
                  enableClickClose: true,
                  componentProps: exitProps,
                })
              }}
              type={'secondary'}
              size={'large'}
              children={<CloseIcon />}
            />
          }
          <ToggleSidebar showingSidebar={showingSidebar} onClick={() => setShowingSidebar(!showingSidebar)} />
        </ButtonsContainer>
        <TitleContainer>
          <Type>{parentTitle || titleConfig.type}</Type>
          <Title>{titleConfig.title}</Title>
        </TitleContainer>
      </Section>
      {progressBar && !hideQuestionList &&
        <Section>
          <TitleRow style={{marginBottom: '8px'}}>
            <Title style={{fontSize: '16px', fontWeight: '400'}}>{template === 'coursePlayer' ? 'Lessons Completed' : 'Questions Tagged'}</Title>
          </TitleRow>
          <ProgressContainer>
            <ProgressRow>
              {progressBar}
              <ProgressCount>
                {template === 'coursePlayer' ? `${completeLessons}/${totalLessons}` : `${mastery.total}/${questionCount}`}
              </ProgressCount>
            </ProgressRow>
          </ProgressContainer>
        </Section>
      }
      {!hide?.includes('questions') &&
        <Section themePalette={themePalette}>
          <ButtonRow>
            {
              ['tbcQuestionBank', 'tbcSavedBank', 'masteryReview', 'bookmarkedQuestionReview', 'quickReview', 'customTest'].includes(template) && !['nclex'].includes(bootcamp) && !hide?.includes('questions') && (
                <ShuffleContainer
                  shuffled={shuffled}
                  themePalette={themePalette}
                  onClick={() => {
                    toggleQuestionProgressShuffle();
                    setCurrentIndex(1, 0);
                  }}
                  children={<ShuffleIcon />}
                />
              )
            }
            <SettingsToggleWrapper toggled={settingsToggled} themePalette={themePalette} onClick={event => {
              event.stopPropagation();
              setSettingsToggled(!settingsToggled);
            }}>
              <SettingsToggle />
            </SettingsToggleWrapper>
          </ButtonRow>
        </Section>
      }
      <SettingsArea toggled={hide?.includes('questions') || settingsToggled} onClick={e => e.stopPropagation()}>
        <OptionBox>
          {bootcamp !== 'chemistry' &&
            <Option>
              <OptionTitle>Answer Stats</OptionTitle>
              <StyledTextTooltip id="answer-tooltip">Show or hide answer stats</StyledTextTooltip>
              <StyledSwitch checked={!hideAnswerData} onChange={() => saveUserInteraction('hideAnswerData', !hideAnswerData)} />
            </Option>
          }
          {(!hide.includes('timer') && template !== 'testReview') &&
            <Option>
              <OptionTitle>Timer</OptionTitle>
              <StyledTextTooltip id="timer-tooltip">Show or hide test timer</StyledTextTooltip>
              <StyledSwitch checked={!hideTimer} onChange={() => saveUserInteraction('hideTimer', !hideTimer)} />
            </Option>
          }
          {!hide.includes('shortcuts') &&
            <Option>
              <OptionTitle>Keyboard Shortcuts</OptionTitle>
              <StyledTextTooltip id="keyboard-tooltip">Enable or disable shortcuts</StyledTextTooltip>
              <StyledSwitch checked={showKeyboardShortcuts} onChange={() => saveUserInteraction('showKeyboardShortcuts', !showKeyboardShortcuts)} />
            </Option>
          }
          {template != 'coursePlayer' && bootcamp === 'nclex' && !customTestConfig?.config?.tutorMode &&
            <Option>
              <OptionTitle>NCLEX Restrictions</OptionTitle>
              <StyledTextTooltip id="restrictions-tooltip">Adds a 0.25s delay between questions to simulate the Pearson testing experience. Disables the ability to cross out or highlight.</StyledTextTooltip>
              <StyledSwitch checked={prometricDelay} onChange={() => saveUserInteraction('prometricDelay', !prometricDelay)} />
            </Option>
          }
          {template != 'coursePlayer' &&
            <Option>
              <OptionTitle>Grid View</OptionTitle>
              <StyledTextTooltip id="grid-tooltip">View questions in grid.</StyledTextTooltip>
              <StyledSwitch checked={grid} onChange={() => setGrid(!grid)} />
            </Option>
          }
          {template === 'coursePlayer' &&
            <Option>
              <OptionTitle>Auto Play </OptionTitle>
              <StyledTextTooltip id="auto-play-tooltip">Automatically play the next video.</StyledTextTooltip>
              <StyledSwitch checked={autoPlayEnabled} onChange={() => saveUserInteraction('autoPlayEnabled', !autoPlayEnabled)} />
            </Option>
          }
          {!hide.includes('toggleDarkMode') &&
            <Option>
              <OptionTitle>Dark Mode</OptionTitle>
              <StyledTextTooltip id="darkmode-tooltip">Enable or disable Dark Mode</StyledTextTooltip>
              <StyledSwitch checked={darkModeEnabled} onChange={() => toggleDarkMode(!darkModeEnabled)} />
            </Option>
          }
        </OptionBox>
      </SettingsArea>

    {!hide?.includes('questions') &&
      <Section>
        {template === 'coursePlayer'
          ? <CourseFilterToggle navigationFilter={navigationFilter} setNavigationFilter={setNavigationFilter} />
          : <QbankFilterToggle visibleFilter={visibleFilter} setVisibleFilter={setVisibleFilter} setNavigationFilter={setNavigationFilter} />
        }
        {!visibleFilter && navigationFilter.key != 'blockFilter' &&
          <div style={{ position: 'relative', marginTop: 8 }}>
            <label style={{ position: 'absolute', left: 8, top: 5, zIndex: 2, color: 'rgba(255,255,255,.5)' }} for="search" ><Search size={16} /></label>
            <SearchInput id="search" placeholder="Search..." value={navigationFilter.key === 'search' ? navigationFilter.value : ''} onChange={e => {
              const value = e.target.value;
              if (value.length >= 1) {
                setNavigationFilter({ key: 'search', value });
                setVisibleFilter(null);
              } else {
                setNavigationFilter({});
              }
            }} />
            {navigationFilter.key == 'search' && <label style={{ position: 'absolute', right: 8, top: 5, zIndex: 2, color: 'rgba(255,255,255,.5)', cursor: 'pointer' }} for="search" onClick={() => setNavigationFilter({})} ><DismissCircle size={16} /></label>}
          </div>
        }
        {visibleFilter && (
          <div style={{marginTop: 8}}>
            {(visibleFilter === 'status') && [
              bootcamp === 'nclex'
              ? (
                <FilterSection>
                  <FilterRow isActive={navigationFilter.key === 'fullCredit' && navigationFilter.value === true} onClick={() => handleFilterChange({target: {value: 'fullCredit'}})}>
                    <Checkmark color={'white'} size={14} />
                    <FilterText>Full Credit</FilterText>
                    <FilterCount>{fullCredit}</FilterCount>
                  </FilterRow>
                  <FilterRow isActive={navigationFilter.key === 'partialCredit' && navigationFilter.value === true} onClick={() => handleFilterChange({target: {value: 'partialCredit'}})}>
                    <Partial color={'white'} size={14} />
                    <FilterText>Partial Credit</FilterText>
                    <FilterCount>{partialCredit}</FilterCount>
                  </FilterRow>
                  <FilterRow isActive={navigationFilter.key === 'noCredit' && navigationFilter.value === true} onClick={() => handleFilterChange({target: {value: 'noCredit'}})}>
                    <IconWrapper>
                      <IncorrectIcon color={'white'} size={11} />
                    </IconWrapper>
                    <FilterText>No Credit</FilterText>
                    <FilterCount>{noCredit}</FilterCount>
                  </FilterRow>
                </FilterSection>
              )
              : (
                <FilterSection>
                  <FilterRow isActive={navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === true} onClick={() => handleFilterChange({target: {value: 'correct'}})}>
                    <Checkmark color={'white'} size={14} />
                    <FilterText>Correct</FilterText>
                    <FilterCount>{correct}</FilterCount>
                  </FilterRow>
                  <FilterRow isActive={navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false} onClick={() => handleFilterChange({target: {value: 'incorrect'}})}>
                    <IconWrapper>
                      <IncorrectIcon color={'white'} size={11} />
                    </IconWrapper>
                    <FilterText>Incorrect</FilterText>
                    <FilterCount>{incorrect}</FilterCount>
                  </FilterRow>
                </FilterSection>
              ),
              <FilterSection>
                <FilterRow isActive={navigationFilter.key === 'masteryLevel' && navigationFilter.value === 'none'} onClick={() => handleFilterChange({target: {value: 'none'}})}>
                  <MasteryTag level={'none'} squared />
                  <FilterText>Unused</FilterText>
                  <FilterCount>{questionCount - mastery.total}</FilterCount>
                </FilterRow>
                <FilterRow isActive={navigationFilter.key === 'masteryLevel' && navigationFilter.value === 'learning'} onClick={() => handleFilterChange({target: {value: 'learning'}})}>
                  <MasteryTag level={'learning'} squared />
                  <FilterText>Learning</FilterText>
                  <FilterCount>{mastery.learning}</FilterCount>
                </FilterRow>
                <FilterRow isActive={navigationFilter.key === 'masteryLevel' && navigationFilter.value === 'reviewing'} onClick={() => handleFilterChange({target: {value: 'reviewing'}})}>
                  <MasteryTag level={'reviewing'} squared />
                  <FilterText>Reviewing</FilterText>
                  <FilterCount>{mastery.reviewing}</FilterCount>
                </FilterRow>
                <FilterRow isActive={navigationFilter.key === 'masteryLevel' && navigationFilter.value === 'mastered'} onClick={() => handleFilterChange({target: {value: 'mastered'}})}>
                  <MasteryTag level={'mastered'} squared />
                  <FilterText>Mastered</FilterText>
                  <FilterCount>{mastery.mastered}</FilterCount>
                </FilterRow>
                <FilterRow isActive={navigationFilter.key === 'bookmarked' && navigationFilter.value === true} onClick={() => handleFilterChange({target: {value: 'bookmarked'}})}>
                  <MasteryTag level={'marked'} squared />
                  <FilterText>Bookmarked</FilterText>
                  <FilterCount>{bookmarked}</FilterCount>
                </FilterRow>
              </FilterSection>
            ]}
          </div>
        )}
        {!hideQuestionList && ['dat', 'oat'].includes(bootcamp) && template === 'testReview' && (isFullLengthTestReview || isFullLengthOATTestReview) &&
          <FilterSelector onChange={handleFilterChange}>
             {/* <option disabled>Tagged Question Filters</option> */}
            <option value={''} selected={navigationFilter.value === 'all'}>Filter By: All Questions</option>
            {/*<option value={'learning'} selected={navigationFilter.value === 'learning'}>Filter By: All Learning</option>
            <option value={'reviewing'} selected={navigationFilter.value === 'reviewing'}>Filter By: All Reviewing</option>
            <option value={'mastered'} selected={navigationFilter.value === 'mastered'}>Filter By: All Mastered</option>
            <option value={'none'} selected={navigationFilter.value === 'none'}>Filter By: All Unused</option>
            <option value={'bookmarked'} selected={navigationFilter.key === 'bookmarked'}>Filter By: All Bookmarked</option>
            {(template !== 'tbcQuestionBank' || bootcamp === 'nclex') && <option disabled>{bootcamp === 'nclex' ? 'Score Filters' : 'Correct / Incorrect Filters'}</option>}
            {bootcamp === 'nclex' && <option value={'fullCredit'} selected={navigationFilter.key === 'fullCredit' && !navigationFilter.value}>Filter By: Full Credit</option>}
            {bootcamp === 'nclex' && <option value={'partialCredit'} selected={navigationFilter.key === 'partialCredit' && !navigationFilter.value}>Filter By: Partial Credit</option>}
            {bootcamp === 'nclex' && <option value={'noCredit'} selected={navigationFilter.key === 'noCredit' && !navigationFilter.value}>Filter By: No Credit</option>} */}
            {/* {template !== 'tbcQuestionBank' && bootcamp !== 'nclex' && <option value={'correct'} selected={navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value}>Filter By: Correct</option>}
            {template !== 'tbcQuestionBank' && bootcamp !== 'nclex' && <option value={'incorrect'} selected={navigationFilter.key === 'didSelectCorrectAnswer' && !navigationFilter.value}>Filter By: Incorrect</option>} */}
            {['dat', 'oat'].includes(bootcamp) && template === 'testReview' && !isFullLengthTestReview && <option disabled>Topic Filters</option> }
            {['dat', 'oat'].includes(bootcamp) && template === 'testReview' && !isFullLengthTestReview &&
              datTopics?.statistics
                ?.sort(firstBy('name'))
                ?.map(({name}) => (
                  <option key={name} value={`topic###${name}`} selected={navigationFilter.key === 'topic' && navigationFilter.value === name}>Filter By: {name}</option>
                ))
            }
            {isFullLengthOATTestReview ? [
              <option disabled>Subject Filters</option>,
              <option value={'subject###Biology'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Biology')}>Filter By: Biology</option>,
              <option value={'subject###General Chemistry'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('General Chemistry')}>Filter By: General Chemistry</option>,
              <option value={'subject###Organic Chemistry'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Organic Chemistry')}>Filter By: Organic Chemistry</option>,
              <option value={'subject###Reading Comprehension'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Reading Comprehension')}>Filter By: Reading Comprehension</option>,
              <option value={'subject###Physics'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Physics')}>Filter By: Physics</option>,
              <option value={'subject###Quantitative Reasoning'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Quantitative Reasoning')}>Filter By: Quantitative Reasoning</option>
            ] : isFullLengthTestReview ? [
              <option disabled>Subject Filters</option>,
              <option value={'subject###Biology'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Biology')}>Filter By: Biology</option>,
              <option value={'subject###General Chemistry'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('General Chemistry')}>Filter By: General Chemistry</option>,
              <option value={'subject###Organic Chemistry'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Organic Chemistry')}>Filter By: Organic Chemistry</option>,
              <option value={'subject###Perceptual Ability'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Perceptual Ability')}>Filter By: Perceptual Ability</option>,
              <option value={'subject###Reading Comprehension'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Reading Comprehension')}>Filter By: Reading Comprehension</option>,
              <option value={'subject###Quantitative Reasoning'} selected={(navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') && navigationFilter.value.match('Quantitative Reasoning')}>Filter By: Quantitative Reasoning</option>
            ] : null}
          </FilterSelector>
        }
        {isFullLengthTestReview && (navigationFilter.key === 'subject' || navigationFilter.key === 'subjectTopic') &&
          <TopicFilterSelector onChange={handleFilterChange}>
            <option value={`subject###${activeSubjectFilter.name}`} selected={navigationFilter?.value?.match('subject') && !navigationFilter?.value?.match('Topic')}>Filter By: All Topics</option>
            {['dat', 'oat'].includes(bootcamp) && template === 'testReview' &&
              activeSubjectFilter?.topicStatistics
                ?.sort(firstBy('name'))
                ?.map(({name}) => (
                <option key={name} value={`subjectTopic###${activeSubjectFilter.name}##${name}`} selected={navigationFilter.key === 'subjectTopic' && navigationFilter.value.match(name)}>Filter By: {name}</option>
              ))
            }
          </TopicFilterSelector>
        }
      </Section>
      }
    </Container>
  );
}

Top.propTypes = {
  title: PropTypes.string
};
Top.defaultProps = {
  title: '',
};

export default Top;
