import React, {useContext, createContext, useReducer, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  visibility: ${({open, customOpen}) => (open || customOpen) ? 'visible' : 'hidden'};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(21, 26, 30, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000000001;
  opacity: ${({open, customOpen}) => (open || customOpen) ? 1 : 0};
  transition: opacity 0.2s;
  overflow: auto;
  ${({modalContainerStyle, theme}) => modalContainerStyle(theme)};
`;

const ModalContext = createContext();
const useModalContext = () => useContext(ModalContext);

const initialState = {
  open: false,
  component: null,
  componentProps: {},
  enableClickClose: false,
  modalContainerStyle: () => true,
  navModal: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'custom':
      return {
        [`custom-${action.key}`]: true,
        component: action.component,
        componentProps: action.componentProps,
        enableClickClose: action.enableClickClose,
        modalContainerStyle: action.modalContainerStyle || initialState.modalContainerStyle,
        navModal: action.navModal || initialState.navModal,
        renderInContainer: action.renderInContainer,
      }
    case 'open':
      return {
        open: true,
        component: action.component,
        componentProps: action.componentProps,
        enableClickClose: action.enableClickClose,
        modalContainerStyle: action.modalContainerStyle || initialState.modalContainerStyle,
        navModal: action.navModal || initialState.navModal,
        renderInContainer: action.renderInContainer,
      };
    case 'close':
      return initialState;
    case 'style':
      return {...state, modalContainerStyle: action.modalContainerStyle};
    default:
      return state;
  }
}

const ModalProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ModalContext.Provider value={{modalState: state, modalDispatch: dispatch}}>
      {children}
    </ModalContext.Provider>
  );
};

const Modal = withRouter(({history, location}) => {
  const {modalState: {open, stayOpen, enableClickClose, renderInContainer, component: Component, componentProps, modalContainerStyle, navModal, ...rest}, modalDispatch: dispatch} = useModalContext();
  const customOpen = Object.entries(rest).some(([key, value]) => key.match('open') && value);

  const close = () => {
    dispatch({type: 'close'});
    navModal && history.push(location.pathname);
  };

  useEffect(() => {
    if (open && !navModal) close();
  }, [location.pathname]);

  const modalComponent = (
    <Container open={open} customOpen={customOpen} onClick={enableClickClose ? close : () => {}} modalContainerStyle={modalContainerStyle}>
      {Component && <Component {...componentProps} history={history} close={close}/>}
    </Container>
  );

  return renderInContainer
    ? ReactDOM.createPortal(modalComponent, renderInContainer)
    : modalComponent;

});

ModalProvider.displayName = 'ModalProvider';
ModalProvider.propTypes = {};


export default ModalProvider;
export {ModalContext, Modal, useModalContext}
