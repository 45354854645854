import React, {Fragment} from 'react';

import {Link} from 'react-router-dom';

import {Account, LogIn, SignUp, Upgrade} from './Buttons';

import {Row} from '../../Grid';
import {LoadingSpinner} from '../../Branding';

import styled from 'styled-components';

const barHeight = 72;
const barHeightMobile = 56;

const ContainerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({color}) => color || 'rgba(108, 92, 231, .9)'};
  opacity: 1;
`;

const Container = styled.div`
  height: ${barHeight}px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 12;
  padding: 0 ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme}) => theme.mediaQueries.tablet} {
    height: ${barHeightMobile}px;
    padding: 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;
const Content = styled(Row)`
  max-width: 1152px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  position: relative;
`;
const Left = styled(Row)`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;
const Center = styled(Row)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const Right = styled(Row)`
  flex: 0;
  align-items: center;
  justify-content: flex-end;
`;

const LoadingWrapper = styled.div`
  flex: 0;
  text-align: right;
`;
const StyledLink = styled(Link)`
  display: flex;
`;

const Loader = () => (
  <LoadingWrapper>
    <LoadingSpinner/>
  </LoadingWrapper>
);

const LoggedIn = () => (
  <Fragment>
    <Upgrade/>
    <Account/>
  </Fragment>
);
const LoggedOut = () => (
  <Fragment>
    <LogIn/>
    <SignUp/>
  </Fragment>
);

export {
  ContainerBackground,
  Container,
  Content,
  Left,
  Center,
  Right,
  LoadingWrapper,
  StyledLink,
  LoggedIn,
  LoggedOut,
  Loader,
  barHeight,
  barHeightMobile,
};
