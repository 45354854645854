import React, {useState, useEffect} from 'react';

import CoursePlayer from '../../../components/CoursePlayer';

import {useUserDataContext} from '../../../contexts/UserData';
import {getTestProgressByUserIdHashTestId, createTestProgress} from '@bootcamp/shared/src/requests';

import {getInObj} from '@bootcamp/shared/src/util';

import styled from 'styled-components';


const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const WrappedCoursePlayer = ({match, testId, themePalette, classroom, lesson}) => {
  const {DEFAULT_USER_ID, loading} = useUserDataContext();
  const [progressId, setProgressId] = useState(null);

  useEffect(() => {
    if (!DEFAULT_USER_ID) return;

    async function fetchTestProgress() {
      const userIdHashTestId = `${DEFAULT_USER_ID}#${testId}`;

      // see if progress for this video lesson exists
      const data = await getTestProgressByUserIdHashTestId(userIdHashTestId, false);
      const progressId = getInObj(['data', 'TestProgressByTestId', 'items', 0, 'id'], data, null);

      if (progressId) {
        return setProgressId(progressId);
      }

      // create testProgress if it hasn't been created yet
      await createTestProgress(userIdHashTestId, DEFAULT_USER_ID, testId);
      return setProgressId(userIdHashTestId);
    }

    // fetchTestProgress();

  }, [DEFAULT_USER_ID]);

  return (
    <Container>
      <CoursePlayer
        match={match}
        testId={testId}
        testProgressId={progressId}
        loading={loading}
        classroomName={classroom.name}
        chapterName={lesson.name}
        themePalette={themePalette}
      />
    </Container>
  );
}

WrappedCoursePlayer.propTypes = {};
WrappedCoursePlayer.defaultProps = {};

export default WrappedCoursePlayer;
