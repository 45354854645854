import {colors} from '../styles/theme';

const config = {
  classrooms: [
    {
      name: 'Anatomy and Physiology',
      tagId: '65dcea9c-ac11-4e6c-aac0-89fa2f979afb',
      route: 'anatomy-and-physiology',
      themePalette: colors.brandPalette.blue,
      classroomDescription: 'Anatomy and Physiology is one of the most important classes for your future career. Learning how the human body works will help you understand the body in health, disease, and treatment.',
      questionCount: 1432,
      icon: 'icons/bio.svg',
      educators: [
        {
          firstName: 'Brianna',
          avatar: 'images/brianna.gif',
        },
        {
          firstName: 'Lance',
          avatar: 'educators/lance.gif',
        },
        {
          firstName: 'Parker',
          avatar: 'images/parker.gif',
        },
      ],
      contentTypes: [
        {
          name: 'A&P Videos',
          route: 'videos',
          content: [
            {
              type: 'Course',
              name: 'A&P Videos',
              description: 'Get to the next level of A&P mastery with our A&P Videos.',
              content: [
                {
                  "id": "f60d5c4d-f49e-49a6-a8b6-8dcc05eb19f7",
                  "classroomName": "Physiology",
                  "route": "circulatory-system",
                  "name": "Circulatory System"
                },
                {
                  "id": "694d48d6-a3be-459e-9105-5231188cf412",
                  "classroomName": "Physiology",
                  "route": "respiratory-system",
                  "name": "Respiratory System"
                },
                {
                  "id": "a5731c48-a7ef-47b7-a1bb-af12bd2624a6",
                  "classroomName": "Physiology",
                  "route": "immune-system",
                  "name": "Immune System"
                },
                {
                  "id": "209c621c-8992-4603-a973-d5e2595786c8",
                  "classroomName": "Physiology",
                  "route": "nervous-system",
                  "name": "Nervous System"
                },
                {
                  "id": "3d855b4e-b167-4c52-b31e-291c48e0b552",
                  "classroomName": "Physiology",
                  "route": "muscular-system",
                  "name": "Muscular System"
                },
                {
                  "id": "c0dc2552-b3eb-426e-9a2b-fce2cedc5676",
                  "classroomName": "Physiology",
                  "route": "skeletal-system",
                  "name": "Skeletal System"
                },
                {
                  "id": "f2de33ff-cb1e-4236-9776-6b28bf26b176",
                  "classroomName": "Physiology",
                  "route": "endocrine-system",
                  "name": "Endocrine System"
                },
                {
                  "id": "7190197d-cd85-4b1f-8231-2fabcef2d393",
                  "classroomName": "Physiology",
                  "route": "digestive-system",
                  "name": "Digestive System"
                },
                {
                  "id": "b2c2896b-1d51-41df-8934-d10c1c380c3a",
                  "classroomName": "Physiology",
                  "route": "excretory-system",
                  "name": "Excretory System"
                },
                {
                  "id": "1f64f9b6-6303-4a5a-9096-8d9bbec227c5",
                  "classroomName": "Physiology",
                  "route": "integumentary-system",
                  "name": "Integumentary System"
                },
                {
                  "id": "bd06bab1-e9ef-430b-ab9f-d912285e9b1c",
                  "classroomName": "Physiology",
                  "route": "reproduction-and-developmental-biology",
                  "name": "Reproduction and Developmental Biology "
                }
              ]
            }
          ]
        },
        {
          name: 'A&P Bites',
          route: 'bites',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  "name": "Circulatory System",
                  "id": "f4fd0992-3194-4529-9c36-4a50f0dd0498",
                  "route": "bank-1"
                },
                {
                  "name": "Respiratory System",
                  "id": "976d9d41-0e68-4999-a5a4-3889bd11a5cf",
                  "route": "bank-2"
                },
                {
                  "name": "Immune System",
                  "id": "7f9dda79-45e5-4bd7-99f8-d406131ea889",
                  "route": "bank-3"
                },
                {
                  "name": "Nervous System",
                  "id": "e56cd1d3-411a-434a-abae-008c51fe519e",
                  "route": "bank-4"
                },
                {
                  "name": "Muscular System",
                  "id": "f32522fd-498b-4bc9-b721-7b6de0cdf907",
                  "route": "bank-5"
                },
                {
                  "name": "Skeletal System",
                  "id": "175cfab9-1896-4544-902d-872f2afa12e1",
                  "route": "bank-6"
                },
                {
                  "name": "Endocrine System",
                  "id": "320c579a-9c24-43ee-a2cd-a3dc55b4d6c7",
                  "route": "bank-7"
                },
                {
                  "name": "Digestive System",
                  "id": "588554ef-5edf-4747-90a9-1afa6d821be7",
                  "route": "bank-8"
                },
                {
                  "name": "Excretory System",
                  "id": "bdd9eed6-985e-4d90-849c-03d70ee80886",
                  "route": "bank-9"
                },
                {
                  "name": "Integumentary System",
                  "id": "b9f7339f-74f1-47f4-8cf4-aa0fa49a3523",
                  "route": "bank-10"
                },
                {
                  "name": "Reproduction and Developmental Biology ",
                  "id": "d2e59217-e32d-4f7f-916c-9d333794ef00",
                  "route": "bank-11"
                }
              ]
            }
          ]
        },
        {
          name: 'A&P Banks',
          // icon: <StarIcon/>,
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content: [
                {
                  "name": "Circulatory System",
                  "id": "248bbbc9-0724-486d-b456-8bb48d309597",
                  "route": "bank-1"
                },
                {
                  "name": "Respiratory System",
                  "id": "1d2ebda7-6049-420d-8bfe-f61d23184943",
                  "route": "bank-2"
                },
                {
                  "name": "Immune System",
                  "id": "f45e2ec1-21e2-4e36-bf94-a593ab1a40e7",
                  "route": "bank-3"
                },
                {
                  "name": "Nervous System",
                  "id": "8210ccd0-064e-4a9a-a073-8895bfe70e0c",
                  "route": "bank-4"
                },
                {
                  "name": "Muscular System",
                  "id": "1596ee53-3cb3-4d42-80fa-6dabccc173d4",
                  "route": "bank-5"
                },
                {
                  "name": "Skeletal System",
                  "id": "2499026f-20ef-466c-9f86-dffd53444249",
                  "route": "bank-6"
                },
                {
                  "name": "Endocrine System",
                  "id": "4bf81f3c-8767-41d2-af31-8d5fecfe447b",
                  "route": "bank-7"
                },
                {
                  "name": "Digestive System",
                  "id": "7b5eef3e-7197-467f-a020-33e11c69c446",
                  "route": "bank-8"
                },
                {
                  "name": "Excretory System",
                  "id": "3b0ffb77-4524-4ece-b9ca-fe69237697c0",
                  "route": "bank-9"
                },
                {
                  "name": "Integumentary System",
                  "id": "5d11b74a-2543-46fb-b39f-e1390a4e301b",
                  "route": "bank-10"
                },
                {
                  "name": "Reproduction and Developmental Biology ",
                  "id": "b4b1b56e-ab42-40bf-b541-dbb37de861bb",
                  "route": "bank-11"
                }
              ]
            }
          ]
        },
        {
          name: 'A&P High-Yield Notes',
          // icon: <StarIcon />,
          route: 'notes',
          content: [
            {
              name: 'Downloads',
              content: [
                {
                  name: "Bootcamp High-Yield A&P Notes",
                  description: "A high-yield review of the most important concepts in A&P",
                  route: 'https://drive.google.com/file/d/1lYPQBu3AIsi1hoCAdVVi3yPSvkNZEyvp/view?usp=sharing',
                },
              ]
            }
          ]
        },
      ]
    },
    {
      name: 'Gross Anatomy',
      route: 'gross-anatomy',
      tagIds: [
        'd8496b7d-8fbb-420f-8b05-91e35ad45bc3', // ULLB
        'eb01603a-62af-4b68-aa9d-b160a3f28279', // Head & Neck
        '7ff0f5aa-9081-4299-bf2e-4944e24582e5', // TAP
      ],
      themePalette: colors.brandPalette.blue,
      icon: 'icons/neck.svg', // TODO add gross anatomy icon
      classroomDescription: `Welcome to your first patient, Doctor. Be sure to watch the video lessons and practice with the qbanks. We'll make learning anatomy easy, so you can ace your exams.`,
      questionCount: 6064,
      practicalSections: [
        'Upper & Lower Limbs, Back',
        'Head & Neck',
        'Thorax, Abdomen & Pelvis'
      ],
      contentSections: [
        'Upper Limbs',
        'Back',
        'Lower Limbs',
        'Head & Neck',
        'Thorax',
        'Abdomen',
        'Pelvis'
      ],
      educators: [
        {
          firstName: 'Adam',
          avatar: 'educators/adam.gif'
        },
        {
          firstName: 'Whitney',
          avatar: 'educators/whitney.png'
        },
        {
          firstName: 'Lance',
          avatar: 'educators/lance.gif'
        },
        {
          firstName: 'John',
          avatar: 'educators/john.gif'
        },
      ],
      contentTypes: [
        {
          "name": "Gross Anatomy Videos",
          "route": "videos",
          "content": [
            {
              "name": "Videos",
              "type": "Course",
              startingIndex: 0,
              "content": [
                {
                  "id": "51dc7e4c-be4a-47c9-bbd9-bcd35a56f55e",
                  "route": "intro-to-anatomy",
                  "name": "Foundational Concepts in Anatomy",
                },
                {
                  "id": "478e27a4-4982-4acd-9b95-495c66df1805",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "pectoral-region",
                  "name": "Pectoral Region",
                },
                {
                  "id": "c4115d51-962b-4a9a-8d9f-1fe318437073",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "scapular-region-and-posterior-arm",
                  "name": "Scapular Region and Posterior Arm",
                },
                {
                  "id": "e5595b27-1c40-4b8d-8a0f-deb2af4bec8f",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "axilla",
                  "name": "Axilla",
                },
                {
                  "id": "825d2dc7-eca1-4fc6-be2e-abfb869ebb45",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "anterior-arm",
                  "name": "Anterior Arm",
                },
                {
                  "id": "afe5fc13-1927-4678-85cd-1c842690aec2",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "forearm-and-cubital-fossa",
                  "name": "Forearm and Cubital Fossa",
                },
                {
                  "id": "b8c8dab4-7b3e-4585-a0e7-3c99db5a7dd0",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "hand",
                  "name": "Hand",
                },
                {
                  "id": "2eccdeeb-95e0-4cff-85b0-d8e2cc9c198d",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Back",
                  "route": "back",
                  "name": "Back",
                },
                {
                  "id": "99630b55-326e-42f4-b6a3-b76572ce4e22",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Back",
                  "route": "spinal-cord",
                  "name": "Spinal Cord",
                },
                {
                  "id": "5041173e-238a-4ef2-956d-f7397021aad8",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "anterior-and-medial-thigh-knee",
                  "name": "Anterior & Medial Thigh, Knee",
                },
                {
                  "id": "f765df25-2a94-46bd-98dc-e720666a4178",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "gluteal-region-posterior-thigh-popliteal-fossa",
                  "name": "Gluteal Region, Posterior Thigh, Popliteal Fossa",
                },
                {
                  "id": "c6192c3d-9262-4370-b5fd-b1135ff027b4",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "leg",
                  "name": "Leg",
                },
                {
                  "id": "79dc7ec3-8278-4ccc-8504-796782dcc997",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "foot",
                  "name": "Foot",
                },
                {
                  "id": "fdbebc14-710e-4b63-99e4-99629c2ab136",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "external-examination-of-the-brain",
                  "name": "External Examination of the Brain + Skull",
                },
                {
                  "id": "fdf1bbac-c504-4c27-b8f8-575cbe8e78ef",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "anterior-triangle-of-the-neck",
                  "name": "Anterior Triangle of the Neck",
                },
                {
                  "id": "1f3df0f5-c65a-417d-99f9-25937a150cd0",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "posterior-triangle-of-the-neck",
                  "name": "Posterior Triangle of the Neck",
                },
                {
                  "id": "63c82c5a-481b-4ea7-93d3-d47f91b95645",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "occipital-region-and-posterior-neck",
                  "name": "Occipital Region & Posterior Neck",
                },
                {
                  "id": "b57b3b32-b585-42a7-8a16-d1d3c1c11b3f",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "face",
                  "name": "Face",
                },
                {
                  "id": "c62affbe-7a90-4849-b45e-0a1dfb99a22d",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "infratemporal-fossa",
                  "name": "Infratemporal Fossa",
                },
                {
                  "id": "a3e373ad-f091-4a6f-af30-f266fd82f85c",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "orbit",
                  "name": "Orbit",
                },
                {
                  "id": "6e70261d-b3f2-4a17-8321-277f1dcb114e",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "pharynx",
                  "name": "Pharynx",
                },
                {
                  "id": "349bcf41-ba82-4338-9916-006b584cbfd8",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "nose-and-nasal-cavity",
                  "name": "Nose & Nasal Cavity",
                },
                {
                  "id": "2e0fa80f-ea84-48dc-bdf0-7d27f5527767",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "hard-and-soft-palate-and-pterygopalatine-fossa",
                  "name": "Hard & Soft Palate, and Pterygopalatine Fossa",
                },
                {
                  "id": "1cb05347-bb84-45ff-be96-2fa0b4eb9cd7",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "tongue-and-floor-of-the-mouth",
                  "name": "Tongue & Floor of the Mouth",
                },
                {
                  "id": "e14ef7d5-e9f4-40d4-b6f5-8df6b458fbde",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "larynx",
                  "name": "Larynx",
                },
                {
                  "id": "69d16981-01bd-4d30-adef-d634be6f5c76",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "cranial-nerve-review",
                  "name": "Cranial Nerve Review",
                },
                {
                  "id": "cb26a305-1ef7-4a89-b47d-70d27e5a7e84",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "thoracic-wall",
                  "name": "Thoracic Wall",
                },
                {
                  "id": "b74e43a3-3cf5-4f9e-9682-adac6725cf88",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "heart",
                  "name": "Heart",
                },
                {
                  "id": "adeb4919-2724-48db-92d2-376f78ac786c",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "lungs",
                  "name": "Lungs",
                },
                {
                  "id": "649d611a-0d52-4dcd-9664-b27dbc349ab3",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "mediastinum",
                  "name": "Mediastinum",
                },
                {
                  "id": "9c70a5e5-5d2c-47ec-be9f-54819ad8014e",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "abdominal-wall",
                  "name": "Abdominal Wall and Viscera in situ",
                },
                {
                  "id": "877d8efa-3c18-416a-a5ee-dd09af992271",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "hepatoduodenal-ligament-celiac-trunk-stomach-spleen-liver-and-gallbladder",
                  "name": "Hepatoduodenal Ligament, Celiac Trunk, Stomach, Spleen, Liver & Gallbladder",
                },
                {
                  "id": "b964abca-edca-4e99-b9cb-a66d4d90931c",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "superior-mesenteric-artery-inferior-mesenteric-artery-small-intestine-pancreas-large-intestine",
                  "name": "Superior Mesenteric Artery, Inferior Mesenteric Artery, Small Intestine, Pancreas, Large Intestine",
                },
                {
                  "id": "7e629bc0-4054-4485-a0d2-4674d34fb192",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "posterior-abdominal-viscera-muscles-and-diaphragm",
                  "name": "Posterior Abdominal Viscera, Muscles and Diaphragm",
                },
                {
                  "id": "44535502-8fdc-4c72-ad3c-d156f7b78a7b",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Pelvis",
                  "route": "posterior-abdominal-wall-inguinal-region-scrotum-testis-and-spermatic-cord",
                  "name": "Posterior Abdominal Wall, Inguinal Region, Scrotum, Testis & Spermatic Cord",
                },
                {
                  "id": "50bc70d2-cfba-4e36-9959-e76a5b794d5c",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Pelvis",
                  "route": "perineum-and-external-genitalia",
                  "name": "Perineum & External Genitalia",
                },
                {
                  "id": "bfae02af-fc2e-4092-b924-790d82098c0e",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Pelvis",
                  "route": "pelvic-cavity",
                  "name": "Pelvic Cavity",
                }
              ]
            }
          ]
        },
        {
          "name": "Question Banks",
          "type": "Question Bank",
          "route": "qbank-collection",
          "content": [
            {
              "name": "Question Banks",
              "content": [
                {
                  "name": "Pectoral Region",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "pectoral-region",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "ad373628-9b71-46cb-9946-cafff16c0c02",
                      "type": "identification",
                    },
                    {
                      route: 'bank-2',
                      testId: 'b5c12041-1a47-42d8-82f8-3bae643f6ccc',
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Scapular Region and Posterior Arm",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "scapular-region-and-posterior-arm",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "c0630572-3327-4abd-872b-53d3a92f688a",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "135c7e39-b545-4ee3-b54e-cfa13c9a995a",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "c7bcbb81-af4c-4724-8e6f-28b007213260",
                      "type": "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Axilla",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "axilla",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "dbec931b-5c74-4a76-b77b-755180974341",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "c28cae6b-4b83-41b7-ab8d-3c77ba5aeccb",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "eb5a0203-b548-4ba5-b422-df066b6b1ee1",
                      "type": "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Anterior Arm",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "anterior-arm",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "0894830c-ff94-45f5-b23f-6295aca943be",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "c091b9b9-30cb-44fd-9897-de1c1a097223",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "f8b50ca5-5680-4396-951a-6579fc92bf91",
                      "type": "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Forearm and Cubital Fossa",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "forearm-and-cubital-fossa",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "2e6297ac-df0e-45e9-9c44-893469e66225",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "f461d479-9b6a-442c-9c39-e5fef78bb7da",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "ab653525-83f3-4eca-a85f-047bb4101af9",
                      "type": "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Hand",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Upper Limbs",
                  "route": "hand",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "6b4cb0d5-86f8-44ec-a23c-e9c9576ea8ab",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "f20bf026-eccb-455d-9a30-6731e0d3ef28",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "8aa3d412-cdab-43fe-8962-980bb64f1d2a",
                      "type": "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Back",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Back",
                  "route": "back",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "e65e5d32-cdad-426c-98f3-f5bd4147897a",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "df585a0f-5231-487e-981b-6e1676832a66",
                      type: "application",
                  },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Spinal Cord",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Back",
                  "route": "spinal-cord",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "d73814ef-27b9-487e-b540-6d42b5f27ea2",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "0901bb8a-fd9c-4de2-8b60-74667093eed7",
                      type: "application",
                  },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Anterior & Medial Thigh, Knee",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "anterior-and-medial-thigh-knee",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "70a667d4-f5d6-4d73-8805-86665dbe6aa5",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "392d4d29-ddd0-4609-9b7b-2e92b19ec586",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "9d2cae20-3dc4-40f8-bff4-0b95b33fe672",
                      type: "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Gluteal Region, Posterior Thigh, Popliteal Fossa",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "gluteal-region-posterior-thigh-popliteal-fossa",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "addef550-c659-4fcb-a33f-32cba70f38e3",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "730caa49-27e5-4604-a044-4e3a93cf9f1b",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "33cda52a-39f0-4c0c-8048-ca47199899bb",
                      type: "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Leg",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "leg",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "0bf378e7-8678-4ef6-ac76-439872222b4f",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "08f492ae-e13f-44b0-8d78-ec919a487603",
                      type: "application",
                  },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Foot",
                  "classroomName": "Upper & Lower Limbs, Back",
                  "section": "Lower Limbs",
                  "route": "foot",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "1496a0c3-3903-4a83-bf4a-988f60e8c2dc",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "0119c069-ffeb-406e-81ae-bccf461b3e47",
                      type: "application",
                    },
                    {
                      "route": "bank-3",
                      "testId": "3113d898-7a20-4eb4-8048-82ca485480c1",
                      type: "Clinical Cases",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "External Examination of the Brain + Skull",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "external-examination-of-the-brain",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "991f6068-6380-4882-9a57-4d3377fd3028",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "d994beba-3915-4169-973e-da1f2b140bf8",
                      type: "application",
                  },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Anterior Triangle of the Neck",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "anterior-triangle-of-the-neck",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "a58093a6-51ed-4e60-a577-2bb3a0e039a5",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "86884df1-ab2f-414c-ac6f-14788174f6c2",
                      type: "application",
                  },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Posterior Triangle of the Neck",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "posterior-triangle-of-the-neck",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "0528413b-a091-427c-9299-8bf6b75d9581",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "5b9f1aab-7d06-4711-9e5b-5d5ece54c285",
                      type: "application",
                  },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Occipital Region & Posterior Neck",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "occipital-region-and-posterior-neck",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "c86ef651-67d5-4b3a-b66e-09c3b140f385",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "556e7418-ce65-4c2b-95ee-b894bee1b26d",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Face",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "face",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "19f35b9f-547f-40cf-9e7e-ed8b506186a4",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "7444ed51-76ff-4dad-9c71-9c16ade8f90d",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Infratemporal Fossa",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "infratemporal-fossa",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "6329de43-e0c0-4952-a8c8-6f82bfc46aef",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "7edc6096-3802-4d50-bded-c471921c0e5a",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Orbit",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "orbit",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "2beebd2a-f9f7-4cda-8800-a25478ff1825",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "c4d4ba72-cace-4300-800d-702bb67623e1",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Pharynx",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "pharynx",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "b75e1301-46d5-44ef-93a2-9c5114fcecca",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "631439cf-a641-46f1-b08c-8b4e7f715c84",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Nose & Nasal Cavity",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "nose-and-nasal-cavity",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "9feff03c-331a-4ad0-afab-47f4aa404aff",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "d214276c-5a76-4bb4-944c-c5f4dc613b15",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Hard & Soft Palate, and Pterygopalatine Fossa",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "hard-and-soft-palate-and-pterygopalatine-fossa",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "58eb1f0c-e005-4c4d-9bcf-7f2251364a04",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "e4a98c42-59c9-4ff8-aa9d-65135932d80f",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Tongue & Floor of the Mouth",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "tongue-and-floor-of-the-mouth",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "09ab1c26-acb3-49b8-ae1f-8a7b0389a382",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "6896adcc-9f20-4c77-96b1-b0b5afae4f2c",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Larynx",
                  "classroomName": "Head & Neck",
                  "section": "Head & Neck",
                  "route": "larynx",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "42f6dcb4-eb56-4276-82e3-fcee8e5a61cd",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "ab084e92-69bf-41b0-b01c-f4fd182d2ab0",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Thoracic Wall",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "thoracic-wall",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "cd0a5996-e6a8-4172-9a5a-0e4d02d7ee3c",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "c412e9c7-f2fc-4829-9bc3-a791b5f6e0fe",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Heart",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "heart",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "47276602-9b8b-490a-93d7-a9bc8874d79e",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "e0e731dd-1c07-4be0-b4d7-bd74657ad966",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Lungs",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "lungs",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "ecbeaaa6-9a6b-4340-b0d6-440a7fb65b10",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "0982076d-e65b-4087-835f-bf0a2c1eb527",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Mediastinum",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Thorax",
                  "route": "mediastinum",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "067c9b4c-7e33-48fe-9cb6-b394a697ac23",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "9abe58bf-a9a7-4fba-a6e4-d8e71be6d574",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Abdominal Wall and Viscera in situ",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "abdominal-wall",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "2bfbbe5a-8be1-47ea-a365-477dfc893761",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "2eff1287-5c6d-4859-b449-639c8001cfe4",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Hepatoduodenal Ligament, Celiac Trunk, Stomach, Spleen, Liver & Gallbladder",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "hepatoduodenal-ligament-celiac-trunk-stomach-spleen-liver-and-gallbladder",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "b337b312-15d2-47f3-8b99-10d31de03b20",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "cffc9dec-1aca-426a-814a-5586dadb89bb",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Superior Mesenteric Artery, Inferior Mesenteric Artery, Small Intestine, Pancreas, Large Intestine",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "superior-mesenteric-artery-inferior-mesenteric-artery-small-intestine-pancreas-large-intestine",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "b594eab8-120d-4e70-91d9-adca533339dd",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "fe951c20-b5b7-4067-a8cc-b72d3eae27ee",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Posterior Abdominal Viscera, Muscles and Diaphragm",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Abdomen",
                  "route": "posterior-abdominal-viscera-muscles-and-diaphragm",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "e0b88263-24dc-418b-9fdb-e872271faeeb",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "17b9a2c3-3fe6-4fe7-b50c-35a0248b07a5",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Posterior Abdominal Wall, Inguinal Region, Scrotum, Testis & Spermatic Cord",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Pelvis",
                  "route": "posterior-abdominal-wall-inguinal-region-scrotum-testis-and-spermatic-cord",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "42daafa6-9960-4449-b741-8d77cb78e529",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "41f0432c-b9f6-4287-bcd9-005c2c99356f",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Perineum & External Genitalia",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Pelvis",
                  "route": "perineum-and-external-genitalia",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "b130caad-135f-4590-a8ec-98c5336d5900",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "c277c886-7d54-4345-af88-572168e14126",
                      type: "application",
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Pelvic Cavity",
                  "classroomName": "Thorax, Abdomen & Pelvis",
                  "section": "Pelvis",
                  "route": "pelvic-cavity",
                  "lessons": [
                    {
                      "route": "bank-1",
                      "testId": "1f7aa4b7-8941-4b6a-a906-79171d8fff62",
                      "type": "identification",
                    },
                    {
                      "route": "bank-2",
                      "testId": "dc56da50-e18e-49ca-9a3a-866b8b23de56",
                      type: "application",
                    }
                  ],
                  "type": "Chapter Bank Dropdown"
                }
              ]
            }
          ]
        },
        {
          "name": "Atlas",
          "type": "Downloads",
          "route": "atlas",
          "content": [
            {
              "name": "Downloads",
              "content": [
                {
                  "route": "https://drive.google.com/file/d/14HkgZ0MFQ6aiW4RXLdNlTJE5NV-OYgEs/view?usp=sharing",
                  "section": "Upper Limbs",
                  "name": "Pectoral Region"
                },
                {
                  "route": "https://drive.google.com/file/d/1MyX_X4N4lcXTtrVBTaC6Fg6TS5NsZYRv/view?usp=sharing",
                  "section": "Upper Limbs",
                  "name": "Scapular Region and Posterior Arm"
                },
                {
                  "route": "https://drive.google.com/file/d/10EXMGI64Xs5pt0M6GbCwmnLn0B66PUGh/view?usp=sharing",
                  "section": "Upper Limbs",
                  "name": "Axilla"
                },
                {
                  "route": "https://drive.google.com/file/d/1T7RB7Gc07Xr5u6GVM7Q4EHNrYLFx9DXU/view?usp=sharing",
                  "section": "Upper Limbs",
                  "name": "Anterior Arm"
                },
                {
                  "route": "https://drive.google.com/file/d/1veM3KbgokG7YsasvnTi5c-2GT4K2mutQ/view?usp=sharing",
                  "section": "Upper Limbs",
                  "name": "Forearm and Cubital Fossa"
                },
                {
                  "route": "https://drive.google.com/file/d/1VEn1UovqeJnaX6Yk-r21EzszdRrcK9hy/view?usp=sharing",
                  "section": "Upper Limbs",
                  "name": "Hand"
                },
                {
                  "route": "https://drive.google.com/file/d/1svlO3O_SR3SpUftLdvIlWEjhihGLlTvH/view?usp=sharing",
                  "section": "Back",
                  "name": "Back"
                },
                {
                  "route": "https://drive.google.com/file/d/1YohZHQJyRi7XxmxPgJtRRgF9MVvkKhmW/view?usp=sharing",
                  "section": "Back",
                  "name": "Spinal Cord"
                },
                {
                  "route": "https://drive.google.com/file/d/1AQv0M6-I55vks2tGXxgCmOtyaOc_bElu/view?usp=sharing",
                  "section": "Lower Limbs",
                  "name": "Anterior & Medial Thigh, Knee"
                },
                {
                  "route": "https://drive.google.com/file/d/1-qIB3LF8q9PRgl6eJ0XGPmywCIXmL4k1/view?usp=sharing",
                  "section": "Lower Limbs",
                  "name": "Gluteal Region, Posterior Thigh, Popliteal Fossa"
                },
                {
                  "route": "https://drive.google.com/file/d/177VCGbmzopbNG5cMhcct03PJ8pqbcXgm/view?usp=sharing",
                  "section": "Lower Limbs",
                  "name": "Leg"
                },
                {
                  "route": "https://drive.google.com/file/d/1BtfbQFgH0VQPUbIKwnR9wEHyaW_KOd4K/view?usp=sharing",
                  "section": "Lower Limbs",
                  "name": "Foot"
                },
                {
                  "route": "https://drive.google.com/file/d/1GMTBxpd1Wc5JFgT21WzwcGZQYLvnbx_V/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "External Examination of the Brain + Skull"
                },
                {
                  "route": "https://drive.google.com/file/d/1x5XBD9ERfzuV-GsWbE-cgFnsEdCmGTvc/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Anterior Triangle of the Neck"
                },
                {
                  "route": "https://drive.google.com/file/d/1VzkevE1Png_Q45fqKgQoOBsdlE33_NgH/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Posterior Triangle of the Neck"
                },
                {
                  "route": "https://drive.google.com/file/d/1eqrKquL_2Ue_H7I_5VLkM3D5JEs3kQH7/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Occipital Region & Posterior Neck"
                },
                {
                  "route": "https://drive.google.com/file/d/1tkWG0p9IcXcbrgleskbwoQdgebx80pNX/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Face"
                },
                {
                  "route": "https://drive.google.com/file/d/1MBreiP6G_bMMQEFSJUV4BF90HIJHX2Cl/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Infratemporal Fossa"
                },
                {
                  "route": "https://drive.google.com/file/d/1E5aE8GIMIfc6-kO12YDILVSsdF9HpEO3/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Orbit"
                },
                {
                  "route": "https://drive.google.com/file/d/112drewWKrT16VMCuXLjD_scprWixuuWM/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Pharynx"
                },
                {
                  "route": "https://drive.google.com/file/d/1088j8YKN6YduR4uSOLOZ9Mro1Im8IzgN/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Nose & Nasal Cavity"
                },
                {
                  "route": "https://drive.google.com/file/d/1PjOofoFscOTp3YlpAgCJNjhnlACTHygv/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Hard & Soft Palate, and Pterygopalatine Fossa"
                },
                {
                  "route": "https://drive.google.com/file/d/1tRltFBE0CcKJjx0OrdeZPsyrojtj8hZn/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Tongue & Floor of the Mouth"
                },
                {
                  "route": "https://drive.google.com/file/d/1LTw0hXLT_WtTA9jMFz9fY4g5g8o6Pjzm/view?usp=sharing",
                  "section": "Head & Neck",
                  "name": "Larynx"
                },
                {
                  "route": "https://drive.google.com/file/d/1OHZpM5sgDfNd-wboW9XWkj-B3Gnub-my/view?usp=sharing",
                  "section": "Thorax",
                  "name": "Thoracic Wall"
                },
                {
                  "route": "https://drive.google.com/file/d/1-yDuDj2XZLhU2BgvvfWq6hI4UW_quM98/view?usp=sharing",
                  "section": "Thorax",
                  "name": "Heart"
                },
                {
                  "route": "https://drive.google.com/file/d/1Qu-BRvfelXnxByOvegr8uOVNTPHlpZ6E/view?usp=sharing",
                  "section": "Thorax",
                  "name": "Lungs"
                },
                {
                  "route": "https://drive.google.com/file/d/1jhI5aq_Aaloafx_js-CSGFzhiDnjk1Xd/view?usp=sharing",
                  "section": "Thorax",
                  "name": "Mediastinum"
                },
                {
                  "route": "https://drive.google.com/file/d/1vqvR523PB5DARvZHDshBK3u3Zqdn9oyL/view?usp=sharing",
                  "section": "Abdomen",
                  "name": "Abdominal Wall and Viscera in situ"
                },
                {
                  "route": "https://drive.google.com/file/d/1euCjPtN_SoUEZIKecxgzm0lIju0T1R8E/view?usp=sharing",
                  "section": "Abdomen",
                  "name": "Hepatoduodenal Ligament, Celiac Trunk, Stomach, Spleen, Liver & Gallbladder"
                },
                {
                  "route": "https://drive.google.com/file/d/17HKZtYVvOSa81woPURnoBYY04h5cj2EK/view?usp=sharing",
                  "section": "Abdomen",
                  "name": "Superior Mesenteric Artery, Inferior Mesenteric Artery, Small Intestine, Pancreas, Large Intestine"
                },
                {
                  "route": "https://drive.google.com/file/d/1aMq3O4AWvI9_6Yjjdo9iuUIQDt6EZPEq/view?usp=sharing",
                  "section": "Abdomen",
                  "name": "Posterior Abdominal Viscera, Muscles and Diaphragm"
                },
                {
                  "route": "https://drive.google.com/file/d/1qQFdD6o4NpxKi0MyKPWINxab58Suydv5/view?usp=sharing",
                  "section": "Pelvis",
                  "name": "Posterior Abdominal Wall, Inguinal Region, Scrotum, Testis & Spermatic Cord"
                },
                {
                  "route": "https://drive.google.com/file/d/1Z_hZi9mdq0Ko7pboAuMbAX_ID6T4w3tM/view?usp=sharing",
                  "section": "Pelvis",
                  "name": "Perineum & External Genitalia"
                },
                {
                  "route": "https://drive.google.com/file/d/151mnUkmn2sN2TdeSBrNjjiXK8Hg1t8Ap/view?usp=sharing",
                  "section": "Pelvis",
                  "name": "Pelvic Cavity"
                }
              ]
            }
          ]
        },
        {
          "name": "Practical Exams",
          "type": "Question Bank",
          "route": "practicals",
          "content": [
            {
              "name": "Practical Exams",
              "content": [
                {
                  "name": "Upper & Lower Limbs, Back - Practical Exam #1",
                  "route": "upper-and-lower-limbs-back-practical-1",
                  "id": "14827b2b-d410-46e8-9c02-b8c626d7b85a",
                  "classroomName": "Upper & Lower Limbs, Back"
                },
                {
                  "name": "Upper & Lower Limbs, Back - Practical Exam #2",
                  "route": "upper-and-lower-limbs-back-practical-2",
                  "id": "50ff513a-cb5f-4956-bd6d-537b0af3778e",
                  "classroomName": "Upper & Lower Limbs, Back"
                },
                {
                  "name": "Upper & Lower Limbs, Back - Practical Exam #3",
                  "route": "upper-and-lower-limbs-back-practical-3",
                  "id": "746fea73-cad0-48d3-9d1a-b4f4bcde432e",
                  "classroomName": "Upper & Lower Limbs, Back"
                },
                {
                  "name": "Head & Neck - Practical Exam #1",
                  "route": "head-and-neck-practical-1",
                  "id": "ff9bdd33-f3f9-4401-b85d-2d7888cc39be",
                  "classroomName": "Head & Neck"
                },
                {
                  "name": "Head & Neck - Practical Exam #2",
                  "route": "head-and-neck-practical-2",
                  "id": "310f90cc-a7ce-4b8d-9cdb-b549acd99a67",
                  "classroomName": "Head & Neck"
                },
                {
                  "name": "Head & Neck - Practical Exam #3",
                  "route": "head-and-neck-practical-3",
                  "id": "9cc708db-d821-4684-b324-0512d623a427",
                  "classroomName": "Head & Neck"
                },
                {
                  "name": "Thorax, Abdomen & Pelvis - Practical Exam #1",
                  "route": "thorax-abdomen-and-pelvis-practical-1",
                  "id": "f97c8145-e819-44c4-85d3-fa9c79015647",
                  "classroomName": "Thorax, Abdomen & Pelvis"
                },
                {
                  "name": "Thorax, Abdomen & Pelvis - Practical Exam #2",
                  "route": "thorax-abdomen-and-pelvis-practical-2",
                  "id": "a3be441a-6351-4a76-81e0-83fdef68c0f2",
                  "classroomName": "Thorax, Abdomen & Pelvis"
                },
                {
                  "name": "Thorax, Abdomen & Pelvis - Practical Exam #3",
                  "route": "thorax-abdomen-and-pelvis-practical-3",
                  "id": "321723b2-81e0-41b3-bee4-4e0a797a015b",
                  "classroomName": "Thorax, Abdomen & Pelvis"
                }
              ]
            }
          ]
        }
      ],
    },
    {
      name: 'Neuroanatomy',
      route: 'neuroanatomy',
      tagId: '298aed63-b9f9-4cf6-b9f5-5dbbf929a429', // bio tag: 65dcea9c-ac11-4e6c-aac0-89fa2f979afb -- but not sure if we want to use this here.
      themePalette: colors.brandPalette.pink,
      icon: 'icons/neuro.svg',
      classroomDescription: `It's all in your head. Let's learn how your brain works to give you life. Watch Bootcamp.com's video lessons and practice with the qbanks to master neuroanatomy.`,
      questionCount: 609,
      educators: [
        {
          firstName: 'John',
          avatar: 'educators/john.gif'
        },
        {
          firstName: 'Chris',
          avatar: 'educators/chris.jpeg',
        }
      ],
      contentTypes: [
        {
          "name": "John's Neuro Videos",
          "route": "videos",
          "content": [
            {
              "name": "Videos",
              "type": "Course",
              "content": [
                {
                  "id": "9741ddae-8a35-4a96-ac8a-1c5615bed02d",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cerebral-cortex",
                  "name": "Cerebral Cortex",
                },
                {
                  "id": "302e3335-01db-44c3-9ba5-bdf70a56496a",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "diencephalon",
                  "name": "Diencephalon",
                },
                {
                  "id": "f7978856-89d2-44b5-8f61-f9b40f2a6236",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "basal-ganglia",
                  "name": "Basal Ganglia",
                },
                {
                  "id": "9017509d-de7c-407b-a7fd-01dce3a44e1a",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "subcortical-white-matter",
                  "name": "Subcortical White Matter",
                },
                {
                  "id": "ca6fc41c-38cd-44db-8079-587c769b34de",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cranial-nerves",
                  "name": "Cranial Nerves",
                },
                {
                  "id": "f862ed49-cdab-4c08-b712-2b9d110ac489",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "superficial-brainstem",
                  "name": "Superficial Brainstem",
                },
                {
                  "id": "eda2a64e-20ae-48e0-9077-5d579c933451",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cerebellum",
                  "name": "Cerebellum",
                },
                {
                  "id": "e6ee46bb-dd87-4225-8780-ad88dc0b24bb",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "brain-ventricles",
                  "name": "Brain Ventricles",
                },
                {
                  "id": "7dee7886-d53a-4c66-9405-0800e8feac0b",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cerebral-vasculature",
                  "name": "Cerebral Vasculature",
                }
              ]
            }
          ]
        },
        {
          "name": "Question Banks",
          "type": "Question Bank",
          "route": "qbank-collection",
          "content": [
            {
              "name": "Question Banks",
              "content": [
                {
                  "name": "Cerebral Cortex",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cerebral-cortex",
                  "lessons": [
                    {
                      "testId": "30287c71-7e29-447f-9ec5-26757d7e8e67",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "e6f25606-0bfb-4b48-83a3-61a3b53d5194",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Diencephalon",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "diencephalon",
                  "lessons": [
                    {
                      "testId": "95a40d89-4767-480d-9e2c-b8e101d53e31",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "82a17aa4-eeef-4dc6-8f25-85d415edebaf",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Basal Ganglia",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "basal-ganglia",
                  "lessons": [
                    {
                      "testId": "fdc84b3d-67b7-4052-ab35-1602a94acd55",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "70c5123f-6118-4183-b8a5-efa110857168",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Subcortical White Matter",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "subcortical-white-matter",
                  "lessons": [
                    {
                      "testId": "5729cd09-8d69-4965-a493-3d485d289174",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "ccc49416-4b88-4b34-9eba-23ede26e39be",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Cranial Nerves",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cranial-nerves",
                  "lessons": [
                    {
                      "testId": "82f152f8-cb5f-426e-9705-3c76c52708e7",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "47d2c3f2-43e1-402d-a016-6701cc30a674",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Superficial Brainstem",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "superficial-brainstem",
                  "lessons": [
                    {
                      "testId": "1707cdde-8527-446f-9c72-d34a48be2504",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "64abb213-7c17-4d64-b327-d4bb26e719b4",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Cerebellum",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cerebellum",
                  "lessons": [
                    {
                      "testId": "fb8884ea-0807-4a06-9709-5c58e0411a5c",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "9dd610ab-4781-418c-b248-4d2d05be2aa3",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Brain Ventricles",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "brain-ventricles",
                  "lessons": [
                    {
                      "testId": "1a8ffed6-2fbb-43e6-be25-41153b38ef2d",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "45c9c966-b8f6-48a6-b2c4-94b3c05af428",
                        type: "application",
                        "route": "bank-2"
                    },
                  ],
                  "type": "Chapter Bank Dropdown"
                },
                {
                  "name": "Cerebral Vasculature",
                  "classroomName": "Neuroanatomy",
                  "section": "",
                  "route": "cerebral-vasculature",
                  "lessons": [
                    {
                      "testId": "cd92ef32-2f75-498c-8ffb-abdbda946164",
                      "type": "identification",
                      "route": "bank-1"
                    },
                    {
                        "testId": "f27a5902-8348-4643-b0d0-c8d1dafbfaba",
                        type: "application",
                        "route": "bank-2"
                    }
                  ],
                  "type": "Chapter Bank Dropdown"
                }
              ]
            }
          ]
        },
        {
          "name": "Atlas",
          "type": "Downloads",
          "route": "atlas",
          "content": [
            {
              "name": "Downloads",
              "content": [
                {
                  "route": "https://drive.google.com/file/d/1KMQ7eZSRhGG5DCzIk6j0IzEF2CAM42sd/view?usp=sharing",
                  "section": "",
                  "name": "Cerebral Cortex"
                },
                {
                  "route": "https://drive.google.com/file/d/1jdCN24mSvrWnmUnWRdrvxTYSPjOhHg0V/view?usp=sharing",
                  "section": "",
                  "name": "Diencephalon"
                },
                {
                  "route": "https://drive.google.com/file/d/14hQTNLhOW18HTy0_kJJQXoYQlT5Pc7VA/view?usp=sharing",
                  "section": "",
                  "name": "Basal Ganglia"
                },
                {
                  "route": "https://drive.google.com/file/d/16-u0wvuEWqci-sa5kZuX4W9BGrUfRR3H/view?usp=sharing",
                  "section": "",
                  "name": "Subcortical White Matter"
                },
                {
                  "route": "https://drive.google.com/file/d/1xtzpWJvKUPReD0An46FV9TTF-RIrII29/view?usp=sharing",
                  "section": "",
                  "name": "Cranial Nerves"
                },
                {
                  "route": "https://drive.google.com/file/d/1nbZXHYW5Jgp1Z0P-QYiGhCnRy_WCJmPN/view?usp=sharing",
                  "section": "",
                  "name": "Superficial Brainstem"
                },
                {
                  "route": "https://drive.google.com/file/d/1GFRaEUHbB49t3_SQTFJuN6XjuJkBrShQ/view?usp=sharing",
                  "section": "",
                  "name": "Cerebellum"
                },
                {
                  "route": "https://drive.google.com/file/d/1zXpsx3llAq3CABn4hgYjMd7ShPjM8o9y/view?usp=sharing",
                  "section": "",
                  "name": "Brain Ventricles"
                },
                {
                  "route": "https://drive.google.com/file/d/11rma8MRuCCcwJ7c_gRbjpE5rueAv-7L0/view?usp=sharing",
                  "section": "",
                  "name": "Cerebral Vasculature"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Histology',
      tagId: "0b7fa985-e051-4a99-85b2-34a50ba4fcac",
      route: 'histology',
      themePalette: colors.brandPalette.teal,
      videoLessonCount: 256,
      classroomDescription: `The study of the microanatomy of cells, tissues, and organs as seen through a microscope. We'll learn the correlation between structure and function of cells.`,
      questionCount: 0,
      icon: 'icons/histo.svg',
      educators: [
        {
          firstName: 'Monica',
          avatar: 'educators/monica.gif'
        },
        {
          firstName: 'James',
          avatar: 'images/james_faul.gif'
        }
      ],
      contentTypes: [
        {
          "name": "Monica's Histology Videos",
          "route": "videos",
          "content": [
            {
              "name": "Videos",
              "type": "Course",
              "content": [
                {
                  "id": "66313bea-e10c-43fe-b3a0-89a200c7f120",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "methods-and-overview-of-cells",
                  "name": "Methods and Overview of Cells",
                },
                {
                  "id": "52a74180-7bd8-4478-8dbd-e5336842f96a",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "epithelium",
                  "name": "Epithelium",
                },
                {
                  "id": "5206c58f-ee4c-424d-bcb4-765b446bb168",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "connective-tissue",
                  "name": "Connective Tissue",
                },
                {
                  "id": "e0c88eb8-1721-4e3e-89c3-9ccb71e308f3",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "cartilage",
                  "name": "Cartilage",
                },
                {
                  "id": "47f39e88-e899-4a67-bbc4-fb928dce4429",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "bone",
                  "name": "Bone",
                },
                {
                  "id": "6531d4ed-8036-437d-90f8-dd350f4afd54",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "muscles",
                  "name": "Muscles",
                },
                {
                  "id": "8d7f2d23-924e-4e87-81df-bd219b51032b",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "nervous-system",
                  "name": "Nervous System",
                },
                {
                  "id": "d9298a0d-edff-4309-be8b-6b8f6898133c",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "cardiovascular-system",
                  "name": "Cardiovascular System",
                },
                {
                  "id": "9b2c7157-d273-4310-9fd6-00f4a6d68f5f",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "respiratory-system",
                  "name": "Respiratory System",
                },
                {
                  "id": "513a2a1e-26b4-4015-9b23-e2a29f65c84e",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "blood-and-blood-formation",
                  "name": "Blood and Blood Formation",
                },
                {
                  "id": "fa8c5869-ae8d-484c-926a-850dc6ebb464",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "lymphatic-system",
                  "name": "Lymphatic System",
                },
                {
                  "id": "84be4ea4-4bbd-4950-bd1d-dd37df5abbdf",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "skin",
                  "name": "Skin",
                },
                {
                  "id": "e0bb07b6-4748-4a30-aca9-b7e3a70b8639",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "endocrine-system",
                  "name": "Endocrine System",
                },
                {
                  "id": "f1b10f9a-d833-4c89-9e1a-e117a8283ee6",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "eyes",
                  "name": "Eyes",
                },
                {
                  "id": "ff76f6bb-75d7-4718-84fa-6bb099751794",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "ears",
                  "name": "Ears",
                },
                {
                  "id": "51cec2a4-1c81-4247-b9d3-0d98a9528e5a",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "oral-cavity",
                  "name": "Oral Cavity",
                },
                {
                  "id": "d2246bf3-2a92-47f9-b069-52eef281a3e3",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "gastrointestinal-tract",
                  "name": "Gastrointestinal Tract",
                },
                {
                  "id": "c19ecf56-7f42-47b3-8768-97b75efa50bc",
                  "classroomName": "Histology",
                  "section": "",
                  "route": "liver-gallbladder-pancreas",
                  "name": "Liver, Gallbladder and Pancreas",
                },
              ]
            }
          ]
        },
        {
          "name": "Question Banks",
          "route": "qbanks",
          "type": "Question Bank",
          "content": [
            {
              "name": "Question Banks",
              "content": [
                {
                  "name": "Methods and Overview of Cells",
                  "route": "methods-and-overview-of-cells",
                  "id": "7b9b73dd-e01f-4042-98b9-92568d5d807b"
                },
                {
                  "name": "Epithelium",
                  "route": "epithelium",
                  "id": "3b478615-3367-4964-b5f5-0e7c7a708a1b"
                },
                {
                  "name": "Connective Tissue",
                  "route": "connective-tissue",
                  "id": "47c4a495-f3e5-4db4-b784-101fdb1fef7f"
                },
                {
                  "name": "Cartilage",
                  "route": "cartilage",
                  "id": "de7a1a8d-2a23-412a-84b1-385f4a33adcc"
                },
                {
                  "name": "Bone",
                  "route": "bone",
                  "id": "e350e794-c91a-48bb-a475-902061055a4c"
                },
                {
                  "name": "Muscles",
                  "route": "muscles",
                  "id": "8a7d45e4-f2fe-414d-bea2-b438d2aeebb7"
                },
                {
                  "name": "Nervous System",
                  "route": "nervous-system",
                  "id": "e3cc5aa2-8301-4a4c-b330-a9f0f9488be9"
                },
                {
                  "name": "Cardiovascular System",
                  "route": "cardiovascular-system",
                  "id": "7aa10e84-4215-4ef3-820a-b2e708af945d"
                },
                {
                  "name": "Respiratory System",
                  "route": "Respiratory System",
                  "id": "28ae7c4f-e5dc-4c47-ad41-66c7fec310e3"
                },
                {
                  "name": "Blood and Blood Formation",
                  "route": "Blood and Blood Formation",
                  "id": "d14455e4-0bfd-4286-b0e9-0a8044262986"
                },
                {
                  "name": "Lymphatic System",
                  "route": "Lymphatic System",
                  "id": "cf3517d7-8723-4568-9da3-28a0f7275755"
                },
                {
                  "name": "Skin",
                  "route": "skin",
                  "id": "d235eec5-9ea0-4b8e-b91c-a26426f59f33"
                },
              ]
            }
          ]
        },
        // {
        //   "name": "Atlas",
        //   "type": "Downloads",
        //   "route": "atlas",
        //   "content": [
        //     {
        //       "name": "Downloads",
        //       "content": []
        //     }
        //   ]
        // }
      ]
    },
    {
      name: 'Embryology',
      route: 'embryology',
      tagId: '1cb0857c-d81a-4e41-8add-8ba000cf0123',
      themePalette: colors.brandPalette.orange,
      icon: 'icons/embryo.svg',
      questionCount: 577,
      videoLessonCount: 29,
      classroomDescription: `The study of the development of an embryo and fetus. We'll learn how babies are really made.`,
      educators: [
        {
          firstName: 'Margaret',
          avatar: 'educators/margaret.gif'
        }
      ],
      contentTypes: [
        {
          "name": "Margaret's Embryology Videos",
          "route": "videos",
          "content": [
            {
              "name": "Videos",
              "type": "Course",
              "content": [
                {
                  "id": "22a77689-af10-4113-af5b-cee1bb196a91",
                  "classroomName": "Embryology",
                  "section": "",
                  "route": "fetal-stage",
                  "name": "Fetal Stage",
                },
                {
                  "id": "cb6d4181-02ad-4429-879c-1512f0c1f885",
                  "classroomName": "Embryology",
                  "section": "",
                  "route": "organogenesis-development-of-external-features",
                  "name": "Organogenesis, Development of External Features",
                },
                {
                  "id": "53dfe026-a7d6-45ce-9ed2-37d1a004fd6f",
                  "classroomName": "Embryology",
                  "section": "",
                  "route": "thoracic-systems-respiratory-and-cardiovascular",
                  "name": "Thoracic Systems (Respiratory & Cardiovascular)",
                },
                {
                  "id": "fc4051ec-12cc-408e-9c0c-a7dd4fbd116e",
                  "classroomName": "Embryology",
                  "section": "",
                  "route": "abdominopelvic-systems-digestive-urinary-and-reproductive",
                  "name": "Abdominopelvic Systems (Digestive, Urinary & Reproductive)",
                }
              ]
            }
          ]
        },
        {
          "name": "Question Banks",
          "route": "qbanks",
          "type": "Question Bank",
          "content": [
            {
              "name": "Question Banks",
              "content": [
                {
                  "name": "Spermatogenesis, Oogenesis",
                  "route": "spermatogenesis-oogenesis",
                  "id": "7b09353f-4c67-4701-925d-4fbee01fce2d"
                },
                {
                  "name": "Fertilization",
                  "route": "fertilization",
                  "id": "997f37bf-f45e-4dad-8bea-0601673b7d94"
                },
                {
                  "name": "Early Cell Development",
                  "route": "early-cell-development",
                  "id": "332bec49-6d16-4e12-8fa5-3d38df2b386e"
                },
                {
                  "name": "Implantation",
                  "route": "implantation",
                  "id": "71e38848-e075-4572-ae87-71815e6d3ba1"
                },
                {
                  "name": "Gastrulation",
                  "route": "gastrulation",
                  "id": "b43e1124-f986-47c5-b69f-df9c4e497bdd"
                },
                {
                  "name": "Amnion & Chorion",
                  "route": "amnion-and-chorion",
                  "id": "a0e8fc4e-821a-4244-9eb2-b4f04bed58b5"
                },
                {
                  "name": "Neurulation",
                  "route": "neurulation",
                  "id": "fab7039d-1dae-41e9-b54f-437a26be5dbb"
                },
                {
                  "name": "Somites, Sclerotome",
                  "route": "somites-sclerotome",
                  "id": "598fa602-0bb5-455f-81bb-742590065939"
                },
                {
                  "name": "CNS",
                  "route": "cns",
                  "id": "16dbb525-a24b-4fe8-8e79-4e7968679ceb"
                },
                {
                  "name": "Face & Pharynx",
                  "route": "face-and-pharynx",
                  "id": "adfb95c8-52c9-49e9-b637-5464380a2365"
                },
                {
                  "name": "Face Development",
                  "route": "face-development",
                  "id": "4d58373e-170c-4ee0-908f-2c83d00ee36f"
                },
                {
                  "name": "Limb Development",
                  "route": "limb-development",
                  "id": "4384a0da-5320-435f-bd61-e725a5a88dde"
                },
                {
                  "name": "Skin Development",
                  "route": "skin-development",
                  "id": "9e729410-3d59-49df-a10c-fb91d091cb9a"
                },
                {
                  "name": "Eye Development",
                  "route": "eye-development",
                  "id": "3e4efe1f-05dd-402d-9c0b-15b70a5116cb"
                },
                {
                  "name": "Ear Development",
                  "route": "limb-development",
                  "id": "bfef4b95-2f9f-4f59-ae8d-469c0cb58ff5"
                },
                {
                  "name": "Separation of Body Cavities",
                  "route": "separation-of-body-cavities",
                  "id": "fa99f697-f5e1-46f7-84e3-7d2073e77188"
                },
                {
                  "name": "Respiratory System",
                  "route": "respiratory-system",
                  "id": "b26800a8-cb0d-4ac5-af46-6b8d22552383"
                },
                {
                  "name": "Cardiovascular System - Development of Blood & Vessels",
                  "route": "cardiovascular-system-development-of-blood-and-vessels",
                  "id": "0db3d5f8-de53-436f-856e-80452917b78a"
                },
                {
                  "name": "Cardiovascular System - Arterial System",
                  "route": "cardiovascular-system-arterial-system",
                  "id": "3681d20d-01be-4bc3-b700-5f100f68e42f"
                },
                {
                  "name": "Cardiovascular System - Venous System",
                  "route": "cardiovascular-system-venous-system",
                  "id": "ec423bb9-809a-4b27-bb5b-40df84f2551d"
                },
                {
                  "name": "Cardiovascular System - Endocardial Looping",
                  "route": "cardiovascular-system-endocardial-looping",
                  "id": "38ebe880-08d4-4a7b-9a9e-5b2d45843730"
                },
                {
                  "name": "Cardiovascular System - Chamber Development",
                  "route": "cardiovascular-system-chamber-development",
                  "id": "e1f006cb-dcc6-4e4a-a982-ab252511707c"
                },
                {
                  "name": "Cardiovascular System - Fetal Circulation",
                  "route": "cardiovascular-system-fetal-circulation",
                  "id": "383eb3fb-bdbd-499a-877f-1d391b628668"
                },
                {
                  "name": "Digestive System - Foregut",
                  "route": "digestive-system-foregut",
                  "id": "7368abf7-f90b-4e62-9a01-8c7d1b37128f"
                },
                {
                  "name": "Digestive System - Midgut, Hindgut & Intestinal Rotation",
                  "route": "digestive-system-midgut-hindgut-and-internal-rotation",
                  "id": "bd49bbcc-b7c0-4259-a412-aded4ab53e67"
                },
                {
                  "name": "Urinary System",
                  "route": "urinary-system",
                  "id": "40ee5276-3c1b-4d51-9c7f-4f3184eaa9b3"
                },
                {
                  "name": "Reproductive System",
                  "route": "reproductive-system",
                  "id": "b1a5599e-cb8d-4585-8458-0cf83d8626e3"
                },
                {
                  "name": "Reproduction",
                  "route": "reproduction",
                  "id": "b8037d69-9e1b-40fd-a6bb-fc7335d95bf3"
                }
              ]
            }
          ]
        },
        {
          "name": "Student Resources",
          "route": "anki",
          "content": [
            {
              "name": "Anki",
              "type": "Link",
              "content": [
                {
                  "name": "Spermatogenesis, Oogenesis",
                  "route": "https://ankiweb.net/shared/info/1321836690"
                },
                {
                  "name": "Fertilization",
                  "route": "https://ankiweb.net/shared/info/792709887"
                },
                {
                  "name": "Early Cell Development",
                  "route": "https://ankiweb.net/shared/info/899556799"
                },
                {
                  "name": "Implantation",
                  "route": "https://ankiweb.net/shared/info/1165620282"
                },
                {
                  "name": "Gastrulation",
                  "route": "https://ankiweb.net/shared/info/601082048"
                },
                {
                  "name": "Amniotic & Chorionic Development",
                  "route": "https://ankiweb.net/shared/info/1784994218"
                },
                {
                  "name": "Neurulation",
                  "route": "https://ankiweb.net/shared/info/1898290619"
                },
                {
                  "name": "Nervous System",
                  "route": "https://ankiweb.net/shared/info/1605939939"
                },
                {
                  "name": "Face & Pharynx",
                  "route": "https://ankiweb.net/shared/info/1471765780"
                },
                {
                  "name": "Face Development",
                  "route": "https://ankiweb.net/shared/info/205999590"
                },
                {
                  "name": "Eye Development",
                  "route": "https://ankiweb.net/shared/info/984910429"
                },
                {
                  "name": "Ear Development",
                  "route": "https://ankiweb.net/shared/info/86231708"
                },
                {
                  "name": "Digestive System",
                  "route": "https://ankiweb.net/shared/info/758261480"
                },
                {
                  "name": "Respiratory System",
                  "route": "https://ankiweb.net/shared/info/1332230343"
                },
                {
                  "name": "Cardiovascular System",
                  "route": "https://ankiweb.net/shared/info/1372398626"
                },
                {
                  "name": "Urinary System",
                  "route": "https://ankiweb.net/shared/info/1910508368"
                },
                {
                  "name": "Reproductive System: Male & Female",
                  "route": "https://ankiweb.net/shared/info/267667472"
                }
              ]
            }
          ]
        }
      ],
    },
  ],
  studyTools: [
    {
      name: 'Bookmarks',
      route: 'bookmarks',
      themePalette: colors.brandPalette.royal,
      showOnHome: true,
    },
  ],
  meta: {
    siteTitle: 'Anatomy Bootcamp',
    subjectIds: [
      '65dcea9c-ac11-4e6c-aac0-89fa2f979afb', // A & P
      'd8496b7d-8fbb-420f-8b05-91e35ad45bc3', // ULLB
      'eb01603a-62af-4b68-aa9d-b160a3f28279', // Head & Neck
      '7ff0f5aa-9081-4299-bf2e-4944e24582e5', // TAP
      '298aed63-b9f9-4cf6-b9f5-5dbbf929a429', // Neruoanatomy
      '7d5e9730-7ace-4b16-99a1-b65581ad55da', // Histology
      '1cb0857c-d81a-4e41-8add-8ba000cf0123', // Embryology
    ],
  }
};

const getAnatomyConfigObject = () => {};

export {getAnatomyConfigObject} // TODO this is a vestige of the old anatomy config setup & can be removed when legacy Anatomy code is deleted (used in router & various AB content pages)
export default config;
