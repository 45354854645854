import styled from 'styled-components';

import Switch from 'rc-switch';

import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {GutterColumn} from '@bootcamp/web/src/components/Spacing';
import {Column} from '@bootcamp/web/src/components/Grid';
import NextButtonImage from '../assets/Next.png';
import PreviousButtonImage from '../assets/Previous.png';
import ExitButtonImage from '../assets/Exit.png';
import SuspendButtonImage from '../assets/Suspend.png';
import EndButtonImage from '../assets/End.png';
import NoButtonImage from '../assets/No.png';
import YesButtonImage from '../assets/Yes.png';
import ExhibitButtonImage from '../assets/Exhibit.png';
import MarkButtonImage from '../assets/Mark.png';
import MarkedButtonImage from '../assets/Marked.png';
import ReviewButtonImage from '../assets/Review.png';
import ReviewAllButtonImage from '../assets/Review All.png';
import ReviewMarkedButtonImage from '../assets/Review Marked.png';
import ReviewIncompleteButtonImage from '../assets/Review Incomplete.png';
import CheckButtonImage from '../assets/Check Button.png';
import LearningButtonImage from '../assets/Learning Button.png';
import ReviewingButtonImage from '../assets/Reviewing Button.png';
import MasteredButtonImage from '../assets/Mastered Button.png';

import MouseImageSrc from '../assets/tutorial/tut_mouseptr.gif';
import ScrollImageSrc from '../assets/tutorial/tut_scroll.gif';
import ReviewScreenImageSrc from '../assets/tutorial/tut_revscreen.gif';
import TimeLimitImageSrc from '../assets/tutorial/timelimit.gif';
import useHotkeys from "@reecelucas/react-use-hotkeys";



export const Container = styled(GutterColumn)`
  padding: 2em 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  color: #252E33;

  a {
    color: #46b1e1;
    text-decoration: none;
  }
`;

export const Header = styled.p`
  font-size: 32px;
  margin: 0 auto;
  margin-bottom: 10px;
`;


export const TableContainer = styled(Column)`
  height: 500px;
  overflow-y: scroll;
  border: 1px solid black;
`;

export const Table = styled.table`
  padding: 2em 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #252E33;
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
  tr {
    cursor: pointer;
  }
  th {
    background: #698DA8;
    border: 1px solid black;
  }
  th, td {
    padding: 4px 8px;
    text-align: left;
    font-weight: normal;
    width: 25%;
  }
`;

export const PrometricWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  border: 3px solid black;
  padding: 32px;
  font-family: Arial;
`;

export const NextText = styled.p.attrs(props => ({children: `Click on the 'Next' button to continue.`}))`
  font-weight: bold;
  margin: 0;
`;

const ImageButton = styled.img`
  user-select: none;
  user-drag: none;
  display: block;
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 56px;
  }
`;

const HotkeyHandler = ({hotkey='', handler, children}) => {
  const {searchUserInteractions, bootcamp} = useUserDataContext();
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;

  useHotkeys(hotkey, handler, {enabled: showKeyboardShortcuts && ['dat', 'inbde', 'oat'].includes(bootcamp)})
  return children
}

export const StyledNextButton = styled(ImageButton).attrs(props => ({src: NextButtonImage}))``;
export const NextButton = props => (
  <HotkeyHandler hotkey={props.hotkey || ['n', 'ArrowRight']} handler={props.onClick}>
    <StyledNextButton {...props} />
  </HotkeyHandler>
)
export const StyledYesButton = styled(ImageButton).attrs(props => ({src: YesButtonImage}))``;
export const YesButton = props => (
  <HotkeyHandler hotkey={props.hotkey} handler={props.onClick}>
    <StyledYesButton {...props} />
  </HotkeyHandler>
)
export const StyledNoButton = styled(ImageButton).attrs(props => ({src: NoButtonImage}))``;
export const NoButton = props => (
  <HotkeyHandler hotkey={props.hotkey} handler={props.onClick}>
    <StyledNoButton {...props} />
  </HotkeyHandler>
)
export const StyledPreviousButton = styled(ImageButton).attrs(props => ({src: PreviousButtonImage}))``;
export const PreviousButton = props => (
  <HotkeyHandler hotkey={props.hotkey || ['p', 'ArrowLeft']} handler={props.onClick}>
    <StyledPreviousButton {...props} />
  </HotkeyHandler>
)
export const StyledEndButton = styled(ImageButton).attrs(props => ({src: EndButtonImage}))``;
export const EndButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'd'} handler={props.onClick}>
    <StyledEndButton {...props} />
  </HotkeyHandler>
)
export const StyledExitButton = styled(ImageButton).attrs(props => ({src: ExitButtonImage}))``;
export const ExitButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'x'} handler={props.onClick}>
    <StyledExitButton {...props} />
  </HotkeyHandler>
)
export const StyledSuspendButton = styled(ImageButton).attrs(props => ({src: SuspendButtonImage}))``;
export const SuspendButton = props => (
  <HotkeyHandler hotkey={props.hotkey} handler={props.onClick}>
    <StyledSuspendButton {...props} />
  </HotkeyHandler>
)
export const StyledExhibitButton = styled(ImageButton).attrs(props => ({src: ExhibitButtonImage}))``;
export const ExhibitButton = props => (
  <HotkeyHandler hotkey={props.hotkey} handler={props.onClick}>
    <StyledExhibitButton {...props} />
  </HotkeyHandler>
)
export const StyledMarkButton = styled(ImageButton).attrs(props => ({src: MarkButtonImage}))``;
export const MarkButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'm'} handler={props.onClick}>
    <StyledMarkButton {...props} />
  </HotkeyHandler>
)
export const StyledMarkedButton = styled(ImageButton).attrs(props => ({src: MarkedButtonImage}))``;
export const MarkedButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'm'} handler={props.onClick}>
    <StyledMarkedButton {...props} />
  </HotkeyHandler>
)
export const StyledReviewButton = styled(ImageButton).attrs(props => ({src: ReviewButtonImage}))``;
export const ReviewButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'r'} handler={props.onClick}>
    <StyledReviewButton {...props} />
  </HotkeyHandler>
)
export const StyledReviewAllButton = styled(ImageButton).attrs(props => ({src: ReviewAllButtonImage}))``;
export const ReviewAllButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'a'} handler={props.onClick}>
    <StyledReviewAllButton {...props} />
  </HotkeyHandler>
)
export const StyledReviewMarkedButton = styled(ImageButton).attrs(props => ({src: ReviewMarkedButtonImage}))``;
export const ReviewMarkedButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'm'} handler={props.onClick}>
    <StyledReviewMarkedButton {...props} />
  </HotkeyHandler>
)
export const StyledReviewIncompleteButton = styled(ImageButton).attrs(props => ({src: ReviewIncompleteButtonImage}))``;
export const ReviewIncompleteButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'i'} handler={props.onClick}>
    <StyledReviewIncompleteButton {...props} />
  </HotkeyHandler>
)
export const StyledCheckButton = styled(ImageButton).attrs(props => ({src: CheckButtonImage}))``;
export const CheckButton = props => (
  <HotkeyHandler hotkey={props.hotkey || 'c'} handler={props.onClick}>
    <StyledCheckButton {...props} />
  </HotkeyHandler>
)
export const StyledLearningButton = styled(ImageButton).attrs(props => ({src: LearningButtonImage}))`margin-right: 16px;`;
export const LearningButton = props => (
  <HotkeyHandler hotkey={props.hotkey || '1'} handler={props.onClick}>
    <StyledLearningButton {...props} />
  </HotkeyHandler>
)
export const StyledReviewingButton = styled(ImageButton).attrs(props => ({src: ReviewingButtonImage}))`margin-right: 16px;`;
export const ReviewingButton = props => (
  <HotkeyHandler hotkey={props.hotkey || '2'} handler={props.onClick}>
    <StyledReviewingButton {...props} />
  </HotkeyHandler>
)
export const StyledMasteredButton = styled(ImageButton).attrs(props => ({src: MasteredButtonImage}))``;
export const MasteredButton = props => (
  <HotkeyHandler hotkey={props.hotkey || '3'} handler={props.onClick}>
    <StyledMasteredButton {...props} />
  </HotkeyHandler>
)

export const MouseImage = styled(ImageButton).attrs(props => ({src: MouseImageSrc}))``;
export const ScrollImage = styled(ImageButton).attrs(props => ({src: ScrollImageSrc}))``;
export const ReviewScreenImage = styled(ImageButton).attrs(props => ({src: ReviewScreenImageSrc}))``;
export const TimeLimitImage = styled(ImageButton).attrs(props => ({src: TimeLimitImageSrc}))``;

export const Passage = styled.div`
  max-height: 400px;
  min-height: 400px;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #D5D8D9;
  p {
    margin: .5em 4em;
    img {
      margin: auto;
      display: block;
    }
  }
  margin-bottom: 21px;
`;

export const Section = styled.div`
  width: 100%;
  margin-bottom: 21px;
`;
export const CenterSection = styled(Section)`
  text-align: center;
`;
export const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 21px;
`;

export const SettingsContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  overflow: auto;
`;
export const SettingsWrapper = styled.div`
`;
export const SettingsHeader = styled.div`
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 16px;
`;
export const Setting = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 12px 16px;
  border: 1px solid ${({theme}) => theme.colors.borderPalette.primary};
  border-radius: 8px;
  background: ${({theme}) => theme.colors.backgroundPalette.secondary};
`;
export const SettingWrapper = styled.div`
  flex: 1;
`;
export const Toggle = styled(Switch)`
  margin-right: 16px;

  &:after {
    transform: none !important;
    animation-name: none !important;
  }
`;

