import React from 'react';
import styled from 'styled-components';
import {useUserDataContext} from '../../../../contexts/UserData';
import {IntercomAPI} from 'react-intercom';
import TagManager from 'react-gtm-module';

import {Container, Header, Body} from '../../';
import {Body3} from '../../../Typography';
import {Button} from '../../../Branding/Buttons';


const StyledContainer = styled(Container)`
  overflow: hidden;
  max-height: 500px;
`;
const StyledBody = styled(Body)`
  overflow-y: auto;
`;
const StyledHeader = styled(Header)`
  margin-bottom: 24px;
`;
const SubHeader = styled(Body3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l}
`;
const SelectButton = styled(Button)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;

const FurtherInfo = ({setRedirect}) => {
  const {setBootcamp, validBootcamps} = useUserDataContext();

  function getBootcampFromUrlState(validBootcamps) {
    const search = window.location.hash.split('?')[1];
    const bootcamp = new URLSearchParams(search)
      ?.get('redirectTo')
      ?.replace('/', '')
      ?.split('/')[0];
    return validBootcamps.includes(bootcamp) ? bootcamp : null;
  }
  const bootcampDestination = getBootcampFromUrlState(validBootcamps) || 'anatomy';

  const handleSelect = (info) => {
    IntercomAPI('update', {'Area_of_Study': info});
    setRedirect(`/${bootcampDestination}`);
    setBootcamp(bootcampDestination);
  };

  return (
    <StyledContainer>
      <StyledBody>
        <StyledHeader>What are you studying?</StyledHeader>
        <SelectButton color={'indigo'} fixed onClick={() => handleSelect('nursing')}>
          Nursing
        </SelectButton>
      </StyledBody>
  </StyledContainer>
  );
};

FurtherInfo.defaultProps = {};
FurtherInfo.propTypes = {};

export default FurtherInfo;
