import React from 'react';

import {
  Container,
  Wrapper,
  Heading,
  HeadingName,
  HeadingSchool,
  HeadingResultContainer,
  HeadingResultScore,
  HeadingResultPercentile,
  Comment,
  StudentImageContainer,
  StudentImage,
  StudentImageIconContainer,
  StudentImageIcon,
} from './shared';

import deepali from '../assets/deepalimittal.png';
import jaden from '../assets/jadenborders.png';

const outcome1 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={deepali} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Deepali Mittal
        </HeadingName>
        <HeadingResultContainer>
          <HeadingResultScore>
            360 AA
          </HeadingResultScore>
          <HeadingResultPercentile>
            (91%)
          </HeadingResultPercentile>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      “I would recommend OAT Bootcamp to every student who plans on taking the OAT because I do not think I would have done as well without it. <strong>It prepared me for the worst and I am so thankful for that.</strong>”
    </Comment>
  </Container>
);

const outcome2 = (
  <Container>
    <Wrapper>
      <StudentImageContainer>
        <StudentImage src={jaden} />
        <StudentImageIconContainer>
          <StudentImageIcon />
        </StudentImageIconContainer>
      </StudentImageContainer>
      <Heading>
        <HeadingName>
          Jaden Borders
        </HeadingName>
        <HeadingResultContainer>
          <HeadingResultScore>
            360 AA
          </HeadingResultScore>
          <HeadingResultPercentile>
            (91%)
          </HeadingResultPercentile>
        </HeadingResultContainer>
      </Heading>
    </Wrapper>
    <Comment>
      “The schedule given by Bootcamp to get through all of the material was <strong>extremely helpful.</strong> I followed the schedule for the majority of my study time.”
    </Comment>
  </Container>
);

export const oatOutcomes = [
  outcome1,
  outcome2,
];