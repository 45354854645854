import React from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

import {useModalContext} from '../../../../contexts/Modal';
import Upgrade from '../../../../components/Modal/components/Upgrade';

import {Row, Column, FlexBox} from '../../../../components/Grid';
import {H3, H4, H5, Label3} from '../../../../components/Typography';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {Play} from '@styled-icons/heroicons-solid/Play';
import {CheckCircle} from '@styled-icons/material-rounded/CheckCircle';
import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {Flag} from '@styled-icons/evaicons-solid/Flag';

import {findByRenderType, parseVideoDuration} from '@bootcamp/shared/src/util';


const IconArea = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const BadgeWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
`;
const StyledBadge = styled(Badge)`
  path {
    fill: ${({theme}) => theme.overlays.opacity.dark._400};
  }
`;
const DetailText = styled(H5)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-right: ${({theme, padded}) => padded ? '4px' : theme.layouts.spacing.m};
  margin-left: auto;
  transition: all .3s;
`;
const PlayButtonIcon = styled(Play).attrs({size: 32})`
  color: ${({theme, themePalette}) => themePalette ? themePalette.default : theme.colors.brandPalette.violet.default};
  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 24px;
    height: 24px;
  }
`;
const FlagButtonIcon = styled(Flag).attrs({size: 20})`
  color: ${({theme, themePalette}) => themePalette ? themePalette.default : theme.colors.brandPalette.violet.default};
`;
const RowContainer = styled(Row)`
  align-items: center;
  min-height: 48px;
  ${({mobileOnly}) => mobileOnly && css`
    display: none;
    ${({theme}) => theme.mediaQueries.mobileL} {
      display: flex;
    }
  `}
`;
const RowLabel = styled(Label3)`
  color: ${({theme, themePalette}) => themePalette ? themePalette.dark : theme.colors.brandPalette.violet.dark};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  display: none;
  ${({mobileOnly}) => mobileOnly && css`
    display: none !important;
    ${({theme}) => theme.mediaQueries.mobileL} {
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 14px;
    }
  `}
`;
const RowTitleContainer = styled(Column)`
  width: auto;
  ${({desktopOnly}) => desktopOnly && css`
    ${({theme}) => theme.mediaQueries.mobileL} {
      display: none;
    }
  `}
`;
const RowTitle = styled(H4)`
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  ${({theme}) => theme.mediaQueries.mobileL} {
    font-weight: 600;
  }
`;

const RowLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  min-height: 56px;
  padding: 4px 24px;
  background: white;
  transition: all .2s;
  border-radius: 0px;

  ${({locked}) => locked && `
    &:hover {
      ${OptionContainer} {
        display: none;
      }
      ${BlockNumberContainer} {
        display: none;
      }
      ${RowTitleContainer} {
        display: none;
      }
      ${DetailText} {
        display: none;
      }
      ${UpgradeOverlayContainer} {
        display: flex;
      }
      ${BadgeWrapper} {
        margin-left: auto;
      }
    }
  `}

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 8px 16px;
    display: flex;
  }

  &:hover {
    transform: scale(1.01);
    transition: all .1s ease 0s;
    border-radius: 8px;
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
    ${({dark}) => dark && css`
      background: ${({theme}) => theme.colors.neutralsPalette.light};
    `}
  }

  ${({dark}) => dark && css`
    background: ${({theme}) => theme.colors.neutralsPalette.light};
    border-bottom: .5px solid ${({theme}) => theme.overlays.opacity.dark._50};

    ${RowTitle} {
      color: ${({theme}) => theme.colors.neutralsPalette.dark};
    }
`}

  ${({active}) => active && css`
    padding: 28px 34px;
    padding-left: 14px;
    background: white;
    border-radius: 8px;
    transition: all .3s;
    box-shadow: ${({theme}) => theme.elevation.shadow.medium};

    ${({theme}) => theme.mediaQueries.mobileL} {
      padding: 16px;
    }


    &:hover {
      background: white;
      box-shadow: ${({theme}) => theme.elevation.shadow.high};

      ${PlayButtonIcon} {
        transform: scale(1.05);
      }
    }

    ${BlockNumberContainer} {
      background: ${({theme, themePalette}) => themePalette ? themePalette.light : theme.colors.brandPalette.violet.light};
      border: 2px solid white;
      ${({theme}) => theme.mediaQueries.mobileL} {
        min-width: 32px;
        min-height: 32px;
      }

      ${BlockNumber} {
        color: ${({theme, themePalette}) => themePalette ? themePalette.dark : theme.colors.brandPalette.violet.dark}
      }
    }
    ${RowLabel} {
      display: block;
      ${({theme}) => theme.mediaQueries.mobileL} {
        display: block !important;
      }
    }
    ${RowTitle} {
      color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
    }
    ${OptionOuter} {
      border-color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    }
    ${PlayButtonIcon} {
      box-shadow: ${({theme}) => theme.elevation.shadow.medium};
      &:hover {
        background: ${({theme, themePalette}) => themePalette ? themePalette.hover : theme.colors.brandPalette.violet.hover};
      }
    }
    ${DetailText} {
      color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
    }
  `}
`;

const BlockNumberContainer = styled(FlexBox)`
  align-items: center;
  width: auto;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  border-radius: 8px;
  min-width: 48px;
  min-height: 48px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-right: ${({theme}) => theme.layouts.spacing.s};
    min-width: 30px;
    min-height: 30px;
  }
`;

const BlockNumber = styled(H3)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  ${({theme}) => theme.mediaQueries.mobileL} {
    font-weight: 600;
    font-size: 14px;
  }
`;
const Duration = styled(H4)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  ${({theme}) => theme.mediaQueries.mobileL} {
    font-weight: 600;
    white-space: pre;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  transition: all 100ms;
  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.light};
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
    border: .5px solid ${({theme}) => theme.overlays.opacity.dark._50};
    padding: 4px;
    margin-right: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const OptionOuter = styled.div`
  min-width: 20px;
  min-height: 20px;
  padding: 1px;
  border-radius: 100px;
  border: 1px solid ${({theme}) => theme.overlays.opacity.dark._200};
  ${({theme}) => theme.mediaQueries.mobileL} {
    min-width: 16px;
    min-height: 16px;
  }
`;
const OptionInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: none;
`;
const OptionChecked = styled(CheckCircle).attrs(props => ({size: 24}))`
  fill: ${({theme, themePalette}) => themePalette ? themePalette.default : theme.colors.brandPalette.violet.default};
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 16px;
    width: 16px;
  }
`;

const Option = ({checked, onClick, themePalette}) => checked
  ? <OptionChecked onClick={onClick} themePalette={themePalette}/>
  : <OptionOuter onClick={onClick} children={<OptionInner onClick={onClick}/>}/>

const UpgradeOverlayContainer = styled.div`
  position: absolute;
  display: none;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: white;
  align-items: center;
  justify-content: center;
`;
const UpgradeOverlayText = styled(H3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const UpgradeOverlay = () => (
  <UpgradeOverlayContainer>
    <UpgradeOverlayText>Upgrade to unlock</UpgradeOverlayText>
    <ArrowForwardOutline color={'#85868E'} size={20}/>
  </UpgradeOverlayContainer>
);

function getDuration(testBlock) {
  try {
    const json = findByRenderType(testBlock.components, 'video');
    const rawDuration = JSON.parse(json).duration;
    return parseVideoDuration(rawDuration);

  } catch(error) {
    return '';
  }
}

const VideoRow = ({index, testBlock, blockProgress, saveBlockProgress, active, themePalette, locked, bootcamp, route}) => {
  const {modalDispatch} = useModalContext();
  const {status} = blockProgress || {};
  const title = testBlock && testBlock.title;
  const number = testBlock.type === 'text'
    ? findByRenderType(testBlock.components, 'contentType', 'Info')
    : findByRenderType(testBlock.components, 'number', ['questionSet', 'passage'].includes(testBlock?.type) ? 'Quiz' : '');
  const duration = getDuration(testBlock);
  const numQuestions = testBlock.type === 'questionSet' ? testBlock.questionConnections.items.length : null;
  const linkTo = locked ? `#` : `videos/${route}?index=${index}`;


  const onClick = !locked
    ? () => {}
    : ['oat', 'dat', 'inbde', 'anatomy'].includes(bootcamp)
    ? () => modalDispatch({
        type: 'open',
        component: Upgrade,
        enableClickClose: true,
        modalContainerStyle: theme => `
          background: ${theme.overlays.opacity.dark._300};
          padding: 10vh 10vw;
          align-items: flex-start;
          ${theme.mediaQueries.tablet} {
            background: ${theme.overlays.opacity.dark._400};
            padding: 0;
          }
        `
      })
    : () => window.location = `https://bootcamp.com/${bootcamp}/upgrade`;

  const onOptionClick = e => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    saveBlockProgress({status: status === 'complete' ? '' : 'complete'}, index)
  };

  return (
    <RowLink themePalette={themePalette} onClick={onClick} to={linkTo} active={active} dark={status === 'complete'} locked={locked}>
      <UpgradeOverlay/>
      <RowContainer>
        <OptionContainer onClick={onOptionClick}>
          <Option checked={status === 'complete'} themePalette={themePalette} />
        </OptionContainer>
        <BlockNumberContainer>
          <BlockNumber>{number}</BlockNumber>
        </BlockNumberContainer>
        <RowLabel themePalette={themePalette} mobileOnly>{index === 1 ? 'Get started' : 'Dive back in'}</RowLabel>

        <RowTitleContainer>
          <RowLabel themePalette={themePalette}>{index === 1 ? 'Get started' : 'Dive back in'}</RowLabel>
          <RowTitle>{title}</RowTitle>
        </RowTitleContainer>
        {locked
          ? (
            <IconArea>
              {numQuestions && <Duration>{numQuestions} question{numQuestions !== 1 ? 's' : ''}</Duration>}
              {duration && <Duration>{duration}</Duration>}
              <BadgeWrapper>
                <StyledBadge/>
              </BadgeWrapper>
            </IconArea>
          )
          : (
            <IconArea>
              {numQuestions && <Duration padded={numQuestions > 0}>{numQuestions} question{numQuestions !== 1 ? 's' : ''}</Duration>}
              {duration && <Duration>{duration}</Duration>}
              {!['text', 'questionSet'].includes(testBlock.type) && <PlayButtonIcon themePalette={themePalette}/>
              }
            </IconArea>
          )
        }
      </RowContainer>
    </RowLink>
  )
}

export default VideoRow;
