import React, {useMemo, useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {useHistory, Link} from 'react-router-dom';
import {H1, Body2} from '../../../components/Typography';

import {LoadingSpinner} from '../../../components/Branding';
import {useTable, usePagination, useSortBy} from 'react-table';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import {BareSelect} from '../../components/MasteryReview/Select';

import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useAsync} from '@bootcamp/shared/src/util/hooks';
import {getTestProgressByUserIdHashTestId} from '@bootcamp/shared/src/requests';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import {Edit} from '@styled-icons/material-rounded/Edit';
import Storage from '@aws-amplify/storage';

import {ChevronLeft} from '@styled-icons/fluentui-system-filled/ChevronLeft';
import {ChevronRight} from '@styled-icons/fluentui-system-filled/ChevronRight';
import {PauseCircle} from '@styled-icons/fluentui-system-filled/PauseCircle';
import CreateTestButton from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/CreateTestButton';
import {Blurb} from '../CustomTestReview/components/shared';
import {Info} from '@styled-icons/fluentui-system-filled/Info';
import Container from '../PageContainer';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const Title = styled(H1)`
  color: white;
  margin-bottom: 16px;
`;
const StyledBlurb = styled(Blurb)`
  margin-bottom: 16px;
`;
const StyledLink = styled(Link)`
  color: white;
`;
const PauseIcon = styled(PauseCircle)`
  width: 18px;
  height: 18px;
  fill: ${({theme}) => theme.colors.interfacePalette.yellow.default};
`;
const StyledInfo = styled(Info)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
  color: ${({theme}) => theme.overlays.opacity.light._300};
`;

const retrieveQuestionProgresses = testData => {
  const {blockProgresses: {items: blockProgressItems}} = testData || {blockProgresses: {items: []}};
  return blockProgressItems.reduce((acc, blockProgress) => [...acc, ...(blockProgress.questionProgresses.items || [])], []);
}

const CustomTests = ({}) => {
  const {DEFAULT_USER_ID, searchUserInteractions, bootcamp} = useUserDataContext();
  const [currentPage, setCurrentPage] = useState({pageIndex: 0, pageSize: 10});
  const savedTests = searchUserInteractions(`${DEFAULT_USER_ID}_${bootcamp}_savedTests`);

  const fetchSavedTests = useCallback(async () => {
    return await Promise.all(savedTests?.testList?.slice()?.reverse()?.slice(currentPage.pageIndex*currentPage.pageSize, (currentPage.pageIndex + 1) * currentPage.pageSize).map(async testStorageKey => {
      try {
        const result = await Storage.get(testStorageKey, {
          download: true,
          cacheControl: 'no-cache',
          contentType: 'application/json',
        })
        const testData = await new Response(result.Body).json();
        const testResults = testData.testId ? await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${testData.testId}`, true) : {data: {TestProgressByTestId: {items: []}}};
        return {testData, testResults}
      } catch (e) {
        console.log(e);
      }
    }));
  }, [DEFAULT_USER_ID, currentPage])

  const {value, pending, execute} = useAsync(fetchSavedTests);
  const data = value || [];

  function getScorePercentage(questionProgresses, overridingMaxScore) {
    const {score, maxScore} = questionProgresses.reduce((acc, curr) => {
      const answerState = JSON.parse(curr?.answerState || "{}");
      // TODO we'll probably have to pull max score out of the question like we're doing on the score report page since
      // it isn't always in answer state...
      return {
        score: acc.score + (answerState.score || (curr.didSelectCorrectAnswer && 1) || 0),
        maxScore: acc.maxScore + (answerState.maxScore || 1)
      }
    }, {score: 0, maxScore: 0});

    return `${Math.round(((score / (overridingMaxScore || maxScore)) || 0) * 100)}%`;
  }

  const columns = useMemo(
    () => {
      let columns = [
        {
          Header: '#',
          Cell: ({row}) => savedTests?.testList?.length - row.index - (currentPage.pageIndex*currentPage.pageSize),
          accessor: row => savedTests?.testList?.length - row.index - (currentPage.pageIndex*currentPage.pageSize)
        },
        {
          Header: 'Score',
          Cell: ({row}) => {
            const {data: {TestProgressByTestId: {items}}} = row.original.testResults;
            const {config: {maxScore}, submitted} = row.original.testData || {};

            if (items[0]) {
              const questionProgresses = retrieveQuestionProgresses(items[0]);
              return submitted ? getScorePercentage(questionProgresses, maxScore) : <PauseIcon/>;
            }
            return 'N/A'
          },
        },
        {
          Header: 'Name',
          Cell: ({row}) => {
            const {testTitle, testStorageKey, customTitle} = row.original.testData.config;
            const testName = `Previous Test #${savedTests?.testList?.length - row.index - (currentPage.pageIndex*currentPage.pageSize)}`
            return (
              <div>{customTitle || testName} <Edit className="editButton" style={{cursor: 'pointer'}} size={16} onClick={async e => {
                e.persist();
                e.preventDefault();
                e.stopPropagation();
                const newTitle = window.prompt('Rename test to...');
                if (!newTitle) return;
                const testData = row.original.testData;
                testData.config.customTitle = newTitle;
                await Storage.put(testStorageKey, JSON.stringify(testData), {contentType: 'application/json'});
                execute()
                return false;
              }} /></div>
            )
          }
        },
        {
          Header: 'Date Taken',
          Cell: ({row}) => {
            const {createdAt} = row.original.testData.config;
            return moment(createdAt).format('MMM Do, YYYY');
          },
          accessor: row => new Date(row.createdAt),
          sortType: 'datetime'
        },
        {
          Header: 'Type',
          Cell: ({row}) => {
            const testType = row.original.testData.config.type;
            switch (testType) {
              case 'preclinical':
                return 'Step 1 - Boards Style';
              case 'bites':
                return 'Bites';
              case 'anatomy':
                return 'Anatomy Bootcamp';
              default:
                return 'Custom';
            }
          }
        },
        {
          Header: 'Settings',
          Cell: ({row}) => {
            return `${row.original.testData.config.timed ? 'Timed' : 'Untimed'}, ${row.original.testData.config.tutorMode ? 'Tutored' : 'Untutored'}`
          }
        },
        {
          Header: 'Questions',
          Cell: ({row}) => {
            return row.original.testData.selectedQuestions.length
          }
        },
        {
          Header: ' ',
          Cell: () => <ChevronRight size={24} color="#C8C9CE" />
        }
      ]
      return columns
    },
    [data, execute]
  );

  const {config} = useBootcampConfigContext();
  const history = useHistory();
  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | Previous Tests</title>
        <meta name="description" content={`${config.meta.siteTitle} Previous Tests`}></meta>
      </Helmet>
      <StyledBreadcrumbs history={history} lockAtDepth={2} />
      <Title>Previous Tests</Title>
      {bootcamp === 'nclex' &&
        <StyledBlurb bareIcon={<StyledInfo/>} lightMode>
          Your Readiness Exam attempts can be reviewed on the <StyledLink to={'/nclex/next-gen-readiness-exams'}>Readiness Exams page.</StyledLink>
        </StyledBlurb>
      }
      <Table columns={columns} data={data} loaded={!pending} pageCount={Math.ceil(savedTests?.testList?.length / currentPage.pageSize)} setCurrentPage={useCallback(setCurrentPage)} />
    </Container>
  )
};

const TableStyles = styled.div`
  width: 100%;
  table {
    background: white;
    border-radius: ${({hasResults}) => hasResults ? '8px' : '8px 8px 0px 0px'};
    overflow: hidden;
    width: 100%;
    border-collapse: collapse;

    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
    th {
      cursor: default;
      background: #FAFAFA;

      &:first-child {
        font-weight: 700;
        color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
        text-align: center;
        font-size: 16px;
      }

      font: bold 16px 'proxima-nova', sans-serif;
      text-shadow: none;
      border-width: 0;
      padding: 15px;
      min-height: 74px;
      text-align: left;
      border-bottom: 1px solid #EDEDF0;
      font-weight: 600;
    }
    tr {
      cursor: pointer;
      &:last-child {
        td {
          border-bottom: none;
        }
      }
      &:hover {
        background-color: ${({theme}) => theme.colors.neutralsPalette.offWhite};
      }
    }
    td {
      min-width: 50px;
      font-size: 16px;

      &:first-child {
        font-weight: 700;
        color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
      height: 50px;
      padding: 8px 15px;
      vertical-align: middle;
      font: 400 16px 'proxima-nova', sans-serif;
      text-align: left;
      border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
      position: relative;
      .editButton {
        position: absolute;
        margin: auto 8px;
        display: none;
      }
      &:hover {
        .editButton {
          display: inline;
        }
      }
    }
  }
`;

const LoadingSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;
const DataLoadingSpinner = (
  <LoadingSpinnerContainer>
    <LoadingSpinner color={'white'} size={24}/>
  </LoadingSpinnerContainer>
)

const ResultsMessage = styled(Body2)`
  text-align: center;
  padding: 24px;
  width: 100%;
  font-weight: 400;
  color: rgb(47, 48, 55);
  background: white;
  border-radius: 0px 0px 8px 8px;
`;

const TableWrapper = styled.div`
`;

const PaginationWrapper = styled.div`
  padding-top: ${({theme}) => theme.layouts.spacing.l};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;

  button {
    height: 42px;
    width: 42px;
    border-radius: 100%;
    border: 1px solid ${({theme}) => theme.overlays.opacity.light._100};
    background: ${({theme}) => theme.overlays.opacity.light._100};
    color: white;
    cursor: pointer;
    margin-right: 8px;

    &:hover {
      background: ${({theme}) => theme.overlays.opacity.light._200};
    }
    &:disabled {
      cursor: not-allowed;
      border: 1px solid ${({theme}) => theme.overlays.opacity.light._50};
      background: ${({theme}) => theme.overlays.opacity.light._50};
      color: ${({theme}) => theme.overlays.opacity.light._400};
      &:hover {
        background: ${({theme}) => theme.overlays.opacity.light._50};
      }
    }
  }
  span {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  input {
    width: 80px;
    text-align: center;
    border: 1px solid ${({theme}) => theme.overlays.opacity.light._50};
    background: ${({theme}) => theme.overlays.opacity.light._50};
    font-family: 'proxima-nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: white;
    height: 40px;
    border-radius: 8px;
    margin-left: ${({theme}) => theme.layouts.spacing.m};
    ::placeholder {
      color: ${({theme}) => theme.overlays.opacity.light._400};
    }
  }
`;
const StyledCreateTestButton = styled(CreateTestButton)`
  margin: 0 auto;
`;

const StyledSelect= styled(BareSelect)`
  margin-left: 8px;
  select {
    padding: 12px;
    margin-right: 28px;
  }
`;


const Table = ({columns, pageCount, data, loaded, setCurrentPage}) => {
  const {bootcamp} = useUserDataContext();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [{id: '#', desc: false}], hiddenColumns: document.documentElement.clientWidth <= 425 ? ['Date Taken', 'Settings', 'Type', 'Subjects', 'Questions'] : [] },
      disableSortRemove: true,
      manualPagination: true,
      pageCount,
      autoResetPage: false
    },
    useSortBy,
    usePagination
  )
  useEffect(() => setCurrentPage({pageIndex, pageSize}), [pageIndex, pageSize]);

  const {push} = useHistory();

  const hasResults = data.length > 0;

  const pagination = (
    <PaginationWrapper>
      <span>
        Page {pageIndex + 1} of {pageOptions.length}
      </span>
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <ChevronLeft size={24} color="white" />
      </button>
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        <ChevronRight size={24} color="white" />
      </button>
      {/* <span>
        Go to page:
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
        />
      </span> */}
      <StyledSelect
        value={pageSize}
        config={[
          { name: 'Show 10', value: 10 },
          { name: 'Show 20', value: 20 },
          { name: 'Show 30', value: 30 },
          { name: 'Show 40', value: 40 },
          { name: 'Show 50', value: 50 },
        ]}
        onChange={value => {
          setPageSize(value);
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </StyledSelect>
    </PaginationWrapper>
  );

  const noResults = (
    <ResultsMessage>
      <div style={{marginBottom: '16px'}}>Tests you end or pause will appear here for you to review. Try creating a test.</div>
      <StyledCreateTestButton/>
    </ResultsMessage>
  );
  return (
    <TableWrapper>
      <TableStyles hasResults={hasResults}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const {config: {testStorageKey}, submitted} = row.original.testData;

              return (
                <tr onClick={() => push({pathname: submitted ? `/${bootcamp}/previous-tests/review` : `/${bootcamp}/custom-test` , search: `?key=${encodeURIComponent(testStorageKey)}`})} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {!loaded ? DataLoadingSpinner : !hasResults ? noResults : null}
      </TableStyles>
      {hasResults && pagination}
    </TableWrapper>
  );
}

export {TableStyles};
export default CustomTests;