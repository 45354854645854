import React, {useEffect, useState} from 'react';
import {getInObj} from '@bootcamp/shared/src/util';
import {getTestProgressByUserIdHashTestId} from '@bootcamp/shared/src/requests';

import Chapter, {Wrapper as CardWrapper, Right, Left} from './Chapter';
import {useUserDataContext} from '../../../contexts/UserData';
import {Label2, Label3} from '../../../components/Typography/next';
import {getResults, getOATResults} from '@bootcamp/shared/src/util/scoring';
import {restoreQuizProgress} from '@bootcamp/web/src/bootcamps/dat/Performance';

import {CalendarLtr} from '@styled-icons/fluentui-system-regular/CalendarLtr';
import {TargetArrow} from '@styled-icons/fluentui-system-regular/TargetArrow';
import {CheckmarkCircle} from '@styled-icons/fluentui-system-regular/CheckmarkCircle';

import {UpgradeButton, RoundButton} from '@bootcamp/web/src/components/UI';
import {Button, LinkButton} from '@bootcamp/web/src/components/Branding/Buttons';

import moment from 'moment';
import styled, {css} from 'styled-components';
import {API, graphqlOperation} from '@aws-amplify/api';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {SubmitAnatomyCase} from '@bootcamp/web/src/components/Modal';
import theme from '@bootcamp/shared/src/styles/theme';
import {Info} from '@styled-icons/fluentui-system-filled/Info';

const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.shadow.low};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  overflow: hidden;
  transition: all 100ms ease;

  ${({locked, theme}) => locked ? css`
    opacity: 0.7;
  ` : css`
    &:hover {
      transform: scale(1.01);
    }
  `}
`;
const ChapterCard = styled(Chapter)`
  margin-bottom: 0;
  display: flex;
  box-shadow: none;
  transition: none;
  border-radius: 0;
  border: none;
  background: white;

  &:hover {
    transform: none;
  }

  ${Left} {
    ${({theme}) => theme.mediaQueries.tablet} {
      align-self: flex-start;
    }
  }

  ${CardWrapper} {
    align-items: center;
    ${({theme}) => theme.mediaQueries.tablet} {
      flex-direction: column;
    }
  }
  ${Right} {
    ${({theme}) => theme.mediaQueries.tablet} {
      padding: 0px 16px;
      margin-top: 8px;
      width: 100%;
    }
  }
`;
const colorMap = {
  'Low': theme.colors.interfacePalette.red.default,
  'Borderline': theme.colors.interfacePalette.yellow.default,
  'High': theme.colors.interfacePalette.green.default,
  'Very High': theme.colors.interfacePalette.green.dark,
}
const IconWrapper = styled.div`
  display: flex;
  color: ${({theme, color}) => color ? colorMap[color] || theme.colors.typographyPalette.primary : theme.colors.neutralsPalette.extraDark};
  border-radius: 100px;
  width: 16px;
  height: 16px;
  order: 1;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: 8px;
    color: ${theme.colors.neutralsPalette.extraDark};
  }
`;
const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  margin-right: 16px;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: row;
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
    flex-direction: column;
    background: ${({theme}) => theme.colors.backgroundPalette.secondary};
    padding: 8px 16px;
    border-radius: 8px;
  }
  /* width: 234px; */
`;
const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: row;
    width: 100%;
  }
`;
const Divider = styled.div`
  background: ${({theme}) => theme.colors.borderPalette.primary};
  width: 1px;
  margin: 0 16px;

  ${({mobileHidden}) => mobileHidden && css`
    ${({theme}) => theme.mediaQueries.tablet} {
      display: none;
    }
  `}
  ${({theme}) => theme.mediaQueries.tablet} {
    width: auto;
    height: 1px;
    margin: 8px 0px;
  }
`;
const ResultItemText = styled(Label3)`
  color: ${({theme, color}) => color ? colorMap[color] || theme.colors.typographyPalette.primary : theme.colors.typographyPalette.primary};
  font-weight: 700;
  margin-top: 8px;
  order: 3;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: 0;
  }


  `;
const ResultItemSubText = styled(ResultItemText)`
  color: ${({theme}) => theme.colors.typographyPalette.primary};
  font-weight: 400;
  margin-top: 4px;

  ${({theme}) => theme.mediaQueries.tablet} {
    order: 2;
    flex: 1;
    margin-top: 0;
  }


`;
const Placeholder = styled(Label2)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 251px;
  background: ${({theme}) => theme.colors.backgroundPalette.secondary};
  color: ${({theme}) => theme.colors.typographyPalette.primary};
  height: 64px;
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 40px;
    margin: 0 auto;
  }
`;
const StyledUpgradeButton = styled(UpgradeButton)`
  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: 14px;
    flex: 1;
    height: 32px;
    padding: 0;
    ${({hideOnMobile}) => hideOnMobile && css`display: none;`}
  }
`;
const StyledBeginPracticeButton = styled(RoundButton)`
  ${({theme}) => theme.mediaQueries.tablet} {
    display: ${({attempted}) => attempted ? 'flex' : 'none'};
    font-size: 14px;
    flex: 1;
    height: 32px;
    padding: 0;
  }
`;
const StyledButton = styled(Button)`
  ${({theme}) => theme.mediaQueries.tablet} {
    flex: 1;
    font-size: 14px;
    height: 32px;
    padding: 0;
  }
`;

const RetakeButton = styled(LinkButton)`
  ${({theme}) => theme.mediaQueries.tablet} {
    flex: 1;
    button {
      flex: 1;
      font-size: 14px;
      height: 32px;
      padding: 0;
    }
  }
`;


const Result = ({score, scoreText, attemptDate, resultDetails, loading}) => {
  if (!score) {
    return (
      <ResultContainer>
        <Divider mobileHidden />
        <Placeholder>
          {loading ? 'Loading...' : 'Not Yet Attempted.'}
        </Placeholder>
      </ResultContainer>
    )
  }

  return (
    <ResultContainer>
      <Divider mobileHidden />
      <ResultItem>
        <IconWrapper>
          <CalendarLtr/>
        </IconWrapper>
        <ResultItemText>{moment(attemptDate).format("MMM D")}</ResultItemText>
        <ResultItemSubText>Date</ResultItemSubText>
      </ResultItem>
      {resultDetails?.result && resultDetails?.text &&
        <>
          <Divider />
          <ResultItem>
            <IconWrapper>
              <TargetArrow />
            </IconWrapper>
            <ResultItemText>{resultDetails.result}</ResultItemText>
            <ResultItemSubText>{resultDetails.text}</ResultItemSubText>
          </ResultItem>
        </>
      }
      <Divider/>
      <ResultItem>
        <IconWrapper color={scoreText.label !== 'Chance' ? null : scoreText.score}>
          {resultDetails?.result ? <CheckmarkCircle /> : <TargetArrow />}
        </IconWrapper>
        <ResultItemText color={scoreText.label !== 'Chance' ? null : scoreText.score}>{scoreText.score}</ResultItemText>
        <ResultItemSubText>{scoreText.label}</ResultItemSubText>
      </ResultItem>
      <Divider mobileHidden />
    </ResultContainer>
  );
}

const AlertIcon = styled(Info)`
  color: ${({theme}) => theme.colors.interfacePalette.red.default};
`;
const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.interfacePalette.red.default}3A;
  padding: 12px;
  width: fit-content;
  height: auto;
  margin: 0 auto;
  margin-bottom: 16px;
`;

const ResetButton = ({testProgressId, themePalette, setLastAttempt}) => {
  const {modalDispatch} = useModalContext();
  const onClick = () => modalDispatch({
    type: 'open',
    component: SubmitAnatomyCase,
    componentProps: {
      icon: <AlertContainer><AlertIcon size={32} /></AlertContainer>,
      textAlign: 'center',
      headerText: 'Reset this exam?',
      bodyText: 'This will reset your answered questions and pass prediction. Your question tags will remain. Keep in mind your first attempt is your most accurate pass prediction.',
      confirmConfig: {
        onConfirm: async () => {
          // use API.graphql to set userIdHashTestId to null
          await API.graphql(graphqlOperation(`
            mutation UpdateTestProgress($input: UpdateTestProgressInput!) {
              updateTestProgress(input: $input) {
                id
              }
            }`, {input: {id: testProgressId, userIdHashTestId: null}}))
          setLastAttempt();
        },
        colorConfig: theme.colors.interfacePalette.red,
        text: 'Yes, reset this exam'
      },
      cancelConfig: {
        text: 'No, don\'t reset'
      },
    },
    enableClickClose: true,
    modalContainerStyle: theme => `
        background: ${theme.overlays.opacity.dark._300};
        padding: 10vh 10vw;
        align-items: flex-start;
        ${theme.mediaQueries.tablet} {
          background: ${theme.overlays.opacity.dark._400};
          padding: 0;
        }
      `
  })
  if (!testProgressId) return null;
  return (
    <StyledButton
      colorConfig={themePalette}
      round
      type="ghost"
      children={'Reset'}
      size={'small'}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
        return false;
      }}
    />

  )
};


const PracticeTest = ({
  showTestResults,
  progressSegments,
  progress,
  questionCount,
  label,
  actions,
  locked,
  testReviewRoute='test-review',
  formatScore,
  testRouteParam,
  name,
  testTitle,
  formatResultDetails,
  formatScoreText,
  startText,
  reviewText,
  isLocked,
  simLocked,
  ...props
}) => {
  const [lastAttempt, setLastAttempt] = useState(null);
  const {DEFAULT_USER_ID, isUpgraded, loading, bootcamp} = useUserDataContext();

  const testSlug = props.route.split('/')[1];
  const testRoute = testRouteParam || `${window.location.pathname.replace('plus-tests', 'practice-tests').replace('full-length-plus', 'full-length')}/${testSlug}`;

  useEffect(() => {
    if (!showTestResults || loading || !props.id || props.defaultLocked || simLocked || !!lastAttempt || lastAttempt === false) return;

    const fetchLastAttempt = async () => {
      try {
        const {data: {TestProgressByTestId: {items: testProgressArray}}} = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${props.id}`, true);
        const attempt = getInObj(['blockProgresses', 'items'], testProgressArray[0], false);

        const attemptId = testProgressArray[0]?.id;
        const attemptDate = testProgressArray[0]?.createdAt;

        const score = formatScore
          ? formatScore(attempt)
          : attempt?.length && attempt
            .map(block => block.questionProgresses.items)
            .flat()
            .reduce((acc, { didSelectCorrectAnswer }) => ({ correct: acc.correct + !!didSelectCorrectAnswer, total: acc.total + 1 }), { correct: 0, total: 0 });

        setLastAttempt({attemptId, attemptDate, score, attempt});

      } catch (e) {
        console.log(e);
        setLastAttempt(false);
      }
    }

    fetchLastAttempt();

  }, [props.defaultLocked, lastAttempt, props.id, loading]);

  const cardLocked = simLocked || (props.defaultLocked ? props.defaultLocked :(!isUpgraded && props.index > 1));
  const cardActions = actions || [
    cardLocked &&
    <StyledUpgradeButton children={simLocked ? 'Locked' : 'Upgrade'}/>,
    !cardLocked && lastAttempt?.attemptId && ['next-gen-readiness-exams/review', 'self-assessment/review', 'previous-tests/review', 'simulation-exam/review', 'practice-tests/review'].includes(testReviewRoute) && <ResetButton testProgressId={lastAttempt?.attemptId} themePalette={props.themePalette} setLastAttempt={setLastAttempt} />,
    !cardLocked && lastAttempt?.attemptId && ['dat', 'oat'].includes(bootcamp) && (testRoute?.match('practice-tests') || testRoute?.match('full-length')) && <RetakeButton to={testRoute} fixed colorConfig={props.themePalette} size={'small'} round children={'Retake'} disabled={progress === 'loading'} type={'ghost'}/>,
    (!cardLocked && (isUpgraded || props.index === 1)) &&
    <StyledBeginPracticeButton themePalette={props.themePalette} attempted={lastAttempt?.attemptId} small children={lastAttempt?.attemptId ? (reviewText || 'Review') : (startText || 'Start')} disabled={progress === 'loading'}/>
  ];

  const route = lastAttempt?.attemptId && lastAttempt?.score
    ? `/${bootcamp}/${testReviewRoute}?id=${props.id}&testProgressId=${encodeURIComponent(lastAttempt?.attemptId)}&testRoute=${testRoute}`
    : props.route;

  const defaultFormatResultDetails = () => {
    try {
      const testSubject = name.match(/([^#]+)( Test)/)[1] || '';
      const testNumber = name.match(/#(.*)/)[1];
      if (testSubject === 'Full Length') {
        if (!lastAttempt?.attempt) return null;
        if (bootcamp === 'dat') {
          const result = restoreQuizProgress({blockProgresses: {items: lastAttempt?.attempt}})
          const restoredProgress = result.filter(blockProgress => blockProgress.questions && blockProgress.questions.length);
          const bioResult = {testTitle: `Biology Test #${testNumber}`, result: restoredProgress.slice(0, 1)};
          const gcResult = {testTitle: `General Chemistry Test #${testNumber}`, result: restoredProgress.slice(1, 2)};
          const ocResult = {testTitle: `Organic Chemistry Test #${testNumber}`, result: restoredProgress.slice(2, 3)};
          // ignore pat
          // const patResult = {testTitle: `Perceptual Ability Test #${testNumber}`, result: restoredProgress.slice(3, 4)};
          const rcResult = {testTitle: `Reading Comprehension Test #${testNumber}`, result: restoredProgress.slice(4, 7)};
          const qrResult = {testTitle: `Quantitative Reasoning Test #${testNumber}`, result: restoredProgress.slice(7, 8)};
          const splitResults = [bioResult, gcResult, ocResult, rcResult, qrResult].map(({testTitle, result: selectedResult}) => {
            const totalQuestions = selectedResult.reduce((acc, {questions}) => acc += questions.length, 0);
            const totalCorrect = selectedResult.reduce((acc, {questions}) => acc += questions.reduce((acc, {didSelectCorrectAnswer}) => acc += !!didSelectCorrectAnswer, 0), 0);
            const testSubject = testTitle.match(/([^#]+)( Test)/)[1] || '';
            const results = getResults((totalCorrect / totalQuestions) * 100, testTitle, testSubject);
            return {stats: {score: results.score.replace('<', '')}};
          })
         // avg score from stats
         const avgScore = Math.round(splitResults.reduce((acc, {stats}) => acc += parseInt(stats.score), 0) / splitResults.length);

         return {result: `${avgScore}AA`, text: 'Estimate'};
        }

        if (bootcamp === 'oat') {
          const result = restoreQuizProgress({blockProgresses: {items: lastAttempt?.attempt}})
          const restoredProgress = result.filter(blockProgress => blockProgress.questions && blockProgress.questions.length);
          const bioResult = {testTitle: `Biology Test #${testNumber}`, result: restoredProgress.slice(0, 1)};
          const gcResult = {testTitle: `General Chemistry Test #${testNumber}`, result: restoredProgress.slice(1, 2)};
          const ocResult = {testTitle: `Organic Chemistry Test #${testNumber}`, result: restoredProgress.slice(2, 3)};
          const rcResult = {testTitle: `Reading Comprehension Test #${testNumber}`, result: restoredProgress.slice(3, 6)};
          const physResult = {testTitle: `Physics Test #${testNumber}`, result: restoredProgress.slice(6, 7)};
          const qrResult = {testTitle: `Quantitative Reasoning Test #${testNumber}`, result: restoredProgress.slice(7, 8)};
          const splitResults = [bioResult, gcResult, ocResult, rcResult, physResult, qrResult].map(({testTitle, result: selectedResult}) => {
            const totalQuestions = selectedResult.reduce((acc, {questions}) => acc += questions.length, 0);
            const totalCorrect = selectedResult.reduce((acc, {questions}) => acc += questions.reduce((acc, {didSelectCorrectAnswer}) => acc += !!didSelectCorrectAnswer, 0), 0);
            const testSubject = testTitle.match(/([^#]+)( Test)/)[1] || '';
            const results = getOATResults((totalCorrect / totalQuestions) * 100, testTitle, testSubject);
            return {stats: {score: results}}
          })
          // avg score from stats
          const avgScore = Math.round(splitResults.reduce((acc, {stats}) => acc += parseInt(stats.score), 0) / splitResults.length);

          return {result: avgScore, text: 'Estimate'};
        }
      }
      const percentage = (lastAttempt?.score?.correct / lastAttempt?.score?.total) * 100;
      let estimate;
      if (bootcamp === 'oat') {
        estimate = getOATResults(percentage, name, testSubject);
      } else {
       const {score} = getResults(percentage, name, testSubject);
       estimate = score
      }
      return {result: estimate, text: 'Estimate'};
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const defaultFormatScoreText = (score) => {
    if (!score) return;
    return {score: `${score?.correct} / ${score?.total}`, label: 'Score'}

  };

  const resultDetails = formatResultDetails ? formatResultDetails(lastAttempt) : defaultFormatResultDetails();
  const scoreText = formatScoreText ? formatScoreText(lastAttempt?.score) : defaultFormatScoreText(lastAttempt?.score);

  return (
    <Wrapper>
      <ChapterCard
        rawRoute
        progress={progress}
        actions={cardActions}
        name={name}
        {...props}
        questionCount={questionCount}
        route={route}
        defaultLocked={cardLocked}
        simLocked={simLocked}
        loading={(showTestResults && lastAttempt === null && !cardLocked)}
        disabled
      >
        {!cardLocked && showTestResults &&
          <Result
            score={lastAttempt?.score}
            scoreText={scoreText}
            attemptDate={lastAttempt?.attemptDate}
            route={lastAttempt?.attemptId ? route : null}
            testTitle={name}
            resultDetails={resultDetails}
            loading={(showTestResults && lastAttempt === null && !cardLocked)}
          />
        }
      </ChapterCard>
    </Wrapper>
  );
};

export default PracticeTest;
