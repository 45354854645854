import React from 'react';
import styled from 'styled-components';

import {H3} from '../../../components/Typography';
import {Container, TitleBar, Content as CardContent, Detail, DetailLabel, DetailContent} from '../AccountPage/components/Cards/shared';

const Content = styled(CardContent)`
  padding-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;
const StyledDetail = styled(Detail)`
  &:last-child {
    margin-bottom: 0;
  }
`;

function formatAmount(amount) {
  const parsed = parseInt(amount);

  return (parsed / 100).toFixed(2);
}

const Receipt = ({amount, currency, last4}) => {

  const loading = !amount && !currency && !last4;

  if (loading) return null;

  return (
    <Container>
      <TitleBar>
        <H3>Your Bootcamp.com Receipt</H3>
      </TitleBar>
      <Content>
        <StyledDetail>
          <DetailLabel>Purchase Date</DetailLabel>
          <DetailContent>{new Date().toLocaleString('en-US', {dateStyle: 'long'})}</DetailContent>
        </StyledDetail>
        <StyledDetail>
          <DetailLabel>Amount Paid</DetailLabel>
          <DetailContent>${formatAmount(amount)} {currency?.toUpperCase()}</DetailContent>
        </StyledDetail>
        {last4 && <StyledDetail>
          <DetailLabel>Payment Method</DetailLabel>
          <DetailContent>{`**** **** **** ${last4}`}</DetailContent>
        </StyledDetail>}
      </Content>
    </Container>
  );
};

Receipt.defaultProps = {};
Receipt.propTypes = {};

export default Receipt;
