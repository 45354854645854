import React from 'react';
import styled from 'styled-components';
import useDocumentTitle from '@rehooks/document-title';
import {useHistory} from 'react-router-dom';
import {Column} from '../../../components/Grid';
import AuthNavigation from '../../../components/Form/Auth';
import IconButton from '../../../components/Branding/Buttons/IconButton';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';

import {ReactComponent as BootcampLogo} from '@bootcamp/shared/src/assets/svg/bootcamp_white_text_logo.svg';


const ContainerBackgroundColor = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({theme}) => theme.colors.brandPalette.royal.dark};
`;
const BackButtonLink = styled.div`
  position: absolute;
  top: ${({theme}) => theme.layouts.spacing.xl};
  left: ${({theme}) => theme.layouts.spacing.xl};
  z-index: 12;

  ${({theme}) => theme.mediaQueries.mobileL} {
    position: relative;
    top: 0;
    left: 0;
    margin-right: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const BackButton = styled(IconButton).attrs({type: 'secondary', size: 'large'})`
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 8px;
  }
  `;
const BackButtonIcon = styled(ChevronLeft).attrs({size: 32})`
  color: white;

`;
const LogoWrapper = styled.div`
  display: flex;
  width: 289px;
  height: 30px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  justify-content: center;
  align-items: center;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin: 0;
    height: 40px;
  }

`;
const Wrapper = styled.div`
  ${LogoWrapper} {
    display: none;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    display: flex;
    align-items: center;
    padding: ${({theme}) => `${theme.layouts.spacing.m}`};

    ${LogoWrapper} {
      display: flex;
    }
  }
`;
const Content = styled(Column)`
  max-width: 528px;
  align-items: center;
  margin: auto;
  padding-top: 90px;
  height: auto;
  position: relative;
  z-index: 10;

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding-top: 0;

    ${LogoWrapper} {
      display: none;
    }
  }
`;

const StyledBootcampLogo = styled(BootcampLogo)`
  max-width: 250px;
  margin-bottom: 32px;
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin: 0;
    max-width: 100%;
    width: auto; 
    height: 100%;
  }
`;

const Authentication = () => {
  const {goBack} = useHistory();

  useDocumentTitle('Bootcamp | Authentication');

  return (
    <ContainerBackgroundColor>
      <Wrapper>
        <BackButtonLink>
          <BackButton
            children={<BackButtonIcon />}
            onClick={goBack}
          />
        </BackButtonLink>
        <LogoWrapper children={<StyledBootcampLogo/>} />
      </Wrapper>
      <Content>
        <LogoWrapper children={<StyledBootcampLogo/>} />
        <AuthNavigation/>
      </Content>
    </ContainerBackgroundColor>
  )
}

export default Authentication;
