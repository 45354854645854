import React, {useEffect, useState} from 'react';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import styled from 'styled-components';

// generator templates
import AngleRanking from './components/AngleRanking';
import CubeCounting from './components/CubeCounting';
import HolePunching from './components/HolePunching';
import PatternFolding from './components/PatternFolding';
import Tfe from './components/Tfe';
import Trainer from './components/Trainer';

// generator scripts
import angleRankingJs from './scripts/angleRanking';
import cubeCountingJs from './scripts/cubeCounting';
import holePunchingJs from './scripts/holePunching';
import patternFoldingJs from './scripts/patternFolding';
import newPatternFoldingJs from './scripts/newPatternFolding';
import newCubeCountingJs from './scripts/newCubeCounting';
import TrainerJs from './scripts/Trainer';

import {ExitQuizButton} from '../../../components/QuestionBank/Buttons';

const contentMap = {
  'angle-ranking': [AngleRanking, angleRankingJs, 'angleRanking.css'],
  'cube-counting': [CubeCounting, newCubeCountingJs, 'newCubeCounting.css'],
  'hole-punching': [HolePunching, holePunchingJs, 'holePunching.css'],
  'pattern-folding': [PatternFolding, newPatternFoldingJs, 'newPatternFolding.css'],
  'tfe': [Tfe, null, 'angleRanking.css'],
  'trainer': [Trainer, TrainerJs, 'Trainer.css'],
}

const StyledExitQuizButton = styled(ExitQuizButton)`
  position: absolute;
  top: 8px;
  left: 8px;
`;

const Generators = ({generatorKey}) => {
  const [loaded, setLoaded] = useState(false)
  const [Component, script, stylesheet] = contentMap[generatorKey];

  useEffect(() => {
    // dynamic stylesheet import to prevent style conflicts since some generators use same id/classnames
    import (`./styles/${stylesheet}`);

    $( document ).on( 'contextmenu', '.label, .grade label, .answers ul li', function( event ) {
      $(this).toggleClass( 'strikethrough' );
      event.preventDefault();
      return false;
    });

    $( ".nextbutton" ).click(function() {
      $(".grade label").removeClass( 'strikethrough' );
    });
    $( document ).on( 'click',  ".strikethrough", function (event) {
      $(this).removeClass( 'strikethrough' );
    })
    if (!script) return;

    // run generator logic on mount
    script($);
  }, [])

  useEffect(() => {
    if (!window.onload || loaded || !['cube-counting', 'hole-punching', 'trainer'].includes(generatorKey)) return;
    window.onload()
    setLoaded(true);
  }, [window.onload])

  return (
    <div style={{overflow: 'auto', height: '100%', background: 'white'}}>
      <StyledExitQuizButton/>
      <Component/>
    </div>
  );
};

Generators.defaultProps = {};
Generators.propTypes = {};

export default Generators;
