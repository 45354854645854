import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Callout from '../Cards/Callout';

import PracticeTestCard from '../Cards/PracticeTest';
import {PageSection} from '../ClassroomContentWrapper';
import {UpgradeButton, BeginPracticeButton} from '../Cards/components/Buttons';
import {Element as FAQElement, Heading as FAQHeading} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';

import {useUserDataContext} from '../../../contexts/UserData';

import {getInObj} from '@bootcamp/shared/src/util';


const Container = styled.div`
`;
const FAQWrapper = styled.div`
  margin-top: 32px;
`;
const StyledFAQHeading = styled(FAQHeading)`
  margin-top: ${({theme}) => theme.layouts.spacing.l};
`;

const datPracticeTestFAQs = [
  {
    title: 'How representative are DAT Bootcamp practice tests of the real DAT?',
    text: 'DAT Bootcamp has the <strong>most representative practice tests available,</strong> which is part of the reason why more students choose DAT Bootcamp than any other product. To ensure our tests reflect the current DAT, we update them weekly using data from thousands of recent students who have taken the test. Our practice tests also feature Prometric Mode, which simulates the exact same test interface you’ll see in the Prometric test center on test day.'
  },
  {
    title: 'How accurate is my estimated AA?',
    text: "More students use DAT Bootcamp than any other product. We’ve helped 90%+ of predental students for the last 5+ years and we have access to more data than anyone else. This allows us to make better, more accurate predictions. Check out the <a href='https://app.bootcamp.com/dat/performance'>performance page</a> to see an estimate of your DAT score."
  },
  {
    title: 'I have testing accommodations. Can I change the test timer?',
    text: "Yes! When you're about to take a practice test, you'll see a setting for Test Accommodations, which increases the amount of time you'll have available. If you need more time, right-click the test timer during the test to set a custom time limit."
  },
  {
    title: 'How do the Subject Practice Tests (like Biology Test #1) relate to the Full Length Tests?',
    text: "Each Full Length Test is a compilation of the Practice Tests from every subject. For example, if you've taken Biology Test #2, you'll see the same biology questions in Full Length Test #2. If you’re following the <a href='https://bootcamp.com/dat/aris-study-guide-for-dat-domination'>Study Schedule</a>, we’ll provide recommendations on how you can approach taking the Subject and Full Length Tests."
  },
]

const PracticeTests = ({subject, content, themePalette, data, calloutText}) => {
  const topicRef = useRef();

  const tests = content.find(({name}) => name === 'Tests').content;

  const {isUpgraded, bootcamp} = useUserDataContext();
  const {counts, progress} = data;

  const showDATFaqs = bootcamp === 'dat' && !!window?.location?.pathname?.match('practice-tests');

  useEffect(() => {
    if (window.location.pathname.match('progress')) {
      topicRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [window.location.pathname])

  return (
    <Container>
      <PageSection>
        {calloutText && <Callout children={calloutText}/>}
        {tests.map((item, index) => (
          <PracticeTestCard
            key={item.id}
            route={window.location.pathname.endsWith('progress') ? item.route : `practice-tests/${item.route}`}
            name={item.name}
            id={item.id}
            index={item.index || index + 1}
            showProgress={true}
            themePalette={themePalette}
            questionCount={counts && counts[item.id]}
            progress={progress ? progress.byTest[item.id] : 'loading'}
            showTestResults
            showText={'Review Previous Attempts'}
            hideText={'Hide Previous Attempts'}
            defaultLocked={!isUpgraded && index > 0}
          />
        ))}
      </PageSection>
      {showDATFaqs &&
      <FAQWrapper>
        <StyledFAQHeading>FAQs</StyledFAQHeading>
        {datPracticeTestFAQs.map(({ title, text }) => (
          <FAQElement
            key={title}
            title={title}
            text={text}
          />
        ))}
      </FAQWrapper>
      }
    </Container>
  );
};

PracticeTests.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
PracticeTests.defaultProps = {
  content: [],
  data: {},
};

export default PracticeTests;
