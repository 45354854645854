export const updateActivationCode = `mutation UpdateActivationCode($input: UpdateActivationCodeInput!) {
  updateActivationCode(input: $input) {
    id
    cohortId
    userId
  }
}
`;

export const createUserInteraction = `mutation CreateUserInteraction($input: CreateUserInteractionInput!) {
  createUserInteraction(input: $input) {
    id
    value
  }
}
`;

export const updateUserInteraction = `mutation UpdateUserInteraction($input: UpdateUserInteractionInput!) {
  updateUserInteraction(input: $input) {
    id
  }
}
`;

export const updateMembership = `mutation UpdateMembership($input: UpdateMembershipInput!) {
  updateMembership(input: $input) {
    id
  }
}
`;

export const createQuestionBase = `mutation CreateQuestionBase($input: CreateQuestionBaseInput!) {
  createQuestionBase(input: $input) {
    id
  }
}
`;

export const updateQuestionBase = `mutation UpdateQuestionBase($input: UpdateQuestionBaseInput!) {
  updateQuestionBase(input: $input) {
    id
  }
}
`;

export const createTest = `mutation CreateTest($input: CreateTestInput!) {
  createTest(input: $input) {
    id
  }
}
`;

export const updateTest = `mutation UpdateTest($input: UpdateTestInput!) {
  updateTest(input: $input) {
    id
  }
}
`;
export const updateTestBlock = `mutation UpdateTestBlock($input: UpdateTestBlockInput!) {
  updateTestBlock(input: $input) {
    id
  }
}
`;

export const deleteQuestionConnection = `mutation DeleteQuestionConnection($input: DeleteQuestionConnectionInput!) {
  deleteQuestionConnection(input: $input) {
    id
  }
}
`;

export const createTestProgress = `mutation CreateTestProgress($input: CreateTestProgressInput!) {
  createTestProgress(input: $input) {
    id
  }
}
`;
export const createTestProgressWithBlocks = `mutation CreateTestProgress($input: CreateTestProgressInput!) {
  createTestProgress(input: $input) {
    id
    blockProgresses (limit: 5000) {
      items {
        id
        status
      }
    }
  }
}
`;
export const createTestProgressWithBlocksAndQuestions = `mutation CreateTestProgress($input: CreateTestProgressInput!) {
  createTestProgress(input: $input) {
    id
  }
}
`;
export const updateTestProgress = `mutation UpdateTestProgress($input: UpdateTestProgressInput!) {
  updateTestProgress(input: $input) {
    id
  }
}
`;

export const createBlockProgress = `mutation CreateBlockProgress($input: CreateBlockProgressInput!) {
  createBlockProgress(input: $input) {
    id
  }
}
`;


export const updateBlockProgress = `mutation UpdateBlockProgress($input: UpdateBlockProgressInput!) {
  updateBlockProgress(input: $input) {
    id
  }
}
`;

export const createQuestionProgress = `mutation CreateQuestionProgress($input: CreateQuestionProgressInput!) {
  createQuestionProgress(input: $input) {
    id
  }
}
`;
export const createTestBlock = /* GraphQL */ `
  mutation CreateTestBlock($input: CreateTestBlockInput!) {
    createTestBlock(input: $input) {
      id
    }
  }
`;

export const createTestBlockConnection = `mutation CreateTestBlockConnection($input: CreateTestBlockConnectionInput!) {
  createTestBlockConnection(input: $input) {
    id
  }
}
`;
export const updateTestBlockConnection = /* GraphQL */ `
  mutation UpdateTestBlockConnection($input: UpdateTestBlockConnectionInput!) {
    updateTestBlockConnection(input: $input) {
      id
    }
  }
`;
export const deleteTestBlockConnection = /* GraphQL */ `
  mutation DeleteTestBlockConnection($input: DeleteTestBlockConnectionInput!) {
    deleteTestBlockConnection(input: $input) {
      id
    }
  }
`;
export const createWordPressUserData = `mutation CreateWordPressUserData($input: CreateWordPressUserDataInput!) {
  createWordPressUserData(input: $input) {
    wpUserId
  }
}
`;
export const createQuestionMastery = /* GraphQL */ `
  mutation CreateQuestionMastery($input: CreateQuestionMasteryInput!) {
    createQuestionMastery(input: $input) {
      userIdHashQuestionBaseId
    }
  }
`;
export const updateQuestionMastery = /* GraphQL */ `
  mutation UpdateQuestionMastery($input: UpdateQuestionMasteryInput!) {
    updateQuestionMastery(input: $input) {
      userIdHashQuestionBaseId
    }
  }
`;

export const updateQuestionProgress = /* GraphQL */ `
  mutation UpdateQuestionProgress($input: UpdateQuestionProgressInput!) {
    updateQuestionProgress(input: $input) {
      id
    }
  }
`;

export const createQuestionComment = /* GraphQL */ `
  mutation CreateQuestionComment($input: CreateQuestionCommentInput!) {
    createQuestionComment(input: $input) {
      id
    }
  }
`;
export const updateQuestionComment = /* GraphQL */ `
  mutation UpdateQuestionComment($input: UpdateQuestionCommentInput!) {
    updateQuestionComment(input: $input) {
      id
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
