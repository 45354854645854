import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import useDocumentTitle from '@rehooks/document-title';
import moment from 'moment';
import firstBy from 'thenby';
// DATA
import {getQuestionParts} from '@bootcamp/shared/src/util';

// DISPLAY
// extra
import QuestionView from '../../components/QuestionView';
import {GutterColumn, GutterRow, GutterInner} from '../../components/Spacing';
import {RaisedButton, LoadingSpinner, FixedBar} from '../../components/Branding';
import {Column, Row} from '../../components/Grid';
import {Close} from '@styled-icons/material/Close';
import Exhibit from '../../components/Exhibit';
import SubmitAnatomyCase from '../Modal/components/SubmitAnatomyCase';
import {useModalContext} from '../../contexts/Modal';

// intra
import Progress from './components/Progress';
import FrontPage from './components/FrontPage';
import LastPage from './components/LastPage';
import {useHistory} from 'react-router-dom';

// VARS
const barHeight = 75;


// STYLE
const Bar = styled(FixedBar).attrs({barHeight})`
  background: #5096E4;
`

const TopBar = styled(Bar).attrs({barHeight, size: 1, position: 'top'})`
  box-shadow: 0px 1px 0px #3c82d0;
`

const CloseButton = styled(Close).attrs({ size: 24 })`
  color: white;
  cursor: pointer;
  margin-right: 1em;
`;
const BottomBar = styled(Bar).attrs({barHeight, size: 1.5, position: 'bottom'})`
  background: white;
  box-shadow: 0px -1px 0px #d4d8d9;
`;

const ContentArea = styled(GutterColumn)`
  margin-top: ${barHeight}px;
  padding-bottom: ${barHeight * 3}px;
  min-height: calc(100vh - ${barHeight}px);
  overflow-y: scroll;
  background: ${({currentPage}) => currentPage === 0 ? 'linear-gradient(-180deg,#5096e4,#2F80ED)' : 'white'};
  ${GutterInner} > ${Column} {
    flex: 1;
    ${({centered}) => css`
      justify-content: ${centered ? 'center' : 'flex-start'};
      height: ${centered ? '100%' : 'auto'};
      padding-bottom: ${centered ? '0px' : `${barHeight * 1.5}px`};
    `}
  }
`;

const Container = styled(Column)``;

const BarWrapper = styled(GutterRow)`
  ${GutterInner} > ${Row} {
    align-items: center;
  }
`;

const ControlButton = styled(RaisedButton)``;

const ButtonGroup = styled(Row)`
  align-items: center;
  ${ControlButton} {
    min-width: 150px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 15px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;

const ExamControls = ({blueButton, greyButton, exhibitButton}) => {
  return (
    <ButtonGroup>
      {greyButton && <ControlButton color="grey" {...greyButton} />}
      {blueButton && <ControlButton color="blue" {...blueButton} />}
      {exhibitButton && <ControlButton color="grey" {...exhibitButton} />}
    </ButtonGroup>
  )
}

const OffscreenLoader = styled.div`
  position: absolute;
  transform: translate(-10000px, -10000px);
  height: 0;
  width: 0;
  overflow: hidden;
`;

const CurrentDateContainer = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0 20px 0 auto;
`;

const CurrentDate = () => <CurrentDateContainer>{moment().format("dddd, MMMM Do, YYYY")}</CurrentDateContainer>;

const FrontQuiz = ({location, loading, test}) => {
  // QUIZ STATE
  const [currentPage, setCurrentPage] = useState(0);
  const [quizProgress, setQuizProgress] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const startTime = useRef(null);
  const endTime = useRef(null);
  const nextQuestionIndex = questionIndex + 1;
  const {goBack} = useHistory();
  const {modalDispatch} = useModalContext();
  //TODO: use Router w/ questionIndex to allow using browserhistory

  // QUESTION STATE
  const [didSubmitAnswer, setDidSubmitAnswer] = useState(false);

  // INTERACTIVE PAGE ELEMENTS
  const [blueButton, setBlueButton] = useState();
  const [greyButton, setGreyButton] = useState();
  const [exhibitButton, setExhibitButton] = useState();
  const [showingExhibit, setShowingExhibit] = useState(false);

  // DATA
  useDocumentTitle(`team bootcamp | warmup`);
  const testBlockConnections = (!!test.blockConnections && test.blockConnections.items.sort(firstBy('index'))) || [];
  const questionConnections =  testBlockConnections.reduce((acc, {testBlock: {questionConnections}}) => !!questionConnections && !!questionConnections.items ? acc.concat(questionConnections.items.sort(firstBy('index'))) : acc, []) || [];
  // questionConnection has question @connection field
  const questions = questionConnections.reduce((questions, {question}) => (question && questions.concat(question)), []);

  // CURRENT DATA
  const currentQuestion = questions[questionIndex] || {};
  const questionParts = getQuestionParts(currentQuestion);
  const {subject} = questionParts.tags.find(tag => !!tag.subject) || {};
  const periodicTableSubjects = ['Organic Chemistry', 'General Chemistry', 'Biology'];
  const calculatorSubjects = ['Quantitative Reasoning'];
  const exhibitType = periodicTableSubjects.includes(subject) ? 'periodic' : calculatorSubjects.includes(subject) ? 'calculator' : null;

  // PREFETCHED DATA
  const nextQuestion = questions[nextQuestionIndex] || {};
  const nextQuestionParts = getQuestionParts(nextQuestion);

  // PREFETCH
  // during quiz, prefetch nextRevision; before/after quiz, fetch currentRevision
  const prefetchQuestionParts = [1,3].includes(currentPage) ? nextQuestionParts : questionParts;
  const offscreenQuestion = (
    <OffscreenLoader>
      <QuestionView
        key={`offscreen`}
        alwaysShowingExplanation
        {...prefetchQuestionParts}
      />
    </OffscreenLoader>
  );

  // HANDLERS
  const handleSelectAnswerIndex = (selectedAnswerIndex, didSelectCorrectAnswer) => {
    setBlueButton({
      children: 'Check',
      onClick: () => handleSubmitAnswerClick(selectedAnswerIndex, didSelectCorrectAnswer)
    })
  }

  const handleSubmitAnswerClick = (submittedAnswerIndex, didSubmitCorrectAnswer) => {
    setDidSubmitAnswer(true);

    // track quiz progress & score here
    // use array so we can track the most recent answers ("X in a row")
    const quizProgressElement = {questionIndex, submittedAnswerIndex, didSubmitCorrectAnswer};
    setQuizProgress([...quizProgress, quizProgressElement]);

    // if the final question has been answered, we need to mark the endTime.
    if (nextQuestionIndex === questions.length) {
      endTime.current = new Date();
    }

    setBlueButton({
      children: 'Next',
      onClick: handleNextQuestionClick
    });
    setGreyButton();
  }

  const handleNextQuestionClick = () => {
    setDidSubmitAnswer(false);
    setShowingExhibit(false)
    setQuestionIndex(nextQuestionIndex);
  }

  const handleExhibitToggle = () => setShowingExhibit(showingExhibit => !showingExhibit);

  const quizProgressByQuestionIndex = questionIndex => quizProgress.find(quizProgressElement => quizProgressElement.questionIndex === questionIndex) || {};

  const pages = [
    <FrontPage />,
    <QuestionView
      key={questionIndex}
      onSelectAnswerIndex={handleSelectAnswerIndex}
      didSubmitAnswer={didSubmitAnswer}
      {...questionParts}
      questionId={currentQuestion.id}
      showFeedback
    />,
    <LastPage progress={quizProgress} startTime={startTime} endTime={endTime}/>,
    <QuestionView
      key={`review-${questionIndex}`}
      onSelectAnswerIndex={handleSelectAnswerIndex}
      didSubmitAnswer
      didSubmitAnswerIndex={quizProgressByQuestionIndex(questionIndex).submittedAnswerIndex}
      {...questionParts}
      questionId={currentQuestion.id}
      showFeedback
    />,
  ];

  const ExamComponent = pages[currentPage];
  // HOOKS:
  // changing pages hook
  useEffect(() => {
    switch (currentPage) {
      case 0:
        setQuestionIndex(0);
        setQuizProgress([]);
        setDidSubmitAnswer(false);
        setBlueButton(!loading ? {
          children: <span>Loading... <LoadingSpinner /></span>
        } : {
          children: 'Start',
          onClick: () => {
            setCurrentPage(1);
            startTime.current = new Date();
          }
        });
        setGreyButton(false);
        break;
      case 1:
        setBlueButton({
          children: 'Check',
          disabled: true
        });
        setExhibitButton(exhibitType && {
          children: 'Exhibit',
          onClick: handleExhibitToggle
        });
        break;
      case 2:
        setBlueButton({
          children: 'Review',
          onClick: () => setCurrentPage(3)
        });
        setGreyButton();
        setExhibitButton();
        setQuestionIndex(0);
        break;
      case 3:
        setBlueButton({
          children: 'Next',
          onClick: handleNextQuestionClick
        });
        setExhibitButton(exhibitType && {
          children: 'Exhibit',
          onClick: handleExhibitToggle
        });
        setGreyButton(questionIndex > 0 && {
          children: 'Previous',
          onClick: () => setQuestionIndex(questionIndex - 1)
        });
        break;
      default:
        return
    }
  }, [currentPage, questionIndex, loading, exhibitType])

  // end of test hook
  useEffect(() => {
    if ([1,3].includes(currentPage) && questionIndex === questions.length) {
      setCurrentPage(2);
      setShowingExhibit(false);
    }
  }, [currentPage, questionIndex, questions]);

  function handleCloseClick () {
    if (document.getElementById('wp-root')) {
      if (window.confirm('Would you like to end the quiz and return to the Classroom page?')) {
        window.location.pathname = '/classroom';
      }
    } else {
      modalDispatch({
        type: 'open',
        component: SubmitAnatomyCase,
        enableClickClose: true,
        componentProps: {
          headerText: 'Would you like to end your warmup?',
          bodyText: '',
          cancelConfig: {
            text: 'Keep Reviewing',
            onCancel: () => {
              modalDispatch({type: 'close'});
            },
            shouldRedirect: false,

          },
          confirmConfig: {
            text: 'End Review',
            color:  '#5096E4',
            onConfirm: () => {
              setTimeout(() => goBack(), 100);
            },
            shouldRedirect: false,
          }
        },
      })
    }
  }

  return (
    <Container>
      {offscreenQuestion}
      <TopBar>
        {currentPage > 0
          ? (
            <BarWrapper>
              <CloseButton onClick={handleCloseClick} />
              <Progress
                displayStyle={currentPage < 3 ? 'bar' : 'numeric'}
                answerStats={quizProgress}
                progress={currentPage < 3 ? quizProgress.length : questionIndex}
                total={questions.length}
              />
            </BarWrapper>
          )
          : <BarWrapper><CurrentDate /></BarWrapper>
        }
      </TopBar>
      <ContentArea currentPage={currentPage} centered={[0,2].includes(currentPage)}>
        {ExamComponent}
      </ContentArea>
      <BottomBar>
        <BarWrapper>
          <ExamControls {...{blueButton, greyButton, exhibitButton}} />
          {(showingExhibit && exhibitType) && <Exhibit type={exhibitType} onCloseExhibit={() => setShowingExhibit(false)} />}
        </BarWrapper>
      </BottomBar>
    </Container>
  )
}

export default FrontQuiz;
