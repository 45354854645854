import React from 'react';
import Auth from '@aws-amplify/auth';

import {Link, withRouter} from 'react-router-dom';
import {createUser, createMembership} from '@bootcamp/shared/src/requests';

import FieldRenderer from '../../FieldRenderer';
import FormWrapper from '../../FormWrapper';

import {FormSubmit} from '../';
import {Container, Header, Body, FieldGroup, LinkButton} from '../../';

async function login(username, password) {
  const requestUrl = `https://datbootcamp.com/wp-json/mobile/v1/login`;

  try {
    const authResponse = await fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({username, password}),
    });

    const user = await authResponse.json();
    return user.token ? Promise.resolve(user) : Promise.reject(user);
  } catch (error) {
    const message = JSON.stringify({requestUrl, error, message: 'error logging in'});
    throw new Error(message);
  }
}

const SignIn = ({location, setRedirect, setCreatingAccount, setBootcamp}) => {
  async function onSubmitHandler (values, formMethods, secondAttempt=false) {
    setBootcamp('dat');

    const {username: usernameAsSubmitted, password} = values;

    try {
      // try lowercase username by default
      const username = secondAttempt ? usernameAsSubmitted : usernameAsSubmitted.toLowerCase();

      const user = await Auth.signIn(username, password);

      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // You need to get the code from the UI inputs
        // and then trigger the following function with a button click
        const code = window.prompt(`Enter authenticator code:`);

        if (!code) return setRedirect('/not-found');
        const mfaType = user.challengeName;
        // If MFA is enabled, sign-in should be confirmed with the confirmation code
        await Auth.confirmSignIn(
          user,   // Return object from Auth.signIn()
          code,   // Confirmation code
          mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );
      }

    } catch (e) {
      if (e.code === "UserNotFoundException") {
        if (secondAttempt) {
          // TRY LOGGING INTO DBC
          try {
            const wpLoginAttempt = await login(usernameAsSubmitted, password);

            if (wpLoginAttempt.token) {
              // DISABLE REDIRECT AFTER SIGNIN
              setCreatingAccount('datbootcamp');

              await Auth.signUp({
                username: usernameAsSubmitted,
                password,
                attributes: {
                  email: wpLoginAttempt.user_email
                }
              });

              // auto log user in (account verification is happening in preSignUp lambda trigger)
              await Auth.signIn(usernameAsSubmitted, password);

              await createUser(usernameAsSubmitted, wpLoginAttempt.id);

              if (parseInt(wpLoginAttempt.days_left) > 0) {
                const groups = wpLoginAttempt.isBootcampPlus ? ['DATBootcamp', 'PlusPackAccess'] : ['DATBootcamp'];
                await createMembership({
                  id: `${usernameAsSubmitted}-${(new Date()).toISOString()}`,
                  productId: wpLoginAttempt.isBootcampPlus ? 'price_1JpIi9D2xOfNJKfx5Q7ARaMs' : 'price_1JpIi9D2xOfNJKfxp7n8Nnnz',
                  userMembershipsId: usernameAsSubmitted, // can't do user_id because due to cognito it has to match the username, no idOverride for memberships
                  startDate: (new Date()).toISOString(),
                  duration: wpLoginAttempt.days_left,
                  status: 'active',
                  groups,
                });
              }
              setRedirect('/dat');
            }
          } catch (e) {
            console.log(e);
            if (e.code === '[jwt_auth] incorrect_password') {
              return formMethods.setError('username', e.code, `Incorrect DATBootcamp.com user/pass`);
            }
            return formMethods.setError('username', e.code, `DATBootcamp login error: `);
          }
          return formMethods.setError('username', e.code, `${e.message} User not found.`);
        }
        // if this was the first attempt, rerun to try without forcing lowercase username
        return onSubmitHandler(values, formMethods, true);
      }
      if (e.code === "NotAuthorizedException") {
        if (e.message === "Password attempts exceeded") {
          // TODO: handle password overentry error
          return formMethods.setError('password', e.code, e.message);
        } else {
          return formMethods.setError('password', e.code, e.message);
        }
      }
    }
  }

  return (
    <FormWrapper onSubmit={onSubmitHandler}>
      <Container>
        <Body>
          <Header>Log in with your DATBootcamp.com account</Header>
          <FieldGroup children={<FieldRenderer config={SIGNIN_FORM_CONFIG} />}/>
          <LinkButton color={'indigo'} children={<Link to={{
            pathname: `/join`,
            search: location.search
          }}>Create a Bootcamp.com account</Link>} />
          <LinkButton color={'red'} children={<a href="https://datbootcamp.com/forgot-password">Reset DATBootcamp.com password</a>} />
        </Body>
        <FormSubmit setRedirect={setRedirect}>
          Log In
        </FormSubmit>
      </Container>
    </FormWrapper>
  );
}


export default withRouter(SignIn);

const SIGNIN_FORM_CONFIG = [
  {
    type: 'Text',
    label: 'DATBootcamp.com Username',
    name: 'username',
    additionalInfo: '(Case Sensitive)',
    inputProps: {
      autoFocus: true,
      type: 'username',
      autoComplete: 'username'
    },
    validation: () => ({
      required: 'Email is required',
    })
  },
  {
    type: 'Password',
    label: 'Password',
    name: 'password',
    // additionalInfo: <ForgotPasswordLink to={`/forgot`}>Forgot Password</ForgotPasswordLink>,
    inputProps: {
      autoComplete: 'current-password'
    },
    validation: () => ({
      required: true,
      minLength: {
        value: 8,
        message: 'Must be at least 8 characters'
      }
    })
  }
]
