import React from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router';

import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import QuestionBank from '../../../components/QBank';

import {colors} from '@bootcamp/shared/src/styles/theme';
import {getInObj, deSlugify} from '@bootcamp/shared/src/util';

import styled from 'styled-components';

const Container = styled.div`
  z-index: 10;
  height: 100%;
  position: relative;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
`;

const TestReview = ({match, contentId}) => {
  const {push, goBack} = useHistory();
  const {config} = useBootcampConfigContext();

  const {cognitoUser} = useUserDataContext();

  const username = getInObj(['attributes', 'name'], cognitoUser, '');
  const userId = getInObj(['username'], cognitoUser, '');

  const startBlockTitle = deSlugify(match.params.chapter);
  const testTitle = ``;
  const urlParams = new URLSearchParams(window.location.search);
  const testRoute = urlParams.get('testRoute');
  const onExit = () => testRoute ? push(`/${testRoute.split('/').slice(1,3).join('/')}`) : goBack();

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | Test Review</title>
        <meta name="description" content={`${config.meta.siteTitle} ${startBlockTitle} ${testTitle}`}></meta>
      </Helmet>
      <TestNavigatorProvider
        match={match}
        template={'testReview'}
        testId={contentId}
        onExitTest={onExit}>
          <QuestionBank
            title={testTitle}
            startBlockTitle={startBlockTitle}
            testId={contentId}
            withNavSidebar={false}
            themePalette={colors.brandPalette.royal}
            username={username || userId}
            testRoute={testRoute}
            handleEndBlockUpdate
            enableBookmarking
            />
      </TestNavigatorProvider>
    </Container>
  );
}

TestReview.propTypes = {};
TestReview.defaultProps = {};

export default TestReview;
