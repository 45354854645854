import React, {useState, useMemo, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled, {css} from 'styled-components';
import {useHotkeys} from 'react-hotkeys-hook';

import {useTestNavigatorContext} from '../../../contexts/TestNavigator';
import {useUserDataContext} from '../../../contexts/UserData';

import {Column} from '@bootcamp/web/src/components/Grid';
import VideoPlayer from '@bootcamp/web/src/components/VideoPlayer';
import AdminButtons from '../../QuestionView/components/AdminButtons';

import {Question} from './components';
import {Solution} from './components/shared';
import {getScores, getScoringRule, answerDataFormatter, calculateMaxScore} from '@bootcamp/shared/src/util/scoring/NCLEX';
import DataTabs from './components/DataTabs';
import SolutionMeta from '../../QBank/Question/SolutionMeta';

import {checkAnswerState} from '@bootcamp/shared/src/util/scoring/NCLEX';



const Container = styled(Column)`
  /* transform: translate3d(0,0,0); figure out if we still need this for safari b/c it messes with the drag and drop question types*/
  align-items: flex-start;
  height: 100%:

  img {
    display: block;
    margin: 12px 0px;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    overflow: auto;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({showClientDataTabs}) => showClientDataTabs ? 'row' : 'column'};
  width: 100%;
  height: 100%;
  padding: ${({showClientDataTabs, theme}) => showClientDataTabs ? `0 0 0 ${theme.layouts.spacing.l}` : '0'};

  * {
    font-size: ${({fontScale}) => `${16 + fontScale}px`} !important;
    line-height: ${({fontScale}) => `${24 + fontScale}px`} !important;
  }

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: column;
  }
`;
const VerticalLine = styled.div`
  min-width: 1px;
  min-height: ${({theme, visible}) => visible ? '100vh': '1px'};
  background: ${({theme, visible}) => visible ? theme.colors.neutralsPalette.nclex.light : 'white'};

  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
`;
const QuestionAreaWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({showClientDataTabs}) => showClientDataTabs ? 'column' : 'row'};
  flex: 1;
  padding: ${({theme, showClientDataTabs}) => showClientDataTabs ? `${theme.layouts.spacing.l} ${theme.layouts.spacing.l} 0 ${theme.layouts.spacing.l}` : 0};
  overflow: ${({showClientDataTabs, showingExplanation}) => showClientDataTabs ? 'auto' : !showClientDataTabs && !showingExplanation ? 'auto' : 'hidden'};

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: column;
    overflow: initial;
    padding-left: 0;
  }
  `;
const QuestionWrapper = styled.div`
  width: ${({showClientDataTabs, type, showingExplanation}) => showClientDataTabs ? '100%' : type?.match('hotSpot') && !showingExplanation ? '75%' : '50%'};
  padding: ${({theme, showClientDataTabs}) => showClientDataTabs ? 0 : `${theme.layouts.spacing.l}`};
  overflow: ${({showClientDataTabs}) => showClientDataTabs ? 'initial' : 'auto'};

  ${({theme}) => theme.mediaQueries.laptop} {
    width: 100%;
    margin: 0;
    overflow: initial;
  }
`;
const SolutionWrapper = styled.div`
  padding: ${({theme, showClientDataTabs}) => !showClientDataTabs ? `${theme.layouts.spacing.l} ${theme.layouts.spacing.l} 0px ${theme.layouts.spacing.l}` : 0};
  width: ${({showClientDataTabs}) => showClientDataTabs ? '100%' : '50%'};
  overflow: ${({showClientDataTabs}) => showClientDataTabs ? 'initial' : 'auto'};

  ${({theme}) => theme.mediaQueries.laptop} {
    width: 100%;
    border-left: none;
    overflow: initial;
  }
`;
const StyledSolutionMeta = styled(SolutionMeta)`

  > div {
    padding: 12px;
    border-radius: 4px;
    background: rgb(250, 250, 250);
    ${({didNotAnswer, fullCredit, partialCredit, theme}) => css`
      border-left: 5px solid ${didNotAnswer ? theme.colors.neutralsPalette.nclex.dark : fullCredit ? theme.colors.interfacePalette.green.default : partialCredit ? theme.colors.interfacePalette.yellow.default : theme.colors.interfacePalette.red.default};
    `}
    ${({theme}) => theme.mediaQueries.mobileL} {
      padding: 0px 12px;
    }
  }
  * {
    font-size: 14px !important;
  }
`;


const QuestionView = ({
  prompt,
  questionHeader,
  answers,
  explanation,
  citations,
  updatedAt,
  lastUpdated,
  tags,
  didSubmitAnswer,
  alwaysShowingExplanation,
  onSelectAnswerIndex,
  didSubmitAnswerIndex,
  showFeedback,
  questionId,
  latestRevisionId,
  enableHighlighting,
  disableVideos,
  setHighlights,
  highlights,
  questionIndex,
  didMark,
  time,
  savedCrossedAnswerIndexes,
  onCrossedAnswerIndexesUpdate,
  viewKey,
  answerGroups,
  answerMatrix,
  type,
  hyperlinks,
  hotSpotImage,
  hotSpotImageSelection,
  hotSpotImageInput,
  dndHeaderLeft,
  dndHeaderRight,
  isCaseQuestion=true,
  clientDataTabs,
  clientDataTabsTypes,
  caseHeader,
  checkOnClick,
  fontScale,
  isDraft
}) => {

  // selectedAnswerState can either be
  // SINGLE ARRAY
  //// [0] (selected answer index 0)
  //// [0,3]
  // MULTIPLE ARRAY
  //// [[0,3], [2]]
  // MATRIX REPRESENTATION
  //// [[true, false], [true, true]]
  const [crossedAnswerIndexes, setCrossedAnswerIndexes] = useState(savedCrossedAnswerIndexes);
  const {
    methods: {
      getAnswerDataForQuestion,
    },
    variables: {
      answerData,
      quizProgress,
      blockIndex
    }
  } = useTestNavigatorContext();

  const {questions: blockQuestionProgress} = {questions: [], ...quizProgress[blockIndex]};
  const answerState = blockQuestionProgress?.[questionIndex]?.answerState?.answerState || []; // TODO add question type specific defaults if need be

  const score = blockQuestionProgress?.[questionIndex]?.answerState?.score || 0;
  const maxScore = blockQuestionProgress?.[questionIndex]?.answerState?.maxScore ||  calculateMaxScore(prompt, type, answers, answerGroups, answerMatrix);

  const solutionRef = useRef();
  const showingExplanation = didSubmitAnswer || alwaysShowingExplanation;
  const didNotAnswer = !checkAnswerState(answerState);

  const filteredAnswers = answers?.filter(value => Array.isArray(value) && value.length >= 2);
  const clientDataTabMap = useMemo(() => (
    clientDataTabsTypes
    ?.filter(type => !!type)
    ?.reduce((acc, curr, index) => ({...acc, [curr]: clientDataTabs[index]}), {})
    ), [clientDataTabs, clientDataTabsTypes]);
    const showClientDataTabs = !!clientDataTabsTypes?.length;

  const questionAnswerData = answerData?.[questionId];

  const {searchUserInteractions} = useUserDataContext();

  const hideAnswerData = searchUserInteractions('hideAnswerData') === true;

  useHotkeys('c, s', checkOnClick, [checkOnClick]);
  function updateCrossedAnswerIndexes(index) {
    if (showingExplanation) return;

    const indexes = crossedAnswerIndexes.indexOf(index) > -1
      ? crossedAnswerIndexes.filter(i => i !== index)
      : [...crossedAnswerIndexes, index];
    onCrossedAnswerIndexesUpdate(indexes);
    setCrossedAnswerIndexes(indexes);
  }

  function setAnswerState(props) {
    const updatedState = typeof props === 'function' ? props(answerState || []) : props;
    const {score, maxScore} = getScores(prompt, type, updatedState, answers, answerGroups, hotSpotImageSelection, answerMatrix);
    onSelectAnswerIndex({answerState: updatedState, score, maxScore, type});
  }

  useEffect(() => {
    // load answer data for question if need be
    getAnswerDataForQuestion(questionId, latestRevisionId, progresses => answerDataFormatter(type, progresses));

  }, [questionId, didSubmitAnswerIndex]);

  useEffect(() => {
    if (!disableVideos && solutionRef.current && showingExplanation) {
      const vimeoNodes = solutionRef.current.querySelectorAll(':not(del) > .video-thumb');

      const vimeoIds = new Set([...vimeoNodes].map(node => node.dataset.vimeoId));

      [...vimeoIds].forEach(async vimeoId => {
        try {
          vimeoNodes.forEach((node, index) => {
            if (node.dataset.vimeoId === vimeoId) {
              const videoContainer = document.createElement('div');
              videoContainer.className = "videoContainer";
              videoContainer.style.marginBottom = '1em';
              if (node.classList.contains('video-lock')) {
                node.insertAdjacentElement('afterEnd', videoContainer);
              } else {
                if (node.parentNode && node.parentNode.children.length === 1 && node.parentNode.children[0].classList.contains('video-thumb') && node.parentNode !== solutionRef.current) {
                  node.parentNode.remove();
                } else {
                  node.remove();
                }

                if (solutionRef.current) solutionRef.current.insertAdjacentElement('afterBegin', videoContainer);
              }
              const videoPlayer = <VideoPlayer playerId={`${vimeoId}-${index}-${viewKey}`} vimeoId={vimeoId} />;
              ReactDOM.render(videoPlayer, videoContainer);
            }
          })
        } catch (e) {
          console.log(e);
        }
      })
      const gifs = solutionRef.current.querySelectorAll('.rubgif');

      [...gifs].forEach(gif => {
        gif.style.display = 'none';
        const gifPlayer = document.createElement('gif-player');
        const gifSrc = gif.getAttribute('src');
        gifPlayer.setAttribute('src', gifSrc + '?version=gifplayer');
        gifPlayer.setAttribute('speed', '1');
        gifPlayer.setAttribute('play', true);
        gif.insertAdjacentElement('afterend', gifPlayer);
      });
    }
    return () => {
      if (solutionRef.current) {
        solutionRef.current.querySelectorAll('.videoContainer').forEach(node => node.remove());
      }
    }
  }, [explanation, showingExplanation]);

  const solutionMeta = (
    <StyledSolutionMeta
      didSelectCorrectAnswer={score>0}
      didNotAnswer={didNotAnswer}
      didMark={didMark}
      loadingAnswerData={!answerData}
      hideTimeSpent={false}
      time={time}
      avgScore={maxScore > 1 && questionAnswerData?.hasOwnProperty('avgScore') ? `${parseFloat(questionAnswerData?.avgScore)?.toFixed(1)} / ${maxScore}` : null}
      correctPercentageText={(maxScore === 1) && (questionAnswerData?.hasOwnProperty('percentage') || questionAnswerData?.hasOwnProperty('avgScore')) ? `${((questionAnswerData?.percentage || questionAnswerData?.avgScore) * 100)?.toFixed(1)}%` : null}
      score={`${score} / ${maxScore}`}
      fullCredit={parseInt(score) === parseInt(maxScore)}
      partialCredit={(parseInt(score) !== parseInt(maxScore)) && score !== 0}
      scoringRule={getScoringRule(type)}
      hideCorrectAnswer
      hideResult
      hideAnswerData={hideAnswerData}
      // lastUpdated={lastUpdated}
    />
  );

  return (
    <Container>
      <AdminButtons questionId={questionId} />
      <Wrapper showClientDataTabs={showClientDataTabs} showingExplanation={showingExplanation} fontScale={fontScale}>
        {showClientDataTabs &&
          <>
            <DataTabs
              setHighlights={setHighlights}
              highlights={highlights}
              enableHighlighting={enableHighlighting}
              tabs={clientDataTabMap}
              header={caseHeader}
              />
            <VerticalLine visible/>
          </>
        }
        <QuestionAreaWrapper showClientDataTabs={showClientDataTabs} showingExplanation={showingExplanation}>
          <QuestionWrapper showClientDataTabs={showClientDataTabs} type={type} showingExplanation={showingExplanation}>
            <Question
              prompt={prompt}
              questionHeader={questionHeader}
              setHighlights={setHighlights}
              highlights={highlights}
              enableHighlighting={enableHighlighting}
              questionIndex={questionIndex}
              didSubmitAnswer={didSubmitAnswer}
              alwaysShowingExplanation={alwaysShowingExplanation}
              answers={filteredAnswers}
              answerGroups={answerGroups}
              answerMatrix={answerMatrix}
              answerState={answerState}
              setAnswerState={setAnswerState}
              showingExplanation={showingExplanation}
              crossedAnswerIndexes={crossedAnswerIndexes}
              updateCrossedAnswerIndexes={updateCrossedAnswerIndexes}
              hotSpotImage={hotSpotImage}
              hotSpotImageSelection={hotSpotImageSelection}
              hotSpotImageInput={hotSpotImageInput}
              dndHeaderLeft={dndHeaderLeft}
              dndHeaderRight={dndHeaderRight}
              type={type}
              checkOnClick={checkOnClick}
            />
            {!showClientDataTabs && showingExplanation && solutionMeta}
          </QuestionWrapper>
          {<VerticalLine visible={!showClientDataTabs && showingExplanation}/>}
          <SolutionWrapper showClientDataTabs={showClientDataTabs}>
            {showingExplanation &&
              <>
                {showClientDataTabs && solutionMeta}
                <Solution
                  solution={explanation}
                  setHighlights={setHighlights}
                  highlights={highlights}
                  enableHighlighting={enableHighlighting}
                  citations={citations}
                  didNotAnswer={didNotAnswer}
                  score={score}
                  answerState={answerState}
                  showFeedback={showFeedback}
                  questionId={questionId}
                  tags={tags}
                  didMark={didMark}
                  time={time}
                  solutionRef={solutionRef}
                  updatedAt={updatedAt}
                  lastUpdated={lastUpdated}
                  hyperlinks={hyperlinks}
                />

              </>
            }
          </SolutionWrapper>
        </QuestionAreaWrapper>
      </Wrapper>
    </Container>
  )
}

QuestionView.defaultProps = {
  prompt: '',
  answers: [],
  explanation: '',
  updatedAt: '',
  onSelectAnswerIndex: () => true,
  alwaysShowingExplanation: false,
  didSubmitAnswerIndex: [],
  showFeedback: false,
  questionId: '',
  tags: [],
  enableHighlighting: false,
  setHighlights: () => { },
  highlights: null, // this should be a serialized highlight string,
  didMark: false,
  time: "",
  savedCrossedAnswerIndexes: [],
  onCrossedAnswerIndexesUpdate: () => true
}

export default QuestionView;
