import {useEffect, useCallback} from 'react'

const useBeforeUnload = preventReload => {

  const handleBeforeunload = useCallback((beforeunloadEvent) => {
    let returnValue
    if (typeof preventReload === 'function') {
      returnValue = preventReload(beforeunloadEvent)
    } else {
      returnValue = preventReload
    }
    if (returnValue) {
      beforeunloadEvent.preventDefault()
      beforeunloadEvent.returnValue = returnValue
    }
    return beforeunloadEvent
  }, [preventReload]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload)
    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [preventReload])
}

export default useBeforeUnload
