import React, {useState, useEffect, createContext, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {firstBy} from 'thenby';
import Storage from '@aws-amplify/storage';

import {useUserDataContext} from '../../../contexts/UserData';

import {calculateMaxScore} from '@bootcamp/shared/src/util/scoring/NCLEX';
import {
  getMasteryMap,
  getScore,
  getTags,
  formatScoringBreakdown,
  formatReadinessExamScoringBreakdown,
  deSlugify,
} from '@bootcamp/shared/src/util';

import {getTestProgressByUserIdHashTestId, getTestProgress, getTest} from '@bootcamp/shared/src/requests';

const useConfigContext = () => {
  const [testConfig, setTestConfig] = useState({});

  const {DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const history = useHistory();

  const searchParams = new URLSearchParams(history?.location?.search);

  const testStorageKey = searchParams.get('key');
  const attempt = searchParams.get('attempt');
  const testRoute = searchParams.get('testRoute');

  let testProgressId = searchParams.get('testProgressId');
  const testId = searchParams.get('id');

  const isMCATExam = bootcamp === 'mcat';
  const isReadinessExam = !!testRoute?.match('next-gen-readiness-exams') || [
    'b21f76dd-8a20-4a71-9bf6-839e7b4d7faa',
    '6ed36c4c-25ff-417e-b004-cc63471fdf8a',
    '8e61fa1d-88ae-4650-a7e5-b5540c1cd5d3',
    '8e6eb0ba-6162-41fb-bd69-3de3c58da932',
    'f7b7ac19-1130-43a0-818c-5c42269ad4dc',
    '3a147a25-d1e4-4462-86bb-ae4824325446',
    'b7246a32-9b27-4a44-921f-9fac4c5f36c4',
  ].includes(testId);

  async function getTestConfig() {
    try {
      const result = await Storage.get(testStorageKey, {
        download: true,
        cacheControl: 'no-cache',
        contentType: 'application/json',
      });

      const json = await new Response(result.Body).json();

      const progressIdResult = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${json.testId}`);
      const progressId = progressIdResult?.data?.TestProgressByTestId?.items?.[0]?.id;

      const progress = await getTestProgress(progressId);
      const scoring = await formatScoringBreakdown(progress?.data?.getTestProgress, json, DEFAULT_USER_ID, bootcamp);

      setTestConfig({...json, attempt, scoring, bootcamp});

    } catch (error) {
      console.log('error fetching test config', error);
    }
  }

  async function getReadinessExamConfig() {
    try {
      if (!testProgressId) {
        const progressIdResult = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${testId}`);
        testProgressId = progressIdResult?.data?.TestProgressByTestId?.items?.[0]?.id;
      }

      const progress = await getTestProgress(testProgressId);
      const test = await getTest(testId, "getTestStructureOnly");

      const selectedQuestions = progress?.data?.getTestProgress?.blockProgresses?.items?.sort(firstBy('index')).reduce((acc, curr) => {
        const testBlockQuestions = curr?.questionProgresses?.items?.sort(firstBy('index')).map(({question}) => question.id);
        return [...acc, ...testBlockQuestions];
      }, []);

      let progressWithSequentialBlockData = progress?.data?.getTestProgress

      progressWithSequentialBlockData.blockProgresses = {
        items: progress?.data?.getTestProgress?.blockProgresses?.items?.map(blockProgress => {

          try {
            const block = test?.data?.getTest?.blockConnections?.items?.find(({testBlock}) => testBlock.id === blockProgress.blockId);
            const isSequentialSet = JSON.parse(block?.testBlock?.components?.find(component => component.renderType === 'config')?.contents || 'null')?.isSequentialSet;

            if (isSequentialSet) {
              return {
                ...blockProgress,
                questionProgresses: {
                  items: blockProgress?.questionProgresses?.items?.map((questionProgress, index) => ({
                    ...questionProgress,
                    isSequentialSet: true,
                    isSequentialStart: questionProgress.index === 0,
                    isSequentialEnd: (blockProgress.questionProgresses.length - 1) === questionProgress.index
                  }))
                }
              }
            }

          } catch (error) {
            return blockProgress
          }

          return blockProgress;
        })
      };

      const config = {
        selectedQuestions,
        testLength: selectedQuestions.length,
        timed: true,
        tutorMode: false,
        testId,
        testRoute,
        testProgressId,
        config: {
          customTitle: deSlugify(testRoute?.split('/').slice(-1)[0]),
          createdAt: progress?.data?.getTestProgress?.createdAt,
          testLength: selectedQuestions.length,
        },
      };

      const scoring = await formatReadinessExamScoringBreakdown(progressWithSequentialBlockData, config, DEFAULT_USER_ID, bootcamp);

      // OVERRIDE AVG SCORE FOR MSB ASSESSMENT
      // TODO: REMOVE THIS ONCE THE ASSESSMENT IS READY
      if (bootcamp === 'med-school') {
        scoring.overview.avgScore = 100.8;
      }
      setTestConfig({...config, attempt, scoring, bootcamp, isReadinessExam});

    } catch (error) {
      console.log('error fetching test config', error);
    }
  }

  useEffect(() => {
    if (!DEFAULT_USER_ID) return;

      (isReadinessExam || isMCATExam) ? getReadinessExamConfig() : getTestConfig();
  }, [DEFAULT_USER_ID]);

  return testConfig;
};

const ConfigState = createContext();

const ConfigProvider = ({children}) => {
  const value = useConfigContext();

  return (
    <ConfigState.Provider value={value}>
      {children}
    </ConfigState.Provider>
  );
};

const useConfigState = () => useContext(ConfigState);

export {ConfigProvider, useConfigState};