import {getTest, getTestProgressByUserIdHashTestId, createTestProgress, createBlockProgress, updateBlockProgress, getAllQuestionMastery} from '@bootcamp/shared/src/requests';
import {getInObj} from '@bootcamp/shared/src/util';

async function fetchQBankCollectionData (config, DEFAULT_USER_ID) {
  // we could split qbank page data by subchapter, but there's no reason bc no testId overlap
  // so let's just fetch lesson data and store by test ID
  const urlParams = new URLSearchParams(window.location.search);
  const selectedSection = urlParams.get('section');
  const lessons = config.content[0].content.filter(({type, section}) => !selectedSection || selectedSection === 'all' ? type === 'Chapter Bank Dropdown' : type === 'Chapter Bank Dropdown' && selectedSection === section).reduce((acc, {lessons}) => [...acc, ...lessons], []);
  return await lessons.reduce(async (acc, lesson) => {
    const {testId: tId, id} = lesson;
    const testId = tId || id;

    const progressResult = await getAllQuestionMastery({userIdHashTestId: `${DEFAULT_USER_ID}#${testId}`}, 'testSmall');
    const mastery = getInObj(['data', 'QuestionMasteryByTestId', 'items'], progressResult);

    const testResult = await getTest(testId, 'getTestConfig');
    const test = getInObj(['data', 'getTest'], testResult, {});

    const userIdHashTestId = `${DEFAULT_USER_ID}#qbankCollection#${testId}`;

    const baseProgress = [{
      blockProgressId: userIdHashTestId,
      testBlockId: `${testId}-qbank-collection`,
      status: '',
      newBlockProgress: true,
    }];

    // see if progress for this video lesson exists
    const data = await getTestProgressByUserIdHashTestId(userIdHashTestId, true);
    const progressId = getInObj(['data', 'TestProgressByTestId', 'items', 0, 'id'], data, null);
    if (progressId) {
      const testProgress = getInObj(['data', 'TestProgressByTestId', 'items', 0], data, null);
      const restoredProgress = baseProgress.map(blockProgress => {
        const savedProgress = testProgress.blockProgresses.items && testProgress.blockProgresses.items.find(savedBlockProgress => savedBlockProgress.blockId === blockProgress.testBlockId);
        if (savedProgress) {
          const {id, blockId, index, status} = savedProgress;
          return {
            ...blockProgress,
            blockProgressId: id,
            testBlockId: blockId,
            index,
            status,
            newBlockProgress: false
          }
        }
        return blockProgress;
      });

      return {
        ...(await acc),
        [testId]: {
          test,
          mastery,
          progressId,
          quizProgress: restoredProgress
        }
      };
    };
    // create testProgress if it hasn't been created yet
    const testProgress = await createTestProgress(userIdHashTestId, `${DEFAULT_USER_ID}#qbankCollection`, testId);
    const newProgressId = getInObj(['data', 'createTestProgress', 'id'], testProgress, null);

    return {
      ...(await acc),
      [testId]: {
        test,
        mastery,
        progressId: newProgressId,
        quizProgress: baseProgress
      }
    };
  }, {});
}


async function getQbankCollectionData (config, DEFAULT_USER_ID, setData) {
  const {route} = config;
  const qbankCollectionData = await fetchQBankCollectionData(config, DEFAULT_USER_ID);

  function saveBlockProgress (testId, value) {
    function backendSaveRequest (progressId, quizProgress) {
      if (DEFAULT_USER_ID) {
        const {blockProgressId, testBlockId, newBlockProgress} = quizProgress[0];
        if (newBlockProgress) {
          createBlockProgress(
            blockProgressId,
            progressId,
            testBlockId,
            {...value},
            0
          );
        } else {
          updateBlockProgress(
            blockProgressId,
            {
              testProgressBlockProgressesId: progressId,
              blockId: testBlockId,
              index: 0,
              ...value
            }
          );
        }
      }
    }

    setData((data) => {
      // fetch current state data
      const {qbankCollectionData} = data[route];
      const {progressId, quizProgress} = qbankCollectionData[testId];

      // async save to backend
      backendSaveRequest(progressId, quizProgress);

      // create immutable state update
      const updatedQuizProgress = [{
        ...quizProgress[0],
        ...value,
        newBlockProgress: false
      }]

      // sync state update
      // return immutable
      return {
        ...data,
        [route]: {
          ...data[route],
          qbankCollectionData: {
            ...data[route].qbankCollectionData,
            [testId]: {
              ...data[route].qbankCollectionData[testId],
              quizProgress: updatedQuizProgress
            }
          }
        }
      }
    });
  }
  return {qbankCollectionData, saveBlockProgress};
}

export {getQbankCollectionData};
