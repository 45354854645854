import React, {useCallback} from 'react';
import styled, {css} from 'styled-components';
import moment from 'moment';

import {LinkButton, Button} from '../../../Branding/Buttons';

import {useUserDataContext} from '../../../../contexts/UserData';
import {useAsync} from '../../../../hooks';

import {User} from '@styled-icons/fa-solid/User';
import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';

import {Loader} from '../';
import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import UpgradeModal from '@bootcamp/web/src/components/Modal/components/Upgrade';
import Avvvatars from 'avvvatars-react'

const UserIconContainer = styled(LinkButton)`
  display: inline-block;
  width: auto;
  height: auto;

  margin-left: ${({theme}) => theme.layouts.spacing.s};

  button {
    padding: 0;
    border-radius: 100%;
    height: 42px;
    width: 42px;
    position: relative;
    ${({theme}) => theme.mediaQueries.mobileL} {
      height: 40px;
      width: 40px;
    }
  }
`;
const ExpirationNotificationContainer = styled(LinkButton)`
  button {
    border-radius: 24px;
  }

  span {
    white-space: nowrap;
  }
`;
const UserIcon = styled(User)`
  width: 20px;
  height: 20px;
`;
const UpgradeBadge = styled(Badge)`
  height: 12px;
  width: 12px;
  path {
    fill: ${({theme}) => theme.colors.neutralsPalette.white};
  }
`;

export const Close = styled(X)`
  width: 20px;
  height: 20px;
  color: white;

  ${({theme}) => theme.mediaQueries.tablet} {
    width: 16px;
    height: 16px;
  }
`;

const LogInButton = styled(LinkButton)`
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  width: auto;

  button {
    color: ${({theme}) => theme.overlays.opacity.light._400};
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.height.small2};
    padding: 0;

    &:hover {
      color: ${({theme}) => theme.overlays.opacity.light._200};
    }
  }
`;
const SignUpButton = styled(LinkButton)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  width: auto;
`;
const UpgradeButton = styled(LinkButton)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  border-radius: 24px;
  width: auto;
  white-space: nowrap;
  ${({white}) => white && css`
    button {
      background: white;
      color: ${({theme}) => theme.colors.brandPalette.indigo.default};
      &:hover {
        background: white;
      }
    }
    ${UpgradeBadge} {
      path {
        fill: ${({theme}) => theme.colors.brandPalette.indigo.default};
      }
    }
  `}

`;

export const LogIn = ({className}) => (
  <LogInButton
    className={className}
    buttonType={'text'}
    size={'small'}
    to={'/auth#/signin'}>
    Log In
  </LogInButton>
);
export const SignUp = ({className, children='Sign Up', to='/auth'}) => (
  <SignUpButton
    className={className}
    type={'secondary'}
    size={'small'}
    to={to}>
    {children}
  </SignUpButton>
);
export const LogInMobile = ({className}) => (
  <LinkButton
    className={className}
    fixed
    type={'ghost'}
    to={'/auth#/signin'}>
    Log In
  </LinkButton>
);
export const SignUpMobile = ({className}) => (
  <LinkButton
    className={className}
    fixed
    to={'/auth'}>Sign Up
  </LinkButton>
);

function getTrialMessage (trialMembership, userModel) {
  if (!trialMembership) return;
  const trialExpiration = moment(userModel?.createdAt).add(3, 'days');
  return `Free Trial ends ${moment().to(trialExpiration)}`
}

export const Upgrade = ({className, type='secondary', size='small', color='indigo', fixed=false, children='Upgrade'}) => {
  const {isUpgraded, trialMembership, bootcamp, userModel} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  if ((isUpgraded && !trialMembership) || window.location.pathname.includes('/thank-you')) return null;

  const sharedProps = {
    className,
    type,
    size,
    color,
    fixed,
    to: '/upgrade',
    children: getTrialMessage(trialMembership, userModel) || children
  }

  if (trialMembership && bootcamp === 'anatomy') {
    sharedProps.onClick = () => modalDispatch({
        type: 'open',
        component: UpgradeModal,
        enableClickClose: true,
        modalContainerStyle: theme => `
          background: ${theme.overlays.opacity.dark._300};
          padding: 10vh 10vw;
          align-items: flex-start;
          ${theme.mediaQueries.tablet} {
            background: ${theme.overlays.opacity.dark._400};
            padding: 0;
          }
        `
      })
  }

  if (userModel?.memberships?.items?.some(membership => {
    const endDate = moment(membership.startDate).add(membership.duration, 'days');
    return moment().isAfter(endDate) && moment().isBefore(moment(endDate).add(7, 'days'))
  })) {
    sharedProps.children = "Extend Membership"
    sharedProps.onClick = () => window.location = `https://app.bootcamp.com/${bootcamp}/account/memberships`;
    sharedProps.href = `https://app.bootcamp.com/${bootcamp}/account/memberships`;
  }


  return (
    <UpgradeButton
      as={Button}
      iconConfig={{position: 'left', color, icon: UpgradeBadge}}
      onClick={() => window.open(`https://bootcamp.com/${bootcamp}/upgrade`, '_blank')}
      href={`https://bootcamp.com/${bootcamp}/upgrade`}
      {...sharedProps}
    />

  );
}

const DefaultProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  padding: 3px;
  position: relative;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0px;
  }
`;

const ProfilePictureImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;
const StyledDefaultProfilePicture = styled.div`
  border-radius: 100%;
  height: 38px;
  width: 38px;
  ${({theme, loading}) => theme.animation.loadingBackground({loading})};
`;

export const DefaultProfilePicture = ({children, className, size=36}) => {
  const {userModel, cognitoUser} = useUserDataContext();
  return (
    <DefaultProfilePictureContainer className={className}>
      {children || <Avvvatars size={size} value={(cognitoUser?.attributes?.name || userModel?.id || 'User').slice(0,1)}/>}
    </DefaultProfilePictureContainer>
  )
};

export const ExpirationNotification = () => {
  const {bootcamp, userModel} = useUserDataContext();
  const activeMembershipType = userModel?.activeMembership?.type;

  function getDaysLeft () {
    try {

      const expirationDate = moment.unix(userModel?.membershipExpirationDate);
      const now = moment();

      return moment.duration(expirationDate.diff(now)).asDays();

    } catch (error) {
      // console.log('error getting days', error);
    }
  }

  const daysLeft = getDaysLeft();

  // if we have more than 7 days remaining in membership, don't show this notification
  if (activeMembershipType === 'subscription' || !daysLeft || daysLeft < 0 || daysLeft > 14) return null;

  const roundedDaysLeft = Math.round(daysLeft);
  const expirationText = daysLeft > 0 && daysLeft <= 1
    ? 'Membership expires in < 24hrs'
    : `Membership expires in ${roundedDaysLeft} day${roundedDaysLeft === 1 ? '' : 's'}`

  return (
    <ExpirationNotificationContainer
      type={'secondary'}
      size={'small'}
      buttonType={'default'}
      to={`/${bootcamp}/account/memberships`}
      children={expirationText}
    />
  );
}

export const Account = ({className, onClick=()=>{}, buttonType='default', type='secondary', size='small'}) => {
  const {bootcamp, profPicUrl} = useUserDataContext();



  return (
    <>
      <ExpirationNotification/>
      <UserIconContainer
        onClick={onClick}
        className={className}
        type={type}
        size={size}
        buttonType={buttonType}
        to={`/${bootcamp}/account`}
        children={profPicUrl === 'error' ? <DefaultProfilePicture /> : <StyledDefaultProfilePicture loading={!profPicUrl} children={(!profPicUrl || (profPicUrl === 'error')) ? null : <ProfilePictureImage src={profPicUrl} />} />}
      />
    </>
  );
}
