import React, {useEffect} from 'react';
import useDocumentTitle from '@rehooks/document-title';
import {useTestContext} from '../../../contexts/TestBase';
import {useUserDataContext} from '../../../contexts/UserData';

import StartBlock from './StartBlock';
import USMLEBank from '../../../components/USMLEBank';
import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {colors} from '@bootcamp/shared/src/styles/theme';


const SelfAssessment = props => {
  const {config} = useBootcampConfigContext();

  const {bootcamp, cognitoUser, userModel, DEFAULT_USER_ID} = useUserDataContext();
  const username = cognitoUser?.attributes?.name || cognitoUser?.attributes?.email || userModel?.id;

  useDocumentTitle(`${config.meta.siteTitle} | Self Assessment`);

  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('testProgressId');

  const blockRenderers = {
    startBlock: props => {
      return <StartBlock {...props}/>;
    }
  };

  return (
    <TestNavigatorProvider
      {...props}
      testId={'b21f76dd-8a20-4a71-9bf6-839e7b4d7faa'}
      template={isReview ? 'testReview' : 'tbcSavedBank'}
      type={'readinessExam'}
      skipStartBlock={isReview}
    >
      <USMLEBank themePalette={colors.brandPalette.royal} username={username} blockRenderers={blockRenderers} enableBookmarking />
    </TestNavigatorProvider>
  )
}

export default SelfAssessment;
