import React from 'react';
import {Helmet} from 'react-helmet';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useUserDataContext} from '../../../contexts/UserData';
import MCATBank from '../../../components/MCATBank';
import USMLEBank from '../../../components/USMLEBank';
import NCLEXBank from '../../../components/NCLEXQBank';
import QBank from '../../../components/QBank';

import {getInObj, deSlugify} from '@bootcamp/shared/src/util';

import styled from 'styled-components';

const Container = styled.div`
  z-index: 10;
  height: 100%;
  position: relative;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
`;

const slugOverrides = {
  'ans': 'ANS',
  'cns-and-sedation': 'CNS and Sedation',
  'occlusion-and-TMJ': 'Occlusion and TMJ',
}

const AppQuestionBank = ({match, contentId, themePalette, educators, name}) => {
  const {config} = useBootcampConfigContext();
  const {cognitoUser, bootcamp} = useUserDataContext();

  const {path, params: {classroom, contentSlug, contentType}} = match;

  const username = getInObj(['attributes', 'name'], cognitoUser, '');
  const userId = getInObj(['username'], cognitoUser, '');

  const startBlockTitle = deSlugify(match.params.chapter);
  const testTitle = slugOverrides[match.params.contentSlug] || deSlugify(match.params.contentSlug);
  const siteTitle = config.meta.siteTitle;

  const alignQuestion = 'center';

  const type = !!name?.match('Readiness') && classroom !== 'testing'
    ? 'readinessExam'
    : bootcamp === 'mcat'
    ? 'mcatExam'
    : '';

  // setup for getting slug routing without urlParams for progress
  const testFilter = deSlugify(classroom);
  const {id: tagId} = !!path.match('progress')
    ? config.classrooms
        .find(({route}) => route === classroom)
        .contentTypes
        .find(({route}) => route === 'practice-tests')
        .content
        .find(({route}) => route === 'progress')
        .content
        .find(({route}) => route === contentSlug)
    : {id: null};

  const QuestionBank = bootcamp === 'mcat'
    ? MCATBank
    : bootcamp === 'nclex'
    ? NCLEXBank
    : contentType === 'board-banks'
    ? USMLEBank
    : QBank;
  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('testProgressId');
  return (
    <Container>
      <Helmet>
        <title>{siteTitle} | Question Bank | {testTitle}</title>
        <meta name="description" content={`${siteTitle} Question Bank ${testTitle}`}></meta>
      </Helmet>
      <TestNavigatorProvider
        match={match}
        template={isReview ? 'testReview' : 'tbcSavedBank'}
        testId={contentId}
        tagId={tagId}
        testFilter={testFilter}
        type={type}
        skipStartBlock={!(bootcamp === 'nclex' && type === 'readinessExam' && !isReview)}
      >
        <QuestionBank
          title={testTitle}
          startBlockTitle={startBlockTitle}
          match={match}
          testId={contentId}
          alignQuestion={alignQuestion}
          withNavSidebar={false}
          themePalette={themePalette}
          username={username || userId}
          educators={educators}
          name={name}
          enableBookmarking
        />
      </TestNavigatorProvider>
    </Container>
  );
}

AppQuestionBank.propTypes = {};
AppQuestionBank.defaultProps = {};

export default AppQuestionBank;
