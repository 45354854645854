import React, {useState, useEffect, createContext, useContext} from 'react';
import {utc} from 'moment';

export const TimerContext = createContext();

export const useMinuteTimer = (minutes, autoStart, disabled) => {
  const [limit, setLimit] = useState(minutes);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [isActive, setIsActive] = useState(autoStart);
  const [timeUp, setTimeUp] = useState(false);

  const minutesAsSeconds = limit * 60;
  const difference = minutesAsSeconds - timeElapsed;
  const milliseconds = difference * 1000;
  const format = limit >= 60 ? 'HH:mm:ss' : 'm:ss';
  const formattedTimeLeft = utc(milliseconds).format(format);
  const longTimeLeft = utc(milliseconds).format('HH:mm:ss');

  useEffect(() => {
    if (limit === null && minutes) {
      setLimit(minutes);
    }
  }, [minutes]);

  function reset() {
    setTimeElapsed(0);
    setIsActive(false);
  }

  function updateTimeLimit(minutes) {
    setStartTime(Date.now());
    setTimeElapsed(0);
    setLimit(minutes);
    setTimeUp(false);
    setIsActive(false);
  }

  useEffect(() => {
    // always need a limit for minute timer
    if (!limit || disabled) return;

    let interval = null;
    if (isActive && !startTime) {
      const millisecondsElapsed = timeElapsed * 1000;
      setStartTime(Date.now() - millisecondsElapsed);
    } else if (isActive) {
      interval = setInterval(() => {
        const now = Date.now();
        const secondsElapsed = Math.round((now - startTime) / 1000);
        if (secondsElapsed < minutesAsSeconds) {
          setTimeElapsed(secondsElapsed);
        } else {
          setTimeElapsed(secondsElapsed);
          setIsActive(false);
          setTimeUp(true);
        }
      }, 1000);
    } else if (!isActive && timeElapsed !== 0) {
      clearInterval(interval);
      setStartTime(0);
    }
    return () => clearInterval(interval);
  }, [isActive, startTime, minutesAsSeconds]);

  return {
    timeLeft: formattedTimeLeft,
    longTimeLeft,
    timerActive: isActive,
    toggleTimer: (onOrOff) => {
      if (!isActive && timeElapsed === 0) {
        setStartTime(0);
      }
      setIsActive(isActive => onOrOff || !isActive)
    },
    resetTimer: reset,
    timeUp,
    updateTimeLimit,
    disabled
  }
}

export const useStopwatch = (disabled) => {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const milliseconds = timeElapsed * 1000;
  const format = 'HH:mm:ss';
  const formattedTimeElapsed = utc(milliseconds).format(format);

  function reset() {
    setTimeElapsed(0);
    setStartTime(Date.now())
    setIsActive(false);
  }

  useEffect(() => {
    if (disabled) return;

    let interval = null;
    if (isActive && !startTime) {
      const millisecondsElapsed = timeElapsed * 1000;
      setStartTime(Date.now() - millisecondsElapsed);
    } else if (isActive) {
      interval = setInterval(() => {
        const now = Date.now();
        const secondsElapsed = Math.round((now - startTime) / 1000);
        setTimeElapsed(secondsElapsed);
      }, 500);
    } else if (!isActive && timeElapsed !== 0) {
      clearInterval(interval);
      setStartTime(0);
    }
    return () => clearInterval(interval);
  }, [isActive, startTime]);

  return {
    timeElapsed: formattedTimeElapsed,
    timerActive: isActive,
    toggleTimer: (onOrOff) => {
      if (!isActive && timeElapsed === 0) {
        setStartTime(0);
      }
      setIsActive(isActive => onOrOff || !isActive)
    },
    resetTimer: reset,
    disabled
  }
}

export const useTimerContext = () => useContext(TimerContext);
