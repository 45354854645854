import React, {useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import Storage from '@aws-amplify/storage';
import {useHotkeys} from 'react-hotkeys-hook';

import {updateQuestionProgress as updateQbQuestionProgress, trackNCLEXQuizProgressPerformance} from '@bootcamp/shared/src/requests';

import {useReloadBlockerContext} from '@bootcamp/web/src/contexts/ReloadBlocker';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {insertAtIndex, getTestBlockQuestions, getQuestionParts} from '@bootcamp/shared/src/util';
import {LoadingSpinner} from '@bootcamp/web/src/components/Branding';
import {Box, Button, HoverButton} from './shared';

import {normalizeHighlights} from '../../helpers';

import MasteryIcon from '../../bootcamps/components/MasteryIcon';
import {Tag} from '@styled-icons/feather/Tag';
import {Tooltip} from '@bootcamp/web/src/components/Tooltip';
import {ReactComponent as PauseSVG} from './assets/Pause.svg';

import {SubmitAnatomyCase as SubmitModal} from '../Modal';

import {
  EndIcon,
  NavigatorIcon,
  LeftArrowIcon,
  RightArrowIcon,
  DraggablePopup,
  FlagIcon
} from './shared'

const TagIcon = styled(Tag).attrs(props => ({size: 22}))`
  stroke-width: 3;
  margin-right: 4px;
  color: white;
  > path {
    fill: transparent !important;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-right: 0px;
  }
`;

const ButtonText = styled.span`
  ${({theme, hideLaptop}) => hideLaptop ? theme.mediaQueries.laptop : theme.mediaQueries.tablet} {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PrometricSpinner = ({close}) => {
  useEffect(() => {
    setTimeout(close, 250)
  }, []);
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', background: '#B6B6B4'}}>
      <LoadingSpinner size="36" color="blue"/>
    </div>
  )
}
const UnderlineSpan = styled.span`
  text-decoration: underline;
`
const MasteryTag = styled(MasteryIcon)`
  display: ${({level}) => !!level ? 'inline-block' : 'none'};
  max-width: 20px;
  height: 20px;
`;
const MasteryButton = ({label, onClick, color, hotkey}) => {
  useHotkeys(hotkey, onClick, [onClick]);

  return (
    <Button color={color} onClick={onClick}>
      <TagIcon /><ButtonText hideLaptop><UnderlineSpan>{label.slice(0,1)}</UnderlineSpan>{label.slice(1)}</ButtonText>
    </Button>
  )
}
const PauseIcon = styled(PauseSVG)`
  margin-right: 4px;
`;
const PauseButton = styled(HoverButton)`
  &:hover {
    color: #FFFF00;
    path {
      fill: #FFFF00;
      stroke: #FFFF00;
    }
    rect {
      stroke: #FFFF00;
    }
  }
`;
const Controls = ({themePalette, alwaysShowingExplanation, toggleSidebar, className, navigatorComponent}) => {
  const {timeLeft, toggleTimer, timeUp} = useTimerContext();
  const startTime = useRef(new Date());

  const {
    methods: {
      transitionBlock,
      setCurrentIndex,
      updateQuizProgress,
      saveQuestionProgresses,
      autoTagOnSubmit,
      saveAndTrackProgress,
    },
    variables: {
      blockIndex,
      quizProgress,
      navigationFilter,
      testBlockConnections,
      test,
      customTestConfig,
      template,
      tutorMode,
      type,
      testProgressId,
    }
  } = useTestNavigatorContext();

  const {modalDispatch} = useModalContext();
  const {push, goBack, action, replace} = useHistory();
  const {toggleReloadBlocker} = useReloadBlockerContext();
  const {searchUserInteractions, DEFAULT_USER_ID, bootcamp} = useUserDataContext();

  const hideTimer = searchUserInteractions('hideTimer') === true;
  const prometricDelay = searchUserInteractions('prometricDelay') === true;
  const isReadinessExam = type === 'readinessExam';


  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('review');
  const urlTestProgressId = urlParams.get('testProgressId');

  const canGoBack = action !== 'POP';
  const urlParts = window.location.pathname.split('/');
  const testRoute = urlParts.slice(-1)[0];
  const previousPath = urlParts
  .slice(0,2)
  .join('/');


  const handleGoBack = () => {
    if (urlParts[2] === 'custom-test') {
      push(`${previousPath}/previous-tests/review?key=${encodeURIComponent(test.config.testStorageKey)}`);
    } else if (canGoBack) {
      goBack();
    } else {
      push(previousPath);
    }
  };

  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses.findIndex(({current}) => current);
  const testBlock = testBlockConnections?.[blockIndex]?.testBlock;
  const questions = getTestBlockQuestions(testBlock, template === 'customTest');
  const currentQuestion = questions[questionIndex];
  const filterActive = navigationFilter && navigationFilter.key;
  const nextFilteredIndex = questionProgresses.findIndex((progressObject, index) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
    }

    return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
  });

  const nextBlocksMatchFilter = quizProgress.slice(blockIndex + 1).map(({questions}) => questions || []).flat().findIndex((progressObject) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase());
    }

    return progressObject[navigationFilter.key] === navigationFilter.value
  }) !== -1;


  const setQuestionIndex = index => {
    // TODO maybe move this logic into the test navigator...
    const blockTransition = index < 0
      ? 'previous'
      : index === questions.length
      ? 'next'
      : null;

    if (!blockTransition) {
      return setCurrentIndex(blockIndex, index);
    }

    transitionBlock(blockTransition);
  };

  // update qp locally
  const updateQuestionProgress = params => {
    const updatedQuestionProgress = {...questionProgresses[questionIndex], ...params};
    updateQuizProgress({
      questions: insertAtIndex(
        questionProgresses,
        questionIndex,
        updatedQuestionProgress
      )
    });
    return updatedQuestionProgress;
  };

  const udpateQuestionTime = () => {

    const timeElapsed = new Date(new Date() - startTime.current);
    const time = (parseFloat(questionProgresses[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    startTime.current = new Date();

    updateQuestionProgress({time});
  };
  // udpate qp in backend
  const updateQuestionProgressData = async () => {
    if (customTestConfig?.submitted || questionProgresses?.[questionIndex]?.didCheck) {
      startTime.current = new Date();
      return;
    };
    const timeElapsed = new Date(new Date() - startTime.current);
    const time = (parseFloat(questionProgresses[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    startTime.current = new Date();

    const {
      blockArrayIndex,
      questionProgressId,
      questionBaseId,
      questionRevisionId,
      current,
      crossedAnswerIndexes,
      bookmarked,
      originalTestId,
      questionIndex: currentQuestionIndex,
      subject,
      topic,
      subjectTopic,
      question,
      isSequentialStart,
      isSequentialEnd,
      isSequentialSet,
      answerState,
      seen,
      fullCredit,
      partialCredit,
      noCredit,
      highlights, // not saving these for now b/c there's an issue with restoration from the new object format they're being saved in
      ...questionProgress
    } = updateQuestionProgress({time});

    const stringifiedAnswerState = JSON.stringify(answerState || '');
    const stringifiedHighlights = JSON.stringify(normalizeHighlights(highlights) || '');

    const updatedProgress = await updateQbQuestionProgress(questionProgressId, {...questionProgress, answerState: stringifiedAnswerState, highlights: stringifiedHighlights});

    return updatedProgress;
  }

  const handleNextQuestion = () => {
    prometricDelay && modalDispatch({
      type: 'open',
      component: PrometricSpinner,
      modalContainerStyle: theme => `
        transition: 0s;
      `
    })
    if (navigationFilter.key && navigationFilter.value !== 'all') {
      const nextIndex = questionProgresses.findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
        }

        return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
      });

      if (nextIndex !== -1) {
        setQuestionIndex(nextIndex)
      } else {
        transitionBlock('nextFilterActive');
      }
    } else {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
    }
  }
  const handlePreviousQuestion = () => {
    prometricDelay && modalDispatch({
      type: 'open',
      component: PrometricSpinner,
      modalContainerStyle: theme => `
        transition: 0s;
      `
    })
    if (blockIndex === 1 && questionIndex === 0) return;

    if (navigationFilter.key) {
      const prevIndex = (questions.length - 1) - questionProgresses.slice().reverse().findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > ((questions.length - 1) - questionIndex);
        }

        return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex)
      });
      if (prevIndex !== -1 && prevIndex !== questions.length) {
        setQuestionIndex(prevIndex);
      } else {
        setQuestionIndex(-1);
      }
    } else {
      const prevIndex = questionIndex - 1;
      setQuestionIndex(prevIndex);
    }
  }
  const handleNavigation = (direction) => {
    // navigation handle wrapper for syncing question progress state
    const handler = direction === 'previous' ? handlePreviousQuestion : handleNextQuestion;

    if (isReadinessExam && !isReview) {
      udpateQuestionTime();
    }

    // only update question progresses for custom tests for now
    if (template === 'customTest') {
      updateQuestionProgressData();
    }

    handler();
  }

  const ultimateQuestionReached = questionIndex === questions.length - 1 && blockIndex === testBlockConnections.length - 2;

  useEffect(() => {
    if (timeUp) {

      if (isReadinessExam && !isReview) {
        udpateQuestionTime();
      }

      const alertText = isReadinessExam
        ? "You have no more time remaining. You can review your test results from the readiness exam review page."
        : "You have no more time remaining. You can review your test results from the previous tests page." ;

      modalDispatch({
        type: 'open',
        modalContainerStyle: theme => `
          background: transparent;
        `,
        component: ({close}) => {
          return (
            <SubmitModal
              prometricStyling
              headerText={"No Time Remaining"}
              bodyText={alertText}
              confirmConfig={{
                text: 'View Test Results',
                onConfirm: async () => {
                    if (type === 'readinessExam') {

                      if (!isReview) {
                        udpateQuestionTime();
                        // make sure current question state is saved
                        await saveAndTrackProgress();
                        // auto tag questions
                        await autoTagOnSubmit();
                      }

                      toggleReloadBlocker(false);
                      setTimeout(() => replace(`/${bootcamp}/previous-tests/review?id=${test.id}&testProgressId=${encodeURIComponent(testProgressId || urlTestProgressId)}&testRoute=nclex/next-gen-readiness-exams/qbanks/${testRoute}`), 100);
                      return;
                    }

                    const testStorageKey = customTestConfig?.config?.testStorageKey;

                    try {
                      // update custom test config to indicate suspension
                      const updatedConfig = {
                        ...customTestConfig,
                        suspended: false,
                        timeLeft: null,
                        submitted: true,
                      };

                      await Storage.put(testStorageKey, JSON.stringify(updatedConfig), {contentType: 'application/json' });

                    } catch (error) {
                      console.log('error marking test as submitted', error);
                    }

                    if (customTestConfig?.config && !customTestConfig.submitted) {
                      // only track performance if not in tutor mode
                      if (!tutorMode) {
                        await trackNCLEXQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                      }
                      // auto tag in all custom tests
                      await autoTagOnSubmit();
                    }

                    toggleReloadBlocker(false);
                    setTimeout(handleGoBack, 100);
                }
              }}
              cancelConfig={{}}
            />
          )
        },
        enableClickClose: false,
      })
    };
  }, [timeUp]);

  const finishedOnClick = () => {

    if (isReadinessExam && !isReview) {
      toggleTimer(false);
      udpateQuestionTime();
    }

    const hasUnansweredQuestions = quizProgress
      .some(({questions}) => questions?.some(({answerState}) => !answerState?.answerState))

    const {headerText, bodyText, confirmText, cancelText} = type === 'readinessExam' && !isReview
      ? {
        headerText: 'Would you like to submit your exam?',
        bodyText: hasUnansweredQuestions ? "You've left some questions unanswered. Submit your exam anyway? You'll be able to review your exam results on the next page." : "You'll be able to review your exam results on the next page.",
        confirmText: 'Submit Exam',
        cancelText: 'Return to Exam'
      }
      : (customTestConfig?.submitted || !customTestConfig || isReview)
      ? {
        headerText: 'Would you like to end your review?',
        bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
        confirmText: 'End Review',
        cancelText: 'Return to Review'
      } : {
        headerText: 'End Test',
        bodyText: 'Do you want to end this test? Once ended you can review your test results from the previous tests page.',
        confirmText: 'End Test',
        cancelText: 'Return to Test'
      }
    modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
        background: transparent;
      `,
      component: ({close}) => {
        return (
          <SubmitModal
            headerText={headerText}
            bodyText={bodyText}
            prometricStyling

            cancelConfig={{
              text: cancelText,
              onCancel: () => {
                close();

                if (isReadinessExam) {
                  toggleTimer(true);
                  return;
                }

                // filterActive ? transitionBlock('firstFilterActive') : transitionBlock('firstQuestionSet');
              },
            }}
            confirmConfig={{
              text: confirmText,
              onConfirm: async () => {
                if (type === 'readinessExam') {

                  if (!isReview) {
                    // make sure current question state is saved
                    await saveAndTrackProgress();
                    // auto tag questions
                    await autoTagOnSubmit();
                  }

                  toggleReloadBlocker(false);
                  setTimeout(() => replace(`/${bootcamp}/next-gen-readiness-exams/review?id=${test.id}&testProgressId=${encodeURIComponent(testProgressId || urlTestProgressId)}&testRoute=nclex/next-gen-readiness-exams/qbanks/${testRoute}`), 100);
                  return;
                }

                const testStorageKey = customTestConfig?.config?.testStorageKey;

                try {
                  // update custom test config to indicate suspension
                  const updatedConfig = {
                    ...customTestConfig,
                    suspended: false,
                    timeLeft: null,
                    submitted: true,
                  };

                  await Storage.put(testStorageKey, JSON.stringify(updatedConfig), { contentType: 'application/json' });

                } catch (error) {
                  console.log('error marking test as submitted', error);
                }

                if (template === 'customTest') {
                  await updateQuestionProgressData();
                }

                if (customTestConfig?.config && !customTestConfig.submitted) {
                    // only track performance if not in tutor mode
                    if (!tutorMode) {
                      await trackNCLEXQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                    }
                    // auto tag in all custom tests
                    await autoTagOnSubmit();
                  }

                toggleReloadBlocker(false);
                setTimeout(handleGoBack, 100);
              }
            }}
          />
        )
      },
      enableClickClose: true,
    })
  };

  const handleSuspendClick = () => {
    // save question state here too
    modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
        background: transparent;
      `,
      component: ({close}) => {
        return (
          <SubmitModal
            headerText={"Pause Test"}
            bodyText={"Do you want to pause this test? Once paused you can resume this test from the previous tests page."}
            prometricStyling
            cancelConfig={{
              text: 'Return to Test',
              onCancel: close,
            }}
            confirmConfig={{
              text: 'Pause Test',
              onConfirm: async () => {
                const testStorageKey = customTestConfig?.config?.testStorageKey;
                  const updatedConfig = {
                    ...customTestConfig,
                    suspended: true,
                    timeLeft: timeLeft?.length <= 5 ? `00:${timeLeft}` : timeLeft,
                    questionIndex
                  };

                  await Storage.put(testStorageKey, JSON.stringify(updatedConfig), { contentType: 'application/json' });
                  await updateQuestionProgressData();

                  toggleReloadBlocker(false);
                  setTimeout(() => push(`/nclex/previous-tests`), 100);
                }
            }}
          />
        )
      },
      enableClickClose: true,
    })
  };

  useEffect(() => {
    if (questionProgresses[questionIndex]?.didCheck) {
      toggleTimer(false);
    } else {
      toggleTimer(true);
    }
  }, [questionIndex]);

  useHotkeys('left, p', () => handleNavigation('previous'), [handleNavigation]);
  useHotkeys('right, n', (ultimateQuestionReached || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter)) ? finishedOnClick : () => handleNavigation('next'), [handleNavigation]);

  const updateMastery = (masteryLevel) => {
    const updatedQuestionProgress = updateQuestionProgress({masteryLevel, question: {...currentQuestion, masteryLevel}});
    saveQuestionProgresses([updatedQuestionProgress]);
  }

  const onClickMasteryButton = (masteryLevel) => {
    updateMastery(masteryLevel);
    (ultimateQuestionReached || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter)) ? finishedOnClick() : handleNextQuestion();
  }

  function validateAnswerState(answerState, question) {
    try {
      if (question) {
        const {type} = getQuestionParts(question);

        if (type === 'matrixMultipleChoice') {
          return !!answerState?.answerState?.every(answerRow => answerRow.some(el => !!el))

        } else if (type === 'bowtie') {
          return true;
        }
      }
      return answerState?.answerState;
    } catch (error) {
      return false;
    }
  }

  const showNavigator = () => {
    if ((customTestConfig && !tutorMode && !customTestConfig?.submitted) || (isReadinessExam && !isReview)) {

      return modalDispatch({
        type: 'open',
        component: navigatorComponent
          ? ({close}) => navigatorComponent({quizProgress, isReadinessExam, updateQuestionProgressData, setCurrentIndex, close, alwaysShowingExplanation})
          : ({ close }) => {
          const questionRows = quizProgress.reduce((acc, { questions, isSequentialSet }, blockIndex) => [...acc, ...(questions || []).map((question, index) => (
            <NavigatorTableRow current={question.current} isSequentialSet={isSequentialSet} onClick={() => {
              !isReadinessExam && updateQuestionProgressData();
              setCurrentIndex(blockIndex, index);
              close();
            }} even={index % 2 === 0}>
              <NavigatorTableRowCell>
                {acc.length + index + 1} {question.didMark || question.bookmarked ? <FlagIcon withColor /> : null}
              </NavigatorTableRowCell>
              <NavigatorTableRowCell isRed={!question.didCheck && !alwaysShowingExplanation && !validateAnswerState(question?.answerState)}>
                {(question.didCheck || alwaysShowingExplanation || validateAnswerState(question?.answerState, question?.question)) ? 'Complete' : question.seen ? 'Incomplete' : 'Unseen'}
              </NavigatorTableRowCell>
              {isReadinessExam ? null : <NavigatorTableRowCell>
                <MasteryTag level={question.masteryLevel} squared />
              </NavigatorTableRowCell>}
            </NavigatorTableRow>
          ))], [])
          return (
            <DraggablePopup
              close={close}
              headerContent={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <NavigatorIcon />Navigate
                </div>
              }
              footerContent={
                <div>
                  {questionRows.length} Questions, {alwaysShowingExplanation ? 0 : quizProgress.reduce((acc, {questions}) => [...acc, ...(questions || [])], [])?.filter(({seen}) => !seen)?.length} Unseen
                </div>
              }
            >
              <NavigatorTableHeader>
                <NavigatorTableHeaderCell>Question #</NavigatorTableHeaderCell>
                <NavigatorTableHeaderCell>Status</NavigatorTableHeaderCell>
                {isReadinessExam ? null : <NavigatorTableHeaderCell>Tag</NavigatorTableHeaderCell>}
              </NavigatorTableHeader>
              <NavigatorTable>
                {questionRows}
              </NavigatorTable>
            </DraggablePopup>
          )
        },
        enableClickClose: true,
        componentProps: {},
        modalContainerStyle: theme => `
          background: transparent;
        `
      })
    }
    return toggleSidebar();
  }

  useHotkeys('v', showNavigator, [showNavigator]);

  return (
    <Box className={className} style={{position: 'relative', padding: '0'}}>
      <ButtonContainer>
        <HoverButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={finishedOnClick}><EndIcon/><ButtonText>End</ButtonText></HoverButton>
        {!customTestConfig?.submitted && customTestConfig?.config && <PauseButton style={{borderLeft: 'none', borderRight: '1px solid white'}} onClick={handleSuspendClick}><PauseIcon/><ButtonText hideLaptop>Pause</ButtonText></PauseButton>}
      </ButtonContainer>
      <ButtonContainer>
        {((questionProgresses?.[questionIndex]?.didCheck && tutorMode) || alwaysShowingExplanation) ? [
          <Tooltip dismissalInteractionKey="dismissedMasteryTagTutorial"/>,
          <MasteryButton hotkey="1, l" label="Learning" color="red" onClick={() => onClickMasteryButton('learning')} />,
          <MasteryButton hotkey="2, r" label="Reviewing" color="yellow" onClick={() => onClickMasteryButton('reviewing')} />,
          <MasteryButton hotkey="3, m" label="Mastered" color="green" onClick={() => onClickMasteryButton('mastered')} />,
          <HoverButton onClick={() => handleNavigation('previous')}>
            <LeftArrowIcon /><ButtonText><UnderlineSpan>P</UnderlineSpan>revious</ButtonText>
          </HoverButton>,
          <HoverButton onClick={showNavigator}>
            <NavigatorIcon /><ButtonText>Na<UnderlineSpan>v</UnderlineSpan>igate</ButtonText>
          </HoverButton>,
          <HoverButton onClick={(ultimateQuestionReached || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter)) ? finishedOnClick : () => handleNavigation('next')}>
            <ButtonText><UnderlineSpan>N</UnderlineSpan>ext</ButtonText><RightArrowIcon />
          </HoverButton>,
          ] : [
        <HoverButton onClick={() => handleNavigation('previous')}>
          <LeftArrowIcon /><ButtonText><UnderlineSpan>P</UnderlineSpan>revious</ButtonText>
        </HoverButton>,
        <HoverButton onClick={showNavigator}>
          <NavigatorIcon /><ButtonText>Na<UnderlineSpan>v</UnderlineSpan>igate</ButtonText>
        </HoverButton>,
        <HoverButton onClick={(ultimateQuestionReached || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter)) ? finishedOnClick : () => handleNavigation('next')}>
          <ButtonText><UnderlineSpan>N</UnderlineSpan>ext</ButtonText><RightArrowIcon />
        </HoverButton>
        ]}
      </ButtonContainer>
    </Box>
  )
}

const NavigatorTableHeader = styled.div`
  display: flex;
  align-content: center;
  /* nclex/ui-light-blue */

  background: #80AEE1;
  /* nclex/ui-dark-blue */

  border-bottom: 0.5px solid #006DAA;
`;

const NavigatorTableHeaderCell = styled.div`
  width: 50%;
  padding: 12px 16px;
  color: white;
`;
const NavigatorTable = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
const NavigatorTableRow = styled.div`
  display: flex;
  background: ${({even, current}) => current ? '#FFFF67' : even ? 'white' : '#EEEEEE'};
  &:hover {
    cursor: pointer;
    background: #FFFF67;
  }
  ${({isSequentialSet}) => isSequentialSet && css`
    border-left: 5px solid #3385ff;
  `}
`;

const NavigatorTableRowCell = styled.div`
  width: 50%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  color: ${({isRed}) => !isRed ? '#404040' : '#E05E57'};
`;

export default Controls;
