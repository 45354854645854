import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Link} from 'react-router-dom';
import {Spinner2} from '@styled-icons/icomoon/Spinner2';
import DATBootcampLogo from '@bootcamp/shared/src/assets/images/logo.svg';
import BootcampLogoSVG from '@bootcamp/shared/src/assets/svg/bootcamp_dark_text_logo.png';
import {ChevronDown} from '@styled-icons/heroicons-outline/ChevronDown';
import baseTheme from '@bootcamp/shared/src/styles/theme';

export const OpenIndicator = styled(ChevronDown)`
  color: #C8C9CE;
  margin-left: auto;
  margin-right: 0;
  transition: .2s transform;
  transform: rotate(${({open}) => open ? '0deg' : '-90deg'});
`;

const Button = styled(Link)`
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 1.25em;
  background: ${({theme}) => theme.brandPurple};
  border-radius: 10px;
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
  user-select: none;
  border: none;
  transition: all 200ms ease;
  font-family: proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  &:hover {
    transition: all 200ms ease;
  }
  &:focus {
    outline: none;
  }
  ${({disabled}) => disabled && ({
    cursor: 'not-allowed',
    opacity: '.5'
  })}
`;

const RaisedButton = styled(Button).attrs(props => ({as: 'button', type: 'button'}))`
  letter-spacing: 1px;
  ${({color}) => {
    switch (color) {
      case 'blue':
        return css`
          background: #5096E4;
          box-shadow: 0px 5px 0px #3c82d0;
          &:hover {
            background: rgb(78, 148, 226);
            box-shadow: rgb(55, 125, 203) 0px 5px 0px;
          }
        `;
      case 'red':
        return css`
          background: #fa4d4d;
          box-shadow: 0px 5px 0px #ad3636;
          &:hover {
            background: #fa4d4d;
            box-shadow: 0px 5px 0px #ad3636;
          }
        `;
      case 'salmon':
        return css`
          background: #FF7E73;
          box-shadow: 0px 5px 0px #EB6A5F;
          &:hover {
            background: #FF7E73;
            box-shadow: 0px 5px 0px #EB6A5F;
          }
        `;
      case 'yellow':
        return css`
          background: #fbc531;
          box-shadow: 0px 5px 0px rgb(231, 177, 29);
          &:hover {
            background: #fbc531;
            box-shadow: 0px 5px 0px rgb(231, 177, 29);
          }
        `;
      case 'green':
        return css`
          background: #44bd32;
          box-shadow: 0px 5px 0px rgb(40,169,30);
          &:hover {
            background: #44bd32;
            box-shadow: 0px 5px 0px rgb(40,169,30);
          }
        `;
      case 'grey':
        return css`
          color: rgb(110, 117, 119);
          background: rgb(237, 240, 242);
          box-shadow: 0px 5px 0px rgb(204, 204, 204);
          &:hover {
            background: rgb(237, 240, 242);
            box-shadow: 0px 5px 0px rgb(204, 204, 204);
          }
        `;
      case 'gold':
        return css`
          color: white;
          background: #FCD742;
          box-shadow: 0px 5px 0px #f1c40f;
          &:hover {
            background: #FCD742;
            box-shadow: 0px 5px 0px #f1c40f;
          }
        `;
      default:
        return css`
          background: white;
          box-shadow: rgba(221, 221, 221, 1);
          &:hover {
            background: rgba(221, 221, 221, 1);
            box-shadow: rgba(172, 172, 172, 1) 0px 5px 0px;
          }
        `;
      }
    }
  }
`;

const PurpleButton = styled(Button)`
  &:hover {
    background: rgb(110, 81, 199);
  }
`;

const GreyButton = styled(Button)`
  background: ${({theme}) => theme.lightGrey};
  color: ${({theme}) => theme.lightTextColor};
  &:hover {
    background: ${({theme}) => theme.greyButtonHover};
  }
`;

const BlueButton = styled(Button)`
  background: ${({theme}) => theme.brandBlue};
`;

const LoadingSpinner = styled(Spinner2).attrs(props => ({size: props.size || '14'}))`
  animation: ${({theme}) => keyframes`${theme.animation.spin}`} 2s infinite linear;
  fill: ${({theme: {colors: {brandPalette}}, color}) => (brandPalette[color] && brandPalette[color].default) || baseTheme.colors.neutralsPalette.white};
`;

const Loader = styled(Spinner2).attrs(props => ({size: props.size || '14'}))`
  opacity: ${({active}) => active ? 1 : 0};
  animation: ${({theme}) => keyframes`${theme.animation.spin}`} 2s infinite linear;
  fill: ${({theme: {colors: {brandPalette, neutralsPalette}}, color}) => (brandPalette[color] && brandPalette[color].default) || neutralsPalette.white};
`;

const FullPageSpinnerContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.15);
  opacity: 0;
  transition: opacity ease 250ms;
  ${({active}) => {
    if (active) {
      return css`
        z-index: 200;
        opacity: 1;
      `
    }
  }}
`;
const FullContainerSpinnerContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.15);
  opacity: 0;
  transition: opacity ease 250ms;
  ${({active}) => {
    if (active) {
      return css`
        z-index: 3;
        opacity: 1;
      `
    }
  }}
`;

const FullPageSpinner = ({className, ...props}) => (
  <FullPageSpinnerContainer className={className} {...props}>
    <LoadingSpinner size={24} {...props}/>
  </FullPageSpinnerContainer>
)
const FullContainerSpinner = ({className, ...props}) => (
  <FullContainerSpinnerContainer className={className} {...props}>
    <LoadingSpinner size={24} {...props}/>
  </FullContainerSpinnerContainer>
)

// TODO make this responsive
const FixedBar = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  left: 0;
  ${({position}) => {
    switch (position) {
      case 'top':
        return css`
          box-shadow: 0px 1px 0px #3c82d0;
          top: 0;
        `
      case 'bottom':
        return css`
          box-shadow: 0px -1px 0px #d4d8d9;
          bottom: 0;
        `
      default:
        return;
    }
  }}
  ${({size, barHeight}) => css`
    height: ${barHeight*size}px;
  `}
  ${({background}) => css`
    background: ${background};
  `}
`;

const DBCLogo = styled.img.attrs(props => ({src: DATBootcampLogo}))`
  cursor: pointer;
  max-width: 175px;
`;
const BootcampLogo = styled.img.attrs(props => ({src: BootcampLogoSVG}))`
  cursor: pointer;
  max-width: 175px;
`;


export {
  Button,
  PurpleButton,
  GreyButton,
  BlueButton,
  RaisedButton,
  LoadingSpinner,
  Loader,
  FullPageSpinner,
  FullContainerSpinner,
  FixedBar,
  DBCLogo,
  BootcampLogo,
}
