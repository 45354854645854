import React from 'react';

import {Link} from 'react-router-dom';

import styled from 'styled-components';

import {resolveIconPath} from '@bootcamp/web/src/helpers';

import {H3} from '../../../components/Typography';

import ProgressBar from '../../../components/ProgressBar';
import {ExpertEducators} from '../../../components/ExpertEducators';

import {ChevronRight} from '@styled-icons/feather/ChevronRight';
import theme from '@bootcamp/shared/src/styles/theme';

import {formatClassroomVideoProgress} from '@bootcamp/shared/src/util/formatting';
import {useTestProgressArray} from '@bootcamp/shared/src/util/hooks';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

const Container = styled(Link)`
  display: flex;
  width: 100%;
  padding: 16px ${({theme}) => theme.layouts.spacing.l};
  background: linear-gradient(180deg,#FFFFFF 0%,#FFFFFF 50.68%,#F5F5F5 100%);
  text-decoration: none;
  border-radius: 8px;
  transition: all 100ms ease;
  align-items: center;
  user-select: none;
  &:hover {
    transform: scale(1.01);
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const ComingSoonContainer = styled(Container)`
  transition: none;
  cursor: default;
  &:hover {
    transform: none;
  }
  opacity: .9;
`;
const Index = styled(H3)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  align-self: flex-start;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const Body = styled.div`
  width: 100%;
  max-width: 630px;
`;
const Title = styled(H3)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const StyledProgressBar = styled(ProgressBar)`
  &:nth-child(2) {
    margin-left: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const ProgressWrapper = styled.div`
  display: flex;
  margin-right: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
    gap: 8px;
    margin-right: ${({theme}) => theme.layouts.spacing.s};

    ${StyledProgressBar} {
      margin: 0;
    }
  }
`;
const Educators = styled(ExpertEducators)`
  width: auto;
  margin-left: auto;
  justify-content: flex-end;

  h4 {
    max-width: 150px;
    text-align: right;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Arrow = styled(ChevronRight)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  display: ${({mobile}) => mobile ? 'none' : 'initial'};

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-right: ${({theme}) => theme.layouts.spacing.m};
  }
`;


const defaultProgress = {
  reviewing: 0,
  learning: 0,
  mastered: 0,
  total: 0,
};

const ClassroomRow = ({
  className,
  name,
  route,
  questionCount,
  locked,
  educators,
  progress,
  index,
  comingSoon,
  showProgress,
  showVideoProgress,
  videoLessonCount,
  detailsOrientation,
  iconPath,
  educatorNameLayout,
  courses
}) => {

  const {DEFAULT_USER_ID} = useUserDataContext();
  const videoLessonProgress = useTestProgressArray(courses, formatClassroomVideoProgress, DEFAULT_USER_ID);

  const loadingProgress = (progress === null || videoLessonProgress === null || questionCount === 'loading' || videoLessonCount === 'loading') && !locked;

  const {reviewing, learning, mastered} = progress || defaultProgress;

  const progressSegments = [
    {count: mastered},
    {count: reviewing},
    {count: learning}
  ];

  if (comingSoon) {
    return (
      <ComingSoonContainer as={'div'} className={className}>
        <Index style={{alignSelf: 'center'}}>{index}</Index>
        <Body>
          <Title style={{marginBottom: 0}}>{name} (coming soon)</Title>
        </Body>
        <Educators
          educators={educators}
          size={'small'}
          textPosition={'left'}
          whiteMode
        />
        {/* <ArrowWrapper>
          <Arrow size={24}/>
        </ArrowWrapper> */}
      </ComingSoonContainer>
    )
  }

  return (
    <Container to={route} className={className}>
      {iconPath && <Icon src={resolveIconPath(iconPath)}/>}
      {index && <Index>{index}</Index>}
      <Body>
        {iconPath && <Icon src={resolveIconPath(iconPath)} mobile/>}
        <Title>{name}</Title>
        <ProgressWrapper>
          {showProgress && (!loadingProgress && questionCount > 0) &&
            <StyledProgressBar
              segments={progressSegments}
              loading={loadingProgress}
              total={questionCount}
              lightMode
            />
          }
          {showVideoProgress &&
            <StyledProgressBar
              loading={loadingProgress}
              total={videoLessonCount}
              segments={[{count: videoLessonProgress, color: theme.colors.brandPalette.blue.gradient}]}
              label={<span>{<strong>{`${videoLessonProgress > videoLessonCount ? videoLessonCount : videoLessonProgress || 0}`}</strong>} of {`${videoLessonCount} sections completed`}</span>}
              detailsOrientation={detailsOrientation}
              lightMode
            />
          }
        </ProgressWrapper>
      </Body>
      <Educators
        educators={educators}
        size={'small'}
        textPosition={'left'}
        ledByTextOrientation={'column'}
        educatorNameLayout={educatorNameLayout}
        whiteMode
      />
      <ArrowWrapper>
        <Arrow size={24}/>
      </ArrowWrapper>
    </Container>
  );
};

ClassroomRow.defaultProps = {};
ClassroomRow.propTypes = {};

export default ClassroomRow;
