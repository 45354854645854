import React from 'react';

import {Link} from 'react-router-dom';

import styled, {css} from 'styled-components';

import {FlexBox, Column, Row} from '../../../components/Grid';
import {H2, Body3} from '../../../components/Typography';

import {ChevronRight} from '@styled-icons/feather/ChevronRight';

const LinkWrapper = styled(Link)`
  align-self: stretch;
  text-decoration: none;
  background: ${({theme, color}) => theme.colors.neutralsPalette.white};
  border-radius: 8px;
  transition: all 100ms ease;
  overflow: hidden;
  display: block;
  margin-bottom: 16px;
  opacity: 0.7;
  ${({locked}) => !locked && css`
    opacity: 1;
    &:hover {
      transform: scale(1.01);
      box-shadow: ${({theme}) => theme.elevation.shadow.high};
    }
  `}
  ${({theme}) => theme.mediaQueries.laptop} {
    width: 100%;
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const Container = styled(FlexBox)`
  position: relative;
  padding: ${({theme}) => theme.layouts.spacing.xl};
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  flex-direction: column;
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  border-radius: 8px;

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: row;
    max-width: 100%;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    min-height: auto;
    flex-direction: column;
    height: 100%;
  }

  ${({locked, theme}) => locked && css`
    background: ${theme.colors.special.naturalDark};
    border: 1px solid ${theme.overlays.opacity.dark._50};
    box-shadow: ${theme.elevation.inner.progressDark};

    &:hover {
      transform: none;
    }
  `}
`;

const IconWrapper = styled.div`
  height: 64px;
  width: 64px;
  min-width: 64px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  border-radius: 2000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`;

const Header = styled(Column)`
  min-height: auto;

  ${({theme}) => theme.mediaQueries.laptop} {
    justify-content: space-between;
    margin-bottom: 0px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    min-height: auto;
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const Title = styled(H2)`
  width: 100%;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;
const QuestionCount = styled(Body3)`
  width: 100%;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArrowForward = styled(ChevronRight)`
  color:  ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  height: 32px;
  width: 32px;
`

const IconsWrapper = styled(Row)`
  height: auto;
  justify-content: space-between;
  align-items: center;
`

const Details = styled(Column)`
  justify-content: space-between;
  margin-left: 24px;
`;

const Icon = styled.div`
  background: ${({theme, color}) => color};
  border-radius: 100px;

  padding: 16px;

  svg {
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  }
`;

const ClassroomCard = ({name,
  icon,
  lightMode,
  className,
  route,
  themePalette,
  questionCount,
  locked,
}) => {

  const containerProps = locked
    ? {as: 'div'}
    : {as: Link, to: route, color: themePalette.gradient}

  return (
    <LinkWrapper {...containerProps} className={className} locked={locked}>
      <Container locked={locked}>
        <Header>
          <IconsWrapper>
            <IconWrapper>
              <Icon color={themePalette.gradient} children={icon} />
            </IconWrapper>
            <Details>
              <Title>
                {name}
              </Title>
              <QuestionCount>
                {questionCount} Question{questionCount === 1 ? '' : 's'}
              </QuestionCount>
            </Details>
            <ArrowForward />
          </IconsWrapper>

        </Header>
      </Container>
    </LinkWrapper>
  );
}

export {Icon, IconWrapper};
export default ClassroomCard;
