import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

import {
  PrometricWrapper,
  NextText,
  NextButton,
} from './shared';

import keyhole from '../assets/Keyhole.png'
import TFE1 from '../assets/TFE1.png'
import TFE2 from '../assets/TFE2.png'
import angleRanking from '../assets/Angle Ranking.png'
import holePunching from '../assets/Hole Punching.png'
import patternFolding from '../assets/Pattern Folding.png'

const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 21px;
`;
const Section = styled.div`
  width: 100%;
  margin-bottom: 21px;
`;

const Keyholes = () => (
  <PrometricWrapper>
    <Heading>
      Official Keyhole Instructions
    </Heading>
    <Section>
      This visualization test consists of a number of items similar to the sample below. A three-dimensional object is shown at the left. This is followed by outlines of five apertures or openings.
    </Section>
    <Section>
      In each item the task is exactly the same. First, you are to imagine how the object looks from all directions (rather than from a single direction as shown). Then pick, from the five apertures outlined, the opening through which the object could pass directly if the proper side were inserted first. Finally, use the mouse to select the answer choice corresponding to the answer you have chosen.
    </Section>
    <Section>
      Here are the rules:
    </Section>
    <Section>
      <ol>
        <li>Prior to passing through the aperture, the irregular solid object may be turned in any direction. It may be started through the aperture on a side not shown.</li>
        <li>Once the object is started through the aperture, it may not be turned or twisted. It must pass completely through the opening. The opening is always the exact shape of the appropriate external outline of the object.</li>
        <li>Both objects and apertures are drawn to the same scale. Thus it is possible for an opening to be the correct shape but too small for the object. In all cases, however, differences are large enough to judge by eye.</li>
        <li>There are no irregularities in any hidden portion of the object. However, if the figure has symmetric indentations, the hidden portion is symmetric with the part shown.</li>
        <li>For each object there is only one correct aperture.</li>
      </ol>
    </Section>
    <Section>
      <img src={keyhole} />
    </Section>
    <Section>
      The correct answer is [C] since the object would pass through this aperture if the side at the left were introduced first.
    </Section>
    <NextText />
  </PrometricWrapper>
);

const TFE = () => (
  <PrometricWrapper>
    <Heading>
      Official Top Front End Instructions
    </Heading>
    <Section>
      The pictures that follow are top, front, and end views of various solid objects. The views are without perspective. That is, the points in the viewed surface are viewed along parallel lines of vision. The projection looking DOWN on it is shown in the upper left-hand corner (TOP VIEW). The projection looking at the object from the FRONT is shown in the lower left-hand corner (FRONT VIEW). The projection looking at the object from the END is shown in the lower right-hand corner (END VIEW). These views are ALWAYS in the same positions and are labeled accordingly.
    </Section>
    <Section>
      <img src={TFE1} />
    </Section>
    <Section>
      If there were a hole in the block, the views would look like this:
    </Section>
    <Section>
      <img src={TFE2} />
    </Section>
    <Section>
      Note that the lines cannot be seen on the surface in some particular view are DOTTED in that view. In the problems that follow, two views will be shown, with four alternatives to complete the set. You are to select the correct one with your mouse.
    </Section>
    <NextText />
  </PrometricWrapper>
);

const AngleRanking = () => (
  <PrometricWrapper>
    <Heading>
      Official Angle Ranking Instructions
    </Heading>
    <Section>You are to examine the four INTERIOR angles and rank each in terms of degrees from SMALL to LARGE. Select the choice that has the correct ranking.</Section>
    <Section>Here's an example:</Section>
    <Section><img src={angleRanking} /></Section>
    <Section>The correct ranking of the angles from small to large is {`2 - 1 - 4 - 3`}; therefore, choice [B] is correct.</Section>
    <NextText />
  </PrometricWrapper>
);

const HolePunching = () => (
  <PrometricWrapper>
    <Heading>
      Official Hole Punching Instructions
    </Heading>
    <Section>A flat square sheet of paper is folded one or more times. The broken lines indicate the original position of the paper. The solid lines indicate the position of the folded paper. The paper is never turned or twisted. The folded paper always remains within the edges of the original square. There may be from one to four folds in each item. After the last fold a hole is punched in the paper. Your task is to unfold the paper mentally and determine the position of the holes on the original square. Choose the pattern of black circles that indicates the position of the holes on the original square sheet of paper. There is only one correct pattern for each item.</Section>
    <Section>Here's an example:</Section>
    <Section><img src={holePunching} /></Section>
    <Section>Figure B shows the result of the first fold. Figure C shows the position of the punched hole on the folded paper. When the paper is unfolded the pattern of the holes on the original square is shown by the dark circles in Figure D. The answer has two holes since the paper was two thicknesses when punched.</Section>
    <NextText />
  </PrometricWrapper>
)
const CubeCounting = () => (
  <PrometricWrapper>
    <Heading>
      Official Cube Counting Instructions
    </Heading>
    <Section>Each figure has been made by cementing together cubes of the same size. After being cemented each figure was painted on all sides EXCEPT FOR THE BOTTOM ON WHICH IT IS RESTING. The only hidden cubes are those required to support other cubes.</Section>
    <Section>For the following questions you are to examine each figure closely to determine how many cubes have:</Section>
    <Section>
      <ul>
        <li>Only one of their sides painted</li>
        <li>Only two of their sides painted</li>
        <li>Only three of their sides painted</li>
        <li>Only four of their sides painted</li>
        <li>All five of their sides painted.</li>
      </ul>
    </Section>
    <Section>
      Note: There are no problems for which zero (0) is the correct answer.
    </Section>
    <NextText />
  </PrometricWrapper>
)

const PatternFolding = () => (
  <PrometricWrapper>
    <Heading>
      Official Pattern Folding Instructions
    </Heading>
    <Section>
      A flat pattern will be presented. This pattern is to be folded into a three dimensional figure. The correct figure is one of the four given at the right of the pattern. There is only one correct figure in each set. The outside of the pattern is what is seen at the left. The outside of the pattern must remain on the outside of the three dimensional figure.
    </Section>
    <Section>
      Here's an example:
    </Section>
    <Section><img src={patternFolding} /></Section>
    <Section>
      One of the above figures (A, B, C, or D) can be formed from the flat pattern given at the left. The only figure that corresponds to the pattern is [D]. If the shaded surfaces are looked at as the sides of the box, then all four sides must be shaded, while the top and bottom are white.
    </Section>
    <NextText />
  </PrometricWrapper>
)

const tutorialSet = [
  Keyholes,
  TFE,
  AngleRanking,
  HolePunching,
  CubeCounting,
  PatternFolding
]

const PATTutorial = ({instructionIndex, setDismissedPATTutorial}) => {

  const {
    methods: {
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
    }
  } = useTestNavigatorContext();

  useEffect(() => {
    setLeftButtons([{component: <div />}]);
    setCenterButtons([{
      component: <NextButton onClick={() => setDismissedPATTutorial(dismissedPATTutorial => [...dismissedPATTutorial, instructionIndex])} />
    }]);
    setRightButtons([{component: <div />}]);
    return () => {
      // When this component dismounts...
      setCenterButtons([]);
    }

  }, [])

  return tutorialSet[instructionIndex]();
}

export default PATTutorial;
