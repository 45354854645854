import React, {createContext, useContext, useState, useEffect, useMemo, useCallback} from 'react';
import styled from 'styled-components';
// import AnatomyConfig from '@bootcamp/shared/src/config/anatomy';
import ChemistryConfig from '@bootcamp/shared/src/config/updatedChemistryConfig';
// import DATConfig from '@bootcamp/shared/src/config/dat';
// import OATConfig from '@bootcamp/shared/src/config/oat';
// import INBDEConfig from '@bootcamp/shared/src/config/inbde';
// import DSBConfig from '@bootcamp/shared/src/config/dsb';
// import MSBConfig from '@bootcamp/shared/src/config/msb';
import MCATConfig from '@bootcamp/web/src/bootcamps/mcat/config';

import {useUserDataContext} from '../contexts/UserData';
import Storage from '@aws-amplify/storage';

import {useAsync} from '@bootcamp/web/src/hooks';
import {colors} from '@bootcamp/shared/src/styles/theme';
import {FullPageSpinner} from '@bootcamp/web/src/components/Branding';
import Maintenance from '@bootcamp/web/src/components/Maintenance';
import {asyncFetchWithS3Cache, getBootcampConfig} from '@bootcamp/shared/src/util';
import {getQuestionData} from '@bootcamp/shared/src/requests';
import {indexConfig, expandConfig} from '@bootcamp/shared/src/config/search';
import moment from 'moment';

const BootcampConfigContext = createContext();

const Loader = styled(FullPageSpinner)`
  background: ${({theme}) => theme.colors.brandPalette.royal.dark};
`
const BootcampConfigProvider = ({children}) => {
  const [bootcampConfigLoaded, setBootcampConfigLoaded] = useState();
  const {bootcamp, userModel} = useUserDataContext();
  const [expandedSearchConfig, setExpandedSearchConfig] = useState(null)

  const configFetch = useCallback(async () => {

    if (bootcamp === 'mcat') {
      setBootcampConfigLoaded(bootcamp);
      return MCATConfig;
    }

    let result = await getBootcampConfig(bootcamp)

    result.classrooms.forEach(classroom => {
      if (typeof classroom.themePalette === 'string') {
        const themeKey = classroom.newThemePalette || classroom.themePalette;
        classroom.themePaletteString = themeKey;

        const theme = ['oat', 'dat'].includes(bootcamp) ? colors.brandPalette[themeKey] : colors.brandPalette.royal;
        classroom.themePalette = theme;
      }
    });
    setBootcampConfigLoaded(bootcamp);
    return result
  }, [bootcamp])

  const {value: config} = useAsync(configFetch);

  useEffect(() => {
    if (!config || !bootcamp || ['nclex', 'mcat'].includes(bootcamp)) return;

    const storageKey = `json/${bootcamp}/lambdaExpandedConfig.json`;

    const asyncExpandConfig = async () => {
      try {
        const expandedConfig = await fetch(`https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com/public/${storageKey}`, {cache: "no-store"});
        const expandedJSON = await expandedConfig.json();
        expandedJSON.classrooms.forEach(classroom => {
          if (typeof classroom.themePalette === 'string') {
            const themeKey = classroom.newThemePalette || classroom.themePalette;
            classroom.themePalette = colors.brandPalette[themeKey];
          }
        });
        setExpandedSearchConfig(expandedJSON);
      } catch (e) {
        console.log(e);
      }
    }
    asyncExpandConfig();
  }, [config]);

  const activeMembershipGroups = useMemo(() => userModel?.memberships?.items?.reduce((acc, membership) => (
    membership.status === 'active' ? [...acc, ...membership.groups] : acc
  ), []) || [], [userModel])

  const indexedConfig = useMemo(() => expandedSearchConfig ? indexConfig(bootcamp, expandedSearchConfig.classrooms, activeMembershipGroups) : {}, [expandedSearchConfig, activeMembershipGroups]);

  // load question counts
  const getQuestionCounts = useCallback(async() => getQuestionData(bootcamp), [bootcamp]);
  const {value: questionCounts} = useAsync(getQuestionCounts);


  const adminConfigFetch = useCallback(async () => {
    const fetchResult = await fetch(`https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.amazonaws.com/read-only/adminConfig.json`, {cache: "no-store"});
    return fetchResult.json();
  }, [])
  const {value: adminConfig} = useAsync(adminConfigFetch);

  if (adminConfig?.status === 'maintenance') {
    return <Maintenance adminConfig={adminConfig} />
  }

  return <BootcampConfigContext.Provider value={{config, indexedConfig, questionCounts}} children={!config || bootcampConfigLoaded !== bootcamp ? <Loader color="white" active /> : children} />
}

const useBootcampConfigContext = () => useContext(BootcampConfigContext);

export {BootcampConfigContext, BootcampConfigProvider, useBootcampConfigContext};
