import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';

import {getTestBlockQuestions} from '@bootcamp/shared/src/util';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

import {updateQuestionProgressState} from '../../helpers';

import {Box, BoxSection, BoxSubsection, IconButton, MenuText} from './shared';

import {ReactComponent as MenuSVG} from './assets/Menu.svg';
import {ReactComponent as MarkFlagSVG} from './assets/MarkFlag.svg';
import {ReactComponent as ArrowSVG} from './assets/Arrow.svg';
import {ReactComponent as FullScreenSVG} from './assets/FullScreen.svg';
import {ReactComponent as TutorialSVG} from './assets/Tutorial.svg';
import {ReactComponent as LabValuesSVG} from './assets/LabValues.svg';
import {ReactComponent as CalculatorSVG} from './assets/Calculator.svg';

import {Settings} from '@styled-icons/fluentui-system-filled/Settings';
import useHotkeys from "@reecelucas/react-use-hotkeys";

const Container = styled(Box)`
  padding: 2px 8px;
`

const MenuIcon = styled(MenuSVG)`
  margin-right: 8px;
  cursor: pointer;
  width: 32px;
  height: 32px;
`;

const ItemCount = styled(MenuText)`
`;
const MarkFlagWrapper = styled.div`
  padding: 4px;
  margin: 0 2px;
`;
const MarkFlagIcon = styled(MarkFlagSVG)`
  height: 28px;
  width: 28px;
`;

const MarkSection = styled(BoxSubsection)`
  margin-left: 24px;
  cursor: pointer;
  &:hover {
    background-color: #283294;
  }
`;

const LeftSection = ({setShowingSidebar}) => {
  const {
    methods: {
      updateQuizProgress,
      saveQuestionProgresses
    },
    variables: {
      blockIndex,
      quizProgress,
      testBlockConnections,
      type,
      template
    }
  } = useTestNavigatorContext();
  const isReadinessExam = type === 'readinessExam';
  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const testBlock = testBlockConnections?.[blockIndex]?.testBlock;
  const questions = getTestBlockQuestions(testBlock, template === 'customTest');
  const currentQuestion = questions[questionIndex];

  const bookmarked = questionProgresses[questionIndex]?.bookmarked;
  const masteryLevel = questionProgresses[questionIndex]?.masteryLevel;
  const originalTestId = questionProgresses[questionIndex]?.originalTestId;

  const globalQuestionIndex = quizProgress.slice(0, blockIndex).reduce((questionCount, blockProgress) => {
    if (!blockProgress.questions) return questionCount;
    return questionCount + blockProgress.questions.length;
  }, 0) + questionIndex + 1;
  const totalQuestions = useMemo(() => {
    return testBlockConnections.reduce((prev, curr) => {
      const total = curr?.testBlock?.questionConnections?.items?.filter(({question}) => template === 'customTest' || question?.status !== 'draft')?.length;
      return prev + (total || 0);
    }, 0)
  }, [testBlockConnections]);

  return (
    <BoxSection key={`top-left-${questionIndex}`}>
      <BoxSubsection>
        <MenuIcon onClick={e => {
          e.stopPropagation();
          setShowingSidebar(showingSidebar => !showingSidebar)
        }} />
        <div style={{minWidth: '100px'}}>
          {isReadinessExam ? <ItemCount>Block {(blockIndex + 1) / 2} of 4</ItemCount> : <ItemCount>Item {globalQuestionIndex} of {totalQuestions}</ItemCount>}
          {/* <BlockCount>Question Id: {questionIndex+1}</BlockCount> */}
        </div>
      </BoxSubsection>
      <MarkSection onClick={() => {
        updateQuestionProgressState(updateQuizProgress, questionProgresses, questionIndex, {bookmarked: !bookmarked});
        const updatedBookmark = {bookmarked: !bookmarked, question: {...currentQuestion, bookmarked: !bookmarked}, originalTestId, masteryLevel};
        saveQuestionProgresses([updatedBookmark]);
      }}>
        <input checked={bookmarked} type="checkbox" />
        <MarkFlagWrapper>
          <MarkFlagIcon/>
        </MarkFlagWrapper>
        <MenuText>Mark</MenuText>
      </MarkSection>
    </BoxSection>
  )
}

const LeftArrowIcon = styled(ArrowSVG)``;
const RightArrowIcon = styled(ArrowSVG)`
  transform: rotate(180deg);
`;

const ProgressButton = styled(IconButton)`
  min-width: 62px;
  ${({theme}) => theme.mediaQueries.laptop} {
    min-width: auto;
  }
  div {
    font-size: 12px;
  }
`;


const CenterSection = ({handleNavigation}) => {
  return (
    <BoxSection>
      {
        <BoxSubsection>
          <ProgressButton Icon={LeftArrowIcon} text={'Previous'} onClick={() => handleNavigation('previous')} />
        </BoxSubsection>
      }
      {
        <BoxSubsection>
          <ProgressButton Icon={RightArrowIcon} text={'Next'} onClick={() => handleNavigation('next')}/>
        </BoxSubsection>
      }
    </BoxSection>
  )
}

const FullScreenIcon = styled(FullScreenSVG)``;
const TutorialIcon = styled(TutorialSVG)``;
const LabValuesIcon = styled(LabValuesSVG)``;
const CalculatorIcon = styled(CalculatorSVG)``;

const SettingsIcon = styled(Settings)`
  width: 26px;
  height: 26px;
  color: white;
`;

const RightSection = ({settingsSidebarState, setShowingExhibit, setShowingLabValues}) => {
  const [showingSidebar, setShowingSidebar] = settingsSidebarState;
  const {variables: {type}} = useTestNavigatorContext();
  return (
    <BoxSection>
      <BoxSubsection>
        <IconButton
          Icon={FullScreenIcon}
          text={'Full Screen'}
          onClick={() => document.fullscreen ? document.exitFullscreen() : document.documentElement.webkitRequestFullscreen ? document.documentElement.webkitRequestFullscreen() : document.documentElement.requestFullscreen()}
        />
      </BoxSubsection>
      {/* <BoxSubsection><IconButton Icon={TutorialIcon} text={'Tutorial'} /></BoxSubsection> */}
      <BoxSubsection>
        <IconButton
          Icon={LabValuesIcon}
          text={'Lab Values'}
          onClick={() => setShowingLabValues(showingLabValues => !showingLabValues)}
        />
      </BoxSubsection>
      <BoxSubsection>
        <IconButton
          Icon={CalculatorIcon}
          text={'Calculator'}
          onClick={() => setShowingExhibit(showingExhibit => !showingExhibit)}
        />
      </BoxSubsection>
      {type === 'readinessExam' ? null : <BoxSubsection>
        <IconButton
          Icon={SettingsIcon}
          text={'Settings'}
          onClick={() => setShowingSidebar(showingSidebar => !showingSidebar)}
        />
      </BoxSubsection>}
    </BoxSection>
  )
}

const TopBar = ({settingsSidebarState, sidebarState, setShowingExhibit, setShowingLabValues, handleNavigation}) => {
  const [showingSidebar, setShowingSidebar] = sidebarState;

  return (
    <Container>
      <LeftSection setShowingSidebar={setShowingSidebar} />
      <CenterSection handleNavigation={handleNavigation} />
      <RightSection settingsSidebarState={settingsSidebarState} setShowingExhibit={setShowingExhibit} setShowingLabValues={setShowingLabValues} />
    </Container>
  )
}

export default TopBar;
