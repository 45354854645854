import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {useAsync, useDebounce} from '@bootcamp/shared/src/util/hooks';
import {searchQuestions, getQuestionMastery} from '@bootcamp/shared/src/requests';

import Search from '../index';

const DAT_TEST_TAG_ID = '371eb193-7e9e-442c-88c1-82139e559912';
const INBDE_TEST_TAG_ID = '3022e66b-64f9-46ef-abba-392864efe3fe';

const BareQuestionSearch = ({titleButtons, renderResults, titleSize, additionalFilter, updateMasteryCache, cachedMastery, DEFAULT_USER_ID, bootcamp}) => {
  const [searchParams, setSearchParams] = useState({
    filterRequired: true,
    filter: '',
    limit: 25,
    sort: {},
    nextToken: null,
    filterOverride: null
  });
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const composeFilterOverride = filter => {
    return {and: [{searchString: {matchPhrasePrefix: filter.toLowerCase()}}, {or: [{searchString: {matchPhrase: `[test:${bootcamp === 'inbde' ? INBDE_TEST_TAG_ID : DAT_TEST_TAG_ID}]`}}, {searchString: {matchPhrase: `[bootcamp:${bootcamp === 'inbde' ? INBDE_TEST_TAG_ID : DAT_TEST_TAG_ID}]`}}]}]}
  }

  const debouncedSearchFilter = useDebounce(searchParams.filter, 500);
  const fetchFilteredQuestions = async () => {
    const {filter, filterOverride, filterRequired, limit, sort} = searchParams;
    if (!(filter || filterOverride) && filterRequired) return setLoadingQuestions(false);
    setLoadingQuestions(true);
    const input = {
      ...(filterOverride ?
        {filter: filterOverride, limit, sort}
        : filter ?
        {filter: {searchString: {matchPhrasePrefix: filter.toLowerCase()}}}
        : {}),
        limit,
        sort,
        nextToken: searchParams.nextToken
      };
      const {items: fetchedQuestions, nextToken} = await searchQuestions(input);
      setSearchParams({...searchParams, nextToken});
      setHasNextPage(nextToken && fetchedQuestions.length === limit);
      const promises = fetchedQuestions.map(async question => {
        if (cachedMastery[question.id]) return;
        const result = await getQuestionMastery({userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${question.id}`});
        const masteryLevel = result?.data?.getQuestionMastery?.masteryLevel;
        masteryLevel && updateMasteryCache(question.id, masteryLevel, question.tags, true);
      });
      await Promise.all(promises);

      setFilteredQuestions([...filteredQuestions, ...fetchedQuestions]);
      setLoadingQuestions(false);
  }

  const asyncFetch = useCallback(fetchFilteredQuestions, [debouncedSearchFilter]);
  const {pending: loadingNextPage} = useAsync(asyncFetch);

  useEffect(() => {
    setSearchParams({...searchParams, filter: '', filterRequired: true, sort: {}, nextToken: null});
  }, [setSearchParams]);

  return (
    <Search
      title={''}
      titleSize={titleSize}
      titleButtons={titleButtons}
      getResults={({filter}) => {
        setSearchParams({...searchParams, filter, filterOverride: composeFilterOverride(filter), nextToken: null})
        setFilteredQuestions([]);
      }}
      getNextPage={() => hasNextPage && fetchFilteredQuestions()}
      renderResults={(search, results) => renderResults(results)}
      results={(filteredQuestions || []).filter(additionalFilter)}
      loading={loadingNextPage}
      loadingNextPage={loadingQuestions}
      hasNextPage={hasNextPage}
    />
  );
}

BareQuestionSearch.propTypes = {
  renderResults: PropTypes.func,
  titleSize: PropTypes.string,
  pageSize: PropTypes.number
};

BareQuestionSearch.defaultProps = {
  titleSize: 'large',
  pageSize: 10,
  additionalFilter: () => true
}

export default BareQuestionSearch;
