import React, {useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {Prompt, AnswerBox, QuestionHeader, SubmitButton} from './shared';

import DragAndDrop from './DragAndDrop';
import HotSpot from './HotSpot';
import Bowtie from './Bowtie';

import Highlightable from '../../../Highlightable';
import {displayScoredMatrixUI, displayScoredDropdownUI, getAnswerMatrix, syncMatrixWithAnswerState, renderDropdown} from '../helpers';
import {useTestNavigatorContext} from '../../../../contexts/TestNavigator';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {AlertPopup} from '../../shared';

const Container = styled.div`
  ${({answered}) => !answered && css`padding-bottom: 72px;`}
`;

const DefaultQuestion = ({
  prompt,
  questionHeader,
  setHighlights,
  highlights,
  enableHighlighting,
  questionIndex,
  didSubmitAnswer,
  alwaysShowingExplanation,
  answers,
  answerGroups,
  answerMatrix,
  answerState,
  setAnswerState,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
  showAnswerBox,
  type,
  hotSpotImage,
  hotSpotImageSelection,
  hotSpotImageInput,
  checkOnClick,
  tutorMode,
}) => {
  const promptRef = useRef();
  const {modalDispatch} = useModalContext();
 // highlight handler
  useEffect(() => {
    if (!['highlight', 'highlightSelectN'].includes(type)) return;

    const targets = promptRef?.current?.getElementsByClassName('fr-highlight');

    Array.from(targets).forEach((target, index) => {
      const isSelected = answerState?.find(([selectedIndex]) => index === selectedIndex);
      isSelected && target.classList.add('fr-highlight-selected');
    });

    // no need for event handler init if always shoing explanation
    if (alwaysShowingExplanation || didSubmitAnswer) return;

    const handler = event => {
      if (!event.target.classList.contains('fr-highlight')) return;

      const choiceLimit = prompt?.match(/.fr-highlight-correct/g)?.length;
      const isCorrect = event.target.classList.contains('fr-highlight-correct');
      const highlightIndexClicked = Array.from(promptRef.current.querySelectorAll('.fr-highlight')).indexOf(event.target);
      setAnswerState(answerState => {
        const answerPreviouslySelected = answerState?.find(([index]) => index == highlightIndexClicked)
        // if unselected, but we're at the choicelimit - don't select
        if (!answerPreviouslySelected && type === 'highlightSelectN' && answerState.length === choiceLimit) {
          return answerState
        }

        // toggle selection
        event.target.classList.toggle('fr-highlight-selected');

        // if already selected remove from answerState
        if (answerPreviouslySelected) {
          return [...answerState.filter(([index]) => index != highlightIndexClicked)]
        }

        return [...(answerState || []), [highlightIndexClicked, isCorrect]]
      })
    };

    promptRef.current?.addEventListener('click', handler);

    return () => promptRef.current?.removeEventListener('click', handler);

  }, [answerState, prompt, promptRef?.current, showingExplanation]);

  // fitb handlers
  useEffect(() => {
    if (!['fillInTheBlank'].includes(type)) return;

    const fitbInput = promptRef.current.querySelector('[data-fill-in-the-blank=""]');

    if (!fitbInput) return;

    if (answerState) {
      fitbInput.value = answerState;
    }

    if (showingExplanation) {
      fitbInput.disabled = true;
      return;
    }
    const handler = e => {
      setAnswerState(e.target.value);
    }
    fitbInput.addEventListener('change', handler)
    return () => {
      fitbInput.removeEventListener('change', handler)
    }
  }, [prompt, showingExplanation]);

  useEffect(() => {
    if (!['fillInTheBlank'].includes(type)) return;

    const fitbInput = promptRef.current.querySelector('[data-fill-in-the-blank=""]')
    if (!fitbInput || !showingExplanation) return;
    fitbInput.defaultValue = answerState;
  }, [showingExplanation]);

  // matrix handlers
  useEffect(() => {
    if (!['multipleResponseGrouping', 'matrixMultipleChoice', 'matrix'].includes(type)) return;

    if (showingExplanation) {
      try {
        const matrix = JSON.parse(answerMatrix[0].contents || '[]');
        displayScoredMatrixUI(promptRef.current, matrix, answerState, type);

      } catch (error) {
        console.log('error parsing matrix prompt', error);
      }
    } else {

      const handleTableChange = e => {
        try {
          if (e.target.tagName === 'SPAN') {
            if (e.target.previousElementSibling) {
              e.target.previousElementSibling.checked = !e.target.previousElementSibling.checked;
            }
          }
        } catch (e) {
          console.log(e);
        }
        const answeredMatrix = getAnswerMatrix(null, promptRef?.current);
        setAnswerState(answeredMatrix);
      };

      const table = promptRef?.current?.getElementsByTagName('table')?.[0];

      syncMatrixWithAnswerState(table, answerState);

      table?.addEventListener('click', handleTableChange);

      return () => table?.removeEventListener('click', handleTableChange);
    }
  }, [prompt, promptRef?.current, showingExplanation]);

  // dropdown handlers
  useEffect(() => {
    if (!['dropdown', 'dropdownTable', 'dropdownCloze', 'dropdownRationale'].includes(type) || showingExplanation) return;

    //TODO observing a bug wherein when selecting both options in a cloze style dropdown question, then navigating to next question, then navigating back
    // the dropdown first selected will lose it's answer state

    const handler = (e, index) => {
      if (!e.target.value) return;

      setAnswerState(answerState => {
        const relevantAnswerGroup = JSON.parse(answerGroups[index].contents);
        const relevantAnswer = relevantAnswerGroup.find((answer, answerIndex) => answerIndex === e.target.selectedIndex - 1);

        // empty out answer state if there isn't a relevant answer match
        if (!relevantAnswer) {
          answerState[index] = ['', false];
          return answerState;
        }

        // set answer state
        answerState[index] = [...relevantAnswer, e.target.selectedIndex];

        return answerState;
      });
    }

    // add event listeners
    const dropdowns = promptRef.current.querySelectorAll('[data-dropdown=""]') || [];

    dropdowns.forEach((dropdown, index) => {
      const selectedIndex = answerState?.[index]?.[2];

      // sync prompt html with answer state if necessary
      if (selectedIndex) {
        dropdown.selectedIndex = selectedIndex;
      }

      // remove options with undefined value
      Array.from(dropdown?.options)?.forEach(option => {
        if (option.value === "undefined" || option.value === "null") {
          dropdown.removeChild(option);
        }
      });

      dropdown.addEventListener('change', e => handler(e, index))
    });

    return () => {
      dropdowns.forEach((dropdown, index) => {
        dropdown.removeEventListener('change', e => handler(e, index))
      })
    }
  }, [prompt, promptRef?.current]);

  useEffect(() => {
    if (!['dropdown', 'dropdownTable', 'dropdownCloze', 'dropdownRationale'].includes(type) || !showingExplanation) return;

    const dropdowns = promptRef.current.querySelectorAll('[data-dropdown=""]') || [];

    if (showingExplanation) {
      displayScoredDropdownUI(promptRef, answerGroups, answerState)
      dropdowns.forEach((dropdown, index) => {
        const dropdownDisplay = document.createElement('div');
        dropdownDisplay.className = 'dropdownCover';
        dropdownDisplay.addEventListener('click', e => renderDropdown(e, answerGroups[index], answerState[index]))
        dropdown.parentNode.appendChild(dropdownDisplay)
      })
    } else {
      setAnswerState(answerGroups?.map(i => ['', false]) || []);
    }
    return () => {
      dropdowns.forEach((dropdown, index) => {
        dropdown.parentNode.removeEventListener('click', e => renderDropdown(e, answerGroups[index], answerState[index]))
      })
    }
  }, [showingExplanation, questionIndex, type]);

  function reformatPrompt (prompt) {
    return prompt.replace(/checked="true"/g, '').replace(/disabled/g, '')
  }

  return (
    <Container answered={didSubmitAnswer || alwaysShowingExplanation}>
      <Highlightable
        type={'stem'}
        htmlString={prompt}
        setHighlights={setHighlights}
        highlights={highlights}
        disable={!enableHighlighting || type?.match('highlight')}
        disableToolTip
        >
        {questionHeader && (
          <QuestionHeader
            className={'fr-view'}
            dangerouslySetInnerHTML={{__html: questionHeader}}
          />
        )}
        <Prompt
          ref={promptRef}
          className={'fr-view'}
          type={type}
          dangerouslySetInnerHTML={{__html: reformatPrompt(prompt)}}
          answered={didSubmitAnswer || alwaysShowingExplanation}
        />
      </Highlightable>
      {['hotSpot'].includes(type) &&
        <HotSpot
          hotSpotImageSelection={hotSpotImageSelection}
          hotSpotImageInput={hotSpotImageInput}
          hotSpotImage={hotSpotImage}
          showingExplanation={showingExplanation}
          setAnswerState={setAnswerState}
          answerState={answerState}
        />
      }
      {['fillInTheBlank'].includes(type) && showingExplanation && (
        <Prompt
          className={'fr-view'}
          children={`Correct Answer: ${parseFloat(answers?.[0]?.[0])}`}
        />
      )}
      {showAnswerBox &&
        <AnswerBox
          answers={answers}
          answerState={answerState}
          setAnswerState={setAnswerState}
          showingExplanation={showingExplanation}
          crossedAnswerIndexes={crossedAnswerIndexes}
          updateCrossedAnswerIndexes={updateCrossedAnswerIndexes}
          type={type}
        />
      }
      {tutorMode && !didSubmitAnswer && !alwaysShowingExplanation &&  (
        <SubmitButton submitPadding onClick={e => {
          try {
            if (type === 'matrixMultipleChoice') {
              if (answerState.some(answerRow => answerRow.every(el => !el))) {
                return modalDispatch({
                  type: 'open',
                  modalContainerStyle: theme => `
                    background: transparent;
                  `,
                  component: ({close}) => {
                    return (
                      <AlertPopup
                        close={close}
                        headerContent={"Question Incomplete"}
                        alertText={"You must respond to all rows before submitting."}
                        buttonConfig={[
                          {
                            children: 'Return to Question',
                            onClick: close
                          }
                        ]}
                      />
                    )
                  },
                  enableClickClose: false,
                })
              }
            }
          } catch (error) {
            console.log('Error checking MMC state')
          }
          checkOnClick(e)
        }}/>
      )}
    </Container>
  );
}

const questionViewsByType = {
  multipleChoice: DefaultQuestion,
  multipleResponse: DefaultQuestion,
  multipleResponseSelectN: DefaultQuestion,
  fillInTheBlank: DefaultQuestion,
  hotSpot: DefaultQuestion,
  highlight: DefaultQuestion,
  highlightSelectN: DefaultQuestion,
  matrix: DefaultQuestion,
  matrixMultipleChoice: DefaultQuestion,
  multipleResponseGrouping: DefaultQuestion,
  dropdown: DefaultQuestion,
  dropdownTable: DefaultQuestion,
  dropdownRationale: DefaultQuestion,
  dragAndDropCloze: DragAndDrop,
  dragAndDropSelectN: DragAndDrop,
  dragAndDropSATA: DragAndDrop,
  dragAndDropRationale: DragAndDrop,
  dragAndDropOrderedResponse: DragAndDrop,
  bowtie: Bowtie,
};

export const Question = props => {
  const Component = questionViewsByType[props?.type] || DefaultQuestion;
  const showAnswerBox = ['multipleChoice', 'multipleResponse', 'multipleResponseSelectN'].includes(props.type);
  const {variables: {tutorMode}} = useTestNavigatorContext();

  return <Component showAnswerBox={showAnswerBox} tutorMode={tutorMode} {...props} />;
}