import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';

import Dropdown from '../Dropdown';
import Row from './Row';

import {Button} from '../../../../components/Branding/Buttons';
import ProgressBar from '../../../../components/ProgressBar';
import {H5} from '../../../../components/Typography';
import {Row as FlexRow} from '../../../../components/Grid';
import {useUserDataContext} from '../../../../contexts/UserData';

const Details = styled(H5)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin: 0 !important;
  line-height: 21px;
`;
const Progress = styled(ProgressBar)`
  ${({theme}) => theme.mediaQueries.mobileL} {
    align-items: flex-start;
    margin: ${({theme}) => theme.layouts.spacing.m} 0 0;
  }
`;
const CTAWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: flex-end;

  ${({theme}) => theme.mediaQueries.mobileL} {
    flex-direction: row-reverse;
    width: 32px;
    margin-left: 16px;
    margin-top: 0px;
  }
`;

const ProgressBarWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  ${({hidden}) => hidden && `visibility: hidden;`}
`;


const QBankCollection = props => {
  const {
    config: {name, route, lessons},
    qbankCollectionData,
    saveBlockProgress,
    searchTerm,
    themePalette,
    chapterIndex,
    scrollIntoView
  } = props;

  const ref = useRef(null);
  const {isUpgraded, bootcamp} = useUserDataContext();

  useEffect(() => {
    if (scrollIntoView && ref?.current) {
      ref?.current?.scrollIntoView();
    }
  }, [scrollIntoView, ref?.current]);

  const rows = lessons.map((lesson, index) => (
    <Row
      index={index}
      key={lesson.testId || lesson.testId}
      lesson={lesson}
      routeParent={route}
      test={qbankCollectionData ? qbankCollectionData[lesson.testId || lesson.id]?.test : {}}
      blockProgress={qbankCollectionData ? qbankCollectionData[lesson.testId || lesson.id]?.quizProgress[0] : {}}
      mastery={qbankCollectionData && qbankCollectionData[lesson.testId || lesson.id]?.mastery}
      saveBlockProgress={(value) => saveBlockProgress(lesson.testId || lesson.id, value)}
      themePalette={themePalette}
      route={lesson.route}
      bootcamp={bootcamp}
      locked={!isUpgraded && index > 0}
    />
  ));

  const testIds = lessons.map(lesson => lesson.testId || lesson.id);
  const progress = !qbankCollectionData ? {} : testIds.reduce((acc, testId) => {
    const data = qbankCollectionData[testId].mastery;
    const testMastery = data.reduce((acc, {masteryLevel}) => {
      return {
        ...acc,
        [masteryLevel]: acc[masteryLevel] + 1
      }
    }, acc);
    return {
      ...testMastery,
      totalTagged: acc.totalTagged + data.length,
      total: acc.total + qbankCollectionData[testId].test.config.questionCount
    }
  }, {learning: 0, reviewing: 0, mastered: 0, totalTagged: 0, total: 0});

  const collectionSlug = window.location.pathname.match('qbank-collection') || window.location.pathname.match('identify-structures')
    ? 'identify-structures'
    // NOTE this case is only for keyholes and TFE banks which appears on the qbanks content route alongside normal qbs
    : window.location.pathname.match('qbanks')
    ? 'qbank-collection'
    : 'lecture-style-questions';
  const activeLesson = !qbankCollectionData ? '' : lessons.find(({testId, id}) => qbankCollectionData[testId || id].quizProgress[0].status === '');
  const blockLink = !qbankCollectionData ? '#' : activeLesson ? `${collectionSlug}/${route}/${activeLesson.route}` : `${collectionSlug}/${route}/${lessons[0].route}`;

  return (
    <Dropdown
      componentRef={ref}
      key={name}
      // route={blockLink}
      name={name}
      index={chapterIndex}
      expandOnChange={searchTerm || scrollIntoView}
      textContent={(
        <ProgressBarWrapper>
          <Progress
            loading={!qbankCollectionData}
            segments={[{count: progress.mastered}, {count: progress.reviewing}, {count: progress.learning}]}
            total={progress.total}
            label={<span> <strong>{progress.totalTagged}</strong> {`of ${progress.total} questions tagged`}</span>}
            lightMode
          />
          <Details>
          </Details>
        </ProgressBarWrapper>
      )}
      actions={[
        // <CTAWrapper>
        //   <Button size={'medium'} colorConfig={themePalette}>Start Bank #{activeLesson?.route?.slice(-1)}</Button>
        // </CTAWrapper>
      ]}
      {...props}
    >
      {rows}
    </Dropdown>
  );
};

export default QBankCollection;
