import React from 'react';
import styled from 'styled-components';

import {insertAtIndex} from '@bootcamp/shared/src/util';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';

import {Box, BoxSection, IconButton} from './shared';
import TICalculator from '@bootcamp/web/src/components/Exhibit/TICalculator';

import {updateQuestionProgress as updateQbQuestionProgress} from '@bootcamp/shared/src/requests';

import {useHotkeys} from 'react-hotkeys-hook';
import {
  FlagIcon,
  ClockIcon,
  SettingsIcon,
  QuestionIcon,
  CalculatorIcon,
} from './shared';


const Container = styled(Box)`
  padding: 12px;
`
const LeftSection = ({}) => {
  const {
    variables: {
      blockIndex,
      quizProgress,
      customTestConfig,
      tutorMode
    }
  } = useTestNavigatorContext();

  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);
  const timed = customTestConfig?.config?.timed;

  return (
    <BoxSection key={`top-left-${questionIndex}`}>
      NCLEX-RN Test<DesktopOnlyText>{customTestConfig?.config && ` (${tutorMode ? 'Tutored' : 'Untutored'}, ${timed ? 'Timed' : 'Untimed'})`}</DesktopOnlyText>
    </BoxSection>
  )
}

const DesktopOnlyText = styled.span`
  white-space: pre;

  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

const CenterSection = () => {
  return (
    <BoxSection style={{justifyContent: 'center', textAlign: 'center', width: 'auto', whiteSpace: 'pre'}}>
      <DesktopOnlyText>Bootcamp.com</DesktopOnlyText>
    </BoxSection>
  )
}

const HoverHighlight = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    color: #FFFF00;
    path {
      fill: #FFFF00;
    }
    rect {
      stroke: #FFFF00;
    }
  }
`
const RightSection = ({setShowingExhibit}) => {
  const {timeElapsed, longTimeLeft} = useTimerContext();
  const {
    variables: {
      blockIndex,
      quizProgress,
      testBlockConnections,
      customTestConfig,
      type,
      template
    }
  } = useTestNavigatorContext();
  const {searchUserInteractions, saveUserInteraction} = useUserDataContext();
  const hideTimer = searchUserInteractions('hideTimer') === true || (customTestConfig && customTestConfig?.submitted);
  const hideQuestionIndex = searchUserInteractions('hideQuestionIndex') === true;

  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const timed = customTestConfig?.config?.timed || type === 'readinessExam';

  const totalQuestions = testBlockConnections.reduce((prev, curr) => {
      const total = curr?.testBlock?.questionConnections?.items?.filter(({question}) => (template === 'customTest' || question?.status !== 'draft'))?.length;
      return prev + (total || 0);
    }, 0)

  const globalQuestionIndex = quizProgress.slice(0, blockIndex).reduce((questionCount, blockProgress) => {
    if (!blockProgress.questions) return questionCount;
    return questionCount + blockProgress.questions.length;
  }, 0) + questionIndex + 1;

  return (
    <BoxSection style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      <HoverHighlight onClick={() => saveUserInteraction('hideTimer', !hideTimer)}>
        <ClockIcon />{!hideTimer && <div style={{ display: 'inline-block', width: '67px' }}>{timed ? longTimeLeft : timeElapsed}</div>}
      </HoverHighlight>
      <HoverHighlight onClick={() => saveUserInteraction('hideQuestionIndex', !hideQuestionIndex)}>
        <QuestionIcon />{!hideQuestionIndex && <><DesktopOnlyText>Question</DesktopOnlyText>&nbsp;{globalQuestionIndex} of {totalQuestions}</>}
      </HoverHighlight>
    </BoxSection>
  )
}
const CenteredRow = styled.div`
  display: flex;
  align-items: center;
`;
const Button = styled(CenteredRow)`
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #FFFF00;

    path {
      stroke: #FFFF00;
    }

    rect {
      stroke: #FFFF00;
    }
  }
`;
const SettingsButton = styled(Button)`
  margin-right: 8px;
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-right: 0px;
  }
`
const ButtonText = styled(CenteredRow)`
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const Subcontainer = styled.div`
  background: #80AEE1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  color: white;
`;
const UnderlineSpan = styled.span`
  text-decoration: underline;
`

const TopBar = ({themePalette, setShowingExhibit, toggleSidebar}) => {
  const {modalDispatch} = useModalContext();

  const launchCalculator = () => modalDispatch({
    type: 'open',
    component: ({close}) => <TICalculator onCloseExhibit={close} />,
    enableClickClose: true,
    modalContainerStyle: theme => `
      background: transparent;
    `
  })
  useHotkeys('o', launchCalculator, [launchCalculator]);
    const {
    methods: {
      markQuestion,
    },
    variables: {
      blockIndex,
      quizProgress,
      type
    }
  } = useTestNavigatorContext();
  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const isReadinessExam = type === 'readinessExam';
  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('review');

  const didMark = questionProgresses[questionIndex]?.didMark || questionProgresses[questionIndex]?.bookmarked;

  useHotkeys('k', markQuestion, [markQuestion]);

  return (
    <>
      <Container>
        <LeftSection />
        <CenterSection themePalette={themePalette} />
        <RightSection setShowingExhibit={setShowingExhibit} />
      </Container>
      <Subcontainer>
        <Button onClick={launchCalculator}>
          <CalculatorIcon/>
          <ButtonText>
            Calculat<UnderlineSpan>o</UnderlineSpan>r
          </ButtonText>
        </Button>
        <CenteredRow>
          {/* <button onClick={() => setFontScale(-2)}>font -</button>
          <button onClick={() => setFontScale(2)}>font +</button>
          {`scale: ${fontScale}`} */}
          {isReadinessExam && isReview &&
            <SettingsButton
              onClick={toggleSidebar}>

              <SettingsIcon/>
              <ButtonText>
                Settings
              </ButtonText>
            </SettingsButton>
          }
          <Button onClick={markQuestion}>
            <FlagIcon marked={didMark} />
            <ButtonText>
              {didMark ? <>Mar<UnderlineSpan>k</UnderlineSpan>ed</> : <>Mar<UnderlineSpan>k</UnderlineSpan>&nbsp;for Review</>}
            </ButtonText>
          </Button>
        </CenteredRow>
      </Subcontainer>
    </>
  )
}

export default TopBar;
