import {getInObj, getNCLEXSubjectSystemTags} from '../index';

export function formatClassroomMastery(item, masteryType) {
  if (!item) return;

  const {data} = item;
  const queryLookup = [masteryType[0].toUpperCase(), ...masteryType.slice(1)].join('');

  const mastery = data
    && data[`QuestionMasteryBy${queryLookup}${queryLookup === 'Test' ? '' : 'Tag'}Id`]
    && data[`QuestionMasteryBy${queryLookup}${queryLookup === 'Test' ? '' : 'Tag'}Id`].items;

  if (!mastery) return [];

  return mastery.reduce((acc, {masteryLevel, bookmarked}) => ({...acc, [masteryLevel]: acc[masteryLevel] + 1, total: acc.total + 1, bookmarked: acc.bookmarked + bookmarked}), {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    bookmarked: 0,
    total: 0,
  });
}

export function formatClassroomMasteryWithTopic({data}, masteryType) {
  const queryLookup = [masteryType[0].toUpperCase(), ...masteryType.slice(1)].join('');
  const mastery = data
    && data[`QuestionMasteryBy${queryLookup}${queryLookup === 'Test' ? '' : 'Tag'}Id`]
    && data[`QuestionMasteryBy${queryLookup}${queryLookup === 'Test' ? '' : 'Tag'}Id`].items;

  if (!mastery) return [];


  return mastery.reduce((acc, {masteryLevel, bookmarked, question, userIdHashTopicTagId, userIdHashSubjectTagId}) => {
    const subjectTagId = userIdHashSubjectTagId?.split('#')[1];
    const topicTagId = userIdHashTopicTagId?.split('#')[1];
    const topicTag = question?.tags?.items?.find(({tag}) => !!tag.topic)?.tag;
    const bareTags = question?.tags?.items?.map(({tag}) => tag);

    const {system, systemTag} = getNCLEXSubjectSystemTags(bareTags) || {};

    return {
      ...acc,
      [masteryLevel]: acc[masteryLevel] + 1,
      total: acc.total + 1,
      bookmarked: (acc.bookmarked + bookmarked) || 0,
      byTopic: {
        ...acc.byTopic,
        [topicTagId]: {
          ...acc.byTopic[topicTagId],
          [masteryLevel]: (acc.byTopic?.[topicTagId]?.[masteryLevel] || 0) + 1,
          total: (acc.byTopic?.[topicTagId]?.total || 0) + (masteryLevel !== 'none' ? 1 : 0),
          bookmarked: ((acc.byTopic?.[topicTagId]?.bookmarked || 0) + bookmarked) || 0,
          tag: {...topicTag, subjectTagId},
        }
      },
      bySystem: {
        ...acc.bySystem,
        [system]: {
          ...acc.bySystem[system],
          [masteryLevel]: (acc.bySystem?.[system]?.[masteryLevel] || 0) + 1,
          total: (acc.bySystem?.[system]?.total || 0) + (masteryLevel !== 'none' ? 1 : 0),
          bookmarked: ((acc.bySystem?.[system]?.bookmarked || 0) + bookmarked) || 0,
          tag: {...systemTag, subjectTagId},
        }
      }
    }
  }, {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    bookmarked: 0,
    byTopic: {},
    bySystem: {},
    total: 0,
  })
}

export function formatReviewVideoProgress(results) {
  return results.reduce((acc, result) => ([
    ...acc,
    ...getInObj(['data', 'TestProgressByTestId', 'items', 0, 'blockProgresses', 'items'], result, []),
  ]), []);
}

export function formatClassroomVideoProgress(data) {
  return data?.reduce((acc, curr) => {
    if (!curr) return acc;
    return acc + (curr.data?.TestProgressByTestId?.items[0]?.blockProgresses?.items.filter(({status}) => status === 'complete').length || 0);
  }, 0);
}

export function formatClassroomVideoProgressBySubjectByTest(data, key) {
  return data?.reduce((acc, curr) => {
    const testProgress = curr?.data?.TestProgressByTestId?.items[0];
    const completedBlocks = testProgress?.blockProgresses?.items.filter(({status}) => status === 'complete').length || 0;

    if (!completedBlocks) return acc;

    acc.byTest[testProgress.testId] = completedBlocks;
    acc.total = acc.total + completedBlocks;

    return acc;
  }, {byTest: {}, total: 0});
}


export function formatMasteryCardLink(subjectRoute, masteryLevel, topicId) {
  const urlParams = topicId
    ? `?topicId=${encodeURIComponent(topicId)}`
    : '';

  return subjectRoute
    ? `${subjectRoute}/tagged-questions/${masteryLevel}${urlParams}`
    : `tagged-questions/${masteryLevel}`;
}

