import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {useTestNavigatorContext} from '../../../contexts/TestNavigator';
import {TimerProvider} from '../../../contexts/TestTimer';

import {useHistory} from 'react-router-dom';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {handleGoBack, updateQuestionProgressData, updateQuestionProgressState} from '../../../helpers';
import {useReloadBlockerContext} from '@bootcamp/web/src/contexts/ReloadBlocker';

import {trackQuizProgressPerformance} from '@bootcamp/shared/src/requests';
import {SubmitAnatomyCase as SubmitModal} from '../../Modal';
import {useHotkeys} from 'react-hotkeys-hook';
import {LoadingSpinner} from '@bootcamp/web/src/components/Branding';

// import Exhibit from '../../components/Exhibit';
import {FullPageSpinner} from '../../Branding';
import {Column} from '../../Grid';

import TopBar from './TopBar';
import PassageBlock from './PassageBlock';
import Navigator from './Navigator';
import SectionReview from './SectionReview';
import BottomBar from './BottomBar';
import SettingsSidebar from '../../PrometricBank/components/Sidebar';
import Question from '../../USMLEBank/Question';

import {changeIntercomDisplay, getTestBlockQuestions, getQuestionParts, updateCustomTestConfig} from '@bootcamp/shared/src/util';

import theme from '@bootcamp/shared/src/styles/theme';

import debounce from 'lodash.debounce';


const Container = styled(Column)`
  justify-content: center;
  position: relative;
  background: ${({theme}) => theme.colors.brandPalette.royal.dark};
`;
const ContentArea = styled(Column)`
  opacity: ${({loading, template}) => (loading && (template === 'testReview' || template === 'quickReview')) ? 0 : 1};
  transition: opacity .5s;
`;
const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;
const Loader = styled(FullPageSpinner)`
  background: ${({color}) => color};
`;
const PrometricSpinner = ({close}) => {
  useEffect(() => {
    setTimeout(close, 250)
  }, []);
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', background: '#B6B6B4'}}>
      <LoadingSpinner size="36" color="blue" />
    </div>
  )
}
const QuestionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledQuestion = styled(Question)`
  padding: 32px;
`;

const QBank = ({}) => {
  const themePalette = theme.colors.brandPalette.indigo;

  const [highlightMode, setHighlightMode] = useState('add')
  const sidebarState = useState(window.innerWidth > 768);
  const settingsSidebarState = useState(false);
  const {modalDispatch} = useModalContext();
  const history = useHistory();
  const {toggleReloadBlocker} = useReloadBlockerContext();
  const {toggleTimer, timeLeft, questionStartTime, timeUp} = useTimerContext();
  const {searchUserInteractions, DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const hideTimer = searchUserInteractions('hideTimer') === true;
  const prometricDelay = false // searchUserInteractions('prometricDelay') === false;

  const {
    methods: {
      transitionBlock,
      setCurrentIndex,
      autoTagOnSubmit,
      updateQuizProgress,
      saveQuestionProgresses
    },
    variables: {
      test,
      blockIndex,
      testBlockConnections,
      loading,
      template,
      quizProgress,
      skipStartBlock,
      customTestConfig,
      navigationFilter,
      type
    }
  } = useTestNavigatorContext();

  const isReadinessExam = type === 'readinessExam';
  const questionsSoFar = quizProgress.slice(0, blockIndex).reduce((acc, {questions}) => acc + (questions || []).length, 0);
  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses?.findIndex(({current}) => current);
  const testBlock = testBlockConnections?.[blockIndex]?.testBlock;
  const questions = getTestBlockQuestions(testBlock);
  const currentQuestion = questions[questionIndex];
  const isSectionReview = testBlock?.type === 'endBlock';

  const displayEndBlockNavigation = (cancelable=true) => {
    if (!cancelable) {
      return modalDispatch({
        type: 'open',
        component: ({close}) => (
          <SubmitModal
            prometricStyling
            headerText={'No Time Remaining'}
            bodyText={'You have no more time remaining for this block.'}
            confirmConfig={{
              text: 'Proceed',
              onConfirm: async () => {
                if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                setCurrentIndex(blockIndex + 1, 0);
              }
            }}
            cancelConfig={{}}
          />
        )
      })
    }
    modalDispatch({
      type: 'open',
      component: ({close}) => (
        <SubmitModal
          prometricStyling
          headerText={'Warning - You are about to end the block!'}
          bodyText={'If you end this block you will not be able to return.'}
          confirmConfig={{
            text: 'End Block',
            onConfirm: async () => {
              if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
              }

              setCurrentIndex(blockIndex + 1, 0);
            }
          }}
          cancelConfig={{
            text: 'Remain in Block',
            onCancel: close
          }}
        />
      )
    })
  }

  const setQuestionIndex = index => {
    // TODO maybe move this logic into the test navigator...
    const blockTransition = index < 0
      ? 'previous'
      : index === questions.length
      ? 'next'
      : null;

    if (!blockTransition) {
      return setCurrentIndex(blockIndex, index);
    }

    if (isReadinessExam && template !== 'testReview') {
      if (blockTransition === 'next') {
        return displayEndBlockNavigation();
      } else if (blockTransition === 'previous') return;
    }

    transitionBlock(blockTransition);
  };

  const handleNextQuestion = ( ) => {
    if (navigationFilter.key && navigationFilter.value !== 'all') {
      const nextIndex = questionProgresses.findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
        }
        if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
          return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex && (progressObject.didCheck || template === 'testReview' || customTestConfig?.submitted);
        }
        return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
      });
      if (nextIndex !== -1) {
        setQuestionIndex(nextIndex)
      } else {
        transitionBlock('nextFilterActive');
      }
    } else {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
    }
  };

  const handlePreviousQuestion = () => {
    if (navigationFilter.key) {
      const prevIndex = (questions.length - 1) - questionProgresses.slice().reverse().findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > ((questions.length - 1) - questionIndex);
        }
        if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
          return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex) && (progressObject.didCheck || template === 'testReview' || customTestConfig?.submitted);
        }
        return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex)
      });
      if (prevIndex !== -1 && prevIndex !== questions.length) {
        setQuestionIndex(prevIndex);
      } else {
        setQuestionIndex(-1);
      }
    } else {
      const prevIndex = questionIndex - 1;
      setQuestionIndex(prevIndex);
    }
  };

  const handleNavigation = (direction) => {
    if (timeUp) return;
    // navigation handle wrapper for syncing question progress state
    const handler = direction === 'previous' ? handlePreviousQuestion : ultimateQuestionReached ? handleSubmitClick : handleNextQuestion;

    prometricDelay && handler != handleSubmitClick && modalDispatch({
      type: 'open',
      component: PrometricSpinner,
      modalContainerStyle: theme => `
        transition: 0s;
      `
    })

    // only update question progresses for custom tests for now
    if (['tbcSavedBank', 'customTest'].includes(template) && !customTestConfig?.submitted && !questionProgresses?.[questionIndex]?.didCheck) {
      updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
    }

    handler();
  };

  const handleSuspendClick = async () => {
    const modalText = {
      headerText: 'Would you like to pause your review?',
      bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
      confirmText: 'Pause Review',
      cancelText: 'Return to Review'
    };
    modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
      background: transparent;
    `,
      component: ({close}) => {
        return (
          <SubmitModal
            prometricStyling
            headerText={modalText.headerText}
            bodyText={modalText.bodyText}
            confirmConfig={{
              text: modalText.confirmText,
              onConfirm: async () => {
                // if (type === 'readinessExam') {

                //   if (!isReview) {
                //     udpateQuestionTime();
                //     // make sure current question state is saved
                //     await saveAndTrackProgress();
                //     // auto tag questions
                //     await autoTagOnSubmit();
                //   }

                //   toggleReloadBlocker(false);
                //   setTimeout(() => replace(`/${bootcamp}/previous-tests/review?id=${test.id}&testProgressId=${encodeURIComponent(testProgressId || urlTestProgressId)}&testRoute=nclex/next-gen-readiness-exams/qbanks/${testRoute}`), 100);
                //   return;
                // }

                // const testStorageKey = customTestConfig?.config?.testStorageKey;

                // try {
                //   // update custom test config to indicate suspension
                //   const updatedConfig = {
                //     ...customTestConfig,
                //     suspended: false,
                //     timeLeft: null,
                //     submitted: true,
                //   };

                //   await Storage.put(testStorageKey, JSON.stringify(updatedConfig), {contentType: 'application/json' });

                // } catch (error) {
                //   console.log('error marking test as submitted', error);
                // }

                // if (customTestConfig?.config && !customTestConfig.submitted) {
                //   // only track performance if not in tutor mode
                //   if (!tutorMode) {
                //     await trackNCLEXQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                //   }
                //   // auto tag in all custom tests
                //   await autoTagOnSubmit();
                // }
                handleGoBack();
              }
            }}
            cancelConfig={{
              text: modalText.cancelText,
              onCancel: () => {
                close();
              }
            }}
          />
        )
      },
      enableClickClose: false,
    })
  };

  const handleSubmitClick = async () => {
    if (type === 'readinessExam' && template !== 'testReview') {
      if (blockIndex !== 7) {
        return displayEndBlockNavigation();
      }
    }
    const {headerText, bodyText, confirmText, cancelText} = ['quickReview', 'testReview'].includes(template) ?
      {
        headerText: `Nice work! You’ve reached the end of your review.`,
        bodyText: `Would you like to keep reviewing or complete your review?`,
        confirmText: `Complete Review`,
        cancelText: `Keep Reviewing`
      }
      : (customTestConfig?.submitted || !customTestConfig) && type !== 'readinessExam'
        ? {
          headerText: 'Would you like to end your review?',
          bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
          confirmText: 'End Review',
          cancelText: 'Return to Review'
        } : {
          headerText: 'End Test',
          bodyText: 'Do you want to end this test? Once ended you can review your test results from the previous tests page.',
          confirmText: 'End Test',
          cancelText: 'Return to Test'
        }
    return modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
          background: transparent;
        `,
      component: ({close}) => {
        return (
          <SubmitModal
            headerText={headerText}
            bodyText={bodyText}
            prometricStyling
            cancelConfig={{
              text: cancelText,
              onCancel: close,
            }}
            confirmConfig={{
              text: confirmText,
              onConfirm: async () => {
                await updateCustomTestConfig(customTestConfig, {
                  suspended: false,
                  timeLeft: null,
                  submitted: true,
                });

                // make sure current question progress is saved on end
                if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                const shouldAutoTag = ((customTestConfig?.config && !customTestConfig?.submitted) || (isReadinessExam && template !== 'testReview'));
                const shouldTrackPerformance = shouldAutoTag && !customTestConfig?.config?.tutorMode;

                if (shouldAutoTag) {
                  if (shouldTrackPerformance) {
                    await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                  }
                  await autoTagOnSubmit();
                }

                toggleReloadBlocker(false);
                setTimeout(() => handleGoBack(history), 100);
              }
            }}
          />
        )
      },
      enableClickClose: true,
    })
  };

  const filterActive = navigationFilter && navigationFilter.key;
  const nextFilteredIndex = questionProgresses?.findIndex((progressObject, index) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck && index > questionIndex;
    }
    return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
  });

  const nextBlocksMatchFilter = quizProgress.slice(blockIndex + 1).map(({questions}) => questions || []).flat().findIndex((progressObject) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase());
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck;
    }

    return progressObject[navigationFilter.key] === navigationFilter.value
  }) !== -1;

  const ultimateQuestionReached = (questionIndex === questions.length - 1 && blockIndex === testBlockConnections.length - 2) || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter);


  const onClickMasteryButton = (masteryLevel) => {
    const updatedQuestionProgress = updateQuestionProgressState(updateQuizProgress, questionProgresses, questionIndex, {masteryLevel, question: {...currentQuestion, masteryLevel}});
    saveQuestionProgresses([updatedQuestionProgress]);
    handleNavigation('next');
  };

  useEffect(() => {
    toggleTimer(!hideTimer);
  }, [hideTimer]);

  useEffect(() => {
    if (timeUp && isReadinessExam) {
      if (blockIndex === 7) {
        modalDispatch({
          type: 'open',
          component: SubmitModal,
          enableClickClose: false,
          componentProps: {
            headerText: 'No Time Remaining',
            prometricStyling: true,
            bodyText: 'You have no more time remaining.',
            cancelConfig: {
              text: ''
            },
            confirmConfig: {
              text: 'View Test Results',
              colorConfig: themePalette,
              onConfirm: async () => {
                await updateCustomTestConfig(customTestConfig, {
                  suspended: false,
                  timeLeft: null,
                  submitted: true,
                });

                if (['tbcSavedBank', 'customTest'].includes(template) && !questionProgresses?.[questionIndex]?.didCheck) {
                  await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
                }

                const shouldAutoTag = ((customTestConfig?.config && !customTestConfig?.submitted) || (isReadinessExam && template !== 'testReview'));
                const shouldTrackPerformance = shouldAutoTag && !customTestConfig?.config?.tutorMode;

                if (shouldAutoTag) {
                  if (shouldTrackPerformance) {
                    await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                  }
                  await autoTagOnSubmit();
                }

                toggleReloadBlocker(false);
                setTimeout(() => handleGoBack(history), 100);
              },
              shouldRedirect: false,
            }
          },
        })
      } else {
        displayEndBlockNavigation(false);
      }
      return;
    }

    if (customTestConfig?.config?.timed && timeUp) {
      modalDispatch({
        type: 'open',
        component: SubmitModal,
        enableClickClose: false,
        componentProps: {
          headerText: 'No Time Remaining',
          prometricStyling: true,
          bodyText: 'You have no more time remaining. You can review your test results from the previous tests page.',
          cancelConfig: {
            text: ''
          },
          confirmConfig: {
            text: 'View Test Results',
            colorConfig: themePalette,
            onConfirm: async () => {
              await updateCustomTestConfig(customTestConfig, {
                suspended: false,
                timeLeft: null,
                submitted: true,
              });

              if (template === 'customTest' && !questionProgresses?.[questionIndex]?.didCheck) {
                await updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
              }

              const shouldAutoTag = ((customTestConfig?.config && !customTestConfig?.submitted) || (isReadinessExam && template !== 'testReview'));
              const shouldTrackPerformance = shouldAutoTag && !customTestConfig?.config?.tutorMode;

              if (shouldAutoTag) {
                if (shouldTrackPerformance) {
                  await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                }
                await autoTagOnSubmit();
              }

              toggleReloadBlocker(false);
              setTimeout(() => handleGoBack(history), 100);
            },
            shouldRedirect: false,
          }
        },
      })
    };
  }, [timeUp]);

  useHotkeys('left, option+p', () => isSectionReview ? true : handleNavigation('previous'), [handleNavigation]);
  useHotkeys('right, option+n', () => isSectionReview ? true :  handleNavigation('next'), [handleNavigation]);

  const blockRenderer = (testBlockConnection) => {
    const defaultBlocksForTemplate = {
      masteryReview: {testBlock: {type: 'startMasteryReview'}},
      tbcQuestionBank: {testBlock: {type: 'startBlock'}},
      bookmarkedQuestionReview: {testBlock: {type: 'startBookmarkedQuestionReview'}},
      quickReview: {testBlock: {type: 'startQuickReview'}},
      // customTest: {testBlock: {type: 'startQuickReview'}},
    }[template] || {testBlock: {}};

    const {testBlock} = testBlockConnection || defaultBlocksForTemplate;


    switch (testBlock.type) {
      case 'passage':
        const passageTitle = DEFAULT_USER_ID === 'matthewdempsey' ? `Passage` : `Passage ${blockIndex} (Questions ${questionsSoFar + 1}-${questionsSoFar + questions.length})`;
        return (
          <PassageBlock
            testBlock={testBlock}
            settingsSidebarState={settingsSidebarState}
            handleNavigation={handleNavigation}
            finishedOnClick={handleSubmitClick}
            handleSuspendClick={handleSuspendClick}
            passageTitle={passageTitle}
            highlightMode={highlightMode}
            setHighlightMode={setHighlightMode}
          />
        )
      case 'questionSet':
      case 'caseQuestionSet':
        return (
          <QuestionContainer>
            <TopBar highlightMode={highlightMode} settingsSidebarState={settingsSidebarState}/>
            <QuestionWrapper>
              <StyledQuestion themePalette={themePalette} />
            </QuestionWrapper>
            <BottomBar handleNavigation={handleNavigation} finishedOnClick={handleSubmitClick} handleSuspendClick={handleSuspendClick} />
            <SettingsSidebar sidebarState={settingsSidebarState} themePalette={themePalette} hide={['questions']} />
          </QuestionContainer>
        )
      case 'endBlock':
        return (
          <QuestionContainer>
            <TopBar highlightMode={highlightMode} />
            <SectionReview />
            <BottomBar settingsSidebarState={settingsSidebarState} navigatorComponent={props => <Navigator {...props} />} handleNavigation={handleNavigation} finishedOnClick={handleSubmitClick} handleSuspendClick={handleSuspendClick} />
            <SettingsSidebar sidebarState={settingsSidebarState} themePalette={themePalette} />
          </QuestionContainer>
        );
      default:
        return null;
    }
  }

  const examComponent = blockRenderer(testBlockConnections[blockIndex]);

  const updateSidebarState = debounce(event => {
    const setSidebarState = sidebarState[1];
    setSidebarState(!(window.innerWidth < 768));
  }, 150);

  useEffect(() => {
    window.addEventListener('resize', updateSidebarState);
    return () => window.removeEventListener('resize', updateSidebarState);
  }, []);

  useEffect(() => {

    // NOTE annoyingly intercom launcher classname / dom element hierarchy varies from production to staging/local
    // changing the styles for the .intercom-launcher class doesn't work on staging/local but should work in production
    // (it's what we use in the Qbank component to hide the launcher on mount)
    changeIntercomDisplay('', '.intercom-launcher', ['bottom: 64px !important;']);

    return () => {
      changeIntercomDisplay('', '.intercom-launcher', ['bottom: 20px !important;']);
    }
  }, []);

  const width = window.innerWidth;

  return (
    <Container themePalette={themePalette} onClick={e => {
      const setSidebarState = sidebarState[1];
      if (width <= 1200) setSidebarState(false);
    }}>
      <Loader color={themePalette.dark} active={loading && (template === 'testReview' || skipStartBlock)}/>
      <ContentArea children={examComponent} loading={loading} template={template} />
    </Container>
  );
}

const WrappedQbank = props => {
  const {
    variables: {
      quizProgress,
      blockIndex,
    }
  } = useTestNavigatorContext();

  const {questions: questionProgresses} = quizProgress[blockIndex] || {questions: []};
  const questionIndex = questionProgresses?.findIndex(({current}) => current);

  return (
    <TimerProvider timeLimit={false} stopwatch={true} autoStart disabled={false} questionIndex={questionIndex}>
      <QBank {...props} />
    </TimerProvider>
  )
}

export default WrappedQbank;
