import React from 'react';
import {useHistory} from 'react-router-dom';

import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import {LoadingSpinner} from '../../../components/Branding';
import Container, {Wrapper as PageWrapper} from '../PageContainer';

import {ConfigProvider, useConfigState} from './context';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';

import Details from './components/Details';
import Score from './components/Score';
import Subject from './components/Subject';
import Question from './components/Question';
import Time from './components/Time';

import {Element as FAQElement} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';

import {H1} from '../../../components/Typography/next';

import {Card, Heading, Title} from './components/shared';

import styled from 'styled-components';
import useDocumentTitle from '@rehooks/document-title';


const StyledContainer = styled(Container)`
  ${({theme}) => theme.mediaQueries.tablet} {
    ${PageWrapper} {
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
const PageHeader = styled(H1)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin: ${({theme}) => theme.layouts.spacing.s} 0 ${({theme}) => theme.layouts.spacing.m};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const Loader = styled.div`
  position: absolute;
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;
const ElementWrapper = styled.div`
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0px 16px;
  }
`;
const StyledCard = styled(Card)`
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  }
`;

const LoadingWrapper = ({children}) => {
  const config  = useConfigState();
  const loading = !Object.keys(config).length;

  return (
    <StyledContainer>
      {loading
        ? <Loader>
            <LoadingSpinner active size={22}/>
          </Loader>
        : children
      }
    </StyledContainer>
  );
}

const Header = () => {
  const config  = useConfigState();
  const {DEFAULT_USER_ID, bootcamp, searchUserInteractions} = useUserDataContext();
  const savedTests = searchUserInteractions(`${DEFAULT_USER_ID}_${bootcamp}_savedTests`);
  const attempt = config?.config && savedTests?.testList ? savedTests.testList.findIndex(storageKey => storageKey === config.config.testStorageKey) + 1 : '...';
  const testTitle = config?.config?.customTitle || `Previous Test #${attempt}`;
  return (
    <PageHeader>{testTitle}</PageHeader>
  );
}

const CustomBreadcrumbs = () => {
  const history = useHistory();
  const config  = useConfigState();
  const {DEFAULT_USER_ID, bootcamp, searchUserInteractions} = useUserDataContext();
  const savedTests = searchUserInteractions(`${DEFAULT_USER_ID}_${bootcamp}_savedTests`);
  const attempt = config?.config && savedTests?.testList ? savedTests.testList.findIndex(storageKey => storageKey === config.config.testStorageKey) + 1 : '...';
  const testTitle = config?.config?.customTitle || `Previous Test #${attempt}`;
  return (
    <Breadcrumbs history={history} customActiveTitle={testTitle} />
  )
}

const faqItems = [
  {title: "How can I improve my score?", text: "The best way to improve your score is to make sure you are reading through each explanation thoroughly - even for the questions you got correct. Review the Subject Breakdown table above and focus on subjects marked with a red icon - that's where you can improve the most!"},
];


const CustomTestReview = () => {
  const {config} = useBootcampConfigContext();
  useDocumentTitle(`${config.meta.siteTitle} | Test Review`)
  return (
    <ConfigProvider>
      <LoadingWrapper>
        <ElementWrapper>
          <CustomBreadcrumbs/>
          <Header/>
        </ElementWrapper>
        <Wrapper>
          <ElementWrapper>
            <StyledCard>
              <Details/>
              <Score/>
            </StyledCard>
          </ElementWrapper>
          <Subject/>
          <Time/>
          <Question/>
          <ElementWrapper>
            <Heading>
              <Title>FAQs</Title>
            </Heading>
            {faqItems.map(({ title, text }) => <FAQElement key={title} title={title} text={text} />)}
          </ElementWrapper>
        </Wrapper>
      </LoadingWrapper>
    </ConfigProvider>
  );
};

CustomTestReview.defaultProps = {};
CustomTestReview.propTypes = {};

export default CustomTestReview;