import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import IconButton from './IconButton';
import TextButton from './TextButton';
import styled from 'styled-components';

import { LoadingSpinner } from '..';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';

import baseTheme from '@bootcamp/shared/src/styles/theme';


function getButtonSizeStyles(size, theme) {
  const {font} = theme;

  switch (size) {
    case 'small':
      return `
        padding: 12px 16px;
        font-size: ${font.size.medium};
        line-height: ${font.height.small2};
      `;
    case 'medium':
      return `
        padding: 15px 16px;
        font-size: ${font.size.medium};
        line-height: ${font.height.smaller};
      `;
    case 'large':
      return `
        padding: 19px 16px;
        font-size: ${font.size.regular};
        line-height: ${font.height.smaller};
        height: 56px;
        min-width: 64px;
      `;
    default:
      return '';
  }
}

function getButtonTypeStyles(type, color, colorConfig, theme) {
  const {colors: {brandPalette, neutralsPalette}, overlays: {opacity}} = theme;

  const primaryColor = colorConfig.default || brandPalette[color].default;
  const primaryDark = colorConfig.dark || brandPalette[color].dark;
  const primaryHover = colorConfig.hover || brandPalette[color].hover;
  const ghostHover = colorConfig.light || brandPalette[color].light;

  switch (type) {
    case 'primary':
      return `
        transition: all 100ms ease;
        background: ${primaryColor};
        box-shadow: 0px 2px 0px ${primaryDark};

        &:hover {
          transform: translateY(-1px);
          background: ${primaryHover};
        }

        &:disabled {
          transform: none;
          cursor: not-allowed;
          box-shadow: none;
          background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceSecondary : neutralsPalette.lightGrey};
          opacity: ${theme.darkModeEnabled ? 0.5 : 1};

          &:hover {
            background: ${theme.darkModeEnabled ? theme.colors.darkModePalette.surfaceTertiary : neutralsPalette.lightGrey};

          }
          box-shadow: none;
        }

        svg > path {
          fill: ${baseTheme.colors.neutralsPalette.white};
        }
      `;
    case 'secondary':
      return `
        transition: all 100ms ease;
        background: ${theme.overlays.opacity.light._100};
        border: .5px solid ${theme.overlays.opacity.light._50};

        &:hover {
          background: ${opacity.light._200};
        }

        &:disabled {
          transform: none;
          cursor: not-allowed;
          opacity: 0.5;
          box-shadow: none;
        }
      `;
    case 'ghost':
      return `
        transition: all 100ms ease;
        border: 1px solid ${primaryColor};
        color: ${primaryColor};
        background: transparent;

        &:hover {
          background: ${ghostHover};
        }

        &:disabled {
          transform: none;
          cursor: not-allowed;
          box-shadow: none;
          border: 1px solid ${neutralsPalette.extraLight};
          color: ${neutralsPalette.light};
        }
      `;
      case 'ghostWhite':
        return `
          transition: all 100ms ease;
          border: 1px solid white;
          color: white;
          background: transparent;

          &:hover {
            background: ${opacity.light._100};
          }

          &:disabled {
            transform: none;
            cursor: not-allowed;
            box-shadow: none;
            border: 1px solid ${opacity.light._300};
            color: ${opacity.light._300};
          }
        `;
    default:
      return `
        transition: all 100ms ease;
        background: ${primaryColor};
        box-shadow: 0px 2px 0px ${primaryDark};

        &:hover {
          transform: translateY(-1px);
          box-shadow: ${theme.elevation.shadow.medium};
          background: ${primaryHover};
        }

        &:disabled {
          transform: none;
          cursor: not-allowed;
          box-shadow: none;
          background: ${neutralsPalette.light};
        }

        svg > path {
          fill: ${baseTheme.colors.neutralsPalette.white};
        }
      `;
  }
}

const Container = styled.button`
  display: flex;
  flex-direction: ${({iconPosition}) => (iconPosition === 'top' || iconPosition === 'bottom') ? 'column' : 'row'};
  align-items: center;
  justify-content: center;
  border-radius: ${({round}) => round ? '64px' : '8px'};
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  border: none;

  font-family: 'proxima-nova';

  width: ${({fixed}) => fixed ? '100%' : 'auto'};
  font-weight: ${({theme}) => theme.font.weight.semibold};
  color: ${baseTheme.colors.neutralsPalette.white};

  &:focus {
    outline: none;
  }

  ${({size, theme}) => getButtonSizeStyles(size, theme)}
  ${({buttonType, color, theme, colorConfig}) => getButtonTypeStyles(buttonType, color, colorConfig, theme)}

  ${({theme}) => theme.mediaQueries.mobileL} {
    font-size: ${({theme}) => theme.font.size.small};
    line-height: ${({theme}) => theme.font.weight.small2};
  }
`;

const Wrapper = styled.span`
`;
const IconWrapper = styled.span`
  display: flex;

  ${({position, addSpacing, theme: {layouts: {spacing}}}) => addSpacing && `
    ${position === 'left'
      ? `margin-right: ${spacing.s};`
      : position === 'right'
      ? `margin-left: ${spacing.s};`
      : position === 'top'
      ? `margin-bottom: 3px;`
      : position === 'bottom'
      ? `margin-top: 3px;`
      : ''
    }
  `}
`;
const LinkWrapper = styled(Link)`
  text-decoration: none;
  width: 100%;
  margin: 0;
`;

const renderIcon = (icon, size, position, addSpacing) => {
  const Icon = icon;

  return (
    <IconWrapper position={position} addSpacing={addSpacing}>
      <Icon size={size} width={size} height={size}/>
    </IconWrapper>
  );
};

const Button = ({className, size, type, color, colorConfig, fixed, children, iconConfig, disabled, onClick, buttonProps, loading, round}) => {
  const {position: iconPosition, size: iconSize, icon} = iconConfig;
  const addIconSpacing = children && children.length > 0;

  return (
    <Container className={className} iconPosition={iconPosition} size={size} buttonType={type} color={color} colorConfig={colorConfig} fixed={fixed} disabled={disabled} onClick={onClick} round={round} {...buttonProps}>
      {icon && (iconPosition === 'left' || iconPosition === 'top') && renderIcon(icon, iconSize, iconPosition, addIconSpacing)}
      <Wrapper>
        {loading ? <LoadingSpinner size={14} active/> : children}
      </Wrapper>
      {icon && (iconPosition === 'right' ||  iconPosition === 'bottom') && renderIcon(icon, iconSize, iconPosition, addIconSpacing)}
    </Container>
  );
}

export const UpgradeButton = styled((props) => <Button iconConfig={{position: 'left',  icon: Badge, size: 16}} {...props}/>)`
  background: ${({theme}) => theme.colors.neutralsPalette.light};
  box-shadow: none;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};

  svg > path {
    fill: ${({theme}) => theme.colors.neutralsPalette.grey};
  }

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  }
`;


Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'ghostWhite']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red', 'pink', 'indigo']),
  colorConfig: PropTypes.shape({
    default: PropTypes.string,
    hover: PropTypes.string,
  }),
  iconConfig: PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    icon: PropTypes.object,
    size: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fixed: PropTypes.bool,
  round: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  size: 'large',
  color: 'royal',
  fixed: false,
  iconConfig: {
    position: 'left',
    icon: null,
    size: 16
  },
  disabled: false,
  colorConfig: {},
  onClick: () => true,
  buttonProps: {},
  round: false,
};

const linkButtonTypes = {
  text: TextButton,
  icon: IconButton,
  default: Button,
};

// purposfully not passing onClick handler to button if using LinkButton to prevent
// unintended behavior
const LinkButton = ({className, to, buttonType, onClick, ...buttonProps}) => {
  const ButtonComponent = linkButtonTypes[buttonType];

  return (
    <LinkWrapper className={className} to={to}>
      <ButtonComponent {...buttonProps}/>
    </LinkWrapper>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(['text', 'icon', 'default']),
  ...Button.propTypes,
};

LinkButton.defaultProps = {
  buttonType: 'default',
};

export {Button, LinkButton, IconButton, TextButton};
