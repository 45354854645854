import API from '@aws-amplify/api';
import {handleError} from './error';

import {customQueries, customMutations} from '../gql';

export async function createUserInteraction (input) {
  try {
    return await API.graphql({query: customMutations.createUserInteraction, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (e) {
    handleError('creating user interaction', e)
  }
}
export async function updateUserInteraction (input) {
  try {
    return await API.graphql({query: customMutations.updateUserInteraction, variables: {input}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (e) {
    handleError('updating user interaction', e)
  }
}

export async function fetchUserInteraction (id) {
  try {
    return await API.graphql({query: customQueries.getUserInteraction, variables: {id}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (e) {
    handleError('creating user interaction', e)
  }
}

export async function listUserInteractions(userId) {
  try {
    return await API.graphql({query: customQueries.listUserInteractionsByUserId, variables: {userId}, authMode: 'AMAZON_COGNITO_USER_POOLS'});
  } catch (e) {
    handleError('listing user interaction', e)
  }
}
