import React from 'react';
import styled from 'styled-components';

import NewReviewingMasteryIcon from '@bootcamp/shared/src/assets/icons/MasteryIcon/reviewing-new.svg';
import NewLearningMasteryIcon from '@bootcamp/shared/src/assets/icons/MasteryIcon/learning-new.svg';
import NewMasteredMasteryIcon from '@bootcamp/shared/src/assets/icons/MasteryIcon/mastered-new.svg';
import UntaggedMasteryIcon from '@bootcamp/shared/src/assets/icons/MasteryIcon/untagged.svg';
import {Tag} from '@styled-icons/feather/Tag';
import {Bookmark} from '@styled-icons/feather/Bookmark';

import SquaredReviewingMasteryIcon from '@bootcamp/shared/src/assets/icons/SquaredMasteryIcon/MasteryIcon/reviewing.png';
import SquaredLearningMasteryIcon from '@bootcamp/shared/src/assets/icons/SquaredMasteryIcon/MasteryIcon/learning.png';
import SquaredMasteredMasteryIcon from '@bootcamp/shared/src/assets/icons/SquaredMasteryIcon/MasteryIcon/mastered.png';
import SquaredUntaggedMasteryIcon from '@bootcamp/shared/src/assets/icons/SquaredMasteryIcon/MasteryIcon/untagged.png';
import SquaredUntaggedSelectedMasteryIcon from '@bootcamp/shared/src/assets/icons/SquaredMasteryIcon/MasteryIcon/untagged-selected.png';
import SquaredBookmarkedMasteryIcon from '@bootcamp/shared/src/assets/icons/SquaredMasteryIcon/MasteryIcon/bookmarked.png';

const masteryLevelToIcon = {
  learning: NewLearningMasteryIcon,
  reviewing: NewReviewingMasteryIcon,
  mastered: NewMasteredMasteryIcon,
  untagged: UntaggedMasteryIcon,
  bookmarked: Bookmark,
}

const squaredMasteryLevelToIcon = {
  learning: SquaredLearningMasteryIcon,
  reviewing: SquaredReviewingMasteryIcon,
  mastered: SquaredMasteredMasteryIcon,
  untagged: SquaredUntaggedMasteryIcon,
  none: SquaredUntaggedMasteryIcon,
  untaggedSelected: SquaredUntaggedSelectedMasteryIcon,
  marked: SquaredBookmarkedMasteryIcon,
}

const MasteryIconWrapper = styled.img`
  width: 64px;
  height: 64px;
`;

const BareIcon = styled.div`
  display: flex;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
  min-width: 64px;
  height: 64px;
  padding: 4px;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 10px;
`;
const BareIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  color: white;
  background: ${({theme, color}) => color || theme.colors.brandPalette.royal.gradient};
  border-radius: 100px;
  width: 100%;
  height: 100%;

  svg {
    width: 28px;
  }
`;

const MasteryIcon = ({color, className, level, squared}) => {
  const icon = squared
    ? squaredMasteryLevelToIcon[level]
    : masteryLevelToIcon[level];

  return level === 'all'
  ? <BareIcon>
      <BareIconWrapper color={color}>
        <Tag/>
      </BareIconWrapper>
    </BareIcon>
  : level === 'bookmarked'
  ? <BareIcon>
      <BareIconWrapper color={color}>
        <Bookmark/>
      </BareIconWrapper>
    </BareIcon>
  : <MasteryIconWrapper className={className} src={icon} />
};

MasteryIcon.defaultProps = {
  level: 'mastered',
  squared: false
};
MasteryIcon.propTypes = {};

export default MasteryIcon;
