import React from 'react';
import styled from 'styled-components';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {GutterColumn} from '@bootcamp/web/src/components/Spacing';
import {TableContainer, Table} from './shared';
import Completed from '../assets/ReviewTable/Completed.png'
import Marked from '../assets/ReviewTable/Marked.png'
import Incomplete from '../assets/ReviewTable/Incomplete.png';
import correctIcon from '@bootcamp/shared/src/assets/nclex/correct.png';
import incorrectIcon from '@bootcamp/shared/src/assets/nclex/incorrect.png';
import MasteryIcon from '@bootcamp/web/src/bootcamps/components/MasteryIcon'

const Mastery = styled(MasteryIcon)`
  margin-left: auto;
  height: 20px;
  width: 20px;
`
const Container = styled(GutterColumn)`
  margin: 20px auto;
`;

const ReviewIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Row = styled.tr`
  &:hover {
    background: rgb(250, 250, 250);
  }
`
const EndBlock = ({goToQuestion, sectionWindow=[]}) => {
  const {variables: {quizProgress, customTestConfig}} = useTestNavigatorContext();
  const showReviewDetails = customTestConfig?.submitted
  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Marked</th>
            <th>Completed</th>
            <th>Skipped</th>
          </tr>
        </tbody>
      </Table>
      <TableContainer>
        <Table>
          <tbody>
            {quizProgress.reduce((acc, blockProgress, blockIndex) => {
              if (!blockProgress.questions || blockIndex < sectionWindow[0] || blockIndex > sectionWindow[1]) return acc;
              return [
                ...acc,
                ...blockProgress.questions
              .map(({didMark, didSkip, selectedAnswerIndex, time, didSelectCorrectAnswer, masteryLevel}, index) => {
                const questionIndex = sectionWindow.length > 0 ? quizProgress.slice(sectionWindow[0], blockIndex).map(({questions}) => questions || []).flat().length + index : acc.length + index;
                const reviewIconSrc = showReviewDetails && didSelectCorrectAnswer ? correctIcon : showReviewDetails ? incorrectIcon : didMark ? Marked : didSkip && !didMark ? Incomplete : selectedAnswerIndex !== -1 ? Completed : Incomplete
                return (
                  <Row key={`row-${blockIndex}-${questionIndex}`} onClick={() => goToQuestion(blockIndex, index)}>
                    <td><Flexbox><ReviewIcon src={reviewIconSrc} />Question {questionIndex + 1} {showReviewDetails ? <Mastery level={['learning', 'reviewing', 'mastered']?.includes(masteryLevel) ? masteryLevel : 'untaggedSelected'} squared /> : null}</Flexbox></td>
                    <td>{didMark ? 'Yes' : ''}</td>
                    <td>{selectedAnswerIndex !== -1 ? 'Yes' : ''}</td>
                    <td>{didSkip && !didMark ? 'Yes' : ''}</td>
                  </Row>
                )})
              ];
            }, [])}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  )
};

export default EndBlock;
