import React from 'react';
import Calculator from './Calculator';
import TICalculator from './TICalculator';
import PeriodicTable from './PeriodicTable';
import PropTypes from 'prop-types';
import useHotkeys from '@reecelucas/react-use-hotkeys';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

const typeMap = {
  'calculator': Calculator,
  'ticalc': TICalculator,
  'periodic': PeriodicTable
}

const Exhibit = ({type, onCloseExhibit, defaultPosition}) => {
  const {variables: {template}} = useTestNavigatorContext() || {variables: {template: ''}};
  const SelectedExhibit = typeMap[type];

  useHotkeys('Escape', onCloseExhibit, [onCloseExhibit]);

  return <SelectedExhibit onCloseExhibit={onCloseExhibit} defaultPosition={defaultPosition} template={template} />
}

Exhibit.propTypes = {
  type: PropTypes.oneOf(['calculator', 'periodic'])
};

export default Exhibit;
