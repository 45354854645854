import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {useFormContext} from 'react-hook-form';
import moment from 'moment';
import {Column, Row} from '../../../../Grid';
import {H5, H6} from '../../../../Typography';

import {Eye} from '@styled-icons/evaicons-solid/Eye';
import {EyeOff} from '@styled-icons/evaicons-solid/EyeOff';
import {AlertCircle} from '@styled-icons/evaicons-solid/AlertCircle';
import {CheckmarkCircle2} from '@styled-icons/evaicons-solid/CheckmarkCircle2';

const Container = styled.label`
  width: 100%;
  display: block;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;

const Info = styled(Row)`
  justify-content: space-between;
  height: auto;
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const Label = styled(H5)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;

const AdditionalInfo = styled(H5)`
  width: 100%;
  flex-shrink: 1;
  text-align: right;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;

export const Input = styled.input`
  width: 100%;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  font-size: ${({theme}) => theme.font.size.regular};
  line-height: ${({theme}) => theme.font.height.regular};
  padding: 10.5px 48px 10.5px ${({theme}) => theme.layouts.spacing.l};
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  transition: all 100ms;


  &:focus {
    outline: none;
    background: ${({theme}) => theme.colors.brandPalette.indigo.light};
    border: 1px solid ${({theme}) => theme.colors.brandPalette.indigo.default};
  }

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey}
  }

  ${({error}) => error && css`
    border: 1px solid ${({theme}) => theme.colors.interfacePalette.red.default};
    background: ${({theme}) => theme.colors.interfacePalette.red.light};
  `}

  &:disabled {
    background: ${({theme}) => theme.colors.neutralsPalette.light};
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
    user-select: none;
    cursor: not-allowed;
    border: none;

    &:hover {
      border: none;
    }
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const IconContainer = styled(Column)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  width: auto;
  justify-content: center;
  padding: 10.5px ${({theme}) => theme.layouts.spacing.l} 10.5px 0px;
  cursor: pointer;
`;

const ErrorIcon = styled(AlertCircle).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.interfacePalette.red.default};
`;
const CompleteIcon = styled(CheckmarkCircle2).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.interfacePalette.green.default};
`;

const ErrorContainer = styled(H6)`
  color: ${({theme}) => theme.colors.interfacePalette.red.default};
  position: absolute;
  margin-top: ${({theme}) => theme.layouts.spacing.xs};
`;

export const InputDecorator = ({className, name, label, additionalInfo, children, error, complete, hideInfo=false}) => {
  return (
    <Container className={className} htmlFor={name}>
      {!hideInfo &&
        <Info>
          <Label>{label}</Label>
          {additionalInfo && <AdditionalInfo children={additionalInfo} />}
        </Info>
      }
      <InputWrapper>
        {children}
        <IconContainer>
          {error && <ErrorIcon />}
          {complete && !error && <CompleteIcon />}
        </IconContainer>
      </InputWrapper>
      {error && <ErrorContainer children={error.message} />}
    </Container>
  );
}

export const Text = ({name, label, additionalInfo, inputProps, validation}) => {
  const {register, watch, errors, clearError} = useFormContext();
  const error = errors[name];

  return (
    <Container htmlFor={name}>
      <Info>
        <Label>{label}</Label>
        {additionalInfo && <AdditionalInfo children={additionalInfo} />}
      </Info>
      <InputWrapper>
        <Input
          id={name}
          name={name}
          ref={register(validation(watch))}
          onChange={event => {
            clearError(event.target.name);
          }}
          {...inputProps}
          error={error}
        />
        <IconContainer>
          {error && <ErrorIcon />}
        </IconContainer>
      </InputWrapper>
      {error && <ErrorContainer children={error.message} />}
    </Container>
  )
}

export const DatePicker = ({name, label, additionalInfo, inputProps, validation}) => {
  const {register, watch, errors, clearError} = useFormContext();
  const error = errors[name];

  return (
    <Container htmlFor={name}>
      <Info>
        <Label>{label}</Label>
        {additionalInfo && <AdditionalInfo children={additionalInfo} />}
      </Info>
      <InputWrapper>
        <Input
          id={name}
          type={'date'}
          min={moment().format('YYYY-MM-DD')}
          name={name}
          ref={register(validation(watch))}
          onChange={event => {
            clearError(event.target.name);
          }}
          {...inputProps}
          error={error}
        />
        <IconContainer>
          {error && <ErrorIcon />}
        </IconContainer>
      </InputWrapper>
      {error && <ErrorContainer children={error.message} />}
    </Container>
  )
}

Text.defaultProps = {
  validation: () => ({}),
};


const RevealedIcon = styled(Eye).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.brandPalette.indigo.default};
`;

const HiddenIcon = styled(EyeOff).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  &:hover {
    color: ${({theme}) => theme.colors.brandPalette.indigo.default};
  }
`;

export const Password = ({name, label, additionalInfo, inputProps, validation}) => {
  const [revealed, setRevealed] = useState(false);
  const {register, watch, errors, clearError} = useFormContext();
  const error = errors[name];

  return (
    <Container htmlFor={name}>
      <Info>
        <Label>{label}</Label>
        {additionalInfo && <AdditionalInfo children={additionalInfo} />}
      </Info>
      <InputWrapper>
        <Input
          id={name}
          ref={register(validation(watch))}
          name={name}
          {...inputProps}
          onChange={event => clearError(event.target.name)}
          onBlur={() => setRevealed(false)}
          type={revealed ? 'text' : 'password'}
          error={error}
        />
        <IconContainer onClick={() => setRevealed(!revealed)}>
          {revealed ? <RevealedIcon /> : <HiddenIcon />}
        </IconContainer>
      </InputWrapper>
      {error && <ErrorContainer children={error.message} />}
    </Container>
  )
}


Password.defaultProps = {
  validation: () => ({}),
};
