import React from 'react';
import styled from 'styled-components';
import useHotkeys from "@reecelucas/react-use-hotkeys";
import {Button} from '@bootcamp/web/src/components/Branding/Buttons';
import {Tag} from '@styled-icons/fluentui-system-filled/Tag';

const MasteryButtonWrapper = styled.div`
`;

export const MasteryButton = props => {
  useHotkeys(props.hotkey, props.onClick, [props.onClick]);
  useHotkeys('ArrowLeft', props.handlePreviousQuestion ? props.handlePreviousQuestion : () => true, [props.handlePreviousQuestion]);
  useHotkeys('ArrowRight', props.handleNextQuestion ? props.handleNextQuestion : () => true, [props.handleNextQuestion]);
  return (
    <MasteryButtonWrapper>
      <Button type="primary" {...props} />
    </MasteryButtonWrapper>
  );
}

const MasteryButtonChild = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
`;

const MasteryButtonChildText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 8px;
  ${({theme}) => theme.mediaQueries.custom(1080)} {
    display: none;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    display: none;
  }
`;

const TagIcon = styled(Tag).attrs(props => ({size: 16}))`
  stroke-width: 3;
  color: white;
`;

export const MasteryButtonText = ({children, empty=false}) => {
  return (
    <MasteryButtonChild>
      <TagIcon />
      {!empty && (
        <MasteryButtonChildText>
          {children}
        </MasteryButtonChildText>
      )}
    </MasteryButtonChild>
  )
}
