import React from 'react';
import {useConfigState} from '../context';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import {Row, Column, Label} from './shared';
import {Label3} from '@bootcamp/web/src/components/Typography/next';

import {CalendarLtr} from '@styled-icons/fluentui-system-filled/CalendarLtr'
import {Clock} from '@styled-icons/fluentui-system-filled/Clock';
import {Settings} from '@styled-icons/fluentui-system-filled/Settings';
import {TaskListSquareLtr} from '@styled-icons/fluentui-system-filled/TaskListSquareLtr';
import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {RoundButton} from '@bootcamp/web/src/components/UI';
import moment from 'moment';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

const Container = styled(Row)`
  padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`};
  border-bottom: 1px solid ${({theme}) => theme.colors.borderPalette.primary};
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const Header = styled(Label)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const PillContainer = styled(Row)`
  flex-flow: row wrap;
  gap: 8px;
`;
const ActionWrapper = styled(Row)`
  align-items: center;
  justify-content: flex-end;
`;
const Item = styled(Label3)`
  display: flex;
  padding: 4px 8px;
  color: ${({theme}) => theme.colors.typographyPalette.primary};
  border: 1px solid ${({theme}) => theme.colors.borderPalette.primary};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`;
const Icon = styled.div`
  margin-right: ${({theme}) => theme.layouts.spacing.xs};
  fill: ${({theme}) => theme.colors.typographyPalette.primary};
  width: 14px;
`;
const ReviewButton = styled(RoundButton)`
  width: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: ${({theme}) => theme.layouts.spacing.m};
    width: 100%;
    button {
      width: 100%;
    }
  }
`;

const Details = () => {
  const {bootcamp} = useUserDataContext();
  const {config, isReadinessExam, testRoute, testProgressId} = useConfigState();
  const history = useHistory();
  return (
    <Container>
      <Column>
        <Header>{isReadinessExam ? config.customTitle : 'Score Report'}</Header>
        <PillContainer>
          <Item>
            <Icon as={CalendarLtr} />
            {config?.createdAt && moment(config?.createdAt).format('MMMM Do, YYYY')}
          </Item>
          {!isReadinessExam &&
            <Item>
              <Icon as={Clock} />
              {config?.timed ? 'Timed' : 'Untimed'}
            </Item>
          }
          {!isReadinessExam &&
            <Item>
              <Icon as={Settings} />
              {config?.tutorMode ? 'Tutored' : 'Untutored'}
            </Item>
          }
          <Item>
            <Icon as={TaskListSquareLtr} />
            {config?.testLength} Question{config?.testLength === 1 ? '' : 's'}
          </Item>
        </PillContainer>
      </Column>
      <ActionWrapper>
        <ReviewButton
          small
          IconComponent={<ArrowForwardOutline size={16} />}
          iconPosition={'right'}
          onClick={() => history.push(isReadinessExam ? `/${testRoute}?testProgressId=${encodeURIComponent(testProgressId)}&review=true` : `/${bootcamp}/custom-test?key=${encodeURIComponent(config?.testStorageKey)}&review=true`)}
        >
          Start Review
        </ReviewButton>
      </ActionWrapper>
    </Container>
  );
};

export default Details;