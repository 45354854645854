import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {FlexBox} from '../../../../components/Grid';
import {H4} from '../../../../components/Typography';
import {AnatomyImageViewer} from '../../../../components/AnatomyImageViewer';
import {FullContainerSpinner} from '../../../../components/Branding';
import {ImageNotSupported} from '@styled-icons/material/ImageNotSupported';

import styled, {css} from 'styled-components';

const Container = styled(FlexBox)`
  height: 100%;
  width: 100%;
  position: relative;

  ${({theme, mobileDisplay}) => mobileDisplay ? css`
    display: none;
    ${theme.mediaQueries.tablet} {
      display: flex;
      height: 40vh;
      margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
    }
    ${theme.mediaQueries.mobileL} {
      height: 35vh;
      margin-bottom: 16px;
    }
  ` : css`
    display: flex;
    ${theme.mediaQueries.tablet} {
      display: none;
    }
  `}
`;
const ImageLoadingSpinner = styled(FullContainerSpinner)`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: ${({active}) => active ? 1 : 0};
  transition: opacity .25s;
`;
const Img = styled.img`
  opacity: ${({loaded}) => loaded ? 1 : 0};
  transition: opacity .25s;
`;


const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
  cursor: all-scroll;
  user-select: none;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const NoImageIcon = styled(ImageNotSupported)`
  color: ${({theme}) => theme.overlays.opacity.light._300};
`;
const NoImageText = styled(H4)`
  color: ${({theme}) => theme.overlays.opacity.light._300};
  margin-top: 8px;
  max-width: 214px;
  text-align: center;
`

const CaseImage = ({caseImageProps, caseImageSolutionProps, mobileDisplay}) => {
  const [loading, setLoading] = useState(true);
  const [showingLabeled, setShowingLabeled] = useState(false);
  const {anatomyImage, anatomyImageTransform} = caseImageProps || {};
  const {anatomyImageSolution} = caseImageSolutionProps || {anatomyImageSolution: ''};
  const {scale, translation, s3Key} = JSON.parse(anatomyImageSolution || '{}');

  const [imageUrl, setImageUrl] = useState('');

  const imageViewerProps = !showingLabeled && anatomyImageTransform ? {
    defaultScale: anatomyImageTransform.scale,
    defaultTranslation: anatomyImageTransform.translation,
  } : {
    defaultScale: scale,
    defaultTranslation: translation
  };

  const toggleLabeled = labeled => {
    setLoading(true);
    setShowingLabeled(labeled);
  };

  useEffect(() => {
    if (!anatomyImage) {
      setImageUrl('noImage');
      setLoading(false);
      return;
    }

    const url = `https://dekni9rgkrcu0.cloudfront.net/${showingLabeled ? s3Key : anatomyImage}`;

    if (url === imageUrl) return;

    setLoading(true);
    setImageUrl(url);

  }, [anatomyImage, s3Key, showingLabeled]);

  useEffect(() => {
    setShowingLabeled(false);
  }, [s3Key]);

  return (
    <Container mobileDisplay={mobileDisplay}>
      <ImageLoadingSpinner active={loading} size={24} />
      {anatomyImageTransform ? (
        <AnatomyImageViewer
          key={anatomyImage}
          {...imageViewerProps}
          containerDimensions={anatomyImageTransform.containerDimensions} // We want to preserve the "scaling factor" across both labeled & unlabeled views
          onChange={() => true}
          showingLabeled={showingLabeled}
          setShowingLabeled={toggleLabeled}
          hasLabels={anatomyImageSolution}
        >
          <Img src={imageUrl} onLoad={() => setLoading(false)} loaded={!loading}/>
        </AnatomyImageViewer>
      ) : (
        <Placeholder>
          {imageUrl === 'noImage' && (
            <NoImage>
              <NoImageIcon size={48}/>
              <NoImageText>No image needed for this question.</NoImageText>
            </NoImage>
          )}
        </Placeholder>
      )}
    </Container>
  );
}

CaseImage.propTypes = {
  src: PropTypes.string,
};
CaseImage.defaultProps = {
  src: '',
};

export default CaseImage;
