/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTestFromCache = /* GraphQL */ `
  query GetTestFromCache($id: ID!) {
    getTestFromCache(id: $id) {
      id
      createdAt
      config {
        title
        type
        timeLimit
        status
        questionCount
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      groupsCanRead
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getExam = /* GraphQL */ `
  query GetExam($id: ID!) {
    getExam(id: $id) {
      id
      examComponents {
        items {
          id
          index
          examComponentQuestionId
          questionRevisionId
          extra {
            index
            renderType
            contents
          }
          exam {
            id
            examComponents {
              items {
                id
                index
                examComponentQuestionId
                questionRevisionId
                extra {
                  index
                  renderType
                  contents
                }
                exam {
                  id
                  createdAt
                  title
                  active
                  updatedAt
                }
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            createdAt
            title
            active
            updatedAt
          }
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      title
      active
      updatedAt
    }
  }
`;
export const listExams = /* GraphQL */ `
  query ListExams(
    $filter: ModelExamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        examComponents {
          items {
            id
            index
            examComponentQuestionId
            questionRevisionId
            extra {
              index
              renderType
              contents
            }
            exam {
              id
              examComponents {
                items {
                  id
                  index
                  examComponentQuestionId
                  questionRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              title
              active
              updatedAt
            }
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        title
        active
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExamComponent = /* GraphQL */ `
  query GetExamComponent($id: ID!) {
    getExamComponent(id: $id) {
      id
      index
      examComponentQuestionId
      questionRevisionId
      extra {
        index
        renderType
        contents
      }
      exam {
        id
        examComponents {
          items {
            id
            index
            examComponentQuestionId
            questionRevisionId
            extra {
              index
              renderType
              contents
            }
            exam {
              id
              examComponents {
                items {
                  id
                  index
                  examComponentQuestionId
                  questionRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              title
              active
              updatedAt
            }
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        title
        active
        updatedAt
      }
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listExamComponents = /* GraphQL */ `
  query ListExamComponents(
    $filter: ModelExamComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExamComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        examComponentQuestionId
        questionRevisionId
        extra {
          index
          renderType
          contents
        }
        exam {
          id
          examComponents {
            items {
              id
              index
              examComponentQuestionId
              questionRevisionId
              extra {
                index
                renderType
                contents
              }
              exam {
                id
                examComponents {
                  nextToken
                }
                createdAt
                title
                active
                updatedAt
              }
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          createdAt
          title
          active
          updatedAt
        }
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTagJoin = /* GraphQL */ `
  query GetTagJoin($id: ID!) {
    getTagJoin(id: $id) {
      id
      createdAt
      updatedAt
      tag {
        id
        test
        subject
        topic
        contentType
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTagJoins = /* GraphQL */ `
  query ListTagJoins(
    $filter: ModelTagJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        tag {
          id
          test
          subject
          topic
          contentType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getContentQueue = /* GraphQL */ `
  query GetContentQueue($name: String!) {
    getContentQueue(name: $name) {
      name
      startDate
      currentIndex
      contentType
      content
      createdAt
      updatedAt
    }
  }
`;
export const listContentQueues = /* GraphQL */ `
  query ListContentQueues(
    $name: String
    $filter: ModelContentQueueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContentQueues(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        startDate
        currentIndex
        contentType
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWordPressUserData = /* GraphQL */ `
  query GetWordPressUserData($wpUserId: Int!) {
    getWordPressUserData(wpUserId: $wpUserId) {
      wpUserId
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWordPressUserDatas = /* GraphQL */ `
  query ListWordPressUserDatas(
    $wpUserId: Int
    $filter: ModelWordPressUserDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWordPressUserDatas(
      wpUserId: $wpUserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        wpUserId
        testProgresses {
          items {
            id
            testId
            userIdHashTestId
            blockProgresses {
              items {
                id
                blockId
                questionProgresses {
                  nextToken
                }
                highlights
                status
                index
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            wordPressUserDataTestProgressesId
            updatedAt
          }
          nextToken
        }
        questionMastery {
          items {
            userIdHashQuestionBaseId
            wpUserId
            masteryLevel
            bookmarked
            questionRevisionId
            questionMasteryQuestionId
            userIdHashTestTagId
            userIdHashSubjectTagId
            userIdHashTopicTagId
            userIdHashTestId
            questionBaseIdHashTestId
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBootcampUserData = /* GraphQL */ `
  query GetBootcampUserData($bootcampUserId: ID!) {
    getBootcampUserData(bootcampUserId: $bootcampUserId) {
      bootcampUserId
      createdAt
      updatedAt
    }
  }
`;
export const listBootcampUserDatas = /* GraphQL */ `
  query ListBootcampUserDatas(
    $bootcampUserId: ID
    $filter: ModelBootcampUserDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBootcampUserDatas(
      bootcampUserId: $bootcampUserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bootcampUserId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionMastery = /* GraphQL */ `
  query GetQuestionMastery($userIdHashQuestionBaseId: ID!) {
    getQuestionMastery(userIdHashQuestionBaseId: $userIdHashQuestionBaseId) {
      userIdHashQuestionBaseId
      wpUserId
      masteryLevel
      bookmarked
      questionRevisionId
      questionMasteryQuestionId
      userIdHashTestTagId
      userIdHashSubjectTagId
      userIdHashTopicTagId
      userIdHashTestId
      questionBaseIdHashTestId
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listQuestionMasterys = /* GraphQL */ `
  query ListQuestionMasterys(
    $userIdHashQuestionBaseId: ID
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestionMasterys(
      userIdHashQuestionBaseId: $userIdHashQuestionBaseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTestProgress = /* GraphQL */ `
  query GetTestProgress($id: ID!) {
    getTestProgress(id: $id) {
      id
      testId
      userIdHashTestId
      blockProgresses {
        items {
          id
          blockId
          questionProgresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          highlights
          status
          index
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      wordPressUserDataTestProgressesId
      updatedAt
    }
  }
`;
export const listTestProgresss = /* GraphQL */ `
  query ListTestProgresss(
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestProgresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testId
        userIdHashTestId
        blockProgresses {
          items {
            id
            blockId
            questionProgresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            highlights
            status
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        wordPressUserDataTestProgressesId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlockProgress = /* GraphQL */ `
  query GetBlockProgress($id: ID!) {
    getBlockProgress(id: $id) {
      id
      blockId
      questionProgresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      highlights
      status
      index
      createdAt
      updatedAt
    }
  }
`;
export const listBlockProgresss = /* GraphQL */ `
  query ListBlockProgresss(
    $filter: ModelBlockProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockProgresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blockId
        questionProgresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        highlights
        status
        index
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionProgress = /* GraphQL */ `
  query GetQuestionProgress($id: ID!) {
    getQuestionProgress(id: $id) {
      id
      selectedAnswerIndex
      didSelectCorrectAnswer
      didSkip
      didMark
      didCheck
      time
      highlights
      masteryLevel
      questionRevisionId
      index
      createdAt
      updatedAt
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listQuestionProgresss = /* GraphQL */ `
  query ListQuestionProgresss(
    $filter: ModelQuestionProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionProgresss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        selectedAnswerIndex
        didSelectCorrectAnswer
        didSkip
        didMark
        didCheck
        time
        highlights
        masteryLevel
        questionRevisionId
        index
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByWpUserId = /* GraphQL */ `
  query QuestionMasteryByWpUserId(
    $wpUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByWpUserId(
      wpUserId: $wpUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByMasteryLevel = /* GraphQL */ `
  query QuestionMasteryByMasteryLevel(
    $wpUserId: ID
    $masteryLevel: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByMasteryLevel(
      wpUserId: $wpUserId
      masteryLevel: $masteryLevel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByQuestionId = /* GraphQL */ `
  query QuestionMasteryByQuestionId(
    $questionMasteryQuestionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByQuestionId(
      questionMasteryQuestionId: $questionMasteryQuestionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByTestTagId = /* GraphQL */ `
  query QuestionMasteryByTestTagId(
    $userIdHashTestTagId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTestTagId(
      userIdHashTestTagId: $userIdHashTestTagId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryBySubjectTagId = /* GraphQL */ `
  query QuestionMasteryBySubjectTagId(
    $userIdHashSubjectTagId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryBySubjectTagId(
      userIdHashSubjectTagId: $userIdHashSubjectTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByTopicTagId = /* GraphQL */ `
  query QuestionMasteryByTopicTagId(
    $userIdHashTopicTagId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTopicTagId(
      userIdHashTopicTagId: $userIdHashTopicTagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByTestId = /* GraphQL */ `
  query QuestionMasteryByTestId(
    $userIdHashTestId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByTestId(
      userIdHashTestId: $userIdHashTestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionMasteryByQuestionBaseIdTestId = /* GraphQL */ `
  query QuestionMasteryByQuestionBaseIdTestId(
    $questionBaseIdHashTestId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionMasteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionMasteryByQuestionBaseIdTestId(
      questionBaseIdHashTestId: $questionBaseIdHashTestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userIdHashQuestionBaseId
        wpUserId
        masteryLevel
        bookmarked
        questionRevisionId
        questionMasteryQuestionId
        userIdHashTestTagId
        userIdHashSubjectTagId
        userIdHashTopicTagId
        userIdHashTestId
        questionBaseIdHashTestId
        createdAt
        updatedAt
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const testProgressByTestId = /* GraphQL */ `
  query TestProgressByTestId(
    $userIdHashTestId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TestProgressByTestId(
      userIdHashTestId: $userIdHashTestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testId
        userIdHashTestId
        blockProgresses {
          items {
            id
            blockId
            questionProgresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            highlights
            status
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        wordPressUserDataTestProgressesId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      idOverride
      testProgresses {
        items {
          id
          testId
          userIdHashTestId
          blockProgresses {
            items {
              id
              blockId
              questionProgresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              highlights
              status
              index
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          wordPressUserDataTestProgressesId
          updatedAt
        }
        nextToken
      }
      questionMastery {
        items {
          userIdHashQuestionBaseId
          wpUserId
          masteryLevel
          bookmarked
          questionRevisionId
          questionMasteryQuestionId
          userIdHashTestTagId
          userIdHashSubjectTagId
          userIdHashTopicTagId
          userIdHashTestId
          questionBaseIdHashTestId
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      memberships {
        items {
          id
          productId
          groups
          startDate
          duration
          status
          userMembershipsId
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        idOverride
        testProgresses {
          items {
            id
            testId
            userIdHashTestId
            blockProgresses {
              items {
                id
                blockId
                questionProgresses {
                  nextToken
                }
                highlights
                status
                index
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            wordPressUserDataTestProgressesId
            updatedAt
          }
          nextToken
        }
        questionMastery {
          items {
            userIdHashQuestionBaseId
            wpUserId
            masteryLevel
            bookmarked
            questionRevisionId
            questionMasteryQuestionId
            userIdHashTestTagId
            userIdHashSubjectTagId
            userIdHashTopicTagId
            userIdHashTestId
            questionBaseIdHashTestId
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        memberships {
          items {
            id
            productId
            groups
            startDate
            duration
            status
            userMembershipsId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      productId
      groups
      startDate
      duration
      status
      userMembershipsId
      type
      createdAt
      updatedAt
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        groups
        startDate
        duration
        status
        userMembershipsId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productId = /* GraphQL */ `
  query ProductId(
    $productId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        groups
        startDate
        duration
        status
        userMembershipsId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserInteraction = /* GraphQL */ `
  query GetUserInteraction($id: ID!) {
    getUserInteraction(id: $id) {
      id
      userId
      value
      createdAt
      updatedAt
    }
  }
`;
export const listUserInteractions = /* GraphQL */ `
  query ListUserInteractions(
    $filter: ModelUserInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInteractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userId = /* GraphQL */ `
  query UserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      createdAt
      config {
        title
        type
        timeLimit
        status
        questionCount
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      groupsCanRead
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        config {
          title
          type
          timeLimit
          status
          questionCount
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groupsCanRead
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestBlockConnection = /* GraphQL */ `
  query GetTestBlockConnection($id: ID!) {
    getTestBlockConnection(id: $id) {
      id
      index
      testBlockConnectionTestBlockId
      createdAt
      updatedAt
      test {
        id
        createdAt
        config {
          title
          type
          timeLimit
          status
          questionCount
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        groupsCanRead
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      testBlock {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listTestBlockConnections = /* GraphQL */ `
  query ListTestBlockConnections(
    $filter: ModelTestBlockConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestBlockConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        testBlockConnectionTestBlockId
        createdAt
        updatedAt
        test {
          id
          createdAt
          config {
            title
            type
            timeLimit
            status
            questionCount
          }
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          groupsCanRead
          updatedAt
          blockConnections {
            items {
              id
              index
              testBlockConnectionTestBlockId
              createdAt
              updatedAt
              test {
                id
                createdAt
                config {
                  title
                  type
                  timeLimit
                  status
                  questionCount
                }
                tags {
                  nextToken
                }
                groupsCanRead
                updatedAt
                blockConnections {
                  nextToken
                }
              }
              testBlock {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        testBlock {
          id
          title
          type
          components {
            index
            renderType
            contents
          }
          createdAt
          updatedAt
          blockConnections {
            items {
              id
              index
              testBlockConnectionTestBlockId
              createdAt
              updatedAt
              test {
                id
                createdAt
                config {
                  title
                  type
                  timeLimit
                  status
                  questionCount
                }
                tags {
                  nextToken
                }
                groupsCanRead
                updatedAt
                blockConnections {
                  nextToken
                }
              }
              testBlock {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
            }
            nextToken
          }
          questionConnections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTestBlock = /* GraphQL */ `
  query GetTestBlock($id: ID!) {
    getTestBlock(id: $id) {
      id
      title
      type
      components {
        index
        renderType
        contents
      }
      createdAt
      updatedAt
      blockConnections {
        items {
          id
          index
          testBlockConnectionTestBlockId
          createdAt
          updatedAt
          test {
            id
            createdAt
            config {
              title
              type
              timeLimit
              status
              questionCount
            }
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            groupsCanRead
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          testBlock {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      questionConnections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listTestBlocks = /* GraphQL */ `
  query ListTestBlocks(
    $id: ID
    $filter: ModelTestBlockFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTestBlocks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionConnection = /* GraphQL */ `
  query GetQuestionConnection($id: ID!) {
    getQuestionConnection(id: $id) {
      id
      index
      questionConnectionQuestionId
      pinnedRevisionId
      createdAt
      updatedAt
      block {
        id
        title
        type
        components {
          index
          renderType
          contents
        }
        createdAt
        updatedAt
        blockConnections {
          items {
            id
            index
            testBlockConnectionTestBlockId
            createdAt
            updatedAt
            test {
              id
              createdAt
              config {
                title
                type
                timeLimit
                status
                questionCount
              }
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              groupsCanRead
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            testBlock {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        questionConnections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      question {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listQuestionConnections = /* GraphQL */ `
  query ListQuestionConnections(
    $filter: ModelQuestionConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        questionConnectionQuestionId
        pinnedRevisionId
        createdAt
        updatedAt
        block {
          id
          title
          type
          components {
            index
            renderType
            contents
          }
          createdAt
          updatedAt
          blockConnections {
            items {
              id
              index
              testBlockConnectionTestBlockId
              createdAt
              updatedAt
              test {
                id
                createdAt
                config {
                  title
                  type
                  timeLimit
                  status
                  questionCount
                }
                tags {
                  nextToken
                }
                groupsCanRead
                updatedAt
                blockConnections {
                  nextToken
                }
              }
              testBlock {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
            }
            nextToken
          }
          questionConnections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
        }
        question {
          id
          oldId
          createdAt
          status
          tags {
            items {
              id
              createdAt
              updatedAt
              tag {
                id
                test
                subject
                topic
                contentType
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          searchString
          progresses {
            items {
              id
              selectedAnswerIndex
              didSelectCorrectAnswer
              didSkip
              didMark
              didCheck
              time
              highlights
              masteryLevel
              questionRevisionId
              index
              createdAt
              updatedAt
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          updatedAt
          groupsCanRead
          connections {
            items {
              id
              index
              questionConnectionQuestionId
              pinnedRevisionId
              createdAt
              updatedAt
              block {
                id
                title
                type
                components {
                  index
                  renderType
                  contents
                }
                createdAt
                updatedAt
                blockConnections {
                  nextToken
                }
                questionConnections {
                  nextToken
                }
              }
              question {
                id
                oldId
                createdAt
                status
                tags {
                  nextToken
                }
                searchString
                progresses {
                  nextToken
                }
                updatedAt
                groupsCanRead
                connections {
                  nextToken
                }
                comments {
                  nextToken
                }
                questionRevisions {
                  nextToken
                }
                latestRevisions {
                  nextToken
                }
              }
            }
            nextToken
          }
          comments {
            items {
              id
              index
              content
              createdBy
              createdAt
              updatedAt
              type
              questionBaseCommentsId
            }
            nextToken
          }
          questionRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
          latestRevisions {
            items {
              id
              questionComponents {
                index
                renderType
                contents
              }
              scorePossible
              createdAt
              revisedBy
              questionBaseQuestionRevisionsId
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getQuestionBase = /* GraphQL */ `
  query GetQuestionBase($id: ID!) {
    getQuestionBase(id: $id) {
      id
      oldId
      createdAt
      status
      tags {
        items {
          id
          createdAt
          updatedAt
          tag {
            id
            test
            subject
            topic
            contentType
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      searchString
      progresses {
        items {
          id
          selectedAnswerIndex
          didSelectCorrectAnswer
          didSkip
          didMark
          didCheck
          time
          highlights
          masteryLevel
          questionRevisionId
          index
          createdAt
          updatedAt
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      updatedAt
      groupsCanRead
      connections {
        items {
          id
          index
          questionConnectionQuestionId
          pinnedRevisionId
          createdAt
          updatedAt
          block {
            id
            title
            type
            components {
              index
              renderType
              contents
            }
            createdAt
            updatedAt
            blockConnections {
              items {
                id
                index
                testBlockConnectionTestBlockId
                createdAt
                updatedAt
                test {
                  id
                  createdAt
                  groupsCanRead
                  updatedAt
                }
                testBlock {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            questionConnections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
          }
          question {
            id
            oldId
            createdAt
            status
            tags {
              items {
                id
                createdAt
                updatedAt
                tag {
                  id
                  test
                  subject
                  topic
                  contentType
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            searchString
            progresses {
              items {
                id
                selectedAnswerIndex
                didSelectCorrectAnswer
                didSkip
                didMark
                didCheck
                time
                highlights
                masteryLevel
                questionRevisionId
                index
                createdAt
                updatedAt
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            updatedAt
            groupsCanRead
            connections {
              items {
                id
                index
                questionConnectionQuestionId
                pinnedRevisionId
                createdAt
                updatedAt
                block {
                  id
                  title
                  type
                  createdAt
                  updatedAt
                }
                question {
                  id
                  oldId
                  createdAt
                  status
                  searchString
                  updatedAt
                  groupsCanRead
                }
              }
              nextToken
            }
            comments {
              items {
                id
                index
                content
                createdBy
                createdAt
                updatedAt
                type
                questionBaseCommentsId
              }
              nextToken
            }
            questionRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
            latestRevisions {
              items {
                id
                questionComponents {
                  index
                  renderType
                  contents
                }
                scorePossible
                createdAt
                revisedBy
                questionBaseQuestionRevisionsId
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      comments {
        items {
          id
          index
          content
          createdBy
          createdAt
          updatedAt
          type
          questionBaseCommentsId
        }
        nextToken
      }
      questionRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
      latestRevisions {
        items {
          id
          questionComponents {
            index
            renderType
            contents
          }
          scorePossible
          createdAt
          revisedBy
          questionBaseQuestionRevisionsId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listQuestionBases = /* GraphQL */ `
  query ListQuestionBases(
    $filter: ModelQuestionBaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchQuestionBases = /* GraphQL */ `
  query SearchQuestionBases(
    $filter: SearchableQuestionBaseFilterInput
    $sort: SearchableQuestionBaseSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchQuestionBases(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        oldId
        createdAt
        status
        tags {
          items {
            id
            createdAt
            updatedAt
            tag {
              id
              test
              subject
              topic
              contentType
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        searchString
        progresses {
          items {
            id
            selectedAnswerIndex
            didSelectCorrectAnswer
            didSkip
            didMark
            didCheck
            time
            highlights
            masteryLevel
            questionRevisionId
            index
            createdAt
            updatedAt
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        updatedAt
        groupsCanRead
        connections {
          items {
            id
            index
            questionConnectionQuestionId
            pinnedRevisionId
            createdAt
            updatedAt
            block {
              id
              title
              type
              components {
                index
                renderType
                contents
              }
              createdAt
              updatedAt
              blockConnections {
                items {
                  id
                  index
                  testBlockConnectionTestBlockId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              questionConnections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            question {
              id
              oldId
              createdAt
              status
              tags {
                items {
                  id
                  createdAt
                  updatedAt
                }
                nextToken
              }
              searchString
              progresses {
                items {
                  id
                  selectedAnswerIndex
                  didSelectCorrectAnswer
                  didSkip
                  didMark
                  didCheck
                  time
                  highlights
                  masteryLevel
                  questionRevisionId
                  index
                  createdAt
                  updatedAt
                }
                nextToken
              }
              updatedAt
              groupsCanRead
              connections {
                items {
                  id
                  index
                  questionConnectionQuestionId
                  pinnedRevisionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              comments {
                items {
                  id
                  index
                  content
                  createdBy
                  createdAt
                  updatedAt
                  type
                  questionBaseCommentsId
                }
                nextToken
              }
              questionRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
              latestRevisions {
                items {
                  id
                  scorePossible
                  createdAt
                  revisedBy
                  questionBaseQuestionRevisionsId
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        comments {
          items {
            id
            index
            content
            createdBy
            createdAt
            updatedAt
            type
            questionBaseCommentsId
          }
          nextToken
        }
        questionRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
        latestRevisions {
          items {
            id
            questionComponents {
              index
              renderType
              contents
            }
            scorePossible
            createdAt
            revisedBy
            questionBaseQuestionRevisionsId
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getQuestionComment = /* GraphQL */ `
  query GetQuestionComment($id: ID!) {
    getQuestionComment(id: $id) {
      id
      index
      content
      createdBy
      createdAt
      updatedAt
      type
      questionBaseCommentsId
    }
  }
`;
export const listQuestionComments = /* GraphQL */ `
  query ListQuestionComments(
    $filter: ModelQuestionCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        content
        createdBy
        createdAt
        updatedAt
        type
        questionBaseCommentsId
      }
      nextToken
    }
  }
`;
export const commentTypeByCreatedAt = /* GraphQL */ `
  query CommentTypeByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CommentTypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        content
        createdBy
        createdAt
        updatedAt
        type
        questionBaseCommentsId
      }
      nextToken
    }
  }
`;
export const getQuestionRevision = /* GraphQL */ `
  query GetQuestionRevision($id: ID!) {
    getQuestionRevision(id: $id) {
      id
      questionComponents {
        index
        renderType
        contents
      }
      scorePossible
      createdAt
      revisedBy
      questionBaseQuestionRevisionsId
      updatedAt
    }
  }
`;
export const listQuestionRevisions = /* GraphQL */ `
  query ListQuestionRevisions(
    $filter: ModelQuestionRevisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionRevisions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionComponents {
          index
          renderType
          contents
        }
        scorePossible
        createdAt
        revisedBy
        questionBaseQuestionRevisionsId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      test
      subject
      topic
      contentType
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        test
        subject
        topic
        contentType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
