import React, {useEffect} from 'react';
import useDocumentTitle from '@rehooks/document-title';
import {useTestContext} from '../../../contexts/TestBase';
import {useUserDataContext} from '../../../contexts/UserData';

import USMLEBank from '../../../components/USMLEBank';
import PrometricBank from '../../../components/PrometricBank';
import NCLEXBank from '../../../components/NCLEXQBank';
import QBank from '../../../components/QBank';
import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {colors} from '@bootcamp/shared/src/styles/theme';

const qbankComponentByBootcamp = {
  'med-school': USMLEBank,
  'med-school-preclinical': USMLEBank,
  'med-school-anatomy': QBank,
  'med-school-bites': QBank,
  'nclex': NCLEXBank,
  'inbde-mockExam': PrometricBank,
  'inbde-questionBank': PrometricBank,
  'inbde-quickReview': PrometricBank,
  'inbde': PrometricBank,
  'anatomy': QBank,
  'anatomy-anatomy': QBank,
};

const CustomTest = props => {
  const {config} = useBootcampConfigContext();

  const {bootcamp, cognitoUser, userModel, DEFAULT_USER_ID} = useUserDataContext();
  const username = cognitoUser?.attributes?.name || cognitoUser?.attributes?.email || userModel?.id;
  const {test, restoreCustomTest} = useTestContext();

  const params = new URLSearchParams(props.location.search);
  const testStorageKey = params.get('key');
  const type = test?.config?.type;

  useDocumentTitle(`${config.meta.siteTitle} | Created Test`);

  useEffect(() => {
    if (!DEFAULT_USER_ID) return;

    if (!test) {
      if (testStorageKey) {
        restoreCustomTest(testStorageKey);
      }
    }
  }, [DEFAULT_USER_ID]);

  useEffect(() => {
    const redirectInvalidTest = test?.hasOwnProperty('valid') && !test.valid;

    if (redirectInvalidTest) throw new Error('Test id mismatch');

  }, [test])

  const bootcampWithType = type ? `${bootcamp}-${type}` : bootcamp;
  const QuestionBank = qbankComponentByBootcamp[bootcampWithType];

  return (
    <TestNavigatorProvider
      {...props}
      template={'customTest'}
      skipStartBlock
    >
      <QuestionBank themePalette={colors.brandPalette.royal} username={username} enableBookmarking />
    </TestNavigatorProvider>
  )
}

export default CustomTest;
