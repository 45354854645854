import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {Body4} from '../../../components/Typography';
import {RoundButton} from '@bootcamp/web/src/components/UI';

const Background = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-decoration: none;
  transform: translate3d(0,0,0);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.overlays.opacity.light._50};
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  cursor: default;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
`;

const StyledBadge = styled(Badge)`
  height: 16px;
  width: 16px;
  path {
    fill: white;
  }
`;

const Text = styled(Body4)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-bottom: 16px;
  text-align: center;
`;


const UpgradeButton = ({bootcamp, trialMembership}) => {
  return (
    <Background>
      <Container>
        <Text>
          Upgrade your membership to get {trialMembership ? 'continued ' : 'instant '}access to all content and features.
        </Text>
        <RoundButton target="_blank" href={`https://bootcamp.com/${bootcamp}/upgrade`} small full iconPosition="left" IconComponent={<StyledBadge />}>
          Upgrade
        </RoundButton>
      </Container>
    </Background>
  )
}

export default UpgradeButton;
