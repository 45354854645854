import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Body3} from '../Typography';
import {Label2} from '../Typography/next';

import {colors} from '@bootcamp/shared/src/styles/theme';
import styled, {css} from 'styled-components';

import {Checkmark} from '@styled-icons/fluentui-system-filled/Checkmark';
import {TargetArrow} from '@styled-icons/fluentui-system-filled/TargetArrow';


// segment spacing in pixels
const spacing = 4;

function getThemeColor({lightMode, theme}) {
  return 'linear-gradient(rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.02) 100%);';
  // return lightMode ? theme.colors.special.naturalLight : theme.colors.special.naturalDark
}

function getThemeShadow({lightMode, theme}) {
  return 'rgb(0 0 0 / 7%) 0px 2px 6px inset;';
  // return lightMode ? theme.elevation.inner.progressLight : theme.elevation.inner.progressDark
}

function getLoadingAnimation({loading, theme, lightMode}) {
  if (!loading) return '';

  const gradientRange = lightMode
    ? [theme.colors.neutralsPalette.light, theme.colors.neutralsPalette.lightGrey]
    : [theme.overlays.opacity.dark._100, theme.overlays.opacity.dark._200]; // TODO this probably needs to be tweaked

  return `
    background: linear-gradient(-90deg, ${gradientRange[0]} 0%, ${gradientRange[1]} 50%, ${gradientRange[0]} 100%);
    animation: pulse 1.2s ease-in-out infinite;
    background-size: 400% 400%;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }
  `;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-shadow: ${getThemeShadow};
  position: relative;

  padding: ${({padBar}) => padBar ? spacing : 0}px;
  border-radius: 8px;
  margin-bottom: ${({theme, withMargin}) => withMargin ? theme.layouts.spacing.s : '0px'};
  background: ${getThemeColor};
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme, withMargin}) => withMargin ? theme.layouts.spacing.xs : '0px'};
  }

  ${getLoadingAnimation}
`;
const Segment = styled.span`${({count, color, total, loading, isLast, height, round}) => `
  display: inline-block;
  width: ${loading ? 0 : round ? Math.round((count / total) * 100) : (count / total) * 100}%;
  opacity: ${loading ? 0 : 1};
  height: ${height};
  margin-right: ${(!!count && total !== count && !isLast) ? spacing : 0}px;
  border-radius: 8px;
  background: ${color};
  transition: width .5s;
  box-shadow: ${({theme}) => theme.elevation.shadow.tight};

  &:last-child {
    margin-right: 0px;
  }
`}`;

const Marker = styled.div`
  height: 16px;
  width: 1px;
  background: ${({theme, targetMet}) => targetMet ? theme.colors.interfacePalette.green.default : theme.colors.neutralsPalette.lightGrey};
`;
const BareMarker = styled(Marker)`
  height: 16px;
  width: 2px;
  background: ${({theme, targetMet}) => theme.colors.borderPalette.secondary};
  position: absolute;
  bottom: calc(50% - 4px);
  left: ${({left, accountForPadding}) => `calc(${left}% - 9px + ${accountForPadding ? '8px' : '0px'})`};
`;
const TargetLabelContent = styled.div`
  position: absolute;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  bottom: -26px;
  font-family: 'proxima-nova';
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 3px;
  border-radius: 10px;

  ${({targetMet, theme}) => targetMet
  ? css`
      width: 16px;
      height: 16px;
      padding: 2px;
      background: ${theme.colors.interfacePalette.green.default};
      color: white;
      svg {
        color: white;
        width: 18px;
        height: 18px;
      }
    `
    : css`
      svg {
        color: ${theme.colors.neutralsPalette.lightGrey};
        width: 18px;
        height: 18px;
      }
    `
  }
`;
const BarMarkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: calc(50% - 4px);
  left: ${({left, accountForPadding}) => `calc(${left}% - 9px + ${accountForPadding ? '8px' : '0px'})`};
`;

const LabelTop = styled(Label2)`
  color: ${({theme}) => theme.colors.typographyPalette.primary};
  margin-bottom: 8px;
`;


const Target = ({targetNumber, targetLabel, accountForPadding, targetMet}) => {
  return (
    <BarMarkerContainer left={targetNumber} accountForPadding={accountForPadding}>
      <IconWrapper targetMet={targetMet}>
        {targetMet
          ? <Checkmark/>
          : <TargetArrow/>
        }
      </IconWrapper>
      <Marker targetMet={targetMet}/>
      <TargetLabelContent>{targetLabel}</TargetLabelContent>
    </BarMarkerContainer>
  );
}


// TODO keep this from jumping around when label isn't present
const ProgressBarLabel = styled(Body3)`
  color: ${({theme, lightMode}) => lightMode ? theme.colors.neutralsPalette.grey : theme.colors.neutralsPalette.white};
  min-height: ${({theme}) => theme.font.height.smaller};
  position: relative;
`;

const defaultColors = [colors.interfacePalette.green.gradient, colors.interfacePalette.yellow.gradient, colors.interfacePalette.red.gradient];
const defaultSegments = [
  {color: defaultColors[0], count: 0},
  {color: defaultColors[1], count: 0},
  {color: defaultColors[2], count: 0},
];

const ProgressBar = ({
  className,
  segments,
  total,
  label,
  showLabel,
  lightMode,
  children,
  loading,
  withMargin,
  segmentHeight,
  round,
  showPercentage,
  padBar=false,
  bareLabel=false,
  targetNumber,
  targetLabel,
  targetMet,
  showLabelTop,
  labelTop,
  withSuffix=true,
  markPosition,
}) => {

  const countSum = useMemo(() => (segments || defaultSegments).reduce((acc, segment) => acc + (segment?.count || 0), 0), [segments]);
  const defaultLabel = <span><strong>{Math.min(countSum, total) || 0}</strong> of {total || '...'}{withSuffix && ' questions tagged'}</span>;
  const lastIndexWithCount = (segments || defaultSegments).slice(0).reverse().findIndex(({count}) => !!count) || (segments || defaultSegments).length - 1;

  return (
    <Container className={className} lightMode={lightMode}>
      {!!labelTop && <LabelTop>{labelTop}</LabelTop>}
      <Wrapper lightMode={lightMode} loading={loading} withMargin={withMargin} padBar={padBar}>
        {(segments || defaultSegments)
          .map(({count, color, height}, index) => (
            <Segment
              key={`segment-${index}`}
              loading={loading || !total}
              count={count}
              color={color || defaultColors[index]}
              total={total || countSum}
              isLast={lastIndexWithCount === index}
              height={segmentHeight}
              round={round}
            />
          ))
        }
        {markPosition && <BareMarker left={markPosition} targetMet={countSum > markPosition} accountForPadding/>}
      </Wrapper>
      {showLabel && !bareLabel && <ProgressBarLabel lightMode={lightMode}>{loading ? 'loading...' : label || defaultLabel} {showPercentage && !!total && <div style={{position: 'absolute', right: 0, top: 0, bottom: 0}}>{(countSum ? (countSum / total) * 100 : 0).toFixed(0)}%</div>}</ProgressBarLabel>}
      {showLabel && bareLabel && label}
      {!!targetNumber && <Target targetNumber={targetNumber} targetLabel={targetLabel} accountForPadding={segments?.length > 1} targetMet={targetMet}/>}
    </Container>
  );
}

ProgressBar.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      count: PropTypes.number,
    })
  ),
  label: PropTypes.string,
  lightMode: PropTypes.bool,
  loading: PropTypes.bool,
  showLabel: PropTypes.bool
};
ProgressBar.defaultProps = {
  segments: defaultSegments,
  label: null,
  lightMode: false,
  total: 50,
  loading: false,
  showLabel: true,
  withMargin: true,
  segmentHeight: '8px',
};

export {ProgressBarLabel};
export default ProgressBar;
