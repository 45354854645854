import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import useDocumentTitle from '@rehooks/document-title';

import MasteryReview from '../../components/MasteryReview';
import GettingStarted from '../../components/GettingStarted';

import {H2} from '../../../components/Typography';
import FAQs from '../../components/WebflowFAQs';
import ClassroomCard from '../../components/Cards/ClassroomRow';
import FullClassroomCard from '../../components/Cards/Classroom';
import CreateTest from '../../pages/CreateTest';

import {useMasteryArray, useTestProgressArray} from '../../../hooks';

import {ReactComponent as Coffee} from '@bootcamp/shared/src/assets/svg/coffee.svg';
import reviewVideoBackground from '@bootcamp/shared/src/assets/images/inbde/review_video_background.jpg';
import {AddCircle} from '@styled-icons/fluentui-system-filled/AddCircle';

import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {formatClassroomMastery, formatReviewVideoProgress} from '@bootcamp/shared/src/util/formatting'
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import {useAsync} from '../../../hooks';
import {People} from '@styled-icons/fluentui-system-filled/People'


import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '../../../contexts/UserData';

import Container from '../../pages/PageContainer';

import styled from 'styled-components';

const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  }
`;
const StyledClassroomCard = styled(ClassroomCard)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  &:last-child {
    margin-bottom: 0;
  }
`;
const ReviewVideos = styled(FullClassroomCard)`
  display: block;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledAddCircle = styled(AddCircle)`
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;


const Home = () => {
  const {config} = useBootcampConfigContext();
  const {isUpgraded} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const bootcamp = 'inbde';
  const bootcampTitle = bootcamp.toUpperCase();

  const todayActions = [
    {
      interactionKey: `Streak-${bootcamp}-Create-Test`,
      icon: {
        component: () => <StyledAddCircle/>,
        text: 'Create Test'
      },
      text: isUpgraded ? 'Study up to 100 questions at a time' : `Free users can study up to 40 questions. Upgrade your membership to get instant access to all content and features.`,
      containerProps: {
        onClick: () => modalDispatch({
          type: 'open',
          component: CreateTest,
          componentProps:  !!isUpgraded ? {} : {defaults: {subject: 'Free Questions'}},
          enableClickClose: true,
        }),
      }
    },
    {
      interactionKey: `Streak-DWU-${bootcamp}`,
      hide: !isUpgraded,
      icon: {
        component: Coffee,
        text: 'Quick Study', themeColor: 'royal'
      },
      text: 'Study 10 random questions',
      containerProps: {
        as: Link,
        to: `/${bootcamp}/quick-study`,
      },
      checkIfComplete: interactionObj => {
        // TODO we'll probably want to do something with streak completion for dwu
        // check to see if they completed a warmup today and if there's a custom warmup test
        // for the day, load that review page or resume test etc...

        // const {streak, date: streakDate} = JSON.parse((interactionObj || {}).value || '{}');
        // const {streakCompletedToday} = getStreakDetails(streak, streakDate);
        // return streakCompletedToday;

        return false;
      }
    },
    {
      icon: {
        component: People,
        text: 'Study Group', themeColor: 'royal'
      },
      text: `Join 8k fellow students`,
      containerProps: {
        as: 'a',
        href: 'https://www.facebook.com/groups/inbdebootcamp',
        target: '_blank'
      }
    },

  ];

  const reviewVideos = config.classrooms.find(({route})=> route === 'review-videos');
  const classrooms = config.classrooms.filter(({route}) => route !== 'review-videos');
  const reviewVideoIds = reviewVideos.contentTypes[0].content[0].content.map(({id}) => id);

  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);
  const testsByClassroom = getTestsByClassroom(config?.classrooms, [], [], false, ['Question Banks']);

  const getQuestionCounts = useCallback(() => getClassroomQuestionCounts(bootcamp, testsByClassroom), [])
  const getCourseBlockCounts = useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []);

  const {value: courseBlockCounts} = useAsync(getCourseBlockCounts);
  const {value: questionCounts} = useAsync(getQuestionCounts);

  const classroomTestIds = Object.values(testsByClassroom).flat();

  const progresses = useMasteryArray(classroomTestIds, 'testSmall', data => formatClassroomMastery(data, 'test'));
  const [reviewVideoProgress] = useTestProgressArray(reviewVideoIds, formatReviewVideoProgress);
  const loading = !progresses;

  // realign progresses with classrooms?
  const subjectProgress = classrooms.reduce((acc, {tagId, route}) => {
    const testIds = testsByClassroom[route];

    const mastery = testIds.reduce((acc, id) => ({
      learning: acc.learning + (progresses[id]?.learning || 0),
      reviewing: acc.reviewing + (progresses[id]?.reviewing || 0),
      mastered: acc.mastered + (progresses[id]?.mastered || 0),
      bookmarked: acc.bookmarked + (progresses[id]?.bookmarked || 0),
    }), {
      learning: 0,
      reviewing: 0,
      mastered: 0,
      bookmarked: 0
    });

    return {
      ...acc,
      [tagId]: mastery
    }
  }, {});

  const classroomMasteryConfig = classrooms
    .filter(({locked}) => !locked)
    .map(({name, route, tagId}) => ({name, route, progress: subjectProgress[tagId]}));

  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      <Title>
        Subjects
      </Title>
      <CardWrapper>
        {classrooms.map(({themePalette, name, route, tagId, tagIds, locked, questionCount, educators, comingSoon, icon}, index) => (
          <StyledClassroomCard
            key={tagId}
            iconPath={icon}
            themePalette={themePalette}
            name={name}
            route={`/${bootcamp}/${route}`}
            masteryType={'subject'}
            tagId={tagId}
            locked={locked}
            courses={coursesByClassroom?.[route]}
            questionCount={questionCounts?.classroomQuestionCounts ? questionCounts?.classroomQuestionCounts?.[route] || 0 : 'loading'}
            educators={educators}
            showProgress
            videoLessonCount={courseBlockCounts?.classroomCourseBlockCounts ? courseBlockCounts?.classroomCourseBlockCounts?.[route] || 0 : 'loading'}
            showVideoProgress={!!coursesByClassroom?.[route]?.length}
            progress={
              Object.keys(subjectProgress).length === 0 ? null : tagIds
                ? tagIds.reduce((acc, tagId) => ({
                    learning: acc.learning + subjectProgress[tagId].learning,
                    reviewing: acc.reviewing + subjectProgress[tagId].reviewing,
                    mastered: acc.mastered + subjectProgress[tagId].mastered
                }), {learning: 0, reviewing: 0, mastered: 0})
                : subjectProgress[tagId]
            }
            comingSoon={comingSoon}
          />
        ))}
      </CardWrapper>
      <Title>
        {bootcampTitle} Review Videos
      </Title>
      <ReviewVideos
        themePalette={reviewVideos.themePalette}
        educators={reviewVideos.educators}
        route={`${bootcamp}/${reviewVideos.route}`}
        icon={reviewVideos.icon}
        progress={{mastered: reviewVideoProgress?.length}}
        name={'Review Videos'}
        progressLabel={<span>{<strong>{`${reviewVideoProgress?.length || 0}`}</strong>} of {`352 lessons completed`}</span>}
        questionCount={187}
        showExpertEducators
        educatorLayout={'column'}
        detailsBorder={false}
        backgroundImg={reviewVideoBackground}
        showProgress
      />
      <FAQs />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
