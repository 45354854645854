import React, {useCallback} from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import PracticeTest from '../../components/Cards/PracticeTest';
import Container from '../../pages/PageContainer';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';

import {Heading} from '@bootcamp/web/src/bootcamps/nclex/shared';
import {useContentTypeProgress} from '../../api/progress/contentType';
import {H2} from '@bootcamp/web/src/components/Typography';
import {Element as FAQElement} from '@bootcamp/web/src/bootcamps/components/WebflowFAQs';
import {getReadinessExamGrade} from '../../pages/CustomTestReview/components/Score';
import API, {graphqlOperation} from '@aws-amplify/api';
import {useAsync} from '@bootcamp/shared/src/util/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;

const FAQHeader = styled(H2)`
  color: white;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;

const faqItems = [
  {
    title: "What is the INBDE Bootcamp Simulation Exam?", 
    text: `The Simulation Exam simulates the exact look, feel, timing, and content of the real INBDE. You will take the test timed and see your answers at the end of a section. At the end of each section, you’ll get an accurate prediction of how likely you are to pass the INBDE based on your performance, as well as personalized recommendations on how to improve your chances of passing.`
  },
  {
    title: `How many questions are in the INBDE Simulation Exam? How long does it take to complete?`,
    text: `<div>
      <div>The content breakdown and timing of the Simulation Exam matches the real INBDE. There are 500 questions spread across 6 sections.</div>
      <br/>
      <div>Number of questions and timing of the actual INBDE:</div>
      <br/>
      <div>
        <strong>INBDE Day 1 schedule</strong>: 360 questions, 8 hours and 15 minutes
        <ul>
          <li>Tutorial: 15 minutes</li>
          <li><strong>Section 1</strong>: 100 standalone questions, 1 hour and 45 minutes</li>
          <ul>
            <li>Break: 15 minutes</li>
          </ul>
          <li><strong>Section 2</strong>: 100 standalone questions, 1 hour and 45 minutes</li>
          <ul>
            <li>Break: 30 minutes</li>
          </ul>
          <li><strong>Section 3</strong>: 100 standalone questions, 1 hour and 45 minutes</li>
          <ul>
            <li>Break: 15 minutes</li>
          </ul>
          <li><strong>Section 4</strong>: 60 case set questions, 1 hour and 45 minutes</li>
        </ul>
      </div>
      <div>
        <strong>INBDE Day 2 schedule</strong>: 140 questions, 4 hours and 15 minutes
        <ul>
          <li>Tutorial: 15 minutes</li>
          <li><strong>Section 1</strong>: 70 case set questions, 1 hour and 45 minutes</li>
          <ul>
            <li>Break: 15 minutes</li>
          </ul>
          <li><strong>Section 2</strong>: 70 case set questions, 1 hour and 45 minutes</li>
          <li>Post-Exam Survey: 15 minutes</li>
        </ul>
      </div>
    </div>`
  },
  {
    title: `Do I have to complete an entire day in one sitting?`,
    text: `<div>
      <div>No, we designed the Simulation Exam so you can take one section at a time for a more effective study experience.</div>
      <br/>
      <div>We’ve found the main challenge for students on the real INBDE is that they struggle with mastering the content, not test fatigue. That’s why it’s more important to focus on your review than it is to simulate spending 8 hours taking a test. We recommend taking time to throughly review the explanations and learn from your mistakes after completing each section. This is the best way to improve your chances of passing the INBDE.</div>
    </div>`
  },
  {
    title: `Can I pause a section and come back to it later?`,
    text: `No, in order to provide the most accurate pass prediction, you must answer all questions in a section in one sitting. Set aside at least 1 hour and 45 minutes for each section. If you accidentally submitted a section, you can reset the exam.`
  },
  {
    title: `I accidentally submitted a section before I was finished. What do I do?`,
    text: `You can reset the exam to take it again.`
  },
  {
    title: `Are these questions repeated in the question bank?`,
    text: `No, these are unique questions only found in the Simulation Exam.`
  },
  {
    title: `When will I get my score report?`,
    text: `Immediately after you submit a section, you will receive a detailed score analysis, pass prediction, and be able to review all your answers and the explanations.`
  },
  {
    title: `Is the distribution of topics the same as the actual INBDE?`,
    text: `<div>
      Yes, we follow the <a href="https://jcnde.ada.org/en/inbde" target="_blank">official content outline</a> announced by the ADA and have carefully selected questions to reflect the relative distribution of topics on the actual INBDE.
    </div>`
  },
  {
    title: "How accurate is the pass prediction?",
    text: "We’ve used statistical models to compare your performance against previous students who have passed the INBDE to determine your readiness level."
  },
  {
    title: "When should I take the INBDE Simulation Exam?",
    text: "We recommend to start taking it within the last 2 weeks of your study schedule. Remember, the Simulation Exam is 500 questions and learning from the explanations is crucial. Make sure you leave enough time so that you can adequately review your results."
  }
]

const SimulationExams = ({match, history, location}) => {
  const {isUpgraded, bootcamp, DEFAULT_USER_ID} = useUserDataContext();

  const {config} = useBootcampConfigContext();

  const name = 'Simulation Exams';
  const classroomConfig = config.classrooms.find(({route}) => route === 'simulation-exam');

  const qbankContent = classroomConfig.contentTypes.find(({route}) => route === 'qbanks');

  const banks = qbankContent.content.find(({name}) => name === 'Question Banks') || {content: []};
  
  const contentTypeProgress = useContentTypeProgress(qbankContent, 'simulation-exams');

  const {progress, counts} = contentTypeProgress?.['qbanks-simulation-exams'] || {};

  const {value, pending} = useAsync(useCallback(async () => {
    const query = `
      query queryCohortUserLinksByGsiUserCohorts($userId: String!) {
        queryCohortUserLinksByGsiUserCohorts(userId: $userId) {
          items {
            cohort {
             contentRestrictions
            }
          }
        }
      }
    `
    const {data} = await API.graphql(graphqlOperation(query, {userId: DEFAULT_USER_ID}));
    return data?.queryCohortUserLinksByGsiUserCohorts?.items;
  }, [DEFAULT_USER_ID]))

  function formatResultDetails(attempt) {
    return null;
  }

  function formatScoreText(score) {
    if (!score) return;
    const {gradeLabel} = getReadinessExamGrade(score.correct, score.total, bootcamp);
    return {score: gradeLabel, label: 'Chance'};
  }

  function formatScore(attempt) {
    const questionProgresses = attempt
      ?.map(({questionProgresses}) => questionProgresses?.items)
      ?.flat();

    const scoreDetails = questionProgresses?.reduce((acc, {didSelectCorrectAnswer}) => {
      try {
        return {...acc, score: acc.score + (didSelectCorrectAnswer ? 1 : 0), maxScore: acc.maxScore + 1};
      } catch (error) {
        return acc;
      }
    }, {score: 0, maxScore: 0});

    return {correct: scoreDetails.score, total: scoreDetails.maxScore};
  }
  if (pending) return null;
  const contentRestrictions = value?.reduce((acc, item) => [...acc, ...(item?.cohort?.contentRestrictions || [])], []);
  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading
        name={name}
        description={'Simulate the look, feel, and content of the INBDE. Use this to gauge your readiness!'}
        showEducators={false}
        showEmily={false}
        />
      <Wrapper>
        {banks.content.map((test, index) => {
          const isLocked = !isUpgraded;

          return (
            <PracticeTest
              route={`simulation-exam/qbanks/${test.route}`}
              id={test.id}
              name={test.name}
              testReviewRoute={'simulation-exam/review'}
              testRouteParam={`inbde/simulation-exam/qbanks/${test.route}`}
              formatResultDetails={formatResultDetails}
              formatScoreText={formatScoreText}
              formatScore={formatScore}
              fullWidthProgress
              showTestResults
              startText={'Start Exam'}
              reviewText={'Review'}
              progress={progress && progress.byTest ? progress.byTest[test.id] : 'loading'}
              questionCount={counts ? counts[test?.id] : 'loading'}
              defaultLocked={isLocked}
              simLocked={contentRestrictions?.find(name => name === test.name)}
              index={index+1}
            />
        )})}
      </Wrapper>
      <FAQHeader>FAQs</FAQHeader>
      {faqItems.map(({title, text}) => <FAQElement key={title} title={title} text={text} />)}
    </Container>
  );
}

SimulationExams.propTypes = {};
SimulationExams.defaultProps = {};

export default SimulationExams;
