import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {IntercomAPI} from 'react-intercom';

import {Link} from 'react-router-dom';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, PaymentRequestButtonElement, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Lottie} from '@crello/react-lottie';

import Sequence from '../../../components/Sequence';
import {Container, Body, Header, SubHeader, FieldGroup, SubmitContainer, SubmitButton} from '../components';
import {Input, InputDecorator} from '../FieldRenderer/components/Field';
import {TextButton} from '../../Branding/Buttons';
import {Loader} from '../../Branding';
import {H5, Body4} from '../../Typography';
import {FlexBox} from '../../Grid'
import {useDebouncedState} from '../../../hooks';

import checkoutCompleteAnimation from './checkoutComplete.json';

import {handleError, createMembership, getPrice as getActivePrice, updateActivationCode, invokeLambda} from '@bootcamp/shared/src/requests';
import {useUserDataContext} from '../../../contexts/UserData';
import {graphqlOperation} from 'aws-amplify';
import {capitalize} from '@bootcamp/shared/src/util';

import {stripePublishableKey} from '../../../config/keys';
import {font, colors} from '@bootcamp/shared/src/styles/theme';
import styled, {css} from 'styled-components';
import nanoid from 'nanoid';
import TagManager from 'react-gtm-module';

const stripePromise = loadStripe(stripePublishableKey);

const Form = styled.form`
  width: 100%;
  height: 100%;
`;
const DiscountInputWrapper = styled.div`
  padding: 44px ${({theme}) => theme.layouts.spacing.xxl};
  width: 100%;
  align-items: center;

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`}
  }

  button {
    margin: 0;
  }
`;
const SummaryLoader = styled(Loader)`
  fill: ${({theme}) => theme.colors.brandPalette.royal.default};
`;
const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const SectionWrapper = styled.div`
  margin-top: ${({theme}) => theme.layouts.spacing.xxl};
  max-width: 528px;
  width: 100%;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: ${({theme}) => theme.layouts.spacing.xl};
    padding: 0px 16px;
  }

  &:last-child {
    padding-bottom: ${({theme}) => theme.layouts.spacing.xxl};
  }

  ${Header} {
    color: white;
    text-align: left;
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};

    ${({theme}) => theme.mediaQueries.tablet} {
      margin-bottom: ${({theme}) => theme.layouts.spacing.l};
    }
  }
`;
const Summary = styled(Body)`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`;
const SummaryTotal = styled(Body)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
const SummaryTotalDenomination = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-right: 8px;
`;
const SummaryDollarAmount = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  height: 36px;
`;
const LineItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-self: stretch;
`;

const LoadingAnimation = css`
  ${({loading, theme}) => {
      const gradientRange = [theme.colors.neutralsPalette.light, theme.colors.neutralsPalette.extraLight];

      return loading && css`
        background: linear-gradient(
          -90deg,
          ${gradientRange[0]} 0%,
          ${gradientRange[1]} 50%,
          ${gradientRange[0]} 100%
        );
        animation: pulse 1.2s ease-in-out infinite;
        background-size: 400% 400%;
        @keyframes pulse {
          0% {
            background-position: 0% 0%;
          }
          100% {
            background-position: -135% 0%;
          }
        }`;
    }}
`;

const LineItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  background: ${({lineItemType, theme}) => lineItemType === 'Pro' ? theme.colors.brandPalette.royal.default : theme.colors.brandPalette.orange.default};
  color: white;
  font-size: 14px;
  font-weight: 600;

  ${LoadingAnimation}
`;
const LineItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  border-radius: 8px;

  ${LoadingAnimation}
`;
const LineItemText = styled(Body4)`
`;
const LineItemHeader = styled(LineItemText)`
`;
const LineItemSubHeader = styled(LineItemText)`
  font-size: 12px;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  line-height: 16px;
`;
const LineItemPrice = styled(Body4)`
  margin: auto;
`;
const CardInput = styled(Input)`
  width: 100%;

  ${({focused, theme}) => focused && `
    outline: none;
    background: ${theme.colors.brandPalette.royal.light};
    border: 1px solid ${theme.colors.brandPalette.royal.default};
  `}
`;
const InputTransition = styled(InputDecorator)`
  max-height: ${({active}) => active ? '500px' : '0px'};
  max-width: ${({active}) => active ? '500px' : '0px'};
  opacity: ${({active}) => active ? '1' : '0'};
  margin-bottom: ${({active, theme}) => active ? theme.layouts.spacing.m : '0px'};
  transition: max-width .25s ease-in, opacity .5s;
`;
const DiscountCodeToggle = styled(TextButton)`
  width: ${({active}) => active ? '0px' : '100%'};
  margin: ${({theme}) => theme.layouts.spacing.m} 0px;
`;
const AnimationContainer = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.xl} 0px;
`;
const FinishedLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

function getCardElementOptions() {
  return {
    style: {
      base: {
        fontSize: font.size.regular,
        color: colors.neutralsPalette.extraDark,
        lineHeight: font.height.regular,
      }
    }
  }
}

async function incrementCouponRedemptions(id) {
  if (!id) return;

  try {
    const res = await invokeLambda('tbc-Stripe', {path: '/stripe/coupon/increment', couponId: id});
    return res;
  } catch (error) {
    console.log('error updating coupon redemptions', error);
  }
}

async function fetchPaymentIntent(priceId, discountCode, receiptEmail, referral='') {
  try {
    const {body: {payment_intent}} = await invokeLambda('tbc-Stripe', {
      path: '/stripe/payment-intent',
      priceId,
      discountCode,
      receiptEmail,
      referral
    });
    return payment_intent;
  } catch (error) {
    console.log('error fetching payment intent', error);
    return {client_secret: null};
  }
};

async function fetchPrice(priceId, discountCode) {
  try {
    const {body} = await invokeLambda('tbc-Stripe', {
      path: '/stripe/discount',
      priceId,
      discountCode,
    });

    return body;

  } catch (error) {
    console.log('error fetching price', error);
  }
};

async function createSubscription(email, priceId, paymentMethodId, discountCode) {
  try {
    const {body, error} = await invokeLambda('tbc-Stripe', {
      path: '/stripe/create-subscription',
      email,
      paymentMethodId,
      priceId,
      discountCode,
    });

    return {...body, error};

  } catch (error) {
    console.log('error creating subscription price', error);
  }
}

function formatPrice(price) {
  if (!price) return '0';
  return parseFloat(price / 100).toFixed(2);
}

const DiscountGroup = styled.div`
  width: 100%;
  margin: ${({theme}) => theme.layouts.spacing.xl} auto;
`;

const DiscountCode = ({onChange, isValid, isComplete, className}) => {
  const [showingInput, setShowingInput] = useState(false);
  const InputRef = useRef();
  const error = isValid ? null : {message: 'Invalid code'};

  return (
    <DiscountGroup className={className}>
      {!showingInput &&
        <DiscountCodeToggle color={'royal'} active={showingInput} onClick={() => {
          setShowingInput(true);
          InputRef.current.focus();
        }}>
          Have a promo code?
        </DiscountCodeToggle>
      }
      <InputTransition active={showingInput} label={'Promo Code'} additionalInfo={'(Optional)'} error={error} complete={isComplete}>
        <Input ref={InputRef} onChange={onChange}/>
      </InputTransition>
    </DiscountGroup>
  );
};

const StyledDiscountCode = styled(DiscountCode)`
  margin: 0;
`;

const PaymentRequestButtonElementContainer = styled.div`
  width: 100%;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
`;

const PaymentOptionText = styled(H5)`
  width: 100%;
  text-align: center;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin: ${({theme}) => theme.layouts.spacing.l} 0;
`;

const FormLoader = styled(FlexBox)`
  min-height: 354px;
  align-items: center;
  justify-content: center;
`;

const TermsOfUse = styled.div`
  margin-bottom: 24px;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  padding: 0px ${({theme}) => theme.layouts.spacing.m};
  text-align: center;
  a {
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
    &:visited {
      color: ${({theme}) => theme.colors.neutralsPalette.grey};
    }
  }
`;

const bootcampTitleMap = {
  'step-1': 'Step 1',
  'med-school': 'Med School',
  'dental-school': 'Dental School',
  'anatomy': 'Anatomy',
  'chemistry': 'Chemistry',
};

const createCohortUserLink = async (cohortId, userId) => {
  const createCohortUserLink = /* GraphQL */ `
      mutation CreateCohortUserLink($input: CreateCohortUserLinkInput!) {
        createCohortUserLink(input: $input) {
          id
          user {
            id
          }
        }
      }
    `;

  return await API.graphql(graphqlOperation(createCohortUserLink, {input: {cohortId, userId}}));
}

async function addUserToCohort(discountCode, membershipInput) {
  if (!discountCode) return;

  try {
    // attempt to update the activation code - if it's not able to update, return
    const activationCode = await updateActivationCode({
      id: discountCode,
      couponId: discountCode,
      priceId: membershipInput.productId,
      userId: membershipInput.userMembershipsId,
      redeemedAt: membershipInput.startDate,
      membershipId: membershipInput.id,
    });

    if (!activationCode) return;

    const {cohortId, userId} = activationCode;

    // create cohort user link
    await createCohortUserLink(cohortId, userId);

  } catch (error) {
    console.log('error adding user to cohort', error);
  }
}

const Checkout = ({onFinished, priceId, type, bootcamp}) => {
  const {refreshSession, userModel} = useUserDataContext();
  const [cardElementFocused, setCardElementFocused] = useState(false);
  const [discountCode, setDiscountCode] = useDebouncedState(null);
  const [{originalPrice, discountedPrice, promoCodeId}, setPrice] = useState({});
  const [cardInfoComplete, setCardInfoComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [checkedBrowserPaymentMethods, setCheckedBrowserPaymentMethods] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  // here we are looking at priceId as an indication for membership type. In the Stripe subscription API a priceId is used rather than a product
  // sku for subscription creation, so this is a reliable indicator.
  const membershipType = (type === 'recurring' || summaryData?.type === 'recurring') ? 'subscription' : 'oneTimePayment';

  const formattedOriginalPrice = formatPrice(originalPrice);
  const formattedDiscountPrice= formatPrice(discountedPrice)
  useEffect(() => {
    if (!discountedPrice) return;

    try {
      setPaymentRequest(null);
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Bootcamp Membership: Subtotal',
          amount: discountedPrice,
          // This total is merely used to display the value to the user.
          // The actual amount to be charged is still determined by the clientSecret.
        }
      });

      paymentRequest.on('paymentmethod', async (ev) => {
        const user = await Auth.currentAuthenticatedUser();
        const paymentIntent = await fetchPaymentIntent(priceId, discountCode, user.attributes.email);
        const {client_secret: clientSecret, metadata} = paymentIntent;

        // Confirm the PaymentIntent without handling potential next actions (yet).
        // NOTE: We'll call confirmCardPayment again after we tell the browser
        // to close the paymentRequest window. Not sure if necessary, but recommended
        // by the tutorial here:
        // https://stripe.com/docs/stripe-js/elements/payment-request-button#react-complete-payment
        const {error: confirmError} = await stripe.confirmCardPayment(
          clientSecret,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(clientSecret);

          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            const groups = (metadata.groups || '').split(',').filter(group => !!group);
            const startDate = findStartDate(groups);

            const membershipInput = {
              id: paymentIntent.id,
              startDate: startDate.toISOString(),
              productId: metadata.priceId,
              duration: metadata.duration,
              status: 'active',
              userMembershipsId: user.username,
              type: 'oneTimePayment',
              groups,
            }
            handlePaymentComplete(membershipInput, user.attributes.email, false, metadata.title);
          }
        }
      })

      paymentRequest.canMakePayment().then(paymentMethod => {
        setCheckedBrowserPaymentMethods(true);
        paymentMethod && setPaymentRequest(paymentRequest);
      });

      paymentRequest.on('cancel', async (ev) => {
        // If a user authorizes a payment but then cancels the paymentRequest
        // (according to Stripe, this can happen on some browsers but none specified?)
        // we need to cancel the payment intent.
        // if (!paymentIntent) return;
        // try {
        //   const refund = await refundPaymentIntent(paymentIntent.id);
        //   console.log('successfully requested refund', refund);
        // } catch (e) {
        //   console.error('error requesting refund', e);
        // }

      });

    } catch (e) {
      console.log(e);
    }

  }, [discountedPrice]);

  const getPrice = async () => {
    if (!stripe) return;
    setLoading(true);
    const calculatedPrice = await fetchPrice(priceId, discountCode);
    setPrice(calculatedPrice);
    setLoading(false);
  };

  const handleClick = event => {
    // this is mainly here to prevent form clicks from closing the modal (when this component is rendered in a modal)
    event.persist();
    if (event.target?.id === 'upgrade-link') return;
    event.preventDefault();
    event.stopPropagation();
  };

  const processPayment = async (clientSecret, skipProcessing) => {
    // skip processing card payment if payment intent fetch says so (handling $0 balances)
    if (skipProcessing) return {error: null, paymentIntent: {id: `${nanoid()}_${discountCode}`}};
    try {
      const card = elements.getElement(CardElement);
      return stripe.confirmCardPayment(clientSecret, {payment_method: {card}});
    } catch (e) {
      handleError('processPayment err', e);
      return {error: {message: 'Error occurred, please contact support.'}, paymentIntent: {id: nanoid()}};
    }
  };

  const createPaymentMethod = async () => {
    const card = elements.getElement(CardElement);

    try {
      return await stripe.createPaymentMethod({
        type: 'card',
        card,
        // optionally add billing details etc here
      });
    } catch (error) {
      console.log('error creating payment method', error);
      return {paymentMethodError: error};
    }
  };

  function findStartDate(membershipGroups) {
    try {

      const extendableMemberships = userModel?.memberships?.items
        // active memberships are extendable
        ?.filter(membership => membership.status === 'active')
        // memberships that share a group with the membership being purchased are extendable
        ?.filter(membership => membership.groups
          .filter(group => group !== 'PlusPackAccess')
          .find(group => membershipGroups.find(membershipGroup => membershipGroup === group))
        );

      if (!extendableMemberships || extendableMemberships.length === 0) return new Date();

      // we need to find when this membership should start
      // based on the ultimate end date of our current memberships
      let latestEnd;

      for (const membership of extendableMemberships) {
        if (!latestEnd) latestEnd = membership;

        // end date of the current membership iteration
        const membershipDate = new Date(membership.startDate);
        const membershipEndDate = membershipDate.setDate(membershipDate.getDate() + membership.duration);

        // end date of the stored "latestEnd" membership
        const endDate = new Date(latestEnd.startDate);
        const latestEndDate = endDate.setDate(endDate.getDate() + latestEnd.duration);

        // if the current membership iteration ends later than the latestEnd - update latest end
        if (membershipEndDate > latestEndDate) {
          latestEnd = membership;
        }
      }

      const endDate = new Date(latestEnd.startDate);
      const latestEndDate = endDate.setDate(endDate.getDate() + latestEnd.duration);

      // latestEndDate should be a unix string
      return new Date(latestEndDate);
    } catch (e) {
      console.error('findStartDate err', e);
      return new Date();
    }
  }

  const handleSubscriptionSubmit = async (event) => {

    // Block native form submission.
    event.preventDefault();

    // fetch curent user
    const user = await Auth.currentAuthenticatedUser();

    // return if stripe hasn't loaded
    if (!stripe || !elements || !user) return;

    setLoading(true);

    // create payment method
    const {paymentMethod, paymentMethodError} = await createPaymentMethod();

    if (paymentMethodError) {
      handleError('error processing payment', paymentMethodError);
      setError({message: paymentMethodError.message || 'There was a problem processing your payment method.'});
      setLoading(false);
      return;
    }

    const appliedDiscount = originalPrice === discountedPrice ? '' : promoCodeId;

    // create subscription
    const {subscription, error} = await createSubscription(user.attributes.email, priceId, paymentMethod.id, appliedDiscount);

    if (error) {
      handleError('error processing payment', error);
      setError({message: error.message});
      setLoading(false);
    } else {
      const {price} = subscription?.items?.data?.[0] || {price: {id: 'none'}};
      const groups = (price.metadata?.groups || '').split(',').filter(group => !!group);
      const startDate = findStartDate();
      const membershipInput = {
        id: subscription.id,
        startDate: startDate.toISOString(),
        productId: price.id,
        status: 'active',
        userMembershipsId: user.username,
        type: 'subscription',
        duration: 30, // TODO get this from metadata
        groups,
      };

      const productName = subscription?.plan?.metadata?.title;

      handlePaymentComplete(membershipInput, user.attributes.email, false, productName);
    }
  };

  const handleOneTimePaymentSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    // fetch curent user
    const user = await Auth.currentAuthenticatedUser();

    // return if stripe hasn't loaded
    if (!stripe || !elements || !user) return;

    setLoading(true);

    const appliedDiscount = originalPrice === discountedPrice ? '' : discountCode;
    const referral = window.Rewardful?.referral || '';
    // fetch payment intent
    const {client_secret: clientSecret, metadata, skipProcessing} = await fetchPaymentIntent(priceId, appliedDiscount, user.attributes.email, referral);
    const activeMemberships = (userModel?.memberships?.items || []).filter(membership => membership?.status === 'active');

    if (skipProcessing && activeMemberships.length > 0) {
      window.alert('ERROR: Cannot apply free membership to upgraded account')
      setLoading(false);
      return
    }

    const {error, paymentIntent} = await processPayment(clientSecret, skipProcessing);

    if (error) {
      handleError('error processing payment', error);
      setError({message: error.message});
      setLoading(false);
    } else {
      const groups = (metadata.groups || '').split(',').filter(group => !!group);
      const startDate = findStartDate(groups);

      const membershipInput = {
        id: paymentIntent.id,
        startDate: startDate.toISOString(),
        productId: metadata.priceId,
        duration: metadata.duration,
        status: 'active',
        userMembershipsId: user.username,
        type: 'oneTimePayment',
        groups,
      }
      handlePaymentComplete(membershipInput, user.attributes.email, false, metadata.title);
    }
  };

  const handlePaymentComplete = async (membershipInput, email, skipProcessing, productName) => {
    // increment coupon redemption metadata
    await incrementCouponRedemptions(discountCode);

    // create membership
    const membership = await createMembership(membershipInput);

    // add user to cohort
    await addUserToCohort(discountCode, membershipInput);

    await refreshSession();

    try {
      // update intercom user attributes
      if (membershipInput.type === 'subscription') {
        // create intercom event
        await IntercomAPI('trackEvent', 'Upgraded to monthly plan', {
          ...membershipInput,
          groups: JSON.stringify(membershipInput.groups),
          discountCode,
          discountedPrice: (discountedPrice / 100).toFixed(2),
          originalPrice: (originalPrice / 100).toFixed(2),
        });
        try {
          TagManager.dataLayer({
            dataLayer: {
              'transactionId': membershipInput.id,
              'transactionTotal': (discountedPrice / 100).toFixed(2),
              'transactionCoupon': discountCode,
              'transactionProducts': [{
                'sku': priceId,
                'name': productName || priceId,
                'price': (discountedPrice / 100).toFixed(2),
                'quantity': 1
              }],
              'email': email,
              'event': 'Transaction Complete',
            }
          })
        } catch (e) {
          console.log(e);
        }
      } else {
        // create intercom event
        await IntercomAPI('trackEvent', `Account Upgraded: Joined group(s) ${membershipInput.groups.join(', ')}`, {
          ...membershipInput,
          groups: JSON.stringify(membershipInput.groups),
          discountCode,
          discountedPrice: (discountedPrice / 100).toFixed(2),
          originalPrice: (originalPrice / 100).toFixed(2),
        });
        try {
          if (discountCode) {
            await IntercomAPI('update', {discountCode});
          }
        } catch (error) {
          console.log(error)
        }
        try {
          window.rewardful && window.rewardful('convert', { email });
          TagManager.dataLayer({
            dataLayer: {
              'transactionId': membershipInput.id,
              'transactionTotal': (discountedPrice / 100).toFixed(2),
              'transactionCoupon': discountCode,
              'transactionProducts': [{
                'sku': priceId,
                'name': productName || priceId,
                'price': (discountedPrice / 100).toFixed(2),
                'quantity': 1
              }],
              'email': email,
              'event': 'Transaction Complete',
            }
          })
        } catch (e) {
          console.log(e);
        }
      }

    } catch (error) {
      console.log('error registering intercom', error);
    }

    setLoading(false);

    onFinished(email, membershipInput.id, skipProcessing);
    return membership;
  }

  useEffect(() => {
    if (!elements || !checkedBrowserPaymentMethods) return;

    const cardElement = elements.getElement(CardElement);

    cardElement.on('change', (event) => {
      if (event.complete) setCardInfoComplete(true);
      else if (cardInfoComplete && !event.complete) setCardInfoComplete(false);
    });

  }, [elements, cardInfoComplete, checkedBrowserPaymentMethods]);

  useEffect(() => {
    getPrice();
  }, [stripe, discountCode]);

  useEffect(() => {
    async function getSummaryData() {
      const price = await getActivePrice(priceId);
      setSummaryData(price);
    }

    priceId && getSummaryData();
  }, [priceId, bootcamp]);

  const lineItemType = summaryData?.metadata?.title?.split(' ').slice(-1)[0];

  const bootcampTitle = bootcampTitleMap[bootcamp] || bootcamp.toUpperCase();

  const FREE_PURCHASE = formattedDiscountPrice == 0;

  return (
    <CheckoutContainer onClick={handleClick}>
      <SectionWrapper>
        <Header>Order Summary</Header>
        <Container>
          <Summary>
            <LineItem>
              <LineItemIcon lineItemType={lineItemType} loading={!summaryData}>
                {summaryData && bootcampTitle}
                <br/>
                {lineItemType}
              </LineItemIcon>
              <LineItemDetails loading={!summaryData}>
                <LineItemHeader>{!!summaryData && summaryData?.metadata?.title}</LineItemHeader>
                <LineItemSubHeader>{!!summaryData && `Grants ${summaryData?.metadata?.durationString || 'access'} to ${bootcampTitle} Bootcamp`}</LineItemSubHeader>
              </LineItemDetails>
              <LineItemPrice>
                {formattedOriginalPrice ? `$${formattedOriginalPrice}` : ''}{membershipType === 'subscription' ? '/mo' : ''}
              </LineItemPrice>
            </LineItem>
          </Summary>
          <DiscountInputWrapper>
            <StyledDiscountCode
              onChange={event => {
                setLoading(true);
                setDiscountCode(event.target.value);
              }}
              isValid={loading || !discountCode || (originalPrice && Number.isInteger(discountedPrice) && originalPrice !== discountedPrice)}
              isComplete={discountCode && originalPrice && Number.isInteger(discountedPrice) && originalPrice !== discountedPrice}
            />
          </DiscountInputWrapper>
          <SummaryTotal>
            <Body4>Total</Body4>
            <span>
              <SummaryTotalDenomination>USD</SummaryTotalDenomination>
              <SummaryDollarAmount>
                {loading ? <SummaryLoader active size={16}/>: `$${formattedDiscountPrice}`}
              </SummaryDollarAmount>
              <SummaryTotalDenomination>{membershipType === 'subscription' ? '/mo' : ''}</SummaryTotalDenomination>
            </span>
          </SummaryTotal>
        </Container>
      </SectionWrapper>
      <SectionWrapper>
        <Header>Payment Information</Header>
        <Container>
          {FREE_PURCHASE ? null : <Body>
            {checkedBrowserPaymentMethods ? (
              <Form>
                {paymentRequest && membershipType !== 'subscription' &&
                  <PaymentRequestButtonElementContainer>
                    <PaymentOptionText>
                      Use saved card
                    </PaymentOptionText>
                    <PaymentRequestButtonElement options={{paymentRequest, style: {paymentRequestButton: {height: '56px'}}}} />
                    <PaymentOptionText>
                      Or enter details below
                    </PaymentOptionText>
                  </PaymentRequestButtonElementContainer>
                }
                <FieldGroup>
                  <InputDecorator error={error}>
                    <CardInput
                      onFocus={() => setCardElementFocused(true)}
                      onBlur={() => setCardElementFocused(false)}
                      options={getCardElementOptions()}
                      focused={cardElementFocused}
                      as={CardElement}
                    />
                  </InputDecorator>
                </FieldGroup>
              </Form>
            ) : <FormLoader><Loader active size={24} color={'royal'} /></FormLoader>}
          </Body>}
          <SubmitContainer>
            <SubmitButton
              onClick={membershipType === 'subscription' ? handleSubscriptionSubmit : handleOneTimePaymentSubmit}
              disabled={!stripe || !elements || loading || (!cardInfoComplete && Number.isInteger(discountedPrice) && discountedPrice !== 0)}>
              {loading ? <Loader active size={16}/> : FREE_PURCHASE ? `Proceed` : `Pay $${formatPrice(discountedPrice)}`}
            </SubmitButton>
          </SubmitContainer>
          <TermsOfUse>
            By clicking above, I agree to the <a id="upgrade-link" href="https://bootcamp.com/terms" target="_blank" rel={"noreferrer"}>Terms of Use</a>.
          </TermsOfUse>
        </Container>

      </SectionWrapper>

    </CheckoutContainer>
  )
};

const FinishedAnimation = ({onFinished}) => {
  const eventListeners = onFinished
    ? [{name: 'complete', callback: onFinished}]
    : [];

  return (
    <AnimationContainer>
      <Lottie
        style={{maxHeight: '150px', maxWidth: '150px'}}
        config={{animationData: checkoutCompleteAnimation}}
        lottieEventListeners={eventListeners}
        />
    </AnimationContainer>
  )
}

const Finished = ({start, link, email, paymentIntentId, skipProcessing, onAnimationFinished, bootcamp}) => (
  <Container>
    <Body>
      <Header>{(bootcamp === 'oat' || bootcamp === 'inbde' || bootcamp === 'dat') ? `Payment Successful!` : `Awesome! We're looking forward to helping you master ${bootcampTitleMap[bootcamp] || bootcamp.toUpperCase()}`}</Header>
      {!skipProcessing && !onAnimationFinished && <SubHeader>We've sent a receipt to {email}. You are now an upgraded member of {['dat', 'oat', 'inbde'].includes(bootcamp) ? bootcamp.toUpperCase() : bootcampTitleMap[bootcamp] || bootcamp} Bootcamp.</SubHeader>}
      {start && <FinishedAnimation onFinished={() => onAnimationFinished(bootcamp, paymentIntentId)}/>}
    </Body>
    {!onAnimationFinished &&
      <SubmitContainer>
        <FinishedLink to={link.to}>
          <SubmitButton>
            {link.text}
          </SubmitButton>
        </FinishedLink>
      </SubmitContainer>
    }
  </Container>
);

const StyledSequence = styled(Sequence)`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

const ContextWrapper = ({finishedLink, onAnimationFinished, priceId, bootcamp, type}) => {
  const SequenceWrappedCheckout = useCallback(({next}) => !bootcamp
    ? <FormLoader><Loader active size={24} color={'white'} /></FormLoader>
    : (
      <Checkout
        bootcamp={bootcamp}
        priceId={priceId}
        type={type}
        onFinished={(email, paymentIntentId, skipProcessing) => {
          next({stepSize: 1, activeProps: {finished: true, email, paymentIntentId, skipProcessing}})
        }}
      />
    ),
    [bootcamp]
  );

  return (
    <Elements stripe={stripePromise}>
      <StyledSequence>
        {[
          SequenceWrappedCheckout,
          ({finished, email, paymentIntentId, skipProcessing}) => <Finished start={finished} link={finishedLink} email={email} paymentIntentId={paymentIntentId} skipProcessing={skipProcessing} bootcamp={bootcamp} onAnimationFinished={onAnimationFinished}/>
        ]}
      </StyledSequence>
    </Elements>
  );
}

ContextWrapper.propTypes = {
  finishedLink: PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string,
  }),
  showHeader: PropTypes.bool,
};
ContextWrapper.defaultProps = {
  finishedLink: {
    to: '/account',
    text: 'Close'
  },
  showHeader: true,
};


export {Checkout, Finished};
export default ContextWrapper;
