import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import Highlightable from '../../../Highlightable';


const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 72px;
  padding-right: 24px;

  ${({theme}) => theme.mediaQueries.laptop} {
    overflow: initial;
    padding-bottom: 0;
    flex: 0;
  }
`;
const Wrapper = styled.div`
  ${({headerPresent}) => !headerPresent && css`
    padding-top: 24px;
  `}
`;
const Header = styled.div`
  padding: ${({theme}) => `${theme.layouts.spacing.l} 0 ${theme.layouts.spacing.m}`};

  p {
    margin: 0;
  }
`;
const TabName = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.s} ${({theme}) => theme.layouts.spacing.m};
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.light};
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  background: ${({active, theme}) => active ? 'none' : '#EEE'};
  margin-right: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1em !important;
  cursor: pointer;
  font-weight: ${({theme}) => theme.font.weight.bold};
  &:hover {
    background: #E0E0E0;
  }
  ${({active}) => active && css`
    box-shadow: 0px 1px 0px 0px white;
  `}
`;
const NameContainer = styled.div`
  display: flex;
`;
const NameWrapper = styled.div`
  display: flex;
  position: relative;
`;
const ContentWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.light};
  border-radius: 8px;
  border-top-left-radius: 0px;
  padding: 12px;
`;
const TabContent = styled.div`
  display: ${({active}) => active ? 'flex' : 'none'};
  flex-direction: column;
  * {
    max-width: 100%;
    font-size: 16px !important;
    line-height: 24px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0;
  }
  p {
    margin: 0;
  }
  ol, ul {
    margin: 1em 0;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  img {
    height: auto;
  }
  .fr-highlight {
    cursor: pointer;
    /* background: ${({theme}) => theme.colors.neutralsPalette.nclex.lightYellow}; */
    &:hover {
      background: #FFFF00;
      opacity: 0.5;
    }
  }
  ${({type}) => type?.match('highlight') && css`
    cursor: not-allowed;
  `}
  .fr-highlight-selected {
    background: ${({theme}) => theme.colors.neutralsPalette.nclex.yellow};
    &:hover {
      background: ${({theme}) => theme.colors.neutralsPalette.nclex.yellow};
      opacity: 1;
    }
  }

  .fitb-input {
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.nclex.light};
    background: ${({theme}) => theme.colors.neutralsPalette.nclex.offWhite};
    height: 21px;
  }

  {/* this input is targeting our matrix question types */}
  .matrix-input-container {
    display: flex;
    text-align: left;
    align-items: center;
  }
  .matrix-checkbox-input, .matrix-radio-input {
    min-width: 20px;
    height: 20px;
    margin: 0 ${({theme}) => theme.layouts.spacing.s};
    vertical-align: middle;
  }
  .matrix-score-container, .matrixMultipleChoice-score-container {
    position: absolute;
    display: flex;
    left: 28px;
    top: 0;
    bottom: 0;
    align-items: center;
  }
  .score-icon {
    min-width: 14px;
    max-width: 14px;
  }

  .score-icon-empty {
    min-width: 14px;
    max-width: 14px;
  }

  ${({ answered }) => answered && css`
    .fr-highlight-correct {
      background: yellow;
      &:hover {
        background: yellow;
      }
    }
    .fr-highlight-incorrect {
      background: lightgrey;
      &:hover {
        background: lightgrey;
      }
    }
  `}
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  td {
    position: relative !important;
    border: 1px solid #ddd;
    &:first-child {
      text-align: left;
    }
  }
  tr {
    &:first-child {
      background: #e8f2ff !important;
      color: initial !important; */
    }

    &:nth-child(even) {
      background: #f2f2f2 !important;
    }
  }
  .hanging-indent-table {
    width: auto !important;
    text-align: left;
    margin-left: initial !important;

    tr {
      color: black !important;
      &:first-child {
        background: none !important;
        border: none !important;
      }
      &:nth-child(even) {
        background: none !important;
        border: none !important;
      }
    }

    td {
      font-weight: normal !important;
      background: none !important;
      border: none !important;
      vertical-align: top;
      width: auto !important;
      padding: 5px 10px;
    }
  }
  /* .unstyled-table {
    tr {
      color: black !important;
      font-weight: normal !important;
      background: white !important;
    }
    td {
      border: none;
    }
  } */

`;

const Names = ({ tabs, activeIndex, onClick }) => (
  <NameContainer>
    {Object
      .keys(tabs)
      .map((name, index) => (
        <NameWrapper>
          <TabName
            key={name}
            active={index === activeIndex}
            onClick={() => onClick(index)}>
            {name}
          </TabName>
        </NameWrapper>
      ))}
  </NameContainer>
);
const Content = ({tabs, activeIndex, setHighlights, highlights, enableHighlighting}) => (
  <ContentWrapper>
    {Object
      .values(tabs)
      .map((content, index) => (
        <Highlightable
          type={`tab-content-${index}`}
          htmlString={content}
          setHighlights={setHighlights}
          highlights={highlights}
          disable={!enableHighlighting}
          disableToolTip>
          <TabContent
            key={`tab-content-${index}`}
            active={index === activeIndex}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Highlightable>
      ))}
  </ContentWrapper>
);

const DataTabs = ({tabs, header, setHighlights, highlights, enableHighlighting}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  if (!tabs) return null;

  return (
    <Container>
      {header && <Header dangerouslySetInnerHTML={{__html: header}}/>}
      <Wrapper headerPresent={!!header}>
        <Names tabs={tabs} onClick={setActiveIndex} activeIndex={activeIndex}/>
        <Content tabs={tabs} activeIndex={activeIndex} setHighlights={setHighlights} highlights={highlights} enableHighlighting={enableHighlighting}/>
      </Wrapper>
    </Container>
  );
};

DataTabs.defaultProps = {};
DataTabs.propTypes = {};

export default DataTabs;