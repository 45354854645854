import React, {useState, useRef, useLayoutEffect, useEffect} from 'react';
import styled from 'styled-components';

import debounce from 'debounce';

import {Column, Row} from '../Grid';
import {IconButton} from '../Branding/Buttons'
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import {ChevronRight} from '@styled-icons/feather/ChevronRight';


const Container = styled(Column)`
  justify-content: center;
  overflow: auto;
`;

export const CarouselContainer = styled(Row)`
  height: ${({maxCardHeight}) => maxCardHeight ? maxCardHeight + 'px' : '100%'};
  position: relative;
  overflow: auto;
  overflow-x: hidden;
`;

const CarouselSlide = styled.div`
  width: 100%;
  transition: transform .3s, opacity .5s;
  overflow: hidden;

  ${({slideIndex, activeIndex}) => {
    const isActive = slideIndex === activeIndex;
    return `
      transform: translateX(
        ${((activeIndex - slideIndex) * -10)}%
      );
      position: ${(isActive ? 'relative' : 'absolute')};
      opacity: ${(isActive ? 1 : 0)};
      z-index: ${isActive ? 1 : 0};
    `}
  }
`;

const ControlContainer = styled(Row)`
  align-items: center;
  position: relative;
  flex: 0 0 0;
  z-index: 1;
  user-select: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};
`;

const ControlDots = styled(Row)`
  align-items: center;
  justify-content: center;
  height: 48px;
`;

const ControlDot = styled.div`
  width: 12px;
  height: 12px;
  margin: 4px;
  border-radius: 100%;
  transition: background .5s, border .25s;
  cursor: pointer;
  ${({isActive, theme}) => `
    background: ${isActive ? 'transparent' : theme.overlays.opacity.light._200};
    border: ${isActive ? `1px solid ${theme.colors.neutralsPalette.white}` : 'none'};
  `}
`;

const ControlButtons = styled(Row)`
  position: absolute;
  right: 0;
  bottom: 0;
  flex: 0;
  width: auto;
  > div {
    margin-left: 8px;
  }
`;

const LeftArrow = styled(ChevronLeft).attrs(props => ({size: 24}))`
  stroke-width: 1.5px;
  color: ${({theme}) => theme.colors.neutralsPalette.white};
`;

const RightArrow = styled(ChevronRight).attrs(props => ({size: 24}))`
  stroke-width: 1.5px;
  color: ${({theme}) => theme.colors.neutralsPalette.white};
`;

const Slide = ({slideIndex, activeIndex, slide, setHeight}) => {
  const ref = useRef();

  useEffect(() => {
    window.addEventListener('resize', debounce(() => {
      const height = ref && ref.current && ref.current.getBoundingClientRect().height;
      ref && ref.current && setHeight(height);
    }, 250));

    return () => window.removeEventListener('resize', setHeight);
  }, []);

  useLayoutEffect(() => {
    const height = ref && ref.current && ref.current.getBoundingClientRect().height;
    return setHeight(height);
  }, [ref.current]);

  return <CarouselSlide ref={ref} slideIndex={slideIndex} activeIndex={activeIndex} children={slide} />
}

const Carousel = ({className, children}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideHeights, setSlideHeights] = useState(children.map(() => 0))

  const updateHeightByIndex = index => {
    return height => {
      slideHeights[index] = height;
      setSlideHeights(slideHeights);
    }
  }

  const tallestCard = Math.max(...slideHeights);

  return (
    <Container className={className}>
      <CarouselContainer maxCardHeight={tallestCard}>
        {children?.map((slide, index) => (
          <Slide key={`carousel-slide-${index}`} slideIndex={index} activeIndex={activeIndex} slide={slide} setHeight={updateHeightByIndex(index)}/>
        ))}
      </CarouselContainer>
      {children?.length > 1 &&
        <ControlContainer>
          <ControlDots>
            {children?.map((child, index) => <ControlDot key={`dot-${index}`} isActive={index === activeIndex} onClick={() => setActiveIndex(index)} />)}
          </ControlDots>
          <ControlButtons>
            <IconButton type={'secondary'} size={'small'} onClick={() => setActiveIndex(activeIndex => activeIndex === 0 ? children.length - 1 : activeIndex - 1)}>
              <LeftArrow />
            </IconButton>
            <IconButton type={'secondary'} size={'small'} onClick={() => setActiveIndex(activeIndex => (activeIndex + 1) % children.length)}>
              <RightArrow />
            </IconButton>
          </ControlButtons>
        </ControlContainer>
      }
    </Container>
  );
}

export default Carousel;
