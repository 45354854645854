import React, {Fragment, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import SVG from 'react-inlinesvg';

import {Row, FlexBox} from '../../../Grid';
import {H5} from '../../../Typography';
import {HomeIcon, StudentReviewsIcon} from '../../../Branding/Classroom/Icons';
import {Account, Upgrade, Close, LogInMobile, SignUpMobile} from '../Buttons';
import {IconButton} from '../../../Branding/Buttons';
import {useUserDataContext} from '../../../../contexts/UserData';
import {resolveIconPath} from '@bootcamp/web/src/helpers';

import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {Menu as MenuIconImport} from '@styled-icons/evaicons-solid/Menu';

import {colors} from '@bootcamp/shared/src/styles/theme';


const Container = styled.div`
  position: absolute;
  top: ${({barHeight}) => barHeight}px;
  z-index: 10000000;
  opacity: ${({active}) => active ? 1 : 0};
  transition: opacity .45s;
  height: ${({active}) => active ? 'auto' : 0};
  width: ${({active}) => active ? 'auto' : 0};
  overflow: hidden;
${({theme}) => theme.mediaQueries.tablet} {
    top: 0;
    right: 0;
    position: fixed;
    width: ${({active}) => active ? '100%' : 0};
    height: ${({active}) => active ? '100vh' : 0};
    background: rgba(94, 110, 141, 0.9);
  }
`;
const Wrapper = styled.div`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: ${({theme}) => theme.layouts.spacing.m};
  box-shadow: ${({theme}) => theme.elevation.shadow.high};
  width: 400px;
  border-radius: 8px;
  ${({theme}) => theme.mediaQueries.tablet} {
    border-radius: 0;
    width: 100%;
    padding: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const MenuIcon = styled(MenuIconImport)`
  width: 20px;
  height: 20px;
  color: white;
  ${({theme}) => theme.mediaQueries.tablet} {
    width: 16px;
    height: 16px;
  }
`;
const MenuButton = styled(IconButton)`
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  padding: 10px;
  height: auto;
  width: auto;
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 8px;
  }
`;
const List = styled(FlexBox)`
  flex-direction: column;
`;
const Arrow = styled(ArrowForwardOutline)`
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  color: ${({theme}) => theme.colors.neutralsPalette.white};
`;
const Locked = styled.span`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  font-size: ${({theme}) => theme.font.size.smaller};
  line-height: 15px;
`;
const ListItem = styled(FlexBox)`
  padding: ${({theme}) => theme.layouts.spacing.s};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  align-items: center;
  border-radius: 100px;
  background: ${({active, theme}) => active ? theme.colors.neutralsPalette.extraLight : theme.colors.neutralsPalette.white};
  min-height: 50px;
  &:hover {
    background: ${({hoverColor, theme, locked}) => hoverColor || theme.colors.brandPalette.indigo.light};
    ${Arrow} {
      color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const ItemLink = styled(H5)`
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
`;
const Current = styled.span`
  display: ${({active}) => active ? 'inline-block' : 'none'};
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  font-size: ${({theme}) => theme.font.size.smaller};
  line-height: 15px;
`;
const IconWrapper = styled.span`
  background: ${({color, theme}) => color || theme.colors.brandPalette.indigo.dark};
  border-radius: 25px;
  padding: 6px;
  width: 32px;
  height: 32px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};
  svg {
    width: 20px;
    height: 20px;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 10px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;
const ItemNameWrapper = styled.div`
`;

const MobileTop = styled(Row)`
  display: none;
  ${({theme}) => theme.mediaQueries.tablet} {
    justify-content: space-between;
    display: flex;
    margin-bottom: 12px;
  }
`;
const MobileBottom = styled(Row)`
  display: none;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
  }
`;
const AccountButtonWrapper = styled.span`
`;


function isRouteActive(history, route) {
  const pathname = history.location.pathname.split('/').slice(-1)[0];

  return pathname === route;
}

function getMenuItems(onMenuItemClick, history, config, includeStudentReviewLink) {
  const listItemConfig = [
    {route: 'home', name: 'Home', icon: <HomeIcon/>, themePalette: {}},
    ...config,
    ...(includeStudentReviewLink ? [{href: 'https://blog.anatomybootcamp.com/student-reviews', name: 'Student Reviews', icon: <StudentReviewsIcon/>, themePalette: colors.brandPalette.purple}] : []),
  ];

  return listItemConfig.map(({route, href, themePalette, icon, name, locked, releaseDate, directRoute}, index) => {
    const active = !href && isRouteActive(history, route);
    const elementProps = locked
      ? {as: 'div', onClick: () => {}}
      : href
      ? {as: 'a', href, target: '_blank', onClick: onMenuItemClick}
      : directRoute
      ? {as: Link, to: index === 0 ? `/${route}` : route, onClick: onMenuItemClick}
      : {as: Link, to: index === 0 ? `/${route}` : `/home/${route}`, onClick: onMenuItemClick}

    return (
      <ListItem key={`jump-menu-item-${route}`} hoverColor={themePalette.light} active={active}>
        <ItemLink
          {...elementProps}
          children={
            <Fragment>
              <IconWrapper
                color={themePalette.default}
                children={typeof icon === 'string' ? <SVG src={resolveIconPath(icon)}/> : icon}
              />
              <ItemNameWrapper>
                <div>{name}</div>
                {locked
                  ? <Locked children={releaseDate}/>
                  : <Current active={active} children={'Current'}/>
                }
              </ItemNameWrapper>
              {!locked && <Arrow/>}
            </Fragment>
          }
          />
      </ListItem>
    )});
}

const CloseButton = styled(Close)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-left: ${({theme}) => theme.layouts.spacing.s};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.tablet} {
    width: 32px;
    height: 32px;
  }
`;
const AccountButton = styled(Account)`
  svg {
    color: ${({theme}) => theme.colors.brandPalette.indigo.default};
  }
`;
const UpgradeButton = styled(Upgrade)`
  margin: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.s}`};
`;
const LogInButton = styled(LogInMobile)`
  margin: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.s}`};
  margin-right: 4px;
`;
const SignUpButton = styled(SignUpMobile)`
  margin: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.s}`};
  margin-left: 4px;
`;

const BottomLoggedIn = () => (
  <UpgradeButton
    type={'primary'}
    size={'large'}
    color={'indigo'}
    fixed={true}
    children={'Upgrade Membership'}
    />
);

const BottomLoggedOut = () => (
  <Fragment>
    <LogInButton/>
    <SignUpButton/>
  </Fragment>
);

const Menu = withRouter(({history, setMenuOpen, menuOpen, barHeight, barHeightMobile, config}) => {
  const {isUpgraded, loggedIn, bootcamp} = useUserDataContext();
  const ref = useRef();

  function handleClick(event) {
    if (!menuOpen) return;

    const canDoWork = ref && ref.current && ref.current.contains && event && event.target;
    const isInside = canDoWork && ref.current.contains(event.target);

    if (!isInside) setMenuOpen(false);
  }

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [menuOpen]);

  return (
    <Container barHeight={barHeight} barHeightMobile={barHeightMobile} active={menuOpen}>
      <Wrapper ref={ref} active={menuOpen}>
        <MobileTop>
          <CloseButton onClick={() => setMenuOpen(false)}/>
          <AccountButtonWrapper onClick={() => setMenuOpen(false)}>
            <AccountButton buttonType={'icon'} type={'primary'} size={'large'}/>
          </AccountButtonWrapper>
        </MobileTop>
        <List children={getMenuItems(() => setMenuOpen(false), history, config, bootcamp === 'anatomy')}/>
        <MobileBottom>
          {loggedIn
            ? !isUpgraded && <BottomLoggedIn/>
            : <BottomLoggedOut/>
          }
        </MobileBottom>
      </Wrapper>
    </Container>
  );
});



export default Menu;
export {MenuIcon, MenuButton};