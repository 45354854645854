import React, {useEffect} from 'react';
import useDocumentTitle from '@rehooks/document-title';
import {useTestContext} from '../../../contexts/TestBase';
import {useUserDataContext} from '../../../contexts/UserData';

import PrometricBank from '../../../components/PrometricBank';
import {TestNavigatorProvider} from '../../../contexts/TestNavigator';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {colors} from '@bootcamp/shared/src/styles/theme';


const SimulationExam = props => {
  const {config} = useBootcampConfigContext();

  const {cognitoUser, userModel} = useUserDataContext();
  const username = cognitoUser?.attributes?.name || cognitoUser?.attributes?.email || userModel?.id;

  useDocumentTitle(`${config.meta.siteTitle} | Simulation Exam`);

  const urlParams = new URLSearchParams(window.location.search);
  const isReview = urlParams.get('review');

  return (
    <TestNavigatorProvider
      {...props}
      testId={props.testId}
      template={isReview ? 'testReview' : 'tbcSavedBank'}
      type={'readinessExam'}
    >
      <PrometricBank themePalette={colors.brandPalette.royal} username={username} enableBookmarking />
    </TestNavigatorProvider>
  )
}

export default SimulationExam;
