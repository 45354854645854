import React from 'react';

const Blood = () => (
	<table>
		<thead>
			<tr>
				<th class="labName">Hematologic</th>
				<th class="refRange">Reference Range</th>
				<th class="SI">SI Reference</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td rowspan="2">Erythrocyte count (RBC)</td>
				<td class="refRange">Male: 4.3–5.9 million/mm<sup>3</sup></td>
				<td class="SI">4.3–5.9 x 10<sup>12</sup>/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 3.5–5.5 million/mm<sup>3</sup></td>
				<td class="SI">3.5–5.5 x 10<sup>12</sup>/L</td>
			</tr>
			<tr>
				<td rowspan="2">Erythrocyte sedimentation rate (Westergren)</td>
				<td class="refRange">Male: 0–15 mm/h</td>
				<td class="SI group">0–15 mm/h</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 0–20 mm/h</td>
				<td class="SI">0–20 mm/h</td>
			</tr>
			<tr>
				<td rowspan="2">Hematocrit</td>
				<td class="refRange">Male: 41%–53%</td>
				<td class="SI">0.41–0.53</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 36%–46%</td>
				<td class="SI">0.36–0.46</td>
			</tr>
			<tr>
				<td rowspan="2">Hemoglobin, blood</td>
				<td class="refRange">Male: 13.5–17.5 g/dL</td>
				<td class="SI">135–175 g/L</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 12.0–16.0 g/dL</td>
				<td class="SI">120–160 g/L</td>
			</tr>
			<tr>
				<td>Hemoglobin A<sub>1c</sub></td>
				<td class="refRange">≤6%</td>
				<td class="SI">≤42 mmol/mol</td>
			</tr>
			<tr>
				<td>Leukocyte count (WBC)</td>
				<td class="refRange">4500–11,000/mm<sup>3</sup></td>
				<td class="SI">4.5–11.0 x 10<sup>9</sup>/L</td>
			</tr>
			<tr>
				<td>Neutrophils, segmented</td>
				<td class="refRange">54%–62%</td>
				<td class="SI">0.54–0.62</td>
			</tr>
			<tr>
				<td>Neutrophils, bands</td>
				<td class="refRange">3%–5%</td>
				<td class="SI">0.03–0.05</td>
			</tr>
			<tr>
				<td>Eosinophils</td>
				<td class="refRange">1%–3%</td>
				<td class="SI">0.01–0.03</td>
			</tr>
			<tr>
				<td>Basophils</td>
				<td class="refRange">0%–0.75%</td>
				<td class="SI">0.00–0.0075</td>
			</tr>
			<tr>
				<td>Lymphocytes</td>
				<td class="refRange">25%–33%</td>
				<td class="SI">0.25–0.33</td>
			</tr>
			<tr>
				<td>Monocytes</td>
				<td class="refRange">3%–7%</td>
				<td class="SI">0.03–0.07</td>
			</tr>
			<tr>
				<td>CD4+ T-lymphocyte count</td>
				<td class="refRange">≥500/mm<sup>3</sup></td>
				<td class="SI">≥0.5 x 10<sup>9</sup>/L</td>
			</tr>
			<tr>
				<td>Platelet count</td>
				<td class="refRange">150,000–400,000/mm<sup>3</sup></td>
				<td class="SI">150–400 x 10<sup>9</sup>/L</td>
			</tr>
			<tr>
				<td>Reticulocyte count</td>
				<td class="refRange">0.5%–1.5%</td>
				<td class="SI">0.005–0.015</td>
			</tr>
			<tr>
				<td>D-Dimer</td>
				<td class="refRange">≤250 ng/mL</td>
				<td class="SI">≤1.4 nmol/L</td>
			</tr>
			<tr>
				<td>Partial thromboplastin time (PTT) (activated)</td>
				<td class="refRange">25–40 seconds</td>
				<td class="SI">25–40 seconds</td>
			</tr>
			<tr>
				<td>Prothrombin time (PT)</td>
				<td class="refRange">11–15 seconds</td>
				<td class="SI">11–15 seconds</td>
			</tr>
			<tr>
				<td>Mean corpuscular hemoglobin (MCH)</td>
				<td class="refRange">25–35 pg/cell</td>
				<td class="SI">0.39–0.54 fmol/cell</td>
			</tr>
			<tr>
				<td>Mean corpuscular hemoglobin concentration (MCHC)</td>
				<td class="refRange">31%–36% Hb/cell</td>
				<td class="SI">4.8–5.6 mmol Hb/L</td>
			</tr>
			<tr>
				<td>Mean corpuscular volume (MCV)</td>
				<td class="refRange">80–100 µm<sup>3</sup></td>
				<td class="SI">80–100 fL</td>
			</tr>
			<tr>
				<th colspan="3">Volume</th>
			</tr>
			<tr class="group">
				<td rowspan="2">Plasma</td>
				<td class="refRange">Male: 25–43 mL/kg</td>
				<td class="SI">0.025–0.043 L/kg</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 28–45 mL/kg</td>
				<td class="SI">0.028–0.045 L/kg</td>
			</tr>
			<tr class="group">
				<td rowspan="2">Red cell</td>
				<td class="refRange">Male: 20–36 mL/kg</td>
				<td class="SI">0.020–0.036 L/kg</td>
			</tr>
			<tr class="group">
				<td class="refRange">Female: 19–31 mL/kg</td>
				<td class="SI">0.019–0.031 L/kg</td>
			</tr>
		</tbody>
	</table>
);

export default Blood;