import styled from 'styled-components';
import {Column, Row} from '../../../../Grid';
import {H1, H3, H5, Body2, Body3} from '../../../../Typography';

import {ReactComponent as badge} from '@bootcamp/shared/src/assets/svg/badge.svg';

import {Star} from '@styled-icons/fluentui-system-filled/Star';

const Container = styled(Column)`
  position: relative;

  ${({theme}) => `
    padding: ${theme.layouts.spacing.xl} 0;
    background: ${theme.overlays.opacity.light._100};
    border-radius: ${theme.layouts.spacing.s};
  `}

  ${({theme}) => theme.mediaQueries.tablet} {
    width: auto;
    min-height: 0;
    margin: 0 ${({theme}) => theme.layouts.spacing.l};
    padding: ${({theme}) => theme.layouts.spacing.l} 0;
    background: none;
    border-radius: 0px;
    border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light+'4C'};
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${({theme}) => theme.layouts.spacing.l};
`;

const Heading = styled.div`
  align-self: center;
`;

const HeadingName = styled(H3)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  }
`;

const HeadingSchool = styled(H5)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  }
`;

const HeadingResultContainer = styled(Row)`
  flex-flow: row wrap;
  align-items: center;
  height: auto;
`;

const HeadingResultScore = styled(H1)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-right: ${({theme}) => theme.layouts.spacing.s};

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
  }
`;

const HeadingResultPercentile = styled(H3)`
  color: ${({theme}) => theme.overlays.opacity.light._400};

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
  }
`;

const Comment = styled(Body2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`};

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
    padding: ${({theme}) => theme.layouts.spacing.l} 0;
  }
`;

const StudentImageContainer = styled.div`
  position: relative;
  border-radius: 100%;
  width: 120px;
  min-width: 120px;
  height: 120px;
  margin-right: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.tablet} {
    min-width: auto;
    width: auto;
    width: 64px;
    height: 64px;
  }
`;

const StudentImage = styled.img`
  width: 100%;
`;

const StudentImageIconContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  svg {
    fill: ${({theme}) => theme.colors.brandPalette.orange.default};
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 6px;
    width: 24px;
    height: 24px;
  }
`;

const StudentImageIcon = styled(badge)`
  width: 100%;
  height: 100%;
`;

const UpdatedStudentImage = styled(StudentImage)`
  border-radius: 100px;
  border: 3px solid rgba(255, 255, 255, .1);
`;


const RatingContainer = styled.div`
  display: flex;
  padding: 8px 12px;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border-radius: 100px;
  margin-left: 24px;
`;
const StarIcon = styled(Star)`
  width: 13px;
  fill: ${({theme}) => theme.colors.interfacePalette.yellow.hover};
  stroke: ${({theme}) => theme.colors.interfacePalette.yellow.hover};
`;

const Rating = () => (
  <RatingContainer>
    <StarIcon />
    <StarIcon />
    <StarIcon />
    <StarIcon />
    <StarIcon />
  </RatingContainer>
);

const Name = styled(H3)`
  color: white;

  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  }
`;

const SubHeader = styled(Body3)`
  color: white;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0px;


  ${({theme}) => theme.mediaQueries.tablet} {
    color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  }
`;

export {
  Container,
  Wrapper,
  Heading,
  HeadingName,
  HeadingSchool,
  HeadingResultContainer,
  HeadingResultScore,
  HeadingResultPercentile,
  Comment,
  StudentImageContainer,
  StudentImage,
  StudentImageIconContainer,
  StudentImageIcon,
  UpdatedStudentImage,
  Name,
  SubHeader,
  Rating,
};
