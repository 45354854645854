import React from 'react';
import styled from 'styled-components';

import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import firstBy from 'thenby';
import {Info} from '@styled-icons/fluentui-system-filled/Info';
import {StyledBlurb, StyledHeader, StyledLabel, StyledTable} from './shared';
const Container = styled.div`
  border-radius: 8px;
`;


export const Subject = ({dataBySubject=[], expandAll=false}) => {
  const rows = dataBySubject
  .map(({testSubject, correct, total, timeSpent, topics}) => {
    const parentPercentage = Math.round(correct / total * 100)
    return {
      parent: [
        {content: testSubject},
        {content: ''},
        {content: total ? `${parentPercentage}%` : ' '},
        {content: total ? `${correct} / ${total}` : ' '},
      ],
      nested: Object
        .entries(topics)
        .sort(firstBy(([system]) => system))
        .map(([system, dataBySubject]) => {
          const systemPercentage = Math.round(dataBySubject?.correct / dataBySubject?.total * 100);
          const isRed = systemPercentage <= 50;
          return {
            onClick: () => {},
            cells: [
              {content: ''},
              {content: testSubject, disabled: true},
              {content: <span style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>{system} {isRed ? <Info style={{marginLeft: '8px', minWidth: 16}} size={16} color="#E05E57" /> : ''}</span>},
              {content: <span>{systemPercentage}%</span>},
              {content: <span>{dataBySubject?.correct} / {dataBySubject?.total}</span>},
            ]
          }
        })
      }
  })
  const totalsAcrossSubjects = dataBySubject.reduce((acc, {correct, total, timeSpent}) => ({
    correct: acc.correct + correct,
    total: acc.total + total,
    timeSpent: acc.timeSpent + timeSpent,
  }), {correct: 0, total: 0, timeSpent: 0})

  return (
    <Container>
      <StyledHeader>
        <StyledLabel>Topic Breakdown</StyledLabel>
        <StyledBlurb icon={ari} text={<span style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>Topics marked with a red <Info style={{margin: '0px 4px'}} size={16} color="#E05E57" /> should receive additional attention.</span>} lightMode/>
      </StyledHeader>
      <StyledTable
        expandAll={expandAll}
        hideChevrons
        rows={[
          { cells: [{ content: '' }, { content: 'Subject' }, { content: 'Topic' }, {content: 'Score'}, { content: '# Correct / Total' }, { content: '' }] },
          {cells: [{content: ''}, {content: 'Total'}, {content: ''}, {content: `${Math.round(totalsAcrossSubjects.correct / (totalsAcrossSubjects.total || 1) * 100)}%`}, {content: `${totalsAcrossSubjects.correct} / ${totalsAcrossSubjects.total}` }, { content: '' }] },
          ...rows
        ]}
      />
    </Container>
  );
};

Subject.defaultProps = {};
Subject.propTypes = {};

export default Subject;