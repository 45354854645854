import {colors} from '@bootcamp/shared/src/styles/theme';

const config = {
  classrooms: [
    {
      name: 'Biology',
      route: 'biology',
      tagId: '65dcea9c-ac11-4e6c-aac0-89fa2f979afb',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "95cf6793-a858-420b-8374-e5b1d47ad679",
        "4c429a8d-031f-45d9-bc62-7ea6e13a21cd",
        "0573db68-92e8-42bc-befd-4c9000ac97d9",
        "cc7e95fa-c25b-4ae3-8517-9ed27b9666d7",
        "98d2b6c6-39e0-40b3-9905-c7234b376374",
        "084fc9eb-c3d6-47bc-a097-f32ff54730e2",
        "504108a0-a530-4316-a747-dae7ba777c77",
        "a1ece18f-203d-4844-97b2-330e6a443288",
        "f2e14dcb-9001-4884-904a-a6c5419e5451",
        "1bb70b9a-f6b7-45de-8c38-a028c0a69b5b",
        // boosters
        "f853fac6-eed5-4011-a1ef-e89a3c353111",
        "dc396081-3b34-404d-aa2b-d6f044edec55",
        "9a2819e8-ec32-47a7-8a30-6e7bc9f58edc",
        "f2b085b1-a10d-493a-b833-87fa3af66966",
        "4dc1252f-7db7-41a5-b1c8-86e840ba451a",
      ],
      qbIds: [
        "9e04cfe5-d60a-47a3-84b4-a488cd0bb744",
        "81df654d-0ef2-4575-91dc-d4f5da169a02",
        "900b491f-804b-40c6-ab6b-977b58b7bd4a",
        "6f800735-6378-43fd-877a-9bbc99599cd0",
        "96950ce1-0943-4f23-9afa-2c0b96158023",
        "bc92203d-6721-4c65-b154-46a3ade2c790",
        "cc92bb54-d3fa-45de-a4b0-9ff8e460f170",
        "a70ed145-fcaf-4661-bdd6-8be5fccd39e9",
        "63e9c921-e3f1-4261-b3f3-03b6e066d7b7",
        "54ca687a-e66b-4650-9c85-2c4689c0f61b",
        "c26f73c8-b7fa-405a-b0e2-7f37d81c4e9b",
        "d07482d9-89b7-45c2-afe1-7831e124f135",
        "fe358879-bda3-4120-afd5-b7376edef5a5",
        "08a03fbf-a696-4c55-ba5e-0ee89a243b1a",
        "f2d27f84-3216-4e27-8570-02c395f8e2ec",
        "03a86ce4-a5a7-4be3-8e28-471a7b6e00eb",
        "8c045781-a82f-4ce4-85e4-2e6f2f170524",
        "96dcb1d9-be02-4bc6-b60b-1589cc11756b",
        "ce11ca9b-6e61-4eb4-bfab-32fd95b28899",
        "bd831bce-8a06-4aac-bb77-8084a01e9e9e",
        "79125610-850c-47cc-acf7-01b85bb6e05d",
        "69101662-cd5f-4242-b4a6-5b98fb5539f9",
        "42f0b588-2a09-4354-ae22-ae0894c1b808",
        "d93b94bd-740c-4740-ab27-1ca8e477a823",
        // Bio Bites
        '7890e7a6-b279-4d49-89ac-ab2115353f57',
        'b12b4488-804c-47f8-906a-40795d2e2403',
        '62afc384-08a2-4e12-806c-2a63a117cf29',
        '6fb999d3-8244-4115-ba35-e5869b94c697',
        '639de0b6-2e0f-4dc7-ae8c-9d0842872bb9',
        '59a22c20-2372-4751-a302-437d205e7bdf',
        '6e2fe8c0-e615-4121-8053-5765d97aecf1',
        'd092bd1f-8f77-465a-8e38-5df8fb46f2e4',
        '674c417f-4ca8-43f3-8da8-c0d372fd8b08',
        'da9679b1-9769-44d1-bd6d-ff9a3e693fb4',
        '969fb6d4-80b8-4f3a-b4d2-2bc3bf655569',
        '07043b23-43ec-44df-8edd-66093e6d0172',
        '38e76450-2386-4e55-9f1c-fb0ce4f44c1f',
        '6d1e08e9-a31f-4d50-a13d-5306f57775fb',
        '08cec486-e136-43ae-869a-635b482b31e4',
        'b7bb421e-cbb5-4a40-8b1c-d5f54a0640b8',
        '82f7ced1-d695-4c32-80bd-223f5f9a2d77',
        '97294d5e-d679-495a-a60c-f22c53cbe313',
        'd6cc725d-452d-4213-8952-575433b54bda',
        'a7582d6a-2eb1-49bf-94bc-c9f1fd73ffb4',
        '9f7b9306-b09e-443e-9086-405790ea1680',
        '7a4ceaa7-f40d-4cff-b6f4-f3d0aa7ad3af',
        'bd7f9266-3216-482f-9bcd-bb7cd1cecbc1',
        '50801e05-15df-4478-a5cc-dbd730c5ebc9',
      ],
    },
    {
      name: 'General Chemistry',
      route: 'general-chemistry',
      tagId: 'fa72381b-e2c2-4e46-a113-135220a010b6',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "cca868cd-2c5b-46cb-b2e5-9f3d98c0671b",
        "092b7148-66a0-40d5-ad81-2040cfef0fa8",
        "a9873393-34c0-4a5a-8c52-378ed46b5b54",
        "71df7e34-e38e-43d0-bc2e-791966e5dadd",
        "3bfcb89c-2752-4b74-86c2-7dcad54ebfad",
        "9f143fd4-8d34-4319-b2b9-5ea37c9aed14",
        "608b5f5d-65d6-466f-b8a1-366b4b32ba2d",
        "1c9afe79-ac74-4de1-8e32-8dd3eeb73fe4",
        "28857cc9-bdbf-4021-bc92-db498a2947f8",
        "cc3afe18-18cd-4c6e-9660-764a2b39d6b7",
        // booster
        "8f713de8-8a8e-46a6-ae6e-7d48c776d3a3",
        "44f3f3c5-9902-44e7-942f-1824d0df8f23",
        "001ea248-f414-4690-b85d-3f2615705ce6",
        "858969a2-b314-4fb3-9ca1-123266859ec5",
        "00a5f478-c045-4690-b8f6-e89b5d7ba550",
      ],
      qbIds: [
        "6b3f8d22-c456-4f18-a81a-2933cc24ea1f",
        "132eca7e-3d38-4a6b-afa8-ead1d77ac74a",
        "26f6aad6-de11-4db2-b37e-b45178e020b2",
        "b8659ef0-586b-413f-8ff7-711154e1a377",
        "19423504-5970-4822-9471-fb77a878c2b0",
        "886d55b4-ae34-42e5-8947-d6b23cff4ce6",
        "ec481b9f-8255-4ab3-8129-2737b1ef84a5",
        "769d1742-743a-4da2-8f62-c4533bdccb4c",
        "c0bdcf69-36e0-4514-a4e4-0c00a4970be0",
        "4acdd745-9e6e-4f4b-b0d6-bf7ee49583ea",
        "1c010898-79f0-4bbc-9df3-49ea73004079",
        "551694a0-86ef-4f31-8148-2d90f8ece78b",
        "66fbcbf9-30a5-4b6b-97fc-600c6bfd4423",
      ],
    },
    {
      name: 'Organic Chemistry',
      route: 'organic-chemistry',
      tagId: '1a0ba62c-1c67-45ef-a811-245a6ddcedd4',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "f49cdd3c-bb24-485b-b6f6-ec2c031406c3",
        "d94ec214-6492-47e7-a4e5-2874eaa079b7",
        "5c637268-78a9-4ab9-b65a-106feb61f05b",
        "4199d531-65fb-4b83-91e8-a319fedcf004",
        "b0cc9761-f2a1-4e43-86c3-a096958ccdee",
        "bb4ca324-2a39-44bc-b076-fba4160e0672",
        "561a476c-f83a-45d3-96b7-4aee410895d6",
        "ffac078b-6501-4e00-9c91-f40f263f62fe",
        "3eeff172-9bb8-4744-947f-beeb2dc6223a",
        "cbfea1e4-8003-4cb9-84b6-34697f29fd08",
        // booster
        "c185be27-f481-42a4-a179-d26662e363b3",
        "343e0a19-059a-4945-a8f9-8f396f8f778e",
        "d66163d7-b15c-4c8d-8f98-217914bf5b06",
        "dbd0b3e2-4181-4df1-a378-1712aceb997f",
        "43774e3e-6f38-44f3-8cbe-8a2d61eb9232",
      ],
      qbIds: [
        "8488fd74-4121-4cba-b9ae-667eb9ac5e42",
        "f2fa8d63-6a8b-4b7b-b1bc-bc544ea893fb",
        "6767e7fe-59af-44a5-bd74-7978143146e5",
        "12957f25-d54b-4e7c-bf4f-1a85b9c8f9da",
        "850649a6-b0a3-4c80-a6c4-49227925850c",
        "cb7930b3-5df0-4812-92b2-9a02f58f529e",
        "2bd56bdc-4529-4e12-84e2-b6e33085cb45",
        "2f9d862f-1a6b-4960-adda-dbc123000682",
        "7b52953f-1c43-4532-9068-46b5df77a7d4",
        "8a7b86a5-aa84-43f6-b191-7a2f6d6d9887",
        "e45dc5f3-f5cb-4f19-9b90-93ae8c346b06",
        "052bb2b3-b848-4df2-9e49-98264a1985d6",
        "f1e06e83-266c-44ff-8730-fb82de06c7c3",
        "44bad24d-a4ca-4387-9065-704b82759b88",
        "188f05c8-5892-451e-a0bf-e4dc17de65a0",
        "21fcb9de-3b1e-4e7a-b368-92c45161ddcc",
        "175de41f-c443-4181-8a7b-be1226c04287",
        "abd0f123-0ec3-44bd-90ce-3249703d4e7e",
        "7cf5945b-6d47-44f5-92fc-e0f6b67b8ec7",
      ]
    },
    {
      name: 'Perceptual Ability',
      route: 'perceptual-ability',
      tagId: '8437a097-a40d-4f2e-a56d-f8f819e6f780',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "017e5fa3-f7ec-4a3a-9d35-ebb2e936c6c6",
        "56f1a49c-7e08-46b1-84c7-7bde953e6771",
        "c1d12c8b-6df3-4325-8cf1-80e229cedfc1",
        "7351be70-42d0-4c45-8986-311357b91393",
        "4e642e33-3dcc-40b4-ad20-b7fc5c8f4248",
        "8d952adc-dbc4-4cbf-ae47-2ff4f909932c",
        "d1796d26-2db0-4344-acdf-b330e87fdc7b",
        "7bf07307-90a9-4b42-a7a5-a677b0109fdb",
        "a7699b88-976d-492e-8ecf-1fa3358e2e10",
        "544a445b-c677-4348-9189-6faf77acce8f",
        "23dc10e0-5351-4496-8c02-d67615a9968c",
        "bbd76bd6-10db-4e87-a3a8-eea3d3c87f99",
        "ead42fac-00f0-4b20-ac1d-393cb5af7993",
        "b7b71fd8-69a7-4b23-b9d6-a4a123bf293d",
        "9cdf51a4-4731-4339-b089-590bef5fe12f",
      ],
      qbIds: [
        "e98d3996-77e2-40e5-8974-dcd0dcc38155",
        "1182146c-4ca1-47d9-8463-8d23639bfa08",
        "055739dc-7faf-43eb-a0fb-83b34392e7f7",
        "66e12d14-7819-448d-b193-6f1017479b0d",
        "b383d5ad-e83e-458a-9c7d-836b29e7840b",
        "e92436e0-21ae-4a4a-b759-ddf469f5f15c",
      ]
    },
    {
      name: 'Quantitative Reasoning',
      route: 'quantitative-reasoning',
      tagId: '27846321-e1b8-4c9a-a2b9-4668c3e8fd22',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "fe11f4b8-1006-41ec-ba6e-2626185866df",
        "98fb90c7-884e-45fb-b186-5d18b093c35f",
        "86cf9541-d316-4906-968a-2d9daddc148a",
        "30870d34-14a7-4d5f-8e81-eaa89bc51475",
        "19e836ef-93db-4574-b82c-adc485f7757f",
        "fa6128c3-d343-424a-84b7-7fb2d4a7554f",
        "b27a2fa6-9d0c-4663-b0e6-ab9da63e7eae",
        "5ced68c8-b0c7-4fc5-aa92-61531c1dd4d3",
        "cae9941b-0629-43ce-b52b-5dbecc503816",
        "9b22f379-8ba3-4bbd-b51a-b30a4c7f1d9c",
        // "2292ea3f-24cc-414e-9bc5-7cc45bf33a8b",
        // "13141cb8-33ca-479c-87fa-73c6686e5b02",
        // "1462752f-c4cc-4e68-8a9d-5de33a4e5970",
        // "8d619696-e38e-45f6-b079-a6707dce2d74",
        // "cfaa0ea6-14c2-4934-96c4-ade2ea7e6ecb",
      ],
      qbIds: [
        'f144b17f-ac0c-46ca-a2c8-7753c4d9373d',
        'e85d98e4-00d7-4eb1-bbca-210bdcdcc289',
        '2b52d0ad-8106-424e-a7b8-427f12a487c5',
        '6ed80f46-ff57-4f7c-a0d4-aea7ae627c76',
        '56b79d57-9788-42a8-96f8-dbea763457be',
        '75545784-dca9-43c8-a47b-15aab013186e',
        'eb08eb04-54fc-412f-a255-d7561e4b4c4c',
        '58077f40-322f-4bd0-aa25-d0b756303146',
        '0cd89b72-e7ac-4cb1-b7da-5fd68f88853f',
        'b87e7acc-7816-4fb7-8c77-2300df30a54a',
        '958b99af-1cee-4da4-8259-ac9dc516d5d6',
        'ea6be34f-3f65-441d-b84c-a5cef527134a',
        '3a35920c-3949-41f6-949c-1a97d8749c77',
        '4f6db1a2-5006-416c-8a56-a913a598f2be',
        '8785548b-8a5d-482d-a3a5-f07b769d2eee',
        'd09c2815-e555-45a0-b646-fab74ec5afa9',
        'f6b63119-32ec-4d50-b476-a4187cbb956b',
        'c474949a-dcb1-4453-9bae-4664fe15ca53',
      ]
    },
    {
      name: 'Reading Comprehension',
      route: 'reading-comprehension',
      tagId: 'fa65df0d-4cbe-4a25-8537-e8bc05c51f47',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "ce6a8b3a-3fd5-429d-8684-2fa810a1746b",
        "5460d4cc-5556-4776-83e0-8785fb1b1880",
        "828e5a05-0cbf-4ba6-8eab-aaa117dced5e",
        "bf431a5b-2cea-4f2f-a626-29e579ab2587",
        "84e1b493-68a5-4897-8b90-e12cfee48dde",
        "259936bb-317f-4bd3-b2b4-ad4f13978f9b",
        "3eaff7db-1b92-47f0-a4ec-3701c43a1c33",
        "69ecbe65-f6e5-4a06-8619-28100a1bb2cb",
        "6fe45579-0293-4cb5-9b4a-ffdacd4ea813",
        "8fd95617-59b4-4df3-b113-84890058d2d6",
        "f0b6fc0f-16ca-4d91-8f75-998a1e9dc577",
        "71c717e5-2f2e-4860-bee9-4063b0d35fe1",
        "0628c06c-76bd-4180-9e86-e94640973454",
        "fd50ef06-f932-4065-a3b1-6b005fc2ad2d",
        "f3385b56-aa4b-4fee-bf22-f17f8875ba8b",
      ],
      qbIds: [
        "b15320da-90af-401c-9750-66e7b42e82b2",
        "bcb55577-f918-40b9-8777-64145c43ac9f",
        "a6e0a215-19bc-48f0-9764-7530c5774f22",
        "a2856dee-c960-485b-b59c-2ad51f5131fd",
        "bd3a3703-86e3-4e20-968d-bd2bd2212fb4",
        "88aa40c1-9142-424e-8a63-e978793a6c3f",
        "a85bf496-e2ee-4cc0-a048-29095989522d",
        "154e32db-96bc-4152-8a6e-e91d2aada941",
        "1827ad5f-82e3-4419-aed7-28a1312732d0",
        "fb2852f3-1dc9-481f-ad6f-bd2c8c564dc0",
        "b0d3392e-77b5-466d-85d5-c6f78789f70b",
        "65e23ca4-6923-40d3-9958-836088c851ec",
        "8019000b-7663-4a24-8182-6e52b67e1582"
      ]
    },
    {
      name: 'Physics',
      route: 'physics',
      tagId: '5d95d40b-9be6-4b51-8313-aa2650ddeccb',
      themePalette: colors.brandPalette.royal,
      testIds: [
        "ee5cd659-df00-4f30-8131-d240d454243a",
        "e3e098af-da46-4277-bbd3-5c410c7c34f8",
        "2b25348b-6138-43f8-9d03-6b96ba271389",
        "d112d6ef-3ab5-4cc0-a4af-039d5bf53fa4",
        "47245130-6399-48b9-b7ac-0b845d1fede6",
        "39ca5573-3401-489b-88ad-296671b1bb16",
        "8813eb07-15e2-4ed1-bc10-ef8d5873a4fb",
        "e27cf7ab-3006-4fdd-83b9-f04839b86d72",
        "21de7754-bcca-4d31-9e44-284599648d3c",
        "7ff9a49f-850d-4955-b229-13f83574bc86",
      ]
    },
  ],
};

export default config;
