import React from 'react';
import styled, {css} from 'styled-components';

import {H3} from '@bootcamp/web/src/components/Typography';

import {Minus} from '@styled-icons/heroicons-outline/Minus';
import {Check} from '@styled-icons/heroicons-outline/Check';
import {Info} from '@styled-icons/fluentui-system-filled/Info';

import {Tooltip} from 'react-tooltip';

import baseTheme from '@bootcamp/shared/src/styles/theme';


export const Container = styled.div`
  padding: 16px 0px;
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`
export const Header = styled(H3)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
export const Label = styled.label`
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  opacity: ${({disabled}) => disabled ? '0.5' : '1'};
  padding: 6px 8px;
`;
export const Count = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  padding: ${({theme}) => theme.layouts.spacing.xs} ${({theme}) => theme.layouts.spacing.s};
  border-radius: 100px;]
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};

  ${({theme, masteryLevel, loading}) => loading
    ? css`
        ${theme.animation.loadingBackground({loading})};
      `
    : masteryLevel === 'learning'
    ? css`
        background: ${theme.colors.interfacePalette.red.light};
        color: ${theme.colors.interfacePalette.red.dark};
      `
    : masteryLevel === 'reviewing'
    ? css`
        background: ${theme.colors.interfacePalette.yellow.light};
        color: ${theme.colors.interfacePalette.yellow.dark};
      `
    : masteryLevel === 'mastered'
    ? css`
        background: ${theme.colors.interfacePalette.green.light};
        color: ${theme.colors.interfacePalette.green.dark};
      `
    : css`
        background: ${theme.colors.neutralsPalette.offWhite};
        color: ${theme.colors.neutralsPalette.grey};
      `
  };
`;
export const CustomInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 4px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  border: 1px solid ${({theme, checked, disabled, locked}) => ((checked && !disabled) || locked) ? 'none' : theme.colors.neutralsPalette.lightGrey};
  background: ${({checked, disabled, theme}) => disabled
    ? theme.colors.neutralsPalette.extraLight
    : checked
    ? theme.colors.brandPalette.blue.default
    : 'white'
  };
`;
export const InputCheck = styled(Check)`
  width: 12px;
  height: 12px;
  color: white;
  path {
    stroke-width: 4px;
  }
`;
export const InputMinus = styled(Minus)`
  width: 12px;
  height: 12px;
  color: ${({theme}) => theme.colors.brandPalette.blue.default};
  path {
    stroke-width: 4px;
  }
`;
const TooltipContainer = styled.div`
  display: flex;
  margin-left: 6px;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const InputHoverWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    ${CustomInputWrapper} {
      background: ${({theme, active, disabled}) => !active && !disabled && theme.colors.neutralsPalette.light};
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  background: ${baseTheme.colors.neutralsPalette.extraDark};
`;

export const TextTooltip = ({className, text, children, id}) => (
  <TooltipContainer className={className} data-tooltip-id={id}>
    <StyledTooltip id={id} children={children || text}/>
    <Info />
  </TooltipContainer>
);