import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

import {
  PrometricWrapper,
  NextText,
  NextButton,
} from './shared';


const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 21px;
`;
const Section = styled.div`
  width: 100%;
  margin-bottom: 21px;
`;
const QRTutorial = ({setDismissedQRTutorial}) => {

  const {
    methods: {
      setLeftButtons,
      setCenterButtons,
      setRightButtons
    }
  } = useTestNavigatorContext();

  useEffect(() => {
    setLeftButtons([{component: <div />}]);
    setCenterButtons([{
      component: <NextButton onClick={() => setDismissedQRTutorial(true)} />
    }]);
    setRightButtons([{component: <div />}]);
    return () => {
      // When this component dismounts...
      setCenterButtons([]);
    }

  }, [])

  return (
    <PrometricWrapper>
      <Heading>
        Quantitative Reasoning
      </Heading>
      <Section>Time limit: 45 minutes</Section>
      <Section>This examination is comprised of 40 questions.</Section>
      <NextText />
    </PrometricWrapper>
  );
}

export default QRTutorial;
