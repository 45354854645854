import React from 'react';
import {Link} from 'react-router-dom';

import {IconButton} from '../../../components/Branding/Buttons';
import {H2, Label3} from '../../../components/Typography';

import {ArrowRight} from '@styled-icons/heroicons-outline/ArrowRight';

import styled, {css} from 'styled-components';


const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const Button = styled(IconButton).attrs(props => ({size: 8}))`
  width: 56px;
  height: 56px;
  min-width: 56px;
  box-shadow: none;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: 0px;
  transition: all 100ms ease;

  &:hover {
    box-shadow: none;
    background: ${({theme}) => theme.overlays.opacity.light._200};
  }
`;

const Container = styled(Link)`
  flex: 1;
  color: white;
  border-radius: 8px;
  margin-right: ${({theme}) => theme.layouts.spacing.xl};
  position: relative;
  overflow: hidden;
  text-decoration: none;
  transition: all 100ms ease;

  ${Background} {
    opacity: ${({active}) => active ? 0.15 : 1};
    background: ${({backgroundColor, theme, active}) => active ? backgroundColor : theme.colors.special.naturalDark};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => `${theme.layouts.spacing.m}`};
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: ${({theme}) => theme.elevation.shadow.high};

    ${Button} {
      box-shadow: none;
      background: ${({theme}) => theme.overlays.opacity.light._200};
    }
  }

  &:last-child {
    margin: 0;
  }

  ${({active}) => !active && css`
    cursor: default;
    box-shadow: ${({theme}) => theme.elevation.inner.medium};
    &:hover {
      transform: scale(1);
      box-shadow: ${({theme}) => theme.elevation.inner.medium};
    }
  `}
`;
const Wrapper = styled.div`
  margin: 1px;
  padding: ${({theme}) => theme.layouts.spacing.l};
  border-radius:  8px;

  ${({theme}) => theme.mediaQueries.laptop} {
    padding: ${({theme}) => `${theme.layouts.spacing.l}`};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.xl}`};
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextWrapper = styled.div`
  margin-right: auto;
  align-self: center;
`;
const Text = styled(Label3)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  font-weight: ${({theme}) => theme.font.weight.semibold};
  text-transform: uppercase;
`;
const SubText = styled(H2)`
  color: ${({theme}) => theme.overlays.opacity.light._500};
`;
const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};

  ${({textLayout}) => textLayout === 'row' && css`
    margin-bottom: 0;

    ${TextWrapper} {
      margin-left: ${({theme}) => theme.layouts.spacing.l};
    }
  `}

  ${({theme}) => theme.mediaQueries.laptop} {
    /* flex-direction: column; */
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: row;
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const Generic = ({className, icon, linkTo, active, color, backgroundColor, text, subText, textLayout='column', useAnchor=false}) => {
  const containerProps = useAnchor ? {href: active && linkTo} : {to: active && linkTo}
  return (
    <Container as={useAnchor ? 'a' : Link} className={className} {...containerProps} color={color} backgroundColor={backgroundColor} active={active}>
      <Background/>
      <Wrapper>
        <Top textLayout={textLayout}>
          {icon}
          {textLayout === 'row' &&
            <TextWrapper>
              <SubText>{subText}</SubText>
              <Text>{text}</Text>
            </TextWrapper>
          }
          {active && (
            <Button>
              <ArrowRight size={21}/>
            </Button>
          )}
        </Top>
        {textLayout === 'column' &&
          <Bottom>
            {/*!!progress
              ? <Text>Review {progress} questions</Text>
              : (
                <InactiveText color={color}>
              Once you tag questions as <span>{deSlugify(level)}</span> you can study them here.
                </InactiveText>
              )
            */}
            <TextWrapper>
              <Text>{text}</Text>
              <SubText>{subText}</SubText>
            </TextWrapper>
          </Bottom>
        }
      </Wrapper>
    </Container>
  );
}

Generic.defaultProps = {};
Generic.propTypes = {};

export default Generic;
