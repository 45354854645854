function handleError(message, error) {
  // TODO add sentry reporting here
  if (error.errors?.[0]?.originalError?.response?.data?.errors?.[0]?.errorType == 'BadRequestException' && !document.getElementById('clockAlert')) {
    const alertWindow = document.createElement('div');
    alertWindow.id = 'clockAlert';
    alertWindow.style.border = '1px solid red';
    alertWindow.style.padding = '15px';
    alertWindow.style.zIndex = '10000';
    alertWindow.style.position = 'relative';
    alertWindow.innerHTML = 'Your system clock is out of sync, so Bootcamp.com will not function properly. Please synchronize your system clock, then reload this page.  '
    // const bootcampLink = document.createElement('a');
    // bootcampLink.href = 'https://help.datbootcamp.com/en/articles/5902400-how-to-sync-your-computer-s-clock';
    // bootcampLink.innerHTML = 'Click to read our Bootcamp.com support article for helpful instructions.';
    // bootcampLink.target = "_blank";
    // alertWindow.appendChild(bootcampLink);
    document.getElementById('root').prepend(alertWindow);
  }
  console.log(message, JSON.stringify(error, null, 2));
}

export {
  handleError,
}
