import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {PageSection} from '../ClassroomContentWrapper';
import {usePerformance} from '@bootcamp/web/src/bootcamps/dat/Performance';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {PerformanceChart} from '@bootcamp/web/src/bootcamps/dat/Performance/PerformanceChart';
import {Subject} from '@bootcamp/web/src/bootcamps/dat/Performance/Subject';
import SubjectPerformance from './SubjectPerformance';
import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';


const Container = styled.div`
`;

const DATPerformance = ({subject, content, themePalette}) => {
  const {isBootcampPlusStudent, bootcamp, searchUserInteractions, DEFAULT_USER_ID, saveUserInteraction} = useUserDataContext();

  const {config} = useBootcampConfigContext();
  const {dataBySubject, dataByTest, fetching} = usePerformance(isBootcampPlusStudent, config, bootcamp, searchUserInteractions, DEFAULT_USER_ID, saveUserInteraction);
  const averages = dataByTest[0].map((_, index) => {
    // transpose breakdown
    const subjectScores = dataByTest.map(test => test[index]).filter(({score}) => score !== '-');
    if (!subjectScores.length) return {content: '-'}
    const subjectAverage = subjectScores.reduce((acc, {score}) => acc + parseInt(score), 0) / subjectScores.length;
    return {content: Math.round(subjectAverage)}
  })

  return (
    <Container>
      <PageSection>
        <PerformanceChart averages={averages} fetching={fetching} subjectOverride={subject} />
        <div style={{marginTop: 32}} />
        <Subject dataBySubject={dataBySubject.filter(({testSubject}) => testSubject == subject)} expandAll />
      </PageSection>
    </Container>
  );
};

const Wrapper = props => {
  const {bootcamp} = useUserDataContext();
  const Component = bootcamp === 'inbde' ? SubjectPerformance : DATPerformance;

  return <Component {...props} />;
};

Wrapper.propTypes = {
};
Wrapper.defaultProps = {
};

export default Wrapper;
