import React from 'react';

import {Column, Row} from '../Grid';
import {Button, TextButton} from '../Branding/Buttons';
import {Loader} from '../Branding';
import {H2, Body3} from '../Typography';
import Input from './Input';
import Checkbox from './Checkbox';
import Menu from './Menu';

import styled from 'styled-components';

const Container = styled(Column)`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  box-shadow: ${({theme}) => theme.elevation.shadow.high};
  border-radius: 8px;
  height: auto;
  max-width: 528px;
  margin: auto;
  align-items: center;
`;
const Body = styled(Column)`
  padding: ${({theme}) => theme.layouts.spacing.xl} ${({theme}) => theme.layouts.spacing.xxl};
  align-items: center;

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`}
  }
`;
const Header = styled(H2)`
  width: 100%;
  text-align: center;
`;
const SubHeader = styled(Body3)`
  text-align: center;
  margin-top: ${({theme}) => theme.layouts.spacing.xs};
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const FieldGroup = styled.div`
  width: 100%;
  margin: ${({theme}) => theme.layouts.spacing.l} auto 0;
`;
const SubmitContainer = styled(Row)`
  height: auto;
  padding: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.xxl}`};
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 0px #E5E4E8;
`;
const Submit = styled(Button).attrs(props => ({
  size: 'large',
  type: 'primary',
  fixed: true,
  buttonProps: {
    type: 'submit'
  }
}))`
  ${({theme}) => theme.mediaQueries.mobileL} {
    font-size: 18px;
    min-width: 250px;
  }
`;
const SubmitButton = ({loading, children, ...props}) => (
  <Submit
    color={'indigo'}
    children={loading ? <Loader active size={16}/> : children}
    {...props}
  />
)

const LinkButtonContainer = styled.div`
  width: 100%;
  padding: ${({theme}) => theme.layouts.spacing.m} 0 ${({theme}) => theme.layouts.spacing.s} 0;
  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 8px 0px;
  }
`;

const LinkButton = ({children}) => (
  <LinkButtonContainer>
    <TextButton children={children} />
  </LinkButtonContainer>
)

export {
  Input,
  Checkbox,
  Menu,
  Container,
  Body,
  Header,
  SubHeader,
  FieldGroup,
  SubmitContainer,
  SubmitButton,
  LinkButton,
};
