import React, {useState} from 'react';
import styled from 'styled-components';

import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';

import {SubmitAnatomyCase as SubmitModal} from '@bootcamp/web/src/components/Modal';

import {ReactComponent as Strikethrough} from '../assets/pencil.svg';

import {ReactComponent as Flag} from '../assets/flag-outline.svg';
import {ReactComponent as Clock} from '../assets/clock.svg';
import {QuestionIcon} from '../../NCLEXQBank/shared';
import {CaretDown} from '@styled-icons/fluentui-system-filled/CaretDown';
import {useHotkeys} from 'react-hotkeys-hook';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  * {
    user-select: none;
  }
`;

const Top = styled.div`
  display: flex;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.dark};
  align-items: center;
  height: 45px;
  padding: 0 10px;
  color: white;
  * {
    font-family: Tahoma;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 36px;
  }
`;
const Middle = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.light};
  height: 34px;
  padding: 0 10px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  color: white;
  * {
    font-family: Verdana !important;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    border: none;
    height: 28px;
    font-size: 12px;
  }
`;
const Bottom = styled.div`
  display: flex;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.grey};
  padding: 0px 4px;
  height: 28px;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const Left = styled.div`
  font-size: 21px;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`;
const TopMiddle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  ${({theme}) => theme.mediaQueries.tablet} {
    justify-content: flex-start;
  }
`;
const Right = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: row;
  }
`;
const Time = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2px;
  span {
    margin-right: 4px;
  }
  .timerText {
    ${({showingTime}) => !showingTime && `display: none;`}
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    justify-content: center;
    span {
      display: none;
    }
  }
  &:hover {
    color: yellow;
    ${ClockIcon} {
      path {
        fill: yellow;
      }
    }
  }

`;
const ClockIcon = styled(Clock)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  cursor: pointer;
`;
const Count = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    ${({showingCount}) => !showingCount && `display: none;`}
  }
  &:hover {
    color: yellow;
    ${CountIcon} {
      rect:nth-child(1) {
        fill: yellow;
      }
      rect:nth-child(2) {
        fill: yellow;
      }
      rect:nth-child(3) {
        fill: yellow;
      }
      rect:nth-child(4) {
        stroke: yellow;
      }
    }
  }
`;
const CountIcon = styled(QuestionIcon)`
  margin-right: 4px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const FlagWrapper = styled(IconWrapper)`
  margin-left: auto;

  &:hover {
    color: yellow;
    path {
      stroke: yellow;
    }
  }
`;
const Highlight = styled(IconWrapper)`
  margin-right: 16px;
`;
const Swath = styled.div`
  background: ${({theme, mode}) => mode === 'add' ? theme.colors.prometricPalette.mcat.highlight : theme.colors.prometricPalette.mcat.light};
  width: 18px;
  height: 18px;
  border: 2px solid white;
`;
const HighlightText = styled.span`
  &:hover {
    color: yellow;
  }
`;
const StrikethroughIcon = styled(Strikethrough)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
const Caret = styled(CaretDown)`
  color: white;
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;
const FlagIcon = styled(Flag)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: ${({marked}) => marked ? 'yellow' : 'none'};
`;
const PauseButton = styled.button`
  cursor: pointer;
  height: 22px;
  padding: 0px 10px;
  background: #F4F4F4;
  border: 1px solid white;
  font-family: Verdana;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
`;

const TopBar = ({highlightTooltipRef, strikethroughTooltipRef, highlightMode}) => {
  const [showingTime, setShowingTime] = useState(true);
  const [showingCount, setShowingCount] = useState(true);
  const {
    methods: {
      markQuestion,
      handleGoBack,
    },
    variables: {
      blockIndex,
      quizProgress,
      customTestConfig,
      type,
      testBlockConnections
    }
  } = useTestNavigatorContext();

  const {DEFAULT_USER_ID, cognitoUser} = useUserDataContext();
  const {timeElapsed, longTimeLeft} = useTimerContext();
  const {modalDispatch} = useModalContext();
  const timed = false;
  const {testBlock} = testBlockConnections[blockIndex];

  const {questions: questionProgresses} = quizProgress[blockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  const totalQuestions = quizProgress.reduce((acc, {questions}) => questions ? acc + questions.length : acc, 0);
  const globalQuestionIndex = quizProgress.slice(0, blockIndex).reduce((acc, {questions}) => questions ? acc + questions.length : acc, 0) + questionIndex + 1;

  const didMark = questionProgresses[questionIndex]?.didMark || questionProgresses[questionIndex]?.bookmarked;

  const modalText = {
    headerText: 'Would you like to pause your review?',
    bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
    confirmText: 'Pause Review',
    cancelText: 'Return to Review'
  };

  useHotkeys('option+f', markQuestion, [markQuestion]);

  const handlePause = () => modalDispatch({
    type: 'open',
    modalContainerStyle: theme => `
      background: transparent;
    `,
    component: ({close}) => {
      return (
        <SubmitModal
          prometricStyling
          headerText={modalText.headerText}
          bodyText={modalText.bodyText}
          confirmConfig={{
            text: modalText.confirmText,
            onConfirm: async () => {
                // if (type === 'readinessExam') {

                //   if (!isReview) {
                //     udpateQuestionTime();
                //     // make sure current question state is saved
                //     await saveAndTrackProgress();
                //     // auto tag questions
                //     await autoTagOnSubmit();
                //   }

                //   toggleReloadBlocker(false);
                //   setTimeout(() => replace(`/${bootcamp}/previous-tests/review?id=${test.id}&testProgressId=${encodeURIComponent(testProgressId || urlTestProgressId)}&testRoute=nclex/next-gen-readiness-exams/qbanks/${testRoute}`), 100);
                //   return;
                // }

                // const testStorageKey = customTestConfig?.config?.testStorageKey;

                // try {
                //   // update custom test config to indicate suspension
                //   const updatedConfig = {
                //     ...customTestConfig,
                //     suspended: false,
                //     timeLeft: null,
                //     submitted: true,
                //   };

                //   await Storage.put(testStorageKey, JSON.stringify(updatedConfig), {contentType: 'application/json' });

                // } catch (error) {
                //   console.log('error marking test as submitted', error);
                // }

                // if (customTestConfig?.config && !customTestConfig.submitted) {
                //   // only track performance if not in tutor mode
                //   if (!tutorMode) {
                //     await trackNCLEXQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
                //   }
                //   // auto tag in all custom tests
                //   await autoTagOnSubmit();
                // }
              handleGoBack();
            }
          }}
          cancelConfig={{
            text: modalText.cancelText,
            onCancel: () => {
              close();
            }
          }}
        />
      )
    },
    enableClickClose: false,
  })

  if (testBlock.type === 'endBlock') {
    return (
      <Container>
        <Top>
          <Left>Medical College Admission Test - {cognitoUser?.attributes?.name || DEFAULT_USER_ID}</Left>
          <TopMiddle>Bootcamp.com | MCAT</TopMiddle>
          <Right>
            <Time onClick={() => setShowingTime(!showingTime)}>
              <ClockIcon/>
              {timed ? 'Time Remaining' : 'Time Elapsed'}: {timed ? longTimeLeft : timeElapsed}
            </Time>
          </Right>
        </Top>
      </Container>
    )
  }
  return (
    <Container>
      <Top>
        <Left>Medical College Admission Test - {cognitoUser?.attributes?.name || DEFAULT_USER_ID}</Left>
        <TopMiddle>Bootcamp.com | MCAT</TopMiddle>
        <Right>
          <Time onClick={() => setShowingTime(!showingTime)} showingTime={showingTime}>
            <ClockIcon/>
            <span className="timerText"><span>{timed ? 'Time Remaining' : 'Time Elapsed'}:</span> {timed ? longTimeLeft : timeElapsed}</span>
          </Time>
          <Count onClick={() => setShowingCount(!showingCount)} showingCount={showingCount}>
            <CountIcon/>
            <span>{globalQuestionIndex} of {totalQuestions}</span>
          </Count>
        </Right>
      </Top>
      <Middle>
        <Highlight ref={highlightTooltipRef}>
          <Swath mode={highlightMode}/>
          <Caret/>
          <HighlightText>
            <u>H</u>ighlight
          </HighlightText>
        </Highlight>
        <IconWrapper ref={strikethroughTooltipRef}>
          <StrikethroughIcon/>
          <u>S</u>trikethrough
        </IconWrapper>
        <FlagWrapper onClick={markQuestion}>
          <FlagIcon marked={didMark}/>
          <span style={{textDecoration: 'underline'}}>F</span>lag for Review
        </FlagWrapper>
      </Middle>
      <Bottom>
        <PauseButton onClick={handlePause}>Pause</PauseButton>
      </Bottom>
    </Container>
  );
};

TopBar.defaultProps = {};
TopBar.propTypes = {};

export default TopBar;