import React from 'react';
import styled from 'styled-components';

import Dropdown from '../Dropdown';
import Row from './Row';

import {RoundButton} from '../../../../components/UI';
import ProgressBar from '../../../../components/ProgressBar';
import {H5} from '../../../../components/Typography';
import {Row as FlexRow} from '../../../../components/Grid';
import {getTestBlockConnections, getRuntime} from '@bootcamp/shared/src/util';
import {useUserDataContext} from '../../../../contexts/UserData';
import moment from 'moment';

const Details = styled(H5)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin: 0 !important;
  line-height: 21px;
`;
const Progress = styled(ProgressBar)`
  max-width: 630px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    align-items: flex-start;
    margin: ${({theme}) => theme.layouts.spacing.m} 0 0;
  }
`;
const CTAWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: flex-end;

  ${({theme}) => theme.mediaQueries.mobileL} {
    flex-direction: row-reverse;
    width: 32px;
    margin-left: 16px;
    margin-top: 0px;
  }
`;

const ProgressBarWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  ${({hidden}) => hidden && `visibility: hidden;`}
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-top: 8px;
  }
`;


const VideoLesson = props => {
  const {config: {route, name, id, section}, videoLessonData: {test, quizProgress}, saveBlockProgress, searchTerm, themePalette, locked, chapterIndex} = props;
  const {isUpgraded, bootcamp} = useUserDataContext();
  // if (!test || !quizProgress) return null;

  // if (!test || !quizProgress) return <LoadingContainer children={<LoadingSpinner color={'white'} size={24} />} />
  const testBlockConnections = getTestBlockConnections(test) || [];
  const testBlocks = testBlockConnections.reduce((acc, {testBlock}) => testBlock ? [...acc, testBlock] : acc, []);

  const completed = quizProgress?.reduce((acc, blockProgress) => blockProgress.status === 'complete' ? acc + 1 : acc, 0) || 0
  const total = testBlocks.filter(testBlock => !['startBlock', 'endBlock'].includes(testBlock.type)).length

  const activeBlock = quizProgress?.findIndex(blockProgress => blockProgress.status === '');

  function isFilteredBlock (testBlock) {
    if (['startBlock', 'endBlock'].includes(testBlock.type) || !testBlock.title) return false;
    if (!searchTerm) return true;
    const testBlockKeywords = testBlock.components.find(({renderType}) => renderType === 'keywords')?.contents || '';
    return testBlock.title.toLowerCase().includes(searchTerm.toLowerCase()) || name.toLowerCase().includes(searchTerm.toLowerCase()) || searchTerm.toLowerCase().split(' ').every(key => testBlockKeywords.toLowerCase().includes(key));
  }

  if (testBlocks.length > 0 && !testBlocks.some(testBlock => isFilteredBlock(testBlock))) {
    return null;
  }

  const targetIndex = searchTerm ? testBlocks.findIndex(testBlock => isFilteredBlock(testBlock)) : activeBlock > -1 ? activeBlock : 1;
  const blockLink = locked ? '#' : `videos/${route}?index=${targetIndex}`;

  const urlParams = new URLSearchParams(window.location.search);
  const selectedSection = urlParams.get('section');

  if (selectedSection && selectedSection !== 'all' && selectedSection !== section) return null;
  const rows = testBlocks.reduce((acc, testBlock, index) =>
    isFilteredBlock(testBlock)
      ? [
        ...acc,
          <Row
            index={index}
            key={testBlock.id}
            testBlock={testBlock}
            blockProgress={quizProgress[index]}
            saveBlockProgress={(value, blockIndex) => saveBlockProgress(id, value, blockIndex)}
            themePalette={themePalette}
            route={route}
            locked={bootcamp === 'nclex' ? false : bootcamp === 'dat' && ['Molecules and Fundamentals of Biology', 'General and Lab Concepts Review', 'Bonding and Molecular Geometry', 'Keyholes', 'RC Questions', 'Functions and Expressions'].includes(name) ? false : (bootcamp !== 'inbde' && !isUpgraded && index > 2 && !window.location.pathname.includes('application-services'))}
            bootcamp={bootcamp}
          />
      ] : acc, []);

  return (
    <Dropdown
      route={blockLink}
      name={name}
      index={chapterIndex}
      expandOnChange={searchTerm}
      textContent={(
        <ProgressBarWrapper>
          <Progress
            loading={!test}
            segments={[{count: completed, color: themePalette?.gradient || 'white'}]}
            total={total}
            label={<span> <strong>{completed > total ? total : completed}</strong> {`of ${total} lessons completed ${getRuntime(testBlocks)}`}</span>}
            lightMode
          />
          <Details>
          </Details>
        </ProgressBarWrapper>
      )}
      actions={[
        <CTAWrapper>
          <RoundButton small themePalette={themePalette}>
            {completed > 0 ? 'Continue' : 'Start'}
          </RoundButton>
        </CTAWrapper>
      ]}
      {...props}
    >
      {rows}
    </Dropdown>
  );
};

export default VideoLesson;
