import React from 'react';
import {useFormContext} from 'react-hook-form';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-flex;
  height: 45px;
  margin-right: 12px;
  border-radius: 5px;
`;

const Select = styled.select`
  cursor: pointer;
  font-size: 16px;
  border: none;
  min-width: ${({minWidth}) => minWidth || '225px'};
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%;
  background-size: 12px;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
  background-color: #f2f5f7;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

function defaultRenderOptions(options) {
  return options.map((option, index) => <option key={`menu-option-${index}`} value={option}>{option}</option>);
}

const Menu = ({options, name, disabled, minWidth, renderOptions}) => {
  const {register} = useFormContext();
  return (
    <Wrapper>
      <Select
        ref={register}
        name={name}
        disabled={disabled}
        children={renderOptions(options)}
        minWidth={minWidth}
        />
    </Wrapper>
  );
}

Menu.defaultProps = {
  renderOptions: defaultRenderOptions
}

export default Menu;
