import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import {IconButton} from '../../../Branding/Buttons';
import {ToggleSidebar} from '../../../Branding/Buttons/iconButtons';

import SideBar, {sideBarWidth} from '../../../../router/components/SideBar';
import {Menu as MenuIconImport} from '@styled-icons/evaicons-solid/Menu';


const Container = styled.div`
  display: none;
  position: fixed;
  top: ${({barHeight}) => barHeight}px;
  left: 0;
  top: 0;
  right: 0;
  width: ${({active}) => active ? '100%' : 0};
  height: ${({active}) => active ? '100vh' : 0};
  z-index: 10000000;
  opacity: ${({active}) => active ? 1 : 0};
  background: ${({theme, active}) => active ? theme.overlays.opacity.dark._400 : 'transparent'};
  transition: opacity .45s;

  ${({theme}) => theme.mediaQueries.laptop} {
    display:  initial;
  }
`;
const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;
const MenuIcon = styled(MenuIconImport)`
  width: 20px;
  height: 20px;
  color: white;

  ${({theme}) => theme.mediaQueries.tablet} {
    width: 22px;
    height: 22px;
  }
`;
const MenuButton = styled(IconButton)`
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  padding: 10px;
  height: auto;
  width: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 8px;
  }
`;
const StyledSideBar = styled(SideBar)`
  display: initial;
  width: ${({active}) => active ? sideBarWidth : 0}px;
  transition: width .2s;
  top: 0;

  ${({theme}) => theme.mediaQueries.mobileM} {
    width: ${({active}) => active ? '100%' : 0};
  }
`;
const HideButton = styled(ToggleSidebar)`
  width: 42px;
  height: 42px;
  padding: 10px;
`;

// TODO once this is polished we can go through and remove unused vars etc.. Leaving in temporarily for now just in case
// there's anything we want to keep / will need
const Menu = withRouter(({className, history, setMenuOpen, menuOpen, barHeight, barHeightMobile, fullWidth, renderMenuItems, nonContextConfig}) => {
  const ref = useRef();

  function handleClick(event) {
    if (!menuOpen) return;

    const canDoWork = ref && ref.current && ref.current.contains && event && event.target;
    const isInside = canDoWork && ref.current.contains(event.target);

    if (!isInside) setMenuOpen(false);
  }

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [menuOpen]);

  return (
    <Container barHeight={barHeight} barHeightMobile={barHeightMobile} active={menuOpen}>
      <Wrapper ref={ref} active={menuOpen}>
        <StyledSideBar active={menuOpen}>
          <HideButton onClick={() => setMenuOpen(false)}/>
        </StyledSideBar>
      </Wrapper>
    </Container>
  );
});


export default Menu;
export {MenuIcon, MenuButton};
