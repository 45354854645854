import React from 'react';

import {Row, FlexBox} from '../../../Grid';
import {LinkButton} from '../../../Branding/Buttons';

import {ChevronRight} from '@styled-icons/feather/ChevronRight';

import {deSlugify} from '@bootcamp/shared/src/util';

import styled from 'styled-components';


const Container = styled(FlexBox)`
  align-items: center;
  overflow: hidden;
  height: auto;
`;
const Breadcrumb = styled(LinkButton)`
  width: auto;
  overflow: hidden;
  white-space: nowrap;

  button {
    display: inline-block;
    color: ${({theme, active}) => active ? theme.overlays.opacity.light._300 : theme.colors.neutralsPalette.white};
    width: 100%;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 0;

    &:hover {
      color: ${({theme}) => theme.overlays.opacity.light._400};
    }

    &:disabled {
      color: ${({theme, active}) => theme.overlays.opacity.light._300};
    }

    ${({active}) => active && `
      cursor: default;
    `}
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    font-size: ${({theme}) => theme.font.size.medium};
    line-height: ${({theme}) => theme.font.height.small2};
  }
`;
const Wrapper = styled(Row)`
  align-items: center;
  width: auto;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.tablet} {
    &:first-child {
      display: ${({index, activeDepth}) => activeDepth === 1 && index === 0 ? 'flex' : 'none'};
    }
  }
`;
const Separator = styled(ChevronRight)`
  display: flex;
  width: 10px;
  height: 10px;
  color: ${({theme}) => theme.overlays.opacity.light._300};
  stroke-width: 4px;
  margin: 0px 8px;
  ${({theme, index, activeDepth}) => `${theme.mediaQueries.tablet} {${index === 1 && activeDepth > 1 && `display: none;`}}`}
`;

const slugOverrides = {
  'faqs': 'FAQs',
  'upper-and-lower-limbs-back': 'Upper & Lower Limbs, Back',
  'gluteal-region-posterior-thigh-popliteal-fossa': 'Gluteal Region, Posterior Thigh, Popliteal Fossa',
  'thorax-abdomen-and-pelvis': 'Thorax, Abdomen & Pelvis',
  'anatomy-and-physiology': 'Anatomy and Physiology',
  'self-assessment': 'Self-Assessment',
}

const getCrumbText = (crumb) => slugOverrides[crumb] || deSlugify(crumb);

const Breadcrumbs = ({className, history, lockAtDepth, customActiveTitle}) => {
  const basePaths = history.location.pathname.split('/').slice(1);
  const paths = basePaths.slice(0, lockAtDepth || basePaths.length);

  const activeDepth = paths.filter(path => !!path).length - 1;

  const crumbs = paths.map((crumb, index) => {
    if (!crumb) return null;

    const text = getCrumbText(crumb);
    const link = crumb === 'account' ? 'account' : paths.slice(0, index + 1).join('/');
    const active = index === activeDepth;

    return (
      <Wrapper key={`crumb-${link}`} index={index} activeDepth={activeDepth}>
        {index > 0 && <Separator index={index} activeDepth={activeDepth}/>}
        <Breadcrumb
          to={`/${link}`}
          buttonType={'text'}
          children={active && customActiveTitle ? customActiveTitle : index === 0 ? 'Home' : text}
          active={active}
          disabled={active}
        />
      </Wrapper>
    );
  });

  return (
    <Container className={className}>
      {crumbs}
    </Container>
  );
}

export default Breadcrumbs;
