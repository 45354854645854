import React, {useState, useCallback} from 'react';
import styled, {css} from 'styled-components';

import {Plus} from '@styled-icons/evaicons-solid/Plus';
import {Minus} from '@styled-icons/evaicons-solid/Minus';

import {H2, H3, Body2} from '../../components/Typography';
import {IconButton} from '../../components/Branding/Buttons';
import {Row} from '../../components/Grid';

import {useAsync} from '../../hooks';
import API from '@aws-amplify/api';
import { Cache } from 'aws-amplify';
import {useUserDataContext} from '../../contexts/UserData';
import firstBy from 'thenby';

const ToggleButton = styled(IconButton).attrs(props => ({size: 8}))`
  width: 32px;
  height: 32px;
  box-shadow: none;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  padding: 8px;
  transition: all 100ms ease;
  &:hover {
    box-shadow: none;
    background: rgba(255,255,255,0.2);
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 32px;
    height: 32px;
  }
`;

const ElementContainer = styled.div`
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border-radius: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const TitleBar = styled(Row)`
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px 24px;
  user-select: none;
  cursor: pointer;
  align-items: center;
  &:hover {
    ${({theme, expanded}) => !expanded && css`background: rgba(255,255,255,0.2)`};

  ${ToggleButton} {
    ${({theme, expanded}) => !expanded && css`background: rgba(255,255,255,0.2)`};
  }
}



${({theme}) => theme.mediaQueries.mobileL} {
  padding: ${({theme}) => theme.layouts.spacing.l};
}`;

const Title = styled(H3)`
  color: white;
  flex: 1;
`;

const Text = styled(Body2)`
  color: white;
  padding: 16px 24px;
  padding-top: 0;

  p {
    margin: 0;
  }

  a {
    color: white;
  }
`;

export const Element = ({title, text}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <ElementContainer onClick={() => !expanded && setExpanded(true)}>
      <TitleBar expanded={expanded} onClick={() => expanded && setExpanded(false)}>
        <Title dangerouslySetInnerHTML={{__html: title}} />
        <ToggleButton children={expanded ? <Minus size={20} /> : <Plus size={20}/>}/>
      </TitleBar>
      {expanded && <Text dangerouslySetInnerHTML={{__html: text}} />}
    </ElementContainer>
  )
}

const Container = styled.div`
  width: 100%;
`;

export const Heading = styled(H2)`
  color: white;
  margin-bottom: 16px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;

const FAQs = ({heading="Frequently Asked Questions"}) => {
  const {bootcamp} = useUserDataContext();

  const fetchFAQs = useCallback(async () => {
    try {
      const cachedFAQs = Cache.getItem(`${bootcamp}-FAQs`);
      if (cachedFAQs) return cachedFAQs;

      const result = await API.get('webflow', `/faqs/${bootcamp}`);
      const fetchedFAQs = result?.items?.items || [];

      if (fetchedFAQs.length > 0) {
        const expires = (new Date()).getTime() + (60 * 1000) // in a minute
        Cache.setItem(`${bootcamp}-FAQs`, fetchedFAQs, {expires});
      }

      return fetchedFAQs
    } catch (e) {
      console.log(e);
      return [];
    }
  }, [bootcamp]);

  const {value: items} = useAsync(fetchFAQs);
  if (items?.length === 0) return null;

  return (
    <Container>
      {heading &&
        <Heading children={heading}></Heading>
      }
      {items?.sort(firstBy('order')).map((item) => (
        <Element
          title={item['question-copy']}
          text={item['answer']}
        />
      ))}
    </Container>
  )
}
export default FAQs;
