import React, {useState} from 'react';
import styled from 'styled-components';
import {H2, H4, Body2, Body3} from '@bootcamp/web/src/components/Typography';
import {Button} from '@bootcamp/web/src/components/Branding/Buttons';
import Switch from 'rc-switch';
import "rc-switch/assets/index.css";
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 64px;
  max-width: 600px;
  overflow: auto;
`;

const Header = styled(H2)`
  text-align: left;
  margin-bottom: 16px;
`;

const Body = styled(Body2)`
  color: #585962;
  margin-bottom: 16px;
`;

const OptionBox = styled.div`
  border: 1px solid #EDEDF0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px;
`;
const OptionContents = styled.div`
  max-width: 85%;
`
const OptionDescription = styled(Body3)`
  margin-top: 4px;
`;
const OptionTitle = styled(H4)`
`;

const StyledSwitch = styled(Switch)`
  &:after {
    transform: none !important;
    animation-name: none !important;
  }
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #EDEDF0;
  padding: 16px 0px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const Confirm = styled(Button)`
  margin-top: 32px;
  margin-left: auto;
`;


const QBankSettings = ({close, themePalette, hide=[]}) => {
  const {saveUserInteraction, searchUserInteractions, bootcamp} = useUserDataContext();

  const hideAnswerData = searchUserInteractions('hideAnswerData') === true;
  const hideTimer = searchUserInteractions('hideTimer') === true;
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;
  const prometricDelay = searchUserInteractions('prometricDelay') === true;

  return (
    <Container onClick={e => e.stopPropagation()}>
      <Header>Question Bank Settings</Header>
      <Body>
        Customize your question bank experience using the options below.
      </Body>
      <OptionBox>
        {bootcamp !== 'chemistry' && 
          <Option>
            <OptionTitle>Show Answer Stats</OptionTitle>
            <StyledSwitch checked={!hideAnswerData} onChange={() => saveUserInteraction('hideAnswerData', !hideAnswerData)} />
          </Option>
        }
        {!hide.includes('timer') &&
          <Option>
            <OptionTitle>Show Timer</OptionTitle>
            <StyledSwitch checked={!hideTimer} onChange={() => saveUserInteraction('hideTimer', !hideTimer)} />
          </Option>
        }
        {!hide.includes('shortcuts') &&
          <Option>
            <OptionTitle>Show Keyboard Shortcuts</OptionTitle>
            <StyledSwitch checked={showKeyboardShortcuts} onChange={() => saveUserInteraction('showKeyboardShortcuts', !showKeyboardShortcuts)} />
          </Option>
        }
        {bootcamp === 'nclex' &&
          <Option>
            <OptionContents>
              <OptionTitle>Enable NCLEX Restrictions</OptionTitle>
              <OptionDescription>Adds a 0.25s delay between questions to simulate the Pearson testing experience. Disables the ability to cross out or highlight.</OptionDescription>
            </OptionContents>
            <StyledSwitch checked={prometricDelay} onChange={() => saveUserInteraction('prometricDelay', !prometricDelay)} />
          </Option>
        }
      </OptionBox>
      <Confirm fixed onClick={close} colorConfig={themePalette}>
        Save and Continue
      </Confirm>
    </Container>
  )
}

export default QBankSettings;
