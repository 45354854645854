import {getTest, getAllQuestionMastery, getQuestionForTest} from '@bootcamp/shared/src/requests';
import {getRandom, asyncFetchWithS3Cache, capitalize} from '@bootcamp/shared/src/util';

import Storage from '@aws-amplify/storage';
import moment from 'moment';


export const composeQuickStudyByContentType = async (config, userId, bootcamp, subjectIds, contentType, testLength=10, onError) => {
  // fetch all question mastery so that we can filter for 'untagged' questions
  const allQuestionMastery = await Promise.all(subjectIds.map(subjectId => getAllQuestionMastery({
    userIdHashSubjectTagId: `${userId}#${subjectId}`,
    limit: 10000,
  }, 'subjectWithQuestionBaseId')));

  const allQuestionMasteryItems = allQuestionMastery.reduce((acc, masteryResult) => [...acc, ...masteryResult.data.QuestionMasteryBySubjectTagId.items], []);

  // subroutine for fetching & formatting a set of questions to create our quick study bank
  async function fetchDrawPile() {
    if (config.meta.siteTitle === 'Chemistry Bootcamp') {
      throw new Error('Chemistry Bootcamp is not yet supported for Quick Study.')
    }
    // format an array of testIds from config which match a certain style
    const testIds = contentType === 'anatomy'
      ? config.classrooms
        .filter(({name}) => name === 'Gross Anatomy')
        .reduce((acc, {contentTypes}) => [
          ...acc,
          ...contentTypes
            .reduce((acc, {content}) => [
              ...acc,
              ...content
                .reduce((acc, {name, content}) => ['Question Banks', 'Identify Structures', 'Lecture Style Questions'].includes(name) // only use QB questions for Quick Study
                  ? [
                    ...acc,
                    ...content.filter(({name}) => !name.match('Passage')) // filter out passage banks
                      .reduce((acc, {lessons, lectures, id}) => id ? [...acc, id] : (lessons || lectures)
                        ? [
                          ...acc,
                          ...([...(lessons || []), ...(lectures || [])]).map(({testId, id}) => testId || id)
                          ]
                        : acc, [])
                    ]
                  : acc, [])
            ], [])
        ], [])
      : config.classrooms
        .reduce((acc, {contentTypes}) => [
          ...acc,
          ...contentTypes
            .filter(({name}) => name.match(capitalize(contentType)))
            .reduce((acc, {content}) => ([
              ...acc,
              ...content
                .reduce((acc, {content}) => content?.map(({id}) => id), [])
            ]), [])
        ], []);

    // fetch all tests to so that we can create a question pool to pull from
    const results = await Promise.all(
      testIds.map(async testId => await getTest(testId, 'getTestQuestionIdsWithConfig'))
    );

    
    // question pool
    const questionPool = results
    ?.reduce((acc, {data: {getTest: {id: testId, blockConnections}}}) => [
      ...acc,
      ...blockConnections.items
      .reduce((acc, {testBlock}) => {
        if (testBlock?.questionConnections) {
          const isSequentialSet = JSON.parse(testBlock.components?.find(component => component.renderType === 'config')?.contents || 'null')?.isSequentialSet
          if (isSequentialSet) return acc;
          const mappedBlock = testBlock.questionConnections.items.filter(({question}) => question.status !== 'draft').map(({question}) => ({id: question.id, testId}))
          return [...acc, ...mappedBlock]
        }
        return acc
      }, [])
    ], []);

    if (questionPool.length === 0) {
      throw new Error('No questions in pool.')
    }
    return questionPool;
  }

  // subroutine for formatting test from a given set of question ids
  async function assembleTest(questionIds) {

    // helper for pulling all learning / mastered from mastery fetch
    function getListOfLearningReviewingQuestions() {
      return allQuestionMasteryItems
        .filter(({masteryLevel}) => ['learning', 'reviewing'].includes(masteryLevel))
        .map(({userIdHashQuestionBaseId, masteryLevel}) => ({id: userIdHashQuestionBaseId.replace(`${userId}#`, ''), masteryLevel}))
    }

    // fill qid array with learning/reviewing questions if need be
    const ids = questionIds.length < testLength
      ? [
          ...questionIds.map(question => ({...question, masteryLevel: 'none'})),
          ...getRandom(getListOfLearningReviewingQuestions(), testLength - questionIds.length)
        ]
      : getRandom(questionIds, testLength).map(question => ({...question, masteryLevel: 'none'}));

    const questions = await Promise
      .all(ids.map(async question => {
        const {data: {getQuestionBase}} = await getQuestionForTest(question.id, contentType === 'anatomy');
        return {...question, question: {...getQuestionBase, originalTestId: question.testId}}
      }))

    return {
      config: {
        title: `Quick Study`
      },
      id: '',
      blockConnections: {
        items: [
          {testBlock: {type: 'startQuickReview'}, index: 0},
          {
            testBlock: {
              type: 'questionSet',
              questionConnections: {
                items: questions
              }
            },
            index: 1
          },
          {testBlock: {type: 'endBlock'}, index: 2}
        ]
      }
    }
  }
  try {
    const configQuestionIds = await asyncFetchWithS3Cache(`json/${bootcamp}/questionIds/${contentType}`, moment().subtract(7, 'days'), fetchDrawPile);
    const excludedQuestionIds = allQuestionMasteryItems.map(({userIdHashQuestionBaseId}) => userIdHashQuestionBaseId.replace(`${userId}#`, ''));
    // if any configQuestionIds need to be filtered, do it now
    const filteredQuestionIds = configQuestionIds.filter(id => !excludedQuestionIds.includes(id));
    const composedTest = await assembleTest(filteredQuestionIds);
  
    return composedTest;
  } catch (error) {
    if (onError) onError()
  }
};

export const composeQuickStudy = async (config, userId, bootcamp, subjectIds, testLength=10, limitedPool=false) => {
  const allQuestionMastery = await Promise.all(subjectIds.map(subjectId => getAllQuestionMastery({
      userIdHashSubjectTagId: `${userId}#${subjectId}`,
      limit: 10000,
    }, 'subjectWithQuestionBaseId')
  ));

  const items = allQuestionMastery.reduce((acc, masteryResult) => [...acc, ...masteryResult.data.QuestionMasteryBySubjectTagId.items], []);
  const questionIds = items.map(({userIdHashQuestionBaseId}) => userIdHashQuestionBaseId.replace(`${userId}#`, ''));
  // So, we know which questions we DON'T want.

  const fetchDrawPile = async () => {
    const storageKey = `json/${bootcamp}/${['dat', 'oat', 'inbde'].includes(bootcamp) ? 'quickStudyConfigIds' : 'lambdaQuestionConfigIds'}.json`;
    let configQuestionIds = [];

    try {
      const fetchConfigQuestionIds = await fetch(`https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.us-east-1.amazonaws.com/public/${storageKey}`, {cache: 'no-cache'});
      configQuestionIds = await fetchConfigQuestionIds.json();
    } catch {
      const testIds = config.classrooms
        .reduce((acc, {contentTypes}) => [
          ...acc,
          ...contentTypes
            .reduce((acc, {content}) => [
              ...acc,
              ...content
                .reduce((acc, {name, content}) => ['Question Banks', 'Identify Structures', 'Lecture Style Questions'].includes(name) // only use QB questions for Quick Study
                  ? [
                    ...acc,
                    ...content.filter(({name}) => !name.match('Passage')) // filter out passage banks
                      .reduce((acc, {lessons, lectures, id}) => id ? [...acc, id] : (lessons || lectures)
                        ? [
                          ...acc,
                          ...([...(lessons || []), ...(lectures || [])]).map(({testId, id}) => testId || id)
                          ]
                        : acc, [])
                    ]
                  : acc, [])
            ], [])
        ], [])

      const promises = testIds
        .map(async testId => await getTest(testId, 'getTestQuestionIds'));
      const results = await Promise.all(promises);
      const tests = results
        .map(({data: {getTest: fetchedTest}}) => fetchedTest);
      configQuestionIds = tests
        .reduce((acc, {id: testId, blockConnections}) => [
          ...acc,
          ...blockConnections.items
            .reduce((acc, {testBlock}) => testBlock?.questionConnections ? [
              ...acc,
              ...testBlock.questionConnections.items.filter(({question}) => question.status !== 'draft').map(({question}) => ({id: question.id, testId}))
            ] : acc, [])
          ], [])

      await Storage.put(storageKey, JSON.stringify(configQuestionIds), {contentType: 'application/json'});
    }

    if (limitedPool) {
      configQuestionIds = configQuestionIds.slice(0, 100);
    }

    const filteredQuestionIds = configQuestionIds.filter(({id}) => !questionIds.includes(id));

    if (filteredQuestionIds.length < testLength) {
      // fill DWU with learning/reviewing questions if user has fewer than [testLength] untagged
      const learningAndReviewingIds = items
        .filter(({masteryLevel}) => ['learning', 'reviewing'].includes(masteryLevel))
        .map(({userIdHashQuestionBaseId, masteryLevel}) => ({id: userIdHashQuestionBaseId.replace(`${userId}#`, ''), masteryLevel}));

      return [
        ...filteredQuestionIds.map(question => ({...question, masteryLevel: 'none'})),
        ...getRandom(learningAndReviewingIds, testLength - filteredQuestionIds.length)
      ]
    } else {
      // otherwise just pull 10 random questions
      return getRandom(filteredQuestionIds, testLength).map(question => ({...question, masteryLevel: 'none'}));
    }
  }

  const questionConnections = await fetchDrawPile();

  let questionSets = [];
  let index = 1;
  const questionsPerBlock = 10;
  for (var i = 0; i < questionConnections.length; i+=questionsPerBlock) {
    const items = questionConnections.slice(i, i+questionsPerBlock);
    for (var j = 0; j < items.length; j++) {
      const result = await getQuestionForTest(items[j].id, ['med-school', 'dental-school', 'anatomy'].includes(bootcamp));
      items[j].question = {
        originalTestId: items?.[j]?.testId,
        ...result.data.getQuestionBase
      };
    }
    questionSets.push({
      testBlock: {
        type: 'questionSet',
        questionConnections: {items}
      },
      index: index++
    })
  }

  const composedTest = {
    config: {
      title: `Quick Study`
    },
    id: '',
    blockConnections: {
      items: [
        {testBlock: {type: 'startQuickReview'}, index: 0},
        ...questionSets,
        {testBlock: {type: 'endBlock'}, index: questionSets.length + 1}
      ]
    }
  }

  return composedTest;
};

export const composeQuickReview = async (config, userId, bootcamp, subjectIds, testLength=10) => {
  const allQuestionMastery = await Promise.all(subjectIds.map(subjectId => getAllQuestionMastery({
      userIdHashSubjectTagId: `${userId}#${subjectId}`
    }, 'subjectWithQuestionBaseId')
  ));

  const items = allQuestionMastery.reduce((acc, masteryResult) => [...acc, ...masteryResult.data.QuestionMasteryBySubjectTagId.items], []);
  const learningAndReviewingItems = items.filter(({masteryLevel}) => ['learning', 'reviewing'].includes(masteryLevel));
  const questionIds = items.map(({userIdHashQuestionBaseId}) => userIdHashQuestionBaseId.replace(`${userId}#`, ''));
  const testIdToFetch = bootcamp === 'anatomy'
    ? '66788bff-f27b-45ab-9717-365a4b8a5628' // Hand qbank 1
    : bootcamp === 'chemistry'
    ? '5fc9bb57-d897-4919-935f-aed4d1935cd5' // Alcohols, Ethers, and Epoxides qbank 1
    : bootcamp === 'med-school' || bootcamp === 'dental-school'
    ? '9f9de7d0-fb5b-4968-85c0-2844881b25ba'  // Head and neck anatomy qbank
    : ''

  const fetchDrawPile = async () => {
    if (learningAndReviewingItems.length >= testLength) return learningAndReviewingItems;
    if (items.length >= testLength) return items;
    if (!['anatomy', 'chemistry', 'oat', 'dat', 'inbde', 'step-1', 'med-school', 'dental-school'].includes(bootcamp)) return [];

    // else.... fetch a random test from this bootcamp
    // for now: let's just hardcode the test ID
    const {data: {getTest: fetchedTest}} = await getTest(testIdToFetch, 'getTestQuestionIds');
    return [
      // ...items, NOTE: NOT INCLUDING ANY TAGGIES WHEN COUNT < 10
      ...fetchedTest.blockConnections.items
        .reduce((acc, {testBlock}) =>
          [
            ...acc,
            ...testBlock.questionConnections.items
              .filter(({question}) => question.status !== 'draft')
              .reduce((acc, {question}) => questionIds.includes(question.id) ? acc :
                [
                  ...acc,
                  {
                    masteryLevel: 'none',
                    userIdHashQuestionBaseId: question.id
                  }
                ], [])
          ], [])
    ];
  }
  const drawPile = await fetchDrawPile();

  const questionConnections = getRandom(drawPile, testLength)
    .map((item, index) => ({
      ...item,
      index,
      question: {
        id: item.userIdHashQuestionBaseId.replace(`${userId}#`, '')
      }
    }));


  let questionSets = [];
  let index = 1;
  const questionsPerBlock = 10;
  for (var i = 0; i < questionConnections.length; i+=questionsPerBlock) {
    const items = questionConnections.slice(i, i+questionsPerBlock);
    for (var j = 0; j < items.length; j++) {
      const result = await getQuestionForTest(items[j].question.id, ['med-school', 'dental-school', 'anatomy'].includes(bootcamp));
      items[j].question = {
        ...result.data.getQuestionBase,
        masteryLevel: items[j].masteryLevel || 'none',
      };
    }
    questionSets.push({
      testBlock: {
        type: 'questionSet',
        questionConnections: {items}
      },
      index: index++
    })
  }

  const composedTest = {
    config: {
      title: `Daily Warmup`
    },
    id: testIdToFetch,
    blockConnections: {
      items: [
        {testBlock: {type: 'startQuickReview'}, index: 0},
        ...questionSets,
        {testBlock: {type: 'endBlock'}, index: questionSets.length + 1}
      ]
    }
  }
  return composedTest;
};
