import React, {useState} from 'react';
import styled from 'styled-components';

import {useConfigState} from '../context';
import {Column, Table, Heading, Expand, Card, Title, SubTitle} from './shared';

import ProgressBar from '@bootcamp/web/src/components/ProgressBar';
import {AlertIcon} from '@bootcamp/web/src/bootcamps/pages/Performance/Subject';
import theme from '@bootcamp/shared/src/styles/theme';


const Container = styled(Column)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};


  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledHeading = styled(Heading)`
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;
const OuterWrapper = styled.div`
  ${({theme}) => theme.mediaQueries.tablet} {
    overflow-x: auto;
  }
  `;
const Wrapper = styled(Card)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};


  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};

    margin-right: ${({theme}) => theme.layouts.spacing.m};
    margin-left: ${({theme}) => theme.layouts.spacing.m};
    width: 800px;
  }
`;
const StyledTable = styled(Table)`
  td:nth-child(6) * {
    color: ${({theme}) => theme.colors.typographyPalette.secondary};
  }
  td:nth-child(6) {
    color: ${({theme}) => theme.colors.typographyPalette.secondary};
  }

`;
const ScoreRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.layouts.spacing.xs};
`;

const Subject = () => {
  const [expanded, setExpanded] = useState(false);
  const {scoring} = useConfigState();

  const rows = Object
    .entries(scoring?.subjectBreakdown || {})
    .map(([subject, breakdown]) => ({
      parent: [
        {content: <span>{subject}  {breakdown?.score < breakdown?.avgScore && <AlertIcon/>}</span>},
        {content: ''},
        {content: `${breakdown?.score} / ${breakdown?.maxScore}`},
        {content: (
          <ScoreRow>
            <span style={{width: 56}}>{Math.round(breakdown?.score / breakdown?.maxScore * 100)}%</span>
            <ProgressBar
              withMargin={false}
              showLabel={false}
              total={100}
              segments={[{count: Math.round(breakdown?.score / breakdown?.maxScore * 100), color: theme.colors.brandPalette.blue.gradient}]}
              markPosition={Math.round((breakdown?.avgScore / breakdown?.maxScore) * 100)}
            />
          </ScoreRow>
        )},
        {content: `${Math.round((breakdown?.avgScore / breakdown?.maxScore) * 100)}%`},
      ],
      nested: Object
        .entries(breakdown?.systems)
        .map(([system, breakdown]) => ({
          onClick: () => {},
          cells: [
            {content: ''},
            {content: subject, disabled: true},
            {content: (<span>{system}</span>)},
            {content: `${breakdown?.score} / ${breakdown?.maxScore}`},
            {content: (
              <ScoreRow>
                <span style={{width: 56}}>{Math.round(breakdown?.score / breakdown?.maxScore * 100)}%</span>
                <ProgressBar
                  withMargin={false}
                  showLabel={false}
                  total={100}
                  segments={[{count: Math.round(breakdown?.score / breakdown?.maxScore * 100), color: theme.colors.brandPalette.blue.gradient}]}
                  markPosition={Math.round((breakdown?.avgScore / breakdown?.maxScore) * 100)}
                />
              </ScoreRow>
            )},
            {content: `${Math.round((breakdown?.avgScore / breakdown?.maxScore) * 100)}%`},
          ]
        }))
    }))

  return (
    <Container>
      <StyledHeading>
        <Title>
          Subject Breakdown
          <Expand expanded={expanded} setExpanded={setExpanded} />
        </Title>
        <SubTitle>Increase your score by focusing on subjects marked with a red icon - that's where you can improve the most!</SubTitle>
      </StyledHeading>
      <OuterWrapper>
        <Wrapper>
          <StyledTable
            expandAll={expanded}
            hideChevrons
            rows={[
              {cells: [{content: ''}, {content: 'Subject'}, {content: 'System'}, {content: 'Correct / Total'}, {content: 'Score'}, {content: 'Avg. Score'}, {content: ''}]},
              ...rows
            ]}
          />
        </Wrapper>
      </OuterWrapper>
  </Container>
  );
};

export default Subject;