import React from 'react';

import {Lottie} from '@crello/react-lottie';

import {H1, Body2} from '../../../components/Typography';
import {LinkButton, Button} from '../../../components/Branding/Buttons';
import {Column, Row, FlexBox} from '../../../components/Grid';

import wavePng from '@bootcamp/shared/src/assets/images/wave-partition.png';
import wavePngMobile from '@bootcamp/shared/src/assets/images/wave-partition-mobile.png';

import animation from './animation.json';

import styled from 'styled-components';
import {useUserDataContext} from '../../../contexts/UserData';
import {IntercomAPI} from 'react-intercom';
import {ChatMultiple} from '@styled-icons/fluentui-system-filled/ChatMultiple';
import {Home} from '@styled-icons/fluentui-system-filled/Home';

const Container = styled(FlexBox)`
  overflow-y: auto;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const Left = styled(Column)`
  width: 56.25%;
  background: linear-gradient(180deg, #536BDF 0%, #28378E 100%);
  padding-left: 10%;

  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
    height: 40%;
    padding: 10%;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 60%;
    padding: 32px 16px;
  }
`;
const LeftWrapper = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 480px;
`;
const Right = styled(Row)`
  width: 43.75%;
  align-items: center;
  background: white;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
    width: 100%;
    height: 60%;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40%;
  }
`;
const Wave = styled.img`
  position: absolute;
  width: 200px;
  height: 100%;
`;
const WaveMobile = styled.img`
`;
const SvgWrapper = styled.div`
  position: relative;
  height: 100%;

  ${WaveMobile} {
    display: none;
    width: 100%;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    height: 0;

    ${WaveMobile} {
      display: inline-block;
    }
    ${Wave} {
      display: none;
    }
  }
`;
const AnimationWrapper = styled.div`
  max-width: 480px;
  padding: 0 100px;
  margin: 0 auto;
  width: 100%;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin: auto 0;
  }
`;
const Header = styled(H1)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  line-height: 45px;
  ${({theme}) => theme.mediaQueries.mobileL} {
    line-height: 30px;
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
    font-size: 24px;
  }
`;
const Body = styled(Body2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const HomeButton = styled(LinkButton)`
  width: auto;
  button {
    font-weight: 400;
    color: black;
    background: ${({theme}) => theme.colors.neutralsPalette.white};

    &:hover {
      background: ${({theme}) => theme.overlays.opacity.light._400};
    }
    border-radius: 64px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
const MessageButton = styled(Button)`
  font-weight: 400;
  color: black;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  &:hover {
    background: ${({theme}) => theme.overlays.opacity.light._400};
  }
  border-radius: 64px;
  ${({theme}) => theme.mediaQueries.mobileL} {
    width: 100%;
  }
`;

const CenterRow = styled(Row)`
  align-items: center;
  gap: 8px;
`;

const ButtonRow = styled(Row)`
  gap: 16px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  ${({theme}) => theme.mediaQueries.mobileL} {
    flex-direction: column;
    gap: 8px;
  }
`;


const NotFound = () => {
  const {bootcamp} = useUserDataContext();
  return (
    <Container>
      <Left>
        <LeftWrapper>
          <Header>We couldn't find the page you are looking for 😬</Header>
          <Body> No worries! Let’s get this fixed. Click Go Home and see if this page appears again. If so, send us a message and we’ll get you back to studying ASAP.</Body>
          <ButtonRow>
            <HomeButton to={`/${bootcamp}`} type={'ghost'} color={'indigo'} children={<CenterRow><Home size={20}/> Go Home</CenterRow>}/>
            <MessageButton onClick={e => {
              e.preventDefault();
              IntercomAPI('showNewMessage')
            }} type={'ghost'} color={'indigo'} children={<CenterRow><ChatMultiple size={20} />Send Us a Message</CenterRow>}/>
          </ButtonRow>
        </LeftWrapper>
      </Left>
      <Right>
        <SvgWrapper children={[<Wave src={wavePng}/>, <WaveMobile src={wavePngMobile}/>]}/>
        <AnimationWrapper>
          <Lottie config={{loop: true, animationData: animation}}/>
        </AnimationWrapper>
      </Right>
    </Container>
  );
}

export default NotFound;
