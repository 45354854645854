import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {AnimatedSwitch, spring} from 'react-router-transition';
import {HashRouter, Route, Redirect, useHistory, Link} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import {IntercomAPI} from 'react-intercom';
import TagManager from 'react-gtm-module';

import {Column} from '../../../components/Grid';
import {useUserDataContext} from '../../../contexts/UserData';
import {SubmitContainer, SubmitButton} from '../';

import Join from './Join';
import SignIn from './SignIn';
import DATSignIn from './DATSignIn';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SelectBootcamp from './SelectBootcamp';
import FurtherInfo from './FurtherInfo';
import {LoadingSpinner} from '../../Branding';
import {Container as SmallContainer, Header, SubHeader, Body} from '../';

const Container = styled(Column)`
  max-width: 528px;
  align-items: center;
  height: ${({height}) => height}px;
`;

const Switcher = styled(AnimatedSwitch)`
  position: relative;
  width: 100%;
  height: 100%;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;


function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `translateX(${styles.offset}px)`
  };
}

function glide(val) {
  return spring(val, {
    stiffness: 174,
    damping: 19,
  });
}

const pageTransitions = {
  atEnter: {
    offset: 200,
    opacity: 0,
  },
  atLeave: {
    offset: glide(-100),
    opacity: glide(0),
  },
  atActive: {
    offset: glide(0),
    opacity: glide(1),
  },
};
const RouteWrapperContainer = styled.div`
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin: 0px 16px;
  }
`;
const RouteWrapper = ({setHeight, ...props}) => {
  return (
    <RouteWrapperContainer ref={node => {
      node && setHeight(node.getBoundingClientRect().height);
    }}>
      <Route {...props} />
    </RouteWrapperContainer>
  )
}

function getBootcampFromUrlState(validBootcamps) {
  const search = window.location.hash.split('?')[1];
  const bootcamp = new URLSearchParams(search)
    ?.get('redirectTo')
    ?.replace('/', '')
    ?.split('/')[0];
  return validBootcamps.includes(bootcamp) ? bootcamp : null;
}

const FormSubmit = ({setRedirect, children}) => {
  const {loading, loggedIn} = useUserDataContext();
  const {formState} = useFormContext();

  return (
    <SubmitContainer>
      <SubmitButton loading={formState.isSubmitting || loading || loggedIn} disabled={!formState.isValid}>
        {children}
      </SubmitButton>
    </SubmitContainer>
  );
}

const AuthNavigation = () => {
  const [height, setHeight] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false)
  const {loggedIn, bootcamp, loading, validBootcamps, setBootcamp, cognitoUser} = useUserDataContext();
  const {location} = useHistory();
  const routerRef = useRef();

  useEffect(() => {
    const bootcampDestination = getBootcampFromUrlState(validBootcamps) || bootcamp;
    if (!loggedIn || loading) {
      return;
    } else if (bootcampDestination) {
      if (false && bootcampDestination === 'anatomy') {
        setCreatingAccount(false);
        routerRef?.current?.history.push('/details?redirectTo=anatomy');
        return
      }

      try {
        if (creatingAccount === 'datbootcamp') {
          return;
        }
        if (creatingAccount) {
          // only run when creating account
          IntercomAPI('trackEvent', 'Signed up', {bootcamp: bootcampDestination});
          IntercomAPI('update', {bootcamp: bootcampDestination, email: cognitoUser?.attributes?.email})
          TagManager.dataLayer({
            dataLayer: {
              email: cognitoUser?.attributes?.email,
              event: 'Sign Up',
              bootcamp: bootcampDestination
            }
          })
        }
      } catch (e) {
        console.log(e);
      }
      // route to app.bootcamp/[bootcamp] home page
      setBootcamp(bootcampDestination);

      if (bootcampDestination === 'nclex') {
        return setRedirect('/nclex/next-gen-cases')
      }
      
      const redirectTo = window.location?.hash?.match(/redirectTo=(.*)/)?.[1];
      setRedirect(redirectTo || `/${bootcampDestination}`);
    } else {
      // UserData is unable to determine a 'recent-bootcamp' from our user interactions table, redirect to bootcamp select screen
      setCreatingAccount(false);
      routerRef?.current?.history.push('/bootcamp');
    }
  }, [loggedIn, loading, bootcamp, location]);

  if (redirect) return <Redirect to={redirect}/>;

  if (loading) {
    return (
      <LoadingSpinner size={36} color={'white'} />
    )
  }
  const bootcampDestination = getBootcampFromUrlState(validBootcamps) || bootcamp;

  return (
    <Container onClick={e => e.stopPropagation()} height={height}>
      <HashRouter ref={routerRef}>
        <Switcher
          {...pageTransitions}
          mapStyles={mapStyles}
        >
          <RouteWrapper setHeight={setHeight} path={`/bootcamp`} render={props => <SelectBootcamp setRedirect={setRedirect} {...props}/>}/>
          <RouteWrapper setHeight={setHeight} path={`/join`} render={props => <Join setRedirect={setRedirect} setCreatingAccount={setCreatingAccount} {...props}/>}/>
          <RouteWrapper setHeight={setHeight} path={`/signin`} render={props => <SignIn setRedirect={setRedirect} {...props}/>}/>
          <RouteWrapper setHeight={setHeight} path={`/datsignin`} render={props => <DATSignIn setRedirect={setRedirect} setBootcamp={setBootcamp} setCreatingAccount={setCreatingAccount} {...props}/>}/>
          <RouteWrapper setHeight={setHeight} path={`/forgot`} render={props => <ForgotPassword setRedirect={setRedirect} {...props}/>}/>
          <RouteWrapper setHeight={setHeight} path={`/reset`} render={props => <ResetPassword setRedirect={setRedirect} {...props}/>}/>
          <RouteWrapper setHeight={setHeight} path={`/details`} render={props => <FurtherInfo setRedirect={setRedirect} {...props}/>}/>
          <Redirect to={`/join`} />
        </Switcher>
      </HashRouter>
    </Container>
  )
}

export {FormSubmit};
export default AuthNavigation;
