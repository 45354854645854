/* eslint-disable */
const holepunching = jQuery => jQuery(document).ready(function(jQuery) {
// jQuery(document).ready(function() {
  var ANS_MULT, Fold, FoldedSet, Line, OptionObject, Page, Polygon, PunchedHoleList, startGenerator, QUES_MULT, TableSpot, Vertex, answerCanvases, answerGrades, answerValues, clearAnswers, clearGrades, clearRadios, clearSteps, createAndDisplayFolds, currentPage, erasePage, executeFold, explicateStep, foldLines, freshSheet, freshTable, answerToggle, gridToggle, gameReset, init, intersection, listPoints, newPage, outlineList, pagePosition, pointMatch, populateFoldLines, previousPage, printList, radioChangeEvent, disableRadios, randomInt, relocateSpots, restoreCurrentPage, revealExplication, sanitize, sanitizeCanvases, serializedPages, setPageNumber, startTimer, stepCanvases, tallyScoreboard, timeChangeEvent, timeLeft, timeOut, timeReset, timer, timerOn,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };


  timerOn = false;

  timeLeft = 30;

  var initialTime = 30;

  timer = {};

  foldLines = [];

  serializedPages = [];

  pagePosition = 0;

  currentPage = {};

  answerCanvases = ["can-A", "can-B", "can-C", "can-D", "can-E"];
  answerGrades = ["grade-A", "grade-B", "grade-C", "grade-D", "grade-E"];
  answerValues = ["A", "B", "C", "D", "E"];

/* difficulty init */
    var difficulty = 0;
    var _maxCandidates, _numAnswers, radioElements, answerElements;
    var fourthFoldActive = 0
/* end difficulty init */

var questionStartTime = new Date().getTime();
var totalTime = 0;
var totalAnswered = 0;

var showAnswer = false;
var showGrid = false;

/*
 * Change difficulty controls
 */
function setDifficulty() {
    radioElements = jQuery(".grade");
    answerElements = jQuery(".answer");
        //console.dir(answerElements);
        //console.dir(radioElements);

    if (difficulty == 2) {
      _maxCandidates = 20;
      _numAnswers = 5;
    } else if (difficulty == 1) {
        _maxCandidates = 10;
        _numAnswers = 5;
        // jQuery(radioElements[3]).hide();
        // jQuery(radioElements[4]).hide();
        // jQuery(answerElements[3]).hide();
        // jQuery(answerElements[4]).hide();
    } else  {
        _maxCandidates = 20;
        _numAnswers = 5;
        // jQuery(radioElements[3]).show();
        // jQuery(radioElements[4]).show();
        // jQuery(answerElements[3]).show();
        // jQuery(answerElements[4]).show();
    }
}

    stepCanvases = ["step0", "step1", "step2", "step3", "step4", "step5"];

  ANS_MULT = 10;

  QUES_MULT = 10;



  init = function() {
    jQuery('#setdifficulty').change(function() {
     difficulty = (parseInt(jQuery(this).val()));
        jQuery('#next').click();
    });

    jQuery('#settime').change(function() {
      initialTime = (parseInt(jQuery(this).val()));
      timeReset();
     });

     document.querySelectorAll('.grade-label').forEach(function (element){
      element.addEventListener( 'mousedown' ,function(ev){
        if(ev.which == 3)
        {
          const input = element.querySelector("input");
          if (currentPage.answer === "z"){
            input.disabled = !input.disabled;
            this.style.textDecoration = input.disabled ? 'line-through' : 'none';
            this.style.color = input.disabled ? 'grey' : 'inherit';
          }
        }
      });
     })

    var rad, rb, _i, _len, _results;
    populateFoldLines();
    sanitizeCanvases();
    newPage();
    timerOn = true;
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.getElementById("next").onclick = newPage;
    document.getElementById("previous").onclick = previousPage;
    document.getElementById("time-button").onclick = timeChangeEvent;
    document.getElementById("game-reset").onclick = gameReset;

    document.getElementById("answer-exp").onclick = answerToggle;
    document.getElementById("answer-grid").onclick = gridToggle;
    document.getElementById("startGenerator").onclick = startGenerator;


    rad = document.getElementsByName("answer");
    _results = [];
    for (_i = 0, _len = rad.length; _i < _len; _i++) {
      rb = rad[_i];
      _results.push(rb.onclick = radioChangeEvent);
    }
    return _results;
  };

  startGenerator = function(){
    document.getElementById('generator').style.display='block';
    document.getElementById('introduction').style.display='none';
    startTimer();
    questionStartTime = new Date().getTime();
  }

  newPage = function() {
    //console.log("creating new page with difficulty : " + difficulty);
    setDifficulty();
    showAnswer = false;
    showGrid = false;

    pagePosition++;
    if (Math.random() <= .2 || difficulty == 2) {
      fourthFoldActive = 1;
    } else {
      fourthFoldActive = 0;
    }
    if (!fourthFoldActive) {
      jQuery('#step4text').html('Hole Punch');
      jQuery('#step5container').hide();
      jQuery('#folds').css('max-width', '686px');
    } else {
      jQuery('#step4text').html('Fold 4');
      jQuery('#step5text').html('Hole Punch');
      jQuery('#folds').css('max-width', '823px');
      jQuery('#step5container').show();

    }
    questionStartTime = new Date().getTime();
    document.getElementById('answer-toggle').style.opacity = 0;
    document.querySelectorAll('.grade-label').forEach((elem) => {
      elem.style.color = 'inherit';
      elem.style.textDecoration = 'none';
    });
    if (pagePosition > serializedPages.length) {
      currentPage = new Page();
      serializedPages[pagePosition - 1] = currentPage;
      erasePage();
      createAndDisplayFolds();
      currentPage.generateFalseAnswers();
      currentPage.printAnswerChoices();
      if (timerOn) {
        return startTimer();
      }
    } else {
      erasePage();
      return restoreCurrentPage();
    }
  };

  previousPage = function() {
    showAnswer = false;
    showGrid = false;
    if (pagePosition > 1) {
      pagePosition--;
      erasePage();
      return restoreCurrentPage();
    }
  };

  timeChangeEvent = function() {
    timerOn = !timerOn;
    if (timerOn) {
      return startTimer();
    } else {
      clearInterval(timer);
      if (timeLeft === 0) {
        return timeReset();
      }
    }
  };

  startTimer = function() {
    clearInterval(timer);
    return timer = setInterval(function() {
      timeLeft--;
      document.getElementById("time-display").innerHTML = timeLeft;
      if (timeLeft < 1) {
        return timeOut();
      }
    }, 1000);
  };

  timeOut = function() {
    var rad;
    clearInterval(timer);
    rad = document.getElementsByName("answer");
    if (currentPage.correctOption !== 0) {
      rad[0].checked = true;
    } else {
      rad[1].checked = true;
    }
    return radioChangeEvent();
  };

  disableRadios = function(){
    var radios, rd, _i, _len;
    radios = document.getElementsByName('answer');
    for (_i = 0, _len = radios.length; _i < _len; _i++) {
      rd = radios[_i];
      rd.disabled = true;
    }
  }

  radioChangeEvent = function() {
    var radios, rd, _i, _len;
    clearInterval(timer);
    radios = document.getElementsByName('answer');
    for (_i = 0, _len = radios.length; _i < _len; _i++) {
      rd = radios[_i];
      if (rd.checked) {
        currentPage.answer = rd.value;
        break;
      }
    }
    totalTime += new Date().getTime() - questionStartTime;
		++totalAnswered;

		var newAverage = (totalTime / (1000* totalAnswered));
		newAverage -= newAverage % 1;

		jQuery('#average-time').text(newAverage);
    clearGrades();
    document.getElementById(answerGrades[currentPage.correctOption]).innerHTML = '<img src="http://d102fwow1ltq0.cloudfront.net/game/check.png" style="width: 12px;" />';
    if (answerValues[currentPage.correctOption] !== currentPage.answer) {
      document.getElementById('grade-' + currentPage.answer).innerHTML = '<img src="http://d102fwow1ltq0.cloudfront.net/game/x.png" style="width: 12px;" />';
    }
    showAnswer = true;
    showGrid = true;
    revealExplication();
    return tallyScoreboard();
  };

  tallyScoreboard = function() {
    var correct, incorrect, sp, _i, _len;
    incorrect = 0;
    correct = 0;
    for (_i = 0, _len = serializedPages.length; _i < _len; _i++) {
      sp = serializedPages[_i];
      if (sp.answer !== "z") {
        if (sp.answer === answerValues[sp.correctOption]) {
          correct++;
        } else {
          incorrect++;
        }
      }
    }
    document.getElementById('incorrect').innerHTML = incorrect;
    return document.getElementById('correct').innerHTML = correct;
  };

  setPageNumber = function() {
    document.getElementById("location").innerHTML = pagePosition;
    return document.getElementById("total").innerHTML = serializedPages.length;
  };

  restoreCurrentPage = function() {
    if (showAnswer){
      return revealExplication();
    }

    var c, ctx, i, paperStages, radios, rd, _i, _j, _len, _ref;
    currentPage = serializedPages[pagePosition - 1];
    if (currentPage.folds.length == 3) {
      fourthFoldActive = 0;
      jQuery('#step4text').html('Hole Punch');
      jQuery('#step5container').hide();
      jQuery('#folds').css('max-width', '686px');
    } else {
      fourthFoldActive = 1;
      jQuery('#step4text').html('Fold 4');
      jQuery('#step5text').html('Hole Punch');
      jQuery('#folds').css('max-width', '823px');
      jQuery('#step5container').show();
    }
    document.getElementById('answer-toggle').style.opacity = 0;
    document.querySelectorAll('.grade-label').forEach((elem) => {
      elem.style.color = 'inherit';
      elem.style.textDecoration = 'none';
    });
    paperStages = [];
    paperStages.push(freshSheet());
    for (i = _i = 0, _ref = currentPage.folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      c = document.getElementById(stepCanvases[i]);
      ctx = c.getContext("2d");
      if (i !== 0) {
        printList(ctx, paperStages[i]);
      }
      paperStages[i + 1] = executeFold(currentPage.folds[i], paperStages[i]);
    }
    c = document.getElementById(stepCanvases[3 + fourthFoldActive]);
    ctx = c.getContext("2d");
    printList(ctx, paperStages[3 + fourthFoldActive]);
    c = document.getElementById(stepCanvases[4 + fourthFoldActive]);
    ctx = c.getContext("2d");
    outlineList(ctx, paperStages[3 + fourthFoldActive], false);
    if (timerOn && currentPage.answer === "z") {
      startTimer();
    }
    currentPage.printAnswerChoices();
    if (currentPage.answer !== "z") {
      document.getElementById('answer-toggle').style.opacity = 1;
      radios = document.getElementsByName('answer');
      for (_j = 0, _len = radios.length; _j < _len; _j++) {
        rd = radios[_j];
        if (rd.value === currentPage.answer) {
          rd.checked = true;
          break;
        }
      }
      disableRadios();
      document.getElementById(answerGrades[currentPage.correctOption]).innerHTML = '<img src="http://d102fwow1ltq0.cloudfront.net/game/check.png" style="width: 12px;" />';
      if (answerValues[currentPage.correctOption] !== currentPage.answer) {
        document.getElementById('grade-' + currentPage.answer).innerHTML = '<img src="http://d102fwow1ltq0.cloudfront.net/game/x.png" style="width: 12px;" />';
      }
    }
  };

  randomInt = function(scale) {
    return Math.floor(Math.random() * scale);
  };

  createAndDisplayFolds = function() {
    var c, ctx, i, paperStages, _i, _ref;
    paperStages = [];
    paperStages.push(freshSheet());
    for (i = _i = 0, _ref = currentPage.folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      c = document.getElementById(stepCanvases[i]);
      ctx = c.getContext("2d");
      if (i !== 0) {
        printList(ctx, paperStages[i]);
      }
      currentPage.folds[i] = new Fold();
      currentPage.folds[i].generate(currentPage.tableSpots, paperStages[i]);
      paperStages[i + 1] = executeFold(currentPage.folds[i], paperStages[i]);
      relocateSpots(currentPage.tableSpots, currentPage.folds[i]);
    }
    c = document.getElementById(stepCanvases[3 + fourthFoldActive]);
    ctx = c.getContext("2d");
    printList(ctx, paperStages[3 + fourthFoldActive]);
    const punchSecondHole = difficulty != 1 && Math.random() < 0.25;
    currentPage.locateHole(punchSecondHole);
    c = document.getElementById(stepCanvases[4 + fourthFoldActive]);
    ctx = c.getContext("2d");
    return outlineList(ctx, paperStages[3 + fourthFoldActive], false);
  };

  relocateSpots = function(table, fold) {
    var l, lVal, mir, sVal, subject, tmi, tmj, tmk, _i, _len, _results;
    l = foldLines[fold.location];
    lVal = l.v1.mode[l.angle];
    _results = [];
    for (_i = 0, _len = table.length; _i < _len; _i++) {
      tmi = table[_i];
      if (tmi.members.length === 0) {
        continue;
      }
      subject = tmi;
      sVal = subject.mode[l.angle];
      if ((sVal > lVal && fold.orientation) || (sVal < lVal && !fold.orientation)) {
        mir = subject.mirror(fold.location);
        _results.push((function() {
          var _j, _k, _len1, _len2, _ref, _results1;
          _results1 = [];
          for (_j = 0, _len1 = table.length; _j < _len1; _j++) {
            tmj = table[_j];
            if (tmj.identical(mir)) {
              _ref = subject.members;
              for (_k = 0, _len2 = _ref.length; _k < _len2; _k++) {
                tmk = _ref[_k];
                tmj.addSpot(tmk);
              }
              subject.clear();
              break;
            } else {
              _results1.push(void 0);
            }
          }
          return _results1;
        })());
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  printList = function(ctx, polyList) {
    var pl, _i, _len, _results;
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";
    _results = [];
    for (_i = 0, _len = polyList.length; _i < _len; _i++) {
      pl = polyList[_i];
      _results.push(pl.print(ctx));
    }
    return _results;
  };

  outlineList = function(ctx, polyList, holes) {
    var pl, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = polyList.length; _i < _len; _i++) {
      pl = polyList[_i];
      if (pl.queue.length > 2) {
        _results.push(pl.outlineAroundHoles(ctx, holes));
      } else {
        _results.push(void 0);
      }
    }

    let addedLine = false;
    let line = new Polygon();

    for (const poly of polyList){
      if (!addedLine && poly.line && poly.line.queue.length == 2){
        addedLine = true;
        line = poly.line
      }
      if (showAnswer && poly.flapped){
        poly.outlineAroundHoles(ctx, holes, "#ECE2F9");
      }
    }

    if (showAnswer){

      if (showGrid){
        drawGrid(ctx);
      }

      if (line.queue.length == 2){
        drawFoldLine(ctx, line);
      }
    }

    return _results;
  };

  var drawGrid = function (ctx) {
    let lineDrawn1, lineDrawn2;

    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(105, 105 , 105, .3)';

    for (let i = 2; i < 8; i += 2){
      lineDrawn1 = new Polygon();
      lineDrawn2 = new Polygon();

      lineDrawn1.addVertex(new Vertex(i , 0));
      lineDrawn1.addVertex(new Vertex(i , 8));

      lineDrawn2.addVertex(new Vertex(0, i));
      lineDrawn2.addVertex(new Vertex(8, i));

      lineDrawn1.outline(ctx);
      lineDrawn2.outline(ctx);
    }
  }

  var drawFoldLine = function (ctx , line) {
    const vertex1 = line.queue[0];
    const vertex2 = line.queue[1];
    let x = vertex1.x;
    let y = vertex1.y;

    let diffX = vertex1.x - vertex2.x;
    let diffY = vertex1.y - vertex2.y;

      while (x >= 0 && x <= 8 && y >= 0 && y <= 8){
        x += diffX;
        y += diffY;
      }

      const lineDrawn = new Polygon();
      lineDrawn.addVertex(new Vertex(x , y));

      diffY = -diffY;
      diffX = -diffX;

      x = vertex2.x;
      y = vertex2.y;

      while (x >= 0 && x <= 8 && y >= 0 && y <= 8){
        x += diffX;
        y += diffY;
      }

      lineDrawn.addVertex(new Vertex(x , y));
      lineDrawn.outline(ctx, "red");
  }

  executeFold = function(fld, oldList) {
    var cnt, i, newList, tempSet, _i, _j, _k, _ref, _ref1, _ref2;
    tempSet = [];
    newList = [];
    cnt = oldList.length;
    for (i = _i = 0, _ref = cnt - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      tempSet[i] = new FoldedSet(fld, oldList[i]);
    }
    let linePoly = new Polygon();

    for (const foldedSet of tempSet){
      if (foldedSet.lines.queue.length == 2){
        linePoly = foldedSet.lines;
      }
    }

    for (i = _j = 0, _ref1 = cnt - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
      newList[i] = tempSet[i].base;
      newList[i].flapped = false;
      newList[i].line = linePoly;
    }
    for (i = _k = 0, _ref2 = cnt - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; i = 0 <= _ref2 ? ++_k : --_k) {
      newList[i + cnt] = tempSet[cnt - i - 1].flap;
      newList[i + cnt].flapped = true;
      newList[i + cnt].line = linePoly;
    }
    i = 0;
    while (i < newList.length) {
      if (newList[i].queue.length < 1) {
        newList.splice(i, 1);
      } else {
        i++;
      }
    }
    return newList;
  };

  intersection = function(v1, v2, fold) {
    var foldPoints, segPoints, sp, _i, _len;
    segPoints = listPoints(v1, v2);
    foldPoints = listPoints(foldLines[fold].v1, foldLines[fold].v2);
    for (_i = 0, _len = segPoints.length; _i < _len; _i++) {
      sp = segPoints[_i];
      if (pointMatch(sp, foldPoints)) {
        return sp;
      }
    }
    return false;
  };

  pointMatch = function(candidate, set) {
    var s, _i, _len;
    for (_i = 0, _len = set.length; _i < _len; _i++) {
      s = set[_i];
      if (candidate.identical(s)) {
        return true;
      }
    }
    return false;
  };

  listPoints = function(v1, v2) {
    var deltaX, deltaY, quant, result, xCur, yCur;
    result = new Array();
    quant = 0;
    deltaX = v2.x - v1.x;
    deltaY = v2.y - v1.y;
    if (deltaX !== 0) {
      quant = Math.abs(deltaX);
    } else {
      quant = Math.abs(deltaY);
    }
    result.push(v1);
    while (result.length < quant) {
      xCur = result[result.length - 1].x;
      yCur = result[result.length - 1].y;
      xCur += deltaX < 0 ? -1 : deltaX > 0 ? 1 : 0;
      yCur += deltaY < 0 ? -1 : deltaY > 0 ? 1 : 0;
      result.push(new Vertex(xCur, yCur));
    }
    result.push(v2);
    return result;
  };

  revealExplication = function() {
    var c, ctx, holeList, i, paperStages, punchedHoles, tableSpots, _i, _ref;
    clearSteps();
    holeList = [];
    paperStages = [];
    paperStages.push(freshSheet());
    tableSpots = freshTable();
    disableRadios();
    document.getElementById('answer-toggle').style.opacity = 1;
    punchedHoles = currentPage.tableSpots[currentPage.holeSpot].members;
    if (currentPage.secondHoleSpot){
      for (const val of currentPage.tableSpots[currentPage.secondHoleSpot].members){
        punchedHoles.push(val);
      }
    }
    for (i = _i = 0, _ref = currentPage.folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      c = document.getElementById(stepCanvases[i]);
      ctx = c.getContext("2d");
      holeList = explicateStep(punchedHoles, tableSpots);
      outlineList(ctx, paperStages[i], holeList);
      paperStages[i + 1] = executeFold(currentPage.folds[i], paperStages[i]);
      relocateSpots(tableSpots, currentPage.folds[i]);
    }
    c = document.getElementById(stepCanvases[3 + fourthFoldActive]);
    ctx = c.getContext("2d");
    outlineList(ctx, paperStages[3 + fourthFoldActive], false);
    c = document.getElementById(stepCanvases[4 + fourthFoldActive]);
    ctx = c.getContext("2d");
    return outlineList(ctx, paperStages[3 + fourthFoldActive], false);
  };

  explicateStep = function(punchedHoles, tableSpots) {
    var inThere, result, tb, _i, _len;
    result = [];
    inThere = function(arr, spots) {
      var sp1, sp2, _i, _j, _len, _len1, _ref;
      _ref = arr.members;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        sp1 = _ref[_i];
        for (_j = 0, _len1 = spots.length; _j < _len1; _j++) {
          sp2 = spots[_j];
          if (sp1.identical(sp2)) {
            return true;
          }
        }
      }
      return false;
    };
    for (_i = 0, _len = tableSpots.length; _i < _len; _i++) {
      tb = tableSpots[_i];
      if (inThere(tb, punchedHoles)) {
        result.push(tb);
      }
    }
    return result;
  };

  Fold = (function() {
    function Fold() {
      this.location = 0;
      this.orientation = false;
    }

    Fold.prototype.generate = function(table, polyList) {
      var affected, flip, l, lCandidate, lVal, mir, oCandidate, over, sVal, tbl, under, validity, _i, _len;
      lCandidate = 0;
      oCandidate = false;
      while (true) {
        lCandidate = randomInt(_maxCandidates); // 20 for normal, 10 to disable 45 deg folds
        flip = Math.random() * 20;
        oCandidate = flip < 10;
        validity = true;
        affected = false;
        l = foldLines[lCandidate];
        lVal = l.v1.mode[l.angle];
        // console.log("Fold :: l.angle = " + l.angle + " , lCandidate  = " + lCandidate + " , oCandidate = " + oCandidate);
        over = false;
        under = false;
        for (_i = 0, _len = table.length; _i < _len; _i++) {
          tbl = table[_i];
          if (tbl.members.length < 1) {
            continue;
          }
          sVal = tbl.mode[l.angle];
          if (sVal > lVal) {
            over = true;
            if (oCandidate) {
              mir = tbl.mirror(lCandidate);
              if (!mir.valid()) {
                validity = false;
                break;
              }
              if (mir.x !== tbl.x || mir.y !== tbl.y) {
                affected = true;
              }
            }
          }
          if (sVal < lVal) {
            under = true;
            if (!oCandidate) {
              mir = tbl.mirror(lCandidate);
              if (!mir.valid()) {
                validity = false;
                break;
              }
              if (mir.x !== tbl.x || mir.y !== tbl.y) {
                affected = true;
              }
            }
          }
        }
        if (validity && over && under && affected) {
          break;
        }
      }
      this.location = lCandidate;
      return this.orientation = oCandidate;
    };

    return Fold;

  })();

  FoldedSet = (function() {
    function FoldedSet(fld, poly) {
      var cand, flapPoly, i, j, l, lVal, pVal, poly1, poly2, tempPoint, testHist, testLine, tl, topFold, trimmedPoly, _i, _j, _k, _l, _len, _m, _ref, _ref1, _ref2, _ref3;
      if (poly.queue.length === 0) {
        this.base = new Polygon();
        this.flap = new Polygon();
        this.lines = new Polygon();
      } else {
        trimmedPoly = {};
        flapPoly = {};
        testLine = [];
        testHist = [];
        for (i = _i = 0, _ref = poly.queue.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          j = i + 1;
          if (j >= poly.queue.length) {
            j = 0;
          }
          tempPoint = intersection(poly.queue[i], poly.queue[j], fld.location);
          if (tempPoint === false) {
            continue;
          }
          for (_j = 0, _len = testLine.length; _j < _len; _j++) {
            tl = testLine[_j];
            if (tl.identical(tempPoint)) {
              tempPoint = false;
              break;
            }
          }
          if (tempPoint === false) {
            continue;
          }
          testLine.push(tempPoint);
          testHist.push(i);
        }
        if (testLine.length !== 2 || poly.edgeCase(fld)) {
          l = foldLines[fld.location];
          lVal = l.v1.mode[l.angle];
          pVal = fld.orientation ? poly.key[l.angle].mode[l.angle] : poly.antiKey[l.angle].mode[l.angle];
          if ((pVal > lVal && fld.orientation) || (pVal < lVal && !fld.orientation)) {
            flapPoly = poly;
            trimmedPoly = new Polygon();
          } else {
            trimmedPoly = poly;
            flapPoly = new Polygon();
          }
        } else {
          poly1 = new Polygon();
          poly2 = new Polygon();
          for (i = _k = 0, _ref1 = poly.queue.length - 1; 0 <= _ref1 ? _k <= _ref1 : _k >= _ref1; i = 0 <= _ref1 ? ++_k : --_k) {
            poly1.addVertex(poly.queue[i]);
            if (i === testHist[0]) {
              break;
            }
          }
          poly1.addVertex(testLine[0]);
          poly1.addVertex(testLine[1]);
          cand = testHist[1] + 1;
          if (cand < poly.queue.length) {
            for (i = _l = cand, _ref2 = poly.queue.length - 1; cand <= _ref2 ? _l <= _ref2 : _l >= _ref2; i = cand <= _ref2 ? ++_l : --_l) {
              poly1.addVertex(poly.queue[i]);
            }
          }
          poly2.addVertex(testLine[0]);
          cand = testHist[0] + 1;
          if (cand <= testHist[1]) {
            for (i = _m = cand, _ref3 = testHist[1]; cand <= _ref3 ? _m <= _ref3 : _m >= _ref3; i = cand <= _ref3 ? ++_m : --_m) {
              poly2.addVertex(poly.queue[i]);
            }
          }
          poly2.addVertex(testLine[1]);
          poly1.sanitize();
          poly2.sanitize();
          if ((fld.orientation && poly2.contains(poly.key[foldLines[fld.location].angle])) || (!fld.orientation && poly1.contains(poly.key[foldLines[fld.location].angle]))) {
            trimmedPoly = poly1;
            flapPoly = poly2;
          } else {
            trimmedPoly = poly2;
            flapPoly = poly1;
          }
        }
        topFold = flapPoly.mirror(fld.location);
        this.base = trimmedPoly;
        this.flap = topFold;
        this.lines = new Polygon();

        for (const line of testLine){
          this.lines.addVertex(line);
        }
      }
    }

    return FoldedSet;

  })();

  Vertex = (function() {
    function Vertex(x, y) {
      this.x = x;
      this.y = y;
      this.mode = [8 - y, x, x + (8 - y), (8 - x) + (8 - y)];
    }

    Vertex.prototype.print = function(ctx, fillColor, multiplier, stroked) {
      var radius;
      ctx.fillStyle = fillColor;
      radius = multiplier / 1.7;
      ctx.beginPath();
      ctx.arc(this.x * multiplier + 1.5, this.y * multiplier + 1.5, radius, 0, 2 * Math.PI);
      ctx.fill();
      if (stroked) {
        ctx.lineWidth = 3;
        return ctx.stroke();
      }
    };

    Vertex.prototype.fill = function(ctx, multiplier) {
      var radius;
      radius = multiplier / 1.7;
      ctx.beginPath();
      ctx.arc(this.x * multiplier + 1.5, this.y * multiplier + 1.5, radius, 0, 2 * Math.PI);
      return ctx.fill();
    };

    Vertex.prototype.outline = function(ctx, multiplier, lineWidth) {
      var radius;
      radius = multiplier / 1.7;
      ctx.beginPath();
      if (lineWidth){
        ctx.lineWidth = lineWidth;
      }
      ctx.arc(this.x * multiplier + 1.5, this.y * multiplier + 1.5, radius, 0, 2 * Math.PI);
      return ctx.stroke();
    };

    Vertex.prototype.valid = function() {
      if (this.x >= 0 && this.x <= 8 && this.y >= 0 && this.y <= 8) {
        return true;
      } else {
        return false;
      }
    };

    Vertex.prototype.mirror = function(fNum) {
      var delta, l, newX, newY;
      l = foldLines[fNum];
      switch (l.angle) {
        case 0:
          newY = this.y + (2 * (l.v1.y - this.y));
          return new Vertex(this.x, newY);
        case 1:
          newX = this.x + (2 * (l.v1.x - this.x));
          return new Vertex(newX, this.y);
        case 2:
          delta = l.v1.mode[l.angle] - this.mode[l.angle];
          return new Vertex(this.x + delta, this.y - delta);
        case 3:
          delta = l.v1.mode[l.angle] - this.mode[l.angle];
          return new Vertex(this.x - delta, this.y - delta);
        default:
          return false;
      }
      return false;
    };

    Vertex.prototype.identical = function(alter) {
      return (this.x === alter.x) && (this.y === alter.y);
    };

    return Vertex;

  })();

  TableSpot = (function(_super) {
    __extends(TableSpot, _super);

    function TableSpot(spot) {
      TableSpot.__super__.constructor.call(this, spot.x, spot.y);
      this.members = [];
      this.members[0] = spot;
    }

    TableSpot.prototype.addSpot = function(spot) {
      return this.members.push(spot);
    };

    TableSpot.prototype.clear = function() {
      return this.members = [];
    };

    return TableSpot;

  })(Vertex);

  Line = (function() {
    function Line(v1, v2) {
      this.v1 = v1;
      this.v2 = v2;
      if (v1.y === v2.y) {
        this.angle = 0;
      } else if (v1.x === v2.x) {
        this.angle = 1;
      } else if ((v1.x - v2.x) === (v1.y - v2.y)) {
        this.angle = 2;
      } else {
        this.angle = 3;
      }
    }

    return Line;

  })();

  Polygon = (function() {
    function Polygon() {
      this.queue = [];
      this.key = [];
      this.antiKey = [];
    }

    Polygon.prototype.mirror = function(fNum) {
      var qu, result, _i, _len, _ref;
      result = new Polygon();
      _ref = this.queue;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        qu = _ref[_i];
        result.addVertex(qu.mirror(fNum));
      }
      return result;
    };

    Polygon.prototype.contains = function(candidate) {
      var qu, _i, _len, _ref;
      _ref = this.queue;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        qu = _ref[_i];
        if (candidate.identical(qu)) {
          return true;
        }
      }
      return false;
    };

    Polygon.prototype.print = function(ctx) {
      var canv, nctx;
      if (this.queue.length > 0) {
        canv = document.createElement('canvas');
        canv.width = QUES_MULT * 8 + 3;
        canv.height = QUES_MULT * 8 + 3;
        nctx = canv.getContext('2d');
        nctx.strokeStyle = "black";
        nctx.lineWidth = 2;
        nctx.lineCap = "round";
        nctx.globalCompositeOperation = "source-over";
        this.outline(nctx);
        nctx.fillStyle = "white";
        nctx.globalCompositeOperation = "destination-over";
        this.fill(nctx);
        return ctx.drawImage(canv, 0, 0);
      }
    };

    Polygon.prototype.fill = function(ctx) {
      var OFFSET, qu, _i, _len, _ref;
      OFFSET = 1.5;
      if (this.queue.length > 0) {
        ctx.beginPath();
        ctx.moveTo(this.queue[0].x * QUES_MULT + OFFSET, this.queue[0].y * QUES_MULT + OFFSET);
        _ref = this.queue;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          qu = _ref[_i];
          ctx.lineTo(qu.x * QUES_MULT + OFFSET, qu.y * QUES_MULT + OFFSET);
        }
        ctx.closePath();
        return ctx.fill();
      }
    };

    Polygon.prototype.outline = function(ctx, strokeSyle) {
      var OFFSET, i, j, _i, _ref, _results;
      OFFSET = 1.5;

      const initialStrokeStyle = ctx.strokeStyle;
      const lineWidth = ctx.lineWidth;

      if (this.queue.length > 0) {
        ctx.lineCap = "round";
        if (strokeSyle){
          ctx.strokeStyle = strokeSyle;
          ctx.lineWidth = 3;
        }
        _results = [];
        for (i = _i = 0, _ref = this.queue.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
          j = i + 1;
          if (j >= this.queue.length) {
            j = 0;
          }
          ctx.beginPath();
          ctx.moveTo(this.queue[i].x * QUES_MULT + OFFSET, this.queue[i].y * QUES_MULT + OFFSET);
          ctx.lineTo(this.queue[j].x * QUES_MULT + OFFSET, this.queue[j].y * QUES_MULT + OFFSET);
          _results.push(ctx.stroke());
        }
        ctx.strokeStyle = initialStrokeStyle;
        ctx.lineWidth = lineWidth;
        return _results;
      }
    };

    Polygon.prototype.outlineAroundHoles = function(ctx, holes, fColor = "white") {
      var canvases, ctxes, hl, i, _i, _j, _len, _len1, _ref;
      if (holes === false) {
        holes = [];
        holes.push(currentPage.tableSpots[currentPage.holeSpot]);

        if (currentPage.secondHoleSpot){
          holes.push(currentPage.tableSpots[currentPage.secondHoleSpot]);
        }
      }
      canvases = [];
      ctxes = [];
      _ref = [0, 1];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        i = _ref[_i];
        canvases[i] = document.createElement('canvas');
        canvases[i].width = QUES_MULT * 8 + 3;
        canvases[i].height = QUES_MULT * 8 + 3;
        ctxes[i] = canvases[i].getContext('2d');
        ctxes[i].strokeStyle = "black";
        ctxes[i].lineCap = "butt";
        ctxes[i].lineWidth = 2;
        ctxes[i].globalCompositeOperation = "source-over";
      }
      this.outline(ctxes[0]);
      //ctxes[0].globalCompositeOperation = "destination-out";
      //ctxes[0].globalCompositeOperation = "destination-over";
      ctxes[0].fillStyle = "white";
      for (_j = 0, _len1 = holes.length; _j < _len1; _j++) {
        hl = holes[_j];
        hl.fill(ctxes[0], QUES_MULT);
        hl.outline(ctxes[1], QUES_MULT, 4);
      }
      ctxes[1].globalCompositeOperation = "source-in";
      this.fill(ctxes[1]);
      ctxes[1].fillStyle = fColor;
      ctxes[1].globalCompositeOperation = "destination-over";
      this.fill(ctxes[1]);
      ctx.drawImage(canvases[1], 0, 0);
      return ctx.drawImage(canvases[0], 0, 0);
    };

    Polygon.prototype.addVertex = function(vert) {
      var i, _i, _results;
      this.queue.push(vert);
      _results = [];
      for (i = _i = 0; _i <= 3; i = ++_i) {
        if (this.queue.length > 1) {
          if (vert.mode[i] > this.key[i].mode[i]) {
            this.key[i] = vert;
          }
          if (vert.mode[i] < this.antiKey[i].mode[i]) {
            _results.push(this.antiKey[i] = vert);
          } else {
            _results.push(void 0);
          }
        } else {
          this.key[i] = vert;
          _results.push(this.antiKey[i] = vert);
        }
      }
      return _results;
    };

    Polygon.prototype.edgeCase = function(fld) {
      var foldPoints, fp, hit1, hit2, i, j, _i, _j, _len, _ref;
      foldPoints = listPoints(foldLines[fld.location].v1, foldLines[fld.location].v2);
      for (i = _i = 0, _ref = this.queue.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        j = i + 1;
        if (j >= this.queue.length) {
          j = 0;
        }
        hit1 = false;
        hit2 = false;
        for (_j = 0, _len = foldPoints.length; _j < _len; _j++) {
          fp = foldPoints[_j];
          if (this.queue[i].identical(fp)) {
            hit1 = true;
          }
          if (this.queue[j].identical(fp)) {
            hit2 = true;
          }
        }
        if (hit1 && hit2) {
          return true;
        }
      }
      return false;
    };

    Polygon.prototype.sanitize = function() {
      var i, j, _results;
      i = 0;
      _results = [];
      while (i < this.queue.length) {
        j = i + 1;
        if (j >= this.queue.length) {
          j = 0;
        }
        if (this.queue[i].identical(this.queue[j])) {
          _results.push(this.queue.splice(j, 1));
        } else {
          _results.push(i++);
        }
      }
      return _results;
    };

    return Polygon;

  })();

  PunchedHoleList = (function(_super) {
    __extends(PunchedHoleList, _super);

    function PunchedHoleList() {
      PunchedHoleList.__super__.constructor.call(this);
    }

    PunchedHoleList.prototype.generateOptionObject = function() {
      var i, result, x, y, _i, _len;
      result = new OptionObject();
      for (_i = 0, _len = this.length; _i < _len; _i++) {
        i = this[_i];
        x = (i.x - 1) / 2;
        y = (i.y - 1) / 2;
        result.answerSpots[x][y] = true;
      }
      return result;
    };

    PunchedHoleList.prototype.checkValidity = function() {
      var i, _i, _len;
      for (_i = 0, _len = this.length; _i < _len; _i++) {
        i = this[_i];
        if (!i.valid()) {
          return false;
        }
      }
      if (this.length < 2) {
        return false;
      }
      return true;
    };

    return PunchedHoleList;

  })(Array);

  freshSheet = function() {
    var myPoly, mySet;
    myPoly = new Polygon();
    myPoly.addVertex(new Vertex(0, 0));
    myPoly.addVertex(new Vertex(8, 0));
    myPoly.addVertex(new Vertex(8, 8));
    myPoly.addVertex(new Vertex(0, 8));
    mySet = [];
    mySet[0] = myPoly;
    return mySet;
  };

  freshTable = function() {
    var cnt, i, j, tableSpots, x, y, _i, _j;
    tableSpots = [];
    cnt = 0;
    for (i = _i = 0; _i <= 3; i = ++_i) {
      for (j = _j = 0; _j <= 3; j = ++_j) {
        x = (i * 2) + 1;
        y = (j * 2) + 1;
        tableSpots[cnt] = new TableSpot(new Vertex(x, y));
        cnt++;
      }
    }
    return tableSpots;
  };

  Page = (function() {
    function Page() {
      var i, _i, _j, _ref;
      this.answer = "z";
      this.folds = [];
      for (i = _i = 0; _i <= (2 + fourthFoldActive); i = ++_i) {
        this.folds[i] = false;
      }
      this.correctOption = randomInt(_numAnswers);
      this.tableSpots = freshTable();
      this.options = [];
      for (i = _j = 0, _ref = answerCanvases.length - 1; 0 <= _ref ? _j <= _ref : _j >= _ref; i = 0 <= _ref ? ++_j : --_j) {
        this.options[i] = new OptionObject();
      }
    }

    Page.prototype.locateHole = function(hasSecondhole = false) {
      var cnt, max, ts, _i, _len, _ref, _results;
      max = 2;
      if (Math.random() < 0.4) {
        _ref = this.tableSpots;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          ts = _ref[_i];
          cnt = ts.members.length;
          if (cnt > max) {
            max = cnt;
          }
        }
      }
      _results = [];
      while (true) {
        this.holeSpot = randomInt(16);
        if (this.tableSpots[this.holeSpot].members.length >= max) {
          break;
        } else {
          _results.push(void 0);
        }
      }

      if (hasSecondhole){
        let pos;
        for (let i = 0; i < 100; ++i) {
          pos = randomInt(16);
          if (pos != this.holeSpot && this.tableSpots[pos].members.length >= 2) {
            //this.holeSpot = this.secondHoleSpot;
            this.secondHoleSpot = pos;
            break;
          } else {
            _results.push(void 0);
          }
        }
      }

      return _results;
    };

    Page.prototype.punchHole = function(ctx) {
      return this.tableSpots[this.holeSpot].print(ctx, "white", QUES_MULT, false);
    };

    Page.prototype.falseMirror = function() {
      var falseFold, falsified, flsPg, fp, j, newFldLine, orientation, _i, _j, _len, _ref, _ref1;
      while (true) {
        flsPg = new Page();
        for (j = _i = 0, _ref = this.folds.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; j = 0 <= _ref ? ++_i : --_i) {
          flsPg.folds[j] = this.folds[j];
        }
        flsPg.holeSpot = this.holeSpot;
        falseFold = randomInt(3);
        newFldLine = flsPg.folds[falseFold].location + randomInt(3) - 1;
        orientation = flsPg.folds[falseFold].orientation;
        if (Math.random() < 0.5) {
          orientation = !orientation;
        }
        if (newFldLine < 0) {
          newFldLine = 1;
        }
        if (newFldLine > 19) {
          newFldLine = 18;
        }
        flsPg.folds[falseFold] = new Fold();
        flsPg.folds[falseFold].location = newFldLine;
        flsPg.folds[falseFold].orientation = orientation;
        _ref1 = flsPg.folds;
        for (_j = 0, _len = _ref1.length; _j < _len; _j++) {
          fp = _ref1[_j];
          relocateSpots(flsPg.tableSpots, fp);
        }
        falsified = flsPg.copyOfPunchedHoles();
        if (falsified.checkValidity()) {
          return falsified.generateOptionObject();
        }
      }
    };

    Page.prototype.falseRelocate = function() {
      var deltaX, deltaY, falseCandidate, falsified, mover, newSpot, unmovable;
      falsified = this.copyOfPunchedHoles();
      unmovable = falsified[0].identical(this.tableSpots[this.holeSpot]) ? 1 : 0;
      while (true) {
        mover = randomInt(falsified.length - unmovable) + unmovable;
        deltaX = 2 * randomInt(3) - 2;
        deltaY = 2 * randomInt(3) - 2;
        if (falsified.length < 3 && Math.random() < 0.5) {
          deltaX = 2 * deltaX;
          deltaY = 2 * deltaY;
        }
        newSpot = new Vertex(falsified[mover].x + deltaX, falsified[mover].y + deltaY);
        if (!newSpot.valid()) {
          continue;
        }
        falseCandidate = this.copyOfPunchedHoles();
        falseCandidate[mover] = newSpot;
        return falseCandidate.generateOptionObject();
      }
    };

    Page.prototype.generateFalseAnswers = function() {
      var i, j, unique, _i, _ref, _results;
      this.options[this.correctOption] = this.copyOfPunchedHoles().generateOptionObject();
      _results = [];
      for (i = _i = 0, _ref = answerCanvases.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        if (i !== this.correctOption) {
          _results.push((function() {
            var _j, _ref1, _results1;
            _results1 = [];
            while (true) {
              unique = true;
              this.options[i] = Math.random() < 0.5 ? this.falseRelocate() : this.falseMirror();
              for (j = _j = 0, _ref1 = this.options.length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
                if (j !== i) {
                  if (!this.options[i].checkUniqueness(this.options[j])) {
                    unique = false;
                    break;
                  }
                }
              }
              if (unique) {
                break;
              } else {
                _results1.push(void 0);
              }
            }
            return _results1;
          }).call(this));
        }
      }
      return _results;
    };

    Page.prototype.copyOfPunchedHoles = function() {
      var result, spt, spts, _i, _len;
      result = new PunchedHoleList();
      spts = this.tableSpots[this.holeSpot].members;

      if (this.secondHoleSpot){
        for (const val of currentPage.tableSpots[currentPage.secondHoleSpot].members){
          spts.push(val);
        }
      }

      for (_i = 0, _len = spts.length; _i < _len; _i++) {
        spt = spts[_i];
        result.push(new Vertex(spt.x, spt.y));
      }
      return result;
    };

    Page.prototype.printAnswerChoices = function() {
      var c, ctx, i, _i, _ref, _results;
      _results = [];
      for (i = _i = 0, _ref = answerCanvases.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        c = document.getElementById(answerCanvases[i]);
        ctx = c.getContext("2d");
        _results.push(this.options[i].print(ctx, ANS_MULT));
      }
      return _results;
    };

    return Page;

  })();

  OptionObject = (function() {
    function OptionObject() {
      var i, j, _i, _j, _ref, _ref1;
      this.answerSpots = new Array(4);
      for (i = _i = 0, _ref = this.answerSpots.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        this.answerSpots[i] = new Array(4);
        for (j = _j = 0, _ref1 = this.answerSpots[i].length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
          this.answerSpots[i][j] = false;
        }
      }
    }

    OptionObject.prototype.checkUniqueness = function(optObj) {
      var i, j, _i, _j, _ref, _ref1;
      for (i = _i = 0, _ref = optObj.answerSpots.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        for (j = _j = 0, _ref1 = optObj.answerSpots[i].length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
          if (this.answerSpots[i][j] !== optObj.answerSpots[i][j]) {
            return true;
          }
        }
      }
      return false;
    };

    OptionObject.prototype.print = function(ctx, mult) {
      var i, j, radius, x, y, _i, _ref, _results;
      ctx.fillStyle = "black";
      _results = [];
      for (i = _i = 0, _ref = this.answerSpots.length - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        _results.push((function() {
          var _j, _ref1, _results1;
          _results1 = [];
          for (j = _j = 0, _ref1 = this.answerSpots[i].length - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
            x = mult + i * mult * 2;
            y = mult + j * mult * 2;
            radius = mult / 1.7;
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI);
            if (this.answerSpots[i][j]) {
              ctx.fill();
            }
            ctx.lineWidth = 2;
            _results1.push(ctx.stroke());
          }
          return _results1;
        }).call(this));
      }
      return _results;
    };

    return OptionObject;

  })();

  gridToggle = function() {
    if (!showAnswer){
      return;
    }

    showGrid = !showGrid;
    restoreCurrentPage();
  }

  answerToggle = function() {
    if (currentPage.answer !== "z") {
      showAnswer = !showAnswer;
      if (!showAnswer){
        showGrid = false;
        erasePage();
      }else{
        showGrid = true;
      }
      restoreCurrentPage();
    }
  }

  gameReset = function() {
    timeLeft = initialTime;
    totalTime = 0;
		totalAnswered = 0;

		jQuery('#average-time').text(0);
    clearInterval(timer);
    serializedPages = [];
    pagePosition = 0;
    newPage();

    if (timerOn){
      startTimer();
    }
    return tallyScoreboard();
  };

  timeReset = function() {
    timeLeft = initialTime;
    return document.getElementById("time-display").innerHTML = timeLeft;
  };

  erasePage = function() {
    clearGrades();
    clearSteps();
    clearAnswers();
    clearRadios();
    clearInterval(timer);
    timeReset();
    return setPageNumber();
  };

  clearGrades = function() {
    var ag, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = answerGrades.length; _i < _len; _i++) {
      ag = answerGrades[_i];
      _results.push(document.getElementById(ag).innerHTML = '');
    }
    return _results;
  };

  clearAnswers = function() {
    var arrLength, c, ctx, dimension, i, _i, _ref, _results;
    dimension = ANS_MULT * 8;
    arrLength = answerCanvases.length;
    _results = [];
    for (i = _i = 0, _ref = arrLength - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      c = document.getElementById(answerCanvases[i]);
      ctx = c.getContext("2d");
      ctx.fillStyle = "white";
      _results.push(ctx.fillRect(0, 0, dimension, dimension));
    }
    return _results;
  };

  clearSteps = function() {
    var arrLength, c, ctx, dimension, i, _i, _ref, _results;
    dimension = ANS_MULT * 8 + 3;
    arrLength = stepCanvases.length;
    _results = [];
    for (i = _i = 0, _ref = arrLength - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      c = document.getElementById(stepCanvases[i]);
      ctx = c.getContext("2d");
      _results.push(ctx.clearRect(0, 0, dimension, dimension));
    }
    return _results;
  };

  clearRadios = function() {
    var ele, radi, _i, _len, _results;
    ele = document.getElementsByName("answer");
    _results = [];
    for (_i = 0, _len = ele.length; _i < _len; _i++) {
      radi = ele[_i];
      radi.disabled = false;
      _results.push(radi.checked = false);
    }
    return _results;
  };

  populateFoldLines = function() {
    var i, l, v1, v1x, v1y, v2, v2x, v2y, _i, _results;
    _results = [];
    for (i = _i = 0; _i <= 4; i = ++_i) {
      v1 = new Vertex(i + 2, 0);
      v2 = new Vertex(i + 2, 8);
      l = new Line(v1, v2);
      foldLines[i] = l;
      v1 = new Vertex(0, i + 2);
      v2 = new Vertex(8, i + 2);
      l = new Line(v1, v2);
      foldLines[i + 5] = l;
      v1x = -4 + 2 * i;
      if (v1x < 0) {
        v1x = 0;
      }
      v1y = 4 - 2 * i;
      if (v1y < 0) {
        v1y = 0;
      }
      v2x = 4 + 2 * i;
      if (v2x > 8) {
        v2x = 8;
      }
      v2y = 12 - 2 * i;
      if (v2y > 8) {
        v2y = 8;
      }
      v1 = new Vertex(v1x, v1y);
      v2 = new Vertex(v2x, v2y);
      l = new Line(v1, v2);
      foldLines[i + 10] = l;
      v1x = -4 + 2 * i;
      if (v1x < 0) {
        v1x = 0;
      }
      v1y = 4 + 2 * i;
      if (v1y > 8) {
        v1y = 8;
      }
      v2x = 4 + 2 * i;
      if (v2x > 8) {
        v2x = 8;
      }
      v2y = -4 + 2 * i;
      if (v2y < 0) {
        v2y = 0;
      }
      v1 = new Vertex(v1x, v1y);
      v2 = new Vertex(v2x, v2y);
      l = new Line(v1, v2);
      _results.push(foldLines[i + 15] = l);
    }
    return _results;
  };

  sanitizeCanvases = function() {
    var arrLength, c, dimension, i, _i, _j, _ref, _ref1, _results;
    dimension = ANS_MULT * 8;
    arrLength = answerCanvases.length;
    for (i = _i = 0, _ref = arrLength - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
      c = document.getElementById(answerCanvases[i]);
      sanitize(c, dimension);
    }
    dimension = QUES_MULT * 8 + 3;
    arrLength = stepCanvases.length;
    _results = [];
    for (i = _j = 0, _ref1 = arrLength - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
      c = document.getElementById(stepCanvases[i]);
      _results.push(sanitize(c, dimension));
    }
    return _results;
  };

  sanitize = function(can, val) {
    can.width = val;
    return can.height = val;
  };

  init();
});

export default holepunching;
