import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Auth from '@aws-amplify/auth';

import {capitalize} from '@bootcamp/shared/src/util';
import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import {Button} from '../../../components/Branding/Buttons';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import Overview from '../../components/Cards/Overview';
import PageTabs from '../../components/PageTabs';
import Container, {resetScrollPosition} from '../PageContainer';

import PersonalDetails from './components/Cards/PersonalDetails';
import ResetMastery from './components/Cards/ResetMastery';
import Memberships from './components/Memberships';
import CodeRedemption from './components/Memberships/CodeRedemption';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;
const StyledOverview = styled(Overview)`
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0px;
  }
`;
const ProfilePageContainer = styled.div`
  width: 100%;
`;
const StyledPersonalDetails = styled(PersonalDetails)`
  width: 100%;
  max-width: none;
  margin-bottom: 16px;
`;
const StyledResetMastery = styled(ResetMastery)`
  width: 100%;
  max-width: none;
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const LogOutButtonContainer = styled.div`
  width: 240px;
  margin: ${({theme}) => theme.layouts.spacing.xl} auto 0;
  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
  }
`
const LogOutButton = styled(Button).attrs({type: 'ghostWhite', size: 'large', fixed: true})``;
const ProfilePage = () => {
  const {searchUserInteractions, bootcamp} = useUserDataContext();
  const didResetMastery = searchUserInteractions('didResetMastery') === true;
  return (
    <ProfilePageContainer>
      <StyledPersonalDetails/>
      {(!didResetMastery || true) && (['med-school', 'anatomy'].includes(bootcamp)) && <StyledResetMastery/>}
    </ProfilePageContainer>
  )
}
const pageComponents = {
  profile: <ProfilePage />,
  memberships: <Memberships/>,
  'redeem-code': <CodeRedemption/>,
  // billing:  (billingDetails, membership) => <Billing billingDetails={billingDetails} membership={membership}/>,
};

const AccountPage = ({history, match}) => {
  const {page} = match.params;
  const [activeTab, setActiveTab] = useState(page);
  const {userModel, cognitoUser, bootcamp} = useUserDataContext();
  const {config} = useBootcampConfigContext();

  const username = cognitoUser?.attributes?.name || userModel.id;
  const memberships = userModel?.memberships?.items;
  const pageTitle = config.meta.siteTitle;

  useEffect(() => {
    setActiveTab(page);
    resetScrollPosition();
  }, [page]);

  if (!activeTab) {
    return <Redirect to={'account/profile'}/>;
  }

  return (
    <Container>
      <Helmet>
        <title>{pageTitle} | Account | {capitalize(activeTab)}</title>
        <meta name="description" content={`${pageTitle} Account ${capitalize(activeTab)}`}></meta>
      </Helmet>
      <Wrapper>
        <StyledBreadcrumbs history={history} lockAtDepth={2}/>
        <StyledOverview
          subTitle={`Happy ${moment().format('dddd')}, ${username || '...'}!`}
          body={'Thanks for being part of the Bootcamp.com family. Edit your profile, manage your memberships, view payment history, and more on this page.'}
          actions={[]}
          showProgress={false}
          educators={[]}
        />
        <PageTabs
          tabs={[
            {name: 'Profile', route: 'profile'},
            memberships?.length > 0 ? {name: 'Memberships', route: 'memberships'} : {},
            ['med-school', 'inbde', 'dat', 'nclex'].includes(bootcamp) ? {name: 'Redeem Institutional Code', route: 'redeem-code'} : {},
            // {name: 'Billing', route: 'billing'}
          ]}
          activeTab={activeTab}
          themePalette={{default: ''}}
          // themePalette={themePalette}
        />
        {pageComponents[activeTab]}
        {activeTab === 'profile' &&
          <LogOutButtonContainer>
            <LogOutButton onClick={async () => {
              await Auth.signOut();
              history.push('/auth#/signin');
            }}>
              Log Out
            </LogOutButton>
          </LogOutButtonContainer>
        }
      </Wrapper>
    </Container>
  );
};

AccountPage.defaultProps = {};
AccountPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default AccountPage;
