import React, {useCallback, useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {getQuestionBaseWithFaqOnly} from '@bootcamp/shared/src/requests';
import {IntercomAPI} from 'react-intercom';
import {Button} from '../../Branding/Buttons';
import {H4, H3, Body2} from '../../Typography';
import {preventDefault} from '../helpers';
import {useModalContext} from '../../../contexts/Modal';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useAsync} from '@bootcamp/shared/src/util/hooks';
import {Send} from '@styled-icons/fluentui-system-filled/Send'

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 768px;
  background: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  overflow-y: scroll;
  transition: transform .4s;
  ${({visible}) => visible
    ? `
      transform: none;
    `
    : `
      transform: translateX(100%);
    `
  }

  padding: 16px;

  ${({theme}) => theme.mediaQueries.tablet} {
    max-width: 100%;
    ${({visible}) => visible
      ? `
        transform: none;
      `
      : `
        transform: translateY(100%);
      `
    }
  }
`;

const CommentContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  border-radius: 8px;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  * {
    font-family: proxima-nova !important;
  }
`;

const Label = styled(H4)`
  font-weight: 600;
  font-family: proxima-nova;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  border-bottom: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 18px;
`;
const BootcampAILabel = styled(H3)`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
`;

const Content = styled(Body2)`
  font-family: proxima-nova;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  margin-bottom: 16px;
`;

const BotContent = styled(Content)`
  background-color: ${({theme}) => theme.darkModeEnabled ? 'transparent' : `#F0EFFC`};
  padding: 16px;
  border-radius: 8px;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`;

const CloseButton = styled(Button).attrs(props => ({type: 'primary', size: 'large'}))`
  margin-bottom: 16px;
`

const BotInput = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.neutralsPalette.darkGrey};
  background: white;
  box-sizing: border-box;
  outline: none;
  position: relative;
`;

const SendButton = styled(Send).attrs(props => ({size: 24}))`
  fill: ${({theme}) => theme.colors.brandPalette.royal.default};
`;

const SendButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SubmitButton = styled(Button).attrs(props => ({type: 'primary', size: 'large'}))`
  margin-bottom: 16px;
`

const IntercomButton = styled(Button).attrs(props => ({type: 'primary', size: 'large'}))`
  margin: 16px 0px;
`

const Disclaimer = styled.em`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`

const Comment = ({content=''}) => {
  const [question, answer] = content.split(' [SEPARATE] ');

  return (
    <CommentContainer>
      <Label>Question</Label>
      <Content dangerouslySetInnerHTML={{__html: question}} />
      <Label>Answer</Label>
      <Content dangerouslySetInnerHTML={{__html: answer}} />
    </CommentContainer>
  )
}

export const BootcampBot = ({questionId, blockId}) => {
  const [query, setQuery] = useState();
  const [model, setModel] = useState('gpt-4o');
  const [value, setValue] = useState();
  const inputRef = useRef();
  const {bootcamp} = useUserDataContext();

  const streamLambdaRequest = useCallback(async () => {
    if (!query) return;
    setValue();
    await IntercomAPI('trackEvent', 'asked-bootcamp-ai', {bootcamp});
    const response = await fetch('https://m5owwna344murizkejzvgmt4lu0clmka.lambda-url.us-east-1.on.aws', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({query, questionId, model, returnHTML: true})
    });


    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      setValue(value => value ? value + chunk : chunk);
    }
  }, [query, model]);
  const {pending, error, execute} = useAsync(streamLambdaRequest, false);

  useEffect(() => {
    const handleEnter = (event) => {
      if (event.key === 'Enter' && !pending && query) {
        execute();
        inputRef.current.blur();
      }
    };
    window.addEventListener('keydown', handleEnter);
    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, [pending, execute]);

  return (
    <CommentContainer>
      <BootcampAILabel><img src="https://dekni9rgkrcu0.cloudfront.net/assets/icons/bootcamp-glyph-black.png" style={{width: 24, marginRight: 12, borderRadius: '100px', border: '1px solid white'}} />Ask Bootcamp AI</BootcampAILabel>
      <div style={{padding: '16px'}}>
        <div style={{position: 'relative', marginBottom: 16}}>
          <BotInput ref={inputRef} placeholder={bootcamp === 'nclex' ? "Ex. Why is option 1 incorrect?" : "Ex. Why is B incorrect?"} onChange={e => setQuery(e.target.value)} />
          {!pending && (
            <SendButtonContainer onClick={() => execute()}>
              <SendButton />
            </SendButtonContainer>
          )}
        </div>
        <Content>
          {pending && !value ? 'Looking that up, one moment...' : error ? 'There was an error. Please try again.': ''}
        </Content>
        {value && !error && (
          <BotContent dangerouslySetInnerHTML={{__html: value}} />
        )}
        <Disclaimer>Bootcamp AI can help answer your questions instantly. It cannot see images yet.</Disclaimer>
      </div>
    </CommentContainer>
  )
}


const QuestionFAQs = ({questionId, intercomQuestionString, close}) => {
  const [visible, setVisible] = useState()
  const [comments, setComments] = useState([]);
  const {modalDispatch} = useModalContext();
  const {bootcamp} = useUserDataContext();
  useEffect(() => {
    async function fetch () {
      const {data: {getQuestionBase: {comments}}} = await getQuestionBaseWithFaqOnly(questionId) || {data: {getQuestionBase: {comments: {items: []}}}};
      if (comments.items.length || (['dat', 'oat'].includes(bootcamp) && !window.location.pathname.includes('perceptual-ability'))) {
        modalDispatch({type: 'style', modalContainerStyle: theme => `
            background: ${theme.overlays.opacity.dark._300};
            align-items: flex-start;
            justify-content: flex-end;
            z-index: 10;
            ${theme.mediaQueries.tablet} {
              background: ${theme.overlays.opacity.dark._400};
            }
        `});
        setVisible(true);
        setComments(comments.items);
      } else {
        close();
        IntercomAPI('showNewMessage', intercomQuestionString);
      }
    }
    fetch();
  }, []);

  return (
    <Container onClick={preventDefault} visible={visible}>
      <CloseButton onClick={close}>
        Close FAQs
      </CloseButton>
      <BootcampBot questionId={questionId} />
      {comments.map(comment => <Comment {...comment} />)}

    </Container>
  )

}

export default QuestionFAQs;