import React from 'react';
import {Redirect} from 'react-router-dom';

import styled from 'styled-components';

import PracticeTest from '../PracticeTest';
import QuestionBank from '../QuestionBank';
import Generator from '../Generator';
import SelfAssessment from '../SelfAssessment'
import SimulationExam from '../SimulationExam'

import CoursePlayer from '../../components/CoursePlayer';

import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';


const Container = styled.div`
`;

const Content = ({history, match}) => {
  const {config} = useBootcampConfigContext();
  const {isBootcampPlusStudent, loading} = useUserDataContext();
  const {path, params: {bootcamp, classroom, contentType, contentParent, contentSlug}} = match;

  if (window.location.pathname.startsWith(`/${bootcamp}/full-length/`)) {
    const fullLengthTests = config.studyTools
      .find(item => item.route === 'full-length')
        .content
        .filter(test => test.plusPack ? isBootcampPlusStudent : true);

    const test = fullLengthTests
      .find(({route}) => route === contentSlug);

    return (
      <PracticeTest
        template={'fullLengthTest'}
        history={history}
        match={match}
        testId={test?.id}
      />
    );
  }

  const classroomConfig = config.classrooms.find(({route}) => route === classroom);

  if (bootcamp === 'mcat') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === 'qbanks').content;

    const banks = qbankContent.find(({name}) => name === 'Question Banks') || {content: []};

    const contentMatch = [...banks.content]
      .find(({route}) => (route === contentSlug));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={contentMatch?.name}/>
    );
  }

  if (bootcamp === 'med-school' && classroom === 'self-assessment') {
    return <SelfAssessment
      history={history}
      match={match}
    />
  }
  if (bootcamp === 'nclex' && !contentType.includes('videos')) {

    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === contentType).content;

    const banks = qbankContent.find(({name}) => name === 'Question Banks') || {content: []};

    const contentMatch = banks.content
      .find(({route}) => (route === contentSlug));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={contentMatch?.name}/>
    );
  }

  if (path.match('progress')) {
    return (
      <QuestionBank match={match} themePalette={classroomConfig.themePalette}/>
    );
  }

  if (contentType.includes('generators')) {
    const classroomContent = classroomConfig.contentTypes.find(({route}) => route === 'generators');

    const generator = contentSlug === 'trainer'
      ? {route: 'trainer'}
      : classroomContent.content.find(({route}) => route === contentSlug);

    if (!generator) return <Redirect to={'/not-found'}/>;

    return (
      <Generator generatorKey={generator.route}/>
    );
  }

  if (contentType.includes('videos')) {
    const classroomContent = classroomConfig.contentTypes.find(({route}) => route === 'videos');
    const lesson = classroomContent.content
      .find(({type}) => type === 'Course').content
      .find(({route}) => route === contentSlug);

    if (!lesson) return <Redirect to={'/not-found'}/>;

    return (
      <CoursePlayer
        match={match}
        testId={lesson.id}
        themePalette={classroomConfig.themePalette}
        classroom={classroomConfig}
        lesson={lesson}
      />
    );
  }

  if (contentType === 'practice-tests') { // TODO this needs to render topics too I think
    const classroomTests = classroomConfig.contentTypes.find(({name}) => name === 'Practice Tests');
    const classroomTest = classroomTests.content
      .find(({name}) => name === 'Tests').content
    const plusPackTests = classroomConfig.contentTypes.find(({name}) => name === 'Plus Tests')
    const plusPackTest = plusPackTests?.content
      .find(({name}) => name === 'Tests')?.content || []
    const testPool = isBootcampPlusStudent ? [...classroomTest, ...plusPackTest] : classroomTest;
    const contentMatch = testPool.find(({route}) => route === contentSlug);
    if (!loading && !contentMatch) return <Redirect to={'/not-found'}/>
    if (loading) return null;
    return (
      <PracticeTest
        history={history}
        match={match}
        testId={contentMatch.id}
      />
    );
  }

  if (['bites', 'bio-bites'].includes(contentType)) {

    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === contentType).content;

    const banks = qbankContent.find(({name}) => name === 'Question Banks') || {content: []};

    const contentMatch = [...banks.content]
      .find(({route}) => (route === contentSlug));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={contentMatch?.name}/>
    );
  }

  if (contentType === 'qbank-collection') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => ['qbanks', 'qbank-collection'].includes(route)).content;

    const banks = qbankContent.find(({name}) => ['Question Banks'].includes(name)) || {content: []};

    const parentContentMatch = banks.content
      .find(({route}) => (route === contentParent));

    if (!parentContentMatch) return <Redirect to={'/not-found'}/>

    const contentMatch = parentContentMatch.lessons.find(({route, type}) => route === contentSlug);

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={parentContentMatch?.name}/>
    );
  }

  if (contentType === 'identify-structures') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => [contentType, 'qbank-collection'].includes(route)).content;

    const banks = qbankContent.find(({name}) => ['Identify Structures', 'Question Banks'].includes(name)) || {content: []};

    const parentContentMatch = banks.content
      .find(({route}) => (route === contentParent));
    if (!parentContentMatch) return <Redirect to={'/not-found'}/>

    const contentMatch = parentContentMatch.lessons.find(({route, type}) => route === contentSlug && (!type || type === 'identification'));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.testId} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={parentContentMatch?.name}/>
    );
  }

  if (contentType === 'lecture-style-questions') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => [contentType, 'qbank-collection'].includes(route)).content;

    const banks = qbankContent.find(({name}) => ['Lecture Style Questions', 'Question Banks'].includes(name)) || {content: []};

    const parentContentMatch = banks.content
      .find(({route}) => (route === contentParent));
    if (!parentContentMatch) return <Redirect to={'/not-found'}/>

    const contentMatch = parentContentMatch.lessons.find(({route, type}) => route === contentSlug && (!type || type === 'lecture'));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.testId} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={parentContentMatch?.name}/>
    );
  }

  if (contentType === 'application') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => [contentType, 'qbank-collection'].includes(route)).content;

    const banks = qbankContent.find(({name}) => ['Lecture Style Questions', 'Question Banks'].includes(name)) || {content: []};

    const parentContentMatch = banks.content
      .find(({route}) => (route === contentParent));
    if (!parentContentMatch) return <Redirect to={'/not-found'}/>

    const contentMatch = parentContentMatch.lessons.find(({route, type}) => route === contentSlug && (!type || type === 'application'));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.testId} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={parentContentMatch?.name}/>
    );
  }

  if (contentType === 'clinical-cases') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => [contentType, 'qbank-collection'].includes(route)).content;

    const banks = qbankContent.find(({name}) => ['Lecture Style Questions', 'Question Banks'].includes(name)) || {content: []};

    const parentContentMatch = banks.content
      .find(({route}) => (route === contentParent));
    if (!parentContentMatch) return <Redirect to={'/not-found'}/>

    const contentMatch = parentContentMatch.lessons.find(({route, type}) => route === contentSlug && (!type || type === 'Clinical Cases'));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.testId} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={parentContentMatch?.name}/>
    );
  }

  if (contentType === 'practicals') {
    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === contentType).content;

    const banks = qbankContent.find(({name}) => name === 'Practical Exams') || {content: []};

    if (!banks) return <Redirect to={'/not-found'}/>

    const contentMatch = banks.content.find(({route}) => route === contentSlug);

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={contentMatch?.name}/>
    );
  }

  if (bootcamp === 'inbde' && classroom === 'simulation-exam') {

    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === contentType).content;

    const banks = qbankContent.find(({name}) => name === 'Question Banks') || {content: []};
    const plusPacks = qbankContent.find(({name}) => name === 'Plus Packs') || {content: []};

    const contentMatch = [...banks.content, ...plusPacks.content]
      .find(({route}) => (route === contentSlug));

    if (!contentMatch) return <Redirect to={'/not-found'} />

    return (
      <SimulationExam
        history={history}
        match={match}
        testId={contentMatch.id}
      />
    )
  }

  if (contentType === 'qbanks' || contentType === 'plus-packs' || contentType === 'lectures' || contentType === 'board-banks') {

    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === contentType).content;

    const banks = qbankContent.find(({name}) => name === 'Question Banks') || {content: []};
    const plusPacks = qbankContent.find(({name}) => name === 'Plus Packs') || {content: []};

    const contentMatch = [...banks.content, ...plusPacks.content]
      .find(({route}) => (route === contentSlug));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    if (bootcamp === 'inbde' && false) {
      return (
        <PracticeTest
          history={history}
          match={match}
          testId={contentMatch.id}
          template="tbcQuestionBank"
        />
      )
    }
    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={contentMatch?.name}/>
    );
  }

  if (contentType === 'rbites') {

    const qbankContent = classroomConfig.contentTypes.find(({route}) => route === contentType).content;

    const banks = qbankContent.find(({name}) => name === 'Reaction Bites') || {content: []};

    const contentMatch = banks.content
      .find(({route}) => (route === contentSlug));

    if (!contentMatch) return <Redirect to={'/not-found'}/>

    return (
      <QuestionBank contentId={contentMatch.id} match={match} themePalette={classroomConfig.themePalette} educators={classroomConfig.educators} name={contentMatch?.name}/>
    );
  }

  return (
    <Container>{match.params.contentSlug}</Container>
  );
};

Content.defaultProps = {};
Content.propTypes = {};

export default Content;
