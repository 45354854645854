import React from 'react';
import PropTypes from 'prop-types';

import {H4} from '../Typography';

import styled from 'styled-components';

const Correct = styled(H4)`
  padding: ${({theme}) => theme.layouts.spacing.s};
  padding-right: ${({theme}) => theme.layouts.spacing.m};

`;
const Incorrect = styled(H4)`
  padding: ${({theme}) => theme.layouts.spacing.s};
  padding-left: ${({theme}) => theme.layouts.spacing.m};
`;
const Divider = styled.span`
  height: 24px;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({theme}) => `3px ${theme.layouts.spacing.m}`};
  background: ${({theme}) => theme.overlays.opacity.light._100};
  border: .5px solid ${({theme, lightMode}) => lightMode ? theme.colors.neutralsPalette.light : theme.overlays.opacity.light._100};
  border-radius: 100px;
  margin: auto 0;

  ${Correct} {
    color: ${({theme, correct, lightMode}) => lightMode && correct === 0 ? theme.colors.neutralsPalette.grey : correct > 0 ? theme.colors.interfacePalette.green.default : theme.overlays.opacity.light._400};
  }

  ${Incorrect} {
    color: ${({theme, incorrect, lightMode}) => lightMode && incorrect > 0 ? theme.colors.interfacePalette.red.dark : lightMode ? theme.colors.neutralsPalette.grey : theme.overlays.opacity.light._400};
  }

  ${Divider} {
    border-right: 1px solid ${({theme, lightMode}) => lightMode ? theme.colors.neutralsPalette.lightGrey : theme.overlays.opacity.light._100};
  }
`;

const QuestionStats = ({className, correct, incorrect, lightMode}) => {
  return (
    <Container className={className} correct={correct} incorrect={incorrect} lightMode={lightMode}>
      <Correct>{correct} correct</Correct>
      <Divider/>
      <Incorrect>{incorrect} incorrect</Incorrect>
    </Container>
  );
}
QuestionStats.propTypes = {
  correct: PropTypes.number,
  incorrect: PropTypes.number,
};
QuestionStats.defaultProps = {
  correct: 0,
  incorrect: 0,
};

export default QuestionStats;
