import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
`;

const htmlString = `
<div id="content" class="exam">
			<div class="posts">
				<article>

					<div class="box-wrap">

							<div id="introduction">

								<div class="bartop">

								<div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Angle Ranking Practice Generator</h2></div></div></div>

								<div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

								<div class="instructions-container">

									<p>This is the angle ranking generator. Please read the following before starting:</p>

									<p>1. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">angle ranking instructions</a>.<br />
									2. If you're having trouble, check out the <a href="/dat/perceptual-ability/videos/angle-ranking?index=1" target="_blank">PAT Academy Angle Ranking Tutorial</a>.<br />
									3. This generator creates entirely new, unique questions on the fly, so practice as much as you'd like!</p>

									<p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

									<p id="start-generator" style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;">Start Generator</a></p>

									<div id="instructions" style="display: none;">

									<p style="margin-top: 50px;"><strong>Official Angle Ranking Instructions</strong></p>

									<p>You are to examine the four INTERIOR angles and rank each in terms of degrees from SMALL to LARGE. Select the choice that has the correct ranking.</p>

									<p>Here's an example:</p>

									<img src="https://s3.amazonaws.com/datbootcamp/anglerankingexample.png" style="max-width: 560px; margin: 35px 0px;">

									<p>The correct ranking of the angles from small to large is 2 < 1 < 4 < 3; therefore, choice [B] is correct.</p></div>

									<div style="display: block; height: 100px;"></div>

								</div>

							</div>

								<div id="generator" style="display: none;">

									<div class="bartop">
										<div class="bartopcontainer">
											<div class="column-center"><h2>Angle Ranking Practice Generator</h2></div>
											<div class="column-left"><p id="position">Question <span id="location" style="font-weight: 600;">1</span> of <span id="total" style="font-weight: 600;">1</span></p></div><div class="column-right">
												<p id="time-box" style="float: right;">Time Remaining: <span id="time-display" style="font-weight: 600;">30</span><span id="time-button" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">On/Off</span></p>
												<p id="time-box" style="float: right;">Average Time: <span id="average-time" style="font-weight: 600;">0</span><span  style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Seconds</span></p>
											</div></div>
									</div>

									<div class="generator-container">

											<div id="anglecontainer">

												<div class="canvascontainer" style="border-right: none;">
													<canvas id="canvas1" width="200" height="230"></canvas>
												</div>

												<div class="canvascontainer" style="border-right: none;">
													<canvas id="canvas2" width="200" height="230"></canvas>
												</div>

												<div class="canvascontainer" style="border-right: none;">
													<canvas id="canvas3" width="200" height="230"></canvas>
												</div>

												<div class="canvascontainer">
													<canvas id="canvas4" width="200" height="230"></canvas>
												</div>

											</div>

										<div id="answers"></div>

										<div style="clear: both;"></div>

										<div id="grades" style="font-size: 16px; margin-top: 10px; border: 1px solid #E8E8E8; padding: 5px;">
											<div class="grade" style="margin: 3px 3px 8px 3px;"><label><input class="grade-input" type="radio" name="answer" value="A" style="margin-right: 7px;">A</label><div id="grade-A" style="margin: -14px 0px 0px 15px; display: inline-block;"></div></div>
											<div class="grade" style="margin: 3px 3px 8px 3px;"><label><input type="radio" name="answer" value="B" style="margin-right: 7px;">B</label><div id="grade-B" style="margin: -14px 0px 0px 15px; display: inline-block;"></div></div>
											<div class="grade" style="margin: 3px 3px 8px 3px;"><label><input type="radio" name="answer" value="C" style="margin-right: 7px;">C</label><div id="grade-C" style="margin: -14px 0px 0px 15px; display: inline-block;"></div></div>
											<div class="grade" style="margin: 3px 3px 8px 3px;"><label><input type="radio" name="answer" value="D" style="margin-right: 7px;">D</label><div id="grade-D" style="margin: -14px 0px 0px 15px; display: inline-block;"></div></div>
										</div>


										<div class="mobileexamfix" style="clear: both; height: 25px; margin: 30px 0px;"></div>

										<div class="barbottom">

											<div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; padding: 15px 0px; justify-content: space-between;">

												<div style="display: flex; flex-direction: row; width: 33%;"><button type="button" id="previous" class="nextbutton" style="margin-right: 15px!important;">Previous</button><button type="button" id="next" class="nextbutton">Next</button></div>

												<div style="display: flex; flex-direction: column; width: 67%; text-align: right; color: #FFF;">
													<div id="scoreboard">Correct:
														<span id="correct">0</span> | Incorrect:
														<span id="incorrect">0</span> |
														<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span> | Current Difficulty:
														<select name="Difficulty" id="setdifficulty" class="bottomselector">
															<option value="0">DAT Mode</option>
															<option value="1">Beginner Mode</option>
														</select>
														<select name="AngleMode" id="setAngleMode" class="bottomselector">
															<option value="0">Random</option>
															<option value="1">Acute</option>
															<option value="2">Obtuse</option>
														</select>
														<select name="Timer" id="setTimer" class="bottomselector">
															<option value="30">30 secs</option>
															<option value="25">25 secs</option>
															<option value="20">20 secs</option>
															<option value="15">15 secs</option>
														</select>
													</div>
												</div>

											</div>

										</div>

									</div>

								</div>

					</div>

				</article>

			</div>

		</div>
`

const AngleRanking = () => (
  <Container dangerouslySetInnerHTML={{__html: htmlString}}/>
);

export default AngleRanking;
