import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';


function getWrapperPosition({activeIndex, width, index}) {
  const prefix = index < activeIndex ? '-' : '';
  return `${prefix}${Math.abs(index - activeIndex) * width}px`;
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  transition: left .45s, opacity .45s;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${getWrapperPosition};
  opacity: ${({activeIndex, index}) => index === activeIndex ? 1 : 0};
  width: 100%;
`;

const StyledFormProgress = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const FormProgress = ({className, children=[], height=400}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeProps, setActiveProps] = useState(0);
  const [width, setWidth] = useState(0);

  const containerRef = useRef();

  function next ({stepSize=1, activeProps={}}) {
    const nextIndex = Math.min(children.length - 1, activeIndex + stepSize);

    setActiveIndex(nextIndex);
    setActiveProps(activeProps);
  }

  function previous ({stepSize=1, activeProps={}}) {
    const previousIndex = Math.max(0, activeIndex - stepSize);

    setActiveIndex(previousIndex);
    setActiveProps(activeProps);
  }

  function updateDimensions () {
    if (!containerRef.current) return;
    setWidth(containerRef.current.clientWidth);
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    }
  }, []);

  return (
    <StyledFormProgress className={className} ref={containerRef}>
      {children.map((Child, index) => (
        <Wrapper
          key={index}
          width={width}
          activeIndex={activeIndex}
          index={index}
        >
          <Child next={next} previous={previous} {...activeProps}/>
        </Wrapper>
      ))}
    </StyledFormProgress>
  )
}

export default FormProgress;
