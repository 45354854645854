import React from 'react';

import useDocumentTitle from '@rehooks/document-title';
import styled from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';

import Container from '../PageContainer';

import datStudyGuide from './dat';
import inbdeStudyGuide from './inbde';
import oatStudyGuide from './oat';


const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const studyGuides = {
  dat: datStudyGuide,
  oat: oatStudyGuide,
  inbde: inbdeStudyGuide
};

const StudyGuides = props => {
  const {config} = useBootcampConfigContext();
  const {bootcamp} = useUserDataContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Study Guides`);

  return (
    <Container>
      <StyledBreadcrumbs history={props.history} lockAtDepth={2}/>
      {studyGuides[bootcamp]}
    </Container>
  );
}

StudyGuides.propTypes = {};
StudyGuides.defaultProps = {};

export default StudyGuides;
