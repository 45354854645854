import React, {useCallback} from 'react';

import {H3, Body2} from '../../../../../components/Typography';
import {LoadingSpinner} from '../../../../../components/Branding';
import {Button} from '../../../../../components/Branding/Buttons';

import {Container, TitleBar, Content} from './shared';
import {IntercomAPI} from 'react-intercom';

import {useUserDataContext} from '../../../../../contexts/UserData';
import {useAsync} from '../../../../../hooks';
import {updateQuestionMastery, getAllQuestionMasteryIdsByUserId} from '@bootcamp/shared/src/requests';

import styled from 'styled-components';

const FixAlert = styled.div`
  margin: 16px 0;
`;
const FixButton = styled(Button)`
  margin-top: 16px;
`;

const MasteryDetails = ({className}) => {
  const {DEFAULT_USER_ID, saveUserInteraction} = useUserDataContext();

  const deleteAllQuestionMastery = async () => {
    const result = await getAllQuestionMasteryIdsByUserId(DEFAULT_USER_ID);
    const items = result.data.QuestionMasteryByWpUserId.items;
    const batchSize = 500;
    const batches = Math.ceil(items.length / batchSize);

    for (let i = 0; i < batches; i++) {
      const batch = items.slice(i * batchSize, (i + 1) * batchSize);
      const batchPromises = batch.map(mastery => {
        const questionBaseId = mastery.userIdHashQuestionBaseId.replace(`${DEFAULT_USER_ID}#`, '');
        const update = {
          userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${questionBaseId}`,
          userIdHashTestId: `${DEFAULT_USER_ID}#undefined`,
          userIdHashTopicTagId: `${DEFAULT_USER_ID}#undefined`,
          userIdHashSubjectTagId: `${DEFAULT_USER_ID}#undefined`,
          userIdHashTestTagId: `${DEFAULT_USER_ID}#undefined`,
          masteryLevel: 'none',
          bookmarked: false
        };
        return updateQuestionMastery(update, false);
      });
      await Promise.all(batchPromises)
    }
  };

  const deleteMastery = useCallback(async () => {
    if (window.prompt('Warning: This will reset all your question tags to unused. This CANNOT be reversed. You will need to re-login on the mobile app. Type confirm to proceed:') !== 'confirm') return;
    await deleteAllQuestionMastery();
    await saveUserInteraction('didResetMastery', true)
    await IntercomAPI('trackEvent', 'Reset mastery tags');
    window.location.reload();
  }, []);

  const {execute: fixMastery, pending} = useAsync(deleteMastery, false)

  return (
    <Container className={className}>
      <TitleBar>
        <H3>
          Reset Question Tags
        </H3>
      </TitleBar>
      {pending
        ? <Content><LoadingSpinner color="royal" /></Content>
        : (
          <Content>
            <FixAlert>
              <Body2>Warning: This will reset all your question tags to unused. This CANNOT be reversed.</Body2>
              <FixButton onClick={() => pending ? null : fixMastery()}>
                Reset Question Mastery Tag(s)
              </FixButton>
            </FixAlert>
          </Content>
        )
      }
    </Container>
  )
}

export default MasteryDetails;
