import React, {useState, useEffect, useCallback} from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import styled, {css} from 'styled-components';

import {useContentTypeProgress} from '../../api/progress/contentType';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import Overview from '../../components/Cards/Overview';
import PageTabs from '../../components/PageTabs';
import ContentTypes from '../../components/ContentTypes';
import {Row} from '../../../components/Grid';
import Container, {resetScrollPosition} from '../PageContainer';
import {useUserDataContext} from '../../../contexts/UserData';
import {getTestBlockConnections, parseVideoDuration, findByRenderType, getTestsByClassroom} from '@bootcamp/shared/src/util';
import {getClassroomQuestionCounts, getClassroomCourseBlockCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';
import Storage from '@aws-amplify/storage';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;
const StyledOverview = styled(Overview)`
  margin-bottom: 8px;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: 0px;
  }
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const SectionSelectorContainer = styled(Row)`
  flex-wrap: wrap;
`;
const SectionSelectorOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'proxima-nova';
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  background: #FFFFFF1A;
  border-radius: 8px;
  color: white;
  height: 42px;
  padding: 12px 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  cursor: pointer;
  text-transform: capitalize;

  ${({active}) => active && css`
    background: white;
    color: #2F3037;
  `}

  &:last-child {
    margin-right: 0;
  }
`;
function getDuration(testBlock) {
  try {
    const json = findByRenderType(testBlock.components, 'video');
    const rawDuration = JSON.parse(json).duration;
    return parseVideoDuration(rawDuration);

  } catch (error) {
    return '';
  }
}

const Classroom = ({match, history, location}) => {
  const {userModel, interactions, saveUserInteraction, loading, isAdmin, isUpgraded, isBootcampPlusStudent} = useUserDataContext();

  const activeMembershipGroups = userModel?.memberships?.items?.reduce((acc, membership) => (
    membership.status === 'active' ? [...acc, ...membership.groups] : acc
  ), []) || [];

  const {bootcamp, classroom, contentType} = match.params;

  const {config} = useBootcampConfigContext();

  const [activeTab, setActiveTab] = useState(contentType);
  const {name, contentTypes: unfilteredContentTypes, themePalette, classroomDescription, educators, contentSections, route} = config.classrooms.find(configClassroom => configClassroom.route === classroom);
  // only show contentTypes that are allowed based on user
  const extendedContentTypes = ['dat', 'inbde'].includes(bootcamp) && !['application-services', 'review-videos'].includes(route)
    ? [...unfilteredContentTypes, {name: 'Performance', route: 'performance'}]
    : unfilteredContentTypes;

  const contentTypes = extendedContentTypes.filter(({allow}) => !allow || allow?.some(group => activeMembershipGroups.includes(group)) || isAdmin);

  const urlParams = new URLSearchParams(location.search);
  const selectedSubject = urlParams.get('subject');

  const selectedSection = contentSections && !selectedSubject ? urlParams.get('section') : 'all';

  // TODO since we are loading data here in the overview, we can passs progress data to PTP and QB cards to prevent additional fetches
  const contentTypeConfig = contentTypes.find(type => type.route && (type.route === activeTab)) || contentTypes?.[0];
  const overviewData = useContentTypeProgress(contentTypeConfig, selectedSection)[`${contentTypeConfig.route}-${selectedSection}`];

  const hasProgress = overviewData?.progress
    ? !!Object.values(overviewData?.progress?.all).find(value => !!value)
    : overviewData?.qbankCollectionData
    ? !!Object.values(overviewData?.qbankCollectionData).find(value => !!value?.mastery?.length)
    : null;

  // sum the duration of each video lesson
  const {value: duration} = useAsync(useCallback(async () => {
    try {
      if (duration) return duration;
      const result = await Storage.get(`json/${bootcamp}/videoDurations/${classroom}-duration.json`, {download: true});
      // if result is over 7 days old, refetch
      if ((!result || result.lastModified < Date.now() - 604800000) && contentType === 'videos') {
        console.log('Refetch')
        throw new Error('Refetch');
      }
      return JSON.parse(await result.Body.text());
    } catch (e) {
      if (!overviewData?.videoLessonData) return '';
      const testBlocks = Object.values(overviewData?.videoLessonData || {})?.reduce((acc, {test}) => [...acc, ...getTestBlockConnections(test).map(({testBlock}) => testBlock)], []);

      const totalDuration = testBlocks.reduce((acc, testBlock) => {
        const duration = getDuration(testBlock);
        if (!duration) return acc;
        // duration is a timestamp in the form "HH:MM:SS"
        // timestamp could also be in the form "MM:SS"
        // parse and sum the total duration in seconds
        const durationSplit = duration.split(':').map(Number);
        if (durationSplit.length === 2) {
          const [minutes, seconds] = durationSplit.map(Number);
          return acc + minutes * 60 + seconds;
        } else if (durationSplit.length === 3) {
          const [hours, minutes, seconds] = durationSplit.map(Number);
          return acc + hours * 3600 + minutes * 60 + seconds;
        }
      }, 0);
      // format totalDuration in the form "Hh Mm Sss"
      const hours = Math.floor(totalDuration / 3600);
      const minutes = Math.floor((totalDuration % 3600) / 60);
      const duration = `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}`;
      await Storage.put(`json/${bootcamp}/videoDurations/${classroom}-duration.json`, JSON.stringify(duration), {contentType: 'application/json'});
      return duration;
    }
  }, [overviewData]));

  const coursesByClassroom = getTestsByClassroom(config?.classrooms, [], ['Course']);
  const {value: questionCounts} = useAsync(useCallback(() => getClassroomQuestionCounts(bootcamp, [], isBootcampPlusStudent && ['dat', 'oat'].includes(bootcamp)), []));
  const {value: courseCounts} = useAsync(useCallback(() => getClassroomCourseBlockCounts(bootcamp, coursesByClassroom), []));
  useEffect(() => {
    if (!contentType) return;

    setActiveTab(contentType);
    resetScrollPosition();

    if (contentSections && !selectedSection && !selectedSubject && !loading) {
      const defaultDestination = interactions.find(({id}) => id.match(`${route}-classroom-section`))?.value || 'all';
      history.replace({
        pathname: location.pathname,
        search: `?section=${encodeURIComponent(defaultDestination)}`
      });
    }
  }, [contentType, loading]);

  // TODO redirect here when there is no content type match from config
  if (!contentTypeConfig.route) return <Redirect to={`/${bootcamp}`}/>;
  if (!contentTypes.find(type => type.route && (type.route === contentType))) return <Redirect to={`/${bootcamp}/${classroom}/${contentTypeConfig.route}`}/>;

  const SectionSelector = ({contentSections}) => {
    if (!contentSections || contentType === 'practicals' || contentType === 'reference' || (name === 'Microbiology' && contentType != 'videos')) return null;
    const sectionClickHandler = sectionTitle => {
      const isDeselecting = selectedSection === sectionTitle;
      if (!isDeselecting) {
        saveUserInteraction(`${route}-classroom-section`, sectionTitle);
      }
      return history.replace({
        pathname: location.pathname,
        search: `?section=${isDeselecting ? 'all' : encodeURIComponent(sectionTitle)}`
      });
    };
    return (
      <SectionSelectorContainer>
        {['all', ...contentSections].map(contentSectionTitle => <SectionSelectorOption active={selectedSection === contentSectionTitle} key={contentSectionTitle} onClick={() => sectionClickHandler(contentSectionTitle)}>{contentSectionTitle}</SectionSelectorOption>)}
      </SectionSelectorContainer>
    )
  };

  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Wrapper>
        <StyledBreadcrumbs history={history} lockAtDepth={2}/>
        <StyledOverview
          key={name}
          subTitle={name}
          body={bootcamp === 'dat' ? '' : bootcamp === 'anatomy' ? classroomDescription.replace(', Doctor', '') : classroomDescription}
          actions={[]}
          showProgress={false}
          educators={educators}
          hasProgress={hasProgress}
          classroom={classroom}
          showCreateTestButton={['inbde'].includes(bootcamp) && !['gross-anatomy', 'neuroanatomy', 'histology', 'embryology', 'mock-exam', 'simulation-exam', 'review-videos'].includes(classroom)}
          showStudyTaggedQuestionsButton={isUpgraded && (!['med-school', 'inbde', 'nclex'].includes(bootcamp) || ['gross-anatomy', 'neuroanatomy', 'histology', 'embryology'].includes(classroom)) && ![
            'reading-comprehension',
            'application-services'
            ].includes(classroom)}
          showingDuration={contentTypes?.find(({route}) => route === 'videos')}
          duration={duration}
          showingCourseCount={courseCounts?.classroomCourseBlockCounts?.[classroom] > 0}
          courseCount={courseCounts?.classroomCourseBlockCounts?.[classroom]}
          showingTotalQuestionCount={questionCounts?.classroomQuestionCounts?.[classroom] > 0}
          totalQuestionCount={questionCounts?.classroomQuestionCounts?.[classroom]}
        />
        <PageTabs
          tabs={name === 'Reading Comprehension' ? [...contentTypes, {name: 'Daily Reading Practice', externalLink: 'https://www.sciencedaily.com/news/top/'}] : contentTypes}
          activeTab={activeTab}
          themePalette={themePalette}
        />
        <SectionSelector contentSections={contentSections} />
        <ContentTypes
          subject={name}
          config={contentTypeConfig}
          themePalette={themePalette}
          overviewData={overviewData}
          calloutText={contentTypeConfig.calloutText}
        />
      </Wrapper>
    </Container>
  );
}

Classroom.propTypes = {};
Classroom.defaultProps = {};

export default Classroom;
