import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'array-flat-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react'; // TODO set up sentry for app.bootcamp
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import TagManager from 'react-gtm-module';

import 'react-tooltip/dist/react-tooltip.css';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

smoothscroll.polyfill();

// function registerWorker () {
//   serviceWorker.register({
//     onUpdate: (registration) => {
//       const waitingServiceWorker = registration.waiting;

//       if (waitingServiceWorker) {
//         waitingServiceWorker.addEventListener('statechange', (event) => {
//           if (event.target.state === 'activated') {
//             window.location.reload();
//           }
//         });
//         waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//       }
//     },
//   });
// }

const defaultRoot = document.getElementById('root');

const subdomain = window.location.host.split('.')[0];
const domain = window.location.host;

if (domain === 'tbc.d1t156yxaeacew.amplifyapp.com') {
  window.location.href = 'https://admin.bootcamp.com';
}

if (!['datbootcamp.com', 'admin.teambootcamp.com', 'admin.bootcamp.com', 'localhost', 'anatomy.localhost', 'chemistry.localhost', 'localhost:3000', 'anatomy.localhost:3000', 'chemistry.localhost:3000', 'oat.localhost:3000'].includes(domain)) {
  Sentry.init({
    dsn: "https://ce2c68f29bed4a70a954ee526454f871@o418783.ingest.sentry.io/5324366",
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
const tagManagerArgs = {
  gtmId: 'GTM-T4VNGX5' // Bootcamp.com GTM Code
}
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, defaultRoot);
