import {useEffect, useState} from 'react';

import {useUserDataContext} from '../UserData';
import {useBootcampConfigContext} from '../BootcampConfig';
import {getTestBlockConnections} from './helpers';

import {getTestBlockQuestions, getRevisions, getTagType, getInObj, calculateAnswerPercentage, getQuestionParts, getQuestionPartsAsync} from '@bootcamp/shared/src/util';
import {insertAtIndex} from '@bootcamp/shared/src/util';
import {calculateMaxScore} from '@bootcamp/shared/src/util/scoring/NCLEX';
import {saveTestProgress, createBlockProgress, createQuestionProgress, updateQuestionProgress, updateBlockProgress, updateQuestionMastery, getAllQuestionMastery, getQuestionAnswerData, getTestProgress, getTestProgressByUserIdHashTestId, trackFirstQuestionAttempt} from '@bootcamp/shared/src/requests';
import nanoid from 'nanoid';
import firstBy from 'thenby';
import Storage from '@aws-amplify/storage';
import * as Sentry from '@sentry/react'; // TODO set up sentry for app.bootcamp

const generateId = (userId) => `${nanoid()}_${userId}`;

const questionProgressTemplate = {
  customTest: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: false,
    isSequentialSet: false,
    isSequentialStart: false,
    isSequentialEnd: false,
    seen: false
  },
  practiceTest: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    time: "0",
    current: false,
    highlights: '',
    masteryLevel: 'none',
    crossedAnswerIndexes: []
  },
  coursePlayer: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    current: false,
    highlights: '',
    crossedAnswerIndexes: []
  },
  tbcQuestionBank: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: false,
  },
  tbcSavedBank: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: false,
  },
  quickReview: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: false,
  },
  masteryReview: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: false,
  },
  bookmarkedQuestionReview: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    didCheck: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: true,
  },
  testReview: {
    selectedAnswerIndex: -1,
    didSelectCorrectAnswer: false,
    didSkip: true,
    didMark: false,
    time: "0",
    highlights: '',
    masteryLevel: 'none',
    bookmarked: false,
  }
};

const progressTemplate = {
  practiceTest: {
    startBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    passage: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId, 'practiceTest')
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId, 'practiceTest'),
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  coursePlayer: {
    startBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    text: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      status: () => '',
      newBlockProgress: () => true
    },
    lesson: {
      blockProgressId: (block, userId) => `${block.id}#${userId}`,
      testBlockId: block => block.id,
      highlights: () => '',
      status: () => '',
      newBlockProgress: () => true
    },
    passage: {
      blockProgressId: (block, userId) => `${block.id}#${userId}`,
      testBlockId: block => block.id,
      highlights: () => '',
      status: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.coursePlayer, block, userId),
      newBlockProgress: () => true
    },
    questionSet: {
      blockProgressId: (block, userId) => `${block.id}#${userId}`,
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.coursePlayer, block, userId),
      status: () => '',
      newBlockProgress: () => true
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  tbcQuestionBank: {
    startBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      isSequentialSet: (block) => {return JSON.parse(block.components?.find(component => component.renderType === 'config')?.contents || 'null')?.isSequentialSet},
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.tbcQuestionBank, block, userId, 'tbcQuestionBank', masteryMap),
    },
    caseQuestionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId, 'tbcQuestionBank', masteryMap),
    },
    passage: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.tbcQuestionBank, block, userId, 'tbcQuestionBank', masteryMap)
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  tbcSavedBank: {
    startBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      isSequentialSet: (block) => {return JSON.parse(block.components?.find(component => component.renderType === 'config')?.contents || 'null')?.isSequentialSet},
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.tbcQuestionBank, block, userId, 'tbcQuestionBank', masteryMap),
    },
    caseQuestionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId, 'tbcQuestionBank', masteryMap),
    },
    passage: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.tbcQuestionBank, block, userId, 'tbcQuestionBank', masteryMap)
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    sectionBreak: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  customTest: {
    startBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    startCustomTest: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: (block, userId) => generateId(userId),
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: (block, userId) => generateId(userId),
      highlights: () => '',
      questions: (block, userId, masteryMap) => getQuestionProgressArray(questionProgressTemplate.customTest, block, userId, 'customTest', masteryMap),
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: (block, userId) => generateId(userId),
    },
  },
  quickReview: {
    startQuickReview: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.quickReview, block, userId, 'quickReview'),
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  masteryReview: {
    startMasteryReview: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.masteryReview, block, userId, 'masteryReview'),
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  bookmarkedQuestionReview: {
    startBookmarkedQuestionReview: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.masteryReview, block, userId, 'masteryReview'),
    },
    endBlock: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  testReview: {
    startReview: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    passage: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId)
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId)
    },
    endReview: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  },
  fullLengthTest: {
    startFullLength: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    passage: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId, 'fullLengthTest'),
    },
    questionSet: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
      highlights: () => '',
      questions: (block, userId) => getQuestionProgressArray(questionProgressTemplate.practiceTest, block, userId, 'fullLengthTest'),
    },
    sectionBreak: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    sectionStart: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
    sectionReview: {
      blockProgressId: (block, userId) => generateId(userId),
      testBlockId: block => block.id,
    },
  }
};

async function getMasteryMap (userId, questions, template, testId) {
  // these templates don't need mastery in progress: no map required
  if (['practiceTest', 'fullLengthTest'].includes(template)) return {};

  // these templates have mastery embedded in questionbases: index by questionBaseId
  if (['quickReview', 'masteryReview'].includes(template)) {
    return questions.reduce((acc, question) => ({...acc, [question.id]: question}), {});
  }
  // otherwise, create masteryMap by indexing questionMastery per questionBaseId
  const questionMasteryPromises = questions.map(question => getAllQuestionMastery({userIdHashQuestionBaseId: `${userId}#${question.id}`}));
  const userQuestionMasteryResult = await Promise.all(questionMasteryPromises);
  return userQuestionMasteryResult.reduce((acc, {data: {getQuestionMastery: questionMastery}}) => questionMastery ? {...acc, [questionMastery.userIdHashQuestionBaseId.replace(`${userId}#`, '')]: questionMastery } : acc, {});
}

async function getQuestionProgressArray(progressTemplate, testBlock, userId, template, masteryMap) {
  const questions = getTestBlockQuestions(testBlock, template === 'customTest');

  const userQuestionMasteryMap = masteryMap || await getMasteryMap(userId, questions, template);

  return Array.from({length: questions.length}).map((__, index) => {
    const question = questions[index];
    const questionRevisions = getRevisions(question);
    const {prompt, type, answers, answerGroups, answerMatrix} = getQuestionParts(question);
    const {id: questionRevisionId} = questionRevisions[questionRevisions.length - 1] || {};
    const {masteryLevel, bookmarked} = userQuestionMasteryMap[question.id] || {};

    progressTemplate.masteryLevel = masteryLevel || 'none';
    progressTemplate.bookmarked = bookmarked;
    progressTemplate.didMark = !!bookmarked;

    progressTemplate.answerState = {
      score: 0,
      maxScore: calculateMaxScore(prompt, type, answers, answerGroups, answerMatrix),
      type,
    };

    if (template === 'customTest') {
      progressTemplate.isSequentialSet = question.isSequentialSet
      progressTemplate.isSequentialStart = question.isSequentialStart
      progressTemplate.isSequentialEnd = question.isSequentialEnd
    }

    function getOriginalTestId () {
      const {originalTestId} = question; // originalTestId stored in questionbase when creating QuickReview test
      if (originalTestId) return originalTestId;
      if (template === 'fullLengthTest') {
        const blockSubject = testBlock.type === 'passage' ? 'Reading' : getInObj(['title'], testBlock, '').split(' ')[0];
        const originalTestBlock = blockSubject
          ? testBlock?.blockConnections?.items.find(({test}) => {
            const testTitle = getInObj(['config', 'title'], test, '');
            if (testTitle.includes('Plus Pack')) return false; // skip plus pack attribution for DAT questions
            return testTitle.match(blockSubject)
          })
          : {};
        return getInObj(['test', 'id'], originalTestBlock, null);
      }
    }

    return {...progressTemplate, originalTestId: getOriginalTestId(), questionProgressId: generateId(userId), questionBaseId: question.id, questionRevisionId, questionIndex: index, question};
  });
}

const useProgress = (test, testResultId, template, blockIndex, match, isReadinessExam) => {
  const [quizProgress, setQuizProgress] = useState([]);
  const [testProgressId, setTestProgressId] = useState();
  const [restoringProgress, setRestoringProgress] = useState(false);
  const [answerData, setAnswerData] = useState({});
  const {config} = useBootcampConfigContext();
  const {testProgress, setTestProgress, fetchTestProgress, DEFAULT_USER_ID, bootcamp, cognitoUser, isAdmin} = useUserDataContext();
  const getAnswerDataUploadKey = questionId => `answerData/question/${questionId}.json`;

  const urlParams = new URLSearchParams(window.location.search);

  const getAnswerDataForQuestion = async (questionId, revisionId, formatter) => {
    if (!questionId || answerData[questionId]) return;

    const answerDataUploadKey = getAnswerDataUploadKey(questionId);

    try {
      const data = await getQuestionAnswerData(questionId, answerDataUploadKey, formatter);

      setAnswerData(currentState => ({...currentState, [questionId]: data?.[revisionId] || {}}));

    } catch (error) {
      // console.log('error fetching answer data');
    }
  };

  const updateQuizProgress = (blockProgress, blockIndexOverride=blockIndex) => {
    return setQuizProgress(quizProgress => ([
      ...quizProgress.slice(0, blockIndexOverride),
      {...quizProgress[blockIndexOverride], ...blockProgress},
      ...quizProgress.slice(blockIndexOverride + 1)
    ]))
  };

  const instantiateBlockProgressFromTemplate = (testBlock, masteryMap) => {
    const blockTemplate = progressTemplate[template][testBlock.type];
    const newBlockProgress = Object.keys(blockTemplate)
      .reduce(async (acc, key) => ({...(await acc), [key]: await blockTemplate[key](testBlock, DEFAULT_USER_ID, masteryMap)}), {});
    return newBlockProgress;
  };

  const instantiateQuestionProgressFromArray = (startIndex, questionArray) => {
    const progressTemplate = questionProgressTemplate[template];
    return questionArray
      .filter(({question}) => question?.id) // filter out any dead-end questions
      .map(({question, ...questionProgress}, index) => ({...progressTemplate, ...questionProgress, questionBaseId: question.id, index: startIndex + index, questionIndex: startIndex + index}));
  };

  const syncProgress = async (testProgress) => {
    if (!testProgress || !test) return;
    // we need to synchronize the test structure with the saved test progress, and if there are discrepancies, we need to update the saved testProgress
    const testBlockConnections = getTestBlockConnections(test);

    const allQuestions = testBlockConnections.reduce((acc, {testBlock}) => [...acc, ...getTestBlockQuestions(testBlock)], []);
    const allQuestionProgresses = testProgress.blockProgresses.items.reduce((acc, blockProgress) => [...acc, ...(blockProgress.questionProgresses?.items || [])], []);

    const questionMasteryPromises = allQuestions.map(question => getAllQuestionMastery({userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${question.id}`}));
    const userQuestionMasteryResult = await Promise.all(questionMasteryPromises);
    const userQuestionMasteryMap = userQuestionMasteryResult.reduce((acc, {data: {getQuestionMastery: questionMastery}}) => questionMastery ? {...acc, [questionMastery.userIdHashQuestionBaseId.replace(`${DEFAULT_USER_ID}#`, '')]: questionMastery} : acc, []);

    const restoredProgress = testBlockConnections.reduce(async (acc, {testBlock}, index) => {
      let blockProgress = testProgress.blockProgresses.items.find(blockProgress => blockProgress.blockId === testBlock.id);
      if (!blockProgress) {
        blockProgress = await instantiateBlockProgressFromTemplate(testBlock, []);
        blockProgress.id = blockProgress.blockProgressId
        await createBlockProgress(blockProgress.id, testProgress.id, testBlock.id, {}, index);
        blockProgress.questionProgresses = {items: []};
      }
      const {id: blockProgressId, blockId, highlights, status} = blockProgress;

      const isSequentialBlock = JSON.parse(testBlock?.components?.find(component => component.renderType === 'config')?.contents || "{}")?.isSequentialSet;

      const blockSubject = testBlock.type === 'passage' ? 'Reading' : getInObj(['title'], testBlock, '').split(' ')[0];
      const originalTestBlock = blockSubject
        ? testBlock?.blockConnections?.items.find(({test}) => {
          const testTitle = getInObj(['config', 'title'], test, '');
          if (bootcamp === 'dat' && testTitle.includes('Plus Pack')) return false; // skip plus pack attribution for DAT questions
          return testTitle.match(blockSubject)
        })
        : {};
      const originalTestId = getInObj(['test', 'id'], originalTestBlock, null);

      const questions = getTestBlockQuestions(testBlock);

      const restoredQuestionProgresses = await questions.reduce(async (questionProgressesAcc, question, index) => {
        const questionRevisionId = question.latestRevisions.items[0].id;
        const questionProgress = allQuestionProgresses.find(questionProgress => questionProgress.question.id === question.id);
        if (!questionProgress) {
          const progressTemplate = questionProgressTemplate[template];
          const {subject, topic} = question.tags && question.tags.items && question.tags.items.length > 0 ? (question.tags.items.find(({tag}) => tag && !!tag.subject && !!tag.topic) || {}).tag || {} : {};
          const {masteryLevel, bookmarked} = userQuestionMasteryMap[question.id] || {};
          const questionProgressId = generateId(DEFAULT_USER_ID);
          await createQuestionProgress(questionProgressId, blockProgressId, question.id, questionRevisionId, {index, masteryLevel: masteryLevel || 'none', selectedAnswerIndex: -1, didSelectCorrectAnswer: false, didSkip: true, didMark: false, time: "0", highlights: ''}, index);
          return [
            ...(await questionProgressesAcc),
            {
              ...progressTemplate,
              questionProgressId,
              questionBaseId: question.id,
              questionRevisionId,
              index,
              questionIndex: index,
              subject,
              topic,
              subjectTopic: `${subject}##${topic}`,
              masteryLevel: masteryLevel || 'none',
              bookmarked,
              originalTestId: originalTestId || question.originalTestId,
              isSequentialSet: isSequentialBlock,
              isSequentialStart: isSequentialBlock && index === 0,
              isSequentialEnd: isSequentialBlock && index === questions.length - 1,
              didMark: bookmarked,
              answerState: {},
            }
          ];
        }

        const originalTestIdFromQuestion = question.originalTestId;
        const {id: questionProgressId, answerState, ...questionProgressObj} = questionProgress;
        const {masteryLevel, bookmarked} = userQuestionMasteryMap[question.id] || {};
        const {subject, topic} = question.tags && question.tags.items && question.tags.items.length > 0 ? (question.tags.items.find(({tag}) => tag && !!tag.subject && !!tag.topic) || {}).tag || {} : {};
        const questionAnswerState = JSON.parse(answerState || '{}');
        const didAnswerNclexQuestion = !!questionAnswerState?.answerState;

        const nclexProgressProps = bootcamp === 'nclex' ?
          {
            fullCredit: !!didAnswerNclexQuestion && !!questionAnswerState?.score && !!questionAnswerState?.maxScore && questionAnswerState?.score === questionAnswerState?.maxScore,
            partialCredit: !!didAnswerNclexQuestion && questionAnswerState?.score > 0 && (questionAnswerState?.score !== questionAnswerState?.maxScore),
            noCredit: (!questionAnswerState?.score || questionAnswerState?.score === 0),
            isSequentialSet: isSequentialBlock,
            isSequentialStart: isSequentialBlock && index === 0,
            isSequentialEnd: isSequentialBlock && index === questions.length - 1,
            didCheck: didAnswerNclexQuestion,
            didSelectCorrectAnswer: null,
          } : {
            didCheck: questionProgressObj.didCheck,
            isSequentialSet: isSequentialBlock,
            isSequentialStart: isSequentialBlock && index === 0,
            isSequentialEnd: isSequentialBlock && index === questions.length - 1,
          };

        const restoredQuestionProgress = {
          questionProgressId,
          subject,
          topic,
          subjectTopic: `${subject}##${topic}`,
          ...questionProgressObj,
          questionRevisionId: !questionProgressObj.didCheck || isAdmin ? questionRevisionId : questionProgressObj.questionRevisionId,
          masteryLevel: masteryLevel || 'none',
          bookmarked,
          originalTestId: originalTestId || originalTestIdFromQuestion,
          questionIndex: index,
          answerState: questionAnswerState,
          ...nclexProgressProps,
          didMark: bookmarked
        };

        return [
          ...(await questionProgressesAcc),
          restoredQuestionProgress
        ];
      }, []);

      return [
        ...(await acc),
        {
          blockId,
          blockProgressId,
          highlights,
          questions: restoredQuestionProgresses,
          status
        }
      ]
    }, []);
    const awaitedRestoredProgress = await restoredProgress;

    const allRestoredQuestionProgresses = awaitedRestoredProgress.reduce((acc, blockProgress) => [...acc, ...(blockProgress?.questions || [])], []);
    // now, if there are any allQuestionProgress items that are not in allRestoredQuestionProgresses, we need to update them to unlink from blockProgress

    const questionProgressesToRemove = allQuestionProgresses.filter(questionProgress => !allRestoredQuestionProgresses.find(restoredQuestionProgress => restoredQuestionProgress?.question?.id === questionProgress?.question?.id));
    const questionProgressesToRemovePromises = questionProgressesToRemove.map(questionProgress => updateQuestionProgress(questionProgress.id, {blockProgressQuestionProgressesId: null}));
    await Promise.all(questionProgressesToRemovePromises);
    // now, if there are any blockProgress items that are not in the restoredProgress, we need to update them to unlink from testProgress
    const blockProgressesToRemove = testProgress.blockProgresses.items.filter(blockProgress => !awaitedRestoredProgress.find(restoredBlockProgress => restoredBlockProgress.blockProgressId === blockProgress.id));
    const blockProgressesToRemovePromises = blockProgressesToRemove.map(blockProgress => updateBlockProgress(blockProgress.id, {testProgressBlockProgressesId: null}));
    await Promise.all(blockProgressesToRemovePromises);
    return setQuizProgress(awaitedRestoredProgress);
  }

  const restoreProgress = async (testProgress) => {
    if (!testProgress) return [];

    const restoredProgress = testProgress.blockProgresses.items.sort(firstBy('index'))
    .reduce((acc, blockProgress) => acc.find(filteredBlockProgress => filteredBlockProgress.index === blockProgress.index) ? acc : [...acc, blockProgress], []) // only one blockProgress for each index will be used for quizProgress
    .reduce(async (acc, blockProgress, index) => {
      try {
        const {id: blockProgressId, blockId, highlights, questionProgresses: {items: questionProgresses}, status} = blockProgress;

        const {testBlock} = test.blockConnections.items.find(connection => connection.index === index);
        const isSequentialBlock = JSON.parse(testBlock?.components?.find(component => component.renderType === 'config')?.contents || "{}")?.isSequentialSet;

        const blockSubject = testBlock.type === 'passage' ? 'Reading' : getInObj(['title'], testBlock, '').split(' ')[0];
        const originalTestBlock = blockSubject
          ? testBlock?.blockConnections?.items.find(({test}) => {
            const testTitle = getInObj(['config', 'title'], test, '');
            if (bootcamp === 'dat' && testTitle.includes('Plus Pack')) return false; // skip plus pack attribution for DAT questions
            return testTitle.match(blockSubject)
          })
          : {};
        const originalTestId = getInObj(['test', 'id'], originalTestBlock, null);

        questionProgresses.sort(firstBy('index'));

        const questions = questionProgresses.filter(questionProgress => questionProgress.question).map(questionProgress => questionProgress.question);
        const questionMasteryPromises = questions.map(question => getAllQuestionMastery({userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${question.id}`}));
        const userQuestionMasteryResult = await Promise.all(questionMasteryPromises);
        const userQuestionMasteryMap = userQuestionMasteryResult.reduce((acc, {data: {getQuestionMastery: questionMastery}}) => questionMastery ? {...acc, [questionMastery.userIdHashQuestionBaseId.replace(`${DEFAULT_USER_ID}#`, '')]: questionMastery } : acc, []);

        const restoredQuestionProgresses = await questionProgresses.reduce(async (questionProgressesAcc, questionProgress, index) => {
          // NOTE this variable is handling custom test original test id attribution
          const originalTestIdFromQuestion = testBlock?.questionConnections?.items?.[index]?.question?.originalTestId;
          const {id: questionProgressId, answerState, didSelectCorrectAnswer, ...questionProgressObj} = questionProgress;
          const question = questionProgress.question || {};
          const {masteryLevel, bookmarked} = userQuestionMasteryMap[question.id] || {};
          const {subject, topic} = question.tags && question.tags.items && question.tags.items.length > 0 ? (question.tags.items.find(({tag}) => tag && !!tag.subject && !!tag.topic) || {}).tag || {} : {};
          const questionAnswerState = JSON.parse(answerState || '{}');
          const didAnswerNclexQuestion = !!questionAnswerState?.answerState;
          const {answers} = await getQuestionPartsAsync(question, questionProgressObj.questionRevisionId);
          const correctAnswerIndex = answers?.reduce((acc, [answerText, answerCorrect], index) => answerCorrect ? `${acc}${index}` : acc, '') || -1;
          const answeredCorrectly = correctAnswerIndex == questionProgress.selectedAnswerIndex;
          if (answeredCorrectly != didSelectCorrectAnswer && template === 'testReview') {
            await updateQuestionProgress(questionProgressId, {didSelectCorrectAnswer: answeredCorrectly});
          }
          const nclexProgressProps = bootcamp === 'nclex' ?
            {
              fullCredit: !!didAnswerNclexQuestion && !!questionAnswerState?.score && !!questionAnswerState?.maxScore && questionAnswerState?.score === questionAnswerState?.maxScore,
              partialCredit: !!didAnswerNclexQuestion && questionAnswerState?.score > 0 && (questionAnswerState?.score !== questionAnswerState?.maxScore),
              noCredit: (!questionAnswerState?.score || questionAnswerState?.score === 0),
              isSequentialSet: isSequentialBlock,
              isSequentialStart: isSequentialBlock && index === 0,
              isSequentialEnd: isSequentialBlock && index === questionProgresses.length - 1,
              didCheck: didAnswerNclexQuestion,
              didSelectCorrectAnswer: null,
            } : {
              didCheck: template === 'testReview' ? true : questionProgressObj.didCheck,
              isSequentialSet: isSequentialBlock,
              isSequentialStart: isSequentialBlock && index === 0,
              isSequentialEnd: isSequentialBlock && index === questionProgresses.length - 1,
            };

          const restoredQuestionProgress = {
            questionProgressId,
            subject,
            topic,
            subjectTopic: `${subject}##${topic}`,
            ...questionProgressObj,
            didSelectCorrectAnswer: template === 'testReview' ? answeredCorrectly : didSelectCorrectAnswer,
            masteryLevel: masteryLevel || 'none',
            bookmarked,
            originalTestId: originalTestId || originalTestIdFromQuestion,
            questionIndex: index,
            answerState: questionAnswerState,
            ...nclexProgressProps,
            didMark: bookmarked
          };

          return [
            ...(await questionProgressesAcc),
            restoredQuestionProgress
          ];
        }, []);
        return [
          ...(await acc),
          {
            blockId,
            blockProgressId,
            highlights,
            questions: restoredQuestionProgresses,
            status
          }
        ]
      } catch (error) {
        return [
          ...(await acc),
          blockProgress
        ]
      }
    }, []);

    setQuizProgress(await restoredProgress);
  };

  const saveQuestionProgresses = async (questionProgresses) => {
    try {
      const questionProgressesPromises = questionProgresses.map(async ({questionProgressId, question, ...questionProgress}, questionIndex) => {
        if (!question || !question.id) return null;
        const {masteryLevel, bookmarked, originalTestId} = questionProgress;

        function getTestIdOverride () {
          try {
            if (template === 'coursePlayer') {
              const pathParams = window.location.pathname.split('/');
              // locate corresponding qbank for this courseplayer bite
              if (pathParams?.[3] === 'videos') {
                if (bootcamp === 'dat' || bootcamp === 'oat') {

                  // these are classrooms within dat that have shared questions between topic qbs & course player quizzes
                  // eg. course player quizzes in the biology classroom correspond to the topic qbs
                  const linkedContent = {
                    'biology': 'bio-bites',
                    'organic-chemistry': 'qbanks',
                    'general-chemistry': 'qbanks',
                  };
                  const classroomRouteParam = pathParams?.[2];
                  const classroomConfig = config.classrooms.find(({route}) => route === classroomRouteParam);
                  let courseIndex = classroomConfig.contentTypes.find(({route}) => route === 'videos').content[0].content.findIndex(({route}) => route === pathParams?.[4]);
                  const correspondingQbank = classroomConfig.contentTypes.find(({route}) => linkedContent[classroomRouteParam] === route).content[0].content[courseIndex];
                  return correspondingQbank?.id || originalTestId;
                }
                if (bootcamp === 'inbde') {
                  const classroomConfig = config.classrooms.find(({route}) => route === pathParams?.[2]);
                  const correspondingQbank = classroomConfig.contentTypes.find(({route}) => route === 'bites')?.content
                  .find(({name}) => name === 'Question Banks')?.content
                  .find(({route}) => route === pathParams?.[4]);
                  return correspondingQbank?.id || originalTestId;
                }
                const classroomConfig = config.classrooms.find(({route}) => route === pathParams?.[2]);
                const correspondingQbank = classroomConfig.contentTypes.find(({route}) => route === 'qbanks')?.content
                .find(({name}) => name === 'Question Banks')?.content
                .find(({route}) => route === pathParams?.[4]);
                return correspondingQbank?.id || originalTestId;
              }
            }
            return originalTestId
          } catch (e) {
            return originalTestId;
          }
        }

        const testIdOverride = getTestIdOverride();

        const {data: {getQuestionMastery: questionMastery}} = await getAllQuestionMastery({userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${question.id}`}) || {data: {getQuestionMastery: null}};
        const tagIds = question.tags.items?.filter(({tag}) => !['nclexPlan', 'nclexConcept'].includes(tag?.contentType)).reduce((acc, {tag}) => ({[getTagType(tag)]: tag.id, ...acc}), {test: test.id});
        const userIdHashTestId = !testIdOverride && !test.id && questionMastery && questionMastery?.userIdHashTestId?.replace(`${DEFAULT_USER_ID}#`, '') !== 'undefined'
          ? questionMastery.userIdHashTestId
          : `${DEFAULT_USER_ID}#${testIdOverride || test.id}`;

        const masteryInput = {
          wordPressUserDataQuestionMasteryId: DEFAULT_USER_ID,
          userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${question.id}`,
          userIdHashTestTagId: `${DEFAULT_USER_ID}#${tagIds.bootcamp}`,
          userIdHashSubjectTagId: `${DEFAULT_USER_ID}#${tagIds.subject}`,
          userIdHashTopicTagId: `${DEFAULT_USER_ID}#${tagIds.topic}`,
          userIdHashTestId: userIdHashTestId,
          wpUserId: DEFAULT_USER_ID,
          questionMasteryQuestionId: question.id,
          masteryLevel,
          bookmarked,
        }
        const createOrUpdateQuestionMasteryPromise = updateQuestionMastery(masteryInput, !questionMastery);

        return createOrUpdateQuestionMasteryPromise;
      });

      const resolvedPromises = await Promise.all(questionProgressesPromises);
      const filteredPromises = resolvedPromises.filter(promise => promise !== null);
      return filteredPromises;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  async function saveBlockProgress (value, blockIndexOverride=blockIndex) {
    updateQuizProgress({...value, newBlockProgress: false}, blockIndexOverride);
    const {blockProgressId, testBlockId, newBlockProgress} = quizProgress[blockIndexOverride];

    if (!testProgressId) {
      const localStorageBlockProgressKey = `${test.id}#${testBlockId}`;
      const blockProgress = {...quizProgress[blockIndexOverride], ...value, newBlockProgress: false};
      window.localStorage.setItem(localStorageBlockProgressKey, JSON.stringify(blockProgress));

      const localStorageArrayKey = `blockProgressKeyArray`;
      const blockProgressKeyArray = JSON.parse(window.localStorage.getItem(localStorageArrayKey) || '[]');
      // don't add duplicate keys to KeyArray
      if (blockProgressKeyArray.includes(localStorageBlockProgressKey)) return;

      const newBlockProgressKeyArray = [...blockProgressKeyArray, localStorageBlockProgressKey];
      window.localStorage.setItem(localStorageArrayKey, JSON.stringify(newBlockProgressKeyArray));
    } else {
      if (newBlockProgress) {
        await createBlockProgress(
          blockProgressId,
          testProgressId,
          testBlockId,
          {...value},
          blockIndexOverride
        );
      } else {
        await updateBlockProgress(
          blockProgressId,
          {
            testProgressBlockProgressesId: testProgressId,
            blockId: testBlockId,
            index: blockIndexOverride,
            ...value
          }
        );
      }
    }
  }

  const updateAverages = async () => {
    const {totalTime, totalScore, totalCount} = quizProgress.reduce((acc, {questions}) => {
      if (!questions?.length) return acc;
      return {
        ...acc,
        ...questions.reduce((acc, {didSelectCorrectAnswer}) => ({...acc, totalScore: didSelectCorrectAnswer ? acc.totalScore += 1 : acc.totalScore}), {totalScore: acc.totalScore}),
        totalCount: acc.totalCount + questions.length
      }
    }, {totalScore: 0, totalCount: 0});

    const testAverage = totalScore / totalCount;

    if (!testAverage) return;

    const uploadKey = `testresults/averages/${test.id}.json`
    let existingAverages = {totalAverage: 0, totalCount: 0};

    try {
      const existingAveragesFetch = await Storage.get(uploadKey, {
        download: true,
        cacheControl: 'no-cache',
      });
      existingAverages = JSON.parse(await existingAveragesFetch.Body.text());
    } catch (e) {
      console.log(e);
    } finally {
      existingAverages.totalAverage += testAverage;
      existingAverages.totalCount += 1;
      await Storage.put(uploadKey, JSON.stringify(existingAverages, null, 2), {contentType: 'application/json'});
    }
  };

  const saveProgress = async () => {
    try {
      const filteredProgress = quizProgress.map(({questions, ...rest}) => ({...rest, questions: questions?.map(({question, ...rest}) => ({...rest}))}));
    } catch (error) {
      console.log(error)
    }

    const filteredProgress = quizProgress
      .map(({questions, ...rest}) => ({
        ...rest,
        questions: questions?.map(({question, highlights, ...rest}) => ({...rest, highlights: isReadinessExam ? '' : highlights}))
      }));

    try {
      await saveTestProgress({testProgressId, userId: DEFAULT_USER_ID, testId: test.id, quizProgress: filteredProgress}, cognitoUser?.signInUserSession?.idToken?.jwtToken);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
    const submissionJSON = {
      quizProgress: filteredProgress,
      testProgressId,
      userId: DEFAULT_USER_ID,
      testId: test.id,
    }

    await Storage.put(`testresults/${DEFAULT_USER_ID}/${test.id}/${testProgressId}_${(new Date()).toISOString()}.json`, JSON.stringify(submissionJSON, null, 2), {contentType: 'application/json'});
    try {
      await updateAverages();
    } catch (e) {
      console.log(e);
    }
    return testProgressId;
  };

  const saveAndTrackProgress = async () => {
    try {
      const filteredProgress = quizProgress.map(({questions, ...rest}) => ({...rest, questions: questions?.map(({question, ...rest}) => ({...rest}))}));
    } catch (error) {
      console.log(error)
    }

    const filteredProgress = quizProgress
      .map(({questions, ...rest}) => ({
        ...rest,
        questions: questions?.map(({question, highlights, ...rest}) => ({...rest, highlights: isReadinessExam ? '' : highlights})) || []
      }));

    try {
      await saveTestProgress({testProgressId, userId: DEFAULT_USER_ID, testId: test.id, quizProgress: filteredProgress}, cognitoUser?.signInUserSession?.idToken?.jwtToken);

      // track question attempts here
      const questionProgresses = filteredProgress.map(({questions}) => questions).flat();

      await Promise.all(questionProgresses.map(async (questionProgress, index) => {
        const {
          blockArrayIndex,
          questionProgressId,
          questionBaseId,
          questionRevisionId,
          current,
          crossedAnswerIndexes,
          bookmarked,
          originalTestId,
          questionIndex: currentQuestionIndex,
          subject,
          topic,
          subjectTopic,
          question,
          isSequentialStart,
          isSequentialEnd,
          isSequentialSet,
          answerState,
          seen,
          fullCredit,
          partialCredit,
          noCredit,
          highlights, // not saving these for now b/c there's an issue with restoration from the new object format they're being saved in
          ...filteredQuestionProgress
        } = questionProgress || {};

        const wasAttempted = !!answerState?.answerState || (questionProgress.selectedAnswerIndex !== -1);
        const stringifiedAnswerState = JSON.stringify(answerState || '');
        const questionProgressToSave = {...filteredQuestionProgress, answerState: stringifiedAnswerState, index};

        // skip tracking if question was not attempted
        if (!wasAttempted) return;

        try {
          await trackFirstQuestionAttempt(DEFAULT_USER_ID, test.id, questionBaseId, questionRevisionId, questionProgressToSave);
        } catch (error) {
          console.log('error saving attempt', error);
        }
      }))

    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
    const submissionJSON = {
      quizProgress: filteredProgress,
      testProgressId,
      userId: DEFAULT_USER_ID,
      testId: test.id,
    }

    await Storage.put(`testresults/${DEFAULT_USER_ID}/${test.id}/${testProgressId}_${(new Date()).toISOString()}.json`, JSON.stringify(submissionJSON, null, 2), {contentType: 'application/json'});

    // removing test average updating for now since saveAndTrackProgress is only used in NCLEX readiness exams and the averaging logic operates
    // using didSubmitCorrectAnswer - it needs to be updated to work with answer data before average tracking works for readiness exams

    // try {
    //   await updateAverages();
    // } catch (e) {
    //   console.log(e);
    // }
    return testProgressId;
  };

  const updateQuestionProgressState = params => {
    const {questions: questionProgresses} = quizProgress[blockIndex];
    const questionIndex = questionProgresses.findIndex(({current}) => current);

    const updatedQuestionProgress = {...questionProgresses[questionIndex], ...params};
    updateQuizProgress({
      questions: insertAtIndex(
        questionProgresses,
        questionIndex,
        updatedQuestionProgress
      )
    });
    return updatedQuestionProgress;
  };

  const markQuestion = () => {
    const {questions: questionProgresses} = quizProgress[blockIndex];
    const questionIndex = questionProgresses.findIndex(({current}) => current);

    const didMark = !questionProgresses[questionIndex]?.didMark || !questionProgresses[questionIndex]?.bookmarked;
    // update question progress locally
    const {questionProgressId} = updateQuestionProgressState({didMark, bookmarked: didMark});

    // update question progress in backend
    if (template === 'customTest') {
      updateQuestionProgress(questionProgressId, {didMark});
    }

    // update bookmarked status
    const updatedBookmark = {...questionProgresses[questionIndex], bookmarked: didMark};
    saveQuestionProgresses([updatedBookmark]);
  }
  const isReview = urlParams.get('review');

  const instantiateProgress = async (test) => {
    const testBlocks = getTestBlockConnections(test).map(({testBlock}) => testBlock);

    let masteryMap;
    if (test?.id) {
      const {data: {QuestionMasteryByTestId: {items: questionMasteryByTestId}}} = await getAllQuestionMastery({userIdHashTestId: `${DEFAULT_USER_ID}#${test.id}`}, 'testSmall');
      masteryMap = questionMasteryByTestId.reduce((acc, record) => record.questionMasteryQuestionId ? {...acc, [record.questionMasteryQuestionId]: record} : acc, {})
    }
    const baseProgress = await Promise.all(testBlocks.map(testBlock => instantiateBlockProgressFromTemplate(testBlock, masteryMap)));

    setQuizProgress(baseProgress);
    setTestProgressId(generateId(DEFAULT_USER_ID));
  }

  const instantiateCourseProgress = async (test) => {
    if (!testProgress || restoringProgress || quizProgress.length > 0) return [];

    setRestoringProgress(true);

    setTestProgressId(testResultId);
    const testBlocks = getTestBlockConnections(test).map(({testBlock}) => testBlock);

    const baseProgress = await Promise.all(testBlocks.map(instantiateBlockProgressFromTemplate));
    // IF NECESSARY, RESTORE SAVED PROGRESS
    const restoredProgress = baseProgress.map(blockProgress => {
      // if blockProgress has been saved to backend, restore now
      if (testProgress) {
        const savedProgress = testProgress.blockProgresses.items && testProgress.blockProgresses.items.find(savedBlockProgress => savedBlockProgress.blockId === blockProgress.testBlockId);
        if (savedProgress) {
          const {id, blockId, index, status} = savedProgress;
          return {
            ...blockProgress, // TODO: QUESTION PROGRESS WILL NOT BE RESTORED
            blockProgressId: id,
            testBlockId: blockId,
            index,
            status,
            newBlockProgress: false
          }
        };
      } else {
        // if blockProgress has been saved to browser, restore now
        const savedProgress = window.localStorage.getItem(`${test.id}#${blockProgress.blockProgressId}`);
        if (savedProgress) return JSON.parse(savedProgress);
      }
      return blockProgress;
    });

    setQuizProgress(restoredProgress);
    setRestoringProgress(false);
  }
  const instantiateTestProgress = async (test) => {
    const testBlocks = getTestBlockConnections(test).map(({testBlock}) => testBlock);

    try {

      const progressIdResult = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${test.id}`);
      const progressId = progressIdResult?.data?.TestProgressByTestId?.items?.[0]?.id;
      if (isReadinessExam) {
        if (progressId) {
          window.alert(`Heads up: It looks like you've already submitted this exam. If you'd like to take it again, please reset your previous attempt on the next page. We're going to redirect you now. Thanks!`);
          window.location.href = window.location.pathname.split('/').slice(0, 3).join('/');
          return;
        }
        throw new Error('create progress')
      }

      if (!progressId) throw new Error('no progress');

      const progressResult = await getTestProgress(progressId);
      const progress = progressResult?.data?.getTestProgress;
      if (template === 'customTest') {
        try {
          const customTestQuestionProgresses = (progress.blockProgresses.items?.find(item => item?.index === 1)?.questionProgresses?.items || []).sort(firstBy('index'));
          const correspondingQuestions = testBlocks?.[1]?.questionConnections?.items;
          for (var i = 0; i < customTestQuestionProgresses.length; i++) {
            try {
              let questionProgress = customTestQuestionProgresses[i];
              questionProgress.questionBaseId = questionProgress.question.id
              const correspondingQuestion = correspondingQuestions[i];
              questionProgress.isSequentialSet = correspondingQuestion.isSequentialSet
              questionProgress.isSequentialStart = correspondingQuestion.isSequentialStart
              questionProgress.isSequentialEnd = correspondingQuestion.isSequentialEnd
              questionProgress.seen = questionProgress.time !== '0';
            } catch (e) {
              console.log(e, 'unable to restore sequential display');
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
      if (['tbcSavedBank', 'coursePlayer'].includes(template)) {
        syncProgress(progress);
      } else {
        restoreProgress(progress);
      }

      setTestProgressId(progressId);
    } catch (error) {
      console.log(error);
      const newProgressId = generateId(DEFAULT_USER_ID);
      const baseProgress = await Promise.all(testBlocks.map(testBlock => instantiateBlockProgressFromTemplate(testBlock, isReadinessExam ? [] : null)));
      const filteredBaseProgress = baseProgress.map(({questions, ...rest}) => ({
        ...rest,
        ...(questions ? {questions: questions?.map(({question, highlights, ...values}) => ({...values}))} : {})
      }));
      // create progress for custom test if it doesn't exist yet
      const result = await saveTestProgress({testProgressId: newProgressId, userId: DEFAULT_USER_ID, testId: test.id, quizProgress: filteredBaseProgress});
      if (!result) {
        const retry = window.prompt('An error has occurred. Retry? Otherwise, please contact Bootcamp.com support.');
        if (retry) {
          window.location.reload();
        }
      } else {
        setQuizProgress(baseProgress);
        setTestProgressId(newProgressId);
      }
    }
  }


  // fetch test progress if need be on mount
  useEffect(() => {
    const resultId = urlParams.get('testProgressId') || testResultId;

    resultId && fetchTestProgress(resultId.replace(' ', '+'));

  }, [DEFAULT_USER_ID, testResultId]);

  useEffect(() => {
    if (!test) return;

    switch (template) {
      case 'testReview':
        restoreProgress(testProgress);
        break;
      case 'coursePlayer':
        instantiateTestProgress(test);
        break;
      case 'customTest':
        instantiateTestProgress(test);
        break
      case 'tbcSavedBank':
        if (isReadinessExam && ['med-school', 'inbde', 'nclex'].includes(bootcamp)) return;
        if (bootcamp === 'nclex' && isReview) {
          syncProgress(testProgress);
        } else {
          instantiateTestProgress(test);
        }
        break;
      default:
        if (bootcamp === 'nclex' && (match?.params?.classroom === 'next-gen-cases')) {
          instantiateTestProgress(test);
        } else if (quizProgress.length === 0) {
          instantiateProgress(test);
        }
    }


  }, [test, template, testProgress, testResultId]);

  useEffect(() => {
    return () => {
      setTestProgress();
    }
  }, []);

  return {
    methods: {
      setQuizProgress,
      updateQuizProgress,
      saveProgress,
      saveAndTrackProgress,
      saveQuestionProgresses,
      saveBlockProgress,
      instantiateBlockProgressFromTemplate,
      instantiateQuestionProgressFromArray,
      getAnswerDataForQuestion,
      markQuestion,
      instantiateTestProgress
    },
    variables: {
      quizProgress,
      testProgressId,
      answerData,
    }
  }
}

export default useProgress;
