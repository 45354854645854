import React from 'react';

import * as Fields from './components/Field';

const FieldRenderer = ({config}) => {

  const renderedFields = config.map(({type, ...props}) => {
    const Field = Fields[type];

    return (
      <Field
        {...props}
      />
    );
  });

  return renderedFields;
};

FieldRenderer.defaultProps = {
  config: [
    {type: 'Text', name: '1', inputProps: {placeholder: 'testing'}},
  ]
};

export default FieldRenderer;
