import React, {useCallback, useState, useEffect} from 'react';

import {FullPageSpinner} from '../../components/Branding';
import {getInObj} from '@bootcamp/shared/src/util';
import {usePrevious} from '../../hooks';
import {loadMastery, getAllQuestionMastery} from '@bootcamp/shared/src/requests';
import {useUserDataContext} from '../../contexts/UserData';
import {useTestContext} from '../../contexts/TestBase';
import {TestNavigatorProvider, useTestNavigatorContext} from '../../contexts/TestNavigator';

const tagIdSubjectNameMap = {
  '65dcea9c-ac11-4e6c-aac0-89fa2f979afb': 'Biology',
  'fa72381b-e2c2-4e46-a113-135220a010b6': 'General Chemistry',
  '1a0ba62c-1c67-45ef-a811-245a6ddcedd4': 'Organic Chemistry',
  '5d95d40b-9be6-4b51-8313-aa2650ddeccb': 'Physics',
  '27846321-e1b8-4c9a-a2b9-4668c3e8fd22': 'Quantitative Reasoning',
  'fa65df0d-4cbe-4a25-8537-e8bc05c51f47': 'Reading Comprehension',
  '8437a097-a40d-4f2e-a56d-f8f819e6f780': 'Perceptual Ability'
}

const formatTestFromMastery = (masteryArray, masteryLevel, tagIds, nextToken, template, topicName) => {
  return {
    config: {
      title: topicName
        ? `${topicName} | Mastery Review`
        : 'Mastery Review',
      type: 'Mastery Review'
    },
    blockConnections: {
      items: [
        {testBlock: {type: template === 'bookmarkedQuestionReview' ? 'startBookmarkedQuestionReview' : 'startMasteryReview'}, index: 0},
        {
          testBlock: {
            id: `mastery-block-${masteryLevel}`,
            type: 'questionSet',
            questionConnections: {
              items: masteryArray.filter(({masteryLevel, question}) => template !== 'bookmarkedQuestionReview' ? !!masteryLevel && !['untagged', 'none'].includes(masteryLevel) : true),
            },
            nextToken,
            masteryLevel,
            tagIds,
          },
          index: 1,
        },
        {testBlock: {type: 'endBlock'}, index: 2}
      ]
    }
  };
};

const Loader = () => {
  const {
    variables: {
      updating,
    },
  } = useTestNavigatorContext();

  return updating && <FullPageSpinner active/>
}

const MasteryBank = ({masteryLevel, tagIds, children, type}) => {
  const [totalQuestions, setTotalQuestions] = useState(null);
  const [fetchedMastery, setFetchedMastery] = useState([]);
  const [loading, setLoading] = useState(false);

  const {DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const {test, setTest} = useTestContext();

  const {
    variables: {
      blockIndex,
      quizProgress,
      template
    },
    methods: {
      setQuizProgress,
      instantiateQuestionProgressFromArray,
      setCurrentIndex,
      setUpdating,
    }
  } = useTestNavigatorContext();

  const previousBlocks = usePrevious(getInObj(['blockConnections', 'items'], test, null));
  const previousActiveQuestionSet = usePrevious(getInObj(['blockConnections', 'items', blockIndex, 'testBlock', 'questionConnections', 'items'], test, null))
  const {questions} = quizProgress[blockIndex] || {questions: []};

  const load = useCallback(async (masteryLevel) => {
    setLoading(true);

    const bookmarksOnly = template === 'bookmarkedQuestionReview';
    const {mastery, nextToken} = await loadMastery(masteryLevel, tagIds, DEFAULT_USER_ID, null, bootcamp, bookmarksOnly, type);
    const topicName = type === 'topicReview'
      ? mastery?.[0]?.question?.tags?.items?.find(({tag}) => !!tag?.topic)?.tag?.topic
      : ''
    const loadedTest = formatTestFromMastery(mastery, masteryLevel, tagIds, nextToken, template, topicName);

    setTest(loadedTest);
    setFetchedMastery([...fetchedMastery, masteryLevel]);
    setLoading(false);

  }, [DEFAULT_USER_ID, test, setTest, fetchedMastery, setFetchedMastery, setLoading, loading, type]);

  const loadQuestionTotals = async () => {
    const filter = template === 'bookmarkedQuestionReview'
      ? {filter: {bookmarked: {eq: true}}}
      : masteryLevel === 'all' ? {} : {filter: {masteryLevel: {eq: masteryLevel}}};

    const lookupKey = type === 'topicReview'
      ? 'userIdHashTopicTagId'
      : 'userIdHashSubjectTagId';

    const queryType = type === 'topicReview'
      ? 'topic'
      : 'subject';

    const dataKey = type === 'topicReview'
      ? 'QuestionMasteryByTopicTagId'
      : 'QuestionMasteryBySubjectTagId'

    try {
      const results = await Promise.all(tagIds.map(id => getAllQuestionMastery({
        [lookupKey]: `${DEFAULT_USER_ID}#${id}`,
        ...filter
      }, queryType)));


      const total = results.reduce((acc, result) => acc + result.data[dataKey].items.filter(({masteryLevel}) => template !== 'bookmarkedQuestionReview' ? !!masteryLevel && !['untagged', 'none'].includes(masteryLevel) : true).length, 0);

      setTotalQuestions(total);
    } catch (error) {
      setTotalQuestions(0);
    }
  };

  const loadAllMastery = async (masteryLevel) => {
    const filter = template === 'bookmarkedQuestionReview'
      ? {filter: {bookmarked: {eq: true}}}
      : masteryLevel === 'all' ? {} : {filter: {masteryLevel: {eq: masteryLevel}}};

    const lookupKey = type === 'topicReview'
      ? 'userIdHashTopicTagId'
      : 'userIdHashSubjectTagId';

    const queryType = type === 'topicReview'
      ? 'topicWithQuestion'
      : 'subjectWithQuestion';

    const dataKey = type === 'topicReview'
      ? 'QuestionMasteryByTopicTagId'
      : 'QuestionMasteryBySubjectTagId'

    try {
      const results = await Promise.all(tagIds.map(id => getAllQuestionMastery({
        [lookupKey]: `${DEFAULT_USER_ID}#${id}`,
        ...filter
      }, queryType)));


      const mastery = results
        .reduce((acc, result) => [...acc, ...result.data[dataKey].items], [])
        .filter(({masteryLevel}) => template !== 'bookmarkedQuestionReview' ? !!masteryLevel && !['untagged', 'none'].includes(masteryLevel) : true)

      const topicName = type === 'topicReview'
        ? mastery?.[0]?.question?.tags?.items?.find(({ tag }) => !!tag?.topic)?.tag?.topic
        : ''

      const loadedTest = formatTestFromMastery(mastery, masteryLevel, tagIds, null, template, topicName);

      setTest(loadedTest);
      setFetchedMastery([...fetchedMastery, masteryLevel]);
      setTotalQuestions(mastery.length);

    setLoading(false);

    } catch (error) {
      setTotalQuestions(0);
    }
  };

  // this use effect is short circuiting the lazy loading in mastery banks for now
  useEffect(() => {

    if (!DEFAULT_USER_ID || loading || !!test) return;

    // load initail mastery
    loadAllMastery(masteryLevel);

  }, [DEFAULT_USER_ID, load, loading, test]);


  // useEffect(() => {
  //   if (!DEFAULT_USER_ID) return;

  //   loadQuestionTotals();
  // }, [DEFAULT_USER_ID]);

  // useEffect(() => {

  //   if (!test || !previousBlocks || blockIndex === 0) return;

  //   const currentBlock = test.blockConnections.items[blockIndex];
  //   const shouldUpdateQuestionSet = currentBlock.testBlock.questionConnections.items.length !== previousActiveQuestionSet.length;

  //   if (shouldUpdateQuestionSet) updateQuestionSet();
  //   else setUpdating(false);

  //   async function updateQuestionSet() {
  //     const currentQuestionIndex = questions.findIndex(({current}) => current);
  //     const previousEndingIndex = previousActiveQuestionSet.length;
  //     const newQuestions = test.blockConnections.items[blockIndex].testBlock.questionConnections.items.slice(previousEndingIndex);

  //     const shouldUpdateQuestionIndex = currentQuestionIndex === previousEndingIndex - 1;

  //     setQuizProgress(quizProgress => ([
  //       ...quizProgress.slice(0, blockIndex),
  //       {...quizProgress[blockIndex], questions: [...quizProgress[blockIndex].questions, ...instantiateQuestionProgressFromArray(previousEndingIndex, newQuestions)]},
  //       ...quizProgress.slice(blockIndex + 1)
  //     ]));

  //     shouldUpdateQuestionIndex && setCurrentIndex(blockIndex, previousEndingIndex);
  //   }

  // }, [test, previousBlocks, previousActiveQuestionSet]);

  return children(totalQuestions);
};

const Wrapper = ({children, masteryLevel, tagIds, type, ...props}) => (
  <TestNavigatorProvider {...props} template={masteryLevel === 'bookmarked' ? 'bookmarkedQuestionReview' : 'masteryReview'} type={type} skipStartBlock>
    <Loader/>
    <MasteryBank
      masteryLevel={masteryLevel}
      type={type}
      tagIds={tagIds}>
      {children}
    </MasteryBank>
  </TestNavigatorProvider>
);

MasteryBank.defaultProps = {};
MasteryBank.propTypes = {};

export default Wrapper;
