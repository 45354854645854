
export const breakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXl: '480px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const mediaQueries = {
  mobileS: `@media (max-width: ${breakpoints.mobileS})`,
  mobileM: `@media (max-width: ${breakpoints.mobileM})`,
  mobileL: `@media (max-width: ${breakpoints.mobileL})`,
  mobileXl: `@media (max-width: ${breakpoints.mobileXl})`,
  tablet: `@media (max-width: ${breakpoints.tablet})`,
  laptop: `@media (max-width: ${breakpoints.laptop})`,
  laptopL: `@media (max-width: ${breakpoints.laptopL})`,
  desktop: `@media (max-width: ${breakpoints.desktop})`,
  desktopL: `@media (max-width: ${breakpoints.desktop})`,
  custom: breakpoint => `@media (max-width: ${breakpoint}px)`,
};

const darkModePalette = {
  background: '#121212',
  surfacePrimary: '#1E1E1E',
  surfaceSecondary: '#262626',
  surfaceTertiary: '#2E2E2E',
  borderPrimary: '#333333',
};

const typographyPalette = {
  primary: '#2F3037',
  secondary: '#85868E',
  tertiary: '#C8C9CE',
  transparent: 'rgba(255, 255, 255, 0.70)',
};

const borderPalette = {
  primary: '#EDEDF0',
  secondary: '#C8C9CE',
};
const backgroundPalette = {
  secondary: '#FAFAFA',
};

export const font = {
  size: {
    smallest: '10px',
    smaller: '12px',
    small: '14px',
    medium: '16px',
    regular: '18px',
    large: '20px',
    larger: '22px',
    xlarge: '24px',
    xxlarge: '26px',
    super: '28px',
    xsuper: '36px',
    xxsuper:'40px',
    gojira: '48px'
  },
  weight: {
    normal: 400,
    regular: 500,
    semibold: 600,
    bold: 700,
    bolder: 800
  },
  height: {
    smallest: '15px',
    small2: '16px',
    smaller: '18px',
    small: '21px',
    medium: '24px',
    regular: '27px',
    large: '30px',
    larger: '33px',
    xlarge: '36px',
    xxlarge: '39px',
    super: '42px',
    xsuper: '54px',
    xxsuper: '60px',
    gojira: '64px'
  },
  multipliers: {
    none: 1,
    small: 1.2,
    large: 1.5,
  }
};

export const colors = {
  prometricPalette: {
    'inbde': {
      default: '#0f6292',
      dark: '#0C4E74',
    },
    'med-school': {
      default: '#3852A4',
    },
    'nclex': {
      default: '#006DAA',
      medium: '#007EC4',
      dark: '#004E7A',
    },
    mcat: {
      dark: '#006DAA',
      light: '#80AEE1',
      grey: '#A6A6A6',
      highlight: '#FFFF00'
    }
  },
  brandPalette: {
    blue: {
      default: '#3B82F6',
      dark: '#1d4ed8',
      hover: '#60A5FA',
      light: '#DBEAFE',
      gradientStart: '#60A5FA',
      gradientMid: '#3B82F6',
      gradientEnd: '#2563EB',
      gradient: 'linear-gradient(180deg, #60A5FA 0%, #3B82F6 50%, #1d4ed8 100%)'
    },
    indigo: {
      default: '#6366F1',
      dark: '#4338ca',
      hover: '#818CF8',
      light: '#E0E7FF',
      gradientStart: '#818CF8',
      gradientMid: '#6366F1',
      gradientEnd: '#4F46E5',
      gradient: 'linear-gradient(180deg, #818CF8 0%, #6366F1 50%, #4338ca 100%)'
    },
    violet: {
      default: '#8B5CF6',
      dark: '#6d28d9',
      hover: '#A78BFA',
      light: '#EDE9FE',
      gradientStart: '#A78BFA',
      gradientMid: '#8B5CF6',
      gradientEnd: '#7C3AED',
      gradient: 'linear-gradient(180deg, #A78BFA 0%, #8B5CF6 50%, #6d28d9 100%)'
    },
    teal: {
      default: '#14B8A6',
      dark: '#0f766e',
      hover: '#2DD4BF',
      light: '#CCFBF1',
      gradientStart: '#2DD4BF',
      gradientMid: '#14B8A6',
      gradientEnd: '#0D9488',
      gradient: 'linear-gradient(180deg, #2DD4BF 0%, #14B8A6 50%, #0f766e 100%)'
    },
    orange: {
      default: '#F97316',
      dark: '#c2410c',
      hover: '#FB923C',
      light: '#FFEDD5',
      gradientStart: '#FB923C',
      gradientMid: '#F97316',
      gradientEnd: '#D97706',
      gradient: 'linear-gradient(180deg, #FB923C 0%, #F97316 50%, #c2410c 100%)'
    },
    pink: {
      default: '#DC517C',
      dark: '#B13F62',
      hover: '#E5517E',
      light: '#F9E5EC',
      gradientStart: '#F05B89',
      gradientMid: '#DC4F7A',
      gradientEnd: '#B13F62',
      gradient: 'linear-gradient(180deg, #F05B89 0%, #DC4F7A 50%, #B13F62 100%)'
    },
    purple: {
      default: '#9E46B8',
      dark: '#833499',
      hover: '#A748C2',
      light: '#F1E6F4',
      gradientStart: '#BC63D6',
      gradientMid: '#9E46B8',
      gradientEnd: '#7A318E',
      gradient: 'linear-gradient(180deg, #BC63D6 0%, #9E46B8 50%, #7A318E 100%)'
    },
    royal: {
      default: '#6255E5',
      dark: '#5127C4',
      light: '#E0E6FF',
      hover: '#6765EF',
      gradientStart: '#776CFE',
      gradientMid: '#6B60E5',
      gradientEnd: '#5740DB',
      gradient: 'linear-gradient(180deg, #776CFE 0%, #6B60E5 50%, #5127C4 100%)',
    },
    rose: {
      default: '#F43F5E',
      dark: '#be123c',
      light: '#FFE4E6',
      hover: '#FB7185',
      gradientStart: '#FB7185',
      gradientMid: '#F43F5E',
      gradientEnd: '#E11D48',
      gradient: 'linear-gradient(180deg, #FB7185 0%, #F43F5E 50%, #be123c 100%)',
    },
    shamrock: {
      default: '#1A9E60',
      dark: '#147E44',
      light: '#E7FFF4',
      hover: '#1CA65B',
      gradientStart: '#1A9E60',
      gradientEnd: '#147E44',
      gradient: 'linear-gradient(180deg, #1A9E60 0%, #147E44 100%);',
    },
    cerulean: {
      default: '#00A8E0',
      dark: '#0086B4',
      light: '#EFFBFF',
      hover: '#00B0EC',
      gradientStart: '#00A8E0',
      gradientEnd: '#0086B4',
      gradient: 'linear-gradient(180deg, #00A8E0 0%, #0086B4 100%);',
    },
    magenta: {
      default: '#BA3996',
      dark: '#952E78',
      light: '#FFEEFA',
      hover: '#C33C9E',
      gradientStart: '#BA3996',
      gradientEnd: '#952E78',
      gradient: 'linear-gradient(180deg, #BA3996 0%, #952E78 100%);',
    },
    pumpkin: {
      default: '#FE7F2D',
      dark: '#CB6424',
      light: '#FFF4ED',
      hover: '#FF8630',
      gradientStart: '#FE7F2D',
      gradientEnd: '#CB6424',
      gradient: 'linear-gradient(180deg, #FE7F2D 0%, #CB6424 100%);',
    },
  },
  typographyPalette,
  borderPalette,
  backgroundPalette,
  darkModePalette: {
    ...darkModePalette,
    neutralsPalette: {
      extraDark: '#FAFAFA',
      grey: '#C8C9CE',
      extraLight: '#1E1E1E',
      white: '#121212',
      offWhite: '#1E1E1E',
      dark: '#FAFAFA',
    },
  },
  neutralsPalette: {
    extraDark: '#2F3037',
    dark: '#585962',
    grey: '#85868E',
    lightGrey: '#C8C9CE',
    light: '#EDEDF0',
    extraLight: '#F6F6F6',
    white: '#FFFFFF',
    offWhite: '#FAFAFA',
    lightBorder: '#ededf0',
    nclex: {
      light: '#E0E0E0',
      offWhite: '#EEEEEE',
      dark: '#707070',
      lightBlue: '#EAF4FE',
      yellow: '#FDFF00',
      lightYellow: '#f7f5c1',
    },
    mcat: {
      black: '#151A1E',
      grey: '#D2D2D2'
    },
  },
  interfacePalette: {
    green: {
      default: '#66AC5C',
      light: '#EAF5EA',
      dark: '#4C8B43',
      hover: '#6CB461',
      gradientStart: '#7BCC6F',
      gradientMid: '#66AC5C',
      gradientEnd: '#54964B',
      gradientColors: ['#7BCC6F', '#66AC5C', '#54964B'],
      gradientLocations: [0, .5, 1],
      gradient: 'linear-gradient(180deg, #7BCC6F 0%, #66AC5C 48.96%, #54964B 100%)',
      transparent: {
        gradientColors: [
          'rgba(123,204,111,0.15)',
          'rgba(102,172,92,0.15)',
          'rgba(76,139,67,0.15)'
        ],
        gradientLocations: [
          0, .5, 1
        ]
      },
    },
    red: {
      default: '#E05E57',
      light: '#FCECEE',
      dark: '#C33C35',
      hover: '#E9635C',
      gradientStart: '#F96A62',
      gradientMid: '#E05E57',
      gradientEnd: '#C43C35',
      gradientColors: ['#F96A62', '#E05E57', '#C43C35'],
      gradientLocations: [0, .5, 1],
      gradient: 'linear-gradient(180deg, #F96A62 5.47%, #E05E57 51.56%, #C43C35 100%)',
      transparent: {
        gradientColors: [
          'rgba(249, 106, 98, 0.15)',
          'rgba(224, 94, 87, 0.15)',
          'rgba(196, 60, 53, 0.15)'
        ],
        gradientLocations: [
          0.05, .5, 1
        ]
      },
    },
    yellow: {
      default: '#F3D456',
      light: '#FFFDE9',
      dark: '#D09528',
      hover: '#F8D859',
      gradientStart: '#F9D95B',
      gradientMid: '#F3D456',
      gradientEnd: '#D09528',
      gradientColors: ['#F9D95B', '#F3D456', '#D09528'],
      gradientLocations: [0, .5, 1],
      gradient: 'linear-gradient(180deg, #F9D95B 0%, #F3D456 49.48%, #D09528 100%)',
      transparent: {
        gradientColors: [
          'rgba(255, 222, 90, 0.15)',
          'rgba(243, 212, 86, 0.15)',
          'rgba(208, 149, 40, 0.15)'
        ],
        gradientLocations: [
          0, .5, 1
        ]
      },
    }
  },
  fade: {
    white: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.33%, #FFFFFF 100%)'
  },
  special: {
    secondaryGradient: {
      gradientStart: 'rgba(255, 255, 255, 0.12)',
      gradientMid: 'rgba(255, 255, 255, 0.08)',
      gradientEnd: 'rgba(255, 255, 255, 0.05)'
    },
    secondary: 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.08) 52.05%, rgba(255, 255, 255, 0.05) 100%)',
    pearlGradient: {
      gradientStart: '#FFFFFF',
      gradientMid: '#FFFFFF',
      gradientEnd: '#F5F5F5'
    },
    pearl: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 50.68%, #F5F5F5 100%)',
    naturalDarkGradient: {
      gradientStart: 'rgba(0, 0, 0, 0.08)',
      gradientEnd: 'rgba(0, 0, 0, 0.04)'
    },
    naturalDark: 'linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.04) 100%)',
    naturalLightGradient: {
      gradientStart: 'rgba(0, 0, 0, 0.04)',
      gradientEnd: 'rgba(0, 0, 0, 0.02)'
    },
    naturalLight: 'linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.02) 100%);',
    tuxedo: 'rgb(21, 26, 30);'
  }
};

export const elevation = {
  shadow: {
    tight: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    low: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    medium: '0px 8px 20px rgba(0, 0, 0, 0.1)',
    high: '0px 12px 32px rgba(0, 0, 0, 0.12)'
  },
  inner: {
    progressDark: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    progressLight: 'inset 0px 2px 6px rgba(0, 0, 0, 0.07)',
    medium: 'inset 0px 2px 4px rgba(0, 0, 0, 0.25)',
    high: 'inset 0px 2px 8px rgba(0, 0, 0, 0.1)'
  }
};

export const overlays = {
  opacity: {
    light: {
      _500: 'rgba(255, 255, 255, .9)',
      _400: 'rgba(255, 255, 255, .7)',
      _300: 'rgba(255, 255, 255, .5)',
      _200: 'rgba(255, 255, 255, .3)',
      _100: 'rgba(255, 255, 255, .1)',
      _50: 'rgba(255, 255, 255, .05)'
    },
    dark: {
      _500: 'rgba(0, 0, 0, .9)',
      _400: 'rgba(0, 0, 0, .7)',
      _300: 'rgba(0, 0, 0, .5)',
      _200: 'rgba(0, 0, 0, .3)',
      _100: 'rgba(0, 0, 0, .1)',
      _50: 'rgba(0, 0, 0, .05)'
    }
  }
};

export const layouts = {
  spacing: {
    none: '0px',
    xs: '4px',
    s: '8px',
    ms: '12px',
    m: '16px',
    ml: '18px',
    l: '24px',
    xl: '32px',
    xxl: '64px'
  }
};

function loadingBackground({loading, lightMode}) {

  if (!loading) return '';

  const gradientRange = lightMode
    ? [theme.colors.neutralsPalette.light, theme.colors.neutralsPalette.lightGrey]
    : [theme.overlays.opacity.dark._100, theme.overlays.opacity.dark._200]; // TODO this probably needs to be tweaked

  return `
    background: linear-gradient(-90deg, ${gradientRange[0]} 0%, ${gradientRange[1]} 50%, ${gradientRange[0]} 100%);
    animation: pulse 1.2s ease-in-out infinite;
    background-size: 400% 400%;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }
  `;
}

export const theme = {
  borderColor: '#D5D8D9',
  purpleBorderColor: '#5B3ABB',
  lightBorderColor: '#F1F1F1',
  mediumBorderColor: '#DFE4EA',
  brandPurple: '#6C5CE7',
  brandBlue: '#46B0E1',
  barBlue: '#0F6292',
  lightGrey: '#EDF0F2',
  darkTextColor: '#252E33',
  lightTextColor: '#6E7577',
  greyTextColor: '#8f9cb5',
  backgroundShade: '#F7F8FC',
  lightBackground: '#FDFDFD',
  lightBarBackground: '#F5F8FA',
  hoverColor: '#7558CF',
  greyButtonHover: '#e1e6e9',
  animation: {
    loadingBackground,
    spin: `
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    `
  },
  mediaQueries,
  colors,
  elevation,
  overlays,
  layouts,
  font,
};

export default theme;
