import React from 'react';
import styled from 'styled-components';

import useForm, {FormContext, useFormContext} from 'react-hook-form';

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
`;

const Form = props => {
  const {handleSubmit, onSubmit, ...formMethods} = useFormContext();
  return (
    <StyledForm onSubmit={handleSubmit(values => onSubmit(values, formMethods))} noValidate>
      {props.children}
    </StyledForm>
  )
};

const FormWrapper = ({children, defaultValues, ...props}) => (
  <FormContext {...useForm({mode: 'onChange', defaultValues})} {...props}>
    <Form>
      {children}
    </Form>
  </FormContext>
);

FormWrapper.defaultProps = {
  children: [],
  defaultValues: {}
}

export default FormWrapper
