import React from 'react';
import {AppRouter} from './router';
import styled, {ThemeProvider} from 'styled-components/macro';
import GlobalStyle from './styles/global';
import Intercom from 'react-intercom';

import {TestProvider} from './contexts/TestBase';
import {NotificationProvider} from './contexts/Notification';
import {UserDataProvider} from './contexts/UserData';
import {BootcampConfigProvider} from './contexts/BootcampConfig';

import {useDarkMode} from './hooks/useDarkMode';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import Storage from '@aws-amplify/storage';
import PubSub from '@aws-amplify/pubsub';

import awsconfig from './aws-exports';
import ErrorBoundary from './util/error/Boundary';

import theme from '@bootcamp/shared/src/styles/theme';

// import { DateUtils } from '@aws-amplify/core';
// console.log('test', test);

Amplify.configure(awsconfig);

// Auth.configure({
//   ...awsconfig,
//   cookieStorage: {
//       domain: window.location.hostname.match('localhost') ? window.location.hostname : '.bootcamp.com',
//       path: '/',
//       expires: 365,
//       secure: window.location.hostname.match('localhost') != true
//     }
// });

Auth.configure(awsconfig);
API.configure(awsconfig);
Storage.configure(awsconfig);
PubSub.configure(awsconfig);
//
// Auth.currentUserCredentials()
// .then(cred => {
//   // console.log('amplify currentUserCredentials ', cred);
//   // console.log('amplify currentUserCredentials ', cred['cognito']);
//   // console.log('amplify currentUserCredentials ', cred['cognito']['config']['systemClockOffset']);
//   DateUtils.setClockOffset(cred['cognito']['config']['systemClockOffset']);
// })


const AppContainer = styled.div`
  height: 100%;
  background: ${({theme}) => theme.colors.special.tuxedo};
`;

const DBCIntercomId = 'f4986fe139175a19cb635805f0f000eb1ea0da07';

const App = () => {
  const {darkModeEnabled, getTheme, toggleDarkMode} = useDarkMode();

  return (
    <ErrorBoundary>
      <Intercom appID={DBCIntercomId}/>
      <ThemeProvider theme={theme}>
        <UserDataProvider toggleDarkMode={toggleDarkMode} darkModeEnabled={darkModeEnabled} getDarkModeTheme={getTheme}>
          <BootcampConfigProvider>
            <NotificationProvider>
              <TestProvider>
                <AppContainer>
                  <GlobalStyle />
                  <AppRouter />
                </AppContainer>
              </TestProvider>
            </NotificationProvider>
          </BootcampConfigProvider>
        </UserDataProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};



export {App};
