import React, {useCallback, createContext, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useTestContext} from '../TestBase';
import {useUserDataContext} from '../UserData';
import useProgress from './useProgress';

import {ReloadBlockerProvider, useReloadBlockerContext} from '../ReloadBlocker';
import {getTestBlockConnections, getTestReviewBlockConnections, loadMoreMasteryIntoBlock} from './helpers';
import {insertAtIndex, getTestBlockQuestions, getQuestionParts} from '@bootcamp/shared/src/util';

import {loadMastery} from '@bootcamp/shared/src/requests';
import * as blockTransitions from './transitions';
import firstBy from 'thenby';
const TestNavigatorContext = createContext();


const useTestNavigator = (template, match, testId, progressId, interactionKey, skipStartBlock, type) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [blockIndex, setBlockIndex] = useState(0);
  const [shuffled, setShuffled] = useState(false);
  const [navigationFilter, setNavigationFilter] = useState({});
  const [timeMultiplier, setTimeMultiplier] = useState(1);

  const {params: {id: urlParamTestId}} = match;

  // use ids from props instead of match if possible
  const id = testId || urlParamTestId;

  // INTERACTIVE PAGE ELEMENTS
  const [leftButtons, setLeftButtons] = useState([]);
  const [centerButtons, setCenterButtons] = useState([]);
  const [rightButtons, setRightButtons] = useState([]);

  const {toggleReloadBlocker} = useReloadBlockerContext();
  const {push, goBack, action, replace} = useHistory();
  const {fetchTest, reset, test, setTest, customTestConfig} = useTestContext();

  const isReadinessExam = type === 'readinessExam';
  const isMcatExam = type === 'mcatExam';
  const tutorMode = (isReadinessExam) ? false : (!customTestConfig || test?.config?.tutorMode);

  const {
    methods: {
      setQuizProgress,
      updateQuizProgress,
      saveProgress,
      saveAndTrackProgress,
      saveQuestionProgresses,
      saveBlockProgress,
      instantiateBlockProgressFromTemplate,
      instantiateQuestionProgressFromArray,
      getAnswerDataForQuestion,
      markQuestion,
      instantiateTestProgress
    },
    variables: {
      quizProgress,
      testProgressId,
      answerData,
    }
  } = useProgress(test, progressId, template, blockIndex, match, isReadinessExam);

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array
  }

  const toggleQuestionProgressShuffle = () => {
    setQuizProgress(quizProgress => {
      const savedQuestions = [...(quizProgress?.[1]?.questions || [])];
      return quizProgress.reduce((acc, blockProgress, index) => {
        if (shuffled) {
          // if we're already shuffled, we want to unshuffle -- sort questions back into blockProgresses
          blockProgress.questions = savedQuestions.filter(({blockArrayIndex}) => blockArrayIndex === index).sort(firstBy('questionIndex'))
        } else {
          if (index === 1) {
            const allProgress = quizProgress.reduce((acc, blockProgress, index) => {
              const testBlock = testBlockConnections[index] && testBlockConnections[index].testBlock;
              const isSequentialBlock = testBlock?.components?.[0]?.contents?.includes('"isSequentialSet":true');
              const questions = getTestBlockQuestions(testBlock, template === 'customTest');
              return blockProgress.questions ? [...acc, ...blockProgress.questions.map((question, questionIndex) => ({...question, isSequentialBlock, blockArrayIndex: index, question: {...questions[questionIndex], overallIndex: acc.length + questionIndex, connections: {items: [{block: testBlock}]}}, }))] : acc
            }, [])
            const filteredProgress = allProgress.filter(({isSequentialBlock}) => isSequentialBlock);
            blockProgress.questions = [...shuffleArray(allProgress.filter(({isSequentialBlock}) => !isSequentialBlock)), ...filteredProgress];
          } else {
            blockProgress.questions = []
          }
        }
        return [...acc, blockProgress];
      }, [])
    });
    setShuffled(!shuffled);
  }

  const testBlockConnections = template === 'testReview' ? getTestReviewBlockConnections(test, quizProgress) : getTestBlockConnections(test);
  const startBlockIndex = 0;
  const endBlockIndex = testBlockConnections.length - 1;

  const {userQuestionMastery, loadedUserData, DEFAULT_USER_ID, bootcamp, searchUserInteractions, toggleDarkMode} = useUserDataContext();

  function clearCurrentIndex () {
    setQuizProgress(quizProgress =>
      quizProgress.map(blockProgress => ({
        ...blockProgress,
        ...(!!blockProgress.questions
          ? {
              questions: blockProgress.questions.map(questionProgress => ({
                ...questionProgress,
                ...template === 'questionBank' ? {didCheck: false, selectedAnswerIndex: -1} : {},
                current: false
              }))
            }
          : {})
      })));
  }

  function setCurrentIndex(blockIndex, questionIndex) {
    clearCurrentIndex();
    setQuizProgress(quizProgress => {
      setBlockIndex(blockIndex);

      const blockProgress = quizProgress[blockIndex];
      const blockHasQuestions = !!blockProgress.questions;

      const updatedBlockProgress = blockHasQuestions && Number.isInteger(questionIndex)
        ? {...blockProgress, questions: blockProgress.questions.map((question, index) => ({...question, current: index === questionIndex, seen: question.seen || index === questionIndex}))}
        : blockProgress;

      return insertAtIndex(quizProgress, blockIndex, updatedBlockProgress);
    });
  };

  function transitionBlock(action) {
    // this has been implemented as a singular action based block updater rather than having
    // in favor of update block index / semaphore
    const setters = {setBlockIndex, setCurrentIndex, clearCurrentIndex};
    blockTransitions[template](action, blockIndex, testBlockConnections, quizProgress, navigationFilter, setters, endBlockIndex);
  }

  function initPositionFromUrlParam(blockId, questionId) {
    const blockIndexMatch = quizProgress.findIndex(({blockId: testBlockId}) => testBlockId === blockId);
    const blockIndex = blockIndexMatch === -1 ? 1 : blockIndexMatch;
    const questionIndex = quizProgress?.[blockIndex]?.questions?.find(({questionBaseId, question}) => (questionBaseId || question?.id) === questionId)?.index

    setCurrentIndex(blockIndex, questionIndex);
  }

  const loadMoreForBlock = useCallback(async (block, blockIndex) => {
    const {nextToken, masteryLevel, tagIds} = block;

    if (!nextToken) return;
    setUpdating(true);

    // TODO move loadMastery into separate request function so that it can be reused between here and MasteryBank
    // TODO there is a situation here specifically for bookmarkedQuestionReview banks wherein the load mastery request returns no results but DOES return a nextToken
    // this is because we are enforcing a filter (bookmarked: true) and appsync filters the result after the limit is enforced.. FIX
    const bookmarksOnly = template === 'bookmarkedQuestionReview';
    const {mastery, nextToken: nextNextToken} = await loadMastery(masteryLevel, tagIds, DEFAULT_USER_ID, nextToken, bootcamp, bookmarksOnly, type);

    const updatedTest = loadMoreMasteryIntoBlock(test, blockIndex, mastery, nextNextToken); // TODO test this with different block sizes

    const updatedBlock = {
      ...block,
      nextToken: nextNextToken,
      questionConnections: {
        items: [
          ...block.questionConnections.items,
          ...mastery
        ]
      }
    };
    const filteredQuestions = updatedBlock.questionConnections.items.filter((question) => {
      if (!navigationFilter.key) return true;
      if (navigationFilter.key === 'search') {
        const {prompt, answers, explanation} = getQuestionParts(question);
        const questionString = `${prompt} ${answers.map(({text}) => text).join(' ')} ${explanation}`;
        return questionString.toLowerCase().includes(navigationFilter.value.toLowerCase());
      }
      return (navigationFilter.key === 'masteryLevel' && navigationFilter.value === question.masteryLevel) ||
              // question answer outcome doesn't match answered filter
              (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === question.didSelectCorrectAnswer) ||
              (navigationFilter.key === 'bookmarked' && question.bookmarked)

    });
    if (filteredQuestions.length <= 20 && nextNextToken) {
      return await loadMoreForBlock(updatedBlock, blockIndex);
    }

    setTest(updatedTest);

  }, [DEFAULT_USER_ID, test, setTest, navigationFilter]);

  // reset test in TestBase when component unmounts
  useEffect(() => reset, []);

  useEffect(() => {
    window.scroll(0, 0);

    if (!test && id) {
      fetchTest(id, template === 'testReview' ? "getTestNoQuestions" : "getTest")
    }
  }, [id, template, progressId]);

  useEffect(() => {
    if (!!test && !quizProgress.length && isReadinessExam && template === 'tbcSavedBank' && ['inbde', 'med-school', 'nclex'].includes(bootcamp)) {
      return setLoading(false);
    }
    if (!!test && !!quizProgress.length && loading) {
      const blockId = new URLSearchParams(window.location.search).get('blockId');
      const questionId = new URLSearchParams(window.location.search).get('questionId');

      if (blockId || questionId) {
        initPositionFromUrlParam(blockId, questionId);
      } else if (test.config?.suspended && test.config?.questionIndex) {
        setCurrentIndex(1, test.config?.questionIndex);
      } else if (skipStartBlock) {
        transitionBlock(['tbcSavedBank', 'tbcQuestionBank'].includes(template) && !isReadinessExam ? 'nextUnanswered' : 'next');
      }

      const darkModeEnabled = searchUserInteractions('darkModeEnabled') === true;

      toggleDarkMode(darkModeEnabled);
      setLoading(false);
    }
  }, [quizProgress, test, skipStartBlock]);

  async function autoTagOnSubmit () {
    try {
      const updates = quizProgress.reduce((acc, {questions}) => !questions ? acc : [...acc, ...questions.map(currentQuestionProgress => {
        const credit = {
          full: (!!currentQuestionProgress?.answerState?.score && !!currentQuestionProgress?.answerState?.maxScore && currentQuestionProgress?.answerState?.score === currentQuestionProgress?.answerState?.maxScore) || currentQuestionProgress?.didSelectCorrectAnswer,
          partial: currentQuestionProgress?.answerState?.score > 0 && (currentQuestionProgress?.answerState?.score !== currentQuestionProgress?.answerState?.maxScore),
          none: (!currentQuestionProgress?.answerState?.score || currentQuestionProgress?.answerState?.score === 0),
        };
        const autoTag = credit.full
          ? 'mastered'
          : credit.partial
            ? 'reviewing'
            : 'learning';
        return {...currentQuestionProgress, masteryLevel: autoTag, question: {...currentQuestionProgress.question, masteryLevel: autoTag}, }
      })], [])

      // only save progresses for 'seen' untagged questions
      const filteredUpdates = updates
        .filter(({seen, didCheck}) => seen && !didCheck);

      await saveQuestionProgresses(filteredUpdates);
    } catch (error) {
      console.error(error);
    }
  }

  function handleGoBack() {
    try {

      const handler = () => {
        const canGoBack = action !== 'POP';
        const urlParts = window.location.pathname.split('/');
        const previousPath = urlParts
        .slice(0,2)
        .join('/');

        if (urlParts[2] === 'custom-test') {
          push(`${previousPath}/previous-tests/review?key=${encodeURIComponent(test.config.testStorageKey)}`);
        } else if (canGoBack) {
          goBack();
        } else {
          push(previousPath);
        }
      }

      toggleReloadBlocker(false);
      setTimeout(handler, 100);
    } catch (error) {
      // console.log('error going back');
    }
  };

  const currentQuestionIndex = quizProgress?.[blockIndex]?.questions?.findIndex(({current}) => current);
  const currentQuestion = getTestBlockQuestions(testBlockConnections[blockIndex]?.testBlock)[currentQuestionIndex];
  const {answers} = getQuestionParts(currentQuestion);
  const correctAnswerIndex = answers?.reduce((acc, [answerText, answerCorrect], index) => answerCorrect ? `${acc}${index}` : acc, '') || -1;

  return {
    methods: {
      setBlockIndex,
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
      setNavigationFilter,
      setQuizProgress,
      saveProgress,
      saveAndTrackProgress,
      updateQuizProgress,
      saveQuestionProgresses,
      clearCurrentIndex,
      setCurrentIndex,
      transitionBlock,
      setUpdating,
      saveBlockProgress,
      instantiateBlockProgressFromTemplate,
      instantiateQuestionProgressFromArray,
      loadMoreForBlock,
      getAnswerDataForQuestion,
      toggleQuestionProgressShuffle,
      getSectionWindow: blockTransitions.getSectionWindow,
      autoTagOnSubmit,
      markQuestion,
      handleGoBack,
      setTimeMultiplier,
      instantiateTestProgress,
      setLoading
    },
    variables: {
      answerData,
      test,
      testBlockConnections,
      startBlockIndex,
      endBlockIndex,
      loading,
      updating,
      blockIndex,
      quizProgress,
      leftButtons,
      centerButtons,
      rightButtons,
      navigationFilter,
      template,
      testProgressId,
      shuffled,
      interactionKey,
      skipStartBlock,
      customTestConfig,
      tutorMode,
      type,
      timeMultiplier,
      correctAnswerIndex
    }
  }
}

const TestNavigatorProviderBase = ({match, children, template, testId, progressId, onExitTest, interactionKey, enableReloadBlocker=true, skipStartBlock, type}) => {
  const testNavigatorProps = useTestNavigator(template, match, testId, progressId, interactionKey, skipStartBlock, type);
  const {variables: {startBlockIndex, blockIndex}} = testNavigatorProps;
  const {toggleReloadBlocker} = useReloadBlockerContext();

  useEffect(() => {
    if (startBlockIndex === blockIndex) return;

    // enable reload blocker after moving past start block
    enableReloadBlocker && toggleReloadBlocker(true);
  }, [startBlockIndex, blockIndex]);

  return (
    <TestNavigatorContext.Provider value={{...testNavigatorProps, onExitTest}}>
      {children}
    </TestNavigatorContext.Provider>
  );
}


const TestNavigatorProvider = props => (
  <ReloadBlockerProvider>
    <TestNavigatorProviderBase {...props}/>
  </ReloadBlockerProvider>
);


const useTestNavigatorContext = () => useContext(TestNavigatorContext);

export {useTestNavigatorContext, TestNavigatorProvider};
