import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';

import {Column, Row} from '@bootcamp/web/src/components/Grid';
import {GutterColumn} from '@bootcamp/web/src/components/Spacing';
import SearchComponent from '@bootcamp/web/src/components/Search/App';
import ButtonBar from '@bootcamp/web/src/components/ButtonBar';
import QuestionView from '@bootcamp/web/src/components/QuestionView';
import Exhibit from '@bootcamp/web/src/components/Exhibit';
import useDocumentTitle from '@rehooks/document-title';

import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {updateQuestionMastery} from '@bootcamp/shared/src/requests';
import {getQuestionParts, getTagType} from '@bootcamp/shared/src/util';

const SearchResultElement = styled.div`
  position: relative;
  font: 400 16px 'proxima-nova', sans-serif;
  color: #FFF;
  cursor: pointer;
  padding: .75em;
  background: white;
  color: #181818;
  border-radius: 5px;
  transition: background .2s;
  border-left: 10px solid rgba(255,255,255,.25);
  margin-bottom: 1.5em;
  ${({masteryLevel}) => {
    switch (masteryLevel) {
      case 'learning':
        return css`
          border-color: #FB7E7B;
        `
      case 'reviewing':
        return css`
          border-color: #E6B55B;
        `
      case 'mastered':
          return css`
            border-color: #7BD46F;
          `
      default:
        return;
    }
    }}
  &:hover {
    background: rgba(255,255,255,.9);
    transition: background .2s;
  }
`;

const QuestionSnippet = styled.div`
  overflow: hidden;
`;

const QuestionTag = styled.div`

`;

function renderQuestionList (setSelectedQuestion, cachedMastery, questions) {
  return questions.map(question => {
    const {prompt, tags} = getQuestionParts(question);

    return (
      <SearchResultElement key={question.id} masteryLevel={cachedMastery[question.id]?.masteryLevel || 'none'} onClick={() => setSelectedQuestion(question)}>
        <QuestionSnippet dangerouslySetInnerHTML={{__html: prompt}} />
        <QuestionTag>
          {tags.subject}
        </QuestionTag>
      </SearchResultElement>
    )
  });
}

const QuestionSearchPage = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [cachedMastery, setCachedMastery] = useState({});

  const {DEFAULT_USER_ID, bootcamp} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  const updateMasteryCache = async (questionId, masteryLevel, tags, initialUpdate=false) => {
    const tagIds = tags.items.reduce((acc, {tag}) => ({[getTagType(tag)]: tag.id, ...acc}), {});

    const previouslyCached = cachedMastery[questionId];

    if (previouslyCached) {
      const masteredQuestion = cachedMastery[questionId];
      if (masteredQuestion.masteryLevel === masteryLevel) return;

      masteredQuestion.masteryLevel = masteryLevel;
      cachedMastery[questionId] = masteredQuestion;

      setCachedMastery({...cachedMastery});

      return await updateQuestionMastery({
        userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${questionId}`,
        userIdHashSubjectTagId: `${DEFAULT_USER_ID}#${tagIds.subject}`,
        userIdHashTopicTagId: `${DEFAULT_USER_ID}#${tagIds.topic}`,
        masteryLevel,
        bookmarked: masteredQuestion.bookmarked,
      });

    } else {
      const newMastery = {
        question: {id: questionId},
        masteryLevel,
        bookmarked: false
      };
      cachedMastery[questionId] = newMastery;
      setCachedMastery({...cachedMastery});
      if (masteryLevel !== 'none' && masteryLevel && !initialUpdate) {
        return await updateQuestionMastery({
          wordPressUserDataQuestionMasteryId: DEFAULT_USER_ID,
          userIdHashQuestionBaseId: `${DEFAULT_USER_ID}#${questionId}`,
          userIdHashSubjectTagId: `${DEFAULT_USER_ID}#${tagIds.subject}`,
          userIdHashTopicTagId: `${DEFAULT_USER_ID}#${tagIds.topic}`,
          questionMasteryQuestionId: questionId,
          wpUserId: DEFAULT_USER_ID,
          masteryLevel,
          bookmarked: false,
        }, true)
      }
    }
  }
  useDocumentTitle(` ${config.meta.siteTitle} | Search`);

  return (
    <QuestionSearchPageContainer>
      <Search updateMasteryCache={updateMasteryCache} cachedMastery={cachedMastery} renderQuestionList={questions => renderQuestionList(setSelectedQuestion, cachedMastery, questions)} bootcamp={bootcamp} />
      <Result key={selectedQuestion && selectedQuestion.id} question={selectedQuestion} updateMasteryCache={updateMasteryCache} />
    </QuestionSearchPageContainer>
  )
}

const QuestionSearchPageContainer = styled(Row)`

`;

const Search = ({renderQuestionList, cachedMastery, updateMasteryCache, bootcamp}) => {
  const {IS_OAT_STUDENT, DEFAULT_USER_ID} = useUserDataContext();

  return (
    <SearchContainer>
      <SearchComponent
        title={'Questions'}
        titleButtons={[]}
        renderResults={renderQuestionList}
        cachedMastery={cachedMastery}
        updateMasteryCache={updateMasteryCache}
        DEFAULT_USER_ID={DEFAULT_USER_ID}
        bootcamp={bootcamp}
        additionalFilter={question => {
          const isPublished = question.status !== 'draft';
          const isTaggedPARC = question.tags.items.find(({tag}) => tag.subject && ['Perceptual Ability', 'Reading Comprehension'].includes(tag.subject));
          const showingPhysics = IS_OAT_STUDENT ? true : !question.tags.items.find(({tag}) => tag.subject && ['Physics'].includes(tag.subject));
          return isPublished && !isTaggedPARC && showingPhysics;
        }}
      />
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  background: ${({theme}) => theme.colors.brandPalette.royal.default};
  box-shadow: 3px 0 6px 0 rgba(0,0,0,.12);
  height: 100%;
  overflow: scroll;
  flex: 1;
`;

const Result = ({question, updateMasteryCache}) => {
  const [checked, setChecked] = useState(false);

  const [showingExhibit, setShowingExhibit] = useState(false);
  const [exhibitType, setExhibitType] = useState();

  const questionParts = getQuestionParts(question);

  useEffect(() => {
    const {subject} = questionParts.tags.length > 0 ? questionParts.tags.find(tag => tag && !!tag.subject) : {}
    const periodicTableSubjects = ['Organic Chemistry', 'General Chemistry'];
    const calculatorSubjects = ['Quantitative Reasoning'];
    setExhibitType(periodicTableSubjects.includes(subject) ? 'periodic' : calculatorSubjects.includes(subject) ? 'calculator' : null);
  }, [question]);

  const leftButtons = [
    {
      children: 'Check',
      color: 'grey',
      onClick: () => setChecked(true)
    },
    exhibitType && {
      children: 'Exhibit',
      color: 'grey',
      onClick: () => setShowingExhibit(showingExhibit => !showingExhibit)
    },
  ];

  const rightButtons = !checked ? [] : [
    {
      children: 'Learning',
      color: 'salmon',
      onClick: () => updateMasteryCache(question.id, 'learning', question.tags)
    },
    {
      children: 'Reviewing',
      color: 'yellow',
      onClick: () => updateMasteryCache(question.id, 'reviewing', question.tags)
    },
    {
      children: 'Mastered',
      color: 'green',
      onClick: () => updateMasteryCache(question.id, 'mastered', question.tags)
    }
  ];

  if (!question) return <ResultContainer />;

  return (
    <ResultContainer>
      <QuestionViewArea>
        <QuestionView
          key={`${question.id}`}
          didSubmitAnswer={checked}
          questionId={question.id}
          enableHighlighting={true}
          showFeedback={true}
          {...questionParts}
        />
      </QuestionViewArea>
      <ButtonBar
        buttonGroups={[
          leftButtons,
          rightButtons
        ]}
      />
      {(showingExhibit && exhibitType) && <Exhibit type={exhibitType} onCloseExhibit={() => setShowingExhibit(false)} />}
    </ResultContainer>
  )
};

const ResultContainer = styled(Column)`
  justify-content: center;
  position: relative;
  flex: 2;
  background: white;
`;
const QuestionViewArea = styled(GutterColumn)`
  overflow-y: scroll;
  background: white;
`;


export default QuestionSearchPage;
