import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import Highlightable from '@bootcamp/web/src/components/Highlightable';
import {TagLinks} from '@bootcamp/web/src/components/Tags';
import {Column} from '@bootcamp/web/src/components/Grid';
import AdminButtons from '@bootcamp/web/src/components/QuestionView/components/AdminButtons';
import {determineColorFromTitle} from '@bootcamp/shared/src/util';
import {PrometricWrapper, NextText, Section, Heading} from './shared';

const ChoiceContainer = styled.label`
  min-height: 30px;
  height: auto;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({isCorrect, isIncorrect}) => isCorrect ? 'rgba(123, 213, 110, 0.5)' : isIncorrect ? 'rgba(251, 126, 122, 0.5)' : null};
  font-weight: ${({isCorrect}) => isCorrect && 'bold'};
  margin-bottom: .5em;
  user-select: none;
  text-decoration: ${({isCrossed}) => isCrossed ? 'line-through' : 'none'};
  // opacity: ${({isCrossed}) => isCrossed ? 0.6 : 1};
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
`
const Option = styled.input.attrs({ type: 'radio', name: 'answer' })`
  cursor: pointer;
`
const LabelText = styled.span`
  padding-left: 5px;
  * {
    max-width: 100%;
    font-size: 16px !important;
    display: inline;
  }
  p {
    margin: 0;
  }
  img {
    height: auto;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-dib, .fr-dii {
    margin: 0 !important;
    display: inline !important;
    vertical-align: middle !important;
  }
  max-width: 100%;
  font-size: 16px !important;
  display: inline;
`

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const AnswerChoice = ({answer, isCrossed, isSelected, setSelectedAnswerIndex, updateCrossedAnswerIndexes, showingExplanation, answerIndex}) => {
  const [answerText, answerCorrect] = answer;

  const isCorrect = answerCorrect && showingExplanation;
  const isIncorrect = !answerCorrect && isSelected && showingExplanation;

  function handleContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    updateCrossedAnswerIndexes();
  }

  const answerLetter = alphabet[answerIndex];

  const letteredAnswerText = `${answerLetter}. ${answerText}`;

  return (
    <ChoiceContainer
      isCorrect={isCorrect}
      isIncorrect={isIncorrect}
      isCrossed={isCrossed}
      onContextMenu={handleContextMenu}>
      <Option
        disabled={showingExplanation}
        checked={isSelected}
        onChange={event => {
          setSelectedAnswerIndex();
          isCrossed && updateCrossedAnswerIndexes();
          event?.target?.blur();
        }}
      />
      {useMemo(() => <LabelText className={'fr-view'} dangerouslySetInnerHTML={{__html: letteredAnswerText}} />, [letteredAnswerText])}
    </ChoiceContainer>
  )
}

const AnswerBoxContainer = styled.form`
  border-radius: 5px;
  padding: 0 1em;
  clear: both;
  width: 100%;
`;

const AnswerBox = ({
  answers,
  selectedAnswerIndex,
  setSelectedAnswerIndex,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
}) => {

  return (
    <AnswerBoxContainer>
      {answers.map((answer, index) => {
        return (
          <AnswerChoice
            key={`answerindex-${index}`}
            setSelectedAnswerIndex={() => setSelectedAnswerIndex(index)}
            updateCrossedAnswerIndexes={() => updateCrossedAnswerIndexes(index)}
            isSelected={selectedAnswerIndex === index}
            isCrossed={crossedAnswerIndexes.indexOf(index) > -1}
            answer={answer}
            showingExplanation={showingExplanation}
            answerIndex={index}
          />
        )
      })}
    </AnswerBoxContainer>
  )
}


  const Container = styled(PrometricWrapper)`
    transform: translate3d(0,0,0);
    img {
      display: block;
      margin: 12px 0px;
    }
  `;
  const Prompt = styled.div`
    * {
      max-width: 100%;
      font-size: 16px !important;
      line-height: 21px;
      font-family: Arial;
      margin: 0;
    }
    p {
      margin: 0;
    }
    ol, ul {
      margin: 1em 0;
    }
    .sup, .sub, sup, sub {
      font-size: 12px !important;
    }
    .sup {
      vertical-align: sup;
    }
    .sub {
      vertical-align: sub;
    }
    img {
      height: auto;
      margin-left: 0 !important;
    }
    margin: 1em 0px;
    max-width: 100%;
    width: 100%;
    font-size: 16px !important;
    line-height: 21px;
    font-family: Arial;
  `

const QuestionView = ({
  prompt,
  answers,
  explanation,
  caseset,
  xray,
  clinicalPhoto,
  citations,
  updatedAt,
  tags,
  sectionTitle,
  didSubmitAnswer,
  testBlockName,
  alwaysShowingExplanation,
  onSelectAnswerIndex,
  didSubmitAnswerIndex,
  showFeedback,
  questionId,
  enableHighlighting,
  disableVideos,
  setHighlights,
  highlights,
  questionIndex,
  didMark,
  time,
  savedCrossedAnswerIndexes,
  onCrossedAnswerIndexesUpdate,
  viewKey,
  tutorialMode,
  blockPassage,
  isDraft
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(didSubmitAnswerIndex);
  const [crossedAnswerIndexes, setCrossedAnswerIndexes] = useState(savedCrossedAnswerIndexes);

  const filteredAnswers = answers.filter(Array.isArray);

  const correctAnswerIndex = filteredAnswers
    .map(([answerText, answerCorrect]) => answerCorrect)
    .indexOf(true);
  // const correctAnswerLetter = alphabet[correctAnswerIndex];
  const didSelectCorrectAnswer = selectedAnswerIndex === correctAnswerIndex;
  const showingExplanation = didSubmitAnswer || alwaysShowingExplanation;

  // selectedAnswerIndex hook
  useEffect(() => {
    if (!filteredAnswers?.length) return;
    if (selectedAnswerIndex !== -1) {
      onSelectAnswerIndex(selectedAnswerIndex, didSelectCorrectAnswer);
    }

    alwaysShowingExplanation && setSelectedAnswerIndex(didSubmitAnswerIndex || correctAnswerIndex);
  }, [selectedAnswerIndex]);

  function updateCrossedAnswerIndexes(index) {
    if (showingExplanation) return;

    const indexes = crossedAnswerIndexes.indexOf(index) > -1
      ? crossedAnswerIndexes.filter(i => i !== index)
      : [...crossedAnswerIndexes, index];
    onCrossedAnswerIndexesUpdate(indexes);
    setCrossedAnswerIndexes(indexes);
  }
  const {subject} = tags.find(tag => !!tag.topic && tag.contentType !== 'auxiliaryAnatomy') || {};
  const ContainerType = tutorialMode ? Column : Container
  return (
    <ContainerType>
      <Section>
        {sectionTitle && (
          <Heading>
            {sectionTitle} {sectionTitle === 'Survey of Natural Sciences' ? `- ${subject}` : ''}
          </Heading>
        )}
        <AdminButtons questionId={questionId} correctAnswerIndex={correctAnswerIndex} />
        {testBlockName &&
          <TagLinks tags={[{
            title: testBlockName,
            color: determineColorFromTitle(testBlockName),
            as: 'span',
          }]}
          />
        }
        <Highlightable
          type={'stem'}
          htmlString={prompt}
          setHighlights={setHighlights}
          highlights={highlights}
          disable={!enableHighlighting}>
          <Prompt
            className={'fr-view'}
            dangerouslySetInnerHTML={{__html: questionIndex ? `${questionIndex}. ${prompt}` : prompt}}
          />
        </Highlightable>
        <AnswerBox
          answers={filteredAnswers}
          selectedAnswerIndex={selectedAnswerIndex}
          setSelectedAnswerIndex={setSelectedAnswerIndex}
          showingExplanation={showingExplanation}
          crossedAnswerIndexes={crossedAnswerIndexes}
          updateCrossedAnswerIndexes={updateCrossedAnswerIndexes}
        />
      </Section>
      {blockPassage}
      {tutorialMode ? null : <NextText />}
    </ContainerType>
  )
}

QuestionView.defaultProps = {
  prompt: '',
  answers: '[]',
  explanation: '',
  updatedAt: '',
  onSelectAnswerIndex: () => true,
  alwaysShowingExplanation: false,
  didSubmitAnswerIndex: -1,
  showFeedback: false,
  questionId: '',
  tags: [],
  enableHighlighting: false,
  setHighlights: () => {},
  highlights: null, // this should be a serialized highlight string,
  didMark: false,
  time: "",
  savedCrossedAnswerIndexes: [],
  onCrossedAnswerIndexesUpdate: () => true
}

export default QuestionView;
