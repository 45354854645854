
export function formatRoute(route, parentRoute, grandparentRoute, greatGrandparentRoute) {

  if (route === 'keyholes' || route === 'tfe' || route === 'pattern-folding') {
    return `${greatGrandparentRoute}/${grandparentRoute}`;
  }

  if (route?.startsWith('?')) {
    return route.substring(1);
  }
  if (grandparentRoute === 'qbank-collection') {
    // we want the url to look like /
    const url = [greatGrandparentRoute, grandparentRoute, parentRoute]
      .filter(string => !!string)
      .join('/');

    return `${url}`;
  }

  if (grandparentRoute === 'identify-structures' || grandparentRoute === 'lecture-style-questions') {
    // we want the url to look like /
    const search = `?subject=${route}`;

    const url = [greatGrandparentRoute, grandparentRoute, parentRoute]
      .filter(string => !!string)
      .join('/');

    return `${url}${search}`;
  }

  return [greatGrandparentRoute, grandparentRoute, parentRoute, route]
    .filter(string => !!string)
    .join('/');
}

export function indexContent(bootcamp, content, contentTypeName, parentName, parentRoute, grandparentRoute, greatGrandparentRoute, activeMembershipGroups) {
  return content.reduce((acc, {id, testId, name, route, content: contentTypeContent, keywords, video, allow, index, type}) => {

    if (allow && !allow.some(group => activeMembershipGroups.includes(group))) {
      return acc;
    }
    // list of classrooms to ignore
    const ignoredSubjects = ['Mental Dental Review Videos'];
    if (['Downloads', 'Flashcards'].includes(contentTypeName) || ignoredSubjects.includes(parentName)) return acc;

    return ({
    ...acc,
    [`${name} ${parentName} ${keywords || ''} ${contentTypeName}`]: {
      id,
      testId,
      index,
      name,
      video,
      type,
      contentTypeName,
      route: `/${bootcamp}/${formatRoute(route, parentRoute, grandparentRoute, greatGrandparentRoute)}`,
      subject: parentName,
    },
    ...(contentTypeContent ? indexContent(bootcamp, contentTypeContent, name, parentName, route, parentRoute, grandparentRoute, greatGrandparentRoute, activeMembershipGroups) : {})
  })}, {})
}

export function indexContentTypes(bootcamp, contentTypes, parentName, parentRoute, activeMembershipGroups) {
  return contentTypes
    .reduce((acc, {name, route, content}) => ({
      ...acc,
      ...indexContent(bootcamp, content, name, parentName, route, parentRoute, '', activeMembershipGroups)
    }), {})
}

// TODO move this into export function passed into config search
export function indexConfig(bootcamp, config, activeMembershipGroups) {
  const colorMap = config.reduce((acc, classroom) => ({...acc, [classroom.name]: classroom.themePalette}), {});

  return config
    .filter(classroom => !classroom.comingSoon)
    .reduce((acc, {id, testId, name, route, contentTypes, index}) => ({
      ...acc,
      [name]: {
        id,
        testId,
        name,
        route: `/${bootcamp}/${route}`,
        themePalette: colorMap[name],
        index,
      },
      ...indexContentTypes(bootcamp, contentTypes, name, route, activeMembershipGroups),
    }), {});
}

export async function expandConfig(config, onFinished, fetchTest) {
  console.log('this is running');
  const expandedSearchConfig = await Object.keys(config).reduce(async (acc, configKey) => {
    if (configKey === 'classrooms') {
      return {
        ...(await acc),
        classrooms: await config['classrooms'].reduce(async (acc, classroom) => {
          return [
            ...(await acc),
            {
              ...classroom,
              contentTypes: await classroom.contentTypes.reduce(async (acc, contentType) => {
                return [
                  ...(await acc),
                  {
                    ...contentType,
                    content: await contentType.content.reduce(async (acc, content) => {
                      if (!content.shouldExpand && !['Videos', 'Neurology Videos', 'GI Videos', 'Pulmonary Videos', 'Cardiology Videos', 'Psychiatry Videos', 'Biostatistics Videos', 'Heme & Onc Videos', 'Nephrology Videos', 'Dermatology Videos', 'Immunology Videos',  'Dr. Roviso\'s Videos', 'Bio Videos', 'A&P Videos', `Mike's Videos`, `PAT Academy`, `RC Academy`, `QR Academy`].includes(content.name)) {
                        return [
                          ...(await acc),
                          content
                        ]
                      }
                      return [
                        ...(await acc),
                        {
                          ...content,
                          content: await content.content.reduce(async (acc, content) => {
                            const test = await fetchTest(content.id);
                            return [
                              ...await (acc),
                              {
                                ...content,
                                content: await test.blockConnections?.items
                                  ?.filter(({testBlock}) => testBlock.type !== 'startBlock' && testBlock.type !== 'endBlock')
                                  ?.map(({index, testBlock}) => ({
                                    name: testBlock.title,
                                    keywords: testBlock.components?.find(({renderType}) => renderType === 'keywords')?.contents || '',
                                    video: testBlock.components?.find(({renderType}) => renderType === 'video')?.contents || '',
                                    route: `?${classroom.route}/videos/${content.route}?index=${index}`,
                                    type: testBlock?.type,
                                    id: test.id,
                                    index,
                                  }))
                              }
                            ]
                          }, [])
                        }
                      ]
                    }, [])
                  }
                ]
              }, [])
            }
          ]
        }, [])
      }
    }
    return {
      ...(await acc),
      [configKey]: config[configKey]
    }
  }, {});

  onFinished(expandedSearchConfig);
  return expandedSearchConfig;
}
