import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {MapInteraction} from 'react-map-interaction';
import debounce from 'lodash.debounce';
import {FilterCenterFocus} from '@styled-icons/material-outlined/FilterCenterFocus';
import {Eye} from '@styled-icons/evaicons-solid/Eye';
import {EyeOff} from '@styled-icons/evaicons-solid/EyeOff';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .anatomy-image-viewer__controls {
    border-radius: 8px;
    position: absolute;
    top: 32px;
    right: 32px;
    overflow: hidden;

    ${({theme}) => theme.mediaQueries.tablet} {
      top: 24px;
      right: 24px;
    }

    ${({theme}) => theme.mediaQueries.mobileL} {
      top: 16px;
      right: 16px;
    }

  }
  .anatomy-image-viewer__controls-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 40px;
    margin: 0;
    border-width: 0px;
    font-size: 24px;
    background: white;
    cursor: pointer;
    &:first-child {
      border-bottom: 1px solid #EEEDF0;
    }
  }
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
  cursor: all-scroll;
  user-select: none;
  background: black;
`;

const MapWrapper = styled.div`
  transform: ${({transform}) => transform};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: transform .2s ease-in; */
`;

const ScalePercentContainer = styled.div`
  position: absolute;
  top: 120px;
  right: 32px;
  width: 48px;
  text-align: center;
  color: ${({theme}) => theme.overlays.opacity.light._400};
  font-size: 16px;
  line-height: 20px;
  z-index: 2;

  ${({theme}) => theme.mediaQueries.tablet} {
    top: 112px;
    right: 24px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    top: 104px;
    right: 16px;
  }

`;

const ScalePercent = ({scale}) => {
  const scalePercent = Math.round(scale * 100);
  return (
    <ScalePercentContainer>
      {scalePercent}%
    </ScalePercentContainer>
  )
}

const ResetButtonContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 96px;
  width: 48px;
  height: 40px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.tablet} {
    top: 24px;
    right: 80px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    top: 16px;
    right: 72px;
  }

`;
const ResetButtonIcon = styled(FilterCenterFocus).attrs({size: 24, color: '#2F3137'})``;

const ResetButton = ({reset}) => (
  <ResetButtonContainer
    onClick={reset}
    onTouchEnd={reset}
    children={<ResetButtonIcon />}
  />
)


const ToggleLabelsButtonContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
  height: 40px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: ${({theme, showingLabeled}) => showingLabeled ? theme.colors.neutralsPalette.darkGrey : theme.colors.neutralsPalette.grey};
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.tablet} {
    top: 24px;
    left: 24px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    top: 16px;
    left: 16px;
  }

`;

const LabelsActiveButtonIcon = styled(Eye).attrs({size: 24})`
  margin-right: 8px;
`;

const LabelsInactiveButtonIcon = styled(EyeOff).attrs({size: 24})`
  margin-right: 8px;
`;

const ToggleLabelsButton = ({setShowingLabeled, showingLabeled}) => (
  <ToggleLabelsButtonContainer
    showingLabeled={showingLabeled}
    onClick={setShowingLabeled}
    onTouchEnd={setShowingLabeled}
  >
    {showingLabeled ? <LabelsActiveButtonIcon /> : <LabelsInactiveButtonIcon />}
    Labels
  </ToggleLabelsButtonContainer>
)

export const AnatomyImageViewer = ({onChange, children, showingLabeled, setShowingLabeled, hasLabels, containerDimensions={height: 0, width: 0}, defaultScale=1, defaultTranslation={x: 0, y: 0}}) => {
  const initialValues = {scale: defaultScale, translation: defaultTranslation};

  const [scale, setScale] = useState(defaultScale);
  const [translation, setTranslation] = useState(defaultTranslation);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const containerRef = useRef();

  useEffect(() => {
    const resizer = debounce(() => {
      if (containerRef && containerRef.current) {
        const dimensions = containerRef.current.getBoundingClientRect();
        setHeight(dimensions.height);
        setWidth(dimensions.width);
      }
    }, 500)
    resizer();

    window.addEventListener('resize', resizer);
    return () => window.removeEventListener('resize', resizer);
  }, []);

  const heightScalingFactor = containerDimensions.height ? height / containerDimensions.height : 1;
  const widthScalingFactor = containerDimensions.width ? width / containerDimensions.width : 1;

  const scalingFactor = Math.min(heightScalingFactor, widthScalingFactor);

  const scaleDelta = initialValues.scale !== scale;
  const translationDelta = initialValues.translation.x !== translation.x || initialValues.translation.y !== translation.y;


  return (
    <Wrapper>
      <MapInteraction
        showControls
        onChange={({scale, translation}) => {
          setScale(scale);
          setTranslation(translation);
          onChange({scale, translation, containerDimensions: {height, width}});
        }}
        scale={scale}
        translation={translation}
        controlsClass={'anatomy-image-viewer__controls'}
        btnClass={'anatomy-image-viewer__controls-button'}
      >
        {
          ({ translation, scale }) => {
            return (
              <MapContainer ref={node => {
                if (node) {
                  containerRef.current = node;
                }
              }}>
                {scaleDelta &&
                  <ScalePercent scale={scale} />
                }
                {(scaleDelta || translationDelta) &&
                  <ResetButton reset={e => {
                    e.preventDefault();
                    e.target.blur();
                    setScale(initialValues.scale);
                    setTranslation(initialValues.translation);
                    onChange({scale: initialValues.scale, translation: initialValues.translation, containerDimensions: {height, width}});
                  }} />
                }
                {hasLabels &&
                  <ToggleLabelsButton
                    setShowingLabeled={e => {
                      e.preventDefault();
                      e.target.blur();
                      setShowingLabeled(!showingLabeled);
                    }}
                    showingLabeled={showingLabeled}
                  />
                }
                <MapWrapper transform={`scale(${scalingFactor}) translate(${translation.x}px, ${translation.y}px) scale(${scale})`}>
                  {children}
                </MapWrapper>
              </MapContainer>
            );
          }
        }
      </MapInteraction>
    </Wrapper>
  )
};
