import React, {useCallback, useEffect} from 'react';
import {getTest, getQuestionForTest} from '@bootcamp/shared/src/requests';

import FrontTest from '../../../components/FrontTest';
import {useAsync} from '@bootcamp/shared/src/util/hooks';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import moment from 'moment';
import {getStreakDetails} from '@bootcamp/shared/src/util';
import firstBy from 'thenby';
const NUMBER_OF_QUESTIONS = 5;

const WarmupTest = ({location}) => {
  const fetchTest = useCallback(async () => {
    try {
      const test = await getTest('830d3a5d-8ce4-46cb-a6d5-1417fb4b50c3', 'getTestQuestionIds');
      const questionConnections = test.data.getTest.blockConnections.items[1].testBlock.questionConnections.items.sort(firstBy('index'));
      const totalDWUDays = Math.ceil(questionConnections.length / NUMBER_OF_QUESTIONS);
      // which day are we on?
      const originDate = moment('12/14/21');
      const startIndex = (moment().diff(originDate, 'days') % totalDWUDays) * NUMBER_OF_QUESTIONS;
      const idsToFetch = questionConnections
        .slice(startIndex, startIndex + NUMBER_OF_QUESTIONS)
        .map(({question}) => question.id);

      const promises = idsToFetch.map(getQuestionForTest);
      const resolvedPromises = await Promise.all(promises);
      const questions = resolvedPromises.map(promise => promise.data.getQuestionBase);
      const questionSets = [
        {
          index: 1,
          testBlock: {
            questionConnections: {
              items: questions.map(question => ({question}))
            }
          }
        }
      ]
      return {
        config: {
          title: `Daily Warmup`
        },
        id: '',
        blockConnections: {
          items: [
            {testBlock: {type: 'startQuickReview'}, index: 0},
            ...questionSets,
            {testBlock: {type: 'endBlock'}, index: questionSets.length + 1}
          ]
        }
      }
    } catch (e) {
      return {}
    }
  }, []);
  const {value: test} = useAsync(fetchTest);
  const {bootcamp, cognitoUser, interactions, saveUserInteraction} = useUserDataContext();

  useEffect(() => {
    if (!cognitoUser) return;
    const interactionKey = `Streak-DWU-${bootcamp}`;
    const previousInteraction = interactions.find(({id}) => id === `${cognitoUser?.getUsername()}-${interactionKey}`);
    const {streak, longestStreak, date: streakDate} = JSON.parse((previousInteraction || {}).value || '{}');

    const {today, streakCompletedToday, streakActive} = getStreakDetails(streak, streakDate);
    if (streakCompletedToday) return;

    const streakData = {
      streak: streak > 0 && streakActive ? streak + 1 : 1,
      date: today.format('M/D/YYYY'),
      longestStreak: streak + 1 > longestStreak && streakActive ? streak + 1 : (longestStreak || streak + 1)
    }
    saveUserInteraction(interactionKey, JSON.stringify(streakData))

  }, [cognitoUser])
  return (
    <FrontTest
      location={location}
      loading={test}
      test={test || {}}
      />
  );
}

export default WarmupTest;
