import React, {useEffect, useRef, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';

import QuestionView from '../QuestionView';
import Highlightable from '../Highlightable';
import {SubmitAnatomyCase, SubmitModal, FullScreenAnimation} from '../../components/Modal';
import {MasteryButton, MasteryButtonText} from '../../components/Branding/Buttons/MasteryButton';
import {KeyboardShortcutLabel} from '../../components/Typography';
import {CheckButton, UpdatedPreviousButton, UpdatedCheckButton, UpdatedNextButton, BookmarkButton, UpdatedExhibitButton} from '../../components/QuestionBank/Buttons';
import FinishedDWUAnimation from '@bootcamp/shared/src/assets/animations/FinishedDWUAnimation.json';

import {useTestNavigatorContext} from '../../contexts/TestNavigator';
import {useTimerContext} from '../../contexts/TestTimer';
import {useModalContext} from '../../contexts/Modal';
import {useUserDataContext} from '../../contexts/UserData';
import {createQuestionProgress, updateQuestionProgress as updateQbQuestionProgress} from '@bootcamp/shared/src/requests';
import baseTheme from '@bootcamp/shared/src/styles/theme';
import {useReloadBlockerContext} from '../../contexts/ReloadBlocker';

import {colors} from '@bootcamp/shared/src/styles/theme';
import {getQuestionParts, getQuestionPartsAsync, insertAtIndex, getTestBlockQuestions, getStreakDetails, defaultQuestionParts, delay, getNCLEXSubjectSystemTags} from '@bootcamp/shared/src/util';
import {useAsync} from '@bootcamp/web/src/hooks';
import {createBlockProgress, updateBlockProgress, createTestProgress, trackQuestionPerformance, trackQuizProgressPerformance} from '@bootcamp/shared/src/requests';
import {NextButton as FLNextButton, PreviousButton as FLPreviousButton, ExhibitButton as FLExhibitButton, MarkButton as FLMarkButton, MarkedButton as FLMarkedButton, ReviewButton as FLReviewButton, CheckButton as FLCheckButton, LearningButton as FLLearningButton, ReviewingButton as FLReviewingButton, MasteredButton as FLMasteredButton} from '@bootcamp/web/src/components/PrometricBank/components/shared';
import {normalizeHighlights} from '@bootcamp/web/src/helpers';
import {updateCustomTestConfig} from '@bootcamp/shared/src/util';

const SuperContainer = styled.div`
  width: 100%;
  ${({scrollable}) => scrollable && css`
    overflow-y: auto;
    ${baseTheme.mediaQueries.tablet} {
      overflow-y: initial;
    }
  `}
  ${({maxHeight}) => maxHeight && css`
    height: 100%;
    ${baseTheme.mediaQueries.tablet} {
      overflow-y: auto;
    }
  `}
  ${({padded}) => padded && css`
    padding: 0px 24px;
  `}

  ${({overflowHidden}) => overflowHidden && css`
    overflow: hidden;
  `}
`;
const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: ${({alignQuestion}) => alignQuestion === 'left' ? '0' : '0 auto'};
  ${({maxWidth}) => maxWidth && css`
    width: 100%;
    max-width: ${maxWidth}px;
  `}
  ${({noMaxWidth}) => noMaxWidth && css`
    max-width: none;
  `}
`;

const Passage = styled.div`
  max-height: 400px;
  min-height: 400px;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  font-family: Arial;
  text-align: justify;
  line-height: 25px;
  margin-top: 10px;
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : '#D5D8D9'};
  margin-top: 2em;

  ${({theme}) => theme.darkModeEnabled && css`
  * {
    color: ${baseTheme.colors.neutralsPalette.offWhite} !important;
  }
  `}
  p {
    margin: .5em 4em;
    img {
      margin: auto;
      display: block;
    }
  }
  margin-bottom: 100px;
`;

const LabReferencesButton = (
  <UpdatedCheckButton
    onClick={() => window.open('https://drive.google.com/file/d/1L2Fu0q_PsSlEIQvzTKUwY_sLsG0Iu51n/view?usp=sharing', '_blank')}
    themePalette={colors.brandPalette.royal}
    children={'Lab Reference'}
    hotkeyDisabled
  />
);

const QuestionSet = ({padded, overflowHidden, setExhibitType, setShowingExhibit, scrollToTop, enableBookmarking, renderQuestion, themePalette=colors.brandPalette.blue, maxWidth, alignQuestion, scrollable, match, maxHeight, noMaxWidth}) => {
  const {timerActive, toggleTimer, timeUp} = useTimerContext() || {timerActive: false, toggleTimer: () => true};
  const startTime = useRef(null);

  const {
    methods: {
      updateQuizProgress,
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
      setCurrentIndex,
      transitionBlock,
      saveQuestionProgresses,
      loadMoreForBlock,
      getSectionWindow,
      autoTagOnSubmit
    },
    variables: {
      blockIndex,
      test,
      testBlockConnections,
      quizProgress,
      navigationFilter,
      template,
      interactionKey,
      shuffled,
      customTestConfig,
      type
    }
  } = useTestNavigatorContext();
  const isReadinessExam = type === 'readinessExam';
  const history = useHistory();
  const testSubmitted = customTestConfig?.submitted;
  const tutorModeExists = customTestConfig?.config && Object.keys(customTestConfig?.config).includes('tutorMode')
  const tutorMode = customTestConfig?.config?.tutorMode;
  const untutoredMode = (customTestConfig && tutorModeExists && !tutorMode) || isReadinessExam

  const {toggleReloadBlocker} = useReloadBlockerContext();
  const {modalDispatch} = useModalContext();
  const {interactions, saveUserInteraction, DEFAULT_USER_ID, bootcamp, cognitoUser, searchUserInteractions} = useUserDataContext();
  const showKeyboardShortcuts = searchUserInteractions('showKeyboardShortcuts') === true;
  const autoTagEnabled = true;
  const prometricDelay = customTestConfig?.config?.tutorMode ? false : searchUserInteractions('prometricDelay') === true;

  const {highlights: blockHighlightProgress, questions: blockQuestionProgress} = {questions: [], ...quizProgress[blockIndex]};
  const testBlock = testBlockConnections[blockIndex] && testBlockConnections[blockIndex].testBlock;
  const questions = getTestBlockQuestions(testBlock, template === 'customTest');

  const totalQuestions = useMemo(() => {
    return testBlockConnections.reduce((prev, curr) => {
      const total = curr?.testBlock?.questionConnections?.items?.filter(({question}) => (template === 'customTest' || question?.status !== 'draft'))?.length;
      return prev + (total || 0);
    }, 0)
  }, [testBlockConnections]);

  const questionIndex = blockQuestionProgress.findIndex(({current}) => current);

  const passageBlock = testBlock.type === 'passage';
  const passage = passageBlock && testBlock.components.find(component => component.renderType === 'passage').contents;
  const passageTitle = passageBlock && testBlock.components.find(component => component.renderType === 'passageTitle').contents;
  const passageHtml = passageBlock && `<h2 style="text-align: center;">${passageTitle}</h2>`+passage;

  const globalQuestionIndex = quizProgress.slice(0, blockIndex).reduce((questionCount, blockProgress) => {
    if (!blockProgress.questions) return questionCount;
    return questionCount + blockProgress.questions.length;
  }, 0) + questionIndex + 1;

  const setQuestionIndex = index => {
    // TODO maybe move this logic into the test navigator...
    const blockTransition = shuffled ? null : index < 0
      ? 'previous'
      : index === questions.length
      ? 'next'
      : null;

    if (!blockTransition) {
      return setCurrentIndex(blockIndex, index);
    }

    transitionBlock(blockTransition);
  };

  const updateQuestionProgress = params => {
    if (questionIndex === -1) return;

    const updatedQuestionProgress = {...blockQuestionProgress[questionIndex], ...params};

    updateQuizProgress({
      questions: insertAtIndex(
        blockQuestionProgress,
        questionIndex,
        updatedQuestionProgress
      )
    });
    if (params.didCheck) {
      timerActive && toggleTimer();
    }
    return updatedQuestionProgress;
  };
  const updateQuestionTime = () => {
    const timeElapsed = new Date(new Date() - startTime.current);
    const time = (parseFloat(blockQuestionProgress[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);
    updateQuestionProgress({time});
    startTime.current = new Date();
  };

  useEffect(() => {
    if (timerActive || !startTime.current) {
      startTime.current = new Date();
    } else if (['practiceTest', 'fullLengthTest'].includes(template) && startTime.current) {
      updateQuestionTime();
    }
  }, [timerActive]);

  useEffect(() => {
    scrollToTop();
    passage && document.querySelector(`.${Passage.styledComponentId}`) && document.querySelector(`.${Passage.styledComponentId}`).scroll(0,0);
  }, [questionIndex]);

  // CURRENT DATA
  const currentQuestionIndex = shuffled ? questionIndex : `${blockQuestionProgress.find(({current}) => current)?.questionIndex}` || questionIndex;
  const currentQuestion = template === 'testReview' || shuffled ? blockQuestionProgress[currentQuestionIndex]?.question || {} : questions[currentQuestionIndex] || {};
  const currentQuestionProgress = blockQuestionProgress[questionIndex] || {};

  let questionParts = defaultQuestionParts // getQuestionParts(currentQuestion);
  const {value, error} = useAsync(useCallback(() => getQuestionPartsAsync(currentQuestion, currentQuestionProgress.questionRevisionId), [currentQuestionIndex, blockIndex, shuffled]));
  if (['testReview', 'tbcSavedBank'].includes(template) && !isReadinessExam && !window.location.pathname.includes('next-gen-cases') && !error) {
    if (!!value) questionParts = value;
  } else {
    questionParts = getQuestionParts(currentQuestion, currentQuestionProgress.questionRevisionId);
  }

  const {test: testTag, subject} = (questionParts?.tags || []).find(tag => !!tag.subject) || {};
  const periodicTableSubjects = ['General Chemistry'];
  const calculatorSubjects = ['Quantitative Reasoning'];
  const exhibitType = periodicTableSubjects.includes(subject) ? 'periodic' : calculatorSubjects.includes(subject) ? 'calculator' : null;

  useEffect(() => {
    templateHandlers(template).handleBlockProgressChange();
  }, [questionIndex, blockQuestionProgress, timerActive, showKeyboardShortcuts, prometricDelay, exhibitType]);

  setExhibitType && setExhibitType(exhibitType);

  const sectionWindow = getSectionWindow(testBlockConnections, blockIndex);
  const firstQuestionOfThisBlockThatMatchesFilter = blockQuestionProgress.findIndex((progressObject, index) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase());
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck;
    }

    return progressObject[navigationFilter.key] === navigationFilter.value
  });

  const previousBlocksMatchFilter = quizProgress.slice(...sectionWindow)
    .filter((block, index) => (index + sectionWindow[0]) < blockIndex)
    .map(({questions}) => questions || [])
    .flat()
    .findIndex((progressObject) => {
      if (navigationFilter.key === 'search') {
        const {prompt, answer, explanation} = getQuestionParts(progressObject.question);
        const search = [prompt, answer, explanation].join(' ').toLowerCase();
        return search.includes(navigationFilter.value.toLowerCase());
      }
      if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
        return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck;
      }
      return progressObject[navigationFilter.key] === navigationFilter.value
    }) !== -1;

  const ultimateQuestionReached = (questionIndex === questions.length - 1 && blockIndex === testBlockConnections.length - 2) || (shuffled && globalQuestionIndex === totalQuestions);
  const filterActive = navigationFilter && navigationFilter.key;
  const nextFilteredIndex = blockQuestionProgress.findIndex((progressObject, index) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck && index > questionIndex;
    }
    return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
  });

  const nextBlocksMatchFilter = quizProgress.slice(blockIndex + 1).map(({questions}) => questions || []).flat().findIndex((progressObject) => {
    if (navigationFilter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(navigationFilter.value.toLowerCase());
    }
    if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
      return progressObject.didSelectCorrectAnswer === false && progressObject.didCheck;
    }

    return progressObject[navigationFilter.key] === navigationFilter.value
  }) !== -1;
  const isPreviousBlockQuestionSetOrPassage = ['questionSet', 'passage', 'caseQuestionSet'].includes(testBlockConnections[blockIndex - 1].testBlock.type);
  const shouldHidePrevious = (!isPreviousBlockQuestionSetOrPassage && (questionIndex === 0 || (navigationFilter.key && questionIndex <= firstQuestionOfThisBlockThatMatchesFilter)))
    || (navigationFilter.key && !previousBlocksMatchFilter && (firstQuestionOfThisBlockThatMatchesFilter >= questionIndex
    || firstQuestionOfThisBlockThatMatchesFilter === -1));

  const shouldHideNext = globalQuestionIndex === totalQuestions || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter);


  const handleMarkToggle = () => {
    // update question progress locally
    const didMark = !blockQuestionProgress[questionIndex].didMark
    updateQuestionProgress({didMark});
    const masteryLevel = blockQuestionProgress[questionIndex].masteryLevel;
    const originalTestId = blockQuestionProgress[questionIndex].originalTestId;
    // update bookmarked status
    const updatedBookmark = {bookmarked: didMark, question: {...currentQuestion, bookmarked: didMark}, originalTestId, masteryLevel}
    saveQuestionProgresses([updatedBookmark]);
  }

  const handleExhibitToggle = () => setShowingExhibit(showingExhibit => !showingExhibit);

  // udpate qp in backend
  const updateQuestionProgressData = async () => {
    if (testSubmitted || blockQuestionProgress[questionIndex]?.didCheck) {
      startTime.current = new Date();
      return;
    };

    const timeElapsed = new Date(new Date() - startTime.current);
    const time = (parseFloat(blockQuestionProgress[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    startTime.current = new Date();

    const {
      blockArrayIndex,
      questionProgressId,
      questionBaseId,
      questionRevisionId,
      current,
      crossedAnswerIndexes,
      indexes,
      bookmarked,
      originalTestId,
      questionIndex: currentQuestionIndex,
      subject,
      topic,
      subjectTopic,
      question,
      isSequentialStart,
      isSequentialEnd,
      isSequentialSet,
      answerState,
      seen,
      fullCredit,
      partialCredit,
      noCredit,
      // highlights, // not saving these for now b/c there's an issue with restoration from the new object format they're being saved in
      ...questionProgress
    } = updateQuestionProgress({time});

    questionProgress.answerState = JSON.stringify(answerState || '');
    questionProgress.questionRevisionId = questionRevisionId;

    const updatedProgress = await updateQbQuestionProgress(questionProgressId, questionProgress);

    return updatedProgress;
  }

  useEffect(() => {
    if (['tbcSavedBank'].includes(template) && isReadinessExam && bootcamp === 'inbde' && timeUp) {
      updateQuestionProgressData();
    }
  }, [timeUp]);

  const handleNextQuestion = () => {
    if (['customTest', 'tbcSavedBank', 'coursePlayer'].includes(template)) {
      updateQuestionProgressData()
      if (ultimateQuestionReached || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter)) {
        if (bootcamp === 'inbde' && isReadinessExam) {
          return transitionBlock('review');
        }
        return finishedOnClick()
      }
    } else {
      startTime.current = new Date();
    }
    if (navigationFilter.key && navigationFilter.value !== 'all') {
      const nextIndex = blockQuestionProgress.findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > questionIndex;
        }
        if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
          return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex && progressObject.didCheck;
        }
        return progressObject[navigationFilter.key] === navigationFilter.value && index > questionIndex
      });
      if (nextIndex !== -1) {
        setQuestionIndex(nextIndex)
      } else {
        transitionBlock('nextFilterActive');
      }
    } else {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
    }
  };

  const handlePreviousQuestion = () => {
    if (['customTest', 'tbcSavedBank', 'coursePlayer'].includes(template)) {
      updateQuestionProgressData()
    } else {
      startTime.current = new Date();
    }
    if (navigationFilter.key) {
      const prevIndex = (questions.length - 1) - blockQuestionProgress.slice().reverse().findIndex((progressObject, index) => {
        if (navigationFilter.key === 'search') {
          const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
          const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
          return search.includes(navigationFilter.value.toLowerCase()) && index > ((questions.length - 1) - questionIndex);
        }
        if (navigationFilter.key === 'didSelectCorrectAnswer' && navigationFilter.value === false) {
          return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex) && progressObject.didCheck;
        }

        return progressObject[navigationFilter.key] === navigationFilter.value && index > ((questions.length - 1) - questionIndex)
      });
      if (prevIndex !== -1 && prevIndex !== questions.length) {
        setQuestionIndex(prevIndex);
      } else {
        setQuestionIndex(-1);
      }
    } else {
      const prevIndex = questionIndex - 1;
      setQuestionIndex(prevIndex);
    }
  }

  const updateMastery = (masteryLevel) => {
    const updatedQuestionProgress = updateQuestionProgress({masteryLevel, question: {...currentQuestion, masteryLevel}});
    saveQuestionProgresses([updatedQuestionProgress]);
  }

  const onClickMasteryButton = (masteryLevel) => {
    updateMastery(masteryLevel);
    (ultimateQuestionReached || (filterActive && nextFilteredIndex === -1 && !nextBlocksMatchFilter)) && template !== 'customTest' ? finishedOnClick() : handleNextQuestion();
  }

  const finishedOnClick = () => {
    switch (template) {
      case 'tbcQuestionBank':
      case 'tbcSavedBank':
        return modalDispatch({
          type: 'open',
          component: SubmitAnatomyCase,
          enableClickClose: true,
          componentProps: {
            headerText: 'Nice work! You\'ve reached the end of your review.',
            bodyText: 'Would you like to keep reviewing or complete your review?',
            cancelConfig: {
              text: 'Keep Reviewing',
              onCancel: () => {
                modalDispatch({type: 'close'});
                // filterActive ? transitionBlock('firstFilterActive') : transitionBlock('firstQuestionSet');
              },
              shouldRedirect: false,

            },
            confirmConfig: {
              text: 'Complete Review',
              colorConfig: themePalette,
              onConfirm: async () => {

                // make sure reload blocker doesn't take over on submit
                toggleReloadBlocker(false);

                if (['med-school', 'dental-school'].includes(bootcamp)) {
                  const userIdHashTestId = `${DEFAULT_USER_ID}#qbankCollection#${test.id}`;
                  try {
                    await createTestProgress(userIdHashTestId, `${DEFAULT_USER_ID}#qbankCollection`, test.id);
                  } catch (e) {
                    console.log(e);
                  }

                  try {
                    await createBlockProgress(
                      userIdHashTestId,
                      userIdHashTestId,
                      `${test.id}-qbank-collection`,
                      {status: 'complete'},
                      0
                    );
                  } catch (e) {
                    await updateBlockProgress(
                      userIdHashTestId,
                      {status: 'complete'}
                    );
                  }
                }
              },
              shouldRedirect: true,
              redirect: location => {
                const urlParts = window.location.pathname.split('/');
                const isAnatomy = ['gross-anatomy', 'neuroanatomy'].includes(urlParts[2]) && ['identify-structures', 'application', 'clinical-cases'].includes(urlParts[3])
                const isPAT = ['perceptual-ability'].includes(urlParts[2]) && ['keyholes', 'tfe', 'pattern-folding'].includes(urlParts[4]);

                if (isAnatomy) {
                  return urlParts.slice(0, -3).join('/') + `/qbank-collection`
                } else if (isPAT) {
                  return urlParts.slice(0, -3).join('/') + `/qbanks`
                } else {
                  return urlParts.slice(0, -1).join('/')
                }
              }
            }
          },
        });
      case 'quickReview':
        const showFinishedModal = () => {
          modalDispatch({
            type: 'open',
            component: FullScreenAnimation,
            componentProps: {
              lottieConfig: {
                style: {
                  width: '100%',
                  height: '100%'
                },
                config: {
                  animationData: FinishedDWUAnimation
                },
                lottieEventListeners: [
                  {
                    name: 'complete',
                    callback: () => {
                      toggleReloadBlocker(false);
                      window.location.pathname = window.location.pathname.split('/').slice(1, 2);
                    }
                  }
                ]
              }
            },
            modalContainerStyle: theme => `
              background: ${theme.overlays.opacity.dark._400};
              padding: 10vh 10vw;
              align-items: center;
              ${theme.mediaQueries.tablet} {
                background: ${theme.overlays.opacity.dark._400};
                padding: 0;
              }
            `
          })
        }
        const interactionKeyName = interactionKey || `Streak-DWU-${bootcamp}`;
        const previousInteraction = interactions.find(({id}) => id === `${cognitoUser.getUsername()}-${interactionKeyName}`);
        const {streak, longestStreak, date: streakDate} = JSON.parse((previousInteraction || {}).value || '{}');
        const {today, streakCompletedToday, streakActive} = getStreakDetails(streak, streakDate);
        const isDailyWarmup = !['quick-study'].includes(match?.params?.reviewType);

        return isDailyWarmup
          ? modalDispatch({
              type: 'open',
              component: SubmitModal,
              componentProps: !streakCompletedToday ? {
                headerText: `Nice work! Ready to mark your Daily Warmup as complete?`,
                bodyText: `You’re one click away from increasing your Daily Streak. Come back tomorrow to keep it going!`,
                cancelConfig: {
                  text: 'Not Yet',
                  onCancel: () => {
                    transitionBlock('firstQuestionSet');
                    modalDispatch({type: 'close'});
                  },
                },
                confirmConfig: {
                  text: 'Mark Complete',
                  colorConfig: themePalette,
                  onConfirm: history => {
                    const streakData = {
                      streak: streak > 0 && streakActive ? streak + 1 : 1,
                      date: today.format('M/D/YYYY'),
                      longestStreak: streak + 1 > longestStreak && streakActive ? streak + 1 : (longestStreak || streak + 1)
                    }
                    saveUserInteraction(interactionKeyName, JSON.stringify(streakData));
                    showFinishedModal();
                  }
                }
              } : {
                headerText: `Nice work!`,
                bodyText: `You've already completed today's Daily Streak. Come back tomorrow to keep it going!`,
                cancelConfig: {
                  text: 'Keep Reviewing',
                  onCancel: () => {
                    modalDispatch({type: 'close'});
                    // transitionBlock('firstQuestionSet');
                  },
                },
                confirmConfig: {
                  text: 'Go Home',
                  colorConfig: themePalette,
                  onConfirm: () => {
                    window.location.pathname = window.location.pathname.split('/').slice(1, 2);
                  }
                }
              },
            })
          :  modalDispatch({
              type: 'open',
              component: SubmitAnatomyCase,
              enableClickClose: true,
              componentProps: {
                headerText: 'Nice work! You\'ve reached the end of your review.',
                bodyText: 'Would you like to keep reviewing or complete your review?',
                cancelConfig: {
                  text: 'Keep Reviewing',
                  onCancel: () => {
                    modalDispatch({type: 'close'});
                  },
                  shouldRedirect: false,

                },
                confirmConfig: {
                  text: 'Complete Review',
                  colorConfig: themePalette,
                  onConfirm: () => {
                    toggleReloadBlocker(false);
                  },
                  shouldRedirect: true,
                  redirect: location => location.pathname.split('/').slice(0, 2).join('/')
                }
              },
            });
      case 'testReview':
        return modalDispatch({
          type: 'open',
          component: SubmitAnatomyCase,
          enableClickClose: true,
          componentProps: {
            headerText: 'Nice work! You\'ve reached the end of your review.',
            bodyText: 'Would you like to keep reviewing or complete your review?',
            cancelConfig: {
              text: 'Keep Reviewing',
              onCancel: () => {
                modalDispatch({type: 'close'});
                // filterActive ? transitionBlock('firstFilterActive') : transitionBlock('firstQuestionSet');
              },
              shouldRedirect: false,

            },
            confirmConfig: {
              text: 'Complete Review',
              colorConfig: themePalette,
              onConfirm: () => {
                toggleReloadBlocker(false);
              },
              shouldRedirect: true,
              redirect: location => location.pathname.split('/').slice(0, -1).join('/')
            }
          },
        });
      case 'customTest':
        const {headerText, bodyText, confirmText, cancelText} = customTestConfig?.submitted
        ? {
          headerText: 'Would you like to end your review?',
          bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
          confirmText: 'End Review',
          cancelText: 'Return to Review'
        } : {
          headerText: 'End Test',
          bodyText: 'Do you want to end this test? Once ended you can review your test results from the previous tests page.',
          confirmText: 'End Test',
          cancelText: 'Return to Test'
        }

        const onConfirmClick = async () => {
          try {
            // update custom test config to indicate suspension
            await updateCustomTestConfig(customTestConfig, {
              suspended: false,
              timeLeft: null,
              submitted: true,
            });

            if (customTestConfig?.config && !customTestConfig.submitted) {
              if (!customTestConfig?.config?.tutorMode) {
                await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
              }
              await autoTagOnSubmit();
            }
          } catch (error) {
            console.log('error marking test as submitted', error);
          }

          toggleReloadBlocker(false);
          setTimeout(() => history.push(`/${bootcamp}/previous-tests/review?key=${encodeURIComponent(customTestConfig?.config?.testStorageKey)}`), 100);
        }

        const component = customTestConfig?.config?.type === 'anatomy'
          ? ({ close }) => (
            <SubmitAnatomyCase
              headerText={headerText}
              bodyText={bodyText}
              confirmConfig={{
                text: confirmText,
                onConfirm: onConfirmClick
              }}
              cancelConfig={{
                text: cancelText,
                onCancel: close
              }}
            />
          )
          : ({ close }) => (
            <SubmitAnatomyCase
              headerText={headerText}
              bodyText={bodyText}
              cancelConfig={{
                text: cancelText,
                onCancel: close
              }}
              confirmConfig={{
                text: confirmText,
                onConfirm: onConfirmClick,
              }}
            />
          )

        return modalDispatch({
          type: 'open',
          modalContainerStyle: customTestConfig?.config?.type === 'anatomy' ? () => {} : theme => `background: transparent;`,
          component,
          enableClickClose: true,
        })
      default:
        const {testBlock: activeBlock} = testBlockConnections[blockIndex];

        return activeBlock.nextToken
          ? loadMoreForBlock(activeBlock, blockIndex)
          : modalDispatch({
              type: 'open',
              component: SubmitAnatomyCase,
              enableClickClose: true,
              componentProps: {
                headerText: 'Nice work! You\'ve reached the end of your review.',
                bodyText: 'Would you like to keep reviewing or complete your review?',
                cancelConfig: {
                  text: 'Keep Reviewing',
                  onCancel: () => {
                    modalDispatch({type: 'close'});
                  },
                  shouldRedirect: false,

                },
                confirmConfig: {
                  text: 'Complete Review',
                  colorConfig: themePalette,
                  onConfirm: () => {
                    toggleReloadBlocker(false);
                  },
                  shouldRedirect: true,
                  redirect: location => location.pathname.split('/').slice(0, 2).join('/')
                }
              },
            });
    }
  }

  const commonQuestionViewProps = {
    onSelectAnswerIndex: (selectedAnswerIndex, didSelectCorrectAnswer, answerState) => {
      updateQuestionProgress({selectedAnswerIndex, didSelectCorrectAnswer, answerState, didSkip: selectedAnswerIndex === -1})
    },
    didSubmitAnswer: blockQuestionProgress?.[questionIndex]?.didCheck,
    didSubmitAnswerIndex: blockQuestionProgress?.[questionIndex]?.selectedAnswerIndex,
    questionId: currentQuestion.id,
    showFeedback: true,
    enableHighlighting: false,
    bookmarked: blockQuestionProgress?.[questionIndex]?.bookmarked,
    didMark: blockQuestionProgress?.[questionIndex]?.didMark,
    didCheck: blockQuestionProgress?.[questionIndex]?.didCheck,
    time: blockQuestionProgress?.[questionIndex]?.time,
    savedCrossedAnswerIndexes: (blockQuestionProgress?.[questionIndex]?.crossedAnswerIndexes) || [],
    onCrossedAnswerIndexesUpdate: (crossedAnswerIndexes) => {
      updateQuestionProgress({crossedAnswerIndexes});
    }
  }

  const bookmarkStatus = blockQuestionProgress?.[questionIndex]?.bookmarked;
  const didCheck = blockQuestionProgress?.[questionIndex]?.didCheck;

  const displayMasteryButtons = () => {
    setLeftButtons([
      {
        component: (
          <UpdatedPreviousButton
            key={currentQuestion.id + 'previous'}
            disabled={shouldHidePrevious}
            onClick={() => handlePreviousQuestion()}
          />
        )
      },
      {
        component: (
          <UpdatedNextButton
            key={currentQuestion.id + 'next'}
            disabled={template === 'customTest' ? false : shouldHideNext}
            onClick={() => template !== 'customTest' && shouldHideNext ? finishedOnClick() : handleNextQuestion()}
          />
        )
      },
      {
        component: (
          <MasteryButton
            key={currentQuestion.id+'learning'}
            hotkey={'1'}
            children={<MasteryButtonText>Learning{showKeyboardShortcuts && <KeyboardShortcutLabel children="1" />}</MasteryButtonText>}
            onClick={() => onClickMasteryButton('learning')}
            colorConfig={baseTheme.colors.interfacePalette.red}
          />
        ),
      },
      {
        component: (
          <MasteryButton
            key={currentQuestion.id+'reviewing'}
            hotkey={'2'}
            children={<MasteryButtonText>Reviewing{showKeyboardShortcuts && <KeyboardShortcutLabel children="2" />}</MasteryButtonText>}
            onClick={() => onClickMasteryButton('reviewing')}
            colorConfig={baseTheme.colors.interfacePalette.yellow}
          />
          )
      },
      {
        component: (
          <MasteryButton
            key={currentQuestion.id+'mastered'}
            hotkey={'3'}
            children={<MasteryButtonText>Mastered{showKeyboardShortcuts && <KeyboardShortcutLabel children="3" />}</MasteryButtonText>}
            onClick={() => onClickMasteryButton('mastered')}
            colorConfig={baseTheme.colors.interfacePalette.green}
          />
        )
      },
      {
        component: enableBookmarking && (
          <BookmarkButton
            key={currentQuestion.id+'bookmark'}
            onClick={() => {
              const updatedQuestionProgress = updateQuestionProgress({bookmarked: !bookmarkStatus, question: currentQuestion});
              saveQuestionProgresses([updatedQuestionProgress]);
            }}
            bookmarked={bookmarkStatus}
            colorConfig={bookmarkStatus ? baseTheme.colors.interfacePalette.yellow : baseTheme.colors.neutralsPalette.light}
            children={showKeyboardShortcuts && <KeyboardShortcutLabel children="B" />}
          />
        ),
      },
      {
        component: exhibitType && (
          <UpdatedExhibitButton
            key={currentQuestion.id+'exhibit'}
            onClick={handleExhibitToggle}
            exhibitType={exhibitType}
            type={'secondary'}
            children={showKeyboardShortcuts && <KeyboardShortcutLabel children="E" />}
          />
        )
      },
    ]);
  }
  const checkOnClick = (e) => {
    e.preventDefault();

    if (questionIndex === -1) return;


    const timeElapsed = new Date(new Date() - startTime.current);
    const time = (parseFloat(blockQuestionProgress[questionIndex].time) + (timeElapsed.getTime() / 1000)).toFixed(2);

    const credit = {
      full: (!!currentQuestionProgress?.answerState?.score && !!currentQuestionProgress?.answerState?.maxScore && currentQuestionProgress?.answerState?.score === currentQuestionProgress?.answerState?.maxScore) || currentQuestionProgress?.didSelectCorrectAnswer,
      partial: currentQuestionProgress?.answerState?.score > 0 && (currentQuestionProgress?.answerState?.score !== currentQuestionProgress?.answerState?.maxScore),
      none: (!currentQuestionProgress?.answerState?.score || currentQuestionProgress?.answerState?.score === 0),
    };

    const autoTag = credit.full
      ? 'mastered'
      : credit.partial
      ? 'reviewing'
      : 'learning';

    const {
      blockArrayIndex,
      questionProgressId,
      questionBaseId,
      questionRevisionId,
      current,
      crossedAnswerIndexes,
      bookmarked,
      originalTestId,
      questionIndex: currentQuestionIndex,
      subject,
      topic,
      subjectTopic,
      question,
      isSequentialStart,
      isSequentialEnd,
      isSequentialSet,
      answerState,
      seen,
      highlights,
      fullCredit,
      partialCredit,
      noCredit,
      ...questionProgress
    } = updateQuestionProgress({
      didCheck: true,
      time,
      fullCredit: credit.full,
      partialCredit: credit.partial,
      noCredit: credit.none,
      ...(autoTagEnabled ? {masteryLevel: autoTag} : {}),
    });

    questionProgress.highlights = JSON.stringify(normalizeHighlights(highlights) || '');
    questionProgress.answerState = JSON.stringify(answerState || '');
    questionProgress.questionRevisionId = questionRevisionId;

    if (['customTest', 'tbcSavedBank', 'coursePlayer'].includes(template) || match?.params?.classroom === 'next-gen-cases') {
      updateQbQuestionProgress(questionProgressId, questionProgress);
    }

    // auto tag question if we're on nclex or med school
    if (autoTagEnabled) {

      const update = {
        blockArrayIndex,
        questionProgressId,
        questionBaseId,
        questionRevisionId,
        current,
        crossedAnswerIndexes,
        bookmarked,
        originalTestId,
        questionIndex: currentQuestionIndex,
        subject,
        topic,
        subjectTopic,
        question: {...question, masteryLevel: autoTag},
        isSequentialStart,
        isSequentialEnd,
        isSequentialSet,
        answerState,
        seen,
        highlights,
        fullCredit,
        partialCredit,
        noCredit,
        ...questionProgress
      };

      saveQuestionProgresses([update]);
    }

    // NOTE question progress creation is happening for any question with 'truthy' answer state, we may want to validate
    // the answerState before submitting (similar to the selectedAnswerIndex !== -1 conditional)

    // BlockProgress
    // id - username#originaltestid#tracking
    // QuestionProgress
    // id - username#originaltestid#questionbaseid#tracking
    // create IFF not exists:
    // qbankprogress
    try {
      const blockProgressId = `tracking#${cognitoUser.getUsername()}#${originalTestId || test.id}`
      const qbankProgressId = `tracking#${cognitoUser.getUsername()}#${originalTestId || test.id}#${questionBaseId}`
      createQuestionProgress(
        qbankProgressId,
        blockProgressId,
        questionBaseId,
        questionRevisionId,
        {...questionProgress, time},
        currentQuestionIndex,
      );
    } catch (error) {
      console.log('can ignore this error', error)
    }
    const shouldTrack = bootcamp === 'med-school' ? !window.location.pathname.includes('qbanks') && !window.location.pathname.includes('videos') : !window.location.pathname.includes('bites') && !window.location.pathname.includes('videos');
    if (shouldTrack) {
      try {
        if (bootcamp === 'nclex') {
          // subject tag
          const {subject, system} = getNCLEXSubjectSystemTags(questionParts?.tags);
          trackQuestionPerformance(DEFAULT_USER_ID, subject, system, answerState.maxScore, answerState.score, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
        } else {
          // update for nclex here...
          const {id: subject} = (questionParts?.tags || []).find(tag => !!tag.subject && !tag.topic) || {};
          const {id: topic} = (questionParts?.tags || []).find(tag => !!tag.topic) || {};
          trackQuestionPerformance(DEFAULT_USER_ID, subject, topic, 1, questionProgress.didSelectCorrectAnswer ? 1 : 0, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const displayNavigationButtons = (hideCheckButton=false) => {
    setLeftButtons([
      {
        component: (
          <UpdatedPreviousButton
            key={currentQuestion.id + 'previous'}
            disabled={shouldHidePrevious}
            onClick={() => handlePreviousQuestion()}
          />
        )
      },
      {
        component: (
          <UpdatedNextButton
            key={currentQuestion.id + 'next'}
            disabled={template === 'customTest' ? false : shouldHideNext}
            onClick={() => template !== 'customTest' && shouldHideNext ? finishedOnClick() : handleNextQuestion()}
          />
        )
      },
      !hideCheckButton && {component: (
        <UpdatedCheckButton
          key={currentQuestion.id+questionIndex+'check'}
          questionId={currentQuestion.id}
          onClick={checkOnClick}
          colorConfig={themePalette}
          children={<span style={{display: 'flex', alignItems: 'center'}}>Check Answer{showKeyboardShortcuts && <KeyboardShortcutLabel children="C" />}</span>}
          disabled={currentQuestionProgress?.selectedAnswerIndex === -1}
        />
      )},
      exhibitType && {
        component: (
          <UpdatedExhibitButton
            key={currentQuestion.id+'exhibit'}
            onClick={handleExhibitToggle}
            exhibitType={exhibitType}
            type={'secondary'}
            children={showKeyboardShortcuts && <KeyboardShortcutLabel children="E" />}
          />
        )
      },
      testTag === 'Med School USMLE' && {component: LabReferencesButton}
    ])
  }
  const displayPrometricNavigationButtons = () => {
    setLeftButtons([
      !shouldHidePrevious && {
        component: <FLPreviousButton onClick={async () => {
          timerActive && template !== 'customTest' && !(bootcamp === 'inbde' && isReadinessExam) && updateQuestionTime();
          prometricDelay && await delay(1667);
          handlePreviousQuestion();
        }} />
      },
      (['tbcSavedBank', 'tbcQuestionBank'].includes(template) || bootcamp === 'inbde') && {
        component: <FLNextButton onClick={async () => {
          timerActive && template !== 'customTest' && !(bootcamp === 'inbde' && isReadinessExam) && updateQuestionTime();
          prometricDelay && await delay(1667);
          handleNextQuestion();
        }}/>
      }
    ]);
    setCenterButtons([
      bootcamp !== 'inbde' && {
        component: ['tbcSavedBank', 'tbcQuestionBank'].includes(template)
        ? <FLCheckButton onClick={checkOnClick} />
        : <FLNextButton onClick={async () => {
          timerActive && template !== 'customTest' && !(bootcamp === 'inbde' && isReadinessExam) && updateQuestionTime();
          prometricDelay && await delay(1667);
          handleNextQuestion();
        }}/>
      }
    ])
    setRightButtons([
      {
        component: blockQuestionProgress[questionIndex] && blockQuestionProgress[questionIndex].didMark ? <FLMarkedButton onClick={handleMarkToggle} /> : <FLMarkButton onClick={handleMarkToggle} />
      },
      exhibitType && {
        component: <FLExhibitButton onClick={handleExhibitToggle} />
      },
      {
        component: <FLReviewButton onClick={async () => {
          if (template === 'customTest' || (bootcamp === 'inbde' && isReadinessExam)) {
            updateQuestionProgressData()
          } else {
            timerActive && updateQuestionTime();
          }
          prometricDelay && await delay(1667);
          transitionBlock(template === 'fullLengthTest' ? 'sectionReview' : 'review');
        }} />
      }
    ]);
  }
  const displayPrometricMasteryButtons = () => {
    setLeftButtons([
      !shouldHidePrevious && {
        component: <FLPreviousButton onClick={async () => {
          timerActive && template !== 'customTest' && !(bootcamp === 'inbde' && isReadinessExam) && updateQuestionTime();
          prometricDelay && await delay(1667);
          handlePreviousQuestion();
        }} />
      },
      (['tbcSavedBank', 'tbcQuestionBank', 'testReview'].includes(template) || (template === 'customTest' && (!untutoredMode || testSubmitted))) && {
        component: <FLNextButton onClick={async () => {
          timerActive && template !== 'customTest' && !(bootcamp === 'inbde' && isReadinessExam) && updateQuestionTime();
          prometricDelay && await delay(1667);
          handleNextQuestion();
        }}/>
      }
    ]);
    setCenterButtons([
      {
        component: (
          <FLLearningButton
            key={currentQuestion.id+'learning'}
            onClick={() => onClickMasteryButton('learning')}
          />
        ),
      },
      {
        component: (
          <FLReviewingButton
            key={currentQuestion.id+'reviewing'}
            onClick={() => onClickMasteryButton('reviewing')}
          />
          )
      },
      {
        component: (
          <FLMasteredButton
            key={currentQuestion.id+'mastered'}
            onClick={() => onClickMasteryButton('mastered')}
            handleNextQuestion={globalQuestionIndex === totalQuestions ? () => true : handleNextQuestion}
            handlePreviousQuestion={shouldHidePrevious ? () => true : handlePreviousQuestion}
          />
        )
      }
    ])
    setRightButtons([
      {
        component: blockQuestionProgress[questionIndex] && blockQuestionProgress[questionIndex].didMark ? <FLMarkedButton onClick={handleMarkToggle} /> : <FLMarkButton onClick={handleMarkToggle} />
      },
      exhibitType && {
        component: <FLExhibitButton onClick={handleExhibitToggle} />
      },
      {
        component: <FLReviewButton onClick={async () => {
          if (template === 'customTest' || (isReadinessExam && template !== 'testReview')) {
            updateQuestionProgressData()
          } else {
            timerActive && updateQuestionTime();
          }
          prometricDelay && await delay(1667);
          transitionBlock(template === 'fullLengthTest' ? 'sectionReview' : 'review');
        }} />
      }
    ])
  }
  const templateHandlers = () => {
    switch (template) {
      case 'coursePlayer':
        return {
          handleBlockProgressChange: () => {},
          questionViewProps: {...commonQuestionViewProps, enableHighlighting: true}
        }
      case 'customTest':
      case 'tbcQuestionBank':
      case 'tbcSavedBank':
        return {
          handleBlockProgressChange: () => {
            if (bootcamp === 'inbde' && (template === 'customTest' || isReadinessExam)) {
              if (didCheck || testSubmitted) {
                displayPrometricMasteryButtons()
              } else {
                displayPrometricNavigationButtons(untutoredMode);
              }
            } else if (didCheck || testSubmitted) {
              displayMasteryButtons();
            } else {
              displayNavigationButtons(untutoredMode);
            }
          },
          questionViewProps: { // TODO think about moving the nclex specific props into the nclex question component - all of these values can be derived from testnav context
            ...commonQuestionViewProps,
            enableHighlighting: bootcamp === 'nclex' ? !prometricDelay : true,
            onSelectAnswerIndex: bootcamp === 'nclex' ? answerState => updateQuestionProgress({answerState}) : commonQuestionViewProps.onSelectAnswerIndex,
            didSubmitAnswerIndex: bootcamp === 'nclex' ? blockQuestionProgress?.[questionIndex]?.answerState?.answerState : commonQuestionViewProps.didSubmitAnswerIndex,
            didSubmitAnswer: untutoredMode && !testSubmitted
              ? false
              : testSubmitted ? true
              : blockQuestionProgress?.[questionIndex]?.didCheck,
          }
        }
      case 'quickReview':
        return {
          handleBlockProgressChange: () => {
            if (didCheck) {
              displayMasteryButtons();
            } else {
              displayNavigationButtons();
            }
          },
          questionViewProps: commonQuestionViewProps
        }
      case 'testReview':
        return {
          handleBlockProgressChange: () => {
            if (bootcamp === 'inbde') {
              return displayPrometricMasteryButtons();
            }
            displayMasteryButtons()
          },
          questionViewProps: {
            ...commonQuestionViewProps,
            onSelectAnswerIndex: (selectedAnswerIndex, didSelectCorrectAnswer) => false,
            didSubmitAnswer: true,
          }
        }
      case 'practiceTest':
      case 'fullLengthTest':
        return {
          handleBlockProgressChange: displayPrometricNavigationButtons,
          questionViewProps: {
            onSelectAnswerIndex: (selectedAnswerIndex, didSelectCorrectAnswer) => {
              updateQuestionProgress({selectedAnswerIndex, didSelectCorrectAnswer, didSkip: false});
            },
            savedCrossedAnswerIndexes: blockQuestionProgress[questionIndex] && blockQuestionProgress[questionIndex].crossedAnswerIndexes || [],
            onCrossedAnswerIndexesUpdate: (crossedAnswerIndexes) => {
              updateQuestionProgress({crossedAnswerIndexes});
            },
            didSubmitAnswer: false,
            didSubmitAnswerIndex: blockQuestionProgress[questionIndex] && blockQuestionProgress[questionIndex].selectedAnswerIndex,
            questionId: currentQuestion.id,
            enableHighlighting: true,
            showFeedback: true
          }
        }
      default:
        return {
          handleBlockProgressChange: () => {
            if (didCheck) {
              displayMasteryButtons();
            } else {
              displayNavigationButtons();
            }
          },
          questionViewProps: commonQuestionViewProps
        }
    }
  }

  return (
    <SuperContainer scrollable={scrollable} maxHeight={maxHeight} padded={padded} overflowHidden={overflowHidden}>
      <Container maxWidth={maxWidth} alignQuestion={alignQuestion} noMaxWidth={noMaxWidth}>
        {renderQuestion({
          testBlock,
          questionIndex,
          masteryLevel: currentQuestionProgress.masteryLevel,
          highlights: normalizeHighlights(blockQuestionProgress[questionIndex] ? blockQuestionProgress[questionIndex].highlights : null),
          setHighlights: updateQuestionProgress,
          ...templateHandlers(template).questionViewProps,
          ...questionParts,
          passage,
          isDraft: currentQuestion.status === 'draft',
          questionRevisionId: currentQuestionProgress.questionRevisionId,
          checkOnClick,
          blockPassage: passage && (
            <Highlightable
              type={'passage'}
              highlights={blockHighlightProgress}
              htmlString={passageHtml}
              setHighlights={updateQuizProgress}>
              <Passage dangerouslySetInnerHTML={{__html: passageHtml}}/>
            </Highlightable>
          )
        })}
        {passage && !['practiceTest', 'fullLengthTest'].includes(template) &&
          <Highlightable
            type={'passage'}
            highlights={blockHighlightProgress}
            htmlString={passageHtml}
            setHighlights={updateQuizProgress}>
            <Passage dangerouslySetInnerHTML={{__html: passageHtml}}/>
          </Highlightable>
        }
      </Container>
    </SuperContainer>
  )
}

QuestionSet.propTypes = {
  setExhibitType: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.number, // null
  ]),
  setShowingExhibit: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.number,
  ]),
  scrollToTop: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.number,
  ]),
  renderQuestion: PropTypes.func,
};

QuestionSet.defaultProps = {
  setExhibitType: null,
  setShowingExhibit: null,
  scrollToTop: () => document.querySelector(`.${SuperContainer.styledComponentId}`)?.scroll(0,0),
  renderQuestion: ({testBlock, questionIndex, ...viewProps}) => (
    <QuestionView key={`${testBlock.id}-${questionIndex}`} {...viewProps}/>
  )
};


export default QuestionSet;
