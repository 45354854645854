import React, {useCallback, useState} from 'react';
import styled, {css} from 'styled-components';
import Auth from '@aws-amplify/auth';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {createMembership, createUser, getUser} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/shared/src/util/hooks';
import {H2, Body2, Body3} from '@bootcamp/web/src/components/Typography';

// window.datbootcamp_plugin_obj = {
//   user_id: '81914',
//   days_left: '180',
//   user_email: 'warren.mcquinn+test@datbootcamp.com'
// }

const Container = styled.div`
  color: white;
  font-family: 'proxima-nova';
  padding: 16px;
  border: 3px solid white;
  border-radius: 8px;
  position: relative;
  transition: all 100ms ease;
  margin-bottom: 16px;

  ${({theme}) => theme.mediaQueries.tablet} {
  }
`;

const Heading = styled(H2)`
  color: white;
  margin-bottom: 8px;
`;

const Text = styled(Body2)`
  color: white;
`;
const Subtext = styled(Body3)`
  color: ${({theme}) => theme.overlays.opacity.light._300};
`;

const {user_id, days_left, user_email} = {} // window.datbootcamp_plugin_obj;
const TBCSignup = () => {
  const [importing, setImporting] = useState(false);
  const [password, setPassword] = useState();
  const {userQuestionMastery} = useUserDataContext();

  const fetchUser = useCallback(async () => {
    if (importing) return;
    const {data: {getUser: userData}} = await getUser(user_email, 'AWS_IAM');
    return userData;
  }, [importing]);
  const {value: userData} = useAsync(fetchUser);
  const loginLink = 'http://app.bootcamp.com/auth#/signin?redirectTo=/dat&email='+encodeURI(user_email)

  // if userdata exists, then we know that the import has already happened!
  if (true) {
    console.log(userData);
    return (
      <Container>
        <Heading>Your Bootcamp.com Account Info</Heading>
        <FormLabel>
          Email address (same as current account):
          <FormInput name="email" type="email" value={user_email} disabled />
        </FormLabel>
        <FormLabel>
          Membership duration: {parseInt(days_left) + 7} days
        </FormLabel>
        <UpgradeButton children="Log into Bootcamp.com now" onClick={() => window.open(loginLink, "_blank")} />
      </Container>
    )
  }
  async function importUser () {
    if (importing) return;
    setImporting(true);
    const username = user_email.toLowerCase();
    const email = user_email;

    await Auth.signUp({username, attributes: {email}, password});
    await Auth.signIn({username, password});
    const groups = window.datbootcamp_plugin_obj.user_has_booster_bio ? ['DATBootcamp', 'PlusPackAccess'] : ['DATBootcamp'];
    await createUser(
      email,
      user_id
    );
    await createMembership({
      id: `${user_id}-${(new Date()).toISOString()}`,
      productId: window.datbootcamp_plugin_obj.user_has_booster_bio ? 'price_1JpIi9D2xOfNJKfx5Q7ARaMs' : 'price_1JpIi9D2xOfNJKfxp7n8Nnnz',
      userMembershipsId: email.toLowerCase(), // can't do user_id because due to cognito it has to match the username, no idOverride for memberships
      startDate: (new Date()).toISOString(),
      duration: days_left,
      status: 'active',
      groups,
    });
    window.open(loginLink, '_blank');
    setImporting(false);
  }

  return (
    <Container>
      <Heading>Upgrade to Bootcamp.com Now</Heading>
      <Subtext>DAT Bootcamp is changing - don't miss out</Subtext>
      <Text>This is a free, easy upgrade with some added bonuses:</Text>
      <FeatureContainer>
        <FeatureList>
          <FeatureListElement>Subscription duration: <Strikethrough>{days_left} days</Strikethrough> <strong>{parseInt(days_left) + 7} days</strong> (One Week Free after transfer)</FeatureListElement>
          <FeatureListElement>{userQuestionMastery.length} tagged & bookmarked question{userQuestionMastery.length === 1 ? '' : 's'} transferred</FeatureListElement>
        </FeatureList>
        <FeatureList>
          <FeatureListElement>NEW: Prometric Practice Test Mode</FeatureListElement>
          <FeatureListElement>Upgraded platform with all DAT Bootcamp content + more</FeatureListElement>
        </FeatureList>
      </FeatureContainer>
      <Text>To continue, create a password below. We'll finish your account upgrade and send you to the Bootcamp.com login screen.</Text>
      <div style={{marginBottom: '16px'}}/>
      <FormLabel>
        Email address (same as current account):
        <FormInput name="email" type="email" value={user_email} disabled />
      </FormLabel>
      <FormLabel htmlFor="password">
        Password:
        <FormInput placeholder="8+ characters" type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
      </FormLabel>
      <UpgradeButton children={importing ? 'Importing your account...' : 'Click to Upgrade to Bootcamp.com'} onClick={importUser} disabled={importing || !password || password?.length < 8}/>
      <Text><strong>Important:</strong> After Jan 2022, DATBootcamp.com will begin redirecting to Bootcamp.com. You'll have full access to DATBootcamp.com until then. We recommend upgrading your account ASAP.</Text>
    </Container>
  )
}

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const FeatureList = styled.ul`
  width: 100%;
`;
const FeatureListElement = styled.li`
  color: white;
`;
const Strikethrough = styled.span`
  text-decoration: line-through;
`;
const FormLabel = styled.label`
`;
const FormInput = styled.input`
  margin: 10px 0px;
  display: block;
  border: 1px solid white;
  border-radius: 8px;
  width: 300px;
  padding: 16px;
  ${({disabled}) => disabled && css`color: white; cursor: not-allowed;`}
`;
const UpgradeButton = styled.div`
  opacity: 1;
  color: white;
  font-family: 'proxima-nova';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  margin: 16px 0px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  box-shadow: ${({theme}) => theme.elevation.shadow.low};
  transform: scale(1);
  transition: all .2s;
  background: ${({theme}) => theme.colors.brandPalette.royal.default};
  &:hover {
    transform: scale(1.01);
    box-shadow: ${({theme}) => theme.elevation.shadow.high};
  }
  ${({importing}) => importing && css`
    background: ${({theme}) => theme.colors.brandPalette.royal.dark};
  `}
  ${({disabled}) => disabled && css`
    cursor: not-allowed;
    opacity: .75;
  `}
`;



export default TBCSignup;
