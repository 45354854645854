import React from 'react';
import styled, {css} from 'styled-components';
import {Link as SiteLink} from 'react-router-dom';
import PropTypes from 'prop-types';

import {GutterContainer, GutterInner} from '../../Spacing';
// import {PurpleButton} from '../../Branding';
import svgTitle from '@bootcamp/shared/src/assets/svg/title.svg';
// import Menu, {MenuIcon, MenuButton} from '../components/Menu';
// import {Close} from '../components/Buttons';

// import {PatchQuestion} from '@styled-icons/bootstrap/PatchQuestion';
// import {DocumentText} from '@styled-icons/heroicons-outline/DocumentText';

// import {colors} from '@bootcamp/shared/src/styles/theme';

const NavBarContainer = styled(GutterContainer)`
  background-color: ${({theme}) => theme.brandPurple};
  box-shadow: 0px 1px 0px ${({theme}) => theme.purpleBorderColor};
  height: auto;
`;

const NavBarInner = styled(GutterInner)`
  display: flex;
  min-height: 70px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const Links = styled.div`
  width: 100%;
  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
`;

const RightLinks = styled(Links)`
  display: flex;
  justify-content: flex-end;
`;

const Link = styled(SiteLink)`
  width: 100px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  text-shadow: ${({isActive, theme}) => isActive ? `-0.02ex 0 ${theme.brandPurple}, 0.02ex 0 ${theme.brandPurple}` : 'none'};
  color: ${({isActive, theme}) => isActive ? 'white' : '#FFFFFFE6'};
  font-weight: ${({isActive}) => isActive ? '700' : 'normal'};
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  ${({isActive}) => isActive && css`
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  `}

  margin: 0px 8px;

  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    color: ${({theme, isActive}) => !isActive && 'white'};
  }
`;
// const MenuLink = styled(Link)`
//   color: ${({theme}) => theme.brandPurple};
//   text-align: left;
//   margin: 0;

//   &:hover {
//     color: ${({theme}) => theme.brandPurple};
//     font-weight: bold;
//   }
// `;

const Title = styled.img`
  color: ${({theme}) => theme.lightTextColor};
  height: 22px;
`;

// const StyledMenuButton = styled(MenuButton)`
//   display: none;
//   ${({theme}) => theme.mediaQueries.laptop} {
//     display: block;
//   }
// `;

// const config = [
//   {
//     route: '/questions',
//     name: 'Questions',
//   },
//   {
//     route: '/test',
//     name: 'Tests',
//   },
// ];

// function renderMenuItems(onClick, history, config, bootcamp) {
//   return config.map(({route, name, themePalette, icon}) => (
//     <MenuLink to={route} onClick={onClick} themePalette={themePalette}>{name}</MenuLink>
//   ))
// }

const NavBar = ({activeRoute}) => {
  // const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavBarContainer>
      <NavBarInner>
        {/* {<StyledMenuButton
          type={'secondary'} size={'small'}
          children={menuOpen ? <Close /> : <MenuIcon />}
          onClick={() => setMenuOpen(!menuOpen)}
        />
        <Menu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          barHeight={0}
          barHeightMobile={0}
          nonContextConfig={config}
          renderMenuItems={renderMenuItems}
        />} */}
        <Links>
          <Link to={'/questions'} isActive={activeRoute === '/questions'}>Questions</Link>
          <Link to={'/test'} isActive={activeRoute === '/test'}>Tests</Link>
        </Links>
        <SiteLink to="/" style={{justifyContent: 'center', width: '100%', display: 'flex', textDecoration: 'none'}}>
          <Title src={svgTitle}/>
        </SiteLink>
        <RightLinks>
          <Link to={'/revisions'} isActive={activeRoute === '/revisions'}>Revisions</Link>
          <Link to={'/login'} isActive={activeRoute === '/login'} as={Link}>Account</Link>
        </RightLinks>
      </NavBarInner>
    </NavBarContainer>
  )
}


NavBar.propTypes = {
  className: PropTypes.string,
  activeRoute: PropTypes.string,
};

export default NavBar;
