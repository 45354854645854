import React from 'react';
import PropTypes from 'prop-types';

import {FlexBox, Column} from '../Grid';
import {H2, H3, H4, Body3} from '../Typography';
import {ExpertEducators} from '../ExpertEducators';

import ProgressBar from '../ProgressBar';

import {useMastery} from '../../hooks';
import {getInObj} from '@bootcamp/shared/src/util';

import {ExitQuizButton, backButtonWidth} from './Buttons';

import styled from 'styled-components';


const Container = styled(FlexBox)`
  padding: ${({theme}) => theme.layouts.spacing.m};
  justify-content: center;
  align-items: center;

  ${({theme}) => theme.mediaQueries.laptop} {
    display: block;
    flex-direction: column;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    padding: 0;
  }
`;
const Wrapper = styled(FlexBox)`
  height: auto;
  align-items: center;
  margin-left -${backButtonWidth}px;

  ${({theme}) => theme.mediaQueries.laptop} {
    margin: 0;
    display: block;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: auto;
  }
`;
const ContentWrapper = styled(FlexBox)`
  height: auto;
  max-width: 608px;
  margin: 0 auto;
  flex-direction: column;
  background: ${({theme}) => theme.colors.special.pearl};
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  border-radius: 8px;
  padding: ${({theme}) => theme.layouts.spacing.xxl};

  ${({theme}) => theme.mediaQueries.mobileL} {
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    padding: 0px ${({theme}) => theme.layouts.spacing.m};
    box-shadow: none;
  }
`;
const Header = styled(Column)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  `;
const LessonHighlight = styled(H2)`
  color: ${({color}) => color};
  margin-bottom: ${({theme}) => theme.layouts.spacing.xs};
  width: 100%;
  min-height: ${({theme}) => theme.font.size.xxlarge.split('px')[0] * theme.font.multipliers.small}px;
  border-radius: 8px;
  ${({theme, loading}) => theme.animation.loadingBackground({loading})};
`;

const ChapterHighlight = styled(H4)`
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  width: 100%;
  min-height: ${({theme}) => theme.font.size.medium.split('px')[0] * theme.font.multipliers.small}px;
  border-radius: 8px;
  ${({theme, loading}) => theme.animation.loadingBackground({loading})};
`;

const StyledProgress = styled(ProgressBar)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;
const Description = styled(Body3)`
  padding: ${({theme}) => theme.layouts.spacing.xl};
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  border-radius: 8px;
  width: 100%;
`;
const DescriptionHeader = styled(H3)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const DescriptionText = styled(Body3)`
  font-style: italic;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
`;
const ButtonWrapper = styled(FlexBox)`
`;

const defaultProgress = {
  learning: 0,
  reviewing: 0,
  mastered: 0,
};

function formatTestMastery({data}) {
  const mastery = getInObj(['QuestionMasteryByTestId', 'items'], data, []);

  const {learning, reviewing, mastered} = mastery.reduce((acc, {masteryLevel, question}) => question ? ({
    ...acc,
    [masteryLevel]: acc[masteryLevel] + 1
  }) : acc, defaultProgress);

  return [{count: mastered}, {count: reviewing}, {count: learning}];
}

const StartBlock = ({
  themePalette,
  questionCount,
  testId,
  loading,
  onExitClick,
  header,
  subheader,
  descriptionHeader,
  descriptionText,
  buttons,
  educators,
  showProgress=true,
  overrideHeaderLoading
}) => {

  const [progress] = useMastery(testId, 'test', formatTestMastery);

  return (
    <Container>
      <ExitQuizButton onClick={onExitClick}/>
      <Wrapper>
        <ContentWrapper>
          <Header>
            <LessonHighlight color={themePalette.default} loading={loading && !overrideHeaderLoading}>{(!loading || overrideHeaderLoading) && header}</LessonHighlight>
            <ChapterHighlight loading={loading && !overrideHeaderLoading}>{(!loading || overrideHeaderLoading) && subheader}</ChapterHighlight>
          </Header>
            {showProgress &&
              <StyledProgress
                segments={progress}
                loading={progress === null}
                total={questionCount}
                lightMode
              />
            }
          <Description>
            <DescriptionHeader>{descriptionHeader}</DescriptionHeader>
            <DescriptionText>
              {descriptionText}
            </DescriptionText>
            {educators && !!educators.length &&
              <ExpertEducators
                themeColor={themePalette.default}
                educators={educators}
              />
            }
          </Description>
          <ButtonWrapper>
            {buttons(themePalette.default, progress)}
          </ButtonWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}

StartBlock.propTypes = {
  buttons: PropTypes.func,
  testId: PropTypes.string,
  questionCount: PropTypes.number,
  loading: PropTypes.bool,
  header: PropTypes.string,
  subheader: PropTypes.node,
  descriptionHeader: PropTypes.string,
  descriptionText: PropTypes.string,
  themePalette: PropTypes.shape({}),
  educators: PropTypes.array,
};

StartBlock.defaultProps = {
  buttons: () => [],
  themePalette: {},
  questionCount: 0,
  loading: false,
  header: '',
  subheader: '',
  descriptionHeader: '',
  descriptionText: '',
  educators: [],
};

export default StartBlock;
