import React from 'react';
import styled from 'styled-components';

import {ProtectedRoute} from '../wrappers';
import NavBar from '../../../components/NavBar/App';
import SideBar, {sideBarWidth} from '../../components/SideBar';
import {RouteBackground, NavBarRouteContainer} from '../shared';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {colors} from '@bootcamp/shared/src/styles/theme';

const DEFAULT_THEME_PALETTE = {themePalette: colors.brandPalette.royal};


const SideBarRouteContainer = styled(NavBarRouteContainer)`
`;

const NavBarWithSideBar = styled(NavBar)`
  overflow: hidden;
  border-radius: 16px 0px 0px 0px;
  width: calc(100% - ${sideBarWidth}px);
  left: ${sideBarWidth}px;
  transition: all 200ms ease;

  ${({theme: {mediaQueries}}) => mediaQueries.laptop} {
    border-radius: 0px;
    width: 100%;
    left: 0;
  }

  ${({theme: {mediaQueries}}) => mediaQueries.tablet} {
    padding: 0 ${({theme}) => theme.layouts.spacing.m};
  }
`;

const Route = ({bootcampRequired=true, ...props}) => {
  // TODO figure out how to not rely on a whitelist here
  const freeRoutes = [
    'videos',
    'bank-1',
    'lesson-1',
    'test-1',
    'bite-1',
    'passage-1', // first RC bank
    'lecture-1',
    'upper-and-lower-limbs-back-practical-1',
    'molecules-and-fundamentals-of-biology', // first Bio bank
    'lab-concepts', // first GC bank
    'bonding-and-molecular-geometry', // first OC bank,
    'alkene-&-alkyne-reactions', // first Rbank
    'algebra-fundamentals', // frist QR bank
    'trainer',
    'units-and-vectors', // first OAT physics qbank
    'head-and-neck-anatomy',
    'oral-pathology',
    'local-anesthetics',
    'pathology',
    'operative-dentistry',
    'developmental-biology',
    'molecular-and-cell-biology',
    'psychology-and-sociology',
    'oral-medicine', // first inbde medicine bites bank
    'anesthesia-and-pain-management', // first inbde pharma qbank
    'pharmacology', // first inbde pharma bites bank
    'embryology', // first step 1 qbank (for Neurology & cardiology)
    'tooth-id-incisors', // first Dental Anatomy ID structure
    'spermatogenesis-oogenesis', // first Embryo bank
    'methods-and-overview-of-cells', // first Histo bank
    'patient-management', // first INBDE pateient management qbank
    'permanent-incisors-anatomy', // first Dental Anatomy lecture bank
    'anatomy', // first Cardio board style bank
    'immunology', // first Dermatology board style bank
    'skin-structure-and-function', // first Derm bite bank
    'immunology', // first Immuno board style bank
    'lymphoid-tissue', // first Immuno bite bank
    'psychology', // first Psychiatry psych bite bank
    'introduction-to-the-respiratory-system', // first Pulmonology bite bank
    'histology', // first Nephrology board style bank
    'anatomy-and-embryology', // first Nephrololgy bite bank
    'biochemistry', // first Gastroenterology board style bank
    'visceral-anatomy', // first Gastroenterology bite bank
    'evidence-based-dentistry', // first inbde research bank
    'cell-biology', // first Biochemistry bite bank
    'research-study-designs', // first Biostats bite bank
    'fundamentals-of-bacteriology', // first microbio bite bank
    'ethics-and-communications', // first phs board bank
    'fundamental-principles-of-endocrinology', // first endo bite bank,
    'cellular', // first biochem bank
    'biostatistics-and-epidemiology',
    'clinical-genetics',
    'antimicrobials',
    'principles-of-osteopathy',
    'pharmacokinetics-and-pharmacodynamics',
    'behavioral-science',
    'general-principles',
    'dna-structure',
    'skeletal-muscle',
    'pharmacodynamics',
    'ethics',
    'fetal-development',
    // first three nclex banks
    'next-gen-case-1',
    'next-gen-case-2',
    'next-gen-case-3',
    // first readiness exam
    'readiness-exam-1',
  ];
  const {config} = useBootcampConfigContext();
  const {contentSlug, contentType} = props.computedMatch.params;

  const allow = (freeRoutes.includes(contentSlug) || freeRoutes.includes(contentType)) ? ['Free'] : props.allow;

  const {themePalette} = config?.classrooms?.find(({route}) => route && props.computedMatch.url.match(route)) || DEFAULT_THEME_PALETTE;

  // if config is falsey return here to prevent 404 redirects on classroom page loads
  if (!config) return null;

  return (
    <RouteBackground color={'transparent'}>
      <ProtectedRoute routeProps={props} allow={allow} color={themePalette.dark}/>
    </RouteBackground>
  );
};

const PageContainer = styled.div`
  background: ${({themePalette}) => themePalette.gradient};
  width: calc(100% - 256px);
  margin-left: 256px;
  border-top-left-radius: 8px;
  ${({theme: {mediaQueries}}) => mediaQueries.laptop} {
    border-top-left-radius: 0px;
    margin-left: 0px;
    width: 100%;
  }
  height: 100%;
`;

const GradientBackground = styled.div`
  background: linear-gradient(121.4deg, rgba(14, 20, 24, 0) 0%, rgba(14, 20, 23, 0.4) 100%);
  height: 100%;
`;

const SideBarRoute = ({location, match, withoutPadding, withoutBorder, lockAtDepth, ...props}) => {
  const {config} = useBootcampConfigContext();
  const {themePalette} = config?.classrooms?.find(({route}) => route && props.computedMatch.url.match(route)) || DEFAULT_THEME_PALETTE;

  return [
    <SideBar match={props.computedMatch} key={'nav-sidebar'}/>,
    <PageContainer themePalette={themePalette}>
      <GradientBackground>
        <NavBarWithSideBar key={`${location.pathname}-navbar`} themePalette={themePalette} withoutBorder={withoutBorder} lockAtDepth={lockAtDepth}/>
        <SideBarRouteContainer id="route-container" key={'route-container'} color={'transparent'} withoutPadding={withoutPadding}>
          <Route themePalette={themePalette} location={location} match={props.computedMatch} {...props}/>
        </SideBarRouteContainer>
      </GradientBackground>
    </PageContainer>
  ];
};

export {
  Route,
  SideBarRoute,
};
