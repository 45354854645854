import {useEffect} from 'react';
import {useTestNavigatorContext} from '../../../../contexts/TestNavigator';
import {getTestBlockQuestions, getQuestionParts} from '@bootcamp/shared/src/util';

function fetchQuestionImage (question) {
  const {anatomyImage} = getQuestionParts(question);
  new Image().src = `https://dekni9rgkrcu0.cloudfront.net/${anatomyImage}`;
}

// This component prefetches the next two anatomyImages.
const ImagePrefetcher = () => {
  const {
    variables: {
      testBlockConnections,
      quizProgress,
    }
  } = useTestNavigatorContext();

  // compose array of all questions
  const questions = testBlockConnections.reduce((acc, {testBlock}) => [...acc, ...getTestBlockQuestions(testBlock)], []);

  // find currentQuestionIndex
  const questionProgresses = quizProgress.reduce((acc, {questions}) => questions ? [...acc, ...questions] : acc, []);
  const currentQuestionIndex = questionProgresses.findIndex(({current}) => current);

  // when currentQuestionIndex changes, prefetch two images
  useEffect(() => questions.slice(currentQuestionIndex+1, currentQuestionIndex+3).forEach(fetchQuestionImage), [currentQuestionIndex]);

  return null;
}

export default ImagePrefetcher;
