import React, {useState, useRef, useEffect} from 'react';
import styled, {css} from 'styled-components';

import Blood from './tables/Blood';
import Cerebrospinal from './tables/Cerebrospinal';
import Serum from './tables/Serum';
import UrineBMI from './tables/UrineBMI';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({active}) => active ? '66%' : '0%'};
  transition: width .5s;
  overflow: hidden;
  font-size: 14px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px 6px;
`;
const Search = styled.div`
`;
const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 12px;
`;
const TableWrapper = styled.div`
  flex: 1;
  height: 100%;
  background: #D7DCED;
  border: 1px solid #AAB;
  overflow: auto;

  .SI {
      display: none;
    }

  ${({showingReferenceIntervals}) => showingReferenceIntervals && css`
    .SI {
      display: block;
    }
  `}

  table {
    background: #D7DCED;
    color: #252E33;
    font-size: 14px;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    width: 100%;
    border-top: none;
    border-bottom: none;
    border-collapse: collapse;
    border-spacing: 2px;
    display: table;
  }

  thead {
    text-rendering: optimizeLegibility;
    color: #000;
    user-select: none;
    font-size: 1.2rem;
    font-family: Arial,Helvetica,sans-serif;
    border-collapse: collapse;
    box-sizing: border-box;
    margin: 0;
    padding: 1px;

    tr {
      border-top: none;
    }

    &:first-child {
      th {
        padding: 4px .5em;
        border-top: none;
      }
    }
  }

  th {
    text-rendering: optimizeLegibility;
    color: #000;
    user-select: none;
    border-collapse: collapse;
    box-sizing: border-box;
    margin: 0;
    padding: 0 0.25em 0.15em 0;
    border: 0 solid black;
    padding-bottom: 1px;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #BBC;
    padding-left: 0.5rem;
  }

  tbody {
    color: #252E33;
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 2px;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    text-rendering: optimizeLegibility;
  }

  tr {
    color: #252E33;
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 2px;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    border-top: 1px solid #BBC;
  }

  td {
    color: #252E33;
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 2px;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    padding-left: 1rem;
    vertical-align: top;
  }

  .group {
    color: #252E33;
    font-size: 14px;
    border-collapse: collapse;
    border-spacing: 2px;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    border-top: none;
  }

  .labName {
    text-rendering: optimizeLegibility;
    color: #000;
    user-select: none;
    border-collapse: collapse;
    box-sizing: border-box;
    margin: 0;
    padding: 0 0.25em 0.15em 0;
    border: 0 solid black;
    padding-bottom: 1px;
    text-align: left;
    vertical-align: top;
    padding-left: 0.5rem;
  }
`;
const Tab = styled.div`
  background: white;
  border: 1px solid #AAB;
  border-radius: 3px 3px 0 0;
  padding: .5rem;
  margin-right: 6px;
  z-index: 1;
  border-bottom: none;
  cursor: pointer;

  ${({active}) => active && css`
    border-bottom: 1px solid #D7DCED;
    background: #D7DCED;
    font-weight: bold;
    transform: translateY(1px);
    overflow: hidden;
  `};

  ${({highlighted}) => highlighted && css`
    // add yellow box shadow to bottom of box only (with blur radius)
    box-shadow: 0 -3px 2px 0 #FF0 inset;
  `};
`;
const TabContent = styled.div`
  display: ${({active}) => active ? 'block' : 'none'};
`;

const tabComponentIds = [
  'serum',
  'cerebrospinal',
  'blood',
  'urineBMI'
];

function highlightTerm(htmlString, term) {
  // Create a DOMParser to convert the string to a Document
  var parser = new DOMParser();
  var doc = parser.parseFromString(htmlString, 'text/html');

  // Recursive function to process text nodes
  function processNode(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      // Split the text node by the search term and reassemble with highlighted spans
      var parts = node.nodeValue.split(new RegExp(`(${term})`, 'gi'));
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].toLowerCase() === term.toLowerCase()) {
          var span = doc.createElement('span');
          span.className = 'highlight';
          span.textContent = parts[i];
          node.parentNode.insertBefore(span, node);
        } else {
          var textNode = doc.createTextNode(parts[i]);
          node.parentNode.insertBefore(textNode, node);
        }
      }
      node.parentNode.removeChild(node);
    } else {
      // Process child nodes
      for (var i = node.childNodes.length - 1; i >= 0; i--) {
        processNode(node.childNodes[i]);
      }
    }
  }

  // Start processing from the body
  processNode(doc.body);

  // Serialize back to a string
  return doc.body.innerHTML;
}

function highlightText(text, containerId, originalHTML) {
  const container = document.getElementById(containerId);

  if (!text) {
    container.innerHTML = originalHTML;
    return;
  };

  var highlightedHTML = highlightTerm(originalHTML, text);
  container.innerHTML = highlightedHTML;
  if (highlightedHTML != originalHTML) {
    return true;
  } 
  
}

const LabValues = ({active}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [highlightedTabs, setHighlightedTabs] = useState([]);
  const [showingReferenceIntervals, setShowingReferenceIntervals] = useState(false);

  const ref = useRef(null);
  const [search, setSearch] = useState('');

  const [originalHTML, setOriginalHTML] = useState('');

  function handleInputChange(e) {
    const value = e.target.value.trim();
    const highlightedTabs = []
    //highlight tabs that contain search term
    for (let i = 0; i < tabComponentIds.length; i++) {
      if (!value) continue;
      const tabId = tabComponentIds[i];
      const tabComponent = i === activeTab ? originalHTML : document.getElementById(tabId).innerHTML;
      if (highlightTerm(tabComponent, value) != tabComponent) {
        highlightedTabs.push(tabId)
      }
    }
    setHighlightedTabs(highlightedTabs);

    highlightText(value, tabComponentIds[activeTab], originalHTML);
    setSearch(value);
  }

  function handleTabChange(index) {
    // reset active tab before changing
    if (activeTab !== index) {
      const activeTabComponentId = tabComponentIds[activeTab];
      const activeTabComponent = document.getElementById(activeTabComponentId);
      activeTabComponent.innerHTML = originalHTML;
    }

    const tabId = tabComponentIds[index];
    const tabComponent = document.getElementById(tabId);

    setActiveTab(index);
    setOriginalHTML(tabComponent.innerHTML);

    highlightText(search, tabId, tabComponent.innerHTML);
  }

  useEffect(() => {
    handleTabChange(0);
  }, []);

  return (
    <Container active={active}>
      <Header>
        <Search>
          <input type={'text'} disabled={!originalHTML} placeholder={'search'} onChange={handleInputChange}/>
        </Search>
        <Toggle>
          <input type="checkbox" onClick={() => setShowingReferenceIntervals(!showingReferenceIntervals)}/>
          <label>SI Reference Intervals</label>
        </Toggle>
      </Header>
      <TabWrapper>
        <Tab highlighted={highlightedTabs.includes('serum')} onClick={() => handleTabChange(0)} active={activeTab === 0}>Serum</Tab>
        <Tab highlighted={highlightedTabs.includes('cerebrospinal')} onClick={() => handleTabChange(1)} active={activeTab === 1}>Cerebrospinal</Tab>
        <Tab highlighted={highlightedTabs.includes('blood')} onClick={() => handleTabChange(2)} active={activeTab === 2}>Blood</Tab>
        <Tab highlighted={highlightedTabs.includes('urineBMI')} onClick={() => handleTabChange(3)} active={activeTab === 3}>Urine BMI</Tab>
      </TabWrapper>
      <TableWrapper ref={ref} showingReferenceIntervals={showingReferenceIntervals}>
        <TabContent id={'serum'} active={activeTab === 0}>
          <Serum/>
        </TabContent>
        <TabContent id={'cerebrospinal'} active={activeTab === 1}>
          <Cerebrospinal/>
        </TabContent>
        <TabContent id={'blood'} active={activeTab === 2}>
          <Blood/>
        </TabContent>
        <TabContent id={'urineBMI'} active={activeTab === 3}>
          <UrineBMI/>
        </TabContent>
      </TableWrapper>
    </Container>
  );
};

export default LabValues;