import React from 'react';
import styled from 'styled-components';
import {Rnd} from 'react-rnd'
import {Times} from '@styled-icons/fa-solid/Times';

const BOTTOM_BAR_HEIGHT = 112.5;

const Wrapper = styled.div`
  z-index: 11;
  background: white;
  overflow: visible;
  border: 1px solid #ccc;
`;
const Image = styled.img.attrs({draggable: false})`
  width: 100%;
  height: auto;
`;

const CloseButton = styled(Times)`
  position: absolute;
  top: -23px;
  right: 0;
  width: 23px;
  height: 23px;
  text-align: center;
  background: red;
  color: white;
  cursor: pointer;
`;

const PeriodicTable = ({onCloseExhibit, template}) => (
    <Rnd
      default={{
        width: '66vw',
        height: 'auto',
        x: window.innerWidth / 2 - (window.innerWidth * 0.66) / 2,
        y: BOTTOM_BAR_HEIGHT
      }}
      minWidth={'15vw'}
      maxWidth={'120vw'}
      style={{zIndex: 10}}
      lockAspectRatio
    >
      <Wrapper>
        <CloseButton onClick={onCloseExhibit} />
        <Image src={`${process.env.PUBLIC_URL}/assets/periodic/periodic_table.png`} />
      </Wrapper>
    </Rnd>
);

export default PeriodicTable;
