import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';

import {Link} from 'react-router-dom';
import {Row, FlexBox} from '../../../components/Grid';
import {H3, H4} from '../../../components/Typography';
import {TableStyles} from '../../../bootcamps/pages/PastTestResults'

import {ChevronDown} from '@styled-icons/feather/ChevronDown';
import {ChevronUp} from '@styled-icons/feather/ChevronUp';
import {ChevronRight} from '@styled-icons/feather/ChevronRight';

import AnimateHeight from 'react-animate-height';

const HeadingLink = styled(Link)`
  text-decoration: none;
`;
const Heading = styled.div`
  cursor: not-allowed;
`;

const HeadingContainer = styled(FlexBox)`
  background: white;
  border-radius: 8px 8px 0px 0px;
  padding: 16px 24px;
  align-items: center;
  flex-direction: row;
  position: relative;
  z-index: 2;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-top: 16px;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 16px 16px 16px;
  }

`;

const ActionsWrapper = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  max-width: 150px;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
  }
`

const TextContent = styled.div`
  width: 100%;
  max-width: 630px;
`;

const Title = styled(H3)`
  margin-right: 16px;
`;

const Container = styled.div`
  margin-bottom: 16px;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  border-radius: 8px;
  &:first-child {
    margin-top: 0px;
  }
  transition: all .2s;

  ${({expanded}) => !expanded && css`
    &:hover {
      transition: all .2s;
      transform: scale(1.01);
    }
  `}
`;

const DropdownTrigger = styled.div`
  padding: 16px 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({theme}) => theme.colors.neutralsPalette.offWhite};
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  transition: all 200ms;

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding-left: 12px;
  }
`;

const DropdownTriggerText = styled(H4)`
  margin-left: 16px;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutralsPalette.grey};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-left: 8px;
  }
`;

const DropdownTriggerArrowUp = styled(ChevronUp).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.neutralsPalette.grey}
`;
const DropdownTriggerArrowDown = styled(ChevronDown).attrs(props => ({size: 24}))`
  color: ${({theme}) => theme.colors.neutralsPalette.grey}
`;

const ContentWrapper = styled(Row)`
  align-items: center;

  ${({theme}) => theme.mediaQueries.mobileL} {
    align-items: flex-start;
    margin-right: 16px;
  }
`

const ChapterIndex = styled(H3)`
  align-self: flex-start;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-right: 16px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-right: 16px;
  }
`;

const DropdownContainer = styled(TableStyles)`
  width: 100%;
  min-height: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  padding: 0;

  th {
    display: none;
  }

  td {
    &:last-child {
      padding-right: ${({theme}) => theme.layouts.spacing.l};
    }
  }
`;

const Dropdown = ({componentRef, index, name, route, actions, locked, defaultLocked, showText, hideText, children, textContent, expandOnChange}) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (expandOnChange && !expanded) setExpanded(true);
    if (!expandOnChange && expanded) setExpanded(false);
  }, [expandOnChange]);
  const HeadingWrapper = (locked || defaultLocked || !route) ? Heading : HeadingLink;

  const headingProps = route ? {to: route} : {onClick: () => setExpanded(!expanded), style: {cursor: 'pointer'}}
  return (
    <Container ref={componentRef} expanded={expanded}>
      <HeadingWrapper {...headingProps}>
        <HeadingContainer expanded={expanded}>
          <ContentWrapper>
            <ChapterIndex>
              {index}
            </ChapterIndex>
            <TextContent>
              <Title>{name}</Title>
              {textContent}
            </TextContent>
          </ContentWrapper>
          <ActionsWrapper children={actions} />
        </HeadingContainer>
      </HeadingWrapper>
      <AnimateHeight
        id={`dropdown-${index}-${name}`}
        duration={ 500 }
        height={ expanded ? 'auto' : 0 }
      >
        <DropdownContainer>
          {children}
        </DropdownContainer>
      </AnimateHeight>
      <DropdownTrigger
        onClick={() => setExpanded(!expanded)}
        aria-expanded={ expanded }
        aria-controls={`dropdown-${index}-${name}`}
      >
        {expanded ? <DropdownTriggerArrowUp /> : <DropdownTriggerArrowDown />}
        <DropdownTriggerText>
          {expanded ? hideText : showText}
        </DropdownTriggerText>
      </DropdownTrigger>
    </Container>
  );
}

Dropdown.defaultProps = {
  actions: [<ChevronRight size={32}/>],
  children: () => true,
  showText: 'Show',
  hideText: 'Hide',
}

export default Dropdown;
