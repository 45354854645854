import React from 'react';
import PropTypes from 'prop-types';

import {FlexBox, Column, Row} from '../../../components/Grid';
import {H1, Body2} from '../../../components/Typography';
import ProgressBar from '../../../components/ProgressBar';
import {ExpertEducators} from '../../../components/ExpertEducators';
import {LinkButton, Button} from '../../../components/Branding/Buttons';

import {Tag} from '@styled-icons/heroicons-outline/Tag';

import styled, {css} from 'styled-components';
import {useModalContext} from '../../../contexts/Modal';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {DefaultButton} from '@bootcamp/web/src/components/UI';
import {VideoClipMultiple} from '@styled-icons/fluentui-system-filled/VideoClipMultiple';
import {TaskListSquareLtr} from '@styled-icons/fluentui-system-filled/TaskListSquareLtr';
import {Clock} from '@styled-icons/fluentui-system-filled/Clock';

const Container = styled(FlexBox)`
  background: ${({theme}) => theme.colors.special.naturalDark};
  border: .5px solid ${({theme}) => theme.overlays.opacity.dark._100};
  box-shadow: ${({theme}) => theme.elevation.inner.progressLight};
  justify-content: space-between;
  border-radius: 8px;
  padding: 48px ${({theme}) => theme.layouts.spacing.xxl};
  height: auto;

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: column;
    flex-wrap: wrap;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 48px;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    padding: 32px 24px;
  }
`;
const Left = styled(Column)`
  flex: 1;
  /* ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
  } */
  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: 0px;
  }
`;
const Right = styled(Column)`
  max-width: 240px;
  justify-content: space-between;
  height: auto;
  align-self: stretch;
`;
export const SubTitle = styled(H1)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({theme}) => theme.mediaQueries.tablet} {
    display: block;
    font-size: 28px;
    line-height: 33.6px;
    margin-bottom: 4px;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    line-height: 1.125em;
  }
`;

const Progress = styled(ProgressBar)`
  align-items: flex-end;

  ${({theme}) => theme.mediaQueries.laptop} {
    margin-top: ${({theme}) => theme.layouts.spacing.l};
    align-items: flex-start;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: 0px;
    margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  }
`;

const ActionWrapper = styled(Row)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  > * {
    margin-right: ${({theme}) => theme.layouts.spacing.m}
  }
`;
const StudyButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StudyButton = styled(LinkButton)`
  width: auto;
  button {
    background: ${({theme}) => theme.overlays.opacity.light._100};
    border: 0.5px solid ${({theme}) => theme.overlays.opacity.light._100};
    border-radius: 36px;
  }

  margin-right: ${({theme}) => theme.layouts.spacing.l};

  div {
    background: ${({theme}) => theme.overlays.opacity.light._100};

    &:hover {
      background: ${({theme}) => theme.overlays.opacity.light._200};
    }
  }
`;
const CreateTestButton = styled(Button)`
  width: auto;
  button {
    background: ${({theme}) => theme.overlays.opacity.light._100};
    border: 0.5px solid ${({theme}) => theme.overlays.opacity.light._100};
    border-radius: 36px;
  }

  margin-right: ${({theme}) => theme.layouts.spacing.l};

  div {
    background: ${({theme}) => theme.overlays.opacity.light._100};

    &:hover {
      background: ${({theme}) => theme.overlays.opacity.light._200};
    }
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    display: none;
  }
`;
const StyledTag = styled(Tag)`
  color: white;
  width: auto;
`;
const StyledExpertEducators = styled(ExpertEducators)`
  width: auto;
  p {
    align-self: flex-start;
    ${({theme}) => theme.mediaQueries.tablet} {
      align-self: flex-end;
    }
    ${({theme}) => theme.mediaQueries.mobileL} {
      align-self: flex-start;
    }
  }
  div {
    ${({theme}) => theme.mediaQueries.mobileL} {
      justify-content: flex-start;
    }
  }
`;

const defaultProgress = {
  learning: 0,
  reviewing: 0,
  mastered: 0,
  total: 0,
};

const Body = styled(Body2)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  max-width: 750px;
  margin-bottom: 8px;
`
const UIButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 4px 8px;
  text-decoration: none;

  height: 24px;
  font-size: 14px;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-right: 8px;
  margin-bottom: 12px;
  cursor: default;
`;
const UIButtonText = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  ${({iconPosition, IconComponent}) => iconPosition && IconComponent && css`
    margin-${iconPosition}: 4px;
  `}
`;

const Chiclet = DefaultButton(UIButtonContainer, UIButtonText);

const Overview = ({
  title,
  subTitle,
  body,
  actions,
  questionCount,
  educators,
  showProgress=true,
  hasProgress,
  progress,
  className,
  progressLabel,
  showStudyTaggedQuestionsButton,
  showCreateTestButton,
  classroom,
  showingDuration,
  duration,
  showingTotalQuestionCount,
  totalQuestionCount,
  showingCourseCount,
  courseCount,
}) => {
  const {modalDispatch} = useModalContext();

  const {learning, reviewing, mastered, completed} = progress?.all || defaultProgress;

  const progressSegments = completed
    ? [
        {count: completed.count, color: completed.color}
      ]
    : [
        {count: mastered},
        {count: reviewing},
        {count: learning}
      ];

  return (
    <Container className={className}>
      <Left>
        <SubTitle>
          {subTitle}
        </SubTitle>
        {body && <Body dangerouslySetInnerHTML={{__html: body}}/>}
        {!!actions.length && <ActionWrapper children={actions}/>}
        <Row>
          {showingTotalQuestionCount && (
            <Chiclet iconPosition={'left'} IconComponent={<TaskListSquareLtr size={16} />} loading={!totalQuestionCount}>
              {totalQuestionCount} questions
            </Chiclet>
          )}
          {showingCourseCount && (
            <Chiclet iconPosition={'left'} IconComponent={<VideoClipMultiple size={16} />} loading={!courseCount || !duration}>
              {courseCount} lessons ({duration?.trim()})
            </Chiclet>
          )}
        </Row>
        {classroom && (
          <StudyButtonWrapper>
            {showCreateTestButton && (
              <CreateTestButton
                onClick={() => {
                  modalDispatch({
                    type: 'open',
                    component: CreateTest,
                    componentProps: {defaults: {subject: subTitle}},
                    enableClickClose: true,
                  })
                }}
                type={'secondary'}
                size={'small'}
                iconConfig={{
                  position: 'left',
                  icon: StyledTag,
                  size: 16,
                }}
              >
                Create Test
              </CreateTestButton>
            )}
            {/* {showStudyTaggedQuestionsButton &&
              <StudyButton
                to={`tagged-questions/all`}
                type={'secondary'}
                size={'small'}
                disabled={!hasProgress}
                iconConfig={{
                  position: 'left',
                  icon: StyledTag,
                  size: 16,
                }}
              >
                Review Tagged Questions
              </StudyButton>} */}
            {!!educators.length &&
              <StyledExpertEducators
                educators={educators}
                ledByTextOrientation={'column'}
                ledByOrientation={'column'}
                lightMode
              />
            }
          </StudyButtonWrapper>
        )}
      </Left>
      {showProgress &&
        <Right>
          <Progress
            loading={!hasProgress}
            segments={progressSegments}
            total={questionCount}
            label={progressLabel}
          />
        </Right>
      }
    </Container>
  );
}

Overview.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  body: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node)
};
Overview.defaultProps = {
  title: 'Classroom',
  subTitle: 'Upper & Lower Limbs',
  body: 'Start by watching the lesson video and download the PDF outline. Qbanks start at the superficial level and proceed to deeper structures.',
  actions: []
};

export default Overview;
