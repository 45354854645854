import styled from 'styled-components';

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Column = styled(FlexBox)`
  flex-direction: column;
`;

export const Row = styled(FlexBox)`
  flex-direction: row;
`;
