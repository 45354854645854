/* eslint-disable */
module.exports = jQuery => jQuery(document).ready(function(jQuery) {

  var COLUMNS, Cube, HEIGHT, LAYER_X, LAYER_Y, PADDING, PUZZLE_X, PUZZLE_Y, Page, Point, ROWS, Structure, answerGrades, answerValues, clearGrades, clearLayers, clearPuzzle, clearRadios, currentPage, erasePage, gameReset, init, layerIds, newPage, numberWord, pagePosition, previousPage, questionIds, questionText, radioChangeEvent, randomInt, restoreCurrentPage, revealExplication, sanitize, sanitizeCanvases, screenLine, screenMove, serializedPages, setPageNumber, startTimer, tallyScoreboard, timeChangeEvent, timeLeft, timeOut, timeReset, timer, timerOn;

  timerOn = false;

  timeLeft = 60;

  timer = {};

  serializedPages = [];

  pagePosition = 0;

  currentPage = {};

  answerGrades = ["grade-A", "grade-B", "grade-C", "grade-D", "grade-E"];

  answerValues = ["A", "B", "C", "D", "E"];

  layerIds = ["layer-1", "layer-2", "layer-3", "layer-4"];

  questionIds = ["amount-1", "amount-2", "amount-3", "amount-4", "amount-5"];

  PADDING = 3;

  PUZZLE_X = 284;

  PUZZLE_Y = 236;

  LAYER_X = 140;

  LAYER_Y = 100;

  ROWS = 5;

  COLUMNS = 5;

  HEIGHT = 4;

  var difficulty = 0;

  init = function() {
    var rad, rd, _i, _len, _results;
    console.log("init...");
    jQuery('#setdifficulty').change(function() {
        difficulty = (parseInt(jQuery(this).val()));
        console.log("Changed difficulty to: " + difficulty);
        setDifficulty();
        gameReset();
        // $('#next').click();
    });
    sanitizeCanvases();
    newPage();
    document.getElementById("next").onclick = newPage;
    document.getElementById("previous").onclick = previousPage;
    document.getElementById("time-button").onclick = timeChangeEvent;
    document.getElementById("game-reset").onclick = gameReset;
    rad = document.getElementsByName("answer");
    _results = [];
    for (_i = 0, _len = rad.length; _i < _len; _i++) {
      rd = rad[_i];
      _results.push(rd.onclick = radioChangeEvent);
    }
    return _results;
  };

  window.onload = init;

/*
 * Change difficulty controls
 */
function setDifficulty() {
    if (difficulty === 0) {
        ROWS = 5;
        COLUMNS = 5;
        HEIGHT = 4;
    } else {
        ROWS = 3;
        COLUMNS = 3;
        HEIGHT = 3;
    }
}

  newPage = function() {
    setDifficulty();
    pagePosition++;
    if (pagePosition > serializedPages.length) {
      if (pagePosition === 1 || serializedPages[pagePosition - 2].structure.paintQueries.length >= 3) {
        currentPage = new Page(new Structure());
      } else {
        currentPage = new Page(serializedPages[pagePosition - 2].structure);
      }
      serializedPages.push(currentPage);
      erasePage();
      currentPage.display();
      if (timerOn) {
        return startTimer();
      }
    } else {
      erasePage();
      return restoreCurrentPage();
    }
  };

  previousPage = function() {
    if (pagePosition > 1) {
      pagePosition--;
      erasePage();
      return restoreCurrentPage();
    }
  };

  revealExplication = function() {
    document.getElementById("solution").innerHTML = "Solution:";
    return currentPage.explicate();
  };

  timeChangeEvent = function() {
    timerOn = !timerOn;
    if (timerOn) {
      return startTimer();
    } else {
      clearInterval(timer);
      if (timeLeft === 0) {
        return timeReset();
      }
    }
  };

  startTimer = function() {
    clearInterval(timer);
    return timer = setInterval(function() {
      timeLeft--;
      document.getElementById("time-display").innerHTML = timeLeft;
      if (timeLeft < 1) {
        return timeOut();
      }
    }, 1000);
  };

  timeOut = function() {
    var rad;
    clearInterval(timer);
    rad = document.getElementsByName("answer");
    rad[currentPage.correctOption !== 0 ? 0 : 1].checked = true;
    return radioChangeEvent();
  };

  radioChangeEvent = function() {
    var radios, rd, _i, _len;
    clearInterval(timer);
    radios = document.getElementsByName('answer');
    for (_i = 0, _len = radios.length; _i < _len; _i++) {
      rd = radios[_i];
      if (rd.checked) {
        currentPage.answer = rd.value;
        break;
      }
    }
    clearGrades();
    document.getElementById(answerGrades[currentPage.correctOption]).innerHTML = '<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/155.png" style="width: 12px; padding-bottom: 6px;" />';
    if (answerValues[currentPage.correctOption] !== currentPage.answer) {
      document.getElementById('grade-' + currentPage.answer).innerHTML = '<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/361.png" style="width: 12px; padding-bottom: 6px;" />';
    }
    revealExplication();
    return tallyScoreboard();
  };

  tallyScoreboard = function() {
    var correct, incorrect, sp, _i, _len;
    incorrect = 0;
    correct = 0;
    for (_i = 0, _len = serializedPages.length; _i < _len; _i++) {
      sp = serializedPages[_i];
      if (sp.answer !== "z") {
        if (sp.answer === answerValues[sp.correctOption]) {
          correct++;
        } else {
          incorrect++;
        }
      }
    }
    document.getElementById('incorrect').innerHTML = incorrect;
    return document.getElementById('correct').innerHTML = correct;
  };

  setPageNumber = function() {
    document.getElementById("location").innerHTML = pagePosition;
    return document.getElementById("total").innerHTML = serializedPages.length;
  };

  restoreCurrentPage = function() {
    var radios, rd, _i, _len;
    currentPage = serializedPages[pagePosition - 1];
    if (timerOn && currentPage.answer === "z") {
      startTimer();
    }
    currentPage.display();
    if (currentPage.answer !== "z") {
      radios = document.getElementsByName('answer');
      for (_i = 0, _len = radios.length; _i < _len; _i++) {
        rd = radios[_i];
        if (rd.value === currentPage.answer) {
          rd.checked = true;
          break;
        }
      }
      document.getElementById(answerGrades[currentPage.correctOption]).innerHTML = '<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/155.png" style="width: 12px; padding-bottom: 6px;" />';
      if (answerValues[currentPage.correctOption] !== currentPage.answer) {
        return document.getElementById('grade-' + currentPage.answer).innerHTML = '<img src="https://d1o8b3a0nr7r5o.cloudfront.net/legacy/datbootcamp/2013/07/361.png" style="width: 12px; padding-bottom: 6px;" />';
      } else {
        return revealExplication();
      }
    }
  };

  randomInt = function(scale) {
    return Math.floor(Math.random() * scale);
  };

  numberWord = function(n) {
    switch (n) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
    }
  };

  questionText = function(n) {
    if (n === 1) {
      return "1 cube";
    } else {
      return n + " cubes";
    }
  };

  screenLine = function(ctx, p, mult) {
    var x, y;
    x = Math.floor(p[0] * mult) + PADDING;
    y = Math.floor(p[1] * mult) + PADDING;
    return ctx.lineTo(x, y);
  };

  screenMove = function(ctx, p, mult) {
    var x, y;
    x = Math.floor(p[0] * mult) + PADDING;
    y = Math.floor(p[1] * mult) + PADDING;
    return ctx.moveTo(x, y);
  };

  Point = (function() {
    function Point(x, y, z) {
      this.x = x;
      this.y = y;
      this.z = z;
    }

    Point.prototype.screenPoint = function(view, xOff, yOff) {
      var deltaX, result;
      result = [];
      deltaX = this.z * 0.42;
      if (view >= 1) {
        deltaX = -deltaX + COLUMNS / 2;
      }
      result[0] = this.x - deltaX + xOff;
      result[1] = this.y - this.z * 0.38 + yOff;
      return result;
    };

    return Point;

  })();

  Cube = (function() {
    function Cube(x, y, z) {
      this.x = x;
      this.y = y;
      this.z = z;
      this.painted = 5;
    }

    Cube.prototype.print = function(ctx, mult, view, color, xOff, yOff) {
      ctx.fillStyle = color;
      this.fill(ctx, mult, view, xOff, yOff);
      return this.outline(ctx, mult, view, xOff, yOff);
    };

    Cube.prototype.fill = function(ctx, mult, view, xOff, yOff) {
      ctx.beginPath();
      screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
      ctx.closePath();
      ctx.fill();
      ctx.beginPath();
      screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
      ctx.closePath();
      ctx.fill();
      if (view === 0) {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.closePath();
        return ctx.fill();
      } else {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        ctx.closePath();
        return ctx.fill();
      }
    };

    Cube.prototype.outline = function(ctx, mult, view, xOff, yOff) {
      ctx.lineCap = "round";
      ctx.lineWidth = 1;
      ctx.beginPath();
      screenMove(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      ctx.beginPath();
      screenMove(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      ctx.beginPath();
      screenMove(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      ctx.beginPath();
      screenMove(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      ctx.beginPath();
      screenMove(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      if (view === 0) {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
      } else {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
        ctx.stroke();
      }
      ctx.beginPath();
      screenMove(ctx, new Point(this.x + 1, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x + 1, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      ctx.beginPath();
      screenMove(ctx, new Point(this.x, this.y, this.z + 1).screenPoint(view, xOff, yOff), mult);
      screenLine(ctx, new Point(this.x, this.y, this.z).screenPoint(view, xOff, yOff), mult);
      ctx.stroke();
      if (view === 0) {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
        return ctx.stroke();
      } else {
        ctx.beginPath();
        screenMove(ctx, new Point(this.x + 1, this.y + 1, this.z).screenPoint(view, xOff, yOff), mult);
        screenLine(ctx, new Point(this.x + 1, this.y + 1, this.z + 1).screenPoint(view, xOff, yOff), mult);
        return ctx.stroke();
      }
    };

    return Cube;

  })();

  Structure = (function() {
    function Structure() {
      this.xOff = 0;
      this.yOff = 0;
      this.perspective = randomInt(2);
      this.paintQueries = [];
      while (true) {
        this.cubes = [];
        this.seed();
        this.createPattern();
        if (this.visible()) {
          break;
        }
      }
      this.scale();
      this.tallySides();
      this.order();
    }

    Structure.prototype.visible = function() {
      var cb, front, h, i, j, lip, obstuctColumn, side, wall, _i, _j, _k, _len, _ref, _ref1, _ref2;
      for (i = _i = 0, _ref = COLUMNS - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        for (j = _j = 0, _ref1 = ROWS - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
          h = this.height(i, j);
          if (h < HEIGHT) {
            _ref2 = this.cubes;
            for (_k = 0, _len = _ref2.length; _k < _len; _k++) {
              cb = _ref2[_k];
              if (cb.y < h && cb.z <= j) {
                if (this.perspective === 0 && i - cb.x === 1) {
                  return false;
                }
                if (this.perspective === 1 && cb.x - i === 1) {
                  return false;
                }
              }
            }
          }
          front = this.height(i, j - 1);
          if (front <= h && front < HEIGHT) {
            side = this.perspective === 0 ? this.height(i - 1, j) : this.height(i + 1, j);
            if (side <= h && side < HEIGHT) {
              lip = side > front ? side : front;
              wall = this.height(i, j + 1);
              if (wall <= lip) {
                if (this.height(i, j - 1) < lip || this.height(i, j - 2) < lip) {
                  obstuctColumn = this.perspective === 0 ? i - 1 : i + 1;
                  if (this.height(obstuctColumn, j - 1) >= lip && this.height(obstuctColumn, j - 2) >= lip) {
                    return false;
                  }
                }
              }
            }
          }
        }
      }
      return true;
    };

    Structure.prototype.height = function(x, z) {
      var cb, h, _i, _len, _ref;
      h = HEIGHT;
      _ref = this.cubes;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        cb = _ref[_i];
        if ((cb.x === x && cb.z === z) && (cb.y < h)) {
          h = cb.y;
        }
      }
      return h;
    };

    Structure.prototype.tallySides = function() {
      var cb1, cb2, div, _i, _len, _ref, _results;
      _ref = this.cubes;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        cb1 = _ref[_i];
        _results.push((function() {
          var _j, _len1, _ref1, _results1;
          _ref1 = this.cubes;
          _results1 = [];
          for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
            cb2 = _ref1[_j];
            if (cb2 !== cb1) {
              if (cb1.x === cb2.x && cb1.y === cb2.y) {
                div = cb1.z - cb2.z;
                if (div === 1) {
                  _results1.push(cb1.painted--);
                } else if (div === -1) {
                  _results1.push(cb1.painted--);
                } else {
                  _results1.push(void 0);
                }
              } else if (cb1.x === cb2.x && cb1.z === cb2.z) {
                div = cb1.y - cb2.y;
                if (div === 1) {
                  _results1.push(cb1.painted--);
                } else {
                  _results1.push(void 0);
                }
              } else if (cb1.z === cb2.z && cb1.y === cb2.y) {
                div = cb1.x - cb2.x;
                if (div === 1) {
                  _results1.push(cb1.painted--);
                } else if (div === -1) {
                  _results1.push(cb1.painted--);
                } else {
                  _results1.push(void 0);
                }
              } else {
                _results1.push(void 0);
              }
            }
          }
          return _results1;
        }).call(this));
      }
      return _results;
    };

    Structure.prototype.push = function(cube) {
      return this.cubes.push(cube);
    };

    Structure.prototype.printCubes = function(ctx) {
      var cb, _i, _len, _ref, _results;
      _ref = this.cubes;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        cb = _ref[_i];
        _results.push(cb.print(ctx, this.multiplier, this.perspective, "white", this.xOff, this.yOff));
      }
      return _results;
    };

    Structure.prototype.order = function() {
      var cb, hldr, holder, holder2, i, j, result, _i, _j, _k, _l, _len, _len1, _ref, _ref1, _ref2, _ref3;
      result = [];
      holder = [];
      for (i = _i = 0, _ref = HEIGHT - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        holder.push([]);
      }
      _ref1 = this.cubes;
      for (_j = 0, _len = _ref1.length; _j < _len; _j++) {
        cb = _ref1[_j];
        holder[cb.y].push(cb);
      }
      i = HEIGHT - 1;
      while (i >= 0) {
        holder2 = [];
        for (j = _k = 0, _ref2 = ROWS - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; j = 0 <= _ref2 ? ++_k : --_k) {
          holder2.push([]);
        }
        _ref3 = holder[i];
        for (_l = 0, _len1 = _ref3.length; _l < _len1; _l++) {
          hldr = _ref3[_l];
          holder2[hldr.z].push(hldr);
        }
        j = ROWS - 1;
        while (j >= 0) {
          if (this.perspective === 0) {
            holder2[j].sort(function(a, b) {
              return b.x - a.x;
            });
          } else {
            holder2[j].sort(function(a, b) {
              return a.x - b.x;
            });
          }
          result = result.concat(holder2[j]);
          j--;
        }
        i--;
      }
      return this.cubes = result;
    };

    Structure.prototype.seed = function() {
      var x, z;
      x = randomInt(COLUMNS);
      z = randomInt(ROWS);
      return this.push(new Cube(x, HEIGHT - 1, z));
    };

    Structure.prototype.scale = function() {
      var b, bottom, cube, l, left, r, right, t, top, xScale, yScale, _i, _len, _ref;
      top = 10000;
      bottom = -10000;
      right = -10000;
      left = 10000;
      _ref = this.cubes;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        cube = _ref[_i];
        t = new Point(cube.x, cube.y, cube.z + 1).screenPoint(this.perspective, this.xOff, this.yOff)[1];
        if (t < top) {
          top = t;
        }
        b = new Point(cube.x, cube.y + 1, cube.z).screenPoint(this.perspective, this.xOff, this.yOff)[1];
        if (b > bottom) {
          bottom = b;
        }
        if (this.perspective === 0) {
          r = new Point(cube.x + 1, cube.y, cube.z).screenPoint(this.perspective, this.xOff, this.yOff)[0];
          if (r > right) {
            right = r;
          }
          l = new Point(cube.x, cube.y, cube.z + 1).screenPoint(this.perspective, this.xOff, this.yOff)[0];
          if (l < left) {
            left = l;
          }
        } else {
          r = new Point(cube.x + 1, cube.y, cube.z + 1).screenPoint(this.perspective, this.xOff, this.yOff)[0];
          if (r > right) {
            right = r;
          }
          l = new Point(cube.x, cube.y, cube.z).screenPoint(this.perspective, this.xOff, this.yOff)[0];
          if (l < left) {
            left = l;
          }
        }
      }
      xScale = LAYER_X / (right - left);
      yScale = LAYER_Y / (bottom - top);
      if (xScale > yScale) {
        this.solutionScale = LAYER_Y / (bottom - top);
      } else {
        this.solutionScale = LAYER_X / (right - left);
      }
      this.multiplier = 40;
      this.xOff = 0 - left;
      return this.yOff = 0 - top;
    };

    Structure.prototype.createPattern = function() {
      var a, cb, iter, j, upperCubes, valid, x, z, _i, _j, _len, _len1, _ref, _ref1;
      upperCubes = [];
      while (true) {
        a = randomInt(this.cubes.length);
        x = this.cubes[a].x;
        z = this.cubes[a].z;
        switch (randomInt(4)) {
          case 0:
            x--;
            break;
          case 1:
            x++;
            break;
          case 2:
            z++;
            break;
          default:
            z--;
        }
        if (x >= COLUMNS || x < 0 || z >= ROWS || z < 0) {
          continue;
        }
        valid = true;
        _ref = this.cubes;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          cb = _ref[_i];
          if (x === cb.x && z === cb.z) {
            valid = false;
            break;
          }
        }
        if (valid) {
          this.push(new Cube(x, HEIGHT - 1, z));
        }
        if (!(this.cubes.length < (5 + randomInt(4) + randomInt(4) + randomInt(4) + randomInt(4)))) {
          break;
        }
      }
      while (true) {
        upperCubes = [];
        _ref1 = this.cubes;
        for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
          cb = _ref1[_j];
          iter = randomInt(HEIGHT);
          x = cb.x;
          z = cb.z;
          j = 0;
          while (j < iter) {
            upperCubes.push(new Cube(x, HEIGHT - (2 + j), z));
            j++;
          }
        }
        if (!(upperCubes.length + this.cubes.length < 15 || upperCubes.length + this.cubes.length > 20)) {
          break;
        }
      }
      return this.cubes = this.cubes.concat(upperCubes);
    };

    Structure.prototype.testStructure = function() {
      var i, j, k, _i, _ref, _results;
      this.cubes = [];
      _results = [];
      for (i = _i = 0, _ref = COLUMNS - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
        _results.push((function() {
          var _j, _ref1, _results1;
          _results1 = [];
          for (j = _j = 0, _ref1 = HEIGHT - 1; 0 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 0 <= _ref1 ? ++_j : --_j) {
            _results1.push((function() {
              var _k, _ref2, _results2;
              _results2 = [];
              for (k = _k = 0, _ref2 = ROWS - 1; 0 <= _ref2 ? _k <= _ref2 : _k >= _ref2; k = 0 <= _ref2 ? ++_k : --_k) {
                _results2.push(this.push(new Cube(i, j, k)));
              }
              return _results2;
            }).call(this));
          }
          return _results1;
        }).call(this));
      }
      return _results;
    };

    return Structure;

  })();

  Page = (function() {
    function Page(structure) {
      this.structure = structure;
      this.answer = "z";
      this.options = [];
      this.paintQuery();
      this.sequentialQuestions();
    }

    Page.prototype.paintQuery = function() {
      var pq, valid, _i, _len, _ref;
      while (true) {
        valid = true;
        this.sidesQty = randomInt(5) + 1;
        _ref = this.structure.paintQueries;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          pq = _ref[_i];
          if (this.sidesQty === pq) {
            valid = false;
            break;
          }
        }
        if (!valid) {
          continue;
        }
        this.getTally();
        if (this.tally !== 0) {
          break;
        }
      }
      return this.structure.paintQueries.push(this.sidesQty);
    };

    Page.prototype.populateQuestions = function() {
      var candidate, history, hst, i, valid, _i, _len, _results;
      this.options.push(this.tally);
      history = [];
      i = 0;
      while (i < questionIds.length) {
        while (true) {
          candidate = randomInt(5) + 1;
          if (Math.random() < 0.5) {
            candidate = -candidate;
          }
          if (candidate + this.tally < 1 || candidate + this.tally > this.structure.cubes.length) {
            continue;
          }
          valid = true;
          for (_i = 0, _len = history.length; _i < _len; _i++) {
            hst = history[_i];
            if (candidate === hst) {
              valid = false;
              break;
            }
          }
          if (!valid) {
            continue;
          }
          history.push(candidate);
          this.options.push(this.tally + candidate);
          break;
        }
        i++;
      }
      this.options.sort(function(a, b) {
        return a - b;
      });
      i = 0;
      _results = [];
      while (i < questionIds.length) {
        if (this.options[i] === this.tally) {
          this.correctOption = i;
        }
        _results.push(i++);
      }
      return _results;
    };

    Page.prototype.sequentialQuestions = function() {
      var choices, i, _i, _results;
      choices = this.tally;
      if (choices > 5) {
        choices = 5;
      }
      this.correctOption = randomInt(choices);
      _results = [];
      for (i = _i = 0; _i <= 4; i = ++_i) {
        _results.push(this.options.push(this.tally - this.correctOption + i));
      }
      return _results;
    };

    Page.prototype.displayQuestions = function() {
      var i, _results;
      document.getElementById("query").innerHTML = numberWord(this.sidesQty);
      i = 0;
      _results = [];
      while (i < questionIds.length) {
        document.getElementById(questionIds[i]).innerHTML = questionText(this.options[i]);
        _results.push(i++);
      }
      return _results;
    };

    Page.prototype.getTally = function() {
      var count, sc, _i, _len, _ref;
      count = 0;
      _ref = this.structure.cubes;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        sc = _ref[_i];
        if (sc.painted === this.sidesQty) {
          count++;
        }
      }
      return this.tally = count;
    };

    Page.prototype.display = function() {
      var c, ctx;
      this.displayQuestions();
      c = document.getElementById('puzzle');
      ctx = c.getContext("2d");
      return this.structure.printCubes(ctx);
    };

    Page.prototype.explicate = function() {
      var c, color, ctx, i, sc, _i, _len, _ref, _results;
      i = 0;
      _results = [];
      while (i < layerIds.length) {
        c = document.getElementById(layerIds[i]);
        ctx = c.getContext("2d");
        _ref = this.structure.cubes;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          sc = _ref[_i];
          if (sc.y === i) {
            color = "white";
            if (sc.painted === this.sidesQty) {
              color = "#5F5";
            }
            sc.print(ctx, this.structure.solutionScale, this.structure.perspective, color, this.structure.xOff, this.structure.yOff);
          } else if (sc.y > i) {
            color = "#EEE";
            sc.print(ctx, this.structure.solutionScale, this.structure.perspective, color, this.structure.xOff, this.structure.yOff);
          }
        }
        _results.push(i++);
      }
      return _results;
    };

    return Page;

  })();

  gameReset = function() {
    timerOn = false;
    clearInterval(timer);
    serializedPages = [];
    pagePosition = 0;
    newPage();
    return tallyScoreboard();
  };

  timeReset = function() {
    timeLeft = 60;
    return document.getElementById("time-display").innerHTML = timeLeft;
  };

  erasePage = function() {
    document.getElementById("solution").innerHTML = "";
    clearGrades();
    clearLayers();
    clearPuzzle();
    clearRadios();
    clearInterval(timer);
    timeReset();
    return setPageNumber();
  };

  clearGrades = function() {
    var ag, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = answerGrades.length; _i < _len; _i++) {
      ag = answerGrades[_i];
      _results.push(document.getElementById(ag).innerHTML = '');
    }
    return _results;
  };

  clearLayers = function() {
    var c, ctx, li, x, y, _i, _len, _results;
    x = LAYER_X + PADDING * 2;
    y = LAYER_Y + PADDING * 2;
    _results = [];
    for (_i = 0, _len = layerIds.length; _i < _len; _i++) {
      li = layerIds[_i];
      c = document.getElementById(li);
      ctx = c.getContext("2d");
      _results.push(ctx.clearRect(0, 0, x, y));
    }
    return _results;
  };

  clearPuzzle = function() {
    var c, ctx, x, y;
    x = PUZZLE_X + PADDING * 2;
    y = PUZZLE_Y + PADDING * 2;
    c = document.getElementById('puzzle');
    ctx = c.getContext("2d");
    return ctx.clearRect(0, 0, x, y);
  };

  clearRadios = function() {
    var el, ele, _i, _len, _results;
    ele = document.getElementsByName("answer");
    _results = [];
    for (_i = 0, _len = ele.length; _i < _len; _i++) {
      el = ele[_i];
      _results.push(el.checked = false);
    }
    return _results;
  };

  sanitizeCanvases = function() {
    var c, li, x, y, _i, _len;
    x = LAYER_X + PADDING * 2;
    y = LAYER_Y + PADDING * 2;
    for (_i = 0, _len = layerIds.length; _i < _len; _i++) {
      li = layerIds[_i];
      c = document.getElementById(li);
      sanitize(c, x, y);
    }
    x = PUZZLE_X + PADDING * 2;
    y = PUZZLE_Y + PADDING * 2;
    c = document.getElementById('puzzle');
    return sanitize(c, x, y);
  };

  sanitize = function(can, x, y) {
    can.width = x;
    return can.height = y;
  };

});
