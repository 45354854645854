import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Carousel from '../../../Carousel';
import {preventDefault} from '../../helpers';
import {Column, Row} from '../../../Grid';

import {Button, IconButton} from '../../../Branding/Buttons';

import {useModalContext} from '../../../../contexts/Modal';
import {useUserDataContext} from '../../../../contexts/UserData';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';

import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';

import {datOutcomes} from './outcomes/dat';
import {oatOutcomes} from './outcomes/oat';
import {inbdeOutcomes} from './outcomes/inbde';
import {anatomyOutcomes} from './outcomes/anatomy';
import {nclexOutcomes} from './outcomes/nclex';

import {datFeatures} from './features/dat';
import {oatFeatures} from './features/oat';
import {inbdeFeatures} from './features/inbde';
import {anatomyFeatures, anatomyTrialFeatures} from './features/anatomy';
import {nclexFeatures} from './features/nclex';


const SuperContainer = styled(Column)`
  position: relative;
  max-width: 1152px;
  min-height: 640px;
  border-radius: 10px;
  line-height: normal;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.laptop} {
    height: auto;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    overflow-y: auto;
    height: 100%;
  }
`;
const UpgradeContainer = styled(Row)`
  position: relative;
  background: white;
  border-radius: 10px;

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: column;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: ${({theme}) => theme.layouts.spacing.xxl};
  }
`;

const UpgradeSection = styled(Column)`
  justify-content: center;
  padding: ${({theme}) => theme.layouts.spacing.xl};
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;

  ${({theme}) => theme.mediaQueries.laptop} {
    border-radius: 10px 10px 0px 0px;
    justify-content: flex-start;
    height: auto;
    padding: ${({theme}) => theme.layouts.spacing.xxl};
    flex-basis: auto;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0;
    height: auto;
  }
`;

export const FeatureSection = styled(UpgradeSection)`
  overflow: auto;
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  order: 2;
  border-radius: 0px 10px 10px 0px;

  ${({theme}) => theme.mediaQueries.laptop} {
    overflow-y: initial;
    order: 0;
    border-radius: 0px 0px 10px 10px;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    overflow: auto;
    order: 1;
    padding-bottom: 130px;
  }
`;

const GradientWrapper = styled.div`
  background: ${({theme}) => theme.colors.brandPalette.royal.gradient};
  width: 100%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;

  ${({theme}) => theme.mediaQueries.laptop} {
    border-radius: 10px 10px 0px 0px;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: flex;
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    align-items: center;
    height: auto;
  }
`;

const OutcomeSection = styled(UpgradeSection)`
  background: linear-gradient(121.4deg, rgba(14, 20, 24, 0) 0%, rgba(14, 20, 23, 0.6) 100%);
  order: 1;
  border-radius: 10px 0px 0px 10px;

  ${({theme}) => theme.mediaQueries.laptop} {
    border-radius: 10px 10px 0px 0px;
    overflow: initial;
    gap: 8px;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    display: none;
    border-radius: 10px 10px 0px 0px;
    order: 2;
    background: ${({theme}) => theme.colors.neutralsPalette.white};
  }
`;

const ButtonContainer = styled(Row)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;

  ${({theme}) => theme.mediaQueries.laptop} {
    border-radius: 0px 0px 10px 10px;
    padding: ${({theme}) => theme.layouts.spacing.l};
    z-index: 2;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    position: fixed;
    bottom: 10%;
    left: 10%;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0px;
    padding-bottom: ${({theme}) => theme.layouts.spacing.m};
    padding-top: ${({theme}) => theme.layouts.spacing.xxl};
    background: ${({theme}) => theme.colors.fade.white};
  }
`;

const CloseButton = styled(IconButton)`
  visibility: hidden;
  z-index: 2;
  position: absolute;
  top: -32px;
  right: -32px;
  opacity: 0;
  transition: none;

  ${({visible}) => visible && `
    opacity: 1;
  `}

  ${({theme}) => theme.mediaQueries.tablet} {
    visibility: initial;
    color: white;
    top: 14px;
    right: -32px;
    ${({visible}) => visible && `
      right: 16px;
    `}
  }
`;
const CancelButton = styled(Button)`
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;

const upgradeDisplayConfig = {
  dat: {
    features: datFeatures,
    outcomes: datOutcomes,
  },
  oat: {
    features: oatFeatures,
    outcomes: oatOutcomes,
  },
  inbde: {
    features: inbdeFeatures,
    outcomes: inbdeOutcomes,
  },
  anatomy: {
    features: anatomyFeatures,
    outcomes: anatomyOutcomes,
    trialFeatures: anatomyTrialFeatures
  },
  nclex: {
    features: nclexFeatures,
    outcomes: nclexOutcomes,
  },
};

const Upgrade = () => {
  const {bootcamp, trialMembership} = useUserDataContext();
  const [visible, setVisible] = useState(false);
  const [{isLaptop, isTablet}, setBreakpoints] = useState({isLaptop: document.documentElement.clientWidth < 1024, isTablet: document.documentElement.clientWidth < 768});

  const updateBreakpoints = () => setBreakpoints({isLaptop: document.documentElement.clientWidth < 1024, isTablet: document.documentElement.clientWidth < 768});
  const {outcomes, features, trialFeatures} = upgradeDisplayConfig[bootcamp];

  useEffect(() => {
    setVisible(true);
    window.addEventListener('resize', updateBreakpoints);
    return () => window.removeEventListener('resize', updateBreakpoints);
  }, []);

  const {modalDispatch} = useModalContext();

  return (
    <SuperContainer>
      <CloseButton
        type={isTablet ? 'secondary' : 'primary'}
        size={isTablet ? 'small' : 'large'}
        onClick={() => modalDispatch({type: 'close'})} visible={visible}
      >
        <X size={32}/>
      </CloseButton>
      <UpgradeContainer onClick={preventDefault} visible={visible}>
        <GradientWrapper>
          <OutcomeSection>
            <Carousel children={outcomes} />

          </OutcomeSection>
        </GradientWrapper>
        <FeatureSection>
          {trialMembership && trialFeatures ? trialFeatures : features}
          <ButtonContainer>
            <CancelButton
              children={"I'm Not Ready, Yet"}
              size={'large'}
              type={'ghost'}
              fixed={true}
              onClick={() => modalDispatch({type: 'close'})}
              round
            />
            <Button
              children={'Upgrade Your Membership'}
              color={'royal'}
              size={'large'}
              fixed={true}
              onClick={() => window.open(`https://bootcamp.com/${bootcamp}/upgrade`, '_blank')}
              round
              iconConfig={{
                position: 'left',
                icon: Badge,
                size: 12,
              }}
            />
          </ButtonContainer>
        </FeatureSection>
      </UpgradeContainer>

    </SuperContainer>
  );
}

export default Upgrade;
