import React, {useState} from 'react';
import styled, {css} from 'styled-components';

import {ReactComponent as Flag} from '../assets/flag.svg';
import {ReactComponent as NavigationIcon} from '../assets/navigation.svg';

import {DraggablePopup} from '../../NCLEXQBank/shared';
import scrollArrow from '@bootcamp/web/src/components/MCATBank/assets/scroll-arrow.svg';
import scrollArrowUp from '@bootcamp/web/src/components/MCATBank/assets/scroll-arrow-up.svg';
import firstBy from 'thenby';

const StyledDraggablePopup = styled(DraggablePopup)`
  border-radius: 0px;
  border: 1px solid black;
  padding: 0px 8px;
  background: ${({theme}) => theme.colors.prometricPalette.mcat.dark};

  .handle {
    padding: 3px 8px;
  }

  .draggable-popup-footer {
    padding: 9.5px 8px;
  }
  * {
    font-family: Tahoma !important;
  }
  max-width: 1000px;
`;
const Container = styled.div`
  overflow-y: auto;
  font-family: Verdana;
  ::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.50) inset;
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.prometricPalette.mcat.light};
  }

  /* Buttons */
  ::-webkit-scrollbar-button:start:decrement {
    display: block;
    background-image: url(${scrollArrowUp});
    background-color: ${({theme}) => theme.colors.prometricPalette.mcat.light};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
  }
  ::-webkit-scrollbar-button:start:increment {
    height: 1px;
  }
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    background-image: url(${scrollArrow});
    background-color: ${({theme}) => theme.colors.prometricPalette.mcat.light};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
  }
  ::-webkit-scrollbar-button:end:decrement {
    height: 1px;
  }
`;
const Wrapper = styled.div`
  height: 100%;
`;
const Header = styled.div`
  font-size: 19px;
  font-family: Verdana;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    height: 23px;
  }
`;
const Footer = styled(Header)`
  font-size: 16px;
`;
const NavigatorTableHeader = styled.div`
  display: flex;
  align-content: center;
  /* nclex/ui-light-blue */

  background: ${({theme}) => theme.colors.prometricPalette.mcat.light};
  /* nclex/ui-dark-blue */

  border-bottom: 0.5px solid #006DAA;
`;
const NavigatorTableHeaderCell = styled.div`
  width: 50%;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 16px;
  color: white;
  text-align: center;
  cursor: pointer;

  border-top: 1px solid white;

  &:nth-child(2) {
    border-left: 1px solid ${({theme}) => theme.colors.neutralsPalette.mcat.grey};
    border-right: 1px solid ${({theme}) => theme.colors.neutralsPalette.mcat.grey};
  }
`;
const NavigatorTable = styled.div`
  height: 100%;
`;
const NavigatorTableRow = styled.div`
  display: flex;
  background: ${({ even, current }) => current ? '#FFFF67' : even ? 'white' : '#EEEEEE'};
  &:hover {
  cursor: pointer;
  background: #FFFF67;
  }
  ${({ isSequentialSet }) => isSequentialSet && css`
  border-left: 5px solid #3385ff;
  `}
`;
const NavigatorTableRowCell = styled.div`
  width: 50%;
  padding: 0px 16px;
  height: 32px;
  display: flex;
  align-items: center;
  color: ${({isRed}) => !isRed ? '#404040' : '#E05E57'};
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.mcat.black};
`;
const StyledFlag = styled(Flag)`
  fill: white;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  path {
    stroke: ${({theme}) => theme.colors.prometricPalette.mcat.dark};
    fill: ${({theme}) => theme.colors.prometricPalette.mcat.dark};
  }
`;
const StyledNavigationIcon = styled(NavigationIcon)`
  width: 16px;
  height: 16px;
`;

const Navigator = ({quizProgress, isReadinessExam, updateQuestionProgressData, setCurrentIndex, close, alwaysShowingExplanation}) => {


  const [sortColumn, setSortColumn] = useState({column: 'index', direction: 'asc'});
  const sortFunction = sortColumn.column === 'index' ? firstBy('globalIndex', sortColumn.direction) : sortColumn.column === 'status' ? firstBy('didCheck', sortColumn.direction).thenBy('seen', sortColumn.direction).thenBy('globalIndex', 'asc') : firstBy('didMark', sortColumn.direction).thenBy('globalIndex', 'asc');

  const flatProgress = quizProgress
    ?.reduce((acc, {questions, isSequentialSet}, blockIndex) => [
      ...acc,
      ...(questions?.map((question, index) => ({...question, globalIndex: acc.length + index, isSequentialSet, blockIndex})) || [])
    ], [])
    ?.sort(sortFunction)
  const questionRows = flatProgress?.map((question, index) => (
    <NavigatorTableRow current={question.current} isSequentialSet={question.isSequentialSet} onClick={() => {
      !isReadinessExam && updateQuestionProgressData();
      setCurrentIndex(question.blockIndex, question.questionIndex);
      close();
    }} even={index % 2 === 0}>
      <NavigatorTableRowCell>
        Question {question.globalIndex + 1}
      </NavigatorTableRowCell>
      <NavigatorTableRowCell isRed={question.selectedAnswerIndex === -1}>
        {(question.selectedAnswerIndex > -1) ? 'Complete' : question.seen ? 'Incomplete' : 'Unseen'}
      </NavigatorTableRowCell>
      <NavigatorTableRowCell>
        {question.didMark && <StyledFlag/>}
      </NavigatorTableRowCell>
    </NavigatorTableRow>
  ))

  return (
    <StyledDraggablePopup
      close={close}
      headerContent={
        <Header>
          <span>
            <StyledNavigationIcon/>
          </span>
            <strong>Navigation</strong> - select a question to go to it
        </Header>
      }
      footerContent={
        <Footer>
          {alwaysShowingExplanation ? 0 : quizProgress?.reduce((acc, {questions}) => [...acc, ...(questions || [])], [])?.filter(({seen, selectedAnswerIndex}) => !seen || selectedAnswerIndex === -1)?.length} Unseen/Incomplete
        </Footer>
      }
    >
    <Container>
      <Wrapper>
        <NavigatorTableHeader>
            <NavigatorTableHeaderCell onClick={() => setSortColumn({column: 'index', direction: sortColumn.direction === 'asc' && sortColumn.column === 'index' ? 'desc' : 'asc'})}>Question #</NavigatorTableHeaderCell>
            <NavigatorTableHeaderCell onClick={() => setSortColumn({column: 'status', direction: sortColumn.direction === 'desc' && sortColumn.column === 'status' ? 'asc' : 'desc'})}>Status</NavigatorTableHeaderCell>
            <NavigatorTableHeaderCell onClick={() => setSortColumn({column: 'mark', direction: sortColumn.direction === 'desc' && sortColumn.column === 'mark' ? 'asc' : 'desc'})}>Flagged for Review</NavigatorTableHeaderCell>
        </NavigatorTableHeader>
        <NavigatorTable>
          {questionRows}
        </NavigatorTable>
      </Wrapper>
    </Container>
    </StyledDraggablePopup>
  );
};

Navigator.defaultProps = {};
Navigator.propTypes = {};

export default Navigator;