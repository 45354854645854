import React from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {H3, H5} from '@bootcamp/web/src/components/Typography';

import {formatDurationString} from '@bootcamp/shared/src/util';

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: transparent;
  text-decoration: none;
  box-shadow: ${({theme}) => theme.elevation.shadow.low};
  transition: all 100ms ease;
  height: ${({layoutType}) => layoutType === 'row' ? '242px' : '264px'};

  &:hover {
    transform: scale(1.01);
    box-shadow: ${({theme}) => theme.elevation.shadow.high};
  }

  ${({layoutType}) => layoutType === 'row' && css`
    min-width: 308px;
    margin-right: ${({theme}) => theme.layouts.spacing.m};
  `}

`;
const ThumbnailContainer = styled.div`
  background: url("${({vimeoId}) => `https://vercel-vimeo.vercel.app/api/vimeo?id=${vimeoId}&size=3`}");
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1;
`;
const Details = styled.div`
  width: 100%;
  padding: 12px 16px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 50.68%, #EBEBEB 100%);
  border-top: 1px solid #EDEDF0;
`;

const DetailsTitle = styled(H3)`
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const DetailsSubject = styled(H5)`
  color: #85868E;
`;

const Overlay = styled.div`
  /* background: linear-gradient(332.24deg, rgba(21, 26, 30, 0.1) 0%, rgba(0, 0, 0, 0) 53.57%), linear-gradient(180deg, rgba(17, 18, 20, 0) 51.49%, rgba(21, 26, 30, 0.1) 100%); */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const Duration = styled.div`
  background: black;
  opacity: .7;
  border-radius: 64px;
  font: proxima-nova;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: white;
  padding: 4px 8px;
  margin: auto 12px 12px auto;
`;

const Result = ({video, route, name, contentTypeName, layoutType='grid'}) => {
  const {id, duration} = JSON.parse(video || '{}');

  return (
    <ResultContainer as={Link} layoutType={layoutType} to={route}>
      <ThumbnailContainer vimeoId={id}>
        <Overlay>
          <Duration children={formatDurationString(duration)} />
        </Overlay>
      </ThumbnailContainer>
      <Details title={name}>
        <DetailsTitle>{name}</DetailsTitle>
        <DetailsSubject>{contentTypeName}</DetailsSubject>
      </Details>
    </ResultContainer>
  )
}

export default Result;
