import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import styled from 'styled-components';

import QBankCollectionCard from '../Cards/QBankCollection';
import CalloutCard from '../Cards/Callout';
import SearchBar from '../SearchBar';

const Container = styled.div`
`;

const QBankCollection = ({content, themePalette, data}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const urlParams = new URLSearchParams(window.location.search)
  const scrollToChapter = urlParams.get('subject');
  const selectedSection = urlParams.get('section')

  const history = useHistory();

  useEffect(() => {
    if (!searchTerm || !selectedSection) return;
    urlParams.set('section', 'all');
    history.replace({
      pathname: window.location.pathname,
      search: `?${urlParams}`
    });
  }, [searchTerm]);

  if (!content) return null;
  const lessonSets = content[0].content;

  const filteredLessonSets = lessonSets.reduce((acc, item, index) => {
    const {name, section, lessons} = item;

    if (lessons?.length === 0) return acc;
    if (searchTerm && !name.toLowerCase().includes(searchTerm.toLowerCase())) return acc;
    if (selectedSection && selectedSection !== 'all' && selectedSection !== section) return acc;

    return [
      ...acc,
      <QBankCollectionCard
        key={`${name}-${section}`}
        config={item}
        chapterIndex={item.index || index + 1}
        showProgress={false}
        themePalette={themePalette}
        saveBlockProgress={data.saveBlockProgress}
        qbankCollectionData={data.qbankCollectionData}
        searchTerm={searchTerm}
        scrollIntoView={item.route === scrollToChapter}
        showText={`Show ${lessons.length} question banks`}
        hideText={`Hide ${lessons.length} question banks`}
        // locked={index > 0 && !isUpgraded}
      />
    ];
  }, []);

  return (
    <Container>
      <SearchBar onChange={e => setSearchTerm(e.target.value)} value={searchTerm} placeholder={`Start typing to filter the question banks`}/>

      {filteredLessonSets}

      {filteredLessonSets.length === 0 && (
        <CalloutCard
          children={`Nothing found. Try searching for something else, or try using the search bar at the top of the page.`}
        />
      )}
    </Container>
  );
};

QBankCollection.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
QBankCollection.defaultProps = {
  content: [],
  data: {},
};

export default QBankCollection;
