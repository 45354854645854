import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

// import {useModalContext} from '../../../../contexts/Modal';

import { StyledBeginPracticeButton, StyledUpgradeButton, StyledResetButton } from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';
import {RoundButton} from '../../../../components/UI';
import {Row, Column, FlexBox} from '../../../../components/Grid';
import {H3, H4, H5, Label3} from '../../../../components/Typography';
import ProgressBar from '../../../../components/ProgressBar';
import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {Play} from '@styled-icons/heroicons-solid/Play';
import {CheckCircle} from '@styled-icons/material-rounded/CheckCircle';
import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {ResetButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';
import {deSlugify} from '@bootcamp/shared/src/util';


const IconArea = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 8px;

  ${({theme}) => theme.mediaQueries.mobileXl} {
    margin-top: 8px;
    width: 100%;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex: 1;

  ${({theme}) => theme.mediaQueries.mobileXl} {
    flex-direction: column;
  }
`;
const BadgeWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
`;
const StyledBadge = styled(Badge)`
  path {
    fill: ${({theme}) => theme.overlays.opacity.dark._400};
  }
`;
const DetailText = styled(H5)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  margin-left: auto;
  transition: all .3s;
`;
const PlayButtonIcon = styled(Play).attrs({size: 32})`
  color: ${({theme, themePalette}) => themePalette ? themePalette.default : theme.colors.brandPalette.violet.default};
  ${({theme}) => theme.mediaQueries.mobileXl} {
    width: 24px;
    height: 24px;
  }
`;
const RowContainer = styled(Row)`
  align-items: center;
  min-height: 79px;
  ${({mobileOnly}) => mobileOnly && css`
    display: none;
    ${({theme}) => theme.mediaQueries.mobileXl} {
      display: flex;
    }
  `}
`;
const RowLabel = styled(Label3)`
  color: ${({theme, themePalette}) => themePalette ? themePalette.dark : theme.colors.brandPalette.violet.dark};
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  display: none;
  ${({mobileOnly}) => mobileOnly && css`
    display: none !important;
    ${({theme}) => theme.mediaQueries.mobileXl} {
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 14px;
    }
  `}
`;
const RowTitleContainer = styled(Column)`
  width: 50%;
  margin-right: 16px;

  ${({theme}) => theme.mediaQueries.mobileXl} {
    width: 100%;
  }
`;
const RowTitle = styled(H4)`
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  ${({theme}) => theme.mediaQueries.mobileXl} {
    font-weight: 600;
    margin-top: ${({theme}) => theme.layouts.spacing.s};
  }
`;

const RowLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-bottom: .5px solid ${({theme}) => theme.colors.neutralsPalette.light};

  padding: ${({locked}) => locked ? '22px 34px' : '12px 34px'};
  padding-left: 14px;
  background: white;
  transition: all .2s;
  border-radius: 0px;

  ${({locked}) => locked && `
    &:hover {
      ${OptionContainer} {
        display: none;
      }
      ${BlockNumberContainer} {
        display: none;
      }
      ${RowTitleContainer} {
        display: none;
      }
      ${UpgradeOverlayContainer} {
        display: flex;
      }
      ${BadgeWrapper} {
        margin-left: auto;
      }
    }
  `}

  ${({theme}) => theme.mediaQueries.mobileXl} {
    padding: 12px 16px;
  }

  &:hover {
    transform: scale(1.01);
    transition: all .2s;
    border-radius: 8px;
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
    ${({dark}) => dark && css`
      background: ${({theme}) => theme.colors.neutralsPalette.light};
    `}
  }

  ${({dark}) => dark && css`
    background: ${({theme}) => theme.colors.neutralsPalette.light};
    border-bottom: .5px solid ${({theme}) => theme.overlays.opacity.dark._50};

    ${RowTitle} {
      color: ${({theme}) => theme.colors.neutralsPalette.dark};
    }
`}

  ${({active}) => active && css`
    padding: 28px 34px;
    padding-left: 14px;
    background: white;
    border-radius: 8px;
    transition: all .3s;
    box-shadow: ${({theme}) => theme.elevation.shadow.medium};

    ${({theme}) => theme.mediaQueries.mobileXl} {
      padding: 16px;
    }


    &:hover {
      background: white;
      box-shadow: ${({theme}) => theme.elevation.shadow.high};

      ${PlayButtonIcon} {
        transform: scale(1.05);
      }
    }

    ${BlockNumberContainer} {
      background: ${({theme, themePalette}) => themePalette ? themePalette.light : theme.colors.brandPalette.violet.light};
      min-width: 64px;
      min-height: 64px;
      border: 2px solid white;
      ${({theme}) => theme.mediaQueries.mobileXl} {
        min-width: 32px;
        min-height: 32px;
      }

      ${BlockNumber} {
        color: ${({theme, themePalette}) => themePalette ? themePalette.dark : theme.colors.brandPalette.violet.dark}
      }
    }
    ${RowLabel} {
      display: block;
      ${({theme}) => theme.mediaQueries.mobileXl} {
        display: block !important;
      }

    }
    ${RowTitle} {
      color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
    }
    ${OptionOuter} {
      border-color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    }
    ${PlayButtonIcon} {
      box-shadow: ${({theme}) => theme.elevation.shadow.medium};
      &:hover {
        background: ${({theme, themePalette}) => themePalette ? themePalette.hover : theme.colors.brandPalette.violet.hover};
      }
    }
    ${DetailText} {
      color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
    }
  `}
`;

const BlockNumberContainer = styled(FlexBox)`
  align-items: center;
  justify-content: center;
  width: auto;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  border-radius: 8px;
  ${({theme}) => theme.mediaQueries.mobileXl} {
    margin-right: ${({theme}) => theme.layouts.spacing.s};
  }
`;

const BlockNumber = styled(H3)`
  width: 22px;
  text-align: center;
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  ${({theme}) => theme.mediaQueries.mobileXl} {
    font-weight: 600;
  }
`;
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100px;
  margin-right: 8px;
  transition: all 100ms;
  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.light};
  }
  ${({theme}) => theme.mediaQueries.mobileXl} {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
    border: .5px solid ${({theme}) => theme.overlays.opacity.dark._50};
    padding: 4px;
    margin-right: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const OptionOuter = styled.div`
  min-width: 20px;
  min-height: 20px;
  padding: 1px;
  border-radius: 100px;
  border: 1px solid ${({theme}) => theme.overlays.opacity.dark._200};
  ${({theme}) => theme.mediaQueries.mobileXl} {
    min-width: 16px;
    min-height: 16px;
  }
`;
const OptionInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: none;
`;
const OptionChecked = styled(CheckCircle).attrs(props => ({size: 24}))`
  fill: ${({theme, themePalette}) => themePalette ? themePalette.default : theme.colors.brandPalette.violet.default};
  ${({theme}) => theme.mediaQueries.mobileXl} {
    height: 16px;
    width: 16px;
  }
`;

const Option = ({checked, onClick, themePalette}) => checked
  ? <OptionChecked onClick={onClick} themePalette={themePalette}/>
  : <OptionOuter onClick={onClick} children={<OptionInner onClick={onClick}/>}/>

const UpgradeOverlayContainer = styled.div`
  position: absolute;
  display: none;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.overlays.opacity.light._100};
  color: white;
  align-items: center;
  justify-content: center;
`;
const UpgradeOverlayText = styled(H3)`
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
`;
const UpgradeOverlay = () => (
  <UpgradeOverlayContainer>
    <UpgradeOverlayText>Upgrade to unlock</UpgradeOverlayText>
    <ArrowForwardOutline color={'#85868E'} size={20}/>
  </UpgradeOverlayContainer>
);

const Progress = styled(ProgressBar)`
  margin-top: 12px;
  width: 100%;
`;


const QBankRow = ({index, lesson, routeParent, test, blockProgress, mastery, saveBlockProgress, active, themePalette, locked, bootcamp, route}) => {
  /* const {modalDispatch} = useModalContext(); */
  const [resetInitialized, setResetInitialized] = useState({});
  const {status} = blockProgress || {};
  const title = lesson.type ? deSlugify(lesson.type) :  `Question Bank #${index+1}`;
  const number = index + 1

  const numQuestions = test?.config?.questionCount;
  const linkTo = locked
    ? `/${bootcamp}/upgrade`
    : lesson.type === 'Clinical Cases'
    ? `clinical-cases/${routeParent}/${lesson.route}`
    : lesson.type === 'application'
    ? `application/${routeParent}/${lesson.route}`
    : ['keyholes', 'tfe', 'pattern-folding'].includes(routeParent)
    ? `qbank-collection/${routeParent}/${lesson.route}`
    : lesson.type === 'lecture' || lesson.type === 'application' || lesson.route.includes('lecture')
    ? `lecture-style-questions/${routeParent}/${lesson.route}`
    : `identify-structures/${routeParent}/${lesson.route}`;

  const onClick = () => {
    // TODO: add handler for lockdown
    // modalDispatch({
    //   type: 'open',
    //   component: BootcampLockdown,
    //   enableClickClose: true,
    //   componentProps: {
    //     isPremiumFeature: true,
    //     showInteractions: false,
    //     config: bootcamp === 'anatomy' ? anatomyConfig : chemistryConfig
    //   },
    //   modalContainerStyle: theme => `
    //     background: ${theme.overlays.opacity.dark._300};
    //     padding: 10vh 10vw;
    //     align-items: flex-start;
    //     ${theme.mediaQueries.tablet} {
    //       padding: 0;
    //     }
    //   `
    // })
  };

  const onOptionClick = e => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    saveBlockProgress({status: status === 'complete' ? '' : 'complete'})
  };

  const progress = mastery?.reduce((acc, {masteryLevel}) => ({...acc, [masteryLevel]: acc[masteryLevel] + 1, total: acc.total + 1}), {
    learning: 0,
    reviewing: 0,
    mastered: 0,
    total: 0,
  });

  const {reviewing, learning, mastered} = progress || {};

  const progressSegments = [
    {count: mastered},
    {count: reviewing},
    {count: learning}
  ];

  return (
    <RowLink themePalette={themePalette} onClick={onClick} to={linkTo} active={active} dark={status === 'complete'} locked={locked}>
      <UpgradeOverlay/>
      <RowContainer>
        <OptionContainer onClick={onOptionClick}>
          <Option checked={status === 'complete'} themePalette={themePalette} />
        </OptionContainer>
        <BlockNumberContainer>
          <BlockNumber>{number}</BlockNumber>
        </BlockNumberContainer>
        <RowLabel themePalette={themePalette} mobileOnly>{index === 1 ? 'Get started' : 'Dive back in'}</RowLabel>
        <Wrapper>
          <RowTitleContainer>
            <RowLabel themePalette={themePalette}>{index === 1 ? 'Get started' : 'Dive back in'}</RowLabel>
            <RowTitle>{title}</RowTitle>
            <Progress
              loading={!mastery || !blockProgress}
              segments={progressSegments}
              total={numQuestions}
              lightMode
            />
          </RowTitleContainer>
          {locked
            ? (
              <IconArea>
                {numQuestions && <DetailText>{numQuestions} question{numQuestions !== 1 ? 's' : ''}</DetailText>}
                <BadgeWrapper>
                  <StyledBadge/>
                </BadgeWrapper>
              </IconArea>
            )
            : (
              <IconArea>
                <StyledResetButton themePalette={themePalette} testId={lesson.testId || lesson.id} setInitialized={setResetInitialized}/>
                <StyledBeginPracticeButton hideOnMobile={!resetInitialized?.[lesson?.testId]} small colorConfig={themePalette}>Start</StyledBeginPracticeButton>
              </IconArea>
            )
          }
        </Wrapper>
      </RowContainer>
    </RowLink>
  )
}

export default QBankRow;
