import React, {useRef, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {H2} from '../../../Typography';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({theme}) => theme.layouts.spacing.xl} ${({theme}) => theme.layouts.spacing.m};
`;
const Wrapper = styled.div`
  max-width: 480px;
`;
const RawResourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.darkModeEnabled && css`
    color: white;
  `}
  div {
    margin: 0 !important;
  }
`;
const RawWrapper = styled.div`
  padding-left: ${({theme}) => theme.layouts.spacing.l};
`;
const Title = styled(H2)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
const ResourceContainer = styled.div`
  border: 1px solid ${({theme}) => theme.darkModeEnabled ? theme.colors.darkModePalette.borderPrimary : theme.colors.neutralsPalette.light};
  border-radius: 8px;
  margin-top: ${({theme}) => theme.layouts.spacing.m};
  padding: ${({theme}) => theme.layouts.spacing.xl};
`;

function parseHtml(html, bootcamp, validBootcamps) {
  // create dummy container so that we can look for data attributes
  const htmlContainer = document?.createElement('div');
  // set dummy container inner html
  htmlContainer.innerHTML = html;

  try {
    // modify anchor hrefs if necessary
    htmlContainer
      .querySelectorAll('a')
      .forEach(element => {

        // check for data attribute
        const shouldFormatHref = element?.dataset?.hasOwnProperty('useBootcamp');
        // check to make sure hrefs are pointing to the bootcamp we're currently on
        const hrefBootcamp = element?.href?.split('/')[3];
        const shouldRepointBootcamp = validBootcamps?.includes(hrefBootcamp) && hrefBootcamp !== bootcamp;

        // modify href if has 'useBootcamp' data attribute
        if (shouldFormatHref) {
          const url = new URL(element?.href);
          url.pathname = `/${bootcamp}${url.pathname}`;
          element.href = url.href;
        // modify href bootcamp needs to be repointed
        } else if (shouldRepointBootcamp) {
          const url = new URL(element?.href);
          url.pathname = url.pathname.replace(hrefBootcamp, bootcamp);
          element.href = url.href;
        }
      })

    return htmlContainer;
  } catch (error) {
    return htmlContainer;
  }
}

const TextBlock = ({html, title, raw}) => {
  const {bootcamp, validBootcamps} = useUserDataContext();
  const [parsedHtml, setParsedHtml] = useState(null);
  const ref = useRef();

  useEffect(() => {
    if (raw || parsedHtml) return;

    const result = parseHtml(html, bootcamp, validBootcamps);

    setParsedHtml(true);

    ref?.current?.appendChild(result);

  }, [html, raw, ref?.current, parsedHtml]);

  return raw
    ? <RawWrapper dangerouslySetInnerHTML={{__html: html}}/>
    : <Container>
        <Wrapper>
          <Title>{title}</Title>
          <ResourceContainer>
            <RawResourceWrapper ref={ref}/>
          </ResourceContainer>
        </Wrapper>
      </Container>
};

TextBlock.defaultProps = {};
TextBlock.propTypes = {};

export default TextBlock;