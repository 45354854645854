import React, {useMemo}  from 'react';
import PropTypes from 'prop-types';

// TODO add more content types here as needed
import QuestionBanks from './QuestionBanks';
import PracticalExams from './PracticalExams';
import PracticeTests from './PracticeTests';
import VideoLessons from './VideoLessons';
import QBankCollection from './QBankCollection';
import Downloads from './Downloads';
import SearchableDownloads from './SearchableDownloads';
import Flashcards from './Flashcards';
import Anki from './Anki';
import Blogposts from './Blogposts';
import Generators from './Generators';
import Trainer from './Trainer';
import Performance from './Performance';


const ContentTypes = ({subject, config, themePalette, overviewData, calloutText}) => {
  const {route} = config;
  const Content = {
    'qbanks': QuestionBanks,
    'generators': Generators,
    'board-banks': QuestionBanks,
    'identify-structures': QBankCollection,
    'lecture-style-questions': QBankCollection,
    'qbank-collection': QBankCollection,
    'lectures': QuestionBanks,
    'practicals': PracticalExams,
    'bites': QuestionBanks,
    'bio-bites': QuestionBanks,
    'plus-packs': QuestionBanks,
    'plus-tests': PracticeTests,
    'practice-tests': PracticeTests,
    'videos': VideoLessons,
    'rbites': QuestionBanks,
    'downloads': Downloads,
    'notes': Downloads,
    'bio-notes': Downloads,
    'atlas': SearchableDownloads,
    'flashcards': Flashcards,
    'anki': Anki,
    'strategy': Blogposts,
    'review-videos': VideoLessons,
    'resources': Downloads,
    'reference': Downloads,
    'guide': Downloads,
    'blog': Blogposts,
    'trainer': Trainer,
    'performance': Performance,
  }[route];

  return useMemo(() => <Content subject={subject} themePalette={themePalette} data={overviewData} calloutText={calloutText} {...config} />, [overviewData, route])
}

ContentTypes.propTypes = {
  config: PropTypes.shape({
    content: PropTypes.array,
    route: PropTypes.string,
  }).isRequired,
  themePalette: PropTypes.shape({}).isRequired,
  overviewData: PropTypes.shape({}),
};
ContentTypes.defaultProps = {
};

export default ContentTypes;
