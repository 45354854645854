import React, {useState, useEffect, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {IntercomAPI} from 'react-intercom';
import Highlightable from '../../components/Highlightable';
import {TagLinks} from '../../components/Tags';
import {Column} from '../../components/Grid';
import moment from 'moment';
import VideoPlayer from '../../components/VideoPlayer';
import shortcode from 'shortcode-parser';
import AdminButtons from './components/AdminButtons';
import {useModalContext} from '../../contexts/Modal';
import QuestionFAQs from '../../components/Modal/components/QuestionFAQs';
import {determineColorFromTitle} from '@bootcamp/shared/src/util';

shortcode.add('toggle', (buff, opts) => {
  return `
    <div class="shortcode-toggle closed" onclick="if(this.classList.contains('closed')) {this.classList.toggle('closed'); this.querySelector('.toggle-content').style.display = 'block';} else {this.classList.toggle('closed'); this.querySelector('.toggle-content').style.display = 'none';}">
      <h4 class="toggle-trigger"><a style="cursor: pointer;">${opts.title_open}</a></h4>
      <div class="toggle-content" style="display: none;"><p></p>
      ${buff}
      </div>
    </div>
  `;
});

const ChoiceContainer = styled.label`
  min-height: 30px;
  height: auto;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({isCorrect, isIncorrect}) => isCorrect ? 'rgba(123, 213, 110, 0.5)' : isIncorrect ? 'rgba(251, 126, 122, 0.5)' : null};
  font-weight: ${({isCorrect}) => isCorrect && 'bold'};
  margin-bottom: .5em;
  user-select: none;
  text-decoration: ${({isCrossed}) => isCrossed ? 'line-through' : 'none'};
  opacity: ${({isCrossed}) => isCrossed ? 0.6 : 1};
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
`
const Option = styled.input.attrs({ type: 'radio', name: 'answer' })`
  cursor: pointer;
`
const LabelText = styled.span`
  padding-left: 5px;
  * {
    max-width: 100%;
    font-size: 16px !important;
    display: inline;
  }
  p {
    margin: 0;
  }
  img {
    height: auto;
  }
  .sup, .sub, sup, sub {
    font-size: 12px !important;
  }
  .sup {
    vertical-align: sup;
  }
  .sub {
    vertical-align: sub;
  }
  .fr-dib, .fr-dii {
    margin: 0 !important;
    display: inline !important;
    vertical-align: middle !important;
  }
  max-width: 100%;
  font-size: 16px !important;
  display: inline;
`

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const AnswerChoice = ({answer, isCrossed, isSelected, setSelectedAnswerIndex, updateCrossedAnswerIndexes, showingExplanation, answerIndex}) => {
  const [answerText, answerCorrect] = answer;

  const isCorrect = answerCorrect && showingExplanation;
  const isIncorrect = !answerCorrect && isSelected && showingExplanation;

  function handleContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    updateCrossedAnswerIndexes();
  }

  const answerLetter = alphabet[answerIndex];

  const letteredAnswerText = `${answerLetter}. ${answerText}`;

  return (
    <ChoiceContainer
      isCorrect={isCorrect}
      isIncorrect={isIncorrect}
      isCrossed={isCrossed}
      onContextMenu={handleContextMenu}>
      <Option
        disabled={showingExplanation}
        checked={isSelected}
        onChange={() => {
          setSelectedAnswerIndex();
          isCrossed && updateCrossedAnswerIndexes();
        }}
      />
      {useMemo(() => <LabelText className={'fr-view'} dangerouslySetInnerHTML={{__html: letteredAnswerText}} />, [letteredAnswerText])}
    </ChoiceContainer>
  )
}

const AnswerBoxContainer = styled.form`
  border: 1px solid #e1e8ed;
  border-radius: 5px;
  padding: .75em 1em;
  clear: both;
  width: 100%;
`;

const AnswerBox = ({
  answers,
  selectedAnswerIndex,
  setSelectedAnswerIndex,
  showingExplanation,
  crossedAnswerIndexes,
  updateCrossedAnswerIndexes,
}) => {

  return (
    <AnswerBoxContainer>
      {answers.map((answer, index) => {
        return (
          <AnswerChoice
            key={`answerindex-${index}`}
            setSelectedAnswerIndex={() => setSelectedAnswerIndex(index)}
            updateCrossedAnswerIndexes={() => updateCrossedAnswerIndexes(index)}
            isSelected={selectedAnswerIndex === index}
            isCrossed={crossedAnswerIndexes.indexOf(index) > -1}
            answer={answer}
            showingExplanation={showingExplanation}
            answerIndex={index}
          />
        )
      })}
    </AnswerBoxContainer>
  )
}


  const Container = styled(Column)`
    transform: translate3d(0,0,0);
    padding: 1em 0;
    align-items: flex-start;
    height: auto;

    img {
      display: block;
      margin: 12px 0px;
    }
  `;
  const Prompt = styled.div`
    * {
      max-width: 100%;
      font-size: 18px !important;
      line-height: 1.5em;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      margin: 0;
    }
    p {
      margin: 0;
    }
    ol, ul {
      margin: 1em 0;
    }
    .sup, .sub, sup, sub {
      font-size: 12px !important;
    }
    .sup {
      vertical-align: sup;
    }
    .sub {
      vertical-align: sub;
    }
    img {
      height: auto;
    }
    margin: 1em 0px;
    max-width: 100%;
    width: 100%;
    font-size: 18px !important;
    line-height: 1.5em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  `
  const Solution = styled.div`
    * {
      max-width: 100%;
      font-size: 16px !important;
      line-height: 1.6em;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      margin: 0;
    }
    p {
      margin: 0;
    }
    ol, ul {
      margin: 1em 0;
      padding-left: 40px;
    }
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
    }
    hr {
      margin: 40px 0 30px;
      border: 0;
      border-bottom: 1px solid #eee;
      height: 1px;
    }
    .sup, .sub, sup, sub {
      font-size: 12px !important;
    }
    .sup {
      vertical-align: sup;
    }
    .sub {
      vertical-align: sub;
    }
    max-width: 100%;
    font-size: 16px !important;
    line-height: 1.6em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    img {
      height: auto;
    }
    .img-airtable-import {
      max-width: 75%;
    }
    table {
      margin: 0 auto;
    }
    blockquote.callout {
      padding: 15px;
      margin: 0 0 1em 0;
      font-family: 'proxima-nova', sans-serif;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      background: #F9f9f9;
      color: #333;
      border-left: solid 5px #0F6292;
    }
    blockquote.callout p {
       margin-bottom: 0 !important;
    }
    .toggle-trigger {
      color: grey;
      text-decoration: underline;
    }
    table {
      margin: 0 auto;
    }
  `
  const SolutionBox = styled.div`
    margin: 1em 0 3em;
    border: 1px solid #e1e8ed;
    border-radius: 5px;
    padding: 1.5em;
    width: 100%;
    position: relative;
  `
  const SolutionText = styled.div`
    color: ${({correct}) => correct ? '#7BD46F' : '#FB7E7B'};
    font-weight: 600;
    margin-bottom: 15px;
  `
  const TopicText = styled.span`
    display: block;
    font-weight: 600;
    margin: 1em 0em;
  `;
  const IntercomButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 45px 0px 10px;
  `;

  const IntercomButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: .75em;
    border: 1px solid #e1e8ed;
    border-radius: 1em;
    margin-right: 2em;
    color: #777;
    text-decoration: none;
    flex-grow: 1;
    text-align: left;
    font: 400 16px;
    cursor: pointer;
    margin-right: 2em;
    &:last-child {
      margin-right: 0;
    }
    opacity: .75;
    transition: opacity .4s;
    &:hover {
      opacity: 1;
      transition: opacity .4s;
    }
  `;

  const QuestionReviewInformation = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    padding: 1.5em;
  `;

  const Time = styled.div`
  `;

  const Mark = styled.div`
    color: #FB7E7B;
    margin-right: 15px;
  `;

  // const UpdatedAtText = styled.div`
  //   display: block;
  //   margin-top: 2em;
  //   font-size: 14px;
  //   color: #777777;
  // `;


  function getQuestionReviewUrl(questionId, answerIndex) { // to load question review route for tbc locally use /question-review instead
    return `https://admin.bootcamp.com/tbc-question-review?questionId=${questionId}&answerIndex=${answerIndex}`;
  }

  const SolutionContainer = ({solution, citations, didSelectCorrectAnswer, didNotAnswer, selectedAnswerIndex, showFeedback, questionId, tags, didMark, time, solutionRef, updatedAt}) => {
    const {modalDispatch} = useModalContext();
    const {subject, topic} = tags.find(tag => !!tag.topic && tag.contentType !== 'auxiliaryAnatomy') || {};
    const {topic: category} = tags.find(tag => !!tag.topic && tag.contentType === 'auxiliaryAnatomy') || {};
    const questionReviewUrl = getQuestionReviewUrl(questionId, selectedAnswerIndex);
    const parsedSolution = shortcode.parse(solution || '');
    const intercomQuestionString = `I have a content question on ${subject} ${questionReviewUrl}. Specifically:`;

    useEffect(() => {
      const handler = event => {
        if (event.target.classList.contains('toggle-trigger')) {
          event.target.parentNode.querySelector('.toggle-content').style.display = 'block';
        }
      }
      solutionRef.current?.addEventListener('click', handler);
      return () => solutionRef.current?.removeEventListener('click', handler);
    }, []);
    const citationsBox = citations ? `
      <br />
      <div class="closed toggle-trigger-container">
        <h4 class="toggle-trigger" style="cursor: pointer;">View Source(s)</h4>
        <div class="toggle-content" style="display: none;">
        ${citations?.replace(/<a /g, '<a target="_blank" ')}
        </div>
      </div>
    ` : ''
    return (
      <SolutionBox>
        <QuestionReviewInformation>
          <Mark>{didMark ? "Marked" : ""}</Mark>
          <Time>{time > 0 ? moment.utc(time * 1000).format('mm:ss') : ""}</Time>
        </QuestionReviewInformation>
        <SolutionText correct={didSelectCorrectAnswer}>{didNotAnswer ? 'Not Answered' : didSelectCorrectAnswer ? 'Correct' : 'Incorrect'}</SolutionText>
        <Solution ref={solutionRef} className={'fr-view'} dangerouslySetInnerHTML={{__html: parsedSolution + citationsBox}} />
        {topic && <TopicText>Topic: {topic}</TopicText>}
        {category && <TopicText>Category: {category}</TopicText>}
        {/*updatedAt && <UpdatedAtText>Last Updated: {updatedAt}</UpdatedAtText>*/}
        {showFeedback && (
          <IntercomButtons>
            <IntercomButton onClick={() => subject === 'Perceptual Ability' ?
              IntercomAPI('showNewMessage', intercomQuestionString) :
              modalDispatch({type: 'open', component: QuestionFAQs, componentProps: {questionId, intercomQuestionString}, enableClickClose: true, modalContainerStyle: theme => `
                align-items: flex-start;
                background: transparent;
                justify-content: flex-end;
                z-index: 1;
              `})
            }>
              I have a question about this explanation
            </IntercomButton>
            <IntercomButton onClick={() => IntercomAPI('showNewMessage', `I have content feedback on ${subject} ${questionReviewUrl}. Specifically:`)}>
              I have feedback about this explanation
            </IntercomButton>
          </IntercomButtons>
        )}
      </SolutionBox>
    );
  }


const QuestionView = ({
  prompt,
  answers,
  explanation,
  caseset,
  xray,
  clinicalPhoto,
  citations,
  updatedAt,
  tags,
  didSubmitAnswer,
  testBlockName,
  alwaysShowingExplanation,
  onSelectAnswerIndex,
  didSubmitAnswerIndex,
  showFeedback,
  questionId,
  enableHighlighting,
  disableVideos,
  setHighlights,
  highlights,
  questionIndex,
  didMark,
  time,
  savedCrossedAnswerIndexes,
  onCrossedAnswerIndexesUpdate,
  viewKey
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(didSubmitAnswerIndex);
  const [crossedAnswerIndexes, setCrossedAnswerIndexes] = useState(savedCrossedAnswerIndexes);
  const solutionRef = useRef();

  const filteredAnswers = answers.filter(Array.isArray);

  const correctAnswerIndex = filteredAnswers
    .map(([answerText, answerCorrect]) => answerCorrect)
    .indexOf(true);
  // const correctAnswerLetter = alphabet[correctAnswerIndex];
  const didSelectCorrectAnswer = selectedAnswerIndex === correctAnswerIndex;
  const showingExplanation = didSubmitAnswer || alwaysShowingExplanation;
  const didNotAnswer = selectedAnswerIndex === -1;

  // selectedAnswerIndex hook
  useEffect(() => {
    if (!filteredAnswers?.length) return;
    if (selectedAnswerIndex !== -1) {
      onSelectAnswerIndex(selectedAnswerIndex, didSelectCorrectAnswer);
    }

    alwaysShowingExplanation && setSelectedAnswerIndex(didSubmitAnswerIndex || correctAnswerIndex);
  }, [selectedAnswerIndex]);

  function updateCrossedAnswerIndexes(index) {
    if (showingExplanation) return;

    const indexes = crossedAnswerIndexes.indexOf(index) > -1
      ? crossedAnswerIndexes.filter(i => i !== index)
      : [...crossedAnswerIndexes, index];
    onCrossedAnswerIndexesUpdate(indexes);
    setCrossedAnswerIndexes(indexes);
  }

  useEffect(() => {
    if (!disableVideos && solutionRef.current && showingExplanation) {
      const vimeoNodes = solutionRef.current.querySelectorAll(':not(del) > .video-thumb');

      const vimeoIds = new Set([...vimeoNodes].map(node => node.dataset.vimeoId));

      [...vimeoIds].forEach(async vimeoId => {
        try {
          vimeoNodes.forEach((node, index) => {
            if (node.dataset.vimeoId === vimeoId) {
              const videoContainer = document.createElement('div');
              videoContainer.className = "videoContainer";
              videoContainer.style.marginBottom = '1em';
              if (node.classList.contains('video-lock')) {
                node.insertAdjacentElement('afterEnd',videoContainer);
              } else {
                if (node.parentNode && node.parentNode.children.length === 1 && node.parentNode.children[0].classList.contains('video-thumb') && node.parentNode !== solutionRef.current) {
                  node.parentNode.remove();
                } else {
                  node.remove();
                }

                if (solutionRef.current) solutionRef.current.insertAdjacentElement('afterBegin',videoContainer);
              }
              const videoPlayer = <VideoPlayer playerId={`${vimeoId}-${index}-${viewKey}`} vimeoId={vimeoId} />;
              ReactDOM.render(videoPlayer, videoContainer);
            }
          })
        } catch (e) {
          console.log(e);
        }
      })
      const gifs = solutionRef.current.querySelectorAll('.rubgif');

      [...gifs].forEach(gif => {
        gif.style.display = 'none';
        const gifPlayer = document.createElement('gif-player');
        const gifSrc = gif.getAttribute('src');
        gifPlayer.setAttribute('src', gifSrc+'?version=gifplayer');
        gifPlayer.setAttribute('speed', '1');
        gifPlayer.setAttribute('play', true);
        gif.insertAdjacentElement('afterend', gifPlayer);
      });
    }
    return () => {
      if (solutionRef.current) {
        solutionRef.current.querySelectorAll('.videoContainer').forEach(node => node.remove());
      }
    }
  }, [explanation, showingExplanation]);

  return (
    <Container>
      <AdminButtons questionId={questionId} correctAnswerIndex={correctAnswerIndex} />
      {testBlockName &&
        <TagLinks tags={[{
            title: testBlockName,
          color: determineColorFromTitle(testBlockName),
            as: 'span',
        }]}
        />
      }
      {caseset &&
        <Prompt
          className={'fr-view'}
          dangerouslySetInnerHTML={{__html: caseset}}
        />
      }
      {xray &&
        <Prompt
          className={'fr-view'}
          dangerouslySetInnerHTML={{__html: xray}}
        />
      }
      {clinicalPhoto &&
        <Prompt
          className={'fr-view'}
          dangerouslySetInnerHTML={{__html: clinicalPhoto}}
        />
      }
      <Highlightable
        type={'stem'}
        htmlString={prompt}
        setHighlights={setHighlights}
        highlights={highlights}
        disable={!enableHighlighting}>
        <Prompt
          className={'fr-view'}
          dangerouslySetInnerHTML={{__html: questionIndex ? `${questionIndex}. ${prompt}` : prompt}}
        />
      </Highlightable>
      <AnswerBox
        answers={filteredAnswers}
        selectedAnswerIndex={selectedAnswerIndex}
        setSelectedAnswerIndex={setSelectedAnswerIndex}
        showingExplanation={showingExplanation}
        crossedAnswerIndexes={crossedAnswerIndexes}
        updateCrossedAnswerIndexes={updateCrossedAnswerIndexes}
      />
      {showingExplanation &&
        <Highlightable setHighlights={setHighlights} highlights={highlights} disable={!enableHighlighting}>
          <SolutionContainer
            solution={explanation}
            citations={citations}
            didNotAnswer={didNotAnswer}
            didSelectCorrectAnswer={didSelectCorrectAnswer}
            selectedAnswerIndex={selectedAnswerIndex}
            showFeedback={showFeedback}
            questionId={questionId}
            tags={tags}
            didMark={didMark}
            time={time}
            solutionRef={solutionRef}
            updatedAt={updatedAt}
          />
        </Highlightable>
      }
    </Container>
  )
}

QuestionView.defaultProps = {
  prompt: '',
  answers: '[]',
  explanation: '',
  updatedAt: '',
  onSelectAnswerIndex: () => true,
  alwaysShowingExplanation: false,
  didSubmitAnswerIndex: -1,
  showFeedback: false,
  questionId: '',
  tags: [],
  enableHighlighting: false,
  setHighlights: () => {},
  highlights: null, // this should be a serialized highlight string,
  didMark: false,
  time: "",
  savedCrossedAnswerIndexes: [],
  onCrossedAnswerIndexesUpdate: () => true
}

export default QuestionView;
