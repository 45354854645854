/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:68b66c72-c507-413c-aedd-19ea4c61d767",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xyVDhrmBL",
    "aws_user_pools_web_client_id": "3vvv1kugsv5vrb7ngci2vhdtt",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://i75lg2i6nncdvinozslpvnlq5y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "tbcPublicAPI",
            "endpoint": "https://c0rwwjyoma.execute-api.us-east-1.amazonaws.com/tbc",
            "region": "us-east-1"
        },
        {
            "name": "webflow",
            "endpoint": "https://9j6zqh6xm3.execute-api.us-east-1.amazonaws.com/tbc",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "testtube6dca237224ff44ca973cd2a6dfb779e3-tbc",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
