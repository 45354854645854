import React from 'react';
import styled from 'styled-components';

import {Container, Header} from './shared';
import { useCreateTestState } from '../context';
import {Body3} from '@bootcamp/web/src/components/Typography';
import moment from 'moment';

const StyledHeader = styled(Header)`
  margin: 0;
  margin-bottom: 8px;
`;
const Subheader = styled(Body3)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  color: ${({theme}) => theme.colors.neutralsPalette.grey};
  line-height: 100%;
`;
const Wrapper = styled.div`
`;
const Input = styled.input`
  padding: ${({theme}) => theme.layouts.spacing.ms};
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  border-radius: 8px;
  width: 100%;
  font-size: 16px;

  &:focus {
    outline: none;
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  }
`;


const Count = () => {
  const {
    variables: {
      testCount,
      maxQuestions,
      loading
    },
    methods: {
      setTestCount
    }
  } = useCreateTestState();

  function handleChange(event) {
    setTestCount(event?.target?.value);
  }

  function clampValue() {
    const clampedValue = Math.floor(Math.min(Math.max(1, testCount), maxQuestions));
    setTestCount(clampedValue);
  }
  // 90 seconds per question
  const durationMultiplier = 90;
  const duration = moment.duration(testCount * durationMultiplier, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const formattedString = `${hours ? `${hours}hr ` : ''}${minutes ? `${minutes}min` : ''}`;
  return (
    <Container style={{borderBottom: 'none'}}>
      <StyledHeader>Number of Questions</StyledHeader>
      <Subheader>You can have a maximum of {maxQuestions} questions per test.</Subheader>
      <Wrapper>
        <Input
          min={1}
          max={maxQuestions}
          type={'number'}
          inputMode={'numeric'}
          value={testCount}
          onChange={handleChange}
          onBlur={clampValue}
          disabled={loading}
        />
      </Wrapper>
      <Subheader style={{marginTop: 16}}>This test should take about {formattedString} to complete.</Subheader>
    </Container>
  );
};

Count.defaultProps = {};
Count.propTypes = {};

export default Count;
