import React from 'react';
import styled from 'styled-components';
import {DraggablePopup, CalculatorIcon} from '@bootcamp/web/src/components/NCLEXQBank/shared';

const Frame = styled.iframe.attrs({
  scrolling: 'no',
  frameborder: '1',
  marginheight: '0px',
  marginwidth: '0px',
})`
  border:0px #FFFFFF none;
  height: 488px;
  width: 250px;
  background: url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-small.gif") no-repeat center;
`;

const Calculator = ({onCloseExhibit, defaultPosition}) => (
  <DraggablePopup
    noSize
    close={onCloseExhibit} 
    headerContent={
      <div style={{display: 'flex', alignItems: 'center'}}>
        <CalculatorIcon />Calculator
      </div>
    } 
    footerContent={<div/>}>
      <Frame src={`${process.env.PUBLIC_URL}/assets/ti_calc/index.html`} />
  </DraggablePopup>
);

export default Calculator;
