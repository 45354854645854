import styled from 'styled-components';

const Input = styled.input`
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  color: ${({theme}) => theme.darkTextColor};
  font-size: 14px;
  padding: 1.5em .5em;
  min-width: 300px;
  border-radius: 2px;
  transition: all 200ms ease;

  &:focus {
    outline: none;
  }
  
  &:hover {
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey}
  }

`;

export default Input;
