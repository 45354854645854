import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
`;

const htmlString = `
	<div id="content" class="exam">

		<div class="posts">

			<article>

				<div class="box-wrap">

						<div id="introduction">

							<div class="bartop">

							<div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Hole Punching Practice Generator</h2></div></div></div>

							<div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

							<div class="instructions-container">

								<p>This is the hole punching generator. Please read the following before starting:</p>

								<p>1. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">hole punching instructions</a>.<br />
								2. If you're having trouble, check out the <a href="/dat/perceptual-ability/videos/hole-punching?index=1" target="_blank">PAT Academy Hole Punching Tutorial</a>.<br />
								3. This generator creates entirely new, unique questions on the fly, so practice as much as you'd like!</p>

								<p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

								<p style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;" id="startGenerator">Start Generator</a></p>


								<div id="instructions" style="display: none;">
								<p style="margin-top: 50px;"><strong>Official Hole Punching Instructions</strong></p>

								<p>A flat square sheet of paper is folded one or more times. The broken lines indicate the original position of the paper. The solid lines indicate the position of the folded paper. The paper is never turned or twisted. The folded paper always remains within the edges of the original square. There may be from one to four folds in each item. After the last fold a hole is punched in the paper. Your task is to unfold the paper mentally and determine the position of the holes on the original square. Choose the pattern of black circles that indicates the position of the holes on the original square sheet of paper. There is only one correct pattern for each item.</p>

								<p>Here's an example:</p>

								<img src="https://s3.amazonaws.com/datbootcamp/holepunchingexample.png" style="max-width: 560px; margin: 35px 0px;">

								<p>Figure B shows the result of the first fold. Figure C shows the position of the punched hole on the folded paper. When the paper is unfolded the pattern of the holes on the original square is shown by the dark circles in Figure D. The answer has two holes since the paper was two thicknesses when punched.</p></div>
								<div style="display: block; height: 100px;"></div>

							</div>

						</div>

							<div id="generator" style="display: none;">

								<div class="bartop">
									<div class="bartopcontainer">
										<div class="column-center"><h2>Hole Punching Practice Generator</h2></div>
										<div class="column-left"><p id="position">Question <span id="location" style="font-weight: 600;">1</span> of <span id="total" style="font-weight: 600;">1</span></p></div>
											<div class="column-right">
												<p id="time-box" style="float: right;">Time Remaining:
													<span id="time-display" style="font-weight: 600;">30</span>
													<span id="time-button" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">On/Off</span>
												</p>
												<p  style="float: right;">Average Time:
													<span id="average-time" style="font-weight: 600;">0</span><span  style="margin-left: 6px; font-weight: 600;">Seconds</span>
												</p>
											</div>
										</div>
								</div>

								<div class="generator-container">

												<div id="folds" class="folds" style="max-width: 823px; border: 2px black solid; text-align: center; clear: both; padding-bottom: 5px; margin: auto; margin-right: 0px;">
										<div class="fold" style="margin: 15px 25px; width: 83px; height: 83px; display: inline-block; position: relative;">
											<canvas class="step" id="step0" style="position: relative; width: 83px; height: 83px; z-index: 2;"></canvas>
											<p style='font-family: "Times New Roman",Georgia,Serif; color: black; margin: 0; font-size: 1em;'>Answer</p>
										</div>
									<div class="fold" style="margin: 15px 25px; width: 83px; height: 83px; display: inline-block; position: relative;">
										<div class="dashed-border" style="margin: 0; padding: 0; width: 77px; height: 77px; z-index: 1; position: absolute; border: 3px black dashed;"></div>
											<canvas class="step" id="step1" style="position: relative; width: 83px; height: 83px; z-index: 2;"></canvas>
											<p style='font-family: "Times New Roman",Georgia,Serif; color: black; margin: 0; font-size: 1em;'>Fold 1</p>
										</div>
									<div class="fold" style="margin: 15px 25px; width: 83px; height: 83px; display: inline-block; position: relative;">
										<div class="dashed-border" style="margin: 0; padding: 0; width: 77px; height: 77px; z-index: 1; position: absolute; border: 3px black dashed;"></div>
											<canvas class="step" id="step2" style="position: relative; width: 83px; height: 83px; z-index: 2;"></canvas>
											<p style='font-family: "Times New Roman",Georgia,Serif; color: black; margin: 0; font-size: 1em;'>Fold 2</p>
									</div>
									<div class="fold" style="margin: 15px 25px; width: 83px; height: 83px; display: inline-block; position: relative;">
										<div class="dashed-border" style="margin: 0; padding: 0; width: 77px; height: 77px; z-index: 1; position: absolute; border: 3px black dashed;"></div>
											<canvas class="step" id="step3" style="position: relative; width: 83px; height: 83px; z-index: 2;"></canvas>
											<p style='font-family: "Times New Roman",Georgia,Serif; color: black; margin: 0; font-size: 1em;'>Fold 3</p>
									</div>
									<div class="fold" style="margin: 15px 25px; width: 83px; height: 83px; display: inline-block; position: relative;">
										<div class="dashed-border" style="margin: 0; padding: 0; width: 77px; height: 77px; z-index: 1; position: absolute; border: 3px black dashed;"></div>
											<canvas class="step" id="step4" style="position: relative; width: 83px; height: 83px; z-index: 2;"></canvas>
											<p id="step4text" style='font-family: "Times New Roman",Georgia,Serif; color: black; margin: 0; font-size: 1em;'>Fold 4</p>
									</div>
									<div id="step5container" class="fold" style="margin: 15px 25px; width: 83px; height: 83px; display: inline-block; position: relative;">
										<div class="dashed-border" style="margin: 0; padding: 0; width: 77px; height: 77px; z-index: 1; position: absolute; border: 3px black dashed;"></div>
											<canvas class="step" id="step5" style="position: relative; width: 83px; height: 83px; z-index: 2;"></canvas>
											<p id="step5text" style='font-family: "Times New Roman",Georgia,Serif; color: black; margin: 0; font-size: 1em;'>Hole Punch</p>
									</div>
										</div>
										<div id="answers" style="max-width: 823px; margin: 13px auto; text-align: right; clear: both;">
									<div class="answer" style="margin: -3px -2px 0 0; border: 2px black solid; background-color: #DDD; padding: 18px 26px; display: inline-block;">
										<div class="answer-sheet" style="width: 83px; height: 83px; border: 2px black solid; margin: 0 auto;">
											<canvas id="can-A" style="width: 100%; height: 100%;"></canvas>
										</div>
										<span style='font-family: "Times New Roman",Georgia,Serif; color: black; margin-top: 4px; font-size: 1.1em;'>A</span>
									</div><!--
										 --><div class="answer" style="margin: -3px -2px 0 0; border: 2px black solid; background-color: #DDD; padding: 18px 26px; display: inline-block;">
										<div class="answer-sheet" style="width: 83px; height: 83px; border: 2px black solid; margin: 0 auto;">
											<canvas id="can-B" style="width: 100%; height: 100%;"></canvas>
										</div>
													<span style='font-family: "Times New Roman",Georgia,Serif; color: black; margin-top: 4px; font-size: 1.1em;'>B</span>
									</div><!--
										 --><div class="answer" style="margin: -3px -2px 0 0; border: 2px black solid; background-color: #DDD; padding: 18px 26px; display: inline-block;">
										<div class="answer-sheet" style="width: 83px; height: 83px; border: 2px black solid; margin: 0 auto;">
											<canvas id="can-C" style="width: 100%; height: 100%;"></canvas>
										</div>
													<span style='font-family: "Times New Roman",Georgia,Serif; color: black; margin-top: 4px; font-size: 1.1em;'>C</span>
									</div><!--
										 --><div class="answer" style="margin: -3px -2px 0 0; border: 2px black solid; background-color: #DDD; padding: 18px 26px; display: inline-block;">
										<div class="answer-sheet" style="width: 83px; height: 83px; border: 2px black solid; margin: 0 auto;">
											<canvas id="can-D" style="width: 100%; height: 100%;"></canvas>
										</div>
										<span style='font-family: "Times New Roman",Georgia,Serif; color: black; margin-top: 4px; font-size: 1.1em;'>D</span>
									</div><!--
										 --><div class="answer" style="margin: -3px -2px 0 0; border: 2px black solid; background-color: #DDD; padding: 18px 26px; display: inline-block;">
										<div class="answer-sheet" style="width: 83px; height: 83px; border: 2px black solid; margin: 0 auto;">
											<canvas id="can-E" style="width: 100%; height: 100%;"></canvas>
										</div>
										<span style='font-family: "Times New Roman",Georgia,Serif; color: black; margin-top: 4px; font-size: 1.1em;'>E</span>
									</div>
											</div>
								<div style="clear: both;"></div>
											<div id="grades" style="font-size: 16px; margin-top: 10px; margin-left: 0px; margin-right: auto; border: 1px solid #E8E8E8; padding: 5px;">
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<label class="grade-label" ><input type="radio" name="answer" value="A" style="margin-right: 7px;">A</label><span id="grade-A" style="margin: -14px 0px 0px 15px; display: inline-block;"></span></div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<label class="grade-label"><input type="radio" name="answer" value="B" style="margin-right: 7px;">B</label><span id="grade-B" style="margin: -14px 0px 0px 15px; display: inline-block;"></span></div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<label class="grade-label"><input type="radio" name="answer" value="C" style="margin-right: 7px;">C</label><span id="grade-C" style="margin: -14px 0px 0px 15px; display: inline-block;"></span></div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<label class="grade-label"><input type="radio" name="answer" value="D" style="margin-right: 7px;">D</label><span id="grade-D" style="margin: -14px 0px 0px 15px; display: inline-block;"></span></div>
									<div class="grade" style="margin: 3px 3px 8px 3px;">
										<label class="grade-label"><input type="radio" name="answer" value="E" style="margin-right: 7px;">E</label><span id="grade-E" style="margin: -14px 0px 0px 15px; display: inline-block;"></span></div>
											</div>


									<div class="mobileexamfix" style="clear: both; height: 25px; margin: 30px 0px;"></div>

									<div class="barbottom">

										<div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; padding: 15px 0px; justify-content: space-between;">

											<div style="display: flex; flex-direction: row; width: 33%;"><button type="button" id="previous" class="nextbutton" style="margin-right: 15px!important;">Previous</button><button type="button" id="next" class="nextbutton">Next</button></div>

											<div style="display: flex; flex-direction: column; width: 67%; text-align: right; color: #FFF;">
												<div id="scoreboard" style="width: max-content;">Correct: <span id="correct">0</span> | Incorrect: <span id="incorrect">0</span> |
													<span id="game-reset" style="cursor: pointer; margin-left: 3px; color: #A9E2F3;">Reset</span>
													<span id="answer-toggle"><span style="color: #FFF;">|</span> Toggle: <span id="answer-exp" style="cursor: pointer;color: #A9E2F3;">Explanation</span> / <span id="answer-grid" style="cursor: pointer;color: #A9E2F3;">Grid</span> <span style="color: #FFF;">|</span></span>
													<span style="margin-left: 3px;">Current Difficulty:</span>
													<select name="Difficulty" id="setdifficulty">
														<option value="0">DAT Mode</option>
														<option value="1">Beginner Mode</option>
														<option value="2">Four-Folds Mode</option>
													</select>
													<select name="timeSettings" id="settime">
														<option value="15">15 secs</option>
														<option value="20">20 secs</option>
														<option value="25">25 secs</option>
														<option selected="selected" value="30">30 secs</option>
														<option value="45">45 secs</option>
													</select>
												</div>
											</div>
										</div>

									</div>

								</div>

							</div>

				</div>

			</article>

		</div>

	</div>
`;

const HolePunching = () => (
  <Container dangerouslySetInnerHTML={{__html: htmlString}}/>
);

export default HolePunching;
