import React, {useState, useEffect} from 'react';

import API from '@aws-amplify/api';
import {loadStripe} from '@stripe/stripe-js';
import {Lottie} from '@crello/react-lottie';

import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Input, InputDecorator} from '../FieldRenderer/components/Field';
import {Container, Body, Header, SubHeader, FieldGroup, SubmitContainer, SubmitButton} from '../index';
import {LinkButton} from '../../Branding/Buttons';
import Sequence from '../../../components/Sequence';
import {Loader} from '../../Branding';

import {useUserDataContext} from '../../../contexts/UserData';
import {stripePublishableKey} from '../../../config/keys';
import checkoutCompleteAnimation from './checkoutComplete.json';

import {font, colors} from '@bootcamp/shared/src/styles/theme';
import styled from 'styled-components';
import {invokeLambda} from '@bootcamp/shared/src/requests';

function getCardElementOptions() {
  return {
    style: {
      base: {
        fontSize: font.size.regular,
        color: colors.neutralsPalette.extraDark,
        lineHeight: font.height.regular,
      }
    }
  }
}

const stripePromise = loadStripe(stripePublishableKey);

const Form = styled.form`
  width: 100%;
  height: 100%;
`;
const CardInput = styled(Input)`
  width: 100%;

  ${({focused, theme}) => focused && `
    outline: none;
    background: ${theme.colors.brandPalette.indigo.light};
    border: 1px solid ${theme.colors.brandPalette.indigo.default};
  `}
`;
const ViewAccountLink = styled(LinkButton)`
  padding-bottom: ${({theme}) => theme.layouts.spacing.xl};
  button {
    color: ${({theme}) => theme.colors.neutralsPalette.grey};
  }
`;
const AnimationContainer = styled.div`
  padding: ${({theme}) => theme.layouts.spacing.xl} 0px;
`;
const StyledSequence = styled(Sequence)`
  height: 100vh;
`;

async function updatePaymentMethodAndRetryInvoice(invoiceId, customerId, paymentMethodId, subscriptionId) {
  try {
    const result = await invokeLambda('tbc-Stripe', {
      path: '/stripe/retry-invoice',
      invoiceId,
      customerId,
      paymentMethodId,
      subscriptionId,
    });
    return result.body.updatedInvoice;
  } catch (error) {
    console.log('error fetching payment intent', error);
    return {error};
  }
}

async function updatePaymentMethod(customerId, paymentMethodId) {
  try {
    const result = await invokeLambda('tbc-Stripe', {
      path: '/stripe/update-payment-method',
      customerId,
      paymentMethodId,
    });
    return result.body.updatedCustomer;
  } catch (error) {
    console.log('error updating payment method', error);
    return {error};
  }
}

async function handlePaymentMethodUpdate(invoiceId, customerId, paymentMethodId, subscriptionId, membershipStatus) {
  if (membershipStatus === 'awaitingPayment') {
    return updatePaymentMethodAndRetryInvoice(invoiceId, customerId, paymentMethodId, subscriptionId);
  } else {
    return updatePaymentMethod(customerId, paymentMethodId);
  }
};

const FinishedAnimation = () => (
  <AnimationContainer>
    <Lottie
      style={{maxHeight: '150px', maxWidth: '150px'}}
      config={{animationData: checkoutCompleteAnimation}}
      />
  </AnimationContainer>
);

const Finished = ({start}) => (
  <Container>
    <Body>
      <Header>Awesome! Your payment information updated successfully, welcome back!</Header>
      {start && <FinishedAnimation/>}
    </Body>
    <SubmitContainer>
      <SubmitButton>
        Back to Account
      </SubmitButton>
    </SubmitContainer>
  </Container>
);

const UpdatePaymentMethod = ({
  subscriptionId,
  latestInvoiceId,
  customerId,
  showAccountLink,
  headerText,
  subheaderText,
  onFinished,
  membershipStatus,
}) => {
  const {refreshSession} = useUserDataContext();
  const [error, setError] = useState(null);
  const [cardElementFocused, setCardElementFocused] = useState(false);
  const [cardInfoComplete, setCardInfoComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!elements) return;

    const cardElement = elements.getElement(CardElement);

    cardElement.on('change', (event) => {
      if (event.complete) setCardInfoComplete(true);
      else if (cardInfoComplete && !event.complete) setCardInfoComplete(false);
    });

  }, [elements, cardInfoComplete]);

  const preventDefault = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const createPaymentMethod = async () => {
    const card = elements.getElement(CardElement);

    try {
      return await stripe.createPaymentMethod({
        type: 'card',
        card,
        // optionally add billing details etc here
      });
    } catch (error) {
      console.log('error creating payment method', error);
    }
  };

  const handleSubmit = async (event) => {
    preventDefault(event);

    // return if stripe hasn't loaded
    if (!stripe || !elements) return;

    setLoading(true);

    try {
      // create new payment method
      const {paymentMethod} = await createPaymentMethod();

      // update payment method and retry charge
      const {error} = await handlePaymentMethodUpdate(latestInvoiceId, customerId, paymentMethod.id, subscriptionId, membershipStatus);

      if (error) {
        setError({message: 'Please double check your card details and try again.'});
        setLoading(false);
      } else {
        await refreshSession();
        onFinished();
      }

    } catch (error) {
      setError({message: 'Please double check your card details and try again.'});
      setLoading(false);
    }
  };

  return (
    <Container onClick={preventDefault}>
     <Body>
        <Header>{headerText}</Header>
        <SubHeader>{subheaderText}</SubHeader>
        <Form>
          <FieldGroup>
            <InputDecorator error={error}>
              <CardInput
                onFocus={() => setCardElementFocused(true)}
                onBlur={() => setCardElementFocused(false)}
                options={getCardElementOptions()}
                focused={cardElementFocused}
                as={CardElement}
              />
            </InputDecorator>
          </FieldGroup>

        </Form>
      </Body>
        <SubmitContainer>
          <SubmitButton
            onClick={handleSubmit}
            disabled={!stripe || !elements || loading || !cardInfoComplete}
            >
            {loading ? <Loader size={16}/>: 'Update Payment Method'}
          </SubmitButton>
        </SubmitContainer>
        {showAccountLink && <ViewAccountLink to={'/account'} buttonType={'text'} type={'secondary'} children={'View your account'}/>}
    </Container>
  );
}


const ContextWrapper = ({subscriptionId, latestInvoiceId, customerId, showAccountLink, headerText, subheaderText, membershipStatus}) => {
  return (
    <Elements stripe={stripePromise}>
      <StyledSequence>
        {[
          ({next}) => (
            <UpdatePaymentMethod
              subscriptionId={subscriptionId}
              latestInvoiceId={latestInvoiceId}
              customerId={customerId}
              showAccountLink={showAccountLink}
              headerText={headerText}
              subheaderText={subheaderText}
              membershipStatus={membershipStatus}
              onFinished={() => {
                next({stepSize: 1, activeProps: {finished: true}})
              }}
              />
          ),
          ({finished}) => <Finished start={finished}/>
        ]}
      </StyledSequence>
    </Elements>
  );
}

UpdatePaymentMethod.propTypes = {};
UpdatePaymentMethod.defaultProps = {};

export default ContextWrapper;
