import React from 'react';
import styled from 'styled-components';

import {Row} from '@bootcamp/web/src/components/Grid';
import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {Chiclet} from '@bootcamp/web/src/components/UI';
import {Color} from '@styled-icons/fluentui-system-filled/Color';
import {LoadingSpinner, Toggle, StyledBlurb, StyledHeader, StyledLabel, StyledTable} from './shared';
import {PerformanceChart} from './PerformanceChart';
import {TextTooltip} from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/shared';

const StyledTextTooltip = styled(TextTooltip)`
  margin-left: 8px;
  svg {
    height: 14px;
    width: 14px;
  }
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;

const Container = styled.div`
  border-radius: 8px;
  margin-bottom: ${({theme}) => theme.layouts.spacing.xl};
`;

const HeatmapToggle = styled(Chiclet)`
  margin-left: 16px;
`;

const Test = ({fetching, dataByTest=[]}) => {
  const {searchUserInteractions, saveUserInteraction, isBootcampPlusStudent} = useUserDataContext();
  const {showingHeatmap} = searchUserInteractions('performance_heatmap');
  const {sortOrder} = searchUserInteractions(`performance_order`, 'DESC');

  const rows = dataByTest
    .map((subjects, index) => ({
      cells: [
          { content: '' },
          { content: `Test ${index+1}` },
        ...subjects.map(({score, testId, testProgressId}) => ({to: !testId ? null : `/dat/test-review?id=${testId}&testProgressId=${testProgressId}`, disabled: score === '-', content: score, background: !showingHeatmap || score === '-' ? 'transparent' : score <= 16 ? '#FCECEE' : score <= 18 ? '#FFFDE9' : '#EAF5EA'})),
          { content: '' },
        ],
    }))

  const averages = dataByTest[0].map((_, index) => {
    // transpose breakdown
    const subjectScores = dataByTest.map(test => test[index]).filter(({score}) => score !== '-');
    if (!subjectScores.length) return {content: '-'}
    const subjectAverage = subjectScores.reduce((acc, {score}) => acc + parseInt(score), 0) / subjectScores.length;
    return {content: Math.round(subjectAverage)}
  })

  const allSubjectsPresent = averages.map(({content}) => content).every(score => score !== '-');
  const average = allSubjectsPresent ? averages[6].content : 'N/A';


  // filter out PAT
  const feedback = average === 'N/A'
    ? <span>Check back once you’ve completed a full practice test for an estimated DAT score. Be sure you complete all {isBootcampPlusStudent ? '15' : '10'} practice tests before your DAT to get the most accurate estimated score.</span>
    : <span>Based on your performance, I'd <strong>estimate an AA score of {average} - {average + 1}</strong> on the DAT. Don't focus too much on the estimate - instead, continue to review your areas of knowledge. Make sure you complete all the practice tests to learn from your mistakes and get a more accurate estimated score. Note your First Attempt is your most accurate score prediction.</span>
  

  return (
    <Container>
      {sortOrder === 'ASC' && (
        <StyledHeader style={{marginBottom: 24}}>
          <StyledLabel>Feedback</StyledLabel>
          <StyledBlurb icon={ari} text={feedback} lightMode/>
        </StyledHeader>
      )}
      <PerformanceChart averages={averages} fetching={fetching} />
      <Row style={{marginBottom: 16, marginTop: 24, alignItems: 'center'}}>
        <StyledLabel style={{marginBottom: 0}}>Score Report</StyledLabel>
        <StyledTextTooltip id="order-tooltip">Use First Attempt for the most accurate score prediction.</StyledTextTooltip>
      </Row>
      <Row style={{marginBottom: '16px'}}>
        <Toggle value={sortOrder === 'DESC'} setValue={() => saveUserInteraction(`performance_order`, JSON.stringify({sortOrder: sortOrder === 'ASC' ? 'DESC' : 'ASC'}))} />
        <HeatmapToggle small iconPosition="left" IconComponent={<Color color={showingHeatmap ? "#2F3037" : 'white'} size={20} />} active={showingHeatmap} dark onClick={() => saveUserInteraction('performance_heatmap', JSON.stringify({showingHeatmap: !showingHeatmap}))}>Color Map</HeatmapToggle>
      </Row>
      <StyledTable
        hideChevrons
          rows={fetching ? [{cells: [{content: ''}, {content: <LoadingSpinner color="royal" active />}, {content: ''}]}] : [
          { cells: [
            { content: '' },
            { content: '' },
            { content: 'BIO' },
            { content: 'GC' },
            { content: 'OC' },
            { content: 'PAT' },
            { content: 'RC' },
            { content: 'QR' },
            { content: 'AA' },
            { content: '' }
          ]
          },
          {cells: [
            { content: '' },
            { content: 'Average' },
            ...averages,
            { content: '' }
          ]},
          ...rows
        ]}
      />
    </Container>
  );
};

Test.defaultProps = {};
Test.propTypes = {};

export default Test;