import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';

import styled from 'styled-components';

import {PageSection} from '../ClassroomContentWrapper';
import {DownloadButton} from '../Cards/components/Buttons';
import Download from '../Cards/Download';
import CalloutCard from '../Cards/Callout';
import SearchBar from '../SearchBar';

const Container = styled.div`
`;

const Downloads = ({content, themePalette}) => {
  const [searchTerm, setSearchTerm] = useState();

  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const selectedSection = urlParams.get('section');

  const filteredDownloads = content
    .find(({name}) => name === 'Downloads').content
    .filter(item => selectedSection && selectedSection !== 'all' ? item.section === selectedSection : true)
    .filter(item => searchTerm ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : true);

  useEffect(() => {
    if (!searchTerm || !selectedSection) return;
    urlParams.set('section', 'all');
    history.replace({
      pathname: window.location.pathname,
      search: `?${urlParams}`
    });
  }, [searchTerm]);


  return (
    <Container>
      <SearchBar onChange={e => setSearchTerm(e.target.value)} value={searchTerm} placeholder={`Start typing to filter`}/>

      <PageSection>
        {filteredDownloads.map((item, index) => (
          <Download
            key={`${item.name}-${index}`}
            name={item.name}
            description={item.description}
            onClick={() => window.open(item.route, '_blank')}
            actions={[
              <DownloadButton themePalette={themePalette} />
            ]}
          />
        ))}
        {filteredDownloads.length === 0 && (
          <CalloutCard
            children={`Nothing found. Try searching for something else, or try using the search bar at the top of the page.`}
          />
        )}
      </PageSection>
    </Container>
  );
};

Downloads.propTypes = {
  content: PropTypes.array,
  themePalette: PropTypes.object.isRequired,
  data: PropTypes.shape({}),
};
Downloads.defaultProps = {
  content: [],
  data: {},
};

export default Downloads;
