import React, {Component, createContext, useContext} from 'react';

import styled from 'styled-components';

const notificationHeight = 45;
const defaultNotificationColor = '#57bc76';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Notification = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .75s;
  position: fixed;
  bottom: ${({show}) => show ? 24 : -notificationHeight}px;
  left: 24px;
  padding: 0px 24px;
  background: ${({color}) => color};
  color: white;
  border-radius: 10px;
  font-weight: 600;
  height: ${notificationHeight}px;
  z-index: 10;
`;

const NotificationContext = createContext();

class NotificationProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: null,
      show: false,
      setNotification: this.setNotification,
      color: null,
    };
  }

  setNotification = (notification, timeout=2000, color=defaultNotificationColor) => {
    this.setState({notification, show: true, color}, () => {
      setTimeout(() => this.setState({show: false}), timeout);
    });
  };

  render() {
    return (
      <NotificationContext.Provider value={this.state.setNotification}>
        <Container>
          {this.props.children}
          {<Notification show={this.state.show} color={this.state.color}>{this.state.notification}</Notification>}
        </Container>
      </NotificationContext.Provider>
    );
  }
};

const useNotificationContext = () => useContext(NotificationContext);

export {NotificationContext, NotificationProvider, useNotificationContext};
