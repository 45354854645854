import React, {useState} from 'react';
import styled from 'styled-components';
import {H2, H4, Body2, Body3} from '@bootcamp/web/src/components/Typography';
import {Button} from '@bootcamp/web/src/components/Branding/Buttons';
import Switch from 'rc-switch';
import "rc-switch/assets/index.css";
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 64px;
  max-width: 600px;
  overflow: auto;
`;

const Header = styled(H2)`
  text-align: left;
  margin-bottom: 16px;
`;

const Body = styled(Body2)`
  color: #585962;
  margin-bottom: 16px;
`;

const OptionBox = styled.div`
  border: 1px solid #EDEDF0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px;
`;

const OptionTitle = styled(H4)`
`;
const OptionDescription = styled(Body3)`
`;
const OptionContents = styled.div`
  max-width: 85%;
`

const StyledSwitch = styled(Switch)`
  &:after {
    transform: none !important;
    animation-name: none !important;
  }
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #EDEDF0;
  padding: 16px 0px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const Confirm = styled(Button)`
  margin-top: 32px;
  margin-left: auto;
`;

const TestSettings = ({close, themePalette, isFullLengthTest}) => {
  const {saveUserInteraction, searchUserInteractions, bootcamp} = useUserDataContext();
  const prometricDelay = searchUserInteractions('prometricDelay') === true;
  const testTitle = isFullLengthTest ? `Full Length Practice Test` : `${bootcamp.toUpperCase()} Simulator`
  return (
    <Container onClick={e => e.stopPropagation()}>
      <Header>{testTitle} Settings</Header>
      <Body>
        Customize your {isFullLengthTest ? testTitle.toLowerCase() : testTitle} experience using the options below.
      </Body>
      <OptionBox>
        <Option>
          <OptionContents>
            <OptionTitle>Prometric Delay</OptionTitle>
            <OptionDescription>Introduce a ~2 second delay when changing questions and when clicking review, just like the real Prometric {bootcamp.toUpperCase()}.</OptionDescription>
          </OptionContents>
          <StyledSwitch checked={prometricDelay} onChange={() => saveUserInteraction('prometricDelay', !prometricDelay)} />
        </Option>
        <Option>
          <OptionContents>
            <OptionTitle>Timing Accommodations</OptionTitle>
            <OptionDescription>Right-click the test timer (top-right) to set a custom time limit during your test.</OptionDescription>
          </OptionContents>
        </Option>
      </OptionBox>
      <Confirm fixed onClick={close} colorConfig={themePalette}>
        Save and Continue
      </Confirm>
    </Container>
  )
}

export default TestSettings;
