import React, {useEffect, useState, useMemo} from 'react';

import {H3, H4, Body3} from '../../Typography';

import {getTestBlock} from '@bootcamp/shared/src/requests';
import {findInConfigById, formatDurationString} from '@bootcamp/shared/src/util';

import styled from 'styled-components';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';
import {InformationCircle} from '@styled-icons/heroicons-outline/InformationCircle';
import {Play} from '@styled-icons/heroicons-solid/Play';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  border-radius: 8px;
  background: white;
  overflow: hidden;
  margin-bottom: 32px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const TextWrapper = styled.div`
  padding: 0 ${({theme}) => theme.layouts.spacing.m};
  flex: 1;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin: ${({theme}) => theme.layouts.spacing.m} 0;
    padding-left: 0;
  }
`;
const Duration = styled(H4)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
  margin-right: ${({theme}) => theme.layouts.spacing.ml};
`;
const PlayButton = styled(Play)`
  color: ${({theme}) => theme.colors.brandPalette.blue.default};
`;
const VideoTitle = styled(H3)`
`;
const SubjectText = styled(Body3)`
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};
  border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`;
const HeaderText = styled(H3)`
  margin-right: ${({theme}) => theme.layouts.spacing.ms};
`;
const InformationIcon = styled(InformationCircle)`
  color: ${({theme}) => theme.colors.neutralsPalette.lightGrey};
`;
const VideoContainer = styled.a`
  display: flex;
  flex-flow: row wrap;
  text-decoration: none;
  flex: 1;
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
`;
const Thumbnail = styled.div`
  background: url("${({vimeoId}) => `https://vercel-vimeo.vercel.app/api/vimeo?id=${vimeoId}&size=3`}");
  background-size: 100%;
  position: relative;
  width: 280px;
  height: 157px;


  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
    padding-bottom: 56%;
  }
`;

function parseVideoData(string) {
  try {
    return JSON.parse(string);
  } catch (error) {
    return [];
  }
}

function parseVimeoIdFromBlockContents(block) {
  try {
    const videoComponent = block?.components?.find(({renderType}) => renderType === 'video');
    return JSON.parse(videoComponent?.contents);
  } catch (error) {
    return {};
  }
}

async function fetchTestBlock(id) {
  try {
    const result = await getTestBlock(id);
    return result?.data?.getTestBlock;
  } catch (error) {
    return {};
  }
}

function assembleRouteFromConfig(bootcamp, configMatch, blockId) {
  const baseRoute = configMatch?.reduce((acc, {route}) => route ? `${acc}/${route}` : acc, `/${bootcamp}`);
  return `${baseRoute}?blockId=${encodeURIComponent(blockId)}`;
}

function formatConfigMatch(config, testId) {
  return findInConfigById(config, testId)?.reverse()?.slice(0, -1);
}

const RelatedVideo = ({blockId, blockName, testId}) => {
  const [block, setBlock] = useState(null);

  const {bootcamp} = useUserDataContext();
  const {config} = useBootcampConfigContext();

  const {id: vimeoId, duration} = parseVimeoIdFromBlockContents(block);
  const configMatch = useMemo(() => formatConfigMatch(config, testId), [testId]);
  const subjectMatch = configMatch?.[0];
  const route = assembleRouteFromConfig(bootcamp, configMatch, blockId);


  useEffect(() => {

    async function getRelatedVideo() {
      const testBlock = await fetchTestBlock(blockId);
      setBlock(testBlock)
    }

    getRelatedVideo(blockId);

  }, []);

  return (
    <VideoContainer href={route} target="_blank">
      <Thumbnail vimeoId={vimeoId}/>
      <ContentWrapper>
        <TextWrapper>
          <VideoTitle>{blockName}</VideoTitle>
          <SubjectText>{subjectMatch?.name}</SubjectText>
        </TextWrapper>
        <Duration>{formatDurationString(duration)}</Duration>
        <PlayButton size={40}/>
      </ContentWrapper>
    </VideoContainer>
  );
}

const RelatedVideos = ({videos}) => {
  const parsedVideos = parseVideoData(videos);
  if (!parsedVideos?.length) return null;
  return (
    <Container>
      <Header>
        <HeaderText>Related Lessons</HeaderText>
        <InformationIcon title="Related lessons help you learn more about the concept presented in this question." size={18}/>
      </Header>
      <Wrapper>
        {parsedVideos.map(({testId, testBlockConnectionTestBlockId, blockName}) => (
          <RelatedVideo
            key={testBlockConnectionTestBlockId}
            testId={testId}
            blockId={testBlockConnectionTestBlockId}
            blockName={blockName}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

RelatedVideos.defaultProps = {};
RelatedVideos.propTypes = {};

export default RelatedVideos;
