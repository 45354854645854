import React, {useState, useRef, useEffect} from 'react';
import styled, {css} from 'styled-components';

import ProgressBar from '../../../../components/ProgressBar';
import {Column, Table, Blurb, BlurbText, BlurbIcon, LineLabelContainer, LineLabelWrapper} from './shared';

import {formatTimeSpent} from '@bootcamp/shared/src/util';
import {Label1, Label2} from '@bootcamp/web/src/components/Typography/next';

import emily from '@bootcamp/shared/src/assets/educators/emily_giddings.jpeg';
import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import anthony from '@bootcamp/shared/src/assets/images/inbde/anthony_roviso.jpeg';

import theme from '@bootcamp/shared/src/styles/theme';
import {useConfigState} from '../context';
import GaugeChart from './Gauge';


const Container = styled(Column)`
  padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l} ${theme.layouts.spacing.l}`};
`;
const ScoreBarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  padding: 24px 0px;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 24px 0px 28px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  gap: 32px;

  ${({theme}) => theme.mediaQueries.tablet} {
    flex-flow: column;
    gap: 0px;
    overflow: hidden;
  }
`;
const ScoreBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;
const Overlays = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 8px;
`;
const OverlayWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const ScoreBar = styled(ProgressBar)`
  margin: ${({theme}) => `${theme.layouts.spacing.l} 0`};
  > div {
    padding: 8px;
    border-radius: 16px;
  }

  span {
    height: 16px;
    border-radius: 16px;
    background: ${({theme, backgroundColor}) => backgroundColor || theme.colors.interfacePalette.green.gradient};
    width: ${({percentage}) => percentage}%;
  }
`;
const ScoreTableWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 386px;
  flex: 1;


  ${({theme}) => theme.mediaQueries.tablet} {
    width: auto;
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    min-width: 0;
  }
`;
const StyledTable = styled(Table)`
  td {
    width: auto;
    text-align: left !important;
  }
  td: first-child {
    text-align: left;
    padding: 0 24px;
    width: 50%;
  }
  td:last-child * {
    color: ${({theme}) => theme.colors.typographyPalette.secondary};
  }
  tr:last-child {
    border-bottom: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
  }
`;
const TooltipContainer = styled.div`
  color: ${({theme, position}) => position === 'above' ? theme.colors.neutralsPalette.white : theme.colors.neutralsPalette.grey};
  padding: ${({theme}) => theme.layouts.spacing.s};
  border-radius: 8px;
  position: absolute;
  min-width: 150px;
  white-space: nowrap;
  left: calc(${({left}) => `${(left)}%`} ${({adjustment}) => (adjustment?.left || adjustment?.right) ? `${adjustment.left ? '+' : '-'} ${adjustment.left || adjustment.right}px` : ''} - 75px);
  text-align: center;
  z-index: 10;
  font-size: 14px;
  font-weight: 700;
  ${({position}) => position === 'above' ? 'top' : 'bottom'}: -26px;
  background: ${({position, theme, backgroundColor}) => position === 'above' ? backgroundColor || theme.colors.brandPalette.blue.default : theme.colors.neutralsPalette.offWhite};
  opacity: ${({adjustment}) => adjustment ? 1 : 0};
  font-size: 16px;
  font-family: 'proxima-nova';

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;
    left: calc(50% ${({adjustment}) => (adjustment?.left || adjustment?.right) ? `${adjustment.left ? '-' : '+'} ${adjustment.left || adjustment.right}px` : ''});
    margin-left: -6px;
    background: ${({position, theme, backgroundColor}) => position === 'above' ? backgroundColor || theme.colors.brandPalette.blue.default : theme.colors.neutralsPalette.offWhite};
    ${({position}) => position === 'above' ? 'border-bottom-right-radius' : 'border-top-left-radius'}: 4px;
    ${({position}) => position === 'above' ? 'bottom' : 'top'}: -6px;
    transform: rotate(${({position}) => position === 'above' ? '45deg': '45deg'});
  }
`;
const ScoreSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  flex: 1;

  ${Label2} {
    color: ${({theme, palette, active}) => active ? palette.default : theme.colors.typographyPalette.primary};
    font-weight: ${({active}) => active ? 'bold' : 'normal'};
    margin-bottom: 8px;
  }
`;
const LabelWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
  padding-bottom: 16px;

  ${Label2} {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    margin-bottom: 0;
    text-overflow: ellipsis;

  }
`;
const Top = styled.div`
`;
const Bottom = styled.div`
`;
const ScoreSection = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({index}) => 48 + (index * 22)}px;
  user-select: none;

  ${Top} {
    width: 100%;
    height: 7px;
    background: ${({active, palette}) => palette.default};

    border-radius: 20px 20px 0px 0px;
    background: ${({theme, palette, active}) => active ? palette.gradient : theme.colors.backgroundPalette.secondary};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06) inset;
  }
  ${Bottom} {
    flex: 1;
    background: ${({theme, palette, active}) => active ? palette.light : theme.colors.backgroundPalette.secondary};
  }
`;

const ReadinessScoreBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  padding: ${({theme}) => `${theme.layouts.spacing.xl} 0`};
  margin: 0 auto;

  ${Label1} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};

    span {
      color: ${({color}) => color};
    }
  }

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
    padding: 0;
    min-width: auto;
  }
`;
const ReadinessScoreBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StyledBlurb = styled(Blurb)`
  position: relative;
  padding: 0;

  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: 28px;
    ${BlurbIcon} {
      top: -36px !important;
      left: 0px !important;
    }
  }

  ${BlurbText} {
    background: ${({ theme, lightMode }) => lightMode || theme.darkModeEnabled ? theme.overlays.opacity.light._100 : theme.colors.brandPalette.royal.light};
    color: ${({ theme, lightMode }) => lightMode ? 'white' : 'initial'};
    border-radius: 8px;
    padding: ${({ theme, icon }) => `${theme.layouts.spacing.ms} ${theme.layouts.spacing.l}`};
    margin-left: ${({ icon }) => icon ? '36px' : '0px'};

    ${({theme}) => theme.mediaQueries.tablet} {
      margin-left: 0px;
      padding: ${({theme}) => `${theme.layouts.spacing.ms} ${theme.layouts.spacing.l}`};
    }

  }

  ${BlurbIcon} {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 3px solid ${({theme}) => theme.overlays.opacity.light._100};
    top: calc(50% - 24px);
  }
`;
const StyledLineLabelWrapper = styled(LineLabelWrapper)`
  top: 0;
  ${({avgScore}) => avgScore <= 45
    ? css`left: 0;`
    : css`right: 0;`
  }
`;


const Tooltip = ({containerRef, ...props}) => {
  const [adjustment, setAdjustment] = useState(null);

  const ref = useRef();

  useEffect(() => {
    if (!containerRef?.current || !ref?.current || (!props.left && props.left !== 0) || props.left === 'NaN' || !!adjustment) return;

    const containerPosition = containerRef?.current?.getBoundingClientRect();
    const tooltipPosition = ref?.current?.getBoundingClientRect();

    const leftBound = containerPosition?.x - 22;

    // if tooltip position x is less than container position, set left adjustment
    if (tooltipPosition?.x  < leftBound) {
      return setAdjustment({left: Math.round(leftBound - tooltipPosition.x)});
    }

    // if tooltip position x + width is greater than container position x + width set right adjustment
    else if ((tooltipPosition?.x + tooltipPosition?.width + 28) > (containerPosition?.x + containerPosition?.width)) {
      return setAdjustment({right: ((tooltipPosition?.x + tooltipPosition.width) - (containerPosition?.x + containerPosition?.width) - 28)});
    }

    else {
      setAdjustment({left: 0, right: 0});
    }

  }, [containerRef?.current, ref?.current, props.left]);

  return (
    <TooltipContainer ref={ref} adjustment={adjustment} {...props}/>
    );
};

const getReadinessExamFeedback = (score, subjects, bootcamp) => {
  const styledSubjects = subjects.map(subject => <strong>{subject}</strong>)
  if (bootcamp === 'nclex') {
    switch (score) {
      case 'Low':
        return <span>You would have a <strong>Low</strong> chance of passing if you took the NCLEX today. But here's the good news! You can improve your likelihood of passing by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go and take another Readiness exam in 1-2 weeks to reassess and see your improvement!</span>
      case 'Borderline':
        return <span>You would have a Borderline chance of passing if you took the NCLEX today. Almost there! You can improve your likelihood of passing by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go and take the next Readiness exam in 1-2 weeks to reassess and see your improvement!</span>
      case 'High':
        return <span>You would have a High chance of passing if you took the NCLEX today. Great job! Continue improving by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go and take the next Readiness exam in 1-2 weeks to reassess and see your improvement!</span>
      case 'Very High':
        return <span>You would have a Very High chance of passing if you took the NCLEX today. Great job! Keep practicing your critical thinking skills, especially in the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go and take the next Readiness exam in 1-2 weeks!</span>
      default:
        break;
    }
  }
  if (bootcamp === 'med-school') {
    switch (score) {
      case 'Low':
        return <span>You would have a <strong>Low</strong> chance of passing if you took Step 1 today. But here's the good news! You can improve your likelihood of passing by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      case 'Borderline':
        return <span>You would have a Borderline chance of passing if you took Step 1 today. Almost there! You can improve your likelihood of passing by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      case 'High':
        return <span>You would have a High chance of passing if you took Step 1 today. Great job! Continue improving by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      case 'Very High':
        return <span>You would have a Very High chance of passing if you took Step 1 today. Great job! Keep practicing your critical thinking skills, especially in the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      default:
        break;
    }
  }
  if (bootcamp === 'inbde') {
    switch (score) {
      case 'Low':
        return <span>You would have a <strong>Low</strong> chance of passing if you took the INBDE today. But here's the good news! You can improve your likelihood of passing by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      case 'Borderline':
        return <span>You would have a Borderline chance of passing if you took the INBDE today. Almost there! You can improve your likelihood of passing by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      case 'High':
        return <span>You would have a High chance of passing if you took the INBDE today. Great job! Continue improving by practicing questions from the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      case 'Very High':
        return <span>You would have a Very High chance of passing if you took the INBDE today. Great job! Keep practicing your critical thinking skills, especially in the {styledSubjects[0]} & {styledSubjects[1]} sections. <strong>Read explanations carefully</strong> as you go.</span>
      default:
        break;
    }
  }
}

const readinessExamGrade = {
  0: 'Low',
  1: 'Borderline',
  2: 'High',
  3: 'Very High',
}

export const getReadinessExamGrade = (score, maxScore, bootcamp) => {
  if (bootcamp === 'nclex') {
    const yourScorePercentage = Math.round((score / maxScore) * 100);
    const readinessExamScore = yourScorePercentage < 50 ? 0 : yourScorePercentage <= 60 ? 1 : yourScorePercentage <= 70 ? 2 : 3;

    return {gradeLabel: readinessExamGrade[readinessExamScore], grade: readinessExamScore};
  }
  if (bootcamp === 'med-school') {
    const yourScorePercentage = Math.round((score / maxScore) * 100);
    const readinessExamScore = yourScorePercentage < 50 ? 0 : yourScorePercentage <= 59 ? 1 : yourScorePercentage <= 66 ? 2 : 3;

    return {gradeLabel: readinessExamGrade[readinessExamScore], grade: readinessExamScore};
  }
  if (bootcamp === 'inbde') {
    const yourScorePercentage = Math.round((score / maxScore) * 100);
    const readinessExamScore = yourScorePercentage < 60 ? 0 : yourScorePercentage <= 64 ? 1 : yourScorePercentage <= 69 ? 2 : 3;

    return {gradeLabel: readinessExamGrade[readinessExamScore], grade: readinessExamScore};
  }
  return {}
}

const Score = () => {
  const {scoring, selectedQuestions, bootcamp, isReadinessExam} = useConfigState();
  const yourScore = scoring?.overview?.score;
  const maxScore = scoring?.overview?.maxScore;

  const [showLineLabel, setshowLineLabel] = useState(true);

  const yourScorePercentage = Math.round((yourScore / maxScore) * 100);
  const avgScorePercentage = Math.round((scoring?.overview?.avgScore / maxScore) * 100);


  const containerRef = useRef();
  const avgTimePerQuestion = scoring?.overview?.avgTime / scoring?.overview?.totalQuestions;


  const readinessExamSegments = [
    {count: 1, palette: theme.colors.interfacePalette.red},
    {count: 2, palette: theme.colors.interfacePalette.yellow},
    {count: 3, palette: theme.colors.interfacePalette.green},
    {count: 4, palette: theme.colors.interfacePalette.green},
  ];
  const {grade, gradeLabel} = getReadinessExamGrade(yourScore, maxScore, bootcamp);
  const readinessExamSegment = readinessExamSegments[grade];

  // scoring.subjectBreakdown is an object with keys of subject names and values of objects with keys of 'score', 'maxScore', 'avgScore', 'totalTime', 'totalQuestions'
  // find two lowest scoring subjects
  const subjects = isReadinessExam && bootcamp === 'inbde' ? Object.keys(scoring.subjectBreakdown['Simulation Exam'].systems).sort((a, b) => (scoring.subjectBreakdown['Simulation Exam'].systems[a].score / scoring.subjectBreakdown['Simulation Exam'].systems[a].maxScore) - (scoring.subjectBreakdown['Simulation Exam'].systems[b].score / scoring.subjectBreakdown['Simulation Exam'].systems[b].maxScore)).slice(0, 2)
  : isReadinessExam ? Object.keys(scoring.subjectBreakdown).sort((a, b) => (scoring.subjectBreakdown[a].score / scoring.subjectBreakdown[a].maxScore) - (scoring.subjectBreakdown[b].score / scoring.subjectBreakdown[b].maxScore)).slice(0, 2) : ['',''];
  const feedback = isReadinessExam
    ? getReadinessExamFeedback(gradeLabel, subjects, bootcamp)
    : bootcamp === 'anatomy' && yourScorePercentage >= avgScorePercentage ? `Nice job! Continue to review your areas of knowledge. Keep up the good work!`
    : bootcamp === 'anatomy' && yourScorePercentage < avgScorePercentage ? `Keep your head up! Review these solutions carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice.`
    : yourScorePercentage === avgScorePercentage
    ? `Nice job! You scored right on the average of ${avgScorePercentage}%. Continue to review your areas of knowledge and you’ll be on your way to passing ${bootcamp === 'inbde' ? 'the INBDE' : bootcamp === 'nclex' ? 'the NCLEX' : 'Step 1'} in no time!`
    : yourScorePercentage >= avgScorePercentage
    ? `Nice job! You scored higher than the average of ${avgScorePercentage}%. Continue to review your areas of knowledge and you’ll be on your way to passing ${bootcamp === 'nclex' ? 'the NCLEX' : bootcamp === 'inbde' ? 'the INBDE' : 'Step 1'} in no time!`
    : `Keep your head up! Review these solutions carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice.`;

    useEffect(() => {
      const handleResize = () => {
        if (!containerRef?.current) return;

        const refDimensions = containerRef.current.getBoundingClientRect();
        if (refDimensions.width <= 460) setshowLineLabel(false);
        else setshowLineLabel(true);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <Container>
      <Wrapper>
        <ScoreBarContainer>
          {!showLineLabel && !isReadinessExam && (avgScorePercentage <= 45 || avgScorePercentage >= 55) &&
            <StyledLineLabelWrapper avgScore={avgScorePercentage}>
                Avg. Score {avgScorePercentage}%
            </StyledLineLabelWrapper>
          }
          {
            isReadinessExam ? (
              <ReadinessScoreBar color={readinessExamSegments[grade].palette.default}>
                <Label1>Chance of Passing: <span>{readinessExamGrade[grade]}</span></Label1>
                <ReadinessScoreBarWrapper>
                  {readinessExamSegments.map((segment, index) => (
                    <ScoreSectionWrapper
                      active={index === grade}
                      palette={segment.palette}>
                      <LabelWrapper>
                        <Label2>{readinessExamGrade[index]}</Label2>
                      </LabelWrapper>
                      <ScoreSection
                        active={index === grade}
                        palette={segment.palette}
                        index={index}>
                        <Top/>
                        <Bottom/>
                      </ScoreSection>
                    </ScoreSectionWrapper>
                  )
                  )}
                </ReadinessScoreBarWrapper>
              </ReadinessScoreBar>
            ) : (
            <ScoreBarWrapper ref={containerRef}>
              <GaugeChart value={yourScorePercentage} avgScore={avgScorePercentage} showLineLabel={showLineLabel || (avgScorePercentage >= 45 && avgScorePercentage <= 55 )} />
            </ScoreBarWrapper>
            )
          }
        </ScoreBarContainer>
        <ScoreTableWrapper>
          <StyledTable
            fixed={false}
            hideChevrons
            rows={[
              {cells: [{content: ''}, {content: 'You'}, {content: 'Average'}]},
              {cells: [{content: 'Score'}, {content: `${yourScorePercentage}%`}, {content: `${avgScorePercentage}%`}]},
              {cells: [{content: 'Correct / Total'}, {content: `${yourScore} / ${maxScore}`}, {content: `${scoring?.overview?.avgScore.toFixed(1)} / ${maxScore}`}]},
              {cells: [{content: 'Time Per Question'}, {content: formatTimeSpent(scoring?.overview?.totalTime / scoring?.overview?.totalQuestions)}, {content: formatTimeSpent(avgTimePerQuestion)}]},
              {cells: [{content: 'Total Time'}, {content: formatTimeSpent(scoring?.overview?.totalTime)}, {content: formatTimeSpent(scoring?.overview?.avgTime)}]},
            ]}
          />
        </ScoreTableWrapper>
      </Wrapper>
      <Column>
        <StyledBlurb icon={bootcamp === 'nclex' ? emily : bootcamp === 'inbde' ? ari : anthony} text={feedback}/>
      </Column>
    </Container>
  );
};

export default Score;