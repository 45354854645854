import correctIcon from '@bootcamp/shared/src/assets/nclex/correct.png';
import incorrectIcon from '@bootcamp/shared/src/assets/nclex/incorrect.png';

export function renderDropdown(e, answerGroup, answerState, offset) {
  const dropdowns = document.querySelectorAll('.dropdownDisplay');
  dropdowns.forEach(e => e.remove());
  const dropdownDisplay = document.createElement('div');
  dropdownDisplay.className = 'dropdownDisplay';
  dropdownDisplay.innerHTML = JSON.parse(answerGroup.contents).reduce((acc, [answer, isCorrect]) => [null, undefined].includes(answer) ? acc : acc + `<div><img src="${isCorrect ? correctIcon : answerState?.[0] === answer ? incorrectIcon : ''}" className="${isCorrect ? 'score-icon-correct' : answerState?.[0] === answer ? 'score-icon-incorrect' : ''}" style="left: 5px;"/> ${answer}</div>`, '')
  e.target.insertAdjacentElement("afterend", dropdownDisplay)
  const outsideClickHandler = event => {
    if (event.target.classList.contains('dropdownCover') && dropdowns.length == 0) return;
    document.querySelectorAll('.dropdownDisplay').forEach(e => e.remove());
    document.body.removeEventListener('click', outsideClickHandler)
  }
  document.body.addEventListener('click', outsideClickHandler)
}

export function displayScoredDropdownUI(node, answerGroups, answerState) {
  const dropdowns = node.current.querySelectorAll('[data-dropdown=""]') || [];

  dropdowns.forEach((dropdown, index) => {
    dropdown.disabled = true;

    const children = Array.from(dropdown.parentNode.children);
    const scoreWrapper = document.createElement('div');
    dropdown.parentNode.prepend(scoreWrapper);
    children.forEach(child => {
      scoreWrapper.appendChild(child);
    });

    scoreWrapper.className = 'dropdown-score-wrapper dropdown-score-wrapper-active';

    const icon = new Image();

    const relevantAnswerState = answerState[index];
    if (relevantAnswerState?.[1]) {
      // did select correct answer
      icon.src = correctIcon;
      icon.className = 'score-icon-correct';
      dropdown.style.border = '1px solid limegreen';
    } else {
      icon.src = incorrectIcon;
      icon.className = 'score-icon-incorrect'
      dropdown.style.border = '1px solid red';
    }
    scoreWrapper.prepend(icon);

    dropdown.value = answerState?.[index]?.[0];
    dropdown.selectedIndex = answerState?.[index]?.[2];
  });
}

export function displayScoredMatrixUI(node, matrix, answerState, type) {
  if (!node) return;

  const cells = node.getElementsByTagName('td');


  Array.from(cells).forEach(cell => {
    // determine row & cell indicies
    const cellIndex = cell.cellIndex;
    const rowIndex = cell.parentElement.rowIndex;

    // find all inputs for a given cell
    const inputs = cell.getElementsByTagName('input');

    // add scoring UI for each input within cell
    Array.from(inputs).forEach((input, inputIndex) => {

      if (input.disabled) return;

      // disable input to prevent further interaction
      input.disabled = true;

      // create score UI container
      const scoreUI = document.createElement('span');
      scoreUI.className = `${type}-score-container`;

      // if there are more than 1 input in a given cell, we assume that answer components
      // will be nested accordingly, otherwise we score assuming 1 input per cell
      const correct = inputs?.length > 1
        ? matrix?.[rowIndex - 1]?.[cellIndex - 1]?.[inputIndex]
        : matrix?.[rowIndex - 1]?.[cellIndex - 1];

      const selectedAnswer = inputs?.length > 1
        ? answerState?.[rowIndex - 1]?.[cellIndex - 1]?.[inputIndex]
        : answerState?.[rowIndex - 1]?.[cellIndex - 1];

      const icon = new Image();
      icon.className = 'score-icon';

      input.checked = selectedAnswer;

      // generate scoring UI
      if (!selectedAnswer && !correct) {
        icon.className = 'score-icon-empty';
      } else if (correct) {
        icon.src = correctIcon;
        icon.className = 'score-icon-correct';
      } else {
        icon.src = incorrectIcon;
        icon.className = 'score-icon-incorrect'
      }

      scoreUI.appendChild(icon)

      input.parentElement.prepend(scoreUI);
    });

  });
}

export function syncMatrixWithAnswerState(table, answerState) {
  const cells = table.getElementsByTagName('td');

  Array.from(cells).forEach(cell => {
    // determine row & cell indicies
    const cellIndex = cell.cellIndex - 1;
    const rowIndex = cell.parentElement.rowIndex - 1;

    // find all inputs for a given cell
    const inputs = cell.getElementsByTagName('input');

    Array.from(inputs).forEach((input, index) => {
      const checked = answerState?.[rowIndex]?.[cellIndex];
      // if checked is array, we're looking at a cell with multiple inputs
      const isGrouping = Array.isArray(checked);

      if (isGrouping) {
        input.checked = checked[index];
      } else {
        input.checked = checked;
      }
    });
  });
}

export function getAnswerMatrix(htmlString, domNode) {
  if (!htmlString && !domNode) return [];

  let root;

  // if we're looking at innerHTML, create a root container
  // otherwise just use the live dom node
  if (htmlString) {
    const root = document.createElement('div');
    root.innerHTML = htmlString;
  } else {
    root = domNode;
  }

  const table = root.getElementsByTagName('table');

  const rows = table?.[0]?.children?.[0]?.children;

  return Array
    ?.from(rows)
    .reduce((acc, row, index) => {
      // return for first row since that is our table header row
      if (index === 0) return acc;

      const cells = Array?.from(row?.children).reduce((acc2, cell, cellIndex) => {
        if (cellIndex === 0) return acc2;

        const inputs = cell.getElementsByTagName('input');

        const inputValues = Array.from(inputs).map(input => input?.checked);
        /*
          this ternary is changing the way a cell's input values are save
          so that we can save a matrix that is representitave of the # of inputs
          in the cell.

          eg. matrix & matrix multiple choice
          [
            [true, false, false], *row-1
            [false, true, false], *row-2
          ]

          eg. multi response grouping
          [
            [*row-1
              [true, false, true], *group-1
              [true, true, true], *group-2
              [true, false, false]  *group-3
            ],
            [*row-2
              [true, false, false], *group-1
              [false, false, false], *group-2
              [true, false, true]  *group-3
            ]
          ]
        */
        const valueArray = inputValues.length > 1 ? [inputValues] : inputValues;

        return [...acc2, ...valueArray];
      }, [])

      return [...acc, cells];

  }, []);
}
