import React, {useMemo} from 'react';
import styled from 'styled-components';

import {Container, Label, Count, Header, CustomInputWrapper, InputCheck, InputHoverWrapper} from './shared';
import {useCreateTestState} from '../context';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({theme}) => theme.layouts.spacing.m};
  cursor: pointer;
`;
const Input = styled.input`
  margin: 0;
  opacity: 0;
  height: 0;
  width: 0;
`;
const StyledCustomInputWrapper = styled(CustomInputWrapper)`
  border-radius: 18px;
`;

const valuesByBootcamp = {
  'med-school': [
    ['preclinical', 'Step 1 Qbank'],
    ['bites', 'Bites'],
    ['anatomy', 'Anatomy Bootcamp'],
  ],
  'inbde': [
    ['questionBank', 'INBDE Question Bank'],
    // ['mockExam', 'INBDE Simulation Exam'],
  ]
};

const Type = ({bootcamp}) => {
  const {
    methods: {
      setType,
    },
    variables: {
      loading,
      type,
      state,
      questionCounts,
    }
  } = useCreateTestState();

  const totals = useMemo(() => {

    if (!questionCounts) return;

    return Object.keys(state).reduce((acc, type) => ({
      ...acc,
      [type]: Object.values(state[type])?.reduce((acc, subject) => {
        return acc + Object.keys(subject.children)?.reduce((acc, testId) => {
          const nestedChildren = subject?.children?.[testId]?.children;

          if (!questionCounts[testId] && !nestedChildren) return acc;

          return nestedChildren
            ? acc + Object.keys(nestedChildren)?.reduce((acc, testId) => acc + Object.keys(questionCounts[testId] || {}).length, 0)
            : acc + Object.keys(questionCounts[testId] || {}).length;
        }, 0)
      }, 0)
    }), {})
  }, [state, questionCounts]);

  return (
    <Container>
      <Header>Question Type</Header>
      <Wrapper>
        {valuesByBootcamp[bootcamp]?.map(([key, title]) => (
          <InputWrapper onClick={() => setType(key)}>
            <InputHoverWrapper active={key === type} disabled={loading}>
              <StyledCustomInputWrapper htmlFor={key} checked={key === type} disabled={loading}>
                <Input type={'radio'} key={key} checked={key === type} disabled={loading} />
                {key === type && <InputCheck />}
              </StyledCustomInputWrapper>
              <Label disabled={loading}>{title}</Label>
              <Count disabled={loading} masteryLevel={key} loading={loading}>{loading ? '' : totals[key]}</Count>
            </InputHoverWrapper>
          </InputWrapper>
        ))}
      </Wrapper>
    </Container>
  );
};

export default Type;