import React from 'react';

import {Link} from 'react-router-dom';
import SVG from 'react-inlinesvg';

import styled, {css} from 'styled-components';

import {resolveIconPath} from '@bootcamp/web/src/helpers';

import IconButton from '../../../components/Branding/Buttons/IconButton';
import {FlexBox, Column, Row} from '../../../components/Grid';
import {H2, H3, Label2} from '../../../components/Typography';

import ProgressBar from '../../../components/ProgressBar';
import {ExpertEducators} from '../../../components/ExpertEducators';

import {ArrowForwardOutline} from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import {LocalFireDepartment} from '@styled-icons/material/LocalFireDepartment';
import theme from '@bootcamp/shared/src/styles/theme';

const LinkWrapper = styled(Link)`
  align-self: stretch;
  text-decoration: none;
  background: ${({theme, color, lightMode, whiteMode, backgroundImg}) => backgroundImg ? `linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.5) 100%), url(${backgroundImg})` : (lightMode || whiteMode) ? theme.colors.neutralsPalette.white : color};
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;

  border-radius: 8px;
  transition: all 100ms ease;
  overflow: hidden;

  ${({locked}) => !locked && css`
    &:hover {
      transform: scale(1.01);
      box-shadow: ${({theme}) => theme.elevation.shadow.high};

      & ${StyledIconButton} {
        background-color: ${({theme, lightMode, color}) => lightMode ? color : theme.overlays.opacity.light._100};
      }
    }
  `}

  ${({locked}) => locked && css`
    &:hover {
      cursor: not-allowed;

    }
  `}
  ${({theme}) => theme.mediaQueries.laptop} {
    width: 100%;
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledIconButton = styled(IconButton)`
  ${({lightMode}) => lightMode && css`
    width: 56px;
    height: 56px;
    background: ${({color}) => color};
  `}

  ${({whiteMode}) => whiteMode && css`
    width: 56px;
    height: 56px;
    background: ${({theme}) => theme.colors.neutralsPalette.extraLight};
  `}
`

const Container = styled(FlexBox)`
  position: relative;
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  flex-direction: column;
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  border-radius: 8px;

  &:hover {
    ${StyledIconButton} {
      background: ${({theme, whiteMode, themePalette}) => whiteMode ? themePalette.default : theme.overlays.opacity.light._200};
      svg {
        fill: white;
      }
    }

  }

  ${({theme}) => theme.mediaQueries.laptop} {
    flex-direction: row;
    max-width: 100%;

    & ${StyledIconButton} {
      display: none;
    }
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    min-height: auto;
    flex-direction: column;
    height: 100%;
  }

  ${({locked, theme}) => locked && css`
    background: ${theme.colors.special.naturalDark};
    border: 1px solid ${theme.overlays.opacity.dark._50};
    box-shadow: ${theme.elevation.inner.progressDark};

    & ${StyledIconButton} {
      display: none;
    }

    &:hover {
      transform: none;
      cursor: not-allowed;
    }
  `}
`;

const Header = styled(Column)`
  padding: ${({theme}) => theme.layouts.spacing.l} ${({theme}) => theme.layouts.spacing.l} ${({withPadding}) => withPadding ? '' : 0};

  ${({theme}) => theme.mediaQueries.laptop} {
    justify-content: space-between;
    padding-bottom: ${({theme}) => theme.layouts.spacing.l};
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    min-height: auto;
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const Title = styled(H2)`
  width: 100%;
  color: ${({theme, lightMode}) => (lightMode) ? theme.colors.neutralsPalette.extraDark : theme.colors.neutralsPalette.white};

  ${({theme}) => theme.mediaQueries.laptop} {
    height: auto;
  }
`;
const TitleSmall = styled(H3)`
  width: 100%;
  color: ${({theme}) => theme.colors.neutralsPalette.extraDark};
  height: 62px;

  ${({theme}) => theme.mediaQueries.laptop} {
    height: auto;
  }
`;


const ArrowForward = styled(ArrowForwardOutline)`
  color: ${({theme, lightMode, whiteMode, color}) => lightMode
    ? color
    : whiteMode
    ? theme.colors.neutralsPalette.lightGrey
    : theme.colors.neutralsPalette.white};
  height: 24px;
  width: 24px;
`

const IconsWrapper = styled(Row)`
  height: auto;
  justify-content: space-between;
  margin-bottom: ${({theme, lightMode}) => theme.layouts.spacing.m};
`

const Details = styled(Column)`
  border-top: ${({theme, withBorder}) => withBorder ? `1px solid ${theme.overlays.opacity.dark._100}` : 'none'};
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l} ${({theme}) => theme.layouts.spacing.l};
  flex-direction: ${({detailsOrientation}) => detailsOrientation === 'horizontal' ? 'row' : 'column'};
  align-items: center;
  justify-content: center;
`;
const Progress = styled(ProgressBar)`
  order: 1;
  width: 100%;
  margin-top: ${({theme, detailsOrientation}) => detailsOrientation === 'horizontal' ? 0 : theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.laptop} {
    align-items: flex-end;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    order: 2;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;
const ExpertEducatorsContainer = styled(ExpertEducators)`
  order: 2;
  justify-content: space-between;

  ${({theme}) => theme.mediaQueries.laptop} {
    justify-content: flex-end;
  }

  ${({theme}) => theme.mediaQueries.mobileL} {
    justify-content: flex-start;
    order: 1;
  }
`;
const Icon = styled.div`
  background: ${({lightMode, color}) => lightMode ? color : 'none'};
  border-radius: 100px;

  svg {
    width: 42px;
    height: 42px;
    stroke: ${({theme}) => theme.overlays.opacity.light._100};
  }

  ${({lightMode}) => lightMode && css`
    padding: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  `}

  ${({whiteMode}) => whiteMode && css`
    svg > path {
      fill: ${({theme, whiteMode, themePalette}) => whiteMode ? themePalette.default : theme.overlays.opacity.light._100};
    }

    svg > g > path {
      stroke: ${({theme, whiteMode, themePalette}) => whiteMode ? themePalette.default : theme.overlays.opacity.light._100};
    }
  `}

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.s};
  }
`;
const Arrow = styled(IconButton)`
  order: 1;
  margin-left: ${({theme}) => theme.layouts.spacing.l};
  align-self: ${({detailsOrientation}) => detailsOrientation === 'horizontal' ? 'center' : 'flex-end'};
`;

const defaultProgress = {
  reviewing: 0,
  learning: 0,
  mastered: 0,
  total: 0,
};

function getLaunchDate (name) {
  switch (name) {
    case 'General Chemistry':
      return 'Spring 2021';
    default:
    return 'Fall 2021'
  }
}

const NewReleaseBannerContainer = styled.div`
  width: 191px;
  background: white;
  border-radius: 8px;
  padding: ${({theme}) => `${theme.layouts.spacing.s} ${theme.layouts.spacing.l}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: ${({theme}) => theme.elevation.shadow.low};

  position: absolute;
  top: -17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  ${({theme}) => theme.mediaQueries.laptop} {
    display: none;
  }
  ${({theme}) => theme.mediaQueries.mobileL} {
    position: relative;
    top: 0;
    width: 149px;
    margin-right: 0;
    display: flex;
    border-radius: 100px;
    padding: ${({theme}) => `${theme.layouts.spacing.s} ${theme.layouts.spacing.m}`};
  }
`

const NewReleaseBannerText = styled(Label2)`
  font-weight: 600;
  color: ${({theme, themePalette}) => (themePalette && themePalette.dark) || theme.colors.brandPalette.pink.dark};
`;

const NewReleaseBannerIcon = styled(LocalFireDepartment).attrs(props => ({size: 16}))`
  color: ${({theme, themePalette}) => (themePalette && themePalette.dark) || theme.colors.brandPalette.pink.dark};
  margin-right: ${({theme}) => theme.layouts.spacing.s};
`;

const NewReleaseBanner = ({themePalette}) => (
  <NewReleaseBannerContainer>
    <NewReleaseBannerIcon themePalette={themePalette}/>
    <NewReleaseBannerText themePalette={themePalette}>New Release</NewReleaseBannerText>
  </NewReleaseBannerContainer>
)

const ClassroomCard = ({
  name,
  backgroundImg,
  icon,
  lightMode,
  className,
  route,
  themePalette,
  newRelease,
  questionCount,
  locked,
  showProgress,
  educators,
  showExpertEducators,
  showArrowButton,
  detailsOrientation,
  detailsBorder=true,
  progress,
  solo,
  educatorLayout,
  expertEducatorOrientation,
  withHeaderPadding=true,
  whiteMode=false,
  label=null,
  progressLabel,
  showVideoProgress,
  videoLessonCount,
  videoLessonProgress,
}) => {


  const loadingProgress = (progress === null || videoLessonProgress === null) && !locked;

  const {reviewing, learning, mastered} = progress || defaultProgress;

  const progressSegments = [
    {count: mastered},
    {count: reviewing},
    {count: learning}
  ];

  const containerProps = locked
    ? {as: FlexBox, color: 'transparent'}
    : {as: Link, to: route, color: themePalette.gradient};

  return (
    <LinkWrapper {...containerProps} className={className} locked={locked} solo={solo} lightMode={lightMode} whiteMode={whiteMode} backgroundImg={backgroundImg}>
      <Container locked={locked} whiteMode={whiteMode} themePalette={themePalette}>
        <Header lightMode={lightMode} withPadding={withHeaderPadding}>
          <IconsWrapper lightMode={lightMode}>
            {icon &&
              <Icon
                lightMode={lightMode}
                color={themePalette.gradient}
                children={<SVG src={resolveIconPath(icon)}/>}
                themePalette={themePalette}
                whiteMode={whiteMode}
                />
            }
            {newRelease && <NewReleaseBanner themePalette={themePalette}/>}
            <StyledIconButton type={'secondary'} size={'small'} lightMode={lightMode} whiteMode={whiteMode} color={themePalette.light}>
              <ArrowForward lightMode={lightMode} whiteMode={whiteMode}  color={themePalette.default}/>
            </StyledIconButton>
          </IconsWrapper>
          {whiteMode
            ? <TitleSmall>
                {name}
              </TitleSmall>
            : <Title as={whiteMode ? H3 : H2} lightMode={lightMode} whiteMode={whiteMode}>
                {name}
              </Title>
          }
          {showProgress &&
            <Progress
              loading={loadingProgress}
              segments={progressSegments}
              total={questionCount}
              label={progressLabel || (locked && `Coming ${getLaunchDate(name)}`)}
              detailsOrientation={detailsOrientation}
            />
          }
          {showVideoProgress &&
            <Progress
              loading={loadingProgress}
              segments={[{count: videoLessonProgress, color: theme.colors.brandPalette.blue.gradient}]}
              total={videoLessonCount}
              label={
                locked
                  ? `Coming ${getLaunchDate(name)}`
                  : <span>{<strong>{`${videoLessonProgress || 0}`}</strong>} of {`${videoLessonCount} lessons completed`}</span>
              }
              detailsOrientation={detailsOrientation}
            />
          }
        </Header>
        <Details detailsOrientation={detailsOrientation} withBorder={detailsBorder}>
          {showExpertEducators &&
            <ExpertEducatorsContainer
              educators={educators}
              lightMode
              whiteMode={lightMode}
              size={'small'}
              ledByOrientation={expertEducatorOrientation}
              ledByTextOrientation={'column'}
              orientation={'row'}
            />
          }
          {showArrowButton &&
            <Arrow detailsOrientation={detailsOrientation} size={'small'} type={'secondary'}>
              <ArrowForwardOutline size={24} color={'blue'}/>
            </Arrow>
          }
          {label && label}
        </Details>
      </Container>
    </LinkWrapper>
  );
}

export default ClassroomCard;
