import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useDocumentTitle from '@rehooks/document-title';
// import {Checkout} from '../../../components/Form';
import {useUserDataContext} from '../../../contexts/UserData';
import {FullPageSpinner} from '@bootcamp/web/src/components/Branding';
import styled from 'styled-components';
import {invokeLambda} from '@bootcamp/shared/src/requests'
const Loader = styled(FullPageSpinner)`
  background: ${({theme}) => theme.colors.brandPalette.royal.dark};
`

const CheckoutRedirect = ({location}) => {
  useDocumentTitle('Bootcamp.com | Checkout');

  const {bootcamp, cognitoUser} = useUserDataContext();
  const urlParams = new URLSearchParams(location.search);
  const priceId = urlParams.get('priceId');
  const mode = urlParams.get('mode');
  const afterpay = urlParams.get('afterpay');
  const {push} = useHistory();

  if (!priceId) window.location = 'https://bootcamp.com';
  useEffect(() => {
    async function setupCheckout () {

      if (cognitoUser.attributes?.email) {
        try {
          const {checkoutUrl} = await invokeLambda('tbc-Stripe-Checkout', {
            userMembershipsId: cognitoUser.getUsername(),
            email: cognitoUser.attributes.email,
            price: priceId,
            mode,
            bootcamp,
            afterpay,
          });
          window.location = checkoutUrl;
        } catch (error) {
          console.log('error setting up checkout', error);
          push('/');
        }
      }
    }
    setupCheckout();
  }, [cognitoUser])

  return (
    <Loader color="white" active/>
  );
};

CheckoutRedirect.propTypes = {};

export default CheckoutRedirect;
