import {colors} from '../styles/theme';

const config = [
  {
    name: 'Organic Chemistry',
    tagId: '13925cee-9aa5-4ddd-9a82-7e65274ef211',
    route: 'organic-chemistry',
    themePalette: colors.brandPalette.violet,
    questionCount: 328,
    icon: 'icons/oc-logo.svg',
    educators: [
      {
        firstName: 'Dr. Mike',
        avatar: 'images/mike.png',
      }
    ],
    chapters: [
      {
        name: 'Bonding and Molecular Geometry',
        tagId: '0ff8c014-edab-4f3f-9847-c51742b3fa72',
        videoLessonId: '6aeb90d0-4817-420c-92f2-15d89e521ef2',
        questionBankIds: ['0dfd58e8-d433-425d-af89-3946d4504c83'],
        route: 'bonding-and-molecular-geometry',
      },
      {
        name: 'Acids and Bases',
        tagId: 'f6ce6bc6-1d6c-4bc5-b4eb-105e30c62510',
        videoLessonId: '20decc73-e13c-4e43-82cd-a8c2a6a6df7f',
        questionBankIds: ['a7b8f2bf-fa69-4658-85aa-b7d994249fd5'],
        route: 'acids-and-bases'
      },
      {
        name: 'Nomenclature',
        tagId: '70e150b0-05ed-4e8f-9e61-16df3a3464e1',
        videoLessonId: '181107aa-4ff8-49cf-8908-9d511ddf6515',
        questionBankIds: ['2f3eda4b-ba44-4a20-ba55-c40190a7e49e'],
        route: 'nomenclature'
      },
      {
        name: 'Stereochemistry',
        tagId: 'f57f0e78-c0bf-44cb-8121-5161c4ae681d',
        videoLessonId: '98151b49-53a8-4f10-9dbf-5434fbe4bd26',
        questionBankIds: ['d58cae22-cbcc-4d53-88c4-a3a980527c6f'],
        route: 'stereochemistry'
      },
      {
        name: 'Alkenes and Alkynes',
        tagId: '9671ae9f-68ed-4bdb-9493-dca5fb60e56c',
        videoLessonId: 'f812811b-f6bb-44f9-b975-d14fb07c8883',
        questionBankIds: ['b5db3d32-6e24-4c1e-b0ed-fe992590845a'],
        pdfUrl: 'https://drive.google.com/file/d/1h1G5pbM5lXYJLAiUAZRD85PoZUlvh9jX/view?usp=sharing',
        route: 'alkenes-and-alkynes'
      },
      {
        name: 'Substitution and Elimination',
        tagId: 'fadf69be-82c6-4023-8b21-42d6a4063391',
        videoLessonId: '9765a9cf-6403-4aad-81d0-e66f5c95b620',
        questionBankIds: ['bceffdf0-b1f6-4e17-9afe-93abdea113a6'],
        pdfUrl: 'https://drive.google.com/file/d/11E-v9ubfW5oP9aT-g7aos3IlnTw-SNlX/view?usp=sharing',
        route: 'substitution-and-elimination'
      },
      {
        name: 'Alcohols, Ethers, and Epoxides',
        tagId: '9af0b015-82f1-451d-91fa-9fe7b1f4e525',
        videoLessonId: 'e5437bf6-6688-49b2-a833-fd3872dbce7e',
        questionBankIds: ['5fc9bb57-d897-4919-935f-aed4d1935cd5'],
        pdfUrl: 'https://drive.google.com/file/d/1ZKFdbdDqJb7yGGNVkY6qdfCcjPqms0xh/view?usp=sharing',
        route: 'alcohols-ethers,-and-epoxides'
      },
      {
        name: 'Spectroscopy',
        tagId: '7d818e38-272a-4994-97cf-bc50ac5709d2',
        videoLessonId: 'eef3a30c-3eaf-4f0e-9011-6d82ce67b6f4',
        questionBankIds: ['f6ccc119-00b8-4467-bdf9-2d009075a76c'],
        route: 'spectroscopy'
      },
      {
        name: 'Free Radical Halogenation and Diels-Alder Reactions',
        tagId: 'c8a4a186-879c-411d-adc1-e9e1436f5e4d',
        videoLessonId: '8878ca86-dc11-4daf-b954-ffb0ee0fc31f',
        questionBankIds: ['5bd82d88-7532-4bed-8189-371228a15c42'],
        pdfUrl: 'https://drive.google.com/file/d/1CPmCNqn_aCOPTpseE-H3kjuQzhZ2drZ5/view?usp=sharing',
        route: 'free-radical-halogenation-and-diels-alder-reactions'
      },
      {
        name: 'Aromatic Compounds',
        tagId: '9db7202f-6978-45ef-8b8c-a01d787cc84b',
        videoLessonId: '81ef0cb6-1082-4bd5-a419-7267d47611b7',
        questionBankIds: ['107b7986-1ebb-4c88-b4c4-b575ce7e2a6a'],
        pdfUrl: 'https://drive.google.com/file/d/1NBK0sMS3fgRAgFCLERCcnTRLYUqVh7ok/view?usp=sharing',
        route: 'aromatic-compounds'
      },
      {
        name: 'Aldehydes and Ketones',
        tagId: '2a7b988c-b24f-42ca-8b30-39fd5a00fc49',
        videoLessonId: '75ea53b2-1ad1-481b-80b3-35acbd0a9c62',
        questionBankIds: ['2c9023b0-e3c8-421a-bf47-059025b465cb'],
        pdfUrl: 'https://drive.google.com/file/d/1VWxvXcpMjo3T1OACU9I7CxMfjJw9FNN2/view?usp=sharing',
        route: 'aldehydes-and-ketones'
      },
      {
        name: 'Carboxylic Acids and Their Derivatives',
        tagId: '84490018-5c28-43d2-a831-0dd787c182f9',
        videoLessonId: '822654e2-3a5f-48e5-acd1-c750752591ba',
        questionBankIds: ['1b2a6a6b-f39b-4b45-b4e8-501f14620216'],
        pdfUrl: 'https://drive.google.com/file/d/1KFMLTHqpioAfvkPuAXD3SX6Tl7PF2NhQ/view?usp=sharing',
        route: 'carboxylic-acids-and-their-derivatives'
      },
      {
        name: 'Alpha Substitution Reactions',
        tagId: 'ec4fb66c-6a7b-4a8c-9e44-bbb44f3cf0e8',
        videoLessonId: '29d7debd-b0ed-40d7-ad77-b144e25f8fdc',
        questionBankIds: ['f48ce08f-afef-4e1e-92eb-426d95100d0b'],
        pdfUrl: 'https://drive.google.com/file/d/1FNFJJsJGgoNKjefssVYZl3R8J3kYHJdb/view?usp=sharing',
        route: 'alpha-substitution-reactions'
      },
      {
        name: 'Olefin Metathesis and Organometallic Coupling Reactions',
        tagId: 'ad571482-e140-40dc-85a5-10acb2895770',
        videoLessonId: 'ab88dbdc-b9b1-45c4-bbe6-a187f6d06811',
        questionBankIds: ['c9628267-ee0e-497a-846f-d27acf1127e2'],
        route: 'olefin-metathesis-and-organometallic-coupling-reactions'
      },
      {
        name: 'Amines',
        tagId: '95d22c93-5e25-4c9f-ae16-0dfc5bb7d649',
        videoLessonId: 'f5c8c919-a04c-40f2-9b04-82b0bc12091b',
        questionBankIds: ['68b6a8a0-cd17-4ee2-bcba-018623aab08d'],
        route: 'amines'
      },
      { // HAS COURSE BUT NO QB OR TAG ID
        name: 'Amino Acids and Proteins',
        tagId: 'd2b4706b-6cd5-470d-8db9-263b568988a8',
        videoLessonId: '1b8062ce-1728-47eb-84c1-ae8ff1158494',
        questionBankIds: ['974b6b68-f8bb-4d7d-aea3-8747abd9efa9'],
        route: 'amino-acids-and-proteins',
      },
      { // HAS COURSE BUT NO QB OR TAG ID
        name: 'Carbohydrates and Lipids',
        tagId: '0e8e6f0c-126f-49b0-ac9f-ca4a5d6ac00f',
        videoLessonId: '99cf687b-820d-4a38-85a7-36385fa28d11',
        questionBankIds: ['ac601f6a-b5cb-4705-969c-243b5cccb24e'],
        route: 'carbohydrates-and-lipids',
      },
      { // HAS COURSE BUT NO QB OR TAG ID
        name: 'Nucleic Acids',
        tagId: 'f9900dd0-5c00-4753-8f50-ddffef39c984',
        videoLessonId: 'bc5bcfdb-28fb-4ddc-abf2-60890bae5ce3',
        questionBankIds: ['004a4148-b9ad-45a0-b545-d86fbd410856'],
        route: 'nucleic-acids',
      },
    ],
  },
  // {
  //   name: 'General Chemistry',
  //   route: 'general-chemistry',
  //   themePalette: colors.brandPalette.teal,
  //   icon: <GeneralChemistryIcon/>,
  //   chapters: [],
  //   locked: true,
  //   releaseDate: 'Coming Spring 2021'
  // }
];

const updatedConfig = {
  classrooms: [
    {
      name: 'Organic Chemistry',
      tagId: '1a0ba62c-1c67-45ef-a811-245a6ddcedd4',
      route: 'organic-chemistry',
      themePalette: colors.brandPalette.pumpkin,
      classroomDescription: "Dr. Mike is going to make organic chemistry easy. Study Dr. Mike’s Videos and move on to the Question Banks and Practice Tests to ace this section.",
      questionCount: 1127,
      icon: 'icons/oc-logo.svg',
      educators: [
        {
          firstName: 'Dr. Mike',
          avatar: 'images/mike.png',
        },
        {
          firstName: 'Tyler',
          avatar: 'images/tylerh.gif',
        },
        {
          firstName: 'Wes',
          avatar: 'images/wesson.gif',
        }
      ],
      contentTypes: [
        {
          name: 'Question Banks',
          // icon: <StarIcon/>,
          route: 'qbanks',
          content: [
            {
              name: 'Question Banks',
              content:  [
                {
                  name: 'Bonding and Molecular Geometry',
                  id: '0dfd58e8-d433-425d-af89-3946d4504c83',
                  route: 'bonding-and-molecular-geometry',
                },
                {
                  name: 'Acids and Bases',
                  id: 'a7b8f2bf-fa69-4658-85aa-b7d994249fd5',
                  route: 'acids-and-bases'
                },
                {
                  name: 'Nomenclature',
                  id: '2f3eda4b-ba44-4a20-ba55-c40190a7e49e',
                  route: 'nomenclature'
                },
                {
                  name: 'Stereochemistry',
                  id: 'd58cae22-cbcc-4d53-88c4-a3a980527c6f',
                  route: 'stereochemistry'
                },
                {
                  name: 'Alkenes and Alkynes',
                  id: 'b5db3d32-6e24-4c1e-b0ed-fe992590845a',
                  pdfUrl: 'https://drive.google.com/file/d/1h1G5pbM5lXYJLAiUAZRD85PoZUlvh9jX/view?usp=sharing',
                  route: 'alkenes-and-alkynes'
                },
                {
                  name: 'Substitution and Elimination',
                  id: 'bceffdf0-b1f6-4e17-9afe-93abdea113a6',
                  pdfUrl: 'https://drive.google.com/file/d/11E-v9ubfW5oP9aT-g7aos3IlnTw-SNlX/view?usp=sharing',
                  route: 'substitution-and-elimination'
                },
                {
                  name: 'Alcohols, Ethers, and Epoxides',
                  id: '5fc9bb57-d897-4919-935f-aed4d1935cd5',
                  pdfUrl: 'https://drive.google.com/file/d/1ZKFdbdDqJb7yGGNVkY6qdfCcjPqms0xh/view?usp=sharing',
                  route: 'alcohols-ethers,-and-epoxides'
                },
                {
                  name: 'Spectroscopy',
                  id: 'f6ccc119-00b8-4467-bdf9-2d009075a76c',
                  route: 'spectroscopy'
                },
                {
                  name: 'Free Radical Halogenation and Diels-Alder Reactions',
                  id: '5bd82d88-7532-4bed-8189-371228a15c42',
                  pdfUrl: 'https://drive.google.com/file/d/1CPmCNqn_aCOPTpseE-H3kjuQzhZ2drZ5/view?usp=sharing',
                  route: 'free-radical-halogenation-and-diels-alder-reactions'
                },
                {
                  name: 'Aromatic Compounds',
                  id: '107b7986-1ebb-4c88-b4c4-b575ce7e2a6a',
                  pdfUrl: 'https://drive.google.com/file/d/1NBK0sMS3fgRAgFCLERCcnTRLYUqVh7ok/view?usp=sharing',
                  route: 'aromatic-compounds'
                },
                {
                  name: 'Aldehydes and Ketones',
                  id: '2c9023b0-e3c8-421a-bf47-059025b465cb',
                  pdfUrl: 'https://drive.google.com/file/d/1VWxvXcpMjo3T1OACU9I7CxMfjJw9FNN2/view?usp=sharing',
                  route: 'aldehydes-and-ketones'
                },
                {
                  name: 'Carboxylic Acids and Their Derivatives',
                  id: '1b2a6a6b-f39b-4b45-b4e8-501f14620216',
                  pdfUrl: 'https://drive.google.com/file/d/1KFMLTHqpioAfvkPuAXD3SX6Tl7PF2NhQ/view?usp=sharing',
                  route: 'carboxylic-acids-and-their-derivatives'
                },
                {
                  name: 'Alpha Substitution Reactions',
                  id: 'f48ce08f-afef-4e1e-92eb-426d95100d0b',
                  pdfUrl: 'https://drive.google.com/file/d/1FNFJJsJGgoNKjefssVYZl3R8J3kYHJdb/view?usp=sharing',
                  route: 'alpha-substitution-reactions'
                },
                {
                  name: 'Olefin Metathesis and Organometallic Coupling Reactions',
                  id: 'c9628267-ee0e-497a-846f-d27acf1127e2',
                  route: 'olefin-metathesis-and-organometallic-coupling-reactions'
                },
                {
                  name: 'Amines',
                  id: '68b6a8a0-cd17-4ee2-bcba-018623aab08d',
                  route: 'amines'
                },
                { // HAS COURSE BUT NO QB OR TAG ID
                  name: 'Amino Acids and Proteins',
                  id: '974b6b68-f8bb-4d7d-aea3-8747abd9efa9',
                  route: 'amino-acids-and-proteins',
                },
                { // HAS COURSE BUT NO QB OR TAG ID
                  name: 'Carbohydrates and Lipids',
                  id: 'ac601f6a-b5cb-4705-969c-243b5cccb24e',
                  route: 'carbohydrates-and-lipids',
                },
                { // HAS COURSE BUT NO QB OR TAG ID
                  name: 'Nucleic Acids',
                  id: '004a4148-b9ad-45a0-b545-d86fbd410856',
                  route: 'nucleic-acids',
                },
              ]
            }
          ]
        },
        {
          name: "Mike's Videos",
          // icon: <StarIcon/>,
          route: 'videos',
          content: [
            {
              type: 'Course',
              name: "Mike's Videos",
              description: "Understand the “big picture” of how chemistry works. Each concept is broken down into bite-size video lessons and quizzes which make it easy to follow along.",
              content: [
                {
                  name: 'Bonding and Molecular Geometry',
                  id: '6aeb90d0-4817-420c-92f2-15d89e521ef2',
                  route: 'bonding-and-molecular-geometry',
                },
                {
                  name: 'Acids and Bases',
                  id: '20decc73-e13c-4e43-82cd-a8c2a6a6df7f',
                  route: 'acids-and-bases'
                },
                {
                  name: 'Nomenclature',
                  id: '181107aa-4ff8-49cf-8908-9d511ddf6515',
                  route: 'nomenclature'
                },
                {
                  name: 'Stereochemistry',
                  id: '98151b49-53a8-4f10-9dbf-5434fbe4bd26',
                  route: 'stereochemistry'
                },
                {
                  name: 'Alkenes and Alkynes',
                  id: 'f812811b-f6bb-44f9-b975-d14fb07c8883',
                  pdfUrl: 'https://drive.google.com/file/d/1h1G5pbM5lXYJLAiUAZRD85PoZUlvh9jX/view?usp=sharing',
                  route: 'alkenes-and-alkynes'
                },
                {
                  name: 'Substitution and Elimination',
                  id: '9765a9cf-6403-4aad-81d0-e66f5c95b620',
                  pdfUrl: 'https://drive.google.com/file/d/11E-v9ubfW5oP9aT-g7aos3IlnTw-SNlX/view?usp=sharing',
                  route: 'substitution-and-elimination'
                },
                {
                  name: 'Alcohols, Ethers, and Epoxides',
                  id: 'e5437bf6-6688-49b2-a833-fd3872dbce7e',
                  pdfUrl: 'https://drive.google.com/file/d/1ZKFdbdDqJb7yGGNVkY6qdfCcjPqms0xh/view?usp=sharing',
                  route: 'alcohols-ethers,-and-epoxides'
                },
                {
                  name: 'Spectroscopy',
                  id: 'eef3a30c-3eaf-4f0e-9011-6d82ce67b6f4',
                  route: 'spectroscopy'
                },
                {
                  name: 'Free Radical Halogenation and Diels-Alder Reactions',
                  id: '8878ca86-dc11-4daf-b954-ffb0ee0fc31f',
                  pdfUrl: 'https://drive.google.com/file/d/1CPmCNqn_aCOPTpseE-H3kjuQzhZ2drZ5/view?usp=sharing',
                  route: 'free-radical-halogenation-and-diels-alder-reactions'
                },
                {
                  name: 'Aromatic Compounds',
                  id: '81ef0cb6-1082-4bd5-a419-7267d47611b7',
                  pdfUrl: 'https://drive.google.com/file/d/1NBK0sMS3fgRAgFCLERCcnTRLYUqVh7ok/view?usp=sharing',
                  route: 'aromatic-compounds'
                },
                {
                  name: 'Aldehydes and Ketones',
                  id: '75ea53b2-1ad1-481b-80b3-35acbd0a9c62',
                  pdfUrl: 'https://drive.google.com/file/d/1VWxvXcpMjo3T1OACU9I7CxMfjJw9FNN2/view?usp=sharing',
                  route: 'aldehydes-and-ketones'
                },
                {
                  name: 'Carboxylic Acids and Their Derivatives',
                  id: '822654e2-3a5f-48e5-acd1-c750752591ba',
                  pdfUrl: 'https://drive.google.com/file/d/1KFMLTHqpioAfvkPuAXD3SX6Tl7PF2NhQ/view?usp=sharing',
                  route: 'carboxylic-acids-and-their-derivatives'
                },
                {
                  name: 'Alpha Substitution Reactions',
                  id: '29d7debd-b0ed-40d7-ad77-b144e25f8fdc',
                  pdfUrl: 'https://drive.google.com/file/d/1FNFJJsJGgoNKjefssVYZl3R8J3kYHJdb/view?usp=sharing',
                  route: 'alpha-substitution-reactions'
                },
                {
                  name: 'Olefin Metathesis and Organometallic Coupling Reactions',
                  id: 'ab88dbdc-b9b1-45c4-bbe6-a187f6d06811',
                  route: 'olefin-metathesis-and-organometallic-coupling-reactions'
                },
                {
                  name: 'Amines',
                  id: 'f5c8c919-a04c-40f2-9b04-82b0bc12091b',
                  route: 'amines'
                },
                { // HAS COURSE BUT NO QB OR TAG ID
                  name: 'Amino Acids and Proteins',
                  id: '1b8062ce-1728-47eb-84c1-ae8ff1158494',
                  route: 'amino-acids-and-proteins',
                },
                { // HAS COURSE BUT NO QB OR TAG ID
                  name: 'Carbohydrates and Lipids',
                  id: '99cf687b-820d-4a38-85a7-36385fa28d11',
                  route: 'carbohydrates-and-lipids',
                },
                { // HAS COURSE BUT NO QB OR TAG ID
                  name: 'Nucleic Acids',
                  id: 'bc5bcfdb-28fb-4ddc-abf2-60890bae5ce3',
                  route: 'nucleic-acids',
                },
              ],
            },
          ],
        },
        {
          name: 'Worksheets',
          // icon: <StarIcon />,
          route: 'downloads',
          content: [
            {
            name: 'Downloads',
              content: [
                {
                  "name": "Mike’s Organic Chemistry Outline",
                  description: "Click the following button to download Mike’s Organic Chemistry outline:",
                  "route": "https://drive.google.com/file/d/1jAyxn9uNqPjy7egupktyyoim25wsfJga/view",
                },
                {
                  "name": "Bootcamp OC Reactions Sheet",
                  description: "A big part of Organic Chemistry is knowing the reactions. Download the Bootcamp OC Reactions Sheet below, study it, and practice with the Reaction Qbank!",
                  "route": "https://drive.google.com/file/d/1hU-hFhyYtYS6FCQpcoQgtpiruh2164s9/view",
                },
              ]
            }
          ]
        },
        {
          name: 'Strategy',
          // icon: <StarIcon />,
          route: 'strategy',
          content: []
        },
      ],
    },
  ],
  studyTools: {},
  meta: {
    siteTitle: 'Chemistry Bootcamp',
    subjectIds: ['fa72381b-e2c2-4e46-a113-135220a010b6'],
  }
};

function getChemistryConfigObject(classroom, chapter, contentSlug) {
  try {
    if (classroom && chapter && contentSlug) {
      const contentSlugStrippedIndex = contentSlug.split('?')[0];
      const [type, index] = contentSlugStrippedIndex.split('-');

      const matchedChapter = config
        .find(({route}) => route === classroom)
        .chapters
        .find(({route}) => route === chapter);

      const isValidContentSlug = type === 'lesson'
        ? parseInt(index) === 1
        : type === 'bank'
        ? index > 0 && index <= matchedChapter.questionBankIds.length
        : false;

      return isValidContentSlug && chapter;
    } else if (classroom && chapter) {
      return config
        .find(({route}) => route === classroom)
        .chapters
        .find(({route}) => route === chapter)
    } else if (classroom) {
      return config
        .find(({route, locked}) => route === classroom && !locked)
    }
    return false;
  } catch (error) {
    return false;
  }

}

export {getChemistryConfigObject, updatedConfig};
export default config;
