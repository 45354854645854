import rangyClassApplier from 'rangy/lib/rangy-classapplier';
import rangyHighlighter from 'rangy/lib/rangy-highlighter';


rangyClassApplier.init();
rangyHighlighter.init();

function unwrap(element) {
  // get the element's parent node
  var parent = element.parentNode;

  // move all children out of the element
  while (element.firstChild) parent.insertBefore(element.firstChild, element);

  // try to remove the empty element
  try {
    parent.removeChild(element);
  } catch (error) {
    console.log(error);
  }
}

function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}

function getTooltipPosition(event, offsetLeft, offsetTop) {
  const selection = window.getSelection();

  if (selection.type !== 'Range') return {};

  return {
    top: event.clientY - offsetTop,
    left: event.clientX - offsetLeft
  };
}

function setupEventListeners(listener, handler) {
  window.addEventListener(listener, handler);
  return () => window.removeEventListener(listener, handler);
}

function createHighlighter(containerElementId) {
  const highlighter = rangyHighlighter.createHighlighter();
  const highlightApplier = rangyClassApplier.createClassApplier('highlight');
  const strikethroughApplier = rangyClassApplier.createClassApplier('strikethrough');

  highlighter.addClassApplier(highlightApplier);
  highlighter.addClassApplier(strikethroughApplier);

  return {
    restoreHighlights: (serializedString) => {
      return highlighter.deserialize(serializedString);
    },
    getSerializedHighlights: () => {
      return highlighter.serialize('highlight');
    },
    highlight: (className) => {
      className === 'highlight'
        ? highlighter.highlightSelection(className, {containerElementId})
        : strikethroughApplier.toggleSelection();

      clearSelection();
    },
    getHighlightsInSelection: () => {
      return highlighter.getHighlightsInSelection();
    },
    removeHighlights: (className='highlight') => {
      const applier = className === 'highlight' ? highlightApplier : strikethroughApplier;
      applier.undoToSelection();
      clearSelection();
    },
    dehighlight: event => {
      if (event.target.nodeName === 'SPAN' && event.target.classList.contains('highlight')) {
        const highlight = highlighter.getHighlightForElement(event.target);

        highlight
          ? highlighter.removeHighlights([highlight])
          : unwrap(event.target);

        return true;
      }

      return null;
    }
  };
}

export {
  unwrap,
  createHighlighter,
  clearSelection,
  setupEventListeners,
  getTooltipPosition,
};
