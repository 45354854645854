import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';

import {
  PrometricWrapper,
  NextText,
  NextButton,
} from './shared';


const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 21px;
`;
const Section = styled.div`
  width: 100%;
  margin-bottom: 21px;
`;

const Biology = () => (
  <PrometricWrapper>
    <Heading>
      Survey of Natural Sciences - Biology
    </Heading>
    <Section>Time limit: 90 minutes</Section>
    <Section>This examination is comprised of 100 items:</Section>
    <Section>Biology (1-40)</Section>
    <NextText />
  </PrometricWrapper>
);
const GeneralChemistry = () => (
  <PrometricWrapper>
    <Heading>
      Survey of Natural Sciences - General Chemistry
    </Heading>
    <Section>This examination is comprised of 100 items:</Section>
    <Section>General Chemistry (41-70)</Section>
    <NextText />
  </PrometricWrapper>
);
const OrganicChemistry = () => (
  <PrometricWrapper>
    <Heading>
      Survey of Natural Sciences - Organic Chemistry
    </Heading>
    <Section>This examination is comprised of 100 items:</Section>
    <Section>Organic Chemistry (71-100)</Section>
    <NextText />
  </PrometricWrapper>
);

const tutorialSet = [
  Biology,
  GeneralChemistry,
  OrganicChemistry
]

const SNSTutorial = ({instructionIndex, setDismissedSNSTutorial}) => {

  const {
    methods: {
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
    }
  } = useTestNavigatorContext();

  useEffect(() => {
    setLeftButtons([{component: <div />}]);
    setCenterButtons([{
      component: <NextButton onClick={() => setDismissedSNSTutorial(dismissedSNSTutorial => [...dismissedSNSTutorial, instructionIndex])} />
    }]);
    setRightButtons([{component: <div />}]);
    return () => {
      // When this component dismounts...
      setCenterButtons([]);
    }

  }, [])

  return tutorialSet[instructionIndex]();
}

export default SNSTutorial;
