import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import {useModalContext} from '../../../contexts/Modal';

import {AlertIcon, CloseIcon} from '../../NCLEXQBank/shared';
import {FlexBox} from '../../Grid';
import {H2, Body2} from '../../Typography';
import {Button} from '../../Branding/Buttons';
import {LoadingSpinner} from '../../Branding';
import useHotkeys from "@reecelucas/react-use-hotkeys";

import styled, {css} from 'styled-components';
import theme from '@bootcamp/shared/src/styles/theme';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: ${({theme}) => `${theme.layouts.spacing.xxl} ${theme.layouts.spacing.xxl} ${theme.layouts.spacing.xl}`};

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => `${theme.layouts.spacing.l} ${theme.layouts.spacing.xl}`};
  }
`;
const Header = styled(H2)`
  text-align: ${({textAlign}) => textAlign};
  width: 100%;
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
`;
const Body = styled(Body2)`
  text-align: ${({textAlign}) => textAlign};
  width: 100%;
`;
const ButtonWrapper = styled(FlexBox)`
  position: relative;
  padding: ${({theme}) => `0px ${theme.layouts.spacing.xxl} ${theme.layouts.spacing.xxl}`};
  align-items: center;
  flex-direction: column;


  ${({theme}) => theme.mediaQueries.tablet} {
    padding: ${({theme}) => `${theme.layouts.spacing.m} ${theme.layouts.spacing.l}`};
  }
`;
const Cancel = styled(Button)`
  background: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  color: ${({theme, themeColor}) => themeColor || theme.colors.neutralsPalette.lightGrey};
  border: 1px solid ${({theme, themeColor}) => themeColor || theme.colors.neutralsPalette.lightGrey};
  box-shadow: none;

  &:hover {
    background: ${({theme}) => theme.colors.neutralsPalette.white};
    box-shadow: none;
    color: ${({theme}) => theme.colors.neutralsPalette.dark};
    border: 1px solid ${({theme}) => theme.colors.neutralsPalette.lightGrey};
    transform: none;
  }
`;

const Confirm = styled(Button)`
  background: ${({themeColor}) => themeColor};
  color: ${({theme}) => theme.colors.white};

  &:hover {
    background: ${({themeColor}) => themeColor};
  }
`;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 10;
`;

const Loader = styled(LoadingSpinner)`
  fill: ${({fill}) => fill};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({theme}) => theme.colors.neutralsPalette.white};

  border-radius: 8px;
  box-shadow: ${({theme}) => theme.elevation.shadow.high};

  max-width: 600px;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.mobileXl} {
    transition: transform .5s, opacity .75s;
    border-radius: 8px 8px 0px 0px;
    ${({visible}) => visible
      ? `
        transform: translateY(0%);
        opacity: 1;
        margin-top: auto;
      `
      : `
        transform: translateY(100%);
        opacity: 0;
      `
    }
  }

  ${({prometricStyling}) => prometricStyling && css`
    padding: 4px 8px;
    background: ${({theme}) => window.location.pathname.includes('mcat') ? '#006DAA' : window.location.pathname.includes('med-school') ? 'rgb(56, 82, 164)' : window.location.pathname.includes('inbde/custom-test') ? 'rgb(15, 98, 146)' : theme.colors.prometricPalette.inbde.default};
    box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.24);
    color: white;
    resize: both;
    overflow: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-width: 475px;
    border-radius: 0px;

    ${({ theme }) => theme.mediaQueries.mobileL} {
      margin: 0px 8px;
    }

    ${Wrapper} {
      padding: 0;
      color: white;
    }
    ${Header} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      font-size: 16px;
      border-bottom: 1px solid white;
      color: white;
      margin: 0;
      line-height: unset;
      font-weight: normal;
      font-family: unset;
    }
    ${Body} {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px;
      color: white;
      font-family: unset;
      font-size: 16px;
      line-height: 150%;
    }
    ${ButtonWrapper} {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding: 16px;
      margin-top: auto;
    }
    ${Cancel} {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      border: 1px solid white;
      padding: 6px 14px;
      margin-right: 16px;
      margin-bottom: 0px;
      cursor: pointer;
      opacity: 0.5;
      background: transparent;
      &:last-child {
      margin-right: 0px;
      }
      &:hover {
        opacity: 1;
        color: white;
      }
    }
    ${Confirm} {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      border: 1px solid white;
      padding: 6px 14px;
      margin-right: 16px;
      cursor: pointer;
      background: transparent;
      &:last-child {
      margin-right: 0px;
      }
      &:hover {
        opacity: 1;
      }
      ${({faded}) => faded && css`opacity: 0.5;`}
    }

    ${LoaderWrapper} {
      background: ${({theme}) => window.location.pathname.includes('med-school') ? 'rgb(56, 82, 164)' : window.location.pathname.includes('inbde/custom-test') ? 'rgb(15, 98, 146)' : theme.colors.prometricPalette.inbde.default};
    }

    ${Loader} {
      fill: white;
    }
  `}

`;


const SubmitAnatomyCase = ({headerText, bodyText, history, location, confirmConfig, cancelConfig, prometricStyling, preventEscape, icon, textAlign="left"}) => {
  const {modalDispatch} = useModalContext();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const {color} = confirmConfig?.color || theme.colors.brandPalette.royal.default;

  useEffect(() => {
    setVisible(true);
  }, []);

  const redirect = (formatPath) => {
    const path = (formatPath && formatPath(location)) || `${location.pathname}?mode=review&from=quiz`;
    history.push(path);
  };

  const handleConfirmClick = async (event) => {
    setLoading(true);
    try {
      confirmConfig.onConfirm && await confirmConfig.onConfirm(history);
      confirmConfig.shouldRedirect && redirect(confirmConfig.redirect);
      modalDispatch({type: 'close'});
    } catch (e) {
      setLoading(false);
      setError(e);
      confirmConfig.onError && confirmConfig.onError(e);
    }
  };

  const handleCancelClick = async (event) => {
    setLoading(true)
    cancelConfig.onCancel && await cancelConfig.onCancel(history);
    cancelConfig.shouldRedirect && redirect(confirmConfig.redirect);
    modalDispatch({type: 'close'});
  };

  const errorHandler = e => {
    return confirmConfig?.errorText
      ? confirmConfig.errorText(e)
      : `Unfortunately, an error has occurred: [${e.message}]. Please close this modal and try re-submitting.`
  }

  useHotkeys('Escape', preventEscape ? () => true : handleCancelClick, [handleCancelClick]);
  useHotkeys(['Enter', 'c'], handleConfirmClick, [handleConfirmClick]);

  return (
    <Container visible={visible} onClick={e => e.stopPropagation()} prometricStyling={prometricStyling}>
      {!error && <Wrapper>
        <Header textAlign={textAlign}>
          {icon}
          {headerText}
          {prometricStyling && cancelConfig.text && <CloseIcon onClick={handleCancelClick}/>}
        </Header>
        <Body textAlign={textAlign}>
          {prometricStyling && <AlertIcon/>}
          {bodyText}
        </Body>
      </Wrapper>}
      {error
        ? <Wrapper children={errorHandler(error)} />
        : (
          <ButtonWrapper>
            {loading &&
              <LoaderWrapper loading={loading}>
                <Loader size={24} fill={color}/>
              </LoaderWrapper>
            }
            {cancelConfig.text ? (
              <Cancel {...(prometricStyling ? {as: 'div'} : {})} fixed colorConfig={cancelConfig.colorConfig} themeColor={cancelConfig.color} onClick={handleCancelClick}>
                {cancelConfig.text}
              </Cancel>
            ) : null}
            <Confirm {...(prometricStyling ? {as: 'div'} : {})} fixed colorConfig={confirmConfig.colorConfig} themeColor={confirmConfig.color} onClick={handleConfirmClick}>
              {confirmConfig.text}
            </Confirm>
            {/* {error ? <Wrapper>Unfortunately, an error has occurred: {error?.message}</Wrapper> : null} */}
          </ButtonWrapper>
        )
      }
    </Container>
  );
};

SubmitAnatomyCase.propTypes = {
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  cancelConfig: PropTypes.shape({
    onCancel: PropTypes.func,
    text: PropTypes.string,
  }),
  confirmConfig: PropTypes.shape({
    onConfirm: PropTypes.func,
    text: PropTypes.string,
    color: PropTypes.string,
  }),
};
SubmitAnatomyCase.defaultProps = {
  headerText: 'Nice work Michael! Ready to submit your quiz?',
  bodyText: 'You successfully answered every question. Now it\'s time to see how you did!',
  cancelConfig: {
    text: 'Not Yet',
  },
  confirmConfig: {
    text: 'Submit Now',
    color: 'blue',
  },
};

export default withRouter(SubmitAnatomyCase);
