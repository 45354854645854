import React, {useCallback} from 'react';
import {Helmet} from 'react-helmet';

import styled from 'styled-components';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import Chapter from '@bootcamp/web/src/bootcamps/components/Cards/Chapter'
import Container from '../../pages/PageContainer';
import {useMasteryArray} from '../../../hooks';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import {getClassroomQuestionCounts} from '@bootcamp/shared/src/requests';
import {useAsync} from '@bootcamp/web/src/hooks';
import {formatClassroomMastery} from '@bootcamp/shared/src/util/formatting';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';

import {StyledUpgradeButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';
import {RoundButton} from '@bootcamp/web/src/components/UI';

import {AddSquare} from '@styled-icons/fluentui-system-filled/AddSquare';

import AdultHealthImg from '@bootcamp/shared/src/assets/icons/nclex/AdultHealth.png'
import ChildHealthImg from '@bootcamp/shared/src/assets/icons/nclex/ChildHealth.png'
import CriticalCareImg from '@bootcamp/shared/src/assets/icons/nclex/CriticalCare.png'
import FundamentalsImg from '@bootcamp/shared/src/assets/icons/nclex/Fundamentals.png'
import MaternalandNewbornHealthImg from '@bootcamp/shared/src/assets/icons/nclex/MaternalandNewbornHealth.png'
import MentalHealthImg from '@bootcamp/shared/src/assets/icons/nclex/MentalHealth.png'
import PharmacologyImg from '@bootcamp/shared/src/assets/icons/nclex/Pharmacology.png'
import ManagementofCareImg from '@bootcamp/shared/src/assets/icons/nclex/ManagementofCare.png'

import {Heading, FreeQuestionsCard} from '../shared';

const ClassroomImageMap = {
  'Adult Health': AdultHealthImg,
  'Child Health': ChildHealthImg,
  'Critical Care': CriticalCareImg,
  'Fundamentals': FundamentalsImg,
  'Maternal & Newborn Health': MaternalandNewbornHealthImg,
  'Mental Health': MentalHealthImg,
  'Pharmacology': PharmacologyImg,
  'Management of Care': ManagementofCareImg
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  margin-top: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.laptop} {
    padding-left: 0;
  }
`;

const StyledRoundButton = styled(RoundButton)`
  ${({theme}) => theme.mediaQueries.mobileXl} {
    flex: 1;
    height: 32px;
  }
`;

const StandaloneQuestions = ({match, history, location}) => {
  const {isUpgraded} = useUserDataContext();

  const {config, questionCounts} = useBootcampConfigContext();

  const name = 'Standalone Questions';
  const filteredClassrooms = config?.classrooms?.filter(({testing, route}) => !testing && !route.match('free-questions') && !route.match('next-gen-cases') && !route.match('next-gen-strategy'));

  const testsByClassroom = getTestsByClassroom(filteredClassrooms, [], [], false, ['Board Style Questions']);
  const testQuestionCounts = {
    classroomQuestionCounts: Object.keys(testsByClassroom).reduce((acc, classroom) => {
      return {
        ...acc,
        [classroom]: (acc[classroom] || 0) + testsByClassroom[classroom].reduce((acc, testId) => acc + Object.keys(questionCounts?.[testId] || {}).length, 0)
      }
    }, {})
  }

  const {modalDispatch} = useModalContext();
  const openCreateTest = (subject, system) => modalDispatch({
    type: 'open',
    component: CreateTest,
    componentProps: {defaults: {subject, system}},
    enableClickClose: true,
  })
  const adultHealthProgress = useMasteryArray(testsByClassroom['adult-health'], 'test', data => formatClassroomMastery(data, 'test'));
  const childHealthProgress = useMasteryArray(testsByClassroom['child-health'], 'test', data => formatClassroomMastery(data, 'test'));
  const criticalCareProgress = useMasteryArray(testsByClassroom['critical-care'], 'test', data => formatClassroomMastery(data, 'test'));
  const fundamentalsProgress = useMasteryArray(testsByClassroom['fundamentals'], 'test', data => formatClassroomMastery(data, 'test'));
  const maternalAndNewbornHealthProgress = useMasteryArray(testsByClassroom['maternal-and-newborn-health'], 'test', data => formatClassroomMastery(data, 'test'));
  const mentalHealthProgress = useMasteryArray(testsByClassroom['mental-health'], 'test', data => formatClassroomMastery(data, 'test'));
  const pharmacologyProgress = useMasteryArray(testsByClassroom['pharmacology'], 'test', data => formatClassroomMastery(data, 'test'));
  const managementOfCareProgress = useMasteryArray(testsByClassroom['management-of-care'], 'test', data => formatClassroomMastery(data, 'test'));

  const progressMap = {
    'adult-health': adultHealthProgress,
    'child-health': childHealthProgress,
    'critical-care': criticalCareProgress,
    'fundamentals': fundamentalsProgress,
    'maternal-and-newborn-health': maternalAndNewbornHealthProgress,
    'mental-health': mentalHealthProgress,
    'pharmacology': pharmacologyProgress,
    'management-of-care': managementOfCareProgress,
  }

  function reduceMastery (masteryArray) {
    return Object.values(masteryArray).reduce((acc, masteryObj) => {
      return {
          learning: acc.learning + masteryObj.learning,
          reviewing: acc.reviewing + masteryObj.reviewing,
          mastered: acc.mastered + masteryObj.mastered,
        }
      }, {learning: 0, reviewing: 0, mastered: 0})
  }
  return (
    <Container>
      <Helmet>
        <title>{config.meta.siteTitle} | {name}</title>
        <meta name="description" content={`${config.meta.siteTitle} ${name}`}></meta>
      </Helmet>
      <Heading name={name} description="The standalone questions below are based on the real Next Gen NCLEX. Practice with these so you're ready for the real exam." />
      <Wrapper>
        {!isUpgraded && <FreeQuestionsCard />}
        {filteredClassrooms.map((classroom, index) => (
          <Chapter
            onClick={() => openCreateTest(classroom.name)}
            name={classroom.name}
            icon={<img style={{width: '24px', height: '24px', margin: 'auto'}} src={ClassroomImageMap[classroom.name]}/>}
            fullWidthProgress
            progress={Object.values(progressMap[classroom.route] || {}).length > 0 ? reduceMastery(progressMap[classroom.route] || {}) : 'loading'}
            questionCount={testQuestionCounts?.classroomQuestionCounts ? testQuestionCounts?.classroomQuestionCounts?.[classroom.route] || 0 : 'loading'}
            actions={[isUpgraded ? <StyledRoundButton small iconPosition="left" IconComponent={<AddSquare color="white" size="20" />}>Create Test</StyledRoundButton> : <StyledUpgradeButton size={'small'} round children={'Upgrade'}/>]}
            defaultLocked={!isUpgraded}
          />
        ))}
      </Wrapper>
    </Container>
  );
}

StandaloneQuestions.propTypes = {};
StandaloneQuestions.defaultProps = {};

export default StandaloneQuestions;
