import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {Upgrade} from '@bootcamp/web/src/components/Modal';
import BootcampLockdown from '@bootcamp/web/src/components/Modal/components/BootcampLockdown';

import {useCreateTestState} from '../context';
import {useTestContext} from '@bootcamp/web/src/contexts/TestBase';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';

import {capitalize} from '@bootcamp/shared/src/util';

import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {RoundButton} from '@bootcamp/web/src/components/UI';


const Container = styled.div`
  z-index: 1;
  padding: ${({theme}) => theme.layouts.spacing.m} ${({theme}) => theme.layouts.spacing.l};
  background: white;
  border-top: 1px solid ${({theme}) => theme.colors.neutralsPalette.light};
`;
const StyledBootcampLockdown = styled(BootcampLockdown)`
  height: auto;
  width: 80%;
  display: flex;
  align-items: center;
  max-width: 560px;

  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: none;
  }
`;
const StyledBadge = styled(Badge)`
  path {
    fill: white;
  }
`;

function recursiveGetTestIds(data) {
  return Object.values(data).reduce((acc, {id, children, checked}) => {

    if (!children) return checked ? [...acc, id] : acc;

    return [
      ...acc,
      ...recursiveGetTestIds(children)
    ]
  }, []);
}

const Submit = () => {
  const [submitting, setSubmitting] = useState()

  const {variables: {data, masteryFilter, testCount, loading, mode, type}} = useCreateTestState();
  const {bootcamp} = useUserDataContext();
  const {composeCustomTest} = useTestContext();
  const {isUpgraded} = useUserDataContext();

  const {push} = useHistory();

  const disabled = loading || !recursiveGetTestIds(data)?.length;

  async function handleSubmit() {
    if (submitting || disabled) return;

    setSubmitting(true);

    const testIds =  recursiveGetTestIds(data);

    const subjectNames = Object
      .values(data)
      .filter(({checked}) => checked)
      .map(({name}) => name)
      .join(', ');

    const testTitle = `${capitalize(masteryFilter)} | ${subjectNames}`;
    const checkedSubjects = Object
      .values(data)
      .filter(({checked}) => checked)
    const onlyCaseStudies = checkedSubjects.length === 1 && checkedSubjects[0].name === 'Case Studies';

    const testStorageKey = await composeCustomTest({testTitle, testIds, masteryLevel: masteryFilter, testLength: testCount, bootcamp, type, onlyCaseStudies, ...mode});

    push({pathname: `/${bootcamp}/custom-test`, search: `?key=${encodeURIComponent(testStorageKey)}${type ? `&type=${encodeURIComponent(type)}` : ''}`});
  }

  async function handleSubmitByBootcamp() {
    if (disabled) return;

    if (isUpgraded || (['nclex', 'inbde'].includes(bootcamp))) {
      handleSubmit();
    } else {
      window.open(`https://bootcamp.com/${bootcamp}/upgrade`, "_blank")
    }
  }

  return (
    <Container>
      <RoundButton
        full
        onClick={handleSubmitByBootcamp}
        iconPosition="left"
        IconComponent={!isUpgraded && !['nclex', 'inbde'].includes(bootcamp) && <StyledBadge/>}
        disabled={['nclex', 'inbde', 'med-school'].includes(bootcamp) ? disabled : !isUpgraded}
        loading={submitting}
      >
        {(isUpgraded || ['nclex', 'inbde'].includes(bootcamp)) ? 'Start Test' : 'Upgrade to Unlock'}
      </RoundButton>
    </Container>
  );
};

Submit.defaultProps = {};
Submit.propTypes = {};

export default Submit;
