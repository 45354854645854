import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {RoundButton} from '@bootcamp/web/src/components/UI';
import {ReactComponent as Badge} from '@bootcamp/shared/src/assets/svg/badge.svg';
import {H1, Body2, H4, Body3} from '@bootcamp/web/src/components/Typography';
import {ExpertEducators} from '../../components/ExpertEducators';
import Breadcrumbs from '@bootcamp/web/src/components/NavBar/components/Breadcrumbs';

import {useBootcampConfigContext} from '@bootcamp/web/src/contexts/BootcampConfig';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {useContentTypeProgress} from '../api/progress/contentType';

import Chapter from '@bootcamp/web/src/bootcamps/components/Cards/Chapter';
import {StyledBeginPracticeButton} from '@bootcamp/web/src/bootcamps/components/Cards/components/Buttons';


const Title = styled(H1)`
  color: white;
  margin-bottom: 8px;
`;
const Body = styled(Body2)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
  margin-bottom: ${({theme}) => theme.layouts.spacing.ms};
  max-width: 750px;
`;

const UpgradeCardContainer = styled.div`
  background: rgba(237, 150, 37, 0.1);

  border: 1px solid ${({theme}) => theme.colors.brandPalette.orange.default};
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const UpgradeCardContent = styled.div`
  margin-right: 16px;
`;
const UpgradeCardIcon = styled.div`
  margin-right: 16px;
  position: relative;
  user-select: none;
`;
const UpgradeCardHeading = styled(H4)`
  color: white;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 4px;
`;
const UpgradeCardBody = styled(Body3)`
  color: rgba(255, 255, 255, 0.7);
`
const UpgradeCardButton = styled(RoundButton)``

const DefaultProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  padding: 3px;
  position: relative;
  min-width: 42px;
  min-height: 42px;
`;

const ProfilePictureDot = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 22px;
  width: 18px;
  opacity: .9;
`;

const ProfilePictureImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

const UpgradeIcon = styled(Badge)`
  height: 16px;
  width: 16px;
  path {
    fill: white;
  }
`

const LargeUpgradeIcon = styled(Badge)`
  height: 24px;
  width: 24px;
  path {
    fill: white;
  }
`

export const UpgradeCard = () => {
  const {bootcamp} = useUserDataContext();
  return (
    <UpgradeCardContainer>
      <UpgradeCardIcon>
        <LargeUpgradeIcon />
      </UpgradeCardIcon>
      <UpgradeCardContent>
        <UpgradeCardHeading>
          Upgrade Your Membership
        </UpgradeCardHeading>
        <UpgradeCardBody>
          As a free member, you only have access to 31 questions and our Next Gen strategy course. Upgrade your membership to get more questions and instant access to all content and features.
        </UpgradeCardBody>
      </UpgradeCardContent>
      <UpgradeCardButton target="_blank" href={`https://bootcamp.com/${bootcamp}/upgrade`} color="orange" small IconComponent={<UpgradeIcon/>} iconPosition="left">
        Upgrade
      </UpgradeCardButton>
    </UpgradeCardContainer>
  )
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;

const StyledExpertEducators = styled(ExpertEducators)`
  width: auto;
  p {
    align-self: flex-start;
    ${({theme}) => theme.mediaQueries.tablet} {
      align-self: flex-end;
    }
    ${({theme}) => theme.mediaQueries.mobileL} {
      align-self: flex-start;
    }
  }
  div {
    ${({theme}) => theme.mediaQueries.mobileL} {
      justify-content: flex-start;
    }
  }
`;

export const Heading = ({name, description, educators=[], showEducators=true}) => {
  const history = useHistory();

  return (
    <>
      <StyledBreadcrumbs history={history} lockAtDepth={2}/>
      <Title>{name}</Title>
      <Body>{description}</Body>
      {showEducators &&
        <StyledExpertEducators
          educators={[
            { firstName: 'Dr. Emily Wilson, DNP', avatar: 'educators/emily_giddings.jpeg' },
            ...educators
          ]}
          alignPreface={'left'}
          ledByTextOrientation={'column'}
          ledByOrientation={'column'}
          lightMode />
        }
    </>
  );
}

export const FreeQuestionsCard = () => {
  const {config} = useBootcampConfigContext();
  const {modalDispatch} = useModalContext();
  const openCreateTest = (subject, system) => modalDispatch({
    type: 'open',
    component: CreateTest,
    componentProps: {defaults: {subject, system}},
    enableClickClose: true,
  })

  const {contentTypes: freeQuestions} = config.classrooms.find(({name}) => name === 'Free Questions');
  const freeQuestionsConfig = freeQuestions?.[0];
  const {progress, counts} = useContentTypeProgress(freeQuestionsConfig, 'all')?.['qbanks-all'] || {};
  return (
    <Chapter
      onClick={() => openCreateTest('Free Questions', 'Free Questions')}
      name={'Free Questions'}
      fullWidthProgress
      progress={progress ? progress.all : 'loading'}
      questionCount={counts ? counts.all : 'loading'}
      actions={[<StyledBeginPracticeButton small children={'Start'} hideOnMobile/>]}
    />
  )
}
