import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Waypoint} from 'react-waypoint';
import {LoadingSpinner} from '../../components/Branding';

import styled from 'styled-components';
import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';
import {IconButton} from '../../components/Branding/Buttons';
import {H4} from '@bootcamp/web/src/components/Typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Bar = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
`;
const SearchBar = styled(Bar)`
  border: 1px solid #D5D8D9;
  font-size: 16px;
  padding: 1.25em;
  &:focus {
    outline: none;
  }
`;
const ResultsText = styled(Bar)`
  background: ${({theme}) => theme.backgroundShade};
`;
const PageLoader = styled(LoadingSpinner)`
  display: block;
  margin: 0 auto;
  margin-top: 24px;
`;
const CloseButton = styled(IconButton)`
  height: 56px;
  width: 56px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);

  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 40px;
    width: 40px;
    padding: 10px;
  }
`;
const CloseIcon = styled(X).attrs(props => ({size: 28, color: 'white'}))`
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 20px;
    width: 20px;
  }
`;
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const LocationText = styled(H4)`
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;
const TitleText = styled(H4)`
  color: white;
`;

const SearchArea = ({setSearch, searchQuery, totalResults, loading, hasNextPage, placeholder, resultType}) => {
  return (
    <TopArea style={{paddingTop: 0}}>
      <SearchBar
        as={'input'}
        name={'searchQuery'}
        placeholder={placeholder}
        onChange={event => setSearch(event.target.value)}
      />

      {loading &&
        <ResultsText>
          Loading...
        </ResultsText>
      }
      {!loading && searchQuery &&
        <ResultsText>
          {`${totalResults}${hasNextPage ? '+' : ''} ${resultType}${totalResults === 1 ? '' : 's'} matching "${searchQuery}"`}
        </ResultsText>
      }
    </TopArea>
  );
}
const TopArea = styled.div`
  padding: 16px;
`;
const ResultsArea = styled.div`
  padding: 16px;
  background: ${({theme}) => theme.overlays.opacity.dark._100};
  height: 100%;
  overflow: scroll;
`;
const TitleContainer = styled.div`
`;
const Search = ({
  getResults,
  getNextPage,
  results,
  renderResults,
  loading,
  loadingNextPage,
  hasNextPage,
  placeholder,
  resultType,
}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    // cleanup on unmount
    return () => {
      getResults({filter: '', filterRequired: true, sort: {}, nextToken: null});
    }
  }, []);

  useEffect(() => {
    getResults({filter: search});
  }, [search]);


  return (
    <Container>
      <TopArea>
        <ButtonsContainer style={{justifyContent: 'space-between'}}>
          <CloseButton
            onClick={() => {
              window.location = window.location.pathname.split('/').slice(0, -1).join('/')
            }}
            type={'secondary'}
            size={'large'}
            children={<CloseIcon />}
          />
          {/* <ToggleSidebarButton type={'secondary'} size={'large'} children={<ToggleSidebarIcon />} /> */}
        </ButtonsContainer>
        <TitleContainer>
          <LocationText>Question Bank</LocationText>
          <TitleText>Search Questions</TitleText>
        </TitleContainer>
      </TopArea>
      <SearchArea
        setSearch={setSearch}
        searchQuery={search}
        totalResults={results.length}
        loading={loading}
        hasNextPage={hasNextPage}
        placeholder={placeholder}
        resultType={resultType}
      />
      <ResultsArea>
        {renderResults(search, results)}
        {!!results.length && <Waypoint onEnter={getNextPage}/>}
        {loadingNextPage && !!results.length && <PageLoader/>}
      </ResultsArea>
    </Container>
  );
};

Search.propTypes = {
  title: PropTypes.string,
  getResults: PropTypes.func.isRequired,
  getNextPage: PropTypes.func,
  renderResults: PropTypes.func,
  results: PropTypes.array,
  titleButtons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  })),
  titleSize: PropTypes.oneOf(['small', 'medium', 'large']),
  refetchDeps: PropTypes.array,
  hasNextPage: PropTypes.bool,
  placeholder: PropTypes.string,
  resultType: PropTypes.string,
};

Search.defaultProps = {
  title: '',
  titleButtons: [],
  getNextPage: () => {},
  getResults: result => result.id,
  titleSize: 'large',
  hasNextPage: false,
  placeholder: 'Start searching for questions...',
  resultType: 'question',
};


export default Search;
