import {getQuestionParts} from '@bootcamp/shared/src/util';

// this gets sectiong bounds using current index as a reference,
// so for example if we have question blocks at indexes 1, 2, 3 and the current
// block index is 3, this should return [1, 3]
function getSectionBounds(testBlockConnections, currentBlockIndex) {
  return testBlockConnections.reduce((acc, curr, index) => {
    return (curr.testBlock.type === 'sectionStart' && index < currentBlockIndex) || (index === 0) ? [index + 1, currentBlockIndex] : acc;
  }, [0, currentBlockIndex]);
}

function getNextReviewBlock(testBlockConnections, currentBlockIndex) {
  return testBlockConnections.find(({testBlock}, index) => ((index > currentBlockIndex) && !['questionSet', 'passage', 'caseQuestionSet'].includes(testBlock.type)));
}

function getNextQuestionBlockIndex(testBlockConnections, currentBlockIndex) {
  return testBlockConnections.findIndex(({testBlock}, index) => ((index > currentBlockIndex) && ['questionSet', 'passage', 'caseQuestionSet'].includes(testBlock.type)));
}

function getSectionWindow(testBlockConnections, currentBlockIndex) {
  const start = testBlockConnections.slice().reverse().find(({index: testBlockIndex, testBlock: {type}}) => ((type === 'sectionStart' || type === 'startFullLength' || type === 'sectionBreak') && testBlockIndex <= currentBlockIndex)) || {index: 0};
  const end = testBlockConnections.find(({index: testBlockIndex, testBlock: {type}}) => (['sectionReview', 'endBlock'].includes(type) && testBlockIndex >= currentBlockIndex)) || {index: 0};

  return [
    start.index,
    end.index,
  ];
}

function getFirstFilterMatch(block, filter) {
  try {
    if (!block || !block.questions) return null;
    const index = block.questions.findIndex(progressObject => {
      if (filter.key === 'search') {
        const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
        const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
        return search.includes(filter.value.toLowerCase());
      }

      return Array.isArray(filter.value)
        ? filter.value.includes(progressObject[filter.key])
        : progressObject[filter.key] === filter.value
    });

    return index !== -1 ? index : null;
  } catch (error) {
    return null;
  }

}

function getLastFilterMatch(block, filter) {
  if (!block.questions) return null;

  const index = block.questions.slice().reverse().findIndex(progressObject => {
    if (filter.key === 'search') {
      const {prompt, answer, explanation, questionHeader} = getQuestionParts(progressObject.question);
      const search = [prompt, answer, explanation, questionHeader].join(' ').toLowerCase();
      return search.includes(filter.value.toLowerCase());
    }

    return progressObject[filter.key] === filter.value
  });
  const adjustedIndex = block.questions.length - index - 1;

  return index !== -1 ? adjustedIndex : null;
}

function getFirstFiltered(quizProgress, sectionBounds, filter) {
  if (!filter) return null;

  return quizProgress
    .reduce((acc, block, index) => {
      const firstFiltered = block.questions && getFirstFilterMatch(block, filter);

      if (acc.length || (index < sectionBounds[0]) || index > sectionBounds[1]) {
        return acc;
      } else if (firstFiltered !== null) {
        return [index, firstFiltered];
      } else {
        return acc;
      }
    }, []);
}

function getLastFiltered(quizProgress, sectionBounds, filter) {
  if (!filter) return null;

  return quizProgress
    .reduce((acc, block, index) => {
      const lastFiltered = block.questions && getLastFilterMatch(block, filter);

      if ((index < sectionBounds[0]) || index > sectionBounds[1]) {
        return acc;
      } else if (lastFiltered !== null) {
        return [index, lastFiltered];
      } else {
        return acc;
      }
    }, []);

}

function getDefaults(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters) {
  const {setBlockIndex, setCurrentIndex: setQuestionIndex, clearCurrentIndex} = setters;

  return {
    firstQuestionSet: () => {
      const firstIndexWithQuestions = testBlockConnections.findIndex(({testBlock}) => testBlock && testBlock.questionConnections && testBlock.questionConnections.items && !!testBlock.questionConnections.items.length);
      setBlockIndex(firstIndexWithQuestions);
      setQuestionIndex(firstIndexWithQuestions, 0);
    },
    start: () => {
      clearCurrentIndex();
      setBlockIndex(0);
    },
    begin: () => {
      setBlockIndex(currentBlockIndex + 1);
      setQuestionIndex(currentBlockIndex + 1, 0);
    },
    previous: () => {
      const previousBlockIndex = Math.max(currentBlockIndex - 1, 0);
      const lastQuestionIndex = testBlockConnections[previousBlockIndex] && testBlockConnections[previousBlockIndex].testBlock && testBlockConnections[previousBlockIndex].testBlock.questionConnections.items.length - 1;
      const questionIndex = getLastFilterMatch(quizProgress[previousBlockIndex], filter) || lastQuestionIndex;

      setBlockIndex(previousBlockIndex);
      setQuestionIndex(previousBlockIndex, questionIndex);
    },
    next: () => {
      // this doesnt work for question banks for now because start/end blocks aren't actually in the backend so testBlockConnections isn't reliable
      // handling in a qb specific 'next' transition for now but can use this default once it has start/end structure blocks in the backend
      const nextBlockIndex = currentBlockIndex + 1;
      const shouldUpdateQuestionIndex = ['questionSet', 'passage', 'caseQuestionSet'].includes(testBlockConnections[nextBlockIndex].testBlock.type);
      const questionIndex = getFirstFilterMatch(quizProgress[nextBlockIndex], filter) || 0;

      setBlockIndex(nextBlockIndex);

      if (shouldUpdateQuestionIndex) {
        setQuestionIndex(nextBlockIndex, questionIndex);
      }
    },
    reviewAll: () => {
      const [startIndex] = getSectionWindow(testBlockConnections, currentBlockIndex);
      setBlockIndex(startIndex + 1);
      setQuestionIndex(startIndex + 1, 0);
    },
    firstFilterActive: () => {
      const [blockIndex, questionIndex] = quizProgress
        .reduce((acc, block, index) => {
          const firstFiltered = block.questions && block.questions.findIndex(progressObject => progressObject[filter.key] === filter.value);

          if (!acc.length && firstFiltered !== undefined && firstFiltered !== -1) {
            return [index, firstFiltered];
          } else {
            return acc;
          }
        }, []);

      // if the quizProgress reducer is unable to find match, do nothing
      if (blockIndex === undefined && questionIndex === undefined) return;

      setQuestionIndex(blockIndex, questionIndex);
    },
    nextFilterActive: () => {
      if (filter.key === 'blockFilter') {
        const nextIndex = testBlockConnections.findIndex(({testBlock}, index) => (((filter.value === 'videos' && testBlock.type === 'lesson') || (filter.value === 'quizzes' && testBlock.type === 'questionSet')) && index > currentBlockIndex));
        if (nextIndex != -1) {
          if (filter.value === 'videos') {
            setBlockIndex(nextIndex);
          } else {
            setQuestionIndex(nextIndex, 0);
          }
          return;
        }
      }
      if (filter.key === 'search') {
        const nextIndex = testBlockConnections.findIndex(({testBlock}, index) => testBlock.title && testBlock.title.toLowerCase().includes(filter.value.toLowerCase()) && index > currentBlockIndex);
        if (nextIndex != -1) {
          setBlockIndex(nextIndex);
          if (testBlockConnections[nextIndex]?.testBlock?.type === 'questionSet') {
            setQuestionIndex(nextIndex, 0);
          }
          return;
        }
      }
      const {index: nextReviewIndex} = getNextReviewBlock(testBlockConnections, currentBlockIndex);

      const sectionBounds = [currentBlockIndex + 1, nextReviewIndex];

      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, sectionBounds, filter);

      if (!blockIndex) {
        setBlockIndex(nextReviewIndex);
      } else {
        setBlockIndex(blockIndex);
        Number.isInteger(questionIndex) && setQuestionIndex(blockIndex, questionIndex);
      }
    },
    previousFilterActive: () => {
      if (filter.key === 'blockFilter') {
        const previousBlock = testBlockConnections.slice(0, currentBlockIndex).reverse().find(({testBlock}, index) => ((filter.value === 'videos' && testBlock.type === 'lesson') || (filter.value === 'quizzes' && testBlock.type === 'questionSet')) && index < currentBlockIndex);
        if (previousBlock) {
          if (filter.value === 'videos') {
            setBlockIndex(previousBlock.index);
          } else {
            setQuestionIndex(previousBlock.index, 0);
          }
          return;
        }
      }
      if (filter.key === 'search') {
        const previousBlock = testBlockConnections.slice(0, currentBlockIndex).reverse().find(({testBlock}, index) => testBlock.title && testBlock.title.toLowerCase().includes(filter.value.toLowerCase()) && index < currentBlockIndex);
        if (previousBlock) {
          setBlockIndex(previousBlock.index);
          if (testBlockConnections[previousBlock.index]?.testBlock?.type === 'questionSet') {
            setQuestionIndex(previousBlock.index, 0);
          }
          return;
        }
      }

      const sectionBounds = [0, currentBlockIndex - 1];

      const [blockIndex, questionIndex] = getLastFiltered(quizProgress, sectionBounds, filter);

      if (blockIndex) {
        setBlockIndex(blockIndex);
        Number.isInteger(questionIndex) && setQuestionIndex(blockIndex, questionIndex);
      }
    },
  };
}

function practiceTest(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters) {
  const defaults = getDefaults(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters);
  const {setBlockIndex, setCurrentIndex: setQuestionIndex} = setters;
  const filterActive = !!filter.key;

  const transitions = {
    ...defaults,
    review: () => {
      const index = testBlockConnections.length - 1;
      setBlockIndex(index);
    },
    reviewSkipped: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'didSkip', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    reviewMarked: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'didMark', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
  };

  const transition = (filterActive && transitions[`${action}FilterActive`]) ? transitions[`${action}FilterActive`] : transitions[action];
  return transition();
}

// TODO might be able to just use quizProgress instead of testBlockConnections
// TALKING POINT if a question is marked, and then unmarked when reviewing marked, it doesn't show up in review skipped
function fullLengthTest(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters) {
  const defaults = getDefaults(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters);
  const {setBlockIndex, setCurrentIndex: setQuestionIndex} = setters;
  const sectionBounds = getSectionBounds(testBlockConnections, currentBlockIndex);
  const filterActive = !!filter.key;

  const transitions = {
    ...defaults,
    sectionReview: () => {
      const nextIndex = testBlockConnections
        .find(({testBlock}, index) => (testBlock.type === 'sectionReview') && index > currentBlockIndex);

      setBlockIndex(nextIndex.index);

    },
    reviewSkipped: () => {
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, sectionBounds, {key: 'didSkip', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    reviewMarked: () => {
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, sectionBounds, {key: 'didMark', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    jumpToNextSection: () => {
      const nextSectionStart = testBlockConnections.slice(currentBlockIndex).find(({testBlock}) => ['sectionStart', 'sectionBreak'].includes(testBlock.type));
      if (nextSectionStart && nextSectionStart.index && nextSectionStart.index === currentBlockIndex) {
        const nextSectionStart = testBlockConnections.slice(currentBlockIndex+1).find(({testBlock}) => ['sectionStart', 'sectionBreak'].includes(testBlock.type));
        return nextSectionStart && nextSectionStart.index && setBlockIndex(nextSectionStart.index);
      }
      nextSectionStart && nextSectionStart.index && setBlockIndex(nextSectionStart.index);
    }
  }

  const transition = (filterActive && transitions[`${action}FilterActive`]) ? transitions[`${action}FilterActive`] : transitions[action];
  return transition();
}

function questionBank(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters, endBlockIndex) {
  const defaults = getDefaults(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters);
  const {setBlockIndex, setCurrentIndex: setQuestionIndex, clearCurrentIndex} = setters;
  const filterActive = !!filter.key;

  const transitions = {
    ...defaults,
    next: () => {
      const nextBlockIndex = getNextQuestionBlockIndex(testBlockConnections, currentBlockIndex);

      if (nextBlockIndex === -1) {
        clearCurrentIndex();
        setBlockIndex(endBlockIndex);
        return;
      } else {
        setBlockIndex(nextBlockIndex);
      }

      const shouldUpdateQuestionIndex = ['questionSet', 'passage', 'caseQuestionSet'].includes(testBlockConnections[nextBlockIndex].testBlock.type);
      const questionIndex = getFirstFilterMatch(quizProgress[nextBlockIndex], filter) || 0;

      setBlockIndex(nextBlockIndex);

      if (shouldUpdateQuestionIndex) {
        setQuestionIndex(nextBlockIndex, questionIndex);
      }
    },
    previous: () => {
      const previousBlockIndex = Math.max(currentBlockIndex - 1, 0);
      const lastQuestionIndex = testBlockConnections?.[previousBlockIndex] && testBlockConnections?.[previousBlockIndex]?.testBlock && testBlockConnections?.[previousBlockIndex]?.testBlock?.questionConnections?.items?.length - 1;
      const questionIndex = getLastFilterMatch(quizProgress?.[previousBlockIndex], filter) || lastQuestionIndex;

      if (previousBlockIndex === 0) return;

      setBlockIndex(previousBlockIndex);
      setQuestionIndex(previousBlockIndex, questionIndex);
    },
    nextUntagged: () => {
      const sectionBounds = [1, endBlockIndex - 1];
      const [blockIndexMatch, questionIndexMatch] = getFirstFiltered(quizProgress, sectionBounds, {key: 'masteryLevel', value: 'none'}) || [0, 0];

      const blockIndex = blockIndexMatch || sectionBounds[0];
      const questionIndex = questionIndexMatch || 0;

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    nextUnanswered: () => {
      const sectionBounds = [1, endBlockIndex - 1];
      const [blockIndexMatch, questionIndexMatch] = getFirstFiltered(quizProgress, sectionBounds, {key: 'didCheck', value: [false, null, undefined]}) || [0, 0];
      const blockIndex = blockIndexMatch || sectionBounds[0];
      const questionIndex = questionIndexMatch || 0;

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    review: () => {
      const index = testBlockConnections.length - 1;
      setBlockIndex(index);
    },
    reviewSkipped: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'didSkip', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    reviewIncomplete: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'selectedAnswerIndex', value: -1});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    reviewMarked: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'didMark', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
  }

  const transition = (filterActive && transitions[`${action}FilterActive`]) ? transitions[`${action}FilterActive`] : transitions[action];
  return transition();
}

function testReview(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters, endBlockIndex) {
  const defaults = getDefaults(action, currentBlockIndex, testBlockConnections, quizProgress, filter, setters);
  const {setBlockIndex, setCurrentIndex: setQuestionIndex, clearCurrentIndex} = setters;
  const filterActive = !!filter.key;

  const transitions = {
    ...defaults,
    review: () => {
      const index = testBlockConnections.length - 1;
      setBlockIndex(index);
    },
    reviewSkipped: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'didSkip', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    reviewMarked: () => {
      const bounds = [1, testBlockConnections.length - 1];
      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, bounds, {key: 'didMark', value: true});

      setBlockIndex(blockIndex);
      setQuestionIndex(blockIndex, questionIndex);
    },
    nextFilterActive: () => {
      const sectionBounds = [currentBlockIndex + 1, testBlockConnections.length];

      const [blockIndex, questionIndex] = getFirstFiltered(quizProgress, sectionBounds, filter);

      if (!blockIndex) {
        setBlockIndex(0);
      } else {
        setBlockIndex(blockIndex);
        Number.isInteger(questionIndex) && setQuestionIndex(blockIndex, questionIndex);
      }
    },
    next: () => {
      const nextBlockIndex = getNextQuestionBlockIndex(testBlockConnections, currentBlockIndex);

      if (nextBlockIndex === -1) {
        clearCurrentIndex();
        setBlockIndex(endBlockIndex);
        return;
      } else {
        setBlockIndex(nextBlockIndex);
      }

      const shouldUpdateQuestionIndex = ['questionSet', 'passage', 'caseQuestionSet'].includes(testBlockConnections[nextBlockIndex].testBlock.type);
      const questionIndex = getFirstFilterMatch(quizProgress[nextBlockIndex], filter) || 0;


      if (shouldUpdateQuestionIndex) {
        setQuestionIndex(nextBlockIndex, questionIndex);
      }
    }
  }

  const transition = (filterActive && transitions[`${action}FilterActive`]) ? transitions[`${action}FilterActive`] : transitions[action];
  return transition();
}

function anatomyCase(...args) {
  return practiceTest(...args);
}

function anatomyCaseReview(...args) {
  return testReview(...args);
}

function coursePlayer(...args) {
  return practiceTest(...args);
}

function tbcQuestionBank(...args) {
  return questionBank(...args);
}
function tbcSavedBank(...args) {
  return questionBank(...args);
}

function quickReview(...args) {
  return questionBank(...args);
}

function masteryReview(...args) {
  return questionBank(...args);
}

function bookmarkedQuestionReview(...args) {
  return questionBank(...args);
}

function customTest(...args) {
  return questionBank(...args);
}

export {practiceTest, anatomyCase, anatomyCaseReview, fullLengthTest, customTest, questionBank, tbcQuestionBank, tbcSavedBank, getSectionBounds, getSectionWindow, testReview, coursePlayer, quickReview, masteryReview, bookmarkedQuestionReview};
