import {getTest, getTestProgressByUserIdHashTestId, createTestProgress, createBlockProgress, updateBlockProgress, getBlockProgress} from '@bootcamp/shared/src/requests';
import {getInObj, getTestBlockConnections} from '@bootcamp/shared/src/util';


function getBlockKey(block) {
  const blockTitle = block?.title?.trim()?.split(' ')?.join('-')?.toLowerCase();
  const blockType = block?.type;

  return `${blockTitle}#${blockType}`;
}

// TODO this block can be removed in 6-8 months or so when the current cohort is finished
async function syncQRVideoLessonData (baseVideoLessonData, config, DEFAULT_USER_ID, saveUserInteraction) {
  const oldCourseIds = [
    // '0ece99a6-3870-4ceb-b209-8af3c406d5b2',
    '5742007a-5041-4767-8bd3-e716232474da',
    '331110ef-cd03-4b00-96b9-90bc9373d3d0',
    // '5fa8df2f-1778-4c67-9b1f-446b4f4af39b',
    '04fbc660-5f86-4fd8-9b5b-8ccedbc97923',
    // '73e307e8-d3d6-4ae7-9c41-fd3bab40c201',
  ];

  try {
    const oldProgressByBlockTitle = await oldCourseIds.reduce(async (acc, courseId) => {
      const {data} = await getTest(courseId, 'getTestTile');
      const {data: {TestProgressByTestId}} = await getTestProgressByUserIdHashTestId(`${DEFAULT_USER_ID}#${courseId}`, true);

      const testBlocks = data?.getTest?.blockConnections?.items;

      const update = await acc;

      TestProgressByTestId
        ?.items
        ?.forEach(({blockProgresses}) => blockProgresses?.items?.forEach(block => {
          const blockMatch = testBlocks?.find(({testBlockConnectionTestBlockId}) => testBlockConnectionTestBlockId === block?.blockId)

          if (!blockMatch) return;

          const blockKey = getBlockKey(blockMatch?.testBlock);

          update[blockKey] = block;
        }))

      return update;
    }, []);

    const syncedQrBlockProgress = Object.keys(baseVideoLessonData).reduce((acc, testId) => {
      const testProgress = baseVideoLessonData[testId];

      return {
        ...acc,
        [testId]: {
          ...testProgress,
          quizProgress: testProgress.quizProgress.map(blockProgress => {

            const blockKey = getBlockKey(blockProgress);

            return {...blockProgress, status: oldProgressByBlockTitle[blockKey]?.status} || blockProgress;
          })
        }
      }
    }, {});

    const syncProgress = async ({progressId, quizProgress}) => {
      try {
        await Promise.all(quizProgress?.map(async ({blockProgressId, testBlockId, status, newBlockProgress}, index) => {
          await createBlockProgress(
            blockProgressId,
            progressId,
            testBlockId,
            {status}
          );
        }))
      } catch (error) {
        // console.log('error saving', error);
      }
    }

    await Promise.all(Object.values(syncedQrBlockProgress).map(syncProgress));

    saveUserInteraction('syncedQrCourseData', true);

    return syncedQrBlockProgress

  } catch (error) {
    // console.log('error syncing data', error);
  }
}

async function fetchVideoLessonData (config, DEFAULT_USER_ID) {
  return await config.content
    .find(({type}) => type === 'Course').content
    .reduce(async (videoLessonData, lesson) => {
      const {id: videoLessonId} = lesson;
      const result = await getTest(videoLessonId, 'getTestQuestionIdsWithConfig');
      const test = getInObj(['data', 'getTest'], result, {});

      const testBlockConnections = getTestBlockConnections(test).map(({testBlock}) => testBlock);
      const blockTemplates = {
        startBlock: {
          blockProgressId: (block, userId) => `${block.id}#${userId}`,
          testBlockId: block => block.id,
        },
        text: {
          blockProgressId: (block, userId) => `${block.id}#${videoLessonId}#${userId}`,
          title: block => block.title,
          type: block => block.type,
          testBlockId: block => block.id,
          status: () => '',
          newBlockProgress: () => true,
        },
        passage: {
          blockProgressId: (block, userId) => `${block.id}#${userId}`,
          title: block => block.title,
          type: block => block.type,
          testBlockId: block => block.id,
          status: () => '',
          newBlockProgress: () => true,
        },
        lesson: {
          blockProgressId: (block, userId) => `${block.id}#${userId}`,
          title: block => block.title,
          type: block => block.type,
          testBlockId: block => block.id,
          status: () => '',
          newBlockProgress: () => true,
        },
        questionSet: {
          blockProgressId: (block, userId) => `${block.id}#${userId}`,
          title: block => block.title,
          type: block => block.type,
          testBlockId: block => block.id,
          status: () => '',
          newBlockProgress: () => true
        },
        endBlock: {
          blockProgressId: (block, userId) => `${block.id}#${userId}`,
          testBlockId: block => block.id,
        }
      }

      const userIdHashTestId = `${DEFAULT_USER_ID}#${videoLessonId}`;

      const baseProgress = await Promise.all(testBlockConnections.map(testBlock => {
        const blockTemplate = blockTemplates[testBlock.type] || {
          blockProgressId: (block, userId) => `${block.id}#${userId}`,
          testBlockId: block => block.id,
          status: () => '',
          newBlockProgress: () => true,
        };
        return Object.keys(blockTemplate)
        .reduce(async (acc, key) => ({...(await acc), [key]: await blockTemplate[key](testBlock, DEFAULT_USER_ID)}), {});
      }));

      // see if progress for this video lesson exists
      const data = await getTestProgressByUserIdHashTestId(userIdHashTestId, true);
      const progressId = getInObj(['data', 'TestProgressByTestId', 'items', 0, 'id'], data, null);
      if (!progressId) {
        // create testProgress if it hasn't been created yet
        const testProgress = await createTestProgress(userIdHashTestId, DEFAULT_USER_ID, videoLessonId);
        const newProgressId = getInObj(['data', 'createTestProgress', 'id'], testProgress, null);

        const promises = baseProgress.map(async (blockProgress, index) => {
          if (config.name === 'Dermatology Videos') {
            const blockProgressId = `${blockProgress.testBlockId}#${DEFAULT_USER_ID}`;
            const {data: {getBlockProgress: existingBlockProgress}} = await getBlockProgress(blockProgressId);
            if (existingBlockProgress) {
              await updateBlockProgress(blockProgressId, {testProgressBlockProgressesId: newProgressId})
            }
            return {
              ...blockProgress,
              ...existingBlockProgress
            }
          }
          return blockProgress;
        });

        const quizProgress = await Promise.all(promises);

        return {
          ...(await videoLessonData),
          [videoLessonId]: {
            test,
            progressId: newProgressId,
            quizProgress
          }
        };
      }
      const testProgress = getInObj(['data', 'TestProgressByTestId', 'items', 0], data, null);
      const restoredProgressPromises = baseProgress.map(async (blockProgress, index) => {
        const savedProgress = testProgress.blockProgresses.items && testProgress.blockProgresses.items.find(savedBlockProgress => savedBlockProgress.blockId === blockProgress.testBlockId);
        if (savedProgress) {
          const {id, blockId, index, status} = savedProgress;
          return {
            ...blockProgress,
            blockProgressId: id,
            testBlockId: blockId,
            index,
            status,
            newBlockProgress: false
          }
        }

        return blockProgress;
      });

      const restoredProgress = await Promise.all(restoredProgressPromises);

      return {
        ...(await videoLessonData),
        [videoLessonId]: {
          test,
          progressId,
          quizProgress: restoredProgress
        }
      };
    }, {});
}


async function getVideoLessonData(config, DEFAULT_USER_ID, setData, routeKey, searchUserInteractions, saveUserInteraction) {
  const {route, name} = config;
  const baseVideoLessonData = await fetchVideoLessonData(config, DEFAULT_USER_ID);

  const hasSyncedQrCourseData = searchUserInteractions('syncedQrCourseData') === true;

  const videoLessonData = ['QR Academy']?.includes(name) && !hasSyncedQrCourseData
    ? await syncQRVideoLessonData(baseVideoLessonData, config, DEFAULT_USER_ID, saveUserInteraction)
    : baseVideoLessonData;

  function saveBlockProgress (testId, value, blockIndexOverride) {
    function backendSaveRequest (progressId, quizProgress) {
      if (DEFAULT_USER_ID) {
        const {blockProgressId, testBlockId, newBlockProgress} = quizProgress[blockIndexOverride];
        if (newBlockProgress) {
          createBlockProgress(
            blockProgressId,
            progressId,
            testBlockId,
            {...value},
            blockIndexOverride
          );
        } else {
          updateBlockProgress(
            blockProgressId,
            {
              testProgressBlockProgressesId: progressId,
              blockId: testBlockId,
              index: blockIndexOverride,
              ...value
            }
          );
        }
      }
    }

    setData((data) => {
      // fetch current state data
      const {videoLessonData} = data[route];
      const {progressId, quizProgress} = videoLessonData[testId];

      // async save to backend
      backendSaveRequest(progressId, quizProgress);

      // create immutable state update
      const updatedQuizProgress = [
        ...quizProgress.slice(0, blockIndexOverride),
        {...quizProgress[blockIndexOverride], ...value, newBlockProgress: false},
        ...quizProgress.slice(blockIndexOverride + 1)
      ];

      // sync state update
      // return immutable
      return {
        ...data,
        [route]: {
          ...data[route],
          videoLessonData: {
            ...data[route].videoLessonData,
            [testId]: {
              ...data[route].videoLessonData[testId],
              quizProgress: updatedQuizProgress
            }
          }
        }
      }
    });
  }

  return {videoLessonData, saveBlockProgress};
}

export {getVideoLessonData};
