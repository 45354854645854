import React, {useEffect} from 'react';
import styled from 'styled-components';
import * as THREE from 'three';

const Container = styled.div`
`;

const newHtmlString = `
    <div class="box-wrap">
      <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r99/three.js"></script>

      <div id="introduction">

        <div class="bartop">

        <div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Cube Counting Practice Generator</h2></div></div></div>

        <div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

        <div class="instructions-container">

        <p>This is the cube counting generator. Please read the following before starting:</p>

        <p>1. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">cube counting instructions</a>.<br />
        2. If you're having trouble, check out the <a href="/dat/perceptual-ability/videos/cube-counting?index=1" target="_blank">PAT Academy Cube Counting Tutorial</a>.<br />
        3. This generator creates entirely new, unique questions on the fly, so practice as much as you'd like!</p>

        <p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

        <p style="margin-top: 35px;"><a class="nextbutton" id="start-generator" style="float: none; cursor: pointer; margin-top: 35px!important;" onclick="">Start Generator</a></p>


        <div id="instructions" style="display: none;">
        <p style="margin-top: 50px;"><strong>Official Cube Counting Instructions</strong></p>

        <p>Each figure has been made by cementing together cubes of the same size. After being cemented each figure was painted on all sides EXCEPT FOR THE BOTTOM ON WHICH IT IS RESTING. The only hidden cubes are those required to support other cubes.</p>

        <p>For the following questions you are to examine each figure closely to determine how many cubes have:
        <ul>
        <li>Only <strong>one</strong> of their sides painted</li>
        <li>Only <strong>two</strong> of their sides painted</li>
        <li>Only <strong>three</strong> of their sides painted</li>
        <li>Only <strong>four</strong> of their sides painted</li>
        <li>All <strong>five</strong> of their sides painted.</li>
        </ul></p>

        <p><strong>Note</strong>: There are no problems for which zero (0) is the correct answer.</p>

        </div>

        <div style="display: block; height: 100px;"></div>

        </div>

      </div>

        <div id="generator" style="display: none;">

        <div class="bartop">
          <div class="bartopcontainer">
          <div class="column-center"><h2>Cube Counting Practice Generator</h2></div>
          <div class="column-left"><p id="position">Question <span id="location" style="font-weight: 600;">1</span> of <span id="total" style="font-weight: 600;">1</span></p></div><div class="column-right">
            <p id="time-box" style="float: right;">Time Remaining: <span id="time-display" style="font-weight: 600;">30</span><span id="time-button" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">On/Off</span></p>
            <p id="time-box" style="float: right;">Average Time: <span id="average-time" style="font-weight: 600;">0</span><span  style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Seconds</span></p>
          </div></div>
        </div>

        <div class="generator-container">

            <div id="container">
              <p class="questiontext" style="font-size: 1.3em;">How many cubes have <span id="query" style="font-weight: 600;">0</span> of their exposed sides painted?</p>
              <div style="height: 242px; margin-top: 10px;">
            <canvas id="puzzle" style="max-width: 290px; height: 100%; float: left;"></canvas>
            <div id="solution-canvas">
            <div style="display: flex; margin-bottom: 5px; justify-content: center;">
              <button class="canvas-button" id="reset">Reset</button>
            </div>
            <div id="sol-layer" style="width: 290px; height: 240px; float: left;"></div>
          </div>
            <div style="height: 242px; float: left; margin-left: 50px;">
            <p id="solution" style="font-size: 1em; color: #0D0; margin: 4px 40px 0 0; padding: 0;"></p>
                <canvas id="layer-1" style="width: 146px; height: 106px; float: left;"></canvas>
                <canvas id="layer-2" style="width: 146px; height: 106px; float: left;"></canvas>
                <canvas id="layer-3" style="width: 146px; height: 106px; float: left;"></canvas>
                <canvas id="layer-4" style="width: 146px; height: 106px; float: left;"></canvas>
            </div>
              </div>
          <div style="clear: both;"></div>
              <div id="grades" style="font-size: 16px; margin-top: 10px; border: 1px solid #E8E8E8; padding: 5px;">
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="A"><div style="display: inline-block; width: 95px;"><label>A.<span id="amount-1" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-A" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="B"><div style="display: inline-block; width: 95px;"><label>B.<span id="amount-2" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-B" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="C"><div style="display: inline-block; width: 95px;"><label>C.<span id="amount-3" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-C" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="D"><div style="display: inline-block; width: 95px;"><label>D.<span id="amount-4" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-D" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="E"><div style="display: inline-block; width: 95px;"><label>E.<span id="amount-5" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-E" style="margin-left: 15px; display: inline-block;"></div></div>
              </div>


          <div class="mobileexamfix" style="clear: both; height: 25px; margin: 30px 0px;"></div>

          <div class="barbottom">

          <div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; padding: 15px 0px; justify-content: space-between;">

            <div style="display: flex; flex-direction: row; width: 33%;"><button type="button" id="previous" class="nextbutton" style="margin-right: 15px!important;">Previous</button><button type="button" id="next" class="nextbutton">Next</button></div>

            <div style="display: flex; flex-direction: column; width: 67%; text-align: right; color: #FFF;"><div id="scoreboard">Correct: <span id="correct">0</span> | Incorrect: <span id="incorrect">0</span> |<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span> | Current Difficulty:
            <select name="Difficulty" id="setdifficulty"><option value="0">DAT Mode</option><option value="1">Beginner Mode</option></select>
            <select name="Timer" id="setTimer">
              <option value="60">60 secs</option>
              <option value="45">45 secs</option>
              <option value="30">30 secs</option>
              <option value="15">15 secs</option>
            </select>
          </div></div>

          </div>

          </div>

        </div>

        </div>

      </div>

`

const htmlString = `
  <div class="box-wrap">

  <div id="introduction">

    <div class="bartop">

    <div class="bartopcontainer"><div class="column-center" style="width: 99%;"><h2 style="margin-top: -1px;">Cube Counting Practice Generator</h2></div></div></div>

    <div style="width: 100%; position: fixed; left: 0px; bottom: 0px; height: 85.71px; background-color: rgb(15, 98, 146); z-index: 1;"></div>

    <div class="instructions-container">

      <p>This is the cube counting generator. Please read the following before starting:</p>

      <p>1. If this is your first time, take a second to read the official <a onclick="$('#instructions').show('slow')" style="cursor: pointer;">cube counting instructions</a>.<br />
      2. If you're having trouble, check out the <a href="/dat/perceptual-ability/videos/cube-counting?index=1" target="_blank">PAT Academy Cube Counting Tutorial</a>.<br />
      3. This generator creates entirely new, unique questions on the fly, so practice as much as you'd like!</p>

      <p>Try to spend 15 minutes practicing with this generator each day to increase your speed and efficiency.</p>

      <p style="margin-top: 35px;"><a class="nextbutton" style="float: none; cursor: pointer; margin-top: 35px!important;" onclick="document.getElementById('generator').style.display='block'; document.getElementById('introduction').style.display='none';">Start Generator</a></p>


      <div id="instructions" style="display: none;">
      <p style="margin-top: 50px;"><strong>Official Cube Counting Instructions</strong></p>

      <p>Each figure has been made by cementing together cubes of the same size. After being cemented each figure was painted on all sides EXCEPT FOR THE BOTTOM ON WHICH IT IS RESTING. The only hidden cubes are those required to support other cubes.</p>

      <p>For the following questions you are to examine each figure closely to determine how many cubes have:
      <ul>
      <li>Only <strong>one</strong> of their sides painted</li>
      <li>Only <strong>two</strong> of their sides painted</li>
      <li>Only <strong>three</strong> of their sides painted</li>
      <li>Only <strong>four</strong> of their sides painted</li>
      <li>All <strong>five</strong> of their sides painted.</li>
      </ul></p>

      <p><strong>Note</strong>: There are no problems for which zero (0) is the correct answer.</p>

      </div>

      <div style="display: block; height: 100px;"></div>

    </div>

  </div>

    <div id="generator" style="display: none;">

      <div class="bartop">
        <div class="bartopcontainer">
          <div class="column-center"><h2>Cube Counting Practice Generator</h2></div>
          <div class="column-left"><p id="position">Question <span id="location" style="font-weight: 600;">1</span> of <span id="total" style="font-weight: 600;">1</span></p></div><div class="column-right"><p id="time-box" style="float: right;">Time Remaining: <span id="time-display" style="font-weight: 600;">30</span><span id="time-button" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">On/Off</span></p></div></div>
      </div>

      <div class="generator-container">

              <div id="container">
                <p class="questiontext" style="font-size: 1.3em;">How many cubes have <span id="query" style="font-weight: 600;">0</span> of their exposed sides painted?</p>
                <div style="height: 242px; margin-top: 10px;">
            <canvas id="puzzle" style="max-width: 290px; height: 100%; float: left;"></canvas>
            <div style="max-width: 600px; height: 242px; float: left;">
              <p id="solution" style="font-size: 1em; color: #0D0; margin: 4px 40px 0 0; padding: 0;"></p>
                    <canvas id="layer-1" style="width: 146px; height: 106px; float: left;"></canvas> <canvas id="layer-2" style="width: 146px; height: 106px; float: left;"></canvas><canvas id="layer-3" style="width: 146px; height: 106px; float: left;"></canvas><canvas id="layer-4" style="width: 146px; height: 106px; float: left;"></canvas>
            </div>
                </div>
          <div style="clear: both;"></div>
                <div id="grades" style="font-size: 16px; margin-top: 10px; border: 1px solid #E8E8E8; padding: 5px;">
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="A"><div style="display: inline-block; width: 95px;"><label>A.<span id="amount-1" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-A" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="B"><div style="display: inline-block; width: 95px;"><label>B.<span id="amount-2" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-B" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="C"><div style="display: inline-block; width: 95px;"><label>C.<span id="amount-3" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-C" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="D"><div style="display: inline-block; width: 95px;"><label>D.<span id="amount-4" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-D" style="margin-left: 15px; display: inline-block;"></div></div>
            <div class="grade" style="margin: 3px 3px 8px 3px;"><input type="radio" name="answer" value="E"><div style="display: inline-block; width: 95px;"><label>E.<span id="amount-5" style="float: right; text-align: left; width: 70px;">0</span></div></label><div id="grade-E" style="margin-left: 15px; display: inline-block;"></div></div>
                </div>


        <div class="mobileexamfix" style="clear: both; height: 25px; margin: 30px 0px;"></div>

        <div class="barbottom">

          <div style="display: flex; width: 1000%; max-width: 1000px; margin: 0 auto; align-items: center; padding: 15px 0px; justify-content: space-between;">

            <div style="display: flex; flex-direction: row; width: 33%;"><button type="button" id="previous" class="nextbutton" style="margin-right: 15px!important;">Previous</button><button type="button" id="next" class="nextbutton">Next</button></div>

            <div style="display: flex; flex-direction: column; width: 67%; text-align: right; color: #FFF;"><div id="scoreboard">Correct: <span id="correct">0</span> | Incorrect: <span id="incorrect">0</span> |<span id="game-reset" style="cursor: pointer; margin-left: 6px; color: #A9E2F3;">Reset</span> | Current Difficulty: <select name="Difficulty" id="setdifficulty"><option value="0">DAT Mode</option><option value="1">Beginner Mode</option></select></div></div>

          </div>

        </div>

      </div>

    </div>

  </div>
`;

const CubeCounting = () => {

  useEffect(() => {
    window.THREE = THREE;
  }, []);

  return (
    <Container dangerouslySetInnerHTML={{__html: newHtmlString}}/>
  );
}


export default CubeCounting;