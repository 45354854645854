import React, {useState, useEffect, useMemo, createContext, useContext} from 'react';

import {useMasteryArray} from '@bootcamp/web/src/hooks';

import nanoid from 'nanoid';

import {capitalize} from '@bootcamp/shared/src/util';
import {useTestState, TestState} from '@bootcamp/shared/src/createTest';
import {useUserDataContext} from '../../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../../contexts/BootcampConfig';



const TestStateContextProvider = ({defaults, children}) => {
  const {isUpgraded, bootcamp, loading, DEFAULT_USER_ID} = useUserDataContext();
  const {config, questionCounts} = useBootcampConfigContext();

  const value = useTestState(config, questionCounts, bootcamp, defaults, isUpgraded, DEFAULT_USER_ID, loading);

  return (
    <TestState.Provider value={value}>
      {children}
    </TestState.Provider>
  );
}


const useCreateTestState = () => useContext(TestState);

export {TestStateContextProvider, useCreateTestState}