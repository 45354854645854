import ari from '@bootcamp/shared/src/assets/educators/ari.gif'

export const defaultEducator = {
  name: 'Dr. Ari',
  imgUrl: ari,
};

export const examResults = {
  "Biology Test #1": [
    "30",
    "25",
    "24",
    "23",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14"
  ],
  "Biology Test #2": [
    "30",
    "25",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14",
    "14",
    "14"
  ],
  "Biology Test #3": [
    "30",
    "25",
    "23",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14",
    "14"
  ],
  "Biology Test #4": [
    "30",
    "24",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "15",
    "14",
    "14"
  ],
  "Biology Test #5": [
    "30",
    "25",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14"
  ],
  "Biology Test #6": [
    "30",
    "25",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "18",
    "16",
    "15",
    "14"
  ],
  "Biology Test #7": [
    "30",
    "25",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "18",
    "16",
    "15",
    "14"
  ],
  "Biology Test #8": [
    "30",
    "25",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "18",
    "16",
    "15",
    "14"
  ],
  "Biology Test #9": [
    "30",
    "25",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "18",
    "16",
    "15",
    "14"
  ],
  "Biology Test #10": [
    "30",
    "25",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "18",
    "16",
    "15",
    "14"
  ],
  "General Chemistry Test #1": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14"
  ],
  "General Chemistry Test #2": [
    "30",
    "26",
    "24",
    "24",
    "23",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "General Chemistry Test #3": [
    "30",
    "26",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14"
  ],
  "General Chemistry Test #4": [
    "30",
    "26",
    "24",
    "23",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "General Chemistry Test #5": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "18",
    "17",
    "14"
  ],
  "General Chemistry Test #6": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "14"
  ],
  "General Chemistry Test #7": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "14"
  ],
  "General Chemistry Test #8": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "14"
  ],
  "General Chemistry Test #9": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "14"
  ],
  "General Chemistry Test #10": [
    "30",
    "26",
    "24",
    "23",
    "22",
    "22",
    "21",
    "20",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "14"
  ],
  "Organic Chemistry Test #1": [
    "30",
    "26",
    "23",
    "22",
    "21",
    "20",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14",
    "13",
    "13",
    "12"
  ],
  "Organic Chemistry Test #2": [
    "30",
    "26",
    "23",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14",
    "14"
  ],
  "Organic Chemistry Test #3": [
    "30",
    "25",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14",
    "14"
  ],
  "Organic Chemistry Test #4": [
    "30",
    "26",
    "23",
    "23",
    "22",
    "21",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "15",
    "14",
    "14"
  ],
  "Organic Chemistry Test #5": [
    "30",
    "26",
    "23",
    "23",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Organic Chemistry Test #6": [
    "30",
    "27",
    "23",
    "22",
    "22",
    "21",
    "21",
    "20",
    "20",
    "19",
    "18",
    "18",
    "17",
    "16",
    "14"
  ],
  "Organic Chemistry Test #7": [
    "30",
    "27",
    "23",
    "22",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "18",
    "17",
    "16",
    "14"
  ],
  "Organic Chemistry Test #8": [
    "30",
    "27",
    "23",
    "22",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "18",
    "17",
    "16",
    "14"
  ],
  "Organic Chemistry Test #9": [
    "30",
    "27",
    "23",
    "22",
    "22",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "18",
    "17",
    "16",
    "14"
  ],
  "Organic Chemistry Test #10": [
    "30",
    "27",
    "23",
    "22",
    "22",
    "21",
    "21",
    "20",
    "20",
    "19",
    "18",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #1": [
    "30",
    "29",
    "26",
    "25",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "14"
  ],
  "Perceptual Ability Test #2": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #3": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #4": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #5": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #6": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #7": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #8": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #9": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #10": [
    "30",
    "29",
    "27",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "18",
    "17",
    "16",
    "14"
  ],
  "Perceptual Ability Test #11": [
    "30",
    "29",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "21",
    "20",
    "19",
    "18",
    "17",
    "14"
  ],
  "Perceptual Ability Test #12": [
    "30",
    "29",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "21",
    "20",
    "19",
    "18",
    "17",
    "14"
  ],
  "Perceptual Ability Test #13": [
    "30",
    "29",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "21",
    "20",
    "19",
    "18",
    "17",
    "14"
  ],
  "Perceptual Ability Test #14": [
    "30",
    "29",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "21",
    "20",
    "19",
    "18",
    "17",
    "14"
  ],
  "Perceptual Ability Test #15": [
    "30",
    "29",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "21",
    "20",
    "19",
    "18",
    "17",
    "14"
  ],
  "Reading Comprehension Test #1": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #2": [
    "30",
    "28",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #3": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #4": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #5": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #6": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #7": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #8": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #9": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #10": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #11": [
    "30",
    "28",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #12": [
    "30",
    "28",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #13": [
    "30",
    "28",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #14": [
    "30",
    "28",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "17",
    "14"
  ],
  "Reading Comprehension Test #15": [
    "30",
    "28",
    "27",
    "26",
    "25",
    "24",
    "23",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "17",
    "14"
  ],
  "Quantitative Reasoning Test #1": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #2": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #3": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #4": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #5": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #6": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #7": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #8": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #9": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ],
  "Quantitative Reasoning Test #10": [
    "30",
    "25",
    "24",
    "22",
    "21",
    "20",
    "20",
    "19",
    "19",
    "18",
    "18",
    "17",
    "16",
    "16",
    "14"
  ]
};

export function getPercentageThresholds(testSubject) {
  switch (testSubject) {
    case "Biology":
    case "General Chemistry":
    case "Organic Chemistry":
      return [
        96.6,
        93.3,
        90,
        86.6,
        83.3,
        80,
        76.6,
        73.3,
        70,
        63.3,
        56.6,
        50,
        43.3,
        36.6,
        0
      ];
    case "Perceptual Ability":
      return [
        98,
        97,
        96,
        94,
        92,
        90,
        86,
        82,
        76,
        71,
        64,
        57,
        50,
        43,
        0
      ];
    case "Reading Comprehension":
      return [
        98,
        96,
        94,
        92,
        88,
        86,
        82,
        78,
        74,
        68,
        62,
        56,
        50,
        44,
        0
      ];
    case "Quantitative Reasoning":
      return [
        97.5,
        95,
        92.5,
        90,
        87.5,
        82.5,
        77.5,
        72.5,
        67.5,
        60,
        55,
        47.5,
        40,
        35,
        0
      ];
    case "Physics":
      return [
        96.6,
        93.3,
        90,
        86.6,
        83.3,
        80,
        76.6,
        73.3,
        70,
        63.3,
        56.6,
        50,
        43.3,
        36.6,
        0
      ]
    default:
      return [];
  }
}

export function getDistributionScores(testSubject) {
  switch (testSubject) {
    case "Biology":
    case "General Chemistry":
      return [
        "100%",
        "99.8%",
        "98.8%",
        "98.1%",
        "94.7%",
        "92.6%",
        "91.3%",
        "88.6%",
        "82.5%",
        "75.8%",
        "63.3%",
        "51.2%",
        "38.8%",
        "27.9%"
      ];
    case "Organic Chemistry":
      return [
        "100%",
        "99.3%",
        "98.6%",
        "94.4%",
        "87.6%",
        "82.5%",
        "77.2%",
        "65.8%",
        "55.9%",
        "44.6%",
        "33.1%",
        "24.2%",
        "16.3%",
        "9.9%"
      ];
    case "Perceptual Ability":
      return [
        "100%",
        "99.9%",
        "99.8%",
        "99.6%",
        "97.4%",
        "96.3%",
        "95.6%",
        "93.5%",
        "88.9%",
        "80.7%",
        "71.3%",
        "54.3%",
        "46.2%"
      ];
    case "Reading Comprehension":
      return [
        "100%",
        "99.4%",
        "98.4%",
        "97.6%",
        "96.2%",
        "92.8%",
        "87.6%",
        "81.1%",
        "71.9%",
        "63.3%",
        "53.7%",
        "43.2%",
        "33.8%",
      ]
    case "Quantitative Reasoning":
      return [
        "100%",
        "97.8%",
        "96.9%",
        "96.1%",
        "86.2%",
        "86.2%",
        "81.6%",
        "81.6%",
        "74.5%",
        "63.3%",
        "63.3%",
        "41.2%"
      ]
    default:
      return [];
  }
}

export function getCommentary(testSubject) {
  const distributionScores = getDistributionScores(testSubject);

  switch (testSubject) {
    case 'Biology':
    case 'General Chemistry':
      const subjectAbbreviations = {
        "Biology": "Bio",
        "General Chemistry": "GC",
      };
      const subjectAbbreviation = subjectAbbreviations[testSubject];
      return [
        `Congratulations, this is very impressive! You're ready to conquer the ${subjectAbbreviation} section.`,
        `Congratulations, this is very impressive! You're ready to conquer the ${subjectAbbreviation} section.`,
        `Congratulations, this is very impressive! You're ready to conquer the ${subjectAbbreviation} section.`,
        `Congratulations, this is very impressive! You're ready to conquer the ${subjectAbbreviation} section.`,
        `Congratulations, this is very impressive! You're ready to conquer the ${subjectAbbreviation} section.`,
        `Don't stop now, review these explanations carefully to see where you made your mistakes. ${subjectAbbreviation} is an important score, so try to score as high as possible!`,
        `Don't stop now, review these explanations carefully to see where you made your mistakes. ${subjectAbbreviation} is an important score, so try to score as high as possible!`,
        `Don't stop now, review these explanations carefully to see where you made your mistakes. ${subjectAbbreviation} is an important score, so try to score as high as possible!`,
        `Review these explanations carefully and see which topics you're making mistakes in. Then, review your notes and practice with the question bank to refresh your mind on those concepts.`,
        `Review these explanations carefully and see which topics you're making mistakes in. Then, review your notes and practice with the question bank to refresh your mind on those concepts.`,
        `Review these explanations carefully and see which topics you're making mistakes in. Then, review your notes and practice with the question bank to refresh your mind on those concepts.`,
        `Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with continued hard work and practice.`,
        `Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with continued hard work and practice. `,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice. `,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice. `
      ];
    case 'Organic Chemistry':
      return [
        `Congratulations, this is very impressive! You're ready to conquer the O-Chem section.`,
        `Congratulations, this is very impressive! You're ready to conquer the O-Chem section.`,
        `Congratulations, this is very impressive! You're ready to conquer the O-Chem section.`,
        `Congratulations, this is very impressive! You're ready to conquer the O-Chem section.`,
        `Awesome! Don't stop now, review these explanations carefully to see where you made your mistakes. O-Chem is an important score, so try to score as high as possible!`,
        `Review these explanations carefully and learn which topics you're making mistakes in. Then, review your notes and practice with the question bank to refresh your mind on these concepts. You've almost mastered O-Chem, keep going!`,
        `Review these explanations carefully and see which topics you're making mistakes in. Then, review your notes and practice with the question bank to refresh your mind on those concepts.`,
        `Review these explanations carefully and see which topics you're making mistakes in. Then, review your notes and practice with the question bank to refresh your mind on those concepts.`,
        `Review these explanations carefully and learn from your mistakes. I'm confident you'll improve this score with hard work.`,
        `Review these explanations carefully and learn from your mistakes. I'm confident you'll improve this score with hard work.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with hard work and practice.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with hard work and practice.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with hard work and practice.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with hard work and practice.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring up this score with hard work and practice.`
      ]
    case 'Perceptual Ability':
      return [
        `Congratulations, this is very impressive! You're ready to conquer the PAT section.`,
        `Congratulations, this is very impressive! You're ready to conquer the PAT section.`,
        `Congratulations, this is very impressive! You're ready to conquer the PAT section.`,
        `Congratulations, this is very impressive! You're ready to conquer the PAT section.`,
        `Congratulations, this is very impressive! You're ready to conquer the PAT section.`,
        `Congratulations, this is very impressive! You're ready to conquer the PAT section. Keep reviewing the explanations and practice to improve your scores!`,
        `Congratulations! Focus on the areas you're weak in and try to see why you're getting them wrong, then correct the problem. Keep reviewing the explanations and practice to improve your scores!`,
        `Congratulations! Focus on the areas you're weak in and try to see why you're getting them wrong, then correct the problem. Keep reviewing the explanations and practice to improve your scores!`,
        `Take your time to carefully review these explanations and understand the intricacies of each question. Try to visualize the question and solution in your mind. Keep reviewing the explanations and practice to improve your scores!`,
        `Take your time to carefully review these explanations and understand the intricacies of each question. Try to visualize the question and solution in your mind. Keep up the practice and review and you'll improve!`,
        `Take your time to carefully review these explanations and understand the intricacies of each question. Try to visualize the question and solution in your mind. With more practice you'll be able to reach higher scores.`,
        `Keep your head up! The PAT section can be learned and practiced. Take your time to carefully review these explanations and understand the intricacies of each question. Try to visualize the question and solution in your mind.`,
        `Keep your head up! Also, don't focus on your estimated score, they mean essentially nothing at the start. Rarely does anyone start these exams and score well immediately, if that was the case then they wouldn't even need to practice! These are 'practice' tests, meaning you're practicing to improve your skills. If you continue to work hard and study, read and understand the explanations, practice with the PAT generators daily, and give it your best effort, I promise your score will improve. Review and learn for now, and the scores will come.`,
        `Keep your head up! Also, don't focus on your estimated score, they mean essentially nothing at the start. Rarely does anyone start these exams and score well immediately, if that was the case then they wouldn't even need to practice! These are 'practice' tests, meaning you're practicing to improve your skills. If you continue to work hard and study, read and understand the explanations, practice with the PAT generators daily, and give it your best effort, I promise your score will improve. Review and learn for now, and the scores will come.`,
        `Keep your head up! Also, don't focus on your estimated score, they mean essentially nothing at the start. Rarely does anyone start these exams and score well immediately, if that was the case then they wouldn't even need to practice! These are 'practice' tests, meaning you're practicing to improve your skills. If you continue to work hard and study, read and understand the explanations, practice with the PAT generators daily, and give it your best effort, I promise your score will improve. Review and learn for now, and the scores will come.`,
      ];
    case 'Reading Comprehension':
      return [
        `Congratulations, this is very impressive! You're ready to conquer the reading section.`,
        `Congratulations, this is very impressive! You're ready to conquer the reading section.`,
        `Congratulations, this is very impressive! You're ready to conquer the reading section.`,
        `Congratulations, this is very impressive! You're ready to conquer the reading section.`,
        `Congratulations, this is very impressive! You're ready to conquer the reading section.`,
        `Congratulations, this is very impressive! You're ready to conquer the reading section. Keep reviewing the explanations and practice to improve your scores!`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental map of the structure of the passage.`,
        `Keep your head up! Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental image of the structure of the passage.`,
        `Keep your head up! Continue to read science articles to build up your reading speed and comprehension. Review these explanations to understand where the answer lies within the passage to each question, and build a mental image of the structure of the passage.`,
      ]
    case 'Quantitative Reasoning':
      return [
        `Congratulations, this is very impressive! You're ready to conquer the QR section.`,
        `Congratulations, this is very impressive! You're ready to conquer the QR section.`,
        `Congratulations, this is very impressive! You're ready to conquer the QR section.`,
        `Congratulations, this is very impressive! You're ready to conquer the QR section.`,
        `Don't stop now, review these explanations carefully to see where you made your mistakes, and understand how to fix them. Your QR score makes up 1/5th of your AA score, so try to score as high as possible!`,
        `Don't stop now, review these explanations carefully to see where you made your mistakes, and understand how to fix them. Your QR score makes up 1/5th of your AA score, so try to score as high as possible!`,
        `Review these explanations carefully and learn which topics you're making mistakes in. Then, use the QR Question Bank to practice those skills.`,
        `Review these explanations carefully and learn which topics you're making mistakes in. Then, use the QR Question Bank to practice those skills.`,
        `Review these explanations carefully and learn which topics you're making mistakes in. Then, use the QR Question Bank to practice those skills.`,
        `See which topics you're making mistakes in and hone-in to understand those concepts! Then, use the QR Question Bank to practice those skills.`,
        `See which topics you're making mistakes in and hone-in to understand those concepts! Then, use the QR Question Bank to practice those skills.`,
        `Review these explanations carefully and learn from your mistakes. I'm confident you'll bring this score up with continued hard work and practice. Check out QR Academy for content review in math.`,
        `Review these explanations carefully and learn from your mistakes. I'm confident you'll bring this score up with continued hard work and practice. Check out QR Academy for content review in math.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice. Check out QR Academy for content review in math.`,
        `Keep your head up! Review these explanations carefully and learn from your mistakes. I'm confident you'll bring this score up with hard work and practice. Check out QR Academy for content review in math.`,
      ]
    default:
      return [];
  }
}

export function getResults(percentage, testTitle, testSubject) {
  const testNumber = testTitle.match(/#(.*)/)[1];
  const percentageThresholds = getPercentageThresholds(testSubject);
  const commentary = getCommentary(testSubject);
  let score = '13', analysis = '', adjustedTestTitle = testTitle;

  if (!examResults[testTitle] && testNumber > 10) {
    // NOTE: this is a quick workaround for plus test scoring - if the test number is greater
    // than ten we know it's a plus test, and we fall back to the 10th PT examResult entry
    // for that subject
    adjustedTestTitle = `${testSubject} Test #${10}`;
  } else if (!examResults[testTitle]) {
    return {score, analysis};
  }

  percentageThresholds.some((percentageThreshold, index) => {
    if (percentage >= percentageThreshold || index === percentageThresholds.length - 1) {
      score = examResults[adjustedTestTitle][index];
      analysis = commentary[index];
      return true;
    }
    return false;
  });
  return {score, analysis};
}

export function getOATPercentageThresholds(testSubject) {
  switch (testSubject) {
    case "Biology":
      return [
        90,
        87.5,
        82.5,
        80,
        75,
        72.5,
        70,
        68.5,
        62.5,
        57.5,
        55,
        50,
        47.5,
        42.5,
        37.5,
        35,
        32.5,
        30,
        27.5,
        20,
        0
      ]
    case "Organic Chemistry":
      return [
        86.6,
        83.3,
        80,
        76.6,
        73.3,
        66.6,
        63.3,
        53.3,
        46.6,
        43.3,
        36.6,
        33.3,
        30,
        26.6,
        23.3,
        20,
        16.6,
        13.3,
        10,
        0
      ]
    case "General Chemistry":
      return [
        96.6,
        93.3,
        90,
        83.3,
        80,
        76.6,
        70,
        63.3,
        56.6,
        50,
        46.6,
        43.3,
        36.6,
        33.3,
        26.6,
        23.3,
        20,
        16.6,
        13.3,
        0
      ];
    case "Quantitative Reasoning":
      return [
        87.5,
        82.5,
        80,
        75,
        70,
        67.5,
        62.5,
        60,
        57.5,
        52.5,
        47.5,
        45,
        42.5,
        37.5,
        32.5,
        30,
        27.5,
        22.5,
        20,
        0
      ];
    case "Reading Comprehension":
      return [
        97.5,
        92.5,
        87.5,
        82.5,
        77.5,
        72.5,
        67.5,
        62.5,
        57.5,
        52.5,
        47.5,
        42.5,
        37.5,
        32.5,
        27.5,
        22.5,
        17.5,
        12.5,
        10,
        5,
        0
      ];
    case "Physics":
      return [
        96.6,
        93.3,
        90,
        86.6,
        83.3,
        80,
        76.6,
        73.3,
        70,
        63.3,
        56.6,
        50,
        43.3,
        36.6,
        0
      ]
    default:
      return [];
  }
}

export const oatScores = {
  'Quantitative Reasoning': [
    400,
    390,
    380,
    370,
    360,
    350,
    340,
    330,
    320,
    310,
    300,
    290,
    280,
    270,
    260,
    240,
    230,
    220,
    210,
    200
  ],
  'Reading Comprehension': [
    400,
    390,
    380,
    370,
    360,
    350,
    340,
    330,
    320,
    310,
    300,
    290,
    280,
    270,
    260,
    250,
    240,
    230,
    220,
    210,
    200
  ],
  'Biology': [
    400,
    380,
    370,
    360,
    350,
    340,
    330,
    320,
    310,
    300,
    290,
    280,
    270,
    260,
    250,
    240,
    230,
    220,
    210,
    200,
    200
  ],
  'General Chemistry': [
    400,
    390,
    380,
    370,
    360,
    350,
    340,
    330,
    320,
    310,
    300,
    290,
    280,
    270,
    260,
    250,
    240,
    220,
    210,
    200
  ],
  'Organic Chemistry': [
    400,
    390,
    380,
    370,
    360,
    350,
    340,
    330,
    320,
    310,
    300,
    290,
    280,
    270,
    260,
    250,
    230,
    220,
    210,
    200
  ],
  'Physics': [
    400,
    350,
    330,
    330,
    330,
    320,
    320,
    320,
    310,
    300,
    300,
    290,
    270,
    260,
    260
  ]
}

export function getOATResults(percentage, testTitle, testSubject) {
  const percentageThresholds = getOATPercentageThresholds(testSubject);
  let score = 260;
  try {
    percentageThresholds.some((percentageThreshold, index) => {
      if (percentage >= percentageThreshold || index === percentageThresholds.length - 1) {
        score = oatScores[testSubject][index];
        return true;
      }
      return false;
    });
  } catch (e) {
    console.log(e);
  } finally {
    return score;
  }
}

export function getStatistics(questions) {
  const answeredQuestions = questions.filter(({didSkip}) => !didSkip);

  return questions.reduce((acc, {
    didSelectCorrectAnswer,
    didSkip,
    didMark,
    didCheck,
    time,
    masteryLevel
  }) => {
    const timeSpent = parseFloat(acc.timeSpent + parseFloat(time)).toFixed(2);
    const timePerQuestion = timeSpent / answeredQuestions.length;

    return {
      ...acc,
      timeSpent: parseFloat(timeSpent),
      timePerQuestion: isFinite(timePerQuestion) ? timePerQuestion : 0,
      correct: didSelectCorrectAnswer ? acc.correct + 1 : acc.correct,
      marked: didMark ? acc.marked + 1 : acc.marked,
      skipped: didSkip ? acc.skipped + 1 : acc.skipped,
      [masteryLevel]: acc[masteryLevel] ? acc[masteryLevel] + 1 : 1
    };
  }, {
    timeSpent: 0.0,
    timePerQuestion: '', // average
    total: questions.length,
    correct: 0,
    marked: 0,
    skipped: 0,
  });
}

export function aggregateBySubject(progresses) {
  const subjects = progresses
    .map(({questions}) => questions)
    .flat()
    .reduce((acc, {subject, ...question}) => {
      if (!subject) return acc;
      const aggregateSubject = acc[subject] ? {[subject]: [...acc[subject], question]} : {[subject]: [question]};
      return {
        ...acc,
        ...aggregateSubject,
      };
    }, {});

  const totalAnswered = progresses
    .map(({questions}) => questions)
    .flat()
    .filter((question) => !question.didSkip)
    .length;

  return Object
    .keys(subjects)
    .reduce((acc, key) => {
      const statistic = getStatistics(subjects[key]);
      const timePerQuestion = (acc.timeSpent + statistic.timeSpent) / totalAnswered;
      // topicStatistics need to be included. aggregateByTopic requires an array of progress objects
      // so we create a minimal "progress object" consisting of just questions:
      const {statistics: topicStatistics} = aggregateByTopic([{questions: subjects[key]}]);
      return {
        ...acc,
        total: acc.total + subjects[key].length,
        correct: acc.correct + statistic.correct,
        timeSpent: acc.timeSpent + parseFloat(statistic.timeSpent),
        timePerQuestion: isFinite(timePerQuestion) ? timePerQuestion : 0,
        markedQuestions: acc.markedQuestions + statistic.marked,
        skippedQuestions: acc.skippedQuestions + statistic.skipped,
        subjectStatistics: [...acc.subjectStatistics, {name: key, ...statistic, topicStatistics}],
        learning: statistic.learning ? acc.learning + 1 : acc.learning,
        reviewing: statistic.reviewing ? acc.reviewing + 1 : acc.reviewing,
        mastered: statistic.mastered ? acc.mastered + 1 : acc.mastered,
      }
    }, {
      total: 0,
      educator: defaultEducator,
      correct: 0,
      timeSpent: 0.0,
      timePerQuestion: 0,
      markedQuestions: 0,
      skippedQuestions: 0,
      subjectStatistics: [],
      learning: 0,
      reviewing: 0,
      mastered: 0
    });
}

export function aggregateByTopic(progresses) {
  const topics = progresses
    .map(({questions}) => questions)
    .flat()
    .reduce((acc, {topic, ...question}) => {
      if (!topic) return acc;
      const aggregateTopic = acc[topic] ? {[topic]: [...acc[topic], question]} : {[topic]: [question]};
      return {
        ...acc,
        ...aggregateTopic,
      };
    }, {});

  return Object
    .keys(topics)
    .reduce((acc, key) => {
      const statistic = getStatistics(topics[key], key);
      const total = acc.total + topics[key].length;
      const timePerQuestion = (acc.timeSpent + parseFloat(statistic.timeSpent)) / total;
      return {
        ...acc,
        correct: acc.correct + statistic.correct,
        timeSpent: acc.timeSpent + parseFloat(statistic.timeSpent),
        timePerQuestion: isFinite(timePerQuestion) ? timePerQuestion : 0,
        markedQuestions: acc.markedQuestions + statistic.marked,
        skippedQuestions: acc.skippedQuestions + statistic.skipped,
        statistics: [...acc.statistics, {name: key, ...statistic}],
        total,
      }
    }, {
      educator: defaultEducator,
      total: 0,
      correct: 0,
      timeSpent: 0.00,
      timePerQuestion: 0,
      markedQuestions: 0,
      skippedQuestions: 0,
      statistics: [],
    });
}

export function aggregateBySection(progresses) {
  const snsProgresses = progresses.slice(0, 3);
  const patProgresses = progresses.slice(3, 4);
  const rcProgresses = progresses.slice(4, 7);
  const qrProgresses = progresses.slice(7, 8);

  const totalAnswered = progresses
    .map(({questions}) => questions)
    .flat()
    .filter((question) => !question.didSkip)
    .length;

  const sns = aggregateBySubject(snsProgresses);
  const pat = aggregateBySubject(patProgresses);
  const rc = aggregateBySubject(rcProgresses);
  const qr = aggregateBySubject(qrProgresses);

  const totalQuestions = sns.total + pat.total + rc.total + qr.total;
  const totalCorrect = sns.correct + pat.correct + rc.correct + qr.correct;
  const totalTime = parseFloat(sns.timeSpent) + parseFloat(pat.timeSpent) + parseFloat(rc.timeSpent) + parseFloat(qr.timeSpent);
  const totalMarked = sns.markedQuestions + pat.markedQuestions + rc.markedQuestions + qr.markedQuestions;
  const totalSkipped = sns.skippedQuestions + pat.skippedQuestions + rc.skippedQuestions + qr.skippedQuestions;
  const timePerQuestion = totalTime / totalAnswered;

  return {
    educator: defaultEducator,
    correct: totalCorrect,
    total: totalQuestions,
    timeSpent: totalTime,
    timePerQuestion: isFinite(timePerQuestion) ? timePerQuestion : 0,
    markedQuestions: totalMarked,
    skippedQuestions: totalSkipped,
    statistics: [
      {name: 'Survey of Natural Sciences', ...sns},
      {name: 'Perceptual Ability', ...pat},
      {name: 'Reading Comprehension', ...rc},
      {name: 'Quantitative Reasoning', ...qr},
    ],
  };
}

export function aggregateByOATSection(progresses) {
  const snsProgresses = progresses.slice(0, 3);
  const rcProgresses = progresses.slice(3, 6);
  const physProgresses = progresses.slice(6, 7);
  const qrProgresses = progresses.slice(7, 8);

  const totalAnswered = progresses
    .map(({questions}) => questions)
    .flat()
    .filter((question) => !question.didSkip)
    .length;

  const sns = aggregateBySubject(snsProgresses);
  const rc = aggregateBySubject(rcProgresses);
  const phys = aggregateBySubject(physProgresses);
  const qr = aggregateBySubject(qrProgresses);

  const totalQuestions = sns.total + phys.total + rc.total + qr.total;
  const totalCorrect = sns.correct + phys.correct + rc.correct + qr.correct;
  const totalTime = sns.timeSpent + phys.timeSpent + rc.timeSpent + qr.timeSpent;
  const totalMarked = sns.markedQuestions + phys.markedQuestions + rc.markedQuestions + qr.markedQuestions;
  const totalSkipped = sns.skippedQuestions + phys.skippedQuestions + rc.skippedQuestions + qr.skippedQuestions;
  const timePerQuestion = totalTime / totalAnswered;


  return {
    educator: defaultEducator,
    correct: totalCorrect,
    total: totalQuestions,
    timeSpent: totalTime,
    timePerQuestion: isFinite(timePerQuestion) ? timePerQuestion : 0,
    markedQuestions: totalMarked,
    skippedQuestions: totalSkipped,
    statistics: [
      {name: 'Survey of Natural Sciences', ...sns},
      {name: 'Reading Comprehension', ...rc},
      {name: 'Physics', ...phys},
      {name: 'Quantitative Reasoning', ...qr},
    ],
  };
}

export function getAcademicAverage(testTitle, statistics) {
  const subjectStatistics = statistics.reduce((acc, statistic) => [...acc, ...statistic.subjectStatistics], []).filter(({name}) => name !== 'Perceptual Ability');
  const testNumber = testTitle.match(/#(.*)/)[1];
  const totalScore = subjectStatistics.reduce((scoreAcc, statistic) => {
    const {correct, total, name} = statistic;
    const percentage = (correct / total) * 100;
    const parsedTestSubject = name === 'Math' ? 'Quantitative Reasoning' : name;
    const parsedTestTitle = `${parsedTestSubject} Test #${testNumber}`;
    const {score} = getResults(percentage, parsedTestTitle, parsedTestSubject);
    const parsedScore = score.startsWith('<') ? parseInt(score.slice(1)) - 1 : parseInt(score);
    return scoreAcc + parsedScore;
  }, 0)
  const academicAverage = Math.round(totalScore / subjectStatistics.length);
  return academicAverage;
}
export function getOATAcademicAverage(testTitle, statistics) {
  const subjectStatistics = statistics.reduce((acc, statistic) => [...acc, ...statistic.subjectStatistics], []);
  const testNumber = testTitle.match(/#(.*)/)[1];
  const totalScore = subjectStatistics.reduce((scoreAcc, statistic) => {
    const {correct, total, name} = statistic;
    const percentage = (correct / total) * 100;
    const parsedTestSubject = name === 'Math' ? 'Quantitative Reasoning' : name;
    const parsedTestTitle = `${parsedTestSubject} Test #${testNumber}`;
    const score = getOATResults(percentage, parsedTestTitle, parsedTestSubject);
    const parsedScore = Number.isInteger(score) ? score : parseInt(score.slice(1));
    return scoreAcc + parsedScore;
  }, 0)
  const academicAverage = Math.round(totalScore / subjectStatistics.length);
  return academicAverage;
}

export function scoreResult(quizProgress, testTitle, testSubject, testNumber, createdAt) {
  const {
    correct,
    total,
    statistics,
  } = aggregateByTopic(quizProgress);

  const percentage = (correct / total) * 100;
  const {score} = getResults(percentage, testTitle, testSubject);
  return {
    testSubject,
    testNumber,
    score: score.replace('<', ''),
    createdAt,
    topics: statistics.map(({marked, skipped, timePerQuestion, undefined, none, ...statistic}) => statistic)
  }
}