import React, {useState, useEffect, useMemo, createContext, useContext} from 'react';

import {nanoid} from 'nanoid';

import {capitalize} from '@bootcamp/shared/src/util';
import {useMasteryArray} from '@bootcamp/shared/src/util/hooks';


const contentTypesByBootcamp = {
  'med-school': ['Board Style Questions'],
  'anatomy': ['Question Banks'],
  'nclex': ['Board Style Questions', 'Case Studies'],
  inbde: ['Question Banks']
};

const defaultMasteryObject = {learning: 0, reviewing: 0, mastered: 0, bookmarked: 0, untagged: 0, all: 0};

function recursiveToggleState(data, validate) {
  const update = {...data};

  function recurse(obj) {
    if (!obj.children) return {...obj, checked: validate(obj)};

    const {children} = obj;

    const update = Object.keys(children).reduce((acc, key) => {

      const updatedChildren = recurse(children[key]);

      return {...acc, [key]: updatedChildren};
    }, {});

    const someChecked = Object.values(update).some(({checked}) => checked);

    return {
      ...obj,
      checked: someChecked,
      children: update
    }
  }

  return recurse(update);
}

function getStateForContentType(content, defaults) {
  const {subject} = defaults || {};

  switch (content.name) {
    case ('Free Tests'):
      return {
        'free-tests': {
          name: 'Free Questions',
          type: 'subject',
          checked: subject ? subject === 'Free Questions' : false,
          defaultToggled: subject ? subject === 'Free Questions' : false,
          children: content.testIds.reduce((acc, id) => ({
            [id]: {
              id,
              type: 'test',
              name: 'Free Questions',
              parentName: 'Free Questions',
              checked: subject ? subject === 'Free Questions' : false,
              free: true,
            }
          }), {}),
          free: true,
        }
      }
    default:
      return;
  }
}

function formatAdditionalContent(content, defaults) {
  if (!content) return {};

  return content.reduce((acc, curr) => ({...acc, ...getStateForContentType(curr, defaults)}), {});
}

function indexClassroomBySubjectId(classroom, contentTypeFilters, defaults) {
  const {name, tagId, tagIds, contentTypes, free} = classroom;

  const {subject, system} = defaults || {};

    // NOTE can simplify this tagId creation if we can say for certain all classrooms have a subject tag id
    // right now in med-school, they do not
    const id = `${name}-${tagId ? tagId : tagIds?.join('#')}`;

    const children = contentTypes
      ?.filter(({name: contentTypeName}) => contentTypeFilters.includes(contentTypeName))
      ?.reduce((acc2, {content}) => {
        const indexedContent = content?.reduce((acc3, curr) => ({
          ...acc3,
          ...curr?.content?.reduce((acc4, curr2) => {
            const rowId = nanoid();

            return ({
              ...acc4,
              ...(curr2?.lessons
                ? {
                  [rowId]: {
                    type: 'topic',
                    id: rowId,
                    parentId: id,
                    name: curr2.name,
                    children: curr2?.lessons?.reduce((acc5, curr3) => ({ ...acc5, [curr3.testId]: {id: curr3.testId, parentId: rowId, grandparentId: id, free, type: curr3.type, name: capitalize(curr3.type), checked: false} }), {}),
                    checked: false,
                    free,
                  }
                } : {
                  [curr2.id]: {
                    type: 'test',
                    id: curr2.id,
                    parentName: name,
                    name: curr2.name,
                    checked: subject && system ? (subject === name && system === curr2.name) : subject ? subject === name : false,
                    free,
                  }
                }
              )
            });
          }, {})
        }), {})

        return {
          ...acc2,
          ...indexedContent
        }
      }, {});

    // filtering out any subjects which don't have Board Style Questions (no children)
    return Object.keys(children)?.length
      ? {
        [id]: {
          name,
          type: 'subject',
          checked: subject ? subject === name : false,
          defaultToggled: subject ? subject === name : false,
          children,
          free
        }
      }
      : {}

}

function initState(config, contentTypeFilters=["Board Style Questions"], defaults, isUpgraded) {
  const state = config?.classrooms?.reduce((acc, classroom) => {

    if ((classroom.testing && !classroom.name.match('Free')) || (isUpgraded && classroom.name.match('Free'))) return acc;

    const subject = indexClassroomBySubjectId(classroom, contentTypeFilters, defaults);

      return {
      ...acc,
      ...subject,
    }
  }, {});

  const additionalState = isUpgraded ? {} : formatAdditionalContent(config?.additionalContent, defaults);

  return {...additionalState, ...state};

}

function initMedSchoolState(config, contentTypeFilters) {
  return {
    preclinical: initState(config, contentTypeFilters),
    bites: initState(config, [
      'Biochem Bites',
      'Biochemistry Bites',
      'Biostats Bites',
      'Cardio Bites',
      'Derm Bites',
      'Endo Bites',
      'Gastro Bites',
      'GI Bites',
      'Genetics Bites',
      'Heme & Onc Bites',
      'Immuno Bites',
      'Micro Bites',
      'MSK Bites',
      'Nephro Bites',
      'Neuro Bites',
      'Pharm Bites',
      'Psych Bites',
      'PHS Bites',
      'Pulm Bites',
      'Reproduction Bites'
    ]),
    anatomy: {
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Gross Anatomy'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Neuroanatomy'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Histology'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Embryology'), ['Question Banks']),
    }
  };
}
function initAnatomyState(config, contentTypeFilters) {
  return {
    anatomy: {
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Anatomy and Physiology'), ['Exam Questions']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Gross Anatomy'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Neuroanatomy'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Histology'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Embryology'), ['Question Banks']),
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Dental Anatomy and Occlusion'), ['Identify Structures', 'Lecture Style Questions']),
    }
  };
}

function initINBDEState(config, contentTypeFilters, defaults, isUpgraded) {
  const filteredClassrooms = config.classrooms.filter(({name}) => name !== 'Simulation Exam');

  return {
    questionBank: initState({...config, classrooms: filteredClassrooms}, contentTypeFilters, defaults, isUpgraded),
    mockExam: {
      ...indexClassroomBySubjectId(config.classrooms.find(({name}) => name === 'Simulation Exam'), ['Question Banks'], defaults),
    }
  };
}

function formatMasteryData(data) {
  return data?.data?.QuestionMasteryByTestId?.items.reduce((acc, item) => ({
    ...acc,
    [item?.questionMasteryQuestionId]: {
      masteryLevel: item?.masteryLevel,
      bookmarked: item?.bookmarked,
    }
  }), {});
}

function getTestIdsFromStateStructure(state) {
  return Object.values(state)?.reduce((acc, {children}) => [...acc, ...Object.keys(children)?.map((id) => children[id]?.children ? Object.keys(children[id].children).map((nestedId) => nestedId) : id)], []).flat();
}

function getFreeTestIdsFromConfig(bootcamp, config) {
  switch (bootcamp) {
    case 'nclex':
      const freeQbIds = config?.classrooms?.find(({name}) => name.match('Free Questions'))?.contentTypes?.[0]?.content?.[0]?.content?.[0]?.id;
      return [freeQbIds];
    case 'inbde':
      return config?.additionalContent?.[0]?.testIds;
    default:
      return null;
  }
}

function getDefaultTypeForBootcamp(bootcamp, defaults) {
  switch (bootcamp) {
    case 'anatomy':
      return 'anatomy';
    case 'med-school':
      return 'preclinical';
    case 'inbde':
      if (defaults?.subject === 'Simulation Exam') return 'mockExam';
      return 'questionBank'
    default:
      return null;
  }
}

const TestState = createContext();

export const questionLimitByBootcamp = {
  'med-school': 40,
  'nclex': 85,
  'inbde': 100,
  'anatomy': 40,
};

const initStateForBootcamp = (config, bootcamp, defaults, isUpgraded) => {
  const contentTypeFilters = contentTypesByBootcamp[bootcamp];

  switch (bootcamp) {
    case 'med-school':
      return initMedSchoolState(config, contentTypeFilters);
    case 'anatomy':
      return initAnatomyState(config, contentTypeFilters);
    case 'inbde':
      return initINBDEState(config, contentTypeFilters, defaults, isUpgraded);
    default:
      return initState(config, contentTypeFilters, defaults, isUpgraded);
  }
}

const useTestState = (config, questionCounts, bootcamp, defaults, isUpgraded, userId, parentLoading) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [type, setType] = useState(getDefaultTypeForBootcamp(bootcamp, defaults));

  // format config into a render friendly object
  const [state, setState] = useState(initStateForBootcamp(config, bootcamp, defaults, isUpgraded));

  const setData = type ? newState => setState(state => ({...state, [type]: newState})) : setState;
  const data = type ? state[type] : state;

  const testIds = useMemo(() => getTestIdsFromStateStructure(data), [data]);

  // load mastery
  const masteryByType = useMasteryArray(testIds, 'testSmall', formatMasteryData, type, null, userId, parentLoading);
  const mastery = type ? masteryByType?.[type] : masteryByType;

  const loadingMastery = !mastery || !Object.keys(mastery).length;

  // toggle states
  const [mode, setMode] = useState({tutorMode: true, timed: false});
  const [masteryFilter, setMasteryFilter] = useState('untagged');
  const [testCount, setTestCount] = useState(!isUpgraded ? 6 : questionLimitByBootcamp[bootcamp] || 40);
  const [selectAll, setSelectAll] = useState(true);

  const onTypechange = (newType) => {
    setType(newType);
    setLoading(true);
  };

  const initSubjectMasteryFilter = (dataObject, defaultSubject) => {
    try {
      const subjectKey = Object.keys(dataObject).find(key => key.split('-')[0] === defaultSubject);
      const subjectTestIds = Object.keys(dataObject[subjectKey]?.children || {});

      const masteryTotals = subjectTestIds.reduce((acc, testId) => {
        const {learning, reviewing, mastered, untagged, bookmarked, all} = Object
          ?.keys(questionCounts?.[testId] || {})
          ?.reduce((acc2, questionId) => {
            const masteryLevel = mastery?.[testId]?.[questionId]?.masteryLevel || 'untagged';
            const bookmarked = mastery?.[testId]?.[questionId]?.bookmarked ? 1 : 0;

            const update = {...acc2};

            update[masteryLevel] += 1;
            update.bookmarked += bookmarked;
            update.all += 1;

            return update;

          }, defaultMasteryObject);

        return {
          learning: acc.learning + learning,
          reviewing: acc.reviewing + reviewing,
          mastered: acc.mastered + mastered,
          untagged: acc.untagged + untagged,
          bookmarked: acc.bookmarked + bookmarked,
          all: acc.all + all,
        };
      }, defaultMasteryObject);

      return ['untagged', 'learning', 'reviewing', 'mastered', 'all'].find(key => masteryTotals[key] > 0);

    } catch (error) {
      console.log('error determining default mastery level', error);
      return 'all';
    }
  };

  const testCounts = useMemo(() => {
    return testIds?.reduce((acc, testId) => ({
      ...acc,
      [testId]: Object
        ?.keys(questionCounts?.[testId] || {})
        ?.filter(questionId => {
          const masteryLevel = mastery?.[testId]?.[questionId]?.masteryLevel;
          const bookmarked = mastery?.[testId]?.[questionId]?.bookmarked;
          // if masteryFilter is untagged, filter for questions with no mastery entry - otherwise match to masteryFilter
          return masteryFilter === 'all'
            ? true
            : masteryFilter === 'untagged'
            ? (!['learning', 'reviewing', 'mastered'].includes(masteryLevel))
            : masteryFilter === 'bookmarked'
            ? bookmarked
            : masteryLevel === masteryFilter
        })?.length
    }), {});
  }, [mastery, masteryFilter, questionCounts, type]);

  const subjectCounts = useMemo(() => {
    return Object.keys(data).reduce((acc, subjectId) => ({
      ...acc,
      [subjectId]: Object.keys(data[subjectId]?.children)?.reduce((acc, testId) => {
        const nestedChildren = data[subjectId]?.children[testId]?.children;

        return nestedChildren
          ? acc + Object.keys(nestedChildren).reduce((acc, testId) => acc + testCounts[testId], 0)
          : acc + testCounts[testId]
      }, 0)
    }), {})
  }, [testCounts]);

  const topicCounts = useMemo(() => {
    return Object.keys(data).reduce((acc, subjectId) => ({
      ...acc,
      ...Object.keys(data[subjectId]?.children)?.reduce((acc, topicId) => {
        const nestedChildren = data[subjectId]?.children[topicId]?.children;

        if (!nestedChildren) return acc;

        return {
          ...acc,
          [topicId]: Object.keys(nestedChildren).reduce((acc, testId) => (acc + testCounts[testId]), 0)
        }

      }, {})
    }), {})
  }, [testCounts]);

  const totals = useMemo(() => {
    const freeTestIds = getFreeTestIdsFromConfig(bootcamp, config);

    return testIds?.reduce((acc, testId) => {

      if (!isUpgraded && freeTestIds && !freeTestIds.includes(testId)) return acc;

      const {learning, reviewing, mastered, untagged, bookmarked, all} = Object
          ?.keys(questionCounts?.[testId] || {})
          ?.reduce((acc2, questionId) => {
            const masteryLevel = mastery?.[testId]?.[questionId]?.masteryLevel || 'untagged';
            const bookmarked = mastery?.[testId]?.[questionId]?.bookmarked ? 1 : 0;

            const update = {...acc2};

            update[masteryLevel] += 1;
            update.bookmarked += bookmarked;
            update.all += 1;

            return update;

          }, defaultMasteryObject);

      return {
        learning: acc.learning + learning,
        reviewing: acc.reviewing + reviewing,
        mastered: acc.mastered + mastered,
        untagged: acc.untagged + untagged,
        bookmarked: acc.bookmarked + bookmarked,
        all: acc.all + all,
      };
    }, defaultMasteryObject);
  }, [mastery, questionCounts]);

  // main loading state use effect
  // syncs checked states with question counts / mastery once it's all successfully loaded
  // also inits default mastery filter

  // we might be able to rewrite this without using a use effect, that would be ideal!
  useEffect(() => {
    if (!loading || loadingMastery || !questionCounts || !data || submitting) {
      return;
    }

    const {subject, system, masteryLevel} = defaults || {};
    const validate = ({id, free, name, parentName}) => {

      const matchesDefaults = subject && system ? (subject === parentName && system === name) : subject ? subject === parentName : false;

      return (((subject || system) ? matchesDefaults : true) && !!testCounts[id] && (isUpgraded || free)) ? true : false;
    }

    const update = Object.keys(data).reduce((acc, key) => ({
      ...acc,
      [key]: recursiveToggleState(data[key], validate)
    }), {});

    const subjectFilter = subject && initSubjectMasteryFilter(update, subject);

    const masteryFilter = masteryLevel
      ? masteryLevel
      : subjectFilter
      ? subjectFilter
      : totals?.untagged
      ? 'untagged'
      : totals?.learning
      ? 'learning'
      : totals?.reviewing
      ? 'reviewing'
      : totals?.mastered
      ? 'mastered'
      : totals?.bookmarked
      ? 'bookmarked'
      : 'all';

    setMasteryFilter(masteryFilter);
    setData(update);

    // // set loading false on next tick so that useEffects in child components
    // // use latest 'data' state value
    setTimeout(() => setLoading(false), 0);

  }, [loadingMastery, questionCounts, defaults, type, totals, loading, submitting, testCounts]);


  return {
    methods: {
      recursiveToggleState,
      setMasteryFilter,
      setTestCount,
      setData,
      setMode,
      setType: onTypechange,
      setLoading,
      setSelectAll,
      setSubmitting,
    },
    variables: {
      state,
      data,
      type,
      testIds,
      testCount,
      totals,
      masteryFilter,
      questionCounts,
      testCounts,
      subjectCounts,
      topicCounts,
      mastery,
      loading: loading || submitting,
      submitting,
      maxQuestions: questionLimitByBootcamp[bootcamp],
      selectAll,
      mode,
    },
  };
}

const useCreateTestState = () => useContext(TestState);

export {useCreateTestState, useTestState, TestState};
