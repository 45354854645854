import React from 'react';
import styled from 'styled-components';
import useDocumentTitle from '@rehooks/document-title';

import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {useUserDataContext} from '../../../contexts/UserData';

import Container from '../PageContainer';
import Overview from '../../components/Cards/Overview';
import Breadcrumbs from '../../../components/NavBar/components/Breadcrumbs';
import Download from '../../components/Cards/Download';

import {DownloadButton} from '../../components/Cards/components/Buttons';
import theme from '@bootcamp/shared/src/styles/theme';
const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${({theme}) => theme.layouts.spacing.s};
`;
const StyledOverview = styled(Overview)`
  background: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  margin-bottom: 16px;
`;

const CheatSheets = ({history}) => {
  const {config} = useBootcampConfigContext();
  const {isUpgraded, bootcamp} = useUserDataContext();
  useDocumentTitle(`${config.meta.siteTitle} | Cheat Sheets`);

  const items = config?.classrooms?.reduce((acc, classroom) => {
    return [
      ...acc,
      ...(classroom?.contentTypes?.find(({name}) => name === 'Cheat Sheets')?.content?.reduce((acc, {content}) => [...acc, ...(content || [])], [])|| [])
    ]
  }, []);
  const gatesUp = !isUpgraded && bootcamp === 'inbde';
  return (
    <Container>
      <StyledBreadcrumbs history={history} lockAtDepth={2} />
      <StyledOverview
        subTitle={'Cheat Sheets'}
        body={'Bootcamp\'s high-yield INBDE cheat sheets are designed to save you time and help you pass.'}
        title={'Classroom'}
        showProgress={false}
      />
      {(gatesUp ? items.sort((a, b) => a.freeAccess ? -1 : 1) : items).map((item, index) => (
        <Download
          key={`${item.name}-${index}`}
          name={item.name}
          description={item.description}
          themePalette={theme.colors.brandPalette.royal}
          locked={gatesUp && !item.freeAccess}
          onClick={() => window.open(item.route, '_blank')}
          actions={[
            <DownloadButton />
          ]}
        />
      ))}
    </Container>
  );
}

export default CheatSheets;
