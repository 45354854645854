import React, {useCallback} from 'react';

import useDocumentTitle from '@rehooks/document-title';
import FAQs from '../../components/WebflowFAQs';
import {H1, H2, H3, Body3} from '../../../components/Typography';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';
import {Column} from '../../../components/Grid';
import Chapter, {Wrapper as ChapterWrapper, Right} from '@bootcamp/web/src/bootcamps/components/Cards/Chapter'
import Container from '../../pages/PageContainer';

import {useContentTypeProgress} from '@bootcamp/web/src/bootcamps/api/progress/contentType'
import styled from 'styled-components';
import {UpgradeCard, FreeQuestionsCard} from '../shared';
import CreateTestButton from '@bootcamp/web/src/bootcamps/pages/CreateTest/components/CreateTestButton';

import PlayImg from '@bootcamp/shared/src/assets/icons/nclex/Play.png'
import ReadinessImg from '@bootcamp/shared/src/assets/icons/nclex/Readiness.png'
import DocumentImg from '@bootcamp/shared/src/assets/icons/nclex/document.png'
import FirstAidImg from '@bootcamp/shared/src/assets/icons/nclex/FirstAid.png'
import StethoscopeImg from '@bootcamp/shared/src/assets/icons/nclex/Stethoscope.png'
import {People} from '@styled-icons/fluentui-system-filled/People'
import {CalendarLtr} from '@styled-icons/fluentui-system-filled/CalendarLtr';

import {useMasteryArray} from '@bootcamp/web/src/hooks';
import {getTestsByClassroom} from '@bootcamp/shared/src/util';
import {useAsync} from '@bootcamp/web/src/hooks';
import {formatClassroomMastery} from '@bootcamp/shared/src/util/formatting';
import {getClassroomQuestionCounts} from '@bootcamp/shared/src/requests';
import {useUserDataContext} from '../../../contexts/UserData';
import moment from 'moment';
import {AddCircle} from '@styled-icons/fluentui-system-filled/AddCircle';
import GettingStarted from '../../components/GettingStarted';
import CreateTest from '../../pages/CreateTest';

import {items} from '@bootcamp/shared/src/config/nclexCheatSheets';

import {useModalContext} from '../../../contexts/Modal';


const Date = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: ${({theme}) => theme.overlays.opacity.light._400};
`;
const TitleBar = styled(Column)`
  jusitfy-content: center;
  margin-bottom: 16px;

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const Wrapper = styled.div`
  margin-bottom: ${({theme}) => theme.layouts.spacing.l};
  display: flex;
  flex-direction: column;
`;

const IntroContainer = styled.div`
  width: 100%;
  background: linear-gradient(180deg, rgba(21, 26, 30, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 2px 8px -6px rgba(21, 26, 30, 0.1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;
`;
const IntroHeader = styled(H3)`
  color: white;
  margin-bottom: 8px;
  font-size: 20px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;
const IntroBody = styled(Body3)`
  color: rgba(255,255,255,.9);
  max-width: 750px;
  margin: 0 auto 16px;
`;
const StyledCreateTestButton = styled(CreateTestButton)`
  margin: 0 auto;
  transform: scale(1);
  box-shadow: ${({theme}) => theme.elevation.shadow.medium};
  transition: all 100ms ease;
  &:hover {
    transform: scale(1.02);
  }
`;
const Intro = () => {
  const {cognitoUser} = useUserDataContext();

  return (
    <IntroContainer>
      <IntroHeader>
        Let's get started{cognitoUser && cognitoUser?.getUsername && `, ${cognitoUser?.getUsername()}`}
      </IntroHeader>
      <IntroBody>
        You have a <strong>free membership</strong>. The best way to get started is to <strong>create a test</strong>. When you're ready, upgrade your membership to get instant access to all content and features.
      </IntroBody>
      <StyledCreateTestButton active dark />
    </IntroContainer>
  )
}
const StyledAddCircle = styled(AddCircle)`
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
  width: 24px;
  height: 24px;
`;
const Title = styled(H2)`
  color: ${({theme}) => theme.colors.neutralsPalette.white};
  margin-bottom: ${({theme}) => theme.layouts.spacing.m};

  ${({theme}) => theme.mediaQueries.mobileL} {
    margin-bottom: ${({theme}) => theme.layouts.spacing.m};
  }
`;
const StyledCalendar = styled(CalendarLtr)`
  fill: ${({theme}) => theme.colors.brandPalette.teal.default};
`;

const StyledPeople = styled(People)`
  fill: ${({theme}) => theme.colors.brandPalette.magenta.default};
`;
const StyledChapterCard = styled(Chapter)`
${({theme}) => theme.mediaQueries.mobileXl} {
  ${ChapterWrapper} {
    flex-direction: row;
  }
  ${Right} {
    margin-top: 0;
    width: auto;
  }
`;

  const VideosCard = () => {
    const {isUpgraded} = useUserDataContext();
    const {config} = useBootcampConfigContext();

    const {contentTypes: freeVideos} = config.classrooms.find(({name}) => name === 'Next Gen NCLEX Strategy Course');
    const freeVideosConfig = freeVideos?.[0];
    const freeVideosData = useContentTypeProgress(freeVideosConfig, 'all')['videos-all'];

    const collectedVideosData = (Object.values(freeVideosData?.videoLessonData || {}) || []).reduce((acc, {quizProgress, test}) => {
      return {
        complete: acc.complete + quizProgress.reduce((acc, {status}) => acc + (!!status ? 1 : 0), 0),
        total: acc.total + test?.blockConnections?.items?.reduce((acc, {testBlock}) => acc + (testBlock?.type === 'lesson' ? 1 : 0), 0)
      }
    }, {complete: 0, total: 0});

    return (
      <StyledChapterCard
        route={`next-gen-strategy-course`}
        name={`Next Gen Strategy Course`}
        icon={<img src={PlayImg} style={{margin: 'auto', width: '32px', height: '32px'}} />}
        showVideoProgress
        fullWidthProgress
        videoLessonCount={collectedVideosData?.total}
        progress={freeVideosData ? {mastered: collectedVideosData?.complete} : 'loading'}
      />
    )
  }
  const CasesCard = () => {
    const {isUpgraded} = useUserDataContext();
    const {config} = useBootcampConfigContext();

    const {contentTypes: caseQuestions} = config.classrooms.find(({name}) => name === 'Case Studies');
    const caseQuestionsConfig = caseQuestions?.[0];
    const caseQuestionsData = useContentTypeProgress(caseQuestionsConfig, 'all')['qbanks-all'];

    return (
      <StyledChapterCard
        route={`next-gen-cases`}
        name={`Case Studies`}
        icon={<img src={FirstAidImg} style={{margin: 'auto', width: '32px', height: '32px'}} />}
        questionCount={caseQuestionsData?.counts?.all}
        fullWidthProgress
        progress={caseQuestionsData ? caseQuestionsData?.progress?.all : 'loading'}
      />
    )
  }
  const ReadinessExamCard = () => {
    const {isUpgraded} = useUserDataContext();
    const {config} = useBootcampConfigContext();

    const {contentTypes: caseQuestions} = config.classrooms.find(({name}) => name === 'Readiness Exams');
    const caseQuestionsConfig = caseQuestions?.[0];
    const caseQuestionsData = useContentTypeProgress(caseQuestionsConfig, 'all')['qbanks-all'];

    return (
      <StyledChapterCard
        route={`next-gen-readiness-exams`}
        name={`Readiness Exams`}
        icon={<img src={ReadinessImg} style={{margin: 'auto', width: '32px', height: '32px'}} />}
        questionCount={caseQuestionsData?.counts?.all}
        fullWidthProgress
        progress={caseQuestionsData ? caseQuestionsData?.progress?.all : 'loading'}
      />
    )
  }
  const CheatSheetCard = () => {

    return (
      <StyledChapterCard
        route={`cheat-sheets`}
        name={`Cheat Sheets`}
        icon={<img src={DocumentImg} style={{margin: 'auto', width: '32px', height: '32px'}} />}
        showProgress={false}
        details={`${items.length} cheat sheets to review`}
        // questionCount={caseQuestionsData?.counts?.all}
        // fullWidthProgress
        // progress={caseQuestionsData ? caseQuestionsData?.progress?.all : 'loading'}
      />
    )
  }
  const StandaloneQuestionCard = () => {
    const {isUpgraded} = useUserDataContext();
    const {config} = useBootcampConfigContext();

    const filteredClassrooms = config?.classrooms?.filter(({testing, route}) => !testing && !route.match('free-questions') && !route.match('next-gen-cases') && !route.match('next-gen-strategy'));

    const testsByClassroom = getTestsByClassroom(filteredClassrooms, [], [], false, ['Board Style Questions', 'Case Studies']);
    const getQuestionCounts = useCallback(() => getClassroomQuestionCounts('nclex', testsByClassroom), [])
    const {value: questionCounts} = useAsync(getQuestionCounts);
    const standaloneQuestionCounts = Object.keys(questionCounts?.classroomQuestionCounts || {}).filter(key => !['free-questions', 'next-gen-cases', 'next-gen-strategy'].includes(key)).reduce((acc, key) => acc + questionCounts?.classroomQuestionCounts?.[key], 0);
    const allTests = Object.values(testsByClassroom).reduce((acc, tests) => [...acc, ...tests], []);
    const filteredProgresses = useMasteryArray(allTests, 'test', data => formatClassroomMastery(data, 'test'));
    const condensedProgresses = Object.values(filteredProgresses).reduce((acc, progressObj) => ({...acc, learning: progressObj.learning + acc.learning, reviewing: progressObj.reviewing + acc.reviewing, mastered: progressObj.mastered + acc.mastered}),{learning: 0, reviewing: 0, mastered: 0})

    return (
      <StyledChapterCard
        route={`next-gen-standalone`}
        name={`Standalone Questions`}
        icon={<img src={StethoscopeImg} style={{margin: 'auto', width: '32px', height: '32px'}} />}
        questionCount={standaloneQuestionCounts}
        fullWidthProgress
        progress={Object.values(filteredProgresses).length > 0 ? condensedProgresses : 'loading'}
      />
    )
  }

const Home = () => {
  const {bootcamp, isUpgraded} = useUserDataContext();
  const {config} = useBootcampConfigContext();
  const {modalDispatch} = useModalContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Home`);

  const {contentTypes: freeQuestions} = config.classrooms.find(({name}) => name === 'Free Questions');
  const freeQuestionsConfig = freeQuestions?.[0];
  const {counts} = useContentTypeProgress(freeQuestionsConfig, 'all')?.['qbanks-all'] || {};

  const todayActions = [
    {
      interactionKey: `Streak-${bootcamp}-Create-Test`,
      icon: {
        component: () => <StyledAddCircle/>,
        text: 'Create Test'
      },
      text: isUpgraded ? 'Study up to 85 questions at a time' : `Upgrade for unlimited access`,
      containerProps: {
        onClick: () => modalDispatch({
          type: 'open',
          component: CreateTest,
          componentProps:  !!isUpgraded ? {} : {defaults: {subject: 'Free Questions'}},
          enableClickClose: true,
        }),
      },
    },
    {
      icon: {
        component: StyledCalendar,
        text: 'Question of the Day', themeColor: 'royal'
      },
      text: `Try out today's question`,
      containerProps: {
        as: 'a',
        href: `https://bootcamp.com/${bootcamp}/question-of-the-day`,
        target: '_blank'
      }
    },
    {
      icon: {
        component: StyledPeople,
        text: 'Study Group', themeColor: 'royal'
      },
      text: `Join 10k fellow students`,
      containerProps: {
        as: 'a',
        href: 'https://www.facebook.com/groups/2169829559872032',
        target: '_blank'
      }
    },
  ];
  return (
    <Container>
      <GettingStarted
        actions={todayActions}
      />
      {/* <TitleBar>
        <Date>{moment().format('dddd, MMM D')}</Date>
        <Title>
          Start Studying
        </Title>
      </TitleBar>
      {!isUpgraded && <Intro />} */}
      <Title>NCLEX Resources</Title>
      <Wrapper>
        <CasesCard />
        <StandaloneQuestionCard />
        <ReadinessExamCard />
        <CheatSheetCard />
        <VideosCard />
      </Wrapper>
      <FAQs heading={'FAQs'} />
    </Container>
  );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;
