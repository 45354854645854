import CreateTest from '@bootcamp/web/src/bootcamps/pages/CreateTest';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';
import {AddSquare} from '@styled-icons/fluentui-system-filled/AddSquare';
import {RoundButton} from '@bootcamp/web/src/components/UI';
import {useUserDataContext} from '../../../../contexts/UserData';

const CreateTestButton = ({className, disableModal, ...props}) => {
  const {isUpgraded} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  const openCreateTest = () => {
    if (disableModal) return;

    modalDispatch({
      type: 'open',
      component: CreateTest,
      componentProps:  !!isUpgraded ? {} : {defaults: {subject: 'Free Questions'}},
      enableClickClose: true,
    });
  }

  return (
    <RoundButton
      className={className}
      onClick={openCreateTest}
      children={"Create Test"}
      iconPosition="left"
      IconComponent={<AddSquare color={props.dark ? "rgb(21, 26, 30)" : "white"} size="20" />}
      small
      {...props}
    />
  )
}
export default CreateTestButton;