export const onCreateTestProgress = `subscription OnCreateTestProgress {
  onCreateTestProgress {
    id
  }
}
`;
export const onUpdateBlockProgress = `subscription OnUpdateBlockProgress {
  onUpdateBlockProgress {
    id
  }
}
`;
export const onUpdateQuestionProgress = `subscription OnUpdateQuestionProgress {
  onUpdateQuestionProgress {
    id
  }
}
`;
