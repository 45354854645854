import {useEffect, useState} from 'react';

import {useUserDataContext} from '../../../../contexts/UserData';
import {getTest, getAllQuestionMastery} from '@bootcamp/shared/src/requests';
import {getInObj} from '@bootcamp/shared/src/util';

import {formatChapterTestMastery, formatQuestionCount} from '../formatting';
import {getVideoLessonData} from './VideoLesson';
import {getQbankCollectionData} from './QBankCollection';

export async function getCounts(configArray) {
  const getCount = async ({name, id}) => {
    const result = await getTest(id, 'getTestConfig');
    const questionCount = getInObj(['data', 'getTest', 'config', 'questionCount'], result, 0);

    return {name, questionCount, id};
  };

  const counts = await Promise.all(
    configArray.map(getCount)
  );

  return formatQuestionCount(counts);
}

export async function getProgress(configArray, userId) {
  const getResult = async ({id, oldIds}) => {
    let result = await getAllQuestionMastery({userIdHashTestId: `${userId}#${id}`}, 'testSmall');
    if (oldIds && oldIds.length > 0) {
      const oldResults = await Promise.all(oldIds.map(oldId => getAllQuestionMastery({userIdHashTestId: `${userId}#${oldId}`}, 'testSmall')));
      result.data.QuestionMasteryByTestId.items = oldResults.reduce((acc, curr) => [...acc, ...curr.data.QuestionMasteryByTestId.items], result.data.QuestionMasteryByTestId.items);
    }
    return {...result, testId: id};
  };

  const results = await Promise.all(configArray.map(getResult));

  return formatChapterTestMastery(results);
}

async function getTestData(config, userId) {
  const testConfig = config.content.find(({name}) => name === 'Tests').content;

  const counts = await getCounts(testConfig);
  const progress = await getProgress(testConfig, userId);

  return {counts, progress};
}

async function getQbData(config, userId, setData, routeKey) {
  const counts = await getCounts(config.content.reduce((acc, {content}) => {
    const filteredContent = content?.filter(({id}) => !!id);

    return [...acc, ...filteredContent];
  }, []));

  const progress = await getProgress(config.content.reduce((acc, {content}) => {
    const filteredContent = content?.filter(({id}) => !!id);

    return [...acc, ...filteredContent];
  }, []), userId);

  // hijacking the default setData call in getQbankCollectionData
  // so that we set data at the right position in the current route state
  const setNestedQbankCollectionData = (dataFn) => {
    setData(data => {
      const result = dataFn({[routeKey]: data?.[routeKey]?.collectionData});
      return ({...data, [routeKey]: {...data[routeKey], collectionData: result?.[routeKey]}})
    });
  };

  const collectionData = await getQbankCollectionData(config, userId, setNestedQbankCollectionData);

  return {counts, progress, collectionData};
}

async function getPracticalsData(config, userId) {
  const counts = await getCounts(config.content.reduce((acc, {content}) => [...acc, ...content], []));

  return {counts};
}

const getProgressByContentTypeMap = {
  'qbanks': getQbData,
  'board-banks': getQbData,
  'identify-structures': getQbankCollectionData,
  'qbank-collection': getQbankCollectionData,
  'lectures': getQbData,
  'lecture-style-questions': getQbankCollectionData,
  'bites': getQbData,
  'bio-bites': getQbData,
  'plus-packs': getQbData,
  'plus-tests': getTestData,
  'rbites': getQbData,
  'practice-tests': getTestData,
  'videos': getVideoLessonData,
  'practicals': getPracticalsData,
  'anki': () => true,
  'performance': () => true
};

export function useContentTypeProgress(config, selectedSection) {
  const [data, setData] = useState({});
  const {DEFAULT_USER_ID, loading, searchUserInteractions, saveUserInteraction} = useUserDataContext();
  const {route} = config;
  const routeKey = `${route}-${selectedSection}`;
  const extendedConfig = {...config, route: routeKey};

  useEffect(() => {
    if (loading || data[routeKey] || !getProgressByContentTypeMap[route] || !selectedSection) return;

    async function getProgress () {
      const request = getProgressByContentTypeMap[route];
      const result = await request(extendedConfig, DEFAULT_USER_ID, setData, routeKey, searchUserInteractions, saveUserInteraction);
      setData(data => ({...data, [routeKey]: result}));
    }

    getProgress();
  }, [route, loading, routeKey]);

  return data;
}
