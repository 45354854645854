import React from 'react';
import useDocumentTitle from '@rehooks/document-title';

import MasteryBank from '../../../components/MasteryBank';
import QuestionBank from '../../../components/QBank';

import {useUserDataContext} from '../../../contexts/UserData';
import {useBootcampConfigContext} from '../../../contexts/BootcampConfig';

import {colors} from '@bootcamp/shared/src/styles/theme';


const MasteryReview = ({match, location, ...props}) => {
  const {config} = useBootcampConfigContext();
  const {cognitoUser, userModel} = useUserDataContext();

  useDocumentTitle(` ${config.meta.siteTitle} | Tagged Questions`);

  const username = cognitoUser?.attributes?.name || cognitoUser?.attributes?.email || userModel?.id;

  const masteryLevel = match.params.masteryLevel;
  const urlParams = new URLSearchParams(window.location.search);
  const urlTopicId = urlParams.get('topicId');
  const masteryBankType = urlTopicId ? 'topicReview' : '';

  const {tagId, tagIds, themePalette} = config.classrooms.find(({route}) => route === match.params.classroom) || {};

  const collectedIds = urlTopicId
    ? [urlTopicId]
    : tagId
    ? [tagId]
    : tagIds ? tagIds
    : config.classrooms.filter(({route}) => !['reading-comprehension'].includes(route)).reduce((acc, {tagId, tagIds}) => [...acc, ...(tagIds ? tagIds : [tagId])], []);

  return (
    <MasteryBank match={match} location={location} masteryLevel={masteryLevel} tagIds={collectedIds} type={masteryBankType}>
      {(totalQuestions) => (
        <QuestionBank
          match={match}
          title={'Mastery Review'}
          startBlockTitle={masteryLevel}
          withNavSidebar={false}
          themePalette={themePalette || colors.brandPalette.royal}
          username={username}
          showScore={false}
          totalQuestions={totalQuestions}
          enableBookmarking
        />
      )}
    </MasteryBank>
  );
};

MasteryReview.defaultProps = {};
MasteryReview.propTypes = {};

export default MasteryReview;
