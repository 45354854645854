import React from 'react';
import styled, {css} from 'styled-components';

import {useTestNavigatorContext} from '@bootcamp/web/src/contexts/TestNavigator';
import MasteryIcon from '@bootcamp/web/src/bootcamps/components/MasteryIcon';

import {ReactComponent as BlackMarkFlagSVG} from './assets/BlackMarkFlag.svg';
import {XIcon, CheckIcon} from './shared';

import {updateQuestionProgressData} from '../../helpers';
import {useTimerContext} from '../../contexts/TestTimer';

const Container = styled.div`
  height: 100%;
  width: 110px;
  display: ${({showingSidebar}) => showingSidebar ? 'block' : 'none'};
  border-right: solid 1px #000;
  background: white;
  overflow: auto;
  ${({theme}) => theme.mediaQueries.laptop} {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 10;
  }
`;

const BlackMarkFlagIcon = styled(BlackMarkFlagSVG)`
  width: 12px;
  height: 12px;
`;
const Section = styled.div`
  font-size: 14px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  ${({isSequentialSet, isSequentialStart, isSequentialEnd}) => {
    const style = {borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: 'none'};
    if (isSequentialSet) {
      style.borderLeft = '1px solid black';
      style.borderRight = '1px solid black';
      if (isSequentialStart) {
        style.borderTop = '1px solid black';
      }
      if (isSequentialEnd) {
        style.borderBottom = '1px solid black';
      }
    }
    return style;
  }}
  padding: 4px 0px;
`;

const SidebarElementContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:nth-child(even) {
    background-color: #EDEDF0;
  }

  ${({current}) => current && css`
    background: #5590cc !important;
    color: white;
  `}
`;

const StyledMasteryIcon = styled(MasteryIcon)`
  width: 16px;
  height: 16px;
`;

const SidebarElement = ({questionProgress, overallIndex, index, onClick, isSequentialSet, isSequentialStart, isSequentialEnd}) => {
  const {selectedAnswerIndex, bookmarked, didSelectCorrectAnswer, masteryLevel, didCheck, current} = questionProgress;
  const hasValidMasteryLevel = ['learning', 'reviewing', 'mastered'].includes(masteryLevel);
  const {variables: {customTestConfig}} = useTestNavigatorContext();
  return (
    <SidebarElementContainer onClick={onClick} current={current}>
      <Section>{selectedAnswerIndex == -1 ? '•' : !didCheck && !customTestConfig?.submitted ? '' : didSelectCorrectAnswer ? <CheckIcon size={14} /> : <XIcon size={14} />}</Section>
      <Section masteryLevel={didCheck ? masteryLevel : false} isSequentialSet={isSequentialSet} isSequentialStart={isSequentialStart} isSequentialEnd={isSequentialEnd}>{overallIndex + 1}</Section>
      <Section>
        {bookmarked && <BlackMarkFlagIcon/>}
      </Section>
      <Section>
        {hasValidMasteryLevel && <StyledMasteryIcon level={masteryLevel} squared />}
      </Section>
    </SidebarElementContainer>
  )
}
// TODO get make sure that qp saves properly on sidebar navigations
const Sidebar = ({sidebarState}) => {
  const [showingSidebar, setShowingSidebar] = sidebarState;
  const {questionStartTime} = useTimerContext();

  const {
    methods: {
      setCurrentIndex,
      updateQuizProgress,
    },
    variables: {
      customTestConfig,
      quizProgress,
      blockIndex: currentBlockIndex,
      type,
      template
    }
  } = useTestNavigatorContext();

  const {questions: questionProgresses} = quizProgress[currentBlockIndex];
  const questionIndex = questionProgresses.findIndex(({current}) => current);

  function handleClick(e, blockIndex, index) {
    e.stopPropagation();

    if ((customTestConfig?.config && !customTestConfig?.submitted && !questionProgresses?.[questionIndex]?.didCheck) || (type === 'readinessExam' && template !== 'testReview')) {
      updateQuestionProgressData(updateQuizProgress, questionStartTime, questionProgresses, questionIndex);
    }

    setCurrentIndex(blockIndex, index)
  }

  const sidebarItems = quizProgress.reduce((acc, blockProgress, blockIndex) => {
    const onlyShowCurrentBlock = (type === 'readinessExam' && template !== 'testReview') && blockIndex != currentBlockIndex;
    if (!blockProgress.questions || onlyShowCurrentBlock) return acc;
    const nextBlockSequential = quizProgress[blockIndex + 1]?.isSequentialSet;
    return [
      ...acc,
      ...blockProgress.questions.map((questionProgress, index) => (
        <SidebarElement
          isSequentialSet={blockProgress.isSequentialSet || questionProgress?.isSequentialSet}
          isSequentialStart={index === 0 || questionProgress?.isSequentialStart}
          isSequentialEnd={(((blockProgress.questions.length - 1) === index && !nextBlockSequential) || (questionProgress?.isSequentialEnd && !blockProgress.questions[index + 1]?.isSequentialStart))}
          questionProgress={questionProgress}
          overallIndex={acc.length + index}
          index={index}
          onClick={event => handleClick(event, blockIndex, index)}
        />
      ))
    ]
  }, []);

  return (
    <Container showingSidebar={showingSidebar}>
      {sidebarItems}
    </Container>
  )
}

export default Sidebar;
