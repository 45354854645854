import React from 'react';

import ari from '@bootcamp/shared/src/assets/educators/ari.gif';
import hannah from '@bootcamp/shared/src/assets/images/hannah.png';

import {
  OverviewText,
  InstructorDetails,
  InstructorDetailsImage,
  InstructorDetailsText,
  InstructorDetailsTextHeading,
  StyledBody2,
  GuidesContainer,
  GuideWell,
  GuideDownloadButton,
  StyledDownloadIcon,
  Testimonial,
  TestimonialRow,
  TestimonialImage,
  TestimonialAuthor,
  TestimonialName,
  TestimonialByline,
} from './shared';

const studyGuide = (
  <>
    <OverviewText>Ari’s Study Guide for OAT Domination</OverviewText>
    <InstructorDetails>
      <InstructorDetailsImage src={ari} />
      <InstructorDetailsText>
        <InstructorDetailsTextHeading>
          The Step-by-Step Guide on How to Ace the {(new Date()).getFullYear()} OAT
        </InstructorDetailsTextHeading>
        <StyledBody2>
          I'm Ari, creator of OAT Bootcamp, and in this guide I will outline a study plan that has worked for me and many other students.
        </StyledBody2>
      </InstructorDetailsText>
    </InstructorDetails>
    <GuidesContainer>
      <GuideWell>
        <StyledBody2>
          The <strong>Study Guide</strong> breaks down all of the OAT-specific tips and test-taking strategies to get you up to speed. I recommend <strong>reading this first</strong>.
        </StyledBody2>
        <GuideDownloadButton color="blue" onClick={() => window.open('https://docs.google.com/document/d/1ebIvj21aXOipfYCCuMyWaWmzD4HDLY2CjutTXz84BEY/edit?usp=sharing', '_blank')}>
          <StyledDownloadIcon /> Get Ari's Study Guide
        </GuideDownloadButton>
      </GuideWell>
      <GuideWell>
        <StyledBody2>
          The <strong>Study Schedule</strong> is an updated day-by-day manual that covers exactly what materials to study and when to study them to best prepare you for the OAT.
        </StyledBody2>
        <GuideDownloadButton color="blue" onClick={() => window.open('https://drive.google.com/file/d/11Dm4qy6GlKoq5z97uXejomn1VmE8-s5t/view', '_blank')}>
          <StyledDownloadIcon /> Get Ari's Study Schedule
        </GuideDownloadButton>
      </GuideWell>
    </GuidesContainer>
    <Testimonial>
      <StyledBody2 style={{textAlign: 'center'}}>
        “This resource honestly saved my life. Once I started with Bootcamp, everything changed.”
      </StyledBody2>
      <TestimonialRow>
        <TestimonialImage src={hannah}/>
        <TestimonialAuthor>
          <TestimonialName>Hannah Grimes - <strong>360 AA</strong> | 380 BIO | 350 GC | 400 OC</TestimonialName>
          <TestimonialByline>Happy OAT Bootcamp Student</TestimonialByline>
        </TestimonialAuthor>
      </TestimonialRow>
    </Testimonial>
    </>
  );

export default studyGuide;
