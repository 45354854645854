import React, {useCallback, useRef} from 'react';

import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import {useFormContext} from 'react-hook-form';
import {IntercomAPI} from 'react-intercom';

import {H3} from '../../../../../components/Typography';
import {LoadingSpinner} from '../../../../../components/Branding';
import {Button, TextButton} from '../../../../../components/Branding/Buttons';
import VerifyEmailModal from '../../../../../components/Modal/components/VerifyEmailModal';
import ChangePasswordModal from '../../../../../components/Modal/components/ChangePasswordModal';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import FieldRenderer from '../../../../../components/Form/FieldRenderer';
import {Container, TitleBar, Content} from './shared';
import {useUserDataContext} from '../../../../../contexts/UserData';
import {useModalContext} from '../../../../../contexts/Modal';
import {DefaultProfilePicture} from '../../../../../components/NavBar/components/Buttons';

import {useAsync} from '../../../../../hooks';
import styled from 'styled-components';
import {getUnixTimestamp} from '@bootcamp/shared/src/util';



const ChangePasswordLink = styled(TextButton)`
  justify-content: flex-start;
  padding: ${({theme}) => theme.layouts.spacing.m} 0 ${({theme}) => theme.layouts.spacing.s} 0;
`;

const Fields = styled.div`
  width: 100%;
  margin: 0 auto;
`;


const FieldSubmitButton = styled(Button).attrs({ type: 'primary', size: 'small' })`
  margin: -12px 0 16px;
`;

const SubmitButton = () => {
  const {formState} = useFormContext();

  if (!formState.isValid || !formState.touched.length) {
    return null;
  }

  if (formState.isSubmitting) {
    return (
      <FieldSubmitButton disabled>Saving...</FieldSubmitButton>
    )
  }

  if (formState.isSubmitted) {
    return (
      <FieldSubmitButton disabled>Saved!</FieldSubmitButton>
    )
  }

  return (
    <FieldSubmitButton type="submit">Save</FieldSubmitButton>
  )
}

const updateCognitoUserAttributes = async values => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, values);
    await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (e) {
    console.log(e);
  }
}

const nameFieldConfig = {
  type: 'Text',
  label: 'Name',
  name: 'name',
  inputProps: {
    placeholder: 'What should we call you?'
  }
};

const FirstNameForm = ({name, onSubmit}) => {
  return (
    <FormWrapper
      defaultValues={{name}}
      onSubmit={onSubmit}
    >
      <Fields children={<FieldRenderer config={[nameFieldConfig]} />} />
      <SubmitButton />
    </FormWrapper>
  )
}

const VerifyLink = styled.div`
  color: ${({theme}) => theme.colors.brandPalette.indigo.default};
`;

const EmailForm = () => {
  const {cognitoUser, refreshSession} = useUserDataContext();
  const {modalDispatch} = useModalContext();
  const openVerifyEmailModal = () => modalDispatch({type: 'open', component: VerifyEmailModal, modalContainerStyle: theme => `
    background: ${theme.overlays.opacity.dark._300};
    padding: 10vh 10vw;
    align-items: flex-start;
    ${theme.mediaQueries.tablet} {
      background: ${theme.overlays.opacity.dark._400};
      padding: 0;
    }
  `});

  const verifiedEmail = cognitoUser && cognitoUser.attributes && cognitoUser.attributes.email_verified;
  const email = cognitoUser && cognitoUser.attributes && cognitoUser.attributes.email;

  const emailFieldConfig = {
    type: 'Text',
    label: 'Contact Email',
    name: 'email',
      inputProps: {
      type: 'email'
    },
    validation: () => ({
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Email must be valid'
      }
    }),
    additionalInfo: verifiedEmail
      ? 'Verified'
      : <VerifyLink onClick={openVerifyEmailModal}>
        Verify Email
      </VerifyLink>
  };

  return (
    <FormWrapper
      defaultValues={{email}}
      onSubmit={async values => {
        await updateCognitoUserAttributes(values);
        refreshSession();
        openVerifyEmailModal();
      }}
    >
      <Fields children={<FieldRenderer config={[emailFieldConfig]} />} />
      <SubmitButton />
    </FormWrapper>
  )
}

const usernameFieldConfig = {
  type: 'Text',
  label: 'Username',
  name: 'username',
  inputProps: {
    disabled: true
  }
};

const UsernameForm = () => {
  const {cognitoUser} = useUserDataContext();
  return (
    <FormWrapper defaultValues={{username: cognitoUser.username}}>
      <Fields children={<FieldRenderer config={[usernameFieldConfig]} />} />
      <SubmitButton />
    </FormWrapper>
  )
}

const ProfilePictureContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 81px;
  min-width: 81px;
  height: 81px;
  background: rgba(255,255,255,.1);
  transition: all 100ms ease 0s;
  margin-right: 12px;
  border-radius: 40px;
  cursor: pointer;

  &:hover {
    background: rgba(255,255,255,.2);
  }

  ${({theme, loading}) => theme.animation.loadingBackground({loading})};
`;

const StyledDefaultProfilePicture = styled(DefaultProfilePicture)`
  width: auto;
`;

const ProfilePictureImage = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  padding: 3px;
`;

const ProfilePicturePrompt = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-left: 8px;
  color: ${({theme}) => theme.colors.brandPalette.royal.default};
`;

export const ProfilePicture = ({className, size=32, imgComponent=null, showPrompt=true}) => {
  const inputRef = useRef();
  const {refreshSession, cognitoUser, loading} = useUserDataContext();
  const USER_ID = cognitoUser?.attributes?.sub;

  const ProfileImage = imgComponent || ProfilePictureImage;

  const uploadProfilePicture = useCallback(async e => {
    try {
      const file = inputRef.current.files[0];
      const maxSize = 2 * 1024 * 1024;
      if (!file.type.match('image/')) {
        return alert('Please upload an image file.');
      }
      if (file.size > maxSize) {
        return alert('File too large, please try another!');
      }
      const s3Key = `users/${USER_ID}/profile/profilePicture`;
      await Storage.put(s3Key, file, {contentType: file.type});
      refreshSession();
    } catch (e) {
      console.log(e);
    }
  }, [USER_ID]);

  const {pending: uploading, execute: executeUpload} = useAsync(uploadProfilePicture, false);

  const personalProfilePictureUrl = `https://testtube6dca237224ff44ca973cd2a6dfb779e3-tbc.s3.us-east-1.amazonaws.com/public/users/${USER_ID}/profile/profilePicture`;

  const fetchProfilePicture = useCallback(async () => {
    if (uploading) return;

    const personalProfilePicture = await fetch(personalProfilePictureUrl, {method: 'HEAD', cache: 'no-cache'});
    if (personalProfilePicture.status !== 200) throw new Error();
    return personalProfilePictureUrl;
  }, [uploading, loading, USER_ID]);

  const {value: profPicUrl, error: fetchError, pending: fetching} = useAsync(fetchProfilePicture, !loading);

  // if fetching, or profPicUrl null (useAsync default value = null), or uploading... and there _is_ a prof pic uploaded, show loader
  const imageLoading = (loading || fetching || !profPicUrl || uploading) && !fetchError;

  const hiddenInput = (
    <input
      type="file"
      onChange={executeUpload}
      ref={inputRef}
      style={{display: 'none'}}
      accept={"image/*"}
    />
  )

  return (
    <ProfilePictureContainer className={className} onClick={() => inputRef.current.click()} loading={imageLoading}>
      {!imageLoading && <StyledDefaultProfilePicture size={size} children={fetchError ? null : <ProfileImage src={profPicUrl} />} />}
      {!imageLoading && showPrompt && <ProfilePicturePrompt>Upload a profile picture</ProfilePicturePrompt>}
      {hiddenInput}
    </ProfilePictureContainer>
  )
}

const StyledProfilePicture = styled(ProfilePicture)`
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 16px;
  border-radius: 8px;
`;



const TestDateForm = () => {
  const {saveUserInteraction, searchUserInteractions, bootcamp} = useUserDataContext();
  const testDateKey = ['nclex'].includes(bootcamp) ? `${bootcamp}_testDate` : 'testDate';
  const currentTestDate = searchUserInteractions(testDateKey);
  const testDateForm = {
    type: 'DatePicker',
    label: bootcamp === 'nclex' ? 'When are you planning on taking the NCLEX?' : 'Test Date',
    name: 'testDate',
    validation: () => true,
  };
  return (
    <FormWrapper
      defaultValues={{testDate: currentTestDate}}
      onSubmit={async ({testDate}) => {
        await saveUserInteraction(testDateKey, JSON.stringify(testDate));
        await IntercomAPI('update', {[`${bootcamp}_test_date`]: getUnixTimestamp(testDate)});
        // todo make this not need to reload
        window.location.reload();
      }}
    >
      <Fields children={<FieldRenderer config={[testDateForm]} />} />
      <SubmitButton />
    </FormWrapper>
  )
}

const PersonalDetails = ({className}) => {
  const {bootcamp, cognitoUser, refreshSession, loading} = useUserDataContext();
  const {modalDispatch} = useModalContext();

  const showTestDateInput = ['dat', 'oat', 'inbde', 'med-school', 'nclex']?.includes(bootcamp);

  return (
    <Container className={className}>
      <TitleBar>
        <H3>
          Personal Details
        </H3>
      </TitleBar>
      {!loading ? (
        <Content>
          <StyledProfilePicture />
          <FirstNameForm
            name={cognitoUser && cognitoUser.attributes && cognitoUser.attributes.name}
            onSubmit={async values => {
              await updateCognitoUserAttributes(values);
              refreshSession();
            }}
          />
          <EmailForm />
          <UsernameForm />
          {showTestDateInput && <TestDateForm/>}
          <ChangePasswordLink color={'indigo'} onClick={() => modalDispatch({
            type: 'open',
            component: ChangePasswordModal,
            enableClickClose: true,
            modalContainerStyle: theme => `
              background: ${theme.overlays.opacity.dark._300};
              padding: 10vh 10vw;
              align-items: flex-start;
              ${theme.mediaQueries.tablet} {
                background: ${theme.overlays.opacity.dark._400};
                padding: 0;
              }
            `})}
            children={'Change Password'} />
        </Content>
      ) : <LoadingSpinner />}
    </Container>
  )
}

export default PersonalDetails;
