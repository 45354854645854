import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

import {useTestNavigatorContext, TestNavigatorProvider} from '@bootcamp/web/src/contexts/TestNavigator';
import {useReloadBlockerContext} from '@bootcamp/web/src/contexts/ReloadBlocker';
import {getSectionBounds, getSectionWindow} from '@bootcamp/web/src/contexts/TestNavigator/transitions';
import debounce from 'lodash.debounce';

// DISPLAY
// extra
import {Column} from '@bootcamp/web/src/components/Grid';
import {FullPageSpinner, FixedBar} from '@bootcamp/web/src/components/Branding';
import {IconButton} from '@bootcamp/web/src/components/Branding/Buttons';
import {CloseOutline as X} from '@styled-icons/evaicons-outline/CloseOutline';
import Exhibit from '@bootcamp/web/src/components/Exhibit';
import QuestionSet from '@bootcamp/web/src/components/TestBlocks/QuestionSet';
import {Tooltip} from '@bootcamp/web/src/components/Tooltip';

import {SubmitAnatomyCase as SubmitModal} from '@bootcamp/web/src/components/Modal';

// intra
import StartBlock from './components/StartBlock';
import EndBlock from './components/EndBlock';
import FullLengthTutorial from './components/FullLengthTutorial';
import PATTutorial from './components/PATTutorial';
import SNSTutorial from './components/SNSTutorial';
import QRTutorial from './components/QRTutorial';
import PhysicsTutorial from './components/PhysicsTutorial';
import RCPassage from './components/RCPassage';
import SectionBreak from './components/SectionBreak';
import QuestionView from './components/QuestionView';

import {SectionTimeUp, TimeUp, Submit, ProceedToSection, SubmitAnatomyCase, TestSettings} from '@bootcamp/web/src/components/Modal';
import INBDEQuestion from '@bootcamp/web/src/components/QBank/Question';
import {TestTimer, TimerProvider, useTimerContext} from '@bootcamp/web/src/contexts/TestTimer';
import {useUserDataContext} from '@bootcamp/web/src/contexts/UserData';
import {useModalContext} from '@bootcamp/web/src/contexts/Modal';

import useOfflineIndicator from '@bootcamp/web/src/components/OfflineIndicator';
import {changeIntercomDisplay, delay} from '@bootcamp/shared/src/util';
import {Settings} from '@styled-icons/fluentui-system-filled/Settings';
import {trackQuizProgressPerformance} from '@bootcamp/shared/src/requests';

import {saveFile} from '@bootcamp/shared/src/util';
import {
  getSectionTitle,
  getOATSectionTitle,
  getSectionTimeLimit,
  handleCloseClick,
  barHeight,
} from './helpers';

import {
  NextButton,
  ReviewAllButton,
  ReviewMarkedButton,
  ReviewIncompleteButton,
  EndButton,
  SuspendButton
} from './components/shared';

import {updateCustomTestConfig} from '@bootcamp/shared/src/util';
import moment from 'moment';
import Sidebar from './components/Sidebar';
import theme from '@bootcamp/shared/src/styles/theme';

// STYLE
const ToggleSettingsButton = styled(IconButton)`
  height: 36px;
  width: 36px;
  padding: 8px;
  margin-left: 16px;
  background: rgba(255, 255, 255, 0.1);

  z-index: 4;
  svg {
    fill: white;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 30px;
    width: 30px;
  }
  box-shadow: none;
`;

const ToggleSettingsButtonIcon = styled(Settings)`
  fill: white;
  ${({theme}) => theme.mediaQueries.mobileL} {
    height: 20px;
    width: 20px;
    padding: 6px;
  }
`;

const Container = styled(Column)`
  background: white;
`;

const ContentArea = styled(Column)`
  overflow-y: scroll;
  margin-top: ${({size}) => barHeight*(size || 2)}px;
`;

const CloseButton = styled(IconButton)`
  height: 42px;
  width: 42px;
  padding: 8px;
  margin-right: 16px;
  background: rgba(255, 255, 255, 0.1);
  ${({theme}) => theme.mediaQueries.tablet} {
    height: 30px;
    width: 30px;
    padding: 6px;
  }
`;

const CloseIcon = styled(X).attrs(props => ({color: 'white'}))`
  height: 20px;
  width: 20px;
`;

const TopBar = styled(FixedBar).attrs(props => ({position: 'top', size: props.size || 2, barHeight}))`
  font-family: Arial;
`;

const ControlsContainer = styled.div`
  min-height: ${barHeight}px;
  background-color: #0F6292;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0,0,0);
`;
const ControlsPadding = styled.div`
  width: 100%;
  max-width: 1920px;
  padding: 0px 32px;
  height: 100%;
  display: flex;
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 0 12px;
    overflow: auto;
  }
`;
const ControlSection = styled.div`
  color: white;
  width: calc(100%/3);
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({justify}) => justify};
  visibility: ${({invisible}) => invisible ? 'hidden' : 'visible'};
  ${({theme}) => theme.mediaQueries.tablet} {
    width: 100%;
  }
`;
const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Controls = () => {
  const {
    variables: {
      leftButtons,
      centerButtons,
      rightButtons,
      loading,
      quizProgress,
      blockIndex,
    }
  } = useTestNavigatorContext();

  const {questions: blockQuestionProgress} = {questions: [], ...quizProgress[blockIndex]};
  const questionIndex = blockQuestionProgress.findIndex(({current}) => current);
  const currentQuestionProgress = blockQuestionProgress?.[questionIndex] || {};

  const didCheck = currentQuestionProgress?.didCheck;

  return (
    <ControlsContainer>
      <ControlsPadding>
        {!loading && didCheck &&
          <Tooltip dismissalInteractionKey="dismissedMasteryTagTutorial" position="above"/>
        }
        <ControlSection justify={'flex-start'} children={leftButtons.filter(button => button).map(button => <ControlWrapper style={{marginRight: '16px'}} children={button.component} />)} />
        <ControlSection justify={'center'} children={centerButtons.filter(button => button).map(button => <ControlWrapper children={button.component} />)} />
        <ControlSection justify={'flex-end'} children={rightButtons.filter(button => button).map(button => <ControlWrapper style={{marginLeft: '16px'}} children={button.component} />)} />
      </ControlsPadding>
    </ControlsContainer>
  );
}

const INBDEQuestionWrapper = styled.div`
  padding: 75px 0px 24px 0px;
  ${({theme}) => theme.mediaQueries.tablet} {
    padding: 24px;
  }
`;

const WrappedINBDEQuestion = props => (
  <INBDEQuestionWrapper key={props.key}>
    <INBDEQuestion {...props} />
  </INBDEQuestionWrapper>
)

const HideMobile = styled.div`
  ${({inline}) => inline && `display: inline;`};
  ${({theme}) => theme.mediaQueries.tablet} {
   display: none;
  }
`

const PrometricBank = () => {
  const [showingExhibit, setShowingExhibit] = useState(false);
  const [exhibitType, setExhibitType] = useState();
  const {DEFAULT_USER_ID, searchUserInteractions, bootcamp} = useUserDataContext();
  const prometricDelay = searchUserInteractions('prometricDelay') === true;
  const timeAccommodation = searchUserInteractions('timeAccommodation') === true;

  const hideTimer = searchUserInteractions('hideTimer') === true;
  const {toggleTimer, updateTimeLimit, timerActive, timeUp, timeLeft} = useTimerContext();
  const history = useHistory();

  const {toggleReloadBlocker} = useReloadBlockerContext();
  const [topLeftText, setTopLeftText] = useState();
  const [topRightText, setTopRightText] = useState()
  const [dismissedPATTutorial, setDismissedPATTutorial] = useState([]);
  const [dismissedSNSTutorial, setDismissedSNSTutorial] = useState([]);
  const [dismissedRCPassage, setDismissedRCPassage] = useState([]);
  const [dismissedQRTutorial, setDismissedQRTutorial] = useState(false);
  const [dismissedPhysicsTutorial, setDismissedPhysicsTutorial] = useState(false);
  useOfflineIndicator();

  const {
    methods: {
      setLeftButtons,
      setCenterButtons,
      setRightButtons,
      setNavigationFilter,
      setCurrentIndex,
      saveProgress,
      transitionBlock,
      autoTagOnSubmit,
      handleGoBack,
      instantiateTestProgress,
      setLoading
    },
    variables: {
      test,
      testBlockConnections,
      startBlockIndex,
      endBlockIndex,
      blockIndex,
      quizProgress,
      loading,
      testProgressId,
      template,
      customTestConfig,
      type
    }
  } = useTestNavigatorContext();

  const isFullLengthTest = template === 'fullLengthTest';

  const IS_OAT_TEST = test && test.config.title?.includes('OAT');
  const sectionTitle = !isFullLengthTest ? '' : IS_OAT_TEST ? getOATSectionTitle(blockIndex) : getSectionTitle(blockIndex);
  const sectionBounds = getSectionBounds(testBlockConnections, blockIndex);
  const sectionWindow = getSectionWindow(testBlockConnections, blockIndex);
  const currentBlockType = testBlockConnections[blockIndex] && testBlockConnections[blockIndex].testBlock.type;

  const quizProgressSection = isFullLengthTest
    ? quizProgress.slice(...sectionWindow)
    : quizProgress
  const quizProgressSectionFlat = quizProgressSection.map(block => block.questions || []).flat()

  const questionCount = quizProgressSectionFlat.length
  const questionCountThusFar = quizProgressSectionFlat.findIndex(({current}) => current) + 1;

  const {modalDispatch} = useModalContext();

  const testBlockConnection = testBlockConnections[blockIndex];
  const {testBlock} = testBlockConnection || {testBlock: {}};
  const sidebarState = useState(false);

  const isReadinessExam = type === 'readinessExam';

  // HOOKS:
  const updateSidebarState = debounce(event => {
    const setSidebarState = sidebarState[1];
    // setSidebarState(!(window.innerWidth < 768));
  }, 150);

  useEffect(() => {
    window.addEventListener('resize', updateSidebarState);
    return () => window.removeEventListener('resize', updateSidebarState);
  }, []);

  useEffect(() => {
    if (isReadinessExam && testProgressId && blockIndex === 0) {
      setLoading(false);
      transitionBlock('next');
      toggleTimer();
    }
  }, [testProgressId]);

  useEffect(() => {
    // updating time limit for section breaks
    if (sectionTitle === 'Section Break') {
      const sectionBreakTime = timeAccommodation ? 45 : 30;
      updateTimeLimit(sectionBreakTime);
      toggleTimer();
    }
    // stop timer on section start blocks
    if (testBlock.type === 'sectionStart') {
      const timeLimit = getSectionTimeLimit(sectionTitle, timeAccommodation);
      timerActive && toggleTimer();
      updateTimeLimit(timeLimit);
      toggleTimer();
      return transitionBlock('next');
    }
  }, [blockIndex]);

  const exitCustomTest = async () => {
    try {
      // update custom test config to indicate suspension
      if ((customTestConfig?.config && !customTestConfig.submitted) || (isReadinessExam && template === 'tbcSavedBank')) {
        if (!customTestConfig?.config?.tutorMode) {
          await trackQuizProgressPerformance(DEFAULT_USER_ID, quizProgress, `${DEFAULT_USER_ID}#${bootcamp}_Performance`);
        }
        await autoTagOnSubmit();
      }
      await updateCustomTestConfig(customTestConfig, {
        suspended: false,
        timeLeft: null,
        submitted: true,
      });
    } catch (error) {
      console.log('error marking test as submitted', error);
    }

    toggleReloadBlocker(false);
    if (isReadinessExam) {
      return setTimeout(() => history.replace(`/inbde/simulation-exam/review?id=${test.id}&testRoute=${window.location.pathname.slice(1)}`), 100);
    }
    setTimeout(() => history.push(`/inbde/previous-tests/review?key=${encodeURIComponent(customTestConfig?.config?.testStorageKey)}`), 100);
  }

  useEffect(() => {
    if (!timeUp) return;
    if (template === 'customTest' || isReadinessExam) {
      return modalDispatch({
        type: 'open',
        modalContainerStyle: theme => `
          background: transparent;
        `,
        component: ({close}) => {
          return (
            <SubmitModal
              headerText={'The test timer has expired'}
              bodyText={'You’ll be able to review your exam results on the next page.'}
              confirmConfig={{
                text: 'End and Show Results',
                onConfirm: exitCustomTest
              }}
              cancelConfig={{}}
              prometricStyling
              preventEscape
            />
          )
        },
        enableClickClose: false,
      })
    }
    const isFinalSection = !isFullLengthTest || sectionTitle === 'Quantitative Reasoning';
    const modalComponent = isFinalSection ? TimeUp : SectionTimeUp;
    setNavigationFilter({});
    const componentProps = blockIndex === 0
      ? {onConfirm: () => {
        transitionBlock('next');
        const timeLimit = timeAccommodation ? 135 : 90;
        updateTimeLimit(timeLimit);
        toggleTimer();
      }}
      : !isFinalSection
        ? {onConfirm: () => transitionBlock('jumpToNextSection')}
        : {onConfirm: async () => {
            toggleReloadBlocker(false);
            const progressId = await saveProgress();
            await autoTagOnSubmit();
            const testRoute = window.location.pathname;
            window.location.href = `/${bootcamp}/test-review?id=${test.id}&testProgressId=${progressId}&submitting=true&testRoute=${testRoute}`;
          },
          onError: async (error) => {
            const currentDate = new Date().toISOString();
            const submissionJSON = {
              currentDate,
              error,
              quizProgress,
              testProgressId,
            }
            saveFile(JSON.stringify(submissionJSON, null, 2), `${bootcamp} Bootcamp Manual Test Submission File - ${currentDate}.json`);
          }};

    modalDispatch({
      type: 'open',
      component: modalComponent,
      componentProps: {
        prometricStyling: true,
        testId: test.id,
        ...componentProps,
      }
    });
  }, [timeUp]);

  const handleSuspendClick = async () => {
    const { headerText, bodyText, confirmText, cancelText } = customTestConfig?.submitted || template === 'testReview'
      ? {
        headerText: 'Would you like to end your review?',
        bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
        confirmText: 'End Review',
        cancelText: 'Return to Review'
      } : {
        headerText: 'Pause Test',
        bodyText: 'Do you want to pause this test? Once paused you can resume this test from the previous tests page.',
        confirmText: 'Pause Test',
        cancelText: 'Return to Test'
      }
    modalDispatch({
      type: 'open',
      modalContainerStyle: theme => `
        background: transparent;
      `,
      component: ({close}) => {
        return (
          <SubmitModal
            headerText={headerText}
            bodyText={bodyText}
            prometricStyling
            cancelConfig={{
              text: cancelText,
              onCancel: close,
            }}
            confirmConfig={{
              text: confirmText,
              onConfirm: async () => {
                // update custom test config to indicate suspension
                await updateCustomTestConfig(customTestConfig, {
                  suspended: true,
                  timeLeft: timeLeft?.length <= 5 ? `00:${timeLeft}` : timeLeft,
                  questionIndex: 0,
                });
                toggleReloadBlocker(false);
                if (isReadinessExam) {
                  return setTimeout(() => history.push(`/inbde/simulation-exam/review?id=${test.id}&testRoute=${window.location.pathname.slice(1)}`), 100);
                }
                setTimeout(() => history.push(`/inbde/previous-tests`), 100);
              }
            }}
          />
        )
      },
      enableClickClose: true,
    })
  }
  const handleSubmitClick = async () => {
    if (template === 'customTest' || isReadinessExam) {
      const {headerText, bodyText, confirmText, cancelText} = customTestConfig?.submitted || template === 'testReview'
        ? {
          headerText: 'Would you like to end your review?',
          bodyText: 'You’ll be able to resume your review whenever you’re ready. Your tagged questions are automatically saved.',
          confirmText: 'End Review',
          cancelText: 'Return to Review'
        } : {
          headerText: 'End Test',
          bodyText: 'Do you want to end this test? Once ended you can review your test results from the previous tests page.',
          confirmText: 'End Test',
          cancelText: 'Return to Test'
        }
      return modalDispatch({
        type: 'open',
        modalContainerStyle: theme => `
          background: transparent;
        `,
        component: ({close}) => {
          return (
            <SubmitModal
              headerText={headerText}
              bodyText={bodyText}
              prometricStyling
              cancelConfig={{
                text: cancelText,
                onCancel: close,
              }}
              confirmConfig={{
                text: confirmText,
                onConfirm: exitCustomTest
              }}
            />
          )
        },
        enableClickClose: true,
      })
    }
    const isFinalSection = !isFullLengthTest || sectionTitle === 'Quantitative Reasoning';
    const modalComponent = isFinalSection ? Submit : ProceedToSection;
    const componentProps = isFinalSection
      ? {
        prometricStyling: true,
        onConfirm: async () => {
          toggleReloadBlocker(false);
          const progressId = await saveProgress();
          await autoTagOnSubmit();
          const testRoute = window.location.pathname;
          window.location.href = `/${bootcamp}/test-review?id=${test.id}&testProgressId=${progressId}&testRoute=${testRoute}`;
        },
        onError: async (error) => {
          const currentDate = new Date().toISOString();
          const submissionJSON = {
            currentDate,
            error,
            timeUp,
            quizProgress,
            testProgressId,
            userId: DEFAULT_USER_ID,
            testId: test.id,
          }
          saveFile(JSON.stringify(submissionJSON, null, 2), `${bootcamp} Bootcamp Manual Test Submission File - ${currentDate}.json`);
        }
      }
      : {
        prometricStyling: true,
        onConfirm: () => {
          transitionBlock('next')
      }};

    modalDispatch({
      type: 'open',
      component: modalComponent,
      componentProps
    });
  };

  const blockRenderer = (testBlockConnection) => {
    const {testBlock} = testBlockConnection || {testBlock: {}};
    changeIntercomDisplay('none');
    switch (testBlock.type) {
      case 'startReview':
        return transitionBlock('review');
      case 'startFullLength':
        return (
          <FullLengthTutorial
            setTopLeftText={setTopLeftText}
            setTopRightText={setTopRightText}
            setExhibitType={setExhibitType}
            setShowingExhibit={setShowingExhibit}
          />
        )
      case 'startBlock':
        return (
          <StartBlock />
        );
      case 'passage':
      case 'questionSet':
        const isPATSection = (isFullLengthTest && !IS_OAT_TEST && blockIndex === 6) || (!isFullLengthTest && blockIndex === 1 && test?.config?.title?.includes("Perceptual"))
        if (isPATSection) {
          const instructionIndexes = [0, 15, 30, 45, 60, 75];
          const instructionIndex = instructionIndexes.findIndex(index => index === questionCountThusFar - 1);

          if (instructionIndex !== -1 && !dismissedPATTutorial.includes(instructionIndex)) {
            return (
              <PATTutorial instructionIndex={instructionIndex} setDismissedPATTutorial={async value => {
                prometricDelay && await delay(1667);
                setDismissedPATTutorial(value)
              }} />
            )
          }
        }

        const isSNSSection = isFullLengthTest && [1,2,3].includes(blockIndex);
        if (isSNSSection) {
          const instructionIndexes = [0, 40, 70];
          const instructionIndex = instructionIndexes.findIndex(index => index === questionCountThusFar - 1);

          if (instructionIndex !== -1 && !dismissedSNSTutorial.includes(instructionIndex)) {
            return (
              <SNSTutorial instructionIndex={instructionIndex} setDismissedSNSTutorial={async value => {
                prometricDelay && await delay(1667);
                setDismissedSNSTutorial(value)
              }} />
            )
          }
        }

        const isQRSection = isFullLengthTest && [15].includes(blockIndex);
        if (isQRSection && !dismissedQRTutorial) {
          return (
            <QRTutorial setDismissedQRTutorial={async value => {
              prometricDelay && await delay(1667);
              setDismissedQRTutorial(value)
            }} />
          )
        }

        const isPhysicsSection = isFullLengthTest && IS_OAT_TEST && [12].includes(blockIndex);
        if (isPhysicsSection && !dismissedPhysicsTutorial) {
          return (
            <PhysicsTutorial setDismissedPhysicsTutorial={async value => {
              prometricDelay && await delay(1667);
              setDismissedPhysicsTutorial(value)
            }} />
          )
        }

        if (testBlock.type === 'passage' && !dismissedRCPassage.includes(blockIndex)) {
          return (
            <RCPassage
              setDismissedRCPassage={async value => {
                prometricDelay && await delay(1667);
                setDismissedRCPassage(value)
              }}
            />
          )
        }
        const QuestionComponent = bootcamp === 'inbde' ? WrappedINBDEQuestion : QuestionView;
        return (
          <QuestionSet
            key={testBlock.id}
            setExhibitType={setExhibitType}
            setShowingExhibit={setShowingExhibit}
            scrollToTop={() => document.querySelector(`.${ContentArea.styledComponentId}`).scroll(0,0)}
            renderQuestion={({testBlock, questionIndex, ...viewProps}) => (
              <QuestionComponent sectionTitle={sectionTitle} key={`${testBlock.id}-${questionIndex}`} {...viewProps}/>
            )}
          />
        );
      case 'sectionBreak':
        return <SectionBreak/>
      case 'sectionReview':
        return (
          <EndBlock sectionWindow={sectionWindow} goToQuestion={async (blockIndex, questionIndex) => {
            prometricDelay && await delay(1667);
            setCurrentIndex(blockIndex, questionIndex);
          }}/>
        );
      case 'endBlock':
      case 'endReview':
        return (
          <EndBlock
            goToQuestion={async (blockIndex, questionIndex) => {
              prometricDelay && await delay(1667);
              setCurrentIndex(blockIndex, questionIndex);
            }}
          />
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    return () => changeIntercomDisplay('block');
  }, []);

  useEffect(() => {
    setShowingExhibit(false);
    const {testBlock} = testBlockConnections[blockIndex] || {testBlock: {type: 'startFullLength'}};
    const questionProgressesSection = isFullLengthTest
      ? quizProgress.slice(...sectionBounds)
      : quizProgress

    const questionProgresses = questionProgressesSection
      .filter(block => !!block.questions)
      .map(block => block.questions)
      .flat();
    switch (testBlock.type) {
      case 'sectionReview':
        setNavigationFilter({});
        setLeftButtons([
          {
            component: <ReviewMarkedButton onClick={async () => {
              if (questionProgresses.findIndex(({didMark}) => didMark) === -1) return;
              prometricDelay && await delay(1667);
              setNavigationFilter({key: 'didMark', value: true});
              transitionBlock('reviewMarked');
            }} />,
          },
          {
            component: <ReviewAllButton onClick={async () => {
              prometricDelay && await delay(1667);
              transitionBlock('reviewAll')
            }} />,
          },
          {
            component: <ReviewIncompleteButton onClick={async () => {
              if (questionProgresses.findIndex(({didSkip}) => didSkip) === -1) return;
              prometricDelay && await delay(1667);
              setNavigationFilter({key: 'didSkip', value: true});
              transitionBlock('reviewSkipped');
            }}/>,
          }
        ]);
        setCenterButtons([]);
        setRightButtons([
          {
            component: <EndButton onClick={handleSubmitClick} />
          }
        ]);
        break;
      case 'sectionBreak':
        setLeftButtons([{component: <div />}]);
        setCenterButtons([{
          component: <NextButton onClick={async () => {
            prometricDelay && await delay(1667);
            transitionBlock('next')
          }} />,
        }]);
        setRightButtons([{component: <div />}]);
        break;
      case 'startBlock':
        setLeftButtons([]);
        setCenterButtons([!loading ? {
          component: <NextButton onClick={async () => {
            if (isReadinessExam) {
              instantiateTestProgress(test);
              setLoading(true);
              return;
            }
            prometricDelay && await delay(1667);
            transitionBlock('next');
            toggleTimer();
          }} />
        }: null]);
        setRightButtons([]);
        break;
      case 'endBlock':
      case 'endReview':
        setNavigationFilter({});
        setLeftButtons([
          {
            component: <ReviewMarkedButton onClick={async () => {
              if (questionProgresses.findIndex(({didMark}) => didMark) === -1) return;
              prometricDelay && await delay(1667);
              setNavigationFilter({key: 'didMark', value: true});
              transitionBlock('reviewMarked');
            }} />,
          },
          {
            component: <ReviewAllButton onClick={async () => {
              prometricDelay && await delay(1667);
              transitionBlock('reviewAll')
            }} />,
          },
          {
            component: <ReviewIncompleteButton onClick={async () => {
              if (questionProgresses.findIndex(({didSkip}) => didSkip) === -1) return;
              prometricDelay && await delay(1667);
              setNavigationFilter({key: 'didSkip', value: true});
              transitionBlock('reviewSkipped');
            }}/>,
          }
        ])
        setCenterButtons([]);
        if (template === 'customTest') {
          setRightButtons([
            {component: <SuspendButton onClick={handleSuspendClick} />},
            {component: <EndButton onClick={handleSubmitClick} />},
          ])
        } else {
          setRightButtons([{component: <EndButton onClick={handleSubmitClick} />}]);
        }
        break;
      default:
        return;
    }
  }, [blockIndex, loading, quizProgress, endBlockIndex]);

  const BlockComponent = blockRenderer(testBlockConnections[blockIndex]);
  return (
    <Container onContextMenu={e => {e.preventDefault()}}>
      <TopBar size={1}>
        <Column>
          <ControlsContainer>
            <ControlsPadding>
              <ControlSection
                justify={'flex-start'}
              >
                {isReadinessExam && template === 'tbcSavedBank' && blockIndex !== 0 ? null : <CloseButton
                  onClick={async () => {
                    if (template === 'customTest' || isReadinessExam) {
                      return blockIndex === 0
                        ? handleGoBack()
                        : await handleSuspendClick();
                    }
                    modalDispatch({
                      type: 'open',
                      component: SubmitAnatomyCase,
                      enableClickClose: true,
                      componentProps: {
                        prometricStyling: true,
                        headerText: 'Would you like to exit the test?',
                        bodyText: 'Your test is in progress. If you exit now, your test results will NOT be saved.',
                        cancelConfig: {
                          text: `Return to the Test`,
                          onCancel: () => {
                            modalDispatch({type: 'close'});
                          },
                          shouldRedirect: false,

                        },
                        confirmConfig: {
                          text: 'Exit Test',
                          color: '#0F6292',
                          onConfirm: () => {
                            toggleReloadBlocker(false);
                          },
                          shouldRedirect: true,
                          redirect: location => location.pathname.split('/').slice(0, -1).join('/')
                        }
                      },
                    })
                  }}
                  type={'secondary'}
                  size={'large'}
                  children={<CloseIcon />}
                />}
                {!([startBlockIndex, endBlockIndex].includes(blockIndex) || (currentBlockType !== 'questionSet' && currentBlockType !== 'passage')) && (topLeftText || <><HideMobile inline>Question&nbsp;</HideMobile>{questionCountThusFar} of {questionCount}</>)}
              </ControlSection>
              <ControlSection justify={'center'} style={{textAlign: 'center', flexDirection: 'column'}}>
                Bootcamp.com
                <HideMobile>
                  {isFullLengthTest && blockIndex === 0 ? 'Full Length Test' : isFullLengthTest ? sectionTitle : test?.config?.title}
                  {(template === 'customTest' && customTestConfig?.config)? `${customTestConfig?.config?.tutorMode ? 'Tutored' : 'Untutored'}, ${customTestConfig?.config?.timed ? 'Timed' : 'Untimed'}` : null}
                </HideMobile>
              </ControlSection>
              <ControlSection
                justify={'flex-end'}
                invisible={
                  blockIndex === 0 && isFullLengthTest
                    ? false
                    : [startBlockIndex].includes(blockIndex)
                  || ![
                    'questionSet',
                    'passage',
                    'sectionBreak',
                    'sectionReview',
                    'endBlock',
                    'endReview',
                  ].includes(currentBlockType)
                }>
                {loading || topRightText ? topRightText : template === 'customTest' && hideTimer ? null : <TestTimer />}
                {loading || (['fullLengthTest', 'practiceTest'].includes(template)) ? null :
                <ToggleSettingsButton
                  type={'secondary'}
                  size={'large'}
                  children={<ToggleSettingsButtonIcon size={28}/>}
                  onClick={() => {
                    if (template === 'customTest' || (isReadinessExam && template === 'testReview')) {
                      sidebarState[1](true);
                    } else {
                      modalDispatch({
                        type: 'open',
                        component: TestSettings,
                        componentProps: {isFullLengthTest},
                        enableClickClose: true,
                          modalContainerStyle: theme => `
                            background: rgba(94, 110, 141, 0.9);
                            padding: 10vh 10vw;
                            align-items: center;
                            ${theme.mediaQueries.laptop} {
                              background: rgba(94, 110, 141, 0.9);
                              padding: 0;
                            }
                          `
                      })
                    }
                  }}
                />
                }
              </ControlSection>
            </ControlsPadding>
          </ControlsContainer>
        </Column>
      </TopBar>
      {(template === 'customTest' || (isReadinessExam && template === 'testReview')) && <Sidebar sidebarState={sidebarState} themePalette={theme.colors.prometricPalette[bootcamp] || theme.colors.brandPalette.blue} />}
      <ContentArea size={1} children={BlockComponent}/>
      <Controls/>
      {(showingExhibit && exhibitType) && <Exhibit type={exhibitType} onCloseExhibit={() => setShowingExhibit(false)} />}
      <FullPageSpinner active={loading}/>
    </Container>
  )
}

const TimedPrometricBank = () => {
  const {variables: {test, customTestConfig, template, loading, type, quizProgress}} = useTestNavigatorContext();

  const {searchUserInteractions, bootcamp} = useUserDataContext();

  const timeAccommodation = searchUserInteractions('timeAccommodation') === true;

  const isReadinessExam = type === 'readinessExam';

  const baseTimeLimit = isReadinessExam ? 105 : !test || loading ? null : customTestConfig?.suspended
    ? moment.duration(customTestConfig?.timeLeft).asMinutes() || parseInt(customTestConfig?.config?.testLength) * 1.05
    : customTestConfig?.config ? (parseInt(customTestConfig?.config?.testLength) * 1.05 || null) : test?.config?.timeLimit || 30;

  const timeLimit = !baseTimeLimit
    ? null
    : (bootcamp === 'dat' || (bootcamp === 'inbde' && isReadinessExam)) && timeAccommodation
    ? baseTimeLimit * 1.5
    : baseTimeLimit;

    return (
      <TimerProvider
        timeLimit={timeLimit}
        autoStart={customTestConfig?.config}
        disabled={customTestConfig?.submitted || ['tbcQuestionBank', 'testReview'].includes(template)}
        stopwatch={customTestConfig && !customTestConfig?.config?.timed}
      >
      <PrometricBank/>
    </TimerProvider>
  )
}

export default TimedPrometricBank;
export {handleCloseClick}
