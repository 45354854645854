import React from 'react';
import styled from 'styled-components';

import {Container, Header, TextTooltip} from './shared';
import {useCreateTestState} from '../context';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${({theme}) => theme.layouts.spacing.l};
  cursor: pointer;
`;
const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  border-radius: 8px;
  margin-right: ${({theme}) => theme.layouts.spacing.s};

  input {
    margin: 0;
    opacity: 0;
    height: 0;
    width: 0;
  }

  input:checked + span {
    background-color: ${({theme}) => theme.colors.brandPalette.blue.default};
  }

  input:focused + span {
    box-shadow: 0 0 1px ${({theme}) => theme.colors.brandPalette.blue.default};

  }

  input:checked + span:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E9E9EA;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 16px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 8px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
`;
const Mode = () => {
  const {
    methods: {
      setMode,
    },
    variables: {
      mode,
      loading,
    }
  } = useCreateTestState();

  function handleChange(event, modeType) {
    const newValue = !mode[modeType];

    event.preventDefault();
    event.stopPropagation();

    setMode(modeState => ({...modeState, [modeType]: newValue}));
  }

  return (
    <Container>
      <HeaderWrapper>
        <Header style={{marginBottom: 0}}>Test Settings</Header>
        <TextTooltip id={'mode-tooltip'}>
          <strong>Tutor</strong> shows an explanation after you answer.<br />
          <strong>Timed</strong> sets a time limit on the test.
        </TextTooltip>
      </HeaderWrapper>
      <Wrapper>
      <SwitchWrapper onClick={(e) => handleChange(e, 'tutorMode')}>
        <Switch>
          <input type={'checkbox'} disabled={loading} checked={mode.tutorMode}/>
          <Slider/>
        </Switch>
        Tutor
      </SwitchWrapper>
      <SwitchWrapper onClick={(e) => handleChange(e, 'timed')}>
        <Switch>
          <input type={'checkbox'} disabled={loading} checked={mode.timed}/>
          <Slider/>
        </Switch>
        Timed
      </SwitchWrapper>
      </Wrapper>
    </Container>
  );
};

export default Mode;