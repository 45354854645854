import React from 'react';
import {PrometricWrapper, CenterSection, NextText} from './shared';
import SectionBreakImage from '../assets/SectionBreak.png';

const SectionBreak = () => {
  return (
    <PrometricWrapper>
      <CenterSection>
        <h2>Section Break</h2>
        <p style={{textAlign: 'left'}}>
          STOP! Please read this message. This is an optional break. The amount of time you have for this break is shown in the "Time Remaining" box in the upper right corner of the screen. Clicking the NEXT button will terminate the break immediately and start the next section of the test. Click NEXT to continue before the break period expires.
        </p>
        <p style={{textAlign: 'left'}}>
          After the break time expires, testing will resume automatically.
        </p>
      </CenterSection>
      <NextText />
    </PrometricWrapper>
  );
};

export default SectionBreak;
